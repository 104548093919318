import { makeStyles } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import KeyboardDoubleArrowDownOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowDownOutlined";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useEffect, useRef, useState } from "react";
import getLoggedInUserRole from "../../Shared/utils/getLoggedInUserRole";
import { UserAuth } from "../../models/interfaces";
import LocationFilter from "../ui/Filters/LocationFilter";
import RecruiterFilter from "../ui/Filters/RecruiterFilter";

const useStyles = makeStyles(() => ({
  menuItem: {
    "& .MuiMenu-list li:hover": {
      backgroundColor: "#fff !important",
      color: "#4540DB",
    },
  },

  scrollMenu: {
    "&::-webkit-scrollbar": {
      width: "0.2em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#4540DB",
      outline: "1px solid #4540DB",
    },
  },
}));

const SubmissionFilterTableToolbar = (props) => {
  const childRef = useRef(null);
  const handleClearChildState = () => {
    childRef.current.clearState();
  }
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [anchorE3, setAnchorE3] = React.useState(null);


  const [uniQue, setuniQue] = React.useState(Date.now());

  const [search, setSearch] = React.useState({
    status: "",
    location: "",
    contractType: "",
  });

  const initialFilterState = {
    status: [],
    location: [],
    contractType: [],
    recruiters : [],
  };

  const [filterState, setfilterState] = useState(initialFilterState);

  const ClearLocationAndContract = () => {
    setfilterState({ ...filterState, location: [], recruiterFilter:[] });
    props.changeState({
      ...props.currentState,
      pageIndex: 1,
      contractType: [],
      location: [],
      recruiterFilter : []
    });
    handleClearChildState()
    
  };
  const clearStatus = () => {
    setuniQue(Date.now());
    props.changeState({ ...props.currentState, pageIndex: 1, statuses: [] });
    setSearch({...search,status:""})
  };


  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorE2);
  const open3 = Boolean(anchorE3);

  const handleKeyWordChange = (e) => {
    props.changeState({
      ...props.currentState,
      searchKeywords: e.target.value,
      pageIndex: 1,
    });
  };
  const userRole = getLoggedInUserRole();

  useEffect(() => {
    filterContractType(props.contractTypesDefault, search.contractType);
  }, [search.contractType]);

  useEffect(() => {
    filterStatuses(props.submissionStatusesDefault, search.status);
  }, [search.status]);

  const filterContractType = (list, searchValue) => {
    let filterList = [];
    if (searchValue !== "") {
      filterList = list.filter((element) =>
        element.match(new RegExp(searchValue, "i"))
      );
      props.setContractTypes(filterList);
    } else {
      props.setContractTypes(props.contractTypesDefault);
    }
  };

  const filterStatuses = (list, searchValue) => {
    let filterList = [];
    if (searchValue !== "") {
      filterList = list?.filter((element) =>
        element?.eventName.match(new RegExp(searchValue, "i"))
      );
      props.setSubmissionStatuses(filterList);
    } else {
      props.setSubmissionStatuses(props.submissionStatusesDefault);
    }
  };

  const openStatusFilterPopUp = (event) => {
    setAnchorEl(event.currentTarget);
       lockScroll(true)
  };

  const openFilterPopUp = (event) => {
    setAnchorE2(event.currentTarget);
       lockScroll(true)
  };
  const openRecruterFilterPopUp = (event) => {
    setAnchorE3(event.currentTarget);
       lockScroll(true)
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorE2(null);
    setAnchorE3(null);
    lockScroll(false)
  };

  const handleSortByChange = () => {
    let newOrder =
      props.currentState.sortOrder === "Ascending" ? "Descending" : "Ascending";
    props.changeState({
      ...props.currentState,
      sortOrder: newOrder,
      pageIndex: 1,
    });
  };

  const changeSearchHandler = (e) => {
    const {
      target: { name, value },
    } = e;
    setSearch({ [name]: value });
  };

  const lockScroll = (lock) => {
    const html = document.querySelector("html");
    if (html) {
      html.style.overflow = lock ? "hidden" : "auto";
    }
  }

  function handleContractTypeChecked(e) {
    props.changeState({
      ...props.currentState,
      pageIndex: 1,
      contractType: [
        ...new Set([...props.currentState.contractType, e.target.name]),
      ],
    });
  }

  function handleStatusChecked(e) {
    let selectedEventName = e.target.name;

    let matchingStatuesNamesList = props?.submissionStatusesDefault?.filter(
      (row) => {
        if (selectedEventName === row.eventName) {
          return row.matchingEventsNames;
        }
      }
    );

    let matchingStatuesNames = [];

    if (matchingStatuesNamesList.length > 0) {
      matchingStatuesNamesList?.forEach((element) => {
        matchingStatuesNames = [
          ...matchingStatuesNames,
          ...element.matchingEventsNames,
        ];
      });
    } 

    props.changeState({
      ...props.currentState,
      pageIndex: 1,
      statuses: [
        ...new Set([
          ...props.currentState.statuses,
          ...matchingStatuesNames,
          selectedEventName,
        ]),
      ],
    });
  }
  
  function handleLocationChecked(e) {
    props.changeState({
      ...props.currentState,
      pageIndex: 1,
      location: [
        ...new Set([...props.currentState.location, e.target.name]),
      ],
    });
  }
  
  function handleRecruitersChecked(e) {
    props.changeState({
      ...props.currentState,
      pageIndex: 1,
      recruiterFilter: [
        ...new Set([...props.currentState.recruiterFilter, e.target.name]),
      ],
    });
  }

  function handleFilterChecked(e, changeType) {
    if (changeType === "contractType") {
      handleContractTypeChecked(e);
    }
    if (changeType === "status") {
      handleStatusChecked(e);
    }
    if (changeType === "location") {
      handleLocationChecked(e);
    }
    if (changeType === "recruiters") {
      handleRecruitersChecked(e);
    }
    if (!filterState[changeType].includes(e.target.name)) {
      let newStateVal = filterState[changeType];
      newStateVal.push(e.target.name);
      setfilterState({
        ...filterState,
        pageIndex: 1,
        [changeType]: newStateVal,
      });
    }
  }

  function handleContractTypeUnchecked(e) {
    let filterContractTypeArray = [];
    filterContractTypeArray = props.currentState.contractType.filter(
      (val) => val !== e.target.name
    );
    props.changeState({
      ...props.currentState,
      pageIndex: 1,
      contractType: filterContractTypeArray,
    });
  }

  function handleStatusUnchecked(e) {
    let filterContractTypeArray = [];
    filterContractTypeArray = props.currentState.statuses.filter(
      (val) => val !== e.target.name
    );
    props.changeState({
      ...props.currentState,
      pageIndex: 1,
      statuses: filterContractTypeArray,
    });
  }

  function handleLocationUnchecked(e) {
    let filterContractTypeArray = [];
    filterContractTypeArray = props.currentState.location.filter(
      (val) => val !== e.target.name
    );
    props.changeState({
      ...props.currentState,
      pageIndex: 1,
      location: filterContractTypeArray,
    });
  }

  function handleRecruitersUnchecked(e) {
    let filterContractTypeArray = [];
    filterContractTypeArray = props.currentState.recruiterFilter.filter(
      (val) => val !== e.target.name
    );
    props.changeState({
      ...props.currentState,
      pageIndex: 1,
      recruiterFilter: filterContractTypeArray,
    });
  }

  function handleFilterUnchecked(e, changeType) {
    if (changeType === "contractType") {
      handleContractTypeUnchecked(e);
    }

    if (changeType === "status") {
      handleStatusUnchecked(e);
    }

    if (changeType === "recruiters") {
      handleRecruitersUnchecked(e);
    }

    if (changeType === "location") {
      handleLocationUnchecked(e);
    }

    if (filterState[changeType].includes(e.target.name)) {
      let filteredArray = filterState[changeType].filter(
        (item) => item !== e.target.name
      );
      setfilterState({
        ...filterState,
        pageIndex: 1,
        [changeType]: filteredArray,
      });
    }
  }

  const handleFilterChange = (e, changeType) => {
    if (changeType == "locationFilterInputs") {
      changeType = "location";
    }
    let isChecked = e.target.checked;

    if (isChecked) {
      handleFilterChecked(e, changeType);
    } else {
      handleFilterUnchecked(e, changeType);
    }
  };

  const renderStatusList = () => {
    return (
      <MenuList>
        {" "}
        {props.submissionStatuses.map((row, index) => {
          return (
            <MenuItem
              key={row?.eventName ?? index}
              sx={{ paddingLeft: "20px", paddingBottom: "2px",fontSize:"14px" }}
            >
              <FormControlLabel
                label={row.eventName}
                className="SubmissionFilterLabel"
                control={<Checkbox
                 
                  onChange={(e) => handleFilterChange(e, "status")}
                  name={row.eventName}
                  checked={props.currentState.statuses?.includes(row.eventName)}
                  sx={{ padding: "0px" }}
                />
              }
              />
            </MenuItem>
          );
        })}
      </MenuList>
    );
  };

  const renderContractList = () => {
    return (
      <MenuList>
        {" "}
        {props.contractTypes.map((contractTypeName, index) => {
          return (
            <MenuItem
              key={ contractTypeName ?? index}
              sx={{ paddingLeft: "20px", paddingBottom: "2px" }}
            >
               <FormControlLabel
               label={contractTypeName}
               className="SubmissionFilterLabel"
                control={<Checkbox
                  onChange={(e) => handleFilterChange(e, "contractType")}
                  name={contractTypeName}
                  checked={props?.currentState?.contractType.includes(
                    contractTypeName
                  )}
                  sx={{ padding: "0px" }}
                />
               }
               />
            </MenuItem>
          );
        })}
      </MenuList>
    );
  };

  return (
    <>
      <Grid container item xs={12} md={12} spacing={2}>
        <Grid container item xs={12} lg={5} spacing={2}>
          <Card style={{ width: "100%", boxShadow: "none" }}>
            <CardContent>
              <TextField
                onChange={handleKeyWordChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                label="Search Candidates"
                fullWidth
                placeholder="Search by Name, Job Title"
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid
          container
          item
          xs={12}
          lg={6}
          spacing={1}
          sx={{
            padding: {
              xs: "0 18px 20px !important",
              lg: "28px 16px 20px !important",
            },
            justifyContent: {
              XS: "flex-start",
              md: "flex-end",
              lg: "flex-start",
            },
          }}
        >
          <Button
            variant="text"
            component="div"
            size="large"
            style={{
              backgroundColor: "#F6F7FC",
              height: "47px",
              width: "120px",
              textTransform: "none",
              fontSize: "17px",
            }}
            sx={{ margin: { xs: "10px", sm: "0 10px 0 0" } }}
            startIcon={<DataUsageIcon />}
            onClick={openStatusFilterPopUp}
            data-testid="btn-submissionfilter-status"
          >
            Status
          </Button>
          <Menu
            id="basic-menu2"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            className={classes.menuItem}
          >
            <TextField
              name="status"
              value={search.status}
              onChange={changeSearchHandler}
              id="standard-basic"
              placeholder="Choose Status"
              variant="standard"
              sx={{ marginLeft: "20px" }}
              InputProps={{
                disableUnderline: true,
              }}
            />
            <span>
              <Button
                data-testid="btn-clear-all-statusfilter"
                style={{
                  marginLeft: "10px",
                  height: "30px",
                  width: "30px",
                  fontSize: "14px",
                }}
                onClick={() => {
                  clearStatus();
                }}
              >
                Clear All
              </Button>
            </span>
            <Divider
              sx={{
                color: "primary",
                width: "12vw",
                marginLeft: "21px",
                bgcolor: "secondary.light",

                padding: "0.8px",
                marginTop: "2px",
              }}
            />
            <Paper
              elevation={3}
              sx={{
                maxWidth: "100%",
                display: "grid",
                flexWrap: "nowrap",
                marginBottom: "25px",
                marginTop: "18px",
                marginRight: "25px",
                marginLeft: "25px",
              }}
              className={classes.scrollMenu}
              style={{
                maxHeight: 230,
                overflow: "auto",
              }}
            >
              {props.submissionStatuses.length === 0 ? (
                <p>No data found</p>
              ) : (
                renderStatusList()
              )}
            </Paper>
          </Menu>
          <Button
            variant="text"
            component="div"
            size="large"
            style={{
              backgroundColor: "#F6F7FC",
              height: "47px",
              width: "120px",
              fontSize: "17px",
            }}
            sx={{ margin: { xs: "10px", sm: "0 10px" } }}
            startIcon={<LayersOutlinedIcon />}
            id="demo-positioned-button"
            aria-controls={open ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={openFilterPopUp}
            data-testid="btn-submissionfilter-location"
          >
            Filter
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorE2}
            open={open2}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            className={classes.menuItem}
          >
            <Typography style={{ display: "none" }}>^</Typography>

            <Typography variant="h5" component="h5" color="#929292" ml={2.5}>
              <span> Location:</span> ({filterState.location?.length + " "}
              Selected)
              <Button
                style={{
                  marginLeft: "10px",
                  height: "30px",
                  width: "30px",
                  fontSize: "14px",
                }}
                onClick={() => {
                  ClearLocationAndContract();
                }}
                data-testid="btn-clear-all-locationfilter"
              >
                Clear All
              </Button>
            </Typography>

            <LocationFilter
              ref={childRef}
              key={uniQue}
              filterState={filterState.location}
              onChange={handleFilterChange}
            />
            <Typography variant="h5" component="h5" color="#929292" ml={2.5}>
              Choose Contract
            </Typography>
            
            <Divider
              sx={{
                color: "primary",
                width: "10rem",
                marginLeft: "21px",
                bgcolor: "secondary.light",
                borderBottomWidth: "2px",
                padding: "0.8px",
                marginTop: "2px",
              }}
            />
            <Paper
              elevation={3}
              sx={{
                maxWidth: "100%",
                display: "grid",
                flexWrap: "nowrap",
                marginBottom: "25px",
                marginTop: "18px",
                marginRight: "25px",
                marginLeft: "25px",
              }}
              className={classes.scrollMenu}
              style={{
                maxHeight: 230,
                overflow: "auto",
              }}
            >
              {props.contractTypes.length === 0 ? (
                <p> No data found</p>
              ) : (
                renderContractList()
              )}
            </Paper>
          </Menu>
                    <Button
            variant="text"
            component="div"
            size="large"
            onClick={handleSortByChange}
            style={{
              backgroundColor: "#F6F7FC",
              height: "47px",
              width: "120px",
              textTransform: "none",
              fontSize: "17px",
            }}
            sx={{ margin: { xs: "10px", sm: "0 10px" } }}
            startIcon={
              props.currentState.sortOrder === "Ascending" ? (
                <KeyboardDoubleArrowDownOutlinedIcon />
              ) : (
                <KeyboardDoubleArrowUpIcon />
              )
            }
          >
            Sort by
          </Button>
          {userRole == UserAuth.Admin && 
          <>
          <Button
            variant="text"
            component="div"
            size="large"
            style={{
              backgroundColor: "#F6F7FC",
              height: "47px",
              width: "120px",
              fontSize: "17px",
            }}
            sx={{ margin: { xs: "10px", sm: "0 10px" } }}
            startIcon={<LayersOutlinedIcon />}
            id="demo-positioned-button"
            aria-controls={open3 ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open3 ? "true" : undefined}
            onClick={openRecruterFilterPopUp}
            data-testid="btn-submissionfilter-recruiter"
          >
            Recruiters
          </Button>
          <Menu
            id="basic-menu"
            className={classes.menuItem}

            anchorEl={anchorE3}
            open={true}
            disableScrollLock={true}
            style={{ display: open3 ? "block" : "none" }}
            onClose={()=> { handleClose()}}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
          <Typography style={{ display: "none" }}>^</Typography>

           <Typography variant="h5" component="h5" color="#929292" ml={2.5}>
              <span> Recruiters:</span> ({props.currentState.recruiterFilter?.length + " "}
              Selected)
              <Button
                style={{
                  marginLeft: "10px",
                  height: "30px",
                  width: "30px",
                  fontSize: "14px",
                }}
                onClick={() => {
                  ClearLocationAndContract();
                }}
                data-testid="btn-clear-all-recruiterfilter"
              >
                Clear All
              </Button>
            </Typography>

            <RecruiterFilter
             ref={childRef}
              key={uniQue}
              filterState={props.currentState.recruiterFilter}
              onChange={handleFilterChange}
            />
            
          
            
          </Menu>
          </>}

          
        </Grid>
      </Grid>
    </>
  );
};

export default SubmissionFilterTableToolbar;
