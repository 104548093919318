import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import React from "react";
import BannerText from "../Common/BannerText";
import { LoginBanner } from "./LoginBanner";
import SignupForm from "./SignupForm";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export const SignUp: React.FC = () => {
  return (
    <React.Fragment>
      <Container maxWidth="xl" className="login-banner-text">
        <Grid container mt={2} sx={{ justifyContent: "space-around" }}>
          <Grid item xs={12}>
            <BannerText bannerText="Your One Stop Recruitment Marketplace" />
          </Grid>
        </Grid>
      </Container>
      <Box
        sx={{
          flexGrow: 1,
          padding: "20px 0",
        }}
      >
        <Container maxWidth="lg" className="login-banner-text">
          <Grid
            container
            spacing={3}
            sx={{ justifyContent: "center", marginTop: "30px" }}
          >
            <LoginBanner />
            <Grid item xs={12} md={5} className="login-right-section" pl={0}
              sx={{ display: "flex"}}
            >
              <Item
                elevation={0}
                sx={{
                  backgroundColor: "transparent",
                  textAlign: "left",
                  paddingRight: { xs: "0 ", md: "40px" },
                  padding: "0",
                }}
                className="login-banner-right"
              >
                <SignupForm />
              </Item>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  );
};
export default SignUp;