import * as Yup from "yup";
import { cityRegex, firstNameRegex, phoneNumberRegex } from "../../../../constants/regex";

const TeamMemberAddEditFormValidationSchema = Yup.object().shape({
    firstName: Yup.string()
    .required("Please enter the first name")
    .matches(firstNameRegex, "Entered First Name is invalid"),
    middleName: Yup.string(),
    lastName: Yup.string()
    .required("Please enter the last name")
    .matches(firstNameRegex, "Entered Last Name is invalid"),
    email: Yup.string()
    .email("Entered email address is invalid")
    .required("Please enter a valid email address"),
    primaryPhoneNumber: Yup.string()
    .required("Please enter the phone number")
    .matches(phoneNumberRegex, "Entered Phone number is invalid"),
    address: Yup.object({
      city: Yup.string().required("Please enter the city").matches(cityRegex, "Entered City is invalid"),
      state: Yup.string().required("Please enter the state").matches(cityRegex, "Entered State is invalid")
    }),
  });

  export default TeamMemberAddEditFormValidationSchema;