import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { GetCandidateEventReminderTypes } from '../../../../Services/candidateService';

export interface ReminderTypesOption {
  id: string;
  reminderDisplayValue: string;
  reminderMinutes:number | undefined;
}

interface Props {
  label:string;
    fieldName:string;
    value:ReminderTypesOption;
    defaultValue?:number;
    isRequired?:boolean;
    isReadOnly?:boolean;
    isError?: boolean;
    helperText?:string | boolean;
    handleChange: (selectedEventType: ReminderTypesOption | null) => void;
    handleBlur: (e: React.FocusEvent<any, Element>) => void;
}

const ReminderAutoComplete: React.FC<Props> = ({ 
  label,
  fieldName,
  value,
  defaultValue,
  isRequired=false,
  isError=false,
  helperText="", 
  handleChange,
  handleBlur,
  isReadOnly=false
 }) => {
   //Initial component States

   const [reminderTypesList, setReminderTypesList] = useState<ReminderTypesOption[]>([]);


   useEffect(()=>{
    _GetCandidateEventReminderTypes();
   },[])

   useEffect(()=>{     
    if(defaultValue && reminderTypesList?.length > 0 ){
      let matchObj:ReminderTypesOption[] = filterRemindersByMinutes(reminderTypesList, defaultValue);
      handleChange(matchObj[0])
    }
   },[defaultValue,reminderTypesList])

   function filterRemindersByMinutes(reminders: ReminderTypesOption[], targetMinutes: number): ReminderTypesOption[] {
    const matchingReminders: ReminderTypesOption[] = reminders.filter(
      (reminder) => reminder.reminderMinutes === targetMinutes
    );
    return matchingReminders;
  }
 
     // GET Master Reminder Type for candidate interview schedule
     const _GetCandidateEventReminderTypes = async () => {
         GetCandidateEventReminderTypes()
         .then((response: any) => {
           if (response?.data?.message?.appStatusCode === "RC_RSF") {
            setReminderTypesList(response?.data?.entityList);            
           } else {
            setReminderTypesList([]);
           }
         })
         .catch((error) => {
           console.log(error);
         });
     };

  const handleDurationChange = (_event: React.ChangeEvent<{}>, changedValue: ReminderTypesOption | null) => {
    handleChange(changedValue);
  };

  return (
    <Autocomplete
      readOnly={isReadOnly}
      clearOnEscape
      value={value}
      onChange={handleDurationChange}
      options={reminderTypesList}
      getOptionLabel={(option) => option.reminderDisplayValue}
      renderInput={(params) => (
        <TextField
        {...params}
        required={isRequired}
        label={label}
        name={fieldName}
        variant="standard"
        onBlur={handleBlur}
        inputProps={{
          ...params.inputProps,
        }}
        error={isError}
        helperText={helperText}
        />
      )}
    />
  );
};

export default ReminderAutoComplete;
