import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { CalculateCompensation } from "../../Services/recruiterService";
import getPackageTypeForCompensationCalculatorView from "../../Shared/utils/getPackageTypeForCompensationCalculatorView";
import getUserDetails from "../../Shared/utils/getUserDetails";
import formatAmount from "../../utils/formatAmount";
import { Payrate } from "../ui/Payrate";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";
  
const IOSSlider = styled(Slider)(({ theme }) => ({
  color: "#3880ff",
  height: 1,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 10,
    width: 22,
    borderRadius: "5px",
    backgroundColor: "#3F3D79",
    boxShadow: iOSBoxShadow,
    "&:focus, &:hover, &.Mui-active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 14,
    fontWeight: "normal",
    top: 0,
    backgroundColor: "unset",
    color: theme.palette.text.primary,
    "&:before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#000",
    },
  },
  "& .MuiSlider-track": {
    border: "none",
    backgroundColor: "#4540DB",
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    backgroundColor: "#4540DB",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#4540DB",
    height: 8,
    width: 1,
    display: "none",
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "#4540DB",
    },
  },
}));

export const CompensationCalculatorBox = (props) => {
  let candidateEmployment = props.selectedCandidateEmployment?.replace(/ /g,'')
 
  let packageType = getPackageTypeForCompensationCalculatorView(props.jobDetails.package.packageType)
   let state =  {
      maxPayRate: props?.jobDetails?.package?.packageValue,
     
     
      calculatedCompensation: "",
      yearlyCompensation: ""
      }
     
      const [expected, setExpected] = useState(props.rateEntered)
      const [employmentType, setemploymentType] = useState(candidateEmployment)

      const [first, setfirst] = useState(state)
      const changeHandler = (val)=>{
        setExpected(val)
        props.changeHandler(val)
      }
      const calculate = ()=> {
        let request =  {
            maxPayRate: props?.jobDetails?.package?.packageValue,
            expectedPayRate: expected,
            netBillRate : props?.jobDetails?.billRatePckg?.netBillRate,
            billRate : props?.jobDetails?.billRatePckg?.billRate,
             employmentType : employmentType ,
            recruiterGuid : getUserDetails().id,

            payRateType: props.jobDetails.package.packageType==='annually'? 'yearly' : props.jobDetails.package.packageType ,

            calculatedCompensation: "",
            yearlyCompensation: ""
            }
        CalculateCompensation(request)?.then(response=> {
            setfirst(response?.data?.entity)})
      }
      useEffect(() => {
        
        calculate()
       
      }, [expected,employmentType])
      useEffect(() => {
        
        setExpected(props?.rateEntered || 0)
        setemploymentType(candidateEmployment)
       
      }, [props.rateEntered,candidateEmployment])
      let yourComp = formatAmount(first?.calculatedCompensation);
      
  return (
    <Grid item xs={12} md={8}>
    <Item elevation={0}>
      <Box className="compensation-calculator-box">
        <Grid>
          <Typography
            variant="h6"
            component="div"
            className="roboto-font letter-spacing-normal primary-color-text"
            sx={{ paddingTop: "0px" }}
          >
           Compensation Calculator (Job ID:{props.jobDetails?.id})
            <IconButton
              data-testid="closeicon"
              aria-label="close"
              onClick={() => {
               props.onClose()
              }}
              sx={{
                float: "right",
                color: (theme) =>
                  theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Typography>
        </Grid>
        <Grid
          container
          spacing={0}
          className="compensation-calculator-table"
        >
          <Grid item xs={12} md={7}>
            <Item>
              <Grid container spacing={1}>
               
                {/* Row 1 */}
                <Grid item xs={8} md={8}>
                  <Item>Maximum Pay Rate</Item>
                </Grid>
                <Grid item xs={4} md={4}>
                <Item style={{ textAlign: "end" }}>
                    <Payrate packageType={packageType} packageValue={props.jobDetails?.package
                          .packageValue} currency={props?.jobDetails?.package?.packageCurrency} />
                  </Item>
                  
                </Grid>
               
                {/* Row 2 */}
                Pay Rate Expected
                <Grid item xs={8} md={10}>
                
                  <Item style={{paddingLeft:10}}>
                 
                    <IOSSlider
                      aria-label="ios slider"
                      value={expected}
                      marks
                      min={0}
                      step={(props.jobDetails?.package.packageValue? (props.jobDetails?.package.packageValue/100)*1 : 1)*1}
                      max={props.jobDetails?.package.packageValue}
                      valueLabelFormat={props?.jobDetails?.package?.packageCurrency +" "+ formatAmount(Math.floor(expected))}
                      onChange={ (e, val) => changeHandler(val)} 
                      valueLabelDisplay="on"
                    />
                  </Item>
                </Grid>

                <Grid item xs={2} md={2}>
                  <Item style={{paddingLeft:4}}>
                    <strong>  {packageType}</strong>
                  </Item>
                </Grid>
                {/* Row 3 */}
                <Grid item xs={7} md={7}>
                  <Item>Your compensation</Item>
                </Grid>
                <Grid  item xs={5} md={5}>
                  <Item style={{ textAlign: "end" }}>
                   <strong>  {props?.jobDetails?.package?.packageCurrency}{" "+yourComp}</strong>
                   <span style={{fontSize: "0.75rem"}}> {packageType}</span >
                  </Item>
                </Grid>
                
              </Grid>
            </Item>
          </Grid>
          <Grid item xs={12} md={5}>
            <Item>
              <Typography
                className="roboto-font"
                variant="caption"
                display="block"
                gutterBottom
              >
                <span className="MuiFormLabel-asterisk">
                  *
                </span>
               
                Please note that your compensation
                will be a function of the pay rate for
                the candidate and how much JobRialto
                is paid for this position by the
                client. The higher the difference
                between tha Maximum Pay Rate and Pay
                Rate Expected, the higher will be your
                compensation. Change the Pay Rate
                Expected to see how it affects your
                compensation for this position. In
                other words, the closer the Pay Rate
                Expected is to the Maximum Pay Rate,
                your compensation will reduce.
              </Typography>
            </Item>
          </Grid>
        </Grid>
        <Box>
          <Typography
            className="roboto-font"
            variant="body1"
            display="block"
            gutterBottom
          >
            Your potential compensation in 1 year for
            this position <strong>{props?.jobDetails?.package?.packageCurrency}{" "+ first?.yearlyCompensation}</strong>
          </Typography>
        </Box>
      </Box>
    </Item>
  </Grid>
  )
}
