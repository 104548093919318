/* jobLocation return name of job location 
@param 
remoteJob - 'y' or 'n'
city - jobCity
stateName - job State Name
*/
export const jobLocation = (remoteJob,city,stateName) => { 
    if (remoteJob === 'y') { 
      return city +", " + stateName + " (Remote)"
    } 
    return city +", " + stateName
  }