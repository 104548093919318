import React from 'react';
import { Dialog, DialogContent, DialogContentText, Button, Typography, IconButton, Grid, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SuccessIcon from '../../../../../assets/images/Approved.svg';
import { useNavigate } from 'react-router-dom';
import { getNevLink } from '../../../../../utils/getFileFromUrl';

interface CandidateSubmittedSuccessProps {
  open: boolean;
  handleClose: () => void;
  editMode: boolean;
}

const CandidateSubmittedSuccessPopup: React.FC<CandidateSubmittedSuccessProps> = ({ 
    open, 
    handleClose, 
    editMode 
}) => {
    const navigate = useNavigate();

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        style: {
          background: "#FFF",
        },
      }}
    >
      <DialogContent>
        <IconButton
          aria-label="close"
          onClick={() => {
            navigate(getNevLink("/jobs/all"));
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContentText
          component="div"
          id="success-dialog-description"
          textAlign="center"
        >
            <figure>
                <img src={SuccessIcon} className="PopupStatusIcon" alt="Success Icon" />
            </figure>
            <Typography
                component="div"
                className="PopupPrimaryTitle"
                my={2}
            >
                {editMode === true
                ? "Candidate Details Updated"
                : "Candidate Details Submitted"}
                <span className='successTextColor'> Successfully</span>
            </Typography>            
            <Typography
                component="div"
                fontWeight={600}
                my={2}
                className="PopupSecondaryTitle"
            >
                {editMode === true
                ? `Candidate details have been updated successfully. As a next step, we will send an email to the candidate mentioning the updated details.`
                : `Thank you for submitting your candidate for this job. As a next step, we will send an email to the candidate to confirm his/her interest in this job. Candidate acknowledgment is mandatory to proceed further.`}
            </Typography>
          <Box my={3}>
            <Grid container>
              <Grid item md={12}>
                <Button
                    className='LeftSideButtonFirst'
                    variant="outlined"
                    onClick={() => {
                        navigate(getNevLink("/submission/active"));
                    }}
                >
                  Go to View Submissions
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate(getNevLink("/jobs/all"));
                  }}
                >
                  Go to Browse Jobs now
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default CandidateSubmittedSuccessPopup;
