import React from 'react';
import Button from "@mui/material/Button";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { LinkedIn  } from 'react-linkedin-login-oauth2';
import axios from './../../Services/CommonHttpRequest'
import { SocialAuthResponse } from '../../models/SocialAuthResponse';
import { plainToInstance } from 'class-transformer';
import { Response } from '../../models/Response';
import { useLocation, useNavigate } from 'react-router-dom';
import { Recruiter } from '../../models/recruiterModel';
import { BillingInfoType, LoggedInUserRole } from '../../models/interfaces';
import setAuthToken from '../../utils/setAuthToken';
import { ClientSocialSignupModel } from '../../models/ClientSocialSignupModel';
import { ClientSocialSignup } from '../../Services/clientService';
import { Client } from '../../models/Client';
import { getRecruiterData } from '../../features/recruiterSlice';
import { loadPermissions } from '../../features/permission/userPermissionSlice';
import getLoggedInUserPermissions from '../../Shared/utils/getLoggedInUserPermission';
import { setLoggedInUseDetails } from '../../features/userDetails/loggedInUserDetailsSlice';
import { useDispatch } from 'react-redux';

// You can use provided image shipped by this package or using your own
interface Props {
  handleAlreadyExist: (errorMessage:string) => void,
  redirectUrl?:string,
  mode?: {recruiter: boolean, client: boolean},
}
const LinkedInButton :React.FC<Props> = ({handleAlreadyExist,redirectUrl,mode}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  const getUserDetails = (code:string) => {
    let linkedIn  = {
      authCode :code,
      redirectUrl:`${window.location.origin}/linkedin`
    };

    let signupFunc = SignupRecruiter;

    if (mode !== undefined && mode.client) {
      signupFunc = SignupClient;
    }

    axios
      .post(`${process.env.REACT_APP_IDPROVIDER_API}api/Authentication/authenticateLinkedIn`,linkedIn,{headers:{ "Content-Type": "application/json" }})
      .then(res => {
        
        if(res.data.status == 200){
          const response = plainToInstance(Response<SocialAuthResponse>,res.data);
        
          if(window.location.pathname == '/login'||window.location.pathname.startsWith("/jobdetail")){
            LoginCommon(response);
          }else if(window.location.pathname == '/signup' || window.location.pathname == '/'){
            signupFunc(response);
          }
        }
      }).catch(err =>{
        console.log(err);
      });
  };

  const SignupRecruiter = (data:Response<SocialAuthResponse>) => {
    const createModel = {
      id: "",
      emailID: data.entity.emailID,
      firstName: data.entity.firstName,
      middleName: "",
      lastName: data.entity.lastName,
      loginType: 'LinkedIn',
      billingInfoType: BillingInfoType[0]

    }
    axios.post(`${process.env.REACT_APP_RECUITER_API}api/RecruiterSocialMediaSignUp/Create`,createModel,{headers:{"Content-Type": "application/json" }})
    .then(res => {
      
      let response = plainToInstance(Response<Recruiter>,res.data);
      if(response.status === 200 && response?.message?.appStatusCode === "RC_RSF") {
        navigate(`/SignupComplete?type=linkedinSuccess&authToken=${data?.entity?.token}`,{state:response.entity});
      }else if(response.status === 409 && response?.message?.appStatusCode === "RC_EXST"){
        handleAlreadyExist(response?.message?.appStatusDescription);
     
      }else{
        handleAlreadyExist(response?.message?.appStatusDescription);
        
      }
    })
  }

  const LoginCommon = (data:Response<SocialAuthResponse>) =>{
    
    let loginModel = {
      userName: data.entity.emailID,
      password: "",
      loginType: "LinkedIn",
      returnUrl: "",
      loggedInUserRole: LoggedInUserRole.Recruiter.toString()

    }
    axios
    .post(`${process.env.REACT_APP_IDPROVIDER_API}api/SignIn/Login`,loginModel,{headers:{ "Content-Type": "application/json" }})
    .then(res => {
      
      if(res.data.status === 200 && res.data.message.appStatusCode === "IP_RSF"){
        localStorage.setItem('user',JSON.stringify(res.data.entity))
        setAuthToken(res.data.accessToken);
        
        dispatch(setLoggedInUseDetails(res.data?.entity))
        let userModules = getLoggedInUserPermissions();
        dispatch(loadPermissions({permissions:userModules}))
        dispatch(getRecruiterData())

        if(redirectUrl){
          navigate(redirectUrl)
        }
        else if (location.state) {
          navigate((location.state as any)?.redirectUrl ?? "/dashboard");
        } else {
          navigate("/dashboard");
        }
      }else if(res.data.status === 200 && res.data.message.appStatusCode === "IP_CRED"){
        handleAlreadyExist(res.data.message.appStatusDescription);
       
      }else if(res.data.status === 401 && res.data.message.appStatusCode === "IP_LINVAL"){
        handleAlreadyExist(res.data.message.appStatusDescription);
        
      }else{
        handleAlreadyExist(res.data.message.appStatusDescription);
        
      }
    }).catch(err =>{
      console.log(err);
    })
  }

  const SignupClient = (data:Response<SocialAuthResponse>) => {
    const model : ClientSocialSignupModel = {
      email: data.entity.emailID,
      firstName: data.entity.firstName,
      middleName: "",
      lastName: data.entity.lastName,
      loginType: "LinkedIn",
    };

    ClientSocialSignup(model)
      .then(res => {
        let response = plainToInstance(Response<Client>,res.data);
        if(response.status === 200 && response?.message?.appStatusCode === "CL_RSF") {
          navigate("/ClientSignup",{state:response.entity});
        } else {
          handleAlreadyExist(response?.message?.appStatusDescription);
        }
      });
  }

  return (
    <LinkedIn
      clientId={process.env.REACT_APP_LINKEDIN_CLIENT as string}
      redirectUri={`${window.location.origin}/linkedin`}
      scope="r_liteprofile r_emailaddress"
      onSuccess={(code: any) => {
        getUserDetails(code);
      }}
      onError={(error: any) => {
        console.log(error);
      }}
      data-testid={"linkedin-login-button"}
    >
      {({ linkedInLogin }) => (
        <Button variant="text" onClick={(event) => {
          event.preventDefault();
          linkedInLogin();
          }} className="linkedin-btn"
        >
          <LinkedInIcon className="linkedin-icon" />
        </Button>
      )}
    </LinkedIn>
  );
}
export default LinkedInButton;
