import React from 'react';
import Grid from '@mui/material/Grid';
import LabelChip from '../LabelChip';
import SectionHeading from '../../../../ui/Form/Heading/SectionHeading';
import { Divider } from '@mui/material';

interface ScorecardCandidateInfoProps {
  technology: string[];
}

const ScorecardTechnology: React.FC<ScorecardCandidateInfoProps> = ({ technology }) => {
  
  return (
    <Grid container spacing={2}>
        <Grid  item xs={12} >
            <SectionHeading
                title='Technology'
            />
             <Divider className="borderedDivider-color" />
        </Grid>
            
        {technology.map((technologyName) => (
            <Grid item key={technologyName}>            
            <LabelChip
                label={technologyName}
            />   
            </Grid>        
            ))}
    </Grid>
  );
};

export default ScorecardTechnology;
