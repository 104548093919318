import React, { useEffect } from 'react'
import { Autocomplete, Chip, CircularProgress, TextField } from '@mui/material'
import useApi from '../../hooks/useApi'
import constructName from '../../utils/constructName'
import { adminFilter } from '../../Services/adminService'

export const SuperadminAutocomplete = (props) => {
    const initialRecruiterFilterState = {
    
        "pageIndex": 1,
        "pageSize": 10,
        "sortColumn": "",
        "sortOrder": "descending",
        "isSorted": true,
        "totalRecord": 0,
        "searchKeywords": "",
        "isActive": true,
        "loggedInUserRole": "SuperAdmin"

        
      
     
    };
    let user = JSON?.parse(localStorage?.getItem("user") || "false");
    let userId = user.userId;
    const [filter, setFilter] = React.useState(
      initialRecruiterFilterState
    );
    const onIndustryInputChange = (e, value) => {
        
        setFilter({ ...filter, searchKeywords: value });
    }
    let admin = props.admin //formik.values.industries
    const allAdmins = useApi(() => adminFilter(filter));
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            allAdmins.request();
           
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [filter.searchKeywords])
    
    const getInputLabel = (option) => {
        const name = constructName(option.firstName, option.middleName, option.lastName);
        const emailPart = props?.showEmail && option.email ? ' (' + option?.email + ')' : '';
        return name + emailPart;
    };

    return (
        <Autocomplete
            data-testid="dropdown-option"
            freeSolo={props.multiple} //remove dropdown arrow
            autoComplete="off"
            noOptionsText={allAdmins?.loading ? 'Loading...' : "No options"}
  
            multiple={props.multiple}
            readOnly={props.readOnly}
            id="size-small-standard-multi3"
            size="medium"
            onInputChange={onIndustryInputChange}
            onBlur={props.onBlur}

            onChange={props.onChange}
            options={allAdmins?.data?.entityList || []}            
            value={admin}
            defaultValue={admin}
            getOptionLabel={(option) => getInputLabel(option)}
            filterOptions={(options, { inputValue }) => {
                // Filter out the option with id = 1
                return options.filter(option => option.userId !== userId);
              }}
            
            renderTags={(value, getTagProps) =>
                value.map((option, index) => { 
                    return  <Chip
                        key={getInputLabel(option) ?? index}
                        color="primary"
                        size="medium"
                        label={getInputLabel(option)}
                        {...getTagProps({ index })}
                    />
            })
            }
            renderInput={(params) => (
                <TextField
                    data-testid="loading-indicator"
                    {...params}
                    placeholder='Search by Name or Email'
                    variant="standard"
                    label={props.label}
                    required={false}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {allAdmins?.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}

                />
            )}
        />
    )
}
SuperadminAutocomplete.defaultProps = {
 
    showEmail: false,
  
  }
  
