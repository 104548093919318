import React from 'react';
import Alert from '@mui/material/Alert';

const NoRecordsFound: React.FC = () => {
  return (
    <Alert severity="info">No Records Found.</Alert>
  );
};

export default NoRecordsFound;
