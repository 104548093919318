import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import DonutLargeOutlinedIcon from "@mui/icons-material/DonutLargeOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Button, Typography, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { PermissionsEnums } from "../../../data/permission";
import {
  ClearClientFilter,
  handleLocations,
  handleSearchKeyword,
  handleStatues,
} from "../../../features/Client/teamMemberSlice";
import { KeyValue } from "../../../models/app/AppModels";
import { AppState } from "../../../store/AppState";
import formatAmount from "../../../utils/formatAmount";
import { getNevLink } from "../../../utils/getFileFromUrl";
import AppFilterToggle, {
  AppFilterToggleRef,
} from "../../Common/Filter/AppFilterToggle";
import AppStatusFilterToggle, {
  AppStatusFilterToggleRef,
} from "../../Common/Filter/AppStatusFilterToggle";
import { ModuleComponent } from "../../Common/Permission/ModuleComponent";
import { Searchbar } from "../../ui/Searchbar";
import "./TeamMember.scss";

interface TeamMemberSearchBarProps {
  totalRecords: number | undefined;
  isApprovedUser: any;
}

export default function TeamMemberSearcxhBar({
  totalRecords,
  isApprovedUser,
}: TeamMemberSearchBarProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  // logger user details from redux state

  const filterRef = React.useRef<AppFilterToggleRef>(null);
  const statusFilterRef = React.useRef<AppStatusFilterToggleRef>(null);

  const { locations, statuses, searchKeyword } = useSelector(
    (state: AppState) => state.teamMemberReducer.filter
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [statusAnchor, setStatusAnchor] = React.useState(null);
  const [search, setSearch] = React.useState(searchKeyword);

  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const searchBarWidth = isSmallScreen ? "310px" : "320px";

  const handleFilterClick = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleStatusClick = (event: any) => {
    setStatusAnchor(statusAnchor ? null : event.currentTarget);
  };

  const onLocationChange = (locations: string[]) => {
    dispatch(handleLocations(locations));
  };

  const onStatusChange = (statues: KeyValue[]) => {
    dispatch(handleStatues(statues));
  };

  const onSearchKeywordChange = () => {
    dispatch(handleSearchKeyword(search ?? ""));
  };

  const clearAllFilter = () => {
    dispatch(ClearClientFilter());
    filterRef.current?.handleClearAll();
    statusFilterRef.current?.handleClearAll();
    setSearch("");
  };

  const haveAnyFilter = () => {
    return (
      (locations && locations?.length > 0) ||
      (statuses && statuses?.length > 0) ||
      (searchKeyword && searchKeyword !== "")
    );
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{ marginTop: "15px" }}
        justifyContent={{ sm: "flex-start", md: "space-between" }}
      >
        <Grid item>
          <Typography className="listing-total-heading">
            {" "}
            Total Team Members ({formatAmount(totalRecords)})
          </Typography>
        </Grid>
        <Grid
          item
        >
          <Grid container justifyContent={{ sm: "flex-start", md: "flex-end" }}>
            <Grid
              item
              style={{ position: "relative" }}
            >
              <Searchbar
                classes="ClientListingSearchbarContainer"
                isNewSeachBar={true}
                width={searchBarWidth}
                height="1.7rem"
                placeHolderText={"Search"}
                txtFontSize={"14px"}
                value={search}
                onChange={(e: any) => {
                  setSearch(e.target.value);
                }}
                onEnter={onSearchKeywordChange}
                onIconClick={onSearchKeywordChange}
              />
            </Grid>
            <Grid
              item
            >
              <Button
                data-testid="textdiv"
                variant="text"
                component="div"
                className="custom-Btn-fontsizes cust-btn-bgClr searchbarRow-ht-btn toolbarActionBtns"
                startIcon={<DonutLargeOutlinedIcon />}
                onClick={handleStatusClick}
              >
                Status
              </Button>
              <Button
                variant="text"
                component="div"
                size="large"
                className="custom-Btn-fontsizes cust-btn-bgClr searchbarRow-ht-btn toolbarActionBtns"
                startIcon={<FilterAltOutlinedIcon />}
                onClick={handleFilterClick}
                data-testid="filter-button"
              >
                Filter
              </Button>
              <ModuleComponent moduleId={PermissionsEnums.Members_Add}>
                <Button
                  disabled={!isApprovedUser}
                  data-testid="filter"
                  variant="contained"
                  startIcon={<AddOutlinedIcon />}
                  className="searchbarRow-ht-btn toolbarActionBtns"
                  onClick={() => {
                    navigate(getNevLink("/addteammember"));
                  }}
                >
                  <span className="custom-addbtns">Add Member</span>
                </Button>
              </ModuleComponent>

              <Button
                variant="text"
                size="small"
                onClick={clearAllFilter}
                className={`toolbarActionBtns ${
                  haveAnyFilter()
                    ? "custom-Btn-fontsizes"
                    : "disable-custom-Btn-fontsizes"
                } cust-btn-bgClr searchbarRow-ht-btn`}
                data-testid="clear-button"
                disabled={!haveAnyFilter()}
                startIcon={<ClearIcon className="closeIcon" />}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AppStatusFilterToggle
        ref={statusFilterRef}
        anchorEl={statusAnchor}
        onClose={handleStatusClick}
        statues={teamMemberStatuses}
        selectedStatuses={statuses ?? []}
        onStatusChange={onStatusChange}
      ></AppStatusFilterToggle>
      <AppFilterToggle
        filterTypes={["Locations"]}
        ref={filterRef}
        anchorEl={anchorEl}
        onClose={handleFilterClick}
        selectedLocations={locations ?? []}
        onLocationChange={onLocationChange}
        onIndustryChange={() => {}}
        selectedIndustries={[]}
      ></AppFilterToggle>
    </>
  );
}

const teamMemberStatuses: KeyValue[] = [
  {
    key: "Active",
    label: "Active",
    value: "true",
  },
  {
    key: "Inactive",
    label: "Inactive",
    value: "false",
  },
];
