import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react";
import { AxiosError, AxiosRequestConfig } from "axios";
import axiosInstance from "../../Services/CommonHttpRequest";
import { AppResponse } from "../../models/app/AppResponse";
import {
  SubmissionFilter,
  SubmissionFilterState,
  SubmissionFilterStateType,
} from "../../models/submission/submissionFilterState";

let baseUrlClient = `${process.env.REACT_APP_CANDIDATE_API}`;
const filterInitialState: SubmissionFilterState = {
  recruiterGuid: "",

  searchKeywords: "",
  jobId: "",
  location: [],
  statuses: [],
  statusIds: [],
  contractType: [],
  showPendingSubmissions: false,

  durationFilter: {
    duration: "0",
    durationType: "All",
    fromDate: "",
    toDate: "",
  },
  candidateGuids: [],
  recruiterFilter: [],
  selectedCandidateFilter: [],
  clientNames: [],
  selectedClientDetails: [],
};
export const initialState: SubmissionFilterStateType = {
  filter: filterInitialState,
  filterType: "filter",
  pendingFilter: filterInitialState,
};

export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl?: string } = { baseUrl: baseUrlClient }
  ): BaseQueryFn<
    {
      url: string;
      method?: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
    },
    unknown,
    unknown
  > =>
  async ({ url, method = "GET", data }) => {
    try {
      const result = await axiosInstance({ url: baseUrl + url, method, data });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: { status: err.response?.status, data: err.response?.data },
      };
    }
  };

export const submissionSlice = createSlice({
  name: "ClientJob",
  initialState: initialState,

  reducers: {
    updateSubmissionFilter: (state, action:  PayloadAction<string>) => {
      const filterToUpdate = state[state.filterType];
     
      filterToUpdate[action.payload] = [];
        
    
    },
    
    handleSubmissionSearchKeyword: (state, action: PayloadAction<string>) => {
      state[state.filterType].searchKeywords = action.payload;
    },
    handleRecruiterFilter: (state, action: PayloadAction<string>) => {
      const itemToAddOrRemove = action.payload;
      const filter = state[state.filterType];

      if (filter.recruiterFilter.includes(itemToAddOrRemove)) {
        // If the item is already in the array, remove it
        filter.recruiterFilter = filter.recruiterFilter.filter(
          (x) => x !== itemToAddOrRemove
        );
      } else {
        // If the item is not in the array, add it
        filter.recruiterFilter.push(itemToAddOrRemove);
      }
    },
    handleCandidateFilter: (state, action: PayloadAction<string>) => {
      const itemToAddOrRemove = action.payload;
      const filter = state[state.filterType];

      if (filter.candidateGuids.includes(itemToAddOrRemove)) {
        // If the item is already in the array, remove it
        filter.candidateGuids = filter.candidateGuids.filter(
          (x) => x !== itemToAddOrRemove
        );
      } else {
        // If the item is not in the array, add it
        filter.candidateGuids.push(itemToAddOrRemove);
      }
    },
    handleClientFilter: (state, action: PayloadAction<string>) => {
      const itemToAddOrRemove = action.payload;
      const filter = state[state.filterType];

      if (filter.clientNames.includes(itemToAddOrRemove)) {
        // If the item is already in the array, remove it
        filter.clientNames = filter.clientNames.filter(
          (x) => x !== itemToAddOrRemove
        );
      } else {
        // If the item is not in the array, add it
        filter.clientNames.push(itemToAddOrRemove);
      }
    },
    handleDurationFilter: (state, action: PayloadAction<any>) => {
      let durationType = action.payload.durationType
      let durationValue = action.payload.durationValue
      const filter = state[state.filterType];
      filter.durationFilter.durationType = durationType
      filter.durationFilter.duration = durationValue

    },
    handleDateRangeFilter: (state, action: PayloadAction<any>) => {
      const filter = state[state.filterType];
      filter.durationFilter.fromDate = action.payload.fromDate
      filter.durationFilter.toDate = action.payload.toDate

    },
    clearAllDateFilter : (state)=>{
      const filter = state[state.filterType];
      filter.durationFilter = filterInitialState.durationFilter

    },
    handleStatusFilter: (state, action: PayloadAction<string>) => {
      const itemToAddOrRemove = action.payload;
      const filter = state[state.filterType];

      if (filter.statusIds.includes(itemToAddOrRemove)) {
        // If the item is already in the array, remove it
        filter.statusIds = filter.statusIds.filter(
          (x) => x !== itemToAddOrRemove
        );
      } else {
        // If the item is not in the array, add it
        filter.statusIds.push(itemToAddOrRemove);
      }
    },
    setPendingSubmission: (
      state    ) => {
      state.filterType = state.filterType === "filter"? 'pendingFilter' : 'filter';
    },
    clearAllFilter: (state) => {
      state[state.filterType] = { ...filterInitialState };
    },
    setFilterType: (state, action: PayloadAction<("filter"|"pendingFilter")>) =>{
      state.filterType = action.payload
    },
    setStatusIds: (state, action: PayloadAction<{filterType:"filter"|"pendingFilter", statusIds:string[]}>) =>{
      state[action.payload.filterType].statusIds = action.payload.statusIds
    }
  },
});

export const submissionApi = createApi({
  reducerPath: "submissionApi",
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    getSubmissions: build.mutation<AppResponse<any>, SubmissionFilter>({
      query: (filter) => ({
        url: `api/CandidateSubmission/GetSubmissions`,
        method: "post",
        data: filter,
      }),
    }),
  }),
});

export const { useGetSubmissionsMutation } = submissionApi;

export const {
  handleSubmissionSearchKeyword,
  updateSubmissionFilter,
  handleRecruiterFilter,
  handleCandidateFilter,
  handleClientFilter,
  clearAllFilter,
  handleStatusFilter,
  handleDurationFilter,
  handleDateRangeFilter,
  clearAllDateFilter,
  setPendingSubmission,
  setFilterType,
  setStatusIds
} = submissionSlice.actions;

export const submissionReducer = submissionSlice.reducer;
