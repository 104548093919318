import * as Yup from "yup";
import { accountHolderNameRegex, addressRegex, routingNumberRegEx, USABankAccountRegex, zipValidation } from "../../../../constants/regex";
const usaSchema = Yup.object({
    userId: Yup.string(),
    email: Yup.string().email(),
    name: Yup.string(),
    routingNumber: Yup.string().required("Please enter routing number").matches(routingNumberRegEx,"Entered routing number is invalid"),
    confirmRoutingNumber : Yup.string().required("Please re-enter the routing number in the confirm routing number field").oneOf(
      [Yup.ref("routingNumber"), null],
      "Routing and confirm routing numbers does not match"
    ),
    accountHolderName: Yup.string().required("Please enter account holder name").matches(accountHolderNameRegex,"Entered AccountHolderName is invalid"),
    bankName: Yup.string().required("Please enter bank name").matches(addressRegex,"Entered BankName is invalid"),
    bankAccountNumber: Yup.string().required("Please enter account number").matches(USABankAccountRegex,"Entered account number is invalid"),
    confirmBankAccountNumber: Yup.string().required("Please re-enter the account number in the confirm account number field").oneOf(
      [Yup.ref("bankAccountNumber"), null],
      "Account number and confirm account number do not match"
    ),
    location: Yup.object().shape({
      address: Yup.string().required("Please enter the address").matches(addressRegex,"Entered Address is invalid"),
      city: Yup.string().required("Please enter the city").matches(addressRegex,"Entered City is invalid"),
      state: Yup.string().required("Please enter the state").matches(addressRegex,"Entered State is invalid"),
      zip: Yup.string().required("Please enter the zip").matches(zipValidation,"Zip is invalid"),
      country: Yup.string().required("Please enter the country").matches(addressRegex,"Entered Country is invalid"),
    }),
    resourceInfo: Yup.object().shape({
      resourceUrl: Yup.string().required(),
      fileName: Yup.string().required("Please attach w-9 form"),
      newFileName: Yup.string().required(),
    }),
  });
  export default usaSchema