import { DocumentTypeEnum } from "../models/interfaces";

export interface Document {
    resourceUrl: string;
    fileName: string;
    newFileName: string;
    documentType: string;
  }
// Utility function to filter documents by type
export const getDocumentsByType = (documents: Document[], type: DocumentTypeEnum): Document[] => {
    return documents?.filter(doc => doc.documentType === type);
  };

  // Utility function to find the index of the first document with the specified type
export const getFirstDocumentIndexByType = (documents: Document[], type: DocumentTypeEnum): number => {
    return documents?.findIndex(doc => doc.documentType === type);
  };