
import getPayRate from "../../utils/getPayRate";

//packageType = hourly,annully, monthly
//packageValue = 10,20,12000
//currency = USD, CAD, AUD
export const Payrate = ({ packageType, packageValue, currency, highLightCurrency,fontSize="0.75rem",color="inherit" }) => {
  let formattedPayRate = getPayRate(packageType, packageValue, currency);
  return (
    <>
      <span style={{ fontSize: fontSize? fontSize :"0.75rem", whiteSpace:"nowrap", color:color}}> <span style={{fontWeight:highLightCurrency? 600 : ''}}>{formattedPayRate.packageValueAndCurrency}</span> <span style={{fontSize: fontSize? fontSize :"0.65rem"}}>{formattedPayRate.packageType}</span></span>
    </>
  );
};
Payrate.defaultProps = {
 
  highLightCurrency: true,

}
