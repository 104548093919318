function constructName(firstName, middleName, lastName) {
  if (!firstName && !middleName && !lastName) {
    return "";
  }

  let nameArray = [firstName, middleName, lastName];
  nameArray = nameArray.filter(Boolean);

  const formattedNames = nameArray?.map(name => name?.charAt(0)?.toUpperCase() + name?.slice(1)?.toLowerCase());
  const fullName = formattedNames.join(' ');

  return fullName.replace(/\b\w/g, match => match.toUpperCase());
}
export default constructName;