import { createSlice } from "@reduxjs/toolkit";
import { JobPostingState } from "../../models/client/Job/AddJob";

const initialState: JobPostingState = {
  clientId: "",
  addedById: "",
  jobDetails: {
    jobTitle: "",
    jobDescription: "",
    jobDomain: "",
    jobIndustry: "",
    primarySkill: "",
    jobDepartment: "",
    jobStartDate: "",
    shiftTimingFrom: "09:00",
    shiftTimingTo: "17:00",
    workingHours: undefined,
    numberOfOpenings: undefined,
    experience: undefined,
    experienceId: "",
    requiredDegree: {
      id:null,
      degreeName:""
    },
    desiredCertifications: [],
    state: "",
    stateCode: "",
    city: "",
    countryName: "",
    countryCode: "",
    zipCode: "",
    requiredSkills: [],
    isRemoteJob: true,
  },
  jobTypeAndRate: {
    jobTypeId:"",
    jobType: "",
    jobTaxTermId:"",
    taxTerms: "",
    jobDuraion: undefined,
    billRate: undefined,
    billRateCurrencyType: "USD",
    salaryRange: "",
    salaryRangeCurrencyType: "USD",
    paymentMode: "",
    jobWorkAuthorization: [],
    isRateNigotiable: true,
  },
  applicationProcess: {
    jobEndDate: "",
    applicationMethod: "Online Form",
    requiredDocuments: [],
    applicationInstructions: "",
  },
  companyInformation: {
    companyId: "",
    companyName: "",
    companyEmail: "",
    industry: "",
  },
  publishJob: false,
};
const addJobStateSlice = createSlice({
  name: "addJobStateSlice",
  initialState,
  reducers: {
    setAddJobState: (state, action: any) => {
      return action.payload;
    },
  },
});

export const { setAddJobState } = addJobStateSlice.actions;

export default addJobStateSlice.reducer;
