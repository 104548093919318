import { Grid, TextField, Typography } from "@material-ui/core";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useState } from "react";
import { LoginTypeName } from "../../../../../models/interfaces";
import { Asterisk } from "../../../../ui/Asterisk";
import { CustomEmailInput } from "../../../../ui/CustomEmailInput";
import { IndustryAutocomplete } from "../../../../ui/IndustryAutocomplete";

export default function CompanyInformationForm(props: any) {
  const { formik, formError } = props;

  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  const _handleTogglePassword = (type: string) => {
    if (type === "password") {
      setShowPassword({ ...showPassword, password: !showPassword.password });
    } else {
      setShowPassword({
        ...showPassword,
        confirmPassword: !showPassword.confirmPassword,
      });
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography className="signUpFormHeading" variant="h6" gutterBottom>
            Company Information
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} >
          <div className="custEmail">
           <span className="phoneNumberLabel">
                Email <Asterisk />
              </span>
          <CustomEmailInput
           name = "email" 
           value={formik?.values?.email} 
           onChange={formik.handleChange} 
           onBlur={formik.handleBlur} 
           readOnly={true} 
           error={formik?.touched?.email && formError?.email}
           helperText={formik?.touched?.email && formError?.email}
           isverified = {true}
           paddingTop={"0px"}           
           />
           </div>
        </Grid>
        { (formik?.values?.loginType === LoginTypeName.Email_UserID)  &&
          <>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                data-testid="client-password"
                id="password"
                name="password"
                label="Password"
                value={formik?.values?.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik?.touched?.password && formError?.password}
                helperText={formik?.touched?.password && formError?.password}
                fullWidth
                type={showPassword?.password ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        data-testid="password-toggler"
                        className="passwordToggler"
                        onClick={() => {
                          _handleTogglePassword("password");
                        }}
                        edge="end"
                      >
                        {showPassword.password ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="confirmPassword"
                name="confirmPassword"
                label="Confirm Password"
                value={formik?.values?.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik?.touched?.confirmPassword && formError?.confirmPassword}
                helperText={formik?.touched?.confirmPassword && formError?.confirmPassword}
                fullWidth
                type={showPassword?.confirmPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        className="passwordToggler"
                        data-testid="confirmPassword-toggler"
                        onClick={() => {
                          _handleTogglePassword("confirmPassword");
                        }}
                        edge="end"
                      >
                        {showPassword?.confirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </>
        }

        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="companyName"
            name="companyName"
            label="Company Name"
            value={formik?.values?.companyName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik?.touched?.companyName && formError?.companyName}
            helperText={formik?.touched?.companyName && formError?.companyName}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="companyWebsite"
            name="companyWebsite"
            label="Company Website"
            value={formik.values?.companyWebsite}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik?.touched?.companyWebsite && formError?.companyWebsite}
            helperText={formik?.touched?.companyWebsite && formError?.companyWebsite}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" fullWidth error={formik?.touched?.companySize && formError?.companySize}>
            <InputLabel id="companySize">Company Size<Asterisk/> </InputLabel>
            <Select
              data-testid="companySize"
              required
              labelId="companySize"
              id="companySize"
              label="Company Size"
              name="companySize"
              value={formik.values?.companySize}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik?.touched?.companySize && formError?.companySize}
              
            >
              
              {
                props?.companySizeList?.map((option:any, index:any)=>{
                  return(
                    <MenuItem value={option} key={option ?? index}>{option}</MenuItem>
                  )
                })
              }
            </Select>
            <FormHelperText>{formik?.touched?.companySize && formError?.companySize}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <IndustryAutocomplete
            label={"Industry"}
            industries={formik?.values?.industries}
            onChange={(e: any, value: any) => {
              formik.setFieldValue("industries", value);
            }}
            readOnly={false}
            search={""}
            isRequired={true}
            isError={formik?.touched?.industries && formError?.industries}
            helperText={formik?.touched?.industries && formError?.industries}
            fullWidth
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
