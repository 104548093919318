import { AppPaginationPerPage } from "../../utils/AppConstant";
import { Paging, SortType } from "../app/AppModels";

export interface AdminFilter extends Paging {
   
    searchKeywords?: string;
    isActive: null | boolean// specify the type as boolean | null,
    status : []
}
export const AdminFilterDefault: AdminFilter = {
  isSorted: true,
  pageIndex: 1,
  pageSize: AppPaginationPerPage,
  sortColumn: "createdDate",
  sortOrder: SortType.Descending,
  totalRecord: 0,
  searchKeywords : "",
  isActive : null,
  status : [],
};

export interface AdminStateFilter{
    searchKeywords?: string;    
    isActive: null | boolean, // specify the type as boolean | null,
    status : string[]
}




