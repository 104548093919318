import { SvgIcon } from '@mui/material'
import React from 'react'

export default function Success(props) {
  return (
    <SvgIcon className={props.className} viewBox='0 0 35 35' {...props}>
<path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 3.0625C7.28781 3.0625 3.0625 7.28781 3.0625 12.5C3.0625 17.7122 7.28781 21.9375 12.5 21.9375C17.7122 21.9375 21.9375 17.7122 21.9375 12.5C21.9375 11.8834 21.8786 11.282 21.7665 10.7007L21.4824 9.22784L24.4281 8.65977L24.7122 10.1326C24.8602 10.9002 24.9375 11.6918 24.9375 12.5C24.9375 19.369 19.369 24.9375 12.5 24.9375C5.63096 24.9375 0.0625 19.369 0.0625 12.5C0.0625 5.63096 5.63096 0.0625 12.5 0.0625C15.1405 0.0625 17.593 0.887042 19.6081 2.29267L20.8384 3.15082L19.1221 5.61138L17.8919 4.75323C16.3638 3.68738 14.5071 3.0625 12.5 3.0625ZM25.5584 4.68775L24.4978 5.74841L13.5603 16.6859L12.4996 17.7466L11.439 16.6859L6.75146 11.9984L5.6908 10.9378L7.81212 8.81643L8.87278 9.87709L12.4996 13.5039L22.3765 3.62709L23.4371 2.56643L25.5584 4.68775Z"
    />    </SvgIcon>
  )
}