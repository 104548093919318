import { Message } from './Message';
import { Recruiter } from './recruiterModel';
export class RecruiterResponse {
	entityList: Recruiter[];
	entity:Recruiter;
	totalRecords: number;
	status: number;
	message: Message;
	emailChangeRequest:  emailChangeRequest;
}

 export class emailChangeRequest{
	email:string;
	isActive:Boolean;
	authToken:string;
	validTill:string;
}