import axios from "axios";

const axiosInstance = axios.create()

axiosInstance.interceptors.response.use((response) => {
    return response;
}, (error) => { 
    checkForUnathorized(error.response?.data?.Status,error.response.data?.Message?.AppStatusCode)
});


export const checkForUnathorized = (responseStatus,appStatusCode) =>{
    if(responseStatus === 401 && appStatusCode ==='RC_TOK_EXP'){
        console.log("Token expired");
    }
}

export default axiosInstance;