import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import "./ViewRecruiterInfoPopup.css";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import useApi from "../../../../hooks/useApi";
import { GetOfferData } from "../../../../Services/recruiterService";
import getPayRate from "../../../../utils/getPayRate";
import moment from "moment";
import constructName from "../../../../utils/constructName";
import "./../styles.scss";
interface Props {
  openPopup: boolean;
  handleofferdetailsClose: any;
  data: any;
  type:string;
}
export const OfferDetailsPopup: React.FC<Props> = ({
  openPopup,
  handleofferdetailsClose,
  data,
  type
}) => {  
  const getOfferData = useApi(() => GetOfferData({ submissionId: data?.id }));
  const offerData = getOfferData.data?.entity;

  React.useEffect(() => {
    getOfferData.request().finally(() => {});
  }, []);

  let formattedPayRate = getPayRate(
    offerData?.payRateType,
    offerData?.payrate,
    offerData?.payRateCurrency
  );

  return (
    <div>
      <Dialog
        className="custom-popup-border"
        open={openPopup}
        onClose={handleofferdetailsClose}
        aria-labelledby="viewRecruiterPopup-title"
        aria-describedby="viewRecruiterPopup-description"
      >
        <Paper elevation={5} style={{ backgroundColor: "#FFF" }}>
          <DialogTitle sx={{ m: 0, p: 2 }}>
            <IconButton
              aria-label="close"
              onClick={handleofferdetailsClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="viewRecruiterPopup-description">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    align="center"
                    className="custom-offerdetails-txt"
                  >
                    Offer Details
                  </Typography>
                </Grid>

                <Grid
                  item
                  pl={"19px"}
                  xs={6}
                  md={6}
                  className="offerDetailsHeading"
                >
                  <Typography className="custom-leftcontent">
                    Candidate ID
                  </Typography>
                </Grid>
                <Grid className="custom-rghtcontent" item xs={6} md={6}>
                  {data?.candidateId}
                </Grid>
                <Grid
                  item
                  pl={"19px"}
                  xs={6}
                  md={6}
                  className="offerDetailsHeading"
                >
                  <Typography className="custom-leftcontent">
                    Name
                  </Typography>
                </Grid>
                <Grid className="custom-rghtcontent" item xs={6} md={6}>
                  {constructName(
                    data?.firstName,
                    data?.middleName,
                    data?.lastName
                  )}
                </Grid>
                <Grid
                  item
                  pl={"19px"}
                  xs={6}
                  md={6}
                  className="offerDetailsHeading"
                >
                  <Typography className="custom-leftcontent">
                    Job Location
                  </Typography>
                </Grid>
                <Grid className="custom-rghtcontent" item xs={6} md={6}>
                  {offerData?.jobLocation}
                </Grid>
                <Grid
                  item
                  pl={"19px"}
                  xs={6}
                  md={6}
                  className="offerDetailsHeading"
                >
                  <Typography className="custom-leftcontent">
                    Client
                  </Typography>
                </Grid>
                <Grid className="custom-rghtcontent" item xs={6} md={6}>
                  {offerData?.clientName || "N/A"}
                </Grid>
                
                <Grid
                  item
                  pl={"19px"}
                  xs={6}
                  md={6}
                  className="offerDetailsHeading"
                >
                  <Typography className="custom-leftcontent">
                    Pay Rate
                  </Typography>
                </Grid>
                <Grid className="custom-rghtcontent" item xs={6} md={6}>
                  {formattedPayRate.packageValueAndCurrency}{" "}
                  {formattedPayRate.packageType}
                </Grid>
                <Grid
                  item
                  pl={"19px"}
                  xs={6}
                  md={6}
                  className="offerDetailsHeading"
                >
                  <Typography  className="custom-leftcontent">
                    Job Start Date
                  </Typography>
                </Grid>
                <Grid className="custom-rghtcontent" item xs={6} md={6}>
                  {moment(offerData?.jobStartDate).format("LL")}
                </Grid>
                <Grid
                  item
                  pl={"19px"}
                  xs={6}
                  md={6}
                  className="offerDetailsHeading"
                >
                  <Typography className="custom-leftcontent">
                    Contract Duration
                  </Typography>
                </Grid>
                <Grid className="custom-rghtcontent" item xs={6} md={6}>
                  {offerData?.contractDuration || "N/A"}
                </Grid>

                <Grid
                  item
                  pl={"19px"}
                  xs={6}
                  md={6}
                  className="offerDetailsHeading"
                >
                  <Typography
                    align="center"
                    className="custom-leftcontent"
                  ></Typography>
                </Grid>
                <Grid
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  item
                  xs={6}
                  md={6}
                >
                  <Button
                    className="custom-submit-btn"
                    variant="contained"
                    color="primary"
                    target={"_blank"}
                    href={`${window.location.origin}/candidates/submissiondetails/offerdetails/${data?.id}/${type}`}
                  >
                    View Offer
                  </Button>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions></DialogActions>
        </Paper>
      </Dialog>
    </div>
  );
};
export default OfferDetailsPopup;
