import {
    Alert,
    AppBar,
    Toolbar,
    Typography,
    Container,
    Grid,
  } from "@mui/material";

import { Link } from "react-router-dom";
import logo from "./../../../assets/images/logo.svg";

import {emailVerificationLinkExpireMsg} from "./expireMessages"

export default function EmailVerificationLinkExpired (){

    return (
        <>
            <AppBar
                className="main-header"
                style={{ background: "#fff", color: "#000" }}
            >
                <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Typography
                    variant="h6"
                    noWrap
                    component="span"
                    sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
                    >
                    <Link to={"/"}>
                        <img src={logo} className="logo" alt="logo" />
                    </Link>
                    </Typography>
                    <Typography
                    variant="h6"
                    noWrap
                    component="span"
                    sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
                    >
                    <Link to={"/"}>
                        <img src={logo} className="logo" alt="logo" />
                    </Link>
                    </Typography>
                </Toolbar>
                </Container>
            </AppBar>
            <Container container spacing={4} sx={{ justifyContent: "left", marginTop:"7rem" }}>
                <Grid item xs={4} md={4}>
                <Alert severity="error">{emailVerificationLinkExpireMsg}</Alert>
                </Grid>
            </Container>
        </>
    )
}