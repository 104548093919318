import styled from "@emotion/styled";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import React, { FunctionComponent } from "react";

const Wrapper = styled.div``;

type CandidateRecordDialogProps = {
  isOpen: boolean;
  onConfirm: any;
  onClose: any;
};

const CandidateRecordDialog: FunctionComponent<CandidateRecordDialogProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  return (
    <>
      <Wrapper>
        <Dialog
          open={isOpen}
          onClose={() => {}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Typography
              component="div"
              className="PopupPrimaryTitle"
            >
                Do you want to overwrite Candidate's data?
              </Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent:"center", marginBottom:"16px" }}>
            <Button className="roboto-font" size="small" variant="outlined" style={{ fontSize: 17 }} onClick={onClose}>
              No
            </Button>
            <Button className="roboto-font" size="small" variant="contained" style={{ fontSize: 17 }} onClick={onConfirm}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Wrapper>
    </>
  );
};

export default CandidateRecordDialog;
