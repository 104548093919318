import * as Yup from "yup";
import { RecruiterAssessmentQuetionType } from "../Model/RecruiterAssessmentType";

export const initializeFormikState = (inputData:any) => {
  const screeningResponse = {
    sections: inputData?.sections?.map(section => ({
      id: section.id,
      sectionTitle: section?.sectionTitle,
      questionResponses: section?.questions?.map(question => ({
        id: question?.id,
        question: question?.question,
        type: question?.type,
        options: [],
        descriptiveAnswer: ''
      }))
    }))
  };

  return screeningResponse;
};

export const createValidationSchema = () => {
  const sectionSchema = Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required('Section ID is required'),
      sectionTitle: Yup.string().required('Section title is required'),
      questionResponses: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().required('Question ID is required'),
          question: Yup.string().required('Question is required'),
          type: Yup.number().required('Question type is required'),
          options: Yup.array().of(
            Yup.object().shape({
              id: Yup.string().required('Option ID is required'),
              optionText: Yup.string().required('Option text is required'),
              instructionText: Yup.string().nullable(),
              specificationRequired: Yup.boolean(),
              specificationText: Yup.string().when('specificationRequired', {
                is: true,
                then: Yup.string().required('Specification text is required when specification is required'),
                otherwise: Yup.string().nullable(),
              }),
            })
            ).when("type", {
              is: RecruiterAssessmentQuetionType.MultiSelect,
              then: Yup.array().min(1, "Please select at least one option"),
              otherwise: Yup.array().when("type", {
                is: RecruiterAssessmentQuetionType.SingleSelect,
                then: Yup.array().min(1, "Please choose an option"),
                otherwise: Yup.array().nullable(),
              }),
            }),
          descriptiveAnswer: Yup.string().when("type", {
            is: (type: number) => type === RecruiterAssessmentQuetionType.TextInput,
            then: Yup.string()
              .required("Please enter at least 20 characters")
              .min(20, "Please enter at least 20 characters")
              .max(250, "You can enter a maximum of 250 characters"),
            otherwise: Yup.string().nullable(),
          }),
        })
      ),
    })
  );

  return Yup.object().shape({
    sections: sectionSchema,
  });
};