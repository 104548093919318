import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HomeIcon from '@mui/icons-material/Home';
import LoginIcon from '@mui/icons-material/Login';
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import PropTypes from "prop-types";
import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import aboutUsIcon from "../../assets/images/aboutUsIcon.svg";
import logo from "./../../assets/images/logo.svg";
import { FAQMenuIcon } from "./Icons/FaqMenuIcon";
import { JobsAdminMenuIcon } from "./Icons/JobsAdminMenuIcon";
import { RecruiterAdminMenuIcon } from "./Icons/RecruiterAdminMeuIcon";
import "./Navigation/header.scss";
import { getIsClientModuleEnabled } from '../../utils/AppConfig';

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

export default function Header(props) {

  const isClientModuleEnabled = getIsClientModuleEnabled();

  const pages = [
    { orderId:1, name: "Home", path: '/' },
    { orderId:2, name: "Jobs", path: "/jobs/all" }, 
    { orderId:3, name: "Recruiter", path: "/recruitmentservices" },    
    { orderId:5, name: "About Us", path: "/aboutus" }, 
    { orderId:6, name: "FAQ", path: "/faqs" }];

    // add paged navigation if client Module enabled
    if(isClientModuleEnabled){
      pages.push({ orderId:4, name: "Clients", path: "/clientservices" })
    }

  // Sort the pages by orderId
  // Define a function to sort pages by orderId
  const sortPagesByOrderId = (pages) => pages.sort((a, b) => a.orderId - b.orderId);

  // Sort the pages
  const sortedPages = sortPagesByOrderId(pages);

    const location = useLocation();

  // Find the index of the matched path in the pages array
  const matchedPageIndex = pages.findIndex(page => page.path === location.pathname);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [selectedIndex, setSelectedIndex] = React.useState(-1);

  React.useEffect(()=>{
    setSelectedIndex(matchedPageIndex)
  },[location])
  
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };



  return (
    <React.Fragment>
      <ElevationScroll {...props}>
        <AppBar
          className="main-header"
          style={{ background: "#fff", color: "#000" }}
        >
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Typography
                variant="h6"
                noWrap
                component="span"
                sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
              >
                <Link to={"/"}>
                  <img src={logo} className="logo" alt="logo" />
                </Link>
              </Typography>

              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={toggleDrawer("left", true)}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>

                <Drawer
                  anchor="left"
                  open={state["left"]}
                  onClose={toggleDrawer("left", false)}
                  className="MobileMenuNavigarDrawer MobileMenuNavigarDrawer-NonSigned "
                >
                  <List sx={{ width: "220px" }}>
                  <ListItemButton
                      button
                      component={Link}
                      to={"/"}
                    >
                      <ListItemIcon>
                        <HomeIcon fontSize="small" sx={{color:"#fff"}}/>
                      </ListItemIcon>
                      <ListItemText primary={"Home"} />
                    </ListItemButton>
                    <ListItemButton
                      button
                      component={Link}
                      to={"/jobs/all"}
                    >
                      <ListItemIcon>
                        <JobsAdminMenuIcon fontSize="small" sx={{color:"#fff"}}/>
                      </ListItemIcon>
                      <ListItemText primary={"Jobs"} />
                    </ListItemButton>
                    <ListItemButton
                      button
                      component={Link}
                      to={"/recruitmentservices"}
                    >
                      <ListItemIcon>
                        <RecruiterAdminMenuIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={"Recruiter"} />
                    </ListItemButton>
                    <ListItemButton
                      button
                      component={Link}
                      to={"/aboutus"}
                    >
                      <ListItemIcon>
                      <img style={{ filter: 'brightness(0) invert(1)'}} className="ht-23 featurejobIcon"  src={aboutUsIcon}  />
                      </ListItemIcon>
                      <ListItemText primary={"About us"} />
                    </ListItemButton>
                    <ListItemButton
                      button
                      component={Link}
                      to={"/faqs"}
                    >
                      <ListItemIcon>
                        <FAQMenuIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={"FAQ"} />
                    </ListItemButton>
                    <Divider />
                    <ListItemButton
                      button
                      component={Link}
                      to={"/login"}
                    >
                      <ListItemIcon>
                        <LoginIcon fontSize="small" sx={{color:"#fff"}}/>
                      </ListItemIcon>
                      <ListItemText primary={"Sign In"} />
                    </ListItemButton>
                    <ListItemButton
                      button
                      component={Link}
                      to={"/Signup"}
                    >
                      <ListItemIcon>
                      <ExitToAppIcon fontSize="small" sx={{color:"#fff"}}/>
                      </ListItemIcon>
                      <ListItemText primary={"Sign Up"} />
                    </ListItemButton>
                  
                    <Divider />
                  </List>
                </Drawer>
              </Box>

              {/* logo for small screens  */}
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{
                  flexGrow: 1,
                  display: { xs: "flex", md: "none", paddingTop: "0px" },
                }}
              >
                <Link to={"#"}>
                  <img src={logo} className="logo" alt="logo" />
                </Link>
              </Typography>
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", md: "flex" },
                  justifyContent: "right",
                  pr: 4
                }}
                className="main-menu"
              >

                {sortedPages.map((page, index) => (
                  <Button
                    className="custom-headernav-txt"
                    onClick={(event) => handleListItemClick(event, index)}
                    component={Link}
                    to={page.path.toString().toLowerCase()}
                    key={page?.orderId}
                    sx={{ my: 2, color: selectedIndex == index ? 'blue' : "#000" }}

                  >
                    {page?.name}
                  </Button>
                ))}
              </Box>
              <Box
                sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
                <Button className="custom-signinbutton-txt" sx={{ color: window.location.pathname == '/login' ? 'blue' : "#000" }}
                  href={`/login`}>Sign in</Button>
                <Button className="custom-signinbutton-txt" href={'/signup'} variant="contained">
                  Sign up
                </Button>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
    </React.Fragment>
  );
}
