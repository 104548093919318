import { InputLabel, makeStyles, Radio } from "@material-ui/core";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import React from "react";
import { Asterisk } from "../../ui/Asterisk";
import { ErrorMessage } from "../../ui/ErrorMessage";

interface Props {
  touched: any;
  errors: any;
  values: any;
  setFieldValue: any;
  jobDetails: any;
}

const useStyles = makeStyles({
  label: {
    "&.Mui-disabled": {
      color: "rgb(102 102 102) !important",
    },
  },
});

export const CandidateEmployment: React.FC<Props> = ({
  touched,
  errors,
  values,
  setFieldValue,
  jobDetails,
}) => {
  const classes = useStyles();
  let jobType = jobDetails?.jobAssignmentType;
  let taxTerms = jobDetails?.taxTerms;

 
  let fullTimeOption = (
    <Grid item xs={6} md={6}>
      <FormControlLabel
        classes={{
          label: classes.label,
        }}
        control={
          <Radio
            checked={values.candidateEmployment === "FullTime"}
            disabled={true}
            name="candidateEmployment"
            onChange={(e) => {
              setFieldValue("candidateEmployment", "FullTime");
            }}
            value={"Full Time"}
            size="small"
            className="radio-sml"
            style={{ paddingTop: 15 }}
          />
        }
        value={"FullTime"}
        label="Full Time"
      />
    </Grid>
  );

  let directc2cOption = (
    <Grid item xs={6} md={6}>
      <FormControlLabel
        control={
          <Radio
            checked={values.candidateEmployment === "DirectContract"}
            name="candidateEmployment"
            onChange={(e) => {
              setFieldValue("candidateEmployment", "DirectContract");
            }}
            value={"DirectContract"}
            size="small"
            className="radio-sml"
            style={{ paddingTop: 15 }}
          />
        }
        value={"DirectContract"}
        label="Direct Contract (C2C)"
      />
    </Grid>
  );
  let employeew2Option = (
    <Grid item xs={6} md={6}>
      <FormControlLabel
        control={
          <Radio
            checked={values.candidateEmployment === "W2_Employee"}
            name="candidateEmployment"
            onChange={(e) => {
              setFieldValue("candidateEmployment", "W2_Employee");
            }}
            value={"W2_Employee"}
            size="small"
            className="radio-sml"
            style={{ paddingTop: 15 }}
          />
        }
        value={"W2_Employee"}
        label="W2"
      />
    </Grid>
  );

  const renderOptions = () => {
    if (jobType == "Full Time") {
      return <>{fullTimeOption}</>;
    }
    if (jobType == "Contract" && taxTerms == "W2") {
      return <>{employeew2Option}</>;
    }
    return (
      <>
        {employeew2Option}
        {directc2cOption}
      </>
    );
  };
  const renderLabel = () => {
    if (jobType == "Full Time") {
      return <></>;
    }
    if (jobType == "Contract" && taxTerms == "W2") {
      return (
        <InputLabel htmlFor="" shrink={true} style={{ fontSize: "1.1rem" }}>
          "W2" if employment will be transfered to a JobRialto
          partner
        </InputLabel>
      );
    }
    return (
      <InputLabel htmlFor="" shrink={true} style={{ fontSize: "1.1rem" }}>
        Please select "Direct Contract (C2C)" if Employed through a
        third-party vendor or "W2" if employment will be transfered
        to a JobRialto partner
      </InputLabel>
    );
  };
  
  
  return (
    <>
      <Box className="form-three-child" mt={{ xs: 1, sm: 2, md: 3 }}>
        <Typography variant="subtitle1" component="div" className="roboto-font">
          Candidate Employment <Asterisk />
        </Typography>
        <ErrorMessage
          errorText={touched.candidateEmployment && errors.candidateEmployment}
        />
        <RadioGroup
          name="candidateEmployment"
        >
          <Grid container spacing={0} className="roboto-font candidate-employment-list">
            {renderOptions()}
          </Grid>
        </RadioGroup>
      </Box>
      {renderLabel()}
    </>
  );
};
