import { Box, Grid, Paper } from "@mui/material";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { SubscribeForm } from "./SubscribeForm";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));


export default function Jobs() {
  return (
    <Container
      maxWidth="xl"
      className="home-subscribe"
      sx={{
        marginTop: "150px",
        paddingBottom: "50px",
      }}
    >
      <Box className="home-subscribe-box">
        <Grid container spacing={3} sx={{ justifyContent: "space-around" }}>
          <Grid item xs={12} md={6}>
            <Item elevation={0}>
              <Typography
                variant="h3"
                color="inherit"
                component="div"
                className="subscribe-text"
                textAlign={"left"}
                pl={4}
              >
                Don’t want to miss <br />
                exciting{" "}
                <span className="primary-color-text">Job Opportunities?</span>
              </Typography>
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item elevation={0}>
               <SubscribeForm />
            </Item>
           
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
