import { createSvgIcon } from "@mui/material/utils";

export const ResumeSubmitted = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="28"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M8.89981 2.91797C6.81772 2.91797 5.12183 4.67789 5.12183 6.83859C5.12183 7.27421 5.46604 7.62271 5.87742 7.62271C6.2888 7.62271 6.63302 7.2655 6.63302 6.83859C6.63302 5.54043 7.64888 4.49493 8.89142 4.49493C9.31119 4.49493 9.64701 4.13772 9.64701 3.71081C9.64701 3.28389 9.3028 2.92668 8.89142 2.92668L8.89981 2.91797Z"
      fill="#4540DB"
    />
    <path
      d="M17.2276 14.454H15.4562V6.6999C15.4562 3.00581 12.5597 0 9 0C5.4403 0 2.53545 3.00581 2.53545 6.6999V14.454H0.763993C0.344216 14.454 0 14.8112 0 15.2469C0 15.6825 0.344216 16.031 0.763993 16.031H6.46455C6.79198 17.1636 7.79944 18 9 18C10.2006 18 11.208 17.1636 11.5354 16.031H17.236C17.6558 16.031 18 15.6738 18 15.2469C18 14.8199 17.6558 14.454 17.236 14.454H17.2276ZM13.9366 14.454H4.05504V6.6999C4.05504 3.87706 6.27146 1.57696 8.9916 1.57696C11.7118 1.57696 13.9282 3.87706 13.9282 6.6999V14.454H13.9366Z"
      fill="#4540DB"
    />
  </svg>,
  "ResumeSubmitted"
);
