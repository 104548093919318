import { createSvgIcon } from "@mui/material/utils";

export const Clock = createSvgIcon(
  <svg viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5 3.6875C9.87157 3.6875 3.6875 9.87157 3.6875 17.5C3.6875 25.1284 9.87157 31.3125 17.5 31.3125C25.1284 31.3125 31.3125 25.1284 31.3125 17.5C31.3125 9.87157 25.1284 3.6875 17.5 3.6875ZM0.6875 17.5C0.6875 8.21471 8.21471 0.6875 17.5 0.6875C26.7853 0.6875 34.3125 8.21471 34.3125 17.5C34.3125 26.7853 26.7853 34.3125 17.5 34.3125C8.21471 34.3125 0.6875 26.7853 0.6875 17.5ZM19 5.0625V6.5625V14.1304C20.289 14.705 21.1875 15.9976 21.1875 17.5C21.1875 17.9655 21.1008 18.4113 20.9431 18.8218L23.6648 21.5435L24.7255 22.6042L22.6042 24.7255L21.5435 23.6648L18.8218 20.9431C18.4113 21.1008 17.9655 21.1875 17.5 21.1875C15.4635 21.1875 13.8125 19.5366 13.8125 17.5C13.8125 15.9976 14.711 14.705 16 14.1304V6.5625V5.0625H19ZM17.5 16.8125C17.1203 16.8125 16.8125 17.1203 16.8125 17.5C16.8125 17.8797 17.1203 18.1875 17.5 18.1875C17.6903 18.1875 17.8601 18.1122 17.9861 17.9861C18.1122 17.8601 18.1875 17.6903 18.1875 17.5C18.1875 17.1203 17.8797 16.8125 17.5 16.8125Z"
      fill="#4540DB"
    />
  </svg>,
  "Clock"
);
