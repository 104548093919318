import { Tooltip, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Divider from "@mui/material/Divider";
import { useDispatch, useSelector } from "react-redux";
import { setIsleftMenuExpanded } from "../../features/Layout/NavigationSlice";
import useMediaQuery from "@mui/material/useMediaQuery";
import getLoggedInUserRole from "../../Shared/utils/getLoggedInUserRole";
import { UserAuth } from "../../models/interfaces";
import { LeftNavigationRoot } from "./LeftNavigationRoot";

const drawerWidth = 179;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  background: "rgb(69, 64, 219)",
  color: "#fff",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  background: "rgb(69, 64, 219)",
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const LeftNavigationLayout = () => {
  const NavigationSlice = useSelector((state) => state.navigationState);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:900px)");
  const userType = getLoggedInUserRole();
  const [open, setOpen] = React.useState(NavigationSlice.isLeftMenuExpanded);

  const handleDrawerClose = () => {
    dispatch(setIsleftMenuExpanded());
    setOpen(!NavigationSlice.isLeftMenuExpanded);
  };
  

  return (
    
    <div
      className="leftNavigationMenu"
      style={{
        display:
          isMobile || userType === UserAuth.NotLoggedIn ? "none" : "inherit",
      }}
    >
      <Drawer
        style={{ backgroundColor: "rgb(69 64 219)" }}
        variant="permanent"
        open={open}
        data-testid="drawer"
      >
        <DrawerHeader style={{ backgroundColor: "rgb(69 64 219)" }}>
          <Tooltip title={"Menu"} placement="right-end">
            <IconButton
              className="desktopMenuIconbtn"
              onClick={handleDrawerClose}
            >
              <MenuIcon style={{ color: "#fff" }} />
            </IconButton>
          </Tooltip>
          {NavigationSlice.isLeftMenuExpanded && <Typography className="header-font"> Menu</Typography>}
        </DrawerHeader>
        <Divider />

         <LeftNavigationRoot/>
      </Drawer>
    </div>
  );
};
