
import { Box, Card, CardContent, Container, Grid } from "@mui/material";
import jwt_decode from "jwt-decode";
import HelloSign from 'hellosign-embedded';
import React, { useEffect, useState } from "react";
import { GetRecruiterById, InitiateRecruiterContract, SetContractDetails } from "../../../../Services/recruiterService";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setNotLoggedUserAuthToken } from "../../../../utils/setAuthToken";
import { UserAuthByRoleName } from "../../../../models/interfaces";
import SectionHeading from "../../../ui/Form/Heading/SectionHeading";
import SnackbarPopup from "../../../Common/Popup/snackbar/SnackbarPopup";
import BlankHeader from "../../../Common/Navigation/BlankHeader";
import AppLinkExpire from "../../../Common/ExpireLinks/AppLinkExpire";
import { recruiterContractSignInLinkExpireMsg } from "../../../Common/ExpireLinks/expireMessages";

export enum ContractStatusCode {
    Signed="Signed",
    Pending="Pending",
    SkipNotification="SkipNotification"
}
export interface RecruiterInitiateContractResponse {
    signatureRequestId: string;
    signatureId: string;
    signUrl: string;
    expiresAt: number;
  }
const SignRecruiterContract: React.FC = () => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const name = searchParams.get("name");
    const email = searchParams.get("email");
    const authToken = searchParams.get("authToken") as string;
    let decoded = jwt_decode(authToken);
    const userId = decoded?.Id as string
    setNotLoggedUserAuthToken(authToken);

    const [isLinkExpired, setIsLinkExpired] = useState<boolean | null >(null);
    const [pageStatus, setPageStatus] = useState({
        isSuccess:false,
        isError:false,
        message:""
      })

    const [contractResponse, setContractResponse] = useState<RecruiterInitiateContractResponse>();

    const _setContractDetails = (payload:any, isFinal:boolean = false) => {
        
        SetContractDetails(payload)
          .then((response) => {
            if (response && response.status === 200) {
             
                if(isFinal){
                    setPageStatus({
                        ...pageStatus,
                        isSuccess:true,
                        message:"Contract details updated successfully"
                      });                      
                }
            } else {
              setPageStatus({
                ...pageStatus,
                isError:true
              })
              console.error("Failed to update contract details");
            }
          })
          .catch((error) => {
            console.error("Error during API call:", error);
          });
      };

      const _GetRecruiterById = (recruiterGuid: string) => {
        if (recruiterGuid) {
          GetRecruiterById(recruiterGuid)
            .then((response) => {
              if (response.data?.message?.appStatusCode === "RC_RSF") {

                if(response?.data?.entity?.contractDetails?.contractStatusCode === ContractStatusCode.Signed){
                        setIsLinkExpired(true);
                }
                else{
                    _InitiateContract();
                }
               
              }
            })
            .catch((er) => {
              console.log(er);
            });
        }
      };

      const _InitiateContract = () =>{
        InitiateRecruiterContract({
            name: name,
            email: email,
            role:UserAuthByRoleName.Recruiter
        }).then((response) => {
            if (response?.data?.message?.appStatusCode === "RC_RSF") {
                let currentUTCTime = Math.floor(new Date().getTime() / 1000);
                if (response?.data?.entity?.expiresAt < currentUTCTime) {
                    setIsLinkExpired(true)
                }else{
                    let body = {
                        id: userId,
                        signatureRequestId:response?.data?.entity?.signatureRequestId,
                        signatureId:response?.data?.entity?.signatureId,
                        signUrl: response?.data?.entity?.signUrl,
                        contractStatusCode:ContractStatusCode.SkipNotification,
                        redirectUrl:response?.data?.entity?.signUrl,
                      };
                        _setContractDetails(body);
                        setIsLinkExpired(false)
                        setContractResponse(response?.data?.entity);
                }
                
            }
        }).catch((error) => {
            console.log(error);
        });
      }

      const launchHelloSign = (contractResponse:any) =>{
        if (contractResponse?.signUrl !== undefined) {
            const client = new HelloSign({
                clientId: process.env.REACT_APP_HELLOSIGN_CLIENT_ID
            });
            
            client.open(contractResponse?.signUrl, {
                clientId: process.env.REACT_APP_HELLOSIGN_CLIENT_ID,
                skipDomainVerification: true,
                container: document.getElementById('contract-div')
            });
            
            client.on('sign', (data: any) => {
                
                let body = {
                    id: userId,
                    signatureRequestId:contractResponse.signatureRequestId,
                    signatureId:contractResponse.signatureId,
                    signUrl:"",
                    contractStatusCode:ContractStatusCode.Signed,
                    redirectUrl: window.location.origin +  "/login/",
                    SkipNotification:false,
                  };
                 // final step                 
                 _setContractDetails(body,true);
                 

            });

            // called only on canceling without completion
            client.on('cancel', (data: any) => {
                navigate('/');
            });

            // called on closing iframe, both after completing sign and by canceling early
            client.on('close', (data: any) => {
                navigate('/');              
            });
        }
      }

    useEffect(() => {
        if (name !== "" && email !== "") {
            _GetRecruiterById(userId)
        }
    }, []);

    useEffect(() => {
        if (contractResponse?.signUrl !== undefined) {
            launchHelloSign(contractResponse)
        }
       
    }, [contractResponse?.signUrl]);
       

    return (
        <>
        <BlankHeader />
        <Container maxWidth="lg" >
            <Grid container > 
                { isLinkExpired 
                ?
                <Grid item mt={10}>
                    <AppLinkExpire                 
                        message={recruiterContractSignInLinkExpireMsg}
                    />
                </Grid>
                :
                <Grid item xs={12} mt={10}>
                    <Card >
                        <Box mt={2} mb={1} sx={{textAlign:"center"}}>
                            <SectionHeading title="Initiate Contract"></SectionHeading>
                        </Box>
                        
                        <CardContent>
                        <div id='contract-div' data-testid="contract-div"></div>
                        </CardContent>
                            <SnackbarPopup 
                            open={pageStatus.isSuccess}
                            message={pageStatus.message}
                            onClose={()=>{
                                setPageStatus({
                                    ...pageStatus,
                                    isSuccess:false,
                                    isError:false,
                                    message:""
                                });
                            }}
                            />
                    </Card>
                </Grid>
                }
            </Grid>
        </Container>
        </>
    );
};
export default SignRecruiterContract;
