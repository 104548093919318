import { Paging, SortType } from "../app/AppModels";

export interface SubmissionFilter extends Paging {
  recruiterGuid?: string;
  jobId: string;
  searchKeywords?: string;
  location: [];
  statuses: [];
  statusIds: string[];
  contractType: [];
  showPendingSubmissions: boolean;

  durationFilter: {
    duration: "0";
    durationType: string;
    fromDate: "";
    toDate: "";
  };
  candidateGuids: [];
  recruiterFilter: [];
  selectedCandidateFilter: [];
  clientNames: [];
  selectedClientDetails: [];
  clientGuid : ''
}
export const submissionFilterStateDefault: SubmissionFilter = {
  isSorted: true,
  pageIndex: 1,
  pageSize: 20,
  sortColumn: "createdDate",
  sortOrder: SortType.Descending,
  totalRecord: 0,
  recruiterGuid: "",
  jobId: "",
  location: [],
  statuses: [],
  statusIds: [],
  contractType: [],
  showPendingSubmissions: false,

  durationFilter: {
    duration: "0",
    durationType: "All",
    fromDate: "",
    toDate: "",
  },
  candidateGuids: [],
  recruiterFilter: [],
  selectedCandidateFilter: [],
  clientNames: [],
  selectedClientDetails: [],
  clientGuid : ''
};

export interface SubmissionFilterState {
  recruiterGuid?: string;
  jobId: string;
  searchKeywords?: string;
  location: [];
  statuses: [];
  statusIds: string[];
  contractType: [];
  showPendingSubmissions: boolean;

  durationFilter: {
    duration: "0";
    durationType: string;
    fromDate: "";
    toDate: "";
  };
  candidateGuids: [];
  recruiterFilter: [];
  selectedCandidateFilter: [];
  clientNames: [];
  selectedClientDetails: [];
}

export interface SubmissionFilterStateType {
  filter: SubmissionFilterState;
  filterType: "filter" | "pendingFilter";
  pendingFilter: SubmissionFilterState;
}
