import "./../../Scorecard.scss";
import React from "react";
import { Grid } from "@mui/material";
interface ScorecardCandidateInfoProps {
  data:any;
}

const ScorecardResumeContent: React.FC<ScorecardCandidateInfoProps> = ({
  data
}) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div
              className="resumeContent-wrapper"
              dangerouslySetInnerHTML={{
                __html:data,
              }}
            />
        </Grid>
      </Grid>
    </>
  );
};

export default ScorecardResumeContent;
