import Grid from "@material-ui/core/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";

import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import FacebookLogo from "./../../assets/images/Facebook-icon.svg";
import iMessagesIcon from "./../../assets/images/iMessagesIcon.svg";
import whatsappIcon from "./../../assets/images/whatsapp_icon.svg";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));


const StaticFooter: React.FC = () => {
  const [isIos, setIsIos] = React.useState(false);
  const  checkIosPlatform=()=> {
   
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  useEffect(()=>{
    setIsIos(checkIosPlatform())
  },[isIos])

  return (
    <div>
      <Box
        className="static-footer-wrapper"
      >
         <Container maxWidth="xl">
        <Grid container>
          <Grid
            direction="column"
              xs={12} sm={12} md={4}
              className="left-side-Footer"
          >
            <Grid container spacing={0}>
              <Grid direction="column" md={6} sm={6} className="quick-link-text">
                <Typography
                  variant="h5"
                  component="span"
                  className="abel  letter-spacing-normal primary-color-text foot-header"
                  gutterBottom
                >
                  Quick Links
                </Typography>
              </Grid>
              <Grid direction="column" md={6} sm={6} >
                
              </Grid>
            </Grid>
             <Box sx={{ width: '100%' }}>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: -2, sm: 1, md: 1, lg:1 }}
                className="qlink-stack1"
                flexWrap={"wrap"}
                >
                  <Item>
                    <Typography className="q-link">
                      <Link className="footer-left-link footer-upper-link" to={'/AboutUs'}>About Us </Link>
                    </Typography>
                  </Item>
                  <Item>
                    <Typography className="q-link">
                      <Link className="footer-left-link footer-upper-link" to={'/jobs/all'}>Browse Jobs</Link>
                    </Typography>
                  </Item>
                  <Item>
                      <Typography className="q-link">
                        <Link className="footer-left-link footer-upper-link" to={'/ContactUs'}>Contact Us </Link>
                      </Typography>
                  </Item>
                  <Item>
                    <Typography className="q-link">
                      <Link className="footer-left-link footer-lower-link" to={'/TermsAndConditions'}>Terms {"&"} Conditions</Link>
                    </Typography>
                  </Item>
                  <Item>
                    <Typography className="q-link">
                      <Link className="footer-left-link footer-lower-link" to={'/PrivacyPolicy'}>Privacy Policy </Link>
                    </Typography>
                  </Item>
              </Stack>
            </Box>
          </Grid>

          <Grid direction="column" xs={12} sm={12} md={8}>
            <Grid container>
              <Grid direction="column" md={12} sm={12} className="reach-us-text">
                  <Typography
                    variant="h5"
                    component="span"
                    className="abel  letter-spacing-normal primary-color-text foot-header"
                    gutterBottom
                  >
                    Reach us
                </Typography>
              </Grid>
              <Grid container direction="row">
              <Grid item>
                    <Item className="Item-Gd">
                        
                        <div className="Footer-box">
                            <img src={FacebookLogo} className="ht-26" ></img>
                          <span className="ml-15 footer-text " >
                          <a href="https://www.facebook.com/JobRialto-100382759477813" className="footer-link" target="_blank" rel = "noopener noreferrer" >fb.com/jobrialtoinc</a>
                          </span>
                        </div>

                      </Item>
                    </Grid>
                    <Grid item>
                      <Item className="Item-Gd">

                        <div className="Footer-box">
                            <img src={whatsappIcon} className="ht-26" ></img>
                          <span className="ml-15 footer-text " >+1 (609) 917-7807</span>
                        </div>

                      </Item>
                    </Grid>
                    <Grid item>
                      <Item className="Item-Gd">
                      </Item>
                    </Grid>
                    <Grid item>
                      <Item className="Item-Gd">
                      
                         <div className="Footer-box">
                           
                           {isIos &&
                           <>
                           <img src={iMessagesIcon} className="ht-26" ></img>
                         <span className="ml-15 footer-text " >
                           <a href="https://bcrw.apple.com/urn:biz:2853624a-0b6f-4c4b-8ad0-f3c74e2ab1ba" 
                           className="footer-link" target="_blank" rel = "noopener noreferrer" >iMessage</a>
                         </span>
                         </>
                            }
                       </div>
                      </Item>
                    </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container md={12} style={{justifyContent:"center", textAlign:"center"}}>
<Grid item md={12} ><Typography 
                        justifyContent="center"
                        className="cpyright-text"
                        
                        >
                          Copyright © {(new Date().getFullYear())}. All Rights Reserved.
                        </Typography></Grid>
        </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default StaticFooter;
