import { GetRecruiterJobAlertRequest, RecruiterJobAlert, RecruiterJobAlertUpdate } from '../models/RecruiterJobAlert';
import axios from './CommonHttpRequest';
let baseUrl = `${process.env.REACT_APP_RECUITER_API}`;
export const AddRecuiterJobAlert = async (data:RecruiterJobAlert) => {
    return await axios.post(`${baseUrl}api/RecruiterJobAlert/AddRecuiterJobAlert`,data);
      
}
export const GetRecuiterJobAlerts = async (request: GetRecruiterJobAlertRequest) => {
    return await axios.post(`${baseUrl}api/RecruiterJobAlert/GetRecuiterJobAlerts`,request);
  };
export const UpdateRecruiterJobAlert = async (data:RecruiterJobAlertUpdate) => {
return await axios.post(`${baseUrl}api/RecruiterJobAlert/UpdateRecruiterJobAlert`,data);
};
export const GetJobAlertById = async (id:string) => {
    return await axios.get(`${baseUrl}api/RecruiterJobAlert/GetJobAlertById/${id}`);
}
export const DeleteRecuiterJobAlerts = async (id:string) => {
    return await axios.delete(`${baseUrl}api/RecruiterJobAlert/DeleteRecuiterJobAlerts?id=${id}`);
}