import { createSlice } from "@reduxjs/toolkit";


export const NavigationSlice = createSlice({
  name: "NavigationSlice",
  initialState: {
    isLeftMenuExpanded: true,
    expandedCollapseMenuLeftIndex:0,
   metaData:[],
   selectedItem : null,
  },
  reducers: {
    setIsleftMenuExpanded: (state) => {
      state.isLeftMenuExpanded = !state.isLeftMenuExpanded;
     
    },   
    setReduxMetaData:(state,action)=>{
      state.metaData=action.payload
    },
    
    setSelectedIndex:(state,action)=>{
      state.selectedItem=action.payload
    },
    setExpandedCollapseLeftMenuIndex:(state,action)=>{
      state.expandedCollapseMenuLeftIndex = action.payload
    }
  },
  
});

// Action creators are generated for each case reducer function
export const { setIsleftMenuExpanded, setReduxMetaData,setSelectedIndex,setExpandedCollapseLeftMenuIndex } =
NavigationSlice.actions;

export default NavigationSlice.reducer;