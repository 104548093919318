import pathToRegexp from "path-to-regexp";
import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { AuthenticateToken } from "../../Services/resetPasswordService";
import protectedRoutes from "../../components/routes/protectedRoutes";
import PermissionRoute from "./PermissionRoute";

const ProtectedRoute = ({ children }) => {
  const [initialLoad, setInitialLoad] = useState(true);
  const location=useLocation();
  const navigate = useNavigate();
  const auth = localStorage.getItem("accessToken") ? true : false;

  const [searchParams] = useSearchParams();
  
  useEffect(() => {
    setInitialLoad(false);
  }, []);

  if (initialLoad) {
    // On initial load, allow the route to render without any restrictions
    return children;
  }

      let authToken = searchParams.get("authToken") === "" ? "" : searchParams.get("authToken");

    if(searchParams.get("authToken"))
    {
      if(auth){
        return children;
      } else {
        localStorage.setItem("authToken",authToken)
        AuthenticateToken(authToken)
        .then((res) => {
          if (res?.status === 200 && (res?.data?.entity?.isExpired === true)) {
            navigate("/emailvericationlinkexpired");
          } else {
              navigate("/login",{state:{redirectUrl:location.pathname + location.search}});            
          }
        });
      }
    } else {
      if (!auth) {
        return <Navigate to="/login" replace state={{redirectUrl:location.pathname + location.search}}/>;
      }else{
       // Check if the current route requires a permissionId
    const route = protectedRoutes.find((route) => {
      const keys = [];
      const regexp = pathToRegexp(route.path, keys);
      const match = regexp.exec(location.pathname);
      const isExact = match && match[0] === location.pathname;
      return isExact && route.permissionId;
    });

    if (route && route.permissionId) {
      // Check if the user has the required permission for the current route
      return (
        <PermissionRoute permissionId={route?.permissionId}>
          {children}
        </PermissionRoute>
      );
    }

    return children;
      }
    }
      return children;
  };

export default ProtectedRoute;