import { MainDashboard } from "../Dashboard/MainDashboard";
import SubmitResume from "../Jobs/Submission/SubmitResume";
import { PermissionsEnums } from "../../data/permission";
import { Error } from "../Common/Error";
import ClientDashboard from "../Dashboard/ClientDashboard";
import ClientJobs from "../Jobs/JobListing/AllJobPage";
import Faq from "../Faq/Faq";

import AddJobPage from "../pages/Job/AddJobPage";
import EditJobPage from "../pages/Job/EditJobPage";
import PendingJobPage from "../pages/Job/PendingJobPage";
import JobDetails from "../Jobs/JobDetails/JobDetails";
import { NotFound } from "../pages/NotFound";
import AddClientTeamMemberPage from "../pages/TeamMember/AddClientTeamMemberPage";
import EditClientTeamMemberPage from "../pages/TeamMember/EditClientTeamMemberPage";
import TeamMembers from "../pages/TeamMember/TeamMembers";
import { RoleManagement } from "../Admin/RoleManagement/RoleManagement";
import Submissions from "../ViewSubmissions/Submissions/Submissions";

//for all clientRoutes in  project where user logged in must be client to view the page

const clientRoutes = [
  {
    path: "/client/dashboard",
    element: <ClientDashboard />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  {
    path: "/client/submission/active",
    element: <Submissions />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  {
    path: "/client/submission/archived",
    element: <Submissions />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  {
    path: "/dashboard",
    element: <MainDashboard />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },

  {
    path: "/client/TeamMembers",
    element: <TeamMembers />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  {
    path: "/client/jobdetail/:id/:fromPage",
    element: <JobDetails />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: true,
  },
  {
    //fromPage required to show breadcrumbs

    path: "/client/submitresume/:id/:fromPage",
    element: <SubmitResume />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  {
    path: "/client/submitresume/:id",
    element: <SubmitResume />,
    exact: true,
    showHeader: true,
    showLeftNav: false,
    showFooter: false,
  },
  {
    path: "/jobs",
    element: <Error />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  {
    path: "/client/jobs/:page",
    element: <ClientJobs />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
    permissionId: PermissionsEnums.Jobs_View,
  },
  {
    path: "/client/addjob",
    element: <AddJobPage />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
    permissionId: PermissionsEnums.Jobs_Add,
  },
  {
    path: "/client/editjob/:id",
    element: <EditJobPage />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  {
    path: "/client/pendingjob/:id",
    element: <PendingJobPage />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  {
    path: "/client/savedjobs",
    element: <NotFound />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  {
    path: "/client/faq",
    element: <Faq />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  {
    path: "/client/addteammember",
    element: <AddClientTeamMemberPage />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  {
    path: "/client/role/manage",
    element: <RoleManagement />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
    permissionId: PermissionsEnums.Role_Management_Page,

  },
  {
    path: "/client/editteammember/:id",
    element: <EditClientTeamMemberPage />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  // and so on for other clientRoutes in  project
];

export default clientRoutes;
