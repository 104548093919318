import ClearIcon from "@mui/icons-material/Clear";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import formatAmount from "../../../../utils/formatAmount";
import { Searchbar } from "../../../ui/Searchbar";
import CandidateListFilters from "./CandidateListFilters";

interface CandidateListTableToobarProps {
  totalRecordCount: number;
  CandidateListState: any;
  handleSearchbar: (searchText: string) => void;
  handleClearGlobalAll: () => void;
  handleOnLocationChange: any;
  handleOnExperineceChange: any;
  handleOnCandidateChange: any;
  handleOnRecruiterChange: any;
  selectedCandidateLocation: [];
  selectedCandidateExperinces: [];
  selectedCandiadtes: [];
  selectedRecruiters: [];
}

const CandidateListTableToobar: React.FC<CandidateListTableToobarProps> = ({
  totalRecordCount,
  CandidateListState,
  handleSearchbar,
  handleClearGlobalAll,
  handleOnLocationChange,
  handleOnExperineceChange,
  handleOnCandidateChange,
  handleOnRecruiterChange,

  selectedCandidateLocation,
  selectedCandidateExperinces,
  selectedCandiadtes,
  selectedRecruiters,
}) => {

  const filterRef = React.useRef<any>(null);
  const statusFilterRef = React.useRef<any>(null);

  // Component states
  const [anchorFilter, setAnchorFilter] = useState<any>(false);
  const [searchKeyword, setSearchKeyword] = useState<string>("");

  // Check is any filter is apply
  const _hasAnyFilterApply = () => {
    return (
      CandidateListState?.searchKeywords !== "" ||
      (CandidateListState.experience && CandidateListState.experience?.length > 0 ) ||
      (CandidateListState?.location && CandidateListState?.location?.length > 0 ) ||
      (CandidateListState.candidateGuids && CandidateListState.candidateGuids?.length >  0 ) ||
      (CandidateListState?.recruiterGuids && CandidateListState?.recruiterGuids?.length > 0 ) ||
      (searchKeyword && searchKeyword !== "")
    );
  };

  // clear All
  const _handleClearAll = () => {
    setSearchKeyword("");
    filterRef.current?.handleClearAll();
    statusFilterRef.current?.handleClearAll();
    handleClearGlobalAll();
  };

  const _handleFilterClick = (event: any) => {
    setAnchorFilter(anchorFilter ? null : event.currentTarget);
  };


  return (
    <>
      <Grid container spacing={0}>
        {/* Row 1 */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            justifyContent={{ sm: "flex-start", md: "space-between" }}
          >
            <Grid item>
              <Typography className="listing-total-heading">
                Total Candidates ({formatAmount(totalRecordCount)})
              </Typography>
            </Grid>
            <Grid item>
              <Grid
                container
                justifyContent={{ sm: "flex-start", md: "flex-end" }}
              >
                <Grid
                  item
                  style={{ position: "relative" }}
                >
                  <Searchbar
                    classes="ClientListingSearchbarContainer searchBarResWidth"
                    isNewSeachBar={true}
                    width={"383px"}
                    height="1.7rem"
                    placeHolderText={"Search"}
                    txtFontSize={"14px"}
                    marginBottom="16px"
                    value={CandidateListState?.searchKeywords}
                    onChange={(e: any) => {
                      handleSearchbar(e.target.value)
                    }}
                    onEnter={(e:any)=> handleSearchbar(e.target.value)}
                    onIconClick={(e:any)=> handleSearchbar(e.target.value)}
                  />
                </Grid>
                <Grid
                  item
                >
                  <Button
                    variant="text"
                    component="div"
                    size="large"
                    className="v2-base-btn cust-btn-bgClr searchbarRow-ht-btn toolbarActionBtn"
                    startIcon={<FilterAltOutlinedIcon />}
                    onClick={_handleFilterClick}
                    data-testid="multifilter-button"
                  >
                    Filter
                  </Button>
                  <Button
                    variant="text"
                    component="div"
                    size="large"
                    className={`toolbarActionBtn ${
                      _hasAnyFilterApply()
                        ? "v2-base-btn"
                        : "disable-custom-Btn-fontsizes"
                    } cust-btn-bgClr searchbarRow-ht-btn clear-cust-wrap`}
                    startIcon={<ClearIcon />}
                    onClick={_handleClearAll}
                    disabled={!_hasAnyFilterApply()}
                    data-testid="clear-button"
                  >
                    Clear
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <CandidateListFilters
        ref={filterRef}
        anchorFilter={anchorFilter}
        onClose={_handleFilterClick}
        onChange={handleOnLocationChange}
        onExperinceChange={handleOnExperineceChange}
        handleCandiateChange={handleOnCandidateChange}
        handleRecruiterChange={handleOnRecruiterChange}

        selectedLocations={selectedCandidateLocation}
        selectedExperinces={selectedCandidateExperinces}
        selectedCandidate={selectedCandiadtes}
        selectedRecruiter={selectedRecruiters}
      />
    </>
  );
};

export default CandidateListTableToobar;
