import { createSvgIcon } from "@mui/material/utils";

export const Interviewing = createSvgIcon(
  <svg viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.145996 0.145844H1.646H5.29183H6.79183V3.14584H5.29183H3.146V5.29168V6.79168H0.145996V5.29168V1.64584V0.145844ZM8.16897 0.145844H9.66897H13.3148H14.8148V3.14584H13.3148H9.66897H8.16897V0.145844ZM16.1877 0.145844H17.6877H21.3335H22.8335V3.14584H21.3335H17.6877H16.1877V0.145844ZM24.2085 0.145844H25.7085H29.3543H30.8543V1.64584V5.29168V6.79168H27.8543V5.29168V3.14584H25.7085H24.2085V0.145844ZM5.97933 7.4376H7.47933H23.521H25.021V10.4376H23.521H7.47933H5.97933V7.4376ZM3.146 8.89584V10.3958V13.3125V14.8125H0.145996V13.3125V10.3958V8.89584H3.146ZM30.8543 8.89584V10.3958V13.3125V14.8125H27.8543V13.3125V10.3958V8.89584H30.8543ZM5.97933 11.8125H7.47933H23.521H25.021V14.8125H23.521H7.47933H5.97933V11.8125ZM3.146 16.1875V17.6875V20.6042V22.1042H0.145996V20.6042V17.6875V16.1875H3.146ZM5.97933 16.1875H7.47933H23.521H25.021V19.1875H23.521H7.47933H5.97933V16.1875ZM30.8543 16.1875V17.6875V20.6042V22.1042H27.8543V20.6042V17.6875V16.1875H30.8543ZM5.97933 20.5625H7.47933H16.2293H17.7293V23.5625H16.2293H7.47933H5.97933V20.5625ZM3.146 24.2083V25.7083V27.8542H5.29183H6.79183V30.8542H5.29183H1.646H0.145996V29.3542V25.7083V24.2083H3.146ZM30.8543 24.2083V25.7083V29.3542V30.8542H29.3543H25.7085H24.2085V27.8542H25.7085H27.8543V25.7083V24.2083H30.8543ZM8.16897 27.8542H9.66897H13.3148H14.8148V30.8542H13.3148H9.66897H8.16897V27.8542ZM16.1877 27.8542H17.6877H21.3335H22.8335V30.8542H21.3335H17.6877H16.1877V27.8542Z"
      fill="#4540DB"
    />
  </svg>,
  "Interviewing"
);
