import { Card, CardContent } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function AcceptanceRate({data}) {
  return (
    <Card
      sx={{ height: "100%" }}
      className="card-custom-shadow total-responses-card"
    >
      <CardContent sx={{ padding: 0 }}>
        <Grid container spacing={3}>
          <Grid
            item
            sx={{
              padding: 0,
              width: "100%",
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                background:
                  "linear-gradient(225deg, #8E8AFF 35.01%, #6D68FC 100%)",
                height: "40px",
                padding: "15px",
              }}
              className="total-responses-card-header"
            >
              <Box
                sx={{display:"flex",alignItems:"center",justifyContent: "space-around",width:"100%" }}
              >
               <Box>
                  <Typography variant="subtitle2" component="div">
                   Total Recruiters
                  </Typography>
                  <Typography variant="subtitle1" component="div">
                  &nbsp;{data?.totalRecruiters}                 
                   </Typography>
                   </Box>
                <Box sx={{borderLeft:"1px solid white",height:"100%"}}></Box>
                <Box>
                  <Typography variant="subtitle2" component="div">
                    Approved Recruiters
                  </Typography>
                  <Typography variant="subtitle1" component="div">
                  &nbsp;{data?.approvedRecruiters}

                  </Typography>
                  </Box>
                <Box sx={{borderLeft:"1px solid white",height:"100%"}}></Box>
                <Box >
                  <CircularProgress
                    color="secondary"
                    variant="determinate"
                    value={data?.rate}
                    thickness={8}
                    sx={{
                      marginTop: "5px",
                      borderRadius: "50%",
                      boxShadow: "inset 0 0 1px 6px #fff",
                      backgroundColor: "transparent",
                    }}
                    style={{ color: "#4540DB" }}
                  />
                  </Box>
              </Box>
            </Box>
            <Box
              sx={{
                margin: "15px",
                padding: "15px 15px 25px 15px",
                borderBottom: " 1px solid rgba(228, 228, 228, 0.6);",
                display: "flex",
                alignItems: "center",
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <Typography
                color="textPrimary"
                variant="h3"
                display={"inline-block"}
                className="primary-color-text"
              >
                {data?.rate!==undefined && data?.rate +"%"}
              </Typography>
              <Typography variant="h5" display={"inline-block"} sx={{fontSize: "1.4rem"}}>
                &nbsp;Acceptance Rate
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
