import { UserAuth } from "../models/interfaces";
import { ternary } from "./complexityUtils";

function getAdvanceSearchMessage(
  jobTitle: [],
  location: [],
  client: [],
  minimumPayRate: number,
  maximumPayRate: number,
  currency: string,
  userRole:number,
) {

  const isClientRole = userRole === UserAuth.Client || userRole === UserAuth.ClientTeamMember;
  const label_pay_bill_rate = ternary(isClientRole, "bill rate", "pay rate");

  const computeSearchResult = (
    jobTitle?.length > 0 ||
    location?.length > 0 ||
    client?.length > 0 ||
    minimumPayRate ||
    maximumPayRate ||
    currency
  );
  
  if (!computeSearchResult) {
    return "";
  }
  
  let searchResult = "Searching";

  if (jobTitle.length > 0) {
    searchResult += ` "${jobTitle}" jobs`;
  } else {
    searchResult += ` jobs`;
  }

  if (location.length > 0) {
    searchResult += ` in "${location}" `;
  }

  if (client.length > 0) {
    searchResult += ` at "${client}" `;
  }

  if (minimumPayRate && maximumPayRate) {
    searchResult += ` with ${label_pay_bill_rate} between ${currency} ${minimumPayRate} and ${currency} ${maximumPayRate} `;
  } else if (minimumPayRate) {
    searchResult += ` with ${label_pay_bill_rate} > ${currency} ${minimumPayRate} `;
  } else if (maximumPayRate) {
    searchResult += ` with ${label_pay_bill_rate} < ${currency} ${maximumPayRate} `;
  } else if (currency) {
    searchResult += ` for "${currency}"`;
  }

  return searchResult;
}
export default getAdvanceSearchMessage;
