import { useSearchParams } from "react-router-dom";
import Submissions from '../Submissions/Submissions';

export const NewSubmissionPage = () => {
  const [searchParams] = useSearchParams();
  let clearStatus = searchParams.get("recruiterGuid") === null ? null : searchParams.get("recruiterGuid");
    
  return (
    <Submissions key={clearStatus}/>
  )
}
