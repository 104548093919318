import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { FunctionComponent, useState } from "react";

import { useImpersonate } from "../../../hooks/useImpersonate";
import { UserAuth } from "../../../models/interfaces";
import { AdminAutocomplete } from "../../ui/AdminAutocomplete";
import { ClientAutocomplete } from "../../ui/ClientAutocomplete";
import { RecruiterAutocomplete } from "../../ui/RecruiterAutocomplete";
import { TeamMemberAutocomplete } from "../../ui/TeamMemberAutocomplete";
import { useDispatch } from "react-redux";
import { setExpandedCollapseLeftMenuIndex } from "../../../features/Layout/NavigationSlice";

const Wrapper = styled.div``;

type ImpersonateUserProps = {
  isOpen: boolean;
  onClose: any;
  userType: UserAuth;
};

const ImpersonateUser: FunctionComponent<ImpersonateUserProps> = ({
  isOpen,
  onClose,
  userType,
}) => {
  const { impersonateUser } = useImpersonate();
 const dispatch = useDispatch();

  const [selectedUser, setselectedUser] = useState<any>({});
  const handleChange = (e: any, value: any) => {
    setselectedUser(value);
  };
  const handlecontinue = () => {
    //Reset Left menu collapse index    
    dispatch(setExpandedCollapseLeftMenuIndex(0));
    impersonateUser(selectedUser.email);
  };
  const renderUserList = () => {
    switch (userType) {
      case UserAuth.Admin:
        return (
          <Grid container spacing={2} pt={0.6}>
            <Grid item sm={1.4} style={{ padding: "14px 0 0 16px" }}>
              <Typography className="AdvanceSearchLabel userTypeTxt" fontSize={16}>
                Admin:
              </Typography>
            </Grid>
            <Grid xs={10.5} pl={2} pt={2}>
              <AdminAutocomplete
                showEmail={true}
                label={""}
                admin={selectedUser}
                onChange={handleChange}
                readOnly={false}
                multiple={false}
              />
            </Grid>
          </Grid>
        );

      case UserAuth.Recruiter:
        return (
          <Grid container spacing={2} pt={0.6}>
            <Grid item sm={2.2} style={{ padding: "14px 0 0 16px" }}>
              <Typography className="AdvanceSearchLabel userTypeTxt" fontSize={16}>
                Recruiter:
              </Typography>
            </Grid>
            <Grid xs={9} pl={2} pt={2}>
              <RecruiterAutocomplete
                showEmail={true}
                label={" "}
                recruiter={selectedUser}
                onChange={handleChange}
                readOnly={false}
                multiple={false}
                isRequired={false}
                disableLabel={true}
              />
            </Grid>
          </Grid>
        );
      case UserAuth.Client:
        return (
          <Grid container spacing={2} pt={0.6}>
            <Grid item sm={1.4} style={{ padding: "14px 0 0 16px" }}>
              <Typography className="AdvanceSearchLabel userTypeTxt" fontSize={16}>
                Client:
              </Typography>
            </Grid>
            <Grid xs={10.5} pl={2} pt={2}>
              <ClientAutocomplete
                showEmail={true}
                label={""}
                admin={selectedUser}
                onChange={handleChange}
                readOnly={false}
                multiple={false}
              />
            </Grid>
          </Grid>
        );
      case UserAuth.ClientTeamMember:
        return (
          <Grid container spacing={2} pt={0.6}>
            <Grid item sm={3.5} style={{ padding: "14px 0 0 16px" }}>
              <Typography className="AdvanceSearchLabel userTypeTxt" fontSize={16}>
                {"Team Member"}:
              </Typography>
            </Grid>
            <Grid className="teammembertxt" xs={8} pl={2} pt={2}>
              <TeamMemberAutocomplete
                showEmail={true}
                label={" "}
                recruiter={selectedUser}
                onChange={handleChange}
                readOnly={false}
                multiple={false}
                isRequired={false}
                disableLabel={true}
              />
            </Grid>
          </Grid>
        );

      default:
        break;
    }
  };
  return (
    <>
      <Wrapper>
        <Dialog
          open={isOpen}
          onClose={onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{ bgcolor: "#fff" }}
          ></DialogTitle>
          <DialogContent sx={{ bgcolor: "#fff" }}>
            <IconButton
              aria-label="close"
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
                padding: "5px",
                marginRight: "6px",
              }}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent
              style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 16 }}
            >
              {renderUserList()}
            </DialogContent>
          </DialogContent>
          <DialogActions sx={{ bgcolor: "#fff" }}>
            <Button
              variant="outlined"
              size="small"
              style={{ marginRight: "5px", textTransform: "capitalize" }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              style={{ textTransform: "capitalize", marginRight: 15 }}
              disabled={selectedUser?.id ? false : true}
              data-testid="handleContinueBtn"
              onClick={() => handlecontinue()}
            >
              Continue
            </Button>
          </DialogActions>
        </Dialog>
      </Wrapper>
    </>
  );
};

export default ImpersonateUser;
