import jwt_decode from "jwt-decode";
import { UserAuth } from "../../models/interfaces";

const identifier = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";

function getLoggedInUserRole(): UserAuth {
  const token = localStorage.getItem("accessToken");

  if (token) {
    const decoded: any = jwt_decode(token);
    
    if (decoded) {
      const userRole = decoded?.[identifier].toLowerCase();
      switch (userRole) {
        case "superadmin":
          return UserAuth.SuperAdmin;
        case "admin":
          return UserAuth.Admin;
        case "recruiter":
          return UserAuth.Recruiter;
        case "client":
          return UserAuth.Client;
          case "clientteammember":

            return UserAuth.ClientTeamMember;
        default:
          break;
      }
    }
  }
  
  return UserAuth.NotLoggedIn;
}

export default getLoggedInUserRole;
