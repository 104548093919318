import React, { FC } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface DuplicateCandidateAlertPopupProps {
  duplicateSubmissionDialogOpen: boolean;
  handleDuplicateDialogConfirmationNo: () => void;
  error: { errorMsg: string };
}

const DuplicateCandidateAlertPopup: FC<DuplicateCandidateAlertPopupProps> = ({
  duplicateSubmissionDialogOpen,
  handleDuplicateDialogConfirmationNo,
  error,
}) => {
  return (
    <Dialog
      open={duplicateSubmissionDialogOpen}
      onClose={handleDuplicateDialogConfirmationNo}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          padding: '20px 24px 10px 24px',
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{ padding: '0px 0px 5px 0px' }}
      >
        <IconButton
          aria-label="close"
          size="small"
          className="close-Btn"
          style={{ position: 'absolute', right: '16px', top: '12px' }}
          onClick={handleDuplicateDialogConfirmationNo}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: '20px 0px 10px 0' }}>
        <DialogContentText>
          <Typography component="div" className="PopupPrimaryTitle">
            {error.errorMsg}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{ justifyContent: 'center', marginBottom: '16px' }}
      >
        <Button
          onClick={handleDuplicateDialogConfirmationNo}
          variant="outlined"
          sx={{ fontWeight: 500 }}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DuplicateCandidateAlertPopup;
