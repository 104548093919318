import React from 'react';
import { useSelector } from 'react-redux';
import getLoggedInUserRole from '../../../Shared/utils/getLoggedInUserRole';
import { UserAuth } from '../../../models/interfaces';

interface ModuleComponentProps {
  moduleId: string;
  children: React.ReactNode;
  showIfNotLoggedIn? : boolean;
}

export const ModuleComponent: React.FC<ModuleComponentProps> = ({
  moduleId,
  children,
  showIfNotLoggedIn = false
}) => {
  let moduleIds = moduleId?.split(',').map(id => id.trim());
  const { features, modules } = useSelector((state: RootState) => state.userPermissions);
  let userRole = getLoggedInUserRole();
  // Combine features and modules into a single array
const combinedArray = [...features, ...modules];
  if(moduleId=="" || !moduleId)
  {
    return <>{children}</>;

  }
  if(userRole==UserAuth.NotLoggedIn && showIfNotLoggedIn)
  {
    return <>{children}</>;
  }
  
  if (moduleIds.every(item => combinedArray.includes(item))) {
    return <>{children}</>;
  }

  // User doesn't have permission for this module//
  return null;
};

// Define the RootState type based on your Redux store structure
interface RootState {
  userPermissions: {
    features: string[];
    modules: string[];
  };
}
