
/**
 * Calculate the sum of two numbers with 2 decimal places precision.
 * If either input is not numeric, returns 0.
 * @param firstNumber - The first number to add.
 * @param secondNumber - The second number to add.
 * @returns The sum with 2 decimal places precision or 0 if inputs are not numeric.
 */
export function calculateSumWithPrecision(
    firstNumber: any, 
    secondNumber: any 
  ): number {
    if (typeof firstNumber === 'number' && typeof secondNumber === 'number') {
      const sum = firstNumber + secondNumber;
      const sumWithPrecision = parseFloat(sum?.toFixed(2));
      return sumWithPrecision;
    } else {
      return 0;
    }
  }

  