import { SvgIcon } from '@mui/material'
import React from 'react'

export default function Process(props) {
  return (
    <SvgIcon viewBox='0 0 35 35' {...props}>
<path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.29116 13.4583L9.53421 17.1042M14.4995 13.4583L14.2564 17.1042M14.2564 17.1042L13.9787 20.75H9.81199L9.53421 17.1042M14.2564 17.1042H16.5833V12.2917C16.5833 10.6348 15.2402 9.29167 13.5833 9.29167H10.2083C8.55148 9.29167 7.20833 10.6348 7.20833 12.2917V17.1042H9.53421M18.6667 18.471C19.0427 18.5824 19.3911 18.703 19.7083 18.8317C21.014 19.3612 21.7917 20.0269 21.7917 20.75C21.7917 22.4759 17.3611 23.875 11.8958 23.875C6.43051 23.875 2 22.4759 2 20.75C2 20.0269 2.77768 19.3612 4.08333 18.8317C4.40058 18.703 4.749 18.5824 5.125 18.471M11.8953 7.20833C10.4571 7.20833 9.29116 6.04241 9.29116 4.60417C9.29116 3.16593 10.4571 2 11.8953 2C13.3336 2 14.4995 3.16593 14.4995 4.60417C14.4995 6.04241 13.3336 7.20833 11.8953 7.20833Z"
    />    </SvgIcon>
  )
}

