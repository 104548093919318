import { createSvgIcon } from "@mui/material/utils";

export const DeleteDocumentIcon = createSvgIcon(
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.9538 2.29034H12.9547C12.928 2.28107 12.9002 2.27506 12.872 2.27229H10.7435V0.506954C10.7436 0.374288 10.6916 0.24692 10.5989 0.152168C10.5061 0.0574247 10.3799 0.00276753 10.2474 0H5.21343C5.07896 0 4.95003 0.0533319 4.85504 0.148436C4.75994 0.243539 4.70648 0.372473 4.70648 0.506951V2.27228H2.58877C2.5606 2.27505 2.53279 2.28107 2.50607 2.29034H0.506942C0.325762 2.29034 0.158412 2.38689 0.0678977 2.54375C-0.0226326 2.70062 -0.0226326 2.89383 0.0678977 3.05069C0.158428 3.20755 0.325765 3.30423 0.506942 3.30423H2.13565L3.45171 15.547C3.46579 15.6716 3.52526 15.7866 3.61881 15.8702C3.71222 15.9538 3.83321 15.9999 3.95864 16H11.5093C11.6334 15.9982 11.7525 15.9511 11.8447 15.8678C11.9366 15.7844 11.995 15.6704 12.009 15.547L13.3249 3.3221H14.9537C15.1349 3.3221 15.3023 3.22555 15.3928 3.06868C15.4833 2.91182 15.4833 2.71849 15.3928 2.56174C15.3023 2.40488 15.1349 2.30821 14.9537 2.30821L14.9538 2.29034ZM5.73131 1.01389H9.72943V2.27228H5.73131V1.01389ZM11.0491 14.9788H4.41165L3.15326 3.32222H12.3074L11.0491 14.9788Z" fill="#E41D1D"/>
    <path d="M7.73252 13.7934C7.867 13.7934 7.99593 13.7399 8.09104 13.6449C8.18614 13.5498 8.23948 13.4209 8.23948 13.2864V5.0168C8.23948 4.83562 8.14293 4.66827 7.98606 4.57775C7.8292 4.4871 7.63587 4.4871 7.479 4.57775C7.32214 4.66828 7.22559 4.83562 7.22559 5.0168V13.2864C7.22559 13.4209 7.27892 13.5498 7.37402 13.6449C7.46913 13.7399 7.59806 13.7934 7.73254 13.7934H7.73252Z" fill="#E41D1D"/>
    <path d="M5.73504 13.7889H5.75659C5.89046 13.7833 6.01662 13.725 6.10765 13.6266C6.19854 13.5282 6.24693 13.3979 6.242 13.264L5.93634 4.99438L5.93646 4.99426C5.93369 4.85882 5.87578 4.73025 5.77598 4.63851C5.67606 4.5469 5.54316 4.50007 5.40785 4.50886C5.27398 4.51452 5.14782 4.5729 5.05692 4.67126C4.96591 4.76961 4.91763 4.89999 4.92245 5.03387L5.22811 13.3035C5.23449 13.4339 5.29059 13.5569 5.38497 13.6472C5.47923 13.7373 5.60455 13.7881 5.73504 13.7889L5.73504 13.7889Z" fill="#E41D1D"/>
    <path d="M9.70915 13.7882H9.72708C9.86071 13.7912 9.99013 13.7416 10.0876 13.6502C10.185 13.5587 10.2428 13.4327 10.2485 13.2991L10.5541 5.02954H10.554C10.5589 4.89567 10.5106 4.76529 10.4197 4.66693C10.3286 4.56857 10.2025 4.51018 10.0686 4.50453C9.93172 4.49044 9.79521 4.53462 9.69252 4.62636C9.58983 4.71797 9.5306 4.84871 9.52928 4.98644L9.22001 13.2561H9.22013C9.21327 13.3917 9.26118 13.5245 9.35316 13.6246C9.44501 13.7246 9.57334 13.7836 9.70914 13.7882L9.70915 13.7882Z" fill="#E41D1D"/>
  </svg>,
  "DeleteDocumentIcon"
);
