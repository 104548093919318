import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { PermissionsEnums } from "../../../../data/permission";
import { CandidateFinalStatuses } from "../../../../models/interfaces";
import { ModuleComponent } from "../../../Common/Permission/ModuleComponent";
import "../../NewViewSubmission/styles.scss";

interface RejectButtonProps {
  row: any;
  setPopup: any;
}

export default function RejectButton({
  row,
  setPopup
} : RejectButtonProps) {

  const shouldRenderRejectButton =
    row?.finalStatus === CandidateFinalStatuses.CandidateSubmitted ||
    row?.finalStatus === CandidateFinalStatuses.CandidateRTROverDue ||
    row?.finalStatus === CandidateFinalStatuses.SubmittedToAM ||
    row?.finalStatus?.toLowerCase() ===
      CandidateFinalStatuses.SubmittedToClient.toLowerCase() ||
    row?.finalStatus === CandidateFinalStatuses.PendingClientReview ||
    row?.finalStatus === CandidateFinalStatuses.ApprovedByAM ||
    row?.finalStatus === CandidateFinalStatuses.RecruiterAssigned ||
    row?.finalStatus === CandidateFinalStatuses.PendingRecruiterReview;

  const handleRejectButtonClick = () => {
    if (shouldRenderRejectButton) {
      setPopup((popup: any) => ({ ...popup, reject: true }));
    }
  };

  return (
    <>
    {shouldRenderRejectButton && (
      <ModuleComponent moduleId={PermissionsEnums.Submissions_Reject}>
        <Grid item>
          <Button
            sx={{
              "&.MuiButtonBase-root:hover": {
                bgcolor: "red !important",
              },
              width: "100%",
            }}
            variant="contained"
            color="error"
            className="submission-action-btn"
            onClick={handleRejectButtonClick}
          >
            Reject
          </Button>
        </Grid>
      </ModuleComponent>
    )}
    </>
  );
}