import React from "react";
import {
    Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress, Typography } from "@material-ui/core";
import "./popup.scss";

interface Props {
  isOpen: boolean;
  title: string;
  type: string;
  handleClose: any;
  handleUpdateConfirmation:any;
  isSubmitting:any;
}
const UpdateConfirmPopup :React.FC<Props> = ({ isOpen, handleClose, title, type, handleUpdateConfirmation,isSubmitting }) => {

  return (
    <div>
      <Dialog
        className="custom-client-popup"
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="pop-up-title"
      >
        <DialogTitle style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography style={{ textAlign: "center" }}>
          </Typography>
          <Typography className="custom-popup-txt">
            {" "}
            Do you want to update the Job Details?
          </Typography>
          <Typography className="custom-popup-txt1"></Typography>
          <Typography className="custom-popup-txt2">
          </Typography>
          <Box
          style={{
            display:"flex",
            alignContent:"center",
            justifyContent:"center"
          }}
          >
          <Button
            type="submit"
            name="saveandcontinue"
            value="saveandcontinue"
            variant="outlined"
            color="primary"
            onClick={handleClose}
            style={{marginRight:"15px"}}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            name="saveandcontinue"
            value="saveandcontinue"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            endIcon={isSubmitting && <CircularProgress
              size={"16px"}
              color={"inherit"}
            />}
            onClick={handleUpdateConfirmation}
          >
            Update 
          </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default UpdateConfirmPopup;
