import axios from "./CommonHttpRequest";
let baseUrl = `${process.env.REACT_APP_RECUITER_API}`;

export const GetRecruiterById = async (id: string) => {
  return await axios.get(`${baseUrl}api/RecruiterSignup/${id}`);
};
export const GetRecruiters = async (filter: any) => {
  return await axios.post(`${baseUrl}api/RecruiterListing/GetRecruiter`, filter);
};
export const RecruiterSaveJob = (data: any) =>
  axios.put(`${baseUrl}api/RecruiterSaveJob/SaveJob`, data);

export const UpdateRecruiterUserProfile = async (body: any) => {
  return await axios.put(`${baseUrl}api/RecruiterUserProfile/Update`, body);
};

export const DeleteIncompleteSignup = async (id:string) => {
  return await axios.delete(`${baseUrl}api/Recruiter/Delete/${id}`);
}
export const GetRecruiterStatus= async () => {

  return await axios.get(`${baseUrl}api/Recruiter/Statuses`);
  
  };

export const GetAllDomains = async (search = "") => {
  let reqBody = {
    pageIndex: 1,
    pageSize: 10,
    sortColumn: "",
    sortOrder: "Ascending",
    isSorted: true,
    totalRecord: 0,
    id: "",
    domainName: "",
    keywordDomainNameSearch: search,
  };

  return await axios.post(`${baseUrl}api/Domain/GetDomain`, reqBody);
};
export const GetAllIndustries = async (search = "",pageSize=10) => {
  let reqBody = {
    pageIndex: 1,
    pageSize: pageSize,
    sortColumn: "",
    sortOrder: "Ascending",
    isSorted: true,
    totalRecord: 0,
    id: "",
    industryName: "",
    keywordIndustryNameSearch: search,
  };
  return await axios.post(`${baseUrl}api/Industry/GetIndustry`, reqBody);
};
export const GetAllLocation = async (search = "") => {
  let reqBody = {
    pageIndex: 1,
    pageSize: 10,
    sortColumn: "",
    sortOrder: "Ascending",
    isSorted: true,
    totalRecord: 0,
    id: "",
    city: "",
    keywordCitySearch: search,
  };
  return await axios.post(`${baseUrl}api/Location/GetLocation`, reqBody);
};

export const GetAllSkills = async (search = "", pageSize = 10) => {
  let reqBody = {
    pageIndex: 1,
    pageSize: pageSize,
    sortColumn: "",
    sortOrder: "Ascending",
    isSorted: true,
    totalRecord: 0,
    id: "",
    skillName: "",
    keywordskillNameSearch: search,
  };
  return await axios.post(`${baseUrl}api/Skill/GetPrimarySkill`, reqBody);
};
export const UpdateRecruiterLocation = async (body: any) => {
  return await axios.put(
    `${process.env.REACT_APP_RECUITER_API}api/RecruiterLocationDetails/Update`,
    body
  );
};

export const UpdateRecruiterPhoneVerificationStatus = async (body: any) => {
  return await axios.put(
    `${process.env.REACT_APP_RECUITER_API}api/RecruiterAccountDetails/UpdatePhoneVerificationStatus`,
    body
  );
};

export const UpdateJobNotificationSetting = async (body: any) => {
  return await axios.put(
    `${process.env.REACT_APP_RECUITER_API}api/RecruiterAccountDetails/UpdateJobNotificationSetting`,
    body
  );
};
export const UpdateProfilePhoto = async (body: any) => {
  return await axios.put(
    `${process.env.REACT_APP_RECUITER_API}api/RecruiterAccountDetails/UpdateProfilePhoto`,
    body
  );
};
export const UpdateRecruiterAccountDetails = async (body: any) => {
  return await axios.put(
    `${process.env.REACT_APP_RECUITER_API}api/RecruiterAccountDetails/Update`,
    body
  );
};
export const SaveVerifyRecruiter = async (body: any) => {
  return await axios.post(
    `${process.env.REACT_APP_RECUITER_API}api/RecruiterSignUpByRole/VerifyRecruiter`,
    body
  );
};

export const VerifyReasonsRecruiter = async (id: string) => {
  return await axios.get(
    `${process.env.REACT_APP_RECUITER_API}api/Recruiter/VerifyReasons?statusId=${id}`    
  );
};

export const SubmitResumeByRecruiter = async (body: any) => {
  return await axios.post(
    `${process.env.REACT_APP_CANDIDATE_API}api/Candidate/Submit`,
    body
  );
};

export const UpdateResumeByRecruiter = async (body: any) => {
  return await axios.put(
    `${process.env.REACT_APP_CANDIDATE_API}api/Candidate/Submission`,
    body
  );
};

export const SaveResumeByRecruiter = async (body: any) => {
  return await axios.post(
    `${process.env.REACT_APP_CANDIDATE_API}api/Candidate/SaveResume`,
    body
  );
};

export const VerifyBlacklistedRecruiter = async (body: any) => {
  return await axios.post(
    `${baseUrl}api/RecruiterSignup/VerifyBlacklistedRecruiter`,
    body
  );
};

export const GetCandidateDataByEmail = async ({
  email,
  fistname,
  lastname,
  recruiterId
}: any) => {
  return await axios.get(
    `${process.env.REACT_APP_CANDIDATE_API}api/Candidate/MatchCandidate?firstName=${fistname}&lastName=${lastname}&email=${email}&recruiterid=${recruiterId}`
  );
};

export const CandidateAuthorize = async (body: any) => {
  return await axios.post(
    `${process.env.REACT_APP_CANDIDATE_API}api/CandidateSubmission/Authorize`,
    body
  );
};

export const CandidateDecline = async (body: any) => {
  return await axios.post(
    `${process.env.REACT_APP_CANDIDATE_API}api/CandidateSubmission/Decline`,
    body
  );
};

export const GetSubmissionData = async ({ submissionId }: any) => {
  return await axios.get(
    `${process.env.REACT_APP_CANDIDATE_API}api/CandidateSubmission/VerifyAcknowledgementLink?submissionId=${submissionId}`
  );
};

export const GetOfferData = async ({ submissionId }: any) => {
  return await axios.get(
    `${process.env.REACT_APP_CANDIDATE_API}api/CandidateSubmission/GetOfferDetail?submissionId=${submissionId}`
 );
};


export const RecruiterUnsaveJob = (data: any) =>
  axios.put(`${baseUrl}api/RecruiterSaveJob/UnsaveJob`, data);

export const RecruiterStatusNumber = (reqPayload: any) => 
  axios.put(`${baseUrl}api/RecruiterStatusNumber/Update`, reqPayload);

export const UpdateEditRecruiter = async (body:any) =>{
    return await axios.post(`${baseUrl}api/RecruiterSignUpByRole/EditRecruiter`, body);
}

export const UpdateCompensation = async (body:any) =>{
  return await axios.post(`${baseUrl}api/RecruiterSignUpByRole/UpdateRecruiterCompensation`, body);
}
export const GetRecruiterCompensation = async () =>{
  return await axios.get(`${baseUrl}api/RecruiterSignUpByRole/GetRecruiterCompensation`);
}

export const CalculateCompensation = async (body:any) =>{
  return await axios.post(`${baseUrl}api/Compensation/CalculateCompensation`,body);
}


export const getRecruiterDashboard = async (recruiterId:string) =>{
  return await axios.get(`${baseUrl}api/Dashboard/GetDashboard/${recruiterId}`);
}
export const getRecruiterGraphicalDashboard = async (recruiterId:string) =>{
  return await axios.get(`${baseUrl}api/Dashboard/GetGraphicalDashboard/${recruiterId}`);
}

export const getAdminGraphicalDashboard = async (recruiterId:string) =>{
  return await axios.get(`${baseUrl}api/RecruiterSignup/GetAdminGraphicalDashboard`);
}

export const GlasssquidScoreResume = async (body:any) =>{
  return await axios.post(`${process.env.REACT_APP_INTEGRATION_API}api/Glasssquid/ScoreResume`,body);
}

export const GetMyEarnings = async (body:any) =>{
  return await axios.post(`${baseUrl}api/Compensation/Earnings`,body);
}

export const ShareJobByRecruiter = async (body: any) => {
  return await axios.post(`${process.env.REACT_APP_JOBPROVIDER_API}api/Jobs/ShareJobDetail`,body);
};

export const ShareInterviewDetailByRecruiter = async (body: any) => {
  return await axios.post(`${process.env.REACT_APP_CANDIDATE_API}api/CandidateSubmission/ShareInterviewDetail`,body);
};
export const SendEmailForDeniedEmailUpdation = async (body:any) =>{
  return await axios.post(`${baseUrl}api/RecruiterAccountDetails/SendEmailForDeniedEmailUpdation`,body);
}

export const SendEmailForDeniedPaymentUpdation = async (body:any) =>{
  return await axios.post(`${baseUrl}api/RecruiterBilling/SendEmailForDeniedUpdation`,body);
}

export const SendEmailForUpdateSubmission = async (body:any) =>{
  return await axios.post(`${process.env.REACT_APP_CANDIDATE_API}api/CandidateSubmission/ShareUpdateSubmissionDetail`,body);
}

export const GetHearAboutUsOptions = async () => {
  return await axios.get(`${baseUrl}api/RecruiterSignup/GetHearAboutUsOptions`);
};

export const GetRecruiterAssessmentSectionData = async () => {
  return await axios.get(`${baseUrl}api/RecruiterSignup/GetScreeningData`);
};

export const getRecruiterInformationAccess = async (body:any) =>{
  return await axios.post(`${process.env.REACT_APP_RECUITER_API}api/RecruiterListing/GetRecruiterInformationAccess`,body);
}
export const GlasssquidMatchProfile = async (body:any) =>{
  return await axios.post(`${process.env.REACT_APP_INTEGRATION_API}api/Glasssquid/MatchProfile`,body);
}
export const SendEmailForUpdateCandidate = async (body:any) =>{
  return await axios.post(`${process.env.REACT_APP_CANDIDATE_API}api/Candidate/ShareUpdateCandidateDetail`,body);
}

export const RecruiterLinkVerificationCheck = async (email:string, expiryTime:string, messageType:string, id:string) =>{
  return await axios.get(`${process.env.REACT_APP_RECUITER_API}api/RecruiterSignupInitiation/SignupInitialVerified?id=${id}&email=${email}&expiryDateTime=${expiryTime}&messageType=${messageType}`);
}
export const EximiusMatchProfile = async (body:any) =>{
  return await axios.post(`${process.env.REACT_APP_INTEGRATION_API}api/Eximius/ScoreCard`,body,{timeout: 300000});
}
export const SetDemoContractStatus = async (body:any) =>{
  return await axios.post(`${baseUrl}api/RecruiterSignUpByRole/SetDemoStatus`,body);
}

export const ShareContractDetails = async (body:any) =>{
  return await axios.post(`${baseUrl}api/RecruiterSignUpByRole/SendContractNotification`,body);
}

export const SetContractDetails = async (body:any) =>{
  return await axios.post(`${baseUrl}api/RecruiterSignUpByRole/SetContractDetails`,body);
}

export const InitiateRecruiterContract = async (body:any) =>{
  return await axios.post(`${process.env.REACT_APP_INTEGRATION_API}api/Contract/initiate-contract`,body);
}
