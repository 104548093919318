import axios from "./CommonHttpRequest";
let baseUrlIdProvider = `${process.env.REACT_APP_IDPROVIDER_API}`;

export const adminFilter = async (body: any) => {
    return await axios.post(`${baseUrlIdProvider}api/Admin/Filter`, body);
  };

  export const addAdmin = async (body: any) => {
    return await axios.post(`${baseUrlIdProvider}api/Admin/Add`, body);
  };
  export const editAdmin = async (body: any) => {
    return await axios.post(`${baseUrlIdProvider}api/Admin/Edit`, body);
  };
  export const setActive = async (body: any) => {
    return await axios.post(`${baseUrlIdProvider}api/Admin/SetActive`, body);
  };
  export const impersonate = async (body: any) => {
    return await axios.post(`${baseUrlIdProvider}api/User/Impersonate`, body);
  };



  