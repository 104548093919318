import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
} from "@material-ui/core";
import { toggleEdit } from "../../../features/editMode/editModeSlice";
import { useDispatch } from "react-redux";
import { ErrorMessage } from "../../ui/ErrorMessage";
import { CustomAlert } from "../../ui/CustomAlert";
import { UserAuth } from "../../../models/interfaces";
import { CustomEmailInput } from "../../ui/CustomEmailInput";
import { Grid } from "@mui/material";
import SnackbarPopup from "../../Common/Popup/snackbar/SnackbarPopup";
import { BillingDetailProps } from "../ManageProfile";
interface Props {
  formik : any,
  pageStatus : any,
  editMode : any,
  formState : any,
  setIsChecked : any,
  isChecked :any,
  isAlreadySet : any,
  viewMode : UserAuth,
  billingDetail: BillingDetailProps | undefined;
  onChange : any,
  handleOnBlur:any;
  setPageStatus?:any,
 
}
export const PayoneerForm: React.FC<Props> = ({
  editMode,
  billingDetail,
  formik,
  pageStatus,
  formState,
  setIsChecked,
  isAlreadySet,
  isChecked,
  viewMode,
  onChange,
  handleOnBlur,
  setPageStatus
  
}) => {
  const dispatch = useDispatch()
   const handleCancelClick = ()=>{
    formik.setTouched({}, false);
    if(!isAlreadySet)
    {
      setIsChecked(false)
    }
    formState.email = billingDetail?.email;
    formik.setValues(formState);
    dispatch(toggleEdit());
  }

  const handleOnchange = (e:any)=> {
    onChange(e)

  }

  const _handleOnBlur = (e:any)=> {
    handleOnBlur(e)

  }
  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12}>
            {
            viewMode!==UserAuth.Admin && editMode && (
            <Box className="manage-profile-btns">
              <Button  
                variant="outlined" 
                onClick={() => handleCancelClick() }
                data-testid={"btn-payoneerform-cancel"}
                className="manage-profile-btnCancel"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={!isChecked}
                data-testid={"btn-payoneerform-updateprofile"}
                onClick={() => {
                  console.log(formik.errors);
                  formik.handleSubmit();
                }}
              >
                Update Profile
              </Button>
            </Box>
          )}
        </Grid>
        <Grid item xs={12}>
          <FormControl
            variant="standard"
            fullWidth
            required={viewMode === UserAuth.Recruiter}
            error={
              formik.touched.email &&
              Boolean(formik.errors.email)
            }
          >
            <InputLabel htmlFor="email" required> Email  </InputLabel>
            <CustomEmailInput 
              style={{width:'50%'}} 
              readOnly={!editMode} 
              name = "email" 
              value={formik?.values?.email} 
              onChange={handleOnchange}
              onBlur={_handleOnBlur} 
              isverified = {billingDetail?.email!=null && (billingDetail.email === formik?.values?.email)}
            />
            <ErrorMessage errorText={formik.touched.email && formik.errors.email} />
          </FormControl>
          {
            viewMode!==UserAuth.Admin && 
            <Box className="form-three-child" sx={{ marginTop: "14px" }}>          
              <Box className="us-billing-checkbox marginTop5" style={{marginBottom: '-20px'}}>
                <FormControlLabel onChange={()=> setIsChecked(!isChecked)}  checked={isChecked}
                  control={<Checkbox style={{marginTop:'-3px'}} disabled={!editMode} />}
                  label="I confirm that the details provided above for my Payoneer account is correct and I understand that I will be responsible for any fees charged by Payoneer for disbursement of payments to me."
                />
              </Box>
            </Box>
          }
        </Grid>
      </Grid>
        <br/>
      {pageStatus.error &&  <CustomAlert key={1} type={"error"} message={pageStatus.error} onClose={()=>{}} />}
      {pageStatus.isSuccess &&  
      <SnackbarPopup open={pageStatus.isSuccess}  message={'Payment details updated successfully'} onClose={() => {setPageStatus({...pageStatus, isSuccess:false})}}/>}
      
    </>
  );
};
