import { createSvgIcon } from "@mui/material/utils";

export const MapMarker = createSvgIcon(
  <svg viewBox="0 0 25 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 3.6875C7.29326 3.6875 3.0625 7.92626 3.0625 13.1672C3.0625 14.3555 3.61061 16.0526 4.61489 18.0745C5.5956 20.0489 6.91861 22.1536 8.26488 24.0999C9.60749 26.0409 10.9531 27.7956 11.9647 29.0666C12.1561 29.307 12.3353 29.5299 12.5 29.7332C12.6647 29.5299 12.8439 29.307 13.0353 29.0666C14.0469 27.7956 15.3925 26.0409 16.7351 24.0999C18.0814 22.1536 19.4044 20.0489 20.3851 18.0745C21.3894 16.0526 21.9375 14.3555 21.9375 13.1672C21.9375 7.92626 17.7067 3.6875 12.5 3.6875ZM12.5 32.0833C11.3631 33.0618 11.3629 33.0616 11.3627 33.0614L11.3621 33.0607L11.3602 33.0584L11.3533 33.0504L11.3279 33.0208C11.3059 32.995 11.2739 32.9574 11.2325 32.9085C11.1497 32.8107 11.0293 32.6677 10.8767 32.4843C10.5717 32.1175 10.1376 31.5884 9.61735 30.9347C8.57811 29.6289 7.18938 27.8186 5.79762 25.8065C4.40952 23.7998 2.99815 21.5634 1.92808 19.4091C0.881577 17.3022 0.0625 15.0828 0.0625 13.1672C0.0625 6.28032 5.62551 0.6875 12.5 0.6875C19.3745 0.6875 24.9375 6.28032 24.9375 13.1672C24.9375 15.0828 24.1184 17.3022 23.0719 19.4091C22.0019 21.5634 20.5905 23.7998 19.2024 25.8065C17.8106 27.8186 16.4219 29.6289 15.3827 30.9347C14.8624 31.5884 14.4283 32.1175 14.1233 32.4843C13.9707 32.6677 13.8503 32.8107 13.7675 32.9085C13.7261 32.9574 13.6941 32.995 13.6721 33.0208L13.6467 33.0504L13.6398 33.0584L13.6379 33.0607L13.6373 33.0614C13.6371 33.0616 13.6369 33.0618 12.5 32.0833ZM12.5 32.0833L13.6369 33.0618L12.5 34.3828L11.3631 33.0618L12.5 32.0833ZM12.5 9.59966C10.5459 9.59966 8.95192 11.1915 8.95192 13.1672C8.95192 15.143 10.5459 16.7348 12.5 16.7348C14.4541 16.7348 16.0481 15.143 16.0481 13.1672C16.0481 11.1915 14.4541 9.59966 12.5 9.59966ZM5.95192 13.1672C5.95192 9.54552 8.87815 6.59966 12.5 6.59966C16.1218 6.59966 19.0481 9.54552 19.0481 13.1672C19.0481 16.7889 16.1218 19.7348 12.5 19.7348C8.87815 19.7348 5.95192 16.7889 5.95192 13.1672Z"
      fill="black"
    />
  </svg>,
  "MapMarker"
);
