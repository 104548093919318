import { createSvgIcon } from "@mui/material/utils";

export const Edit = createSvgIcon(
  <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.502 1.94C14.5954 2.03372 14.6479 2.16066 14.6479 2.293C14.6479 2.42533 14.5954 2.55227 14.502 2.646L13.459 3.69L11.459 1.69L12.502 0.645997C12.5958 0.552261 12.7229 0.499603 12.8555 0.499603C12.9881 0.499603 13.1152 0.552261 13.209 0.645997L14.502 1.939V1.94ZM12.752 4.396L10.752 2.396L3.939 9.21C3.88396 9.26502 3.84253 9.33214 3.818 9.406L3.013 11.82C2.9984 11.864 2.99633 11.9112 3.00701 11.9563C3.0177 12.0014 3.04072 12.0427 3.07351 12.0755C3.10629 12.1083 3.14755 12.1313 3.19267 12.142C3.23779 12.1527 3.28499 12.1506 3.329 12.136L5.743 11.331C5.81676 11.3068 5.88387 11.2657 5.939 11.211L12.752 4.396Z"
      fill="#4540DB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 13.5C0 13.8978 0.158035 14.2794 0.43934 14.5607C0.720644 14.842 1.10218 15 1.5 15H12.5C12.8978 15 13.2794 14.842 13.5607 14.5607C13.842 14.2794 14 13.8978 14 13.5V7.5C14 7.36739 13.9473 7.24021 13.8536 7.14644C13.7598 7.05268 13.6326 7 13.5 7C13.3674 7 13.2402 7.05268 13.1464 7.14644C13.0527 7.24021 13 7.36739 13 7.5V13.5C13 13.6326 12.9473 13.7598 12.8536 13.8535C12.7598 13.9473 12.6326 14 12.5 14H1.5C1.36739 14 1.24021 13.9473 1.14645 13.8535C1.05268 13.7598 1 13.6326 1 13.5V2.5C1 2.36739 1.05268 2.24021 1.14645 2.14644C1.24021 2.05268 1.36739 2 1.5 2H8C8.13261 2 8.25979 1.94732 8.35355 1.85355C8.44732 1.75978 8.5 1.6326 8.5 1.5C8.5 1.36739 8.44732 1.24021 8.35355 1.14644C8.25979 1.05267 8.13261 0.999997 8 0.999997H1.5C1.10218 0.999997 0.720644 1.15803 0.43934 1.43934C0.158035 1.72064 0 2.10217 0 2.5V13.5Z"
      fill="#4540DB"
    />
  </svg>,
  "Edit"
);
