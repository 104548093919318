function getJobHiredStatus(hiredCount, jobStatus) {
    if(jobStatus === "Active")
    {
      if(hiredCount === "0" || hiredCount === "1")
      {
      return hiredCount+ " Candidate hired";
      }
      return hiredCount+ " Candidates hired";

    }
    else
    {
      if(hiredCount === "0" || hiredCount === "1")
      {
      return hiredCount+ " Candidate hired, job closed"
      }
      return hiredCount+ " Candidates hired, job closed"

    }
  }
  export default getJobHiredStatus;
  