import React,{useState} from "react";
import Container from "@mui/material/Container";
import {
  Box,
  Grid,
} from "@mui/material";
import YouTube from 'react-youtube';
import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';

export default function IntroductionInfo(props) {
  
    const [isCloseButton, setIsCloseButton] = useState(false);

    const opts = {
        height: '400',
        width: '700',
        playerVars: {
            autoplay: props.autoPlayVideo,
            loop: 1,
            controls:1,
            showinfo: 0
        },
      };

  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid container>
         
          <Grid item sm={12} xs={12} md={12}style={{textAlign: "center"}}>
            { isCloseButton && 
          <Box className="closeIconOnYutube">
                <Fab  aria-label="helpInfo"
                className="youtubeCloseIconFab"
                data-testid="close-button" // Add this attribute

                onClick={()=>{
                    props.setIsPlayVideo(true)
                    props.setIsWatchedVideo(true);
                }}
                >
                <CloseIcon style={{ color: "#fff" }}/>
                </Fab>
            </Box>
            }
          <YouTube 
            videoId="H1l6BuWwhxA" 
            opts={opts}
            onPlay={(e)=>{
                setIsCloseButton(true);
                props.setIsPlayVideo(true)
                props.setIsWatchedVideo(false);
                console.log(e);
            }} 
            onEnd={(e)=>{
                // does nothing, not sure if intentional
            }}
          />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
