import React, { useState } from 'react';
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';

export interface OptionModel {
  id: string;
  optionText: string;
  instructionText: string;
  specificationRequired?: string;
}

export interface QuestionModel {
  id: string;
  question: string;
  instructionText: string;
  type: number;
  options: OptionModel[];
}

interface RadioSelectionFieldProps {
  name: string;
  isRequired: boolean;
  onHandleChange: (questionObj: QuestionModel) => void;
  onBlur: (id: string | number | undefined) => void;
  questionDetails: QuestionModel;
  error?: boolean;
  helperText?: string;
  defaultSelectedOption?: OptionModel;
}

const RadioSelectionField: React.FC<RadioSelectionFieldProps> = ({
  name,
  isRequired,
  onHandleChange,
  onBlur,
  questionDetails,
  error = false,
  helperText = "",
  defaultSelectedOption
}) => {
  
  const [selectedOption, setSelectedOption] = useState<OptionModel | null>(defaultSelectedOption);
  const [textValue, setTextValue] = useState<string>(defaultSelectedOption?.specificationRequired || "");

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const selectedOption = questionDetails.options.find(option => option.id === value) || null;
    
    setSelectedOption(selectedOption);
    
    if (selectedOption) {
      onHandleChange(selectedOption);
    }
  };

  const handleTextInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setTextValue(value);

    if (selectedOption) {
      const updatedOption = {
        ...selectedOption,
        specificationRequired: value
      };
      
      onHandleChange(updatedOption);
    }
  };

  return (
    <FormControl 
      component="fieldset" 
      key={questionDetails.id} 
      required={isRequired}
      sx={{ marginBottom: 2 }}
    >
      <FormLabel component="legend">{questionDetails.question}</FormLabel>
      <RadioGroup
        name={name}
        onBlur={() => onBlur(questionDetails.id)}
        onChange={handleRadioChange}
        value={selectedOption?.id || ""}
      >
        {questionDetails.options.map((option: OptionModel) => (
          <React.Fragment key={option.id}>
            <FormControlLabel
              value={option.id}
              control={<Radio />}
              label={option.optionText}
            />
            {selectedOption?.id === option.id && option.instructionText && (
              <TextField
                name={`otherInput_${option.id}`}
                onChange={handleTextInputChange}
                value={textValue}
                label=""
                error={error}
                helperText={helperText}
                placeholder="Please specify"
                variant="standard"
                sx={{ marginBottom: "16px" }}
              />
            )}
          </React.Fragment>
        ))}
      </RadioGroup>
      {error && <FormHelperText className='formHelperTextJR'>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default RadioSelectionField;
