import React from "react";
import "./../../Scorecard.scss";
import Grid from "@mui/material/Grid";
import { Divider, Paper } from "@mui/material";
import SectionHeading from "../../../../ui/Form/Heading/SectionHeading";
import KeywordsTools from "../Keywords/KeywordsTools";
import KeywordsEducation from "../Keywords/KeywordsEducation";
import KeywordsCertification from "../Keywords/KeywordsCertification";
import KeywordsIndustry from "../Keywords/KeywordsIndustry";
import KeywordsTech from "../Keywords/KeywordsTech";
import KeywordsSkills from "../Keywords/KeywordsSkills";
import { KeywordsCategory } from "./ScorecardMatchingKeywords";

interface ScorecardMatchingKeywordsProps {
  skillsKeywords: KeywordsCategory;
  technologyKeywords: KeywordsCategory;
  educationKeywords: KeywordsCategory;
  certificationKeywords: KeywordsCategory;
  industryKeywords: KeywordsCategory;
  toolKeywords: KeywordsCategory;
}

const ScorecardMissingKeywords: React.FC<
  ScorecardMatchingKeywordsProps
> = ({
  skillsKeywords,
  technologyKeywords,
  educationKeywords,
  certificationKeywords,
  industryKeywords,
  toolKeywords

}) => {

  return (
    <>
      <Grid container spacing={2}>
        {/* Row 0 for Key skills keyword */}
        <Grid item xs={12}>
          <SectionHeading title="Missing Keywords" />
          <Divider className="borderedDivider" />
        </Grid>
        {/* Row 1 for Key skills keyword */}

        <Grid item xs={12}>
          <Paper elevation={0} className="keywords-background">
            <KeywordsSkills 
              skills={skillsKeywords?.termsNotFound ?? []} 
              className="ErrorChip" 
              type="missing"
              />
          </Paper>
        </Grid>

        {/* Row 2 for Technology keyword */}
        <Grid item xs={12}>
          <Paper elevation={0} className="keywords-background">
            <KeywordsTech technologies={technologyKeywords?.termsNotFound ?? []} className="ErrorChip" />
          </Paper>
        </Grid>
        {/* Row 3 for Tool Keyword */}
        <Grid item xs={12}>
          <Paper elevation={0} className="keywords-background">
            <KeywordsTools tools={toolKeywords?.termsNotFound ?? []} className="ErrorChip" />
          </Paper>
        </Grid>

        {/* Row 4 for Education Keyword */}
        <Grid item xs={12}>
          <Paper elevation={0} className="keywords-background">
            <KeywordsEducation education={educationKeywords?.termsNotFound ?? []} className="ErrorChip" />
          </Paper>
        </Grid>
        {/* Row 5 for certification Keyword */}
        <Grid item xs={12}>
          <Paper elevation={0} className="keywords-background">
            <KeywordsCertification
              certifications={certificationKeywords?.termsNotFound ?? []}
              className="ErrorChip"
            />
          </Paper>
        </Grid>
        {/* Row 6 for Matching Industry Keywords */}
        <Grid item xs={12}>
          <Paper elevation={0} className="keywords-background">
            <KeywordsIndustry industries={industryKeywords?.termsNotFound ?? []} className="ErrorChip" />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default ScorecardMissingKeywords;
