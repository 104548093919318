import { makeStyles } from "@material-ui/core";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Button, Grid, Paper } from "@mui/material";
import Box from '@mui/material/Box';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import { PermissionsEnums } from "../../data/permission";
import { AppDateFormat } from "../../utils/dateUtils";
import { calculateSumWithPrecision } from "../../utils/numericUtils";
import { Fullname } from "../Common/Fullname";
import { ModuleComponent } from "../Common/Permission/ModuleComponent";
import { Payrate } from "../ui/Payrate";
import PleaseWait from "../Common/Loder/PleaseWait";
import NoRecordsFound from "../Common/Table/NoRecordsFound";
import GenericPagination from "../Common/Table/GenericPagination";
import { useState } from "react";

const useStyles = makeStyles({
  tableCell: {
    width:"12.5%",
    flex: 1, // Make the columns equal size
  },
 });

export const AdminEarningTable = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();

    // Pagination state
const [rowsPerPage] = useState(props?.filterState?.pageSize);

const handleChangePage = (event, newPage) => {    
  props?.handlePaginationIndex(newPage);
};


  return (
    <>
    <Grid container>
      <Grid item xs={12}>
        <Box
          sx={{
            marginTop: [-2, -5, -7.5] // -2 for mobile, -5 for tablet, and -7.5 for desktop
          }}
        >
          <TableContainer className="adminearning-table-compnent" >
            <GenericPagination
              count={props?.totalRecords}
              rowsPerPage={rowsPerPage}
              page={props?.filterState?.pageIndex}
              onPageChange={handleChangePage}
            /> 
            <Table stickyHeader aria-label="sticky table" size="small" className="AppTable AppTableList">
              <TableHead className="AppTableListHeader">
                <TableRow>
                  <TableCell  align="left">Recruiter</TableCell>
                  <TableCell  align="left">Candidate</TableCell>
                  <TableCell  align="left">Client</TableCell>
                  <TableCell align="left">Project</TableCell>
                  <TableCell align="left">Commission</TableCell>
                  <TableCell align="left">Payment Terms</TableCell>
                  <TableCell align="left">Commission Due Date</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {props?.data.map((row,index) => {
                  return <TableRow key={ row?.projectId ?? index} className={`AppTableList-table-row ${row?.isAdjustmentEntry ? "adjustment-row" : ""}`}>
                    <TableCell className={classes.tableCell}>{<Fullname fullName={row?.recruiterFullName}/>}</TableCell>
                    <TableCell className={classes.tableCell}>{<Fullname fullName={row?.candidateName}/>}</TableCell>
                    <TableCell className={classes.tableCell}>{row?.projectClientName}</TableCell>
                    <TableCell className={classes.tableCell}>
                      <span className="projectId">{row?.projectId} </span>
                      <span className="projectName">{row?.projectName} </span>
                    </TableCell>                  
                    <TableCell className={classes.tableCell}>
                      <Payrate fontSize="0.875rem" packageType={""} packageValue={calculateSumWithPrecision(row?.totalCommission || 0, row?.oneTimeCommission || 0)} currency={row?.currencyType}/>
                    </TableCell>
                    <TableCell>{row?.paymentTerm}</TableCell>
                    <TableCell className={classes.tableCell}>
                      <span className="commissionDueDateText">
                        {row?.commissionDueDate ? AppDateFormat(row?.commissionDueDate,"MMMM DD, YYYY") : "N/A"}
                      </span>
                    </TableCell>
                    <TableCell className={classes.tableCell}  >
                      <div style={{display:"flex", alignItems:"center"}}>
                        <VisibilityOutlinedIcon
                              data-testid={"viewEarningIcon"}
                              onClick={() => {
                                navigate(`/admin/ApproveEarning/${row?.submissionId}`, {state:{filterState:props?.filterState,selectedRow:row,mode:"viewMode",totalCommission:row?.totalCommission}})
                              }}
                              color="primary"
                              className="pointer custom-prview-dwn-icon"
                              sx={{verticalAlign: "bottom"}}
                            />
                      <ModuleComponent moduleId={PermissionsEnums.Earnings_Approve}>
                        { !row?.isApproved ? 
                          <Button size="small" variant="contained" style={{ marginLeft: "10px" }}
                            onClick={()=>{navigate(`/admin/ApproveEarning/${row?.submissionId}`, {state:{filterState:props?.filterState,selectedRow:row,mode:"ApproveMode",totalCommission:row?.totalCommission}})}}
                          >
                            Approve
                          </Button>
                          : null
                        }
                      </ModuleComponent>
                      </div>
                    </TableCell>
                </TableRow>})}
              </TableBody>
            </Table>
          </TableContainer>
          {props.loading && <PleaseWait />}
          {!props.loading && props?.data?.length === 0 && <NoRecordsFound /> }
          </Box>
      </Grid>
    </Grid>
    </>
  );
};
