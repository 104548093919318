import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import StatisticsCard from "./Cards/StatisticsCard";
import DashboardIcon1 from "./../../assets/images/dashboard-icon-1.svg";
import DashboardIcon2 from "./../../assets/images/dashboard-icon-2.svg";
import DashboardIcon3 from "./../../assets/images/dashboard-icon-3.svg";
import DashboardIcon4 from "./../../assets/images/dashboard-icon-4.svg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TotalResponses from "./Cards/TotalResponses";
import formatAmount from "../../utils/formatAmount";
import { useNavigate } from "react-router-dom";
import TopProfiles from "./Cards/TopProfiles";
import { UserAuth } from "../../models/interfaces";
import { StatusEnumList } from "../Common/SubmissionStatusEnumList";
import { setRecruiterSubmissionState as setActiveRecruiterSubmissionState } from "../../features/recruiterSubmission/activeRecruiterSubmissionSlice";
import { setRecruiterSubmissionState as setRejectedRecruiterSubmissionState } from "../../features/recruiterSubmission/rejectedRecruiterSubmissionSlice";
import { getNevLink } from "../../utils/getFileFromUrl";
import BannerText from "../Common/BannerText";
import Typography from "@mui/material/Typography";
import { ClientDashboardActivity, GetClientGraphicalDashboard } from "../../Services/clientService";
import {
  setFilterType,
  setStatusIds,
} from "../../features/submission/submissionSlice";
import { handleCheckboxFilter } from "../../features/Jobs/clientJobSlice";
import { AppState } from "../../store/AppState";
import Highlights from "./Cards/Highlights";
import TopSubmissions from "./Cards/TopSubmissions";
import { DashboardData } from "../../models/client/Dashboard/ClientDashboardModel";
import RelevantJobs from "./Cards/RelevantJobs";
import SatisfactionTrend from "./Cards/SatisfactionTrend";
import PerformanceByScore from "./Cards/TechStatsCard";
import ScoreBySkills from "./Cards/ScoreBySkills";
import ActivityScrore from "./Cards/ActivityScore";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

export default function ClientDashboard() {

  //Initialization 
  const dispatch = useDispatch();

  let activeSubmissionState = useSelector(
    (state:AppState) => state.activeRecruiterSubmissionState
  );
  let rejectedSubmissionState = useSelector(
    (state:AppState) => state.rejectedRecruiterSubmissionState
  );

  const [clientdashboards, setClientDashboard]: any = useState([]);
  const [clientGraphicalDashboardStat, setClientGraphicalDashboardStat] = useState<DashboardData>();

  const handleCardClick = (statusIds: any, submissionType = "") => {
    if (submissionType === "active") {
      dispatch(
        setActiveRecruiterSubmissionState({
          ...activeSubmissionState,
          pageIndex: 1,
          statusIds: statusIds,
          durationFilter: { duration: "0", durationType: "All" },
          candidateGuids: [],
          recruiterFilter: [],
          selectedRecruiter: undefined,
          searchKeywords: "",
          statusClearAll: true,
        })
      );
      dispatch(setStatusIds({ statusIds: statusIds, filterType: "filter" }));
      dispatch(setFilterType("filter"));
    } else {
      dispatch(
        setRejectedRecruiterSubmissionState({
          ...rejectedSubmissionState,
          pageIndex: 1,
          statusIds: statusIds,
          durationFilter: { duration: "0", durationType: "All" },
          candidateGuids: [],
          recruiterFilter: [],
          selectedRecruiter: undefined,
          searchKeywords: "",
          statusClearAll: true,
        })
      );
    }
    dispatch(setFilterType("pendingFilter"));
    dispatch(
      setStatusIds({ statusIds: statusIds, filterType: "pendingFilter" })
    );

    navigate(getNevLink(`/submission/${submissionType}`));
  };

  
  let navigate = useNavigate();

  const ClientDashboardActivitytest = () => {
    ClientDashboardActivity().then((response: any) => {
      setClientDashboard(response?.data?.entity);
    });
  };

  const ClientGraphicalDashboardStatistics = () => {

    GetClientGraphicalDashboard().then((response: any) => {
      setClientGraphicalDashboardStat(response?.data?.entity);
    });
  };

  useEffect(() => {
    ClientDashboardActivitytest();
    ClientGraphicalDashboardStatistics();
  }, []);

  return (
    <div>
      <BannerText
        bannerText="Snapshot of your activities"
        bannerType="secondary"
      />

      <Item elevation={0}>
        <Typography
          variant="h2"
          color="inherit"
          component="span"
          className="abel abelh3 primary-color-text boldface"
          my={3}
        >
          Your Statistics
        </Typography>

        <Box>
          <Grid
            container
            spacing={1}
            sx={{ justifyContent: "left", textAlign: "left" }}
            className="dashboard-sml-cards"
          >
            <Grid item xs={12} md={3}>
              <Item
                elevation={0}
                sx={{ textAlign: "left" }}
                style={{ cursor: "pointer" }}
                data-testid="client"
                onClick={() => {
                  dispatch(
                    handleCheckboxFilter({
                      filterType: "disabled",
                      filterValue: false,
                    })
                  );
                  navigate(getNevLink("/jobs/all"));
                }}
              >
                <StatisticsCard
                  bg="bg1"
                  yourStatText="Active Jobs"
                  bigNo={formatAmount(
                    clientdashboards?.jobActivitiesCount?.activeJobsCount
                  )}
                  sinceText="Active"
                  icon={DashboardIcon1}
                />
              </Item>
            </Grid>
            <Grid item xs={12} md={3}>
              <Item
                elevation={0}
                sx={{ textAlign: "left" }}
                style={{ cursor: "pointer" }}
                data-testid="pointer"
                onClick={() => {
                  dispatch(
                    handleCheckboxFilter({
                      filterType: "disabled",
                      filterValue: true,
                    })
                  );
                  navigate(getNevLink("/jobs/all"));
                }}
              >
                <StatisticsCard
                  bg="bg2"
                  yourStatText="Not Active Jobs"
                  bigNo={formatAmount(
                    clientdashboards?.jobActivitiesCount?.notActiveJobsCount
                  )}
                  sinceText="Active"
                  icon={DashboardIcon2}
                />
              </Item>
            </Grid>
            <Grid item xs={12} md={3}>
              <Item
                elevation={0}
                sx={{ textAlign: "left" }}
                style={{ cursor: "pointer" }}
                data-testid="jobs"
                onClick={() => {
                  navigate(getNevLink("/jobs/pending"));
                }}
              >
                <StatisticsCard
                  bg="bg3"
                  yourStatText="Pending Jobs"
                  bigNo={formatAmount(
                    clientdashboards?.jobActivitiesCount?.pendingJobsCount
                  )}
                  sinceText="Active"
                  icon={DashboardIcon3}
                />
              </Item>
            </Grid>
            <Grid item xs={12} md={3}>
              <Item
                elevation={0}
                sx={{ textAlign: "left" }}
                style={{ cursor: "pointer" }}
                data-testid="newsubmission"
                onClick={() => {
                  // need to set empty to overwrite retained info
                  handleCardClick([], "active");
                }}
              >
                <StatisticsCard
                  bg="bg4"
                  yourStatText="Submissions "
                  bigNo={formatAmount(
                    clientdashboards?.submissionActivitiesCount
                      ?.activeSubmissionsCount
                  )}
                  sinceText="Active"
                  icon={DashboardIcon4}
                />
              </Item>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ marginTop: "10px" }}>
          <Grid
            container
            spacing={1}
            sx={{ justifyContent: "left", textAlign: "left" }}
            className="dashboard-sml-cards"
          >
            <Grid  item xs={12} md={3}>
              <Item
                elevation={0}
                sx={{ textAlign: "left" }}
                style={{ cursor: "pointer" }}
                data-testid="active"
                onClick={() => {
                  handleCardClick(
                    StatusEnumList?.interview_schedule
                      ?.matchingEventsInterviewIds,
                    "active"
                  );
                }}
              >
                <StatisticsCard
                  bg="bg1"
                  yourStatText="Interview scheduled"
                  bigNo={formatAmount(
                    clientdashboards?.submissionActivitiesCount
                      ?.interviewScheduledCount
                  )}
                  sinceText="Active"
                  icon={DashboardIcon1}
                />
              </Item>
            </Grid>
            <Grid item xs={12} md={3}>
              <Item
                elevation={0}
                sx={{ textAlign: "left" }}
                style={{ cursor: "pointer" }}
                data-testid="candidatehired"
                onClick={() => {
                  handleCardClick(
                    StatusEnumList?.candidate_hired?.matchingEventsOfferIds,
                    "active"
                  );
                }}
              >
                <StatisticsCard
                  bg="bg2"
                  yourStatText="Offers "
                  bigNo={formatAmount(
                    clientdashboards?.submissionActivitiesCount?.offersCount
                  )}
                  sinceText="Active"
                  icon={DashboardIcon2}
                />
              </Item>
            </Grid>
            <Grid item xs={12} md={3}>
              <Item
                elevation={0}
                sx={{ textAlign: "left" }}
                style={{ cursor: "pointer" }}
                data-testid="waitingforoffer"
                onClick={() => {
                  handleCardClick(
                    [StatusEnumList?.waiting_for_offer?.id],
                    "active"
                  );
                }}
              >
                <StatisticsCard
                  bg="bg3"
                  yourStatText="Candidates Selected"
                  bigNo={formatAmount(
                    clientdashboards?.submissionActivitiesCount
                      ?.interviewsAcceptanceCount
                  )}
                  sinceText="Active"
                  icon={DashboardIcon3}
                />
              </Item>
            </Grid>
            <Grid item xs={12} md={3}>
              <Item
                elevation={0}
                sx={{ textAlign: "left" }}
                style={{ cursor: "pointer" }}
                data-testid="interview"
                onClick={() => {
                  handleCardClick(
                    [StatusEnumList?.candidate_rejected_in_interview?.id],
                    "archived"
                  );
                }}
              >
                <StatisticsCard
                  bg="bg4"
                  yourStatText="Interview Rejections"
                  bigNo={formatAmount(
                    clientdashboards?.submissionActivitiesCount
                      ?.interviewRejectionsCount
                  )}
                  sinceText="Active"
                  icon={DashboardIcon4}
                />
              </Item>
            </Grid>
          </Grid>
        </Box>

        {/* Row 1 after statistics  */}

        <Box
          sx={{
            marginTop: "20px",
          }}
        >
          <Grid
            container
            spacing={3}
            sx={{ justifyContent: "space-around", paddingTop: "5px" }}
            className="dashboard-sml-cards2"
          >
            {/* Total Responses */}
            <Grid item xs={12} md={4} sx={{ padding: 0 }}>
              <Item
                elevation={0}
                sx={{ textAlign: "left", padding: 0, height: "240px" }}
                className="dashboard-sml-card2 bg1"
              >
                <TotalResponses data={clientGraphicalDashboardStat?.totalResponses ?? {}} />
              </Item>
            </Grid>
            {/* Highlights */}
            <Grid item xs={12} md={4} sx={{ padding: 0 }}>
              <Item
                elevation={0}
                sx={{ textAlign: "left", padding: 0, height: "240px" }}
                className="dashboard-sml-card2 bg1"
              >
                <Highlights data={clientGraphicalDashboardStat?.highlights || {} } />
              </Item>
            </Grid>
            {/* Top Submissions */}
            <Grid item xs={12} md={4} sx={{ padding: 0 }}>
              <Item
                elevation={0}
                sx={{ textAlign: "left", padding: 0, height: "240px" }}
                className="dashboard-sml-card2 bg1"
              >
                <TopSubmissions data={clientGraphicalDashboardStat?.topSubmissions ?? {} } />
              </Item>
            </Grid>

              {/* Performance score */}
              <Grid item xs={12} md={4} sx={{ padding: 0 }}>
                <Item
                  elevation={0}
                  sx={{ textAlign: "left", padding: 0, height: "320px" }}
                  className="dashboard-sml-card2 bg1"
                >
                  {
                    clientGraphicalDashboardStat?.performance!== undefined && 
                    <PerformanceByScore data={clientGraphicalDashboardStat?.performance ?? {}} />
                  }  
                </Item>
              </Grid>

                {/* Satisfaction Trend */}
                <Grid item xs={12} md={4} sx={{ padding: 0 }}>
                    <Item
                      elevation={0}
                      sx={{ textAlign: "left", padding: 0, height: "320px" }}
                      className="dashboard-sml-card2 bg1"
                    >
                      <SatisfactionTrend  data={clientGraphicalDashboardStat?.satisfactionTrendScore ?? {}}/>
                    </Item>
                  </Grid>
                {/* Relevant Job */}
                <Grid item xs={12} md={4} sx={{ padding: 0 }}>
                    <Item
                      elevation={0}
                      sx={{ textAlign: "left", padding: 0, height: "320px" }}
                      className="dashboard-sml-card2 bg1"
                    >
                     {
                        (clientGraphicalDashboardStat?.relevantJobs && clientGraphicalDashboardStat?.relevantJobs?.length > 0) &&
                        <RelevantJobs data={clientGraphicalDashboardStat?.relevantJobs ?? {}} />
                     } 
                    </Item>
                  </Grid>

                  {/* Top Profiles */}
                  <Grid item xs={12} md={4} sx={{ padding: 0 }}>
                      <Item
                        elevation={0}
                        sx={{ textAlign: "left", padding: 0, height: "320px" }}
                        className="dashboard-sml-card2 bg1"
                      >
                        <TopProfiles 
                          data={clientGraphicalDashboardStat?.topProfiles ?? {} } 
                          userType={UserAuth?.Client} 
                          />
                      </Item>
                    </Grid>

                   {/* Score by skill */}
                <Grid item xs={12} md={4} sx={{ padding: 0 }}>
                    <Item
                      elevation={0}
                      sx={{ textAlign: "left", padding: 0, height: "320px" }}
                      className="dashboard-sml-card2 bg1"
                    >
                     {
                        clientGraphicalDashboardStat?.scoreBySkills &&  
                        <ScoreBySkills data={clientGraphicalDashboardStat?.scoreBySkills ?? {}} userType={UserAuth?.Client}/>
                      }
                    </Item>
                  </Grid>
                   {/* Activity Scores */}
                <Grid item xs={12} md={4} sx={{ padding: 0 }}>
                    <Item
                      elevation={0}
                      sx={{ textAlign: "left", padding: 0, height: "320px" }}
                      className="dashboard-sml-card2 bg1"
                    >
                      <ActivityScrore data={clientGraphicalDashboardStat?.activityScores ?? {}} />
                    </Item>
                  </Grid>
          </Grid>
        </Box>
      </Item>
    </div>
  );
}
