import React, { useEffect } from "react";

import { Autocomplete, Chip, CircularProgress, TextField } from "@mui/material";
import useApi from "../../hooks/useApi";
import { GetAllSkills } from "../../Services/recruiterService";
import toTitles from "../../utils/toTitles";

export const SkillAutocomplete = (props) => {
  const [searchSkill, setSearchSkill] = React.useState("");

  const onSkillInputChange = (e, value) => {
    setSearchSkill(value);
  };

  let skills = props.skills; //formik.values.skills

  const allSkills = useApi(() => GetAllSkills(searchSkill, 20));
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      allSkills.request();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchSkill]);
  return (
    <Autocomplete
      multiple
      noOptionsText={allSkills?.loading ? "Loading..." : "No options"}
      id="size-small-standard-multiskills"
      getOptionDisabled={(option) =>
        skills?.some((e) => e.skill === option.skill) || skills?.length === 16
          ? true
          : false
      }
      size="medium"
      onChange={props.onChange}
      onInputChange={onSkillInputChange}
      readOnly={props.readOnly}
      options={
        allSkills?.data?.entityList?.map(({ id: id, skillName: skill }) => ({
          id,
          skill,
        })) || []
      }
      value={skills}
      getOptionLabel={(option) => toTitles(option.skill)}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            color="primary"
            size="medium"
            label={option.skill}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label=""
          placeholder={!props.readOnly && "Search for more skills here"}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {allSkills?.loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {!props.readOnly && params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
