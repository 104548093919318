import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import getLoggedInUserRole from "../../Shared/utils/getLoggedInUserRole";
import getUserDetails from "../../Shared/utils/getUserDetails";
import { UserAuth } from "../../models/interfaces";
import { AppPaginationPerPage } from "../../utils/AppConstant";

const currentMonthFirstDay = moment.utc().startOf('month').format('YYYY-MM-DD');
const currentMonthLastDay   = moment.utc().endOf('month').format('YYYY-MM-DD');
let userRole = getLoggedInUserRole();

let initialState = {
    pageIndex: 1,
    pageSize: AppPaginationPerPage,
    sortColumn: "",
    sortOrder: "Ascending",
    isSorted: true,
    totalRecord: 0,
    recruiterGuid: userRole === UserAuth.Recruiter ? getUserDetails()?.id : "",
    searchKeyword: "",
    submissionId: "",
    fromDate:currentMonthFirstDay,
    toDate: currentMonthLastDay,
    ...(userRole === UserAuth.Recruiter) && {earningsStatus: "Approved"},
    showAdjustments: true,
    projectId:"",
    currencies : [],
    contractType:["DirectContract","W2_Employee", "OneTimeCommission"]
}
export const ContractEarningFilterStateSlice = createSlice({
  name: "ContractEarningFilterStateSlice",
  initialState,
  reducers: {
    setEarningsState: (state,action) => {
        return action.payload;
    },
    resetEarningsState: (state) => initialState
},
  
});

// Action creators are generated for each case reducer function
export const {setEarningsState,resetEarningsState} =
  ContractEarningFilterStateSlice.actions;

export default ContractEarningFilterStateSlice.reducer;