import { useFormik } from 'formik';
import React from 'react'
import { BillingInfoType, UserAuth } from '../../../models/interfaces';
import { PayoneerPaymentDetail } from '../../../models/PaymentDetails';
import { PayoneerForm } from './PayoneerForm';
import payoneerSchemaAdmin from './schema/payoneerAdminForm';

interface Props {
    editMode : any,
    billingDetail: {
      userId: string;
      email: string;
      name: string;
      billingInfoType : string;
    };   
    onChange : any,
    values : any,
    PayoneerFormRef:any;   
  }
  export const AdminPayoneerBilling: React.FC<Props> = ({
    editMode,
    billingDetail,   
    onChange,
    values,
    PayoneerFormRef
  }) => {
    let initialState: PayoneerPaymentDetail = {
      userId: billingDetail?.userId!=null? billingDetail.userId : '',
      email: "",
      billingInfoType: BillingInfoType[1],
      isUpdateDetails : false
    };
    const [isChecked, setIsChecked] = React.useState(false)
    const pageStatus = {
      isSuccess: false,
      error: "",
      loading: false,
    };
   
    const formik = useFormik({
      initialValues: initialState,
      enableReinitialize: true,
      validationSchema: payoneerSchemaAdmin,
      onSubmit: (values, actions) => {
        
        
      },
    });

    // Attach the Formik instance to the ref
    PayoneerFormRef.current = formik;
    
    React.useEffect(() => {
      formik.setValues(values)
      
   
 }, [values]);
 const changeHandle = (e:any)=>
 {
  formik.setFieldValue("email",e?.target?.value)
  onChange(e,"payoneerBilling")
 }

 const _handleOnBlur = (e:any)=>
  {
   formik.setFieldTouched("email",e?.target?.value);
  }
  return (
    <PayoneerForm 
      onChange={changeHandle} 
      handleOnBlur={_handleOnBlur}
      viewMode={UserAuth.Admin} 
      editMode={editMode} 
      billingDetail={billingDetail} 
      formik={formik} 
      pageStatus={pageStatus} 
      formState={initialState} 
      setIsChecked={setIsChecked} 
      isAlreadySet={false} 
      isChecked={isChecked}
    />

  )
}
