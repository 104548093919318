import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";

interface EmailVerificationStatusProps {
  expireMessage:string;
}

const EmailVerificationStatus: React.FC<EmailVerificationStatusProps> = ({expireMessage}) => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={8} p={3}>
        <Alert severity="error">
          {expireMessage}
        </Alert>
      </Grid>
    </Grid>
  );
};

export default EmailVerificationStatus;
