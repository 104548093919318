import React, { useState } from "react";
import { FormControl, FormLabel, TextField, Typography } from "@mui/material";

interface OptionModel {
  id: string;
  optionText: string;
  instructionText: string;
  specificationText?: string;
  specificationRequired?: boolean;
}

export interface QuestionModel {
  id: string;
  question: string;
  instructionText: string;
  type: number;
  options: OptionModel[];
}

interface TextInputFieldProps {
  name: string;
  isRequired: boolean;
  onHandleChange: (value: string) => void;
  onBlur: (id: string | number | undefined) => void;
  questionDetails: QuestionModel;
  error?: boolean;
  helperText?: string;
  selectedValue:string;
}

const TextInputField: React.FC<TextInputFieldProps> = ({
  name,
  isRequired,
  onHandleChange,
  onBlur,
  questionDetails,
  error = false,
  helperText = "",
  selectedValue,
}) => {
  const [text, setText] = useState(selectedValue);
  const maxLength = 250;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onHandleChange(event.target.value);
    setText(event.target.value);
  };

  return (
    <>
      <FormControl 
        component="fieldset" 
        key={questionDetails.id} 
        required={isRequired}
        sx={{marginBottom:2}}
        fullWidth
        >
        <FormLabel component="legend">{questionDetails.question}</FormLabel>
        <TextField
          name={name}
          onChange={handleChange}
          onBlur={() => onBlur(questionDetails.id)}
          label=""
          error={error}
          helperText={helperText}
          placeholder=''
          variant='standard'
          multiline
          minRows={1}
          defaultValue={selectedValue}
          fullWidth
        />
        <Typography
          variant="caption"
          sx={{ textAlign: "right", display: "block" , fontStyle:"italic"}}
        >
          {`${text.length}/${maxLength}`}
        </Typography>
      </FormControl>
    </>
  );
};

export default TextInputField;
