import React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import { Link } from "react-router-dom";
import { SubmissionsMenuIcon } from "../Icons/SubmissionsMenuIcon";
import { useDispatch, useSelector } from "react-redux";

import { Tooltip } from "@material-ui/core";
import { ModuleComponent } from "../Permission/ModuleComponent";
import { PermissionsEnums } from "../../../data/permission";
import { getNevLink } from "../../../utils/getFileFromUrl";
import { setExpandedCollapseLeftMenuIndex, setSelectedIndex } from "../../../features/Layout/NavigationSlice";
import { ternary } from "../../../utils/complexityUtils";

interface NavigationItem {
  id: number;
  label: string;
  link: string;
  sub: SubMenuItem[];
  name: string;
  icon: any;
  permissionId: PermissionsEnums; // Change the type if needed
}

interface SubMenuItem {
  id: number;
  name: string;
  label: string;
  link: string;
  permissionId: string;
  icon: any; 
}

interface LeftNavProps {
  items: NavigationItem[];
  userRole: string;
}
export const LeftNavigation: React.FC<LeftNavProps> = ({ items }) => {
  let currentLocation = window.location.pathname.toLowerCase();
  const NavigationSlice = useSelector((state: any) => state.navigationState);
  const dispatch = useDispatch();

  const handleListItemClick = (event: any, index: any) => {
    dispatch(setSelectedIndex(index));
  };

// To get submenu list with menuMenu Id and submenu Pathname
const submenuArray: { mainMenuId: number, pathname: string }[] = [];

items.forEach((menuItem:NavigationItem) => {
    if (menuItem.sub.length > 0) {
        menuItem.sub.forEach((subItem:SubMenuItem) => {
            submenuArray.push({
                mainMenuId: menuItem.id,
                pathname: subItem.link
            });
        });
    }
});

  function findIdByPathname(pathname:string) {
    for (const item of submenuArray) {
        if (getNevLink(item.pathname) === pathname) {
            return item.mainMenuId;
        }
    }
    // Return null if the pathname is not found
    return null;
}

  function handleExpand(itemId: number) {
    if (NavigationSlice?.expandedCollapseMenuLeftIndex === itemId) {
      dispatch(setExpandedCollapseLeftMenuIndex(0));
    }
    else {
      dispatch(setExpandedCollapseLeftMenuIndex(itemId));
    }
  }

  return (
    <div
      className={` ${
        !NavigationSlice.isLeftMenuExpanded ? "LeftNavListWrapper-shrink" : ""
      } LeftNavListWrapper`}
    >
      <List
        sx={{
          width: "100%",
          maxWidth: 360,
          bgcolor: "background.paper",
          paddingTop: "3px",
        }}
        component="nav"
        style={{ backgroundColor: "rgb(69 64 219)" }}
        aria-labelledby="Main Navigation"
        className="main-menu-left"
      >
        {items.map((item) => {
          if (item.sub.length == 0) {
            return (
              <>
                {" "}
                <ModuleComponent moduleId={item.permissionId}>
                  {" "}
                  <ListItemButton
                    data-testid={`${item.name}-nav`}
                    className={ternary(
                      currentLocation == getNevLink(item.link).toLowerCase(),
                      "leftnav-active",
                      " "
                    )}
                    selected={
                      currentLocation == getNevLink(item.link).toLowerCase()
                    }
                    onClick={(event) => {
                      handleListItemClick(event, item.id);
                      dispatch(setExpandedCollapseLeftMenuIndex(0))
                    }}
                    component={Link}
                    to={getNevLink(item.link)}
                  >
                    <ListItemIcon>
                      <Tooltip title={item.label} placement="right-start">
                        {item.icon}
                      </Tooltip>
                    </ListItemIcon>
                    <ListItemText
                      sx={{ opacity: "100%" }}
                      primary={ternary(NavigationSlice.isLeftMenuExpanded, item.label, "")}
                    />
                  </ListItemButton>
                </ModuleComponent>
              </>
            );
          } else {
            return (
              <>
                {" "}
                <ModuleComponent moduleId={item.permissionId}>
                  <ListItemButton
                    data-testid={`${item.name}-nav`}
                    selected={NavigationSlice.selectedItem === item.id}
                    onClick={() => handleExpand(item.id) }
                  >
                    <ListItemIcon>
                      <Tooltip title={item.label} placement="right-start">
                        <SubmissionsMenuIcon className="icon-color-default" />
                      </Tooltip>
                    </ListItemIcon>
                    <ListItemText
                      sx={{ opacity: "100%" }}
                      primary={ternary(
                        NavigationSlice.isLeftMenuExpanded,
                        item.label,
                        ""
                      )}
                    />
                    {NavigationSlice?.expandedCollapseMenuLeftIndex === item.id ? (
                      <ExpandLess style={{ color: "#fff" }} />
                    ) : (
                      <ExpandMore style={{ color: "#fff" }} />
                    )}
                  </ListItemButton>
                </ModuleComponent>
                <Collapse
                  in={
                    (
                      (currentLocation && findIdByPathname(currentLocation)) === item.id)
                    || NavigationSlice?.expandedCollapseMenuLeftIndex === item.id
                  }
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="span" disablePadding>
                    {item.sub.map((subItem,index:number) => {
                      return (
                        <ModuleComponent moduleId={subItem.permissionId} key={subItem?.id ?? index}>
                          {" "}
                          <ListItemButton
                            sx={{ pl: 4 }}
                            className={ternary(
                              currentLocation == getNevLink(subItem.link).toLowerCase(),
                              "leftnav-active",
                              " "
                            )}
                            selected={
                              currentLocation ==
                              getNevLink(subItem.link).toLowerCase()
                            }
                            onClick={(event) => {
                              handleListItemClick(event, subItem.id);
                              dispatch(setExpandedCollapseLeftMenuIndex(item.id))
                            }}
                            component={Link}
                            to={getNevLink(subItem.link)}
                          >
                            <ListItemIcon>
                              <Tooltip
                                title={subItem.label}
                                placement="right-start"
                              >
                                {subItem.icon}
                              </Tooltip>
                            </ListItemIcon>
                            <ListItemText
                              sx={{
                                opacity: "100%",
                                wordWrap: "normal",
                                whiteSpace: "pre-wrap",
                              }}
                              primary={ternary(
                                NavigationSlice.isLeftMenuExpanded,
                                subItem.label,
                                ""
                              )}
                            />
                          </ListItemButton>{" "}
                        </ModuleComponent>
                      );
                    })}
                  </List>
                </Collapse>
              </>
            );
          }
        })}
      </List>
    </div>
  );
};
