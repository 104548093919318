import { FormControl, Input, InputLabel } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Alert, CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { FormikHelpers, useFormik } from "formik";
import React from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { AuthenticateRecaptcha } from "../../Services/googleRecaptchaService";
import { RecruiterStatusNumber } from "../../Services/recruiterService";
import {
  AuthenticateToken,
  InitiateReset,
  InitiateResetEmailModel,
  InitiateResetModel,
  ResetPasswordVerifyEmail
} from "../../Services/resetPasswordService";
import getLoggedInUserPermissions from "../../Shared/utils/getLoggedInUserPermission";
import { passWordRegex } from "../../constants/regex";
import { getExpiringSavedJobs } from "../../features/dashboard/recruiterDashboardSlice";
import { loadPermissions } from "../../features/permission/userPermissionSlice";
import { getRecruiterData } from "../../features/recruiterSlice";
import { setLoggedInUseDetails } from "../../features/userDetails/loggedInUserDetailsSlice";
import { LoginType } from "../../models/interfaces";
import { getNevLink } from "../../utils/getFileFromUrl";
import setAuthToken from "../../utils/setAuthToken";
import { ErrorMessage } from "../ui/ErrorMessage";
import axios from "./../../Services/CommonHttpRequest";
import DividerLines from "./../../assets/images/divider.svg";
import GoogleButton from "./GoogleButton";
import LinkedInButton from "./LinkedinButton";
import { LoginComponentMode } from "./Login";
import { PasswordResetSuccess } from "./ResetPassword/PasswordResetSuccess";
import { ResetPassword } from "./ResetPassword/ResetPassword";
import { ResetPasswordConfirmation } from "./ResetPassword/ResetPasswordConfirmation";
import { AxiosResponse } from "axios";

const SigninForm: React.FC<any> = ({ formType, redirectUrl, setMode }) => {
  const dispatch = useDispatch<any>();

  const navigate = useNavigate();
  const location = useLocation();
  const [ShowHideLogin, setShowHideLogin] = React.useState(true);
  const [showHideForgotPassword, setShowHideForgotPassword] = React.useState(false);
  const [ShowHideForgot, setShowHideForgot] = React.useState(false);
  const [ShowHideForgotSuccess, setShowHideForgotSuccess] =
    React.useState(false);
  const [ShowHideReset, setShowHideReset] = React.useState(false);
  const [ShowHideResetSuccess, setShowHideResetSuccess] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const recaptchaAction = "signin";
  const [resetPasswordError, setResetPasswordError] = React.useState("");

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    checkForResetParam();
  }, []);
  const checkForResetParam = () => {
    let resetPassword = searchParams.get("resetPassword");
    let emailtoReset = searchParams.get("email");
    let accessToken = searchParams.get("accessToken");
    if (resetPassword == "true" && emailtoReset != null) {
      authenticateToken(accessToken);
      setShowHideLogin(false);
      setShowHideReset(true);
      setMode(LoginComponentMode.Reset);
      formikReset.setFieldValue("userName", emailtoReset);
      formikReset.setFieldValue("token", accessToken);
    }
  };
  const initialStateLogin = {
    username: "",
    password: "",
    loginType: LoginType.Email_UserID,
    returnUrl: "",
  };
  const LoginSchema = Yup.object({
    username: Yup.string()
      .email("Entered email address is invalid")
      .required("Please enter a valid email address"),
    password: Yup.string().required("Please enter your password"),
    loginType: Yup.number(),
    returnUrl: Yup.string(),
  });
  const formikLogin = useFormik({
    initialValues: initialStateLogin,
    validationSchema: LoginSchema,
    onSubmit: (values, actions) => {
      actions.setSubmitting(true);
      handleReCaptchaVerify(values, actions);
    },
  });
  const handleReCaptchaVerify = React.useCallback(
    async (values, actions) => {
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        return;
      }
      const token = await executeRecaptcha(recaptchaAction);
      AuthenticateRecaptcha({
        reCaptchaToken: token,
        action: recaptchaAction,
      })
        .then((response) => {
          actions.setSubmitting(false);
          if (
            response.data.status == 200 &&
            response.data.entity.score >= 0.5
          ) {
            DoSignin(values, actions);
          } else if (
            response.data.status == 200 &&
            response.data.entity.score <= 0.5
          ) {
            setError(response?.data?.message?.appStatusDescription);
          } else {
            setError("Unexpected error!");
          }
        })
        .catch((err) => {
          setError(err?.message);
          actions.setSubmitting(false);
        });
    },
    [executeRecaptcha]
  );

  function DoSignin(values: any, actions: any) {
    axios
      .post(`${process.env.REACT_APP_IDPROVIDER_API}api/SignIn/Login`, values, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        if (res.data.status === 200) {
          handleLoginResponse(res, actions);
        } else {
          setError(res?.data?.message?.appStatusDescription);
        }
      })
      .catch((err) => {
        setError(err?.message);
        actions.setSubmitting(false);
      });
  }

  function handleLoginResponse(res : AxiosResponse<any, any>, actions : any) {
    let userRole = res?.data?.entity?.loggedInUserRole;
    localStorage.setItem("user", JSON.stringify(res.data.entity));
    //get recruiter

    dispatch(setLoggedInUseDetails(res.data?.entity));
    setAuthToken(res.data.accessToken);

    let userModules = getLoggedInUserPermissions();
    dispatch(loadPermissions({ permissions: userModules }));
    actions.resetForm();
    dispatch(getRecruiterData());

    handleRecruiterStatusNumber(res);

    handleRedirect(userRole);
  }

  function handleRecruiterStatusNumber(res : AxiosResponse<any, any>) {
    if (res?.data?.entity?.loggedInUserRole === "Recruiter") {
      dispatch(getExpiringSavedJobs(res.data?.entity?.userId));

      RecruiterStatusNumber({ id: res.data.entity.userId })
        .then(
          (res: any) => {
            if (res)
              localStorage.setItem(
                "loginStatusNumber",
                res.data.entity.loginStatusNumber
              ); //if count is greater than 1 profile is approved and is 2nd time login, so don't show message
          },
          (error: any) => {
            console.log("Error in RecruiterStatusNumber");
          }
        );
    }
  }

  function handleRedirect(userRole : any) {
    if (formType === "secondary") {
      if (userRole == "Client") {
        navigate(getNevLink(redirectUrl));
      } else {
        navigate(redirectUrl);
      }
    } else {
      if (location.state) {
        navigate((location.state as any)?.redirectUrl ?? "/dashboard");
      } else {
        navigate("/dashboard");
      }
    }
  }

  const initialInitiateResetEmail: InitiateResetEmailModel = {
    email: "",
    redirectUrl: `${window.location.origin}/login?resetPassword=true`,
    name: "",
  };
  const InitiateResetEmailSchema = Yup.object({
    email: Yup.string()
      .email("Entered email address is invalid")
      .required("Please enter a valid email address"),
    redirectUrl: Yup.string(),
  });
  const formikInitiateReset = useFormik({
    initialValues: initialInitiateResetEmail,
    validationSchema: InitiateResetEmailSchema,
    onSubmit: (values, actions) => {
      actions.setSubmitting(true);
      verifyAndResetEmail(values, actions);
    },
  });
  const initiateResetValues: InitiateResetModel = {
    userName: "",
    password: "",
    confirmPassword: "",
    token: "",
  };
  const InitiateResetSchema = Yup.object({
    userName: Yup.string()
      .email("Entered email address is invalid")
      .required("Please enter a valid email address"),
    password: Yup.string()
      .required(
        "The password must be 8-15 characters long and should contain at least one or more of: Uppercase characters (A-Z) Lowercase characters (a-z) Digits (0-9) Special characters (~!@#$%^&*_-+=`|(){}[]:;\"'<>,.?/)"
      )
      .matches(
        passWordRegex,
        "Password must be 8 -15 characters long and must contain atleast one or more of digits, special characters,  upper case and lower case letters"
      ),
    confirmPassword: Yup.string()
      .required(
        "Please re-enter the password. Minimum 8 characters are required"
      )
      .oneOf(
        [Yup.ref("password"), null],
        "Password and Confirm password do not match"
      ),
  });
  const formikReset = useFormik({
    initialValues: initiateResetValues,
    validationSchema: InitiateResetSchema,
    onSubmit: (values, actions) => {
      actions.setSubmitting(true);
      initiateReset(values, actions);
    },
  });
  function initiateReset(
    values: InitiateResetModel,
    actions: FormikHelpers<InitiateResetModel>
  ) {
    InitiateReset(values).then((res) => {
      actions.setSubmitting(false);
      if (res.data.status == 200) {
        setShowHideReset(false);
        setShowHideResetSuccess(true);
        searchParams.delete("resetPassword");
        searchParams.delete("email");
        setSearchParams(searchParams);
        formikReset.resetForm();
        setResetPasswordError("");
      } else {
        setResetPasswordError(res.data.message?.appStatusDescription);
      }
    });
  }
  function verifyAndResetEmail(
    values: InitiateResetEmailModel,
    actions: FormikHelpers<InitiateResetEmailModel>
  ) {
    ResetPasswordVerifyEmail(values).then((res) => {
      actions.setSubmitting(false);
      if (res.data?.status == 200) {
        setShowHideForgotSuccess(!ShowHideForgotSuccess);
        setShowHideForgot(!ShowHideForgot);
        // banner remains the forgot banner, so no need to set state
        setResetPasswordError("");
        setShowHideForgotPassword(false);
      } else {
        formikInitiateReset.setErrors({
          email: res.data.message.appStatusDescription,
        });
      }
    });
  }

  const authenticateToken = (token: any) => {
    AuthenticateToken(token)
      .then((res) => {
        if (res.data.status == 200) {
          if (res?.data?.entity?.isExpired) {
            setShowHideReset(false);
            setShowHideForgot(true);
            setMode(LoginComponentMode.Forgot);
            setResetPasswordError("This link has expired");
          }
        } else {
          setResetPasswordError("Unexpected error occurred");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleShowLogin = () => {
    setShowHideLogin(true);
    setMode(LoginComponentMode.Login);
    setShowHideForgot(false);
    setShowHideForgotSuccess(false);
    setShowHideReset(false);
    setShowHideResetSuccess(false);
    setShowHideForgotPassword(false);
  };

  const handleCloseResetPopup = () => {
    navigate("/login");
    setShowHideLogin(true);
    setMode(LoginComponentMode.Login);
    setShowHideForgot(false);
    setShowHideForgotSuccess(false);
    setShowHideReset(false);
    setShowHideResetSuccess(false);
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleAlreadyExist = (errorMessage: string) => {
    setError(errorMessage);
  };
  return (
    <React.Fragment>
      <Box className="SignFormMainWrapper login-banner-right">
        <Box
          style={{ padding: " 20px 30px 0px" }}
          className={
            ShowHideReset || ShowHideResetSuccess
              ? "login-forms login-shadow"
              : "login-forms"
          }
        >
          <Box
            className={` ${ShowHideLogin ? "" : "d-none"}`}
            data-testid="box-signin"
          >
            <Typography
              variant="h2"
              color="inherit"
              component="span"
              className="custom-wlcm-txt"
              sx={{
                fontSize: {
                  xs: "32px !important",
                  sm: "32px !important",
                  md: "32px !important",
                },
                letterSpacing: "normal !important",
              }}
            >
              Welcome to{" "}
              <span style={{ fontWeight: 700, color: "rgba(53, 53, 53, 1)" }}>
                JobRialto
              </span>
            </Typography>
            <Typography
              variant="h2"
              color="inherit"
              component="div"
              className="custom-signin-txt"
            >
              Sign In
            </Typography>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              sx={{
                flexDirection: "row",
                "& .MuiTextField-root": { m: 1 },
              }}
            >
              <FormControl
                variant="standard"
                required
                error={
                  formikLogin.touched.username &&
                  Boolean(formikLogin.errors.username)
                }
                fullWidth
                margin="normal"
              >
                <InputLabel className="custom-email-txt" htmlFor="email">
                  Your email
                </InputLabel>
                <Input
                  name="username"
                  type="email"
                  className="custom-pwd-txt custom-input-bckgrnd-color"
                  onChange={(e) => {
                    formikLogin.handleChange(e);
                    setError("");
                  }}
                  onBlur={formikLogin.handleBlur}
                  value={formikLogin.values.username}
                  inputProps={{ "data-testid": "input-signin-email" }}
                />
                <ErrorMessage
                  errorText={
                    formikLogin.touched.username && formikLogin.errors.username
                  }
                />
              </FormControl>
              <FormControl
                required
                error={
                  formikLogin.touched.password &&
                  Boolean(formikLogin.errors.password)
                }
                variant="standard"
                fullWidth
                margin="normal"
              >
                <InputLabel
                  className="custom-email-txt"
                  htmlFor="standard-adornment-password"
                >
                  Password
                </InputLabel>
                <Input
                  name="password"
                  type={values.showPassword ? "text" : "password"}
                  className="custom-pwd-txt custom-input-bckgrnd-color"
                  onChange={(e) => {
                    formikLogin.handleChange(e);
                    setError("");
                  }}
                  onBlur={formikLogin.handleBlur}
                  value={formikLogin.values.password}
                  autoComplete="no"
                  inputProps={{ "data-testid": "input-signin-password" }}
                  endAdornment={
                    <span
                      onClick={handleClickShowPassword}
                      className="showHideIcon"
                      data-testid="icon-showpassword"
                    >
                      <InputAdornment position="end">
                        <IconButton size="small">
                          {values.showPassword ? (
                            <Visibility style={{ color: "#353535" }} />
                          ) : (
                            <VisibilityOff style={{ color: "#353535" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    </span>
                  }
                />
                <ErrorMessage
                  errorText={
                    formikLogin.touched.password && formikLogin.errors.password
                  }
                />
              </FormControl>
              <br />
              {error !== "" && <Alert severity="error">{error}</Alert>}
              <Box>
                <Link
                  style={{ textDecoration: "none" }}
                  onClick={(e) => {
                    setShowHideForgot(!ShowHideForgot);
                    setMode(LoginComponentMode.Forgot);
                    setShowHideLogin(false); 
                    setShowHideForgotPassword(true);
                  }}
                  data-testid="link-forgotpassword"
                >
                  <span
                    data-testid="setshowhidelogin"
                    className="custom-forgotpwd-txt"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      fontSize: "16px",
                      color: "rgba(53, 53, 53, 1)",
                      cursor: "pointer",
                    }}
                  >
                    Forgot Password?
                  </span>
                </Link>
              </Box>
              <Grid style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    formikLogin.handleSubmit();
                  }}
                  disabled={formikLogin.isSubmitting}
                  endIcon={
                    formikLogin.isSubmitting ? (
                      <CircularProgress size={"16px"} color={"inherit"} />
                    ) : (
                      ""
                    )
                  }
                  variant="contained"
                  className="custom-signin-btn signup-button"
                  fullWidth
                  data-testid="btn-signin"
                >
                  Sign In
                </Button>
              </Grid>
              <Box sx={{ textAlign: "center" }}>
                <img src={DividerLines} alt="" width="100%" />
              </Box>
              <Typography component="span" className="signup-with">
                Sign in using{"   "}
                <LinkedInButton
                  handleAlreadyExist={handleAlreadyExist}
                  redirectUrl={redirectUrl}
                />
                <GoogleButton
                  handleAlreadyExist={handleAlreadyExist}
                  redirectUrl={redirectUrl}
                />
              </Typography>
            </Box>
          </Box>
                    <Box
            className={` ${showHideForgotPassword ? "" : "d-none"}`}
            data-testid="box-resetpassword"
          >
            <Typography
              variant="h2"
              color="inherit"
              component="span"
              className="custom-wlcm-txt"
            >
              Forgot{" "}
              <span style={{ fontWeight: 700, color: "rgba(53, 53, 53, 1)" }}>
                Password?
              </span>
            </Typography>

            <Typography
              variant="body1"
              sx={{ mt: 1.5 }}
              className="custom-forgotpwd-subhead"
            >
              Enter your email address and we’ll email you instructions on how
              to reset your password
            </Typography>
            <Box component="form" noValidate autoComplete="off">
              <FormControl
                variant="standard"
                required
                error={
                  formikInitiateReset.touched.email &&
                  Boolean(formikInitiateReset.errors.email)
                }
                fullWidth
                margin="normal"
              >
                <InputLabel htmlFor="email">Your email</InputLabel>
                <Input
                  name="email"
                  type="email"
                  onChange={formikInitiateReset.handleChange}
                  onBlur={formikInitiateReset.handleBlur}
                  value={formikInitiateReset.values.email}
                  autoComplete="off"
                  inputProps={{ "data-testid": "input-resetemail" }}
                />
                <ErrorMessage
                  errorText={
                    formikInitiateReset.touched.email &&
                    formikInitiateReset.errors.email
                  }
                />
              </FormControl>
              {resetPasswordError !== "" && (
                <Alert severity="error">{resetPasswordError}</Alert>
              )}
              <Grid style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  className="abel custom-forgotpwd-submit-btn signup-button"
                  fullWidth
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    formikInitiateReset.handleSubmit();
                  }}
                  disabled={formikInitiateReset.isSubmitting}
                  endIcon={
                    formikInitiateReset.isSubmitting ? (
                      <CircularProgress size={"16px"} color={"inherit"} />
                    ) : (
                      ""
                    )
                  }
                  data-testid="btn-resetpassword"
                >
                  Submit
                </Button>
              </Grid>

              <Box sx={{ textAlign: "center" }}>
                <img src={DividerLines} alt="" width="100%" />
              </Box>

              <Box
                sx={{
                  paddingTop: "15px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                data-testid="button-handle-show-login"
              >
                <Typography className="custom-alreadysignedup-text">
                  Already signed up?
                </Typography>

                <Link className="custom-lnk-signin" onClick={handleShowLogin}>
                  Sign in
                </Link>
              </Box>
            </Box>
          </Box>
          {/* Verify Email for Reset Password */}
          <Box
            className={` ${ShowHideForgotSuccess ? "" : "d-none"}`}
            sx={{ textAlign: "center", marginTop: "20px" }}
            data-testid="box-resetpassword-success"
          >
            <ResetPasswordConfirmation
              email={formikInitiateReset.values.email}
              onClick={(e: any) => {
                setShowHideForgotSuccess(false);
                setShowHideForgotPassword(true);
              }}
            />
          </Box>

          <Box
            className={` ${ShowHideReset ? "" : "d-none"}`}
            data-testid="box-setnewpassword"
          >
            <ResetPassword
              handleCloseResetPopup={handleCloseResetPopup}
              formikReset={formikReset}
              resetPasswordError={resetPasswordError}
              setResetPasswordError={setResetPasswordError}
            />
          </Box>

          {/* Reset New */}
          <Box
            className={` ${ShowHideResetSuccess ? "" : "d-none"}`}
            data-testid="box-setnewpassword-success"
          >
            <PasswordResetSuccess
              onClick={(e: any) => {
                handleShowLogin();
              }}
            />
          </Box>
        </Box>
        {!(ShowHideReset || ShowHideResetSuccess) && (
          <Box
            className="custom-lnk-signup1 signInFormInnerWrapper-bottomSection"
            sx={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "rgba(240, 243, 255, 1)",
              paddingTop: "15px",
              fontSize: "15px",
            }}
          >
            Don't have an account?{" "}
            <Link
              style={{ textDecoration: "none", cursor: "pointer" }}
              className="custom-lnk-signup"
              onClick={(e) => navigate("/signup")}
            >
              Sign up
            </Link>
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
};
export default SigninForm;

function useEffect(arg0: () => void, arg1: null[]) {
  throw new Error("Function not implemented.");
}
