import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Component } from "react";
import Chart from "react-apexcharts";

class TechStatsRecruiter extends Component {
  constructor(props) {
    super(props);
    let growthInPercentage = props?.data?.performanceScore?.growthInPercentage
    let diffInSubmission = props?.data?.performanceScore?.differenceInSubmissions
    let isPositive = props?.data?.performanceScore?.isGrowthPositive

 
    this.state = {
      isPositive : isPositive,
      series: [growthInPercentage],
      options: {
        
        chart: {
          type: "radialBar",
          offsetY: -20,
          sparkline: {
            enabled: true,
          },
          
        },
        colors: ["#4540DB"],
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            
            track: {
              background: "#e7e7e7",
              strokeWidth: "60%",
              
            },
            dataLabels: {
              show: true,

              value: {
                show: true,
                offsetY: -30,
                fontSize: "14px",
                fontWeight: "bold",

              },
              total: {
                show: true,
                label: growthInPercentage + "%",

                offsetY: -30,
                fontSize: "10px",
                color: "#808080",
                fontWeight: "300",
                formatter: function (w) {
                  return diffInSubmission + "+"
                }
              }
            }
          },
        },
        grid: {
          padding: {
            bottom: 15,
          }
        },
        fill: {
          type: "solid",
          color: "#4540DB",
        },
        
      },
    };
  }
  renderArrowIcon = () => {
    return this.state.isPositive? <ArrowDropUpIcon sx={{
      position: "absolute",
      marginTop: "37px",
      marginLeft: "-35px",
      width: "15px",
      color:'green'
      
    }}/> : <ArrowDropDownIcon sx={{
      position: "absolute",
      marginTop: "37px",
      marginLeft: "-35px",
      width: "15px",
      color:'red'
      
    }}/> 
  }


  render() {
    return (
      <>
      <div style={{
        height: "32px",
        width: "90px",
        backgroundColor: "#fff",
        borderTopLeftRadius: "110px",
        borderTopRightRadius: "110px",
        borderWidth: "10px",
        borderBlockColor: "#fff",
        position: "absolute",
        borderStyle: "solid",
        borderLeftColor: "lightgray",
        borderRightColor:"lightgray",
        marginBottom: "10px"
        
      }}/>
      {this.renderArrowIcon()}
      

      
      <Chart
        options={this.state.options}
        series={this.state.series}
        type="radialBar"
        width="260"
      />
      </>
      
    );
  }
}

export default TechStatsRecruiter;
