import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useState } from "react";
import DragImagePreview from "./../../../../../assets/images/dragImagePreview.svg";

interface LogoUploaderProps {
  handleUploadChange: (file: File | null) => void;
  logoFile: any;
}

const LogoUploader: React.FC<LogoUploaderProps> = ({ handleUploadChange,logoFile }) => {
  const [logo, setLogo] = useState(logoFile?.file ?? null);
  const [errorMessage, setErrorMessage] = useState("");

  const handleDrop = (e:any) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleFile(file);
  };

  const handleFileInputChange = (e:any) => {
    const file = e.target.files[0];
    handleFile(file);
  };

  const handleFile = (file:any) => {
    if (file && file.type.startsWith("image/") && file.size <= 200 * 1024) {

      // handle for valid file 
      handleUploadChange(file);
      setLogo(file);
      setErrorMessage("");
    } else {
      setErrorMessage("Invalid file. Please upload an image under 200KB.");
    }
  };

  const handleDragOver = (e:any) => {
    e.preventDefault();
  };

  const handleRemovePreview = (e:any) => {
    setLogo(null);
    // for prevent the event for opening browse window
    e.preventDefault();
    handleUploadChange(null);
    setErrorMessage("");
    
  };

  return (
  <>
    <div
      data-testid="dropZone"
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      className="LogoUploaderWrapper"
    >
      <input
        type="file"
        accept="image/*"
        onChange={handleFileInputChange}
        style={{ display: "none" }}
        id="fileInput"
        data-testid="logoInput"
      />
      <label htmlFor="fileInput">
        {logo ? (
          <>
            <img src={URL.createObjectURL(logo)} alt="Logo" style={{ width: "90px",height:"90px" }} />
            <div>
            <IconButton onClick={handleRemovePreview} className="deletePreviewImage" data-testid="deleteLogo">
              <DeleteIcon />
            </IconButton>
            </div>
          </>
        ) : (
            <>
                <img src={DragImagePreview} style={{ width:"36px", height:"36px"}} alt="Preview Icon"/>
                <p style={{margin:"0"}}><span className="drag-drop-text">Drag and drop here </span><br/>or <span className="browser-text">browse</span></p>
             </>
        )}
      </label>      
    </div>
    {errorMessage && <p style={{ color: "red", fontSize:"12px" }}>{errorMessage}</p>}
    </>
  );
};

export default LogoUploader;
