import React from 'react';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

interface props{
    filePreview:undefined | {
        uri: string;
        fileName: string;
        fileType?: string;
      }
}
const ContractDocumentPreview: React.FC<props> = ({
    filePreview,
}) => {

    return (
        <>
            {filePreview && (
                <DocViewer
                    documents={[filePreview]}
                    pluginRenderers={DocViewerRenderers}
                    config={{ pdfVerticalScrollByDefault: true, header: { disableHeader: true } }}
                />
            )}
        </>
    );
};

export default ContractDocumentPreview;
