import { useState } from "react"
import Grid from "@mui/material/Grid";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Card, CardContent } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Link, useLocation } from "react-router-dom";
import './SubmissionFilterTable.css'
import ViewRecruiterInfoPopup from "../ViewSubmissions/NewViewSubmission/Popups/ViewRecruiterInfoPopup";
import Alert from '@mui/material/Alert';
import getLoggedInUserRole from '../../Shared/utils/getLoggedInUserRole';
import { UserAuth } from '../../models/interfaces';
import { Payrate } from "../ui/Payrate";
import AppInfiniteScroll from "../Common/AppInfiniteScroll";
import { getNevLink } from "../../utils/getFileFromUrl";

export default function SubmissionFilterTable(props) {
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const userType = getLoggedInUserRole();
  const [recruiterDetailsId, setRecruiterDetailsId] = useState(false);
  const handleViewRecruiterBtn = (event,recruiterId)=>{
    setRecruiterDetailsId(recruiterId);
    setIsOpenPopup(true);
  }
  const location = useLocation();
  const handleClose = ()=>{
      setIsOpenPopup(false);
  }
  return (
    <>
    <Grid container>
    <Grid item xs={12} sm={12} md={12}>  
    <TableContainer component={Paper} className="submissionFilterTable" style={{marginBottom:"40px"}}>
    <AppInfiniteScroll
      dataLength={props.dataList.length}
      next={props.fetchNextData}
      hasMore={props.hasMoreData}
      isLoading={props.loading}
      loader={true}
    >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Job ID</TableCell>
            <TableCell>Job Title</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Client/ Location</TableCell>
            <TableCell>Contract</TableCell>
            <TableCell style={{minWidth:"70px"}}>Pay Rate</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.dataList.map((row,index) => (
            <TableRow
              key={ row?.jobId ?? index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>
                <span style={{color:'#4540DB'}}>{row.jobId}</span>
              </TableCell>
              <TableCell>{row.jobTitle}</TableCell>
              <TableCell>{row.firstName + " " + row.lastName}</TableCell>
              <TableCell>
              <Card elevation={0} spacing={0} className="client-location-card">
            <CardContent elevation={0} sx={{ padding: "0 !important",display: 'flex',alignItems:'center',justifyContent:'flex-start' }}>
              
                    <Box sx={{display: 'flex',alignItems:'flex-start',justifyContent:'flex-start',flexDirection:'column' }}>
                   <span  style={{margin: '0 !important' ,fontSize: '18px'}}>{row.jobClientName}</span>  <span style={{fontSize: '12px'}}>{row.jobCity + ","+ row?.jobStateCode}</span>
                    </Box>
            </CardContent>
          </Card>
              </TableCell>
              <TableCell>{row.contractType}</TableCell>
              <TableCell>
                <Payrate packageType={row.payRateType} packageValue={row.payRate} currency={row.packageCurrency}/>
              </TableCell>
              <TableCell>{row.finalStatus}</TableCell>
              <TableCell>
                <Box sx={{display: 'flex',alignItems:'center',justifyContent:'center' }}>
                <Button
                        variant="contained"
                        color="secondary"
                        className="jobs-button jobdetail"
                        component={Link}
                        style={{ minWidth:'102px'}}
                        to={getNevLink(`/submissions/viewsubmission/${row.jobId}${location.pathname.replace("/admin","")}${location.search}`)}
                    >
                        View details
                    </Button>
                    
                    {userType === UserAuth.Admin && ( <Button
                        variant="contained"
                        className="jobs-button"
                        
                        sx={{margin:'0 0 0 10px !important'}}
                        style={{minWidth:"115px"}}
                        onClick={(e) => handleViewRecruiterBtn(e,row.recruiterGuid)}
                    >
                        View Recruiter
                      </Button>)}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </AppInfiniteScroll>
      {props.loading && (
                  <p
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Please wait...
                  </p>
                )}
                {!props.loading && props.dataList.length === 0 && (
                  <Alert severity="info" style={{ marginTop: "5px" }}>
                    No Records Found
                  </Alert>
                )}
    </TableContainer>
    </Grid>
    </Grid>

    {isOpenPopup && <ViewRecruiterInfoPopup isOpenPopup={isOpenPopup} recruiterId={recruiterDetailsId} handleClose={handleClose}/>}
    </>
  );
}