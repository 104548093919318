import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { formatAMPM } from "../../../Shared/utils/dateUtils";

import moment from "moment";

import logo from "./../../../assets/images/logo.svg";

const InterviewDetailPdf = (props: any) => {

  return (
    <>
      <Container
        sx={{
          marginTop: "16px",
        }}
        ref={props.shareInterviewDiv}
        style={{ position: "fixed", width: "100%" }}
      >
        <Grid container>
          <Grid item xs={12} md={12}>
            <Typography
              variant="h6"
              noWrap
              component="span"
              sx={{ display: { md: "flex" } }}
            >
              <img
                src={logo}
                className="logo"
                alt="logo"
                style={{ marginLeft: "-29px" }}
              />
            </Typography>
          </Grid>
          <Grid item md={12} mt={8}>
            <TableContainer
              component={Paper}
              className="blueheading"
              elevation={0}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Interview Round</TableCell>
                    <TableCell align="left">Date of Interview</TableCell>
                    <TableCell align="left">Time</TableCell>
                    <TableCell align="left">Mode of Interview</TableCell>
                    <TableCell align="left">Scheduled On</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {props.interviewData?.map((tableData: any, index: any) => {
                    return (
                      tableData && (
                        <TableRow key={ tableData?.createdDate ?? index}>
                          <TableCell align="left" scope="row">
                            {tableData?.event?.title}
                          </TableCell>
                          <TableCell align="left">
                            {moment(tableData?.event?.dateTime?.date).format(
                              "MMMM DD, YYYY"
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {formatAMPM(tableData?.event?.dateTime?.time)}
                          </TableCell>
                          <TableCell align="left">
                            {tableData?.event?.location}
                          </TableCell>
                          <TableCell align="left">
                            {moment(tableData?.createdDate).format(
                              "MMMM DD, YYYY"
                            )}
                          </TableCell>
                          
                          
                        </TableRow>
                      )
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default InterviewDetailPdf;
