import { createSvgIcon } from "@mui/material/utils";

export const MapMarker = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="23"
    viewBox="0 0 22 28"
    fill="none"
  >
    <path
      d="M10.9932 0C4.85205 0.0971098 -0.0855011 5.25318 0.00112259 11.4913C0.00112259 16.4763 6.36569 24.0832 9.10117 27.126C9.58444 27.6763 10.2774 27.9908 11.0023 28C11.7227 27.9954 12.4157 27.6809 12.8989 27.1353C16.2955 23.3017 21.999 16.1711 21.999 11.496C22.0811 5.25318 17.1481 0.0971098 10.9932 0ZM11.8412 26.1734L11.8047 26.2104C11.5768 26.4324 11.2896 26.5387 10.9978 26.5387C10.706 26.5387 10.3869 26.4185 10.1589 26.178C4.68336 20.0139 1.41902 14.5249 1.41902 11.496C1.33239 6.0578 5.62711 1.53988 10.9932 1.43353H11.0023C16.3684 1.53988 20.6632 6.05318 20.5811 11.496C20.5811 14.5249 17.3122 20.0092 11.8412 26.1734Z"
      fill="#4540DB"
    />
    <path
      d="M10.9981 5.69141C8.03012 5.69141 5.61377 8.14227 5.61377 11.1527C5.61377 14.1631 8.03012 16.6139 10.9981 16.6139C13.9661 16.6139 16.3825 14.1631 16.3825 11.1527C16.3825 8.14227 13.9661 5.69141 10.9981 5.69141ZM10.9981 15.1804C8.80973 15.1804 7.0271 13.3723 7.0271 11.1527C7.0271 8.93302 8.80973 7.12493 10.9981 7.12493C13.1865 7.12493 14.9691 8.93302 14.9691 11.1527C14.9691 13.3723 13.1865 15.1804 10.9981 15.1804Z"
      fill="#4540DB"
    />
  </svg>,
  "MapMarker"
);
