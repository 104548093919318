import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  Checkbox,
  ClickAwayListener,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  MenuList,
  Tab,
  Tabs,
  TextField,
  Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import { styled } from "@mui/material/styles";
import * as React from "react";
import useApi from "../../../../../hooks/useApi";
import { CheckBoxSquareIcon } from "../../../../Common/CheckBoxSquareIcon";

import { clientFilter } from "../../../../../Services/clientService";
import { getJobTaxTerms, getJobTypes } from "../../../../../Services/jobService";
import getUnique from "../../../../../utils/getUnique";

const Wrapper = styled("div")({
  fontFamily: "Roboto !important !important",
  zIndex:9
});

interface FilterProps {
  anchorFilter: any;
  onClose: (event: any) => void;
  onClientChange: (val:any) => void;
  handleJobTypeChange: (jobTypes: string[]) => void;
  handleTaxTermChange: (taxTerms: string[]) => void
  selectedClients: string[];
  selectedJobTypes: string[];
  selectedTaxTerms: string[];
}

export interface FilterRef {
  handleClearAll: () => void;  
}

const ClientChangeTrackerTableFilterPopup = (
  props: React.PropsWithChildren<FilterProps>, 
  ref: React.ForwardedRef<FilterRef>
) => {

  const clientFilterIntialState = {
    isSorted: true,
    pageIndex: 1,
    pageSize: 5,
    sortColumn: "companyname",
    sortOrder: 0,
    totalRecord: 0,
    industries: [],
    locations: [],
    statuses: [],
    searchKeyword: ""
  }

  const { anchorFilter } = props;
  const open = Boolean(anchorFilter);
  const [value, setValue] = React.useState(0);
  const [clientFilterState, setClientFilterState] = React.useState(clientFilterIntialState);

  const [selectedClients, setselectedClient] = React.useState<any>(
    props.selectedClients
  );
  const [selectedJobTypes, setselectedJobTypes] = React.useState<string[]>(props.selectedJobTypes);
  const [selectedTaxTerms, setSelectedTaxTerms] = React.useState<string[]>(props.selectedTaxTerms);

  // Client Filter State 
  const allClients = useApi(() =>
    clientFilter(clientFilterState)
  ) as any;

  const allTaxTerms = useApi(() =>
    getJobTaxTerms()
  ) as any;

  const allJobTypes = useApi(() => getJobTypes()) as any;

  const changeSearchHandler = (e: any) => {
    setClientFilterState({
      ...clientFilterState,
      searchKeyword:e.target.value
    });
  };


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      allClients.request();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [clientFilterState.searchKeyword]);



  React.useEffect(() => {
    allJobTypes.request();
    allTaxTerms.request();
  }, []);

  React.useEffect(()=>{
    props?.handleJobTypeChange([...selectedJobTypes])
  },[selectedJobTypes]);

  React.useEffect(()=>{
    props?.handleTaxTermChange([...selectedTaxTerms])
  },[selectedTaxTerms]);


  let clientList =
    selectedClients.length > 0
      ? selectedClients.concat(allClients.data?.entityList)
      : allClients.data?.entityList;

  const uniqueClientList = getUnique(clientList);


  const _handleClientChange = (e: any, client: any) => {
    let isChecked = e.target.checked;
    if (isChecked) {
      setselectedClient([...selectedClients, client]);
    } else {
      setselectedClient(
        selectedClients.filter((item:any) => item.id !== client?.id)
      );
    }
  };


  const handleJobTypeChange = (e: any, jobType: any) => {
    let isChecked = e.target.checked;
    if (isChecked) {
      setselectedJobTypes([...selectedJobTypes, jobType]);
      
    } else {
      setselectedJobTypes(selectedJobTypes.filter((item:any) => item.id !== jobType.id));
    }
  };

  const _handleTexTermsChange = (e: any, taxTerms: any) => {
    let isChecked = e.target.checked;
    if (isChecked) {
      setSelectedTaxTerms([...selectedTaxTerms, taxTerms]);
    } else {
      setSelectedTaxTerms(selectedTaxTerms.filter((item:any) => item.id !== taxTerms.id));
    }
  };

  React.useEffect(() => {
    props.onClientChange(selectedClients);
  }, [selectedClients]);

  React.useEffect(() => {
    if(props?.selectedClients?.length > 0){
      let checked = {target:{checked:true}}
      _handleClientChange(checked,props?.selectedClients[0])
    }else{
      handleClearClients()
    }
  }, [props?.selectedClients]);

  React.useEffect(() => {
    props.handleJobTypeChange(selectedJobTypes);
  }, [selectedJobTypes]);



  const handleClearClients = () => {
    setClientFilterState({
      ...clientFilterState,
      searchKeyword:""
    });
    setselectedClient([]);
  };

  const handleClearJobTypes = () => {
    setselectedJobTypes([]);
  };

  const _handleClearTaxTerms = () =>{
    setSelectedTaxTerms([])
  }

  return (
    <Wrapper>
      <Popper id={"id"} open={open} anchorEl={anchorFilter} placement="bottom-end" style={{zIndex:9999}}>
        <ClickAwayListener onClickAway={props.onClose}>
          <Card>
            <Box
              sx={{
                flexGrow: 1,
                bgcolor: "#FAFAFA",
                display: "flex",
              }}
            >
              <Grid container p={2}>
                <Grid item md={6}>
                  <Typography fontWeight={"bold"} className="roboto-font">
                    Filters
                  </Typography>
                </Grid>
                <Grid item xs={6} className="closeArea">
                  <IconButton
                    aria-label="close"
                    size="small"
                    className="close-Btn"
                    onClick={props.onClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                bgcolor: "#FAFAFA",
                display: "flex",
                height: 290,
              }}
            >
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: "divider", width: 120 }}
              >
                <Tab
                  data-testid="client-tab"
                  className="filterTabNav"
                  label={
                    <Typography
                      fontSize={16}
                      className="roboto-font"
                    >
                      Client
                    </Typography>
                  }
                  {...a11yProps(0)}
                  style={{
                    backgroundColor: value === 0 ? "white" : "inherit",
                    alignItems: "flex-start",
                  }}
                />
                {/* Dmain Tab */}
                <Tab
                  data-testid="jobType-tab"
                  className="filterTabNav"
                  label={
                    <Typography
                      fontSize={16}
                      className="roboto-font"
                    >
                      Job Types
                    </Typography>
                  }
                  {...a11yProps(1)}
                  style={{
                    backgroundColor: value === 1 ? "white" : "inherit",
                    alignItems: "flex-start",
                  }}
                />
                {/* Skill Tab */}
                <Tab
                  data-testid="taxTerms-tab"
                  className="filterTabNav"
                  label={
                    <Typography
                      fontSize={16}
                      className="roboto-font"
                    >
                      Tax Terms
                    </Typography>
                  }
                  {...a11yProps(2)}
                  style={{
                    backgroundColor: value === 2 ? "white" : "inherit",
                    alignItems: "flex-start",
                  }}
                />
              </Tabs>
              <TabPanel value={value} index={0}>
                <Grid container style={{ width: 223 }} p={2}>
                  <Grid item md={12}>
                    <Grid container>
                      <Grid item>
                        <TextField
                          className="roboto-font filter-searchbox"
                          placeholder="Search Client"
                          size="small"
                          value={clientFilterState.searchKeyword}
                          InputProps={{                            
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton>
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M9.16675 1.66666C13.3067 1.66666 16.6667 5.02666 16.6667 9.16666C16.6667 13.3067 13.3067 16.6667 9.16675 16.6667C5.02675 16.6667 1.66675 13.3067 1.66675 9.16666C1.66675 5.02666 5.02675 1.66666 9.16675 1.66666ZM9.16675 15C12.3892 15 15.0001 12.3892 15.0001 9.16666C15.0001 5.94333 12.3892 3.33333 9.16675 3.33333C5.94341 3.33333 3.33341 5.94333 3.33341 9.16666C3.33341 12.3892 5.94341 15 9.16675 15ZM16.2376 15.0592L18.5951 17.4158L17.4159 18.595L15.0592 16.2375L16.2376 15.0592V15.0592Z"
                                      fill="#353535"
                                    />
                                  </svg>
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          onChange={changeSearchHandler}
                        />
                      </Grid>
                      <Grid item pt={1} pb={0.5}>
                        <FormControlLabel
                          data-testid={"clear-industries-button"}
                          onClick={handleClearClients}
                          control={
                            <CheckBoxSquareIcon
                              style={{ paddingRight: "5px" }}
                            ></CheckBoxSquareIcon>
                          }
                          label={
                            <Typography fontSize={14} className="roboto-font">
                              Clear All
                            </Typography>
                          }
                          className="candidates-label"
                          style={{
                            color: "red",
                            fontWeight: "bold",
                            paddingLeft: 0,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    style={{ maxHeight: 300, overflowY: "auto" }}
                  >
                    <MenuList
                      style={{ paddingTop: "0px" }}
                      className="listing-filter"
                    >
                      <MenuItem
                        sx={{
                          paddingLeft: "0px",
                          paddingBottom: "0px",
                          minHeight: "auto",
                        }}
                      ></MenuItem>
                      {uniqueClientList?.map((item: any, index:number) => {
                        return (
                          <MenuItem
                            key={item?.id}
                            sx={{
                              paddingLeft: "0px",
                              paddingBottom: "0px",
                              minHeight: "auto",
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  data-testid={`industry-checkbox-${index}`}
                                  onChange={(e) =>
                                    _handleClientChange(e, item)
                                  }
                                  name={`${item?.companyName}`}
                                  sx={{ padding: "0px", fontSize: "14px" }}
                                  checked={selectedClients.includes(item)}
                                />
                              }
                              label={
                                <Typography
                                  fontSize={14}
                                  className="roboto-font"
                                >
                                  {`${item?.companyName}`}
                                </Typography>
                              }
                              className="candidates-label roboto-font"
                              style={{ paddingLeft: 0 }}
                            />
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid container style={{ width: 223 }} p={2}>
                  <Grid item md={12}>
                    <Grid container>
                      <Grid item pt={1} pb={0.5}>
                        <FormControlLabel
                          data-testid={"clear-jobType-button"}
                          onClick={handleClearJobTypes}
                          control={
                            <CheckBoxSquareIcon
                              style={{ paddingRight: "5px" }}
                            ></CheckBoxSquareIcon>
                          }
                          label={
                            <Typography fontSize={14} className="roboto-font">
                              Clear All
                            </Typography>
                          }
                          className="candidates-label"
                          style={{
                            color: "red",
                            fontWeight: "bold",
                            paddingLeft: 0,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    style={{ maxHeight: 300, overflowY: "auto" }}
                  >
                    {allJobTypes.data?.entityList?.map(
                      (item: any) => {
                        return (
                        <FormControl fullWidth  key={item?.id}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                className="jobTypeCheckboxItem"
                                onChange={(e) => handleJobTypeChange(e, item)}
                                name={item}
                                sx={{ padding: "0px", fontSize: "14px" }}
                                checked={selectedJobTypes.includes(item)}
                              />
                            }
                            label={
                              <Typography fontSize={14} className="roboto-font">
                                {item.jobType}
                              </Typography>
                            }
                            className="candidates-label roboto-font"
                            style={{ paddingLeft: 0 }}
                            
                          />
                          </FormControl>
                        );
                      }
                    )}
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Grid container style={{ width: 223 }} p={2}>
                  <Grid item md={12}>
                    <Grid container>
                      <Grid item pt={1} pb={0.5}>
                        <FormControlLabel
                          data-testid={"clear-taxTerms-button"}
                          onClick={_handleClearTaxTerms}
                          control={
                            <CheckBoxSquareIcon
                              style={{ paddingRight: "5px" }}
                            ></CheckBoxSquareIcon>
                          }
                          label={
                            <Typography fontSize={14} className="roboto-font">
                              Clear All
                            </Typography>
                          }
                          className="candidates-label"
                          style={{
                            color: "red",
                            fontWeight: "bold",
                            paddingLeft: 0,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    style={{ maxHeight: 300, overflowY: "auto" }}
                  >
                    {allTaxTerms.data?.entityList?.map(
                      (item: any, index:number) => {
                        return (
                        <FormControl fullWidth key={item?.id ?? index} >
                          <FormControlLabel
                          
                          key={item?.id}
                            control={
                              <Checkbox
                                className="taxtermsCheckboxItem"
                                onChange={(e) => _handleTexTermsChange(e, item)}
                                name={item}
                                sx={{ padding: "0px", fontSize: "14px" }}
                                checked={selectedTaxTerms.includes(item)}
                              />
                            }
                            label={
                              <Typography fontSize={14} className="roboto-font">
                                {item.taxTerm}
                              </Typography>
                            }
                            className="candidates-label roboto-font"
                            style={{ paddingLeft: 0 }}
                            
                          />
                          </FormControl>
                        );
                      }
                    )}
                  </Grid>
                </Grid>
              </TabPanel>
            </Box>
          </Card>
        </ClickAwayListener>
      </Popper>
    </Wrapper>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ backgroundColor: "white" }}
    >
      {value === index && (
        <Typography className="roboto-font">{children}</Typography>
      )}
    </div>
  );
}

export default React.forwardRef(ClientChangeTrackerTableFilterPopup);
