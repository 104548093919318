import { BreadCrumbItem } from "../../../../models/AppModels";
import { ternary } from "../../../../utils/complexityUtils";
import { getNevLink } from "../../../../utils/getFileFromUrl";
interface JobDetails {
  id: string | number;
}


export const savedJobListJobBreadCrumb: BreadCrumbItem[] = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
    { isLeaf: false, sequence: 2, title: "Saved Jobs", to:  "/savedjobs/recruiter" },
    { isLeaf: true, sequence: 3, title: "Submit Candidate", leafClasses:"custom-appbreadcrumb" },
  ];
  
export const savedJobBreadCrumb: BreadCrumbItem[] = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
    { isLeaf: false, sequence: 2, title: "Saved Jobs", to: "/savedJobs" },
    { isLeaf: true, sequence: 3, title: "Submit Candidate", leafClasses:"custom-appbreadcrumb" },
  ];
export const pendingsubmission: BreadCrumbItem[] = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
    {
      isLeaf: false,
      sequence: 2,
      title: "Pending Submissions",
      to: "/pendingsubmission",
    },
    { isLeaf: true, sequence: 3, title: "Submit Candidate", leafClasses:"custom-appbreadcrumb" },
  ];
export const allJobBreadCrumb: BreadCrumbItem[] = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
    { isLeaf: false, sequence: 2, title: "All Jobs", to: "/jobs/all" },
    { isLeaf: true, sequence: 3, title: "Submit Candidate", leafClasses:"custom-appbreadcrumb"},
  ];

export const updateSubmisssion: BreadCrumbItem[] = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
    { isLeaf: false, sequence: 2, title: "Submissions", to: "/submissions" },
    { isLeaf: true, sequence: 3, title: "Update Details", leafClasses:"custom-appbreadcrumb" },
  ];

export const jobalertBreadCrumb : BreadCrumbItem[] = [
  { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
    { isLeaf: false, sequence: 2, title: "Job Alerts", to: "/jobalerts" },
    { isLeaf: false, sequence: 3, title: "View Jobs", to:'/jobs/jobalerts' },
    { isLeaf: true, sequence: 4, title: "Submit Candidate", leafClasses:"custom-appbreadcrumb" },
];

export const  findJobsBreadCrumb = [
  { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
  { isLeaf: false, sequence: 2, title: `Find Jobs`, to: "/findjobsExt" },
  { isLeaf: true, sequence: 4, title: "Submit Candidate", leafClasses:"custom-appbreadcrumb" },

];


// function which can return dynamically breadcrumbs

export const jobAlertsJobDetailsBreadcrumbs = (jobDetails: JobDetails): BreadCrumbItem[] => {
  return [
    { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
    { isLeaf: false, sequence: 2, title: "Job Alerts", to: "/jobalerts" },
    { isLeaf: false, sequence: 3, title: "View Jobs", to: '/jobs/jobalerts' },
    { isLeaf: false, sequence: 4, title: "Job Details", to: `/jobdetail/${jobDetails?.id}/jobalerts` },
    { isLeaf: true, sequence: 5, title: "Submit Candidate", leafClasses: "custom-appbreadcrumb" },
  ];
};

export const savedJobListJobDetailsJobBreadCrumb = (jobDetails: JobDetails): BreadCrumbItem[] => {
  return [
  { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
  { isLeaf: false, sequence: 2, title: "Saved Jobs", to:  "/savedjobs/recruiter" },
  { isLeaf: false, sequence: 3, title: "Job details", to:  `/jobdetail/${jobDetails?.id}/recruiter`},
  { isLeaf: true, sequence: 4, title: "Submit Candidate", leafClasses:"custom-appbreadcrumb" },
];
};

export const jobDetailsBreadCrumb = (jobDetails: JobDetails, fromPage:string| undefined): BreadCrumbItem[] => {
  return [
  { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
  {
    isLeaf: false,
    sequence: 2,
    title: ternary(
      Boolean(fromPage?.toLowerCase()?.includes("savedjobs")),
      "Saved Jobs",
      "All Jobs"
    ),
    to: ternary(
      Boolean(fromPage?.toLowerCase()?.includes("savedjobs")),
      "/savedjobs",
      "/jobs/all"
    ),
  },
  {
    isLeaf: false,
    sequence: 2,
    title: "Job Details",
    to: `/jobdetail/${jobDetails?.id}/${ternary(
      Boolean(fromPage?.toLowerCase()?.includes("savedjobs")),
      "savedjobs",
      "jobs"
    )}`,
  },
  { isLeaf: true, sequence: 3, title: "Submit Candidate", leafClasses:"custom-appbreadcrumb" },
];
};

export const newSubmissionBreadCrumb = (fromPage:string| undefined): BreadCrumbItem[] => {
  return [
  { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
  {
    isLeaf: false,
    sequence: 2,
    title: `${
      fromPage === "submissionactive"
        ? "Active Submissions"
        : "Archived Submissions"
    }`,
    to: `/submission/${
      fromPage === "submissionactive" ? "active" : "archived"
    }`,
  },
  { isLeaf: true, sequence: 3, title: "Update Details", leafClasses:"custom-appbreadcrumb" },
];
};

export const findJobsFromJobDetailsBreadCrumb = (jobDetails: JobDetails, fromPage:string| undefined): BreadCrumbItem[] => {
  return [
  { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
  { isLeaf: false, sequence: 2, title: `Find Jobs`, to: "/findjobs" },
  { isLeaf: false, sequence: 4, title: "Job Details", to: `/jobdetail/${jobDetails?.id}/${fromPage?.toLowerCase()?.includes('findjobs')? "findjobs" : "jobs" }`},
  { isLeaf: true, sequence: 4, title: "Submit Candidate", leafClasses:"custom-appbreadcrumb" },
];
};