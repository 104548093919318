import React, { useRef, useState, useEffect } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import SignatureCanvas from "react-signature-canvas";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';

interface props {
  onSaveSignature: (imageData:any)=> void;
  loading:boolean;
}

const DrawSignature: React.FC<props> = ({
  onSaveSignature,
  loading
}) => {
  const sigCanvas = useRef<SignatureCanvas>(null);
  const [signatureDataUrl, setSignatureDataUrl] = useState<string | null>(null);
  const [isCanvasEmpty, setIsCanvasEmpty] = useState<boolean>(true);

  const handleClear = () => {
    sigCanvas.current?.clear();
    setIsCanvasEmpty(true);
    setSignatureDataUrl(null);
  };

  const handleSave = () => {
    if (sigCanvas.current && !isCanvasEmpty) {
      const signature = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png").split(",")[1];
      setSignatureDataUrl(signature);
      onSaveSignature(signature)
    }
  };

  const handleEndDrawing = () => {
    if (sigCanvas.current) {
      const isEmpty = sigCanvas.current.isEmpty();
      setIsCanvasEmpty(isEmpty);
    }
  };

  useEffect(() => {
    if (signatureDataUrl && sigCanvas.current) {
      sigCanvas.current.fromDataURL(signatureDataUrl);
      setIsCanvasEmpty(false);
    }
  }, [signatureDataUrl]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 2 }}>
      <SignatureCanvas
        ref={sigCanvas}
        penColor="black"
        onEnd={handleEndDrawing}
        canvasProps={{
          width: 400,
          height: 150,
          style: { margin: "0 16px", border: "1px solid #d7c6c6" },
        }}
        data-testid="signature-canvas"
      />
      <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
        <Button 
          variant="outlined" 
          color="secondary" 
          onClick={handleClear} 
          disabled={isCanvasEmpty || loading}
          endIcon={<ClearIcon />}
          >
          Clear
        </Button>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleSave} 
          disabled={isCanvasEmpty || loading}
          endIcon={loading ? <CircularProgress />  : <CheckCircleIcon />}
          >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default DrawSignature;
