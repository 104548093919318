import * as Yup from "yup";
import { addressRegex, cityRegex, experienceRegex, firstNameRegex, middleNameRegex, phoneNumberRegex, zipValidation } from "../../../../constants/regex";

export const ValidationSchemaCandidateAcknowledgement = Yup.object({
    firstName: Yup.string().required("Please enter the first name").matches(firstNameRegex, "Entered first name is not valid"),
    middleName: Yup.string().matches(middleNameRegex, "Entered middle name is not valid"),
    lastName: Yup.string().required("Please enter the last name").matches(firstNameRegex, "Entered last name is not valid"),
    phone: Yup.string().required("Please enter the phone number").matches(phoneNumberRegex, 'Entered Phone number is invalid'),
    experience: Yup.string().required("Please enter the total experience").matches(experienceRegex,"Please enter the valid experience from 0 to 25 or 25+"),
  
    address: Yup.object({
      streetAddress: Yup.string().required("Please enter the address").matches(addressRegex, "Entered Address is invalid"),
      city: Yup.string().required("Please enter the city").matches(cityRegex, "Entered City is invalid"),
      state: Yup.string().required("Please enter the state").matches(cityRegex, "Entered State is invalid"),
      countryCode: Yup.string().required("Please enter the country").matches(cityRegex, "Entered Country is invalid"),
      postalCode: Yup.string()
       .required("Please enter the zip code")
       .matches(zipValidation, "Entered Zip is invalid"),
    }),
    workAuthorizationStatus: Yup.string().required(),
    employmentType: Yup.string().required("Please select Candidate Employment"),
    employerDetails:Yup.object().when(['workAuthorizationStatus', "employmentType"], {
      is: (workAuthorizationStatus:string,employmentType:string) => {
        return (employmentType === "DirectContract" && 
        (workAuthorizationStatus === "H1B" || workAuthorizationStatus === "E3" || workAuthorizationStatus === "TN"))
      }
      ,
      then:Yup.object().shape({
          name: Yup.string().required("Please enter employer name"),
          email: Yup.string().email("Please enter valid email").required("Please enter employer email"),
          phoneNumber: Yup.string().required("Please enter employer phone number"),
          reference: Yup.string().required("Please enter employer reference"),
          address: Yup.object().shape({
            postalCode: Yup.string().required("Please enter employer zip").matches(zipValidation, "Entered Zip is invalid"),
            countryCode: Yup.string().required("Please enter employer country").matches(cityRegex, "Entered Country is invalid"),
            streetAddress: Yup.string().required("Please enter employer address").matches(addressRegex, "Entered Address is invalid"),
            city: Yup.string().required("Please enter employer city").matches(cityRegex, "Entered City is invalid"),
            state: Yup.string().required("Please enter employer city").matches(cityRegex, "Entered State is invalid"),
          }),
        }),
      otherwise: Yup.object().shape({
        name:Yup.string(),
        email:Yup.string().email("Please enter valid email"),
        phoneNumber:Yup.string(),
        reference:Yup.string(),
        address:Yup.object().shape({
          streetAddress:Yup.string(),
          city:Yup.string(),
          countryCode:Yup.string(),
          state:Yup.string(),
          postalCode:Yup.string()
        })
      }),
    }),
    currentWorkLocation: Yup.object({
      city: Yup.string().required("Please enter the city").matches(cityRegex, "Entered City is invalid"),
      state: Yup.string().required("Please enter the state").matches(cityRegex, "Entered State is invalid"),
    }),
    phoneNumbers: Yup.object({
      primary: Yup.string().required("Please enter the phone number").matches(phoneNumberRegex, 'Entered Phone number is invalid'),
      isPrimaryVerified : Yup.boolean()
      .oneOf([true], "Please verify the primary phone number.")
    }),
    comment: Yup.string()
    .max(1000, "Please enter comments, it must be less than 1000 characters"),
  });