import jwtDecode from 'jwt-decode';

export enum PermissionType {
  AllowedFeaturesIds = 'AllowedFeaturesIds',
  AllowedModulesIds = 'AllowedModulesIds',
}

interface DecodedToken {
  AllowedFeaturesIds?: string | string[];
  AllowedModulesIds?: string | string[];
}

interface PermissionsObject {
  features: string[];
  modules: string[];
}

function getLoggedInUserPermissions(property?: PermissionType): PermissionsObject | undefined {
  const accessToken = localStorage.getItem('accessToken');

  if (!accessToken) {
    return undefined;
  }

  const decoded = jwtDecode<DecodedToken>(accessToken);

  const allowedFeaturesIds = Array.isArray(decoded.AllowedFeaturesIds)
    ? decoded.AllowedFeaturesIds
    : decodeIds(decoded.AllowedFeaturesIds);

  const allowedModulesIds = Array.isArray(decoded.AllowedModulesIds)
    ? decoded.AllowedModulesIds
    : decodeIds(decoded.AllowedModulesIds);

  if (!property) {
    return {
      features: allowedFeaturesIds,
      modules: allowedModulesIds,
    };
  }

  if (property === PermissionType.AllowedFeaturesIds) {
    return {
      features: allowedFeaturesIds,
      modules: [],
    };
  } else if (property === PermissionType.AllowedModulesIds) {
    return {
      features: [],
      modules: allowedModulesIds,
    };
  }

  return undefined;
}

function decodeIds(ids: string | undefined): string[] {
  if (!ids) {
    return [];
  }
  return ids.split(',').map((id) => id.trim());
}

export default getLoggedInUserPermissions;
