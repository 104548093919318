import React, { useEffect, useRef, useState } from "react";

import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField
} from "@mui/material";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import { FormControl } from "@material-ui/core";
import { useFormik } from "formik";
import PlacesAutocomplete from "react-places-autocomplete";
import { searchCities } from "../../constants/googlePlaceOption";
import getAddressForAutoComplete from "../../utils/getAddressForAutoComplete";
import getAddressForAutoFill from "../../utils/getAddressForAutoFill";
import getLabel from "../../utils/getLabelForAutoComplete";
import { ternary, valueOrDefault } from "../../utils/complexityUtils";
import { handleAdvanceSearchKeyword } from "../../features/Jobs/clientJobSlice";
import { useDispatch } from "react-redux";

function useComponentVisible(initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] = useState(
    initialIsVisible
  );
  const ref = useRef(null);

  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setIsComponentVisible(false);
    }
  };

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
}

export default function HomeSearchJobs() {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const {ref, isComponentVisible, setIsComponentVisible} = useComponentVisible(false);
  const [search, setSearch] = React.useState("")
  const [location, setLocation] = React.useState("")

  const formik = useFormik({
    initialValues: location,
    enableReinitialize: true,
    onSubmit: (values, actions) => {
     
    },
  });

  const handleChange = (value)=>{
    setLocation(value)
    setIsComponentVisible(true);
  }

  const handleSelect = (value)=>{
    setLocation(getLabel(getAddressForAutoComplete(value).city,getAddressForAutoComplete(value).state))
    setIsComponentVisible(true);
  }


  const autoCompleteGetWorkLocation = async(address)=>{
    let resp =  await getAddressForAutoFill(address)
    formik.setFieldValue(
      "location",
      valueOrDefault(
        getLabel(
          getAddressForAutoComplete(resp.formattedAddress).city,
          getAddressForAutoComplete(resp.formattedAddress).state
        ),
        ""
      )
    );
  }

  const renderFuncCurrentWorkLocation = ({
    getInputProps,
    suggestions,
    getSuggestionItemProps,
    loading,
  }) => (
    <div className="autocomplete-home-location" >
      <FormControl
        variant="standard"
        className="HomeLocation"
        fullWidth
      >

        <TextField
         {...getInputProps()}
              id="job-search-location"
              className="SearchKeywords"
              variant="standard"
              name="location"
              fullWidth
              disableUnderline="true"
              component="span"
              placeholder="Search by Location"
              onBlur={formik.handleBlur}
              value={location}
              InputProps={{
                disableUnderline: true,
                startAdornment: <LocationOnOutlinedIcon style={{ top: 2 }} />,
                
              }}
              data-testid="location-input"
              />
      </FormControl>
      { isComponentVisible && 
      <div ref={ref} className="autocomplete-dropdown-container">
        {loading && <div>Loading...</div>}
        {suggestions.map((suggestion) => (
          <div {...getSuggestionItemProps(suggestion)}>
            <span onClick={autoCompleteGetWorkLocation} style={{cursor:'pointer'}}>{getLabel(getAddressForAutoComplete(suggestion.description).city,getAddressForAutoComplete(suggestion.description).state)}</span>
          </div>
        ))}
      </div>
    }
    </div>
  );

  const handleAdvancedSearchClick = () => {

    if(search?.trim()?.length > 1 || location?.trim()?.length > 1)
    {
      let updatedAdvanceFilterData = {
        jobTitle:  ternary(search?.trim()?.length > 1,[search?.trim()],[]),
        location: ternary(location?.trim()?.length > 1,[location?.trim()],[]),
        client:  [],
        minimumPayRate:0,
        maximumPayRate:0,
        currency:'',
      };
      dispatch(handleAdvanceSearchKeyword(updatedAdvanceFilterData));
    }
      navigate(`/jobs/all`);
    }  
    

  return (
    <Container maxWidth="xl" className="home-page-search">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid container component="span">
          <Grid item sm={6} md={4} sx={{ paddingLeft: "10px" }}>
            <TextField
              className="SearchKeywords"
              id="job-search-input"
              variant="standard"
              component="span"
              fullWidth
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search Title or Keywords"
              InputProps={{
                disableUnderline: true,
                startAdornment: <SearchOutlinedIcon style={{ top: 2 }} />,
              }}
            />
          </Grid>
          <Grid item md={1} sx={{display:{xs:"none",sm:"none",md:"block"}}}>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ height: "40px", alignSelf: "center" }}
            />
          </Grid>
          <Grid item md={4} sm={6} sx={{ paddingLeft: "10px" }}>
            <PlacesAutocomplete 
                searchOptions={searchCities} 
                value={location}
                 onChange={handleChange}
                 onSelect={handleSelect}
              >
                {renderFuncCurrentWorkLocation}
            </PlacesAutocomplete>
          </Grid>
          
          <Grid item sm={12} xs={12} md={2}style={{textAlign: "center"}}><Button
            style={{ textTransform: "none" }}
            onClick={handleAdvancedSearchClick}
            variant="contained"
          >
            Search
          </Button></Grid>
        </Grid>
      </Box>
    </Container>
  );
}
