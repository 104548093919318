import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function SuccessRightIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 28 28" fill="none">
        <circle cx="14" cy="14" r="14" fill="white"/>
        <path d="M7 15L11 19L21 9" stroke="#26C296" stroke-width="2"/>
        </svg>
    </SvgIcon>
  );
}

export default SuccessRightIcon;