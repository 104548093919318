import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@material-ui/core/Grid";
import Arrow from "./../../assets/images/Arrow.png";
import Plane from "./../../assets/images/Message-icon.svg";
import Building from "./../../assets/images/Interview-icon.png";
import Target from "./../../assets/images/Target-icon.png";
import BrowseJobsIcon from "./../../assets/images/browse-jobs-icon.svg";
import { styled,ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import TalkIcon from "./../../assets/images/Talk-icon.svg"
import StaticFooter from "../Common/StaticFooter";
import Link from '@mui/material/Link';
import { Testimonial } from "./Testimonial";
import staticPageTheme from "../../Shared/theme/staticPageTheme";
import './AboutUs.css';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const AboutUs: React.FC = () => {
  return (
    <div>
    
      <ThemeProvider theme={staticPageTheme}>
      <Container
        maxWidth="xl"
        className="login-banner-text"
        sx={{
          marginTop: "16px",
        }}
      >
        <Typography
          variant="h5"
          component="span"
          className="letter-spacing-normal primary-color-text"
          gutterBottom
          textAlign={"center"}
          sx={{ paddingTop: "20px", textAlign: "center" }}
        >
          <div className="roboto-font">About Us</div>
        </Typography>

        <Typography
          variant="h4"
          color="inherit"
          component="h1"
          sx={{ paddingBottom: "20px", fontWeight: "400" ,fontSize:{xs:"25px !important",sm:"32px !important",md: "34px !important"}}}
          
          textAlign={"center"}
        >
         Thousands of jobs that will get your best candidates hired. <br/>Guaranteed!
        </Typography>
        <Typography
          className="roboto-font"
          variant="h6"
          color="inherit"
          component="h1"
          sx={{ paddingBottom: "10px", fontWeight: "400", color: "#0E0E2C99" }}
          textAlign={"center"}
        >
          We enable recruiters to leverage the power of this crowd-sourced
          marketplace for independent recruiters in a super simple way.
        </Typography>
        <Typography
          className="roboto-font"
          variant="h6"
          color="inherit"
          component="h1"
          sx={{ paddingBottom: "20px", fontWeight: "400", color: "#0E0E2C99" }}
          textAlign={"center"}
        >
          Independent Recruiters: “Redirect your time consuming and costly sales efforts into what actually makes you money – placing candidates. No complex paperwork. Hassle-free process. Guaranteed payments. It’s just that simple."​
        </Typography>

        <Container className="roboto-font aboutUs-space-txt">
          <Grid container justify="center">
            <Button
              className="roboto-font"
              color="primary"
              href="/ContactUs"
              variant="contained"
              target={"_blank"}
            >
              Lets Talk{" "}
              <img style={{ paddingLeft: "10px" }} src={TalkIcon}></img>
            </Button>

            <Typography paddingTop="8px" paddingLeft="40px" fontSize="20px">
              <Link
                className="roboto-font"
                href="/recruitmentServices/checkout"
                target={"_blank"}
                color="inherit"
                underline="none"
              >
                More about our services{" "}<img src={Arrow} alt="" style={{ paddingLeft:"15px"}}/>
              </Link>
            </Typography>
          </Grid>
        </Container>

        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Item elevation={0}>
              <Typography
                variant="h6"
                component="span"
                className="letter-spacing-normal primary-color-text"
                style={{ marginTop: "60px", textAlign: "left" }}
              >
                <div className="roboto-font">How it Works?</div>
              </Typography>
              <Typography
                className="roboto-font aboutusfontweight"
                sx={{
                  fontSize:{xs:"27px !important",sm:"35px !important",md: "36px !important"},
                  textAlign: "left",
                  lineHeight: "50px",
                }}
              >
                JobRialto offers easy & intuitive tools for all your needs as a
                ‘Recruiter’
              </Typography>
            </Item>
          </Grid>

          <Grid item xs={12} md={8}>
            <Item elevation={0}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: { xs: "-webkit-center", md: "unset" },
                      textAlignLast: { xs: "center", md: "unset" },
                    }}
                  >
                    <div
                      className="about-card-icons"
                      style={{
                        marginBottom: "30px",
                      }}
                    >
                      <span>
                        <img src={BrowseJobsIcon} alt="" />
                      </span>
                    </div>
                    <Typography
                      className="roboto-font"
                      variant="h5"
                      component="p"
                      style={{
                        textAlign: "left",
                        marginTop: "30px",
                        fontWeight: "bold",
                        color: "black",
                        fontSize: "20px",
                      }}
                      sx={{ textAlign: "left" }}
                    >
                      Browse Jobs
                    </Typography>
                    <Typography
                      className="roboto-font"
                      component="p"
                      style={{
                        textAlign: "left",
                        marginTop: "10px",
                        fontSize: "16px",
                        width: 180,
                      }}
                    >
                      1000s of Active Jobs at any time
                    </Typography>
                  </Item>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Item elevation={0}
                   sx={{
                    textAlign: { xs: "-webkit-center", md: "unset" },
                    textAlignLast: { xs: "center", md: "unset" },
                  }}>
                    <div
                      className="about-card-icons"
                      style={{
                        marginBottom: "30px",
                      }}
                    >
                      <span>
                        <img src={Plane} alt="" />
                      </span>
                    </div>
                    <Typography
                      className="roboto-font"
                      variant="h5"
                      component="p"
                      style={{
                        textAlign: "left",
                        marginTop: "30px",
                        fontWeight: "bold",
                        color: "black",
                        fontSize: "20px",
                      }}
                    >
                      Submit Candidates
                    </Typography>
                    <Typography
                      component="p"
                      className="roboto-font"
                      style={{
                        textAlign: "left",
                        marginTop: "10px",
                        fontSize: "16px",
                        width: 180,
                      }}
                    >
                      Present your best candidates
                    </Typography>
                  </Item>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Item elevation={0} sx={{
                  textAlign: { xs: "-webkit-center", md: "unset" },
                  textAlignLast: { xs: "center", md: "unset" },
                }}>
                    <div
                      className="about-card-icons"
                      style={{
                        marginBottom: "30px",
                      }}
                    >
                      <span>
                        <img src={Building} alt="" />
                      </span>
                    </div>
                    <Typography
                      className="roboto-font"
                      variant="h5"
                      component="p"
                      style={{
                        textAlign: "left",
                        marginTop: "30px",
                        fontWeight: "bold",
                        color: "black",
                        fontSize: "20px",
                      }}
                    >
                      Interviews
                    </Typography>
                    <Typography
                      component="p"
                      className="roboto-font"
                      style={{
                        textAlign: "left",
                        marginTop: "10px",
                        fontSize: "16px",
                        width: 180,
                      }}
                    >
                      Clear visibility to interview stages & selection process
                    </Typography>
                  </Item>
                </Grid>
                <Grid item xs={12} sm={6} md={3} >
                  <Item elevation={0}
                   sx={{
                    textAlign: { xs: "-webkit-center", md: "unset" },
                    textAlignLast: { xs: "center", md: "unset" },
                  }}>
                    <div
                      className="about-card-icons"
                      style={{
                        marginBottom: "30px",
                      }}
                    >
                      <span>
                        <img src={Target} alt="" />
                      </span>
                    </div>
                    <Typography
                      className="roboto-font"
                      variant="h5"
                      component="p"
                      style={{
                        textAlign: "left",
                        marginTop: "30px",
                        fontWeight: "bold",
                        color: "black",
                        fontSize: "20px",
                      }}
                    >
                      Candidate Hired
                    </Typography>
                    <Typography
                      component="p"
                      className="roboto-font"
                      style={{
                        textAlign: "left",
                        marginTop: "10px",
                        fontSize: "16px",
                        width: 180,
                      }}
                    >
                      For every candidate hired, earn a monthly fee for the duration of the job
                    </Typography>
                  </Item>
                </Grid>
              </Grid>
            </Item>
          </Grid>
        </Grid>
      
      </Container>
      <Testimonial/>
      </ThemeProvider>
     
    
      <StaticFooter />
    </div>
  );
};

export default AboutUs;