import React, { useState } from "react";
import { Box, Button, Tabs, Tab } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { DialogTitle } from "@mui/material";
import DrawIcon from '@mui/icons-material/Draw';
import GestureIcon from '@mui/icons-material/Gesture';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DrawSignature from "./DrawSignature";
import UploadSignature from "./UploadSignature";

interface props{
  onSaveSignature: (imageData: string) => void;
  loading:boolean;
}
const SignatureComponent: React.FC<props> = ({
  onSaveSignature,
  loading,
}) => {
   
  const [isSignaturePopupOpen, setIsSignaturePopupOpen] = useState<boolean>(loading);
  const [tabValue, setTabValue] = useState(0);
  const [isSigned, setIsSigned] = useState<boolean>(false);

  const _onSaveSignature = (imageData:any) => {
    setIsSigned(true)
    setIsSignaturePopupOpen(false)   
    onSaveSignature(imageData) 
  };


  return (
    <>
      {!isSignaturePopupOpen && !isSigned && (
        <Button
          variant="contained"
          onClick={() => setIsSignaturePopupOpen(true)}
          sx={{
            position: "fixed",
            bottom: 16,
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1000,
          }}
          startIcon={ <GestureIcon />}
        >
          Add Signature
        </Button>
      )}
      <Dialog
        open={isSignaturePopupOpen}
        onClose={() => setIsSignaturePopupOpen(false)}
      >
        <DialogTitle>
          <IconButton
            aria-label="Close Preview"
            onClick={() => setIsSignaturePopupOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            data-testid="close-preview-btn"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Tabs
              value={tabValue}
              onChange={(e, newValue) => setTabValue(newValue)}
              className="signatureTypeTabs"
            >
              <Tab icon={<DrawIcon />} iconPosition="start" label="Draw"  />
              <Tab icon={<UploadFileIcon />} iconPosition="start" label="Upload" />
            </Tabs>
            {tabValue === 0 && 
              <DrawSignature 
                onSaveSignature={_onSaveSignature}
                loading={loading}
              />
              }
            {tabValue === 1 && (
              <UploadSignature
              onSaveSignature={_onSaveSignature}
              />
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SignatureComponent;
