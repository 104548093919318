import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {Grid,Paper,} from "@mui/material";
import moment from "moment";
import Alert from '@mui/material/Alert';
import { CommissionTypeNames } from "../../models/interfaces";
interface Props{
  data:any;
  selectedRecruiter:any;
  key:any;

}

export const CompensationTracker: React.FunctionComponent<Props> = (props) => {
  return (
    <React.Fragment>
            <Grid container>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TableContainer style={{ height: props?.data?.length >0? '300px' : 'auto',width:"100%"}}  component={Paper}  className="blueheading"
                            elevation={0}>
                        <Table stickyHeader >
                        <TableHead>
                            <TableRow>
                            <TableCell align="left">Date</TableCell>
                            <TableCell align="left">Commission (%)</TableCell>
                            </TableRow>
                            
                        </TableHead>
                        <TableBody>
                        
                            {props?.data?.filter((n:any)=>n.type?.toLowerCase()!==CommissionTypeNames.OneTimeCommission.toLowerCase()).map((x : any, i : any) => {
                            return (
                                <TableRow
                                key={ x?.effectiveDate ?? i}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                >
                                <TableCell align="left">{moment(x?.effectiveDate).format(
                                        "MMM DD, YYYY"
                                    )}</TableCell>
                                <TableCell align="left">{x?.percentage}</TableCell>
                                </TableRow>
                            );
                            })}
                        </TableBody>
                        </Table>
                    </TableContainer>
                      {props?.data?.filter((n:any)=>n.type?.toLowerCase()!==CommissionTypeNames.OneTimeCommission.toLowerCase()).length===0 && <> 
                          <Alert severity="info" style={{ marginTop: "10px",marginRight:"10px",padding:"0px 10px" }}>
                            No Records Found
                          </Alert>
                          
                          </>}
                </Grid>
          </Grid>
    </React.Fragment>
  );
};
