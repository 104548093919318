import React from 'react';
import ContractSuccessMessage from '../../Common/SignatureComponent/ContractSuccessMessage';
import { Container } from '@mui/material';
import BlankHeader from '../../Common/Navigation/BlankHeader';
import { useLocation } from 'react-router-dom';

const ContractSuccessPage: React.FC = () => {

  const location = useLocation();
  const userEmail = location.state?.email || 'N/A';
  return (
    <Container>
      <BlankHeader />
      <ContractSuccessMessage email={userEmail} />
    </Container>
  );
};

export default ContractSuccessPage;
