import { createSvgIcon } from "@mui/material/utils";

export const Attach = createSvgIcon(
  <svg viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5 3.6875C9.87157 3.6875 3.6875 9.87157 3.6875 17.5C3.6875 25.1284 9.87157 31.3125 17.5 31.3125C25.1284 31.3125 31.3125 25.1284 31.3125 17.5C31.3125 9.87157 25.1284 3.6875 17.5 3.6875ZM0.6875 17.5C0.6875 8.21471 8.21471 0.6875 17.5 0.6875C26.7853 0.6875 34.3125 8.21471 34.3125 17.5C34.3125 26.7853 26.7853 34.3125 17.5 34.3125C8.21471 34.3125 0.6875 26.7853 0.6875 17.5ZM15.8364 8.76089C18.5985 5.97108 23.0788 5.68838 25.8296 8.4668C28.5699 11.2347 28.2945 15.734 25.5384 18.5177L17.386 26.7521C15.2672 28.8922 11.7925 29.1373 9.63896 26.9621C7.49585 24.7975 7.73408 21.3139 9.84693 19.1798L16.8347 12.1218C18.3103 10.6314 20.7794 10.4239 22.3357 11.9958C23.8815 13.5572 23.6806 16.025 22.2109 17.5095L15.2232 24.5675L14.7315 25.064C14.9134 24.9487 15.0889 24.8083 15.2541 24.6414L23.4065 16.4071C25.1529 14.6431 25.1382 12.0324 23.6977 10.5775C22.2676 9.13305 19.7087 9.11368 17.9683 10.8716L9.81595 19.1059L8.76061 20.1719L6.62871 18.0612L7.68405 16.9952L15.8364 8.76089ZM13.9374 25.4053L12.0359 23.5227L13.0912 22.4568L20.079 15.3988C20.539 14.9342 20.4497 14.3549 20.2038 14.1065C19.9683 13.8686 19.4206 13.774 18.9666 14.2325L11.9788 21.2905C10.8756 22.4048 10.9277 23.9998 11.7709 24.8515C12.3018 25.3878 13.1257 25.6053 13.9374 25.4053Z"
      fill="#4540DB"
    />
  </svg>,
  "Attach"
);
