import { makeStyles } from "@material-ui/core";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import * as React from 'react';
import ApproveadminIcon from "../../assets/images/ApproveadminIcon.svg";
import Comments from "../../assets/images/Comments.svg";
import Group_524 from "../../assets/images/Group_524.svg";
import LineFeedback from "../../assets/images/LineFeedback.svg";
import convertToLocalizedDateTime from '../../utils/AppDateUtils';
import { Asterisk } from '../ui/Asterisk';
import { ternary } from "../../utils/complexityUtils";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "transparent",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary,
    boxShadow: "none",
    initComment: {
      padding: "10px",
      marginLeft: 35,
      paddingLeft: 15,
    },
  
    lastComment: {
      padding: "10px",
      marginLeft: 35,
      paddingLeft: 15,
    },
  }));

  
const useStyles = makeStyles((theme: any) => ({
    initComment: {
      padding: "10px",
      marginLeft: 35,
      paddingLeft: 10,
      fontSize:"12px"
    },
  
    lastComment: {
      padding: "10px",
      marginLeft: 35,
      paddingLeft: 10,
      fontSize:"12px"
    },
  }));

interface IPROPS {
    commentDetails : any;
    type?: any;
}
export const ClientCommentTimeline: React.FC<IPROPS> = ({
    commentDetails,
    type,
    
  }) => {

    const classes = useStyles();

    function _renderCommentRow(feedbackNote:any, index:any, row:any) {      
      return (
        <>
          <Box
            key={feedbackNote?.id ?? index}
            className={ternary(index + 1 === row.length, "", "feedback-box")}
            style={{
              position: ternary(index + 1 === row.length, "inherit", "relative"),
            }}
            >
            <Box
                sx={{
                display: "grid",
                gridTemplateColumns:
                    "repeat(2, 1fr)",
                }}
            >
                <Typography
                fontSize="larger"
                color="black"
                style={{
                    position: "relative",
                }}
                >
              {type === "client" ? (
              <>
                {feedbackNote.status === "Approved" ? (
                  <img
                    src={ApproveadminIcon}
                    alt=""
                    style={{
                      position: "absolute",
                      top: 5,
                      fontSize: "large",
                    }}
                  />
                ) : (
                  <img
                    src={Comments}
                    alt=""
                    style={{
                      position: "absolute",
                      top: 5,
                      fontSize: "large",
                    }}
                  />
                )}
              </>
            ) : (
              <img
                src={Group_524}
                alt=""
                style={{
                  position: "absolute",
                  top: 5,
                  fontSize: "large",
                }}
              />
            )}
                {type === "client" ?  <span>
                    <Typography
                    className='adminComment-heading1'
                    >
                    Admin Comments <Asterisk/>
                    </Typography>
                    <Typography
                    className="custom-admin-postdate"
                    fontSize="14px"
                    style={{
                        marginLeft: 45,
                    }}
                    >
                    Posted on{" "}
                    {convertToLocalizedDateTime(feedbackNote.createdDate,"MMMM DD,YYYY,h:mm:ss A")}
                    
                    </Typography>
                </span>:<span>
                    <Typography
                    className='adminComment-heading'
                    >
                    Admin Comments <Asterisk/>
                    </Typography>
                    <Typography
                    fontSize="12px"
                    style={{
                        marginLeft: 45,
                    }}
                    >
                    Posted on{" "}
                    {convertToLocalizedDateTime(feedbackNote.createdDate,"MMMM DD,YYYY,h:mm:ss A")}
                    </Typography>
                </span>}
                </Typography>
                {type === "client" ?  <Typography
                className="custom-adminapproved-text"
                fontSize="14px"
                sx={{
                    justifySelf: "end",
                }}
                >
                Account{" "}
                {feedbackNote.status ===
                "MarkIncomplete"
                    ? "marked incomplete"
                    : feedbackNote.status}{" "}by Admin
                </Typography>:
                <Typography
                fontSize="14px"
                sx={{
                    justifySelf: "end",
                }}
                >
                Account{" "}
                {feedbackNote.status ===
                "MarkIncomplete"
                    ? "marked incomplete"
                    : feedbackNote.status}{" "}by Admin
                </Typography>}
            </Box>
            <div
                className={
                index + 1 === row.length
                    ? classes.lastComment
                    : classes.initComment
                }
            >
                {type === "client" ? <div className="custom-feedback-txt">
                      {feedbackNote.status === "Approved" ? <span className="custom-approve-headingtxt">Approved <span style={{color:"#353535"}}>: {feedbackNote?.comment} </span>{" "}</span>:<span className="custom-reject-headingtxt">Rejected <span style={{color:"#353535"}}>:   {feedbackNote?.comment}</span> {" "}</span>}
                {
                feedbackNote.feedbackDescription
                }
                <img src={LineFeedback} width="100%" />

                </div>:
                <div>
                {
                feedbackNote.feedbackDescription
                }
                </div>}
                {feedbackNote?.reasons?.length > 0 ?
                <>
                    <div className='reasonComment-heading'>Reasons</div>
                        <ul className='rec-verify-reasons-ul-list'>
                        { feedbackNote?.reasons?.map((x : any,i : any) => (
                            <li key={ x?.id ?? i}>
                                {x.reason}
                            </li>
                        ))}
                        </ul>
                </>
                : ""
                }
            </div>
            
            </Box>
        </>
      );
    }



    return(<>
        {commentDetails?.length > 0 &&
           <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                <Box>
                    <>
                    <Paper
                        sx={{
                        padding:"12px 16px"
                        }}
                    >
                       { type === "client" ? <Typography
                        color={"primary"}
                        className='admin-feedbckComment-heading1'
                        >
                        Feedback Comments{" "}
                        </Typography>:
                        <Typography
                        color={"primary"}
                        className='admin-feedbckComment-heading'
                        >
                        Feedback/Comments{" "}
                        <span className='feedbackcomment-downArrow'>
                            <KeyboardArrowDownIcon />
                        </span>
                        </Typography>}
                    

                    <Grid
                        item
                        xs={12}
                        md={12}
                        style={
                            commentDetails
                            .length > 5
                            ? {
                                maxHeight: "437px",
                                overflowY: "auto",
                                scrollBehavior: "auto",
                            }
                            : {}
                        }
                    >
                        <Item elevation={1}>
                        { commentDetails.length? commentDetails
                         .slice() // Create a shallow copy of the array to avoid mutating the original
                         .sort((a:any, b:any) => new Date(b.createdDate) - new Date(a.createdDate)) // Sort by createdDate in descending order
                         .map((
                              feedbackNote: any,
                              index: any,
                              row: any
                            ) => (
                              <>
                                {_renderCommentRow(feedbackNote, index, row)}
                              </>
                            )
                          ) 
                          : ""
                        }
                        </Item>
                    </Grid>
                    </Paper>
                    </>                    
                </Box>
                </Grid>
            </Grid>
          
            }
    </>);
}
