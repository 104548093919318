import React from "react";
import Typography from "@mui/material/Typography";
import {
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import {
  FormikValues,
  FormikErrors,
  FormikTouched,
} from "formik";
import convertToLocalizedDateTime from "../../../../../utils/AppDateUtils";
import { formatTime } from "../../../../../utils/dateUtils";
import { ErrorMessage } from "../../../../ui/ErrorMessage";
import { valueOrDefault } from "../../../../../utils/complexityUtils";

interface AvailabilityProps {
  formik: {
    values: FormikValues;
    errors: FormikErrors<any>;
    touched: FormikTouched<any>;
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean
    ) => void;
  };
  currentAvailabilities:any;
  isChecked:boolean;
}

const ChooseAvailability: React.FC<AvailabilityProps> = ({ 
  formik,
  currentAvailabilities,
  isChecked
}) => {

  
  return (
    <>
    <Typography variant="h6" mb={2} mt={2}>
        Availability
    </Typography>
    <RadioGroup
          aria-label={`availabilityRadio`}
          name={`availabilityRadio`}
        >
      {currentAvailabilities?.map((row:any, index:number) => (
        
            <Grid container spacing={2} key={row?.id ?? index}>
              <Grid item xs={12} sm={12} md={0.5} mb={2}>
                <FormControlLabel
                    name="finalizedAvailabilityId"
                    value={`${valueOrDefault(row?.id, "")}`}
                    disabled={isChecked}
                    control={
                      <Radio 
                        readOnly={isChecked}
                        checked={(row?.id === formik.values?.finalizedAvailabilityId)}
                        onChange={(event)=>{
                          formik?.setFieldValue("finalizedAvailabilityId",valueOrDefault(row?.id, ""));
                        }}
                      />
                    }
                    label=""
                />
              </Grid>
              <Grid item xs={12}  sm={4} md={3.8}>
                <InputLabel required shrink={true}>
                  Availability {index + 1}
                </InputLabel>
                <Typography variant="body1">
                  {
                    convertToLocalizedDateTime(row?.interviewDate, "MMMM DD,YYYY")
                  }
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <InputLabel required shrink={true}>
                  Time
                </InputLabel>
                <Typography variant="body1">
                { formatTime(valueOrDefault(row?.interviewTime, ""))}
                  </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={3.2}>
                <InputLabel required shrink={true}>
                  Duration
                </InputLabel>
                <Typography variant="body1">
                { valueOrDefault(row?.interviewDuration, "")}
                </Typography>
              </Grid>
          </Grid>
        
      ))}
      </RadioGroup>
      <ErrorMessage 
        errorText={
          (!isChecked) 
          && (formik.touched?.finalizedAvailabilityId)
          && (formik.values?.finalizedAvailabilityId === undefined 
            || formik.values?.finalizedAvailabilityId === ""
          ) 
          && 'Please select availability option or choose manually below'
        }
      >
      </ErrorMessage>
    </>
  );
};

export default ChooseAvailability;
