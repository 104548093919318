import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import verifiedIcon from "./../../assets/images/verified.svg";

import { ImageInfo } from "../../models/ImageInfo";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, Input, InputLabel } from "@material-ui/core";
import UploadPhotoDialog from "../Common/UploadPhotoDialog";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Alert, Avatar, Badge, CircularProgress } from "@mui/material";
import { AccountDetails } from "../../models/AccountDetails";
import {
  GetRecruiterById,
  UpdateRecruiterAccountDetails,
  UpdateRecruiterPhoneVerificationStatus,
} from "../../Services/recruiterService";
import { plainToInstance } from "class-transformer";
import { RecruiterResponse } from "../../models/RecruiterResponse";
import {
  disableEdit,
  enableEdit,
  toggleEdit,
} from "../../features/editMode/editModeSlice";
import { ErrorMessage } from "../ui/ErrorMessage";
import { UploadPhoto as UploadPhotoIcon } from "../Common/Icons/UploadPhoto";
import {
  firstNameRegex,
  middleNameRegex,
  phoneNumberRegex,
} from "../../constants/regex";
import { CustomAlert } from "../ui/CustomAlert";
import { CustomEmailInput } from "../ui/CustomEmailInput";
import { AuthenticateToken } from "../../Services/resetPasswordService";
import { emailVerificationLinkExpireMsg } from "../Common/ExpireLinks/expireMessages";
import PhoneInput from "react-phone-input-2";
import { preferredCountries } from "../../data/phoneInput/preferredCountries";
import PhoneVerify from "../Common/PhoneVerify/PhoneVerify";
import { loadImage } from "../../utils/getFileFromUrl";
import { updateImage } from "../../features/recruiterSlice";
import SnackbarPopup from "../Common/Popup/snackbar/SnackbarPopup";
import camera from "./../../assets/images/Camera.svg";
import { isValidPhoneNumber } from "react-phone-number-input";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
interface Props {
  getUserProfile: () => void;
  refresh: boolean;
  doRefresh: () => void;
  isVerifyTrue: boolean;
  verificationType: string | null;
  verifiedUserId: string | null;
  IsNotNow: boolean;
  hasAuthToken: string | null;
  emailVerificationLinkExpired: boolean;
}
const AccountDetailsForm: React.FC<Props> = ({
  refresh,
  doRefresh,
  isVerifyTrue,
  verificationType,
  verifiedUserId,
  IsNotNow,
  hasAuthToken,
  emailVerificationLinkExpired,
}) => {
  const editMode = useSelector((state: any) => state.editMode);
  const dispatch = useDispatch();
  const [existingVerifiedEmail, setExistingVerifiedEmail] = React.useState("");
  const [authToken, setAuthToken] = React.useState(hasAuthToken);

  const [pageStatus, setPageStatus] = React.useState({
    isSuccess: false,
    error: "",
    loading: false,
  });
  const countryShort = "us";
  const [userProfile, setUserProfile] = React.useState({
    id: "",
    firstName: "",
    lastName: "",
    middleName: "",
    emailID: "",
    imageInfo: {
      fileName: "",
      newFileName: "",
      imageUrl: "",
    },
    isUpdateEmail: false,
    phoneNumbers: {
      primary: "",
      secondary: "",
      isPrimaryVerified: false,
      isSecondaryVerified: false,
    },
  });
  const [imgData, setImageData] = React.useState("");

  React.useEffect(() => {
    dispatch(enableEdit());
  }, []);
  React.useEffect(() => {
    GetProfile();
  }, [refresh]);
  const GetProfile = () => {
    let userId = JSON.parse(localStorage.getItem("user")!)?.userId;
    GetRecruiterById(userId)?.then((res) => {
      const recruiter = plainToInstance(RecruiterResponse, res?.data);
      setExistingVerifiedEmail(recruiter?.entity?.email);
      setAuthToken(recruiter?.entity?.emailChangeRequest?.authToken);
      setUserProfile({
        id: recruiter?.entity?.id,
        firstName: recruiter?.entity?.firstName,
        lastName: recruiter?.entity?.lastName,
        middleName: recruiter?.entity?.middleName,
        emailID:
          verificationType === "emailVerification" &&
          recruiter.entity?.emailChangeRequest?.isActive &&
          !IsNotNow &&
          emailVerificationLinkExpired === false
            ? recruiter?.entity?.emailChangeRequest?.email
            : recruiter?.entity?.email,
        imageInfo: recruiter?.entity?.imageInfo,
        isUpdateEmail: isVerifyTrue,
        phoneNumbers: recruiter?.entity?.phoneNumbers,
      });
      //if not present in response set false
      formik.setFieldValue(
        "phoneNumbers.isPrimaryVerified",
        recruiter?.entity?.phoneNumbers?.isPrimaryVerified || false
      );
      formik.setFieldValue(
        "phoneNumbers.isSecondaryVerified",
        recruiter?.entity?.phoneNumbers?.isSecondaryVerified || false
      );
    });
  };
  let initialState: AccountDetails = userProfile;
  const AccountSchema = Yup.object({
    id: Yup.string(),
    firstName: Yup.string()
      .required("Please enter the first name")
      .matches(firstNameRegex, "Entered first name is not valid"),
    middleName: Yup.string().matches(
      middleNameRegex,
      "Entered middle name is not valid"
    ),
    lastName: Yup.string()
      .required("Please enter the last name")
      .matches(firstNameRegex, "Entered last name is not valid"),
    emailID: Yup.string().email().required(),
    phoneNumbers: Yup.object().shape({
      primary: Yup.string()
        .required("Please enter the phone number")
        .matches(phoneNumberRegex, "Entered Phone number is invalid"),
      isPrimaryVerified: Yup.boolean().oneOf(
        [true],
        "Please verify the primary phone number."
      ),
      secondary: Yup.string().matches(
        phoneNumberRegex,
        "Entered alternate phone is invalid"
      ),
    }),
  });
  const formik = useFormik({
    initialValues: initialState,
    enableReinitialize: true,
    validationSchema: AccountSchema,
    onSubmit: async (values) => {
      setPageStatus({ isSuccess: false, error: "", loading: true });
      values.isUpdateEmail = isVerifyTrue;
      try {
        const response = await UpdateRecruiterAccountDetails(values);
        if (response.data.status == 200) {
          dispatch(updateImage({ imageUrl: values?.imageInfo?.imageUrl }));
          UpdateRecruiterPhoneVerificationStatus({
            id: values.id,
            isPrimaryVerified: values.phoneNumbers.isPrimaryVerified,
            isSecondaryVerified: values.phoneNumbers.isSecondaryVerified,
          }).then((response1) => {
            if (response1.data.status != 200) {
              setPageStatus({
                isSuccess: false,
                error: response1.data?.message?.appStatusDescription,
                loading: false,
              });
            }
          });
          setPageStatus({ isSuccess: true, error: "", loading: false });
          dispatch(disableEdit());
          setUserProfile({
            id: values.id,
            firstName: values.firstName,
            lastName: values.lastName,
            middleName: values.middleName,
            emailID: values.emailID,
            imageInfo: values.imageInfo,
            isUpdateEmail: isVerifyTrue,
            phoneNumbers: values.phoneNumbers,
          });
          doRefresh();
        } else {
          setPageStatus({
            isSuccess: false,
            error: response.data?.message?.appStatusDescription,
            loading: false,
          });
        }
      } catch (er: any) {
        setPageStatus({
          isSuccess: false,
          error: er.message,
          loading: false,
        });
      }
    },
  });

  const checkForLinkExpire = (isUpdateDetails: boolean) => {
    setPageStatus({ isSuccess: false, error: "", loading: true });
    let payloadBody = {
      id: userProfile.id,
      firstName: userProfile.firstName,
      lastName: userProfile.lastName,
      middleName: userProfile.middleName,
      emailID: userProfile.emailID,
      imageInfo: userProfile.imageInfo,
      phoneNumbers: userProfile?.phoneNumbers,
      isUpdateEmail: true,
    };

    AuthenticateToken(authToken ?? "").then((res) => {
      if (res.status == 200 && res?.data?.entity?.isExpired === false) {
        if (isUpdateDetails === true) {
          UpdateRecruiterAccountDetails(payloadBody)
            .then((response) => {
              if (response.data.status == 200) {
                setPageStatus({ isSuccess: true, error: "", loading: false });
                dispatch(disableEdit());
                doRefresh();
              } else {
                setPageStatus({
                  isSuccess: false,
                  error: response.data?.message?.appStatusDescription,
                  loading: false,
                });
              }
            })
            .catch((er) => {
              setPageStatus({
                isSuccess: false,
                error: er.message,
                loading: false,
              });
            });
        }
      } else {
        setUserProfile({
          ...userProfile,
          emailID: existingVerifiedEmail,
        });
        setPageStatus({
          isSuccess: false,
          error: emailVerificationLinkExpireMsg,
          loading: false,
        });
      }
    });
  };

  useEffect(() => {
    if (isVerifyTrue === true) {
      if (verifiedUserId === userProfile?.id) {
        let isUpdateDetails = true;
        checkForLinkExpire(isUpdateDetails);
      }
    }
  }, [isVerifyTrue, userProfile?.id]);

  useEffect(() => {
    if (IsNotNow === true) {
      formik.setFieldValue("emailID", existingVerifiedEmail);
    }
  }, [IsNotNow]);

  const [open, setOpen] = React.useState(false);
  const handleOpenCloseDialog = (imageInfo: ImageInfo) => {
    if (imageInfo != undefined) {
      setUserProfile({
        ...userProfile,
        imageInfo: {
          fileName: imageInfo.fileName,
          imageUrl: imageInfo.imageUrl,
          newFileName: imageInfo.newFileName,
        },
      });
    }
    if (imageInfo == undefined) {
      formik.values.imageInfo = new ImageInfo();
      setUserProfile({ ...userProfile, imageInfo: new ImageInfo() });
    }
    setOpen(!open);
  };
  const onPrimaryPhChange = (ev: any) => {
    if (formik.values.phoneNumbers?.primary.replace(/-/g, "") != ev) {
      formik.setFieldValue("phoneNumbers.isPrimaryVerified", false);
    }
    if (userProfile.phoneNumbers?.primary.replace(/-/g, "") == ev) {
      formik.setFieldValue(
        "phoneNumbers.isPrimaryVerified",
        userProfile.phoneNumbers.isPrimaryVerified
      );
    }
    formik.setFieldValue("phoneNumbers.primary", ev);
  };
  const onSecondaryPhChange = (ev: any) => {
    if (formik.values.phoneNumbers?.secondary.replace(/-/g, "") !== ev) {
      formik.setFieldValue("phoneNumbers.isSecondaryVerified", false);
    }
    if (userProfile.phoneNumbers?.secondary.replace(/-/g, "") === ev) {
      formik.setFieldValue("phoneNumbers.isSecondaryVerified", true);
    } else {
      formik.setFieldValue("phoneNumbers.isSecondaryVerified", false);
    }
    formik.setFieldValue("phoneNumbers.secondary", ev);
  };
  const props_Phone1Verify = {
    userId: formik.values.id,
    phoneNumber: formik.values.phoneNumbers?.primary,
    isPrimaryPhone: true,
    otherPhoneVerifyStatus: formik.values.phoneNumbers?.isSecondaryVerified,
    updatePhoneVerify: (isVerified: any) => {
      formik.setFieldValue("phoneNumbers.isPrimaryVerified", isVerified);
    },
  };
  const props_Phone2Verify = {
    userId: formik.values.id,
    phoneNumber: formik.values.phoneNumbers?.secondary,
    isPrimaryPhone: false,
    otherPhoneVerifyStatus: formik.values.phoneNumbers?.isPrimaryVerified,
    updatePhoneVerify: (isVerified: any) => {
      formik.setFieldValue("phoneNumbers.isSecondaryVerified", isVerified);
    },
  };

  useEffect(() => {
    loadImage(userProfile.imageInfo?.imageUrl, setImageData);
  }, [userProfile]);


  function renderVerifiedIcon() {
    return (
      <>
      {(userProfile.phoneNumbers?.isPrimaryVerified &&
        !editMode.isEditable) ||
        (editMode.isEditable &&
        (formik.values.phoneNumbers?.isPrimaryVerified ?? false)) ? (
        <img
          style={{
            paddingLeft: "10px",
            position: "absolute",
            left: "100.28%",
            right: "-6.31%",
            top: "26%",
            bottom: "-7.5%",
          }}
          src={verifiedIcon}
          className="custom-email-verf-icon"
        />
      ) : null}
      </>
    );
  }


  return (
    <>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} p={0} order={{xs:1, sm:1}}>
          {emailVerificationLinkExpired && (
            <Alert severity="error">{emailVerificationLinkExpireMsg}</Alert>
          )}
          {pageStatus?.error && (
            <CustomAlert
              key={1}
              type={"error"}
              message={pageStatus.error}
              onClose={() => {}}
            />
          )}
          {pageStatus.isSuccess && (
            <SnackbarPopup
              open={pageStatus.isSuccess}
              message={"Account information updated successfully"}
              onClose={() => {
                setPageStatus({ ...pageStatus, isSuccess: false });
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={4} order={{xs:3, sm:2}}>
          <Box className="ManageProfileActionBtnToolbar">
            <Button
              variant="outlined"
              color="primary"
              disableRipple={true}
              className="uploadPhoto-btn"
              disabled={!editMode.isEditable}
              onClick={() => setOpen(!open)}
              data-testid="renderProfilePicture"
            >
              <RenderPhoto userProfile={userProfile} imgData={imgData} />
              <span className="uploadPhotoText">Upload Photo</span>
            </Button>
          </Box>
          {open && (
            <UploadPhotoDialog
              handleOpenClose={handleOpenCloseDialog}
              open={open}
            />
          )}
        </Grid>
        <Grid item xs={12} md={8} order={{xs:2, sm:3}}>
          {editMode.isEditable && (
            <Box className="manage-profile-btns">
              <Button
                className="manage-profile-btnCancel"
                variant="outlined"
                onClick={() => {
                  dispatch(toggleEdit());
                  formik.setValues(userProfile);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  console.log(formik.errors);
                  formik.handleSubmit();
                }}
                variant="contained"
                disabled={formik.isSubmitting}
                endIcon={
                  formik.isSubmitting ? (
                    <CircularProgress size={"16px"} color={"inherit"} />
                  ) : (
                    ""
                  )
                }
                data-testid="btn-update-profile"
              >
                Update Profile
              </Button>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={12} order={{xs:4, sm:4}}>
          <Item
            elevation={0}
            sx={{
              backgroundColor: "transparent",
              textAlign: "left",
              padding: 0,
            }}
          >
            {" "}
            <Box
              component="form"
              noValidate
              autoComplete="off"
              sx={{
                flexDirection: "row",
                "& .MuiFormControl-root": {
                  marginTop: "3px",
                  marginBottom: "3px",
                },
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <FormControl
                    required
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    variant="standard"
                    fullWidth
                  >
                    <InputLabel htmlFor="firstName">First Name</InputLabel>
                    <Input
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.firstName}
                      name="firstName"
                      type="text"
                      readOnly={!editMode.isEditable}
                    />
                    <ErrorMessage
                      errorText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl
                    variant="standard"
                    error={
                      formik.touched.middleName &&
                      Boolean(formik.errors.middleName)
                    }
                    fullWidth
                  >
                    <InputLabel htmlFor="middleName">Middle initial</InputLabel>
                    <Input
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.middleName}
                      name="middleName"
                      type="text"
                      readOnly={!editMode.isEditable}
                    />
                    <ErrorMessage
                      errorText={
                        formik.touched.middleName && formik.errors.middleName
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl
                    required
                    error={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    variant="standard"
                    fullWidth
                  >
                    <InputLabel htmlFor="lastName">Last Name</InputLabel>
                    <Input
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.lastName}
                      name="lastName"
                      type="text"
                      readOnly={!editMode.isEditable}
                    />
                    <ErrorMessage
                      errorText={
                        formik.touched.lastName && formik.errors.lastName
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl required fullWidth>
                    <InputLabel htmlFor="emailID">Your email</InputLabel>
                    <CustomEmailInput
                      name="emailID"
                      value={formik.values.emailID}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      readOnly={true}
                      isverified={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={8} md={4}>
                  <InputLabel shrink={true} required>
                    Primary Phone
                  </InputLabel>
                  <FormControl
                    variant="standard"
                    error={
                      formik.touched.phoneNumbers?.primary
                        ? Boolean(formik.errors.phoneNumbers?.primary) ||
                          Boolean(formik.errors.phoneNumbers?.isPrimaryVerified)
                        : false
                    }
                    required
                    fullWidth
                  >
                    <PhoneInput
                      inputProps={{ "data-testid": "primaryPhone" }}
                      disabled={!editMode.isEditable}
                      containerClass="phoneCountryCustom"
                      placeholder="Phone"
                      country={countryShort}
                      onChange={(ev: any) => {
                        formik.setFieldValue("phoneNumbers.primary", ev);
                        onPrimaryPhChange(ev);
                      }}
                      onBlur={() => {
                        formik.setFieldTouched("phoneNumbers.primary", true);
                      }}
                      value={formik.values.phoneNumbers?.primary}
                      preferredCountries={preferredCountries}
                      preserveOrder={["preferredCountries"]}
                    />

                    {renderVerifiedIcon()}

                    <ErrorMessage
                      errorText={
                        formik.touched.phoneNumbers?.primary
                          ? formik.errors.phoneNumbers?.primary ||
                            formik.errors.phoneNumbers?.isPrimaryVerified
                          : ""
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4} md={2} style={{ padding: "16px 0 0 5px" }}>
                  {formik.touched.phoneNumbers?.primary &&
                  !Boolean(formik.errors.phoneNumbers?.primary) &&
                  !formik.values.phoneNumbers?.isPrimaryVerified && isValidPhoneNumber('+'+ formik.values.phoneNumbers?.primary) ? (
                    <PhoneVerify {...props_Phone1Verify}></PhoneVerify>
                  ) : null}
                </Grid>
                <Grid item xs={8} md={4}>
                  <InputLabel shrink={true}>Alternate Phone</InputLabel>
                  <FormControl
                    variant="standard"
                    error={
                      formik.touched.phoneNumbers?.primary &&
                      Boolean(formik.errors.phoneNumbers?.primary)
                    }
                    required
                    style={{ marginRight: "0px" }}
                    fullWidth
                  >
                    <PhoneInput
                      inputProps={{ "data-testid": "AlternatePhone" }}
                      disabled={!editMode.isEditable}
                      containerClass="phoneCountryCustom"
                      placeholder="Alternate Phone"
                      country={countryShort}
                      onChange={(ev) => {
                        onSecondaryPhChange(ev);
                      }}
                      onBlur={() => {
                        formik.setFieldTouched("phoneNumbers.secondary", true);
                      }}
                      value={formik.values.phoneNumbers?.secondary}
                      preferredCountries={preferredCountries}
                      preserveOrder={["preferredCountries"]}
                    />

                    {formik.values.phoneNumbers?.isSecondaryVerified &&
                    formik.values.phoneNumbers?.secondary !== "" ? (
                      <img
                        style={{
                          paddingLeft: "10px",
                          position: "absolute",
                          left: "100.28%",
                          right: "-6.31%",
                          top: "26%",
                          bottom: "-7.5%",
                        }}
                        src={verifiedIcon}
                        className="custom-email-verf-icon"
                      ></img>
                    ) : null}
                    <ErrorMessage
                      errorText={
                        formik.touched.phoneNumbers?.secondary &&
                        formik.errors.phoneNumbers?.secondary
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4} md={2} style={{ padding: "16px 0 0 5px" }}>
                  {(formik.touched.phoneNumbers?.secondary || isValidPhoneNumber('+'+ formik.values.phoneNumbers?.secondary)) &&
                  isValidPhoneNumber('+'+ formik.values.phoneNumbers?.secondary) &&
                  !Boolean(formik.errors.phoneNumbers?.secondary) &&
                  !formik.values.phoneNumbers?.isSecondaryVerified && isValidPhoneNumber('+'+ formik.values.phoneNumbers?.secondary) ? (
                    <PhoneVerify {...props_Phone2Verify}></PhoneVerify>
                  ) : null}
                </Grid>
              </Grid>
            </Box>
          </Item>
        </Grid>
      </Grid>
    </>
  );
};

export default AccountDetailsForm;

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
}));

// Define RenderPhoto component separately
function RenderPhoto({ userProfile, imgData }) {
  if (
    userProfile !== undefined &&
    userProfile.imageInfo?.imageUrl &&
    imgData !== ""
  ) {
    return (
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeContent={<SmallAvatar alt="Upload Photo" src={camera} />}
      >
        <Avatar
          sx={{ width: 120, height: 120 }}
          alt="image"
          src={userProfile && userProfile.imageInfo && imgData}
        >
          {userProfile?.firstName?.charAt(0)?.toUpperCase() +
            userProfile?.lastName?.charAt(0)?.toUpperCase()}
        </Avatar>
      </Badge>
    );
  } else {
    return <UploadPhotoIcon />;
  }
}
