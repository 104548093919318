import { createSlice } from "@reduxjs/toolkit";


let initialState = {
  recruiterGuid: "",
  jobId: "",
  searchKeywords: JSON.parse(localStorage.getItem("archivedRecruiterSearchData"))?.searchKeywords ?? "",
  location: [],
  statuses: [],
  statusIds: JSON.parse(localStorage.getItem("archivedRecruiterSearchData"))?.statusClearAll ?  [] : JSON.parse(localStorage.getItem("archivedRecruiterSearchData"))?.statusIds ?? [],
  contractType: [],
  showPendingSubmissions: false,
  pageIndex: 1,
  pageSize: 11,
  sortColumn: "createdDate",
  sortOrder: "Descending",
  isSorted: true,
  totalRecord: 0,
  durationFilter: JSON.parse(localStorage.getItem("archivedRecruiterSearchData"))?.durationFilter ?? {
    duration: '0',
    durationType: "All",
    fromDate : "",
    toDate : ""
  },
  candidateGuids:JSON.parse(localStorage.getItem("archivedRecruiterSearchData"))?.candidateGuids  ?? [] ,
  recruiterFilter: [],
  selectedCandidateFilter:JSON.parse(localStorage.getItem("archivedRecruiterSearchData"))?.selectedCandidateFilter  ?? [],
  clientNames: JSON.parse(localStorage.getItem("archivedRecruiterSearchData"))?.clientNames ?? [] ,
  selectedClientDetails: JSON.parse(localStorage.getItem("archivedRecruiterSearchData"))?.selectedClientDetails ?? [],
}
export const rejectedRecruiterSubmissionSlice = createSlice({
  name: "rejectedRecruiterSubmissionSlice",
  initialState,
  reducers: {
    setRecruiterSubmissionState: (state,action) => {
        console.log(action);
        return action.payload;

      
    },
    resetRejectedSubmission: (state) => initialState,
    setRecruiterGuid: (state, action) => {
      state.recruiterGuid = action.payload;
    }

},
  
});

// Action creators are generated for each case reducer function
export const {setRecruiterSubmissionState,resetRejectedSubmission,setRecruiterGuid } =
  rejectedRecruiterSubmissionSlice.actions;

export default rejectedRecruiterSubmissionSlice.reducer;