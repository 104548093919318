export type JobStatus = 
  | 'Active'
  | 'Close'
  | 'Deleted'
  | 'Hold'
  | 'Inactive'
  | 'Job Cancel'
  | 'Pending'
  | 'Placed'
  | 'Waiting for client feedback';
 
  export const getJobStatusColor = (status: JobStatus): any => {
  switch (status) {
    case 'Active':
      return '#26af89'; // Green
    case 'Close':
      return '#2196F3'; // Blue
    case 'Deleted':
      return '#F44336'; // Red
    case 'Hold':
      return '#FFC107'; // Amber
    case 'Inactive':
      return '#9E9E9E'; // Grey
    case 'Job Cancel':
      return '#FF5722'; // Deep Orange
    case 'Pending':
      return '#FF9800'; // Orange
    case 'Placed':
      return '#8BC34A'; // Light Green
    case 'Waiting for client feedback':
      return '#FFEB3B'; // Yellow
    default:
      return '#000000'; // Black for unknown status
  }
};
 
