import TextField from '@material-ui/core/TextField';
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from "@mui/material/Paper";
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import PlacesAutocomplete from "react-places-autocomplete";
import * as yup from 'yup';
import { GetAutoSuggessionJobAndClientName } from "../../Services/jobService";
import { RecruiterStatus } from "../../models/enumerations";
import { UserAuth } from "../../models/interfaces";
import getAddressForAutoComplete from "../../utils/getAddressForAutoComplete";
import getLabel from "../../utils/getLabelForAutoComplete";
import './CollapsibleAdvanceSearch.scss';

const useStyles = makeStyles({
  option: {
    padding: "0px",
    margin: "1px !important"
  }
});

const validationSchema = yup.object({
  minimumPayRate: yup
    .number()
    .min(0, 'Min fields should accept minimum value as 0'),
  maximumPayRate: yup
      .number()
      .min(0, 'Max fields should accept minimum value as 0 ')
      .test('superior', 'Max. payrate must be greater than Min. payrate', function (f) {
        const ref = yup.ref('minimumPayRate');
      return (!isNaN(f) && !isNaN(this.resolve(ref))) ? parseInt(f,10) >= parseInt(this.resolve(ref),10) : true
      }),
});

function useComponentVisible(initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] = useState(
    initialIsVisible
  );
  const ref = useRef(null);

  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setIsComponentVisible(false);
    }
  };

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
}

const CollapsibleAdvanceSearch = (props)=>{
    const classes = useStyles();

    const {setIsComponentVisible} = useComponentVisible(false);

    const [jobTitleOption,setJobTitleOption] = useState([]);
    const [clientOption,setClientOption] = useState([]);
    const [activeAutoSuggession, setActiveAutoSuggession] = useState("");
    const [searchAutoSuggestJobtitleText, setSearchAutoSuggestJobtitleText] = useState("");
    const [searchAutoSuggestClientText, setSearchAutoSuggestClientText] = useState("");

    const [location, setLocation] = useState(props.AdvanceSearchFilterData.location)

    const [jobTitleSearchOptionList, setJobTitleSearchOptionList] = useState(props.AdvanceSearchFilterData?.jobTitle || []);
    const [clientSearchOptionList, setClientSearchOptionList] = useState(props.AdvanceSearchFilterData?.client || []);
    const [locationSearchOptionList, setLocationSearchOptionList] = useState(props.AdvanceSearchFilterData?.location || []);
    const [autoCompleteSuggesions, setautoCompleteSuggesions] = useState("")
    
    // call autosuggesstion for jobTitle field
    useEffect(()=>{
      setActiveAutoSuggession("jobTitle");
      GetAutoSuggessionJobAndClient({
          jobTitle:searchAutoSuggestJobtitleText,
          client:""
      })
    },[searchAutoSuggestJobtitleText]);

    useEffect(()=>{
      console.log(locationSearchOptionList);
    },[locationSearchOptionList]);

    // call autosuggesstion for  field hide
    useEffect(()=>{
        setIsComponentVisible(true);
    },[location]);

    // Call autosuggesstion for client field
    useEffect(()=>{
      setActiveAutoSuggession("client");
      GetAutoSuggessionJobAndClient({
          jobTitle:"",
          client:searchAutoSuggestClientText
      })
    },[searchAutoSuggestClientText]);
    
    const formik = useFormik({
        initialValues: {
            jobTitle: props.AdvanceSearchFilterData.jobTitle,
            location:props.AdvanceSearchFilterData.location,
            client:props.AdvanceSearchFilterData.client,
            minimumPayRate:(props.AdvanceSearchFilterData.minimumPayRate !==0 ? props.AdvanceSearchFilterData.minimumPayRate : '') ,
            maximumPayRate:(props.AdvanceSearchFilterData.maximumPayRate !==0 ? props.AdvanceSearchFilterData.maximumPayRate : '' ) ,
            currency:props.AdvanceSearchFilterData.currency
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
          values = {...values,
              jobTitle: jobTitleSearchOptionList,
              location:locationSearchOptionList,
              client:clientSearchOptionList
          }
          
          if(values.currency ==='All'){
            values = {...values, jobTitle: jobTitleSearchOptionList, currency:''}
          }

          props.handleAdvancedSearchClick(values)
        },
      });

      const resetAdvancedSearch = ()=>{
          formik.resetForm({
              values: {
                jobTitle: [],
                location:[],
                client:[],
                minimumPayRate:'',
                maximumPayRate:'',
                currency:''
              }});

          setLocation('');
        setJobTitleSearchOptionList([]);
        setClientSearchOptionList([]);
        setLocationSearchOptionList([]);
        props.clearAdvancedSearch();
      }      

      const GetAutoSuggessionJobAndClient = (SearchData) => {
        GetAutoSuggessionJobAndClientName(SearchData)
          .then((response) => {
           if(activeAutoSuggession ==="jobTitle"){
                setJobTitleOption(response.data.entity.suggestion)
                setClientOption([])
           }else if(activeAutoSuggession ==="client"){
                setClientOption(response.data.entity.suggestion)
                setJobTitleOption([])
           }
          })
          .catch((error) => {
            console.log(error, error.request?.status);
          })
      };

      const defaultPropsJobTitle = {
            options: jobTitleOption,
            getOptionLabel: (option) => option,
        };

        const defaultPropsClient = {
            options: clientOption,
            getOptionLabel: (option) => option,
        };

        const renderPreferredGeographies = ({
            getInputProps,
            suggestions,
          }) => (
           
                <Autocomplete
                  freeSolo
                  multiple
                  id="size-small-standard-multi1"
                  size="medium"
                  options={suggestions.length>0? suggestions.map((str) => ( getLabel(getAddressForAutoComplete(str.description).city,getAddressForAutoComplete(str.description).state))) : []}
                  getOptionLabel={(option) => option} 
                  onBlur={(e)=>{
                    if(e.target.value!=="")
                    {
                      setLocationSearchOptionList([...locationSearchOptionList,e.target.value])
                    }
                  }}
                  clearOnBlur
                  getOptionDisabled={(options) => (locationSearchOptionList?.length >= 5 ? true : false)}
                  value={locationSearchOptionList}
                  onChange={(event, newValue) => {
                    setLocationSearchOptionList(newValue);
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                       key={ option ?? index}
                        color="primary"
                        size="medium"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...getInputProps()}
                      variant="standard"
                      inputProps={{
                        ...params.inputProps,
                        onKeyDown: (e) => {
                          if (locationSearchOptionList?.length >= 5 ? true : false) {
                            e.stopPropagation();
                          }
                        },
                        readOnly:locationSearchOptionList?.length >= 5 ? true : false
                      }}
                      
                    />
                  )}
                />
          );
        
    return(<>
        <Paper elevation={16} className={`${props?.classes} advanceSearchPaper ${props.pageType ? 'custom-advancesearch-top' : ''}`}>
            <Box sx={{ flexGrow: 1 }} className="advanceSearchWrapper">
                <form onSubmit={formik.handleSubmit}>
                <Grid item xs={12} className="closeArea"> 
                    <IconButton aria-label="close" size="small" className="close-Btn" onClick={props.handleClose}>
                        <CloseIcon fontSize="small" />
                     </IconButton>
                </Grid>
                    <Grid container spacing={2} pt={2}>
                        <Grid item xs={3} sm={2} style={{padding:"16px 0 0 16px"}}>
                            <Typography className="newAdvanceSearchLabel job-title-row">
                                Job Title
                            </Typography>
                        </Grid>
                        <Grid item xs={9} sm={10}>
                        <Autocomplete
                            multiple
                            freeSolo
                            id="tags-standard"
                            disableClearable
                            getOptionLabel={(option) => option.title}
                           {...defaultPropsJobTitle}
                           classes={{
                            option: classes.option
                          }}
                          onBlur={(e)=>{
                            if(e.target.value!=="")
                            {
                              setJobTitleSearchOptionList([...jobTitleSearchOptionList,e.target.value])
                              
                            }
                          }}
                          clearOnBlur
                           getOptionDisabled={(options) => (jobTitleSearchOptionList.length >= 5 ? true : false)}
                           value={jobTitleSearchOptionList}
                           onChange={(event, newValue) => {
                            setJobTitleSearchOptionList(newValue);
                           }}
                           onKeyUp={(event)=>setSearchAutoSuggestJobtitleText(event.target.value)}
                           renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    color="primary"
                                    size="medium"
                                    label={option}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                            renderInput={(params) => (
                            <TextField
                            {...params}
                            fullWidth
                            id="jobTitle"
                            name="jobTitle"
                            label=""
                            type="text"
                            value={formik.values.jobTitle}
                            onChange={formik.handleChange}
                            onSelect={formik.handleChange}
                            inputProps={{
                              ...params.inputProps,
                              onKeyDown: (e) => {
                                    if (jobTitleSearchOptionList.length >= 5 ? true : false) {
                                      e.stopPropagation();
                                    }
                              },
                              readOnly:jobTitleSearchOptionList.length >= 5 ? true : false
                            }}
                            />
                            )}
                        />

                       
                        
                        </Grid>
                        <Grid item xs={3} sm={2}>
                            <Typography className="newAdvanceSearchLabel">
                                Location
                            </Typography>
                        </Grid>
                        <Grid item xs={9} sm={10}>
                            <PlacesAutocomplete 
                              searchOptions={{ types: ['(regions)'] }}
                              value={autoCompleteSuggesions}
                              onChange={(ev) => {
                                setautoCompleteSuggesions(ev)
                              }}
                            >
                              {renderPreferredGeographies}
                            </PlacesAutocomplete>
                        </Grid>
                        {(props.recruiterStatus === RecruiterStatus.approved || props.userRole == UserAuth.Admin || props.userRole == UserAuth.SuperAdmin || props.userRole==UserAuth.ClientTeamMember )&& 
                        <>
                        { (props.userRole !== UserAuth.Client && props.userRole !== UserAuth.ClientTeamMember) &&
                        <>  
                        <Grid item xs={3} sm={2}>
                            <Typography className="newAdvanceSearchLabel">
                                Client
                            </Typography>
                        </Grid>
                        <Grid item xs={9} sm={10}>
                        <Autocomplete
                            multiple
                            freeSolo
                            id="free-solo-2-demo2"
                            disableClearable
                            getOptionLabel={(option) => option.title}
                           {...defaultPropsClient}
                           clearOnBlur
                           classes={{
                            option: classes.option
                          }}
                          onBlur={(e)=>{
                            if(e.target.value!=="")
                            {
                              setClientSearchOptionList([...clientSearchOptionList,e.target.value])
                              
                            }
                          }}
                          
                           getOptionDisabled={(options) => (clientSearchOptionList.length >= 5 ? true : false)}
                           value={clientSearchOptionList}
                           onChange={(event, newValue) => {
                            setClientSearchOptionList(newValue);
                           }}
                           onKeyUp={(event)=>setSearchAutoSuggestClientText(event.target.value)}
                           renderTags={(value, getTagProps) =>
                           value.map((option, index) => (
                                <Chip
                                    color="primary"
                                    size="medium"
                                    label={option}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                            renderInput={(params) => (
                            <TextField
                            {...params}
                            fullWidth
                            id="client"
                            name="client"
                            label=""
                            type="text"
                            value={formik.values.client}
                            onChange={formik.handleChange}
                            onSelect={formik.handleChange}
                            inputProps={{
                              ...params.inputProps,
                              onKeyDown: (e) => {
                                    if (clientSearchOptionList.length >= 5 ? true : false) {
                                      e.stopPropagation();
                                    }
                              },
                              readOnly:clientSearchOptionList.length >= 5 ? true : false
                            }}
                            />
                            )}
                        />
                        </Grid>
                        </>
                        }
                        
                        <Grid item xs={3} sm={2} md={2}>
                            <Typography className="newAdvanceSearchLabel payrate-label">
                              { (props.userRole === UserAuth.Client || props.userRole === UserAuth.ClientTeamMember) ? "Bill Rate" : "Pay Rate"} 
                            </Typography>
                        </Grid>
                        <Grid item xs={2.9} sm={3.5} md={3.7} className="payrate-inputs">
                            <TextField
                                fullWidth
                                id="minimumPayRate"
                                name="minimumPayRate"
                                label={<span className="roboto-btn">Minimum</span>}
                                type="number"
                                value={formik.values.minimumPayRate}
                                onChange={formik.handleChange}
                                error={formik.touched.minimumPayRate && Boolean(formik.errors.minimumPayRate)}
                                helperText={formik.touched.minimumPayRate && formik.errors.minimumPayRate}
                                />
                                
                        </Grid>
                        <Grid item xs={2.9} sm={3.5} md={3.7} className="payrate-inputs">
                            <TextField
                                fullWidth
                                id="maximumPayRate"
                                name="maximumPayRate"
                                label={<span className="roboto-btn">Maximum</span>}
                                type="number"
                                value={formik.values.maximumPayRate}
                                onChange={formik.handleChange}
                                error={formik.touched.maximumPayRate && Boolean(formik.errors.maximumPayRate)}
                                helperText={formik.touched.maximumPayRate && formik.errors.maximumPayRate}
                                />
                                
                        </Grid>
                        <Grid item xs={3.2}  sm={3} md={2.6} className="payrate-inputs">
                        <FormControl variant="standard" sx={{ m: 0, minWidth: (props.userRole == UserAuth.Admin || props.userRole == UserAuth.SuperAdmin ? 80 : "100%") }} className="AdvanceSearchCurrency">
                        <InputLabel id="payrateCurrency">Currency</InputLabel>
                        <Select
                            labelId="payrateCurrency"
                            id="payrateCurrency"
                            name="currency"
                            label={<span className="roboto-btn">Currency</span>}
                            value={formik.values.currency}
                            onChange={formik.handleChange}
                            className="AdvanceSearchCurrencySelect"
                            >
                            {props.CurrercyList.map((item, key) => {
                                    return (
                                        <MenuItem  key={item.currencyCode ?? key} value={ item.currencyCode}>
                                            { item.currencyCode}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        </Grid>
                        </>
}
                        <Grid item xs={12} style={{textAlign:"right"}}>
                        <Button variant="outlined" size="large" className="advanceSearchBtn advSearchBtn" style={{marginRight:"15px"}} 
                        onClick={resetAdvancedSearch}
                        >
                            Reset
                        </Button> 
                            <Button color="primary" variant="contained" type="submit" className="advanceSearchBtn advSearchBtn">
                                Search
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Paper>
    </>);

}
export default CollapsibleAdvanceSearch;