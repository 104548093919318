import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetJobs, GetSavedJobs } from "../../Services/jobService";
import { getRecruiterDashboard } from "../../Services/recruiterService";

export const recruiterDashboardActivitiesCount = createAsyncThunk(
  "recruiterDashboard",
  async (id, thunkAPI) => {
    let userId = JSON.parse(localStorage.getItem("user"))?.userId;
    if (userId) {
      return getRecruiterDashboard(userId)
        .then((response) => response.data)
        .catch((error) =>
          thunkAPI.rejectWithValue(error?.response?.data || error)
        );
    }
  }
);

export const recruiterDashboardState = createSlice({
  name: "recruiterDashboard",
  initialState: {
    data: null,
    error: null,
    success: null,
    loading: false,
    expiringJobs: [],
  },
  reducers: {
    clear: (state) => {
      state.data = null;
      state.error = null;
      state.success = null;
      state.loading = false;
    },
    setExpiringJobs: (state, { payload }) => {
      state.expiringJobs = payload.expiringJobs;
    },
    clearExpiringJobs: (state) => {
      state.expiringJobs = [];
    },
  },
  extraReducers: (builder) => {
    //Post candidate
    builder.addCase(
      recruiterDashboardActivitiesCount.pending,
      (state, action) => {
        state.loading = true;
      }
    );

    builder.addCase(
      recruiterDashboardActivitiesCount.fulfilled,
      (state, action) => {
        state.data = action.payload;
        state.error = null;
        state.success = true;
        state.loading = false;
      }
    );
    builder.addCase(
      recruiterDashboardActivitiesCount.rejected,
      (state, action) => {
        console.log(action);
        state.error = action.payload?.message
          ? action.payload.message
          : action.error.message;
        state.loading = false;
      }
    );
  },
});

export const getExpiringSavedJobs = (recruiterId) => async (dispatch) => {
 
   
    const filters = {
      pageIndex: 1,
      pageSize: 40,
      sortColumn: "jobEndDate",
      sortOrder: 0,
      isSorted: true,
      totalRecord: 0,
      jobIds: [],
      searchKeywords: "",
      domains: [],
      expiringInDays: [1,2,3,4,5,6,7],
      advanceSearch: {
        jobTitle: [],
        location: [],
        client: [],
        minimumPayRate: 0,
        maximumPayRate: 0,
        currency: "",
      },
      recruiterGuid:recruiterId
    };
    GetSavedJobs(filters).then((response) => {
     
      dispatch(setExpiringJobs({ expiringJobs: response.data.entityList }));
    });
  
};

export const getExpiringFeaturedJobs = () => async (dispatch) => {
  const filters = {
    pageIndex: 1,
    pageSize: 40,
    sortColumn: "jobEndDate",
    sortOrder: 0,
    isSorted: true,
    totalRecord: 0,
    searchKeywords: "",
    domains: [],
    expiringInDays: [1,2,3,4,5,6,7],
    showFeaturedJobs: true,
    advanceSearch: {
      jobTitle: [],
      location: [],
      client: [],
      minimumPayRate: 0,
      maximumPayRate: 0,
      currency: "",
    },
  };
  GetJobs(filters).then((response) => {
   
    dispatch(setExpiringJobs({ expiringJobs: response.data.entityList }));
  });
};

// Action creators are generated for each case reducer function
export const { clear, setExpiringJobs, clearExpiringJobs } =
  recruiterDashboardState.actions;

export default recruiterDashboardState.reducer;
