import React, { useEffect, useState } from 'react';
import jwt_decode from "jwt-decode";
import { Box, Card, CircularProgress, Container, Grid, Typography } from '@mui/material';
import SignatureComponent from '../../Common/SignatureComponent/SignatureComponent';
import ContractDocumentPreview from '../../Common/SignatureComponent/ContractDocumentPreview';
import SectionHeading from '../../ui/Form/Heading/SectionHeading';
import BlankHeader from '../../Common/Navigation/BlankHeader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { setNotLoggedUserAuthToken } from '../../../utils/setAuthToken';
import { GetRecruiterById, SetContractDetails } from '../../../Services/recruiterService';
import { ContractStatusCode } from '../components/ShareContractDemoContract/SignRecruiterContract';
import { UploadAttachment } from '../../../Services/uploadPhotoService';
import { DocumentTypeEnum } from '../../../models/interfaces';
import { GenerateRecruiterSignedContractPDF } from '../../../Services/getPdfReportService';
import AppLinkExpire from '../../Common/ExpireLinks/AppLinkExpire';
import { recruiterContractSignInLinkExpireMsg } from '../../Common/ExpireLinks/expireMessages';
import { ternary } from '../../../utils/complexityUtils';
import SnackbarPopup from '../../Common/Popup/snackbar/SnackbarPopup';

const SignaturePage: React.FC = () => {
  const navigate = useNavigate();
  const pdfPath = `${process.env.PUBLIC_URL}/documents/contract/RecruiterContract.pdf`;

  const [searchParams] = useSearchParams();
  const name = searchParams.get("name");
  const email = searchParams.get("email");
  const authToken = searchParams.get("authToken") as string;
  const decoded:any = jwt_decode(authToken);
  const userId = decoded?.Id as string
  setNotLoggedUserAuthToken(authToken);

  const [apiLoading , setApiLoading] = useState<boolean>(false);
  const [pageStatus, setPageStatus] = useState({
    isSuccess:false,
    isError:false,
    message:""
  })
  const [isLinkExpired, setIsLinkExpired] = useState<boolean | null >(null);
  const [recruiterDetails, setRecruiterDetails] = useState<any>(null);
  const [filePreview, setFilePreview] = React.useState< undefined | 
  {
    uri: string;
    fileName: string;
    fileType?: string;
  }>(undefined);

    const _setContractDetails = (pdfFile:any) => {
      let payload = {
        id: userId,
        signatureRequestId:null,
        signatureId:null,
        signUrl:"",
        contractStatusCode:ContractStatusCode.Signed,
        contractDocumentInfo: {
          resourceUrl: pdfFile.resourceUrl,
          fileName: pdfFile.fileName,
          newFileName: pdfFile.newFileName,
          documentType: DocumentTypeEnum.RecruiterSignedContract, 
        },
        redirectUrl: window.location.origin +  "/login/",
      };
        
      SetContractDetails(payload)
        .then((response) => {
          if (response && response.status === 200) {
            setPageStatus({
              ...pageStatus,
              isSuccess:true,
              message:"Contract details updated successfully"
            }); 
            navigate('/signcontractsuccess', { state: { email: email } });
          } else {
            setPageStatus({
              ...pageStatus,
              isError:true,
              message:"Failed during update signed document details"
            })
          }
        })
        .catch(() => {
          setPageStatus({
            ...pageStatus,
            isSuccess:false,
            isError:true,
            message:"Failed during update signed document details"
        });
        })
        .finally(()=>{
          setApiLoading(false);       
        })
    };

    const _GetRecruiterById = (recruiterGuid: string) => {
      if (recruiterGuid) {
        GetRecruiterById(recruiterGuid)
          .then((response) => {
            if (response.data?.message?.appStatusCode === "RC_RSF") {
              setRecruiterDetails(response?.data?.entity);
              if(response?.data?.entity?.contractDetails?.contractStatusCode === ContractStatusCode.Signed){
                setIsLinkExpired(true);
              }
              else{
                setIsLinkExpired(false);
                // set Preview of contarct document
                
                setFilePreview({
                  uri: pdfPath,
                  fileName: "RecruiterContractDocument.pdf",
                  fileType: "pdf"
                })
              }
             
            }else{
              setIsLinkExpired(true);
            }
          })
          .catch((er) => {
            console.log(er);
          });
      }
    };

    const handleOnSaveSignature = (imageData:any)=>{
      setApiLoading(true);
      setFilePreview(undefined);      
      generateScreeningPdfSave(imageData)

    }

    async function generateScreeningPdfSave(imageData:any) {
    
      let payload = {
          recruiterGuid: recruiterDetails?.id,
          recruiterId: recruiterDetails?.recruiterId,
          recruiterEmail: recruiterDetails?.email,
          recruiterFirstName: recruiterDetails?.firstName,
          recruiterMiddleName:recruiterDetails?.middleName,
          recruiterLastName: recruiterDetails?.lastName,
          signatureBase64:imageData
        }
  
      let fileResponse = await GenerateRecruiterSignedContractPDF(payload);
      if(fileResponse.statusCode === 200){
        uploadRecruiterSignedContractPdf(fileResponse?.file as File)
      }else{
          setPageStatus({
            ...pageStatus,
            isSuccess:false,
            isError:true,
            message:"Failed to generate signed document"
        });
      }      
      
    }
  
    function uploadRecruiterSignedContractPdf(pdfFile : File) {
      UploadAttachment({
        documentType: DocumentTypeEnum.Other,
        file: pdfFile, 
      })
      .then((pdfRes) => {
        if(pdfRes.data.message.appStatusCode === "RC_RSF")  {

          setPageStatus({
            ...pageStatus,
            isSuccess:true,
            isError:false,
            message:"Contract signed successfully"
        });

        _setContractDetails({
          resourceUrl:pdfRes?.data?.entity?.absoluteUri,
          newFileName:pdfRes?.data?.entity?.newFileName,
          fileName: "SignedRecruiterContractDocument.pdf",
          fileType: "application/pdf"
        });         
          
        }else{
          setPageStatus({
            ...pageStatus,
            isSuccess:false,
            isError:true,
            message:"Failed to upload signed document"
        });
        }    
        
      })
      .catch(() => {
        setPageStatus({
          ...pageStatus,
          isSuccess:false,
          isError:true,
          message:"Something went wrong!!! Failed to upload signed document"
      });
        
      })
    }

    useEffect(() => {
      if (name !== "" && email !== "") {
          _GetRecruiterById(userId);
      }
  }, []);

  useEffect(()=>{
      if(pageStatus.isSuccess || pageStatus.isError){
        setApiLoading(false); 
      }
  },[pageStatus.isSuccess, pageStatus.isError])


  return (
    <>
      <BlankHeader />
      <Container maxWidth="lg" sx={{ marginTop: 8 }}>
      { isLinkExpired 
        ?
        <Box mt={10}>
            <AppLinkExpire                 
                message={recruiterContractSignInLinkExpireMsg}
            />
        </Box>
        :
        <Grid container pt={2}>
          <Grid item xs={12}>
            <Card sx={{ marginBottom: 2, minHeight:"70vh" }}>
              <Box mt={2} mb={1} sx={{ textAlign: "center" }}>
                <SectionHeading title="Sign and Submit Recruiter Contract" />
              </Box>
              { ternary( 
                apiLoading,
                <Box sx={{display:'flex', justifyContent:"center" , marginTop:4}}>
                    <Typography >
                      Please wait ...
                      </Typography>
                    <CircularProgress size={"18px"}/>
                </Box>
                ,
                <ContractDocumentPreview 
                  filePreview={filePreview}             
                />
              )}
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Box mt={2} mb={6} sx={{ textAlign: "center" }}>              
              <SignatureComponent
                loading={apiLoading}
                onSaveSignature={handleOnSaveSignature}
              />
            </Box>
          </Grid>
        </Grid>
      }
      </Container>

      {
        pageStatus?.isError &&
        <SnackbarPopup 
          message={pageStatus.message}
          open={pageStatus?.isError} 
          onClose={()=>{
            setPageStatus({
              ...pageStatus,
              isError:false,
              isSuccess:false,
            })
          }} 
          severity="error" 
        />
      }
    </>
  );
};

export default SignaturePage;
