import { createSlice } from "@reduxjs/toolkit";
import { InterviewAvailability } from "../../models/Candidate/CandidateInterviewAvailabilityModel";

const initialState: InterviewAvailability = {
    id:"",
    submissionId: "",
    title: "",
    subject: "",
    eventType: "Interview",
    eventTypeId: "8a62656d-7c7d-4151-83a9-90544d56c482",
    interviewType: "",
    interviewTypeId: "",
    timeZone: "",
    timeZoneId: "",
    availabilities: [
      {
        interviewDate: "",
        interviewTime: "",
        interviewDuration: "",
      },
    ],
    InterviewLocation: {
      id: "",
      streetAddress: "",
      city: "",
      state: "",
      postalCode: "",
      countryCode: "",
      meetingLink: "",
    },
    reminderMinutes: undefined,
    reminderDisplayValue:"",
    description: "",
    participants: [],
    eventId: "",
    finalizedAvailabilityId:"",
    feedback: ""
  };

const CandidateInterviewAvailabilityStateSlice = createSlice({
  name: "CandidateInterviewAvailabilityStateSlice",
  initialState,
  reducers: {
    setInterviewAvailabilityState: (state, action: any) => {
      return action.payload;
    },
    resetInterviewAvailabilityState: () => {
      return initialState;
    },
  },
});

export const { setInterviewAvailabilityState, resetInterviewAvailabilityState } =
  CandidateInterviewAvailabilityStateSlice.actions;

export default CandidateInterviewAvailabilityStateSlice.reducer;
