import { useFormik } from 'formik';
import React from 'react'
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toggleEdit } from '../../../features/editMode/editModeSlice';
import { BillingInfoType, UserAuth } from '../../../models/interfaces';
import { PayoneerPaymentDetail } from '../../../models/PaymentDetails';
import { GetPayoneerPaymentDetail } from '../../../Services/invoiceManagerService';
import { AddPayoneerPaymentDetail } from '../../../Services/recruiterPaymentService';
import { PayoneerForm } from './PayoneerForm';
import payoneerSchema from './schema/payoneerForm';
import { billingDetailProps } from '../ManageProfile';
interface Props {
    editMode : any,
    billingDetail: billingDetailProps | undefined;
    recruiterData : any,
   
  }
  export const RecruiterPayoneerBilling: React.FC<Props> = ({
    editMode,
    billingDetail,
    recruiterData
  }) => {
    let initialState: PayoneerPaymentDetail = {
      userId: billingDetail?.userId!==null ? billingDetail?.userId ?? "" : '',
      email: "",
      billingInfoType: BillingInfoType[1],
      isUpdateDetails : false
     
      
    };
    const [searchParams] = useSearchParams();

    const [formState, setformState] = React.useState<any>(initialState);
    const [isChecked, setIsChecked] = React.useState(false)
    const [isAlreadySet, setIsAlreadySet] = React.useState(false)
    const [pageStatus, setPageStatus] = React.useState({
      isSuccess: false,
      error: "",
      loading: false,
    });
    const dispatch = useDispatch()

    const formik = useFormik({
      initialValues: initialState,
      enableReinitialize: true,
      validationSchema: payoneerSchema,
      onSubmit: (values, actions) => {
        
        setPageStatus({ isSuccess: false, error: "", loading: true });
        
        AddPayoneerPaymentDetail(values)
          .then((response) => {
            if (response.data.status === 200) {
              setPageStatus({ isSuccess: true, error: "", loading: false });
              dispatch(toggleEdit())
            
            } else {
              setPageStatus({
                isSuccess: false,
                error: response.data?.message?.appStatusDescription,
                loading: false,
              });
            }
          })
          .catch((er) => {
            setPageStatus({
              isSuccess: false,
              error: er.message,
              loading: false,
            });
          });
      },
    });
    const handlePaymentChange = (e:any,paymentType=null)=>{
      formik.handleChange(e)
    }
    React.useEffect(() => {
      if(searchParams.get("verificationType")=="paymentDetails")
      {
        if(recruiterData?.paymentDetailsChangeRequest?.payoneerBilling)
        {
          const recruiterPayment = recruiterData?.paymentDetailsChangeRequest?.payoneerBilling;
          if(recruiterPayment)
          {
            setIsAlreadySet(true);
            setIsChecked(true);
          }
          formik.setFieldValue('email',recruiterPayment?.email|| billingDetail?.email);
          setformState(recruiterPayment || initialState);
        }
      }
      else
      {
        GetPayoneerPaymentDetail(initialState.userId).then((response) => {
      
        
          const paymentDetails = response?.data?.entity;
          if(paymentDetails)
          {
            setIsAlreadySet(true)
            setIsChecked(true)
          }
          formik.setFieldValue('email',paymentDetails?.email|| billingDetail?.email)
          setformState(paymentDetails || initialState)
        });
      }
    }, [recruiterData, searchParams]);
  return (
    <PayoneerForm 
      onChange={handlePaymentChange} 
      viewMode={UserAuth.Recruiter}  
      editMode={editMode} 
      billingDetail={billingDetail} 
      formik={formik} 
      pageStatus={pageStatus} 
      setPageStatus={setPageStatus}
      formState={formState} 
      setIsChecked={setIsChecked} 
      isAlreadySet={isAlreadySet} 
      isChecked={isChecked}
    />

  )
}
