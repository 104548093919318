import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import Box from "@mui/material/Box";
import { Component } from "react";
import Chart from "react-apexcharts";
import Group from "./../../../assets/images/Group.svg";

class TechStatsDonutChartAdmin extends Component {
  constructor(props) {
    const {data} = props

    super(props);

    this.state = {
      options: {
        stroke: {
          width: 0,
        },
        chart: {
          type: "donut",
          offsetY: 10,

          sparkline: {
            enabled: true,
          },
        },
        colors: ["#3A36DB", "#03A89E", "#03A9F4"],
        plotOptions: {
          donut: {
            dataLabels: {
              total: {
                show: false,
              },

              value: {
                show: false,
              },
            },
          },
        },
        grid: {
          padding: {
            bottom: 20,
          },
        },
        labels:['IT','Non-IT','Healthcare']
      },
      series: [data?.it, data?.nonIT, data?.healthcare],
    };
  }

  render() {
    const labelStyles = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };

    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Chart
          style={{ position: "relative" }}
          options={this.state.options}
          series={this.state.series}
          type="donut"
          width="150"
        >
          <div
            style={{
              height: 50,
              width: 50,
              borderRadius: 25,
              backgroundColor: "#F2F3F6",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              left: 50,
              top: 38,
            }}
          ></div>
        </Chart>

        <img
          src={Group}
          alt=""
          style={{ position: "absolute", marginTop: "50px" }}
        />

        <Box
          sx={{display: "flex",justifyContent: "space-between",width: { xs: "200px !important", sm: "250px!important" }}}
        >
          <div style={labelStyles}>
            <FiberManualRecordRoundedIcon sx={{ color: "#3A36DB" }} />
            <span>IT</span>
          </div>

          <div style={labelStyles}>
            <FiberManualRecordRoundedIcon sx={{ color: "#03A89E" }} />
            <span>NON IT</span>
          </div>

          <div style={labelStyles}>
            <FiberManualRecordRoundedIcon sx={{ color: "#03A9F4" }} />
            <span>Healthcare</span>
          </div>
          </Box>
      </div>
    );
  }
}

export default TechStatsDonutChartAdmin;