import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Paper, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import { jobLocation } from "../../../utils/jobUtils";
import AppInfiniteScroll from "../../Common/AppInfiniteScroll";
import { CircularProgressWithLabel } from "../../Common/CircularProgressWithLabel";
import { GotoTop } from "../../ui/GotoTop";
import { Payrate } from "../../ui/Payrate";


interface IPROPS {
  jobDataList: any[];
  totalRecords?: number;
  fetchMoreData: any;
  hasMoreData: any;
  loading: any;
  recommendationData: any[];
  columnSort: boolean;
  handleColumnSort: any;
  onViewScoreCard: (selectedItem: any) => void;
}
export const RecommendedJobsSearchRow: React.FunctionComponent<IPROPS> = (
  props
) => {
  const navigate = useNavigate();
  return (
    <div>
      <TableContainer
        component={Paper}
        className="custom-table-compnent jobs-table"
      >
        <AppInfiniteScroll
          dataLength={100}
          next={props?.fetchMoreData}
          hasMore={
            true
          }
          isLoading={props?.loading}
          loader={true}
          classNames=""
        >
          <Typography
            style={{
              color: "#4540DB",
              fontSize: "24px",
              fontWeight: "400px",
              marginBottom: "10px",
            }}
          >
            Search Results: {props?.totalRecords} Recommended Jobs Found
          </Typography>
          <Divider
            sx={{
              borderBottomWidth: "1",
              width: "100%",
              background: "#4540DB",
            }}
          />

          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ px: 1 }}>
                  <span
                    data-testid="sort-icon-title"
                    onClick={() => props.handleColumnSort("score")}
                    style={{ display: "flex" }}
                  >
                    Job ID
                    {props.columnSort ? (
                      <KeyboardArrowUpIcon className="custom-sort-Job" />
                    ) : (
                      <KeyboardArrowDownIcon className="custom-sort-Job" />
                    )}
                  </span>
                </TableCell>
                <TableCell sx={{ px: 1 }}>
                  Type
                </TableCell>
                <TableCell sx={{ px: 1 }}>
                  <span
                    data-testid="sort-icon-title"
                    onClick={() => props.handleColumnSort("jobTitle")}
                    style={{ display: "flex" }}
                  >
                    Job Title
                    {props.columnSort ? (
                      <KeyboardArrowUpIcon className="custom-sort-Job" />
                    ) : (
                      <KeyboardArrowDownIcon className="custom-sort-Job" />
                    )}
                  </span>
                </TableCell>
                <TableCell sx={{ px: 1 }}>
                  Description{" "}
                </TableCell>
                <TableCell>
                  Client
                </TableCell>
                <TableCell sx={{ px: 1 }}>
                  Location{" "}
                </TableCell>
                <TableCell sx={{ px: 1 }}>
                  <span
                    data-testid="sort-icon-payrate"
                    onClick={() => props.handleColumnSort("packagevalue")}
                    style={{ display: "flex" }}
                  >
                    Pay Rate
                    {props.columnSort ? (
                      <KeyboardArrowUpIcon className="custom-sort-Job" />
                    ) : (
                      <KeyboardArrowDownIcon className="custom-sort-Job" />
                    )}
                  </span>
                </TableCell>
                <TableCell sx={{ px: 1 }}>Scorecard</TableCell>
                <TableCell style={{ paddingTop: "16px" }}>Action </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props?.jobDataList
                ?.map((row: any, index: any) => (
                  <TableRow
                    hover
                    key={row?.id }
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    className="cust-table-row"
                  >
                    <TableCell sx={{ whiteSpace: "nowrap", px: 1, width: 100 }}>
                      <span className="jobTableCell">{row.id}</span>
                      <div style={{ marginTop: "12px", color: "#4540DB" }}>
                        {" "}
                        <CircularProgressWithLabel
                          className="Custom-hover-effect"
                          value={row.score}
                          size="50px"
                          textFontSize="15px"
                        />
                      </div>
                    </TableCell>
                    <TableCell sx={{ px: 1, width: 100 }}>
                      {<Typography>{row.jobType}</Typography>}
                    </TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap", px: 1, width: 100 }}>
                      <span>{row.jobTitle}</span>
                    </TableCell>
                    <TableCell sx={{ px: 1, width: 250 }}>
                      <Typography>{row.jobShortDesc}</Typography>
                    </TableCell>
                    <TableCell sx={{ px: 1, whiteSpace: "nowrap", width: 100 }}>
                      <Typography>{row.clientName}</Typography>
                    </TableCell>
                    <TableCell sx={{ px: 1, width: 100 }}>
                      {jobLocation(row?.remoteJob, row?.city, row?.stateName)}
                    </TableCell>

                    <TableCell sx={{ whiteSpace: "nowrap", px: 1, width: 100 }}>
                      <Payrate
                        packageType={row?.package?.packageType}
                        packageValue={row?.package?.packageValue}
                        currency={row?.package?.packageCurrency}
                      />
                    </TableCell>
                    <TableCell sx={{ px: 1, width: 100 }}>
                      <Typography
                        data-testid="view-scorecard"
                        color={"#4540DB"}
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          props.onViewScoreCard(row);
                        }}
                      >
                        <VisibilityOutlinedIcon
                          color="primary"
                          className="pointer custom-prview-dwn-icon"
                          sx={{ verticalAlign: "bottom" }}
                        />
                        View
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ px: 1, width: 100 }}>
                      <Box sx={{ display: "flex", justifyContent: "left" }}>
                        <Button
                          variant="contained"
                          color="secondary"
                          className="jobs-button jobdetail mulish-font"
                          sx={{ margin: "0 0 0 0 !important" }}
                          onClick={() => {
                            navigate(`/jobdetail/${row.id}/findjobs`);
                          }}
                        >
                          Details
                        </Button>{" "}
                      </Box>
                      <Box sx={{ display: "flex", justifyContent: "left" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          className="jobs-button jobdetail mulish-font"
                          style={{ marginTop: "10px" }}
                          onClick={() => {
                            navigate(`/submitresume/${row.id}/findjobs`);
                          }}
                        >
                          Submit
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </AppInfiniteScroll>
        {props?.loading && (
          <p
            style={{
              marginTop: "50px",
              marginBottom: "4px",
              textAlign: "center",
            }}
          >
            Please wait...
          </p>
        )}
        {!props?.loading && props?.totalRecords === 0 && (
          <Alert severity="info" style={{ marginTop: "5px" }}>
            No Records Found
          </Alert>
        )}
      </TableContainer>
      <div className="gotoTopBtn">
        <GotoTop />
      </div>
    </div>
  );
};
