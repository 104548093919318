import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import React from "react";
import { RecruiterStatus } from "../../models/enumerations";
import StatusPopup from "./StatusPopup";

import { useSelector } from "react-redux";
import RejectIcon from "./../../assets/images/Reject-icon.svg";
import ApproveIcon from "./../../assets/images/approve-icon.svg";
import BlacklistIcon from "./../../assets/images/blacklisted-icon.svg";
import IncompleteIcon from "./../../assets/images/incomplete-icon.svg";
import PendingIcon from "./../../assets/images/pending-icon.svg";
import SuspendedIcon from "./../../assets/images/suspended-icon.svg";
import { valueOrDefault } from "../../utils/complexityUtils";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

interface Props {
  status?: string;
}

const RecruiterStatusBar: React.FC<Props> = () => {
  const [useMessage, setMessage] = React.useState("");
  const [usePopup, setPopup] = React.useState(false);
  const [useIcon, setIcon] = React.useState("");
  const{recruiterData} = useSelector((state : any) => state.recruiter)

  const [isStatus, setStatus] = React.useState("");
  let user = JSON.parse(localStorage.getItem("user")!);
  let status = recruiterData?.entity?.status
  React.useEffect(() => {
    if (user && status) {
     
          setStatus(status);
          if (checkStatus(status)) {
            setPopup(true);
          }
          checkMessage(status);
       
    }
  }, [isStatus, useMessage, usePopup, useIcon,status]);

  const checkStatus = (status: string) => {
    if (
      status == RecruiterStatus.suspended ||
      status == RecruiterStatus.blacklisted
    )
      return true;
    else return false;
  };

  const checkMessage = (status: string) => {
    if (status === "Approved") {
      setMessage(" The profile has now approved by JobRialto team. ");
      setIcon(ApproveIcon);
    } else if (status === "Suspended") {
      setMessage(" The profile has been permanently suspended by Admin. ");
      setIcon(SuspendedIcon);
    } else if (status === "Blacklisted") {
      setMessage(" The profile has been blacklisted by Admin. ");
      setIcon(BlacklistIcon);
    } else if (status === "Rejected") {
      setMessage(" The profile has been rejected by Admin. ");
      setIcon(RejectIcon);
    } else if (status === RecruiterStatus.incomplete) {
      setMessage(" The profile has been marked incomplete by Admin. ");
      setIcon(IncompleteIcon);
    } else {
      setMessage(
        " Profile is pending review. You will be notified soon, once it is verified."
      );
      setIcon(PendingIcon);
    }
  };
  //Do not show status for ADMIN
  const content = () => {
    const loginStatusNumber = JSON.parse(localStorage.getItem("loginStatusNumber")!);
    //if no user is logged in
    if(localStorage.getItem('user')==null){
      return  null
    }
    let item = JSON.parse(valueOrDefault(localStorage.getItem('user'), ""));
    if(item?.loggedInUserRole.toLowerCase()=='admin')
    {
      return null;
    }

  
 
    return ( loginStatusNumber <= 1 && isStatus != RecruiterStatus.approved && useMessage!=='' ) ? <Box>
    <Grid
      container
      spacing={1}
      sx={{ justifyContent: "left", textAlign: "left" }}
      className="dashboard-sml-cards"
    >
      <Grid item xs={12} md={12}>
        <Item elevation={0} sx={{ textAlign: "center" }}>
          <Box sx={{ width: "100%" }}>
            <Item
              className="px-2"
              style={{
                backgroundColor: "#fff",
                boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
                textAlign: "center",
                minHeight: "40px",
                height: "auto",
                marginBottom: "10px",
              }}
            >
              <div className="cust-icon-reject"
                style={{
                  paddingTop: "5px",
                  color: "#4F4F4F",
                  fontSize: "20px",
                  textAlign: "left",
                  paddingLeft: "10px",
                }}
              >
                {usePopup && (
                  <StatusPopup isOpen={usePopup} status={isStatus} />
                )}

                <img className="cust-icon-reject"src={useIcon}></img>
                {useMessage}
              </div>
            </Item>
          </Box>
        </Item>
      </Grid>
    </Grid>
  </Box> : null
  }

 
 

  return (
    content()
  );
};

export default RecruiterStatusBar;
