import React from 'react'
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

export const OtherBilling = () => {
  return (
    <Box className="others-billing">
      <Typography
        variant="subtitle1"
        gutterBottom
        component="span"
        mt={2}
        mb={0}
      >
        For US users that don't have a United States of America bank account or users in other countries that don't have access to a Payoneer account,
        we are currently doing a manual verification and setting up alternate payment mechanism.
      </Typography>
      <Typography
        variant="subtitle1"
        gutterBottom
        component="span"
        mt={2}
        mb={2}
      >
        Please contact us for more detailed information with respect
        to billing and payment related documents at the given email
        address at{" "}
        <a href="mailto:info@jobrialto.com">
          info@jobrialto.com
        </a>
      </Typography>
  
  </Box> 
  )
}
