import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { PermissionsEnums } from "../../../../data/permission";
import { getNevLink } from "../../../../utils/getFileFromUrl";
import { ModuleComponent } from "../../../Common/Permission/ModuleComponent";
import "../../NewViewSubmission/styles.scss";

interface ContractActiveButtonProps {
  row: any;
  navigate: any;
}

export default function ContractActiveButton({
  row,
  navigate
} : ContractActiveButtonProps) {
  const isContractActive = row?.contractStatus === "Contract Active";

  const handleButtonClick = () => {
    if (isContractActive) {
      navigate(getNevLink(`/Contractdetail/${row?.id}?source=activeSubmission`));
    }
  };

  return (
    <ModuleComponent moduleId={PermissionsEnums.Submissions_Contract_Active}>
      {isContractActive && (
        <Grid item>
          <Button
            className="submission-action-btn"
            variant="contained"
            color="primary"
            onClick={handleButtonClick}
          >
            Contract Active
          </Button>
        </Grid>
      )}
    </ModuleComponent>
  );
}