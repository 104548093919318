import { PermissionsEnums } from "../permission";
import { getIsClientModuleEnabled } from "../../utils/AppConfig"; 

const isClientModuleEnabled = getIsClientModuleEnabled();
// Navigation for admin when usertype is admin
const adminPages = [
    {
        name: 'Dashboard',
        to: '/admin/dashboard',
        permissionId : PermissionsEnums.Dashboard_Admin
    },
    {
        name: 'Jobs',
        to: '/jobs/all',
        permissionId : PermissionsEnums.Jobs_View,
    },
    {
        name: 'Recruiters',
        to: '/recruiters',
        permissionId : PermissionsEnums.Recruiters_View
    }
];

// Conditionally add the Clients page if the client module is enabled
if (isClientModuleEnabled) {
    adminPages.push({
        name: 'Clients',
        to: '/clients',
        permissionId: PermissionsEnums.CLUserView
    });
}

export { adminPages };
