import * as Yup from "yup";
import { addressRegex, cityRegex, experienceRegex, firstNameRegex, linkedInRegex, middleNameRegex, passWordRegex, phoneNumberRegex, zipValidation } from "../../../../constants/regex";

export  const RecruiterSignupValidationSchema = Yup.object({
    id: Yup.string(),
    email: Yup.string()
      .email("Entered email address is invalid")
      .required("Please enter a valid email address"),
    password: Yup.string()
      .required(
        "The password must be 8-15 characters long and should contain at least one or more of: Uppercase characters (A-Z) Lowercase characters (a-z) Digits (0-9) Special characters (~!@#$%^&*_-+=`|(){}[]:;\"'<>,.?/)"
      )
      .matches(
        passWordRegex,
        "Password must be 8 -15 characters long and must contain atleast one or more of digits, special characters,  upper case and lower case letters"
      ),
    confirmPassword: Yup.string()
      .required(
        "Please re-enter the password. Minimum 8 characters are required"
      )
      .oneOf(
        [Yup.ref("password"), null],
        "Password and Confirm password do not match"
      ),
    firstName: Yup.string()
      .required("Please enter the first name")
      .matches(firstNameRegex, "Entered First Name is invalid"),
    middleName: Yup.string().matches(
      middleNameRegex,
      "Middle Name is not valid"
    ),
    lastName: Yup.string()
      .required("Please enter the last name")
      .matches(firstNameRegex, "Entered Last Name is invalid"),
    address: Yup.object().shape({
      id: Yup.string(),
      streetAddress: Yup.string()
        .required("Please enter the address")
        .matches(addressRegex, "Entered Address is invalid"),
      city: Yup.string()
        .required("Please enter the city")
        .matches(cityRegex, "Entered City is invalid"),
      state: Yup.string()
        .required("Please enter the state")
        .matches(cityRegex, "Entered State is invalid"),
      postalCode: Yup.string()
        .required("Please enter the zip")
        .matches(zipValidation, "Entered Zip is invalid"),
      countryCode: Yup.string()
        .required("Please enter the country")
        .matches(cityRegex, "Entered Country is invalid"),
    }),
    phoneNumbers: Yup.object().shape({
      primary: Yup.string()
        .required("Please enter the phone number")
        .matches(phoneNumberRegex, "Entered Phone number is invalid"),
      secondary: Yup.string().matches(
        phoneNumberRegex,
        "Entered Phone number is invalid"
      ),
      isPrimaryVerified: Yup.boolean().oneOf(
        [true],
        "Please verify the primary phone number."
      ),
    }),
    alternatePhone: Yup.string(),

    experience: Yup.string()
      .required("Please enter number of years of experience")
      .matches(
        experienceRegex,
        "Please enter the valid experience from 0 to 25 or 25+"
      ),
      linkedIn: Yup.string().matches(linkedInRegex, "LinkedIn URL doesn't match")
      .when('documentInfo', {
        is: (documentInfo:any[]) => !documentInfo || documentInfo?.length === 0,
        then: Yup.string().required("LinkedIn is required if no resume document is uploaded"),
        otherwise: Yup.string(),
      }),
    domains: Yup.array().min(1, "Please select the domain"),
    interests: Yup.array().min(1, "Please select the interest"),
    isEmailVerified: Yup.boolean(),
    isSignupCompleted: Yup.boolean(),
    isIdentityUpdated: Yup.boolean(),
    loginType: Yup.string().required(),
    status: Yup.string(),
    hearAboutUs: Yup.object().shape({
      id: Yup.string().required("Please select the how did you hear about us?"),
      option: Yup.string(),

      hearAboutUsSubOption: Yup.object().when("option", {
        is: (option:string) => option === "Social Media",
        then: Yup.object().shape({
          id: Yup.string().required("Please select a platform."),
          option: Yup.string(),
        }),
        otherwise: Yup.object().shape({
          id: Yup.string(),
          option: Yup.string(),
        }),
      }),

      otherSpecification: Yup.object().when(["option","hearAboutUsSubOption"], {
        is: (option:string, hearAboutUsSubOption:any) => option == "Other" || hearAboutUsSubOption?.option == "Other" ,
        then: Yup.object().shape({
          specifyOther: Yup.string()
          .required("Please enter the other specification")
          .max(500, "Max 500 character is allow to enter"),
        }),
        otherwise: Yup.object().shape({
          specifyOther: Yup.string().max(500, "Max 500 character is allow to enter"),
        }),
      }),
    }),
    checkTermsnCondition: Yup.boolean().oneOf([true], 'Please acknowledge if you agree to the terms before submit'),
    isScreeningResponseChecked: Yup.boolean().oneOf([true], 'Please complete profile screening before submit'),
  });