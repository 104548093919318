import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { PermissionsEnums } from "../../../../data/permission";
import { CandidateStatus, UserAuth } from "../../../../models/interfaces";
import { ModuleComponent } from "../../../Common/Permission/ModuleComponent";
import "../../NewViewSubmission/styles.scss";

interface InterviewDetailsButtonProps {
  row: any;
  userRole: any;
  setPopup: any;
}

export default function InterviewDetailsButton({
  row,
  userRole,
  setPopup
} : InterviewDetailsButtonProps) {
  const isClientOrClientTeamMember =
    userRole === UserAuth.Client || userRole === UserAuth.ClientTeamMember;

  const isCandidateShortlisted = row?.candidateStatuses?.some(
    (x:any) => x.status === CandidateStatus.CandidateShortlisted
  );

  const isNotCandidateHired = !row?.candidateStatuses?.some(
    (x:any) => x.status === CandidateStatus.CandidateHired
  );

  const isNotSelectedOrRejectedInInterview = !row?.candidateStatuses?.some(
    (x:any) =>
      x.status === CandidateStatus.SelectedinInterview ||
      x.status === CandidateStatus.RejectedInInterview
  );

  const handleButtonClick = () => {
    if (     
      isCandidateShortlisted &&
      isNotCandidateHired &&
      isNotSelectedOrRejectedInInterview
    ) {
      setPopup((popup: any) => ({ ...popup, interviewDetails: true }));
    }
  };

  return (
    <ModuleComponent
      moduleId={
        isClientOrClientTeamMember
          ? PermissionsEnums.InterviewDetails
          : PermissionsEnums.Submissions_Interview_Details
      }
    >
      {
        isCandidateShortlisted &&
        isNotCandidateHired &&
        isNotSelectedOrRejectedInInterview && (
          <Grid item>
            <Button
              className="submission-action-btn"
              variant="contained"
              color="primary"
              onClick={handleButtonClick}
            >
              Interview Details
            </Button>
          </Grid>
        )}
    </ModuleComponent>
  );
}