import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import staticPageTheme from "../../Shared/theme/staticPageTheme";


const PrivacyPolicy: React.FC = () => {
  return (
    <div>

      <ThemeProvider theme={staticPageTheme}>

      <Container
        maxWidth="xl"
        className="login-banner-text"
        sx={{
          marginTop: "16px",
        }}
      >
        <Typography
          variant="h3"
          component="h1"
          className="letter-spacing-normal primary-color-text"
          gutterBottom
        >
          <div style= {{marginTop:"60px",textAlign:"center", marginBottom:"25px"}}>Privacy Policy</div>
        </Typography>

        <Typography
          className = "primary-color-text"
        >
        JobRialto Privacy Policy
        </Typography>

        <Typography
          sx = {{marginBottom: "25px"}}
        
        >
        JobRialto Privacy policy JobRialto, Inc. (JobRialto) respects your individual privacy. This privacy policy documents our adherence to the highest industry standards for the protection of your personal information. This policy applies to all JobRialto web sites and covers the following areas: What personally identifiable information is collected by JobRialto or by any third party through our web sites? How JobRialto uses this information? With whom JobRialto may share subscriber information.
        </Typography>


        <Typography
          className = "primary-color-text"
        >
        Information Collection and Use
        </Typography>

        <Typography
          sx = {{marginBottom: "25px"}}
        
        >
          JobRialto may collect information from you at two different stages. First, we may collect anonymous, aggregate information from all visitors to our sites. This type of information is never linked to any personally identifiable information and is only used in the aggregate to generate statistical reports about the use of our sites. Second, we may require the submission of certain personally identifiable information when you subscribe to use JobRialto services.
        </Typography>

        <Typography
          className = "primary-color-text"
        >
        Personal Information of Children Under 13
        </Typography>

        <Typography
          sx = {{marginBottom: "25px"}}
        
        >
          Registered users of JobRialto services must be at least 18 years of age. JobRialto services are not directed to children and JobRialto does not knowingly collect any personally identifiable information from children under 13 years of age.
        </Typography>

        <Typography
          className = "primary-color-text"
        >
        Use of Your Personal Information
        </Typography>

        <Typography
          sx = {{marginBottom: "25px"}}
        
        >
          JobRialto will use your personal information solely for internal purposes incident to the provision of services. We will never give your information or share your information with any third party except those who require the information to provide support for the internal operations of our sites and who do not disclose or use that information for any other purpose. We will never give or sell your personal information to any third party to be used for the purposes of sending you unsolicited commercial offers (such as spam). We will not ourselves use your personal information to send you any unsolicited commercial offers or other purposes without first asking for your permission and offering you an effective way to opt out of the use of personal information for those commercial offers or other purposes. On rare occasions it is necessary for us to send out a strictly service-related announcement. For instance, if our service is suspended for maintenance, we might send subscribers an email. You may not opt-out of these communications, which are not promotional in nature.
        </Typography>

        <Typography
          className = "primary-color-text"
        >
        Profile
        </Typography>

        <Typography
          sx = {{marginBottom: "25px"}}
        
        >
          We store information that we collect through cookies and log files to create a profile of our subscribers. A profile is stored information detailing how individual subscribers use our sites. Collected information in a profile is tied to the subscriber's personally identifiable information. However, your profile is used solely for internal purposes, to help us improve the experience of our subscribers and for accounting/billing purposes. We do not share your profile with any third parties.
        </Typography>

        <Typography
          className = "primary-color-text"
        >
        Cookies and Web Beacons
        </Typography>

        <Typography
          sx = {{marginBottom: "25px"}}
        
        >
          We store information that we collect through cookies and log files to create a profile of our subscribers. A profile is stored information detailing how individual subscribers use our sites. Collected information in a profile is tied to the subscriber's personally identifiable information. However, your profile is used solely for internal purposes, to help us improve the experience of our subscribers and for accounting/billing purposes. We do not share your profile with any third parties.
        </Typography>

        <Typography
          className = "primary-color-text"
        >
        Log Files
        </Typography>

        <Typography
          sx = {{marginBottom: "25px"}}
        
        >
          Log files record internet protocol (IP) addresses, browser types, internet service provider (ISP), referring/exit pages, platform type, date/time stamp, and number of clicks to analyze trends, administer the site, track a subscriber's movement and usage in the aggregate, and gather broad demographic information for aggregate use. Log files record each transaction for purposes of capacity planning and billing and are linked to personal identifiable information.
        </Typography>

        <Typography
          className = "primary-color-text"
        >
        Third-Party Advertisers and Links to Other Web Sites
        </Typography>

        <Typography
          sx = {{marginBottom: "25px"}}
        
        >
          We may use third-party advertising companies to serve ads when you visit our web site(s). The third-party advertising companies may collect and use information (not including your name, address, email address, or telephone number) about your visits to our web sites and other web sites to provide advertisements about goods and services that may be of interest to you. If you would like more information about this practice, and to know your choices for not having this information used by these companies, the Network Advertising Initiative (http://networkadvertising.org/) web site offers information about Internet advertising companies and their web practices. Their web site also provides the capability to "opt-out" of information collection by their member companies. JobRialto does not provide any personally identifiable customer information to advertisers or to third-party web sites.
        </Typography>

        <Typography
          className = "primary-color-text"
        >
        Disclosure of Personal Information As Required By Law
        </Typography>

        <Typography
          sx = {{marginBottom: "25px"}}
        
        >
          Though we make every effort to preserve subscriber privacy, we will disclose personal information when required by law, if we have a good-faith belief that such action is necessary to comply with a current judicial proceeding, a court order or legal process served on us.
        </Typography>


        <Typography
          className = "primary-color-text"
        >
        Business Transitions
        </Typography>

        <Typography
          sx = {{marginBottom: "25px"}}
        
        >
          In the event JobRialto goes through a business transition, such as a merger, acquisition by another company, or sale of a portion of its assets, our subscribers' personal information may be part of the assets transferred. If, because of the business transition, your personally identifiable information will be used in a manner materially different from that stated at the time of collection, you will be given a choice consistent with our Notification of Changes section.
        </Typography>

        <Typography
          className = "primary-color-text"
        >
        Links
        </Typography>

        <Typography
          sx = {{marginBottom: "25px"}}
        
        >
          This website contains links to other sites. JobRialto is not responsible for the privacy practices of these other sites. We encourage you to be aware when you leave our site and to read the privacy statements of each website that collects personally identifiable information. This privacy statement applies solely to information collected by JobRialto.
        </Typography>

        <Typography
          className = "primary-color-text"
        >
        Security
        </Typography>

        <Typography
          sx = {{marginBottom: "25px"}}
        
        >
          JobRialto takes commercially reasonable precautions to protect our subscribers' information. However, given the nature of the Internet and the fact that network security measures are not infallible, we cannot guarantee the security of your information. When our subscription form asks you to enter sensitive information, that information is encrypted and is protected with encryption software, Secure Socket Layer (SSL). While on a secure page, such as the subscription form, the lock icon on the bottom of Web browsers such as Microsoft Internet Explorer appears locked.
        </Typography>

        <Typography
          className = "primary-color-text"
        >
        Notification of Privacy Policy Changes
        </Typography>

        <Typography
          sx = {{marginBottom: "25px"}}
        
        >
          If we decide to change our privacy policy, we will prominently post a notice on the main page and other pages to alert you about the change. If the privacy policy changes materially so that we are going to use your personally identifiable information in a manner different from that stated at the time of collection, we will notify you via email and give you a choice as to whether we may use your information in this different manner. However, if you have opted out of all communication with the site, or deactivated your account, then you will not be contacted, and your personal information will not be used in this new manner. In addition, if we make any material changes in our privacy practices that do not affect registered user information already stored in our database, we will post a prominent notice on our web sites notifying subscribers of the change.
        </Typography>




      </Container>
      </ThemeProvider>
    
    </div>
  );
}

export default PrivacyPolicy;
