import * as Yup from "yup";
import { addressRegex, cityRegex, zipValidation } from "../../../../../constants/regex";

const InterviewAvailabilityValidationSchema = Yup.object().shape({
  subject: Yup.string().required("Please enter the subject").max(100, "Description must be less than 100 characters"),
  interviewType: Yup.string().required("Please select interview type"),
  timeZone: Yup.string().required("Please select timezone"),
  finalizedAvailabilityId: Yup.string(),
  feedback: Yup.string().when("finalizedAvailabilityId", {
    is: (finalizedAvailabilityId: string) => finalizedAvailabilityId !== undefined && finalizedAvailabilityId !== "",
    then: Yup.string().required("Please enter the feedback").max(500, "Feedback must be less than 500 characters"),
    otherwise: Yup.string().max(500, "Feedback must be less than 500 characters"),
  }),
  availabilities: Yup.array().when("finalizedAvailabilityId", {
    is: (finalizedAvailabilityId: string) => finalizedAvailabilityId === undefined || finalizedAvailabilityId === "",
    then: Yup.array().of(
      Yup.object().shape({
        interviewDate: Yup.string().required("Please select interview date"),
        interviewTime: Yup.string().required("Please select interview time"),
        interviewDuration: Yup.string().required("Please select interview duration"),
      })
    ),
    otherwise: Yup.array().strip(),
  }),
  InterviewLocation: Yup.object().when("interviewType", {
    is: (interviewType: string) => interviewType === "In Person",
    then: Yup.object().shape({
      streetAddress: Yup.string()
      .required("Please enter the address")
      .matches(addressRegex, "Entered Address is invalid"),
      city: Yup.string()
      .required("Please enter the city")
      .matches(cityRegex, "Entered City is invalid"),
      state: Yup.string()
      .required("Please enter the state")
      .matches(cityRegex, "Entered State is invalid"),
      countryCode: Yup.string().required("Please enter the Country"),
      postalCode: Yup.string()
      .required("Please enter the zip")
      .matches(zipValidation, "Entered Zip is invalid"),
      meetingLink: Yup.string(),
    }),
    otherwise: Yup.object().strip(),
  }),
  reminderMinutes: Yup.number().required("Please select interview reminder"),
  description: Yup.string().required("Please enter description").max(500, "Description must be less than 500 characters"),
});

export default InterviewAvailabilityValidationSchema;
