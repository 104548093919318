
import EditRecruiters from "../Recruiters/EditRecruiter/EditRecruiters";
import ManageProfile from "../ManageProfile/ManageProfile";
import { ManageProfile as ClientManageProfile } from "../Client/ManageProfile/ManageProfile";
import CandidateViewDetails from "../Candidate/CandidateViewDetails";
import SubmissionFilter from "./../SubmissionFilter/SubmissionFilter";
import PendingSubmission from "../PendingSubmission/PendingSubmissionList";
import ViewSubmissions from "../ViewSubmissions/ViewSubmissions";
import { MainDashboard } from "../Dashboard/MainDashboard";
import CandidateOfferDetails from "../Candidate/SubmissionDetails/CandidateOfferDetails";
import NotificationSettings from "../NotificationSettings/NotificationSettings";
import Contracts from "../Contracts/Contracts";
import ContractDetail from "../Contracts/ContractDetail";
import { NewSubmissionPage } from "../ViewSubmissions/NewViewSubmission/NewSubmissionPage";
import RecommendedJob from "../Jobs/RecommendedJob/RecommendedJob";
import { PermissionsEnums } from "../../data/permission";
import CandidateListPage from "../pages/Candidate/CandidateListPage";

//for other protectedRoutes in  project where user must be logged in to view the page
const protectedRoutes = [
  {
    path: '/edit-recruiter/:id/:fromPage',
    element: <EditRecruiters />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  
  {
    path: "/dashboard",
    element: <MainDashboard />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },




  {
    path: "/candidate-detail/:id",
    element: <CandidateViewDetails />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  {
    path: "/candidates",
    element: <CandidateListPage />,
    exact: true,
    showHeader: true,
    showLeftNav: false,
    showFooter: false,
    permissionId : PermissionsEnums.Candidates_View

  },
 
  {
    path: "/manage-profile",
    element: <ManageProfile />,
    exact: true,
    showHeader: true,
    showLeftNav: false,
    showFooter: false,
    permissionId : PermissionsEnums.Manage_Profile

  },
  {
    path: "/client/manage-profile",
    element: <ClientManageProfile />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
    permissionId : PermissionsEnums.Manage_Profile

  },
 

  {
    path: '/submission',
    element: <NewSubmissionPage/>,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter : false,
    permissionId : PermissionsEnums.Submissions_All




  },
 
  {
    path: "/submissionfilter",
    element: <SubmissionFilter />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
    permissionId : PermissionsEnums.Submissions_All

    
  },
  {
    path: "/PendingSubmission",
    element: <PendingSubmission />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
    permissionId : PermissionsEnums.Submissions_All

  },
  {
    path: "/submissions/viewsubmission/:id/:fromPage/",
    element: <ViewSubmissions />,
    exact: true,
    showHeader: true,
    showLeftNav: false,
    showFooter: false,
    permissionId : PermissionsEnums.Submissions_All

  },
  {
    path: '/candidates/submissiondetails/offerdetails/:submissionId/:pageId',
    element: <CandidateOfferDetails />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  {
    path: "/notificationsettings",
    element: <NotificationSettings />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
    permissionId : PermissionsEnums.Settings

  },
  {
    path: "/contracts",
    element: <Contracts />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
    permissionId : PermissionsEnums.Contracts_View
  },
  {
    path: "/ContractDetail/:id",
    element: <ContractDetail />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },

  {
    path: '/submission',
    element: <NewSubmissionPage/>,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter : false



  },
 {
    path: "/findjobs",
    element: <RecommendedJob />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },

  // and so on for other protectedRoutes in  project
];

export default protectedRoutes;
