
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import SquareIcon from "@mui/icons-material/Square";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Button, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import moment from "moment";
import React from "react";
import {
  useParams,
} from "react-router-dom";
import { GetSubmissionById } from "../../Services/candidateService";
import { GetJobById } from "../../Services/jobService";
import { GetOfferData } from "../../Services/recruiterService";
import formatPhone from "../../Shared/utils/formatPhone";
import { PermissionsEnums } from "../../data/permission";
import constructName from "../../utils/constructName";
import {
  downloadFile,
  getFilePreviewUrl,
  getNevLink,
} from "../../utils/getFileFromUrl";
import getPayRate from "../../utils/getPayRate";
import { Fullname } from "../Common/Fullname";
import { ModuleComponent } from "../Common/Permission/ModuleComponent";
import PreviewFileDialog from "../Common/PreviewFileDialog";
import PayrateHistoryPopup from "./Common/PayrateHistoryPopup";
import "./ContractDetails.scss";
import { BreadCrumbItem } from "../../models/AppModels";
import AppBreadCrumb from "../Common/AppBreadCrumb";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));
interface ContractDetailProps {}

const created_sort = (a: any, b: any) => {
  return new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime();
};

let DefaultBreadcrumbs: BreadCrumbItem[] = [
  { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
  { isLeaf: false, sequence: 2, title: "Contracts", to: getNevLink('/Contracts') },
  {isLeaf: true,sequence: 3,title: "Details", leafClasses: " v2LastLeft",},
];
const ContractDetail: React.FunctionComponent<ContractDetailProps> = (
  props
) => {
  let { id } = useParams();

  const [submissionData, setSubmissionData] = React.useState<any>();
  const [ContractData, setContractData] = React.useState<any>();
  const [jobData, setJobData] = React.useState<any>();
  const [contractDetailData, setContractDetailData] = React.useState<any>();
  const [rateHistory, setRateHistory] = React.useState<{
    show: boolean;
    data?: any;
  }>({ show: false });

  const [filePreview, setFilePreview] = React.useState<
    | undefined
    | {
        resourceUrl: string;
        fileName: string;
        fileType?: string;
      }
  >(undefined);

  React.useEffect(() => {
    getDataList();
    getContractData();
  }, []);

  const getDataList = () => {

    GetSubmissionById(id ?? "")
      .then((response: any) => {
        let contract = response.data?.entity.candidateStatuses[0];

        let sortedPayRateHistory =
          response.data?.entity?.changeRateHistory?.sort(created_sort);

        let payRateHistory = sortedPayRateHistory[0];

        if (payRateHistory) {
          setRateHistory({ ...rateHistory, data: payRateHistory });
        }
        getJob(response.data?.entity.jobId);
        if (contract) {
          setContractData(contract);
        }
        setSubmissionData(response.data?.entity);
      })
      .catch((error) => {
        console.log(error, error, error.request);
      })
  };

  const getContractData = () => {
    GetOfferData({ submissionId: id })
      .then((response: any) => {
        console.log(response.data?.entity, "contract detail");
        setContractDetailData(response.data?.entity);
      })
      .catch((error) => {
        console.log(error, error, error.request?.status);
      })
  };

  const getJob = (JobId: string) => {
    GetJobById(JobId)
      .then((response: any) => {
        setJobData(response.data?.entity);
      })
      .catch((error: any) => {
        console.log(error, error, error.request?.status);
      })
  };

  let formattedPayRate = getPayRate(
    contractDetailData?.payRateType,
    contractDetailData?.payrate,
    contractDetailData?.payRateCurrency
  );

  const mapEmploymentType = (type: string) => {
    if (type === "DirectContract") {
      return "Direct Contract (C2C)";
    } else if (type === "W2_Employee") {
      return "W2";
    } else if (type === "FullTime") {
      return "";
    } else {
      return type;
    }
  };

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} md={12} mb={2}>
          <AppBreadCrumb
            classes="custom-appbreadcrumb"
            BreadCrumbItems={DefaultBreadcrumbs}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography className="Client-txt-fontwt">
              Project ID
                <Typography
                    variant="h6"
                    component="span"
                    style={{ color: "grey" }}
                  >
                    &nbsp;({submissionData?.projectId})
                  </Typography>
          </Typography>
          
          
        </Grid>
        <Grid item xs={12} spacing={0} mt={0}>
          <Divider/>
        </Grid>
        <Grid item xs={12} md={12} >
          <Paper
            elevation={0}
            sx={{ margin: "2rem 0" }}
            className="CandOfferDetailsCard"
          >
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="h6"
                    component="div"
                    className="custom-candidatedetails-txt"
                    sx={{
                      paddingTop: "0px",
                    }}
                  >
                    Candidate Details
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    textAlign: "right",
                    fontSize: "1.2rem",
                    paddingRight: "25px",
                    marginTop: "0px",
                  }}
                  className="custom-status-padding"
                >
                  <Grid>
                    <SquareIcon
                      className={
                        ContractData?.status === "Contract Active"
                          ? "viewstyle"
                          : "toviewstyle"
                      }
                      style={{ marginBottom: "-6px" }}
                    />
                    <span className="custom-contract-value">
                      {" "}
                      Contract Status:{" "}
                      {ContractData?.status === "Contract Active"
                        ? "Active"
                        : "Inactive"}
                    </span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2} className="ContractDetailsPanel">
                <Grid item xs={6} sm={6} md={3}>
                  <Item elevation={0} className="custom-name-txt">
                    Name <span className="mandatory-mark">*</span>
                  </Item>

                  <Item className="custom-contract-value" elevation={0}>
                    <Fullname
                      fullName={constructName(
                        submissionData?.firstName,
                        submissionData?.middleName,
                        submissionData?.lastName
                      )}
                    />
                  </Item>
                </Grid>

                <Grid item xs={6} sm={6} md={3}>
                  <Item elevation={0} className="custom-name-txt">
                    Email <span className="mandatory-mark">*</span>
                  </Item>

                  <Item
                    className="custom-contract-value breakword"
                    elevation={0}
                  >
                    {submissionData?.email}
                  </Item>
                </Grid>

                <Grid item xs={6} sm={6} md={3}>
                  <Item elevation={0} className="custom-name-txt">
                    Start Sheet # <span className="mandatory-mark">*</span>
                  </Item>

                  <Item className="custom-contract-value" elevation={0}>
                    {submissionData?.contractDetails?.startSheetId}
                  </Item>
                </Grid>

                <Grid item xs={6} sm={6} md={3}>
                  <Item elevation={0} className="custom-name-txt">
                    Project Effective Date{" "}
                    <span className="mandatory-mark">*</span>
                  </Item>

                  <Item className="custom-contract-value" elevation={0}>
                    {moment(
                      submissionData?.contractDetails?.projectCreatedDate
                    ).format("MMMM DD, YYYY")}
                  </Item>
                </Grid>

                <Grid item xs={6} sm={6} md={3}>
                  <Item elevation={0} className="custom-name-txt">
                    Work Authorization
                    <span className="mandatory-mark">*</span>
                  </Item>

                  <Item className="custom-contract-value" elevation={0}>
                    {submissionData?.workAuthorizationStatus}
                  </Item>
                </Grid>

                <Grid item xs={6} sm={6} md={3}>
                  <Item elevation={0} className="custom-name-txt">
                    Resume
                    <span className="mandatory-mark">*</span>
                  </Item>

                  <Item
                    className="custom-contract-value breakword"
                    elevation={0}
                  >
                    {submissionData?.resumeInfo?.fileName}
                    <VisibilityOutlinedIcon
                      data-testid="fileName"
                      onClick={() => {
                        getFilePreviewUrl(
                          submissionData?.resumeInfo?.resourceUrl
                        ).then((filePreviewUrl) =>
                          setFilePreview({
                            resourceUrl: filePreviewUrl,
                            fileName: submissionData?.resumeInfo?.fileName,
                          })
                        );
                      }}
                      style={{
                        color: "#353535",
                        width: "16px",
                        height: "16px",
                        marginBottom: "-2px",
                      }}
                      className="pointer"
                      sx={{ ml: 1 }}
                    />
                    <SaveAltOutlinedIcon
                      data-testid="primary"
                      onClick={() => {
                        downloadFile(
                          submissionData?.resumeInfo?.resourceUrl,
                          submissionData?.resumeInfo?.fileName
                        );
                      }}
                      style={{
                        color: "#353535",
                        width: "16px",
                        height: "16px",
                      }}
                      className="pointer"
                    />
                  </Item>
                </Grid>

                <Grid item xs={6} sm={6} md={3}>
                  <Item elevation={0} className="custom-name-txt">
                    Phone
                    <span className="mandatory-mark">*</span>
                  </Item>

                  <Item className="custom-contract-value" elevation={0}>
                    {formatPhone(submissionData?.phone)}
                  </Item>
                </Grid>

                <Grid item xs={6} sm={6} md={3}>
                  <Item elevation={0} className="custom-name-txt">
                    Initiated date
                    <span className="mandatory-mark">*</span>
                  </Item>

                  <Item className="custom-contract-value" elevation={0}>
                    {submissionData?.contractDetails
                      ?.startSheetInitiatedDate &&
                      moment(
                        submissionData?.contractDetails
                          ?.startSheetInitiatedDate
                      ).format("MMMM DD, YYYY")}
                  </Item>
                </Grid>

                <Grid item xs={6} sm={6} md={3}>
                  <Item elevation={0} className="custom-name-txt">
                    Status
                    <span className="mandatory-mark">*</span>
                  </Item>

                  <Item className="custom-contract-value" elevation={0}>
                    {submissionData?.contractDetails?.projectStatus}
                  </Item>
                </Grid>
                {/* -------------------------------- */}
              </Grid>
            </Box>

            <Grid item xs={12} spacing={0} mt={0}>
              <Divider
                style={{
                  height: "1px",
                  background: "#EAEAEA",
                  margin: 0,
                  marginBottom: "30px",
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} className="">
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      component="div"
                      className="custom-candidatedetails-txt"
                      sx={{
                        paddingTop: "0px",
                      }}
                    >
                      Contract Information
                    </Typography>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    className="ContractDetailsPanel"
                    sx={{
                      paddingTop: "0px",
                      marginLeft: "0px",
                    }}
                  >
                    <Grid item xs={6} sm={6} md={3}>
                      <Item elevation={0} className="custom-name-txt">
                        Status <span className="mandatory-mark">*</span>
                      </Item>

                      <Item className="custom-contract-value" elevation={0}>
                        {ContractData?.status}
                      </Item>
                    </Grid>

                    <Grid item xs={6} sm={6} md={3}>
                      <Item elevation={0} className="custom-name-txt">
                        Job Title <span className="mandatory-mark">*</span>
                      </Item>

                      <Item className="custom-contract-value" elevation={0}>
                        {contractDetailData?.jobTitle}
                      </Item>
                    </Grid>

                    <Grid item xs={6} sm={6} md={3}>
                      <Item elevation={0} className="custom-name-txt">
                        Primary Skills{" "}
                        <span className="mandatory-mark">*</span>
                      </Item>

                      <Item className="custom-contract-value" elevation={0}>
                        {jobData?.jobPrimarySkill}
                      </Item>
                    </Grid>

                    <Grid item xs={6} sm={6} md={3}>
                      <Item elevation={0} className="custom-name-txt">
                        Job Type <span className="mandatory-mark">*</span>
                      </Item>

                      <Item className="custom-contract-value" elevation={0}>
                        {contractDetailData?.contractType}
                      </Item>
                    </Grid>

                    <Grid item xs={6} sm={6} md={3}>
                      <Item elevation={0} className="custom-name-txt">
                        Tax Terms
                        <span className="mandatory-mark">*</span>
                      </Item>

                      <Item className="custom-contract-value" elevation={0}>
                        {mapEmploymentType(submissionData?.employmentType)}
                      </Item>
                    </Grid>

                    <Grid item xs={6} sm={6} md={3}>
                      <Item elevation={0} className="custom-name-txt">
                        Pay Rate
                        <span className="mandatory-mark">*</span>
                      </Item>

                      <Item className="custom-contract-value" elevation={0}>
                        {formattedPayRate.packageValueAndCurrency +
                          " " +
                          formattedPayRate.packageType}
                        <span style={{ marginLeft: "10px" }}>
                          {rateHistory?.data && (
                            <ModuleComponent
                              moduleId={
                                PermissionsEnums.Contracts_View_Previous_Rate
                              }
                            >
                              <Button
                                data-testid="small"
                                className="custom-previous-rate"
                                size="small"
                                variant="text"
                                onClick={(e) => {
                                  setRateHistory({
                                    ...rateHistory,
                                    show: true,
                                  });
                                }}
                              >
                                Previous Rate
                              </Button>
                            </ModuleComponent>
                          )}
                        </span>
                      </Item>
                    </Grid>

                    <Grid item xs={6} sm={6} md={3}>
                      <Item elevation={0} className="custom-name-txt">
                        Pay Frequency
                        <span className="mandatory-mark">*</span>
                      </Item>

                      <Item className="custom-contract-value" elevation={0}>
                        {formattedPayRate.packageType}
                      </Item>
                    </Grid>

                    <Grid item xs={6} sm={6} md={3}>
                      <Item elevation={0} className="custom-name-txt">
                        Start Date
                        <span className="mandatory-mark">*</span>
                      </Item>

                      <Item className="custom-contract-value" elevation={0}>
                        {moment(
                          submissionData?.contractDetails?.projectStartDate
                        ).format("LL")}
                      </Item>
                    </Grid>

                    <Grid item xs={6} sm={6} md={3}>
                      <Item elevation={0} className="custom-name-txt">
                        End Date
                        <span className="mandatory-mark">*</span>
                      </Item>

                      <Item className="custom-contract-value" elevation={0}>
                        {moment(
                          submissionData?.contractDetails?.endDate
                        ).format("LL")}
                      </Item>
                    </Grid>

                    {submissionData?.contractDetails?.contractType ==
                      "Contract" && (
                      <Grid item xs={6} sm={6} md={3}>
                        <Item elevation={0} className="custom-name-txt">
                          Contract Duration
                          <span className="mandatory-mark">*</span>
                        </Item>
                        <Item
                          className="custom-contract-value"
                          elevation={0}
                        >
                          {
                            submissionData?.contractDetails
                              ?.contractDuration
                          }
                        </Item>
                      </Grid>
                    )}
                    <Grid item xs={6} sm={6} md={3}>
                      <Item elevation={0} className="custom-name-txt">
                        Shift
                        <span className="mandatory-mark">*</span>
                      </Item>

                      <Item className="custom-contract-value" elevation={0}>
                        {submissionData?.contractDetails?.projectShiftType}
                      </Item>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                      <Item elevation={0} className="custom-name-txt">
                        Shift Timings
                        <span className="mandatory-mark">*</span>
                      </Item>

                      <Item className="custom-contract-value" elevation={0}>
                        From:{" "}
                        {submissionData?.contractDetails?.projectShiftFrom}
                        <> </>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To:{" "}
                        {submissionData?.contractDetails?.projectShiftTo}
                      </Item>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      textAlign: "right",
                      paddingRight: "25px",
                    }}
                  >
                    
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} spacing={0} mt={0}>
              <Divider
                style={{
                  height: "1px",
                  background: "#EAEAEA",
                  margin: 0,
                  marginBottom: "30px",
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} className="">
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      component="div"
                      className="custom-candidatedetails-txt"
                      sx={{
                        paddingTop: "0px",
                      }}
                    >
                      Client Information
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  className="ContractDetailsPanel"
                >
                  <Grid item xs={6} sm={6} md={3}>
                    <Item elevation={0} className="custom-name-txt">
                      End Client <span className="mandatory-mark">*</span>
                    </Item>

                    <Item className="custom-contract-value" elevation={0}>
                      {submissionData?.contractDetails?.endClientName}
                    </Item>
                  </Grid>

                  <Grid item xs={6} sm={6} md={3}>
                    <Item elevation={0} className="custom-name-txt">
                      Work Location{" "}
                      <span className="mandatory-mark">*</span>
                    </Item>

                    <Item className="custom-contract-value" elevation={0}>
                      {contractDetailData?.jobLocation}
                    </Item>
                  </Grid>

                  <Grid item xs={6} sm={6} md={3}>
                    <Item elevation={0} className="custom-name-txt">
                      Industry
                      <span className="mandatory-mark">*</span>
                    </Item>

                    <Item className="custom-contract-value" elevation={0}>
                      {jobData?.industry}
                    </Item>
                  </Grid>

                  <Grid item xs={6} sm={6} md={3}>
                    <Item elevation={0} className="custom-name-txt">
                      Functional Area/Department
                      <span className="mandatory-mark">*</span>
                    </Item>

                    <Item className="custom-contract-value" elevation={0}>
                      {jobData?.departmentName}
                    </Item>
                  </Grid>

                  <Grid item xs={6} sm={6} md={3}>
                    <Item elevation={0} className="custom-name-txt">
                      Role
                      <span className="mandatory-mark">*</span>
                    </Item>

                    <Item className="custom-contract-value" elevation={0}>
                      {submissionData?.contractDetails?.candidateRole}
                    </Item>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <PayrateHistoryPopup
        handleClose={() => {
          setRateHistory({ ...rateHistory, show: false });
        }}
        isOpenPopup={rateHistory.show}
        rateHistory={rateHistory.data}
        contractStatus={ContractData?.status ?? ""}
      ></PayrateHistoryPopup>

      {filePreview !== undefined && (
        <PreviewFileDialog
          open={filePreview !== undefined}
          handleOpenClose={() => setFilePreview(undefined)}
          fileName={filePreview.fileName}
          fileURL={filePreview.resourceUrl}
          fileType={filePreview.fileType}
        />
      )}
    </>
  );
};

export default ContractDetail;
