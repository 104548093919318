import { createSvgIcon } from "@mui/material/utils";

export const IncompleteIcon = createSvgIcon(
<svg width="20" height="22" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="8" cy="8.5" r="8" fill="white"/>
<path d="M13 4.5C12.5 3.83333 11 2.5 8 2.5C5 2.5 2 5.5 2 8.5C2 11.5 3 13 4.5 14" stroke="#4540DB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.5 5.5V9L11 10.5" stroke="#4540DB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 6L14.5 3.5L15 6.5L12 6Z" fill="#4540DB" stroke="#4540DB" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7 15.5C7.5 15.8333 9.27857 16.3054 10.5 16C11.8163 15.6709 13.1667 14.8333 13.5 14.5" stroke="#4540DB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="3 20"/>
</svg>  
    ,
  "IncompleteIcon"
);