import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Alert, Box } from '@mui/material';
import CustomSuccessIcon from './customSuccessIcon';

interface SuccessSnackBarPopupProps{
    message : string;
    open:boolean;
    onClose: ()=> void;
}
const SuccessSnackBarPopup: React.FC<SuccessSnackBarPopupProps> = (props) => {

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    props?.onClose();
    if (reason === 'clickaway') {        
      return;
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Snackbar
        open={props?.open}
        autoHideDuration={9000}
        onClose={handleClose}
        anchorOrigin={{ vertical:"bottom", horizontal:"center" }}
      >
        <Alert 
          onClose={handleClose} 
          severity="success" 
          sx={{ width: '100%' }} 
          className='snackbarSuccess'
          icon={<CustomSuccessIcon />}
          >
        {props?.message}
        </Alert>
      </Snackbar>
      </Box>
  );
}

export default SuccessSnackBarPopup;