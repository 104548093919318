import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  GetJobAlertById,
  UpdateRecruiterJobAlert,
} from "../../../Services/recruiterJobAlertService";
import "./JobAlert.scss";

interface JobAlertEditPopupProps {
  open: boolean;
  onClose: (status: boolean) => void;
  jobAlertId: any;
}

const JobAlertEditPopup: React.FC<JobAlertEditPopupProps> = ({
  open,
  onClose,
  jobAlertId,
}) => {
  const [jobAlert, setJobAlert] = useState<any>({});

  const handleAlertType = (
    value: any
  ) => {
    setJobAlert({ ...jobAlert, jobAlertType: value });
  };

  const handleAlertShareType = (
    value: any
  ) => {
    setJobAlert({ ...jobAlert, jobAlertShareType: value });
  };

  const handleSaveJobAlert = () => {
    UpdateRecruiterJobAlert(jobAlert).then((res) => {
      if (
        res?.data.status === 200 &&
        res?.data.message.appStatusCode === "RC_RSF"
      ) {
        onClose(true);
        return;
      }
      onClose(false);
    });
  };

  useEffect(() => {
    GetJobAlertById(jobAlertId).then((res) => {
      if (
        res?.data?.status === 200 &&
        res?.data?.message?.appStatusCode === "RC_RSF"
      ) {
        setJobAlert(res?.data?.entity);
      }
    });
  }, [jobAlertId]);

  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      <DialogTitle sx={{ textAlign: "center" }} className="ManageJobAlert">
        Manage Job Alert
        <IconButton
          data-testid={"onClose"}
          aria-label="close"
          onClick={() => onClose(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  paddingBottom: "16px",
                }}
              >              
              <Typography >
                <span className="AlertLabelTitle">Job Title:&nbsp;</span>                 
                <span className="jobtitle-text">{jobAlert?.jobTitle?.join(", ")}</span>
              </Typography>
            </Box>
            
            {jobAlert?.location?.length > 0 &&
            <Box
                sx={{
                  display: "-webkit-box",
                  alignItems: "center",
                  paddingBottom: "16px",
                }}
              ><Typography className="text-clr-black">
                Location:&nbsp; {jobAlert?.location?.join(", ")}
              </Typography>
              </Box>
              }
              { jobAlert?.domains?.length > 0 && 
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingBottom: "16px",
                }}
              > <Typography className="text-clr-black">
                  Domains:&nbsp; {jobAlert?.domains?.join(", ")}
                </Typography>
              </Box>
              }
          </Grid>
          <Grid item xs={6} className="border-clr">
            <Typography className="text-clr-primary">Alert me </Typography>
          </Grid>
          <Grid item xs={6} className="border-clr">
            <RadioGroup
              row
              aria-labelledby="independentRecruiter-radio-buttons-group-label"
              name="isGstRegistered"
              className="jobAlertRadiotype"
            >
              <FormControlLabel
                value={"Daily"}
                control={
                  <Radio
                    onChange={() => handleAlertType( "Daily")}
                    checked={jobAlert?.jobAlertType === "Daily"}
                  />
                }
                label="Daily"
                checked
              />
              <FormControlLabel
                value={"Weekly"}
                control={
                  <Radio
                    onChange={() => handleAlertType("Weekly")}
                    checked={jobAlert?.jobAlertType === "Weekly"}
                  />
                }
                label="Weekly"
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={6} className="border-clr">
            <Typography className="text-clr-primary txt-Alert-mt">
              Job Alert will be shared with me via{" "}
            </Typography>
          </Grid>
          <Grid item xs={6} className="border-clr">
            <RadioGroup
              row
              aria-labelledby="independentRecruiter-radio-buttons-group-label"
              name="isGstRegistered"
              value={jobAlert?.jobAlertShareType}
            >
              <FormControlLabel
                value={"Email"}
                control={
                  <Radio
                    onChange={() =>
                      handleAlertShareType( "Email")
                    }
                    checked={jobAlert?.jobAlertShareType === "Email"}
                  />
                }
                label="Email"
                checked
              />
            </RadioGroup>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ paddingBottom: "20px" }}>
        <Button
          data-testid={"closeBtn"}
          onClick={() => onClose(false)}
          variant="outlined"
          className="minwidth-btn"
        >
          Close
        </Button>
        <Button
          onClick={handleSaveJobAlert}
          variant="contained"
          className="minwidth-btn"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default JobAlertEditPopup;
