import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "../../../Services/resetPasswordService";
import { FormControl, Input, InputLabel } from "@material-ui/core";
import { IconButton, List, ListItem, Alert, CircularProgress } from "@mui/material";
import { ErrorMessage } from "../../ui/ErrorMessage";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

interface ResetPasswordProps {
  formikReset: any;
  resetPasswordError: string;
  setResetPasswordError: any;
  handleCloseResetPopup: () => void;
}

export const ResetPassword = ({
  formikReset,
  resetPasswordError,
  setResetPasswordError,
  handleCloseResetPopup,
}: ResetPasswordProps) => {
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  const _handleTogglePassword = (type: string) => {
    if (type === "password") {
      setShowPassword({ ...showPassword, password: !showPassword.password });
    } else {
      setShowPassword({
        ...showPassword,
        confirmPassword: !showPassword.confirmPassword,
      });
    }
  };
  return (
    <>
      <Box>
        <Grid container>
          <Grid item xs={12} md={12} textAlign="right">
            <IconButton
              data-testid="closeIcon"
              aria-label="close"
              onClick={handleCloseResetPopup}
              sx={{
                marginTop: "0px",
                marginRight: "-20px",
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Typography
            variant="h2"
            color="inherit"
            component="span"
            className="custom-wlcm-txt"
          >
            Reset{" "}
            <span style={{ fontWeight: 700, color: "rgba(53, 53, 53, 1)" }}>
              Password?
            </span>
          </Typography>

          <Typography
            variant="body1"
            sx={{ mt: 1.5 }}
            className="custom-forgotpwd-subhead"
          >
            Enter your new password
          </Typography>

          <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{
              flexDirection: "row",
              "& .MuiTextField-root": { m: 1 },
            }}
          >
            <FormControl
              variant="standard"
              required
              error={
                formikReset.touched.userName &&
                Boolean(formikReset.errors.userName)
              }
              fullWidth
              margin="normal"
            >
              <InputLabel htmlFor="userName">Your email</InputLabel>
              <Input
                name="userName"
                type="email"
                readOnly={true}
                onChange={formikReset.handleChange}
                onBlur={formikReset.handleBlur}
                value={formikReset.values.userName}
                autoComplete="off"
                inputProps={{ "data-testid": "input-setnewpassword-email" }}
              />
              <ErrorMessage
                errorText={
                  formikReset.touched.userName && formikReset.errors.userName
                }
              />
            </FormControl>
            <FormControl
              required
              error={
                formikReset.touched.password &&
                Boolean(formikReset.errors.password)
              }
              fullWidth
              margin="normal"
            >
              <InputLabel htmlFor="password">New Password</InputLabel>
              <Input
                name="password"
                type={showPassword?.password ? "text" : "password"}
                onChange={formikReset.handleChange}
                onBlur={formikReset.handleBlur}
                value={formikReset.values.password}
                inputProps={{ "data-testid": "input-setnewpassword-pwd" }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      data-testid="password-toggler"
                      className="passwordToggler"
                      onClick={() => {
                        _handleTogglePassword("password");
                      }}
                      edge="end"
                    >
                      {showPassword.password ? (
                        <Visibility style={{ color: "#353535" }} />
                      ) : (
                        <VisibilityOff style={{ color: "#353535" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <ErrorMessage
                errorText={
                  formikReset.touched.password && formikReset.errors.password
                }
              />
            </FormControl>
            <FormControl
              required
              error={
                formikReset.touched.confirmPassword &&
                Boolean(formikReset.errors.confirmPassword)
              }
              fullWidth
              margin="normal"
            >
              <InputLabel htmlFor="confirmPassword">
                Confirm new password
              </InputLabel>
              <Input
                name="confirmPassword"
                onChange={formikReset.handleChange}
                onBlur={formikReset.handleBlur}
                value={formikReset.values.confirmPassword}
                inputProps={{
                  "data-testid": "input-setnewpassword-confirmpwd",
                }}
                type={showPassword?.confirmPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      className="passwordToggler"
                      data-testid="confirmPassword-toggler"
                      onClick={() => {
                        _handleTogglePassword("confirmPassword");
                      }}
                      edge="end"
                    >
                      {showPassword?.confirmPassword ? (
                        <Visibility style={{ color: "#353535" }} />
                      ) : (
                        <VisibilityOff style={{ color: "#353535" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <ErrorMessage
                errorText={
                  formikReset.touched.confirmPassword &&
                  formikReset.errors.confirmPassword
                }
              />
            </FormControl>

            {resetPasswordError !== "" && (
              <Alert
                data-testid="reset-password-error-alert"
                onClose={() => {
                  setResetPasswordError("");
                }}
                severity="error"
              >
                {resetPasswordError}
              </Alert>
            )}

            <div className="resetpassword-btn-div">
              <Button
                style={{ marginTop: "15px", marginBottom: "25px" }}
                variant="contained"
                className="abel signup-button resetpassword-btn-text"
                type="submit"
                endIcon={
                  formikReset.isSubmitting ? (
                    <CircularProgress size={"16px"} color={"inherit"} />
                  ) : (
                    ""
                  )
                }
                disabled={formikReset.isSubmitting}
                onClick={(e) => {
                  e.preventDefault();
                  formikReset.handleSubmit();
                }}
                data-testid="btn-setnewpassword"
              >
                Reset Password
              </Button>
            </div>

            <List
              className="resetpassword-instructions-list"
              disablePadding
              dense
            >
              <ListItem>
                Your password should contain minimum 8 characters and include
                combination of alphabets, numbers and special character
              </ListItem>
            </List>
          </Box>
        </Grid>
      </Box>
    </>
  );
};
