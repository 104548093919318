import featuredJobIcon from "../../assets/images/featured-job.svg";
import Tooltip from "@mui/material/Tooltip";

export const JobTitle = ({ title, isFeatured, jobId }) => {
  let showFeatureIcon = isFeatured;

  return (
    <>
      <span>
        {" "}
        {title} {jobId !== "" && <span> (Job ID:{jobId}) </span>}
        {showFeatureIcon && (
          <Tooltip title="Featured Job">
            <img className="ht-23 featurejobIcon" src={featuredJobIcon} />
          </Tooltip>
        )}
      </span>
    </>
  );
};

export default JobTitle;
JobTitle.defaultProps = {
  jobId: "",
};
