import { Divider, makeStyles } from "@material-ui/core";
import DataSaverOffRoundedIcon from "@mui/icons-material/DataSaverOffRounded";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import {
  Card,
  CardContent,
  Checkbox,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Button
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import { GetAllCurrencyList } from "../../Services/masterCurrencyService";
import getLoggedInUserRole from "../../Shared/utils/getLoggedInUserRole";
import useApi from "../../hooks/useApi";
import { UserAuth } from "../../models/interfaces";
import BasicDateRangePicker from "../ui/DateRange";
import { AdminEarningTable } from "./AdminEarningTable";
import { RecruiterEarningTable } from "./RecruiterEarningTable";
import "./styles.scss";
import moment from "moment";
import { useDispatch, useSelector, } from "react-redux";
import { GetFilterConsolidatedEarnings } from "../../Services/invoiceManagerService";
import getUserDetails from "../../Shared/utils/getUserDetails";
import { resetEarningsState, setEarningsState } from "../../features/Earnings/ContractEarningFilterStateSlice";
import { getMonthBoundaryDates } from "../../utils/dateUtils";
import { GotoTop } from "../ui/GotoTop";
import { Searchbar } from "../ui/Searchbar";
import { AppState } from "../../store/AppState";
import AppBreadCrumb from "../Common/AppBreadCrumb";
import { BreadCrumbItem } from "../../models/AppModels";
import { getNevLink } from "../../utils/getFileFromUrl";

const useStyles = makeStyles((theme) => ({
  menuItem: {
    "& .MuiMenu-list li:hover": {
      backgroundColor: "#fff !important",
      color: "#4540DB",
    },
  },

  scrollMenu: {
    "&::-webkit-scrollbar": {
      width: "0.2em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#4540DB",
      outline: "1px solid #4540DB",
    },
  },
}));


export function Earnings() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const DefaultBreadcrumbs: BreadCrumbItem[] = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
    { isLeaf: true, sequence: 2, title: "Earnings", leafClasses:" v2LastLeft"},
  ];

  const contractEarningFilterState = useSelector((state:AppState) => state.contractEarningFilterState)
  const [contractEntityList, setContractEntityList] = useState<any>([]);
  const [contractLoading, setContractLoading] = useState(false);
  
  let userRole = getLoggedInUserRole();
  
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);

  const [isExpanded, setisExpanded] = React.useState({
    contract: true,
    fullTime: true,
  });

  const [totalContractRecordsCnt, setTotalContractRecordsCnt] = React.useState(0);

  const currentMonthFirstDay = moment.utc().startOf('month').format('YYYY-MM-DD');
  const currentMonthLastDay   = moment.utc().endOf('month').format('YYYY-MM-DD');
 
  const handleClick2 = (event:any) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const clearAllSearch = () => {
    dispatch(resetEarningsState());
     }
  
  const currencyAPI = useApi(() => GetAllCurrencyList());

  React.useEffect(() => {
    getContractEarningsByFilter()

  }, [contractEarningFilterState?.currencies, contractEarningFilterState?.fromDate, contractEarningFilterState?.toDate, contractEarningFilterState?.searchKeyword]);
  
     
  React.useEffect(() => {
    currencyAPI.request();
  }, []);
  React.useEffect(() => {
    if (contractEarningFilterState?.pageIndex !== 1) {
      getContractEarningsByFilter();
    }
  }, [contractEarningFilterState?.pageIndex]);

  const handlePaginationIndex = (type:string, index:number) => {
    if(type === "contract") 
      {
        dispatch(setEarningsState({ ...contractEarningFilterState, pageIndex: index }));
      }
    
  };

  const handleStatusChange = (e:any) => {
    let isChecked = e.target.checked;
    if (isChecked) {
      if (!contractEarningFilterState?.currencies?.includes(e.target.name)) {
        dispatch(setEarningsState({
          ...contractEarningFilterState,
          pageIndex: 1,
          currencies: [...contractEarningFilterState?.currencies, e.target.name],
        }));
      }
    } else {
      if (contractEarningFilterState?.currencies?.includes(e.target.name)) {
        let filteredArray = contractEarningFilterState?.currencies?.filter(
          (item:any) => item !== e.target.name
        );
        dispatch(setEarningsState({ ...contractEarningFilterState, pageIndex: 1, currencies: [...filteredArray] }));

      }
    }
  };
  const getContractEarningsByFilter = () => {
    setContractLoading(true);
    setContractEntityList([]);
    let selectedFromMonth = getMonthBoundaryDates(contractEarningFilterState?.fromDate);
    let selectedToMonth = getMonthBoundaryDates(contractEarningFilterState?.toDate);
    let UpdatedContractFilter = {
      ...contractEarningFilterState,
      fromDate:selectedFromMonth.firstDate,
     toDate:selectedToMonth.lastDate,
     recruiterGuid: userRole === UserAuth.Recruiter ? getUserDetails()?.id : "",
     ...(userRole === UserAuth.Recruiter) && {earningsStatus: "Approved"},
    }
    dispatch(setEarningsState({ ...contractEarningFilterState, recruiterGuid: userRole === UserAuth.Recruiter ? getUserDetails()?.id : "" }));
    GetFilterConsolidatedEarnings(UpdatedContractFilter)
      ?.then((response) => {        
        if (response?.data?.message?.appStatusCode === "RC_RSF") {
          setTotalContractRecordsCnt(response?.data?.customTotal)
          setContractEntityList([...response.data.entityList]);
        } else {
          setTotalContractRecordsCnt(0)
          setContractEntityList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setContractLoading(false));
  };
  
  const handleDateChange = (e:any, name:string) => {
    if (name === "fromDate") {
      dispatch(setEarningsState({ ...contractEarningFilterState, fromDate: e }));
    }
    if (name === "toDate") {
      dispatch(setEarningsState({ ...contractEarningFilterState, toDate: e }));
    }
  };

  const _hasAnyFilterApply = () => {
    return (
      contractEarningFilterState?.searchKeyword === "" &&
      contractEarningFilterState?.currencies?.length === 0 &&
      contractEarningFilterState.fromDate === currentMonthFirstDay &&
      contractEarningFilterState.toDate === currentMonthLastDay
    );
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <AppBreadCrumb
          classes="custom-appbreadcrumb"
          BreadCrumbItems={DefaultBreadcrumbs}
        />
      </Grid>
      <Grid item xs={12}>
          <Typography className="AppPageHeading">
            Total Contracts Active ({totalContractRecordsCnt})
          </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} md={12}>   
        <Grid container >
          <Grid item xs={12} md={12}>
            <Grid container alignContent={"center"}>
              <Grid item xs={12} sm={3} md={3} pr={{xs:0, sm:3, md:3}} pt={{xs:1, sm:1, md:1}}>
                  <Searchbar 
                    isNewSeachBar={true}
                    classes="custom-search-bar-candidate" 
                    marginBottom="0px" 
                    height="1.8rem"  
                    txtFontSize="14px" 
                    value={contractEarningFilterState?.searchKeyword}
                    onChange={(e:any)=> {
                      dispatch(setEarningsState({...contractEarningFilterState, searchKeyword:e.target.value, pageIndex:1}));
                      }
                    }
                    onEnter={(e:any)=> {
                      dispatch(setEarningsState({...contractEarningFilterState, searchKeyword:e.target.value, pageIndex:1}));
                      }
                    }
                    onIconClick={(e:any)=> {
                      dispatch(setEarningsState({...contractEarningFilterState, searchKeyword:e.target.value, pageIndex:1}));
                      }
                    }
                    placeHolderText={"Search"} 
                    marginLeft="0px"
                    />
              </Grid>
              <Grid item xs={12} sm={9} md={9} pt={{xs:2, sm:1, md:1}}>
                <Box className="date-filterWrapper">
                <Box className="date-wrapper">
                  <Box style={{display:"flex"}} className="durationText">
                  <DataSaverOffRoundedIcon
                    className="myearning-button"
                    sx={{ color: "#000", paddingTop: "4px",fontSize:"18px" }}
                  />
                  <Typography
                    sx={{
                      color: "black",
                      minWidth: "90px",
                      fontSize: "16px",
                      paddingLeft:"10px"
                    }}
                  >
                    Select Duration{" "}
                  </Typography>
                  </Box>
                  <Box className="custom-date-picker" >
                  <span className="durationLabel">From </span>
                    <BasicDateRangePicker
                      className=""
                      data-testid="fromdate"
                      disableFuture={true}
                      value={contractEarningFilterState?.fromDate}
                      name="fromDate"
                      onChange={handleDateChange}
                      defaultDateFormat="DD-MM-YYYY"
                      views={['month', 'year']}
                      isError={(contractEarningFilterState?.fromDate > contractEarningFilterState?.toDate ) ? true :false }
                      errorMessage={(contractEarningFilterState?.fromDate > contractEarningFilterState?.toDate ) ? "From Date cannot be greater than To Date" :"" }
                    />
                  </Box>
                  <Box className="custom-date-picker">
                  <span className="durationLabel">To</span>
                    <BasicDateRangePicker
                      className=""
                      data-testid="fromdate"
                      value={contractEarningFilterState?.toDate}
                      name="toDate"
                      onChange={handleDateChange}
                      minimum={contractEarningFilterState?.fromDate}
                      defaultDateFormat="DD-MM-YYYY"
                      views={['month', 'year']}
                      isError={(contractEarningFilterState?.toDate < contractEarningFilterState?.fromDate )  ? true :false }
                      errorMessage={(contractEarningFilterState?.toDate < contractEarningFilterState?.fromDate ) ? "To Date cannot be less than From Date" : "" }
                    />
                  </Box>                      
                </Box>
                <Box className="FilterWrapper">
                    <Button
                      variant="text"
                      component="div"
                      size="large"
                      className="v2-base-btn cust-btn-bgClr earningListPage-btns"
                      startIcon={<FilterAltOutlinedIcon />}
                      onClick={handleClick2}
                      data-testid="multifilter-button"
                    >
                      Filter
                    </Button>
                    <Button
                      variant="text"
                      component="div"
                      size="large"
                      className={`${
                        !_hasAnyFilterApply()
                          ? "v2-base-btn"
                          : "disable-v2-base-btn"
                      } cust-btn-bgClr btn-ht clear-cust-wrap`}
                      startIcon={<ClearIcon />}
                      onClick={clearAllSearch}
                      disabled={_hasAnyFilterApply()}
                      data-testid="clear-button"
                    >
                      Clear
                    </Button>
                    <Menu
                      id="demo-positioned-menu"
                      aria-labelledby="demo-positioned-button"
                      anchorEl={anchorEl2}
                      open={open2}
                      className={`${classes.menuItem} EarningCurrencyFilter`}
                      data-testid={'filterDialog'}
                      onClose={handleClose2}
                    >
                      <MenuList className="contractEarningFilterState-wrapper">
                        {currencyAPI?.data?.entityList?.length > 0 && currencyAPI?.data?.entityList.map((x:any,index:number )=> {
                          return <MenuItem key={  x?.currencyCode ?? index}
                            sx={{ paddingLeft: "3px", paddingBottom: "0px" }}
                          >
                            <ListItemIcon>
                              <Checkbox name={x.currencyCode}
                                checked={contractEarningFilterState?.currencies?.includes(
                                  x?.currencyCode
                                )}
                                value={x.currencyCode}
                                onChange={(e) => handleStatusChange(e)}
                                sx={{ padding: "0px" }}
                                data-testid={"currencyCode"+index}
                              />
                            </ListItemIcon>
                            <ListItemText>{x.currencyCode}</ListItemText>
                          </MenuItem>
                        })}

                      </MenuList>
                    </Menu>
                  </Box>
                  </Box>
              </Grid>
              </Grid>
            </Grid>
          <Grid item xs={12} md={12}>
            <Box mt={3}>
              <Card sx={{ maxWidth: "100%",marginBottom:"20px"}} elevation={1}>
                <CardContent>
                  <Typography
                    component="span"
                    className="contractSectionHeading"
                  >
                    Contracts (
                    {totalContractRecordsCnt})
                    <span
                      data-testid="isExpanded"
                      style={{ position: "relative", top: "7px" }}
                      onClick={() =>
                        setisExpanded({
                          ...isExpanded,
                          contract: !isExpanded.contract,
                        })
                      }
                    >
                      {!isExpanded.contract ? (
                        <KeyboardArrowUpOutlinedIcon />
                      ) : (
                        <KeyboardArrowDownOutlinedIcon />
                      )}
                    </span>
                  </Typography>
                  <span
                    style={{
                      display: isExpanded.contract ? "block" : "none",
                    }}
                  >
                    {/* Roles should be checked before rendering table ie: Recruiter else Admin/Superadmin */}
                    {
                      userRole === UserAuth.Recruiter ?
                      <RecruiterEarningTable
                        key={1}
                        type={"contract"}
                        data={contractEntityList}
                        handlePaginationIndex={(index:number)=>{handlePaginationIndex("contract", index)}}
                        loading={contractLoading}
                        filterState={contractEarningFilterState}
                        totalRecords={totalContractRecordsCnt}
                      />
                      : 
                    <AdminEarningTable
                      key={1}
                      type={"Full Time"}
                      data={contractEntityList}
                      handlePaginationIndex={(index:number)=>{handlePaginationIndex("contract", index)}}
                      loading={contractLoading}
                      filterState={contractEarningFilterState}
                      totalRecords={totalContractRecordsCnt}
                    />
                    }
                  </span>
                  <GotoTop />
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}