import axios from "./CommonHttpRequest";
let baseUrl = `${process.env.REACT_APP_INTEGRATION_API}api/TwilioVerify`;

export const SendCode = async (to:string , channel:string) => {
  let reqBody = {
    To: to,
    Channel: channel ,
  };
   return await axios.post(`${baseUrl}/SendCode`, reqBody);
};

export const VerifyCode = async (to:string, code:string) => {
  let reqBody = {
    To: to,
    Code: code ,
  };
  return await axios.post( `${baseUrl}/VerifyCode`,  reqBody  );
};
