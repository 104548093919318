import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from '@mui/material/Divider';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import moment from 'moment';
import { Link, useParams } from "react-router-dom";
import {
    GetOfferData,
} from "../../../Services/recruiterService";
import useApi from "../../../hooks/useApi";
import { capitalizeFirstLetter } from "../../../utils/StringUtil";
import { downloadFile, getNevLink } from "../../../utils/getFileFromUrl";
import getPayRate from "../../../utils/getPayRate";
import AppBreadCrumb from "../../Common/AppBreadCrumb";
import './CandidateOfferDetails.css';
import { useEffect } from "react";
import { valueOrDefault } from "../../../utils/complexityUtils";


export default function CandidateOfferDetails() {
    let { submissionId , pageId} = useParams();
    const getOfferData = useApi(() =>
    GetOfferData({ submissionId: submissionId }) 
  );
  useEffect(() => {
    getOfferData.request().finally(() => {});
  }, []);

  const offerData = getOfferData.data?.entity;
  console.log(offerData)


  let formattedPayRate = getPayRate(
   offerData?.payRateType,
   offerData?.payrate,
   offerData?.payRateCurrency
  )
  let submissionBreadCrumb = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
    { isLeaf: false, sequence: 2, title: "Submissions", to: "/submissions" },
    { isLeaf: false, sequence: 3, title: "View Submissions", to: `/submissions/viewsubmission/${offerData?.jobId}/submissions`},
    { isLeaf: false, sequence: 4, title: "Details", to: `/candidates/submissiondetails/${submissionId}/submissions` },
    { isLeaf: true, sequence: 5, title: "Offer Details"},
    
  ];
  let newSubmissionBreadCrumb = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
    { isLeaf: false, sequence: 4, title: `${capitalizeFirstLetter(pageId)} Submissions`, to: `/submission/${pageId}` },
    { isLeaf: true, sequence: 5, title: "Offer Details"},
    
  ];
  return (
        <Grid Container>
            <Grid item xs={12} md={12} mb={2}>
                
            <AppBreadCrumb 
            classes="custom-appbreadcrumb"
            BreadCrumbItems={pageId=="submissiondetails"?submissionBreadCrumb:newSubmissionBreadCrumb}></AppBreadCrumb>

            </Grid>
            <Grid item xs={12} md={12} className=''>
                <Paper elevation={3} sx={{ margin:"2rem 0"}} className="CandOfferDetailsCard"> 
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                            <Typography  variant="h6" component="div"
                                className=" letter-spacing-normal primary-color-text candiOfferDetialsHeading" 
                                sx={{ paddingTop: "0px",fontSize:"2rem",marginLeft:"25px" }}>
                                Offer Details 
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: "right",fontSize:"1.2rem",paddingRight:"25px",marginTop:"15px" }} >
                        <Link
                            color="primary"
                            to={`/jobdetail/${offerData?.jobId}/jobs`}
                            style={{ textDecoration: "none" }}
                            className="roboto-font"
                            >
                            View Job Posting
                            </Link>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider style={{height:"1px",background:"#4540DB"}}/>
                        </Grid>
                    </Grid>
                <Grid container spacing={2} className="OfferDetailsPanel" >
                                            
                    <Grid item xs={4}>
                        <Typography  variant="h6" component="div"
                            className=" letter-spacing-normal primary-color-text" sx={{ paddingTop: "0px" }}>
                            Status <span class="mandatory-mark">*</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography  variant="h6" component="div"
                            className=" letter-spacing-normal " sx={{ paddingTop: "0px" }}>
                            {offerData?.status}  
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography  variant="h6" component="div"
                            className=" letter-spacing-normal primary-color-text" sx={{ paddingTop: "0px" }}>
                            Client <span class="mandatory-mark">*</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography  variant="h6" component="div"
                            className=" letter-spacing-normal " sx={{ paddingTop: "0px" }}>
                            {offerData?.clientName}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography  variant="h6" component="div"
                            className=" letter-spacing-normal primary-color-text" sx={{ paddingTop: "0px" }}>
                            Job Location <span class="mandatory-mark">*</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography  variant="h6" component="div"
                            className=" letter-spacing-normal " sx={{ paddingTop: "0px" }}>
                            {offerData?.jobLocation}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography  variant="h6" component="div"
                            className=" letter-spacing-normal primary-color-text" sx={{ paddingTop: "0px" }}>
                            Job Title <span class="mandatory-mark">*</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography  variant="h6" component="div"
                            className=" letter-spacing-normal " sx={{ paddingTop: "0px" }}>
                            {offerData?.jobTitle} 
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography  variant="h6" component="div"
                            className=" letter-spacing-normal primary-color-text" sx={{ paddingTop: "0px" }}>
                            Contract <span class="mandatory-mark">*</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography  variant="h6" component="div"
                            className=" letter-spacing-normal " sx={{ paddingTop: "0px" }}>
                            {offerData?.contractType}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography  variant="h6" component="div"
                            className=" letter-spacing-normal primary-color-text" sx={{ paddingTop: "0px" }}>
                            Start Date <span class="mandatory-mark">*</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography  variant="h6" component="div"
                            className=" letter-spacing-normal " sx={{ paddingTop: "0px" }}>
                            {moment(offerData?.jobStartDate).format("LL")} 
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography  variant="h6" component="div"
                            className=" letter-spacing-normal primary-color-text" sx={{ paddingTop: "0px" }}>
                            Pay Rate <span class="mandatory-mark">*</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography  variant="h6" component="div"
                            className=" letter-spacing-normal " sx={{ paddingTop: "0px" }}>
                            {formattedPayRate.packageValueAndCurrency} {formattedPayRate.packageType}
                        </Typography>
                    </Grid>
                    {offerData?.contractType == "Contract" && (<> <Grid item xs={4}>
                        <Typography  variant="h6" component="div"
                            className=" letter-spacing-normal primary-color-text" sx={{ paddingTop: "0px" }}>
                            Contract Duration <span class="mandatory-mark">*</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography  variant="h6" component="div"
                            className=" letter-spacing-normal " sx={{ paddingTop: "0px" }}>
                            {valueOrDefault(offerData?.contractDuration,"N/A")} 
                        </Typography>
                        </Grid></>)}
                    <Grid item xs={4}>
                        <Typography  variant="h6" component="div"
                            className=" letter-spacing-normal primary-color-text" sx={{ paddingTop: "0px" }}>
                            Resume <span class="mandatory-mark">*</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography  variant="h6" component="div"
                            className=" letter-spacing-normal " sx={{ paddingTop: "0px" }}>
                            <SaveAltOutlinedIcon
                                    onClick={() =>
                                downloadFile(offerData?.resumeInfo?.resourceUrl,offerData?.resumeInfo?.fileName)
                                    }
                                color="primary"
                                className="pointer"
                                data-testid="save-alt-icon"
                                /> <span style={{verticalAlign:"text-bottom"}}>{offerData?.resumeInfo?.fileName} </span>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            </Paper>
            </Grid>
        </Grid>
  );
}