import { PermissionsEnums } from "../../data/permission";
import { Error } from "../Common/Error";
import ClientDashboard from "../Dashboard/ClientDashboard";
import ClientJobs from "../Jobs/JobListing/AllJobPage";
import Faq from "../Faq/Faq";

import AddJobPage from "../pages/Job/AddJobPage";
import EditJobPage from "../pages/Job/EditJobPage";
import PendingJobPage from "../pages/Job/PendingJobPage";
import JobDetails from "../Jobs/JobDetails/JobDetails";
import RecommendedJob from "../Jobs/RecommendedJob/RecommendedJob";
import TeamMembers from "../pages/TeamMember/TeamMembers";
import AddClientTeamMemberPage from "../pages/TeamMember/AddClientTeamMemberPage";
import EditClientTeamMemberPage from "../pages/TeamMember/EditClientTeamMemberPage";
import Submissions from "../ViewSubmissions/Submissions/Submissions";

//for all clientTeamMemberRoutes in  project where user logged in must be ClientTeamMember to view the page

const clientTeamMemberRoutes = [
  {
    path: `/team/dashboard`,
    element: <ClientDashboard />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
    permissionId: "",
  },
  {
    path: "/team/submission/active",
    element: <Submissions />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  {
    path: "/team/submission/archived",
    element: <Submissions />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  {
    path: "/team/TeamMembers",
    element: <TeamMembers />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  {
    path: "/team/addteammember",
    element: <AddClientTeamMemberPage />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  {
    path: "/team/editteammember/:id",
    element: <EditClientTeamMemberPage />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },

  {
    path: `/team/members`,
    element: <Error />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  {
    path: `/team/faq`,
    element: <Faq />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },

  {
    path: `/team/findjobs`,
    element: <RecommendedJob />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  {
    path: `/team/jobdetail/:id/:fromPage`,
    element: <JobDetails />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: true,
    permissionId: PermissionsEnums.Jobs_Job_Details,

  },
  {
    path: `/team/jobs/:page`,
    element: <ClientJobs />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
    permissionId: PermissionsEnums.Jobs_View,
  },
  {
    path: `/team/addjob`,
    element: <AddJobPage />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
    permissionId: PermissionsEnums.Jobs_Add,

  },
  {
    path: `/team/editjob/:id`,
    element: <EditJobPage />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
    permissionId: PermissionsEnums.Jobs_Edit,

  },
  {
    path: `/team/pendingjob/:id`,
    element: <PendingJobPage />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
    permissionId: PermissionsEnums.Jobs_Pending,

  },
];

export default clientTeamMemberRoutes;
