import React from 'react';
import Grid from '@mui/material/Grid';
import LabelChip from '../LabelChip';
import SectionHeading from '../../../../ui/Form/Heading/SectionHeading';
import { Divider } from '@mui/material';

interface EducationProps {
  education: string[];
}

const ScorecardEducation: React.FC<EducationProps> = ({ education }) => {
  
  return (
    <Grid container spacing={2}>
        <Grid  item xs={12} >
            <SectionHeading
                title='Education'
            />
             <Divider className="borderedDivider-color" />
        </Grid>
            
        {education.map((educationTitle) => (
            <Grid item key={educationTitle} >            
            <LabelChip                 
                label={educationTitle}
            />   
            </Grid>        
            ))}
    </Grid>
  );
};

export default ScorecardEducation;
