import { useSelector } from "react-redux";
import { Error } from "../../components/Common/Error";

const PermissionRoute = ({ children, permissionId }) => {
  const { features, modules } = useSelector((state) => state.userPermissions);

  // Check if the user has the required permission for the current route
  const hasPermission = modules.includes(permissionId) || features.includes(permissionId);
  if(!permissionId)
  {
    return children;
  }
  if (hasPermission) {
    return children;
  } else {
    return <Error message="You don't have permission to access this page"/>;
  }
};

export default PermissionRoute;
