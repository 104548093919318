import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from '../Services/CommonHttpRequest';
import { GetClientById, GetTeamMemberById } from "../Services/clientService";
import { setDomainOnSignin, setFeaturedOnSignin } from "./Jobs/clientJobSlice";
let baseUrl = `${process.env.REACT_APP_RECUITER_API}`;

//get recruiter data";
export const getRecruiterData = createAsyncThunk(
  'getRecruiterData',
  async (id, thunkAPI) => {
    let user = JSON.parse(localStorage.getItem('user'));

    if(user?.userId && user.loggedInUserRole=="Recruiter"){
    return  await axios.get(`${baseUrl}api/RecruiterSignup/${user?.userId}`).then(response => {
      thunkAPI.dispatch(setDomainOnSignin(response.data.entity?.domains?.map((n)=>n?.domain)));
      thunkAPI.dispatch(setFeaturedOnSignin(true))
      return response.data})
    .catch(error => thunkAPI.rejectWithValue(error?.response?.data || error))
    }
    if(user?.userId && user.loggedInUserRole=="Client"){
      return  GetClientById(user?.userId).then(response => response.data)
      .catch(error => thunkAPI.rejectWithValue(error?.response?.data || error))
    }
    if(user?.userId && user.loggedInUserRole=="ClientTeamMember"){
      return  GetTeamMemberById(user?.userId).then(response => response.data)
      .catch(error => thunkAPI.rejectWithValue(error?.response?.data || error))
    }
  }
)


export const recruiterState = createSlice({
  name: "candidateSubmission",
  initialState: {
    recruiterData: null,
    error: null,
    success: null,
    loading : false,
    imageInfo: {imageUrl:"",fileName:''}
  },
  reducers: {
    
    clear: (state) => {
      
      state.recruiterData= null
      state.error= null
      state.success= null
      state.loading = false
      state.imageInfo = {imageUrl:"",fileName:''}
    },
    updateRecruiterData : (state, { payload })=>{
     
      state.recruiterData= payload
      state.error= null
      state.success= null
      state.loading = false

    },
    updateImage : (state, { payload })=>{
     
     state.imageInfo.imageUrl = payload.imageUrl


    }
   
   
  },
  extraReducers: (builder) => {
    //Post candidate
    builder.addCase(getRecruiterData.pending, (state, action) => {
      state.loading = true;
    })
     
   
    builder.addCase(getRecruiterData.fulfilled, (state, action) => {
      let user = JSON.parse(localStorage.getItem('user'));

      let clientImageUrl = action.payload?.entity?.clientAdditionalDetails?.companyLogo?.resourceUrl;
      let imageUrl =  user.loggedInUserRole=="Recruiter"? action.payload?.entity?.imageInfo?.imageUrl : clientImageUrl
      state.recruiterData = action.payload
      state.error=null
      state.success=true
      state.loading = false
      state.imageInfo.imageUrl = imageUrl
      
    })
    builder.addCase(getRecruiterData.rejected, (state, action) => {
      state.error = action.payload?.message? action.payload.message : action.error.message
      state.loading = false
    })

  },
});

// Action creators are generated for each case reducer function
export const {clear,updateRecruiterData,updateImage } =
  recruiterState.actions;

export default recruiterState.reducer;