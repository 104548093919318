export interface Paging {
  pageIndex: number;
  pageSize: number;
  sortColumn: string;
  sortOrder: SortType;
  isSorted: boolean;
  totalRecord: number;
}

export enum SortType {
  Ascending,
  Descending,
}

export interface Industry {
  id: string;
  industryName?: string;
}

export interface KeyValue{
    key:string;
    value:string;
    label:string
}

export enum FormMode {
  EDIT = 'EDIT',
  ADD = 'ADD',
  MORE = 'MORE',
}

export interface Mode {
  mode: FormMode;
}