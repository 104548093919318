import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { GetCandidateInterviewTypes } from '../../../../Services/candidateService';

export enum InterviewTypeOption {
inPerson ="ca8be1cf-bb2f-4911-ae78-2fb3a029c019",
webcam= "df4906f9-4862-4fdd-b427-7da0e2cfe5d4",
telephonic="Telephonic"
}
export interface InterviewType {
  id: string;
  interviewType: "In Person" | "Webcam" | "Telephonic" | string;
}

interface InterviewTypeAutoCompleteProps {
  label:string;
  fieldName:string;
  value:InterviewType;
  isReadOnly?:boolean;
  isRequired?:boolean;
  isError?: boolean;
  helperText?:string | boolean;
  handleChange: (selectedEventType: InterviewType | null) => void;
  handleBlur: (e: React.FocusEvent<any, Element>) => void;
}

const InterviewTypeAutoComplete: React.FC<InterviewTypeAutoCompleteProps> = ({ 
  label,
  value,
  fieldName,
  isRequired=false,
  isError=false,
  helperText="",
  isReadOnly=false, 
  handleChange,
  handleBlur 
}) => {

  //Initial component State

  const [interviewTypesList, setInterviewTypesList] = useState<InterviewType[]>([]);


  useEffect(()=>{
    _GetCandidateInterviewTypes();
  },[])

    // GET Master Interview Type
    const _GetCandidateInterviewTypes = async () => {
      GetCandidateInterviewTypes()
        .then((response: any) => {
          if (response?.data?.message?.appStatusCode === "RC_RSF") {
            setInterviewTypesList(response?.data?.entityList);
          } else {
            setInterviewTypesList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

  const handleTypeChange = (_event: React.ChangeEvent<{}>, changedValue: InterviewType | null) => {
    handleChange(changedValue);
  };

  return (
    <Autocomplete
      data-testid="interviewType"
      readOnly={isReadOnly}
      value={value}
      onChange={handleTypeChange}
      options={interviewTypesList}
      getOptionLabel={(option) => option?.interviewType}
      renderInput={(params) => (
        <TextField
          {...params}
          required={isRequired}          
          label={label}
          name={fieldName}
          variant="standard"
          error={isError}
          helperText={helperText}          
          onBlur={handleBlur}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
    />
  );
};

export default InterviewTypeAutoComplete;
