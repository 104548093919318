import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const SubmissionIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_4424_27323)">
          <path
            d="M0.5 10.7533C0.5 8.27333 0.5 5.79333 0.5 3.32C0.5 1.90667 1.28424 0.733333 2.56275 0.22C2.9384 0.0733333 3.32722 0 3.72923 0C6.83324 0 9.93066 0 13.0347 0C13.7925 0 14.5438 0 15.3017 0C16.8966 0 18.208 1.10667 18.498 2.69333C18.5375 2.91333 18.5507 3.14 18.5507 3.36667C18.5507 5.98667 18.5507 8.60667 18.5507 11.2333C18.5507 11.36 18.5771 11.4133 18.7089 11.4467C21.1605 12.1467 22.7619 13.7333 23.3221 16.2467C23.8625 18.6867 23.1309 20.78 21.2923 22.4533C20.363 23.3 19.2559 23.8067 18.0169 23.9467C15.6905 24.22 13.7662 23.42 12.2636 21.6C12.2043 21.5267 12.1516 21.5 12.0593 21.5C9.28481 21.5 6.51031 21.5 3.72923 21.5C2.24642 21.5 0.981089 20.52 0.612034 19.0667C0.532951 18.7667 0.50659 18.4533 0.50659 18.14C0.5 15.6733 0.50659 13.2067 0.50659 10.7467L0.5 10.7533ZM17.0613 11.24C17.0613 11.1867 17.0613 11.14 17.0613 11.0933C17.0613 10.6733 17.0613 10.26 17.0613 9.84C17.0613 7.66 17.0613 5.48 17.0613 3.3C17.0613 2.28 16.31 1.5 15.3347 1.5C11.453 1.5 7.57794 1.5 3.69627 1.5C3.23496 1.5 2.83295 1.67333 2.50344 2C2.13438 2.36 1.97622 2.81333 1.97622 3.32667C1.97622 6.14667 1.97622 8.96667 1.97622 11.7933C1.97622 13.9267 1.97622 16.0667 1.97622 18.2C1.97622 19.24 2.7341 20 3.75559 20C6.22693 20 8.69828 20 11.1696 20C11.2158 20 11.2619 20 11.3146 20C10.5765 17.9267 10.7281 15.9467 11.9341 14.1133C13.1401 12.28 14.8799 11.36 17.0547 11.24H17.0613ZM22.004 17.6267C22.004 14.94 19.8424 12.76 17.1799 12.7533C14.5307 12.7533 12.3756 14.9333 12.3691 17.62C12.3691 20.3067 14.5241 22.5 17.1799 22.5C19.8358 22.5 22.004 20.3067 22.004 17.6267Z"
            fill="black"
          />
          <path
            d="M9.52203 6.50667C8.2567 6.50667 6.99138 6.50667 5.72605 6.50667C5.33722 6.50667 5.04066 6.26 4.96817 5.89333C4.88249 5.42 5.22519 5 5.71287 5C6.65527 5 7.59768 5 8.54668 5C10.1415 5 11.7364 5 13.3378 5C13.7266 5 14.0232 5.24667 14.0957 5.61333C14.1813 6.08 13.8387 6.50667 13.3642 6.50667C12.4349 6.50667 11.4991 6.50667 10.5699 6.50667C10.2206 6.50667 9.87791 6.50667 9.52862 6.50667H9.52203Z"
            fill="black"
          />
          <path
            d="M9.52867 10.88C8.25675 10.88 6.99142 10.88 5.7195 10.88C5.34386 10.88 5.0407 10.6333 4.96821 10.2933C4.86936 9.81334 5.21205 9.38 5.69973 9.38C6.74099 9.38 7.77566 9.38 8.81692 9.38C10.3261 9.38 11.8287 9.38 13.3378 9.38C13.7135 9.38 14.0166 9.62 14.0891 9.96667C14.188 10.4467 13.8453 10.88 13.3576 10.88C12.3559 10.88 11.3608 10.88 10.359 10.88C10.0823 10.88 9.81205 10.88 9.53526 10.88H9.52867Z"
            fill="black"
          />
          <path
            d="M7.6702 15.26C7.02436 15.26 6.3851 15.26 5.73926 15.26C5.29112 15.26 4.95502 14.94 4.94843 14.5133C4.94843 14.0866 5.28453 13.7533 5.74585 13.7533C7.03754 13.7533 8.32923 13.7533 9.62092 13.7533C10.0625 13.7533 10.4052 14.0933 10.3986 14.5133C10.3986 14.9333 10.0559 15.26 9.61433 15.26C8.96848 15.26 8.31605 15.26 7.6702 15.26Z"
            fill="black"
          />
          <path
            d="M18.5507 17.9533C18.5507 18.0199 18.5507 18.0666 18.5507 18.1199C18.5507 18.7266 18.5375 19.3333 18.5507 19.9399C18.5639 20.4666 18.1948 20.7266 17.7994 20.7066C17.3776 20.6866 16.9493 20.7066 16.5275 20.7066C16.0859 20.7066 15.8289 20.4399 15.8289 19.9933C15.8289 19.3666 15.8289 18.7466 15.8289 18.1199C15.8289 18.0733 15.8289 18.0266 15.8289 17.9666C15.7762 17.9666 15.7301 17.9599 15.6839 17.9599C15.3808 17.9599 15.0842 17.9599 14.7811 17.9599C14.5109 17.9599 14.3395 17.8533 14.2538 17.6466C14.1616 17.4133 14.2407 17.2266 14.4054 17.0599C15.1435 16.3133 15.8816 15.5666 16.6198 14.8266C16.9559 14.4866 17.404 14.4799 17.7401 14.8133C18.4914 15.5666 19.2361 16.3199 19.9808 17.0799C20.2708 17.3733 20.1785 17.8199 19.8094 17.9399C19.7369 17.9599 19.6579 17.9666 19.5788 17.9666C19.2888 17.9666 18.9922 17.9666 18.7023 17.9666C18.6561 17.9666 18.61 17.9666 18.5441 17.9666L18.5507 17.9533Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_4424_27323">
            <rect
              width="23"
              height="24"
              fill="white"
              transform="translate(0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default SubmissionIcon;
