import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import "./ViewRecruiterInfoPopup.css";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ErrorMessage } from "../../../ui/ErrorMessage";
import getUserDetails from "../../../../Shared/utils/getUserDetails";
import { RejectCandidate, UpdateCandidateStatus } from "../../../../Services/candidateService";
import { CandidateSubmissionStatus } from "../../../../models/submission/CandidateSubmissionMasterStatusesModel";
import { UserAuth } from "../../../../models/interfaces";
import { JobOriginIds } from "../../../../models/Job/JobDetailsModel";
import { valueOrDefault } from "../../../../utils/complexityUtils";

interface Props {
  openPopup: boolean;
  handleDocumentsPopupClose: any;
  onRejectSuccess: any;
  selectedRowDetails: any;
  userRole:any;
}
const ariaLabel = { "aria-label": "description" };
export const RejectPopup: React.FC<Props> = ({
  openPopup,
  handleDocumentsPopupClose,
  onRejectSuccess,
  selectedRowDetails,
  userRole
}) => {

  const initialState = {
    commentText: "",
  };
  let userDetails = getUserDetails();
  const commentSchema = Yup.object({
    commentText: Yup.string()
      .required("Please enter comments")
      .max(
        500,
        "Maximum limit allowed is less than or equal to 500 characters"
      ),
  });
  const formik = useFormik({
    initialValues: initialState,
    validationSchema: commentSchema,
    onSubmit: async (values, actions) => {

      if(selectedRowDetails?.jobOriginId === JobOriginIds.jobRialto){
        _handleRejectOnClientJob()
      }else{
        confirmReject();
      }
    },
  });
  const [openConfirmReject, setOpenConfirmReject] = React.useState(false);
  const [pageStatus, setPageStatus] = React.useState({
    isSuccess: false,
    error: "",
    loading: false,
    message: "",
  });
  const handleConfirmationCancel = () => {
    setOpenConfirmReject(false);
    handleDocumentsPopupClose();
  };
  const confirmReject = () => {
    
    setPageStatus({ ...pageStatus, loading: true, message: "" });
    let payLoad = {
      submissionId: valueOrDefault(selectedRowDetails?.id, ""),
      comment: formik.values.commentText,
      adminGuid: userDetails.id,
    };

    //onSuccess
    RejectCandidate(payLoad).then((response) => {
      if (response.data.status == "200") {
        setPageStatus({
          isSuccess: true,
          error: "",
          loading: false,
          message: "Submission has been rejected successfully",
        });
        handleConfirmationCancel();
        onRejectSuccess();
      } else {
        setPageStatus({
          isSuccess: false,
          error: response.data?.message?.appStatusDescription,
          message: response.data?.message?.appStatusDescription,
          loading: false,
        });
      }
    });
  };

  const _handleRejectOnClientJob = () => {
    
    setPageStatus({ ...pageStatus, loading: true, message: "" });

    const requestPayload = {
        submissionId: valueOrDefault(selectedRowDetails?.id, ""),
        clientId: valueOrDefault(selectedRowDetails?.clientId, ""),
        eventId: (userRole === UserAuth.Client || userRole === UserAuth.ClientTeamMember) ?
                              CandidateSubmissionStatus.RejectedByCLI :
                              CandidateSubmissionStatus.RejectedByAdmin,

        feedback: formik.values.commentText,
        redirectUrl: ""
      }      
    //onSuccess for client Job
    UpdateCandidateStatus(requestPayload).then((response) => {
      if (response?.data?.message?.appStatusCode === "RC_RSF") {
        setPageStatus({
          isSuccess: true,
          error: "",
          loading: false,
          message: "Submission has been rejected successfully",
        });
        onRejectSuccess();
      } else {
        setPageStatus({
          isSuccess: false,
          error: response.data?.message?.appStatusDescription,
          message: response.data?.message?.appStatusDescription,
          loading: false,
        });
      }
    });
  };
  return (
    <div>
      {!openConfirmReject && (
        <Dialog
          className="custom-popup-border"
          open={openPopup}
          onClose={handleDocumentsPopupClose}
          aria-labelledby="viewRecruiterPopup-title"
          aria-describedby="viewRecruiterPopup-description"
        >
          <Paper elevation={5} style={{ backgroundColor: "#FFF" }}>
            <DialogTitle sx={{ m: 0, p: 2 }}>
              <IconButton
                aria-label="close"
                onClick={handleDocumentsPopupClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="viewRecruiterPopup-description">
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography
                      data-testid="center"
                      align="center"
                      className="custom-reject-txt"
                    >
                      Are you sure you want to{" "}
                      <span style={{ color: "#F24444" }}>Reject</span> this<br />
                      {
                      selectedRowDetails?.finalStatusId === CandidateSubmissionStatus.SubmittedToCLI ||
                      selectedRowDetails?.finalStatusId === CandidateSubmissionStatus.SubmittedToAM
                      ?
                       "Candidate" :"Submission"}?
                    </Typography>
                  </Grid>

                  <Box>
                    <Grid container spacing={1} className="recruiterInfoBox">
                      <Grid item xs={6} sm={12}>
                        <TextField
                          error={
                            formik.touched.commentText &&
                            Boolean(formik.errors.commentText)
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.commentText}
                          name="commentText"
                          inputProps={ariaLabel}
                          fullWidth
                          type="text"
                          multiline
                          rows={3}
                          variant="outlined"
                          className="AddCommentTxtArea"
                          placeholder="Add Feedback here"
                        />
                        <ErrorMessage
                          errorText={
                            formik.touched.commentText &&
                            formik.errors.commentText
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className="custom-max-limit">
                          Maximum limit allowed is 500 characters.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </DialogContentText>
              <Grid
                item
                xs={12}
                sm={12}
                className="row"
                style={{
                  textAlign: "right",
                  marginTop: "30px",
                  marginRight: "24px",
                }}
              >
                <Button
                  className="custom-submit-btn"
                  sx={{
                    marginRight: "10px",
                    width: "90px",
                  }}
                  variant="outlined"
                  style={{ textTransform: "capitalize" }}
                  onClick={handleDocumentsPopupClose}
                >
                  Cancel
                </Button>
                <Button
                  data-testid="contained"
                  className="custom-submit-btn"
                  sx={{
                    width: "90px",
                  }}
                  variant="contained"
                  style={{ textTransform: "capitalize" }}
                  onClick={() => formik.handleSubmit()}
                >
                  Reject
                </Button>
              </Grid>
            </DialogContent>
            <DialogActions></DialogActions>
          </Paper>
        </Dialog>
      )}
    </div>
  );
};
export default RejectPopup;
