import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Alert, Divider } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  GetRecruiterById,
  SaveVerifyRecruiter,
} from "../../../Services/recruiterService";
import { ErrorMessage } from "../../ui/ErrorMessage";
import RecruiterPaymentDetails from "../components/RecruiterPaymentDetails";
import { RecruiterViewInfo } from "../components/RecruiterViewInfo"; 
import { RecruiterActions } from "../components/RecruiterActions";
import { getNevLink } from "../../../utils/getFileFromUrl";
import { ViewHearAboutUs} from "../components/ViewHearAboutUs"
import { VerifyRecruiterSuccessPopup } from "../components/VerifyRecruiterSuccessPoppup";
import convertToLocalizedDateTime from "../../../utils/AppDateUtils";
import { BreadCrumbItem } from "../../../models/AppModels";
import AppBreadCrumb from "../../Common/AppBreadCrumb";
import getUserDetails from "../../../Shared/utils/getUserDetails";
import { FormikProps } from "formik";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));


const VerifyRecruiter: React.FC = () => {

  const DefaultBreadcrumbs: BreadCrumbItem[] = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
    { isLeaf: false, sequence: 2, title: "Recruiters", to: `${ getNevLink('/recruiters')}` },
    { isLeaf: true, sequence: 3, title: "Verify", leafClasses:" v2LastLeft"},
  ];

  let { id }:any = useParams();

  const user = getUserDetails();

  const [isDialogOpen, setOpen] = React.useState(false);
  const [recruiterDetails, setRecruiterDetails] = React.useState<any>();

  const [errors, setErrors] = React.useState<string | null>(null);
  const [recruiterStatus, setRecruiterStatus] = React.useState('');
  const [pageStatus, setPageStatus] = React.useState({
    isSuccess: false,
    error: "",
    loading: false,
  });
  const navigate = useNavigate();
  const USAFormRef = React.useRef<FormikProps<any>>(null);
  const PayoneerFormRef = React.useRef<FormikProps<any>>(null);

  const feedback = {
      feedbackNotes: {
      feedbackFor: id,
      feedbackType: "Good",
      feedbackDescription: "",
      status: "",
      createdBy: user?.id,
      creatorName: user?.userName,
      creatorEmail: user?.email,
      createdDate: new Date(),
      reasons:[]
    },

    redirectUrl: `${window.location.origin}/dashboard?userId=${id}`,
  };

  const [isOpenDialog,setIsOpenDialog] = React.useState({
  status:"",
  action:false
  });

  const GetRecruiterDetailsById = ()=>{
    GetRecruiterById(id)
    .then((res) => {
      if (res?.data?.message?.appStatusCode === "RC_RSF") {
        
        setRecruiterDetails(res?.data?.entity);
      }
    })
    .catch((error:any)=>{
      console.log(error)
    })
  }

  

  const handleActionBtn = (statusType: string,actionVal: boolean)=>{

    setIsOpenDialog({status:statusType,action:actionVal})
  }

  const handleDocumentsPopupClose = (statusType: string, actionVal: boolean)=>{

    setIsOpenDialog({status:statusType,action:actionVal})
  }

  const handlePopupSubmit = (reason:any,feedbackDescription:string, recruiterStatus:any)=>{
    const finalSelectedReasonList:any[] = [];

    setStatus(finalSelectedReasonList,feedbackDescription,recruiterStatus)
  }

  const setStatus = async (reasons:any,feedbackDescription:string,recruiterStatus:any) => {

    let payloadModel = {
      ...feedback,
      feedbackNotes: {
        ...feedback.feedbackNotes,
        feedbackDescription: feedbackDescription,
        status: recruiterStatus,
        reasons:reasons
      },
     
    }

    setRecruiterStatus(recruiterStatus);
    if (feedbackDescription && feedbackDescription?.trim()?.length < 1) {
      setErrors("Feedback is required");
      return;
    }
    setErrors(null);
    setPageStatus({ isSuccess: false, error: "", loading: true });
    SaveVerifyRecruiter(payloadModel)
      .then((response) => {
        if (response.data.status === 200) {
          setPageStatus({ isSuccess: true, error: "", loading: false });
          setOpen(true);
        } else {
          setPageStatus({
            isSuccess: false,
            error: response.data?.message?.appStatusDescription,
            loading: false,
          });
        }
      })
      .catch((er) => {
        setPageStatus({
          isSuccess: false,
          error: er.message,
          loading: false,
        });
      });
  };

  const handleClose = () => {
    setOpen(false);
    navigate(getNevLink("/recruiters"));
  };

  useEffect(() => {    
    if(id){
      GetRecruiterDetailsById()
    }    
  }, [id]);

  return (
    <>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <AppBreadCrumb
            classes="custom-appbreadcrumb"
            BreadCrumbItems={DefaultBreadcrumbs}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography className="Client-txt-fontwt">
            Manage Recruiter
            <Typography
              variant="h6"
              component="span"
              style={{ color: "grey" }}
            >
              &nbsp; ({recruiterDetails?.recruiterId})
            </Typography>
            {
              recruiterDetails?.status === "Approved" 
              ? 
                <CheckCircleIcon  className="verifyRecruiterIcon"/> 
              : 
              null              
            } 
          </Typography>
        </Grid>
        
        <Grid className="custom-client-rspnsv" item xs={12} sm={6}>
          <Typography className="client-text" pr={2} sx={{
                textAlign: {
                  xs: 'unset',  // For extra small screens
                  sm: 'left',    // For small screens
                  md: 'right',   // For medium screens
                  lg: 'right',  // For large screens
                  xl: 'right'     // For extra large screens
                }
              }}>
            Signed Up on : &nbsp;{" "}
            <span className="singnedDateText">
            {
              convertToLocalizedDateTime(recruiterDetails?.createdDateEST,"MMMM DD,YYYY,h:mm:ss A")
            }
            </span>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={2} sx={{padding:2}}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Item elevation={0}>
                  <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                    sx={{
                      flexDirection: "row",
                      "& .MuiFormControl-root": {
                        marginTop: "15px",
                        marginBottom: "3px",
                      },
                    }}
                  >

                    <RecruiterViewInfo recruiterDetails={recruiterDetails}/>
                    <ViewHearAboutUs recruiterDetails={recruiterDetails}/>
                    <RecruiterPaymentDetails 
                      recruiter={recruiterDetails}  
                      USAFormRef={USAFormRef}
                      PayoneerFormRef={PayoneerFormRef}
                      />  

                    <Grid item xs={12} md={10} mt={2}>
                      <RecruiterActions
                      recruiterDetails={recruiterDetails}
                      handleActionBtn={handleActionBtn}
                      handleDocumentsPopupClose={handleDocumentsPopupClose}
                      isOpenDialog={isOpenDialog}
                      handlePopupSubmit={handlePopupSubmit}
                      />
                    </Grid>
                    

                    <Grid item xs={12} md={10}>
                      <Item style={{ padding: "0px" }}>
                        {
                        recruiterDetails?.status === "Blacklisted" 
                        ?
                        <>  
                        <h1>Account Blacklisted Permanently</h1>
                        <Typography
                          variant="subtitle1"
                          component="div"
                          className="abel"
                        >
                          It looks like your JobRialto account is
                          suspended. To get all the details, look for
                          the email we sent you at the time your
                          account was suspended by Admin. The email
                          subject line should read: “Your JonRialto
                          account has been suspended. You can contact
                          the support center for more details by
                          writing an email to:
                          SupportCenter@Jobrialto.com
                        </Typography></> 
                        : 
                        <>
                          <>

                          </>
                          {errors !== null && (
                            <ErrorMessage errorText={errors} />
                          )}
                          {pageStatus.error && (
                            <Alert severity="error">
                              {pageStatus.error}
                            </Alert>
                          )}
                        </>
                        }                         
                      </Item>
                        
                    </Grid>
                    
                  </Box>
                </Item>
              </Grid>
              <Grid item xs={12} md={6}>
                <Item elevation={0}>
                  <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                    sx={{
                      flexDirection: "row",
                      "& .MuiFormControl-root": {
                        marginTop: "15px",
                        marginBottom: "3px",
                      },
                    }}
                  ></Box>
                    <Box 
                          mt={4}
                          display="flex"
                          justifyContent="flex-end">

                        </Box>
                </Item>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        
    </Grid>

        <VerifyRecruiterSuccessPopup
          onClose={handleClose}
          open={isDialogOpen}
          recruiterStatus={recruiterStatus}
        />
    </>
  );
}

export default VerifyRecruiter;
