import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react";
import { AxiosError, AxiosRequestConfig } from "axios";
import axiosInstance from "../../Services/CommonHttpRequest";
import {
  AdminFilter,
  AdminStateFilter,
} from "../../models/admin/AdminFilterModel";
import { AppResponse } from "../../models/app/AppResponse";

let baseUrlIdProvider = `${process.env.REACT_APP_IDPROVIDER_API}`;
const filterInitialState: AdminStateFilter = {
  status: [],
  pageIndex: 1,
  pageSize: 10,
  sortColumn:"",
  sortOrder:"descending",
  isSorted: true,
  totalRecord: 0,
  searchKeywords:"",
  isActive: true,
  loggedInUserRole:"SuperAdmin"
};

export const initialState: AdminStateFilter = filterInitialState;

export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl?: string } = { baseUrl: baseUrlIdProvider }
  ): BaseQueryFn<
    {
      url: string;
      method?: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
    },
    unknown,
    unknown
  > =>
  async ({ url, method = "GET", data }) => {
    try {
      const result = await axiosInstance({ url: baseUrl + url, method, data });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: { status: err.response?.status, data: err.response?.data },
      };
    }
  };

export const AdminListingSlice = createSlice({
  name: "admin",
  initialState: initialState,
  reducers: {
    handleAdminSearchKeyword: (state, action: PayloadAction<string>) => {
      state.searchKeywords = action.payload;
    },

    clearAdminStatusFilter: (state) => {
      state.status = [];
    },
    handleAdminStatusArrayChange: (state, action: PayloadAction<any>) => {
      const { name, isChecked } = action.payload;

      if (isChecked) {
        // Add 'name' to the 'status' array if isChecked is true
        state.status.push(name);
      } else {
        // Remove 'name' from the 'status' array if isChecked is false
        state.status = state.status.filter((item: any) => item !== name);
      }
    },
  },
});

export const adminsAPI = createApi({
  reducerPath: "adminsAPI",
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    getAdmins: build.mutation<AppResponse<any>, AdminFilter>({
      query: (filter) => ({
        url: `api/Admin/Filter`,
        method: "post",
        data: filter,
      }),
    }),
  }),
});

export const { useGetAdminsMutation } = adminsAPI;

export const {
  handleAdminSearchKeyword,
  clearAdminStatusFilter,
  handleAdminStatusArrayChange,
} = AdminListingSlice.actions;

export const adminListingReducer = AdminListingSlice.reducer;
