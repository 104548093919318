import React from 'react';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import './../Scorecard.scss'; 

interface LabelChipProps {
  label: string;
  className?: 'scoreSuccessChip' | 'ErrorChip' | string;
  isTooltipShow?: boolean;
}

const LabelChip: React.FC<LabelChipProps> = ({
  label,
  className = 'default',
  isTooltipShow = true
}) => {
  const maxLabelLength = 20;
  const renderLabel = () => {
    if (label.length > maxLabelLength && isTooltipShow) {
      return (
        <Tooltip title={label} arrow>
          <span className="ellipsis-text">{`${label.slice(0, maxLabelLength)}...`}</span>
        </Tooltip>
      );
    } else {
      return label;
    }
  };

  return (
    <Chip 
      label={renderLabel()} 
      color="primary" 
      variant="outlined"  
      className={`scorecardLabel-root ${className}` }
    />
  );
};

export default LabelChip;