import VerifyRecruiter from '../Recruiters/VerifyRecruiter/VerifyRecruiter';
import AdminDash from '../Dashboard/AdminDash';
import { AllRecruiter } from '../Admin/ManageCompensation/AllRecruiter';
import { IndividualRecruiter } from '../Admin/ManageCompensation/IndividualRecruiter';
import { Earnings } from '../MyEarnings/Earnings';
import { NewSubmissionPage } from '../ViewSubmissions/NewViewSubmission/NewSubmissionPage';
import { AllCompensation } from '../Admin/ManageCompensation/AllCompensation';
import VerifyHistory from "../Recruiters/VerifyRecruiter/VerifiedHistory";
import CandidateViewDetails from "../Candidate/CandidateViewDetails";
import { MainDashboard } from "../Dashboard/MainDashboard";
import EditRecruiters from "../Recruiters/EditRecruiter/EditRecruiters";
import SubmissionFilter from "./../SubmissionFilter/SubmissionFilter";
import ViewSubmissions from "../ViewSubmissions/ViewSubmissions";
import SubmitResume from "../Jobs/Submission/SubmitResume";
import Contracts from "../Contracts/Contracts";
import ContractDetail from "../Contracts/ContractDetail";
import AddRecruiters from "../Recruiters/AddRecruiters";
import { RoleManagement } from '../Admin/RoleManagement/RoleManagement';
import  AddUser  from '../Admin/UserManagement/AddUser';
import EditUser from '../Admin/UserManagement/EditUser';
import { Admins } from '../Admin/Admins';
import CandidateOfferDetails from '../Candidate/SubmissionDetails/CandidateOfferDetails';
import { PermissionsEnums } from '../../data/permission';
import ApproveEarning from '../MyEarnings/ApproveEarning';
import AddClient from '../Client/UserManagement/AddClient';
import Clients from '../Client/Listing/Clients';
import { Edit } from '../Client/Edit';
import JobDetails from '../Jobs/JobDetails/JobDetails';
import Faq from '../Faq/Faq';
import RecruiterListPage from '../pages/Recruiter/RecruiterListPage';
import ManageClientPage from '../pages/Client/ManageClient/ManageClientPage';
import ClientJobs from '../Jobs/JobListing/AllJobPage';
import CandidateListPage from '../pages/Candidate/CandidateListPage';
import SavedJobPage from '../Jobs/JobListing/SavedJobPage';
import { getIsClientModuleEnabled } from '../../utils/AppConfig';

//for all superAdminRoutes in  project where user logged in must be superAdmin to view the page
const isClientModuleEnabled = getIsClientModuleEnabled();

const superAdminRoutes = [
  {
    path: "/admin/submission/active",
    element: <NewSubmissionPage />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
    permissionId : PermissionsEnums.Submissions_All

  },
  {
    path: "/admin/submission/archived",
    element: <NewSubmissionPage />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
    permissionId : PermissionsEnums.Submissions_All

  },

  
  {
    path: '/admin/recruiters',
    element: <RecruiterListPage />,
    exact: true,
    showHeader: true,
    showLeftNav : true,
    showFooter : false,

  },
  {
    path: '/candidates/submissiondetails/offerdetails/:submissionId/:pageId',
    element: <CandidateOfferDetails />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  {
    path:"/admin/edit-recruiter/:id",
    element: <EditRecruiters />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  {
    path: "/admin/dashboard",
    element: <MainDashboard />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  {
    path: '/admin/admindash',
    element: <AdminDash />,
    exact: true,
    showHeader: true,
    showLeftNav : true,
    showFooter : false
  },
  {
    path: '/admindash',
    element: <AdminDash />,
    exact: true,
    showHeader: true,
    showLeftNav : true,
    showFooter : false
  },
  {
    path: '/admin/earnings',
    element: <Earnings />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter : false,
    permissionId : PermissionsEnums.Earnings_View


  },
  {
    path: '/admin/recruiter/savedjobs/:id',
    element: <SavedJobPage />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter : false

  },
  {
    path: '/admin/oldsubmission/:type',
    element: <NewSubmissionPage/>,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter : false,
    permissionId : PermissionsEnums.Submissions_All



  },
  {
    path: '/admin/recruiters/verify/:id',
    element: <VerifyRecruiter />,
    exact: true,
    showHeader: true,
    showLeftNav : true,
    showFooter : false
  },
  
 {    path: "/admin/recruiter/add",
 element: <AddRecruiters />,
 exact: true,
 showHeader: true,
 showLeftNav: true,
 showFooter: false,
 permissionId : PermissionsEnums.Recruiter_Add

},
  {
    path: '/admin/compensation/all',
    element: <AllCompensation/>,
    exact: true,
    showHeader: true,
    showLeftNav : true,
    showFooter : false,
    permissionId : PermissionsEnums.Compensation_View

  },

  {
    path: "/admin/recruiters/verify_history/:id",
    element: <VerifyHistory />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  {
    path: "/admin/candidate-detail/:id",
    element: <CandidateViewDetails />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  {
    path: "/admin/candidates",
    element: <CandidateListPage />,
    exact: true,
    showHeader: true,
    showLeftNav: false,
    showFooter: false,
    permissionId : PermissionsEnums.All_Candidates_View

  },
  {
    path: "/admin/submissionfilter",
    element: <SubmissionFilter />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  {
    path: "/admin/submissions/viewsubmission/:id/:fromPage/",
    element: <ViewSubmissions />,
    exact: true,
    showHeader: true,
    showLeftNav: false,
    showFooter: false,
  },

  {
    //fromPage required to show breadcrumbs

    path: "/admin/submitresume/:id/:fromPage",
    element: <SubmitResume />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
 
  {
    path: "/admin/jobdetail/:id/:fromPage",
    element: <JobDetails />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: true,
  },
  {
    path: "/admin/jobs/:page",
    element: <ClientJobs/>,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: true,
    permissionId : PermissionsEnums.Jobs_View

  },
  {
    path: "/admin/submissions/viewsubmission/:id/:fromPage/",
    element: <ViewSubmissions />,
    exact: true,
    showHeader: true,
    showLeftNav: false,
    showFooter: false,
  },
  {
    path: "/admin/contracts",
    element: <Contracts />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
    permissionId : PermissionsEnums.Contracts_View

  },
  {
    path: "/admin/ContractDetail/:id",
    element: <ContractDetail />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  {
    path: "/admin/add",
    element: <AddRecruiters />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
    permissionId : PermissionsEnums.Recruiter_Add

  },
  {
    path: '/admin/edit-recruiter/:id/:fromPage',
    element: <EditRecruiters />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  {
    path: '/admin/compensation/allrecruiter',
    element: <AllRecruiter />,
    exact: true,
    showHeader: true,
    showLeftNav : true,
    showFooter : false,
    permissionId : PermissionsEnums.Compensation_View

  },
  {
    path: '/admin/compensation/individualrecruiter',
    element: <IndividualRecruiter />,
    exact: true,
    showHeader: true,
    showLeftNav : true,
    showFooter : false,
    permissionId : PermissionsEnums.Compensation_View

  },

  {
    path: '/admin/role/manage',
    element: <RoleManagement/>,
    exact: true,
    showHeader: true,
    showLeftNav : true,
    showFooter : false,
    permissionId : PermissionsEnums.Role_Management_Page
  },
  {
    path: '/admin/ApproveEarning/:id',
    element: <ApproveEarning/>,
    exact: true,
    showHeader: true,
    showLeftNav : true,
    showFooter : false,

  },
  {
    path: '/admin/user/add',
    element: <AddUser/>,
    exact: true,
    showHeader: true,
    showLeftNav : true,
    showFooter : false,
    permissionId : PermissionsEnums.Admins_Add
  },
  {
    path: '/admin/user/edit/:id',
    element: <EditUser/>,
    exact: true,
    showHeader: true,
    showLeftNav : true,
    showFooter : false
  },
  {
    path: '/admin/admins/all',
    element: <Admins/>,
    exact: true,
    showHeader: true,
    showLeftNav : true,
    showFooter : false,
    permissionId : PermissionsEnums.Admins_View

  },
  {
    path: `/admin/faq`,
    element: <Faq />,
    exact: true,
    showHeader: false,
    showLeftNav: true,
    showFooter: false,
  }
  // and so on for other superAdminRoutes in  project
]

if(isClientModuleEnabled){
  superAdminRoutes.push(
    {
      path: '/admin/clients',
      element: <Clients />,
      exact: true,
      showHeader: true,
      showLeftNav: true,
      showFooter: false,
      permissionId : PermissionsEnums.CLUserView
  
    },
    {
      path: `/admin/manageclient`,
      element: <ManageClientPage />,
      exact: true,
      showHeader: true,
      showLeftNav: true,
      showFooter: false,
      permissionId : PermissionsEnums.Manage_CLUser
    },
    {
      path: "/admin/client/edit/:id/:type",
      element: <Edit />,
      exact: true,
      showHeader: true,
      showLeftNav: true,
      showFooter: false,
    },
    {
      path: '/admin/client/add',
      element: <AddClient />,
      exact: true,
      showHeader: true,
      showLeftNav : true,
      showFooter : false
    },
  )
}

export default superAdminRoutes;

export const superAdminRoutePaths = [...superAdminRoutes.map(n=>n.path)];