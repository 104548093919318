
import { Card, CardContent } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import TechStatsDonutChartAdmin from "./TechStatsDonutChartAdmin";

export default function Domain({data}) {
  return (
    <Card sx={{ height: "100%" }} className="card-custom-shadow highlight-card">
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
          <Grid
            item
            sx={{
              padding: 0,
              width: "100%",
              height: "100%",
              maxHeight: "200px",
            }}
          >
            <Box>
              <Typography
                variant="caption2"
                sx={{ fontSize: "14px", fontWeight: "600" }}
              >
                Recruiters by Domain
              </Typography>
            </Box>
            <Box
              sx={{
                paddingTop: "0px",
                display: "flex",
                justifyContent: "center",
                height: "100%",
                padding: "0 10px !important",
              }}
            >
             {data!==undefined &&   <TechStatsDonutChartAdmin data={data}/>}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
