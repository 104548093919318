import React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { isPastDate } from "../../../Shared/utils/dateUtils";
import { Date } from "../../ui/Date";
import moment from "moment";
import { Alert } from "@mui/material";
import { JobStatus, getJobStatusColor } from "../../../utils/AppUtils";

interface Props {
  jobDetails: any;
  isLoadingJob: any;
}
const ClientRightSideBar: React.FC<Props> = ({ jobDetails, isLoadingJob }) => {
  let isExpired = isPastDate(jobDetails?.jobEndDate);
  let now = moment();
  let days: any = now.diff(jobDetails?.postingDate, "days");
  let isJobActive = jobDetails?.jobStatus?.toLowerCase() == "active";
  return (
    <div>
      <Box className="jobsRightSidebar">
        <List style={{ paddingTop: "20px" }} className="custom-list">
          <ListItem
            style={{ marginBottom: "12px" }}
            className="custom-rightsidebar"
          >
            <span>Number of Openings</span>{" "}
            <span>{jobDetails?.totalRemainingPositions}</span>
          </ListItem>
          <ListItem
            style={{ marginBottom: "12px" }}
            className="custom-rightsidebar"
          >
            <span>Job Status</span>
            <span
              style={{
        
                color: 
                  getJobStatusColor(jobDetails?.jobStatus as JobStatus),
              
              }}
            >
              {jobDetails?.isDisabled === true
                ? "Not Active"
                : jobDetails?.jobStatus}
            </span>
          </ListItem>
          <ListItem
            style={{ marginBottom: "12px" }}
            className="custom-rightsidebar"
          >
            {jobDetails?.postingDate && (
              <>
                <span>Posted</span>{" "}
                <span>{days == "0" ? "Today" : `${days} days ago`}</span>
              </>
            )}
          </ListItem>
          <ListItem className="custom-rightsidebar">
            <span> {isExpired ? "Expired" : "Expires"}</span>{" "}
            {<Date dateTime={jobDetails?.jobEndDate} />}
          </ListItem>
          {isLoadingJob
            ? ""
            : !isJobActive && (
                <Alert style={{ marginTop: "10px" }} severity="error">
                  This job is no longer active for submission
                </Alert>
              )}
        </List>
      </Box>
    </div>
  );
};

export default ClientRightSideBar;
