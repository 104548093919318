import {
  makeStyles
} from '@material-ui/core/styles';
  const checkboxStyle = makeStyles((theme) => ({
    backgroundColorOnInnerBoxOfCustomIcon: {
      '&.Mui-disabled': {
        pointerEvents: 'auto',
        '&:hover': {
          backgroundColor: 'transparent',
        },
        cursor: 'not-allowed',
        '& .MuiSvgIcon-root .innerBox': {
          fill: '#eee',
        },
      },
    },
    backgroundColorOnWholeIcon: {
      '&.Mui-disabled': {
      
        '& .MuiSvgIcon-root': {
          color: 'grey !important',
  
        },
      },
    },
  }));
  export default checkboxStyle;