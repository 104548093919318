import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import Group_524 from "../../../assets/images/Group_524.svg";
import Comments from "../../../assets/images/Comments.svg";
import ApproveadminIcon from "../../../assets/images/ApproveadminIcon.svg";
import LineFeedback from "../../../assets/images/LineFeedback.svg";
import Moment from "moment";
import { Asterisk } from "../../ui/Asterisk";
import { ModuleComponent } from "../../Common/Permission/ModuleComponent";
import { PermissionsEnums } from "../../../data/permission";

import convertToLocalizedDateTime from "../../../utils/AppDateUtils";
import SectionHeading from "../../ui/Form/Heading/SectionHeading";
import { ternary } from "../../../utils/complexityUtils";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  boxShadow: "none",
  initComment: {
    padding: "10px",
    marginLeft: 35,
    paddingLeft: 15,
  },

  lastComment: {
    padding: "10px",
    marginLeft: 35,
    paddingLeft: 15,
  },
}));

const useStyles = makeStyles((theme: any) => ({
  initComment: {
    padding: "10px",
    marginLeft: 35,
    paddingLeft: 10,
    fontSize: "12px",
  },

  lastComment: {
    padding: "10px",
    marginLeft: 35,
    paddingLeft: 10,
    fontSize: "12px",
  },
}));

interface IPROPS {
  commentDetails: any;
  type?: any;
}
export const FeedbackCommentsTimeline: React.FunctionComponent<IPROPS> = (
  props
) => {
  const classes = useStyles();
  const getDate = (date: string) => {
    return Moment.utc(date).format("MMMM DD,YYYY,h:mm:ss A").toString();
  };

  function renderFeedbackIcon(feedbackNote: any) {
    return (
      <>
        {props.type === "client" ? (
          <>
            {feedbackNote.status === "Approved" ? (
              <img
                src={ApproveadminIcon}
                alt=""
                style={{
                  position: "absolute",
                  top: 5,
                  fontSize: "large",
                }}
              />
            ) : (
              <img
                src={Comments}
                alt=""
                style={{
                  position: "absolute",
                  top: 5,
                  fontSize: "large",
                }}
              />
            )}
          </>
        ) : (
          <img
            src={Group_524}
            alt=""
            style={{
              position: "absolute",
              top: 5,
              fontSize: "large",
            }}
          />
        )}
      </>
    );
  }

  function renderFeedbackHeading(feedbackNote: any) {
    return (
      <>
        {props.type === "client" ? (
          <span>
            <Typography className="adminComment-heading1">
              Admin Comments <Asterisk />
            </Typography>
            <Typography
              className="custom-admin-postdate"
              fontSize="14px"
              style={{
                marginLeft: 45,
              }}
            >
              Posted on{" "}
              {getDate(feedbackNote.createdDate)}
            </Typography>
          </span>
        ) : (
          <span>
            <Typography className="adminComment-heading">
              Admin Comments <Asterisk />
            </Typography>
            <Typography
              fontSize="12px"
              style={{
                marginLeft: 45,
              }}
            >
              Posted on{" "}
              {convertToLocalizedDateTime(
                feedbackNote.createdDate,
                "MMMM DD,YYYY,h:mm:ss A"
              )}
            </Typography>
          </span>
        )}
      </>
    );
  }

  function renderFeedbackStatus(feedbackNote: any) {
    return (
      <>
        {props.type === "client" ? 
          (
            <Typography
              className="custom-adminapproved-text"
              fontSize="14px"
              sx={{
                justifySelf: "end",
              }}
            >
              Account{" "}
              {feedbackNote.status === "MarkIncomplete"
                ? "marked incomplete"
                : feedbackNote.status}{" "}
              by Admin
            </Typography>
          ) : 
          (
            <Typography
              fontSize="14px"
              sx={{
                justifySelf: "end",
              }}
            >
              Account{" "}
              {feedbackNote.status === "MarkIncomplete"
                ? "marked incomplete"
                : feedbackNote.status}{" "}
              by Admin
            </Typography>
          )
        }
      </>
    );
  }

  function renderFeedbackText(feedbackNote: any) {
    return (
      <>
        {props.type === "client" ? 
          (
            <div className="custom-feedback-txt">
              {feedbackNote.status === "Approved" ? 
                (
                  <span className="custom-approve-headingtxt">
                    Approved{" "}
                    <span style={{ color: "#353535" }}>
                      :{" "}
                    </span>{" "}
                  </span>
                ) : (
                  <span className="custom-reject-headingtxt">
                    Rejected{" "}
                    <span style={{ color: "#353535" }}>
                      :{" "}
                    </span>{" "}
                  </span>
                )
              }
              {feedbackNote.feedbackDescription}
              <img src={LineFeedback} width="100%" />
            </div>
          ) : 
          (
            <div>
              {feedbackNote.feedbackDescription}
            </div>
          )
        }
      </>
    );
  }

  function renderFeedbackReasons(feedbackNote: any) {
    return (
      <>
        {feedbackNote?.reasons?.length > 0 ? 
          (
            <>
              <div className="reasonComment-heading">
                Reasons
              </div>
              <ul className="rec-verify-reasons-ul-list">
                {feedbackNote?.reasons?.map(
                  (x: any, i: any) => (
                    <li key={x?.reason ?? i}>{x.reason}</li>
                  )
                )}
              </ul>
            </>
          ) : 
          (
            ""
          )
        }
      </>
    );
  }

  return (
    <>
      {props?.commentDetails?.feedbackNotes.length > 0 && (
        <ModuleComponent
          moduleId={PermissionsEnums.Recruiters_Feedback_History}
        >
          <Grid container>
            <Grid item xs={12} md={12}>
              <Box>
                <>
                  {props.type === "client" ? (
                    <SectionHeading title="Feedback Comments" />
                  ) : (
                    <SectionHeading title="Feedback/Comments" />
                  )}

                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={
                      props?.commentDetails?.feedbackNotes.length > 5
                        ? {
                            maxHeight: "440px",
                            overflowY: "auto",
                            scrollBehavior: "auto",
                            marginTop:"16px"
                          }
                        : {}
                    }
                  >
                    <Item elevation={1}>
                      {props?.commentDetails?.feedbackNotes.length
                        ? props?.commentDetails?.feedbackNotes
                            .reverse()
                            .map((feedbackNote: any, index: any, row: any) => (
                              <Box
                                key={ feedbackNote?.feedbackFor ?? index}
                                className={ternary(
                                  index + 1 === row.length,
                                  "",
                                  "feedback-box"
                                )}
                                style={{
                                  position: ternary(
                                    index + 1 === row.length,
                                    "inherit",
                                    "relative"
                                  ),
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(2, 1fr)",
                                  }}
                                >
                                  <Typography
                                    fontSize="larger"
                                    color="black"
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    {renderFeedbackIcon(feedbackNote)}
                                    {renderFeedbackHeading(feedbackNote)}
                                  </Typography>

                                  {renderFeedbackStatus(feedbackNote)}
                                </Box>
                                <div
                                  className={ternary(
                                    index + 1 === row.length,
                                    classes.lastComment,
                                    classes.initComment
                                  )}
                                >
                                  {renderFeedbackText(feedbackNote)}
                                  {renderFeedbackReasons(feedbackNote)}
                                </div>
                              </Box>
                            ))
                        : ""}
                    </Item>
                  </Grid>
                </>
              </Box>
            </Grid>
          </Grid>
        </ModuleComponent>
      )}
    </>
  );
};
