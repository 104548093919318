import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Alert, Grid, InputLabel } from "@mui/material";
import convertToLocalizedDateTime from "../../../../../utils/AppDateUtils";
import { formatTime } from "../../../../../utils/dateUtils";
import { valueOrDefault } from "../../../../../utils/complexityUtils";

interface AvailabilityProps {
  formik: any;
  previousAvailabilities:any;
}

const PreviousAvailability: React.FC<AvailabilityProps> = ({ 
  previousAvailabilities
 }) => {

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleAccordianChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };


  return (
    <>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleAccordianChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }} color={"primary"}>
            Previous Availability
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} style={{maxHeight:"220px", overflow:"auto"}}>
            {previousAvailabilities?.length > 0 && previousAvailabilities?.map((row: any, index: number) => {
              return (
                <>
                  <Grid item xs={12} sm={12} md={4} key={row?.interviewDate ?? index}>
                    <InputLabel required shrink={true}>
                      Availability {index + 1}{" "}
                    </InputLabel>
                    <Typography variant="body1">
                    {
                    convertToLocalizedDateTime(row?.interviewDate, "MMMM DD,YYYY")
                  }

                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <InputLabel required shrink={true}>
                      Time{" "}
                    </InputLabel>
                    <Typography variant="body1">
                    { formatTime(valueOrDefault(row?.interviewTime, ""))}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <InputLabel required shrink={true}>
                      Duration{" "}
                    </InputLabel>
                    <Typography variant="body1">
                      { valueOrDefault(row?.interviewDuration, "")}
                    </Typography>
                  </Grid>
                </>
              );
            })}            
          </Grid>
          <Grid item xs={12} >
          {
              previousAvailabilities?.length === 0 &&
              <Alert severity="info">No records found.</Alert>
            }
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default PreviousAvailability;
