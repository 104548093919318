import MenuIcon from "@mui/icons-material/Menu";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import PropTypes from "prop-types";
import * as React from "react";
import { Link } from "react-router-dom";
import logo from "./../../assets/images/logo.svg";
import "./Navigation/header.scss";

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

export default function AdminSigninHeader(props) {
  const pages = [{name:"Home", path:"/"}, {name:"Jobs", path:"/jobs/all"}, {name:"Recruiters", path:"/recruitmentservices"}, {name:" About Us", path:"/aboutus"}, {name:"FAQ", path:"/faqs"}];

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  return (
    <React.Fragment>
      <ElevationScroll {...props}>
        <AppBar
          className="main-header"
          style={{ background: "#fff", color: "#000" }}
        >
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Typography
                variant="h6"
                noWrap
                component="span"
                sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
              >
                <Link to={"/"}>
                  <img src={logo} className="logo" alt="logo" />
                </Link>
              </Typography>

              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={toggleDrawer("left", true)}
                  color="inherit"
                  data-testid={"btn-toggle"}
                >
                  <MenuIcon />
                </IconButton>

                <Drawer
                  anchor="left"
                  open={state["left"]}
                  onClose={toggleDrawer("left", false)}
                >
                  <List sx={{ width: "250px" }}>
                    {pages.map((page) => (
                      <ListItemButton
                        button
                        key={page?.name}
                        component={Link}
                        to={page.path}
                      >
                        <ListItemIcon>
                          <InboxIcon />
                        </ListItemIcon>
                        <ListItemText primary={page.name} />
                      </ListItemButton>
                    ))}
                    <Divider />
                  
                    <Divider />
                  </List>
                </Drawer>
              </Box>

              {/* logo for small screens  */}
              <Typography
                variant="h6"
                noWrap
                component="span"
                sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
              >
                <Link to={"/"}>
                  <img src={logo} className="logo" alt="logo" />
                </Link>
              </Typography>
              
            </Toolbar>
          </Container>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
    </React.Fragment>
  );
}