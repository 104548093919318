import React, { useEffect, useState } from "react";
import BasicDatePicker from "../../../../ui/DateRange";
import {  Grid } from "@mui/material";
import AppTimePicker from "../../../../ui/DateTime/AppTimePicker";
import DurationAutocomplete, { DurationOption } from "../../../../Common/Form/FormField/DurationAutocomplete";
import { GetCandidateEventDurationTypes } from "../../../../../Services/candidateService";
import { ternary, valueOrDefault } from "../../../../../utils/complexityUtils";

interface AvailabilityProps {
    formik:any;
}

const ScheduleAvailability: React.FC<AvailabilityProps> = ({ 
    formik
 }) => {

    const[defaultDuarationOptionList, setDefaultDuarationOptionList] = useState<DurationOption[]>([]);

    useEffect(()=>{
        _GetCandidateEventTypes();
      },[])
    
      // GET Master Duration Type for candidate interview schedule
      const _GetCandidateEventTypes = async () => {
        GetCandidateEventDurationTypes()
        .then((response: any) => {
          if (response?.data?.message?.appStatusCode === "RC_RSF") {
           setDefaultDuarationOptionList(response?.data?.entityList);
          } else {
            setDefaultDuarationOptionList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const findDurationOption = (durationOptions: DurationOption[], targetDuration: string, type: 'id' | 'duration'): string  => {
        const matchingOption = durationOptions.find(option => option.duration === targetDuration);
        return ternary(type === 'id', valueOrDefault(matchingOption?.id, ""), valueOrDefault(matchingOption?.duration, ""));
      };

      const getInterviewDateErrorMessage = (formik:any, index:number) => {
        if (formik?.values?.availabilities[index]?.interviewDate === "Invalid date") {
          return "Please select interview date";
        } else if (index === 0) {
          return (
            formik?.touched.availabilities?.[index]?.interviewDate &&
            formik?.errors.availabilities?.[index]?.interviewDate
          );
        } else {
          return formik?.errors.availabilities?.[index]?.interviewDate;
        }
      };
    
      
  return (
    <>
    <Grid container spacing={2}>
      {
      ["1","2","3"].map(
        (row: any, index: number) => {
          return (
            <>
              <Grid item xs={12} sm={12} md={4}  key={ row ?? index}>
                <BasicDatePicker
                  label={`Availability ${row}`}
                  disableFuture={false}
                  disablePast={true}
                  value={valueOrDefault(formik?.values?.availabilities[index]?.interviewDate, "")}
                  name={`availabilities.[${index}].interviewDate`}
                  className="candidateInterviewDatePicker"
                  onChange={(selectedDate: string) => {
                    formik?.setFieldValue(
                      `availabilities.[${index}].interviewDate`,
                      selectedDate
                    );
                  }}
                  isError={
                    index === 0 ?
                      formik?.touched.availabilities?.[index]?.interviewDate &&
                      Boolean(formik?.errors.availabilities?.[index]?.interviewDate)
                    :
                    Boolean( formik?.errors.availabilities?.[index]?.interviewDate)
                  }
                  errorMessage={getInterviewDateErrorMessage(formik, index)}
                  defaultDateFormat={"DD-MM-YYYY"}
                  views={["day"]}
                  fullWidth={true}
                  required={index === 0}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
              <AppTimePicker 
                isRequired={index === 0}
                label={"Time"}
                value={valueOrDefault(formik.values?.availabilities[index]?.interviewTime, "")}
                fieldName={`availabilities.[${index}].interviewTime`}
                isError={
                  index === 0 ?
                  formik?.touched.availabilities?.[index]?.interviewTime && Boolean(formik?.errors.availabilities?.[index]?.interviewTime)
                  :
                  Boolean(formik?.errors.availabilities?.[index]?.interviewTime)
                }
                helperText={
                  index === 0 ? 
                  formik?.touched.availabilities?.[index]?.interviewTime && formik?.errors.availabilities?.[index]?.interviewTime
                  :
                  formik?.errors.availabilities?.[index]?.interviewTime
                }
                handleOnChange={(selectedValue:any | null)=>{
                  
                  formik?.setFieldValue(`availabilities.[${index}].interviewTime`, valueOrDefault(selectedValue, ""));
                }}
                handleOnBlur={(e: React.FocusEvent<any, Element>) => {
                  formik.setFieldTouched(`availabilities.[${index}].interviewTime`,true);
                }}
                ampm={false}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <DurationAutocomplete 
                label="Duration"
                fieldName={`availabilities.[${index}].interviewDuration`}
                value={{
                  id:findDurationOption(defaultDuarationOptionList,formik?.values?.availabilities[index]?.interviewDuration,'id'),
                  duration:findDurationOption(defaultDuarationOptionList,formik?.values?.availabilities[index]?.interviewDuration,'duration')
                }}
                isRequired={index === 0}
                isError={
                  index === 0 ?
                  formik?.touched.availabilities?.[index]?.interviewDuration && Boolean(formik?.errors.availabilities?.[index]?.interviewDuration)
                  :
                 Boolean(formik?.errors.availabilities?.[index]?.interviewDuration)
                }
                helperText={
                  index === 0 ? 
                  formik?.touched.availabilities?.[index]?.interviewDuration && formik?.errors.availabilities?.[index]?.interviewDuration
                  :
                  formik?.errors.availabilities?.[index]?.interviewDuration
                }
                handleChange={(selectedValue:DurationOption | null)=>{
                  formik?.setFieldValue(`availabilities.[${index}].interviewDuration`,valueOrDefault(selectedValue?.duration, ""));
                }}
                handleBlur={(e: React.FocusEvent<any, Element>) => formik.handleBlur(e)}
                defaultOptionList={defaultDuarationOptionList}
              />
            </Grid> 
            </>
          );
        }
      )}
      </Grid>
    </>
  );
};

export default ScheduleAvailability;
