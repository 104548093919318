import React from "react";
import Grid from "@mui/material/Grid";
import LabelChip from "../LabelChip";
import SectionHeading from "../../../../ui/Form/Heading/SectionHeading";

interface ScorecardCandidateInfoProps {
  technologies:string[];
  className: any;
}

const KeywordsTech: React.FC<ScorecardCandidateInfoProps> = ({
  technologies,
  className="",
}) => {
  return (
    <Grid container spacing={2} className="keyword-margin">
      <Grid item xs={12}>
        <SectionHeading title="Technology" />
      </Grid>

      {technologies?.length> 0 && technologies.map((technology) => (
        <Grid item key={technology} >
          <LabelChip label={technology} className={className} />
        </Grid>
      ))}
    </Grid>
  );
};

export default KeywordsTech;
