
import axios from './../../Services/CommonHttpRequest'
import { plainToInstance } from 'class-transformer';
import React from 'react'        
import { GoogleLogin  } from "@react-oauth/google";
import {  useLocation, useNavigate } from 'react-router-dom';
import { Recruiter } from '../../models/recruiterModel';
import { Response } from '../../models/Response';
import { SocialAuthResponse } from '../../models/SocialAuthResponse';
import { BillingInfoType, LoggedInUserRole } from '../../models/interfaces';
import setAuthToken from '../../utils/setAuthToken';
import { ClientSocialSignup } from '../../Services/clientService';
import { ClientSocialSignupModel } from '../../models/ClientSocialSignupModel';
import { Client } from '../../models/Client';
import getLoggedInUserPermissions from '../../Shared/utils/getLoggedInUserPermission';
import { useDispatch } from 'react-redux';
import { loadPermissions } from '../../features/permission/userPermissionSlice';
import { getRecruiterData } from '../../features/recruiterSlice';
import { setLoggedInUseDetails } from '../../features/userDetails/loggedInUserDetailsSlice';

const clientId ="447293117605-mmkm2lnocasr0lggm9rp1pqnkvhtn4gg.apps.googleusercontent.comjgjh";

interface Props {
  handleAlreadyExist: (errorMessage:string) => void,
  redirectUrl?:string,
  mode?: {recruiter: boolean, client: boolean},
}

const GoogleButton : React.FC<Props> = ({handleAlreadyExist,redirectUrl, mode}) =>{
  const location = useLocation();
  const dispatch = useDispatch<any>();
  let navigate = useNavigate();
  const onSuccess = (response: any) => {
    let signupFunc = SignupRecruiter;

    if (mode !== undefined && mode.client) {
      signupFunc = SignupClient;
    }

    if ("credential" in response) {
      axios
      .post(`${process.env.REACT_APP_IDPROVIDER_API}api/Authentication/authenticateGoogle`,response.credential,{headers:{ "Content-Type": "application/json" }})
      .then(res => {
        let response = plainToInstance(Response<SocialAuthResponse>,res.data)
        if(response.status == 200) {
          if(window.location.pathname == '/login'||window.location.pathname.startsWith("/jobdetail")){
            LoginCommon(response);
          }else 
          {
            signupFunc(response);
          }
        }
      })
      .catch(err =>{
        console.log(err);
      });
    }
  };
      
  const LoginCommon = (data:Response<SocialAuthResponse>) => {
    let loginModel = {
      userName: data.entity.emailID,
      password: "",
      loginType: "Google",
      returnUrl: "",
      loggedInUserRole: LoggedInUserRole.Recruiter.toString()

    }
    axios
    .post(`${process.env.REACT_APP_IDPROVIDER_API}api/SignIn/Login`,loginModel,{headers:{ "Content-Type": "application/json" }})
    .then(res => {
      if(res.data.status === 200){
        localStorage.setItem('user',JSON.stringify(res.data.entity))
        setAuthToken(res.data.accessToken);

        dispatch(setLoggedInUseDetails(res.data?.entity))
        let userModules = getLoggedInUserPermissions();
        dispatch(loadPermissions({permissions:userModules}))
        dispatch(getRecruiterData())

        if(redirectUrl){
          navigate(redirectUrl)
        }
        else if (location.state) {
          navigate((location.state as any)?.redirectUrl ?? "/dashboard");
        } else {
          navigate("/dashboard");
        }
      }else{
        handleAlreadyExist(res.data.message.appStatusDescription);
      }
    });
  }

  const SignupRecruiter = (data:Response<SocialAuthResponse>) => {
    const createModel = {
      id: "",
      emailID: data.entity.emailID,
      firstName: data.entity.firstName,
      middleName: "",
      lastName: data.entity.lastName,
      loginType: 'Google',
      billingInfoType: BillingInfoType[0]

    }
    axios.post(`${process.env.REACT_APP_RECUITER_API}api/RecruiterSocialMediaSignUp/Create`,createModel)
    .then(res => {
      let response = plainToInstance(Response<Recruiter>,res.data);
      if(response.status === 200 && response.message.appStatusCode === "RC_RSF") {
        navigate(`/SignupComplete?type=googleSuccess&authToken=${data?.entity?.token}`,{state:response.entity});
      }else if(response.status === 409 && response.message.appStatusCode === "RC_EXST"){
        handleAlreadyExist(response?.message?.appStatusDescription);
        
      }else{
        handleAlreadyExist(response.message?.appStatusDescription);
        
      }
    })
  }

  const SignupClient = (data:Response<SocialAuthResponse>) => {
    const model : ClientSocialSignupModel = {
      email: data.entity.emailID,
      firstName: data.entity.firstName,
      middleName: "",
      lastName: data.entity.lastName,
      loginType: "Google",
    };

    ClientSocialSignup(model)
      .then(res => {
        let response = plainToInstance(Response<Client>,res.data);
        if(response.status === 200 && response.message.appStatusCode === "CL_RSF") {
          navigate("/ClientSignup",{state:response.entity});
        } else {
          handleAlreadyExist(response.message?.appStatusDescription);
        }
      });
  }
      
  return(
    <div className="google-login-btn">
      <GoogleLogin
        theme='outline'
        onSuccess={onSuccess}
        onError={() => {
          console.log('Login Failed');
        }}
        type={'icon'}
        size={"medium"}
        data-testid={"google-login-button"}
      />
    </div>
  );
}
export default GoogleButton;