import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Component } from "react";
import Chart from "react-apexcharts";

class TechStatsRadialAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [this.props.percentage],
      options: {
        chart: {
          margin: "0 auto !important",
          height: 350,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            borderRadius: 6,
            offsetX: -15,
            hollow: {
              size: "50%",
            },
            track: {
              background: "#DBF4E9",
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: { offsetY: 5 },
            },
          },
        },
        colors: ["#27B3AA"],
        stroke: {
          lineCap: "round",
        },
      },
    };
  }

  render() {
    return (
      <Box>
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="radialBar"
          width="130"
          className="custom-charts"
          
        />
        <Typography
          className="primary-color-text"
          variant="subtitle1"
          component="div"
          sx={{
            fontSize: "0.75rem",
            lineHeight: "0.823rem",
            textAlign: "center",
            position: "relative",
            top: "-12px",
          }}
        >
          {this.props.title}
        </Typography>
      </Box>
    );
  }
}

export default TechStatsRadialAdmin;
