import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { GetCandidateEventTypes } from '../../../../Services/candidateService';

export interface EventTypeOption {
  id: string;
  eventName: "Interview" | string;
}


interface EventTypeAutoCompleteProps {    
    label:string;
    fieldName:string;
    value:EventTypeOption;
    isRequired?:boolean;
    isReadOnly?:boolean;
    isError?: boolean;
    helperText?:string | boolean;
    handleChange: (selectedEventType: EventTypeOption | null) => void;
    handleBlur: (e: React.FocusEvent<any, Element>) => void;
}

const EventTypeAutoComplete: React.FC<EventTypeAutoCompleteProps> = ({
    label,
    fieldName,
    value,
    isRequired=false,
    isReadOnly=false,
    isError=false,
    helperText="", 
    handleChange,
    handleBlur 
}) => {

  //Initial component States

  const [eventTypesList, setEventTypesList] = useState<EventTypeOption[]>([]);


  useEffect(()=>{
    _GetCandidateEventTypes();
  },[])

    // GET Master Event Type for candidate interview schedule
    const _GetCandidateEventTypes = async () => {
        GetCandidateEventTypes()
        .then((response: any) => {
          if (response?.data?.message?.appStatusCode === "RC_RSF") {
            setEventTypesList(response?.data?.entityList);
          } else {
            setEventTypesList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

  const handleTypeChange = (_event: React.ChangeEvent<{}>, changedValue: EventTypeOption | null) => {
    handleChange(changedValue);
  };

  return (
    <Autocomplete
      data-testid="Interview"
      readOnly={isReadOnly}
      value={value}
      onChange={handleTypeChange}
      options={eventTypesList}
      getOptionLabel={(option) => option?.eventName}
      renderInput={(params) => (
        <TextField
          {...params}
          required={isRequired}
          label={label}
          name={fieldName}
          variant="standard"
          onBlur={handleBlur}
          inputProps={{
            ...params.inputProps,
          }}
          error={isError}
          helperText={helperText}          
        />
      )}
    />
  );
};

export default EventTypeAutoComplete;
