import { createSvgIcon } from "@mui/material/utils";

export const ArrowDown = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="28"
    viewBox="0 0 15 18"
    fill="none"
  >
    <path
      d="M2.63767 0C1.19212 0 0 1.14296 0 2.5199V17.0993C0 17.5943 0.422403 17.9993 0.938673 17.9993C1.09825 17.9993 1.25782 17.9543 1.39862 17.8823L7.5 14.5344L13.6014 17.8823C14.0519 18.1253 14.6245 17.9813 14.878 17.5493C14.9625 17.4143 15 17.2613 15 17.1083V2.5199C15 1.13396 13.8079 0 12.3623 0H2.63767ZM2.63767 1.79993H12.3905C12.8223 1.79993 13.1508 2.10592 13.1508 2.5199V15.5424L7.98811 12.7165C7.69712 12.5545 7.34981 12.5545 7.05882 12.7165L1.89612 15.5424V2.5199C1.89612 2.10592 2.21527 1.79993 2.65645 1.79993H2.63767Z"
      fill="#4540DB"
    />
  </svg>,
  "ArrowDown"
);
