import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import "./ViewRecruiterPopup.scss";
import { GetRecruiterById } from "../../../../Services/recruiterService";
import formatPhone from "../../../../Shared/utils/formatPhone";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getNevLink } from "../../../../utils/getFileFromUrl";
import StatusChip from "../../../Common/Chip/StatusChip";
import { Asterisk } from "../../../ui/Asterisk";

const ViewRecruiterInfoPopup = (props) => {
  const { recruiterId } = props;
  const [recruiterDetails, setRecruiterDetails] = React.useState({});

  const navigate = useNavigate();

  React.useEffect(() => {
    GetRecruiterById(recruiterId).then((res) => {
      if (res?.data?.status == 200) {
        setRecruiterDetails(res?.data?.entity);
      }
    });
  }, []);
  return (
    <div>
      <Dialog
        className="custom-popup-border"
        open={props.isOpenPopup}
        onClose={() => props.handleClose()}
        aria-labelledby="viewRecruiterPopup-title"
        aria-describedby="viewRecruiterPopup-description"
      >
        <Paper elevation={5} className="custom-paper-clr">
          <DialogTitle sx={{ m: 0, p: 2 }}>
            <IconButton
              aria-label="close"
              onClick={() => props.handleClose()}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "#2E5668",
              }}
              data-testid="btn-close-dialog"
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="viewRecruiterPopup-description">
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography align="center" className="custom-rec-details">
                    Recruiter Details
                  </Typography>
                </Grid>
                <Grid item xs={12} align="center">
                  <Typography className="cust-rec-info">
                    Here is the recruiter information linked to this candidate for the job:
                  </Typography>
                </Grid>
                <Grid item xs={12} className="cust-txt-field-mt">
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography className="cutom-col-name">
                        First Name <Asterisk />
                      </Typography>
                      <Typography className="cutom-field-input">
                        {recruiterDetails?.firstName}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className="cutom-col-name">
                        Middle Initials 
                      </Typography>
                      <Typography className="cutom-field-input">
                        {recruiterDetails?.middleName ===""?"N/A":recruiterDetails?.middleName}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} className="cust-txt-field-mt" >
                      <Typography className="cutom-col-name">
                        Last Name <Asterisk />
                      </Typography>
                      <Typography className="cutom-field-input">
                        {recruiterDetails?.lastName}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className="cust-txt-field-mt">
                      <Typography className="cutom-col-name">
                        Email Address <Asterisk />
                      </Typography>
                      <Typography className="cutom-field-input">
                        {recruiterDetails?.email}
                        {recruiterDetails?.isEmailVerified === true ? (
                          <CheckCircleIcon
                          className="checkCircle-icon"
                          />
                        ) : null}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className="cust-txt-field-mt">
                      <Typography className="cutom-col-name">
                        Phone <Asterisk />
                      </Typography>
                      <Typography
                        className="cutom-phone-input cust-txt-field-mt"
                      >
                        {formatPhone(recruiterDetails?.phoneNumbers?.primary)}
                        {recruiterDetails?.phoneNumbers?.isPrimaryVerified ? (
                          <CheckCircleIcon
                          className="checkCircle-icon"
                          />
                        ) : null}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} className="cust-txt-field-mt">
                      <Typography className="cutom-col-name">
                        Status <Asterisk />
                      </Typography>
                      <Typography color="#5A5A5A" className="cust-txt-field-mt">
                        {recruiterDetails?.status == "Approved" ? (
                          <>
                            <StatusChip status={recruiterDetails?.status} />
                          </>
                        ) : (
                          <StatusChip status={recruiterDetails?.status} />
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}></Grid>
                  </Grid>
                  
                  <Box
                    mt={1}
                    textAlign="center"
                    className="viewdetails-btn"
                  >
                    <Button
                      variant="contained"
                      onClick={(e) => {
                        navigate(
                          getNevLink(
                            `/recruiters/verify_history/${recruiterDetails?.id}`
                          )
                        );
                      }}
                      className="viewDetails-Btn"
                    >
                      View Details
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
        </Paper>
      </Dialog>
    </div>
  );
};

export default ViewRecruiterInfoPopup;
