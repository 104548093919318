import Grid from "@material-ui/core/Grid";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import avatar1 from "./../../assets/images/CEO-icon.svg";
import avatar2 from "./../../assets/images/Co-founder-icon.svg";
import LeftArrow from "./../../assets/images/LeftArrow.svg";
import RightArrow from "./../../assets/images/RightArrow.svg";

export const Testimonial = () => {
  const [page, setpage] = useState(1);
  return (
    <Box
      sx={{
        backgroundColor: "#E6E9FB",
        position: "relative",
        width: "100%",
        height: {xs:"775px",sm:"567px",md:"567px"},
        maxWidth: "100%",
        display: "none",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "1112px",
          height: "100%",
          padding:"15px"
        }}
      >
        <div>
          <Typography
            variant="h5"
            component="div"
            className=" letter-spacing-normal primary-color-text"
            gutterBottom
            textAlign={"left"}
            sx={{ paddingTop: "60px" }}
          >
            Testimonials
          </Typography>
        </div>
        <Typography
          className="abelh2"
          sx={{
            textAlign: "left",
            lineHeight: "30px",
            fontSize:{xs:"22px !important",sm:"35px !important",md: "40px !important"},
          }}
        >
          Still unsure about working with us ?<br></br> See what other
          Recruiters are saying about JobRialto
        </Typography>

        <div
          style={{
            marginTop: "20px",
            paddingTop: "0.2in",
            color: "#0E0E2C99",
          }}
        >
          <Grid container spacing={1}>
            {page==1 && 
            <>
            <Grid item xs={12} md={6}>
            <p>
               JobRialto has really transformed my ability to match my candidates with the most suitable jobs.
                </p>
              <Stack direction="row" spacing={2}>
                
                <Avatar src={avatar1} sx={{ width: 68, height: 68 }} />
                <div
                  style={{
                    paddingTop: "10px",
                    fontWeight: "700",
                    fontSize: "18px",
                    lineHeight: "27px",
                    color: "black",
                  }}
                >
                  Johnathan Smith <br></br>{" "}
                  <div style={{ color: "#0E0E2CCC", fontWeight: "500" }}>
                    Independent Recruiter
                  </div>
                </div>
              </Stack>
            </Grid>
           
            <Grid item xs={12} md={6}> 
            <p>
                I would like to express our gratitude to JobRialto for helping me place my best candidates.
                </p>
                <br/>
              <Stack direction="row" spacing={2}>
               
                <Avatar src={avatar1} sx={{ width: 68, height: 68 }} />
                <div
                  style={{
                    paddingTop: "10px",
                    fontWeight: "700",
                    fontSize: "18px",
                    lineHeight: "27px",
                    color: "black",
                  }}
                >
                  Carlos Santiago<br></br>{" "}
                  <div style={{ color: "#0E0E2CCC", fontWeight: "500" }}>
                  Independent Recruiter
                  </div>
                </div>
              </Stack>
            </Grid>
            </>}
            {page==2 && <>
            <Grid item xs={12} md={6}>
            <p>
                Finding the most suitable jobs for my candidates has become far simpler with JobRialto.
                </p>
              <Stack direction="row" spacing={2}>
              
                <Avatar src={avatar1} sx={{ width: 68, height: 68 }} />
                <div
                  style={{
                    paddingTop: "10px",
                    fontWeight: "700",
                    fontSize: "18px",
                    lineHeight: "27px",
                    color: "black",
                  }}
                >
                  Mark Jackson <br></br>{" "}
                  <div style={{ color: "#0E0E2CCC", fontWeight: "500" }}>
                  Independent Recruiter
                  </div>
                </div>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
            <p>
            JobRialto has worked wonders for me to place my candidates easily and make a good living.
                </p>
              <Stack direction="row" spacing={2}>
                <Avatar src={avatar2} sx={{ width: 68, height: 68 }} />
                <div
                  style={{
                    paddingTop: "10px",
                    fontWeight: "700",
                    fontSize: "18px",
                    lineHeight: "27px",
                    color: "black",
                  }}
                >
                  Djamila Malak <br></br>{" "}
                  <div style={{ color: "#0E0E2CCC", fontWeight: "500" }}>
                  Independent Recruiter
                  </div>
                </div>
              </Stack>
            </Grid>
            </>}
          </Grid>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "60px",
          }}
        >
          <Stack direction="row" spacing={5}>
            <Avatar disabled src={LeftArrow} onClick={()=> setpage(1)} sx={{ width: 48, height: 48 }} data-testid="left-arrow" />
            <Avatar src={RightArrow} onClick={()=> setpage(2)} sx={{ width: 48, height: 48 }} data-testid="right-arrow"/>
          </Stack>
        </div>
      </Box>
    </Box>
  );
};
