import pathToRegexp from "path-to-regexp";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { publicProtectedRoutes } from "../../components/routes/publicRoutes";
import PermissionRoute from "./PermissionRoute";

const PublicProtectedRoute = ({ children }) => {
    const location = useLocation();
    const [initialLoad, setInitialLoad] = useState(true);
  
    useEffect(() => {
      setInitialLoad(false);
    }, []);
  
    if (initialLoad) {
      // On initial load, allow the route to render without any restrictions
      return children;
    }
    const auth = localStorage.getItem("accessToken") ? true : false;
    if(auth)
    {
        const route = publicProtectedRoutes.find((route) => {
            const keys = [];
            const regexp = pathToRegexp(route.path, keys);
            const match = regexp.exec(location.pathname);
            const isExact = match && match[0] === location.pathname;
            return isExact && route.permissionId;
          });
      
          if (route && route.permissionId) {
            // Check if the user has the required permission for the current route
            return (
                <PermissionRoute permissionId={route?.permissionId}>
                  {children}
                </PermissionRoute>
              );
          }
      
          return children;
    }
  
    return children;
  };

export default PublicProtectedRoute;