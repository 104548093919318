import React from 'react';
import { Alert } from "@mui/material";


interface AppLinkExpireProps {
  message: string;
}

const AppLinkExpire: React.FC<AppLinkExpireProps> = ({ message }) => {
  return (
    <>
      <Alert severity="error">{message}</Alert> 
    </>
  );
}

export default AppLinkExpire;
