import SquareIcon from "@mui/icons-material/Square";

import { Chip, Tooltip, Typography, styled } from "@mui/material";
import Grid from "@mui/material/Grid";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SendReminderEmail } from "../../../Services/candidateService";
import formatPhone from "../../../Shared/utils/formatPhone";
import getLoggedInUserRole from "../../../Shared/utils/getLoggedInUserRole";
import getJobType from "../../../Shared/utils/getjobType";
import { getTextNameEllipse } from "../../../utils/StringUtil";
import { ternary } from "../../../utils/complexityUtils";
import constructName from "../../../utils/constructName";
import getPayRate from "../../../utils/getPayRate";
import { MapMarker } from "../../Common/Icons/MapMarker";
import { ContractIcon } from "../../Common/Icons/SubmissionScreen/ContractIcon";
import { ExperienceIcon } from "../../Common/Icons/SubmissionScreen/ExperienceIcon";
import { InterviewingIcon } from "../../Common/Icons/SubmissionScreen/InterviewingIcon";
import { PayrateIcon } from "../../Common/Icons/SubmissionScreen/PayrateIcon";
import { PeopleIcon } from "../../Common/Icons/SubmissionScreen/PeopleIcon";
import { ResumeIcon } from "../../Common/Icons/SubmissionScreen/ResumeIcon";
import { Asterisk } from "../../ui/Asterisk";
import ContactInformationPopup from "../NewViewSubmission/Popups/ContactInformationPopup";
import "../NewViewSubmission/styles.scss";
import { hasActiveEvent } from "./Utils/SubmissionsUtils";
import BypassRTRButton from "./buttons/BypassRTRButton";
import CancelInterviewButton from "./buttons/CancelInterviewButton";
import ContactsButton from "./buttons/ContactsButton";
import ContractActiveButton from "./buttons/ContractActiveButton";
import ConversationsButton from "./buttons/ConversationsButton";
import DocumentsButton from "./buttons/DocumentsButton";
import HiredOfferAcceptedButton from "./buttons/HiredOfferAcceptedButton";
import InterviewDetailsButton from "./buttons/InterviewDetailsButton";
import InterviewFeedbackDueButton from "./buttons/InterviewFeedbackDueButton";
import InterviewRescheduleButton from "./buttons/InterviewRescheduleButton";
import NextRoundOfInterviewButton from "./buttons/NextRoundOfInterviewButton";
import OfferDeclinedButton from "./buttons/OfferDeclinedButton";
import OfferDetailsButton from "./buttons/OfferDetailsButton";
import RejectButton from "./buttons/RejectButton";
import RequestRTRButton from "./buttons/RequestRTRButton";
import ShareInterviewAvailabilityButton from "./buttons/ShareInterviewAvailabilityButton";
import SubmittedToClientButton from "./buttons/SubmittedToClientButton";
import UpdateInterviewStatusButton from "./buttons/UpdateInterviewStatusButton";
import ViewSharedAvailabilityButton from "./buttons/ViewSharedAvailabilityButton";
import { JobOriginIds } from "../../../models/Job/JobDetailsModel";
import { UserAuth } from "../../../models/interfaces";
import { getJobStatusColor, JobStatus } from "../../../utils/AppUtils";
import StringWithTooltip from "../../Common/ToolTip/StringWithTooltip";

interface SubmissionActionButtonProps {
  currentjobDetails: any;
  row: any;
  jobData: any;
  type: any;
  showUpdateBtn: any;
  setPopup: any;
  setSubmissionForPopup: any;
  popup: any;
  index: any;
  setRTRPopup: any;
}

const CustomChip = styled(Chip)(({ theme }) => ({
  padding: theme.spacing(0),
  height: "100%",
  display: "flex",
  flexDirection: "row",
  "& .MuiChip-label": {
    overflowWrap: "break-word",
    whiteSpace: "normal",
    textOverflow: "clip",
  },
}));
export default function SubmissionActionButton({
  currentjobDetails,
  row,
  jobData,
  type,
  showUpdateBtn,
  setPopup,
  setSubmissionForPopup,
  popup,
  index,
  setRTRPopup,
}: SubmissionActionButtonProps) {
  const navigate = useNavigate();

  const { features } = useSelector(
    (state: any) => state.userPermissions
  );

  const [emailSend, setemailSend] = React.useState<{
    index: any;
    isSent: boolean;
  }>({
    index: null,
    isSent: false,
  });
  const [selectedRowJobDetails, setSelectedRowJobDetails] = React.useState();

  const [contactInformationData, setcontactInformationData] = React.useState<{
    accountManager: any,
    recruiter: any,
    candidate: any,
    employerDetails: any,
  }>({
    accountManager: null,
    recruiter: null,
    candidate: null,
    employerDetails: null,
  });

  let userRole = getLoggedInUserRole();
  const getjobPayrate = (jobId: string) => {
    let jobDetails = jobData?.find((x: any) => x?.id === jobId);

    return getPayRate(
      jobDetails?.billRatePckg?.type,
      jobDetails?.billRatePckg?.billRate,
      jobDetails?.billRatePckg?.packageCurrency
    );
  };
  const sendEmailReminder = (rowData: any, index: any) => {
    let emailData = {
      submissionId: rowData?.id,
      candidateEmail: rowData?.email,
      candidateName: rowData?.firstName,
      recruiterEmail: rowData?.recruiterEmail,
      jobTitle: rowData?.jobTitle,
      jobID: rowData?.jobId,
      recruiterFullName: constructName(
        rowData?.recruiterFirstName,
        rowData.recruiterMiddleName,
        rowData?.recruiterLastName
      ),
    };
    setemailSend({ index: index, isSent: false });
    SendReminderEmail(emailData)
      .then((response) => {
        if (response.data.status === 200) {
          setemailSend({ index: index, isSent: true });
        }
      })
      .catch((er) => {
        console.log(er);
      });
  };
  

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container>
            {
              ternary(
                  currentjobDetails?.jobOriginId === JobOriginIds.staffline &&
                  (userRole === UserAuth.Admin || userRole === UserAuth.SuperAdmin),
                    <Grid item sm={1.9} xs={3} md={1.9} lg={1.9} xl={1.9}>
                      <Typography className="submission-details">Staffline ID</Typography>
                      <Typography className="submission-details-value">{currentjobDetails?.referenceNumber}</Typography>
                    </Grid>,
                  null
              )
            }            
            <Grid item sm={1.9} xs={3} md={1.9} lg={1.9} xl={1.9}>
              <Typography className="submission-details">
                Experience <Asterisk />
              </Typography>
              <Typography className="submission-details-value">
                {row?.experience + " Years"}
              </Typography>
            </Grid>
            <Grid item sm={3.2}  xs={3} md={3.1} lg={3} xl={3}>
              <Typography className="submission-details">Email ID</Typography>
              <Tooltip title={row?.email}>
                <span className="emailTextOverflow">
                  {" "}
                  <Typography className="submission-details-value breakword">
                    {" "}
                    {getTextNameEllipse(row?.email, 30)}{" "}
                  </Typography>
                </span>
              </Tooltip>
            </Grid>
            <Grid item sm={1.8}  xs={3} md={1.9} lg={2}>
              <Typography className="submission-details">
                Phone <Asterisk />{" "}
              </Typography>
              <Typography className="submission-details-value">
                {formatPhone(row?.phone)}
              </Typography>
            </Grid>
            <Grid item sm={1.8}  xs={3} md={1.9} lg={2}>
              <Typography className="submission-details">
                Work Auth <Asterisk />
              </Typography>
              <Typography className="submission-details-value">
                {row?.workAuthorizationStatus?.concat(
                  ternary(row?.isRemoteUSA, " (Remote USA)", "")
                )}
              </Typography>
            </Grid>
          </Grid>

          <Typography mt={1.8} className="submission-details">
            Description
          </Typography>
          <Typography mb={2} className="sub-description">
            {currentjobDetails?.jobShortDesc}
            <a
              target={"_blank"}
              href={`${window.location.origin}/jobdetail/${row.jobId}/submission${type}`}
              style={{ color: "blue", cursor: "pointer" }}
            >
              More
            </a>
          </Typography>
        </Grid>
      </Grid>
      {currentjobDetails !== null && currentjobDetails !== undefined && (
        <>
          <Grid
            mt={2}
            container
            sx={{
              backgroundColor: "#F2F8FF",              
            }}
            alignContent={"flex-start"}
          >
            <Grid mt={1.8} mb={1.8} md={1.5} lg={1.5} xl={1.5}>
              <Chip
                sx={{
                  border: "none",
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#353535",
                }}
                className="roboto-btn custom-chip-fontsize"
                icon={<MapMarker />}
                label={ternary(row?.remoteJob === "y", "Remote", "Onsite")}
                variant="outlined"
              />
            </Grid>
            <Grid mt={1.8} md={1.3} lg={1.3} xl={1.3}>
              <Chip
                className="roboto-btn custom-chip-fontsize"
                sx={{ border: "none" }}
                icon={<ExperienceIcon />}
                label={currentjobDetails?.jobExperience + " Years"}
                variant="outlined"
              />
            </Grid>
            <Grid mt={1.8} mb={1.8} md={1.7} lg={1.7} xl={1.7}>
              <CustomChip
                sx={{ border: "none" }}
                icon={<ContractIcon />}
                label={getJobType(
                  currentjobDetails?.jobType,
                  currentjobDetails?.jobDuraion
                )}
                className="roboto-btn custom-chip-fontsize"
                variant="outlined"
                style={{ whiteSpace: "pre-line" }}
              />
            </Grid>
            <Grid mt={1.8} mb={1.8} md={1.7} lg={1.7} xl={1.7}>
              <Chip
                className="roboto-btn custom-chip-fontsize"
                sx={{ border: "none" }}
                icon={<PayrateIcon />}
                label={
                  getjobPayrate(row?.jobId)?.packageValueAndCurrency +
                  " " +
                  getjobPayrate(row?.jobId)?.packageType
                }
                variant="outlined"
              />
            </Grid>
            <Grid mt={1.8} md={1.3} lg={1.3} xl={1.3}>
              <Chip
               className="roboto-btn custom-chip-fontsize"
                sx={{ border: "none" }}
                icon={
                  <SquareIcon
                    style={{
                      marginTop: "1px",
                      color: 
                        getJobStatusColor(currentjobDetails.jobStatus as JobStatus),
                      borderRadius: "1px",
                    }}
                  />
                }
                label={<StringWithTooltip text={currentjobDetails.jobStatus} />}
                variant="outlined"
              />
            </Grid>
            <Grid mt={1.8} md={1.5} lg={1.5} xl={1.5}>
              <Chip
                className="roboto-btn custom-chip-fontsize"
                sx={{ border: "none" }}
                icon={<ResumeIcon />}
                label={"Resumes : " + " " + currentjobDetails?.resumeCount}
                variant="outlined"
              />
            </Grid>
            <Grid mt={1.8} md={1.5} lg={1.5} xl={1.5}>
              <Chip
                className="roboto-btn custom-chip-fontsize"
                sx={{ border: "none" }}
                icon={<InterviewingIcon />}
                label={`Interview : ${currentjobDetails?.interviewingCount}`}
                variant="outlined"
              />
            </Grid>
            <Grid mt={1.8} md={1.5} lg={1.5} xl={1.5}>
              <Chip
               className="roboto-btn custom-chip-fontsize"
                sx={{ border: "none" }}
                icon={<PeopleIcon />}
                label={
                  "Opening :" + " " + currentjobDetails?.totalRemainingPositions
                }
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid mt={2} container spacing={2} alignItems="center">
         
            <DocumentsButton
              row={row}
              showUpdateBtn={showUpdateBtn}
              setPopup={setPopup}
              setSubmissionForPopup={setSubmissionForPopup}
            />

            <ConversationsButton
              row={row}
              setPopup={setPopup}
              setSubmissionForPopup={setSubmissionForPopup}
            />

            <HiredOfferAcceptedButton
              row={row}
              userRole={userRole}
              currentjobDetails={currentjobDetails}
              setPopup={setPopup}
            />

            <OfferDeclinedButton
              row={row}
              userRole={userRole}
              currentjobDetails={currentjobDetails}
              setPopup={setPopup}
            />

            <ContactsButton
              row={row}
              userRole={userRole}
              currentjobDetails={currentjobDetails}
              setPopup={setPopup}
              setSelectedRowJobDetails={setSelectedRowJobDetails}
              setcontactInformationData={setcontactInformationData}
            />
          
            <BypassRTRButton
              row={row}
              setRTRPopup={setRTRPopup}
              pageType={type}
            />

            <RequestRTRButton
              row={row}
              index={index}
              sendEmailReminder={sendEmailReminder}
              emailSend={emailSend}
              pageType={type}
            />
          
            <OfferDetailsButton
              row={row}
              currentjobDetails={currentjobDetails}
              setPopup={setPopup}
            />
          
            <InterviewDetailsButton
              row={row}
              userRole={userRole}
              setPopup={setPopup}
            />

            <ContractActiveButton
              row={row}
              navigate={navigate}
            />

            {/* Visible only when the final status is submitted to client */}
            <SubmittedToClientButton
              row={row}
              userRole={userRole}
              currentjobDetails={currentjobDetails}
              setPopup={setPopup}
            />

            {/* Interview schedule action button start*/}

            {/* Share Interview Availability */}
            <ShareInterviewAvailabilityButton
              row={row}
              userRole={userRole}
              currentjobDetails={currentjobDetails}
              setPopup={setPopup}
            />

            {/* View Shared Availability */}
            <ViewSharedAvailabilityButton
              row={row}
              userRole={userRole}
              currentjobDetails={currentjobDetails}
              setPopup={setPopup}
              hasActiveEvent={hasActiveEvent}
            />

            {/* Cancel Interview */}
            <CancelInterviewButton
              row={row}
              userRole={userRole}
              currentjobDetails={currentjobDetails}
              setPopup={setPopup}
              hasActiveEvent={hasActiveEvent}
            />

            {/* Next Round of Interview */}
            <NextRoundOfInterviewButton
              row={row}
              userRole={userRole}
              currentjobDetails={currentjobDetails}
              setPopup={setPopup}
              hasActiveEvent={hasActiveEvent}
            />

            {/* Interview Reschedule */}
            <InterviewRescheduleButton
              row={row}
              userRole={userRole}
              currentjobDetails={currentjobDetails}
              setPopup={setPopup}
              hasActiveEvent={hasActiveEvent}
            />

            {/* Interview Feedback Due */}
            <InterviewFeedbackDueButton
              row={row}
              userRole={userRole}
              currentjobDetails={currentjobDetails}
              setPopup={setPopup}
              hasActiveEvent={hasActiveEvent}
            />

            {/* Update Interview Status */}
            <UpdateInterviewStatusButton
              row={row}
              userRole={userRole}
              setPopup={setPopup}
              hasActiveEvent={hasActiveEvent}
              features={features}
            />

            {/* Interview schedule action button End*/}
            {/* Reject button*/}
            <RejectButton
              row={row}
              setPopup={setPopup}
            />
     
          </Grid>

        {popup?.contact && (
          <ContactInformationPopup
            contactData={contactInformationData}
            openPopup={popup.contact}
            onlyPartnerInfo={false}
            recruitersGroupInfo={selectedRowJobDetails?.locationGroup}
            handleDocumentsPopupClose={() =>
              setPopup({ ...popup, contact: false })
            }
          />
        )}
        </>
      )}
    </>
  );
}