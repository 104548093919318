import { Checkbox, FormControlLabel, Grid, InputLabel, Link, Typography } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import React from "react";
import { Asterisk } from "../../../../ui/Asterisk";
import { ErrorMessage } from "../../../../ui/ErrorMessage";
import LogoUploader from "../FormFields/logoUploader";
import ClientContractPopup from "../Popup/ClientContractPopup";

export default function AdditionalInfoForm(props: any) {
  const { formik, formError } = props;

  const [openContractPopup, setOpenContractPopup] = React.useState(false);
  
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography className="signUpFormHeading" variant="h6" gutterBottom>
            Additional Information
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel className="additionInfoFieldLabel">
            Company Logo
          </InputLabel>    
          <LogoUploader handleUploadChange={props.handleUploadCompanyLogo} logoFile={props?.logoFile} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel className="additionInfoFieldLabel">
            Linkedin Profile URL
          </InputLabel>
          <TextField
            id="companyOverView"
            name="clientAdditionalDetails.linkedInProfileUrl"
            value={formik.values?.clientAdditionalDetails?.linkedInProfileUrl}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched?.clientAdditionalDetails?.linkedInProfileUrl &&
              formError?.clientAdditionalDetails?.linkedInProfileUrl
            }
            helperText={
              formik.touched?.clientAdditionalDetails?.linkedInProfileUrl &&
              formError?.clientAdditionalDetails?.linkedInProfileUrl
            }
            fullWidth
            type="text"
            multiline
            rows={4}
            variant="outlined"
            className="AdditionInfoTxtArea"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel className="additionInfoFieldLabel">
            Company Benefits 
          </InputLabel>
          <TextField
            required
            id="companyBenefits"
            name="clientAdditionalDetails.companyBenefits"
            value={formik.values.clientAdditionalDetails?.companyBenefits}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched?.clientAdditionalDetails?.companyBenefits &&
              formError?.clientAdditionalDetails?.companyBenefits
            }
            helperText={
              formik.touched?.clientAdditionalDetails?.companyBenefits &&
              formError?.clientAdditionalDetails?.companyBenefits
            }
            fullWidth
            type="text"
            multiline
            rows={4}
            variant="outlined"
            className="AdditionInfoTxtArea"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel className="additionInfoFieldLabel">
            Hiring Process
            <Asterisk />
          </InputLabel>
          <TextField
            required
            id="hiringProcess"
            name="clientAdditionalDetails.hiringProcess"
            value={formik.values.clientAdditionalDetails?.hiringProcess}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched?.clientAdditionalDetails?.hiringProcess &&
              formError?.clientAdditionalDetails?.hiringProcess
            }
            helperText={
              formik.touched?.clientAdditionalDetails?.hiringProcess &&
              formError?.clientAdditionalDetails?.hiringProcess
            }
            fullWidth
            type="text"
            multiline
            rows={4}
            variant="outlined"
            className="AdditionInfoTxtArea"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel className="additionInfoFieldLabel">
            Company Overview 
          </InputLabel>
          <TextField            
            id="companyOverview"
            name="clientAdditionalDetails.companyOverview"
            value={formik.values.clientAdditionalDetails?.companyOverview}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched?.clientAdditionalDetails?.companyOverview &&
              formError?.clientAdditionalDetails?.companyOverview
            }
            helperText={
              formik.touched?.clientAdditionalDetails?.companyOverview &&
              formError?.clientAdditionalDetails?.companyOverview
            }
            fullWidth
            type="text"
            multiline
            rows={4}
            variant="outlined"
            className="AdditionInfoTxtArea"
          />
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            className="consentCheck"
            control={
              <Checkbox
                checked={formik.values?.consentPolicyConfirm}
                data-testid="consentPolicyConfirm"
                name="consentPolicyConfirm"
                color="primary"
                onChange={(e) => {
                  formik.setFieldValue(
                    "consentPolicyConfirm",
                    e.target.checked
                  );
                }}
                onBlur={formik.handleBlur}
              />
            }
            label={
              <span className="consentLinkLabel">
                By signing up you confirm that you have read and accepted our{" "}
                <Link href="/TermsAndConditions" target="_blank" rel="noopener">
                  Terms of Service,
                </Link>{" "}
                <Link href="/PrivacyPolicy" target="_blank" rel="noopener">
                  Privacy Policy,{" "}
                </Link>
                and default{" "}
                <Link
                  href="/notificationSettings"
                  target="_blank"
                  rel="noopener"
                >
                  Notification Settings.
                </Link>
              </span>
            }
          />
          <ErrorMessage
            errorText={
              formik.touched?.consentPolicyConfirm &&
              formError?.consentPolicyConfirm
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            className="contractConfirm"
            control={
              <Checkbox
                checked={formik.values?.contractConfirm}
                readOnly
                data-testid="contractConfirm"
                name="contractConfirm"
                color="primary"
                onBlur={formik.handleBlur}
              />
            }
            label={
              <span className="consentLinkLabel">
                Please click on the below link to digitally sign the contractual document to make your account approval process smooth and easy{" "}
                <Link 
                  data-testid="email"
                  onClick={()=>{
                    // only want to initiate contract if not yet completed
                    // and required API fields are filled out
                    if (
                      formik?.values?.companyName 
                      && formik?.values?.email 
                      && !formik.values?.contractConfirm
                    ) {
                      setOpenContractPopup(true);
                    }
                  }}
                >
                  Contract Document.
                </Link>
              </span>
            }
          />
          <ErrorMessage
            errorText={
              (formik.touched?.contractConfirm &&
              formError?.contractConfirm)
              || (formik.touched?.signatureRequestId &&
              formError?.signatureRequestId)
              || (formik.touched?.signatureId &&
              formError?.signatureId)
            }
          />
        </Grid>
        {openContractPopup &&
          <Grid item xs={12}>
            <ClientContractPopup 
              isOpen={openContractPopup}
              handleClose={() => {
                setOpenContractPopup(false);
              }} 
              companyName={formik?.values?.companyName ?? ""}
              companyEmail={formik?.values?.email ?? ""}
              formik={formik}
            />
          </Grid>
        }
      </Grid>
    </>
  );
}
