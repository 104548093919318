import React, { useEffect } from 'react'

import { Autocomplete, Chip, CircularProgress, TextField } from '@mui/material'
import useApi from '../../hooks/useApi'
import constructName from '../../utils/constructName'
import { GetTeamMembersFilter } from '../../Services/clientService'

export const TeamMemberAutocomplete = (props) => {
    const [filter, setFilter] = React.useState({
        
            "pageIndex": 1,
            "pageSize": 20,
            "sortColumn": "",
            "sortOrder": 1,
            "isSorted": true,
            "totalRecord": 0,
            "searchKeyword": "",
            "locations": [
              
            ],
            "statuses": [
              "true"
            ],
             "showVerifiedTeamMembers":true,
            "clientGuid": ""
          
      });

    const onIndustryInputChange = (e, value) => {
        
        setFilter({ ...filter, searchKeyword: value });
    }
    let recruiter = props.recruiter //formik.values.industries
    const allIndustries = useApi(() => GetTeamMembersFilter(filter));
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            allIndustries.request();
           
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [filter.searchKeyword])
    
    const getInputLabel = (option) => {
        const name = constructName(option.fullName);
        const emailPart = props?.showEmail && option.email ? ' (' + option?.email + ')' : '';
        return name + emailPart;
    };

    let label;
    if (props.disableLabel) {
    label = null;
    } else {
    label = props?.label ? props.label : "Type Name Here";
    }

    return (
        <Autocomplete
            data-testid="dropdown-option"
            freeSolo={props.multiple} //remove dropdown arrow
            autoComplete="off"
            noOptionsText={allIndustries.loading ? 'Loading...' : "No options"}
  
            multiple={props.multiple}
            readOnly={props.readOnly}
            id="size-small-standard-multi3"
            size="medium"
            onInputChange={onIndustryInputChange}
            onBlur={props.onBlur}

            onChange={props.onChange}
            options={allIndustries?.data?.entityList || []}            
            value={recruiter || ''}
            defaultValue={recruiter}
            getOptionLabel={(option) => getInputLabel(option)}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        key={ getInputLabel(option) ?? index}
                        color="primary"
                        size="medium"
                        label={getInputLabel(option)}
                        {...getTagProps({ index })}
                    />
                ))
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder='Search by Name or Email'
                    variant="standard"
                    label={label}
                    required={props?.isRequired!==undefined?props.isRequired:true}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {allIndustries.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {recruiter && params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}

                />
            )}
        />
    )
}
TeamMemberAutocomplete.defaultProps = {
 
    showEmail: false,
  
  }
  
