import Container from "@mui/material/Container";

import Typography from "@mui/material/Typography";

export const Providers = () => {
  return (
    <div>
  
    <Container
      maxWidth="xl"
      className="providers-page"
      sx={{
        marginTop: "16px",
      }}
    >
      <Typography
        variant="h6"
        component="span"
        className="abel  letter-spacing-normal primary-color-text"
        gutterBottom
        textAlign={"center"}
      >
        Providers
      </Typography>
    </Container>
  </div>
  )
}

 


export default Providers;
