import { CircularProgress, FormControl, Input, InputLabel } from "@material-ui/core";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import React from "react";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { firstNameRegex, middleNameRegex, phoneNumberRegex } from "../../../constants/regex";
import { preferredCountries } from "../../../data/phoneInput/preferredCountries";
import { AdminUserModel } from "../../../models/AdminUserModel";
import { getNevLink } from "../../../utils/getFileFromUrl";
import { Asterisk } from "../../ui/Asterisk";
import { ErrorMessage } from "../../ui/ErrorMessage";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));
interface UserProps {
  initialValues: AdminUserModel;
  onSubmit : any;
  type:string
  setFormValues?:any
}
export const UserForm: React.FC<UserProps> = ({
  initialValues,
  onSubmit,
  type,
  setFormValues

}) => { 
  let navigate = useNavigate();
  const countryShort = "us";
  const AddSchema = Yup.object({
    firstName: Yup.string().required("Please enter the first name").matches(firstNameRegex, "Entered First Name is invalid"),
    middleName: Yup.string().matches(middleNameRegex, "Middle Name is not valid"),
    lastName: Yup.string().required("Please enter the last name").matches(firstNameRegex, "Entered Last Name is invalid"),
    email: Yup.string().email('Entered email address is invalid').required('Please enter a valid email address'),
    phoneNumber: Yup.string()
      .required("Please enter your phone number")
      .matches(phoneNumberRegex, "Entered Phone number is not valid"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: AddSchema,
    onSubmit: (values, action) => {
      if(type === "Edit"){
        setFormValues(values)
      }
      
      onSubmit(values,action)
    },
  });
 
  return (
    <div>
     
     
          
     <Box className="form-three-child">
                          <FormControl
                            variant="standard"
                            required
                            error={
                              formik.touched.firstName &&
                              Boolean(formik.errors.firstName)
                            }
                          >
                            <InputLabel htmlFor="firstName">
                              First Name
                            </InputLabel>
                            <Input
                              data-testid="firstName"
                               id="firstName"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values?.firstName}
                              name="firstName"
                              type="text"
                            />
                                       <ErrorMessage errorText={formik.touched.firstName && formik.errors.firstName} />

                          </FormControl>
                          <FormControl variant="standard">
                            <InputLabel htmlFor="middleName">
                              Middle initial
                            </InputLabel>
                            <Input
                              data-testid="middleName"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values?.middleName}
                              name="middleName"
                              type="text"
                            />
                                       <ErrorMessage errorText={formik.touched.middleName && formik.errors.middleName} />

                          </FormControl>
                          <FormControl
                            variant="standard"
                            required
                            error={
                              formik.touched.lastName &&
                              Boolean(formik.errors.lastName)
                            }
                          >
                            <InputLabel htmlFor="lastName">
                              Last Name
                            </InputLabel>
                            <Input
                              data-testid="lastName" 
                              id="lastName"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values?.lastName}
                              name="lastName"
                              type="text"
                            />
                                         <ErrorMessage errorText={formik.touched.lastName && formik.errors.lastName} />

                          </FormControl>
                        </Box>
                        <Box className="form-three-child">
                          <FormControl
                            required
                            error={
                              formik.touched.email &&
                              Boolean(formik.errors.email)
                            }
                            
                          >
                            <InputLabel htmlFor="email">Email</InputLabel>
                            <Input
                              data-testid="email"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values?.email}
                              name="email"
                              type="email"
                            />
                                       <ErrorMessage errorText={formik.touched.email && formik.errors.email} />

                          </FormControl>
                          
                          <FormControl
                         
                        variant="standard"
                        required
                        error={
                          formik.touched.phoneNumber &&
                          Boolean(formik.errors.phoneNumber)
                        }
                      >
                        <span>
                          Phone <Asterisk />
                        </span>
                        <PhoneInput
                          containerClass={`phoneCountryCustom-adminForm`}
                          inputProps={{'data-testid':'primaryPhone'}}
                          placeholder="Phone"
                          country={countryShort}
                          onChange={(ev) => {
                            formik.setFieldValue("phoneNumber", ev);
                          }}
                          value={formik.values?.phoneNumber}
                         
                          preferredCountries={preferredCountries}
                          preserveOrder={["preferredCountries"]}
                        />
                        {formik.touched.phoneNumber && (
                          <ErrorMessage errorText={formik.errors.phoneNumber} />
                        )}
                        <div style={{paddingTop:'10px'}}></div>
                      </FormControl>
                        </Box>
                        <Grid>
                          <Item elevation={0}>
                          <Box
                            >
                              
                            </Box>
                            <Box
                              mt={3}
                              display="flex"
                              justifyContent="flex-end"
                            >
                              <Button
                                variant="outlined"
                                style={{ marginRight: "15px",textTransform:"none" }}
                                onClick={() => {
                                  navigate(getNevLink("/admin/admins/all"));
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                variant="contained"
                                disabled={formik.isSubmitting}
                                endIcon={
                                  formik.isSubmitting ? (
                                    <CircularProgress size={"16px"} color={"inherit"} />
                                  ) : (
                                    ""
                                  )
                                }
                                onClick={() => {
                                  console.log(formik.errors);
                                  formik.handleSubmit();
                                }}
                               
                                style={{textTransform:'none'}}
                              >
                               {type=="Add"?  "Add" : "Update"}
                              </Button>
                            </Box>
                          </Item>
                        </Grid>
    </div>
  );
}
