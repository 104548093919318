


import { createSvgIcon } from "@mui/material/utils";

export const InterviewingIcon = createSvgIcon(
    
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_12103_35814)">
<path d="M5.14272 10.2854C6.56086 10.2854 7.71415 9.13215 7.71415 7.71401C7.71415 6.29586 6.56086 5.14258 5.14272 5.14258C3.72457 5.14258 2.57129 6.29586 2.57129 7.71401C2.57129 9.13215 3.72457 10.2854 5.14272 10.2854ZM5.14272 5.99972C6.08815 5.99972 6.857 6.76858 6.857 7.71401C6.857 8.65944 6.08815 9.42829 5.14272 9.42829C4.19729 9.42829 3.42843 8.65944 3.42843 7.71401C3.42843 6.76858 4.19729 5.99972 5.14272 5.99972Z" fill="black"/>
<path d="M18.8571 10.2854C20.2752 10.2854 21.4285 9.13215 21.4285 7.71401C21.4285 6.29586 20.2752 5.14258 18.8571 5.14258C17.4389 5.14258 16.2856 6.29586 16.2856 7.71401C16.2856 9.13215 17.4389 10.2854 18.8571 10.2854ZM18.8571 5.99972C19.8025 5.99972 20.5714 6.76858 20.5714 7.71401C20.5714 8.65944 19.8025 9.42829 18.8571 9.42829C17.9116 9.42829 17.1428 8.65944 17.1428 7.71401C17.1428 6.76858 17.9116 5.99972 18.8571 5.99972Z" fill="black"/>
<path d="M23.1429 11.1426V16.714C23.1429 17.4229 22.566 17.9997 21.8571 17.9997V13.2854C21.8571 12.1039 20.8959 11.1426 19.7143 11.1426H17.5714C17.3349 11.1426 17.1429 11.3346 17.1429 11.5711V13.2854V13.714V14.1426H13.7143C13.4777 14.1426 13.2857 14.3346 13.2857 14.5711V15.8569H10.7143V14.5711C10.7143 14.3346 10.5223 14.1426 10.2857 14.1426H6.85714V13.714V13.2854V11.5711C6.85714 11.3346 6.66514 11.1426 6.42857 11.1426H4.28571C3.10414 11.1426 2.14286 12.1039 2.14286 13.2854V17.9997C1.434 17.9997 0.857143 17.4229 0.857143 16.714V11.1426H0V16.714C0 17.5904 0.530143 18.3434 1.28571 18.6751V23.9997H2.14286V18.8569H2.57143H3H6.42857V23.5711C6.42857 23.8077 6.62057 23.9997 6.85714 23.9997H8.14286H8.57143H9V23.5711V17.1426V16.714H10.2857H13.7143H15V17.1426V23.5711V23.9997H15.4286H15.8571H17.1429C17.3794 23.9997 17.5714 23.8077 17.5714 23.5711V18.8569H21H21.4286H21.8571V23.9997H22.7143V18.6751C23.4699 18.3434 24 17.5904 24 16.714V11.1426H23.1429ZM8.57143 15.8569H7.71429H5.14286V13.2854H4.28571V16.2854C4.28571 16.522 4.47771 16.714 4.71429 16.714H7.71429C7.95043 16.714 8.14286 16.9064 8.14286 17.1426V23.1426H7.28571V18.4283C7.28571 18.1917 7.09371 17.9997 6.85714 17.9997H3V13.2854C3 12.5766 3.57686 11.9997 4.28571 11.9997H6V13.2854V13.714V14.5711C6 14.8077 6.192 14.9997 6.42857 14.9997H9.85714V15.8569H8.57143ZM17.1429 17.9997C16.9063 17.9997 16.7143 18.1917 16.7143 18.4283V23.1426H15.8571V17.1426C15.8571 16.9064 16.0496 16.714 16.2857 16.714H19.2857C19.5223 16.714 19.7143 16.522 19.7143 16.2854V13.2854H18.8571V15.8569H16.2857H15.4286H14.1429V14.9997H17.5714C17.808 14.9997 18 14.8077 18 14.5711V13.714V13.2854V11.9997H19.7143C20.4231 11.9997 21 12.5766 21 13.2854V17.9997H17.1429Z" fill="black"/>
<path d="M8.14265 4.28571V5.57143C8.14265 5.75614 8.26051 5.91943 8.43579 5.97814C8.48036 5.99271 8.52622 6 8.57122 6C8.70365 6 8.83136 5.93829 8.91408 5.82857L10.0712 4.28571H13.2855C13.9944 4.28571 14.5712 3.70886 14.5712 3V1.28571C14.5712 0.576857 13.9944 0 13.2855 0H8.14265C7.43379 0 6.85693 0.576857 6.85693 1.28571V3C6.85693 3.70886 7.43379 4.28571 8.14265 4.28571ZM7.71408 1.28571C7.71408 1.04957 7.9065 0.857143 8.14265 0.857143H13.2855C13.5216 0.857143 13.7141 1.04957 13.7141 1.28571V3C13.7141 3.23614 13.5216 3.42857 13.2855 3.42857H9.85693C9.72193 3.42857 9.59508 3.492 9.51408 3.6L8.99979 4.28571V3.85714C8.99979 3.62057 8.80779 3.42857 8.57122 3.42857H8.14265C7.9065 3.42857 7.71408 3.23614 7.71408 3V1.28571Z" fill="black"/>
<path d="M12.857 1.71484H8.57129V2.57199H12.857V1.71484Z" fill="black"/>
<path d="M14.1427 10.7148H11.5713V11.572H14.1427V10.7148Z" fill="black"/>
<path d="M11.1426 9C10.4338 9 9.85693 9.57686 9.85693 10.2857V12C9.85693 12.7089 10.4338 13.2857 11.1426 13.2857H14.5712C15.2801 13.2857 15.8569 12.7089 15.8569 12V11.7013L16.6421 10.5236C16.7299 10.392 16.7381 10.2231 16.6635 10.0839C16.5889 9.94414 16.4436 9.85714 16.2855 9.85714H15.7836C15.6066 9.35829 15.1301 9 14.5712 9H11.1426ZM14.9998 10.2857C14.9998 10.5223 15.1918 10.7143 15.4284 10.7143H15.4845L15.0718 11.3336C15.0251 11.4039 14.9998 11.487 14.9998 11.5714V12C14.9998 12.2361 14.8074 12.4286 14.5712 12.4286H11.1426C10.9065 12.4286 10.7141 12.2361 10.7141 12V10.2857C10.7141 10.0496 10.9065 9.85714 11.1426 9.85714H14.5712C14.8074 9.85714 14.9998 10.0496 14.9998 10.2857Z" fill="black"/>
<path d="M10.7141 6.85742H9.85693V7.71456H10.7141V6.85742Z" fill="black"/>
<path d="M12.4284 6.85742H11.5713V7.71456H12.4284V6.85742Z" fill="black"/>
<path d="M14.1428 6.85742H13.2856V7.71456H14.1428V6.85742Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_12103_35814">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
);
