import React from 'react';
import { Formik, Form } from 'formik';
import { Button, Grid } from '@mui/material';
import { createValidationSchema } from '../Utils/ValueProcessingUtils';
import RecruiterAssessmentSection from '../Section/RecruiterAssessmentSection';

interface RecruitmentAssessmentFormProps {
  assessmentDetails: any;
  assesmentResponseValue:any;
  handleOnSubmit:(obj:any, isValid:boolean)=>void
}

const RecruitmentAssessmentForm: React.FC<RecruitmentAssessmentFormProps> = ({ 
  assessmentDetails, 
  assesmentResponseValue,
  handleOnSubmit
}) => {
  
  const validationSchema = createValidationSchema();

  return (
    <Formik
      initialValues={assesmentResponseValue}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        handleOnSubmit(values, true)
        setSubmitting(false);
      }}
    >
      {({ values, errors, touched, setFieldValue, handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <RecruiterAssessmentSection
                assessmentDetails={assessmentDetails}
                values={values}
                assesmentResponseValue={assesmentResponseValue}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
              />
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={() => {
                  handleSubmit();
                  console.log("errors", errors);
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default RecruitmentAssessmentForm;
