import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

interface IPROPS{
showDialog:any;
setShowDialog : any;
cancelNavigation : any;
confirmNavigation: any;
}
export const DialogLeavingPage : React.FunctionComponent<IPROPS> = ({
  showDialog,
  setShowDialog,
  cancelNavigation,
  confirmNavigation
}) => {
  const handleDialogClose = () => {
    setShowDialog(false);
  };

  return (
    <Dialog fullWidth open={showDialog} onClose={handleDialogClose}
    
    PaperProps={
        {
          style:{
            background:'#E6E9FB',
            padding:"20px 24px 10px 24px"
          }
        }
      }>
        <IconButton
            aria-label="close"
            onClick={() => {
            handleDialogClose()
            }}
            sx={{
            position: "absolute",
            right: 8,
            top: 7,
            color: (theme :any) => theme.palette.grey[500],
            }}
        >
            <CloseIcon />
        </IconButton>
            <DialogContent sx={{justifyContent:"center"}}>
                    <Typography
                    variant="h6"
                    component="div"
                    className="abel  letter-spacing-normal"
                    >
                    Search criteria will not be saved. Are you sure you want to navigate?
                    </Typography>
            </DialogContent>
      <DialogActions sx={{justifyContent:"center"}}>
        <Button variant="outlined" onClick={cancelNavigation}>
          Cancel
        </Button>
        <Button variant="contained" onClick={confirmNavigation}>
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
};
