import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import "./PendingSubmission.scss";
import PendingSubmissionTable from "./PendingSubmissionTable";
import PendingSubmissionTableToolbar from "./PendingSubmissionTableToolbar";

import { useDispatch, useSelector } from "react-redux";
import { GetSubmissions } from "../../Services/candidateService";
import getLoggedInUserRole from "../../Shared/utils/getLoggedInUserRole";
import { setPendingSubmissionListState } from "../../features/PendingSubmission/PendingSubmissionListStateSlice";
import { UserAuth } from "../../models/interfaces";
import getAddressForAutoComplete from "../../utils/getAddressForAutoComplete";
import { GotoTop } from "../ui/GotoTop";
import { valueOrDefault } from "../../utils/complexityUtils";
import AppBreadCrumb from "../Common/AppBreadCrumb";
import { getNevLink } from "../../utils/getFileFromUrl";
import { BreadCrumbItem } from "../../models/AppModels";

interface PendingSubmissionProps {}

const PendingSubmission: React.FC<PendingSubmissionProps> = () => {
  // Initialisation
  const dispatch = useDispatch();

  const DefaultBreadcrumbs: BreadCrumbItem[] = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink("/dashboard") },
    { isLeaf: true, sequence: 2, title: "Pending Submissions", leafClasses:" v2LastLeft"},
  ];

  //acces the redux toolkit
  const PendingSubmissionListState = useSelector(
    (state: any) => state.pendingSubmission
  );


  let recruiterId = "";
  const userType = getLoggedInUserRole();
  if (userType === UserAuth.Recruiter) {
    recruiterId = valueOrDefault(JSON.parse(localStorage?.getItem("user") as any)?.userId, "");
  } else {
    recruiterId = "";
  }

  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState<any>([]);

  const [totalRecords, setTotalRecords] = useState(0);

  const _handleOnLocationChange = (selectedLocations: string[]) => {
    dispatch(
      setPendingSubmissionListState({
        ...PendingSubmissionListState,
        pageIndex: 1,
        location: selectedLocations,
      })
    );
  };

  const _handleContractTypeChange = (selectedContractType: string) => {
    dispatch(
      setPendingSubmissionListState({
        ...PendingSubmissionListState,
        pageIndex: 1,
        contractType: selectedContractType,
      })
    );
  };

  useEffect(() => {
    getPendingSubmissionList(PendingSubmissionListState);
  }, [
    PendingSubmissionListState?.location,
    PendingSubmissionListState?.contractType,
    PendingSubmissionListState?.sortOrder,
    PendingSubmissionListState?.searchKeywords,
    PendingSubmissionListState?.statuses,
    PendingSubmissionListState?.pageIndex
  ]);


  const getCity = (description: any) => {
    let city = getAddressForAutoComplete(description)?.city;

    return city;
  };
  const getPendingSubmissionList = (reqData: any) => {
    setDataList([]);
    setLoading(true);
    let selectedLocations: string[] =
      PendingSubmissionListState?.location?.map((item: any) => {
        return getCity(item.description);
      }) || [];

    let requestPayload = {
      ...reqData,
      location: selectedLocations,
      recruiterGuid:recruiterId
    };
    GetSubmissions(requestPayload)
      .then((response) => {        
        if (response?.data?.message?.appStatusCode === "RC_RSF") {
          setTotalRecords(response?.data?.totalRecords);
          setDataList([...response.data.entityList]);
        } else {
          setTotalRecords(0);
          setDataList([]);
        }
        
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };


  const _handlePaginationIndex = (index:number) => {
      dispatch(
        setPendingSubmissionListState({
          ...PendingSubmissionListState,
          pageIndex: index,
        })
      );
  }

  return (
    <>
      <Grid container spacing={0}>
      <Grid item xs={12}>
        <AppBreadCrumb   classes="custom-appbreadcrumb" BreadCrumbItems={DefaultBreadcrumbs} />
      </Grid>
        <Grid item xs={12} md={12} className="submisssionFiltertableToolbarCol">
          <PendingSubmissionTableToolbar
            handleOnLocationChange={_handleOnLocationChange}
            onContractTypeChange={_handleContractTypeChange}
            totalRecords={totalRecords}
          />
          <PendingSubmissionTable
            dataList={dataList}
            loading={loading}
            totalRecordCount={totalRecords}
            filterState={PendingSubmissionListState}
            handlePaginationIndex={_handlePaginationIndex}
          />
        </Grid>
      </Grid>
      <GotoTop />
    </>
  );
};
export default PendingSubmission;
