import React from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Divider, Alert, Collapse, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContentText from "@mui/material/DialogContentText";
import * as Yup from "yup";
import { useFormik } from "formik";
import { AdminAddNewRecruiter } from "../../Services/AdminAddNewRecruiterService";
import { firstNameRegex, middleNameRegex } from "../../constants/regex";
import { ErrorMessage } from "../ui/ErrorMessage";
import { getNevLink } from "../../utils/getFileFromUrl";
import AppBreadCrumb from "../Common/AppBreadCrumb";
import TextField from "@mui/material/TextField";
import SuccessIcon from "../../assets/images/Approved.svg";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

const AddRecruiters: React.FC = () => {
  const [email, setEmail] = React.useState("");

  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const [errorMessage, setErrorMessage] = React.useState("");

  const [updateMessage, setUpdateMessage] = React.useState(false);
  let navigate = useNavigate();
  const [pageStatus, setPageStatus] = React.useState({
    isSuccess: false,
    error: "",
    loading: false,
  });

  const initialState = {
    id: "",
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    redirectURL: "",
    loggedinUserRole: "Admin",
    adminFName: "",
    adminEmail: "",
    createdBy: "",
  };
  const AddSchema = Yup.object({
    firstName: Yup.string()
      .required("Please enter the first name")
      .matches(firstNameRegex, "Entered First Name is invalid"),
    middleName: Yup.string().matches(
      middleNameRegex,
      "Middle Name is not valid"
    ),
    lastName: Yup.string()
      .required("Please enter the last name")
      .matches(firstNameRegex, "Entered Last Name is invalid"),
    email: Yup.string()
      .email("Entered email address is invalid")
      .required("Please enter a valid email address"),
  });
  const formik = useFormik({
    initialValues: initialState,
    validationSchema: AddSchema,
    onSubmit: (values, actions) => {
      setPageStatus({ isSuccess: false, error: "", loading: true });
      setErrorMessage("");
      const recruiter = {
        ...values,
        redirectURL: `${window.location.origin}/signupcomplete`,
        loggedInUserRole: user.loggedInUserRole,
        adminFName: user.userName,
        adminEmail: user.userName,
        createdBy: user.userId,
      };
      actions.setSubmitting(true);
      _AdminAddRecruiter(recruiter);
    },
  });

  const _AdminAddRecruiter = (requestPayload: any) => {
    AdminAddNewRecruiter(requestPayload)
      .then((response) => {
        if (
          response.data.status === 200 &&
          response.data.message.appStatusCode === "RC_SEML"
        ) {
          setPageStatus({ isSuccess: true, error: "", loading: false });
          setEmail(requestPayload.email);
        } else if (
          response.data.status === 200 &&
          response.data.message.appStatusCode === "RC_EALV"
        ) {
          setPageStatus({ isSuccess: true, error: "", loading: false });
          setEmail(requestPayload.email);
          setErrorMessage(response.data.message.appStatusDescription);
        } else if (
          response.data.status === 409 &&
          response.data.message.appStatusCode === "RC_EXST_ADM"
        ) {
          setPageStatus({
            isSuccess: false,
            error: response.data.message.appStatusDescription + "RC_EXST_ADM",
            loading: false,
          });
          setErrorMessage(
            response.data.message.appStatusDescription + "RC_EXST_ADM"
          );
          setUpdateMessage(true);
        } else {
          setPageStatus({
            isSuccess: false,
            error: response.data.message.appStatusDescription,
            loading: false,
          });
          setErrorMessage(response.data.message.appStatusDescription);
          setUpdateMessage(true);
        }
      })
      .catch((er) => {
        setPageStatus({
          isSuccess: false,
          error: er.message,
          loading: false,
        });
      });
  };

  let savedJobBreadCrumb = [
    {
      isLeaf: false,
      sequence: 1,
      title: "Dashboard",
      to: getNevLink("/dashboard"),
    },
    { isLeaf: false, sequence: 2, title: "Recruiters", to: "/recruiters" },
    {
      isLeaf: true,
      sequence: 3,
      title: "Add Recruiter",
      leafClasses: "custom-appbreadcrumb",
    },
  ];
  return (
    <div className="clientlist-grid">
      <Grid item xs={12} md={12}>
        <Item elevation={0} sx={{ paddingTop: "20px" }}>
          <AppBreadCrumb
            classes="custom-appbreadcrumb"
            BreadCrumbItems={savedJobBreadCrumb}
          ></AppBreadCrumb>
        </Item>
      </Grid>
      <Divider sx={{ marginLeft: "9px", marginRight: "22px" }} />
      <Paper className="addrecruiterpadding" elevation={0}>
        <Grid container spacing={2} sx={{ justifyContent: "left" }}>
          <Grid item xs={12} md={12}>
            <Typography
              variant="h6"
              component="div"
              className="addbasicdetails"
              sx={{ paddingTop: "0px" }}
            >
              Add Basic Details
            </Typography>
          </Grid>
        </Grid>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            flexDirection: "row",
            "& .MuiFormControl-root": {
              marginTop: "15px",
              marginBottom: "3px",
            },
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} md={3}>
              <TextField
                required
                data-testid="input-name"
                className="form-field-txt"
                label="First Name"
                variant="standard"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                name="firstName"
                fullWidth
                type="text"
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
              />
              <ErrorMessage
                errorText={formik.touched.firstName && formik.errors.firstName}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                data-testid="input-middlename"
                label="Middle initial"
                variant="standard"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.middleName}
                name="middleName"
                type="text"
                fullWidth
              />
              <ErrorMessage
                errorText={
                  formik.touched.middleName && formik.errors.middleName
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                required
                data-testid="input-lastname"
                label="Last Name"
                variant="standard"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                name="lastName"
                type="text"
                fullWidth
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
              />
              <ErrorMessage
                errorText={formik.touched.lastName && formik.errors.lastName}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                required
                data-testid="input-email"
                label="Your email"
                variant="standard"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                name="email"
                type="email"
                fullWidth
                error={formik.touched.email && Boolean(formik.errors.email)}
              />
              <ErrorMessage
                errorText={formik.touched.email && formik.errors.email}
              />
            </Grid>
          </Grid>
          <Grid>
            <Item elevation={0}>
              <Box>
                {errorMessage && (
                  <Collapse in={updateMessage}>
                    <Alert
                      action={
                        <IconButton
                          data-testid="setUpdateMessage"
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setUpdateMessage(false);
                          }}
                        >
                          {" "}
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                      severity="error"
                    >
                      {errorMessage}
                    </Alert>
                  </Collapse>
                )}
              </Box>
              <Box mt={3} display="flex" justifyContent="flex-end">
                <Button
                  className="roboto-font"
                  variant="outlined"
                  style={{ marginRight: "15px", textTransform: "none" }}
                  onClick={() => {
                    navigate(getNevLink("/Recruiters"));
                  }}
                  data-testid="cancelBtn"
                >
                  Cancel
                </Button>
                <Button
                  className="roboto-font"
                  variant="contained"
                  disabled={pageStatus.loading}
                  endIcon={
                    pageStatus.loading ? (
                      <CircularProgress size={"16px"} color={"inherit"} />
                    ) : (
                      ""
                    )
                  }
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                  style={{ textTransform: "none" }}
                  data-testid="sendEmailVerification"
                >
                  Send email for verification
                </Button>
              </Box>
            </Item>
          </Grid>
        </Box>

        <Grid item xs={12} md={6}>
          <Item elevation={0}>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              sx={{
                flexDirection: "row",
                "& .MuiFormControl-root": {
                  marginTop: "15px",
                  marginBottom: "3px",
                },
              }}
            ></Box>
          </Item>
        </Grid>
      </Paper>

      {pageStatus.isSuccess && (
        <Dialog open={pageStatus.isSuccess}>
          <DialogContent sx={{ bgcolor: "#FFF" }}>
            <IconButton
              data-testid="close"
              aria-label="close"
              onClick={() => {
                setPageStatus({ ...pageStatus, isSuccess: false });
                formik.resetForm();
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContentText
              component="div"
              id="success-dialog-description"
              textAlign="center"
            >
              <figure>
                <img
                  src={SuccessIcon}
                  className="PopupStatusIcon"
                  alt="Success Icon"
                />
              </figure>
              <Typography component="div" className="PopupPrimaryTitle" my={2}>
                New Recruiter Successfully Added
              </Typography>
              <Typography
                component="div"
                fontWeight={600}
                my={2}
                className="PopupSecondaryTitle"
              >
                The new recruiter added will receive the verify account email in
                their mailbox sent to {email} within the next 1 hour.
              </Typography>
              <Box my={3}>
                <Button
                  variant="contained"
                  data-testid="absolute"
                  onClick={() => {
                    setPageStatus({ ...pageStatus, isSuccess: false });
                  }}
                >
                  Not the correct email address
                </Button>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};
export default AddRecruiters;
