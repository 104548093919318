function getUniqueLocationForAutoComplete(arr)
{
    const uniqueIds = [];

    const uniqueIndustries = arr?.filter(element => {
      const isDuplicate = uniqueIds.includes(element?.formattedSuggestion?.mainText);
   
      if (!isDuplicate) {
        uniqueIds.push(element?.formattedSuggestion?.mainText);
   
        return true;
      }
   
      return false;
    });
    return uniqueIndustries
}
export default getUniqueLocationForAutoComplete