import { RequestFormPayload } from '../components/Client/ManageClient/Components/FormModel/ManageClientModel';
import { ClientInitiateContractRequest } from '../models/ClientInitiateContractRequest';
import { ClientSignupByRoleModel } from '../models/ClientSignupByRoleModel';
import { ClientSocialSignupModel } from '../models/ClientSocialSignupModel';
import { UpdateClientEmailRequestPaylaod } from '../models/Services/RequestModel/UpdateClientEmailRequest';
import axios from './CommonHttpRequest';

const baseUrl = `${process.env.REACT_APP_CLIENT_API}`;

export const SignupInitialVerification = async (data: any) => {
  return await axios.post(`${baseUrl}api/Client/SignupInitialVerification`, data);
};

export const ClientSocialSignup = async (payload: ClientSocialSignupModel) => {
  return await axios.post(`${baseUrl}api/client/social-signup`, payload);
}

export const accountManagerFilter = async (body: any) => {
  return await axios.post(`${baseUrl}api/client-master/account-manager`, body);
};

export const clientFilter = async (body: any) => {
  return await axios.post(`${baseUrl}api/client/filter`, body);
};
export const ClientSignupByRole = async (payload: ClientSignupByRoleModel) => {
  return await axios.post(`${baseUrl}api/client/signup-by-role`, payload);
};

export const GetCompanySizeList = async (payload: any) => {
  return await axios.post(`${baseUrl}api/client-master/company-size`,payload);
};

export const ClientCompleteSignUpSubmit = async (payload: any) => {
  return await axios.post(`${baseUrl}api/client/complete-signup`,payload);
};

export const checkVerifyEmailLinkForExpire = async (email:string|null, expiryTime:string|null, messageType:string|null, id:string|null)=>{
  return await axios.get(`${baseUrl}api/client/SignupInitialVerified?id=${id}&email=${email}&expiryDateTime=${expiryTime}&messageType=${messageType}`);
}
export const GetStatuses = async () => {
  return await axios.get(`${baseUrl}api/client-master/statuses`);
};

export const GetReasons = async (id: string) => {
  return await axios.get(`${baseUrl}api/client-master/reasons?statusId=${id}`);
};
export const GetPaymentTerms = async () => {
  return await axios.get(`${baseUrl}api/client-master/payment-terms`);
};
export const GetPaymentModes = async () => {
  return await axios.get(`${baseUrl}api/client-master/payment-modes`);
};
export const GetModeOfDelivery = async () => {
  return await axios.get(`${baseUrl}api/client-master/mode-of-delivery`);
};
export const GetClientById = async (id?:string) => {
  return await axios.get(`${baseUrl}api/client/${id}`);
};
export const EditByRole = async (payload:any) => {
  return await axios.put(`${baseUrl}api/client/edit-by-role`,payload);
};

export const VerifyClient = async (payload:any) => {
  return await axios.post(`${baseUrl}api/client/VerifyClient`,payload);
};

// Team Member

// add client member API to submit
export const addClientTeamMember = async (payload: any) => {
  return await axios.post(`${baseUrl}api/team-member/add`,payload);
};

// Edit client Team member

export const editClientTeamMember = async (payload: any) => {
  return await axios.post(`${baseUrl}api/team-member/edit`,payload);
};


// Get Team member details by ID
export const GetTeamMemberById = async (teamMemberId?:string) => {
  return await axios.get(`${baseUrl}api/team-member/${teamMemberId}`);
}

export const GetTeamMembersFilter = async (payload:any) => {
  return await axios.post(`${baseUrl}api/team-member/filter`,payload);
}
// Send the set password link by team member id
export const SetPasswordByTeamMeberId = async (teamMemberId?:string) => {
  return await axios.post(`${baseUrl}api/team-member/send-password-link?id=${teamMemberId}`);
}

export const SetPasswordService = async (payload: any, token: string) => {
  const headers: any = {
    Authorization: `Bearer ${token}`,
  };

  return await axios.post(`${baseUrl}api/team-member/set-password`, payload, {
    headers,
  });
};
export const UpdateLocation = async (payload:any) => {
  return await axios.put(`${baseUrl}api/client-manage-profile/location`,payload);
};

export const UpdateAccountDetails = async (payload:any) => {
  return await axios.put(`${baseUrl}api/client-manage-profile/account-details`,payload);
};

export const UpdateUserProfile = async (payload:any) => {
  return await axios.put(`${baseUrl}api/client-manage-profile/basic-details`,payload);
};

export const UpdateClientPhoto = async (payload:any) => {
  return await axios.put(`${baseUrl}api/client-manage-profile/photo`,payload);
};

// update the email for client
export const UpdateClilentEmail = async (payload : UpdateClientEmailRequestPaylaod) =>{
  return await axios.put(`${baseUrl}api/client-manage-profile/change-email`,payload);
}
 // Manage Client Save API 
 export const ManageClientSave = async (payload : RequestFormPayload) =>{
  return await axios.put(`${baseUrl}api/client/manage`,payload);
}


export const incompleteClientServiceSignup = async (body: any) => {
  return await axios.get(`${baseUrl}api/client/filter`, body);
};

export const DeleteIncompleteClientSignup = async (id:string) => {
  return await axios.delete(`${baseUrl}api/client/delete?id=${id}`);
}

export const ClientDashboardActivity = async () =>{
  return await axios.get(`${baseUrl}api/client/dashboard-activity`);
}

// Client and team member graphical dashboard API

export const GetClientGraphicalDashboard = async () =>{
  return await axios.get(`${baseUrl}api/client/graphical-dashboard`);
}

export const InitiateContract = async (payload: ClientInitiateContractRequest) => {
  return await axios.post(`${baseUrl}api/client/initiate-contract`, payload);
}
