import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRecruiterGraphicalDashboard } from "../../Services/recruiterService";

export const adminGraphicalDashboard = createAsyncThunk(
  'adminGraphicalDashboard',
  async (id, thunkAPI) => {
    let userId = JSON.parse(localStorage.getItem('user'))?.userId;
    if(userId){

    return getRecruiterGraphicalDashboard(userId).then(response => response.data)
    .catch(error => thunkAPI.rejectWithValue(error?.response?.data || error))
    }
    
  }
)


export const adminDashboardState = createSlice({
  name: "adminGraphicalDashboard",
  initialState: {
    data: null,
    error: null,
    success: null,
    loading : false
  },
  reducers: {
    
    clear: (state) => {
      
      state.data= null
      state.error= null
      state.success= null
      state.loading = false
    },
   
   
  },
  extraReducers: (builder) => {
    //Post candidate
    builder.addCase(adminGraphicalDashboard.pending, (state, action) => {
      state.loading = true;
    })
     
   
    builder.addCase(adminGraphicalDashboard.fulfilled, (state, action) => {
    
     
      state.data = action.payload
      state.error=null
      state.success=true
      state.loading = false

    })
    builder.addCase(adminGraphicalDashboard.rejected, (state, action) => {
      console.log(action);  
      state.error = action.payload?.message? action.payload.message : action.error.message
      state.loading = false
    })

  },
});

// Action creators are generated for each case reducer function
export const {clear } =
  adminDashboardState.actions;

export default adminDashboardState.reducer;