import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React from "react";
import { useNavigate } from "react-router-dom";
import Approved from "../../../assets/images/Approved.svg";
import "../../ui/ClientPopup.scss";
import "./SetPassword.scss";

interface Props {}
const PasswordSetPopup: React.FC<Props> = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Box>
        <Container maxWidth="sm">
          <Grid
            container
            spacing={0}
            sx={{ justifyContent: "center", marginTop: "30px" }}
          >
            <Paper
              elevation={3}
              style={{ backgroundColor: "rgba(249, 250, 255, 1)" }}
            >
              <Grid container style={{ padding: "40px" }}>
                <Grid item xs={12} md={12}>
                  <Box>
                    <Typography className="custom-pwdset-txt">
                      Password Set Successfully
                    </Typography>
                  </Box>
                  <Box className="custom-padding-img">
                    <Typography style={{ textAlign: "center" }}>
                      <img src={Approved} />
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      className="custom-pwdset-msg custom-padding-img"
                      style={{ textAlign: "center" }}
                    >
                      Your password has been set successfully, Go to the Sign In
                      page to access your account now.
                    </Typography>
                  </Box>
                  <Box className="custom-pwdset-msg custom-padding-img">
                    <Typography style={{ textAlign: "center" }}>
                      <Button
                        className="custom-pwdset-btn"
                        variant="contained"
                        onClick={() => navigate("/login")}
                      >
                        Sign In here
                      </Button>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default PasswordSetPopup;
