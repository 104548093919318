import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import PlacesAutocomplete from "react-places-autocomplete";
import { Divider, FormControlLabel } from "@mui/material";

import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import { useState, forwardRef, useImperativeHandle } from 'react';

import { makeStyles } from "@material-ui/core";

import getUnique from "../../../utils/getUnique";
import { defaultSelectAutoComplete } from "../../../data/defaultDataAutocomplete";
import getAddressForAutoComplete from "../../../utils/getAddressForAutoComplete";
import getUniqueLocationForAutoComplete from "../../../utils/getUniqueLocationFromAutoComplete";
import { valueOrDefault } from "../../../utils/complexityUtils";

const useStyles = makeStyles(() => ({
  scrollMenu: {
    "&::-webkit-scrollbar": {
      width: "0.2em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#4540DB",
      outline: "1px solid #4540DB",
    },
  },
}));
 const  LocationFilter = (props,ref) => {
  const classes = useStyles();

  const [selectedLocations, setLocationsSelected ] = useState([])
  const [search, setSearch] = useState("");
  const [autoSuggestions, setautoSuggestions] = useState(
    defaultSelectAutoComplete
  );
  const loadSuggestions = (sugg) => {
    if (sugg.length > 0) {
      setautoSuggestions(sugg);
    }
  };
  const clearState = () => {
    setSearch('')
  }
  useImperativeHandle(ref, () => ({
    clearState: clearState
  }));

  const getCity = (description) => {
    let city = getAddressForAutoComplete(description).city;

    return city;
  };
  const handleFilterChange = (e) => {
    //update on parent component

    props.onChange(e, "locationFilterInputs");
   
  };
  const handleSelectionChange = (e,selection)=> {
    let isChecked = e.target.checked;

    if (isChecked) {
    setLocationsSelected([...selectedLocations, selection])
    }
    else
    {
      setLocationsSelected(selectedLocations.filter(item => item !== selection))

    

    }

  }
  const renderPreferredGeographies = () => {
    let locationLists = getUnique(selectedLocations.concat(getUniqueLocationForAutoComplete(autoSuggestions)),"placeId")
    return (
      <PlacesAutocomplete
        searchOptions={{ types: ["(cities)"] }}
        ref={(c) => {
          if (!c) return;
          c.handleInputOnBlur = () => {};
        }}
        value={search}
        onChange={(ev) => {
          if (!ev) {
            setautoSuggestions(defaultSelectAutoComplete);
          }
          setSearch(ev);
        }}
      >
        {({ getInputProps, suggestions }) => {
          loadSuggestions(suggestions);
          return (
            <>
              <TextField
                id="standard-basic"
                name={"location"}
                placeholder="Search Places"
                variant="standard"
                sx={{ marginLeft: "20px",width:"220px" }}
                InputProps={{
                  disableUnderline: true,
                }}
                {...getInputProps()}
              />
              <Divider
                sx={{
                  color: "primary",
                  width: "220px",
                  marginLeft: "21px",
                  bgcolor: "secondary.light",

                  padding: "0.8px",
                  marginTop: "2px",
                }}
              />
              <Paper
                elevation={3}
                sx={{
                  maxWidth: "100%",
                  display: "grid",
                  flexWrap: "nowrap",
                  marginBottom: "25px",
                  marginTop: "18px",
                  marginRight: "25px",
                  marginLeft: "25px",
                }}
                className={classes.scrollMenu}
                style={{
                  maxHeight: 130,
                  overflow: "auto",
                }}
              >
                <MenuList>
                  {locationLists
                  
                    .map((suggestion, index) => {
                      let state = valueOrDefault(
                        suggestion?.formattedSuggestion?.secondaryText?.split(",")[0],
                        ""
                      );
                      let city = getCity(suggestion?.description);

                      if(city!==undefined){
                      return (
                      
                        <MenuItem
                          key={suggestion?.placeId ?? index}
                          sx={{ paddingLeft: "3px", paddingBottom: "0px" }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(e) =>
                                  {handleFilterChange(e); handleSelectionChange(e,suggestion)}
                                }
                                name={city}
                                checked={props.filterState.includes(
                                  city
                                )}
                                sx={{ padding: "0px" }}
                                inputProps={{
                                  'data-testid':"checkbox-location"
                                }}
                              />
                            }
                            label={city + "," + state}
                            className="candidates-label"
                          />
                        </MenuItem>
                      );
                      }
                    })
                  }
                </MenuList>
              </Paper>
            </>
          )
        }}
      </PlacesAutocomplete>
    );
  };
  return <div>{renderPreferredGeographies()}</div>;
};
export default forwardRef(LocationFilter);
