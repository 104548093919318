import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CloseIcon from "@mui/icons-material/Close";

export const UpdateConfirmationDialog = ({open, onClose, onYes, onNo, message, aggreeText,disagreeText}) => {
  return (
    <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={
          {
            style:{
              padding:"20px 24px 10px 24px"
            }
          }
        }
      >
        <DialogTitle id="alert-dialog-title" style={{padding:"0px 0px 5px 0px"}}>
            <IconButton 
              aria-label="close" 
              size="small" 
              className="close-Btn" 
              style={{position:"absolute",right:"5px",top:"10px"}} 
              onClick={onNo}
              data-testid="onClose"
              >
              <CloseIcon fontSize="small" />
            </IconButton>
            </DialogTitle>
        <DialogContent style={{padding:"20px 0px 10px 0"}}>
          <DialogContentText>
          <Typography
              variant="h6"
              component="div"
              className="letter-spacing-normal"
              sx={{textAlign:"center"}}
            >
              {message} 
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{justifyContent:"center"}} style={{padding:"15px 0 7px 0"}}>
          <Button onClick={onYes} variant="contained">{aggreeText}</Button>
          <Button onClick={onNo} variant="outlined">{disagreeText}</Button>
        </DialogActions>
      </Dialog>
  )
}
