import React from "react";
import { Divider, Paper } from "@mui/material";
import Grid from "@mui/material/Grid";
import AppBreadCrumb from "../../Common/AppBreadCrumb";
import { BreadCrumbItem } from "../../../models/AppModels";
import TeamMemberAddEditForm from "../../ClientTeam/TeamMemberAddEditForm";
import { FormMode } from "../../../models/app/AppModels";
import { getNevLink } from "../../../utils/getFileFromUrl";
import SectionHeading from "../../ui/Form/Heading/SectionHeading";

const addJobBreadcrumbs: BreadCrumbItem[] = [
  { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
  { isLeaf: false, sequence: 2, title: "Team Members", to: "/teammembers" },
  { isLeaf: true, sequence: 3, title: "Add Team Member" },
];
const AddClientTeamMemberPage: React.FC = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AppBreadCrumb classes="custom-appbreadcrumb" BreadCrumbItems={addJobBreadcrumbs} />
        </Grid>
        <Grid item xs={12} >
        <SectionHeading title="Team Member Information"></SectionHeading>
          <Divider className="borderedDivider" />
        </Grid>
          <Grid item xs={12} mt={-2}>
          <Paper 
          elevation={0}
          sx={{p:2}}
          className="teamMemberFormPage"
          >
            <TeamMemberAddEditForm mode={FormMode.ADD} />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AddClientTeamMemberPage;
