import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

import SubmissionFilterTable from './SubmissionFilterTable';
import SubmissionFilterTableToolbar from './SubmissionFilterTableToolbar';
import './submissionFilter.css';

import { GetContractTypes, GetSubmissionStatuses, GetSubmissions } from "../../Services/candidateService";
import getLoggedInUserRole from '../../Shared/utils/getLoggedInUserRole';
import getUserDetails from "../../Shared/utils/getUserDetails";
import { UserAuth } from '../../models/interfaces';
import { getNevLink } from "../../utils/getFileFromUrl";
import { GotoTop } from "../ui/GotoTop";
import { ternary } from "../../utils/complexityUtils";


export default function SubmissionFilter() {

  const [searchParams] = useSearchParams();
  let QSstatusValue = searchParams.get("status") === null ? null : searchParams.get("status");
  let recruitersFromParams = searchParams.get("recruiterGuid") === null ? null : searchParams.get("recruiterGuid");
  let QSstatus;
  QSstatus = (QSstatusValue !=="" && QSstatusValue !== null) ? QSstatusValue?.split(',') : []

  let QScontractType = (searchParams.get("contractType") === null || searchParams.get("contractType") === "" || searchParams.get("contractType") === undefined)  ? [] : [(searchParams?.get("contractType"))];

  const userType = getLoggedInUserRole();

  const userDetails = getUserDetails()
  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [submissionStatuses,setSubmissionStatuses] = useState([]);
  const [contractTypes,setContractTypes] = useState([]);
  const [submissionStatusesDefault,setSubmissionStatusesDefault] = useState([]);
  const [contractTypesDefault,setContractTypesDefault] = useState([]);
  const [filterState,setfilterState] = useState({
    recruiterGuid: ternary(userType == UserAuth.Recruiter, userDetails.id, ""),
    jobId:'',
    searchKeywords:'',
    location:[],
    statuses:QSstatus ?? [] ,
    contractType:QScontractType ?? [],
    showPendingSubmissions:false,
    pageIndex:1,
    pageSize:20,
    sortColumn:'',
    sortOrder:'Descending',
    isSorted:true,
    totalRecord:0 ,
    recruiterFilter : ternary(recruitersFromParams == null, [], [recruitersFromParams])
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [hasMoreData, setHasMoreFlag] = useState(null);
  useEffect(()=>{
    GetSubmissionsStatuses();
    GetContractTypesList();
  },[]);


  useEffect(() => {

    getDataList(filterState);
    
  }, [filterState.recruiterFilter, filterState.pageIndex,filterState.location,filterState.contractType,filterState.sortOrder,filterState.searchKeywords,filterState.statuses]);
  
  const getDataList = (reqData)=> {
    if(reqData.pageIndex ===1 ){
      setDataList([]);
    }
    setLoading(true)
    GetSubmissions(reqData)
    .then((response)=>{
      if (response.data.entityList.length < filterState.pageSize) {
              
        setHasMoreFlag(false);
      } else {
        
        setHasMoreFlag(true);
      }

      if (response.data.entityList.length < filterState.pageSize) {
        setHasMoreFlag(false);
      } else {
        setHasMoreFlag(true);
      }
      
      if(filterState.pageIndex === 1){          
        setDataList([...response.data.entityList])
      }else{
          setDataList([...dataList, ...response?.data?.entityList])
      }
      setTotalRecords(response?.data?.totalRecords);
    }).catch((error)=>{
      console.log(error,error, error?.request?.status)
    })
    .finally(() => setLoading(false));
  }

  const GetSubmissionsStatuses = ()=> {
    
    GetSubmissionStatuses()
    .then((response)=>{
      setSubmissionStatusesDefault([...response?.data?.entityList])
      setSubmissionStatuses([...response?.data?.entityList])
      if(filterState.statuses.length ===0 ){
        let statusList = [];
         statusList = response?.data?.entityList?.map((item)=>{
          return item.eventName
        })
        if(recruitersFromParams!==null)
        {
          setfilterState({...filterState, statuses:[]})
        }
        else
        {
        setfilterState({...filterState, statuses:statusList})
        }
      }
    }).catch((error)=>{
      console.log(error,error, error?.request?.status)
    })
  }

  const GetContractTypesList = ()=>{
    GetContractTypes()
    .then((response)=>{
      setContractTypesDefault([...response?.data?.entity?.contractTypes])
      setContractTypes([...response?.data?.entity?.contractTypes])
    }).catch((error)=>{
      console.log(error,error, error?.request?.status)
    })
  }

  const fetchNextData = ()=> {
      setfilterState({ ...filterState, pageIndex: filterState.pageIndex + 1 });
    
  }


  return (
    <div>
    
         
          <Grid item xs={12} md={12}>
            <Grid Container>
                <Grid item xs={12} md={12} mb={2}>
                    <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb"
                    >
                        <Link
                        color="primary"
                        to={(userType == UserAuth.Admin ? getNevLink("/dashboard") : "/dashboard")}
                        style={{ textDecoration: "none" }}
                        className="fontmulish-bread"
                        >
                        Dashboard
                        </Link>
                       {recruitersFromParams==null? "" : <Link
                        color="primary"
                        to={getNevLink('/recruiters')}
                        style={{ textDecoration: "none" }}
                        className="fontmulish-bread"
                        >
                        Recruiters
                        </Link>
}
                    <Typography>Submissions</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item xs={12} md={12} mb={4}>
                <Typography  variant="h6" component="div"
                    className="abel  letter-spacing-normal primary-color-text" sx={{ paddingTop: "0px" }}>
                    Total Submissions ({totalRecords})
                </Typography>
            </Grid>
            <Grid item xs={12} md={12} className='submisssionFiltertableToolbarCol'>
                <SubmissionFilterTableToolbar
                submissionStatusesDefault={submissionStatusesDefault}
                contractTypesDefault={contractTypesDefault}
                submissionStatuses={submissionStatuses}
                setSubmissionStatuses={ setSubmissionStatuses }
                setContractTypes = { setContractTypes }
                contractTypes={contractTypes}
                currentState={filterState}
                changeState={setfilterState}
                />
                <SubmissionFilterTable 
                dataList={dataList}
                fetchNextData={fetchNextData}
                hasMoreData={hasMoreData}
                loading={loading}
                />
            </Grid>
        </Grid>
        <div
        style={{
          position:"fixed",
          right: "5%",
          bottom: "10%",
          zIndex: "50",
        }}
      >
        <GotoTop />
      </div>
        </Grid>
    </div>
  );
}