import React from "react";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { getTextNameEllipse } from "../../../utils/StringUtil";

interface StringWithTooltipProps {
  text: string;
  maxEllipseTextSize?: number;
  className?:string;
  fontSize?:string;
}

const StringWithTooltip: React.FC<StringWithTooltipProps> = ({
  text,
  maxEllipseTextSize = 20,
  fontSize="14px",
  className=""
}) => {
  return text?.length > maxEllipseTextSize ? (
    <Tooltip title={text}>
      <Typography className={`${className}`} noWrap sx={{fontSize:fontSize}}>
        {getTextNameEllipse(text ?? "", maxEllipseTextSize)}
      </Typography>
    </Tooltip>
  ) : (
    <Typography className={`${className}`} sx={{fontSize:fontSize}}>
      {getTextNameEllipse(text ?? "", maxEllipseTextSize)}
    </Typography>
  );
};

export default StringWithTooltip;
