import { useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { toggleEdit } from "../../../features/editMode/editModeSlice";
import { DocumentTypeEnum, UserAuth } from "../../../models/interfaces";
import { USAPaymentDetail } from "../../../models/PaymentDetails";
import { GetUSPaymentDetail } from "../../../Services/invoiceManagerService";
import { AddUSAPaymentDetail } from "../../../Services/recruiterPaymentService";
import {
  UploadAttachment,
  UploadResourceModel,
} from "../../../Services/uploadPhotoService";
import { USAForm } from "./USAForm";
import usaSchema from "./schema/usaForm";
import { isValidFileExtention } from "../../../utils/isValidFileExtensions";
interface Props {
  editMode: any;
  billingDetail: {
    userId: string;
    email: string;
    name: string;
    billingInfoType: string;
  };
  recruiterData: any;
}
export const RecruiterUSABilling: React.FC<Props> = ({
  editMode,
  billingDetail,
  recruiterData,
}) => {
  let initialState: USAPaymentDetail = {
    userId: billingDetail.userId,
    email: billingDetail.email,
    name: billingDetail.name,
    billingInfoType: "USA",
    country: "USA",
    routingNumber: "",
    confirmRoutingNumber: "",
    accountHolderName: "",
    bankName: "",
    bankAccountNumber: "",
    confirmBankAccountNumber: "",
    location: {
      address: "",
      city: "",
      state: "",
      zip: "",
      country: "",
    },
    isRoutingNumberConfirm: false,
    isW9NameConfirm: true,
    resourceInfo: {
      resourceUrl: "",
      fileName: "",
      newFileName: "",
    },
    isUpdateDetails: false,
  };
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const [pageStatus, setPageStatus] = React.useState({
    isSuccess: false,
    error: "",
    loading: false,
  });
  const [formState, setformState] = React.useState<any>(initialState);
  const [isChecked, setIsChecked] = React.useState({
    first: false,
    second: false,
  });
  const [fileError, setfileError] = React.useState("");
  const [fileName, setFileName] = React.useState("");
  const [isUploading, setIsUploading] = React.useState(false);

  const formik = useFormik({
    initialValues: initialState,
    enableReinitialize: true,
    validationSchema: usaSchema,
    onSubmit: (values, actions) => {
      if (fileError !== "") {
        return;
      }
      if (!isChecked.first || !isChecked.second) {
        return;
      }
      setPageStatus({ isSuccess: false, error: "", loading: true });
      values.isW9NameConfirm = Boolean(values.isW9NameConfirm);
      values.isRoutingNumberConfirm = Boolean(values.isRoutingNumberConfirm);
      values.userId = billingDetail.userId;
      values.email = billingDetail.email;
      values.name = billingDetail.name;
      values.isUpdateDetails = false;
      AddUSAPaymentDetail(values)
        .then((response) => {
          if (response.data.status === 200) {
            setPageStatus({ isSuccess: true, error: "", loading: false });
            dispatch(toggleEdit());
          } else {
            setPageStatus({
              isSuccess: false,
              error: response.data?.message?.appStatusDescription,
              loading: false,
            });
          }
        })
        .catch((er) => {
          setPageStatus({
            isSuccess: false,
            error: er.message,
            loading: false,
          });
        });
    },
  });
  const handleUploadClick = (files: FileList | null) => {
    if (!files) return;

    const reader = new FileReader();
    reader.readAsDataURL(files[0]);

    setFileName(files[0].name);

    if (!isValidFileExtention(files[0].name)) {
      setfileError("Invalid file extension");
      return;
    }
    if (files[0].size > 2 * 1024 * 1024) {
      setfileError("File size too large");
    } else {
      formik.setFieldTouched("resourceInfo.fileName", false, false);
      setfileError("");

      setFileName(files[0].name);
      const fileModel: UploadResourceModel = {
        documentType: DocumentTypeEnum.Resume,
        file: files[0],
      };
      setIsUploading(true);
      UploadAttachment(fileModel)
        .then((res) => {
          formik.setFieldValue(
            "resourceInfo.resourceUrl",
            res.data.entity.absoluteUri
          );
          formik.setFieldValue(
            "resourceInfo.fileName",
            res.data.entity.fileName
          );
          formik.setFieldValue(
            "resourceInfo.newFileName",
            res.data.entity.newFileName
          );
        })
        .catch((e) => {
          console.log("error uploading file");
        })
        .finally(() => setIsUploading(false));
    }
  };
  const handlePaymentChange = (e: any, paymentType = null) => {
    formik.handleChange(e);
  };
  React.useEffect(() => {
    if (searchParams.get("verificationType") == "paymentDetails") {
      if (recruiterData?.paymentDetailsChangeRequest?.usaBilling) {
        const recruiterPayment =
          recruiterData?.paymentDetailsChangeRequest?.usaBilling;
        Object.keys(initialState).forEach((e) =>
          {
            const key = e as keyof typeof initialState;
          formik.setFieldValue(e, recruiterPayment[e]  ?? initialState[key])
          }
        );
        setFileName(recruiterPayment?.resourceInfo?.fileName);
        setformState(recruiterPayment);
        setIsChecked({ first: true, second: true });
      }
    } else {
      GetUSPaymentDetail(billingDetail.userId).then((response) => {
        const paymentDetails = response?.data?.entity;
        Object.keys(initialState).forEach((e) => {
          const key = e as keyof typeof initialState;
          if (paymentDetails?.hasOwnProperty(e)) {     

            formik.setFieldValue(
              e, 
              paymentDetails[e] ?? initialState[key]);

          } else {
            formik.setFieldValue(e, initialState[key]);
          }
        });
        setFileName(paymentDetails?.resourceInfo?.fileName);
        setformState(paymentDetails);
        setIsChecked({ first: true, second: true });
      });
    }
  }, [recruiterData]);
  return (
    <>
      <USAForm
        onChange={handlePaymentChange}
        onFileUpload={handleUploadClick}
        viewMode={UserAuth.Recruiter}
        editMode={editMode}
        billingDetail={billingDetail}
        formik={formik}
        pageStatus={pageStatus}
        setPageStatus={setPageStatus}
        fileError={fileError}
        fileName={fileName}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
        formState={formState}
        setfileError={setfileError}
        setFileName={setFileName}
        isFileUploading={isUploading}
      />
    </>
  );
};
