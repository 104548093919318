import { Typography } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../../../../models/interfaces";
import ApprovedIcon from "./../../../../../assets/images/Approved.svg";
import "./popup.scss";

interface Props {
  isOpen: boolean;
  mode?:string;
  userRole:number;
}
const JobUpdateSuccessPopup :React.FC<Props> = ({ isOpen, mode, userRole }) => {
  const navigate = useNavigate();

  const getTeamPath = (mode:string | undefined) => {
    if (mode === 'EDIT') {
      return '/team/jobs/all';
    } else {
      return '/team/jobs/pending';
    }
  };
  
  const getClientPath = (mode:string | undefined) => {
    if (mode === 'EDIT') {
      return '/client/jobs/all';
    } else {
      return '/client/jobs/pending';
    };
  };

  const _handlePopupNavigation = ()=>{
    const path = userRole === UserAuth.ClientTeamMember ? getTeamPath(mode) : getClientPath(mode);
    navigate(path);   
  }

  return (
    <div>
      <Dialog
        className="custom-client-popup"
        open={isOpen}
        onClose={_handlePopupNavigation}
        aria-labelledby="pop-up-title"
      >
        <DialogTitle style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={_handlePopupNavigation}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography style={{ textAlign: "center", marginBottom:"16px" }}>
          <img src={ApprovedIcon} alt="icon" className="popup-icon"/>
          </Typography>
          <Typography className="custom-popup-txt">
            {" "}
            Job updated successfully!
          </Typography>
          <Typography className="custom-popup-txt1">
          Job has been updated successfully, you can see the jobs in job<br/>
listing page.
          </Typography>
          <Typography className="custom-popup-txt2">
          </Typography>
          <Box
          style={{
            display:"flex",
            alignContent:"center",
            justifyContent:"center"
          }}
          >
          <Button
            type="submit"
            name="saveandcontinue"
            value="saveandcontinue"
            variant="contained"
            color="primary"
            onClick={_handlePopupNavigation}
          >
           { mode === 'EDIT' ? 'Go to Browse jobs now' : 'Go to Pending jobs'}
          </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default JobUpdateSuccessPopup;
