import React,{useEffect, useState} from "react";
import BannerText from "../Common/BannerText";
import HomeSearch from "./HomeSearchJobs";
import ConnectToSubmit from "./ConnectToSubmit";
import Subscribe from "./Subscribe";
import StaticFooter from "../Common/StaticFooter";
import FeaturedJobs from "./FeaturedJobs";
import { ThemeProvider } from "@mui/material/styles";
import staticPageTheme from "../../Shared/theme/staticPageTheme";
import IntroductionInfo from "./IntroductionInfo"
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import Cookies from 'universal-cookie';

export default function Home() {

  const [isWatchedVideo, setIsWatchedVideo] = useState(false);
  const [isPlayVideo, setIsPlayVideo] = useState(false);
  const [autoPlayVideo, setAutoPlayVideo] = useState(0);
  const cookies = new Cookies();

  useEffect(()=>{
    (getCookie('jobRialtoInfoVideoCookie') ? 
    setIsWatchedVideo(true) : setIsWatchedVideo(false))
  },[]);

  useEffect(()=>{
    setCookie('jobRialtoInfoVideoCookie',true);
  },[isPlayVideo]);

  const setCookie = (cookiename,value)=>{
    

    let expireDate = new Date();
    expireDate.setTime(expireDate.getTime() + (360*24*60*60*1000));

    cookies.set(cookiename, value, {
         path: '/',
         expires: expireDate,
         maxAge:3600,
         secure:true
        });

}

const getCookie = (cookiename)=>{
    return cookies.get(cookiename)
}


  return (
    <div data-testid="home-search">
      <ThemeProvider theme={staticPageTheme}>
      <BannerText bannerText="Your One Stop Recruitment Marketplace"/>
     { !isWatchedVideo && <IntroductionInfo
         setIsWatchedVideo = {setIsWatchedVideo}
         setIsPlayVideo = {setIsPlayVideo}
         autoPlayVideo = {autoPlayVideo}
      />
     }
      <HomeSearch />
      {/* <Recruiter /> */}
       
       <FeaturedJobs/>
      <ConnectToSubmit />
      <Subscribe />
      </ThemeProvider>
      {isWatchedVideo && 
      <Box className="helpInfoIcon">
        <Fab  aria-label="helpIcon"
          onClick={()=>{ setIsWatchedVideo(false)
            setIsPlayVideo(false);
            setAutoPlayVideo(1);
          }}
        >
         <SmartDisplayIcon className="PlayYoutubeInfoBtn"/>
         </Fab>
      </Box>
      }
      <StaticFooter />
    </div>
  );
}
