import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { PermissionsEnums } from "../../../../data/permission";
import { JobOriginIds } from "../../../../models/Job/JobDetailsModel";
import { UserAuth } from "../../../../models/interfaces";
import { CandidateSubmissionStatus } from "../../../../models/submission/CandidateSubmissionMasterStatusesModel";
import { ModuleComponent } from "../../../Common/Permission/ModuleComponent";
import "../../NewViewSubmission/styles.scss";

interface InterviewFeedbackDueButtonProps {
  row: any;
  userRole: any;
  currentjobDetails: any;
  setPopup: any;
  hasActiveEvent: any;
}

export default function InterviewFeedbackDueButton({
  row,
  userRole,
  currentjobDetails,
  setPopup,
  hasActiveEvent
} : InterviewFeedbackDueButtonProps) {

  const shouldRenderFeedbackDueButton =
    !hasActiveEvent(row?.interviewAvailabilities || []) &&
    currentjobDetails?.jobOriginId === JobOriginIds.jobRialto &&
    (row.finalStatusId === CandidateSubmissionStatus.InterviewScheduled ||
      row.finalStatusId === CandidateSubmissionStatus.InterviewRescheduled ||
      row.finalStatusId === CandidateSubmissionStatus.NextRoundOfInterview) &&
    (userRole === UserAuth.Client || userRole === UserAuth.ClientTeamMember);

  const handleFeedbackDueButtonClick = () => {
    if (shouldRenderFeedbackDueButton) {
      setPopup((popup: any) => ({ ...popup, interviewFeedbackDue: true }));
    }
  };

  return (
    <>
    {shouldRenderFeedbackDueButton && (
      <ModuleComponent moduleId={PermissionsEnums.InterviewFeedbackDue}>
        <Grid item>
          <Button
            className="submission-action-btn"
            variant="contained"
            color="primary"
            onClick={handleFeedbackDueButtonClick}
          >
            Interview Feedback Due
          </Button>
        </Grid>
      </ModuleComponent>
    )}
    </>
  );
}