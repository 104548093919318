export const SUPPORTED_FORMATS = [
    "image/phg",
    "image/bmp",
    "image/jpeg",
    "image/jpg",
    "image/png",
  ];

  export const SUPPORTED_FORMATS_IMAGE = [
    "image/jpeg",
    "image/jpg",
    "image/png",
  ];

 export const MAX_PHOTO_SIZE = 2000000;