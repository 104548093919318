import {
  Button,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { Form, Formik, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./PostingJobStyle.scss";
import AddJobValidationSchema from "./components/FormModel/AddJobValidationSchema";
import ApplicationProcessFormSection from "./components/FormSection/ApplicationProcessFormSection";
import CompanyInformationFormSection from "./components/FormSection/CompanyInformationFormSection";
import JobDetailsFormSection from "./components/FormSection/JobDetailsFormSection";
import JobTypeRateAssignmentFormSection from "./components/FormSection/JobTypeRateAssignmentFormSection";

// API services
import {
  addJob,
  editJob,
  getJobApplicationMethods,
  getJobMasterDomains,
  getJobPaymentModes,
  getJobRequireDegrees,
  getJobRequireDocuments,
  getJobTaxTerms,
  getJobTypes,
  getJobWorkAuthorization,
  getJobWorkAuthorizationById,
  getJobWorkExperience,
} from "../../../Services/jobService";
// Models
import { AxiosResponse } from "axios";
import moment from "moment";
import { GetClientById, GetCompanySizeList } from "../../../Services/clientService";
import { GetAllCurrencyList } from "../../../Services/masterCurrencyService";
import getLoggedInUserRole from "../../../Shared/utils/getLoggedInUserRole";
import getUserDetails from "../../../Shared/utils/getUserDetails";
import { PermissionsEnums } from "../../../data/permission";
import { JobApplicationMethod } from "../../../models/Job/JobApplicationMethod";
import { JobDomain } from "../../../models/Job/JobDomain";
import { JobRequiredDocument } from "../../../models/Job/JobRequiredDocument";
import { ClientUserInfoModel } from "../../../models/SystemUser/ClientInfo";
import { JobPostingState } from "../../../models/client/Job/AddJob";
import { jobPostingDetails } from "../../../models/client/Job/jobPostingDetails";
import { UserAuth } from "../../../models/interfaces";
import { AppState } from "../../../store/AppState";
import { ModuleComponent } from "../../Common/Permission/ModuleComponent";
import { CustomAlert } from "../../ui/CustomAlert";
import PreviewJobPopup from "./PreviewJobPopup";
import JobPostPublishSuccess from "./components/popup/JobPostPublishSuccess";
import JobPostSuccessPopup from "./components/popup/JobPostSuccessPopup";
import JobUpdateSuccessPopup from "./components/popup/JobUpdateSuccessPopup";
import PublishConfirmPopup from "./components/popup/PublishConfirmPopup";
import SaveConfirmPopup from "./components/popup/SaveAndContinueConfirm";
import UpdateConfirmPopup from "./components/popup/UpdateConfirmPopup";
import { ternary, valueOrDefault } from "../../../utils/complexityUtils";

interface JobPostingProps {
  jobPostingDetails?:jobPostingDetails;
  mode?:"EDIT"|"PENDING"|"ADD";
}

const PostingJobForm: React.FC<JobPostingProps> = (props) => {

  // RecruiterData is current logged user details
  const { recruiterData } = useSelector((state: any) => state.recruiter);
  const loggedUserRole = getLoggedInUserRole();

  const loggedUserStatus  = loggedUserRole === UserAuth.ClientTeamMember ? "Approved" :  recruiterData?.entity?.status;
  
  const loggedUserId = getUserDetails()?.id;
  const [displayPreview, setDisplayPreview] = useState(false);
  const addJobInstialState = useSelector(
    (state: AppState) => state.addJobState
  );

  const formRef = React.useRef<FormikProps<JobPostingState>>(null);

  // page states
  const [pageStatus, setPageStatus] = useState<any>({
    isSuccess: false,
    isError: false,
    message: "",
    loading: false,
  });

  const [jobMode, setJobMode] = useState<string>("");

  //popup state

  const [isSavePopup, setIsSavePopup] = useState<boolean>(false);
  const [isSaveSuccessPopup, setIsSaveSuccessPopup] = useState<boolean>(false);
  const [isUpdateConfirm, setIsUpdateConfirm] = useState<boolean>(false);
  const [isPublishConfirm, setIsPublishConfirm] = useState<boolean>(false);
  const [isPublishSuccessPopup, setIsPublishSuccessPopup] =
    useState<boolean>(false);

    const [isUpdateSuccessPopup, setIsUpdateSuccessPopup] =
    useState<boolean>(false);

  //master list states
  const [ClientUserDetails, SetClientUserDetails] = useState<ClientUserInfoModel>();
  const [jobDomainsList, setJobDomainsList] = useState<JobDomain[]>([]);
  const [jobRequireDegreesList, setJobRequireDegreesList] = useState<string[]>(
    []
  );
  const [jobTypesList, setJobTypesList] = useState<string[]>([]);
  const [jobTaxTermsList, setJobTaxTermsList] = useState<string[]>([]);
  const [jobPaymentModesListAll, setJobPaymentModesListAll] = useState<string[]>([]);
  const [jobPaymentModesList, setJobPaymentModesList] = useState<string[]>([]);
  const [jobWorkAuthorizationList, setJobWorkAuthorizationList] = useState<
    string[]
  >([]);
  const [jobApplicationMethodsList, setJobApplicationMethodsList] = useState<
    JobApplicationMethod[]
  >([]);
  const [jobRequireDocumentsList, setJobRequireDocumentsList] = useState<
    JobRequiredDocument[]
  >([]);

  const [jobCurrency, setJobCurrencyList] = useState<string[]>([]);
  const [jobSalaryRangeList, setJobSalaryRangeList] = useState<string[]>([]);
  const [jobWorkExperiencesList, setJobWorkExperiencesList] = useState<
    any[]
  >([]);


  const executeSequentially = async (
    functions: (() => Promise<AxiosResponse>)[],
    callback: (response: AxiosResponse) => any
  ): Promise<boolean> => {
    let allApiCallsCompleted = true;
  
    for (const func of functions) {
      try {
        const response = await func();
        callback(response);
      } catch (error) {
        console.error('Error executing API call:', error);
        allApiCallsCompleted = false;
      }
    }
  
    return allApiCallsCompleted;
  };

  function getSalaryRangeRate() : string {
    if (props?.jobPostingDetails?.jobType === "Full Time") {
      return props?.jobPostingDetails?.package?.packageRange?.range;
    } else if (props?.jobPostingDetails?.taxTerms === "C2C") {
      return valueOrDefault(props?.jobPostingDetails?.package?.packageRange?.ctcRate, '');
    } else {
      return valueOrDefault(props?.jobPostingDetails?.package?.packageRange?.w2Rate, '');
    }
  }


  const handleApiCompletion = async () => {
    const apiCalls = [
      _GetClientByIds,
      _GetJobDomains,
      _GetRequireDegrees,
      _GetJobTypes,
      _GetJobTaxTerms,
      _GetJobPaymentModes,
      _GetJobWorkAuthorization,
      _GetJobApplicationMethods,
      _GetJobRequireDocuments,
      _GetMasterCurrencyList,
      _GetJobSalaryRange,
      _GetJobWorkExperinecesList,
    
    ]; // Add more API call functions if needed
    
    const allApiCompleted = await executeSequentially(apiCalls, (response) => {
      // Process the response if needed
    });
    
    if (!allApiCompleted) {
      console.log("Not All API calls completed successfully");
    }

    // All API calls completed successfully

    // transform the required skill object to match request and response payload
    const transformedRequiredSkill = props?.jobPostingDetails?.manualData?.skills
    ? props?.jobPostingDetails?.manualData?.skills.map((item: any) => item.skill)
    : [];

    // transform for required degree object to match request and response payload
    type requiredDegree = {
      id: string;
      degreeName: string;
      // Other properties specific to requiredDegree
    };

    const transformedRequiredDegree: requiredDegree[] = props?.jobPostingDetails?.education
      ? props?.jobPostingDetails?.education.map((item: any) => ({
          id: item.jobId.toString(),
          degreeName: item.degreeName,
        }))
      : [];
    
    // Extracting the certificationsLicenses values from the array
    const certificationsAndLicenses = props?.jobPostingDetails?.manualData?.certificationsAndLicenses?.map((item:any) => item.certificationsLicenses);

    let jobStartDate = props?.jobPostingDetails?.jobStartDate || '';
    let jobEndDate = props?.jobPostingDetails?.jobEndDate || '';

    // set defaults instead of empty
    if (props?.mode === 'ADD')
    {
      let tempDate = moment().add(28, 'days').utc();
      jobEndDate = tempDate.toISOString();
      jobStartDate = tempDate.add(14, 'days').toISOString();
    }

    let salaryRangeRate = getSalaryRangeRate();
    
    formRef.current?.setValues({
      ...formRef.current?.values,
      jobDetails: {
        ...formRef.current?.values.jobDetails,
        jobTitle: valueOrDefault(props?.jobPostingDetails?.jobTitle, ''),
        jobDescription: valueOrDefault(props?.jobPostingDetails?.jobDesc, ''),
        jobDomain: valueOrDefault(props?.jobPostingDetails?.domain, ''),
        jobIndustry: valueOrDefault(props?.jobPostingDetails?.industry, ''),
        primarySkill: valueOrDefault(props?.jobPostingDetails?.jobPrimarySkill, ''),
        jobDepartment: valueOrDefault(props?.jobPostingDetails?.departmentName, ''),
        jobStartDate: jobStartDate,
        shiftTimingFrom: valueOrDefault(
          props?.jobPostingDetails?.shiftTimingFrom,
          formRef.current?.values?.jobDetails?.shiftTimingFrom
        ),
        shiftTimingTo: valueOrDefault(
          props?.jobPostingDetails?.shiftTimingTo,
          formRef.current?.values?.jobDetails?.shiftTimingTo
        ),
        workingHours: valueOrDefault(props?.jobPostingDetails?.workingHours, undefined),
        numberOfOpenings: valueOrDefault(props?.jobPostingDetails?.numberOfOpening, undefined),
        experience: valueOrDefault(props?.jobPostingDetails?.jobExperience, undefined),
        experienceId: valueOrDefault(props?.jobPostingDetails?.experianceId, ''),
        requiredDegree: transformedRequiredDegree[0],
        desiredCertifications: certificationsAndLicenses,
        state: valueOrDefault(props?.jobPostingDetails?.stateName, ''),
        city: valueOrDefault(props?.jobPostingDetails?.city, ''),
        zipCode: valueOrDefault(props?.jobPostingDetails?.zipCode, ''),
        countryName:valueOrDefault(props?.jobPostingDetails?.countryName, ''),
        countryCode:valueOrDefault(props?.jobPostingDetails?.countryCode, ''),
        requiredSkills: transformedRequiredSkill,
        isRemoteJob: ternary(props?.jobPostingDetails?.remoteJob === "y", true, false),
      },
      jobTypeAndRate: {
        jobTypeId: valueOrDefault(props?.jobPostingDetails?.jobTypeId, ''),
        jobType: valueOrDefault(props?.jobPostingDetails?.jobType, ''),
        jobTaxTermId: valueOrDefault(props?.jobPostingDetails?.jobTaxTermId, ''),
        taxTerms: valueOrDefault(props?.jobPostingDetails?.taxTerms, ''),
        jobDuraion: valueOrDefault(
          ternary(props?.jobPostingDetails?.jobDuraion !=="", props?.jobPostingDetails?.jobDuraion, 0),
          formRef.current?.values?.jobTypeAndRate?.jobDuraion
        ),
        billRate: valueOrDefault(
          props?.jobPostingDetails?.billRatePckg?.billRate,
          formRef.current?.values?.jobTypeAndRate?.billRate
        ),
        billRateCurrencyType: valueOrDefault(
          props?.jobPostingDetails?.billRatePckg?.packageCurrency,
          formRef.current?.values?.jobTypeAndRate?.billRateCurrencyType
        ),
        salaryRange: salaryRangeRate,
        salaryRangeCurrencyType: valueOrDefault(
          props?.jobPostingDetails?.package?.packageCurrency,
          formRef.current?.values?.jobTypeAndRate?.salaryRangeCurrencyType
        ),
        paymentMode: valueOrDefault(props?.jobPostingDetails?.paymentMode, ''),
        jobWorkAuthorization: valueOrDefault(props?.jobPostingDetails?.jobWorkAuthorization, []),
        isRateNigotiable: ternary(props?.jobPostingDetails?.rateNegotiable === "y", true, false),
      },
      applicationProcess: {
        jobEndDate: jobEndDate,
        applicationMethod: valueOrDefault(
          props?.jobPostingDetails?.applicationMethod,
          formRef.current?.values?.applicationProcess?.applicationMethod
        ),
        requiredDocuments: valueOrDefault(props?.jobPostingDetails?.requiredDocuments, []),
        applicationInstructions: valueOrDefault(props?.jobPostingDetails?.applicationInstructions, ""),
      }, 
    });
  }

  useEffect(() => {
    handleApiCompletion();
  }, []);

useEffect(()=>{
  let jobPaymentModesListFilter;
  
  if(props?.jobPostingDetails?.jobType)
  {
    if(props?.jobPostingDetails?.jobType === "Full Time"){
      jobPaymentModesListFilter = jobPaymentModesListAll.filter((option:any)=>
      option.id ==="e3f1acf4-cb51-419f-a3f9-c3a76d7d3f34"
      );
      setJobPaymentModesList(jobPaymentModesListFilter)
      
    }else{
      jobPaymentModesListFilter = jobPaymentModesListAll.filter((option:any)=>
      option.id !=="e3f1acf4-cb51-419f-a3f9-c3a76d7d3f34"
      );
      setJobPaymentModesList(jobPaymentModesListFilter)
    }
  }
},[jobPaymentModesListAll])
  // useEffect to set on client login details
  useEffect(() => {
    formRef.current?.setValues({
      ...formRef.current?.values,
      companyInformation: {
        ...formRef.current?.values?.companyInformation,
        companyId: valueOrDefault(ClientUserDetails?.id, ""),
        companyName: valueOrDefault(ClientUserDetails?.companyName, ""),
        companyEmail: valueOrDefault(ClientUserDetails?.email, ""),
        clientPhone: valueOrDefault(ClientUserDetails?.contactDetails?.primaryPhoneNumber, "")
      },      
    })
  }, [ClientUserDetails]);

  useEffect(() => {
    if (props?.jobPostingDetails?.experianceId) {
      let selectedWorkExperience = jobWorkExperiencesList.find(
        (n) => n.id === props?.jobPostingDetails?.experianceId
      );
      formRef.current?.setFieldValue(
        "jobDetails.experienceText",
        selectedWorkExperience?.experience
      );
    }
  }, [jobWorkExperiencesList]);

  const handleCustomChange = (selectedTaxtermId: string) => {   

    _GetJobWorkAuthorizationById(selectedTaxtermId);
  };

  const _handleCustomJobTypeChange = (jobType:string)=>{
      formRef.current?.setFieldValue("jobTypeAndRate.taxTerms","");
      formRef.current?.setFieldValue("jobTypeAndRate.jobWorkAuthorization",[]);
      if(jobType === "Full Time"){
        let jobPaymentModesListFullTime = jobPaymentModesListAll.filter((option:any)=>
        option.id ==="e3f1acf4-cb51-419f-a3f9-c3a76d7d3f34"
        );        
        setJobPaymentModesList(jobPaymentModesListFullTime)
        formRef.current?.setFieldValue("jobTypeAndRate.paymentMode",jobPaymentModesListFullTime.length > 0 ? jobPaymentModesListFullTime[0].paymentMode : "");
        formRef.current?.setFieldValue("jobTypeAndRate.jobDuraion","");
      }else{
        let jobPaymentModesListOthers = jobPaymentModesListAll.filter((option:any)=>
        option.id !=="e3f1acf4-cb51-419f-a3f9-c3a76d7d3f34"
        );
        setJobPaymentModesList(jobPaymentModesListOthers)
        formRef.current?.setFieldValue("jobTypeAndRate.paymentMode","");
        formRef.current?.setFieldValue("jobTypeAndRate.salaryRange","");
      }
      _GetJobWorkAuthorization();
  }

  // GET Master domains
  const _GetJobDomains = async () => {
    getJobMasterDomains()
      .then((response: any) => {
        if (response?.data?.message?.appStatusCode === "RC_RSF") {
          setJobDomainsList(response?.data?.entityList);
        } else {
          setJobDomainsList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  // GET Master Skills
  const _GetRequireDegrees = async () => {
    getJobRequireDegrees()
      .then((response: any) => {
        if (response?.data?.message?.appStatusCode === "RC_RSF") {
          setJobRequireDegreesList(response?.data?.entityList);
        } else {
          setJobRequireDegreesList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // GET Master GetJobTypes
  const _GetJobTypes = async () => {
    getJobTypes()
      .then((response: any) => {
        if (response?.data?.message?.appStatusCode === "RC_RSF") {
          setJobTypesList(response?.data?.entityList);
        } else {
          setJobTypesList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // GET Master GetJobTaxTerms
  const _GetJobTaxTerms = async () => {
    getJobTaxTerms()
      .then((response: any) => {
        if (response?.data?.message?.appStatusCode === "RC_RSF") {
          setJobTaxTermsList(response?.data?.entityList);
        } else {
          setJobTaxTermsList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // GET Master GetJobPaymentModes
  const _GetJobPaymentModes = async () => {
    getJobPaymentModes()
      .then((response: any) => {
        if (response?.data?.message?.appStatusCode === "RC_RSF") {
          setJobPaymentModesList(response?.data?.entityList);
          setJobPaymentModesListAll(response?.data?.entityList);
        } else {
          setJobPaymentModesList([]);
          setJobPaymentModesListAll([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // GET Master GetJobWorkAuthorization
  const _GetJobWorkAuthorization = async () => {
    getJobWorkAuthorization()
      .then((response: any) => {
        if (response?.data?.message?.appStatusCode === "RC_RSF") {
          setJobWorkAuthorizationList(response?.data?.entityList);
        } else {
          setJobWorkAuthorizationList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // GET Master GetJobApplicationMethods
  const _GetJobApplicationMethods = async () => {
    getJobApplicationMethods()
      .then((response: any) => {
        if (response?.data?.message?.appStatusCode === "RC_RSF") {
          setJobApplicationMethodsList(response?.data?.entityList);
        } else {
          setJobApplicationMethodsList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // GET Master JobRequireDocuments
  const _GetJobRequireDocuments = async () => {
    getJobRequireDocuments()
      .then((response: any) => {
        if (response?.data?.message?.appStatusCode === "RC_RSF") {
          setJobRequireDocumentsList(response?.data?.entityList);
        } else {
          setJobRequireDocumentsList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // GET get job work authorization by id
  const _GetJobWorkAuthorizationById = async (id: string) => {
    getJobWorkAuthorizationById(id)
      .then((response: any) => {
        if (response?.data?.message?.appStatusCode === "RC_RSF") {
          let jobWorkAuthorizationList: string[] =
            response?.data?.entityList?.map(
              (item: any) => item.workAuthorization
            );
            setJobWorkAuthorizationList(response?.data?.entityList)
            
          formRef.current?.setFieldValue(
            "jobTypeAndRate.jobWorkAuthorization",
            jobWorkAuthorizationList
          );
          
        } 
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Get Master Currency List
  const _GetMasterCurrencyList = () => {
    GetAllCurrencyList()
      .then((response: any) => {
        if (response?.data?.message?.appStatusCode === "RC_RSF") {
          setJobCurrencyList(response?.data?.entityList);
        } else {
          setJobCurrencyList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //get salary range size list
  const _GetJobSalaryRange = () => {
    const payload = {
      maxValue: 50000,
      rangeValue: 10000,
    };
    GetCompanySizeList(payload)
      ?.then((response) => {
        if (response?.data?.message?.appStatusCode === "CL_RSF") {
          setJobSalaryRangeList(response?.data?.entityList);
        } else {
          setJobSalaryRangeList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Get Master Job work experiences
  const _GetJobWorkExperinecesList = () => {
    getJobWorkExperience()
      .then((response: any) => {
        if (response?.data?.message?.appStatusCode === "RC_RSF") {
          setJobWorkExperiencesList(response?.data?.entityList);
        } else {
          setJobWorkExperiencesList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Get client company information details
  const _GetClientByIds = ()=>{   
    
    const clientId = valueOrDefault(getUserDetails()?.clientId, "");

    GetClientById(clientId)
    .then((response: any) => {
      if (response?.data?.message?.appStatusCode === "CL_RSF") {
        SetClientUserDetails(response?.data?.entity);
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }
    

  function handleEditJob(response: any) {
    if (response?.data?.message?.appStatusCode === "RC_RSF") {
      if (jobMode === "UPDATE") {
        setIsUpdateSuccessPopup(true);
      }else{
        setIsPublishSuccessPopup(true);
      }
      
      setPageStatus({
        ...pageStatus,
        isSuccess: true,
        isError: false,
        message: "",
      });
    } else {
      setPageStatus({
        ...pageStatus,
        isSuccess: false,
        isError: true,
        message: response?.data?.message?.appStatusDescription,
      });
    }
  }

  function handleAddJob(response: any) {
    if (response?.data?.message?.appStatusCode === "RC_RSF") {
      if (jobMode === "SAVE") {
        setIsSaveSuccessPopup(true);
      } else {
        setIsPublishSuccessPopup(true);
      }
      
      setPageStatus({
        ...pageStatus,
        isSuccess: true,
        isError: false,
        message: "",
      });
    } else {
      setPageStatus({
        ...pageStatus,
        isSuccess: false,
        isError: true,
        message: response?.data?.message?.appStatusDescription,
      });
    }
  }

  // publish, save and continue new job

  const _AddJobSubmit = async (payload: JobPostingState) => {
    //send the logged client id in payload
    
    const updatedPayload = {
      ...payload,
      publishJob: ((jobMode === "UPDATE" && props?.mode ==="EDIT") || (jobMode === "PUBLISH")) ? true : false,
      clientId: loggedUserRole === UserAuth.ClientTeamMember? recruiterData?.entity?.clientId  : recruiterData?.entity?.id,
      addedById: recruiterData?.entity?.id,
      jobDetails:{
        ...payload.jobDetails,
        requiredDegree:[{...payload.jobDetails?.requiredDegree}]
      },
      jobTypeAndRate:{
        ...payload.jobTypeAndRate,
        jobDuraion: !Number.isNaN(Number(payload.jobTypeAndRate?.jobDuraion?.toString())) ? Number(payload.jobTypeAndRate?.jobDuraion) : 0
      },
      companyInformation: {
        ...addJobInstialState.companyInformation,
        companyId: ClientUserDetails?.id,
        companyName: ClientUserDetails?.companyName,
        companyEmail: ClientUserDetails?.email,
        industry: ClientUserDetails?.industries[0]?.industry,
        clientPhone: valueOrDefault(ClientUserDetails?.contactDetails?.primaryPhoneNumber, "")
      },
    };

    setPageStatus({
      ...pageStatus,
      isSuccess: false,
      isError: false,
      message: "",
    });

    if(props?.mode ==="EDIT" || props?.mode ==="PENDING"){
      
      const modifiedPayloadForEdit = {
        ...updatedPayload,
        id: props?.jobPostingDetails?.id,
        jobId: props?.jobPostingDetails?.jobId,
        jobStatus: props?.jobPostingDetails?.jobStatus,
        postingDate: props?.jobPostingDetails?.postingDate,
        modifiedById: loggedUserId,
        clientId: props?.jobPostingDetails?.clientId,
        addedById: props?.jobPostingDetails?.addedById,
      }

      await editJob(modifiedPayloadForEdit)
        .then((response: any) => {
          handleEditJob(response);
        })
        .catch((error) => {
          console.log(error);
          setPageStatus({
            ...pageStatus,
            isSuccess: false,
            isError: true,
            message: "Something Went wrong!!!",
          });
        });

    }else{

        await addJob(updatedPayload)
          .then((response: any) => {
            handleAddJob(response);
          })
          .catch((error) => {
            console.log(error);
            setPageStatus({
              ...pageStatus,
              isSuccess: false,
              isError: true,
              message: "Something Went wrong!!!",
            });
          });
    }
  };

  const _handleSave = () => {
    setJobMode("SAVE");

    setIsSavePopup(true);
  };

  const _handleUpdate = () => {
    setJobMode("UPDATE");
    formRef.current?.handleSubmit();
  };

  const _handlePublish = () => {
    setJobMode("PUBLISH");
    formRef.current?.handleSubmit();
  };

  const _handleSavePopup = () => {
    setIsSavePopup(false);
    formRef.current?.handleSubmit();
  };

  const _handleUpdateConfirmation = ()=>{
    setIsUpdateConfirm(false);
    _handleUpdate();
  }

  const _handlePublishConfirmation = ()=>{
    setIsPublishConfirm(false);
    _handlePublish();
  }

  function _handleClosePopup() {
    setIsSavePopup(false);
    setJobMode("");
  }

  const handlePreview = (display: boolean) => {
    setDisplayPreview(display);
  };

  const _handlePublishButton = ()=>{
    setJobMode('PUBLISH');
    setIsPublishConfirm(true);
  }

  const _handleUpdateButton = ()=>{
    setJobMode("UPDATE");
    setIsUpdateConfirm(true);
  }

  const validateFormik = async (values: JobPostingState) => {
    const errors: Record<string,any> = {};
    let isPublishing = ((jobMode === "UPDATE" && props?.mode ==="EDIT") || (jobMode === "PUBLISH"));
    
    if (isPublishing) {
      let startDate = moment(values.jobDetails.jobStartDate).startOf('day');
      let endDate = moment(values.applicationProcess.jobEndDate).startOf('day');
      let publishDate = moment().startOf('day'); // as this is being published right now
      
      if (startDate.diff(endDate, 'days') < 14) {
        errors.jobDetails = {jobStartDate: "Job Start Date should be at least 14 days after Job End Date."};
      }

      if (endDate.diff(publishDate, 'days') < 28) {
        errors.applicationProcess = {jobEndDate: "Job End Date should be at least 28 days after Job Publish Date."};
      }

      if (startDate.diff(publishDate, 'days') < 0) {
        errors.jobDetails = {jobStartDate: "Job Start Date cannot be less than Job Publish Date."};
      }

      if (endDate.diff(publishDate, 'days') < 0) {
        errors.applicationProcess = {jobEndDate: "Job End Date cannot be less than Job Publish Date."};
      }
    }

    return errors;
  };


  return (
    <>
      <Grid container mb={8}>
        <Grid item xs={12} p={1}>
          <Paper
            elevation={0}
            style={{ padding: "0px 8px 8px 8px", background: "#fff" }}
          >
            <Formik
              initialValues={addJobInstialState}
              validate={validateFormik}
              validationSchema={AddJobValidationSchema}
              onSubmit={(values, actions) => {             
                _AddJobSubmit(values);
              }}
              innerRef={formRef}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                setValues,
                setFieldTouched,
                values,
                touched,
                errors,
                isValid,
                isSubmitting
              }) => (
                <Form onSubmit={handleSubmit} noValidate>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {pageStatus?.isError && (
                        <CustomAlert
                          key={21}
                          message={(pageStatus?.message  !=="" )|| ( pageStatus?.message === undefined) ? pageStatus?.message : "Something went wrong!!!"}
                          type={"error"}
                          onClose={()=>{}}
                        />
                      )}
                      
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} style={{display:"flex",alignItems:"flex-end"}}>
                      <Typography variant="h5" gutterBottom className="postingJobFormHeading">
                        Job Details
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                      <Grid
                        container
                        direction="row"
                        spacing={2}
                        justifyContent={"flex-end"}
                      >
                        <Grid item>
                          <Button
                            disabled={loggedUserStatus !== "Approved"}
                            className="postingJobButton" 
                            variant="outlined"
                            color="primary"
                            onClick={() => handlePreview(true)}
                          >
                            Preview
                          </Button>
                        </Grid>
                        
                        {props?.mode ==="ADD" &&
                        <Grid item>
                          <ModuleComponent moduleId={PermissionsEnums.Jobs_Add_Save}>
                            <Button
                              data-testid="saveandcontinue"
                              disabled={loggedUserStatus !== "Approved" || isSubmitting}
                              endIcon={isSubmitting ? <CircularProgress
                               size={"16px"}
                               color={"inherit"}
                             />:""}
                              className="postingJobButton"
                              type={undefined}
                              name="saveandcontinue"
                              value="saveandcontinue"
                              variant="outlined"
                              color="primary"
                              onClick={(event: any) => {
                                event?.preventDefault();
                                if(isValid){
                                  _handleSave();
                                }else{
                                  handleSubmit();
                                }
                               
                              }}
                            >
                              Save and Continue Later
                            </Button>
                          </ModuleComponent>
                        </Grid> 
                          }
                          { (props?.mode ==="EDIT" || props?.mode ==="PENDING") && 

                          <Grid item>
                          <ModuleComponent moduleId={props?.mode=="PENDING"? PermissionsEnums.Jobs_Pending_Publish_Save:''}>
                            <Button
                             data-testid="update"
                             disabled={loggedUserStatus !== "Approved" || isSubmitting}
                             endIcon={isSubmitting && <CircularProgress
                              size={"16px"}
                              color={"inherit"}
                            />}
                             className="postingJobButton"
                             type={undefined}
                             name="update"
                             value="udpate"
                             variant="contained"
                             onClick={() => {
                               _handleUpdateButton();                             
                             }}
                            >
                               Update
                            </Button>
                          </ModuleComponent>
                          </Grid>
                        }
                        { (props?.mode === "ADD" || props?.mode === "PENDING") && 
                        <ModuleComponent moduleId={props?.mode ==="PENDING"? PermissionsEnums.Jobs_Pending_Publish_Publish : PermissionsEnums.Jobs_Add_Publish}>
                          <Grid item>
                            <Button
                              data-testid="publish"
                              disabled={loggedUserStatus !== "Approved"}
                              className="postingJobButton"
                              type={undefined}
                              name="publish"
                              value="publish"
                              variant="contained"
                              color="primary"
                              onClick={() => {                              
                              if(isValid){
                                _handlePublishButton();
                              }else{
                                handleSubmit();
                              }                            
                            }}
                          >
                             Publish
                            </Button>
                          </Grid>
                        </ModuleComponent>
                        }
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} mt={1}>
                    <Grid item xs={12} sm={12}>
                      <JobDetailsFormSection
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        values={values}
                        touched={touched}
                        errors={errors}
                        jobDomainsList={jobDomainsList}
                        jobRequireDegreesList={jobRequireDegreesList}
                        jobWorkExperiencesList={jobWorkExperiencesList}
                        mode={props?.mode}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <JobTypeRateAssignmentFormSection
                        handleChange={handleChange}
                        handleCustomChange={handleCustomChange}
                        _handleCustomJobTypeChange={_handleCustomJobTypeChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        values={values}
                        touched={touched}
                        errors={errors}
                        jobTypesList={jobTypesList}
                        jobTaxTermsList={jobTaxTermsList}
                        jobCurrency={jobCurrency}
                        jobPaymentModesList={jobPaymentModesList}
                        jobWorkAuthorizationList={jobWorkAuthorizationList}
                        jobSalaryRangeList={jobSalaryRangeList}
                        mode={props?.mode}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <ApplicationProcessFormSection
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        setValues={setValues}
                        values={values}
                        touched={touched}
                        errors={errors}
                        jobApplicationMethodsList={jobApplicationMethodsList}
                        jobRequireDocumentsList={jobRequireDocumentsList}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <CompanyInformationFormSection
                        clientLoginData={ClientUserDetails}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      {/* Confiramtion dialog for save and continue action */}
                      <SaveConfirmPopup
                        isOpen={isSavePopup}
                        handleSave={_handleSavePopup}
                        handleClose={_handleClosePopup}
                        type={"AddJob"}
                        title={"AddJob"}
                      />
                      {/* Confiramtion dialog for update action on edit case */}
                      <SaveConfirmPopup
                        isOpen={isSavePopup}
                        handleSave={_handleSavePopup}
                        handleClose={_handleClosePopup}
                        type={"AddJob"}
                        title={"AddJob"}
                      />
                      {/* After job posting saved job success popup */}
                      <JobPostSuccessPopup
                        isOpen={isSaveSuccessPopup}
                        userRole={loggedUserRole}
                      />
                      {/* Edit case is update confirm popup dialog */}
                      <UpdateConfirmPopup
                        isOpen={isUpdateConfirm}
                        handleClose={()=>{ setIsUpdateConfirm(false)}}
                        type={"saveJob"}
                        title={"saveJob"}
                        handleUpdateConfirmation={_handleUpdateConfirmation}
                        isSubmitting={isSubmitting}
                      />

                      {/* Edit case is update confirm popup dialog */}
                      <PublishConfirmPopup
                        isOpen={isPublishConfirm}
                        handleClose={()=>{ setIsPublishConfirm(false)}}
                        type={"saveJob"}
                        title={"saveJob"}
                        handlePublishConfirmation={_handlePublishConfirmation}
                      />

                      {/* After job posting publish success popup */}
                      <JobPostPublishSuccess
                        isOpen={isPublishSuccessPopup}
                        userRole={loggedUserRole}
                      />

                  {/* After job update  success popup */}
                      <JobUpdateSuccessPopup
                        isOpen={isUpdateSuccessPopup}
                        mode={props?.mode}
                        userRole={loggedUserRole}
                      />

                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Paper>
        </Grid>
      </Grid>

      <PreviewJobPopup
        job={formRef.current?.values}
        display={displayPreview}
        onClose={() => handlePreview(false)}
        onPublish={_handlePublishButton}
        clientLoginData={ClientUserDetails}
        onSave={_handleSave}
        mode={props.mode}
        onUpdate={()=>{
          _handleUpdateButton();
        }}
      ></PreviewJobPopup>
    </>
  );
};

export default PostingJobForm;
