import * as React from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";



const Item = styled(Paper)(({ theme } : any) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  boxShadow: "none",
  initComment: {
    padding: "10px",
    marginLeft: 35,
    paddingLeft: 15,
  },

  lastComment: {
    padding: "10px",
    marginLeft: 35,
    paddingLeft: 15,
  },
}));

interface IPROPS{
    recruiterDetails:any
}

export const ViewHearAboutUs: React.FunctionComponent<IPROPS> = ({recruiterDetails}) => {  

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Box>
            { recruiterDetails?.hearAboutUs?.option !=="" &&
          <Grid item xs={12} md={10} mt={2}>
            <Item style={{ padding: "0px" }} elevation={0}>
            <Typography
                  variant="subtitle1"
                  component="div"
                  className="verify-rec-form-heading"
                >
                  How Did You Hear About Us?
                </Typography>
                <Typography
                  className="inp-color verify-rec-form-value"
                  >
                    {recruiterDetails?.hearAboutUs?.option || "N/A"}
                  </Typography>
            </Item>
          </Grid>
          }
          {(recruiterDetails?.hearAboutUs?.id ==="f1ea0b28-50d7-4164-80d6-0f503a03d872" ) &&
          <Grid item xs={12} md={10} mt={2}>
            <Item style={{ padding: "0px" }} elevation={0}>
            <Typography
                  variant="subtitle1"
                  component="div"
                  className="verify-rec-form-heading"
                >
                  Which Platform?
                </Typography>
                <Typography
                  className="inp-color verify-rec-form-value"
                  >
                      {recruiterDetails?.hearAboutUs?.hearAboutUsSubOption?.option || "N/A"}
                  </Typography>
            </Item>
          </Grid>
          }
          { (recruiterDetails?.hearAboutUs?.id ==="8dcb0133-6963-4308-bb5e-c5a0c98819b2" ||
            recruiterDetails?.hearAboutUs?.hearAboutUsSubOption?.id === "bb6b3251-ce9c-4d76-9cab-64cf0478312d") &&
              <Grid item xs={12} md={10} mt={2}>
                <Item style={{ padding: "0px" }} elevation={0}>
                <Typography
                      variant="subtitle1"
                      component="div"
                      className="verify-rec-form-heading"
                    >
                      Other
                    </Typography>
                    <Typography
                      className="inp-color verify-rec-form-value"
                      >
                        {recruiterDetails?.hearAboutUs?.otherSpecification?.specifyOther || "N/A"}
                      </Typography>
                </Item>
              </Grid>
              }
          </Box>
            </Grid>
      </Grid>
      
    </>
  );
};

export default ViewHearAboutUs;
