import { createSvgIcon } from "@mui/material/utils";

export const RejectIcon = createSvgIcon(
<svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="8" cy="8" r="8" fill="#E2445C"/>
<path d="M4.30762 4.30859L11.6922 11.6932" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.6924 4.30859L4.30777 11.6932" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    ,
  "RejectIcon"
);