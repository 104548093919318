import {
  Alert,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
// Icons
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

import { useNavigate } from "react-router-dom";
import {
  downloadFile,
  getFilePreviewUrl,
  getNevLink,
} from "../../../../utils/getFileFromUrl";
import { PhoneNumber } from "../../../ui/PhoneNumber";

import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

import { SortType } from "../../../../models/app/AppModels";
import AppSort from "../../../Common/AppSort";
import { Fullname } from "../../../Common/Fullname";

import getLoggedInUserRole from "../../../../Shared/utils/getLoggedInUserRole";
import { PermissionsEnums } from "../../../../data/permission";
import { UserAuth } from "../../../../models/interfaces";
import { getTextNameEllipse } from "../../../../utils/StringUtil";
import constructName from "../../../../utils/constructName";
import { ModuleComponent } from "../../../Common/Permission/ModuleComponent";
import PreviewFileDialog from "../../../Common/PreviewFileDialog";
import ViewRecruiterInfoPopup from "../../../ViewSubmissions/NewViewSubmission/Popups/ViewRecruiterInfoPopup";
import { valueOrDefault } from "../../../../utils/complexityUtils";
import GenericPagination from "../../../Common/Table/GenericPagination";
import StringWithTooltip from "../../../Common/ToolTip/StringWithTooltip";
import { CandidateListModel } from "../../../../models/Candidate/CandidateListModel";
import convertToLocalizedDateTime from "../../../../utils/AppDateUtils";
interface CandidateListTableProps {
  totalRecordCount:number;
  CandidateListState:CandidateListModel,
  candidatesData: Array<[]>;
  handlePaginationIndex:(index:number) => void;
  isLoading: boolean;
  handleSortChange: (sort: boolean, sortColumn: string) => void;
}

const CandidateTableList: React.FC<CandidateListTableProps> = ({
  CandidateListState,
  totalRecordCount,
  candidatesData,
  handlePaginationIndex,
  isLoading,
  handleSortChange,
}) => {
  const navigate = useNavigate();
  const [sort, setSort] = useState<boolean>(true);
  const [sortColumnName, setSortColumnName] = useState<string>("createdDate");
  const [filePreview, setFilePreview] = React.useState<any>(undefined);
  const userRole = getLoggedInUserRole();
  const [isOpenPopup, setIsOpenPopup] = React.useState(false);
  const [recruiterId, setRecruiterId] = React.useState(false);

  // Pagination state
  const [rowsPerPage] = useState(CandidateListState?.pageSize);
  const handleChangePage = (event: unknown, newPage: number) => {    
    handlePaginationIndex(newPage);
  };

  const handleViewRecruiterBtn = (event: any, viewRecruiterId: any) => {
    setRecruiterId(viewRecruiterId);
    setIsOpenPopup(true);
  };
  const handleClose = () => {
    setIsOpenPopup(false);
  };
  // Handle sort
  const handleSortClicked = (sortColumnName: string) => {
    handleSortChange(!sort, sortColumnName);
    setSort(!sort);
    setSortColumnName(sortColumnName);
  };

  return (
    <>
      <TableContainer component={Paper}> 
          <GenericPagination
            count={totalRecordCount}
            rowsPerPage={rowsPerPage}
            page={CandidateListState?.pageIndex}
            onPageChange={handleChangePage}
          />     
          <Table className="AppTable candidateListTable">
            <TableHead className="custom-Client-fontsizes">
              <TableRow key={"hedercandidate"}>
                {userRole === UserAuth.Admin ||
                userRole === UserAuth.SuperAdmin ? (
                  <TableCell style={{ minWidth: 130 }}>
                    Recruiter Name
                  </TableCell>
                ) : (
                  ""
                )}
                <TableCell
                  key="Name"
                  className="nameTableHead"
                  data-testid="name-sort-column"
                  onClick={() => {
                    handleSortClicked("name");
                  }}
                >
                  <Grid container>
                    <Grid className="custom-Client-fontsizes pointer">
                      Name
                    </Grid>
                    <Grid>
                      <AppSort
                        sortType={
                          sort ? SortType.Ascending : SortType.Descending
                        }
                        disable={sortColumnName !== "name"}
                      ></AppSort>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell key="Email">Email</TableCell>
                <TableCell key="Location"> Location</TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }} key="TotalExp">
                  Total Exp
                </TableCell>
                <TableCell key="Phone"> Phone</TableCell>
                <TableCell key="Resume"> Resume</TableCell>
                <TableCell key="Date">Date</TableCell>
                <TableCell key="Details" className="actionTableHead">
                  Details
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {candidatesData?.length > 0
                ? candidatesData.map((candidate: any, candidateIndex: any) => (
                    <TableRow
                      key={candidate?.id ?? candidateIndex}
                      className="hover-row v2-table-row"
                    >
                      {userRole === UserAuth.Admin ||
                      userRole === UserAuth.SuperAdmin ? (
                        <TableCell
                          component="th"
                          scope="row"
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            paddingBottom: "0 !important",
                            paddingRight: "0 !important",
                          }}
                        >
                          <span
                            className="cust-recruiter-text"
                            style={{
                              textDecorationLine: "underline",
                              cursor: "pointer",
                            }}
                            onClick={(e) =>
                              handleViewRecruiterBtn(
                                e,
                                candidate?.recruiterGuid
                              )
                            }
                            data-testid="span-candidatetable-viewrecruiter"
                          >
                            <StringWithTooltip 
                              className=""
                              text={constructName(
                                candidate?.recruiterFirstName,
                                candidate?.recruiterMiddleName,
                                candidate?.recruiterLastName
                              )}
                             />
                            
                          </span>
                        </TableCell>
                      ) : (
                        ""
                      )}
                      <TableCell>
                        <Fullname
                          fullName={valueOrDefault(
                            candidate?.firstName + " " + candidate?.lastName,
                            ""
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <div className="emailCell">
                          <StringWithTooltip 
                              className=""
                              maxEllipseTextSize={30}
                              text={candidate?.email ?? "N/A"}
                             />
                          <CheckCircleRoundedIcon
                            className="verifi-success-icon"
                            sx={{
                              marginLeft: "2px",
                              fontSize: "14px",
                            }}
                          />
                        </div>
                      </TableCell>
                      <TableCell>
                        {candidate.address?.city +
                          ", " +
                          candidate.address?.state}
                      </TableCell>
                      <TableCell>{candidate?.experience + " years"}</TableCell>
                      <TableCell
                        sx={{
                          whiteSpace: "nowrap",
                          paddingBottom: "0 !important",
                          paddingRight: "0 !important",
                        }}
                        data-testid="phone"
                      >
                        <div data-testid="phone" className="phoneCell">
                          <PhoneNumber
                            data-testid="phone"
                            number={candidate?.phone ?? "N/A"}
                          />

                          {candidate?.isPhoneVerified === true && (
                            <CheckCircleRoundedIcon
                              className="verifi-success-icon"
                              sx={{
                                marginLeft: "2px",
                                fontSize: "14px",
                              }}
                            />
                          )}
                        </div>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          flexWrap: "wrap",
                          paddingBottom: "0 !important",
                          paddingRight: "0 !important",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <Tooltip
                            title={candidate?.resumeInfo?.fileName ?? ""}
                          >
                            <div style={{ display: "flex" }}>
                              {candidate?.resumeInfo?.resourceUrl !==
                                undefined &&
                                candidate?.resumeInfo?.resourceUrl !== "" && (
                                  <>
                                    <VisibilityOutlinedIcon
                                      data-testid="submission-submit"
                                      onClick={() => {
                                        getFilePreviewUrl(
                                          candidate?.resumeInfo?.resourceUrl
                                        ).then((filePreviewUrl: any) =>
                                          setFilePreview({
                                            resourceUrl: filePreviewUrl,
                                            fileName:
                                              candidate?.resumeInfo?.fileName,
                                          })
                                        );
                                      }}
                                      className="pointer"
                                      style={{
                                        color: "#353535",
                                        width: "16px",
                                        height: "20px",
                                        marginBottom: "-2px",
                                      }}
                                      sx={{ verticalAlign: "bottom" }}
                                    />
                                    <SaveAltOutlinedIcon
                                      data-testid="submission-submit1"
                                      onClick={() => {
                                        downloadFile(
                                          candidate?.resumeInfo?.resourceUrl,
                                          candidate?.resumeInfo?.fileName
                                        );
                                      }}
                                      className="pointer"
                                      style={{
                                        color: "#353535",
                                        width: "16px",
                                        height: "20px",
                                      }}
                                      sx={{
                                        pl: 1,
                                        verticalAlign: "bottom",
                                        marginTop: "-2px",
                                      }}
                                    />
                                  </>
                                )}

                              {candidate?.resumeInfo?.fileName !== "" &&
                              candidate?.resumeInfo?.fileName !== undefined
                                ? getTextNameEllipse(
                                    candidate?.resumeInfo?.fileName ?? "",
                                    13
                                  )
                                : ""}
                            </div>
                          </Tooltip>
                        </div>
                      </TableCell>
                      <TableCell sx={{ px: 1, whiteSpace: "nowrap", width: 100 }}>
                          {
                            valueOrDefault(
                              convertToLocalizedDateTime(
                                candidate?.createdDate,
                                "MMM DD, YYYY,  h:mm A"
                              ),
                              "N/A")
                          }
                      </TableCell>
                      <TableCell>
                        {/* View Candidate Details Button */}
                        <ModuleComponent
                          moduleId={
                            userRole === UserAuth.Admin ||
                            userRole === UserAuth.SuperAdmin
                              ? PermissionsEnums.AllCandidates_Details
                              : PermissionsEnums.Candidates_Details
                          }
                        >
                          <Button
                            data-testid="viewdetails"
                            variant="outlined"
                            size="small"
                            className="cust-subDetails-btn"
                            onClick={() =>
                              navigate(
                                getNevLink(`/candidate-detail/${candidate?.id}`)
                              )
                            }
                          >
                            View Details
                          </Button>
                        </ModuleComponent>
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>  
          {isLoading && (
            <p
              style={{
                marginTop: "50px",
                marginBottom: "4px",
                textAlign: "center",
              }}
            >
              Please wait...
            </p>
          )}
          {!isLoading && candidatesData?.length === 0 && (
            <Alert severity="info" style={{ marginTop: "5px" }}>
              No Records Found
            </Alert>
          )}
      </TableContainer>

      {isOpenPopup && (
        <ViewRecruiterInfoPopup
          recruiterId={recruiterId}
          isOpenPopup={isOpenPopup}
          handleClose={handleClose}
        />
      )}
      {filePreview !== undefined && (
        <PreviewFileDialog
          open={filePreview !== undefined}
          handleOpenClose={() => setFilePreview(undefined)}
          fileName={filePreview.fileName}
          fileURL={filePreview.resourceUrl}
          fileType={filePreview.fileType}
        />
      )}
    </>
  );
};

export default CandidateTableList;
