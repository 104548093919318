import React from 'react';
import { Pagination, PaginationItem, Select, MenuItem, Box, Typography, Tooltip, SelectChangeEvent } from '@mui/material';

import './AppTableStyle.scss';
import { AppPaginationPerPage } from '../../../utils/AppConstant';

interface PaginationProps {
  count: number; // Total number of items
  rowsPerPage: number; // Rows per page
  page: number; // Current page number
  onPageChange: (event: React.ChangeEvent<unknown>, newPage: number) => void;
  onRowsPerPageChange?: (event: SelectChangeEvent<number>) => void;
  rowsPerPageOptions?: number[]; // Options for rows per page
  showRecordRange?: boolean; // Whether to show the range of records
  showRowsPerPageOptions?: boolean; // Whether to show the rows per page dropdown
}

const GenericPagination: React.FC<PaginationProps> = ({
  count,
  rowsPerPage = AppPaginationPerPage,
  page,
  onPageChange,
  onRowsPerPageChange = () => {},
  rowsPerPageOptions = [5, 10, 20],
  showRecordRange = true, // Default to showing the record range
  showRowsPerPageOptions = false, // Default to showing the rows per page dropdown
}) => {
  const totalPages = Math.ceil(count / rowsPerPage);

   // Calculate the starting index of the records
   const startIndex = count > 0 ? ((page - 1) * rowsPerPage) + 1 : 0;

   // Calculate the ending index of the records
   const endIndex = count > 0 ? Math.min(page * rowsPerPage, count) : 0;

  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center" mt={2}>
      {/* Conditionally display the range of records */}
      {showRecordRange && (
        <Typography sx={{ fontSize: '14px', paddingRight:2 }}>
          {startIndex}–{endIndex} of {count}
        </Typography>
      )}

      {/* Conditionally display the rows per page dropdown */}
      {showRowsPerPageOptions && (
        <Box display="flex" alignItems="center">
          <Typography sx={{ fontSize: '14px' }}>Rows per page:</Typography>
          <Select
            value={rowsPerPage}
            onChange={onRowsPerPageChange}
            style={{ marginLeft: 8, marginRight: 16 }}
            className="rowPerPageSelectField"
            data-testid="select-rows-per-page"
          >
            {rowsPerPageOptions.map((option) => (
              <MenuItem key={option} value={option} data-testid={`select-option-${option}`}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </Box>
      )}

      <Pagination
        count={totalPages}
        page={page}
        onChange={onPageChange}
        color="primary"
        showFirstButton
        showLastButton
        variant="outlined"
        shape="rounded"
        className="AppPagination"
        renderItem={(item) => (
          <Tooltip
            placement="top"
            title={getTooltipText(item.type as PaginationItemType)}
          >
            <PaginationItem {...item} />
          </Tooltip>
        )}
      />
    </Box>
  );
};

export default GenericPagination;

// enum for pagination item types
enum PaginationItemType {
  First = 'first',
  Last = 'last',
  Previous = 'previous',
  Next = 'next',
  Default = '',
}
// Function to get the tooltip text based on item type
const getTooltipText = (type: PaginationItemType): string => {
  switch (type) {
    case PaginationItemType.First:
      return 'First Page';
    case PaginationItemType.Last:
      return 'Last Page';
    case PaginationItemType.Previous:
      return 'Previous Page';
    case PaginationItemType.Next:
      return 'Next Page';
    default:
      return '';
  }
};
