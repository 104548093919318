import { StatusEnumList } from '../components/Common/SubmissionStatusEnumList';
import axios from './CommonHttpRequest';

let baseUrl = `${process.env.REACT_APP_CANDIDATE_API}`;

export const GetSubmissionsByRecruiter = async (candidateGuid: string, recruiterGuid: string) => {
  return await axios.get(`${baseUrl}api/CandidateSubmission/GetSubmissionsByRecruiter?candidateGuid=${candidateGuid}&recruiterGuid=${recruiterGuid}`);
};
export const GetSubmissionById = async (submissionId: string) => {
  // Submission ID
  // For Offer accepted
  // d76a94a1-1cf2-41bf-a888-6ba7e6919c32
  // For Offer Declined
  // 8cbc7905-f731-4a02-91ea-9a3bdc704c22

  return await axios.get(`${baseUrl}api/CandidateSubmission/GetSubmission?id=${submissionId}`)
};
export const GetRecruiterSubmissions = async (duration:number,recruiterId:string,submissionsType:string) => {
  return await axios.get(`${baseUrl}api/CandidateSubmission/GetRecruiterSubmissionsByDuration?recruiterGuid=${recruiterId}&duration=${duration}&submissionsType=${submissionsType}`);
}
export const GetSubmissionsByDuration = async (duration: number, recruiterId: string, submissionsType: string,searchKeywords:string) => {
  let reqData = { recruiterGuid: recruiterId, duration: duration, submissionsType: submissionsType,searchKeywords:searchKeywords }
  return await axios.post(`${baseUrl}api/CandidateSubmission/GetSubmissionsByDuration`, reqData);
}

export const GetCandidateById = async (id: string) => {
  return await axios.get(`${baseUrl}api/Candidate/GetCandidate?id=${id}`);
}
export const UpdateCandidate = async (data: any) => {
  return await axios.put(`${baseUrl}api/Candidate/UpdateCandidate`, data);
}
export const GetCandidateByFilter = async (data: any) => {
  return await axios.post(`${process.env.REACT_APP_CANDIDATE_API}api/Candidate/GetCandidateByFilter`, data);
};
export const SendReminderEmail = async (data: any) => {
  return await axios.post(`${process.env.REACT_APP_CANDIDATE_API}api/CandidateSubmission/SendReminder`, data);

}
export const GetSubmissions = async (data: any) => {
  return await axios.post(`${process.env.REACT_APP_CANDIDATE_API}api/CandidateSubmission/GetSubmissions`, data);
}

export const GetSubmissionsById = async (id: any) => {
  return await axios.get(`${process.env.REACT_APP_CANDIDATE_API}api/CandidateSubmission/GetSubmission?id=${id}`);
}

export const GetSubmissionStatuses = async () => {
  return await axios.get(`${process.env.REACT_APP_CANDIDATE_API}api/CandidateSubmission/GetSubmissionStatuses`);
}

export const GetContractTypes = async () => {
  return await axios.get(`${process.env.REACT_APP_CANDIDATE_API}api/CandidateSubmission/GetContractTypes`);
}
export const GetSubmissionFilters = async (type:string) => {
  return await axios.get(`${process.env.REACT_APP_CANDIDATE_API}api/SubmissionFilter/${type}`);
}
export const RejectCandidate = async (payload:any) => {
  return await axios.post(`${process.env.REACT_APP_CANDIDATE_API}api/CandidateSubmission/Reject`,payload);
}
export const ConversationAddComment = async (payload:any) => {
  return await axios.put(`${process.env.REACT_APP_CANDIDATE_API}api/CandidateSubmission/SubmissionComments`,payload);
}
export const GetCandidateFromSubmission = async (payload:any) => {
  return await axios.post(`${process.env.REACT_APP_CANDIDATE_API}api/CandidateSubmission/CandidatesFromSubmissions`,payload);
}

export const GetClientsFromSubmission = async (payload:any) => {
  return await axios.post(`${process.env.REACT_APP_CANDIDATE_API}api/CandidateSubmission/ClientsFromSubmissions`,payload);
}

export const GetInterviewingCountByJob = async (jobId:string,recruiterGuid='') => {
  let statusIds = [
    StatusEnumList.candidate_shortlisted.id
  ];

  let payload = {

    "jobId": jobId,
   
    "statusIds":statusIds,
    recruiterGuid:recruiterGuid,
    "pageIndex": 1,
    "pageSize": 20,
    "sortColumn": "",
    "sortOrder": "Ascending",
    "isSorted": true,
    "totalRecord": 0,
  }
  return await axios.post(`${process.env.REACT_APP_CANDIDATE_API}api/CandidateSubmission/SubmissionCountByFilter`,payload);
}
export const GetSubmissionCountByJob = async (jobId:string,recruiterGuid='') => {
  
  let payload = {

    "jobId": jobId,
    "statusIds": [],
    recruiterGuid: recruiterGuid,
    "pageIndex": 1,
    "pageSize": 20,
    "sortColumn": "",
    "sortOrder": "Ascending",
    "isSorted": true,
    "totalRecord": 0,
  }
  return await axios.post(`${process.env.REACT_APP_CANDIDATE_API}api/CandidateSubmission/SubmissionCountByFilter`,payload);
}

export const GetActiveSubmissionCountByJob = async (jobId:string,recruiterGuid='') => {
  let activeSubmissionFilters = await GetSubmissionFilters('active');
  let statusIds = activeSubmissionFilters?.data?.entityList.map((x: any) => x.id);

  let payload = {
    "jobId": jobId,
    "statusIds": statusIds,
    recruiterGuid: recruiterGuid,
    "pageIndex": 1,
    "pageSize": 20,
    "sortColumn": "",
    "sortOrder": "Ascending",
    "isSorted": true,
    "totalRecord": 0,
  }
  return await axios.post(`${process.env.REACT_APP_CANDIDATE_API}api/CandidateSubmission/SubmissionCountByFilter`,payload);
}

// Update the candidate submission status
export const UpdateCandidateStatus = async (payload:any) => {
  return await axios.put(`${process.env.REACT_APP_CANDIDATE_API}api/CandidateSubmission/status`,payload);
}

// Candidate Master API
// Ge Event Type

export const GetCandidateEventTypes = async () => {
  return await axios.get(`${process.env.REACT_APP_CANDIDATE_API}api/event-master/event-types`);
}
// Get Interview Type
export const GetCandidateInterviewTypes = async () => {
  return await axios.get(`${process.env.REACT_APP_CANDIDATE_API}api/event-master/interview-types`);
}

// Get Timezone type
export const GetCandidateTimezoneTypes = async () => {
  return await axios.get(`${process.env.REACT_APP_CANDIDATE_API}api/event-master/time-zones`);
}

// Get Event Duartion type
export const GetCandidateEventDurationTypes = async () => {
  return await axios.get(`${process.env.REACT_APP_CANDIDATE_API}api/event-master/event-duration`);
}

// Get event Reminder type
export const GetCandidateEventReminderTypes = async () => {
  return await axios.get(`${process.env.REACT_APP_CANDIDATE_API}api/event-master/event-reminder`);
}

// Candidate Interview Scedule API

export const AddInterviewAvailability = async (payload:any) => {
  return await axios.post(`${process.env.REACT_APP_CANDIDATE_API}api/CandidateSubmission/interview-availability`,payload);
}

export const UpdateInterviewAvailability = async (payload:any) => {
  return await axios.put(`${process.env.REACT_APP_CANDIDATE_API}api/CandidateSubmission/interview-availability`,payload);
}

export const GetInterviewAvailabilityById = async (id: string) => {
  return await axios.get(`${baseUrl}api/CandidateSubmission/interview-availability/${id}`);
}

// Graphical Dashboard statistics count
interface GaphicalDashboardCountPayload {
  id:string;
  loggedInUserRole:string;
}
export const GetGraphicalActivitiesCounts = async (requestPayload: GaphicalDashboardCountPayload) => {
  return await axios.post(`${baseUrl}api/CandidateSubmission/GetGraphicalActivitiesCounts`,requestPayload);
}










