import { ContactUsFormValues } from '../components/StaticPages/ContactUs';
import { ExistUserPayload, UserLoginRequest } from '../models/app/Login/UserLoginModel';
import axios from './CommonHttpRequest';

let baseUrl = `${process.env.REACT_APP_IDPROVIDER_API}`;

export const SubscribeToEmail =  (subData:string) => axios.post(`${baseUrl}api/Subscription/Subscribe`,subData);
export const GetPreferences =  (email:string) => axios.get(`${baseUrl}api/Subscription/GetPreferences?emailId=${email}`);
export const UpdatePreferences =  (body:any) => axios.post(`${baseUrl}api/Subscription/UpdatePreferences`,body);
export const GetRoles =  () => axios.get(`${baseUrl}api/Role/Roles`);
export const GetUserPermissionByUserId =  (userId:string) => axios.get(`${baseUrl}api/UserPermissions/ByUserId?userId=${userId}`);
export const GetFeatures =  () => axios.get(`${baseUrl}api/Feature/Features`);
export const AddUserPermissions =  (body:any) => axios.post(`${baseUrl}api/UserPermissions/Add`,body);

export const SendContactEmail = (requestPayload: ContactUsFormValues) =>{
   return axios.post(`${baseUrl}api/Contact/Submit`,requestPayload);
}

export const LoginUser = async (requestPayload: UserLoginRequest) => {
   return axios.post(`${baseUrl}api/SignIn/Login`,requestPayload);
}

export const CheckIsUserEmailExist   = async (requestPayload: ExistUserPayload) => {
   return axios.post(`${baseUrl}api/User/exists`,requestPayload);
}