import {
  Autocomplete,
  Chip,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import moment from "moment";
import React from "react";
import { Asterisk } from "../../../../ui/Asterisk";
import BasicDatePicker from "../../../../ui/DateRange";
import { JobApplicationMethod } from "../../../../../models/Job/JobApplicationMethod";
import { JobRequiredDocument } from "../../../../../models/Job/JobRequiredDocument";


export interface ApplicationProcessFormProps {
  handleChange: any;
  handleBlur: (event: React.FocusEvent<any>) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  setValues:any;
  values: any;
  touched: any;
  errors: any;
  jobApplicationMethodsList: JobApplicationMethod[];
  jobRequireDocumentsList: JobRequiredDocument[];
}

const ApplicationProcessFormSection: React.FC<ApplicationProcessFormProps> = ({
  handleChange,
  handleBlur,
  setFieldValue,
  setValues,
  values,
  touched,
  errors,
  jobApplicationMethodsList,
  jobRequireDocumentsList
}) => {

  return (
    <>
      <Grid container spacing={2} >
        {/* row 2 */}
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h5" className="postingJobFormHeading">Application Process</Typography>
        </Grid>
        {/* row 2 */}
        <Grid item xs={12} sm={6} md={3} style={{marginTop:"4px"}}>
          <BasicDatePicker
            label={"Job End Date"}
            disableFuture={false}
            disablePast={true}
            value={values?.applicationProcess?.jobEndDate}
            name="applicationProcess.jobEndDate"
            minimum={moment().add(28, 'days').utc().toISOString()}
            onChange={(selectedDate: string) => {
              setValues({
                ...values,
                jobDetails: {
                    ...values.jobDetails,
                    jobStartDate: moment(selectedDate).add(14, 'days').utc().toISOString(),
                },
                applicationProcess: {
                    ...values.applicationProcess,
                    jobEndDate: moment(selectedDate).utc().toISOString(),
                },
            });
            }}
            errorMessage={
              touched?.applicationProcess?.jobEndDate &&
              errors?.applicationProcess?.jobEndDate
            }
            defaultDateFormat={"DD-MM-YYYY"}
            views={["day"]}
            fullWidth={true}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
              id="applicationMethod"
              className="cust-field-style"
              disableClearable={true}
              options={jobApplicationMethodsList?.map((options: JobApplicationMethod) => options?.applicationMethod)}
              getOptionLabel={(option) => option}
              value={values?.applicationProcess?.applicationMethod}
              onChange={(_, newValue:any) => {
               setFieldValue("applicationProcess.applicationMethod", newValue);
              }}
              onBlur={handleBlur}
              renderTags={(value, getTagProps) =>
                value.map((option: any, index: any) => (
                  <Chip
                    color="primary"
                    size="small"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  variant="standard"
                  label="Application Method"
                  name="applicationProcess.applicationMethod"
                  data-testid='autocomplete-applicationMethod'
                  error={
                    touched?.applicationProcess?.applicationMethod &&
                    Boolean(errors?.applicationProcess?.applicationMethod)
                  }
                  helperText={
                    touched?.applicationProcess?.applicationMethod &&
                    errors?.applicationProcess?.applicationMethod
                  }
                  placeholder="Select Application Method"
                />
              )}
            />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
        <Autocomplete
            className="cust-field-style"
            multiple
            id="documentType"
            options={jobRequireDocumentsList?.map((option: JobRequiredDocument)=> option?.documentType)}
            getOptionLabel={(option) => option}
            value={values?.applicationProcess?.requiredDocuments}
            onChange={(_, newValue) => {
              setFieldValue(
                "applicationProcess.requiredDocuments",
                newValue
              );
            }}
            renderTags={(value, getTagProps) =>
              value.map((option: any, index: any) => (
                <Chip
                  color="primary"
                  size="small"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                required
                {...params}
                variant="standard"
                label="Required Documents"
                data-testid='autocomplete-documenttype'
                placeholder={values?.applicationProcess?.requiredDocuments?.length > 0 ? `` : `Select documents`}
                error={
                  touched?.applicationProcess?.requiredDocuments &&
                  Boolean(errors?.applicationProcess?.requiredDocuments)
                }
                helperText={
                  touched?.applicationProcess?.requiredDocuments &&
                  errors?.applicationProcess?.requiredDocuments
                }
              />
            )}
          />
        </Grid>

        {/* row 3 */}
        <Grid item xs={12} sm={6} md={6}>
        <InputLabel
            className="additionInfoFieldLabel"
            error={
              touched?.applicationProcess?.applicationInstructions &&
              Boolean(errors?.applicationProcess?.applicationInstructions)
            }
          >
            Application Instructions <Asterisk />
          </InputLabel>
          <TextField
            required
            id="applicationInstructions"
            name="applicationProcess.applicationInstructions"
            value={values?.applicationProcess?.applicationInstructions}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              touched?.applicationProcess?.applicationInstructions &&
              Boolean(errors?.applicationProcess?.applicationInstructions)
            }
            helperText={
              touched?.applicationProcess?.applicationInstructions && errors?.applicationProcess?.applicationInstructions
            }
            fullWidth
            type="text"
            multiline
            rows={2}
            variant="outlined"
            className="AdditionInfoTxtArea"
            placeholder="Enter Instructions"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ApplicationProcessFormSection;
