import { SvgIcon } from '@mui/material'
import React from 'react'

export default function Calender(props) {
  return (
    <SvgIcon className={props.className} viewBox='0 0 35 35' {...props}>
<path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.22925 2.14616H2.60425H1.10425V3.64616V6.77116V17.1878V18.6878H2.60425H10.4926C10.7298 22.7547 14.1029 25.9795 18.2292 25.9795C22.5095 25.9795 25.9792 22.5097 25.9792 18.2295C25.9792 14.4625 23.2916 11.3232 19.7292 10.6245V6.77116V3.64616V2.14616H18.2292H16.6042V1.10449H13.6042V2.14616H7.22925V1.10449H4.22925V2.14616ZM13.6042 5.14616H7.22925V5.27116H13.6042V5.14616ZM16.6042 5.14616V5.27116H16.7292V5.14616H16.6042ZM16.7292 8.27116H4.10425V15.6878H10.9061C11.7991 13.1148 14.0085 11.1579 16.7292 10.6245V8.27116ZM4.10425 5.27116H4.22925V5.14616H4.10425V5.27116ZM16.7292 13.7213C15.0842 14.2685 13.8378 15.6909 13.545 17.436C13.5019 17.6931 13.4792 17.9581 13.4792 18.2295C13.4792 20.8528 15.6059 22.9795 18.2292 22.9795C20.8526 22.9795 22.9792 20.8528 22.9792 18.2295C22.9792 16.1303 21.6175 14.3492 19.7292 13.7212V14.5837V17.6082L20.8524 18.7313L21.9131 19.792L19.7917 21.9133L18.7311 20.8527L17.1686 19.2902L16.7292 18.8508V18.2295V14.5837V13.7213Z"
    />    </SvgIcon>
  )
}