import { Component } from "react";
import Chart from "react-apexcharts";
import truncateString from "../../../utils/truncateString";

class RelevantCandidatesChart extends Component {
  constructor(props) {
    let currentMonthSubmissionCount = (props.data && Array.isArray(props.data)) 
                                    ? props.data.map(x => x.currentMonthSubmissionCount) 
                                    : []
    let previousMonthSubmissionCount = (props.data && Array.isArray(props.data)) 
                                    ? props?.data?.map(x=>x.previousMonthSubmissionCount)
                                    : []
    let jobTitles = (props.data && Array.isArray(props.data))  
                    ? props?.data?.map(x=>x.jobTitle)
                    : []
    let categories = jobTitles

    super(props);

    this.state = {
      series: [
        {
          name: "Last Month",
          data: previousMonthSubmissionCount,
        },
        {
          name: "This Month",
          data: currentMonthSubmissionCount,
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 280,
          toolbar: {
            show: false,
          },
          events: {
            legendClick: function (chartContext, seriesIndex, config) {
            
              this?.changeData()
              
              
            }
          },
        },
        colors: ["#8C83FA", "#4540DB"],
        plotOptions: {
          bar: {
            borderRadius: 6,
            horizontal: false,
          },
        },
      
       
        stroke: {
          show: true,
          width: 3,
          colors: ["#fff"],
        },
        tooltip: {
          x: {
            formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
              return  jobTitles[dataPointIndex] 
            }
          }
        },
        xaxis: {
          categories: categories.map(x => x.replace(' - ', "- ").split(' ').map(x => truncateString(x, 12))),

          labels: {
            style: {
              colors: ["#4540DB", "#4540DB", "#4540DB", "#4540DB", "#4540DB"],
              fontSize: "10px",
            },
            rotate: 0,
          },
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return Math.floor(val);
            },
          },
          
        },
        legend: {
          show: true,
          fontSize: "9px",
          position: "top",
          offsetX: -10,
          offsetY: 15,
          itemContainers: {
            clickable: false,
            focusable: false
          }
        },
        
      },
    };
  }
  changeData (){
    console.log("fired");
   }
  render() {
    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type="bar"
        width="100%"
        height="260"
      />
    );
  }
}

export default RelevantCandidatesChart;
