import { Component } from "react";
import Chart from "react-apexcharts";

class FulFilledBySkillChart extends Component {
  constructor(props) {
    let data = props?.data
    super(props);

    this.state = {
       series: [data?.good, data?.average, data?.bad],

      options: {
        chart: {
          height: 400,
          type: "radialBar",
          fontFamily:"Roboto"
        },
        plotOptions: {
          radialBar: {
            borderRadius: 6,
            offsetY: 0,
            startAngle: -135,
            endAngle: 135,
            hollow: {
              margin: 5,
              size: "55%",
              background: "transparent",
              image: undefined,
            },
            track: {
              background: "#fff",
              startAngle: -135,
              endAngle: 135,
            },
            dataLabels: {
              name: {
                fontSize: "30px",
              },
              value: {
                fontSize: "25px",
              },
              total: {
                show: true,
                label: data?.month,
                formatter: function (w) {
                  return data?.year;
                },
              },
            },
          },
        },
        colors: ["#4540DB", "#9390FA", "#C2C0FF"],
        labels: ["Good", "Average", "Bad"],
        stroke: {
          lineCap: "round",
        },
        legend: {
          show: true,
          floating: false,
          fontSize: "16px",
          position: "bottom",
          offsetX: 0,
          offsetY: 5,
          labels: {
            useSeriesColors: true,
          },
          markers: {
            size: 0,
          },
          formatter: function (seriesName, opts) {
            return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
          },
          itemMargin: {
            vertical: 2,
          },
        },
      },
    };
  }

  render() {
    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type="radialBar"
        width="100%"
        height="280"
      />
    );
  }
}

export default FulFilledBySkillChart;