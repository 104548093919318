import React from 'react';
import Typography from '@mui/material/Typography';


interface props{
    alignment?:"center" | "right" | "left" | "inherit" | "justify" 
    marginTop?:string;
    marginBottom?:string
}

const PleaseWait: React.FC<props> = ({
    alignment = "center",
    marginTop = "16px",
    marginBottom = "16px",
}) => {
  return (
    <>
      <Typography
        variant="body1"
        align={alignment}
        sx={{marginTop:marginTop, marginBottom:marginBottom}}
      >
        Please wait ...
      </Typography>
    </>
  );
};

export default PleaseWait;
