import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Alert } from "@mui/material";

export const ModuleDisabledMessage: React.FC = () => {
  return (
    <>
      <Container maxWidth="md"sx={{ my: 2 }}>
        <Grid
          container
          spacing={3}
          sx={{ justifyContent: "space-around", paddingTop: 4 }}
        >
          <Grid item xs={12} md={10}>
              <Alert severity="error">
                The administration team has disabled the Client Module feature, and it is no longer accessible.
              </Alert>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
