import * as Yup from "yup";
// Manage client validation schema
export const ManageClientValidationSchema = Yup.object().shape({
    clientId: Yup.string().required('Please select client'),
    accountManagerDetails: Yup.object().shape({
      fullName: Yup.string().required('Please select account manager'),
      email: Yup.string().email('Invalid email address').required('Please enter email id'),
      primaryPhone: Yup.string().required('Please enter phone number'),
    }),
    otherDetails: Yup.object().shape({
      oneTimeCommission:Yup.number()
      .min(0,"Please enter positive one time commission"),

      jobType: Yup.string().required('Please select Job type'),
      jobTaxTerm: Yup.string()
      .when('jobType', {
        is: (jobType: string) => jobType !== "Full Time",
        then: Yup.string().required("Please select tax terms"),
        otherwise: Yup.string().notRequired(),
      }),
      payrollOverhead: 
        Yup.number()
        .when('jobTaxTerm', {    
          is: (jobTaxTerm:string) =>
          jobTaxTerm === 'W2',
          then: Yup.number()
            .positive()           
            .max(999, 'Maximum  3 digits allowed')
            .required('Please enter payroll overhead') 
            .nullable(),
          otherwise: Yup.number()
            .max(999, 'Maximum 3 digits allowed')
            .nullable(),
          }),
      paymentTerms: Yup.number().required('Please select payment terms'),
      paymentMode: Yup.string().required('Please select payment mode'),
      modeOfDelivery: Yup.string().required('Please select mode of delivery'),      
      clientFeeType: Yup.string().required('Client Fee Type is required'),
      clientFee: 
      Yup.number().when(['jobType', 'clientFeeType'], {
        is: (jobType: string) => jobType === 'Full Time',
        then: Yup.number().when('clientFeeType', {
          is: 'USD',
          then: Yup.number()
            .positive()
            .max(99999, 'Maximum 5 digits allowed')
            .required('Please enter client fee')
            .nullable(),
          otherwise: Yup.number()
            .when('clientFeeType', {
              is: 'Percentage',
              then: Yup.number()
                .positive()
                .max(999, 'Maximum 3 digits allowed')
                .required('Please enter client fee')
                .nullable(),
              otherwise: Yup.number().nullable(),
            }),
          }),
        otherwise: Yup.number().nullable(),
      })
      ,
      jobRialtoCommissionType: Yup.string().required('JobRialto Commission Type is required'),
      jobRialtoCommission:
      Yup.number().when(['jobType', 'jobRialtoCommissionType'], {
        is: (jobType: string) => jobType !== 'Full Time',
        then: Yup.number().when('jobRialtoCommissionType', {
          is: 'USD',
          then: Yup.number()
            .positive()
            .max(99999, 'Maximum 5 digits allowed')
            .required('Please enter JobRialto commission')
            .nullable(),
          otherwise: Yup.number()
            .when('jobRialtoCommissionType', {
              is: 'Percentage',
              then: Yup.number()
                .positive()
                .max(999, 'Maximum 3 digits allowed')
                .required('Please enter JobRialto commission')
                .nullable(),
              otherwise: Yup.number().nullable(),
            }),
          }),
        otherwise: Yup.number().nullable(),
      }),       
       recruiterCommission: Yup.number()
        .when('recruiterCommissionType', {    
        is: (recruiterCommissionType:string) =>
        recruiterCommissionType === 'USD',
        then: Yup.number()
          .positive()           
          .max(99999, 'Maximum 5 digits allowed')
          .required('Please enter commission') 
          .nullable(),
        otherwise: Yup.number()
          .max(999, 'Maximum 3 digits allowed')
          .nullable(),
        }),
       recruiterCommissionType: Yup.string().required('Recruiter Commission Type is required'),
    }),
});