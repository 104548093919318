import axios from './../Services/CommonHttpRequest';
//auth token
const setAuthToken = token => {
	if (token) {
		
		localStorage.setItem('accessToken', token);
		axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
		axios.defaults.headers.common['Pragma'] = 'no-cache';
		axios.defaults.headers.common['Content-Type'] ='application/x-www-form-urlencoded';
		axios.defaults.headers.common['X-Content-Type-Options'] ='nosniff';

	} else {
		delete axios.defaults.headers.common['Authorization'];
		axios.defaults.headers.common['X-API-Key'] = 'ffc85d8f-6d7e-4c82-bf78-6d19146924ca';
	}
};

export const setNotLoggedUserAuthToken = token => {
	if (token) {
		axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
	}
}

export default setAuthToken;