import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react";
import { AxiosError, AxiosRequestConfig } from "axios";
import axiosInstance from "../../Services/CommonHttpRequest";
import { AppResponse } from "../../models/app/AppResponse";
import {
  ClientFilter,
  ClientStateType
} from "../../models/client/ClientModels";

let baseUrlClient = `${process.env.REACT_APP_CLIENT_API}`;

const initialState: ClientStateType = {
  filter: {
    industries:[],
    locations:[],
    statuses:[]
  },
};

export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl?: string } = { baseUrl: baseUrlClient }
  ): BaseQueryFn<
    {
      url: string;
      method?: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
    },
    unknown,
    unknown
  > =>
  async ({ url, method = "GET", data }) => {
    try {
      const result = await axiosInstance({ url: baseUrl + url, method, data });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: { status: err.response?.status, data: err.response?.data },
      };
    }
  };

export const clientSlice = createSlice({
  name: "Client",
  initialState: initialState,
  reducers: {
    handleIndustries: (state, action: PayloadAction<any[]>) => {
      state.filter.industries = action.payload;
    },
    handleLocations: (state, action: PayloadAction<any[]>) => {
      state.filter.locations = action.payload;
    },
    handleStatues: (state, action: PayloadAction<any[]>) => {
      state.filter.statuses = action.payload;
    },
    handleSearchKeyword: (state, action: PayloadAction<string>) => {
      state.filter.searchKeyword = action.payload;
    },
    ClearClientFilter: (state) => {
      state.filter = {
        industries:[],
        locations:[],
        statuses:[]
      };
    },
  },
});

export const clientApi = createApi({
  reducerPath: "ClientApi",
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    getClients: build.mutation<AppResponse<any>, ClientFilter>({
      query: (filter) => ({
        url: `api/client/filter`,
        method: "post",
        data: filter,
      }),
    }),
  }),
});

export const { useGetClientsMutation } = clientApi;

export const {
  handleIndustries,
  handleLocations,
  handleStatues,
  handleSearchKeyword,
  ClearClientFilter
} = clientSlice.actions;

export const clientReducer = clientSlice.reducer;
