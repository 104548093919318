import * as Yup from "yup";
const AddJobValidationSchema = Yup.object().shape({
  jobDetails: Yup.object().shape({
    jobTitle: Yup.string()
      .required("Please enter Job Title")
      .max(100, "Job Title must be less than 100 characters"),
    jobDescription: Yup.string()
      .required("Please enter Job Description")
      .min(500, "Job Description must be of atleast 500 characters")
      .max(5000, "Job Description must be less than 5000 characters"),
    jobDomain: Yup.string().nullable().required("Please select Domain"),
    jobIndustry: Yup.string().required("Please select Industry"),
    primarySkill: Yup.string().required("Please select Primary Skill"),
    jobStartDate: Yup.string().required("Please select Start Date")
    .nullable()
    .when('applicationProcess.jobEndDate', (endDate, schema) =>
      endDate
        ? schema.max(endDate, 'Start date must be earlier than end date')
        : schema
    ),
    shiftTimingFrom: Yup.string().required("Please select Shift Timings"),
    shiftTimingTo: Yup.string().required("Please select Shift Timings"),
    workingHours: Yup.number().max(24,"Working Hours must be less than or equal to 24").min(1, "Working Hours must be greater than or equal to 1").required("Please enter hours"),
    numberOfOpenings: Yup.number().max(999,"Number of openings must be less than or equal to 999").min(1, "Number of opening must be greater than or equal to 1").required("Please enter openings"),
    experience: Yup.string().required("Please select Experience"),
    requiredDegree: Yup.object().required( "Please select Educational Degree"),
    desiredCertifications: Yup.array()
    .max(
      5,
      "Maximum 5 certifications are allowed"
    ),
    state: Yup.string().required("Please enter State"),
    city: Yup.string().required("Please enter City"),
    zipCode: Yup.string().required("Please enter Zipcode"),
    requiredSkills: Yup.array()
      .min(1,"Please enter skills")
      .max(16, "Maximum 16 skills are allowed"),
    isRemoteJob: Yup.boolean().required("Please select Remote option"),
    }),

    jobTypeAndRate: Yup.object().shape({
      jobType: Yup.string().required("Please select Job Type"),
      taxTerms: Yup.string()
      .when('jobType', {
        is: (jobType: string) => jobType !== "Full Time",
        then: Yup.string().required("Please select Tax Terms"),
        otherwise: Yup.string().notRequired(),
      }),
      jobDuraion:  Yup.number().when('jobType', {
        is: (jobType: string) => jobType !== "Full Time",
        then: Yup.number().required("Please enter Job Duration"),
        otherwise: Yup.number().notRequired(),
      }),
      billRate: Yup.number()
      .when('paymentMode', {
        is: (paymentMode: string) => paymentMode === "Annually",
        then: Yup.number()
        .positive()
        .max(99999999, "Maximum 8 digits are allowed")
        .required("Please enter Bill rate"),
        otherwise: Yup.number()
        .positive()
        .max(999, "Maximum 3 digits are allowed")
        .required("Please enter Bill rate"),
      }),
        
      billRateCurrencyType: Yup.string().required("Please select Currency"),
      salaryRange: Yup.string().when('jobType', {
        is: (jobType: string) => jobType === "Full Time",
        then: Yup.string().required("Please select Salary"),
        otherwise: Yup.string().notRequired(),
      }),
      salaryRangeCurrencyType: Yup.string().when('jobType', {
        is: (jobType: string) => jobType === "Full Time",
        then: Yup.string().required("Please select Currency"),
        otherwise: Yup.string().notRequired(),
      }),
      paymentMode: Yup.string().required("Please select mode of Payment"),
      jobWorkAuthorization: Yup.array().min(
        1,
        "Please select Work Authorization"
      ),
      isRateNigotiable: Yup.boolean().required(
        "Please select Negotiable option"
      ),
    }),

    applicationProcess: Yup.object().shape({
      jobEndDate: Yup.string().required("Please select End Date"),
      applicationMethod: Yup.string().required("Please select Application Method"),
      requiredDocuments: Yup.array().min(
        1,
        "Please select Required documents"
      ),
      applicationInstructions: Yup.string()
      .required("Please enter instructions")
      .min(50, "Instructions must be of atleast 50 characters")
      .max(1000, "Instructions cannot be more than 1000 characters"),
    })
 
});

export default AddJobValidationSchema;
