import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../Services/CommonHttpRequest";

let baseUrlIdProvider = `${process.env.REACT_APP_IDPROVIDER_API}`;

export const getLoggedInUserDetails = createAsyncThunk(
  "getLoggedInUserDetails",
  async (id, thunkAPI) => {
    let userId = JSON.parse(localStorage.getItem("user"))?.userId;
    if (userId) {
      return await axios
        .get(`${baseUrlIdProvider}api/user/${userId}`)
        .then((response) => response.data)
        .catch((error) =>
          thunkAPI.rejectWithValue(error?.response?.data || error)
        );
    }
  }
);
let inits = {
    email: "",
    loggedInUserRole: "",
    loginType: "",
    refreshToken: "",
    returnUrl: "",
    userId: "",
    userName: "",
    clientId : ""
  }
export const loggedInUserDetailsState = createSlice({
  name: "loggedInUserDetails",
  initialState: inits ,
  reducers: {
    clearLoggedInUserDetails: (state) => {
        Object.assign(state, inits);
      },
      setLoggedInUseDetails: (state, { payload }) => {
        Object.assign(state, payload);
      },
  },
  extraReducers: (builder) => {
    //Post candidate
    builder.addCase(getLoggedInUserDetails.pending, (state, action) => {
    });

    builder.addCase(getLoggedInUserDetails.fulfilled, (state, action) => {
      let userDetails =action?.payload?.entity
      state.userId = userDetails?.userId
      state.email = userDetails?.email
      state.userName = userDetails?.userName
      state.loggedInUserRole = userDetails?.loggedInUserRole
      state.clientId = userDetails?.clientId

     
      console.log(userDetails);
     
    });
    builder.addCase(getLoggedInUserDetails.rejected, (state, action) => {
      // doing nothing, not sure if intentional
    });
  },
});

// Action creators are generated for each case reducer function
export const { clearLoggedInUserDetails, setLoggedInUseDetails } = loggedInUserDetailsState.actions;

export default loggedInUserDetailsState.reducer;
