import { Grid } from "@material-ui/core";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, CircularProgress, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { GetClientById, UpdateClilentEmail } from "../../../Services/clientService";
import { AuthenticateToken } from "../../../Services/resetPasswordService";
import getUserDetails from "../../../Shared/utils/getUserDetails";
import { PermissionsEnums } from "../../../data/permission";
import { UpdateClientEmailRequestPaylaod } from "../../../models/Services/RequestModel/UpdateClientEmailRequest";
import { ModuleComponent } from "../../Common/Permission/ModuleComponent";
import EmailVerifyPopup from "../../Common/Popup/EmailVerifyPopup";
import { TabPanel } from "../../ManageProfile/ManageProfile";
import { CustomAlert } from "../../ui/CustomAlert";
import { AccountDetails } from "./components/AccountDetails";
import { Location } from "./components/Location";
import { UserProfileForm } from "./components/UserProfileForm";
import "./manageprofile.scss";
import { valueOrDefault } from "../../../utils/complexityUtils";
import SnackbarPopup from "../../Common/Popup/snackbar/SnackbarPopup";

export interface pageStatus{
  isSuccess:boolean,
  isError:boolean,
  loading:boolean,
  message: string,
  key: any,
}
export const ManageProfile = () => {
  const { modules } = useSelector((state: any) => state.userPermissions);

  const location = useLocation();
  const [queryParam]  = useSearchParams();
  const requestIdParam = queryParam?.get("requestId");
  const authTokenParam = queryParam?.get("authToken");
  const [clientDetails, SetClientDetails] = useState<any>();
  const [isEmailVerificationPopup, setIsEmailVerificationPopup] = useState<boolean>(false);
  const [emailVerificationSuccess, setEmailVerificationSuccess] = useState<boolean>(false);
  const [isApiLoading, setIsApiLoading] = useState<boolean | undefined>(undefined);
  const [isEmailVerifyMode, setIsEmailVerifyMode] = useState<boolean>(false);
  let selectedTab: number;

    if (modules?.includes(PermissionsEnums.Profile_Account)) {
      selectedTab = 0;
    } else if (modules?.includes(PermissionsEnums.Profile_Location)) {
      selectedTab = 1;
    } else if (modules?.includes(PermissionsEnums.Company_Profile)) {
      selectedTab = 2;
    } else {
      selectedTab = 0;
    }
  const [selectedIndex, setselectedIndex] = React.useState<number>(selectedTab);
  const [pageStatus, setpageStatus] = React.useState({
    isSuccess:false,
    isError:false,
    loading:false,
    message: "",
    key: Date.now(),
  });
  const navigate = useNavigate();

  const options = ["Account Detail", "Location", "Company Profile"];
  const permissions = [PermissionsEnums.Profile_Account, PermissionsEnums.Profile_Location, PermissionsEnums.Company_Profile]
  const userDetails = getUserDetails();

  useEffect(() => {         
    if(queryParam.get("authToken"))
    {
      let authToken = queryParam.get("authToken") === "" ? "" : queryParam.get("authToken"); 
        setIsEmailVerifyMode(true);
        _checkAuthToken(valueOrDefault(authToken, ""));

    }
  }, []);


  React.useEffect(() => {
    _GetClientByIds();
  }, [selectedIndex]);

  const accountDetails = useRef<(() => void) | null>(null);
  const manageProfile = useRef<(() => void) | null>(null);
  const locationForm = useRef<(() => void) | null>(null);

    // Get client company information details
    const _GetClientByIds = ()=>{   

      GetClientById(userDetails?.id ?? "")
      .then((response: any) => {
        if (response?.data?.message?.appStatusCode === "CL_RSF") {
          SetClientDetails(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }
 
  // check Authonticate token for email verification case
  const _checkAuthToken = (token:string)=>{  
    setIsApiLoading(true);
    const auth = localStorage.getItem("accessToken") ? true : false;
    AuthenticateToken(token)
    .then((res) => {
      setIsApiLoading(false);
      if (res?.status === 200 && (res?.data?.entity?.isExpired === true)) {
        setIsEmailVerifyMode(false);
        setpageStatus({
          isSuccess:false,
          isError:true,
          loading:false,
          message:'Your email verification link has expired.',
          key:Date.now()
        })
      
        } else if (res.status == 200 && (res?.data?.entity?.isExpired === false)) 
        {
          if(requestIdParam !==""){
            setIsEmailVerificationPopup(true);
          }
          
        } else {
          setIsEmailVerifyMode(false);
          if (!auth) {
            navigate("/login",{state:{redirectUrl:location.pathname + location.search}});
            
          }
        }
    })
    .catch((error:any)=>{
        console.log(error);
        setIsApiLoading(false);
        
    })
   }

   // Handle for now now button action
   const _handleNotNow = ()=>{
    setIsEmailVerificationPopup(false);
    setIsEmailVerifyMode(false);
    _handleChangeEmail(false,"NOTNOW")
   }

   //Handle custom Alert onclose 
   const _handleAlertOnClose = ()=>{
    navigate('/client/manage-profile');
   }
   // Handle Yes
   const _handleYes = ()=>{
    setEmailVerificationSuccess(true);
    setIsEmailVerificationPopup(false);
   }


   // Update the changed email for user
   const _handleChangeEmail = (isEmailUpdate :boolean, type:string)=>{
    setpageStatus({
      ...pageStatus,
      isSuccess:false,
      isError:false,
      message:""
    })
    const payload = {
      clientId: userDetails?.id,
      requestId: requestIdParam,
      updateEmail: isEmailUpdate
    } as UpdateClientEmailRequestPaylaod;

    UpdateClilentEmail(payload)
    .then((res:any) => {
      setIsEmailVerificationPopup(false);
      if(res?.data?.status === 200 && res?.data?.message?.appStatusCode === "CL_RSF"){
        setEmailVerificationSuccess(true);
        if(type !== "OK"){
          _handleAlertOnClose();
        }
        
      }else{
        setEmailVerificationSuccess(false);
        setpageStatus({
          ...pageStatus,
          isSuccess:false,
          isError:true,
          message:res?.data?.message?.appStatusDescription
        })
      }
    })
    .catch(()=>{
      setEmailVerificationSuccess(false);
      setIsEmailVerificationPopup(false);
      setpageStatus({
        ...pageStatus,
        isSuccess:false,
        isError:true,
        message:"Unexpected error!"
      })
    })
   }
  // Function to set the child function in the accountDetails
  const setChildFunction = (func: () => void) => {
    accountDetails.current = func;
  };
  const setManageProfile = (func: () => void) => {
    manageProfile.current = func;
  };
  const setLocation = (func: () => void) => {
    locationForm.current = func;
  };

  // Function to trigger the child function
  const triggerChildFunction = () => {
    switch (selectedIndex) {
      case 0:
        if (accountDetails.current) {
          accountDetails.current(); // Call the child function through the ref
        }
        break;
      case 1:
        if (locationForm.current) {
          locationForm.current(); // Call the child function through the ref
        }
        break;
      case 2:
        if (manageProfile.current) {
          manageProfile.current(); // Call the child function through the ref
        }
        break;
    }
  };

  const getAlertType = (pageStatus:any) => {
    if (pageStatus?.isSuccess) {
      return 'success';
    } else if (pageStatus?.isError) {
      return 'error';
    } else {
      return 'info';
    }
  }

  return (
    <>
    { (authTokenParam !=="" && isApiLoading) ?
      null
    :
      <Box>
       <Grid container>
      <Grid item xs={12}>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={6}>
            <Typography className="profile-btns selected2">Manage Profile</Typography>
          </Grid>
          <Grid item xs={12} sm={6} container justifyContent="flex-end">
            <Button
              data-testid="outlined"
              className="roboto-font"
              style={{ marginRight: '13px', height: 'max-content' }}
              onClick={() => navigate(-1)}
              variant="outlined"
              disabled={ pageStatus.loading }
              endIcon={ pageStatus.loading && 
                <CircularProgress
                  size={"16px"}
                  color={"inherit"}
                />
              }
            >
              Cancel
            </Button>
            <Button
              data-testid="contained"
              className="roboto-font"
              style={{ height: 'max-content' }}
              variant="contained"
              onClick={triggerChildFunction}
              disabled= {pageStatus.loading }
              endIcon={ pageStatus.loading && 
                <CircularProgress
                  size={"16px"}
                  color={"inherit"}
                />
              }
            >
              Update Profile
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={3} style={{ backgroundColor: '#EDEDFA' }}>
          {options.map((x, i) => (
            <ModuleComponent moduleId={permissions[i]} key={x}>
              <Typography
                onClick={() => setselectedIndex(i)}
                pl={'25px'}
                mb={'10px'}
                className={`profile-btns ${i === selectedIndex ? 'selected' : ''}`}
              >
                <Grid container>
                  <Grid item xs={10}>
                    {x}
                    <br />
                  </Grid>
                  <Grid item xs={2}>
                    <ChevronRightIcon />
                  </Grid>
                </Grid>
              </Typography>
            </ModuleComponent>
          ))}
        </Grid>
        <Grid item xs={12} sm={9} style={{ backgroundColor: '#FFF' }}>
          <Box style={{ padding: '0 0 16px 16px' }}>
          {pageStatus.isError && (
              <CustomAlert
                message={pageStatus.message}
                type={getAlertType(pageStatus)}
                key={pageStatus.key}
                onClose={_handleAlertOnClose}
              />
            )}
            
            {pageStatus.isSuccess && (
              <SnackbarPopup
              open={pageStatus.isSuccess}
              message={pageStatus.message}
              onClose={() => {
                setpageStatus({ ...pageStatus, isSuccess: false, loading:false});
              }}
            />
            )}
          </Box>
          <TabPanel index={0} value={selectedIndex}>
            <AccountDetails
              userInfo={clientDetails?.data?.entity}
              onChildFunction={setChildFunction}
              clientId={userDetails?.id || ''}
              setPageStatus={setpageStatus}
              pageStatus={pageStatus}
              emailVerifyMode={isEmailVerifyMode}
              emailVerificationSuccess={emailVerificationSuccess}
              handleChangeEmail={(isUpdate, type) => {
                _handleChangeEmail(isUpdate, type);
              }}
            />
          </TabPanel>
          <TabPanel index={1} value={selectedIndex}>
            <Location
              userInfo={clientDetails?.data?.entity}
              onChildFunction={setLocation}
              clientId={userDetails?.id || ''}
              setPageStatus={setpageStatus}
              pageStatus={pageStatus}
            />
          </TabPanel>
          <TabPanel index={2} value={selectedIndex}>
            <UserProfileForm
              userInfo={clientDetails?.data?.entity}
              onChildFunction={setManageProfile}
              clientId={userDetails?.id || ''}
              setPageStatus={setpageStatus}
              pageStatus={pageStatus}
            />
          </TabPanel>
        </Grid>
        <Grid item xs={12}>
          <EmailVerifyPopup
            isOpen={isEmailVerificationPopup}
            handleClose={_handleNotNow}
            handleYes={_handleYes}
            handleNotNow={_handleNotNow}
            title="To update the requested email id click on Update Profile button"
          />
        </Grid>
      </Grid>
    </Grid>
      </Box>
    }
    </>
  );
};
