import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { disableEdit } from '../../features/editMode/editModeSlice';
import { clear } from '../../features/recruiterSlice';
import { clear as clearRecruiterGraphicalDash } from '../../features/dashboard/recruiterGraphicalDashboardSlice';

import { UserAuth } from '../../models/interfaces';
import getLoggedInUserRole from '../../Shared/utils/getLoggedInUserRole';
import { resetActiveRecruiterSubmission } from '../../features/recruiterSubmission/activeRecruiterSubmissionSlice';
import { resetRejectedSubmission } from '../../features/recruiterSubmission/rejectedRecruiterSubmissionSlice';
import { clearLoggedInUserDetails } from '../../features/userDetails/loggedInUserDetailsSlice';
import { clearCandidateFilter } from '../../features/candidateSlice';
import { clearPermissions } from '../../features/permission/userPermissionSlice';
import { resetAllJobFilter } from '../../features/Jobs/clientJobSlice';
import setAuthToken from '../../utils/setAuthToken';
import { clearAllFilter } from '../../features/submission/submissionSlice';

export const AppLogout = () => {
  let userType = getLoggedInUserRole()
  const dispatch = useDispatch();

  const navigate = useNavigate()
  useEffect(() => {
    dispatch(disableEdit())
    dispatch(clear())
    dispatch(clearLoggedInUserDetails())
    localStorage.clear();
    setAuthToken('')
    dispatch(clearRecruiterGraphicalDash())
    dispatch(resetActiveRecruiterSubmission())
    dispatch(resetRejectedSubmission())
    dispatch(clearCandidateFilter())
    dispatch(clearPermissions())
    dispatch(clearAllFilter())
    dispatch(resetAllJobFilter());
    if (userType == UserAuth.Admin || userType == UserAuth.SuperAdmin ) {
      navigate("/adminSignin");
    }
    else {
      navigate("/login");

    }


  }, [])


  return (
    <div>AppLogout</div>
  )
}
