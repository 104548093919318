import React from 'react';
import './Scorecard.scss'; 
import Grid from '@mui/material/Grid';
import ScorecardCandidateInfo from './Components/ScorecardSection/ScorecardCandidateInfo';
import ScoreSection from './Components/ScorecardSection/ScoreSection';
import { Container } from '@mui/material';
import ScorecardTechnology from './Components/ScorecardSection/ScorecardTechnology';
import ScorecardKeySkills from './Components/ScorecardSection/ScorecardKeySkills';
import ScorecardMatchingKeywords from './Components/ScorecardSection/ScorecardMatchingKeywords';
import ScorecardMissingKeywords from './Components/ScorecardSection/ScorecardMissingKeywords';
import ScorecardTools from './Components/ScorecardSection/ScorecardTools';
import ScorecardEducation from './Components/ScorecardSection/ScorecardEducation';
import ScorecardCertification from './Components/ScorecardSection/ScorecardCertification';
import ScorecardIndustry from './Components/ScorecardSection/ScorecardIndustry';
import ScorecardResumeContent from './Components/ScorecardSection/ScorecardResumeContent';
import { EximiusScoreData } from '../../../models/recruiterModel';

interface ScorecardResumeProps {
    glasssquidScore: any;
}

interface ScoreItem {
  label: string;
  percentage: number;
}

const RenderScorecardContent: React.FC<ScorecardResumeProps> = ({ 
    glasssquidScore,
}) => {

const eximiusScore:any = (glasssquidScore?.messageList && glasssquidScore?.messageList[0]) as typeof EximiusScoreData;

const skillsKeywords = (eximiusScore?.scoreData ?? []).find(
    (n: any) => n.categoryName === "skills_keywords"
  );
  
  const technologyKeywords = (eximiusScore?.scoreData ?? []).find(
    (n: any) => n.categoryName === "technology"
  );

  const educationKeywords = (eximiusScore?.scoreData ?? []).find(
    (n: any) => n.categoryName === "education"
  );

  const certificationKeywords = (eximiusScore?.scoreData ?? []).find(
    (n: any) => n.categoryName === "certification"
  );

  const industryKeywords = (eximiusScore?.scoreData ?? []).find(
    (n: any) => n.categoryName === "industry"
  );

  const toolKeywords = (eximiusScore?.scoreData ?? []).find(
    (n: any) => n.categoryName === "tools"
  );

  const skills_keywords_score = eximiusScore?.unweightedMatchedScores?.find(
    (n: any) => n.categoryName === "skills_keywords"
  );

  const technology_score = eximiusScore?.unweightedMatchedScores?.find(
    (n: any) => n.categoryName === "technology"
  );

  const education_score = eximiusScore?.unweightedMatchedScores?.find(
    (n: any) => n.categoryName === "education"
  );

  const certification_score = eximiusScore?.unweightedMatchedScores?.find(
    (n: any) => n.categoryName === "certification"
  );

  const jobTitle_score = eximiusScore?.unweightedMatchedScores?.find(
    (n: any) => n.categoryName === "jobTitle"
  );

  const tools_score = eximiusScore?.unweightedMatchedScores?.find(
    (n: any) => n.categoryName === "tools"
  );

  const domain_score = eximiusScore?.unweightedMatchedScores?.find(
    (n: any) => n.categoryName === "domain"
  );

  const industry_score = eximiusScore?.unweightedMatchedScores?.find(
    (n: any) => n.categoryName === "industry"
  );
  

  function getScore(label: string, score: number | undefined): ScoreItem {
    return { label, percentage: score ?? 0 };
  }
  
  const scores: ScoreItem[] = [
    getScore('Overall', eximiusScore?.eximScore),
    getScore('Title', jobTitle_score?.unWeightedMatchedScore),
    getScore('Skills', skills_keywords_score?.unWeightedMatchedScore),
    getScore('Technology', technology_score?.unWeightedMatchedScore),
    getScore('Tool', tools_score?.unWeightedMatchedScore),
    getScore('Education', education_score?.unWeightedMatchedScore),
    getScore('Certification', certification_score?.unWeightedMatchedScore),
    getScore('Industry', industry_score?.unWeightedMatchedScore),
    getScore('Domain', domain_score?.unWeightedMatchedScore)
  ];
  return (
    <>
    <Container maxWidth={false} style={{backgroundColor:"#fff"}}>
        <Grid container spacing={2} pb={2}>

            {/* Row 2 for candidate basic infomation */}
            <Grid item xs={12} mb={3}>
                <ScorecardCandidateInfo
                    name= {eximiusScore?.resumeData?.name}
                    email={eximiusScore?.resumeData?.emailAddress}
                    contact= {eximiusScore?.resumeData?.contactNumber}
                    location= {eximiusScore?.resumeData?.location}
                    position = {eximiusScore?.resumeData?.jobTitle}
                />
            </Grid>

            {/* Row 3 for score percentage information */}
            <Grid item xs={12} mb={3}>
                <ScoreSection
                    scores={scores}
                />
            </Grid>

            {/* Row 4 for Key skills keyword */}
            <Grid item xs={12} mb={3}>
                <ScorecardKeySkills
                    skills={eximiusScore?.resumeData?.skillsKeywords ?? []}
                />
            </Grid>


            {/* Row 5 for Technology keyword */}
            <Grid item xs={12} mb={3}>
                <ScorecardTechnology
                    technology={eximiusScore?.resumeData?.technology ?? []}
                />
            </Grid>

            {/* Row 6 for Tools Keyword */}
            <Grid item xs={12} mb={3}>
                <ScorecardTools
                     tools={eximiusScore?.resumeData?.tools ?? []}
                />
            </Grid>

            {/* Row 7 for Education Keyword */}
            <Grid item xs={12} mb={3}>
                <ScorecardEducation
                  education={eximiusScore?.resumeData?.education ?? []}
                />
            </Grid>

            {/* Row 8 for Certification Keyword */}
            <Grid item xs={12} mb={3}>
            <   ScorecardCertification
                  certification={eximiusScore?.resumeData?.certification ?? []}
                />
            </Grid>
             {/* Row 8 for Industry Keyword */}
             <Grid item xs={12} mb={3}>
                <ScorecardIndustry
                  industry={eximiusScore?.resumeData?.industry ?? []}
                />
            </Grid>


            {/* Row 9 for Matching Keywords */}
            <Grid item xs={12} mb={3}>
                <ScorecardMatchingKeywords
                    skillsKeywords={skillsKeywords}
                    technologyKeywords={technologyKeywords}
                    educationKeywords={educationKeywords}
                    certificationKeywords={certificationKeywords}
                    industryKeywords={industryKeywords}
                    toolKeywords={toolKeywords}

                />
            </Grid>

            {/* Row 10 for Missing Keywords */}
            <Grid item xs={12} mb={3}>
                <ScorecardMissingKeywords
                    skillsKeywords={skillsKeywords}
                    technologyKeywords={technologyKeywords}
                    educationKeywords={educationKeywords}
                    certificationKeywords={certificationKeywords}
                    industryKeywords={industryKeywords}
                    toolKeywords={toolKeywords}
                />
            </Grid>

            {/* Row 11 for Resume Row/HTML information */}
            <Grid item xs={12}>
                <ScorecardResumeContent  
                    data={eximiusScore?.highlightedResume ?? ""}
                />
            </Grid>

            
        </Grid>
      </Container>
    </>
  );
};

export default RenderScorecardContent;

