import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Alert } from "@mui/material";
import { useSearchParams } from "react-router-dom";
export const Error = ({message=''}) => {
  const [searchParams] = useSearchParams();
  const msg = searchParams.get("msg");
  return (
    <>
      <Container maxWidth="xl" className="dashboard" sx={{ my: 2 }}>
        <Grid
          container
          spacing={3}
          sx={{ justifyContent: "space-around", paddingTop: "20px" }}
        >
          <Grid item xs={12} md={10}>
            <div className="center">
              <Alert severity="error">{msg || message}</Alert>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
