import React from "react";
import { Helmet } from "react-helmet";

type AppHelmetProps = {
  title: string;
  metaDescription: string;
};

const AppHelmet = (props: AppHelmetProps) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{props.title}</title>
        <meta name="description" content={props.metaDescription} />
      </Helmet>
    </>
  );
};

export default AppHelmet;
