import { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { styled ,useTheme} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import { Eye as EyeIcon } from "../Common/Icons/Eye";
import { Clock as ClockIcon } from "../Common/Icons/Clock";
import { MapMarker as MapMarkerIcon } from "../Common/Icons/MapMarker";
import { useParams } from "react-router-dom";
import { GetJobById } from "../../Services/jobService";
import moment from 'moment'
import StaticFooter from "../Common/StaticFooter";
import useMediaQuery from "@mui/material/useMediaQuery";
import logo from "./../../assets/images/logo.svg";
import {
    AppBar,
    Toolbar,
  } from "@mui/material";
import { jobLocation } from "../../utils/jobUtils"
import JobTitle from "../Common/JobTitle";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

export default function JobDetails() {
const theme = useTheme();
 const matchesXS = useMediaQuery(theme.breakpoints.down("sm"));
 const matchesMD = useMediaQuery(theme.breakpoints.up("sm"));
 const dynamicStyles = {
   ...(matchesXS && { direction: "column", fontSize: "h5" }),
   ...(matchesMD && { direction: "row", fontSize: "h4" }),
 };

  let { id } = useParams(); 

  const [jobDetails, setjobDetails] = useState<any>({})
  const getJob = ()=> {
    GetJobById(id ?? "").then(response => {
      if (response.data.status == 200) {
        setjobDetails(response?.data?.entity)
        }
    })
    .catch(er => {
      console.log(er)
    })  
  }



 

  useEffect(() => {
    getJob()

  }, [])

 

  const formattedJobType = () => {
    if(jobDetails?.jobType=="Full Time")
    {
  return  jobDetails?.jobType
    }
  return jobDetails?.jobType + (` (${jobDetails?.jobDuraion} Months)`)
}



  return (
    <div>
       <AppBar
        className="main-header"
        style={{ background: "#fff", color: "#000" }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="span"
              sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
            >
              
                <img src={logo} className="logo" alt="logo" />
             
            </Typography>
            <Typography
              variant="h6"
              noWrap
              component="span"
              sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
            >
              
                <img src={logo} className="logo" alt="logo" />
              
            </Typography>
          </Toolbar>
        </Container>
      </AppBar>

      <Container
        maxWidth="xl"
        className="jobs-page"
        sx={{
            marginTop: "80px",
          }}
      >
        <Grid
          container
          spacing={3}
          sx={{
            justifyContent: "space-around",
            paddingTop: "2px",
          }}
        >
          <Grid
            item
            xs={12}
            md={2}
            sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
          >
            <Item elevation={0}>
            </Item>
          </Grid>
          <Grid item xs={12} md={8}>
            <Item
              elevation={0}
              sx={{
                paddingTop: "20px",
              }}
            >
              
              <Typography
                component="div"
                className="letter-spacing-normal primary-color-text heading-underline"
                gutterBottom
                sx={{ fontSize: { xs: "1rem !important", sm: "1.25rem !important", md: "1.5rem !important" }, marginTop: "23px" }}

              >
                <JobTitle title={jobDetails?.jobTitle} isFeatured={jobDetails?.isFeatured} jobId={jobDetails?.id}/> 
              </Typography>
              <Stack
                direction={dynamicStyles?.direction as any ?? "column"}
                spacing={2}
                sx={{ marginBottom: "20px" }}
                className="activity-chips"
              >
                <Chip
                  icon={<MapMarkerIcon />}
                  label={jobLocation(jobDetails?.remoteJob,jobDetails?.city ,jobDetails?.stateName)}
                  variant="outlined"
                />
                <Chip
                  icon={<EyeIcon />}
                  label={jobDetails?.jobExperience + " Years"}
                  variant="outlined"
                />
                <Chip
                  icon={<ClockIcon />}
                  label={formattedJobType()}

                
                  variant="outlined"
                />
              </Stack>
              <Typography
                variant="body1"
                component="span"
                gutterBottom
              >
                <div
                  dangerouslySetInnerHTML={{ __html: jobDetails?.jobDesc }}
                />
              </Typography>
              <br />

              {/* ---------- */}
              <Grid container spacing={3} className="job-details-info-tbl">
                <Grid style={{ paddingLeft: "16px" }} item xs={6} md={3}>
                  <Item elevation={0} className="primary-color-text">
                    Industry
                  </Item>{" "}
                  <Item elevation={0}>{jobDetails?.industry || "N/A"}</Item>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Item elevation={0} className="primary-color-text">
                    Domain
                  </Item>
                  <Item elevation={0}>{jobDetails?.domain}</Item>
                </Grid>
                <Grid style={{ paddingLeft: "16px" }} item xs={6} md={3}>
                  <Item elevation={0} className="primary-color-text">
                    Assignment Type
                  </Item>
                  <Item elevation={0}>{"N/A"}</Item>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Item elevation={0} className="primary-color-text">
                    Rate Negotiable?
                  </Item>
                  <Item elevation={0}>{"N/A"}</Item>
                </Grid>
              </Grid>
              {/* -----------  */}
              
                  <Grid container spacing={3} className="job-details-info-tbl">
                    <Grid style={{ paddingLeft: "16px" }} item xs={6} md={3}>
                      <Item elevation={0} className="primary-color-text">
                        Client
                      </Item>
                      <Item elevation={0}>{jobDetails?.clientName}</Item>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Item elevation={0} className="primary-color-text">
                        Department
                      </Item>
                      <Item elevation={0}>{"N/A"}</Item>
                    </Grid>
                    <Grid style={{ paddingLeft: "16px" }} item xs={6} md={3}>
                      <Item elevation={0} className="primary-color-text">
                        Education Level
                      </Item>
                      <Item elevation={0}>
                        {jobDetails?.education?.length > 0
                          ? jobDetails?.education.map((x:any, i:number) => {
                              return (
                                <span key={ x?.degreeName ?? i}>
                                  {" "}
                                  {i > 0 && ", "} {x.degreeName}
                                </span>
                              );
                            })
                          : "N/A"}
                      </Item>
                    </Grid>
                    <Grid style={{ paddingLeft: "16px" }} item xs={12} md={3}>
                      <Item elevation={0} className="primary-color-text">
                        Primary Skill
                      </Item>
                      <Item elevation={0}>{jobDetails?.jobPrimarySkill}</Item>
                    </Grid>
                  </Grid>
                  {/* -----------  */}
                  <Grid container spacing={3} className="job-details-info-tbl">
                    <Grid style={{ paddingLeft: "16px" }} item xs={6} md={3}>
                      <Item elevation={0} className="primary-color-text">
                        Hours Per Week
                      </Item>
                      <Item elevation={0}>
                        {jobDetails?.requiredHoursPerWeek || "N/A"}
                      </Item>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Item elevation={0} className="primary-color-text">
                        Job Start Date
                      </Item>

                      <Item elevation={0}>
                        {moment(jobDetails?.jobStartDate).format("LL")}
                      </Item>
                    </Grid>
                    <Grid style={{ paddingLeft: "16px" }} item xs={12} md={4}>
                      <Item elevation={0} className="primary-color-text">
                        Certifications & Licenses, If any
                      </Item>
                      <Item elevation={0}>
                      {jobDetails?.manualData?.certificationsAndLicenses?.length > 0
                          ? jobDetails?.manualData?.certificationsAndLicenses?.map((x:any, i:number) => {
                            return (
                              <span key={ x?.certificationsLicenses ?? i}>
                                {" "}
                                {x?.certificationsLicenses}
                                <br/>
                              </span>
                            );
                          })
                          : "N/A"}
                      </Item>
                    </Grid>
                  </Grid>

                  <Typography
                    variant="body1"
                    component="span"
                    className="heading-underline"
                  >
                    Skills:
                  </Typography>
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{ marginBottom: "35px", marginLeft:"-15px"  }}
                    className="static-width-chip"
                    flexWrap={"wrap"}
                  >
                    {jobDetails?.skills?.map((x:any, i:number) => {
                      return <Chip key={ x?.skill ?? i} color="primary" label={x.skill} style={{
                        marginTop: "10px",
                        marginLeft: "16px",
                      }} />;
                    })}
                  </Stack>
                  { jobDetails?.jobWorkAuthorization?.length > 0 &&  
                  <>
                    <Typography
                      variant="body1"
                      component="span"
                      className="heading-underline"
                    >
                      Work Authorization:
                    </Typography>
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{ marginBottom: "35px", marginLeft:"-15px" }}
                      className="static-width-chip"
                      flexWrap={"wrap"}
                    >
                      {jobDetails?.jobWorkAuthorization?.map((x:any, i:number) => {
                        return <Chip key={ x ?? i} color="primary" label={x} style={{
                          marginTop: "10px",
                          marginLeft: "16px",
                        }} />;
                      })}
                    </Stack>
                  </>
                  }
                
            </Item>
          </Grid>
          <Grid item xs={12} md={2}>
            <Item
              elevation={0}
              sx={{
                paddingTop: "48px",
              }}
            >
            </Item>
          </Grid>
        </Grid>
      </Container>
       <StaticFooter /> 
    </div>
  );
}