import { Card, CardContent } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TopSubmissionsChart from "./TopSubmissionsChart";

export default function TopSubmissions({data}) {
  return (
    <Card sx={{ height: "100%" }} className="card-custom-shadow">
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
          <Grid
            item
            sx={{
              padding: 0,
              width: "100%",
              height: "250px",
            }}
          >
            <Box sx={{ width: "100%", height: "100%" }}>
              <Typography
                variant="caption2"
                sx={{
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                Top Submissions
              </Typography>
              <Box sx={{ marginTop: "0", width: "100%", height: "100%" }}>
               {
                  data?.length > 0 
                  ? <TopSubmissionsChart data={data} /> 
                  : <Typography mt={2}> No data found</Typography>
               }
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
