import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const SavedJobIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_4424_27321)">
          <path
            d="M24 5.23333V22C24 22.5333 23.8 23 23.4 23.4C23 23.8 22.5333 24 22 24H2C1.46667 24 1 23.8 0.6 23.4C0.2 23 0 22.5333 0 22V2C0 1.46667 0.2 1 0.6 0.6C1 0.2 1.46667 0 2 0H18.7667L24 5.23333ZM22 6.13333L17.8667 2H2V22H22V6.13333ZM11.9933 19.8333C12.9533 19.8333 13.7733 19.5 14.4533 18.8267C15.1333 18.1533 15.4733 17.34 15.4733 16.3733C15.4733 15.4067 15.14 14.5933 14.4667 13.9133C13.7933 13.2333 12.98 12.8933 12.0133 12.8933C11.0467 12.8933 10.2333 13.2267 9.55333 13.9C8.87333 14.5733 8.53333 15.3867 8.53333 16.3533C8.53333 17.32 8.86667 18.1333 9.54 18.8133C10.2133 19.4933 11.0267 19.8333 11.9933 19.8333ZM3.76667 8.53333H15.7V3.76667H3.76667V8.53333ZM2 6.13333V22V2V6.13333Z"
            fill="#010101"
          />
        </g>
        <defs>
          <clipPath id="clip0_4424_27321">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default SavedJobIcon;
