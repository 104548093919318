import React from "react";
import Grid from "@mui/material/Grid";
import LabelChip from "../LabelChip";
import SectionHeading from "../../../../ui/Form/Heading/SectionHeading";

interface ScorecardCandidateInfoProps {
  industries: string[];
  className: any;
}

const KeywordsIndustry: React.FC<ScorecardCandidateInfoProps> = ({
  industries,
  className = "",
}) => {
  return (
    <Grid container spacing={2} className="keyword-margin">
      <Grid item xs={12}>
        <SectionHeading title="Industry" />
      </Grid>

      {industries?.length> 0 && industries?.map((industryName) => (
        <Grid item key={industryName} >
          <LabelChip label={industryName} className={className} />
        </Grid>
      ))}
    </Grid>
  );
};

export default KeywordsIndustry;
