
import { Box, Paper, Typography } from "@mui/material";
import convertToLocalizedDateTime from "../../../utils/AppDateUtils";

const SubmissionTimeline = ({ data }) => {
  let statusesData = data?.candidateFinalStatuses;

  const renderButtonWithStatus = (item, index) => {
    let sortedsts = [...statusesData];
    let latestStep = null;
    let latestDate = null;

    for (let status of sortedsts) {
      const modifiedDate = status.modifiedDate;

      // Check if modifiedDate exists in status object
      if (modifiedDate) {
        // Compare date with current latest date and update if later
        if (latestDate === null || modifiedDate > latestDate) {
          latestDate = modifiedDate;
          latestStep = status.step;
        }
      }
    }


    let isFinalStatus = item?.step === latestStep;
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 2,
            flexGrow: 1,
            backgroundColor: isFinalStatus ? "#4540db" : "transparent", // Color for the active step
            color: isFinalStatus ? "#fff" : "text.primary",
            position: "relative",
            "&:after": {
              content: '""',
              position: "absolute",
              right: 0,
              top: "2%",
              bottom: "2%",
              width: "2px",
              backgroundColor: "#4540db",
              display: statusesData?.length-1 === index ? "none" : "block",
            },
          }}
        >
          <Typography
            variant="body2"
            style={{ color: isFinalStatus ? "white" : "" }}
            className="roboto-btn"
          >
            {item?.status}
          </Typography>
          <Typography
            variant="caption"
            style={{ color: isFinalStatus ? "white" : "" }}
            className="roboto-btn"
          >
            {item.modifiedDate &&
                convertToLocalizedDateTime(item?.modifiedDate,"MMM DD, YYYY  h:mm A")
              }
          </Typography>
        </Box>
      </>
    );
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        overflow: "hidden",
        borderRadius: 2,
        borderColor: "#4540db",
        borderWidth: 2,
      }}
    >
      {[...statusesData]
        ?.sort((a, b) => a.step - b.step)
        .map((item, index) => {
          return (
            <>
              {renderButtonWithStatus(item, index)}
            </>
          );
        })}
    </Paper>
  );
};

export default SubmissionTimeline;
