import React, { useEffect, useState } from "react";
import PostingJobForm from "../../Jobs/AddJob/PostingJobForm";
import { Container, Typography, Box, Divider } from "@mui/material";
import Grid from "@mui/material/Grid";
import AppBreadCrumb from "../../Common/AppBreadCrumb";
import { BreadCrumbItem } from "../../../models/AppModels";
import { GetJobById } from "../../../Services/jobService";
import { useParams } from "react-router-dom";
import getLoggedInUserRole from "../../../Shared/utils/getLoggedInUserRole";
import { UserAuth } from "../../../models/interfaces";
import { getNevLink } from "../../../utils/getFileFromUrl";

const pendingJobBreadcrumbsClient: BreadCrumbItem[] = [
  { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
  { isLeaf: false, sequence: 2, title: "Pending Jobs", to: "/client/jobs/pending" },
  { isLeaf: true, sequence: 3, title: "Pending Job" ,leafClasses:" v2LastLeft" },
];

const pendingJobBreadcrumbsClientTeamMember: BreadCrumbItem[] = [
  { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
  { isLeaf: false, sequence: 2, title: "Pending Jobs", to: "/team/jobs/pending" },
  { isLeaf: true, sequence: 3, title: "Pending Job" ,leafClasses:" v2LastLeft"},
];

const PendingJobPage: React.FC = () => {
  const userRole = getLoggedInUserRole();
  const { id } = useParams();
    const [jobPostingDetails, setJobPostingDetails] = useState<any>();
    const [apiLoadingComplete, setApiLoadingComplete] = useState<boolean | undefined>(undefined);
  useEffect(()=>{
    _GetJobById(id);
  },[])


  const _GetJobById = (jobId: any) => {
    setApiLoadingComplete(false)
    GetJobById(jobId)
      .then((response) => {
        if (response?.data?.message?.appStatusCode === "RC_RSF") {
            setJobPostingDetails(response?.data?.entity);
            setApiLoadingComplete(true);
        } else {
            setApiLoadingComplete(false)
        }
      })
      .catch((er) => {setApiLoadingComplete(false)})
      .finally(() => {});
  };
  return (
    <>
      <Box className="PageWrapper">
        <Container maxWidth={false} disableGutters={true}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AppBreadCrumb 
              classes="custom-appbreadcrumb"
              BreadCrumbItems={ userRole === UserAuth.ClientTeamMember
              ? pendingJobBreadcrumbsClientTeamMember
              : pendingJobBreadcrumbsClient } />
            </Grid>
            <Grid item xs={12}>
              <Typography className="PageTitle">Pending Job ({id})</Typography>
              <Divider className="borderedDivider" />
            </Grid>
          </Grid>
        </Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {/* Edit job form */}
             {apiLoadingComplete && <PostingJobForm jobPostingDetails={jobPostingDetails} mode={"PENDING"}/>}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PendingJobPage;
