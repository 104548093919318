import React from 'react'

export const ErrorMessage = ({
  errorText, 
  textColor="#d32f2f", 
  fontSize="12px",
  className=""
}) => {
  const messsage = () =>{
    if(errorText!==undefined)
    {
      return <p className={`${className} CustErrorMsg`} style={{color:textColor,marginTop:'6px',marginBottom: '0px',fontSize:fontSize}}>{errorText}</p>
    }
    return null
  } 
  return (
    <>
    {messsage()}
    </>
  )
  
};
ErrorMessage.defaultProps = {
 
  textColor: 'red',
  fontSize: "12px"

}
