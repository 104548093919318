import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  Chip,
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  styled,
  Typography
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import React from "react";
import glassquidLogo from "../../../assets/images/glasssquid-logo.png";
import { CircularProgressWithLabel } from "../../Common/CircularProgressWithLabel"; 

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  boxShadow: "none",
  
}));

interface Props {
  handleOpenClose: () => void;
  open: boolean;
  glasssquidScore:any,
  resumeDetails:any
}

const RecommendJobScorecardDialog: React.FC<Props> = ({ 
  handleOpenClose, 
  open, 
  glasssquidScore, 
  resumeDetails 
}) => {

  return (
    <Dialog
      open={open}
      onClose={() => handleOpenClose()}
      className="preview-file-dialog"
      fullWidth
      maxWidth="lg"
      data-testid="scorecard-onclose"
    >
      <DialogContent dividers>
        <IconButton
          aria-label="Close Preview"
          onClick={() => handleOpenClose()}
          className="preview-dialog-close-btn"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          data-testid="scorecard-onclose"
        >
          <CloseIcon />
        </IconButton>
        <Item>
          <Card
            style={{
              overflowY: "auto",
            }}
          >
            <Container maxWidth="xl" className="submit-resume-form">
              <Grid container sx={{ justifyContent: "left" }}>
                <Grid item xs={12} md={12} style={{ paddingTop: 0 }}>
                  <>
                    <Grid container md={12}>
                      <Grid item md={3}>
                        <Item>
                          <Typography
                            variant="h6"
                            component="div"
                            className="abel  letter-spacing-normal primary-color-text"
                            sx={{ paddingTop: "0px" }}
                          >
                            Candidate Profile Match
                          </Typography>
                        </Item>
                        {resumeDetails?.email !==
                        "" ? (
                          <Item>
                            <Typography
                              variant="h6"
                              component="div"
                              className="abel  letter-spacing-normal primary-color-text"
                              sx={{ paddingTop: "0px" }}
                            >
                              Email
                            </Typography>
                          </Item>
                        ) : (
                          ""
                        )}
                        {resumeDetails?.phone?.[0] &&
                        resumeDetails?.phone?.[0] !==
                          "" ? (
                          <Item>
                            <Typography
                              variant="h6"
                              component="div"
                              className="abel  letter-spacing-normal primary-color-text"
                              sx={{ paddingTop: "0px" }}
                            >
                              Phone
                            </Typography>
                          </Item>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item md={2}>
                        <Item>
                          <Typography pt={"5px"}>
                            {
                              resumeDetails
                                ?.full_name
                            }
                          </Typography>
                        </Item>
                        {resumeDetails?.email ? (
                          <Item>
                            <Typography pt={"6px"}>
                              {resumeDetails?.email}
                            </Typography>{" "}
                          </Item>
                        ) : (
                          ""
                        )}
                        {resumeDetails?.phone ? (
                          <Item>
                            <Typography pt={"9px"}>
                              {
                                resumeDetails
                                  ?.phone[0]
                              }
                            </Typography>
                          </Item>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item md={7} >
                        <Grid container md={12} style={{ justifyContent: "right" }}>
                          <Grid
                            item
                            md={1}
                            style={{ textAlignLast: "center" }}
                          >
                            <Item>
                              <Typography pt={"5px"}>
                                <CircularProgressWithLabel
                                  value={
                                    glasssquidScore?.recommendation
                                      ?.matching_percent as number
                                  }
                                />
                              </Typography>
                              <Typography>Skills</Typography>
                            </Item>
                          </Grid>
                          <Grid
                            item
                            md={1}
                            style={{ textAlignLast: "center" }}
                          >
                            <Item>
                              <Typography pt={"5px"}>
                                <CircularProgressWithLabel
                                  value={
                                    glasssquidScore?.recommendation
                                      ?.domain_matching_percent as number
                                  }
                                />
                              </Typography>
                              <Typography>Domain</Typography>
                            </Item>
                          </Grid>
                          <Grid
                            item
                            md={1}
                            
                          >
                            <Item>
                              <Typography pt={"5px"}>
                                <CircularProgressWithLabel
                                  value={
                                    glasssquidScore?.recommendation
                                      ?.industry_matching_percent as number
                                  }
                                />
                              </Typography>
                              <Typography>Industry</Typography>
                            </Item>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container md={12}>
                      <Grid item md={12}>
                        <Typography
                          component="div"
                          className="abel  letter-spacing-normal primary-color-text"
                          sx={{ paddingTop: "0px" }}
                        >
                          Key Skills:
                        </Typography>
                      </Grid>
                      <Divider
                        sx={{
                          color: "primary",
                          width: "100%",
                          bgcolor: "secondary.light",
                        }}
                      ></Divider>
                      <Grid item md={12} pt={"10px"}>
                        <Stack
                          direction="row"
                          spacing={2}
                          sx={{ marginBottom: "35px" }}
                          style={{ flexWrap: "wrap" }}
                          className="static-width-chip"
                          flexWrap={"wrap"}
                        >
                          {resumeDetails?.key_skills?.map(
                            (x:any, i:any) => {
                              return (
                                <Chip
                                  key={x ?? i}
                                  color="primary"
                                  label={x}
                                  style={{
                                    marginTop: "10px",
                                    marginLeft: "16px",
                                  }}
                                />
                              );
                            }
                          )}
                        </Stack>
                      </Grid>
                    </Grid>
                    <Grid container md={12} pt={"10px"}>
                      <Grid item md={12}>
                        <Typography
                          component="div"
                          className="abel  letter-spacing-normal primary-color-text"
                          sx={{ paddingTop: "0px" }}
                        >
                          Matching Keyword:
                        </Typography>
                      </Grid>
                      <Divider
                        sx={{
                          color: "primary",
                          width: "100%",
                          bgcolor: "secondary.light",
                        }}
                      ></Divider>
                      <Grid item md={12} pt={"10px"}>
                        <Grid container>
                          <Grid md={1}>
                            <Typography pt={"14px"}>Concept</Typography>
                          </Grid>
                          <Grid md={11}>
                            <Stack
                              direction="row"
                              spacing={2}
                              className="static-width-chip"
                              flexWrap={"wrap"}
                            >
                              {glasssquidScore?.recommendation?.common_keywords?.concept?.map(
                                (x:any, i:any) => {
                                  return (
                                    <Chip
                                      key={ x ?? i}
                                      color="primary"
                                      label={x}
                                      style={{
                                        backgroundColor: "#27B3AA",
                                        marginTop: "10px",
                                        marginLeft: "16px",
                                      }}
                                    />
                                  );
                                }
                              )}
                            </Stack>
                          </Grid>
                        </Grid>
                        <Grid container pt={"20px"}>
                          <Grid md={1}>
                            <Typography pt={"14px"}>
                              Job Functions
                            </Typography>
                          </Grid>
                          <Grid md={11}>
                            <Stack
                              direction="row"
                              spacing={2}
                              className="static-width-chip"
                              flexWrap={"wrap"}
                            >
                              {glasssquidScore?.recommendation?.common_keywords?.role?.map(
                                (x:any, i:any) => {
                                  return (
                                    <Chip
                                       key={ x ?? i}
                                      color="primary"
                                      label={x}
                                      style={{
                                        backgroundColor: "#27B3AA",
                                        marginTop: "10px",
                                        marginLeft: "16px",
                                      }}
                                    />
                                  );
                                }
                              )}
                            </Stack>
                          </Grid>
                        </Grid>
                        <Grid container pt={"20px"}>
                          <Grid md={1}>
                            <Typography pt={"14px"}>
                              Tools & Technology
                            </Typography>
                          </Grid>
                          <Grid md={11}>
                            <Stack
                              direction="row"
                              spacing={2}
                              className="static-width-chip"
                              flexWrap={"wrap"}
                            >
                              {glasssquidScore?.recommendation?.common_keywords?.toolsTechnologies?.map(
                                (x:any, i:any) => {
                                  return (
                                    <Chip
                                      key={ x ?? i}
                                      color="primary"
                                      style={{
                                        backgroundColor: "#27B3AA",
                                        marginTop: "10px",
                                        marginLeft: "16px",
                                      }}
                                      label={x}
                                    />
                                  );
                                }
                              )}
                            </Stack>
                          </Grid>
                        </Grid>
                        <Grid container pt={"20px"}>
                          <Grid md={1}>
                            <Typography pt={"14px"}>
                              Domain
                            </Typography>
                          </Grid>
                          <Grid md={11}>
                            <Stack
                              direction="row"
                              spacing={2}
                              className="static-width-chip"
                              flexWrap={"wrap"}
                            >
                              {glasssquidScore?.recommendation?.common_keywords?.domain?.map(
                                (x:any, i:any) => {
                                  return (
                                    <Chip
                                      key={ x ?? i}
                                      color="primary"
                                      style={{
                                        backgroundColor: "#27B3AA",
                                        marginTop: "10px",
                                        marginLeft: "16px",
                                      }}
                                      label={x}
                                    />
                                  );
                                }
                              )}
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container md={12} pt={"30px"}>
                      <Grid item md={12}>
                        <Typography
                          component="div"
                          className="abel  letter-spacing-normal primary-color-text"
                          sx={{ paddingTop: "0px" }}
                        >
                          Missing Keywords:
                        </Typography>
                      </Grid>
                      <Divider
                        sx={{
                          color: "primary",
                          width: "100%",
                          bgcolor: "secondary.light",
                        }}
                      ></Divider>
                      <Grid item md={12} pt={"10px"}>
                        <Grid container>
                          <Grid md={1}>
                            <Typography pt={"14px"}>Concept</Typography>
                          </Grid>
                          <Grid md={11}>
                            <Stack
                              direction="row"
                              spacing={2}
                              className="static-width-chip"
                              flexWrap={"wrap"}
                            >
                              {glasssquidScore?.recommendation?.recommended_keywords?.concept?.map(
                                (x:any, i:any) => {
                                  return (
                                    <Chip
                                      key={ x ?? i}
                                      color="primary"
                                      label={x}
                                      style={{
                                        backgroundColor: "#ED893E",
                                        marginTop: "10px",
                                        marginLeft: "16px",
                                      }}
                                    />
                                  );
                                }
                              )}
                            </Stack>
                          </Grid>
                        </Grid>
                        <Grid container pt={"20px"}>
                          <Grid md={1}>
                            <Typography pt={"14px"}>
                              Job Functions
                            </Typography>
                          </Grid>
                          <Grid md={11}>
                            <Stack
                              direction="row"
                              spacing={2}
                              className="static-width-chip"
                              flexWrap={"wrap"}
                            >
                              {glasssquidScore?.recommendation?.recommended_keywords?.role?.map(
                                (x:any, i:any) => {
                                  return (
                                    <Chip
                                      key={ x ?? i}
                                      color="primary"
                                      label={x}
                                      style={{
                                        backgroundColor: "#ED893E",
                                        marginTop: "10px",
                                        marginLeft: "16px",
                                      }}
                                    />
                                  );
                                }
                              )}
                            </Stack>
                          </Grid>
                        </Grid>
                        <Grid container pt={"20px"}>
                          <Grid md={1}>
                            <Typography pt={"14px"}>
                              Tools & Technology
                            </Typography>
                          </Grid>
                          <Grid md={11}>
                            <Stack
                              direction="row"
                              spacing={2}
                              className="static-width-chip"
                              flexWrap={"wrap"}
                            >
                              {glasssquidScore?.recommendation?.recommended_keywords?.toolsTechnologies?.map(
                                (x:any, i:any) => {
                                  return (
                                    <Chip
                                      key={ x ?? i}
                                      color="primary"
                                      style={{
                                        backgroundColor: "#ED893E",
                                        marginTop: "10px",
                                        marginLeft: "16px",
                                      }}
                                      label={x}
                                    />
                                  );
                                }
                              )}
                            </Stack>
                          </Grid>
                        </Grid>
                        <Grid container pt={"20px"}>
                          <Grid md={1}>
                            <Typography pt={"14px"}>
                              Domain
                            </Typography>
                          </Grid>
                          <Grid md={11}>
                            <Stack
                              direction="row"
                              spacing={2}
                              className="static-width-chip"
                              flexWrap={"wrap"}
                            >
                              {glasssquidScore?.recommendation?.recommended_keywords?.domain?.map(
                                (x:any, i:any) => {
                                  return (
                                    <Chip
                                      key={ x ?? i}
                                      color="primary"
                                      style={{
                                        backgroundColor: "#ED893E",
                                        marginTop: "10px",
                                        marginLeft: "16px",
                                      }}
                                      label={x}
                                    />
                                  );
                                }
                              )}
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container md={12} pt={"30px"}>
                      <div
                        className="tool-tech-wrapper"
                        dangerouslySetInnerHTML={{
                          __html:
                            glasssquidScore?.annotatedJobHtml ??
                            "",
                        }}
                      />
                    </Grid>
                    <Grid container md={12}>
                      <Grid item md={10} style={{ textAlign: "right" }}>
                        <img src={glassquidLogo} alt="" />
                      </Grid>
                      <Grid item md={2}>
                        <Typography
                          style={{ color: "#4540DB", fontSize: "24px" }}
                        >
                          Powered By
                        </Typography>
                        <Typography style={{ fontSize: "32px" }}>
                          glasssquid.io
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                </Grid>
              </Grid>
            </Container>
          </Card>
        </Item>
      </DialogContent>
    </Dialog>
  );
};
export default RecommendJobScorecardDialog;
