import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import UploadIcon from '@mui/icons-material/Upload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { MAX_PHOTO_SIZE, SUPPORTED_FORMATS_IMAGE } from "../../../data/supportedImageFormat";
import { ErrorMessage } from "../../ui/ErrorMessage";

interface UploadSignatureProps {
  onSaveSignature: (signatureDataUrl: string | null) => void;
}

const UploadSignature: React.FC<UploadSignatureProps> = ({ onSaveSignature }) => {
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [signatureDataUrl, setSignatureDataUrl] = useState<string | null>(null);
  const [error, setError] = useState("");
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file: File | undefined = event.target.files?.[0];    
    setError("");
    if (!file) return;

    if (file.size > MAX_PHOTO_SIZE) {
      setError("This file exceeds our 2MB size limit. Please compress the file or choose a smaller one.");
      return;
    }
    
    if (!SUPPORTED_FORMATS_IMAGE.includes(file.type)) {
      setError("Unsupported file type. Please try uploading a supported format");
      return;
    }
    
    const reader = new FileReader();
    
    reader.onloadend = () => {
      const result = reader.result as string;
      setSignatureDataUrl(result);
      setUploadedFile(file);
      // Reset the file input to allow reuploading the same file
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    };
    
    reader.readAsDataURL(file);
  };

  const handleClear = () => {
    setUploadedFile(null);
    setSignatureDataUrl(null);
  };

  const handleSave = () => {
    if (signatureDataUrl) {
      onSaveSignature(signatureDataUrl.split(",")[1]);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        width: "418px",
        margin: "1px 8px",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {uploadedFile ? (
        <Box>
          <img
            src={signatureDataUrl || ""}
            alt="Signature Preview"
            style={{ maxWidth: "100%", maxHeight: "150px" }}
          />
        </Box>
      ) : (
        <>
        <Box className="file-upload-wrapper" mt={2} mb={0.25}>
          <Button 
            variant="contained" 
            component="label" 
            size="medium"
            endIcon={<UploadIcon />}
            >
            Upload Signature
            <input 
              type="file"
              accept="image/png, image/jpeg, image/jpg" 
              hidden 
              onChange={handleFileUpload} 
              ref={fileInputRef}
              data-testid="file-upload-input"
              />
          </Button>
          <Typography fontSize={"10px"} sx={{ marginTop: 1 }}>
            Upload a photo of your signature here.
          </Typography>
          <Typography fontSize={"10px"}>
            (Supported formats: png, jpg, jpeg)
          </Typography>
          <Typography fontSize={"10px"} sx={{ marginBottom: 1 }}>
            Max file size: 2 MB
          </Typography>
        </Box>
          {
            error &&
            <Box sx={{maxWidth:"340px"}}>
              <ErrorMessage errorText={error} />
            </Box>
          }
        </>
      )}
      <Box
        sx={{ display: "flex", justifyContent: "center", gap: 2 }}
        marginTop={2}
      >
         {uploadedFile &&
        <Button 
          variant="outlined" 
          color="primary" 
          onClick={handleClear}
          disabled={!uploadedFile}
          endIcon={<DeleteOutlineIcon />}
          >
          Delete
        </Button>
        }
        {uploadedFile && (
          <Button 
            variant="outlined" 
            color="primary" 
            component="label" 
            endIcon={<UploadIcon />}
            >
            Reupload Signature
            <input 
              type="file"
              accept="image/png, image/jpeg, image/jpg" 
              hidden 
              onChange={handleFileUpload} 
              ref={fileInputRef}
              />
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={!uploadedFile}
          endIcon={<CheckCircleIcon />}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default UploadSignature;
