import { createSvgIcon } from "@mui/material/utils";

export const Thumbsup = createSvgIcon(
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.66671 8.33343H4.16671C4.38772 8.33343 4.59968 8.42122 4.75596 8.5775C4.91224 8.73378 5.00004 8.94574 5.00004 9.16676V16.6668C5.00004 16.8878 4.91224 17.0997 4.75596 17.256C4.59968 17.4123 4.38772 17.5001 4.16671 17.5001H1.66671C1.44569 17.5001 1.23373 17.4123 1.07745 17.256C0.921171 17.0997 0.833374 16.8878 0.833374 16.6668V9.16676C0.833374 8.94574 0.921171 8.73378 1.07745 8.5775C1.23373 8.42122 1.44569 8.33343 1.66671 8.33343ZM1.66671 9.16676V16.6668H4.16671V9.16676H1.66671ZM14.7634 15.8543L17.2642 11.6876C17.4142 11.4376 17.5 11.1459 17.5 10.8334V10.0001C17.5 9.55806 17.3244 9.13414 17.0119 8.82158C16.6993 8.50902 16.2754 8.33343 15.8334 8.33343H11.1584L12.3725 3.80009L12.3767 3.78509C12.4147 3.64384 12.4148 3.4951 12.377 3.35379C12.3393 3.21249 12.265 3.0836 12.1617 2.98009L7.15421 7.98759C6.99926 8.14232 6.87643 8.32615 6.79276 8.52852C6.7091 8.73088 6.66626 8.94778 6.66671 9.16676V15.0001C6.66671 15.4421 6.8423 15.866 7.15486 16.1786C7.46742 16.4912 7.89135 16.6668 8.33337 16.6668H13.3334C13.9417 16.6668 14.4734 16.3418 14.7634 15.8543ZM18.3334 10.8334C18.3334 11.3284 18.19 11.7893 17.9417 12.1768L15.52 16.2126C15.3036 16.6028 14.9867 16.928 14.6022 17.1544C14.2177 17.3808 13.7796 17.5002 13.3334 17.5001H8.33337C7.67033 17.5001 7.03445 17.2367 6.56561 16.7679C6.09677 16.299 5.83337 15.6631 5.83337 15.0001V9.16676C5.83337 8.47676 6.11254 7.85259 6.56421 7.40009L12.1617 1.80176L12.7509 2.39092C12.9617 2.60176 13.1121 2.86528 13.1864 3.15403C13.2607 3.44278 13.2562 3.74618 13.1734 4.03259L12.2442 7.50009H15.8334C16.4964 7.50009 17.1323 7.76348 17.6011 8.23232C18.07 8.70117 18.3334 9.33705 18.3334 10.0001V10.8334Z"
      fill="#4540DB"
    />
  </svg>,
  "Thumbsup"
);
