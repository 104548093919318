import pathToRegexp from "path-to-regexp";
import { Navigate, useLocation } from "react-router-dom";
import superAdminRoutes from "../../components/routes/superAdminRoutes";
import { UserAuth } from "../../models/interfaces";
import getLoggedInUserRole from "../utils/getLoggedInUserRole";
import PermissionRoute from "./PermissionRoute";
const SuperAdminRoute = ({ children }) => {
  const location = useLocation();
  const userRole = getLoggedInUserRole();


  if (
    userRole !== UserAuth.NotLoggedIn &&
    userRole === UserAuth.SuperAdmin
  ) {
    // Check if the current route requires a permissionId
    const route = superAdminRoutes.find((route) => {
      const keys = [];
      const regexp = pathToRegexp(route.path, keys);
      const match = regexp.exec(location.pathname);
      const isExact = match && match[0] === location.pathname;
      return isExact && route.permissionId;
    });

    if (route && route.permissionId) {
      // Check if the user has the required permission for the current route
      return (
        <PermissionRoute permissionId={route?.permissionId}>
          {children}
        </PermissionRoute>
      );
    }

    return children;
  }

  return (
    <Navigate
      to="/adminSignin"
      replace
      state={{ redirectUrl: location.pathname }}
    />
  );
};

export default SuperAdminRoute;
