import { createSvgIcon } from "@mui/material/utils";

export const Eye = createSvgIcon(
  <svg viewBox="0 0 37 21" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.30731 9.53819C5.42745 4.60633 11.6202 0.25 18.5001 0.25C25.3801 0.25 31.5728 4.60633 35.693 9.53819L36.4964 10.4999L35.693 11.4616C31.5729 16.3933 25.38 20.75 18.5001 20.75C11.6202 20.75 5.42736 16.3933 1.30731 11.4616L0.503906 10.4999L1.30731 9.53819ZM4.44966 10.4999C7.81711 14.1873 12.2209 17.0812 16.8581 17.6488C13.5986 16.9032 11.1668 13.9856 11.1668 10.5C11.1668 7.0144 13.5986 4.09678 16.8582 3.35119C12.2209 3.91866 7.81715 6.8124 4.44966 10.4999ZM20.1421 3.35119C23.4017 4.09678 25.8335 7.0144 25.8335 10.5C25.8335 13.9856 23.4017 16.9032 20.1422 17.6488C24.7793 17.0812 29.1832 14.1873 32.5506 10.4999C29.1831 6.8124 24.7793 3.91866 20.1421 3.35119ZM18.5001 6.16667C16.1069 6.16667 14.1668 8.10677 14.1668 10.5C14.1668 12.8932 16.1069 14.8333 18.5001 14.8333C20.8934 14.8333 22.8335 12.8932 22.8335 10.5C22.8335 8.10677 20.8934 6.16667 18.5001 6.16667ZM18.5003 9.8125C18.1206 9.8125 17.8128 10.1203 17.8128 10.5C17.8128 10.8797 18.1206 11.1875 18.5003 11.1875C18.88 11.1875 19.1878 10.8797 19.1878 10.5C19.1878 10.1203 18.88 9.8125 18.5003 9.8125ZM14.8128 10.5C14.8128 8.46345 16.4637 6.8125 18.5003 6.8125C20.5368 6.8125 22.1878 8.46345 22.1878 10.5C22.1878 12.5366 20.5368 14.1875 18.5003 14.1875C16.4637 14.1875 14.8128 12.5366 14.8128 10.5Z"
    />
  </svg>,
  "Eye"
);
