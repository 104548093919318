import React from 'react';
import Chip from '@mui/material/Chip';
import './chip.scss';

interface StatusChipProps {
  status: string;
  color?: "default" | "success" | "error" | "warning" | "info" | "primary" | "secondary";
}

const StatusChip: React.FC<StatusChipProps> = ({ status, color = 'default' }) => {

    const _getStatusClassColor = (status: string)=>{
        switch (status) {
            case 'Approved':
              return "successChip";
              case 'Active':
                return "successChip";
            case 'Rejected':
              return "rejectedChip";
              case 'Inactive':
                return "rejectedChip";
            case 'Pending':
              return "pendingChip";
            case 'Blacklisted':
              return "backlistedChip";
            case 'Suspended':
              return "suspendedChip";
            case 'MarkIncomplete':
              return "markInCompleteChip";
            default:
              return "";
          }
    }

  return (
    <Chip
      label={status}
      className={`chipProps ${_getStatusClassColor(status)}`}
    />
  );
};

export default StatusChip;
