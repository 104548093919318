import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import moment from "moment";
import * as React from "react";

interface PayrateHistoryPopupProps {
  rateHistory: any;
  isOpenPopup: boolean;
  handleClose: any;
  contractStatus:string
}

const PayrateHistoryPopup: React.FunctionComponent<PayrateHistoryPopupProps> =
  ({ rateHistory, handleClose, isOpenPopup,contractStatus }) => {
    React.useEffect(() => {}, []);
    return (
      <div>
        <Dialog
          open={isOpenPopup}
          onClose={() => handleClose()}
          aria-labelledby="viewRecruiterPopup-title"
          aria-describedby="viewRecruiterPopup-description"
        >
          <Paper elevation={5} style={{ backgroundColor: "#E6E9FB" }}>
            <DialogTitle sx={{ m: 0, p: 2 }}>
              <IconButton
                aria-label="close"
                onClick={() => handleClose()}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="viewRecruiterPopup-description">
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography
                      align="center"
                      style={{
                        fontSize: "2.0rem",
                        fontWeight: "400",
                        letterSpacing: "5px",
                      }}
                    >
                      Change Rate History
                    </Typography>
                  </Grid>
                  <Divider
                    sx={{
                      height: "1px",
                      width: "11.75rem",
                      margin: "0 auto",
                      background: "#4540DB",
                    }}
                  />
                  <Grid item xs={12}>
                    <Typography
                      color="#4F4F4F"
                      style={{ fontSize: "1.2rem", margin: 7 }}
                    >
                      Here are the change request for the Pay Rate with the
                      effective date:
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} className="recruiterInfoBox">
                      <Grid item xs={4}>
                        <Typography color="#929292">
                          Previous Pay Rate
                        </Typography>
                        <Typography color="#000000">
                          USD {rateHistory?.oldPayRate}{" "}
                          {rateHistory?.rateTypeValue}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography color="#929292">Revised Pay Rate</Typography>
                        <Typography color="#000000">
                          USD {rateHistory?.newPayRate}{" "}
                          {rateHistory?.rateTypeValue}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography color="#929292">Effective Date</Typography>
                        <Typography color="#000000">
                          {moment(rateHistory?.effectiveDate).format(
                            "MMMM DD, YYYY"
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} mt={2}>
                        <Typography color="#929292">Change Reason</Typography>
                        <Typography color="#000000">
                          {rateHistory?.changeRequestReason}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} mt={2}>
                        <Typography color="#929292">
                          Approval Status{" "}
                        </Typography>
                        <Typography color="#000000">By Client</Typography>
                      </Grid>
                      <Grid item xs={6} mt={2}>
                      {rateHistory?.requestStatusValue == "Approved" ? (
                          <>
                          <Typography color="#000000" pt={2.3}></Typography>
                            <CheckCircleIcon
                              style={{ color: "#04C875" }}
                              className="sucessStsIcon"
                            />
                            <span style={{ verticalAlign: "top" }}>
                            &nbsp;&nbsp;{rateHistory?.requestStatusValue }
                            </span>
                          </>
                        ) : (
                          <span style={{ verticalAlign: "top" }}>
                            {rateHistory?.requestStatusValue }
                          </span>
                        )}
                        
                      </Grid>
                      <Grid item xs={12} mt={2}>
                        <Typography color="#929292">
                          Contract Status{" "}
                        </Typography>
                        <Typography color="#000000">{contractStatus}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
          </Paper>
        </Dialog>
      </div>
    );
  };

export default PayrateHistoryPopup;
