import React from 'react';
import Grid from '@mui/material/Grid';
import LabelChip from '../LabelChip';
import SectionHeading from '../../../../ui/Form/Heading/SectionHeading';
import { Divider } from '@mui/material';

interface IndustryProps {
  industry: string[];
}

const ScorecardIndustry: React.FC<IndustryProps> = ({ industry }) => {
  
  return (
    <Grid container spacing={2}>
        <Grid  item xs={12} >
            <SectionHeading
                title='Industry'
            />
             <Divider className="borderedDivider-color" />
        </Grid>
            
        {industry.map((industryName) => (
            <Grid item key={industryName} >            
            <LabelChip                 
                label={industryName}
            />   
            </Grid>        
            ))}
    </Grid>
  );
};

export default ScorecardIndustry;
