import React from "react";
import Grid from "@mui/material/Grid";
import LabelChip from "../LabelChip";
import SectionHeading from "../../../../ui/Form/Heading/SectionHeading";

interface ScorecardCandidateInfoProps {
  education: string[];
  className: any;
}

const KeywordsEducation: React.FC<ScorecardCandidateInfoProps> = ({
  education,
  className="",
}) => {
  return (
    <Grid container spacing={2} className="keyword-margin">
      <Grid item xs={12}>
        <SectionHeading title="Education" />
      </Grid>

      {education?.length> 0 && education?.map((educationLabel) => (
        <Grid item key={educationLabel} >
          <LabelChip label={educationLabel} className={className} />
        </Grid>
      ))}
    </Grid>
  );
};

export default KeywordsEducation;
