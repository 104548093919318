import { createSlice } from "@reduxjs/toolkit";

export interface CandidateStateTypeProps {
  candidateFilter?: any;
  candidateFilterCandidates: any[];
  candidateFilterRecruiters?: any[];
}

const candidateinitialState: CandidateStateTypeProps = {
  candidateFilter: {
    searchKeywords: "",
    location: [],
    experience: [],
    workAuthorizationStatuses: [],
    skills: [],
    payRate: "",
    contractStatus: [],
    pageIndex: 1,
    pageSize: 10,
    sortColumn: "",
    sortOrder: "Descending",
    isSorted: true,
    totalRecord: 0,
    candidateGuids: [],
    recruiterGuids: [],
  },
  candidateFilterCandidates: [],
  candidateFilterRecruiters: [],
};

export const candidateSlice = createSlice({
  name: "candidate",
  initialState: candidateinitialState,
  reducers: {
    setCandidateFilter: (state, action: any) => {
      state.candidateFilter = action.payload.filter;
      state.candidateFilterCandidates =
        action.payload?.selectedCandidates ?? state.candidateFilterCandidates;
      state.candidateFilterRecruiters =
        action.payload?.selectedRecruiters ?? state.candidateFilterRecruiters;
    },
    clearCandidateFilter: (state) => {
      state.candidateFilter = candidateinitialState.candidateFilter;
      state.candidateFilterCandidates = [];
      state.candidateFilterRecruiters = [];
    },
  },
});

export const { setCandidateFilter, clearCandidateFilter } =
  candidateSlice.actions;

export const candidateReducer = candidateSlice.reducer;
