import React, { useEffect } from 'react'
import { Autocomplete, Chip, CircularProgress, TextField } from '@mui/material'
import useApi from '../../hooks/useApi'
import constructName from '../../utils/constructName'
import { clientFilter } from '../../Services/clientService'
import ClearIcon from '@mui/icons-material/Clear';

export const ClientAutocomplete = (props) => {
    const initialRecruiterFilterState = {
        "pageIndex": 1,
        "pageSize": 20,
        "sortColumn": "modifiedDate",
        "sortOrder": "Descending",
        "isSorted": true,
        "totalRecord": 0,
        "searchKeyword": "",
        "industries": [
         
        ],
        "locations": [
        
        ],
        "statuses": 
            props?.getListUserStatus ? props?.getListUserStatus : ["Approved"]
        
      };

    const [filter, setFilter] = React.useState(
      initialRecruiterFilterState
    );
    const onIndustryInputChange = (e, value) => {
        
        setFilter({ ...filter, searchKeyword: value });
    }
    let admin = props.admin //formik.values.industries
    const allAdmins = useApi(() => clientFilter(filter));
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            allAdmins.request();
           
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [filter.searchKeyword]);

    const getInputLabel = (option)=>{
        const companyName = constructName(option.companyName);
        const emailSuffix = props?.showEmail && option.email ? ` (${option?.email})` : '';
        return `${companyName}${emailSuffix}`;
    }
    return (
        <Autocomplete
            freeSolo={props.multiple} //remove dropdown arrow
            readOnly={props.readOnly}
            autoComplete="off"
            noOptionsText={allAdmins?.loading ? 'Loading...' : "No options"}
  
            multiple={props.multiple}
            id="size-small-standard-multi3"
            size="medium"
            onInputChange={onIndustryInputChange}
            onBlur={props.onBlur}

            onChange={props.onChange}
            options={allAdmins?.data?.entityList || []}            
            value={admin}
            defaultValue={admin}
            getOptionLabel={(option) => getInputLabel(option)}
            clearIcon={<ClearIcon fontSize='small' onClick={()=>{props?.handleClear()}} />}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        key={ getInputLabel(option) ?? index}
                        color="primary"
                        size="medium"
                        label={getInputLabel(option)}
                        {...getTagProps({ index })}
                    />
                ))

            }
            renderInput={(params) => (
                <TextField
                    required={props?.isReiqured}
                    {...params}
                    placeholder='Search by Name or Email'
                    variant="standard"
                    label={props.label}
                    
                    InputProps={{
                        ...params.InputProps,
                        disableUnderline: props?.readOnly,

                        endAdornment: (
                            <React.Fragment>
                                {allAdmins?.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {admin && params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}

                />
            )}
        />
    )
}
ClientAutocomplete.defaultProps = {
 
    showEmail: false,
  
  }
  
