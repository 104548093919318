import React, { FC } from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
  Box,
  Grid,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import SuccessIcon from '../../../../../assets/images/Approved.svg';
import { getNevLink } from '../../../../../utils/getFileFromUrl';

interface SaveAndContinueSuccessPopupProps {
  saveResumeSuccessDialog: boolean;
  handleClose: () => void;
}

const SaveAndContinueSuccessPopup: FC<SaveAndContinueSuccessPopupProps> = ({
  saveResumeSuccessDialog,
  handleClose,
}) => {
  const navigate = useNavigate();

  return (
    <Dialog
      onClose={handleClose}
      open={saveResumeSuccessDialog}
      PaperProps={{
        style: {
          background: "#FFF",
        },
      }}
    >
      <DialogContent>
        <IconButton
          aria-label="close"
          onClick={() => {
            navigate(getNevLink("/pendingsubmission"));
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContentText
          component="div"
          id="success-dialog-description"
          textAlign="center"
        >
          <figure>
            <img
              src={SuccessIcon}
              className="PopupStatusIcon"
              alt="Success Icon"
            />
          </figure>
          <Typography component="div" className="PopupPrimaryTitle" my={2}>
            Candidate Details Saved Successfully
          </Typography>
          <Typography
            component="div"
            fontWeight={600}
            my={2}
            className="PopupSecondaryTitle"
          >
            The candidate details were saved successfully. You can complete
            the submission from the pending submissions menu.
          </Typography>
          <Box my={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Button
                  className="roboto-font"
                  sx={{ marginLeft: "25px", marginTop: "5px", fontSize: "1rem" }}
                  variant="outlined"
                  onClick={() => {
                    navigate(getNevLink("/pendingsubmission"));
                  }}
                >
                  Go to Pending Submissions
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  className="roboto-font mobile-margin"
                  sx={{ marginTop: "5px", fontSize: "1rem" }}
                  variant="contained"
                  onClick={() => {
                    navigate(getNevLink("/jobs/all"));
                  }}
                >
                  Go to Browse Jobs now
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default SaveAndContinueSuccessPopup;
