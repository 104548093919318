import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import RadioSelectionField from '../../../../Common/Form/FormField/SingleSelection/RadioSelectionField';
import { RecruiterAssessmentQuetionType } from '../Model/RecruiterAssessmentType';
import CheckboxSelectionField from '../../../../Common/Form/FormField/MultiSelection/CheckboxSelectionField';
import TextInputField from '../../../../Common/Form/FormField/TextInput/TextInputField';
import SectionHeading from '../../../../ui/Form/Heading/SectionHeading';

interface Option {
  id: string;
  optionText: string;
  instructionText: string;
}

interface Question {
  id: string;
  question: string;
  instructionText: string;
  type: number;
  options: Option[];
}

interface Section {
  id: string;
  sectionTitle: string;
  questions: Question[];
}

interface Props {
  assessmentDetails: any;
  values: { sections: Section[] };
  errors: any;
  assesmentResponseValue:any;
  touched: any;
  setFieldValue: (field: string, value: any) => void;
}

const RecruiterAssessmentSection: React.FC<Props> = ({
  assessmentDetails,
  values,
  assesmentResponseValue,
  errors,
  touched,
  setFieldValue,
}) => {
  
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => {
    setExpanded(expanded === panel ? false : panel);
  };

  const handleExpandAll = () => {
    setExpanded('all');
  };

  const handleCollapseAll = () => {
    setExpanded(false);
  };

  const handleOnChange = (sectionId: string, questionId: string, value: any, type: number) => {
    const updatedSections = values?.sections?.map((section:any) => {
      if (section.id === sectionId) {
        return {
          ...section,
          questionResponses: section?.questionResponses?.map((question:Question) => {
            if (question.id === questionId) {
              if (type === RecruiterAssessmentQuetionType.SingleSelect) {
                return {
                  ...question,
                  options: [value],
                };
              } else if (type === RecruiterAssessmentQuetionType.MultiSelect) {
                return {
                  ...question,
                  options: value,
                };
              } else {
                return {
                  ...question,
                  descriptiveAnswer: value,
                };
              }
            }
            return question;
          }),
        };
      }
      return section;
    });

    setFieldValue('sections', updatedSections);
  };

  const renderQuestion = (sectionId: string, question: Question, sectionIndex: number, questionIndex: number) => {
    const questionError = errors?.sections?.[sectionIndex]?.questionResponses?.[questionIndex];
    const questionTouched = touched?.sections?.[sectionIndex]?.questionResponses?.[questionIndex];
    const selectedQuestionValue = assesmentResponseValue?.sections?.[sectionIndex]?.questionResponses?.[questionIndex];
  
    switch (question.type) {
      case RecruiterAssessmentQuetionType.SingleSelect:
        return (
          <RadioSelectionField
            name={question.id}
            isRequired={true}
            defaultSelectedOption={selectedQuestionValue?.options[0] ?? {}}
            onHandleChange={(value) => handleOnChange(sectionId, question.id, value, question.type)}
            onBlur={() => {}}
            questionDetails={question}
            error={Boolean(questionTouched && questionError?.options)}
            helperText={questionTouched && questionError?.options ? questionError.options : ''}
          />
        );
      case RecruiterAssessmentQuetionType.MultiSelect:
        return (
          <CheckboxSelectionField
            name={question.id}
            isRequired={true}
            SelectedOptions={selectedQuestionValue?.options ?? []}
            onHandleChange={(value) => handleOnChange(sectionId, question.id, value, question.type)}
            onBlur={() => {}}
            questionDetails={question}
            error={Boolean(questionTouched && questionError?.options)}
            helperText={questionTouched && questionError?.options ? questionError.options : ''}
          />
        );
      default:
        return (
          <TextInputField
            name={question.id}
            isRequired={true}
            selectedValue={selectedQuestionValue?.descriptiveAnswer ?? ""}
            onHandleChange={(value) => handleOnChange(sectionId, question.id, value, question.type)}
            onBlur={() => {}}
            questionDetails={question}
            error={Boolean(questionTouched && questionError?.descriptiveAnswer)}
            helperText={questionTouched && questionError?.descriptiveAnswer ? questionError.descriptiveAnswer : ''}
          />
        );
    }
  };

  useEffect(() => {
    if (assessmentDetails?.sections?.length) {
      setExpanded(assessmentDetails.sections[0].id);
    }
  }, [assessmentDetails]);

  const isSectionTouched = (sectionIndex: number) => {
    return touched?.sections?.[sectionIndex];
  };

  const isSectionComplete = (section: Section, sectionIndex: number) => {
    return section.questions.every((question, questionIndex) => {
      const questionTouched = touched?.sections?.[sectionIndex]?.questionResponses?.[questionIndex];
      const questionError = errors?.sections?.[sectionIndex]?.questionResponses?.[questionIndex];
      return questionTouched && !questionError;
    });
  };

  const getSectionError = (section: Section, sectionIndex: number) => {
    return section.questions.some((_, questionIndex) => {
      return errors?.sections?.[sectionIndex]?.questionResponses?.[questionIndex];
    });
  };

  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        {expanded !== 'all' ? (
          <Button
            variant='outlined'
            color='primary'
            onClick={handleExpandAll}
            endIcon={<AddCircleOutlinedIcon />}
            sx={{ marginBottom: 2 }}
          >
            Expand All Sections
          </Button>
        ) : (
          <Button
            data-testid="primary"
            variant='outlined'
            color='primary'
            onClick={handleCollapseAll}
            endIcon={<RemoveCircleOutlineIcon />}
          >
            Collapse All Sections
          </Button>
        )}
      </Box>
      {assessmentDetails?.sections?.map((section:any, sectionIndex: number) => (
        <Accordion
          key={section.id}
          expanded={expanded === section.id || expanded === 'all'}
          onChange={() => handleChange(section.id)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${section.id}-content`}
            id={`${section.id}-header`}
          >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <SectionHeading title={section.sectionTitle} />
              {isSectionTouched(sectionIndex) &&  isSectionComplete(section, sectionIndex) && (
                <CheckCircleIcon color="success" sx={{ marginLeft: 1 }} />
              ) }
               {isSectionTouched(sectionIndex) &&  getSectionError(section, sectionIndex) && <ErrorIcon  color='error' sx={{ marginLeft: 1 }}/> }
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {section.questions.map((question: Question, questionIndex: number) => (
              <div key={question.id}>
                {renderQuestion(section.id, question, sectionIndex, questionIndex)}
              </div>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default RecruiterAssessmentSection;
