import pathToRegexp from "path-to-regexp";
import { Outlet, useLocation } from 'react-router-dom';
import getPathName from '../../utils/getPathName';
import Header from '../Common/Navigation/Header';

export default function HeaderLayout (props){
  let { pathname } = useLocation();
  pathname = getPathName(pathname);

  const {headerShown} = props

  const isMatch = headerShown.some((route) => {
    const keys = [];
    const pattern = pathToRegexp(route, keys);
    const match = pattern.exec(pathname);
    return match; // Return true if there's a match, otherwise false
  });
    //list of pathNames

  return (
    <>
     {isMatch && <Header />} 
    
      <Outlet />
    </>
  );
}