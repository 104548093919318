import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  MenuList,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetCandidateFromSubmission,
  GetClientsFromSubmission,
} from "../../../Services/candidateService";
import {
  GetRecruiters,
} from "../../../Services/recruiterService";
import getLoggedInUserRole from "../../../Shared/utils/getLoggedInUserRole";
import getUserDetails from "../../../Shared/utils/getUserDetails";
import { handleCandidateFilter, handleClientFilter, handleRecruiterFilter, updateSubmissionFilter } from "../../../features/submission/submissionSlice";
import useApi from "../../../hooks/useApi";
import { RecruiterStatus, UserAuth } from "../../../models/interfaces";
import { AppState } from "../../../store/AppState";
import constructName from "../../../utils/constructName";
import { CheckBoxSquareIcon } from "../../Common/CheckBoxSquareIcon";

const Wrapper = styled("div")({
  fontFamily: "Roboto !important !important",
});

interface SubmissionFilterProps {
  anchorEl: any;
  onClose: (event: any) => void;
  statusData : any

}

export interface SubmissionFilterRef {
  handleClearAll: () => void;
}

const SubmissionFilter = (
  props: React.PropsWithChildren<SubmissionFilterProps>, 
  ref: React.ForwardedRef<SubmissionFilterRef>
) => {
  const { filterType } = useSelector(
    (state: AppState) => state.submissionReducer
  );
  const { recruiterFilter,clientNames,candidateGuids,recruiterGuid,durationFilter } = useSelector(
    (state: AppState) => state.submissionReducer[filterType]);
  const userRole = getLoggedInUserRole();

  const userDetails = getUserDetails();
  const dispatch = useDispatch<any>();
  const { anchorEl,statusData } = props;
  const open = Boolean(anchorEl);
  let recruiterFilterPermission = (userRole === UserAuth.Client || userRole === UserAuth.ClientTeamMember) ? true : false
  let clientFilterPermission = userRole !== UserAuth.Client && userRole !== UserAuth.ClientTeamMember

  const [value, setValue] = React.useState(recruiterFilterPermission? 1 : 0);

 
  const [recuiterFilter, setrecuiterFilter] = React.useState({
    pageIndex: 1,
    pageSize: 8,
    sortColumn: "DateSignedUp",
    sortOrder: "Descending",
    isSorted: true,
    totalRecord: 0,
    searchKeywords: "",
    status: [RecruiterStatus[1]],
    filterByMasterData: {
      locationFilterInputs: [],
      domainFilterInputs: [],
      industryFilterInputs: [],
      skillsFilterInputs: [],
    },
  });

  const [clientFilterState, setClientFilterState] = React.useState({
    recruiterGuid: userRole === UserAuth.Recruiter ? userDetails?.id : "",
    jobId: "",
    searchKeywords: "",
    location: "",
    statuses: "",
    contractType: "",
    showPendingSubmissions: false,
    recruiterFilter: "",
    statusIds: statusData?.map(x=>x.id),
    durationFilter: durationFilter || {
      duration: "0",
      durationType: "All",
      fromDate: "",
      toDate: "",
    },
    recruiterGuids: recruiterGuid || [],
    candidateGuids: [],
    clientNames: [],
    pageIndex: 1,
    pageSize: 8,
    sortColumn: "",
    sortOrder: "Ascending",
    isSorted: true,
    totalRecord: 0,
  });
  const [candidateFilterState, setCandidateFilterState] = React.useState({
    recruiterGuid: userRole === UserAuth.Recruiter ? userDetails?.id : "",
    jobId: "",
    searchKeywords: "",
    location: "",
    statuses: "",
    contractType: "",
    showPendingSubmissions: false,
    recruiterFilter: "",
    statusIds: statusData?.map((x:any)=>x?.id),
    durationFilter: {
      duration: "0",
      durationType: "All",
      fromDate: "",
      toDate: "",
    },
    recruiterGuids: [],
    candidateGuids: [],
    pageIndex: 1,
    pageSize: 8,
    sortColumn: "",
    sortOrder: "Ascending",
    isSorted: true,
    totalRecord: 0,
  });

  const allRecruiter = useApi(() => GetRecruiters(recuiterFilter)) as any;
  const allClients = useApi(() =>
    GetClientsFromSubmission(clientFilterState)
  ) as any;
  const allCandidates = useApi(() =>
    GetCandidateFromSubmission(candidateFilterState)
  ) as any;

  const changeClientSearchHandler = (e: any) => {
    setClientFilterState({
      ...clientFilterState,
      searchKeywords: e.target.value,
    });
  };
  const changeCandidateSearchHandler = (e: any) => {
    setCandidateFilterState({
      ...candidateFilterState,
      searchKeywords: e.target.value,
    });
  };
  const changeRecruiterSearchHandler = (e: any) => {
    setrecuiterFilter({ ...recuiterFilter, searchKeywords: e.target.value });
  };
  const handleClearRecruiter = () => {
    setrecuiterFilter({ ...recuiterFilter, searchKeywords: "" });
    dispatch(updateSubmissionFilter('recruiterFilter'))
  };
  const handleClearClients = () => {
    setClientFilterState({ ...clientFilterState, searchKeywords: "" });
    dispatch(updateSubmissionFilter('clientNames'))

  };
  const handleClearCandidates = () => {
    setCandidateFilterState({ ...candidateFilterState, searchKeywords: "" });
    dispatch(updateSubmissionFilter('candidateGuids'))

  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if(clientFilterPermission)
      allClients.request();
    
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [clientFilterState.searchKeywords,statusData]);

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if(
        userRole === UserAuth.Client 
        || userRole === UserAuth.ClientTeamMember 
        || userRole === UserAuth.Admin 
        || userRole === UserAuth.SuperAdmin
        )
      allRecruiter.request();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [recuiterFilter.searchKeywords]);

  React.useEffect(() => {    
    const delayDebounceFn = setTimeout(() => {
      allCandidates.request();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [candidateFilterState.searchKeywords,statusData]);

  
  
  const sortedRecruiters = allRecruiter.data?.entityList?.slice().sort((a, b) => {
    const aIsChecked = recruiterFilter?.includes(a?.id);
    const bIsChecked = recruiterFilter?.includes(b?.id);
    return bIsChecked - aIsChecked;
  });
  
  const clientsList = allClients.data?.entityList?.slice().sort((a:any, b:any) => {
    const aIsChecked = clientNames?.includes(a?.jobClientName);
    const bIsChecked = clientNames?.includes(b?.jobClientName);
    return bIsChecked - aIsChecked;
  });
  const candidatesList = allCandidates.data?.entityList?.slice().sort((a:any, b:any) => {
    const aIsChecked = candidateGuids?.includes(a?.candidateGuid);
    const bIsChecked = candidateGuids?.includes(b?.candidateGuid);
    return bIsChecked - aIsChecked;
  });

  const handleClientChange = (e: any, industry: any) => {
    dispatch(handleClientFilter(industry.jobClientName))
  };
  const handleCandidateChange = (e: any, industry: any) => {
     dispatch(handleCandidateFilter(industry.candidateGuid))
  };
  const handleRecruiterChange = (e: any, industry: any) => {
    dispatch(handleRecruiterFilter(industry.id))
  };


  const handleClearAll = () => {
    handleClearCandidates();
    handleClearClients();
    handleClearRecruiter();
  };

  React.useImperativeHandle(ref, () => ({
    handleClearAll,
  }));

  return (
    <Wrapper>
      <Popper id={"id"} open={open} anchorEl={anchorEl}>
        <ClickAwayListener onClickAway={props.onClose}>
          <Card>
            <Box
              sx={{
                flexGrow: 1,
                bgcolor: "#FAFAFA",
                display: "flex",
              }}
            >
              <Grid container p={2}>
                <Grid item md={6}>
                  <Typography fontWeight={"bold"} className="roboto-font">
                    Filters
                  </Typography>
                </Grid>
                <Grid item xs={6} className="closeArea">
                  <IconButton
                    aria-label="close"
                    size="small"
                    className="close-Btn"
                    onClick={props.onClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                bgcolor: "#FAFAFA",
                display: "flex",
                height: 386,
              }}
              className="subFilterTab"
            >
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: "divider", width: 146 }}
              >                
                <Tab
                  data-testid="client-tab"
                  className="filterTabNav"
                  label={
                    <Typography
                      fontSize={16}
                      className="roboto-font"
                      style={{display:"flex"}}
                    >
                      Client
                    </Typography>
                  }
                  {...a11yProps(0)}
                  style={{
                    backgroundColor: value === 0 ? "white" : "inherit",
                    alignItems: "flex-start",
                    display: clientFilterPermission? 'block': 'none'

                  }}
                />
                <Tab
                  data-testid="candidate-tab"
                  className="filterTabNav"
                  label={
                    <Typography
                      fontSize={16}
                      className="roboto-font"
                    >
                      Candidate
                    </Typography>
                  }
                  {...a11yProps(1)}
                  style={{
                    backgroundColor: value === 1 ? "white" : "inherit",
                    alignItems: "flex-start",
                  }}
                />
                {userRole !== UserAuth.Recruiter  &&
                 <Tab
                  data-testid="recruiter-tab"
                  className="filterTabNav"
                  label={
                    <Typography
                      fontSize={16}
                      className="roboto-font"
                    >
                      Recruiter
                    </Typography>
                  }
                  {...a11yProps(2)}
                  style={{
                    backgroundColor: value === 2 ? "white" : "inherit",
                    alignItems: "flex-start"
                  }}
                />
                }
              </Tabs>
              <TabPanel value={value} index={0}>
              <Typography ml={2} className="select_user_filter">Clients ({clientNames?.length})</Typography>
                 <hr/>
                <Grid mt={-2} container style={{ width: 223 }} p={2}>                  
                  <Grid item md={12}>
                    <Grid container>
                      <Grid item>
                        <TextField
                          className="roboto-font filter-searchbox"
                          placeholder="Search Client"
                          size="small"
                          value={clientFilterState.searchKeywords}
                          inputProps={{"data-testid": "txt-search-client"}}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton>
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M9.16675 1.66666C13.3067 1.66666 16.6667 5.02666 16.6667 9.16666C16.6667 13.3067 13.3067 16.6667 9.16675 16.6667C5.02675 16.6667 1.66675 13.3067 1.66675 9.16666C1.66675 5.02666 5.02675 1.66666 9.16675 1.66666ZM9.16675 15C12.3892 15 15.0001 12.3892 15.0001 9.16666C15.0001 5.94333 12.3892 3.33333 9.16675 3.33333C5.94341 3.33333 3.33341 5.94333 3.33341 9.16666C3.33341 12.3892 5.94341 15 9.16675 15ZM16.2376 15.0592L18.5951 17.4158L17.4159 18.595L15.0592 16.2375L16.2376 15.0592V15.0592Z"
                                      fill="#353535"
                                    />
                                  </svg>
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          onChange={changeClientSearchHandler}
                        />
                      </Grid>
                      <Grid item pt={1} pb={0.5}>
                        <FormControlLabel
                          data-testid={"clear-client-button"}
                          onClick={handleClearClients}
                          control={
                            <CheckBoxSquareIcon
                              style={{ paddingRight: "5px" }}
                            ></CheckBoxSquareIcon>
                          }
                          label={
                            <Typography fontSize={14} className="roboto-font">
                              Clear All
                            </Typography>
                          }
                          className="candidates-label"
                          style={{
                            color: "red",
                            fontWeight: "bold",
                            paddingLeft: 0,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    style={{ maxHeight: 248, overflowY: "auto" }}
                  >
                    <MenuList
                      style={{ paddingTop: "0px" }}
                      className="listing-filter"
                    >
                      <MenuItem
                        sx={{
                          paddingLeft: "0px",
                          paddingBottom: "0px",
                          minHeight: "auto",
                        }}
                      ></MenuItem>
                      {clientsList?.map((item: any, index: any) => {
                        return (
                          <MenuItem
                            key={item.id}
                            sx={{
                              paddingLeft: "0px",
                              paddingBottom: "0px",
                              minHeight: "auto",
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  data-testid={`industry-checkbox-${index}`}
                                  onChange={(e) => handleClientChange(e, item)}
                                  name={item?.id}
                                  sx={{ padding: "0px", fontSize: "14px" }}
                                  checked={clientNames.includes(item.jobClientName)}
                                />
                              }
                              label={
                                <Typography
                                  fontSize={14}
                                  className="roboto-font"
                                >
                                  {item?.jobClientName}
                                </Typography>
                              }
                              className="candidates-label roboto-font"
                              style={{ paddingLeft: 0 }}
                            />
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
              <Typography ml={2} className="select_user_filter">Candidates ({candidateGuids?.length})</Typography>
                 <hr/>
                <Grid mt={-2} container style={{ width: 223 }} p={2}>
                  <Grid item md={12}>
                    <Grid container>
                      <Grid item>
                        <TextField
                          className="roboto-font filter-searchbox"
                          placeholder="Search Candidate"
                          size="small"
                          value={candidateFilterState.searchKeywords}
                          inputProps={{"data-testid": "txt-search-candidate"}}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton>
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M9.16675 1.66666C13.3067 1.66666 16.6667 5.02666 16.6667 9.16666C16.6667 13.3067 13.3067 16.6667 9.16675 16.6667C5.02675 16.6667 1.66675 13.3067 1.66675 9.16666C1.66675 5.02666 5.02675 1.66666 9.16675 1.66666ZM9.16675 15C12.3892 15 15.0001 12.3892 15.0001 9.16666C15.0001 5.94333 12.3892 3.33333 9.16675 3.33333C5.94341 3.33333 3.33341 5.94333 3.33341 9.16666C3.33341 12.3892 5.94341 15 9.16675 15ZM16.2376 15.0592L18.5951 17.4158L17.4159 18.595L15.0592 16.2375L16.2376 15.0592V15.0592Z"
                                      fill="#353535"
                                    />
                                  </svg>
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          onChange={changeCandidateSearchHandler}
                        />
                      </Grid>
                      <Grid item pt={1} pb={0.5}>
                        <FormControlLabel
                          data-testid={"clear-candidate-button"}
                          onClick={handleClearCandidates}
                          control={
                            <CheckBoxSquareIcon
                              style={{ paddingRight: "5px" }}
                            ></CheckBoxSquareIcon>
                          }
                          label={
                            <Typography fontSize={14} className="roboto-font">
                              Clear All
                            </Typography>
                          }
                          className="candidates-label"
                          style={{
                            color: "red",
                            fontWeight: "bold",
                            paddingLeft: 0,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    style={{ maxHeight: 248, overflowY: "auto" }}
                  >
                    <MenuList
                      style={{ paddingTop: "0px" }}
                      className="listing-filter"
                    >
                      <MenuItem
                        sx={{
                          paddingLeft: "0px",
                          paddingBottom: "0px",
                          minHeight: "auto",
                        }}
                      ></MenuItem>
                      {candidatesList?.map((item: any, index: any) => {
                        return (
                          <MenuItem
                            key={item.candidateGuid}
                            sx={{
                              paddingLeft: "0px",
                              paddingBottom: "0px",
                              minHeight: "auto",
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  data-testid={`candidate-checkbox-${index}`}
                                  onChange={(e) =>
                                    handleCandidateChange(e, item)
                                  }
                                  name={item?.candidateGuid}
                                  sx={{ padding: "0px", fontSize: "14px" }}
                                  checked={candidateGuids?.includes(item.candidateGuid)}
                                />
                              }
                              label={
                                <Typography
                                  fontSize={14}
                                  className="roboto-font"
                                >
                                  {constructName(
                                    item?.firstName,
                                    item?.middleName,
                                    item?.lastName
                                  )}
                                </Typography>
                              }
                              className="candidates-label roboto-font"
                              style={{ paddingLeft: 0 }}
                            />
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  </Grid>
                </Grid>
              </TabPanel>
              
              <TabPanel value={value} index={2}>
              <Typography ml={2} className="select_user_filter">Recruiters ({recruiterFilter?.length})</Typography>
                 <hr/>
                <Grid mt={-2} container style={{ width: 223 }} p={2}>
                  <Grid item md={12}>
                    <Grid container>
                      <Grid item>
                        <TextField
                          className="roboto-font filter-searchbox"
                          placeholder="Search Recruiter"
                          size="small"
                          value={recuiterFilter.searchKeywords}
                          inputProps={{"data-testid": "txt-search-recruiter"}}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton>
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M9.16675 1.66666C13.3067 1.66666 16.6667 5.02666 16.6667 9.16666C16.6667 13.3067 13.3067 16.6667 9.16675 16.6667C5.02675 16.6667 1.66675 13.3067 1.66675 9.16666C1.66675 5.02666 5.02675 1.66666 9.16675 1.66666ZM9.16675 15C12.3892 15 15.0001 12.3892 15.0001 9.16666C15.0001 5.94333 12.3892 3.33333 9.16675 3.33333C5.94341 3.33333 3.33341 5.94333 3.33341 9.16666C3.33341 12.3892 5.94341 15 9.16675 15ZM16.2376 15.0592L18.5951 17.4158L17.4159 18.595L15.0592 16.2375L16.2376 15.0592V15.0592Z"
                                      fill="#353535"
                                    />
                                  </svg>
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          onChange={changeRecruiterSearchHandler}
                        />
                      </Grid>
                      <Grid item pt={1} pb={0.5}>
                        <FormControlLabel
                          data-testid={"clear-recruiter-button"}
                          onClick={handleClearRecruiter}
                          control={
                            <CheckBoxSquareIcon
                              style={{ paddingRight: "5px" }}
                            ></CheckBoxSquareIcon>
                          }
                          label={
                            <Typography fontSize={14} className="roboto-font">
                              Clear All
                            </Typography>
                          }
                          className="candidates-label"
                          style={{
                            color: "red",
                            fontWeight: "bold",
                            paddingLeft: 0,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    style={{ maxHeight: 248, overflowY: "auto" }}
                  >
                    <MenuList
                      style={{ paddingTop: "0px" }}
                      className="listing-filter"
                    >
                      <MenuItem
                        sx={{
                          paddingLeft: "0px",
                          paddingBottom: "0px",
                          minHeight: "auto",
                        }}
                      ></MenuItem>
                      {sortedRecruiters?.map((item: any, index: any) => {
                        return (
                          <MenuItem
                            key={item?.id}
                            sx={{
                              paddingLeft: "0px",
                              paddingBottom: "0px",
                              minHeight: "auto",
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  data-testid={`industry-checkbox-${index}`}
                                  onChange={(e) =>
                                    handleRecruiterChange(e, item)
                                  }
                                  name={item?.id}
                                  sx={{ padding: "0px", fontSize: "14px" }}
                                  checked={recruiterFilter.includes(item?.id)}
                                />
                              }
                              label={
                                <Typography
                                  fontSize={14}
                                  className="roboto-font"
                                >
                                  {constructName(
                                    item?.firstName,
                                    item?.middleName,
                                    item?.lastName
                                  )}
                                </Typography>
                              }
                              className="candidates-label roboto-font"
                              style={{ paddingLeft: 0 }}
                            />
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  </Grid>
                </Grid>
              </TabPanel>
            </Box>
          </Card>
        </ClickAwayListener>
      </Popper>
    </Wrapper>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ backgroundColor: "white" }}
    >
      {value === index && (
        <Typography className="roboto-font">{children}</Typography>
      )}
    </div>
  );
}

export default React.forwardRef(SubmissionFilter);