import {
  Input,
  InputLabel,
  TextField,
  makeStyles
} from "@material-ui/core";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { CircularProgress, Slider } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import * as React from "react";
import { useEffect, useState } from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  GetAutoSuggessionJobAndClientName,
  GetJobRecommendation,
  GetSavedJobs,
} from "../../../Services/jobService";
import { GetAllCurrencyList } from "../../../Services/masterCurrencyService.js";
import { defaultDataAutocomplete } from "../../../data/defaultDataAutocomplete";
import { setFindJobsSearchState } from "../../../features/Jobs/FindJobsSearchSlice";
import { useNavigatingAway } from "../../../hooks/useNavigatingAway";
import { BreadCrumbItem } from "../../../models/AppModels";
import getAddressForAutoComplete from "../../../utils/getAddressForAutoComplete";
import getLabel from "../../../utils/getLabelForAutoComplete";
import AppBreadCrumb from "../../Common/AppBreadCrumb";
import { Asterisk } from "../../ui/Asterisk";
import { ErrorMessage } from "../../ui/ErrorMessage";
import { IndustryAutocomplete } from "../../ui/IndustryAutocomplete";
import { DialogLeavingPage } from "./DialogLeavingPopup";
import RecommendJobScorecardDialog from "./RecommendJobScorecardDialog";
import { RecommendedJobsSearchRow } from "./RecommendedJobsSearchRow";
import { getNevLink } from "../../../utils/getFileFromUrl";

interface IPROPS {}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
  option: {
    padding: "0px",
    margin: "1px !important",
  },
});

const RecommendedJob: React.FunctionComponent<IPROPS> = (props) => {

  const dispatch = useDispatch()
  const findJobsSearchStateRTK =  useSelector((state : any) => state.findJobsSearchSlice)
  
  let defaultBreadcrumb: BreadCrumbItem[] = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
    { isLeaf: true, sequence: 3, title: "Find Jobs" },
  ];

  const classes = useStyles();

  const [isUploadTouch, setIsUploadTouch] = useState(false);
  const [jobTitleSearchOptionList, setJobTitleSearchOptionList] = useState<
    any[]
  >([]);
  const [jobTitleOption, setJobTitleOption] = useState([]);
  const [searchAutoSuggestJobtitleText, setSearchAutoSuggestJobtitleText] =
    useState("");
  const [activeAutoSuggession, setActiveAutoSuggession] = useState("");
  const [masterCurrercyList, setMasterCurrencyList] = useState([]);
  const [autoCompleteSuggesions, setautoCompleteSuggesions] = useState<any[]>(
    []
  );
  const [locationSearchOptionList, setLocationSearchOptionList] = useState<
    any[]
  >([]);
  const [recommendedJobDataList, setRecommendedJobDataList] = useState<any[]>([]);
  const [jobDataList, setJobDataList] = useState<any[]>([]);
  const [isFormSubmitting, setIsFormSubmitting] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [hasMoreData, setHasMoreFlag] = useState<any>(null);
  const [resumeFile, setResumeFile] = useState<{
    file: File;
    fileName: string;
  }>();
  const [isNewResumeUpload,setIsNewResumeUpload] = useState<boolean>(false);
  const [columnSort, setColumnSort] = useState<boolean>(true);
  const [canShowDialogLeavingPage, setShowDialogLeavingPage] = useState<boolean>(false)
  const [scorecardDialog,setScorecardDialog] = useState<{
    isOpen:boolean;
    scorecardData?:any;
    resumeDetail?:any;
  }>({isOpen:false});

  const [jobFilterState, setJobFilterState] = useState({...jobFilter,jobIds:findJobsSearchStateRTK?.searchJobsId});

  const [
    showDialogLeavingPage,
    confirmNavigation,
    cancelNavigation
  ] = useNavigatingAway(canShowDialogLeavingPage);

  const formik = useFormik({
    initialValues: findJobsSearchStateRTK,
    validationSchema: UserValidtionSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    window.addEventListener('beforeunload', function (event) {
      event.stopImmediatePropagation();
    });
    getAllMasterCurrencyList();
    setShowDialogLeavingPage(false);
    if(findJobsSearchStateRTK?.searchJobsId?.length > 0){
      setRecommendedJobDataList(findJobsSearchStateRTK?.recommendationData);
      getJobs(true);
    }
  }, []);

  useEffect(() => {
    if(formik.values.currency !=="" ||
      formik.values.domains.length > 0 ||
      formik.values.industries.length > 0 ||
      formik.values.jobType.length > 0 ||
      formik.values.minimumPayRate !=="" ||
      formik.values.maximumPayRate !=="" ||
      formik.values.resume !=="" && formik.dirty
    ){
      setShowDialogLeavingPage(true);
    }
  },[formik.values.currency,
    formik.values.domains,
    formik.values.industries,
    formik.values.jobType,
    formik.values.minimumPayRate,
    formik.values.maximumPayRate,
    formik.values.resume
  ]);

  useEffect(() => {
    if(resumeFile?.fileName !== ""){
      formik.setFieldValue("resumeFile", resumeFile);
    }
  }, [resumeFile]);

  useEffect(() => {
    if (jobFilterState?.jobIds?.length > 0) {
      if (jobFilterState.pageIndex === 1) {
        getJobs(true);
      } else {
        getJobs();
      }
    }
  }, [jobFilterState?.jobIds, jobFilterState.pageIndex,jobFilterState.sortOrder]);

  // call autosuggesstion for jobTitle field
  useEffect(() => {
    setActiveAutoSuggession("jobTitle");
    GetAutoSuggessionJobAndClient({
      jobTitle: searchAutoSuggestJobtitleText,
      client: "",
    });
  }, [searchAutoSuggestJobtitleText]);

  
  const handleReset = () => {
    setShowDialogLeavingPage(false);
    formik.setFieldValue("resume", "");
    formik.setFieldValue("resumeInfo", {
      resourceUrl: "",
      fileName: "",
      newFileName: "",
    });
    formik.setFieldValue("jobType", []);
    formik.setFieldValue("domains", []);
    formik.setFieldValue("industries", []);
    formik.setFieldValue("jobTitle", []);
    formik.setFieldValue("location", []);
    formik.setFieldValue("minimumPayRate", "");
    formik.setFieldValue("maximumPayRate", "");
    formik.setFieldValue("currency", "");
    formik.setFieldValue("resumeFile", {
      file: File,
      fileName: String,
    });
    formik.setFieldValue("isSearchResult",false);
    setautoCompleteSuggesions([]);
    setJobTitleSearchOptionList([]);
    setLocationSearchOptionList([]);
    setJobDataList([]);
    localStorage.removeItem("findjobsSearchCriteria");

    formik.setFieldTouched("resume",false);
    formik.setFieldTouched("domains",false);
    formik.setFieldTouched("jobTitle",false);
    setTotalRecords(0);
  };

  const fetchMoreData = () => {
    setJobFilterState({
      ...jobFilterState,
      pageIndex: jobFilterState.pageIndex + 1,
    });
  };

  const getAllMasterCurrencyList = () => {
    GetAllCurrencyList()
      .then((response) => {
        let currencyList = response.data.entityList.map(
          (item: any, index: any) => item
        );
        setMasterCurrencyList(currencyList);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const GetAutoSuggessionJobAndClient = (SearchData: any) => {
    GetAutoSuggessionJobAndClientName(SearchData)
      .then((response: any) => {
        if (activeAutoSuggession === "jobTitle") {
          setJobTitleOption(response.data.entity.suggestion);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (values: any) => {
    let formData = new FormData();
    formData.append("JobInfo.client", "");
    formData.append("JobInfo.currency", values?.currency);
    formData.append("JobInfo.minimumPayRate", values?.minimumPayRate);
    formData.append("JobInfo.maximumPayRate", values?.maximumPayRate);
    jobTitleSearchOptionList.forEach((item) => formData.append("JobInfo.jobTitle[]", item))
    values?.industries.forEach((item:any) => formData.append("JobInfo.industries[]", item.industry))
    values?.domains.forEach((item:any) => formData.append("JobInfo.domains[]", item))
    values?.jobType.forEach((item:any) => formData.append("JobInfo.jobType[]", item))
    values?.location.forEach((item:any) => formData.append("JobInfo.location[]", getLabel(item.location.city, item.location.state)))

    formData.append("JobInfo.sliderInfo.skill", values?.sliderInfo?.skill);
    formData.append("JobInfo.sliderInfo.title", values?.sliderInfo?.title);
    formData.append("JobInfo.sliderInfo.domain", values?.sliderInfo?.domain);
    formData.append("JobInfo.sliderInfo.responsibility", values?.sliderInfo?.responsibility);
    formData.append("JobInfo.numResults", "100");


    if(isNewResumeUpload){
      formData.append("ResumeFile", formik.values?.resumeFile?.file as File);
    }else{
      formData.append("ResumeFile", findJobsSearchStateRTK.resumeFile?.file as File);
    }

    getJobRecommendationAPI(formData);
    localStorage.setItem('findjobsSearchCriteria', JSON.stringify({...values,jobTitle:jobTitleSearchOptionList}));
  };

  const getJobRecommendationAPI = (model: any) => {
    setIsFormSubmitting(true);
    GetJobRecommendation(model)
      .then((response: any) => {
        setShowDialogLeavingPage(false);
        setRecommendedJobDataList(response?.data?.entity?.data || []);
        let jobsIds = response?.data?.entity?.data.map((x: any) => x.id);
        setJobFilterState({ ...jobFilter,pageIndex:1, jobIds: jobsIds });
        let updatedPayload = {...findJobsSearchStateRTK,...formik.values,searchJobsId: jobsIds,recommendationData:response?.data?.entity?.data}
        dispatch(setFindJobsSearchState(updatedPayload))
        setIsFormSubmitting(false);
        setJobDataList([])
        setTotalRecords(response.data?.totalRecords);
        
        setScorecardDialog({...scorecardDialog ,resumeDetail: response?.data?.entity?.resumeDetails})
      })
      .catch((error: any) => {
        console.log(error);
        setIsFormSubmitting(false);
      })
      .finally(()=>{
        setShowDialogLeavingPage(false);
      });
  };

  const handleColumnSort = (type: any) => {
    setHasMoreFlag(false);
    setColumnSort(!columnSort);

    setJobFilterState({
      ...jobFilterState,
      sortColumn: type,
      sortOrder: jobFilterState.sortOrder === 0 ? 1 : 0,
      pageIndex: 1,
    });
  };

  const getJobs = (search = false) => {
    
    setLoading(true);
    let reqPayload = jobFilterState;
    let getJobIdsc = getSortedJobIdsByScore(recommendedJobDataList,jobFilterState.pageIndex, jobFilterState.sortColumn)
    if(jobFilterState.sortColumn === "score")
      reqPayload = {...reqPayload, pageIndex:1};
    GetSavedJobs({...reqPayload,jobIds:getJobIdsc})
      .then((response) => {
        
        if (jobDataList.length > response.data?.totalRecords) {
          setHasMoreFlag(false);
        } else {
          setHasMoreFlag(true);
        }

        let jobs = appendScoreToJobs(response?.data?.entityList,recommendedJobDataList);
        
        if (search) {
          setJobDataList([...jobs]);
        } else {
          setJobDataList([...jobDataList, ...jobs]);
        }

        formik.setFieldValue("isSearchResult",true)
        setShowDialogLeavingPage(false);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const onResumeFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setIsUploadTouch(true);
    if (e.currentTarget.files) {
      if (!isValidFileExtention(e.currentTarget.files[0].name)) {
        formik?.setFieldError("resume", "Invalid file extention");
      } else if (e.currentTarget.files[0].size > 2000000) {
        formik?.setFieldError("resume", "File size too large");
      } else {
        handleUploadClick(e.currentTarget.files || null, "Resume");
        formik?.setFieldValue(
          "resume",
          e.currentTarget.files ? e.currentTarget.files[0].name : ""
        );
      }
    }
  };

  const handleUploadClick = (files: FileList | null, type: string) => {
    if (!files) return;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    setIsNewResumeUpload(true);
    if (type === "Resume") {
      formik.setFieldValue("resumeFile",{
        file: files[0],
        fileName: files[0].name,
      });
      
      setResumeFile({
        file: files[0],
        fileName: files[0].name,
      });
    }
  };

  const handleIndustryChange = (e: any, value: any) => {
    formik?.setFieldValue("industries", value);
  };


  function getSortedJobIdsByScore(
    jobRecommendList: any[],
    pageIndex: number,
    sortColumn: string
  ): string[] {
    if (sortColumn === "score") {
      const sortedJobs = [...jobRecommendList].sort((a, b) => {
        return jobFilterState.sortOrder ? a.score - b.score : b.score - a.score;
      });
      const pageSize = 10;
      const startIndex = (pageIndex - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      const pagedJobs = sortedJobs.slice(startIndex, endIndex);
      return pagedJobs.map((job) => job.id);
    } else {
      return jobRecommendList.map((job) => job.id);
    }
  }

  function appendScoreToJobs(jobList: any[], jobRecommendList: any[]): any[] {
    const updatedJobs = jobList.map(job => {
      const score = jobRecommendList.find(score => score.id === job.id);
      if (score) {
        return { ...job, score: score.score, scorecardData:score  };
      }
      return job;
    });
    return updatedJobs;
  }

  const renderPreferredGeographies = ({ getInputProps, suggestions }: any) => (
    <Autocomplete
      multiple
      id="size-small-standard-multi1"
      size="medium"
      onKeyPress={(e) => {
        formik.values.location?.length === 5
          ? e.preventDefault()
          : console.log();
      }}
      onChange={(e, value: any) => {
        formik.setFieldValue("location", value);
        setLocationSearchOptionList(value);
      }}
      options={
        suggestions.length > 0
          ? suggestions.map((str: any) => ({
              id: str.placeId,
              location: {
                city: getAddressForAutoComplete(str.description).city,
                state: getAddressForAutoComplete(str.description).state,
                country: getAddressForAutoComplete(str.description).country,
              },
            }))
          : defaultDataAutocomplete
      }
      getOptionDisabled={(option: any) =>
        formik.values.location.some((e: any) => e.id === option.id) ||
        formik.values.location?.length === 5
          ? true
          : false
      }
      value={locationSearchOptionList}
      getOptionLabel={(option: any) =>
        getLabel(option.location.city, option.location.state)
      }
      renderTags={(value, getTagProps) =>
        value?.map((option: any, index: any) => (
          <Chip
            color="primary"
            size="medium"
            label={getLabel(option.location.city, option.location.state)}
            {...getTagProps({ index })}
          />
        ))
      }
      onBlur={(e: any) => {
        if (e.target.value && e.target.value !== "") {
          setLocationSearchOptionList([
            ...locationSearchOptionList,
            e.target.value,
          ]);
        }
      }}
      clearOnBlur
      renderInput={(params) => (
        <TextField
          data-testid="cities-autosuggestion"
          {...params}
          {...getInputProps()}
          variant="standard"
          label=""
        />
      )}
    />
  );

  function sortJobDataList(dataList:any, filterState:any) {
    return [...dataList].sort((a, b) => {
      if (filterState.sortColumn === "score") {
        return filterState.sortOrder ? a.score - b.score : b.score - a.score;
      }
      return 1;
    });
  }
  
  let sortedJobDataList = sortJobDataList(jobDataList, jobFilterState);

  return (
    <>
      <Box mt={3} className="recommendedJob-Page">
        <Container maxWidth={false}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Item
                elevation={0}
                sx={{ paddingLeft: "0px", paddingTop: "5px" }}
              >
                <AppBreadCrumb
                  BreadCrumbItems={defaultBreadcrumb}
                ></AppBreadCrumb>
              </Item>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography
                style={{
                  color: "#4540DB",
                  fontSize: "24px",
                  fontWeight: "400px",
                }}
              >
                Upload resume here to find suitable jobs
              </Typography>
              <Divider
                sx={{
                  borderBottomWidth: "1",
                  width: "100%",
                  background: "#4540DB",
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Box mt={{ xs: 1, sm: 2, md: 3 }}>
                <Box className="form-upload-resume" mt={0} mb={1}>
                  <FormControl
                    variant="standard"
                    required
                    error={isUploadTouch && Boolean(formik.errors.resume)}
                  >
                    <InputLabel htmlFor="resume">
                      Resume <Asterisk />
                    </InputLabel>
                    <Input
                      name="resume"
                      type="text"
                      readOnly
                      value={formik.values.resume}
                    />
                    <ErrorMessage
                      errorText={
                        formik.touched?.resume && formik.errors?.resume
                      }
                    />
                  </FormControl>

                  <Button
                    variant="contained"
                    component="label"
                    className="upload-btn"
                  >
                    <FileUploadOutlinedIcon style={{marginRight:"5px",width:"20px"}} /> Attach Resume
                    <input
                      data-testid={"uploadResumeId"}
                      type="file"
                      hidden
                      accept={".txt,.rtf,.doc,.docx,.pdf"}
                      onChange={onResumeFileChange}
                      onClick={(e) => {
                        e.currentTarget.value = "";
                      }}
                    />
                  </Button>
                </Box>
                <Box
                  className="form-three-child"
                  sx={{ position: "relative", top: "0" }}
                >
                  <Typography
                    variant="subtitle1"
                    component="div"
                    className="fileUploadHelper-TextLabel"
                  >
                    (Max file size: 2MB as
                    <span> .txt, .rtf, .doc, .docx, .pdf</span>)
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {" "}
                Select Domain <Asterisk />{" "}
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    name="domains"
                    value={"IT"}
                    checked={formik.values?.domains?.some(
                      (x: any) => x === "IT"
                    )}
                    onChange={formik.handleChange}
                    size="small"
                    data-testid="domain-checkbox"
                  />
                }
                label="IT"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="domains"
                    value={"Non-IT"}
                    checked={formik.values?.domains?.some(
                      (x: any) => x === "Non-IT"
                    )}
                    onChange={formik.handleChange}
                    size="small"
                    data-testid="domain-checkbox"
                  />
                }
                label="Non-IT"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="domains"
                    value={"Healthcare"}
                    checked={formik.values?.domains?.some(
                      (x: any) => x === "Healthcare"
                    )}
                    onChange={formik.handleChange}
                    size="small"
                    data-testid="domain-checkbox"
                  />
                }
                label="Healthcare"
              />
              <ErrorMessage
                errorText={formik.touched?.domains && formik.errors?.domains}
              />
            </Grid>
            <Grid item xs={6}>
              <div style={{ color: "black" }}> Type of Jobs </div>
              <FormControlLabel
                control={
                  <Checkbox
                    name="jobType"
                    value={"Full time"}
                    checked={formik.values?.jobType?.some(
                      (x: any) => x === "Full time"
                    )}
                    onChange={formik.handleChange}
                    size="small"
                    data-testid="jobtype-checkbox"
                  />
                }
                label="Full time"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="jobType"
                    value={"Contract"}
                    checked={formik.values?.jobType?.some(
                      (x: any) => x === "Contract"
                    )}
                    onChange={formik.handleChange}
                    size="small"
                    data-testid="jobtype-checkbox"
                  />
                }
                label="Contract"
              />
            </Grid>
            <Grid item xs={6}>
              <Typography className="AdvanceSearchLabel">Job Title</Typography>
              <Autocomplete
                multiple
                freeSolo
                id="tags-standard"
                disableClearable
                options={jobTitleOption}
                getOptionLabel={(option: any) => option}
                classes={{
                  option: classes.option,
                }}
                onBlur={(e: any) => {
                  if (e.target.value !== "") {
                    setJobTitleSearchOptionList([
                      ...jobTitleSearchOptionList,
                      e.target.value,
                    ]);
                  }
                }}
                clearOnBlur
                getOptionDisabled={() =>
                  jobTitleSearchOptionList.length >= 5 ? true : false
                }
                value={formik.values.jobTitle}
                onChange={(event: any, newValue: any) => {
                  setJobTitleSearchOptionList(newValue);
                  formik.setFieldValue("jobTitle", newValue);
                }}
                onKeyUp={(event: any) =>
                  setSearchAutoSuggestJobtitleText(event.target.value)
                }
                renderTags={(value, getTagProps) =>
                  value.map((option: any, index: any) => (
                    <Chip
                      color="primary"
                      size="medium"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    id="jobTitle"
                    name="jobTitle"
                    label=""
                    type="text"
                    inputProps={{
                      ...params.inputProps,
                      "data-testid": "autocomplete-jobtitle",
                      onKeyDown: (e) => {
                        if (
                          jobTitleSearchOptionList.length >= 5 ? true : false
                        ) {
                          e.stopPropagation();
                        }
                      },
                      readOnly:
                        jobTitleSearchOptionList.length >= 5 ? true : false,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography className="AdvanceSearchLabel">Location</Typography>
              <PlacesAutocomplete
                value={autoCompleteSuggesions}
                onChange={(ev: any) => {
                  setautoCompleteSuggesions(ev);
                }}
                searchOptions={{ types: ["(cities)"] }}
              >
                {renderPreferredGeographies}
              </PlacesAutocomplete>
            </Grid>
            <Grid item xs={6}>
              <Typography className="AdvanceSearchLabel">Industries</Typography>
              <IndustryAutocomplete
                industries={formik.values.industries}
                onChange={handleIndustryChange}
                isRequired={false}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography className="AdvanceSearchLabel">Pay Rate</Typography>
              <Grid container maxWidth={"xl"}>
                <Grid item xs={4} md={4.7} pr={1}>
                  <TextField
                    fullWidth
                    id="minimumPayRate"
                    name="minimumPayRate"
                    label="Minimum"
                    type="number"
                    value={formik.values.minimumPayRate}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item xs={4} md={4.7} pr={1}>
                  <TextField
                    fullWidth
                    id="maximumPayRate"
                    name="maximumPayRate"
                    label="Maximum"
                    type="number"
                    value={formik.values.maximumPayRate}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item xs={4} md={2.2} className="custom-currency-box">
                  <FormControl
                    fullWidth
                    variant="standard"
                    sx={{ minWidth: 80 }}
                    className="CurrencyControl"
                  >
                    <InputLabel id="payrateCurrency">Currency</InputLabel>
                    <Select
                      labelId="payrateCurrency"
                      id="payrateCurrency"
                      name="currency"
                      label="Currency"
                      value={formik.values.currency}
                      onChange={formik.handleChange}
                      className="CurrencySelect"
                    >
                      {masterCurrercyList?.map((item: any, key: number) => {
                        return (
                          <MenuItem key={item?.currencyCode ?? key} value={item?.currencyCode}>
                            {item?.currencyCode}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Typography
                    style={{
                      color: "#4540DB",
                      fontSize: "24px",
                      fontWeight: "400px",
                    }}
                  >
                    Modify the search results
                  </Typography>
                </Grid>
                <Grid md={3} pr={5}>
                  <Typography className="AdvanceSearchLabel">
                    Experience
                  </Typography>
                  <Slider
                    max={10}
                    min={0}
                    aria-label="Volume"
                    name="sliderInfo.responsibility"
                    value={formik.values.sliderInfo?.responsibility}
                    onChange={formik.handleChange}
                    valueLabelDisplay="auto"
                  />
                </Grid>
                <Grid md={3} pr={5}>
                  <Typography className="AdvanceSearchLabel">Skills</Typography>
                  <Slider
                    max={10}
                    min={0}
                    aria-label="Volume"
                    name="sliderInfo.skill"
                    value={formik.values.sliderInfo?.skill}
                    onChange={formik.handleChange}
                    valueLabelDisplay="auto"
                  />
                </Grid>
                <Grid md={3} pr={5}>
                  <Typography className="AdvanceSearchLabel">Title</Typography>
                  <Slider
                    max={10}
                    min={0}
                    aria-label="Volume"
                    name="sliderInfo.title"
                    value={formik.values.sliderInfo?.title}
                    onChange={formik.handleChange}
                    valueLabelDisplay="auto"
                  />
                </Grid>
                <Grid md={3} pr={5}>
                  <Typography className="AdvanceSearchLabel">Domain</Typography>
                  <Slider
                    max={10}
                    min={0}
                    aria-label="Volume"
                    name="sliderInfo.domain"
                    value={formik.values.sliderInfo?.domain}
                    onChange={formik.handleChange}
                    valueLabelDisplay="auto"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box className="text-Align-Right">
                <Button
                  type="button"
                  size="medium"
                  style={{
                    fontSize: "0.875rem",
                    marginRight: "10px",
                    marginTop: "10px",
                  }}
                  variant="outlined"
                  className="abel button-large"
                  onClick={(e) => {
                    handleReset();
                  }}
                  disabled={isFormSubmitting}
                >
                  Reset
                </Button>
                <Button
                  type="submit"
                  size="medium"
                  style={{
                    fontSize: "0.875rem",
                    marginRight: "10px",
                    marginTop: "10px",
                  }}
                  variant="contained"
                  className="abel button-large"
                  onClick={(e) => {
                    formik.handleSubmit();
                  }}
                  disabled={isFormSubmitting}
                  endIcon={
                    isFormSubmitting ? (
                      <CircularProgress size={"16px"} color={"inherit"} />
                    ) : (
                      ""
                    )
                  }
                >
                  Search
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              {!isFormSubmitting && formik?.values?.isSearchResult && (
                <RecommendedJobsSearchRow
                  jobDataList={sortedJobDataList}
                  totalRecords={totalRecords}
                  fetchMoreData={fetchMoreData}
                  hasMoreData={hasMoreData}
                  loading={loading}
                  recommendationData={recommendedJobDataList}
                  columnSort={columnSort}
                  handleColumnSort={handleColumnSort}
                  onViewScoreCard={(selectedItem: any) => {
                    setScorecardDialog({
                      ...scorecardDialog,
                      isOpen: true,
                      scorecardData: selectedItem.scorecardData,
                    });
                  }}
                />
              )}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <DialogLeavingPage
                showDialog={showDialogLeavingPage}
                setShowDialog={setShowDialogLeavingPage}
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <RecommendJobScorecardDialog
        handleOpenClose={() => {
          setScorecardDialog({ ...scorecardDialog, isOpen: false });
        }}
        open={scorecardDialog.isOpen}
        glasssquidScore={scorecardDialog.scorecardData}
        resumeDetails={scorecardDialog.resumeDetail}
      ></RecommendJobScorecardDialog>
    </>
  );
};

const isValidFileExtention = (fileName: string) => {
  let fileExt = fileName.split(".").pop();
  let validExt = ["txt", "rtf", "doc", "docx", "pdf"];
  return validExt.some((item) => item === fileExt);
};

const UserValidtionSchema = Yup.object().shape({
  resume: Yup.string().required("Please attach the candidate resume"),
  domains: Yup.array().min(1, "Please select domain"),
});

export default RecommendedJob;

const jobFilter = {
  pageIndex: 1,
  pageSize: 10,
  sortColumn: "score",
  sortOrder: 0,
  isSorted: true,
  jobIds: [],
  totalRecord: 0,
  searchKeywords: "",
  domains: [],
  showFeaturedJobs: false,
  showDisabledJobs: false,
  showRemoteJobs: false,
  advanceSearch: {
    jobTitle: [],
    location: [],
    client: [],
    minimumPayRate: 0,
    maximumPayRate: 0,
    currency: "",
  },
  recruiterStatus: "",
}