import {  Button, Dialog,  DialogContent, DialogTitle,  IconButton, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from "react";
import { DeleteRecuiterJobAlerts, GetJobAlertById } from "../../../Services/recruiterJobAlertService";
interface JobAlertDeletePopupProps {
    open: boolean;
    onClose: (status: boolean, id: string) => void;
    jobAlertId: string;
  }
  
  const JobAlertDeletePopup: React.FC<JobAlertDeletePopupProps> = ({
    open,
    onClose,
    jobAlertId,
  }) => {
    const [jobAlert, setJobAlert] = useState<any>({});

    const handleDelete = () => {
        DeleteRecuiterJobAlerts(jobAlert?.id)?.then(res =>{
            if(res.data.status == 200 && res.data.message.appStatusCode == "RC_JOB_ALERT_REMOVE"){
               onClose(true,jobAlert?.id)
               return;
            }
            onClose(false,jobAlert?.id)
        })
    }
    React.useEffect(() => {
        GetJobAlertById(jobAlertId)?.then(res => {
            if(res.data.status == 200 && res?.data?.message?.appStatusCode == "RC_RSF"){
                setJobAlert(res?.data?.entity);
            }
        })
    },[])
    return (
        <Dialog data-testid={"openClose"} open={open} onClose={() => onClose(false,jobAlertId)} className="custom-jobalert-dialogue-wrapper-sm" >
            <DialogTitle sx={{ textAlign: "center", paddingBottom:"13px" }}>
                Delete Job Alert
                <IconButton
                    data-testid={"onClose"}
                    aria-label="close"
                    onClick={() => onClose(false,jobAlertId)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ textAlign: 'center' }}>
            <Typography sx={{ color: 'black', paddingBottom: "10px", textAlign: 'center' }}>Are you sure to want to delete the Job alert?</Typography>
                <Button onClick={handleDelete} variant="contained" sx={{ minWidth: '100px' }}>Delete</Button>
            </DialogContent>
        </Dialog>
    )
}
export default JobAlertDeletePopup;