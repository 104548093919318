import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LoginIcon from "@mui/icons-material/Login";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { Avatar } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import PropTypes from "prop-types";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  UpdateClientPhoto
} from "../../../Services/clientService";
import {
  UpdateProfilePhoto
} from "../../../Services/recruiterService";
import decodeJwt from "../../../Shared/utils/decodeJwt";
import getLoggedInUserRole from "../../../Shared/utils/getLoggedInUserRole";
import getUserDetails from "../../../Shared/utils/getUserDetails";
import logoutIcon from "../../../assets/images/logoutIcon.svg";
import { adminPages } from "../../../data/pages/adminPages";
import {
  clientHeaderNavigation,
  recruiterHeaderNavigation,
} from "../../../data/pages/recruiterPages";
import { PermissionsEnums } from "../../../data/permission";
import { setExpandedCollapseLeftMenuIndex, setSelectedIndex } from "../../../features/Layout/NavigationSlice";
import { updateImage } from "../../../features/recruiterSlice";
import { useImpersonate } from "../../../hooks/useImpersonate";
import { UserAuth } from "../../../models/interfaces";
import { getFirstAndLastName } from "../../../utils/StringUtil";
import { getNevLink, loadImage } from "../../../utils/getFileFromUrl";
import ImpersonateUser from "../../Admin/Popups/ImpersonateUser";
import { LeftNavigationRoot } from "../../Layout/LeftNavigationRoot";
import { Fullname } from "../Fullname";
import HeaderNav from "../HeaderNav";
import { FAQMenuIcon } from "../Icons/FaqMenuIcon";
import { PhotoOutlinedIcon } from "../Icons/PhotoOutlinedIcon";
import { ModuleComponent } from "../Permission/ModuleComponent";
import UploadPhotoDialog from "../UploadPhotoDialog";
import logo from "./../../../assets/images/logo.svg";
import "./header.scss";
import { valueOrDefault } from "../../../utils/complexityUtils";
import { getIsClientModuleEnabled } from "../../../utils/AppConfig";

const drawerWidth = 180;
function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  width: `calc(100% - 66px)`,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function Header(props) {

  const isClientModuleEnabled = getIsClientModuleEnabled();
  const NavigationSlice = useSelector((state) => state.navigationState);
  const { features, modules } = useSelector((state) => state.userPermissions);
  const isMobile = useMediaQuery("(max-width:900px)");
  const dispatch = useDispatch();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
  const navigate = useNavigate();
  const userDetails = getUserDetails();
  let userRole = getLoggedInUserRole();
  const [imgData, setImageData] = React.useState("");
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [popups, setPopups] = React.useState({
    loginAsAdmin: false,
    loginAsRecruiter: false,
    loginAsClient: false,
    loginAsTeamMember: false,
  });
  const { imageInfo } = useSelector((state) => state?.recruiter);
  const loggedInUserDetails = useSelector(
    (state) => state?.loggedInUserDetails
  );

  const { impersonateUser} = useImpersonate();

  const handleLogout = () => {
    handleCloseUserMenu();

    navigate("/logout");
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
    setIsMobileMenuOpen(open);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenClose = () => {
    setOpen(!open);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  React.useEffect(() => {
     if(imageInfo?.imageUrl!=""){

    loadImage(imageInfo?.imageUrl, setImageData);
     }
     setImageData(null)
  }, [imageInfo?.imageUrl]);

  const handleOpenCloseDialog = (imageInfo) => {
    //debugger
    let imageModel = {
      id: userDetails?.id,
      imageInfo: {
        fileName: imageInfo != undefined ? imageInfo.fileName : "",
        newFileName: imageInfo != undefined ? imageInfo.newFileName : "",
        resourceUrl: imageInfo != undefined ? imageInfo.imageUrl : "",
      },
    };
    let apiToCall =
      userRole == UserAuth.Recruiter
        ? UpdateProfilePhoto(imageModel)
        : UpdateClientPhoto({
            clientId: userDetails?.id,

            companyLogo: {
              resourceUrl: imageInfo?.imageUrl,
              fileName: imageInfo?.fileName,
              newFileName: imageInfo?.newFileName,
            },
          });
    apiToCall
      .then((response) => {
        if (
          response.data.status == 200 &&
          (response.data.message.appStatusCode == "RC_RSF" ||
            response.data.message.appStatusCode == "CL_RSF")
        ) {
          dispatch(updateImage({ imageUrl: imageInfo?.imageUrl }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setOpen(!open);
  };
  const handleLoginAsClick = (userType) => {
    setPopups({ ...popups, [`loginAs${userType}`]: true });
    handleCloseUserMenu();
  };

  const loginAsSuperAdmin = () => {
    //Reset Left menu collapse index    
    dispatch(setExpandedCollapseLeftMenuIndex(0));
    let tk = decodeJwt();
    if (tk?.IsImpersonated && tk?.ImpersonatedBy) {
      let impersonatedBy = tk.ImpersonatedBy;
      impersonateUser(impersonatedBy);
      handleCloseUserMenu();
      setPopups({
        loginAsAdmin: false,
        loginAsRecruiter: false,
        loginAsClient: false,
      });
    }
  };

  const renderLoginAsSuperAdminOption = () => {
    let tk = decodeJwt();
    if (tk?.IsImpersonated) {
      return (
        <MenuItem data-testid="render-superadmin" onClick={loginAsSuperAdmin}>
          <ListItemIcon>
            <LoginIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            Login as {tk?.ImpersonatedByRole || "Admin"}
          </ListItemText>
        </MenuItem>
      );
    }
  };

  const renderLoginAsClientOption = () =>{
    return(
      <>
        <MenuItem data-testid="loginAsClient" onClick={()=>handleLoginAsClick("Client")}>
            <ListItemIcon>
              <LoginIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Login as Client</ListItemText>
          </MenuItem>          
      </>
    )
  }

  const renderLoginAsClientTeamMemberOption = () =>{
    return(
      <MenuItem data-testid="loginAsTeamMember" onClick={()=>handleLoginAsClick("TeamMember")}>
        <ListItemIcon>
          <LoginIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Login as Team Member</ListItemText>
      </MenuItem>
    )
  }

  const renderAdminHeader = () => {
    return (
      <React.Fragment>
        <ElevationScroll {...props}>
          <AppBar
            className={`${isMobile ? "isMobileMenuActive" : ""} main-header`}
            style={{ background: "#fff", color: "#000" }}
            position="fixed"
            open={NavigationSlice.isLeftMenuExpanded}
          >
            <Container maxWidth={false} disableGutters sx={{paddingLeft:1}}>
              <Toolbar disableGutters className="header-toolbar">
                <Typography
                  variant="h6"
                  noWrap
                  component="span"
                  sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
                >
                  <Link to={"/admindash"} className="TopHeaderMainLogo">
                    <img src={logo} className="logo" alt="logo" />
                  </Link>
                </Typography>

                <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    data-testid="adminToggleLeftMenu"
                    onClick={toggleDrawer("left", !isMobileMenuOpen)}
                    color="inherit"
                  >
                    <MenuIcon />
                  </IconButton>

                  <Drawer
                    anchor="left"
                    open={state["left"]}
                    onClose={toggleDrawer("left", false)}
                    className="MobileMenuNavigarDrawer"
                  >
                    <List sx={{ width: `${drawerWidth}px` }}>
                      <LeftNavigationRoot />
                      <Divider />
                      <MenuItem component={Link} to={getNevLink("/faq")}>
                        <ListItemIcon>
                          <FAQMenuIcon />
                        </ListItemIcon>
                        <ListItemText>FAQ</ListItemText>
                      </MenuItem>
                      <MenuItem data-testid="handleLogout" onClick={handleLogout}>
                        <ListItemIcon>
                          <ExitToAppIcon
                            fontSize="small"
                            sx={{ color: "#fff" }}
                          />
                        </ListItemIcon>
                        <ListItemText>Logout</ListItemText>
                      </MenuItem>
                    </List>
                  </Drawer>
                </Box>

                {/* logo for small screens  */}
                <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                  <Typography
                    variant="h6"
                    noWrap
                    component="span"
                    sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
                  >
                    <Link to={"/admindash"}>
                      <img
                        src={logo}
                        className="logo"
                        alt="logo"
                        style={{ width: "130px" }}
                      />
                    </Link>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    flexGrow: 1,
                    display: { xs: "none", md: "flex" },
                    justifyContent: "right",
                  }}
                  className="main-menu"
                >
                  {adminPages.map((page, index) => {
                    // Check if permissionId is provided and not present in allowedpermissionIds
                    if (
                      !page.permissionId ||
                      features?.includes(page.permissionId) ||
                      modules.includes(page.permissionId)
                    )
                      return (
                        <Button
                        className={
                          `${window.location.pathname.toLowerCase() === getNevLink(page.to)
                            ? "header-active"
                            : "header-not-active"
                          } header-font`
                        }
                          component={Link}
                          to={getNevLink(page.to)}
                          onClick={() => dispatch(setSelectedIndex(null))}
                          key={page.name}
                          sx={{ my: 2 }}
                        >
                          {page.name}
                        </Button>
                      );
                  })}
                </Box>
                <Box>
                  <ListItemText className="custom-login-username">
                    Hi!{" "}
                    {getFirstAndLastName(
                      JSON.parse(localStorage.getItem("user"))?.userName
                    )}
                  </ListItemText>
                </Box>
                <Box sx={{ flexGrow: 0 }} className="header-icon-menu">
                  <>
                    <IconButton data-testid="avtarIconAdmin" onClick={handleOpenUserMenu} sx={{ p: 1 }}>
                      <Avatar
                        alt={""}
                        src={imgData}
                        sx={{ width: 25, height: 25 }}
                      />
                    </IconButton>
                  </>

                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    <ModuleComponent moduleId={PermissionsEnums.Privileged}>
                      <MenuItem data-testid="loginAsRecruiter" onClick={() => handleLoginAsClick("Recruiter")}>
                        <ListItemIcon>
                          <LoginIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Login as Recruiter</ListItemText>
                      </MenuItem>
                      { isClientModuleEnabled && renderLoginAsClientOption() }
                      { isClientModuleEnabled && renderLoginAsClientTeamMemberOption() }
                    </ModuleComponent>
                    <MenuItem onClick={handleLogout}>
                      <ListItemIcon>
                        <img
                          className="ht-23 featurejobIcon"
                          src={logoutIcon}
                        />
                      </ListItemIcon>
                      <ListItemText>Logout</ListItemText>
                    </MenuItem>
                    {renderLoginAsSuperAdminOption()}
                  </Menu>
                </Box>
                {open && (
                  <UploadPhotoDialog
                    handleOpenClose={handleOpenClose}
                    open={open}
                  />
                )}
              </Toolbar>
            </Container>
          </AppBar>
        </ElevationScroll>
        <Toolbar />
        {popups.loginAsRecruiter && (
          <ImpersonateUser
            userType={UserAuth.Recruiter}
            isOpen={popups.loginAsRecruiter}
            onClose={() => setPopups({ ...popups, loginAsRecruiter: false })}
          />
        )}
         {popups.loginAsClient && (
          <ImpersonateUser
          userType={UserAuth.Client}

            isOpen={popups.loginAsClient}
            onClose={() => setPopups({ ...popups, loginAsClient: false })}
          />
        )}
        {popups.loginAsTeamMember && (
          <ImpersonateUser
          userType={UserAuth.ClientTeamMember}

            isOpen={popups.loginAsTeamMember}
            onClose={() => setPopups({ ...popups, loginAsTeamMember: false })}
          />
        )}
      </React.Fragment>
    );
  };
  const renderRecruiterHeader = () => {
    return (
      <React.Fragment>
        <ElevationScroll {...props}>
          <AppBar
            className={`${isMobile ? "isMobileMenuActive" : ""} main-header`}
            style={{ background: "#fff", color: "#000" }}
            position="fixed"
            open={NavigationSlice.isLeftMenuExpanded}
          >
            <Container maxWidth={false} disableGutters sx={{paddingLeft:1}}>
              <Toolbar disableGutters className="header-toolbar">
                <Typography
                  variant="h6"
                  noWrap
                  component="span"
                  sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
                >
                  <Link to={"/"} className="TopHeaderMainLogo">
                    <img src={logo} className="logo" alt="logo" />
                  </Link>
                </Typography>

                <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={toggleDrawer("left", !isMobileMenuOpen)}
                    color="inherit"
                  >
                    <MenuIcon />
                  </IconButton>

                  <Drawer
                    anchor="left"
                    open={state["left"]}
                    onClose={toggleDrawer("left", false)}
                    className="MobileMenuNavigarDrawer"
                  >
                    <List sx={{ width: `${drawerWidth}px` }}>
                      <LeftNavigationRoot />
                      <Divider />

                      <MenuItem component={Link} to={getNevLink("/faq")}>
                        <ListItemIcon>
                          <FAQMenuIcon />
                        </ListItemIcon>
                        <ListItemText>FAQ</ListItemText>
                      </MenuItem>

                      <MenuItem data-testid="uploadPhoto" onClick={handleOpenClose}>
                        <ListItemIcon>
                          <PhotoOutlinedIcon
                            fontSize="small"
                            sx={{ color: "#fff" }}
                          />
                        </ListItemIcon>
                        <ListItemText>Upload Photo</ListItemText>
                      </MenuItem>
                      <ModuleComponent
                        moduleId={PermissionsEnums.Manage_Profile}
                      >
                        <MenuItem component={Link} to={"/manage-profile"}>
                          <ListItemIcon>
                            <SettingsOutlinedIcon
                              fontSize="small"
                              sx={{ color: "#fff" }}
                            />
                          </ListItemIcon>
                          <ListItemText>Manage Profile</ListItemText>
                        </MenuItem>
                      </ModuleComponent>
                      <MenuItem onClick={handleLogout}>
                        <ListItemIcon>
                          <ExitToAppIcon
                            fontSize="small"
                            sx={{ color: "#fff" }}
                          />
                        </ListItemIcon>
                        <ListItemText>Logout</ListItemText>
                      </MenuItem>
                      {renderLoginAsSuperAdminOption()}
                    </List>
                  </Drawer>
                </Box>
                <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                  <Typography
                    variant="h6"
                    noWrap
                    component="span"
                    sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
                  >
                    <Link to={"/"}>
                      <img
                        src={logo}
                        className="logo"
                        alt="logo"
                        style={{ width: "130px" }}
                      />
                    </Link>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    flexGrow: 1,
                    display: { xs: "none", md: "flex" },
                    justifyContent: "right",
                  }}
                  className="main-menu"
                >
                  {recruiterHeaderNavigation.map((page, index) => {
                    // Check if permissionId is provided and not present in allowedpermissionIds
                    if (
                      !page.permissionId ||
                      features?.includes(page.permissionId)
                    )
                      return (
                        <Button
                        className={
                          `${window.location.pathname.toLowerCase() === getNevLink(page.to)
                            ? "header-active"
                            : "header-not-active"
                          } header-font`
                        }
                          component={Link}
                          to={getNevLink(page.to)}
                          onClick={() => dispatch(setSelectedIndex(null))}
                          key={page.name}
                          sx={{ my: 2 }}
                        >
                          {page.name}
                        </Button>
                      );
                  })}
                </Box>
                <Box>
                  <ListItemText className="custom-login-username">
                    Hi! <Fullname fullName={loggedInUserDetails?.userName}/>
                  </ListItemText>
                </Box>
                <Box sx={{ flexGrow: 0 }} className="header-icon-menu">
                  <IconButton 
                  data-testid="recruiterAvtar"
                  onClick={handleOpenUserMenu} sx={{ p: 1 }}>
                    <Avatar
                      alt={""}
                      src={imgData}
                      sx={{ width: 25, height: 25 }}
                    />
                  </IconButton>

                  <Menu
                    className="user-option"
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    <MenuItem data-testid="recruiterUplaodPhoto" onClick={handleOpenClose}>
                      <ListItemIcon>
                        <PhotoOutlinedIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Upload Photo</ListItemText>
                    </MenuItem>
                    <ModuleComponent moduleId={PermissionsEnums.Manage_Profile}>
                      <MenuItem
                        onClick={handleCloseUserMenu}
                        component={Link}
                        to={"/manage-profile"}
                      >
                        <ListItemIcon>
                          <SettingsOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Manage Profile</ListItemText>
                      </MenuItem>
                    </ModuleComponent>
                    <MenuItem onClick={handleLogout}>
                      <ListItemIcon>
                        <ExitToAppIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Logout</ListItemText>
                    </MenuItem>
                    {renderLoginAsSuperAdminOption()}
                  </Menu>
                </Box>
                {open && (
                  <UploadPhotoDialog
                    handleOpenClose={handleOpenCloseDialog}
                    open={open}
                  />
                )}
              </Toolbar>
            </Container>
          </AppBar>
        </ElevationScroll>
        <Toolbar />
      </React.Fragment>
    );
  };

  const renderClientHeader = () => {
    return (
      <React.Fragment>
        <ElevationScroll {...props}>
          <AppBar
            className={`${isMobile ? "isMobileMenuActive" : ""} main-header`}
            style={{ background: "#fff", color: "#000" }}
            position="fixed"
            open={NavigationSlice.isLeftMenuExpanded}
          >
            <Container maxWidth={false} disableGutters sx={{paddingLeft:1}}>
              <Toolbar disableGutters className="header-toolbar">
                <Typography
                  variant="h6"
                  noWrap
                  component="span"
                  sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
                >
                  <Link to={"/"} className="TopHeaderMainLogo">
                    <img src={logo} className="logo" alt="logo" />
                  </Link>
                </Typography>

                <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={toggleDrawer("left", !isMobileMenuOpen)}
                    color="inherit"
                  >
                    <MenuIcon />
                  </IconButton>

                  <Drawer
                    anchor="left"
                    open={state["left"]}
                    onClose={toggleDrawer("left", false)}
                    className="MobileMenuNavigarDrawer"
                  >
                    <List sx={{ width: `${drawerWidth}px` }}>
                      <LeftNavigationRoot />
                      <Divider />

                      <MenuItem component={Link} to={getNevLink("/faq")}>
                        <ListItemIcon>
                          <FAQMenuIcon />
                        </ListItemIcon>
                        <ListItemText>FAQ</ListItemText>
                      </MenuItem>

                      <MenuItem data-testid="uploadPhotoClient" onClick={handleOpenClose}>
                        <ListItemIcon>
                          <PhotoOutlinedIcon
                            fontSize="small"
                            sx={{ color: "#fff" }}
                          />
                        </ListItemIcon>
                        <ListItemText>Upload Photo</ListItemText>
                      </MenuItem>
                      <ModuleComponent
                        moduleId={PermissionsEnums.Manage_Profile}
                      >
                        <MenuItem
                          component={Link}
                          to={"/client/manage-profile"}
                        >
                          <ListItemIcon>
                            <SettingsOutlinedIcon
                              fontSize="small"
                              sx={{ color: "#fff" }}
                            />
                          </ListItemIcon>
                          <ListItemText>Manage Profile</ListItemText>
                        </MenuItem>
                      </ModuleComponent>
                      <MenuItem onClick={handleLogout}>
                        <ListItemIcon>
                          <ExitToAppIcon
                            fontSize="small"
                            sx={{ color: "#fff" }}
                          />
                        </ListItemIcon>
                        <ListItemText>Logout</ListItemText>
                      </MenuItem>
                      {renderLoginAsSuperAdminOption()}
                    </List>
                  </Drawer>
                </Box>

                <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                  <Typography
                    variant="h6"
                    noWrap
                    component="span"
                    sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
                  >
                    <Link to={"/"}>
                      <img
                        src={logo}
                        className="logo"
                        alt="logo"
                        style={{ width: "130px" }}
                      />
                    </Link>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    flexGrow: 1,
                    display: { xs: "none", md: "flex" },
                    justifyContent: "right",
                  }}
                  className="main-menu"
                >
                  {clientHeaderNavigation.map((page, index) => {
                    // Check if permissionId is provided and not present in allowedpermissionIds
                    if (
                      !page.permissionId ||
                      features?.includes(page.permissionId)                     )
                    return (
                      <Button
                      className={
                        `${window.location.pathname.toLowerCase() === getNevLink(page.to)
                          ? "header-active"
                          : "header-not-active"
                        } header-font`
                      }
                        component={Link}
                        onClick={() => dispatch(setSelectedIndex(null))}
                        to={getNevLink(page.to)}
                        key={page.name}
                        sx={{ my: 2 }}
                      >
                        {page.name}
                      </Button>
                    );
                  })}
                </Box>
                <Box>
                  <ListItemText className="custom-login-username">
                    Hi! <Fullname fullName={loggedInUserDetails?.userName}/>
                  </ListItemText>
                </Box>
                <Box sx={{ flexGrow: 0 }} className="header-icon-menu">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 1 }}>
                    <Avatar
                      alt={""}
                      src={valueOrDefault(imgData, "")}
                      sx={{ width: 25, height: 25 }}
                    />
                  </IconButton>

                  <Menu
                    className="user-option"
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    <MenuItem onClick={handleOpenClose}>
                      <ListItemIcon>
                        <PhotoOutlinedIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Upload Photo</ListItemText>
                    </MenuItem>
                    <ModuleComponent moduleId={PermissionsEnums.Manage_Profile}>
                      <MenuItem
                        onClick={handleCloseUserMenu}
                        component={Link}
                        to={"/client/manage-profile"}
                      >
                        <ListItemIcon>
                          <SettingsOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Manage Profile</ListItemText>
                      </MenuItem>
                    </ModuleComponent>
                    <MenuItem onClick={handleLogout}>
                      <ListItemIcon>
                        <ExitToAppIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Logout</ListItemText>
                    </MenuItem>
                    {renderLoginAsSuperAdminOption()}
                  </Menu>
                </Box>
                {open && (
                  <UploadPhotoDialog
                    handleOpenClose={handleOpenCloseDialog}
                    open={open}
                  />
                )}
              </Toolbar>
            </Container>
          </AppBar>
        </ElevationScroll>
        <Toolbar />
      </React.Fragment>
    );
  };
  const renderSuperAdminHeader = () => {
    return (
      <React.Fragment>
        <ElevationScroll {...props}>
          <AppBar
            className={`${isMobile ? "isMobileMenuActive" : ""} main-header`}
            style={{ background: "#fff", color: "#000" }}
            position="fixed"
            open={NavigationSlice.isLeftMenuExpanded}
          >
            <Container maxWidth={false} disableGutters sx={{paddingLeft:1}}>
              <Toolbar disableGutters className="header-toolbar">
                <Typography
                  variant="h6"
                  noWrap
                  component="span"
                  sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
                >
                  <Link
                    to={getNevLink("/dashboard")}
                    className="TopHeaderMainLogo"
                  >
                    <img src={logo} className="logo" alt="logo" />
                  </Link>
                </Typography>

                <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={toggleDrawer("left", !isMobileMenuOpen)}
                    color="inherit"
                  >
                    <MenuIcon />
                  </IconButton>

                  <Drawer
                    anchor="left"
                    open={state["left"]}
                    onClose={toggleDrawer("left", false)}
                    className="MobileMenuNavigarDrawer"
                  >
                    <List sx={{ width: `${drawerWidth}px` }}>
                      <LeftNavigationRoot />
                      <Divider />
                      <MenuItem component={Link} to={getNevLink("/faq")}>
                        <ListItemIcon>
                          <FAQMenuIcon />
                        </ListItemIcon>
                        <ListItemText>FAQ</ListItemText>
                      </MenuItem>
                      <MenuItem onClick={handleLogout}>
                        <ListItemIcon>
                          <ExitToAppIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Logout</ListItemText>
                      </MenuItem>
                    </List>
                  </Drawer>
                </Box>

                {/* logo for small screens  */}
                <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                  <Typography
                    variant="h6"
                    noWrap
                    component="span"
                    sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
                  >
                    <Link to={"/admindash"}>
                      <img
                        src={logo}
                        className="logo"
                        alt="logo"
                        style={{ width: "130px" }}
                      />
                    </Link>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    flexGrow: 1,
                    display: { xs: "none", md: "flex" },
                    justifyContent: "right",
                  }}
                  className="main-menu"
                >
                  {adminPages.map((page, index) => {
                    // Check if permissionId is provided and not present in allowedpermissionIds
                    if (
                      !page.permissionId ||
                      features.includes(page.permissionId) ||
                      modules.includes(page.permissionId)
                    )
                      return (
                        <Button
                        className={
                          `${window.location.pathname.toLowerCase() === getNevLink(page.to)
                            ? "header-active"
                            : "header-not-active"
                          } header-font`
                        }
                          component={Link}
                          to={getNevLink(page.to)}
                          onClick={() => dispatch(setSelectedIndex(null))}
                          key={page.name}
                          sx={{ my: 2 }}
                        >
                          {page.name}
                        </Button>
                      );
                  })}
                </Box>
                <Box>
                  <ListItemText className="custom-login-username">
                    Hi!{" "}
                    {getFirstAndLastName(
                      JSON.parse(localStorage.getItem("user"))?.userName
                    )}
                  </ListItemText>
                </Box>
                <Box sx={{ flexGrow: 0 }} className="header-icon-menu">
                  <>
                    <IconButton data-testid="avtarIcon" onClick={handleOpenUserMenu} sx={{ p: 1 }}>
                      <Avatar
                        alt={""}
                        src={imgData}
                        sx={{ width: 25, height: 25 }}
                      />
                    </IconButton>
                  </>                  
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    
                    <ModuleComponent moduleId={PermissionsEnums.Privileged}>
                      <MenuItem data-testid="loginAsAdmin" onClick={() => handleLoginAsClick("Admin")}>
                        <ListItemIcon>
                          <LoginIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Login as Admin</ListItemText>
                      </MenuItem>
                      <MenuItem  data-testid="loginAsRecruiter" onClick={() => handleLoginAsClick("Recruiter")}>
                        <ListItemIcon>
                          <LoginIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Login as Recruiter</ListItemText>
                      </MenuItem>
                      { isClientModuleEnabled && renderLoginAsClientOption() }
                      { isClientModuleEnabled && renderLoginAsClientTeamMemberOption() }
                    </ModuleComponent>
                    <MenuItem onClick={handleLogout}>
                      <ListItemIcon>
                        <ExitToAppIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Logout</ListItemText>
                    </MenuItem>
                  </Menu>
                </Box>
                {open && (
                  <UploadPhotoDialog
                    handleOpenClose={handleOpenClose}
                    open={open}
                  />
                )}
              </Toolbar>
            </Container>
          </AppBar>
        </ElevationScroll>
        <Toolbar />
        {popups.loginAsRecruiter && (
          <ImpersonateUser
            userType={UserAuth.Recruiter}
            isOpen={popups.loginAsRecruiter}
            onClose={() => setPopups({ ...popups, loginAsRecruiter: false })}
          />
        )}
        {popups.loginAsAdmin && (
          <ImpersonateUser
            userType={UserAuth.Admin}
            isOpen={popups.loginAsAdmin}
            onClose={() => setPopups({ ...popups, loginAsAdmin: false })}
          />
        )}
        {popups.loginAsClient && (
          <ImpersonateUser
            userType={UserAuth.Client}
            isOpen={popups.loginAsClient}
            onClose={() => setPopups({ ...popups, loginAsClient: false })}
          />
        )}
        {popups.loginAsTeamMember && (
          <ImpersonateUser
            userType={UserAuth.ClientTeamMember}
            isOpen={popups.loginAsTeamMember}
            onClose={() => setPopups({ ...popups, loginAsTeamMember: false })}
          />
        )}
      </React.Fragment>
    );
  };

  //Not logged
  switch (userRole) {
    case UserAuth.NotLoggedIn:
      return <HeaderNav />;
    case UserAuth.Recruiter:
      return renderRecruiterHeader();
    case UserAuth.Client:
      return renderClientHeader();
    case UserAuth.ClientTeamMember:
      return renderClientHeader();
    case UserAuth.Admin:
      return renderAdminHeader();
    case UserAuth.SuperAdmin:
      return renderSuperAdminHeader();
    default:
      return <HeaderNav />;
  }
}
