import { createSlice } from "@reduxjs/toolkit";

interface BreadcrumbsModelProps{
    navigateFromPage:string;
    previousLink?:string
}

const initialState: BreadcrumbsModelProps = {
    navigateFromPage:"",
    previousLink:""
}
  
const BreadcrumbsStateSlice = createSlice({
  name: "BreadcrumbsStateSlice",
  initialState,
  reducers: {
    setBreadCrumbsState: (state, action: any) => {
      return action.payload;
    },
    resetBreadCrumbsState: () => {
      return initialState;
    },
  },
});

export const { setBreadCrumbsState, resetBreadCrumbsState } = BreadcrumbsStateSlice.actions;

export default BreadcrumbsStateSlice.reducer;
