import { useSelector } from 'react-redux';
import { UserAuth } from '../../models/interfaces'
import getLoggedInUserRole from '../../Shared/utils/getLoggedInUserRole'
import AdminDash from './AdminDash'
import Dashboard from './Dashboard'
import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { PermissionsEnums } from '../../data/permission';
import { Error } from '../Common/Error';
import { getIsClientModuleEnabled } from '../../utils/AppConfig';
import { ternary } from '../../utils/complexityUtils';
import { ModuleDisabledMessage } from '../Common/ModuleDisabledMessage';

export const MainDashboard = () => {
  const isClientModuleEnabled = getIsClientModuleEnabled();
    const [shouldReload, setShouldReload] = useState(false);
    const { features, modules } = useSelector((state) => state.userPermissions);

    useEffect(() => {
      if (window.location.pathname === "/dashboard") {
        setShouldReload(true);
      }
    }, [window.location.pathname]);
  
    useEffect(() => {
      if (shouldReload) {
        setShouldReload(false);
      }
    }, [shouldReload]);
  
    
    let userRole = getLoggedInUserRole()
    const renderDashboard = ()=> {
      
      const isAdminDashboard = modules.includes(PermissionsEnums.Dashboard_Admin) || features.includes(PermissionsEnums.Dashboard_Admin);
      switch (userRole) {
        case UserAuth.Admin:
          return isAdminDashboard ? <AdminDash /> :<Error message="You don't have permission to access this page"/>;
        case UserAuth.Recruiter:
          return modules.includes(PermissionsEnums.Dashboard_Recruiter) || features.includes(PermissionsEnums.Dashboard_Recruiter) ? <Dashboard /> :<Error message="You don't have permission to access this page"/>;
        case UserAuth.SuperAdmin:
          return isAdminDashboard ? <AdminDash /> :<Error message="You don't have permission to access this page"/>;
        case UserAuth.Client:
          return ternary( 
            isClientModuleEnabled, 
            <Navigate to={'/client/dashboard'}/>, 
            <ModuleDisabledMessage />
          ) 
        case UserAuth.ClientTeamMember:
          return ternary( 
            isClientModuleEnabled, 
            <Navigate to={'/team/dashboard'}/>, 
            <ModuleDisabledMessage />
          )           
        default:
          
          return null;
      }
     }
  return (
    <>{renderDashboard()}</>
  )
}
