import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import BannerText from "../Common/BannerText";
import { LoginBanner } from "./LoginBanner";
import SigninForm from "./SigninForm";
import { ForgotPasswordBanner } from "./ForgotPasswordBanner";

export enum LoginComponentMode {
  Login = "LOGIN",
  Forgot = "FORGOT",
  Reset = "RESET",
}

const Login: React.FC = () => {

  const [mode, setMode] = React.useState(LoginComponentMode.Login);

  const renderLoginSection = () => {
   
    return <React.Fragment>
      {(mode !== LoginComponentMode.Reset) &&
        <BannerText bannerText="Your One Stop Recruitment Marketplace" />
      }
      <Box
        sx={{
          flexGrow: 1,
          padding: "20px 0",
        }}
      >
        <Container maxWidth="lg" className="login-banner-text">
          <Grid container spacing={0} sx={{ justifyContent: "center", marginTop: "30px" }}>
            {(mode !== LoginComponentMode.Reset) &&
              <Grid style={{maxWidth:"520px"}} item xs={12} sm={12} md={6} p={0}>
                { (mode == LoginComponentMode.Forgot) ? 
                  <ForgotPasswordBanner />
                  :
                  <LoginBanner />
                }
              </Grid>
            }
            <Grid style={{maxWidth:"488px"}}item xs={12} md={6} className="loginFormWrapper" pl={0}> 
                <SigninForm setMode={setMode}/>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  }
  
  return (
    <>
    {renderLoginSection()}
    </>
  );
};
export default Login;
