import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { GetCandidateTimezoneTypes } from '../../../../Services/candidateService';

export interface TimezoneType {
  id: string;
  timeZone: string;
}

interface TimeZoneAutoCompleteProps {
  label:string;
  fieldName:string;
  value:TimezoneType;
  isRequired?:boolean;
  isReadOnly?:boolean;
  isError?: boolean;
  helperText?:string | boolean;
  handleChange: (selectedEventType: TimezoneType | null) => void;
  handleBlur: (e: React.FocusEvent<any, Element>) => void;
}

const TimeZoneAutoComplete: React.FC<TimeZoneAutoCompleteProps> = ({ 
  label,
  value,
  fieldName,
  isRequired=false,
  isReadOnly=false,
  isError=false,
  helperText="", 
  handleChange,
  handleBlur 
 }) => {
  
   //Initial component States

   const [eventTimezoneTypesList, setTimezoneTypesList] = useState<TimezoneType[]>([]);


   useEffect(()=>{
    _GetCandidateTimezoneTypes();
   },[])
 
     // GET Master Timezone Type for candidate interview schedule
     const _GetCandidateTimezoneTypes = async () => {
         GetCandidateTimezoneTypes()
         .then((response: any) => {
           if (response?.data?.message?.appStatusCode === "RC_RSF") {
            setTimezoneTypesList(response?.data?.entityList);
           } else {
            setTimezoneTypesList([]);
           }
         })
         .catch((error) => {
           console.log(error);
         });
     };

  const handleTimezoneChange = (_event: React.ChangeEvent<{}>, changedValue: TimezoneType | null) => {
    handleChange(changedValue);
  };

  return (
    <Autocomplete
      readOnly={isReadOnly}
      value={value}
      onChange={handleTimezoneChange}
      options={eventTimezoneTypesList}
      getOptionLabel={(option) => option.timeZone}
      renderInput={(params) => (
        <TextField
          {...params}
          required={isRequired}
          label={label}
          name={fieldName}
          variant="standard"
          error={isError}
          helperText={helperText}
          onBlur={handleBlur}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
    />
  );
};

export default TimeZoneAutoComplete;
