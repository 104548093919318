import { Input, InputLabel } from "@material-ui/core";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { FormControl, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import { UpdateUserProfile } from "../../../../Services/clientService";
import {
  DeleteAttachment,
  UploadAttachment,
} from "../../../../Services/uploadPhotoService";
import { linkedInRegex } from "../../../../constants/regex";
import { DocumentTypeEnum } from "../../../../models/interfaces";
import {
  downloadFile,
  getFileFromUrl,
  getFilePreviewUrl,
} from "../../../../utils/getFileFromUrl";
import { DeleteDocumentIcon } from "../../../Common/Icons/DeleteDocument";
import PreviewFileDialog from "../../../Common/PreviewFileDialog";
import { Asterisk } from "../../../ui/Asterisk";
import { UpdateConfirmationDialog } from "../../../ui/UpdateConfirmationDialog";
import LogoUploader from "../../ClientSignUp/components/FormFields/logoUploader";
import { pageStatus } from "../ManageProfile";

interface UserProfileFormProps {
  userInfo?: any;
  onChildFunction: any;
  clientId?: string;
  setPageStatus: any;
  pageStatus:pageStatus;
}
export const UserProfileForm: React.FC<UserProfileFormProps> = ({
  userInfo,
  onChildFunction,
  clientId,
  setPageStatus,
  pageStatus,
}) => {  
  const [selectedFile, setSelectedFile] = React.useState({
    file: null,
    error: "",
  });
  const [filePreview, setFilePreview] = React.useState<
    | undefined
    | {
        resourceUrl: string;
        fileName: string;
        fileType?: string;
      }
  >(undefined);

  const [confimationData, setConfimationData] = React.useState({
    open: false,
    title: "",
    description: "",
  });
  const initialState = {
    clientId: clientId,
    clientAdditionalDetails: {
      companyLogo: {
        resourceUrl: "",
        fileName: "",
        newFileName: "",
      },
      companyOverview: "",
      companyBenefits: "",
      hiringProcess: "",
      linkedInProfileUrl: "",
    },
  };
  const userProfileSchema = Yup.object({
    clientAdditionalDetails: Yup.object().shape({
      companyOverview: Yup.string(),
      companyBenefits: Yup.string(),
      hiringProcess: Yup.string().required("Hiring Process is required"),
      linkedInProfileUrl: Yup.string()
        .matches(linkedInRegex, "LinkedIn URL doesn't match"),
    }),
  });

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: userProfileSchema,
    onSubmit: async (values, actions) => {
      setPageStatus({
        ...pageStatus,
        isSuccess:false,
        loading:true,
      });
      if (selectedFile.error) {
        return;
      }

      let reqBody = { ...values };
      if (selectedFile.file) {
        UploadAttachment({
          documentType: DocumentTypeEnum.Other,
          file: selectedFile.file,
        })
          .then((uploadResponse) => {
            // Create the nested property if it doesn't exist
            if (!reqBody.clientAdditionalDetails.companyLogo) {
              reqBody.clientAdditionalDetails.companyLogo = {};
            }

            reqBody.clientAdditionalDetails.companyLogo.resourceUrl =
              uploadResponse?.data?.entity?.absoluteUri;
            reqBody.clientAdditionalDetails.companyLogo.fileName =
              uploadResponse?.data?.entity?.fileName;
            reqBody.clientAdditionalDetails.companyLogo.newFileName =
              uploadResponse?.data?.entity?.newFileName;

            // Call EditByRole inside this block to ensure it runs after UploadAttachment is successful
            UpdateUserProfile(reqBody)
            .then((response)=>{
              if(response?.data?.message?.appStatusCode === "CL_RSF"){
                setPageStatus({
                  isSuccess: true,
                  isError: false,
                  laoding:false,
                  message: "Company Profile Updated Successfully.",
                  key: Date.now(),
                });
              }else{
                setPageStatus({
                  isSuccess: false,
                  isError: true,
                  laoding:false,
                  message: response?.data?.message?.appStatusDescription + "Something went ",
                  key: Date.now(),
                });
              }
            })
          })
          .catch((error) => {
            // Handle errors from UploadAttachment
            console.log(error);
          });
      } else {
        // Execute EditByRole directly when selectedFile.file is null
        delete reqBody.clientAdditionalDetails.companyLogo;
        UpdateUserProfile(reqBody)
        .then((response)=>{
          if(response?.data?.message?.appStatusCode === "CL_RSF"){
            setPageStatus({
              isSuccess: true,
              isError: false,
              laoding:false,
              message: "Company Profile Updated Successfully.",
              key: Date.now(),
            });
          }else{
            setPageStatus({
              isSuccess: false,
              isError: true,
              laoding:false,
              message: response?.data?.message?.appStatusDescription + "Something went wrong!!!",
              key: Date.now(),
            });
          }
        })
      }
      
    },
  });

  const loadFile = async () => {
    let fUrl = userInfo?.clientAdditionalDetails?.companyLogo;
    if (fUrl) {
      let file = await getFileFromUrl(fUrl?.resourceUrl, fUrl.fileName);
      setSelectedFile({ file, error: "" });
    }
  };

  const childFunction = () => {
    formik.handleSubmit();
    console.log(formik.errors);
  };
  const handleLogoChange = (e: any, type = "2") => {
    let file;
    if (type == "1") {
      file = e.target.files[0];
    } else {
      file = e;
    }
    if (file) {
      const allowedFormats = ["image/jpeg", "image/png"];
      if (!allowedFormats.includes(file.type)) {
        setSelectedFile({ file: null, error: "Invalid file format." });
        return;
      }

      const maxSize = 200 * 1024; // 200KB in bytes
      if (file.size > maxSize) {
        setSelectedFile({
          file: null,
          error: "File size exceeds the 2MB limit",
        });
        return;
      }
      setSelectedFile({ file, error: "" });
    }
  };

  const handleConfirmation = () => {
    let resourceName =
      formik.values?.clientAdditionalDetails?.companyLogo?.fileName;
    setSelectedFile({ file: null, error: "" });
    DeleteAttachment(DocumentTypeEnum.Other, resourceName);
    formik.setFieldValue("clientAdditionalDetails.companyLogo.fileName", "");
    setConfimationData({
      open: false,
      title: ``,
      description: "",
    });
  };
  const handleConfirmationClose = () => {
    setConfimationData({
      open: false,
      title: ``,
      description: "",
    });
  };

  // Make the child function accessible through the prop
  onChildFunction(childFunction);

  React.useEffect(() => {
    formik.setFieldValue(
      "clientAdditionalDetails",
      userInfo?.clientAdditionalDetails
    );
    loadFile();
  }, [userInfo]);

  let resourceUrl =
    formik.values?.clientAdditionalDetails?.companyLogo?.resourceUrl;

  let resourceName =
    formik.values?.clientAdditionalDetails?.companyLogo?.fileName;

  return (
    <div>
      <Box>
        <Grid container spacing={3}>
          <Grid className="custom-userprfl-top" item xs={12} sm={12}>
            <Typography className="signUpFormHeading" variant="h6" gutterBottom>
              Profile
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel className="additionInfoFieldLabel">
              Company Logo
            </InputLabel>
            {!formik.values?.clientAdditionalDetails?.companyLogo?.fileName ? (
              <>
                <div style={{ marginTop: "5px" }}></div>
                <LogoUploader
                  logoFile={selectedFile?.file}
                  handleUploadChange={handleLogoChange}
                />
                <Typography mt={1} className="roboto-btn">
                  (Max file size: 200KB as .png,.jpg,.jpeg)
                </Typography>
              </>
            ) : (
              <>
                <FormControl variant="standard" style={{ width: "70%" }}>
                  <Input
                    name="fileInput"
                    readOnly
                    type="text"
                    value={selectedFile?.file?.name || "N/A"}
                  />
                  {selectedFile.error ? (
                    <div style={{ color: "#f44336" }}>{selectedFile.error}</div>
                  ) : null}
                </FormControl>
                <>
                  <VisibilityOutlinedIcon
                    data-testid="primary"
                    onClick={() => {
                      getFilePreviewUrl(resourceUrl).then((filePreviewUrl) =>
                        setFilePreview({
                          resourceUrl: filePreviewUrl,
                          fileName: resourceName,
                          fileType: "image/jpeg",
                        })
                      );
                    }}
                    color={resourceName ? "primary" : "disabled"}
                    className={resourceName ? "pointer" : ""}
                    sx={{ pl: 1, verticalAlign: "bottom" }}
                  />
                  <SaveAltOutlinedIcon
                    data-testid="pointer"
                    onClick={() => {
                      downloadFile(resourceUrl, resourceName);
                    }}
                    color={resourceName ? "primary" : "disabled"}
                    className={resourceName ? "pointer" : ""}
                    sx={{
                      pl: 1,
                      verticalAlign: "bottom",
                      marginBottom: "3px",
                    }}
                  />

                  <DeleteDocumentIcon
                    onClick={() => {
                      setConfimationData({
                        open: true,
                        title: `Are you sure you want to delete this file?`,
                        description: "",
                      });
                    }}
                    inheritViewBox
                    className="pointer"
                    sx={{
                      pl: 1,
                      verticalAlign: "bottom",
                      height: "20px",
                      width: "20px",
                    }}
                  />
                </>
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel className="additionInfoFieldLabel">
              Linkedin Profile URL
            </InputLabel>
            <TextField
              id="companyOverView"
              name="clientAdditionalDetails.linkedInProfileUrl"
              value={formik.values.clientAdditionalDetails?.linkedInProfileUrl}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={
                formik.touched?.clientAdditionalDetails?.linkedInProfileUrl &&
                formik.errors?.clientAdditionalDetails?.linkedInProfileUrl
              }
              fullWidth
              type="text"
              variant="standard"
              className="AdditionInfoTxtArea"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel className="additionInfoFieldLabel">
              Company Benefits
            </InputLabel>
            <TextField
              required
              id="companyBenefits"
              name="clientAdditionalDetails.companyBenefits"
              value={formik.values.clientAdditionalDetails?.companyBenefits}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched?.clientAdditionalDetails?.companyBenefits &&
                Boolean(formik.errors?.clientAdditionalDetails?.companyBenefits)
              }
              helperText={
                formik.touched?.clientAdditionalDetails?.companyBenefits &&
                formik.errors?.clientAdditionalDetails?.companyBenefits
              }
              fullWidth
              type="text"
              multiline
              rows={4}
              variant="outlined"
              className="AdditionInfoTxtArea"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel className="additionInfoFieldLabel">
              Hiring Process
              <Asterisk />
            </InputLabel>
            <TextField
              required
              id="hiringProcess"
              name="clientAdditionalDetails.hiringProcess"
              value={formik.values.clientAdditionalDetails?.hiringProcess}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched?.clientAdditionalDetails?.hiringProcess &&
                Boolean(formik.errors?.clientAdditionalDetails?.hiringProcess)
              }
              helperText={
                formik.touched?.clientAdditionalDetails?.hiringProcess &&
                formik.errors?.clientAdditionalDetails?.hiringProcess
              }
              fullWidth
              type="text"
              multiline
              rows={4}
              variant="outlined"
              className="AdditionInfoTxtArea"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel className="additionInfoFieldLabel">
              Company Overview
            </InputLabel>
            <TextField
              id="companyOverview"
              name="clientAdditionalDetails.companyOverview"
              value={formik.values.clientAdditionalDetails?.companyOverview}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched?.clientAdditionalDetails?.companyOverview &&
                Boolean(formik.errors?.clientAdditionalDetails?.companyOverview)
              }
              helperText={
                formik.touched?.clientAdditionalDetails?.companyOverview &&
                formik.errors?.clientAdditionalDetails?.companyOverview
              }
              fullWidth
              type="text"
              multiline
              rows={4}
              variant="outlined"
              className="AdditionInfoTxtArea"
            />
          </Grid>
          <Grid item xs={12} sm={6}></Grid>
        </Grid>
      </Box>
      {filePreview !== undefined && (
        <PreviewFileDialog
          open={filePreview !== undefined}
          handleOpenClose={() => setFilePreview(undefined)}
          fileName={filePreview.fileName}
          fileURL={filePreview.resourceUrl}
          fileType={filePreview.fileType}
        />
      )}
      <UpdateConfirmationDialog
        message={confimationData.title}
        aggreeText={"Delete"}
        disagreeText={"Cancel"}
        onClose={() => handleConfirmationClose}
        open={confimationData.open}
        onYes={handleConfirmation}
        onNo={handleConfirmationClose}
      />
    </div>
  );
};
