import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react";
import { AxiosError, AxiosRequestConfig } from "axios";
import axiosInstance from "../../Services/CommonHttpRequest";
import { AppResponse } from "../../models/app/AppResponse";
import {
  ClientJobFilter,
  ClientJobStateFilter,
  ClientJobStateType,
} from "../../models/client/ClienJobModels";

let baseUrlClient = `${process.env.REACT_APP_JOBPROVIDER_API}`;
const filterInitialState: ClientJobStateFilter = {
  domains: [],
  showRemoteJobs: false,
  showFeaturedJobs: false,
  jobTypes: [],
  showDisabledJobs: false,
  showPendingJobs:false,
  searchKeywords:"",
  advanceSearch : {
    jobTitle: [],
    location: [],
    client: [],
    minimumPayRate:  0,
    maximumPayRate:  0,
    currency: "",
  },
  clientGuid:''

  
};

const initialState: ClientJobStateType = {
  filter: filterInitialState,
  savedJobPageFilter:filterInitialState,
  filterType: "filter",
  pendingFilter: filterInitialState,
};

export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl?: string } = { baseUrl: baseUrlClient }
  ): BaseQueryFn<
    {
      url: string;
      method?: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
    },
    unknown,
    unknown
  > =>
  async ({ url, method = "GET", data }) => {
    try {
      const result = await axiosInstance({ url: baseUrl + url, method, data });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: { status: err.response?.status, data: err.response?.data },
      };
    }
  };

export const clientJobSlice = createSlice({
  name: "ClientJob",
  initialState: initialState,
  reducers: {
    handleLocations: (state, action: PayloadAction<any[]>) => {
      state[state.filterType].locations = action.payload;
    },
    handleDomainChange: (state, action: PayloadAction<string>) => {
      const domain = action.payload;
      const domainIndex = state[state.filterType]?.domains.indexOf(domain);
      if (domainIndex !== -1) {
        // Domain is already in the array, remove it
        state[state.filterType]?.domains.splice(domainIndex, 1);
      } else {
        // Domain is not in the array, add it
        state[state.filterType]?.domains.push(domain);
      }
    },
    handleJobSearchKeyword: (state, action: PayloadAction<string>) => {
        state[state.filterType].searchKeywords = action.payload;
        state[state.filterType].advanceSearch = filterInitialState.advanceSearch;

    },
    handleAdvanceSearchKeyword: (state, action: PayloadAction<any>) => {
      state[state.filterType].searchKeywords = '';

      state[state.filterType].advanceSearch = action.payload;
  },
    handleCheckboxFilter: (state, action: PayloadAction<any>) => {
      let type = action.payload.filterType;
      let value = action.payload.filterValue;
      switch (type) {
        case "remote":
          state[state.filterType].showRemoteJobs = value;
          break;
        case "featured":
          state[state.filterType].showFeaturedJobs = value;
          break;
        case "jobTypes":
          const domainIndex = state[state.filterType].jobTypes.indexOf(value);

          if (domainIndex !== -1) {
            state[state.filterType].jobTypes.splice(domainIndex, 1);
          } else {
            state[state.filterType].jobTypes.push(value);
          }
          break;
        case "disabled":
          state[state.filterType].showDisabledJobs = value;
          break;
      

        default:
          break;
      }
    },
    ClearClientFilter: (state) => {
      state[state.filterType] = {
        domains: [],
        showRemoteJobs: false,
        showFeaturedJobs: false,
        jobTypes: [],
        showDisabledJobs: false,
        searchKeywords:'',
        advanceSearch : filterInitialState.advanceSearch,
        showPendingJobs : state[state.filterType].showPendingJobs,
        
      };
    },
    
    resetAllJobFilter: (state) => {
      state["filter"] = { ...filterInitialState };
      state["pendingFilter"] = { ...filterInitialState };
      state["savedJobPageFilter"] = { ...filterInitialState }; 
    },
    resetSavedJobFilter: (state) => {
      state["savedJobPageFilter"] = { ...filterInitialState }; 
    },
    setJobRecruiterGuid: (
      state,
      action: PayloadAction<typeof initialState.filterType>
    ) => {
      state[state.filterType].recruiterGuid =  ""
    },
    
    setActiveJobPage: (
      state,
      action: PayloadAction<typeof initialState.filterType>
    ) => {
      state.filterType = action.payload;
      state[state.filterType].showPendingJobs =  action.payload === 'filter' ? false:true
    },
    setDomainOnSignin:(state,action: PayloadAction<any>) => {
      const domains = action.payload;
      state.filter.domains= domains;
      state.savedJobPageFilter.domains = domains;
      
    },
    setFeaturedOnSignin:(state,action: PayloadAction<any>) => {
      const featured = action.payload;
      state.filter.showFeaturedJobs= featured;
      
    },
    resetAdvanceFilter:(state)=>{
      state[state.filterType].advanceSearch = filterInitialState.advanceSearch
    }
  },
});

export const clientJobApi = createApi({
  reducerPath: "ClientJobApi",
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    getJobs: build.mutation<AppResponse<any>, ClientJobFilter>({
      query: (filter) => ({
        url: `api/Jobs/GetJob`,
        method: "post",
        data: filter,
      }),
    }),
  }),
});



export const { useGetJobsMutation } = clientJobApi;

export const savedJobJobApi = createApi({
  reducerPath: "SavedJobJobApi",
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    getSavedJobs: build.mutation<AppResponse<any>, ClientJobFilter>({
      query: (filter) => ({
        url: `api/SavedJobs/GetJob`,
        method: "post",
        data: filter,
      }),
    }),
  }),
});
export const { useGetSavedJobsMutation } = savedJobJobApi;

export const {
  handleLocations,
  handleJobSearchKeyword,
  ClearClientFilter,
  resetAllJobFilter,
  resetSavedJobFilter,
  handleDomainChange,
  handleCheckboxFilter,
  setJobRecruiterGuid,
  setActiveJobPage,
  handleAdvanceSearchKeyword,
  setDomainOnSignin,
  setFeaturedOnSignin,
  resetAdvanceFilter
} = clientJobSlice.actions;

export const clientJobReducer = clientJobSlice.reducer;
