import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import DialogContentText from "@mui/material/DialogContentText";
import { Link } from "react-router-dom";
import "../VerifyRecruiter/VerifySuccessDialogRecruiter.scss";
import { getNevLink } from "../../../utils/getFileFromUrl";
import SuccessIcon from "./../../../assets/images/Approved.svg";

export const VerifyRecruiterSuccessPopup = (props: any) => {
  const getRecruiterStatusMessage = (status: string) => {
    let type = "";

    if (status === "Suspended") {
      type = "suspended";
    } else if (status === "MarkIncomplete") {
      type = "marked incomplete";
    } else if (status === "Rejected") {
      type = "rejected";
    } else if (status === "Pending") {
      type = "pending";
    } else if (status === "Approved") {
      type = "approved";
    } else {
      type = status;
    }

    return `Recruiter has been ${type} successfully.`;
  };

  const getRecruiterDescStatusMessage = (status: string) => {
    let type = "";

    switch (status) {
      case "Suspended":
        type = "suspended";
        break;
      case "MarkIncomplete":
        type = "marked incomplete";
        break;
      case "Rejected":
        type = "rejected";
        break;
      case "Pending":
        type = "pending";
        break;
      default:
        type = status;
    }

    return `The recruiter has been successfully ${type}. Thank you for your feedback.`;
  };

  return (
    <Dialog onClose={props.onClose} open={props.open}>
      <DialogContent>
        <IconButton
          aria-label="close"
          data-testid="CloseIcon"
          onClick={props.onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContentText
          component="div"
          id="success-dialog-description"
          textAlign="center"
        >
          <figure>
            <img
              src={SuccessIcon}
              className="PopupStatusIcon"
              alt="Success Icon"
            />
          </figure>
          <Typography component="div" className="PopupPrimaryTitle" my={2}>
            {getRecruiterStatusMessage(props.recruiterStatus)}
          </Typography>
          <Typography
            component="div"
            fontWeight={600}
            my={2}
            className="PopupSecondaryTitle"
          >
            {props.recruiterStatus === "Approved" ? (
              <>
                The recruiter has been successfully approved and added to the
                JobRialto. Thank you for your feedback.
              </>
            ) : (
              <>{getRecruiterDescStatusMessage(props.recruiterStatus)}</>
            )}
          </Typography>

          <Box my={3}>
            <Button
              style={{
                textTransform: "none",
                fontSize: "1rem",
                marginRight: "5px",
              }}
              component={Link}
              to={getNevLink("/admindash")}
              variant="contained"
            >
              Dashboard
            </Button>
            <Button
              style={{ textTransform: "none", fontSize: "1rem" }}
              component={Link}
              to={getNevLink("/admin/recruiters")}
              variant="contained"
            >
              Recruiter Listing
            </Button>
          </Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
