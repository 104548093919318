import { Card, CardContent } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import RelevantCandidatesChart from "./RelevantCandidatesChart";

export default function RelevantCandidates({data}) {
  return (
    <Card sx={{ height: "100%" }} className="card-custom-shadow">
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
          <Grid
            item
            sx={{
              padding: 0,
              width: "100%",
              height: "260px",
            }}
          >
            <Box sx={{ width: "100%", height: "100%" }}>
              <Typography
                variant="caption2"
                sx={{ fontSize: "14px", fontWeight: "600" }}
              >
                Relevant Candidates
              </Typography>
              <Box sx={{ marginTop: "0px", width: "100%", height: "100%" }}>
                {data!==undefined && <RelevantCandidatesChart data={data} />}

              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
