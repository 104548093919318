import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import IconButton from "@mui/material/IconButton";
import { Briefcase as BriefcaseIcon } from "../Common/Icons/BriefcaseIcon";
import { User as UserIcon } from "../Common/Icons/UserIcon";
import { Lock as LockIcon } from "../Common/Icons/LockIcon";
import { MapPointer as MapPointerIcon } from "../Common/Icons/MapPointer";
import AccountDetailsForm from "./AccountDetailsForm";
import LocationForm from "./LocationForm";
import UserProfileForm from "./UserProfileForm";
import { plainToInstance } from "class-transformer";
import { RecruiterResponse } from "../../models/RecruiterResponse";
import { PaymentDetails } from "./PaymentDetails";
import { useDispatch, useSelector } from "react-redux";
import { enableEdit } from "../../features/editMode/editModeSlice";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { setBillingInfoType } from "../../features/paymentDetails/paymenUpdateSlice";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import { useNavigate,useSearchParams,useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { AuthenticateToken } from "../../Services/resetPasswordService";
import {SendEmailForDeniedEmailUpdation, GetRecruiterById} from "../../Services/recruiterService"
import { updateRecruiterData } from "../../features/recruiterSlice";
import { UpdateConfirmationDialog } from "../ui/UpdateConfirmationDialog";
import { PermissionsEnums } from "../../data/permission";
import { AppState } from "../../store/AppState";

interface ManageProfileProps{
  props:any
}

export interface BillingDetailProps{
  userId:string;
  email:string;
  name:string;
  billingInfoType : string;
}
const ManageProfile: React.FC<ManageProfileProps> = (props: ManageProfileProps) => {

  const [searchParams] = useSearchParams();
  let verificationType = searchParams.get("verificationType") === "" ? "Normal" : searchParams.get("verificationType");
  let verifiedUserId = searchParams.get("userId") === "" ? "" : searchParams.get("userId");
  let authToken = searchParams.get("authToken") === "" ? "" : searchParams.get("authToken");

  const editMode = useSelector((state:AppState) => state.editMode)

  const dispatch = useDispatch();
  const [update , setUpdate] = React.useState(true);

  const [userProfile, setUserProfile] = React.useState<any>("");
  const [value, setValue] = React.useState(0);
  const [userDetail, setUserDetail] = React.useState<any>({});
  const [billingDetail, setBillingDetail] = React.useState<BillingDetailProps>({
    userId:"",
    email:"",
    name:"",
    billingInfoType : "",
  });
  const [refresh, setRefresh] = React.useState(false);
  const [isVerifyOpen, setIsVerifyOpen] = React.useState(false);
  const [isVerifyTrue, setIsVerifyTrue] = React.useState(false);
  const [emailVerificationLinkExpired, setEmailVerificationLinkExpired] = React.useState(false);
  const [isNotNow, setIsNotNow] = React.useState(false);
  const [isAuthTokenNotExpire, setIsAuthTokenNotExpire] = React.useState(false);
  const location=useLocation();
  const { features, modules } = useSelector((state:AppState) => state.userPermissions);

  const navigate = useNavigate();
  React.useLayoutEffect(() => {
    const auth = localStorage.getItem("accessToken") ? true : false;

      const authToken = searchParams?.get("authToken") ?? "";

    if(searchParams.get("authToken"))
    {
        setIsAuthTokenNotExpire(false);
        AuthenticateToken(authToken)
        .then((res) => {
          setIsAuthTokenNotExpire(false);
          if (res?.status == 200 && (res?.data?.entity?.isExpired === true)) {
            setIsAuthTokenNotExpire(false);
            navigate("/emailvericationlinkexpired");
          } else {
            setIsAuthTokenNotExpire(true);
            if (!auth) {
              navigate("/login",{state:{redirectUrl:location.pathname + location.search}});
            }
          }
        });
    }else{
      setIsAuthTokenNotExpire(true);
    }
  }, []);

  React.useEffect(() => {
    dispatch(enableEdit());
    GetUserProfile(); 
  }, []);

  React.useEffect(()=>{
    if(searchParams.get("authToken"))
    {
    AuthenticateToken(authToken ?? "")
    .then((res) => {
      if (res.status == 200 && (res?.data?.entity?.isExpired === true)) {
        setEmailVerificationLinkExpired(true);
        setIsVerifyOpen(false);
      }
    })
   }

   },[]);

  function GetUserProfile() {
    let userId = JSON.parse(localStorage.getItem("user") ?? "{}")?.userId ?? "";
    GetRecruiterById(userId)
      .then((res) => {
        if (res?.data?.status === 200) {
          dispatch(updateRecruiterData(res?.data))
        }
        const recruiter = plainToInstance(RecruiterResponse, res.data);
        //Update recruier data
        if(recruiter?.entity?.emailChangeRequest?.isActive === true 
          && verificationType ==='emailVerification' 
          && verifiedUserId === recruiter?.entity?.id 
          && value ===0 
          && isNotNow===false 
          && (emailVerificationLinkExpired===false)
          ){
          setIsVerifyOpen(true);
        }

        setUserDetail({
          id: recruiter.entity.id,
          userSelfDescription: recruiter.entity.userSelfDescription ?? "",
          experience: recruiter.entity.experience,
          domains: recruiter.entity.domains,
          interests: recruiter.entity.interests,
          industries: recruiter.entity.industries,
          preferredLocation: recruiter.entity.preferredLocation,
          skills: recruiter.entity.skills,
          profiles: recruiter.entity.profiles,
          documentInfo : recruiter.entity.documentInfo
        });
        setBillingDetail({
          userId: recruiter.entity.id,
          email: recruiter.entity.email,
          name: recruiter.entity.firstName,
          billingInfoType : recruiter.entity.billingInfoType
        });
        dispatch(setBillingInfoType({billingInfoType:recruiter.entity.billingInfoType}))
        setUserProfile(recruiter.entity);
        if(searchParams.get("verificationType")=="paymentDetails")
      {
        setValue(3)
      }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const handleChange = (event:any, newValue:any) => {
    setValue(newValue);
  };
  function doRefresh() {
    setRefresh(!refresh);
  }

  const handleUpdateConfirmationOk = ()=>{
    setIsVerifyOpen(false);
    setIsVerifyTrue(true);
  }

  const handleUpdateConfirmationNotNow = ()=>{
    setIsVerifyOpen(false);
    setIsVerifyTrue(false);
    setIsNotNow(true);
    if(authToken !=="")
    {
      let token = { authToken : authToken}
      SendEmailForDeniedEmailUpdation(token);
    }
    handleDialogClose();
  }

  const handleDialogClose = ()=>{
    setIsVerifyOpen(false);
    setIsVerifyTrue(false);
    setIsNotNow(true);
  }


  return (
    <React.Fragment>
      {(userProfile.showProfileCompletionPopup) ?
       <Dialog
       open={update}
       disableEscapeKeyDown={true}
       scroll="body"
       style={{color:"#E6E9FB"}}
       aria-describedby="alert-dialog-description"
     >
       <DialogContent >
         <div style={{ marginBottom: "30px" }}>
       <IconButton
           aria-label="close"
           onClick={() => setUpdate(false)}
           sx={{
             position: "absolute",
             right: 8,
             top: 8,
             color: (theme) => theme.palette.grey[500],
           }}
         >
           <CloseIcon />
         </IconButton>
         </div>
         <DialogContentText
           className="alert-dialog-description"
           style={{
             textAlign: "center",
             fontSize: "16px",
             fontStyle: "normal",
             color: "#06152B",
           }}
         >
              We recommend you to complete all profile details with the right information. Completed information will lead to quick and easy profile approval by the JobRialto Admin team.
           <div
             className="row"
             style={{ textAlign: "center", padding: "16px" }}
           >
           </div>
         </DialogContentText>
         <div className="row" style={{ textAlign: "center" }}>
           <Button 
            data-testid="outlined"
            variant="outlined" 
            size="small" 
            style={{marginRight:"16px" , textTransform: "capitalize", lineHeight:"1.2 !important" }} 
            onClick={() => setUpdate(false)}
            >
             OK
           </Button>
           <Button
             data-testid="contained"
             size="small"
             variant="contained"
             style={{ textTransform: "capitalize" }}
             onClick={() => setUpdate(false)}
             className="manage-profile-btnCancel"
           >
             Cancel
           </Button>
         </div>
       </DialogContent>
     </Dialog>
     : null}
      { isAuthTokenNotExpire===true && <>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              component="h1"
              className=" letter-spacing-normal manageProfileMainHeading"
              gutterBottom
            >
              Manage Profile
            </Typography>
          </Grid>
          <Grid item xs={12} mb={5}>
            <Paper elevation={2} >
              <Grid container spacing={2}>
                <Grid item sm={2.5}  sx={{ backgroundColor: "rgb(237, 237, 250)", flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                  <Item
                    elevation={0}
                    sx={{
                      backgroundColor: "rgb(237, 237, 250)",
                      textAlign: "left",
                      width:"100%",
                      padding:0
                    }}
                  >                    
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      orientation="vertical"
                      className="manage-accounts-tabs"
                    >
                    {features?.includes(PermissionsEnums.Profile_Account) || modules?.includes(PermissionsEnums.Profile_Account) && 
                      <Tab
                        icon={<LockIcon />}
                        iconPosition="start"
                        label="Account Details"
                        {...a11yProps(0)}
                      />
                    }
                    {features?.includes(PermissionsEnums.Profile_Location) || modules?.includes(PermissionsEnums.Profile_Location) && 
                      <Tab
                        icon={<MapPointerIcon />}
                        iconPosition="start"
                        label="Location"
                        {...a11yProps(1)}
                      />
                    }
                    {features?.includes(PermissionsEnums.Profile_UserDetails) || modules?.includes(PermissionsEnums.Profile_UserDetails) && 
                      <Tab
                        icon={<UserIcon />}
                        iconPosition="start"
                        label="User Profile"
                        {...a11yProps(2)}
                      />
                    }
                    {features?.includes(PermissionsEnums.Profile_Payments) || modules?.includes(PermissionsEnums.Profile_Payments) && 
                      <Tab
                        icon={<BriefcaseIcon />}
                        iconPosition="start"
                        label="Payment Details"
                        {...a11yProps(3)}
                      />
                    }
                    </Tabs>
                  </Item>
                </Grid>
                <Grid item sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                  <Item
                    elevation={0}
                    sx={{
                      backgroundColor: "transparent",
                      textAlign: "left",
                      display: "flex",
                      alignItems:"center"
                    }}
                  >
                    {/* Mobile Version Tab menu */}
                    <MenuBtn selectedIndex={value} onChangeIndex={handleChange} />
                  </Item>
                </Grid>
                <Grid item xs={12} md={9}  sx={{ marginTop: { xs: "-30px", md: "0px" } }}>
                  <Item
                    elevation={0}
                    sx={{
                      backgroundColor: "transparent",
                      textAlign: "left",
                    }}
                    className="manage-accounts-tabs-Panel"
                  >
                    <TabPanel value={value} index={0}>
                      <Typography
                        variant="h6"
                        component="span"
                        className="letter-spacing-normal heading-underline typography-with-right-text"
                        gutterBottom
                      >
                        <span>Account Details</span>
                      </Typography>
                      <AccountDetailsForm
                        getUserProfile={GetUserProfile}
                        doRefresh={doRefresh}
                        refresh={refresh}
                        isVerifyTrue={isVerifyTrue}
                        verificationType ={verificationType}
                        verifiedUserId={verifiedUserId}
                        IsNotNow = {isNotNow}
                        hasAuthToken = {authToken}
                        emailVerificationLinkExpired ={emailVerificationLinkExpired}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <Typography
                        variant="h6"
                        component="span"
                        className="letter-spacing-normal manageProfileMainHeading heading-underline typography-with-right-text"
                        gutterBottom
                      >
                        <span>Location</span>
                      </Typography>
                      <LocationForm />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      <Typography
                        variant="h6"
                        component="span"
                        className="letter-spacing-normal manageProfileMainHeading heading-underline typography-with-right-text"
                        gutterBottom
                      >
                        <span>User Profile</span>
                      </Typography>
                      <UserProfileForm
                        userDetail={userDetail}
                        getUserProfile={GetUserProfile}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                      <Typography
                        variant="h6"
                        component="span"
                        className="letter-spacing-normal manageProfileMainHeading heading-underline typography-with-right-text"
                        gutterBottom
                      >
                        <span>Payment Details</span>
                      </Typography>
                        <PaymentDetails 
                          editMode={editMode.isEditable} 
                          billingDetail={billingDetail} 
                        />
                    </TabPanel>
                  </Item>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

      
        <UpdateConfirmationDialog 
          message={" Please click the email verification button to update the requested email address."} 
          aggreeText={"Verify Email"} 
          disagreeText={"Not Now"} 
          onClose={handleDialogClose} 
          open={isVerifyOpen} 
          onYes={handleUpdateConfirmationOk} 
          onNo={handleUpdateConfirmationNotNow}
        />
        
      </>
      }
    </React.Fragment>
  );
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export function TabPanel(props:any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index:any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


const ITEM_HEIGHT = 48;

interface MenuBtnProps {
  selectedIndex: number;
  onChangeIndex: any;
}

const MenuBtn: React.FC<MenuBtnProps> = ({ selectedIndex, onChangeIndex }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event:any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClick = (index:any) => {
    handleClose();
    onChangeIndex(null,index);
    console.log("index", index);
  };
  const options = [
    "Account Detail",
    "Location",
    "User Profile",
    "Payment Detail",
  ];

  return (
    <div>
      <IconButton
        data-testid="long-button"
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            data-testid="selectedindex"
            key={option}
            selected={index === selectedIndex}
            onClick={() => {
              handleMenuClick(index);
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ManageProfile;
