import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
const useStyles = makeStyles({
  smallIcon: {
    '& .MuiSvgIcon-root': {
      fontSize: 16,
    },
    
  },
});
export default function BasicDatePicker({
  label="",
  views,
  value,
  onChange,
  name, 
  disablePast = false, 
  disableFuture = false, 
  minimum = '2020-01-02',
  errorMessage,
  isError, 
  defaultDateFormat, 
  fullWidth=false, 
  required=false, 
  className="cust-field-style"
}) {
  const classes = useStyles();


  const changeValue = (e)=>{

  const parsedDate = moment(e?.$d, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
  const formattedDate2 = parsedDate.format('YYYY-MM-DD');
  const formatedDateInUTC = moment.utc(formattedDate2).format('YYYY-MM-DDTHH:mm:ss[Z]')
    
    onChange(formatedDateInUTC,name)
  }

  const yearsFromNow = new Date();
  yearsFromNow.setFullYear(yearsFromNow.getFullYear() + 4);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
      className={`${classes.smallIcon} ${className} JrDatePicker `}
      autoOk
      disableFuture = {disableFuture}
      disablePast = {disablePast}
      
        sx={{ fontSize: "20px"}}
       views={views}
        minDate = {minimum}
        maxDate={yearsFromNow}
        value={value}
        
        onChange={changeValue}
        renderInput={(params) => <TextField variant="standard" {...params}  label={label} error={isError ? isError : errorMessage}
        helperText={errorMessage}
        format={defaultDateFormat}
        fullWidth={fullWidth}
        required={required} 
   />}
      />
    </LocalizationProvider>
  );
}
