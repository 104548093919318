import React from 'react'
import getJobHiredStatus from '../../utils/getJobHiredStatus';

export const JobStatus = ({hiredCount, jobStatus}) => {
const renderMessage = ()=>
{
  if(hiredCount === null || hiredCount === undefined)
  {
    return 
  }
  if(hiredCount === 0)
  {
    return 
  }
 return <div>{getJobHiredStatus(hiredCount,jobStatus)}</div>

}
  return (
   <> {renderMessage()} </>
  )
}
