import { gapi } from "gapi-script";
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from './App';
import "./assets/style/style.scss";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import store, { persistor } from "./store/store";
import setAuthToken from "./utils/setAuthToken";


gapi.load("client:auth2", () => {
  gapi.auth2.init({
    clientId:
    "447293117605-mmkm2lnocasr0lggm9rp1pqnkvhtn4gg.apps.googleusercontent.com",
    plugin_name: "sso_app",
  });
});

setAuthToken(localStorage.accessToken);
const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render( <Provider store={store}><PersistGate loading={null} persistor={persistor}></PersistGate> <App/> </Provider>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
