import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { PermissionsEnums } from "../../../../data/permission";
import { JobOriginIds } from "../../../../models/Job/JobDetailsModel";
import { UserAuth } from "../../../../models/interfaces";
import { CandidateSubmissionStatus } from "../../../../models/submission/CandidateSubmissionMasterStatusesModel";
import { ModuleComponent } from "../../../Common/Permission/ModuleComponent";
import "../../NewViewSubmission/styles.scss";

interface ShareInterviewAvailabilityButtonProps {
  row: any;
  userRole: any;
  currentjobDetails: any;
  setPopup: any;
}

export default function ShareInterviewAvailabilityButton({
  row,
  userRole,
  currentjobDetails,
  setPopup
} : ShareInterviewAvailabilityButtonProps) {
  const isShareInterviewAvailability = (
    row?.interviewAvailabilities?.length === 0 &&
    currentjobDetails?.jobOriginId === JobOriginIds.jobRialto &&
    row.finalStatusId === CandidateSubmissionStatus.SubmittedToCLI &&
    (userRole === UserAuth.Client || userRole === UserAuth.ClientTeamMember)
  );

  const handleButtonClick = () => {
    if (isShareInterviewAvailability) {
      setPopup((popup: any) => ({ ...popup, isConfirmShareInterviewAvailbility: true }));
    }
  };

  return (
    <ModuleComponent moduleId={PermissionsEnums.ShareInterviewAvailability}>
      {isShareInterviewAvailability && (
        <Grid item>
          <Button
            className="submission-action-btn"
            variant="contained"
            color="primary"
            onClick={handleButtonClick}
          >
            Share Interview Availability
          </Button>
        </Grid>
      )}
    </ModuleComponent>
  );
}