import React, { useEffect, useState } from 'react';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, TextField } from '@mui/material';

export interface OptionModel {
  id: string;
  optionText: string;
  instructionText: string;
  specificationText?: string;
  specificationRequired?: boolean;
}

export interface QuestionModel {
  id: string;
  question: string;
  instructionText: string;
  type: number;
  options: OptionModel[];
}

interface SpecificationError {
  specificationText: string;
}

type HelperTextType = string | SpecificationError[];

interface CheckboxSelectionFieldProps {
  name: string;
  isRequired: boolean;
  onHandleChange: (questionObj: QuestionModel) => void;
  onBlur: (id: string | number | undefined) => void;
  questionDetails: QuestionModel;
  error?: boolean;
  helperText?: HelperTextType;
  SelectedOptions:OptionModel[]
}


const CheckboxSelectionField: React.FC<CheckboxSelectionFieldProps> = ({
  name,
  isRequired,
  onHandleChange,
  onBlur,
  questionDetails,
  SelectedOptions,
  error = false,
  helperText = ""
}) => {
  const [checkedOptions, setCheckedOptions] = useState<Record<string, boolean>>({});
  const [selectedOptionList, setSelectedOptionList] = useState<OptionModel[]>(SelectedOptions);

  useEffect(() => {
    // Initialize checkedOptions based on defaultSelectedOptions
    let initialCheckedOptions: Record<string, boolean> = {};

    initialCheckedOptions = questionDetails.options.reduce((acc:any, option) => {
      acc[option.id] = SelectedOptions.some(op => op.id === option.id);
      return acc;
    }, {});
    
    setCheckedOptions(initialCheckedOptions);

  }, [SelectedOptions, questionDetails]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, optionId: string) => {
    const newCheckedOptions = {
      ...checkedOptions,
      [optionId]: event.target.checked
    };
    setCheckedOptions(newCheckedOptions);
    
    const selectedOptions = questionDetails.options
      .filter(option => newCheckedOptions[option.id])
      .map(option => {
        const matchingSelectedOption = selectedOptionList.find(selectedOption => selectedOption.id === option.id);
        return {
          ...option,
          specificationText: matchingSelectedOption?.specificationText ?? ""
        };
      });

    setSelectedOptionList(selectedOptions);
  };

  const handleTextInputChange = (event: React.ChangeEvent<HTMLInputElement>, optionId: string) => {    
    const value = event.target.value;
    const updatedOptions = setSpecificationText(optionId, selectedOptionList, value);
    setSelectedOptionList(updatedOptions);
  };

  function setSpecificationText(id: string, options: OptionModel[], specificationText: string) {
    return options.map(option => {
      if (option.id === id) {
        return { ...option, specificationText: specificationText };
      }
      return option;
    });
  }

  const hasSpecificationText = (options: OptionModel[], id: string): boolean => {
    const option = options.find(option => option.id === id);  
    return option ? !!option.specificationText : false;
  };

  const getSpecificationText = (options: OptionModel[], id: string): string => {
    const option = options.find(option => option.id === id);  
    return  option?.specificationText || '';
  };

  useEffect(() => {
    if (selectedOptionList) {
      onHandleChange(selectedOptionList);      
    }
  }, [selectedOptionList]);

  return (
    <FormControl 
      component="fieldset" 
      key={questionDetails.id} 
      required={isRequired}
      sx={{ marginBottom: 2 }}
    >
      <FormLabel component="legend">{questionDetails.question}{questionDetails.instructionText}</FormLabel>
      {questionDetails.options.map((option: OptionModel, index) => (
        <React.Fragment key={option.id}>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!checkedOptions[option.id]}
                onChange={(e) => handleCheckboxChange(e, option.id)}
              />
            }
            label={option.optionText}
          />
          {checkedOptions[option.id] && option.specificationRequired && (
            <TextField
              name={`specificationText_${option.id}`}
              onChange={(e) => handleTextInputChange(e, option.id)}
              label=""
              defaultValue={getSpecificationText(selectedOptionList, option?.id)}
              error={!hasSpecificationText(selectedOptionList, option.id) && Array.isArray(helperText)}
              helperText={!hasSpecificationText(selectedOptionList, option.id) && Array.isArray(helperText) && "Specification is required"}
              placeholder="Please specify"
              variant="standard"
              sx={{ marginBottom: "16px" }}
            />
          )}
        </React.Fragment>
      ))}
      {error && <FormHelperText className='formHelperTextJR'>{!Array.isArray(helperText) && helperText}</FormHelperText>}
    </FormControl>
  );
};

export default CheckboxSelectionField;
