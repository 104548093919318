import {
  Autocomplete,
  Chip,
  CircularProgress,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { GetAllIndustries } from "../../../../../../Services/recruiterService";
import useApi from "../../../../../../hooks/useApi";
import toTitles from "../../../../../../utils/toTitles";

interface JobIndustriesAutocompleteProps {
  label:string;
  fieldName:string;
   handleBlur: (event: React.FocusEvent<any>) => void;
    setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  selectedValue: string;
  isError: boolean;
  isRequired:boolean;
  helperText:string;
  placeholderText:string;
}

const JobIndustriesAutocomplete: React.FC<JobIndustriesAutocompleteProps> = ({
  label,
  fieldName,
  handleBlur,
  setFieldValue,
  selectedValue,
  helperText,
  isError,
  isRequired,
  placeholderText
}) => {
  const [searchIndustry, setsearchIndustry] = React.useState<string>("");

  const onIndustryInputChange = (event: any) => {    
    setsearchIndustry(event.target.value);
  };
  const allIndustries = useApi(() => GetAllIndustries(searchIndustry));
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      allIndustries.request();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchIndustry]);
  return (
    <>
      <Autocomplete
        noOptionsText={allIndustries?.loading ? "Loading..." : "No options"}
        id="jobIndustries"
        disableClearable
        size="medium"
        data-testid="job-industry"        
        onChange={(_, newValue:any) => {
          setFieldValue(fieldName, newValue);
         }}   
         onBlur={handleBlur}      
        options={
          allIndustries?.data?.entityList?.map(
            (list:any) => list?.industryName
          ) || []
        }
        value={selectedValue}
        defaultValue={selectedValue}
        getOptionLabel={(option:any) => option}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              color="primary"
              size="medium"
              label={toTitles(option)}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}           
            variant="standard"
            label={label}
            name={fieldName}
            onKeyUp={onIndustryInputChange}
            required={isRequired}
            error={isError}
            helperText={helperText}
            placeholder={placeholderText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {allIndustries?.loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
};

export default JobIndustriesAutocomplete;
