import { createSvgIcon } from "@mui/material/utils";

export const PendingSubmissionsMenuIcon = createSvgIcon(
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.66634 15.0003C6.44533 15.0003 6.23337 14.9125 6.07709 14.7562C5.92081 14.6 5.83301 14.388 5.83301 14.167V2.50033C5.83301 2.27931 5.92081 2.06735 6.07709 1.91107C6.23337 1.75479 6.44533 1.66699 6.66634 1.66699C6.88735 1.66699 7.09932 1.75479 7.2556 1.91107C7.41188 2.06735 7.49967 2.27931 7.49967 2.50033V14.167C7.49967 14.388 7.41188 14.6 7.2556 14.7562C7.09932 14.9125 6.88735 15.0003 6.66634 15.0003Z" fill="#fff"/>
    <path d="M9.99935 15.0003C9.77834 15.0003 9.56637 14.9125 9.41009 14.7562C9.25381 14.6 9.16602 14.388 9.16602 14.167V2.50033C9.16602 2.27931 9.25381 2.06735 9.41009 1.91107C9.56637 1.75479 9.77834 1.66699 9.99935 1.66699C10.2204 1.66699 10.4323 1.75479 10.5886 1.91107C10.7449 2.06735 10.8327 2.27931 10.8327 2.50033V14.167C10.8327 14.388 10.7449 14.6 10.5886 14.7562C10.4323 14.9125 10.2204 15.0003 9.99935 15.0003Z" fill="#fff"/>
    <path d="M13.3333 15.0003C13.1123 15.0003 12.9004 14.9125 12.7441 14.7562C12.5878 14.6 12.5 14.388 12.5 14.167V2.50033C12.5 2.27931 12.5878 2.06735 12.7441 1.91107C12.9004 1.75479 13.1123 1.66699 13.3333 1.66699C13.5543 1.66699 13.7663 1.75479 13.9226 1.91107C14.0789 2.06735 14.1667 2.27931 14.1667 2.50033V14.167C14.1667 14.388 14.0789 14.6 13.9226 14.7562C13.7663 14.9125 13.5543 15.0003 13.3333 15.0003Z" fill="#fff"/>
    <path d="M16.5417 10.8337C16.3396 10.8728 16.1577 10.9821 16.0282 11.1421C15.8986 11.3022 15.8296 11.5028 15.8334 11.7087V16.667H4.16672V11.7087C4.17048 11.5028 4.10152 11.3022 3.97196 11.1421C3.84239 10.9821 3.66054 10.8728 3.45839 10.8337C3.33895 10.8155 3.217 10.8236 3.10099 10.8573C2.98498 10.8909 2.87768 10.9494 2.78652 11.0287C2.69536 11.108 2.62252 11.2061 2.57306 11.3163C2.5236 11.4265 2.49869 11.5462 2.50005 11.667V17.5003C2.50005 17.7213 2.58785 17.9333 2.74413 18.0896C2.90041 18.2459 3.11237 18.3337 3.33339 18.3337H16.6667C16.8877 18.3337 17.0997 18.2459 17.256 18.0896C17.4123 17.9333 17.5001 17.7213 17.5001 17.5003V11.667C17.5014 11.5462 17.4765 11.4265 17.427 11.3163C17.3776 11.2061 17.3047 11.108 17.2136 11.0287C17.1224 10.9494 17.0151 10.8909 16.8991 10.8573C16.7831 10.8236 16.6612 10.8155 16.5417 10.8337Z" fill="#fff"/>
    <path d="M4.08301 6.54232L1.04134 4.62565C0.916341 4.54232 0.833008 4.58398 0.833008 4.75065V8.58398C0.833008 8.75065 0.916341 8.79232 1.04134 8.70898L4.08301 6.79232C4.2219 6.70898 4.2219 6.62565 4.08301 6.54232Z" fill="#fff"/>
    <path d="M19.083 6.54232L16.0413 4.62565C15.9163 4.54232 15.833 4.58398 15.833 4.75065V8.58398C15.833 8.75065 15.9163 8.79232 16.0413 8.70898L19.083 6.79232C19.2219 6.70898 19.2219 6.62565 19.083 6.54232Z" fill="#fff"/>
    </svg>,
  "Peninding Submissions"
);