import * as React from 'react';
import { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { TextField } from '@mui/material';

interface Props{
  isRequired?:boolean
  label:string;
  value:Dayjs | null;
  fieldName:string;
  isError?:boolean;
  helperText?:string
  handleOnChange: any;
  handleOnBlur?:any;
  ampm?:boolean;
  classNames?:string;
}
  const AppTimePicker: React.FC<Props> = ({ 
    isRequired,
    label,
    value,
    fieldName,
    isError,
    helperText,
    handleOnChange,
    handleOnBlur,
    ampm=false,
    classNames='defaultAppTimePicker'
 }) => {
  
  const handleOnAppTimeChange = (e:any) =>{        
    handleOnChange(e?.$d?.toString())
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
        className={classNames}          
          value={value}
          ampm={ampm}
          onChange={handleOnAppTimeChange}
          componentsProps={{
            actionBar: {
              actions: ["clear", "accept"]
            }
          }}
          renderInput={(startProps) => (
            <>
              <TextField                
                {...startProps}
                required={isRequired}  
                variant="standard"
                fullWidth 
                onBlur={handleOnBlur} 
                error={isError}
                helperText={helperText}
                label={label}
              />
            </>
          )}
        />
    </LocalizationProvider>
  );
}

export default AppTimePicker;