import { Button, Card, Grid, Stack, Typography } from "@mui/material";
import avatar from "./../../assets/images/avatar.jpg";
import Tooltip from "@mui/material/Tooltip";
import verifiedIcon from "./../../assets/images/verified.svg";
import { PhoneNumber } from "../ui/PhoneNumber";
import { loadImage } from "../../utils/getFileFromUrl";
import { useState, useEffect } from "react";
import { valueOrDefault } from "../../utils/complexityUtils";
import SectionHeading from "../ui/Form/Heading/SectionHeading";
import SnackbarPopup from "../Common/Popup/snackbar/SnackbarPopup";

type RecruiterInformationProps = {
  recruiterDetails: any;
  Token: string;
  jobDetails: any;
  onAuthorize: any;
  onDecline: any;
  actionTaken: any;
  isCandidateInfoEditable: boolean;
  dataLoadComplete: boolean;
  setIsActionTaken:any
};
export const RecruiterInformation = (props: RecruiterInformationProps) => {
  const [imgData, setImageData] = useState("");

  useEffect(() => {
    loadImage(
      props?.recruiterDetails?.imageInfo?.imageUrl ?? "",
      setImageData,
      props?.Token
    );
  }, [props?.recruiterDetails?.imageInfo?.imageUrl]);

  return (
    <>
      <Card sx={{ padding: "10px" }}>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={12}>
            <SectionHeading
              title="Recruiter"
              classes="primaryColorText textCenter fontsize24"
              level="h2"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <img
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                display: "block",
                margin: "0 auto",
              }}
              src={
                props?.dataLoadComplete &&
                props?.recruiterDetails?.imageInfo?.imageUrl &&
                imgData
                  ? imgData
                  : avatar
              }
            ></img>
          </Grid>

          <Grid
            item
            mt={3}
            className="primary-color-text recruiter-info-ackn"
            xs={6}
            md={6}
            lg={5}
          >
            Recruiter ID
          </Grid>
          <Grid item mt={3} xs={6} md={6} lg={7}>
            {valueOrDefault(props?.recruiterDetails?.recruiterId, "")}
          </Grid>
          <Grid
            item
            className="primary-color-text recruiter-info-ackn"
            xs={6}
            md={6}
            lg={5}
          >
            Full Name
          </Grid>
          <Grid item xs={6} md={6} lg={7}>
            {props?.recruiterDetails?.firstName +
              " " +
              props?.recruiterDetails?.lastName}
          </Grid>
          <Grid
            item
            className="primary-color-text recruiter-info-ackn"
            xs={6}
            md={6}
            lg={5}
          >
            Email Address
          </Grid>
          <Grid item xs={6} md={6} lg={7}>
            <Tooltip
              title={props?.recruiterDetails?.email}
              placement="bottom-end"
            >
              <span className="rec-email-text-sp">
                {props?.recruiterDetails?.email}
              </span>
            </Tooltip>
            {props?.recruiterDetails?.isEmailVerified ? (
              <img className=" w20" src={verifiedIcon}></img>
            ) : null}
          </Grid>
          {props.actionTaken.flag ? (
            props.actionTaken.isSuccess &&
            <SnackbarPopup 
              open={props.actionTaken.isSuccess} 
              message={props.actionTaken.action}
              onClose={()=>{props?.setIsActionTaken({...props.actionTaken, flag:true, isSuccess:false})}}
              
              />
          ) : (
            <>
              <Grid
                item
                xs={12}
                md={12}
                className="primary-color-text recruiter-info-ackn"
              >
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 1, sm: 2, md: 2 }}
                >
                  <Button
                    className="acknAuthorizedBtn"
                    disabled={props.isCandidateInfoEditable ? true : false}
                    variant="contained"
                    onClick={() => props.onAuthorize()}
                  >
                    {" "}
                    Authorize Now{" "}
                  </Button>
                  <Button
                    className="acknDeclineBtn"
                    disabled={props.isCandidateInfoEditable ? true : false}
                    variant="contained"
                    onClick={() => props.onDecline()}
                  >
                    {" "}
                    Decline{" "}
                  </Button>
                </Stack>
              </Grid>
            </>
          )}
          { !props.actionTaken.flag && 
          <Grid item xs={12}>
            <Typography
              variant="h6"
              component="div"
              className="letter-spacing-normal primary-color-text fontWt700"
            >
              The recruiter has requested for the "Right to Represent" you for
              this job, please confirm your approval and interest by clicking on
              "Authorize Now" button above
            </Typography>
          </Grid>
          }
        </Grid>
      </Card>
    </>
  );
};