import { FunctionComponent } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link} from "react-router-dom";
import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { BreadCrumbItem } from "../../models/AppModels";
import { getNevLink } from "../../utils/getFileFromUrl";
interface AppBreadCrumbProps {
  BreadCrumbItems: BreadCrumbItem[];
  classes?: any;
}

interface MenuItem {
  isLeaf: boolean;
  sequence?: number;
  title: string;
  leafClasses?: string;
  onClick?: () => void;
  to?: string;
}

const AppBreadCrumb: FunctionComponent<AppBreadCrumbProps> = ({
  BreadCrumbItems,
  classes,
}) => {

  const renderMenuItem = (item:MenuItem, index:number, classes?:string) => {
    const commonProps = {
      key: item?.sequence ?? index,
      className: classes ? classes : "fontRoboto-bread",
    };
  
    if (item.isLeaf) {
      return (
        <Typography {...commonProps}>
          {item.title}
        </Typography>
      );
    } else if (item.onClick) {
      return (
        <Typography
          {...commonProps}
          style={{ color: "rgb(69, 64, 219)", cursor: "pointer" }}
          onClick={item.onClick}
        >
          {item.title}
        </Typography>
      );
    } else {
      return (
        <Link
          {...commonProps}
          color="primary"
          to={item.to ? getNevLink(item.to) : ""}
          style={{ textDecoration: "none", color: "rgb(69, 64, 219)" }}
        >
          {item.title}
        </Link>
      );
    }
  };

  return (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className="breadCrumbWrapper"
      >
        {BreadCrumbItems.map((item, i) => (
          <span key={item?.sequence ?? i}>
            {renderMenuItem(item, i, classes)}
          </span>
        ))}
      </Breadcrumbs>
    </>
  );
};

export default AppBreadCrumb;
