import { makeStyles } from '@material-ui/core/styles';
  export default makeStyles(theme => ({
    stepper: {
      padding: theme.spacing(3, 0, 5)
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    button: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2)
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative'
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%'
    }, 
    activeStepIcon: {
      color: "#4540db", 
    },
    completedStepIcon: {
      color: "#4540db", 
    },
}));
