import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
} from "@material-ui/core";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import React, { useEffect } from "react";

import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Grid } from "@mui/material";
import PlacesAutocomplete from "react-places-autocomplete";
import { useDispatch } from "react-redux";
import { GetBankDetailsByRoutingNumber } from "../../../Services/invoiceManagerService";
import checkboxStyle from "../../../Shared/theme/checkboxTheme";
import getLoggedInUserRole from "../../../Shared/utils/getLoggedInUserRole";
import { routingNumberRegEx } from "../../../constants/regex";
import { disableEdit, enableEdit } from "../../../features/editMode/editModeSlice";
import { UserAuth } from "../../../models/interfaces";
import getAddressForAutoFill from "../../../utils/getAddressForAutoFill";
import { downloadFile, getFilePreviewUrl } from "../../../utils/getFileFromUrl";
import SnackbarPopup from "../../Common/Popup/snackbar/SnackbarPopup";
import PreviewFileDialog from "../../Common/PreviewFileDialog";
import { Asterisk } from "../../ui/Asterisk";
import { CustomAlert } from "../../ui/CustomAlert";
import { ErrorMessage } from "../../ui/ErrorMessage";
import { valueOrDefault } from "../../../utils/complexityUtils";

interface Props {
  editMode : any,
  billingDetail: {
    userId: string;
    email: string;
    name: string;
    billingInfoType : string;
  };
  formik : any,
  pageStatus : any,
  setPageStatus?:any;
  fileError : any,
  fileName : any,
  setIsChecked : any,
  isChecked : any,
  formState : any,
  setfileError : any,
  setFileName : any,
  viewMode : UserAuth,
  onChange : any,
  onFileUpload : any,
  isFileUploading : boolean,
}

type AutoCompleteProps = {
  getInputProps : any;
  suggestions : any;
  getSuggestionItemProps : any;
  loading : any;
};

const useStyles = checkboxStyle
export const USAForm: React.FC<Props> = ({
  editMode,
  formik,
  pageStatus,
  setPageStatus,
  fileError,
  fileName,
  setIsChecked,
  isChecked,
  formState,
  onFileUpload,
  viewMode,
  onChange,
  isFileUploading
}) => {
  const dispatch = useDispatch()
  const classes = useStyles();
  let userRole = getLoggedInUserRole()
  
  //File Handling
  const [isFormTouch,setIsFormTouched] = React.useState(false)
  const [filePreview, setFilePreview] = React.useState<undefined | 
  {
    resourceUrl: string;
    fileName: string;
    fileType?: string;
  }>(undefined);
 
  const routingNumberChange = (e: any) => {
    formik.setFieldValue("bankName", "");

    onChange({target : {name:"bankName",value:""}})
    if (routingNumberRegEx.test(e.target.value)) {
      GetBankDetailsByRoutingNumber(e.target.value).then(response => {
        if (response.data.status === 200) {
          formik.setFieldValue("bankName", valueOrDefault(response.data?.entity?.customer_name, ""));
          onChange({target : {name:"bankName",value:valueOrDefault(response.data?.entity?.customer_name, "")}})
        }
      }).catch(err => {
        console.log(err);
      })
    }
  }
  
  useEffect(() => {
    dispatch(enableEdit());
  }, [])
  
  const handleIsChecked = (checkType :string) =>{
    switch (checkType){
      case "first" :  
        setIsChecked({...isChecked, first:!isChecked.first});
        break;
      case "second" :  
        setIsChecked({...isChecked, second:!isChecked.second});
        break;
    }
    setIsFormTouched(true)
  }
  const handleOnChange = (e:any)=> {
    if(e.target.name=='location.state' || e.target.name=='location.country')
    {
      onChange({target : {name:"location.address",value:""}})

      formik.setFieldValue("location.address","");

          
    }
   onChange(e)
  }
  
  
  const renderFunc : React.FunctionComponent<AutoCompleteProps> = ({
    getInputProps,
    suggestions,
    getSuggestionItemProps,
    loading
  }) => (
    <div className="recruiter-location-address">
      <FormControl
        variant="standard"
        error={
          formik.touched.location?.address &&
          Boolean(formik.errors.location?.address)
        }
        fullWidth
        required
      >
        <InputLabel htmlFor="address-input">Address</InputLabel>
        <Input
          {...getInputProps()}
          name="location.address"
          type="text"
          readOnly={!editMode}
          onBlur={()=>{
            formik.setFieldTouched("location.address",true)
          }}
        />
      <ErrorMessage errorText={formik.touched.location?.address && formik.errors.location?.address} />
      </FormControl>
      <div className="autocomplete-dropdown-container">
        {loading && <div>Loading...</div>}
        {suggestions.map((suggestion:any) => (
          <div {...getSuggestionItemProps(suggestion)}>
            <span onChange={()=>autoCompleteGetAddress(suggestion.description)} style={{cursor:'pointer'}}>{suggestion.description}</span>
          </div>
        ))}
      </div>
    </div>
  );
  const autoCompleteGetAddress = async (address:string)=> {
    let resp =  await getAddressForAutoFill(address);

    formik.setFieldValue("location.zip",valueOrDefault(resp.pin, ""));
    formik.setFieldValue('location.address',valueOrDefault(resp.formattedAddress, ""))
    //change on adminside
    onChange({target : {name:"location.address",value:valueOrDefault(resp.formattedAddress, "")}})
    onChange({target : {name:"location.zip",value:valueOrDefault(resp.pin, "")}})
    onChange({target : {name:"location.city",value:valueOrDefault(resp.city, "")}})
    onChange({target : {name:"location.state",value:valueOrDefault(resp.state, "")}})
    onChange({target : {name:"location.country",value:valueOrDefault(resp.country, "")}})

    formik.setFieldValue("location.state", valueOrDefault(resp.state, ""));
    formik.setFieldValue("location.city", valueOrDefault(resp.city, ""));
    formik.setFieldValue("location.country", valueOrDefault(resp.country, ""));

    if(resp.pin&&resp.pin!==""){
      formik.setFieldTouched("location.zip",false)
    }else{
      formik.setFieldTouched("location.zip",true)
    }
    if(resp.formattedAddress&&resp.formattedAddress!==""){
      formik.setFieldTouched("location.address",false)
    }else{
      formik.setFieldTouched("location.address",true)
    }

    if(resp.state&&resp.state!==""){
      formik.setFieldTouched("location.state",false)
    }
    else{
      formik.setFieldTouched("location.state",true)
    }

    if(resp.country&&resp.country!==""){
      formik.setFieldTouched("location.country",false)
    }else{
      formik.setFieldTouched("location.country",true)
    }

    if(resp.city&&resp.city!==""){
      formik.setFieldTouched("location.city",false)
    }
    else{
      formik.setFieldTouched("location.city",true)
    }
  }
  return (
    <>
      {/* form For USA */}
      <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {
            viewMode!==UserAuth.Admin && editMode && (
            <Box className="manage-profile-btns">
              <Button data-testid="outlined" variant="outlined" onClick={() => {
                dispatch(disableEdit()); 
                formik.setValues(formState);
               }}
               className="manage-profile-btnCancel"
                >
                Cancel
              </Button>
              <Button
                variant="contained"
                data-testid={"btn-usaform-updateprofile"}
                onClick={() => {
                  
                  console.log(formik.errors);
                  console.log(formik.values);
                  formik.handleSubmit();
                }}
                disabled={isFileUploading}
              >
                Update Profile
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
        <FormControl
          variant="standard"
          fullWidth
          required
          error={
            formik.touched.routingNumber &&
            Boolean(formik.errors.routingNumber)
          }
        >
          <InputLabel htmlFor="routingNumber">Routing Number</InputLabel>
          <Input
            name="routingNumber"
            type="text"
            data-testid='routingNumber'
            onChange={(e) => {
              handleOnChange(e);
              routingNumberChange(e)
            }} onBlur={formik.handleBlur}
            value={formik.values.routingNumber}
            readOnly={!editMode}
          />
        <ErrorMessage errorText={formik.touched.routingNumber && formik.errors.routingNumber} />
        </FormControl>
       
        <FormControl
          variant="standard"
          fullWidth
          required
          error={
            formik.touched.confirmRoutingNumber &&
            Boolean(formik.errors.confirmRoutingNumber)
          }
        >
          <InputLabel htmlFor="confirmRoutingNumber">Confirm Routing Number</InputLabel>
          <Input
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            name="confirmRoutingNumber"
            type="text"
            onChange={(e) => {
              handleOnChange(e);
            }} onBlur={formik.handleBlur}
            value={formik.values.confirmRoutingNumber}
            readOnly={!editMode}
            inputProps={{"data-testid": "input-usaform-confirmroutingnumber"}}
          />
          <ErrorMessage errorText={formik.touched.confirmRoutingNumber &&formik.errors.confirmRoutingNumber} />
          <Box
          className="form-three-child"
          sx={{
            position: "relative",
            top: "0px",
          }}
        >
          <Typography
            variant="subtitle1"
            component="span"
            sx={{
              fontSize: "0.75rem",
              textDecorationLine:"underline"
            }}
          >
            ACH/Direct Deposit Routing Number has 9 digits
          </Typography>
        </Box>
        </FormControl>
        <FormControl className="FormControl-label"
          variant="standard"
          fullWidth
          required
          error={
            formik.touched.accountHolderName &&
            Boolean(formik.errors.accountHolderName)
          }
        >
           <InputLabel htmlFor="accountHolderName">Name of Account Holder
          <span className="subLabel" > (Must be same as W9 Form)</span>
          </InputLabel>
          <Input
            onChange={handleOnChange}
            onBlur={formik.handleBlur}
            value={formik.values.accountHolderName}
            name="accountHolderName"
            type="text"
            readOnly={!editMode}
            inputProps={{ "data-testid": "input-usaform-accountholdername" }}
          />
          <ErrorMessage errorText={formik.touched.accountHolderName &&formik.errors.accountHolderName} />
        </FormControl>
        <FormControl
          variant="standard"
          fullWidth
          required
          error={formik.touched.bankName && Boolean(formik.errors.bankName)}
        >
          <InputLabel htmlFor="bankName">Bank Name</InputLabel>
          <Input
            readOnly={!editMode}
            name="bankName"
            onChange={handleOnChange}
            onBlur={formik.handleBlur}
            value={formik.values.bankName}
            type="text"
            autoComplete='off'
            inputProps={{ "data-testid": "input-usaform-bankname" }}
          />
          <ErrorMessage errorText={formik.touched.bankName &&formik.errors.bankName} />
        </FormControl>
               
        <FormControl
          variant="standard"
          fullWidth
          required
          error={
            formik.touched.bankAccountNumber &&
            Boolean(formik.errors.bankAccountNumber)
          }
        >
          <InputLabel htmlFor="bankAccountNumber">
            Bank Account Number
          </InputLabel>
          <Input
            readOnly={!editMode}
            onChange={handleOnChange}
            onBlur={formik.handleBlur}
            value={formik.values.bankAccountNumber}
            name="bankAccountNumber"
            autoComplete='off'
            inputProps={{ className:"key", "data-testid": "input-usaform-bankaccountnumber" }} />
                    <ErrorMessage errorText={formik.touched.bankAccountNumber &&formik.errors.bankAccountNumber} />
 
        </FormControl>
        <FormControl
          variant="standard"
          fullWidth
          required
          error={
            formik.touched.confirmBankAccountNumber &&
            Boolean(formik.errors.confirmBankAccountNumber)
          }
        >
          <InputLabel htmlFor="confirmBankAccountNumber">
            Confirm Bank Account Number
          </InputLabel>
          <Input
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            readOnly={!editMode}
            onChange={handleOnChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmBankAccountNumber}
            name="confirmBankAccountNumber"
            autoComplete='off'
            inputProps={{ "data-testid": "input-usaform-confirmbankaccountnumber" }}
            />
           <ErrorMessage errorText={formik.touched.confirmBankAccountNumber &&formik.errors.confirmBankAccountNumber} />
        </FormControl>
        <PlacesAutocomplete
                value={formik.values?.location?.address}
                onChange={(ev:any) => {
                  formik.setFieldValue("location.address", ev);
                }}
              
                 
                onSelect={autoCompleteGetAddress}
              >
                {renderFunc}
              </PlacesAutocomplete>
        <>
          <Grid   container
          spacing={1}> 
            <Grid item xs={3} md={3}> 
          <FormControl
            variant="standard"
            required
            error={
              formik.touched.location?.city &&
              Boolean(formik.errors.location?.city)
            }
          >
            <InputLabel htmlFor="location.city">City</InputLabel>
            <Input
              readOnly={!editMode}
              onChange={handleOnChange}
              onBlur={formik.handleBlur}
              value={formik.values.location?.city}
              name="location.city"
              type="text"
            />
            <ErrorMessage errorText={formik.touched.location?.city &&formik.errors.location?.city} />
          </FormControl>
          </Grid>
          <Grid item xs={3} md={3}> 
          <FormControl
            variant="standard"
            required
            error={
              formik.touched.location?.state &&
              Boolean(formik.errors.location?.state)
            }
          >
            <InputLabel htmlFor="location.state">State</InputLabel>
            <Input
              readOnly={!editMode}
              onChange={handleOnChange}
              onBlur={formik.handleBlur}
              value={formik.values.location?.state}
              name="location.state"
              type="text"
              inputProps={{"data-testid": "input-usaform-locationstate"}}
            />
                                 <ErrorMessage errorText={formik.touched.location?.state &&formik.errors.location?.state} />
          </FormControl>
          </Grid>
          <Grid item xs={3} md={3}> 
          <FormControl
            variant="standard"
            required
            error={
              formik.touched.location?.zip &&
              Boolean(formik.errors.location?.zip)
            }
          >
            <InputLabel htmlFor="location.zip">Zip</InputLabel>
            <Input
              readOnly={!editMode}
              onChange={handleOnChange}
              onBlur={formik.handleBlur}
              value={formik.values.location?.zip}
              name="location.zip"
              type="text"
            />
          <ErrorMessage errorText={formik.touched.location?.zip &&formik.errors.location?.zip} />
          </FormControl>
          </Grid>
          <Grid item xs={3} md={3}> 
          <FormControl
            variant="standard"
            required
            error={formik.touched?.location?.country && Boolean(formik.errors?.location?.country)}
          >
            <InputLabel htmlFor="location.country">Country</InputLabel>
            <Input
              readOnly={!editMode}

              onChange={handleOnChange}
              onBlur={formik.handleBlur}
              value={formik.values.location?.country}
              name="location.country"
              type="text"
            />
            <ErrorMessage errorText={formik.touched.location?.country &&formik.errors.location?.country} />
          </FormControl>
          </Grid>
          </Grid>
        </>
        {viewMode!==UserAuth.Admin && 
        <Box className="form-three-child" sx={{ marginTop: "10px" }}>
          <Typography
            variant="caption"
            className="label-sml"
           
          >
            Important
            <span
              aria-hidden="true"
              className="MuiFormLabel-asterisk MuiInputLabel-asterisk"
            >
              *
            </span>
          </Typography>

          <Box className="us-billing-checkbox disabled-mui-checkbox">
            <FormControlLabel   onChange={()=> handleIsChecked("first")}  checked={isChecked.first}
              control={
                <Checkbox 
                  className={classes.backgroundColorOnWholeIcon}
                  disabled={!editMode}
                  data-testid={"checkbox-usaform-first"}
                />
              }
              label="I confirm that the ABA routing number provided above for ACH/ Direct Deposit is accurate, and any error can result in a failure or payments being credited to a wrong account."
            />
            <FormControlLabel   onChange={()=> handleIsChecked("second")} checked={isChecked.second}
              control={
                <Checkbox  
                  disabled={!editMode} 
                  className={classes.backgroundColorOnWholeIcon}  
                  data-testid={"checkbox-usaform-second"}
                />
              }
              label="I confirm that my name shared above matches the name on the W9 form (attached) and my bank account."
            />
          </Box>
          
          {((!isChecked.first || !isChecked.second) && isFormTouch) && <ErrorMessage errorText="Important fields must be checked"/>}
        </Box>
}

<Grid container sx={{ justifyContent: "flex-start" }}>
        
          <Grid mt={userRole === UserAuth.Admin? "2px":'-5px'} item md={6} xs={6}>
          <FormControl fullWidth variant="standard" required error={fileError !== ""}>
              <Typography className="W-9form-heading">W-9 Form <Asterisk/> </Typography>
              {editMode && 
              <div className="payment_details_w9">
                <Input
                  readOnly={!editMode}
                  value={valueOrDefault(fileName, "")}
                  name="fileInput"
                  type="text"
                  style={{flexGrow: 1}}
                />
                {formik.values?.resourceInfo?.resourceURL !== undefined &&
                formik.values?.resourceInfo?.resourceURL !== '' &&
                <>
                <VisibilityOutlinedIcon
                  data-testid="setfilepreview"
                  onClick={() => {
                    getFilePreviewUrl(formik.values?.resourceInfo?.resourceURL)
                    .then(filePreviewUrl => setFilePreview({
                      resourceUrl: filePreviewUrl,
                      fileName: fileName
                    }));
                  }}
                  sx={{pl: 1}}
                  color="primary"
                  className="pointer"
                />
                <SaveAltOutlinedIcon
                  data-testid="downloadfile"
                  onClick={() => {
                    downloadFile(
                      formik.values?.resourceInfo?.resourceURL,
                      fileName
                    );
                  }}
                  sx={{pl: 1}}
                  color="primary"
                  className="pointer"
                />
                </>
                }
              </div>
              }
          </FormControl>
          </Grid>
          {/* CWx-1960  'Attach' button be removed in non editable mode */}
          {editMode && 
          <Grid item md={viewMode==UserAuth.Admin? 3 : 2}>
            <Button 
              disabled={isFileUploading}
              variant="contained"
              sx={{ mt: 1.5, ml: 1}}
              size= {viewMode==UserAuth.Admin? 'small' : 'medium'}
              component="label"
              className="upload-btn"
            >
              <FileUploadOutlinedIcon /> {"  "}{isFileUploading? "Uploading" :"Attach"}
              <input
                data-testid="input-usaform-fileupload"
                type="file"
                accept="text/plain, application/pdf, .doc, .docx, .rtf"
                onChange={(e) => onFileUpload(e.currentTarget.files || null)}
                hidden
              />
            </Button>
          </Grid>
          }
          
         
        
        </Grid>
        { fileError || formik.touched.resourceInfo?.fileName &&
          <>
          <ErrorMessage errorText={fileError} />
          <ErrorMessage errorText={formik.touched.resourceInfo?.fileName && formik.errors.resourceInfo?.fileName} />
          </>
        }
        {editMode ?
        <Box
          className="form-three-child"
          sx={{ position: "relative" }}
        >
          <Typography variant="subtitle1" component="span" className="fileUploadHelper-TextLabel" >
            (Max file size: 2MB as .txt, .rtf, .doc, .docx, .pdf )
          </Typography>
        
        </Box>
        :
        <Box className="upload-wrapper-text success-upload" sx={{width: "max-content"}}>
            <Typography
                variant="subtitle1"
                component="div"
                className="title-text"
            >
                {formik?.values?.resourceInfo?.fileName}
            </Typography>
            {formik.values?.resourceInfo?.resourceUrl !== undefined
            && formik.values?.resourceInfo?.resourceUrl !== ""
            &&
            <>
                {formik?.values?.resourceInfo?.type !== "application/x-zip-compressed" // cannot preview zip files
                && formik?.values?.resourceInfo?.fileName.split(".")[1]?.toLowerCase() !== "zip"
                &&
                <VisibilityOutlinedIcon
                    data-testid="primary"
                    onClick={() => {
                        getFilePreviewUrl(formik?.values?.resourceInfo?.resourceUrl)
                        .then(filePreviewUrl => setFilePreview({
                            resourceUrl: filePreviewUrl,
                            fileName: formik?.values?.resourceInfo?.fileName
                        }));
                    }}
                    color="primary"
                    className="pointer"
                    sx={{pl: 2, verticalAlign: "bottom"}}
                />
                }
                <SaveAltOutlinedIcon
                    data-testid="resourceinfo"
                    onClick={() => {
                        downloadFile(
                          formik?.values?.resourceInfo?.resourceUrl,
                          formik?.values?.resourceInfo?.fileName
                        );
                    }}
                    color="primary"
                    className="pointer"
                    sx={{pl: 1, verticalAlign: "bottom"}}
                />
            </>
            }
        </Box>
        }
      </>
      {pageStatus.error &&  
        <CustomAlert 
          key={1} 
          type={"error"} 
          message={pageStatus.error} 
          onClose={()=>{}} 
        />}
      {pageStatus.isSuccess &&  
        <SnackbarPopup 
          open={pageStatus.isSuccess}  
          message={'Payment details updated successfully'} 
          onClose={()=>{ setPageStatus({...pageStatus, isSuccess:false}) }} />}
      
      {filePreview !== undefined &&
      <PreviewFileDialog
        open={filePreview !== undefined}
        handleOpenClose={() => setFilePreview(undefined)}
        fileName={filePreview.fileName}
        fileURL={filePreview.resourceUrl}
        fileType={filePreview.fileType}
      />
      }
    </>
  );
};