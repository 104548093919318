import React, { useState } from "react";
import { Button, FormControlLabel, Checkbox, CircularProgress } from "@mui/material";
import AppConfirmation from "../../../Common/Popup/AppConfirmation";
import { RecruiterStatus } from "../../../../models/interfaces";
import { SetDemoContractStatus, ShareContractDetails } from "../../../../Services/recruiterService";
import SnackbarPopup from "../../../Common/Popup/snackbar/SnackbarPopup";
import { ternary } from "../../../../utils/complexityUtils";
import { ContractStatusCode } from "./SignRecruiterContract";

interface ShareContractDemoContractProps {
  recruiterDetails: any;
}

const ShareContractDemoContract: React.FC<ShareContractDemoContractProps> = ({
  recruiterDetails,
}) => {
  const existingContractStatusCode = recruiterDetails?.contractDetails?.contractStatusCode || "";
  const ContractStatus = ternary(existingContractStatusCode === ContractStatusCode.Signed, ContractStatusCode.Signed, ContractStatusCode.Pending);
  
  const [demoContractChecked, setChecked] = useState<boolean>((recruiterDetails?.demoStatus ?? false) || (existingContractStatusCode === ContractStatusCode.Signed));
  const [contractStatusCode, setContractStatusCode] = useState<string>(ContractStatus);
  const [isAlreadyShareContract, setIsAlreadyShareContract]= useState<boolean>( ternary(recruiterDetails?.contractDetails, true, false))
  const [contractPopupOpen, setContractPopupOpen] = useState<{
    demoContractPopup: boolean;
    shareContractPopup: boolean;
  }>({
    demoContractPopup: false,
    shareContractPopup: false,
  });
  const[apiLoading, setApiLoading] = useState<boolean>(false);
  const message = {
    demoContractDone: "Are you sure demo call of the contract done is done?",
    demoContractNotDone: "Are you sure call demo of the contract done is not yet done?",
    shareContract: "Are you sure you want to share contract?",
  }

  const [pageStatus, setPageStatus] = useState({
    isSuccess:false,
    isError:false,
    message:""
  })

  const handleCheckClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContractPopupOpen((prevState) => ({
      ...prevState,
      demoContractPopup: true,
    }));
  };

  const handleShareClick = () => {
    if (!isButtonShareDisabled) {
      setContractPopupOpen((prevState) => ({
        ...prevState,
        shareContractPopup: true,
      }));
    }
  };

  const handleDemoContractConfirm = () => {
    _setDemoContractStatus();
    setContractPopupOpen((prevState) => ({
      ...prevState,
      demoContractPopup: false,
    }));
  };

  const handleShareContractConfirm = () => {
    _shareContractDetails();
    setContractPopupOpen((prevState) => ({
      ...prevState,
      shareContractPopup: false,
    }));
  };

  const handleCheckboxPopupClose = () => {
    setContractPopupOpen((prevState) => ({
      ...prevState,
      demoContractPopup: false,
    }));
  };

  const handleShareButtonPopupClose = () => {
    setContractPopupOpen((prevState) => ({
      ...prevState,
      shareContractPopup: false,
    }));
  };

  const _setDemoContractStatus = () => {
    let body = {
      id: recruiterDetails?.id,
      demoStatus:!demoContractChecked
    };
    SetDemoContractStatus(body)
      .then((response) => {
        if (response.data?.message?.appStatusCode === "RC_RSF") {
          setPageStatus({
            ...pageStatus,
            isSuccess:true,
            message:"Contract demo call status updated successfully"
          });
          setChecked(!demoContractChecked);
        } else {
          setPageStatus({
            ...pageStatus,
            isError:true,
            message:"Failed to update demo contract"
          })
        }
      })
      .catch((error) => {
        console.error("Error during API call:", error);
      });
  };

  const _shareContractDetails = () => {
    setApiLoading(true);
    let body = {
      id: recruiterDetails?.id,
      redirectUrl: window.location.origin +  "/signcontract/"
    };
    ShareContractDetails(body)
      .then((response) => {
        if (response.data?.message?.appStatusCode === "RC_RSF") {
          setPageStatus({
            ...pageStatus,
            isSuccess:true,
            message:"Share contract details successfully"
          })
          setIsAlreadyShareContract(true);
          let updatedContractStatus = ternary(recruiterDetails?.contractDetails?.contractStatusCode === ContractStatusCode.Signed, ContractStatusCode.Signed, ContractStatusCode.Pending);
          setContractStatusCode(updatedContractStatus);
        } else {
          setPageStatus({
            ...pageStatus,
            isError:true,
            message:"Failed to share contract details with recruiter"
          })
        }
      })
      .catch((error) => {
        console.error("Error during API call:", error);
      })
      .finally(()=>{
        setApiLoading(false);
      })
  };

  function areConditionsEqual(demoContractChecked:boolean, isButtonShareDisabled:boolean) {
        return demoContractChecked === isButtonShareDisabled;
    }
  
  const isButtonShareDisabled = contractStatusCode === ContractStatusCode.Signed
  const isCheckDisabled = recruiterDetails?.status !== RecruiterStatus[RecruiterStatus.Approved] || isButtonShareDisabled || isAlreadyShareContract;
  const ShareContractButtonName = ternary(isAlreadyShareContract, "Resend Contract", "Share Contract")

  return (
    <>
      <FormControlLabel
        className="demoCallText"
        control={
          <Checkbox
            checked={demoContractChecked}
            onChange={handleCheckClick}
            name="checkbox"
            color="primary"
            disabled={isCheckDisabled}           
          />
        }
        
        label="Demo Call"
      />
      <AppConfirmation
        title=""
        open={contractPopupOpen.demoContractPopup}
        handleClose={handleCheckboxPopupClose}
        message={ternary (demoContractChecked, message.demoContractNotDone, message.demoContractDone)}
        onConfirm={handleDemoContractConfirm}
      />
    { !isButtonShareDisabled &&
      <Button
        size="small"
        className={`shareContractButton ${ !areConditionsEqual(demoContractChecked, isButtonShareDisabled) && "action-btn" } fontsize-verify`}
        variant="outlined"
        onClick={handleShareClick}
        disabled={apiLoading || areConditionsEqual(demoContractChecked, isButtonShareDisabled)}
        endIcon={ apiLoading &&  <CircularProgress size={"16px"} color={"inherit"} /> }
      >
        {ShareContractButtonName}
      </Button>
      }
      <AppConfirmation
        title=""
        open={contractPopupOpen.shareContractPopup}
        handleClose={handleShareButtonPopupClose}
        message={message.shareContract}
        onConfirm={handleShareContractConfirm}
      />
      <SnackbarPopup 
        open={pageStatus.isSuccess}
        onClose={()=>{
          setPageStatus({
            isSuccess:false,
            isError:false,
            message:""
          })
        }}
        message={pageStatus.message}
      />
    </>
  );
};

export default ShareContractDemoContract;
