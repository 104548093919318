import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Chip,
  FormControl,
  FormControlLabel,
  Switch,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { PermissionsEnums } from "../../../data/permission";
import { useGetMemberMutation, useSetTeamMemberStatusMutation } from "../../../features/Client/teamMemberSlice";
import { SortType } from "../../../models/app/AppModels";
import {
  MemberFilter,
  teamMemberFilterDefault,
} from "../../../models/client/ClientModels";
import { AppState } from "../../../store/AppState";
import convertToLocalizedDateTime from "../../../utils/AppDateUtils";
import getAddressForAutoComplete from "../../../utils/getAddressForAutoComplete";
import { getNevLink } from "../../../utils/getFileFromUrl";
import SuccessSnackBarPopup from "../../ClientTeam/components/Popup/successSnackBarPopup";
import AppSort from "../../Common/AppSort";
import { Fullname } from "../../Common/Fullname";
import { ModuleComponent } from "../../Common/Permission/ModuleComponent";
import { PhoneNumber } from "../../ui/PhoneNumber";
import "./TeamMember.scss";
import PleaseWait from "../../Common/Loder/PleaseWait";
import NoRecordsFound from "../../Common/Table/NoRecordsFound";
import GenericPagination from "../../Common/Table/GenericPagination";

interface MemberListingTableProps {
  totalRecords:number;
  setTotalRecords: any;
  isApprovedUser:any;
}

export default function MemberListingTable({
  totalRecords,
  setTotalRecords, 
  isApprovedUser
}:MemberListingTableProps) {
  const [teamService] = useSetTeamMemberStatusMutation();
  const { locations, statuses, searchKeyword } = useSelector(
    (state: AppState) => state.teamMemberReducer.filter
  );
  const isMounted = useRef(false);
  const [sort, setSort] = useState(true);
  const navigate = useNavigate();
  const [memberService, clientResult] = useGetMemberMutation();
  const [pageStatus, setPageStatus] = React.useState<any>({
    isSuccess: false,
    isError: false,
    message: "",
    loading: false,
  });

// Pagination state
const handleChangePage = (event:any, newPage:number) => {    
  handlePaginationIndex(newPage);
};
  
  const getCity = (description: any) => {
    let city = getAddressForAutoComplete(description).city;
    return city;
  };

  const [paging, setPaging] = useState<MemberFilter>({
    ...teamMemberFilterDefault,
    locations: locations.map((n) => {
      return getCity(n?.description);
    }),
    statuses: statuses?.map((n) => n.value),
    searchKeyword: searchKeyword,
    clientGuid: "",
  });
  const [teamMembers, setTeamMembers] = useState<any[]>([]);

  React.useEffect(() => {
    memberService(paging);
  }, [paging]);

  React.useLayoutEffect(() => {
    if (isMounted.current) {
      setPaging({
        ...paging,
        pageIndex: 1,
        locations: locations.map((n) => {
          return getCity(n?.description);
        }),
        statuses: statuses.map((n) => n.value),
        searchKeyword: searchKeyword,
      });
    } else {
      isMounted.current = true;
    }
  }, [locations, statuses, searchKeyword]);

  React.useEffect(() => {
    setTeamMembers(clientResult.data?.entityList ?? []);
  }, [clientResult.data?.entityList]);

  React.useEffect(() => {
    if (clientResult.data?.totalRecords !== undefined) {
      setTotalRecords(clientResult.data?.totalRecords);
    }
  }, [clientResult.data?.totalRecords]);


  const handlePaginationIndex = (index:number) => {
    setPaging({
      ...paging,
      pageIndex: index,
    });
  };

  const handleSortClicked = (sortColumnName: string) => {
    setSort(!sort);
    setPaging({
      ...paging,
      sortColumn: sortColumnName,
      isSorted: true,
      sortOrder:
        paging.sortOrder === SortType.Ascending
          ? SortType.Descending
          : SortType.Ascending,
      pageIndex: 1,
      locations: locations,
      statuses: statuses.map((n) => n.value),
      searchKeyword: searchKeyword,
    });
  };

  const handleTeamMemberStatus = (status: boolean, teamMemberId: string) => {
    
    teamService({
      isActive: status,
      teamMemberId: teamMemberId,
    }).then((res :any )=>{
      
      if(res.data?.status === 200){
        setPageStatus({
          isSuccess: true,
          message: res.data?.message?.appStatusDescription,
        })
        setTeamMembers(
          teamMembers.map((n) => {
            if (n.id === teamMemberId) {
              return { ...n, isActive: status };
            } else {
              return n;
            }
          })
        );
      }
    })
  };

  const _handleSuccessClose = ()=>{    
    setPageStatus({
      isSuccess: false,
      isError: false,
      message: "",
      loading: false,
    });
  }

  return (
    <>
      <Grid
        container
        item
        xs={12}
        md={12}
        spacing={2}
        className="Client-listingTable-wrapper"
        style={{ marginTop: "10px" }}
      >
        <TableContainer component={Paper} className="AppTable Client-table">
          <GenericPagination
              count={totalRecords}
              rowsPerPage={paging.pageSize}
              page={paging.pageIndex}
              onPageChange={handleChangePage}
            /> 
            <Table
              aria-label="simple table"
              style={{
                borderCollapse: "separate",
                borderSpacing: "0 4px",
                backgroundColor: "#fafaff",
              }}
            >
              <TableHead className="custom-Client-fontsizes">
                <TableRow>
                  <TableCell
                    data-testid="name-sort"
                    className="minWidthROw"
                    onClick={() => handleSortClicked("fullName")}
                  >
                    <Grid container>
                      <Grid className="custom-Client-fontsizes pointer">
                        Name
                      </Grid>
                      <Grid>
                        <AppSort
                          sortType={
                            sort ? SortType.Ascending : SortType.Descending
                          }
                          disable={paging.sortColumn !== "fullName"}
                        ></AppSort>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell sx={{ px: 1 }} className="minWidthROw">
                    Email
                  </TableCell>
                  <TableCell sx={{ px: 1 }} className="minWidthROw">
                    Phone
                  </TableCell>
                  <TableCell className="minWidthROw rec-Location-width">
                    Location
                  </TableCell>
                  <TableCell
                    data-testid="date-sort"
                    className="minWidthROw"
                    onClick={() => handleSortClicked("createdDate")}
                  >
                    <Grid container>
                      <Grid className="custom-Client-fontsizes pointer">
                        Date
                      </Grid>
                      <Grid>
                        <AppSort
                          sortType={
                            sort ? SortType.Ascending : SortType.Descending
                          }
                          disable={paging.sortColumn !== "createdDate"}
                        ></AppSort>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell className="minWidthROw">Status</TableCell>
                  <TableCell className="minWidthROw1">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {teamMembers.map((row: any, index: number) => {

                    let chipClassName = "statusChip custom-client-status";

                    if (row?.isActive === true) {
                      chipClassName += " Active";
                    } else if (row?.isActive === false) {
                      chipClassName += " Inactive";
                    }

                    // Check if both conditions are met to enable the "Verify" button
                    return (
                      <TableRow
                        className="custom-hover-bgcolor"
                        hover
                        key={ row?.id ??  index}
                        style={{ padding: 20, backgroundColor: "white" }}
                      >
                        <TableCell
                          align="left"
                          width={"13.1%"}
                          sx={{
                            whiteSpace: "nowrap",
                            px: 1,
                            width: 100,
                            fontSize: "12px !important",
                            border: 0,
                          }}
                        >
                          <Fullname fullName={row?.fullName}></Fullname>
                        </TableCell>

                        <TableCell
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            px: 1,
                            width: 100,
                            fontSize: "12px !important",
                            border: 0,
                          }}
                        >
                          <Tooltip title={row?.email}>
                            <div>{row?.email} </div>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            px: 1,
                            width: 100,
                            fontSize: "12px !important",
                            border: 0,
                          }}
                          align="left"
                        >
                          <div className="phNumber">
                            <PhoneNumber
                              number={row?.primaryPhoneNumber ?? "N/A"}
                            />
                          </div>
                        </TableCell>

                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            px: 1,
                            width: 100,
                            fontSize: "12px !important",
                            border: 0,
                          }}
                          align="left"
                        >
                          {row?.address?.city + ", " + row?.address?.state}
                        </TableCell>

                        <TableCell
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            px: 1,
                            width: 100,
                            fontSize: "12px !important",
                            border: 0,
                          }}
                        >
                          {convertToLocalizedDateTime(
                            row?.createdDate,
                            "MMMM DD, YYYY,  h:mm A"
                          )}
                        </TableCell>

                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            px: 1,
                            width: 100,
                            fontSize: "12px !important",
                            border: 0,
                          }}
                        >
                          <Chip
                            label={row?.isActive === true ? "Active" : "Inactive"}
                            className={chipClassName}
                          />
                        </TableCell>

                        <TableCell
                          className="edit-verify-clr"
                          sx={{
                            whiteSpace: "nowrap",
                            px: 1,
                            width: 100,
                            fontSize: "12px !important",
                            border: 0,
                          }}
                        >
                          <ModuleComponent moduleId={PermissionsEnums.Members_Verify}>
                          <FormControl
                            className="cust-field-style-switch"
                            size="medium"
                            style={{ marginLeft: "12px !important" }}
                          >
                            <FormControlLabel
                              control={
                                <Switch
                                  className={`switch-v2-small ${
                                    row?.isActive
                                      ? "switch-v2-checked"
                                      : "switch-v2"
                                  }`}
                                  size="medium"
                                  name="jobDetails.isRemoteJob"
                                  color="secondary"
                                  style={{ marginLeft: "12px !important" }}
                                  data-testid='switch-teammemberstatus'
                                  disabled={!isApprovedUser}
                                  checked={row?.isActive}
                                  onChange={() =>
                                    handleTeamMemberStatus(
                                      !row?.isActive,
                                      row?.id
                                    )
                                  }
                                />
                              }
                              label=""
                            />
                          </FormControl>
                          </ModuleComponent>
                          <ModuleComponent moduleId={PermissionsEnums.Members_Edit}>

                          <Tooltip title={"Edit"} placement="top">
                            <EditOutlinedIcon
                              data-testid='icon-edit'
                              style={{ cursor: "pointer", paddingRight: 10 }}
                              onClick={() =>{
                                if(isApprovedUser){
                                  navigate(
                                    getNevLink(`/editteammember/${row?.id}`)
                                  )
                                }
                              }}
                            />
                          </Tooltip>
                          </ModuleComponent>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              </TableBody>
            </Table>
              {clientResult.isLoading && <PleaseWait />}
              {!clientResult.isLoading && clientResult.data?.totalRecords === 0 && <NoRecordsFound /> }
        </TableContainer>
      </Grid>

    {pageStatus?.isSuccess && (
      <SuccessSnackBarPopup
        open={pageStatus?.isSuccess}
        message={pageStatus?.message}
        onClose={_handleSuccessClose}
      />
    )}</>
  );
}
