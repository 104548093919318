import {
  Box,
  Button,
  CardContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";
import { UserAuth } from "../../../models/interfaces";
import formatName from "../../../Shared/utils/formatStrings";
import getLoggedInUserRole from "../../../Shared/utils/getLoggedInUserRole";
import constructName from "../../../utils/constructName";
import { getNevLink } from "../../../utils/getFileFromUrl";
import { jobLocation } from "../../../utils/jobUtils";
import { Payrate } from "../../ui/Payrate";
import ViewRecruiterInfoPopup from "../../ViewSubmissions/NewViewSubmission/Popups/ViewRecruiterInfoPopup";
import { ModuleComponent } from "../../Common/Permission/ModuleComponent";
import { PermissionsEnums } from "../../../data/permission";
import { Fullname } from "../../Common/Fullname";
import PleaseWait from "../../Common/Loder/PleaseWait";
import NoRecordsFound from "../../Common/Table/NoRecordsFound";
import GenericPagination from "../../Common/Table/GenericPagination";

interface ContractGridProps {
  isLoading: boolean;
  data: any;
  handlePaginationIndex: any;
  totalRecords:number;
  filterState:any;
}

const ContractGrid: React.FunctionComponent<ContractGridProps> = (props) => {
  const [isOpenPopup, setIsOpenPopup] = React.useState(false);
  const [recruiterDetailsId, setRecruiterDetailsId] = React.useState(false);

  const userType = getLoggedInUserRole();

  // Pagination state

  const handleChangePage = (event:any, newPage:number) => {    
    props?.handlePaginationIndex(newPage);
  };

  const handleViewRecruiterBtn = (event: any, recruiterId: any) => {
    setRecruiterDetailsId(recruiterId);
    setIsOpenPopup(true);
  };

  const handleClose = () => {
    setIsOpenPopup(false);
  };

  const mapEmploymentType = (type:string) => {
    if (type === "DirectContract") {
      return "C2C";
    } else if (type === "W2_Employee") {
      return "W2";
    } else if (type === "FullTime") {
      return "";
    } else {
      return type;
    }
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <GenericPagination
            count={props?.totalRecords}
            rowsPerPage={props?.filterState?.pageSize}
            page={props?.filterState?.pageIndex}
            onPageChange={handleChangePage}
          /> 
          <Table
            aria-label="simple table"
            style={{
              borderCollapse: "separate",
              borderSpacing: "0 4px",
              backgroundColor: "#fafaff",
              paddingTop: "20px",
            }}
          >
            <TableHead className="custom-contracttable-th">
              <TableRow>
                <TableCell align="left" sx={{ px: 1 }}>
                  Project ID
                </TableCell>
                <TableCell sx={{ px: 1 }}>Job Title</TableCell>
                <TableCell sx={{ px: 1 }}>Name</TableCell>
                <TableCell style={{ minWidth: 120 }} sx={{ px: 1 }}>
                  Client/ Location
                </TableCell>
                <TableCell sx={{ px: 1 }}>Job Type</TableCell>
                <TableCell sx={{ px: 1 }}>Tax Terms</TableCell>
                <TableCell sx={{ px: 1 }}>Work Auth</TableCell>
                <TableCell sx={{ px: 1 }}>Pay Rate</TableCell>
                {userType === UserAuth.Admin && (
                  <TableCell sx={{ px: 1 }}>Recruiter</TableCell>
                )}
                <TableCell sx={{ px: 1 }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props?.data?.map((row: any, index: any) => (
                <>
                  <TableRow
                    style={{ padding: 20, backgroundColor: "white" }}
                    className="custom-hover-bgcolor"
                    hover
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell sx={{ whiteSpace: "nowrap", px: 1, width: 70 }}>
                      <Link 
                        to={getNevLink(`/Contractdetail/${row.id}`)}
                      >
                          #{row?.projectId}
                      </Link>
                    </TableCell>
                    <TableCell sx={{ px: 1, width: 130 }}>
                      {
                        <Typography
                          className="custom-contract-tablebody"
                          variant="caption"
                          gutterBottom
                          sx={{
                            fontWeight: "400",
                            lineHeight: "24px",
                            fontSize: "16px",
                          }}
                        >
                          {row.jobTitle}
                        </Typography>
                      }
                    </TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap", px: 1, width: 100 }}>
                      <Fullname
                        fullName={constructName(
                          row?.firstName,
                          row?.middleName,
                          row?.lastName
                        )}
                      />
                    </TableCell>
                    <TableCell sx={{ px: 1, width: 150 }}>
                      <CardContent sx={{ padding: "0 !important" }}>
                        <Grid container spacing={0}>
                          <Grid>
                          </Grid>
                          <Grid item xs={9}>
                            <>
                              <Typography
                                component="span"
                                className="custom-contractloc-listinclr"
                                sx={{ fontWeight: "bold" }}
                              >
                                {row?.jobClientName}
                              </Typography>
                              <br />
                            </>

                            <Typography
                              className="custom-contract-listinclr"
                              sx={{ lineHeigh: "16px" }}
                            >
                              {jobLocation(
                                row?.remoteJob,
                                row?.jobCity,
                                row?.jobStateName
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </TableCell>
                    <TableCell sx={{ px: 1, whiteSpace: "nowrap", width: 100 }}>
                      {row.contractType}
                    </TableCell>
                    <TableCell sx={{ px: 1, width: 100 }}>
                      {mapEmploymentType(row?.employmentType)}
                    </TableCell>
                    <TableCell sx={{ px: 1, width: 100 }}>
                      {row?.workAuthorizationStatus}
                    </TableCell>
                    <TableCell sx={{ px: 1, width: 100 }}>
                      <Payrate
                        fontSize="14px"
                        highLightCurrency={false}
                        packageType={row.payRateType}
                        packageValue={row.payRate}
                        currency={row.packageCurrency}
                      />
                    </TableCell>
                    {userType === UserAuth.Admin && (
                      <TableCell
                        sx={{ whiteSpace: "nowrap", px: 1, width: 100 }}
                      >
                        <Link
                          data-testid="recruiterGuid"
                          to="#"
                          style={{ color: "#4540DB", textDecoration: "none" }}
                          onClick={(e) =>
                            handleViewRecruiterBtn(e, row.recruiterGuid)
                          }
                        >
                          <Fullname
                            fullName={formatName(
                              row.recruiterFirstName,
                              row.recruiterLastName
                            )}
                          />
                        </Link>
                      </TableCell>
                    )}

                    <TableCell sx={{ whiteSpace: "nowrap", px: 1, width: 15 }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "left",
                          justifyContent: "left",
                        }}
                      >
                        <ModuleComponent
                          moduleId={PermissionsEnums.Contracts_Details}
                        >
                          <Button
                            component={Link}
                            variant="outlined"
                            className="custom-contract-detailsbtn"
                            sx={{ margin: "0 0 0 0 !important" }}
                            to={getNevLink(`/Contractdetail/${row.id}`)}
                          >
                            Details
                          </Button>
                        </ModuleComponent>
                      </Box>
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        {props.isLoading && <PleaseWait />}
        {!props.isLoading && props?.data?.length === 0 && <NoRecordsFound /> }
      </TableContainer>
        {isOpenPopup && (
          <ViewRecruiterInfoPopup
            recruiterId={recruiterDetailsId}
            isOpenPopup={isOpenPopup}
            handleClose={handleClose}
          />
        )}
    </div>
  );
};

export default ContractGrid;
