import moment from "moment";

function beginningOfMonth(myDate){    
    let date = new Date(myDate);
    date.setDate(1)
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);   
    return date;     
  }
  
  /**
   * Returns a date set to the end of the month
   * 
   * @param {Date} myDate 
   * @returns {Date}
   */
  function endOfMonth(myDate){
    let date = new Date(myDate);
    date.setDate(1); // Avoids edge cases on the 31st day of some months
    date.setMonth(date.getMonth() +1);
    date.setDate(0);
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    return date;
  }
  function isDateFormat(str) {
    if(str){
    const dateFormat = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
    return dateFormat.test(str);
    }
    return true;
  }

  function getMonthBoundaryDates(selectedDate) {
    // Create a new Date object from the selected date
    const date = new Date(selectedDate);
   
    // Get the year and month from the selected date
    const year = date.getFullYear();
    const month = date.getMonth();
   
    // Calculate the first date of the month
    const firstDate = new Date(year, month, 1);
   
    // Calculate the last date of the next month
    const lastDate = new Date(year, month + 1, 0);
   
    // Return the first and last dates as formatted strings
    return {
      firstDate: firstDate.toLocaleDateString("fr-CA"),
      lastDate: lastDate.toLocaleDateString("fr-CA")
    };
   }

   function AppDateFormat(selectedDate,formatType="MMMM DD, YYYY, h:mm A"){
    if(selectedDate){

     return moment(selectedDate)?.utc()?.format(formatType)
    }
    return selectedDate
   }
   const formatTime = (originalTimeString) => {
     const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)(:[0-5]\d)?$/;

     if (timeRegex.test(originalTimeString)) {
       // Extract hours and minutes and format the result
       const [hours, minutes] = originalTimeString.split(":");
       return `${hours}:${minutes}`;
     }

     return originalTimeString;
   };
   
   
export { AppDateFormat, beginningOfMonth, endOfMonth, formatTime, getMonthBoundaryDates, isDateFormat };



