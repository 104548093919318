import { createSlice } from "@reduxjs/toolkit";
import { editModeKey } from "../../data/editModeKey/editModeKey";


export const editModeSlice = createSlice({
  name: "editMode",
  initialState: {
    isEditable: true,
    //edit mode for specific component
    individualEditMode : [{name:editModeKey.AllRecruiter, editMode : true},{ name:editModeKey.IndividualRecruiter,editMode:true},{ name:editModeKey.CandidateAcknInfo,editMode:false},{name:editModeKey.CandidateAcknResume,editMode:false}]
   
  },
  reducers: {
   
    toggleEdit: (state) => {
      state.isEditable = !state.isEditable;
    },
    toggleIndividualEdit: (state,action) => {
      let AlleditModel = state.individualEditMode;
      let pageName = action.payload.pageName;
      let status = AlleditModel.find(x => x.name === pageName);
      status.editMode = !status.editMode
    },
    enabledCandidateAcknInfoEdit: (state) => {
      let AlleditModel = state.individualEditMode;
      let status = AlleditModel.find(x => x.name === editModeKey.CandidateAcknInfo);
      status.editMode = true
    },
    disableEdit: (state) => {
      state.isEditable = false;
    },
    enableEdit: (state) => {
      state.isEditable = true;
    },
    enableIndividualEdit: (state,action) => {
      let AlleditModel = state.individualEditMode;
      let pageName = action.payload.pageName;
      let status = AlleditModel.find(x => x.name === pageName);
      status.editMode = true
    },
  },
  
});

// Action creators are generated for each case reducer function
export const { toggleEdit,disableEdit,enableEdit,toggleIndividualEdit,enableIndividualEdit,enabledCandidateAcknInfoEdit } =
  editModeSlice.actions;

export default editModeSlice.reducer;