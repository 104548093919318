import React, { FC } from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';

interface PayrateAlertPopupProps {
  payrateDialog: {
    display: boolean;
    isConfirm: boolean;
  };
  setPayrateDialog: (dialogState: { display: boolean; isConfirm: boolean }) => void;
  setScoreResume: (value: boolean) => void;
  handleResumeSubmit: (values: any) => void;
  formRef: React.RefObject<{ values: any }>;
}

const PayrateAlertPopup: FC<PayrateAlertPopupProps> = ({
  payrateDialog,
  setPayrateDialog,
  setScoreResume,
  handleResumeSubmit,
  formRef,
}) => {
  return (
    <Dialog
      open={payrateDialog.display}
      onClose={() => setPayrateDialog({ display: false, isConfirm: false })}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent sx={{ bgcolor: '#FFF' }}>
        <DialogContentText
          className="roboto-font"
          id="alert-dialog-description"
          style={{
            textAlign: 'center',
            padding: '10px',
            fontSize: '20px',
            fontStyle: 'normal',
            color: '#06152B',
            paddingBottom: '7px',
          }}
        >
          Entered pay rate is greater than the max. pay rate. Are you sure you
          want to continue?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ bgcolor: '#FFF', justifyContent:"center", marginBottom:"16px" }}>
        <Button
          size="small"
          variant="outlined"
          className="roboto-font"
          style={{ fontSize: 17 }}
          onClick={() => {
            setPayrateDialog({ display: false, isConfirm: false });
            setScoreResume(false);
          }}
        >
          No
        </Button>
        <Button
          size="small"
          variant="contained"
          className="roboto-font"
          style={{ fontSize: 17 }}
          onClick={() => {
            setPayrateDialog({ display: false, isConfirm: true });
            if (formRef.current?.values) {
              handleResumeSubmit(formRef.current.values);
            }
          }}
          autoFocus
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PayrateAlertPopup;
