import { FormControl, Grid, TextField, Typography } from "@material-ui/core";
import Box from "@mui/material/Box";
import React from "react";
import PhoneInput from "react-phone-input-2";
import { preferredCountries } from "../../../../../data/phoneInput/preferredCountries";
import PhoneVerify from "../../../../Common/PhoneVerify/PhoneVerify";
import { Asterisk } from "../../../../ui/Asterisk";
import { ErrorMessage } from "../../../../ui/ErrorMessage";
import verifiedIcon from "./../../../../../assets/images/verified.svg";
import { isValidPhoneNumber } from "react-phone-number-input";

export default function ContactDetailsForm(props: any) {
  const { formik, formError } = props;

  let countryShort = "us";

  const props_Phone1Verify={
    testId:"primaryPhoneBtn",
    userId: formik.values.id,
    phoneNumber: formik.values.contactDetails.primaryPhoneNumber,
    isPrimaryPhone: true,
    updatePhoneVerify : (isVerified:boolean)=>{
      formik.setFieldValue("contactDetails.isPrimaryPhoneVerified",isVerified);
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography className="signUpFormHeading" variant="h6" gutterBottom>
            Contact Details
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="contactDetails.firstName"
            label="First Name"
            value={formik?.values?.contactDetails?.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched?.contactDetails?.firstName && formError?.contactDetails?.firstName}
            helperText={formik.touched?.contactDetails?.firstName && formError?.contactDetails?.firstName}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="middleName"
            name="contactDetails.middleName"
            label="Middle Name"
            value={formik.values?.contactDetails?.middleName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formError?.contactDetails?.middleName}
            helperText={formError?.contactDetails?.middleName}
            fullWidth
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="contactDetails.lastName"
            label="Last Name"
            value={formik.values.contactDetails?.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched?.contactDetails?.lastName && formError?.contactDetails?.lastName}
            helperText={formik.touched?.contactDetails?.lastName && formError?.contactDetails?.lastName}
            fullWidth
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="postion"
            name="contactDetails.position"
            label="Position"
            value={formik.values.contactDetails?.position}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched?.contactDetails?.position && formError?.contactDetails?.position}
            helperText={formik.touched?.contactDetails?.position && formError?.contactDetails?.position}
            fullWidth
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className="phone-number-wrapper signUpComplete-phoneInput">
            <FormControl 
              variant="standard" 
              required
              error={formik.touched?.contactDetails?.primaryPhoneNumber && formError?.contactDetails?.primaryPhoneNumber}
              >
              <span className="phoneNumberLabel">
                Phone Number <Asterisk />
              </span>
              <PhoneInput                
                inputProps={{
                  'data-testid':"phone-input"
                }}
                containerClass="phoneCountryCustom"
                placeholder="Phone"
                country={countryShort}
                preferredCountries={preferredCountries}
                preserveOrder={["preferredCountries"]}
                onBlur={() => {
                  formik.setFieldTouched("contactDetails.primaryPhoneNumber",true);
                }}
                onChange={(ev) => {
                  formik.setFieldValue("contactDetails.primaryPhoneNumber", ev);
                }}
                value={formik.values?.contactDetails?.primaryPhoneNumber}
              />
              { formik.values?.contactDetails?.isPrimaryPhoneVerified ?
                  <img style={{paddingLeft:"10px", position: "absolute", left: "100.28%", right: "-6.31%",top: "50%",bottom: "-7.5%"}} src={verifiedIcon}></img>
                  : null
                }
            </FormControl>
            {(!formik.values.contactDetails.isPrimaryPhoneVerified && formik.touched?.contactDetails?.primaryPhoneNumber && formError?.contactDetails?.primaryPhoneNumber === undefined && isValidPhoneNumber('+'+ formik.values?.phone))? (
              <PhoneVerify {...props_Phone1Verify}></PhoneVerify>
            ) : null}
            <ErrorMessage errorText={formik.touched?.contactDetails?.primaryPhoneNumber && formError?.contactDetails?.primaryPhoneNumber} />
           
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
