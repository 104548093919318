import React from "react";
import { Avatar } from "@mui/material";
import { UploadPhoto as UploadPhotoIcon } from "../Common/Icons/UploadPhoto";

interface RenderPhotoProps {
  imageInfo?: {
    imageUrl?: string;
  };
  imgData: string;
}

const RenderPhoto: React.FC<RenderPhotoProps> = ({ imageInfo, imgData }) => {
  if (imageInfo?.imageUrl) {
    return (
      <Avatar
        sx={{ width: 120, height: 120, display: "inline-block" }}
        alt=""
        src={imgData}
      ></Avatar>
    );
  } else {
    return (
      <Avatar
        sx={{
          width: 120,
          height: 120,
          display: "inline-block",
          backgroundColor: "#ced5fb",
        }}
      >
        <UploadPhotoIcon sx={{ marginTop: "40%" }} />
      </Avatar>
    );
  }
};

export default RenderPhoto;
