import { createSlice } from "@reduxjs/toolkit";

export const rejectedRecruiterSubmissionRowSlice = createSlice({
  name: "rejectedRecruiterSubmissionRow",
  initialState: {
  rowIndex : null,
  selectedRow: null,

   
  },
  reducers: {
    setSelectedRow: (state,action) => {
        state.selectedRow = action.payload;
    },
    setSelectedIndex: (state,action) => {
        state.rowIndex =action.payload;
    },
   
},
  
});

// Action creators are generated for each case reducer function
export const {setSelectedRow,setSelectedIndex } =
  rejectedRecruiterSubmissionRowSlice.actions;

export default rejectedRecruiterSubmissionRowSlice.reducer;