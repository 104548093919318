import { createSvgIcon } from "@mui/material/utils";

export const ResumeSubmitted = createSvgIcon(
  <svg viewBox="0 0 23 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3125 3.60416C8.7247 3.60416 7.43752 4.89134 7.43752 6.47916C7.43752 6.48884 7.43757 6.49852 7.43766 6.50818C7.75215 5.21227 8.92003 4.24999 10.3127 4.24999C11.7049 4.24999 12.8725 5.21174 13.1874 6.50711C13.1875 6.4978 13.1875 6.48848 13.1875 6.47916C13.1875 4.89134 11.9003 3.60416 10.3125 3.60416ZM13.271 10.2427L14.3769 10.978L15.2075 9.72889C15.8267 8.79751 16.1875 7.67819 16.1875 6.47916C16.1875 3.23448 13.5572 0.604156 10.3125 0.604156C7.06785 0.604156 4.43752 3.23448 4.43752 6.47916C4.43752 7.67819 4.79832 8.79751 5.41759 9.72889L6.2481 10.978L7.35432 10.2425V17.3159L5.59194 15.5536C4.43664 14.3983 2.56352 14.3983 1.40822 15.5536C0.271476 16.6903 0.253221 18.522 1.35346 19.6811C3.35501 22.08 4.40799 23.8951 5.26748 25.3768C5.57747 25.9111 5.86229 26.4021 6.15737 26.8614C6.70703 27.7171 7.34557 28.534 8.31476 29.0925C9.28809 29.6533 10.4179 29.8542 11.771 29.8542H14.6877C18.7377 29.8542 22.021 26.5709 22.021 22.5208V16.6875C22.021 15.0536 20.6965 13.7292 19.0627 13.7292C18.9429 13.7292 18.8247 13.7363 18.7086 13.7501C18.1971 12.8658 17.241 12.2708 16.146 12.2708C16.0262 12.2708 15.908 12.2779 15.792 12.2918C15.287 11.4187 14.3486 10.8277 13.271 10.8128V10.2427ZM10.3331 18.1875C10.2945 18.4985 10.2035 18.8015 10.0632 19.0835L8.84241 18.4762L10.0632 19.0835C9.21661 20.785 6.95077 21.155 5.60694 19.8112L4.11991 18.3242C6.02296 20.6897 7.15076 22.6356 7.9541 24.0216C8.22839 24.4949 8.46485 24.9028 8.68143 25.24C9.14307 25.9586 9.46594 26.2934 9.81252 26.4931C10.155 26.6904 10.6953 26.8542 11.771 26.8542H14.6877C17.0809 26.8542 19.021 24.9141 19.021 22.5208V18.2021H16.1043V18.1875H13.271H13.1877H10.3331ZM3.47062 17.6749L3.47067 17.6749ZM7.72798 17.6896C7.72807 17.6897 7.72817 17.6898 7.72826 17.6899L7.72798 17.6896Z"
      fill="#4540DB"
    />
  </svg>,
  "ResumeSubmitted"
);
