import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { impersonate } from "../Services/adminService";
import { getRecruiterData } from "../features/recruiterSlice";
import { resetActiveRecruiterSubmission } from "../features/recruiterSubmission/activeRecruiterSubmissionSlice";
import { resetRejectedSubmission } from "../features/recruiterSubmission/rejectedRecruiterSubmissionSlice";
import { getLoggedInUserDetails } from "../features/userDetails/loggedInUserDetailsSlice";
import setAuthToken from "../utils/setAuthToken";

import getLoggedInUserPermissions from "../Shared/utils/getLoggedInUserPermission";
import { resetAllJobFilter } from "../features/Jobs/clientJobSlice";
import { loadPermissions } from "../features/permission/userPermissionSlice";
export const useImpersonate = () => {
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const impersonateUser = (selectedUser: string) => {
    let reqModel = { userName: selectedUser };
    impersonate(reqModel)
      .then((res) => {
        
        if (res.data.status === 200) {
          localStorage.setItem("user", JSON.stringify(res.data.entity));
          //get recruiter
          dispatch(resetActiveRecruiterSubmission())
          dispatch(resetRejectedSubmission())
          dispatch(getLoggedInUserDetails())
          localStorage.removeItem('jobAdvanceSearchData');
          setAuthToken(res.data.accessToken);
          //get recruiter
          dispatch(getRecruiterData());         
          setSuccess(true)
          let userModules = getLoggedInUserPermissions();
          dispatch(loadPermissions({permissions:userModules}))
          navigate("/dashboard");

          dispatch(resetAllJobFilter());
          
         
        } else {
          setError(res?.data?.message?.appStatusDescription);
        }
      })
      .catch((err) => {
        setError(err?.message);
      });
  };
  return { impersonateUser, error,success };
};
