import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Alert, CircularProgress, Divider, FormControl, IconButton, SvgIcon, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import LinkedInButton from "../LinkedinButton";
import GoogleButton from "../GoogleButton";
import "./SignupPopup.scss";
import { RecruiterSignupIcon } from "../../Common/Icons/RecruiterSignupIcon";
import { SignupCheckIcon } from "../../Common/Icons/SignupCheckIcon";
import { RecruiterSignupIcon_Selected } from "../../Common/Icons/RecruiterSignupIcon_Selected";
import { ClientSignupIcon_Selected } from "../../Common/Icons/ClientSignupIcon_Selected";
import { ErrorMessage } from "../../ui/ErrorMessage";
import { ClientSignupIcon } from "../../Common/Icons/ClientSignupIcon";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "../../../Services/CommonHttpRequest";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { AuthenticateRecaptcha } from "../../../Services/googleRecaptchaService";
import { SignupInitialVerification } from "../../../Services/clientService";
import { getIsClientModuleEnabled } from "../../../utils/AppConfig";
import { CheckIsUserEmailExist } from "../../../Services/idProviderService";
import { ExistUserPayload } from "../../../models/app/Login/UserLoginModel";
import { UserAuth } from "../../../models/interfaces";
import { ternary } from "../../../utils/complexityUtils";
import RedirectStaffline from "../Components/RedirectStaffline";

export type SignUpOptionType = 'recruiter' | 'client' | '';

interface Props {
  isOpen: boolean;
  setIsOpen: any;
  handlePopupClose: any;
  setEmail: any;
  setShowConfirmation: (confirmValue: boolean, selectedOption: SignUpOptionType) => void;
  selectedOption: SignUpOptionType;
}

const signupURL = `${process.env.REACT_APP_RECUITER_API}api/RecruiterSignupInitiation/SignupInitialVerification`;

export const SignupPopup: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  handlePopupClose,
  setEmail,
  setShowConfirmation,
  selectedOption
}) => {

  const isClientModuleEnabled: boolean = getIsClientModuleEnabled();

  const [itemSelected, setItemSelected] = React.useState({
    recruiter: !isClientModuleEnabled,
    client: false
  });

  const [error, setError] = React.useState("");
  const [recruiterError, setRecruiterError] = React.useState("");
  const [clientError, setClientError] = React.useState("");
  
  const { executeRecaptcha } = useGoogleReCaptcha();
  const recaptchaAction = "signin";

  React.useEffect(() => {
    if (selectedOption === 'recruiter') {
      setItemSelected({recruiter: true, client: false});
    }
    else if (selectedOption === 'client') {
      setItemSelected({recruiter: false, client: true});
    }
  }, [selectedOption]);

  const initialState = {
    email: "",
    redirectUrl: "",
  };
  const SignupSchema = Yup.object({
    email: Yup.string()
      .email("Please enter valid email address")
      .required("Please enter email address"),
  });

  const formikRecruiter = useFormik({
    initialValues: initialState,
    validationSchema: SignupSchema,
    onSubmit: (values, actions) => {
      setRecruiterError("");
      setEmail(values.email);
      values.redirectUrl = `${window.location.origin}/SignupComplete`;
      actions.setSubmitting(true);
      verifyUserEmailExistance(values, actions,UserAuth.Recruiter,values?.email)
    },
  });

  const formikClient = useFormik({
    initialValues: initialState,
    validationSchema: SignupSchema,
    onSubmit: (values, actions) => {
      setRecruiterError("");
      setEmail(values.email);
      values.redirectUrl = `${window.location.origin}/ClientSignup`;
      actions.setSubmitting(true);
      verifyUserEmailExistance(values, actions,UserAuth.Client,values?.email)
    },
  });

  const verifyUserEmailExistance = async (values:any, actions:any, userRole:UserAuth, selectedEmail:string) => {
   const reqestPayload:ExistUserPayload = {
      email:selectedEmail
    }
    const EmailExistErrorMsg = "User with this email already exists. Please enter another email address."
    CheckIsUserEmailExist(reqestPayload)
    .then((response)=>{
      if (response?.data?.entity?.isExists) {
        actions.setSubmitting(false);
        if(userRole === UserAuth.Client){
          setClientError(EmailExistErrorMsg);
        }else{
          setRecruiterError(EmailExistErrorMsg);
        }
      } else {
        handleReCaptchaVerify(values, actions, ternary( userRole === UserAuth.Client,  DoClientSignup, DoRecruiterSignup));
      }
    })
    .catch((error)=>{
      console.error(error,"Something went wrong");
    })

  };

  const handleReCaptchaVerify = React.useCallback(
    async (values, actions, signupMethod) => {
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        return;
      }
      const token = await executeRecaptcha(recaptchaAction);
      AuthenticateRecaptcha({
        reCaptchaToken: token,
        action: recaptchaAction,
      }).then((response) => {
        actions.setSubmitting(false);
        if (response.data.status == 200 && response.data.entity.score >= 0.5) {
          signupMethod(values, actions);
        } else if (
          response.data.status == 200 &&
          response.data.entity.score <= 0.5
        ) {
          setError(response?.data?.message?.appStatusDescription);
        } else {
          setError(response?.data?.message?.appStatusDescription);
        }
      });
    },
    [executeRecaptcha]
  );

  function DoRecruiterSignup(values: any, actions: any) {
    axios
      .post(signupURL, values, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        if (res.data.message.appStatusCode == "RC_SEML") {
          localStorage.setItem("recruiter_id", res.data.entity.id);
          setShowConfirmation(true, 'recruiter');
          setIsOpen(false);
          actions.resetForm();
        } else if (
          (res.data.status == 200 &&
            res.data.message.appStatusCode == "RC_SOC_EXST") ||
          res.data.message.appStatusCode == "RC_EXST"
        ) {
          setRecruiterError(res.data.message.appStatusDescription);
        } else {
          setRecruiterError(res.data.message.appStatusDescription);
        }
      })
      .catch((err) => {
        actions.setSubmitting(false);
        setError("Unexpected error");
      });
  }

  function DoClientSignup(values: any, actions: any) {
    SignupInitialVerification(values)
      .then((res) => {
        if (res.data.message.appStatusCode == "CL_SEML") {
          setShowConfirmation(true, 'client');
          setIsOpen(false);
          actions.resetForm();
        } else if (
          (res.data.status == 200 &&
            res.data.message.appStatusCode == "CL_SOC_EXST") ||
          res.data.message.appStatusCode == "CL_EXST"
        ) {
          setClientError(res.data.message.appStatusDescription);
        } else {
          setClientError(res.data.message.appStatusDescription);
        }
      })
      .catch((err) => {
        actions.setSubmitting(false);
        setError("Unexpected error");
      });
  }

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handlePopupClose}
        aria-labelledby="signupPopup-title"
        aria-describedby="signupPopup-description"
        className="dialog-blur"
      >
        <Paper elevation={5} style={{ backgroundColor: "#FFF" }}>
          <DialogTitle sx={{ m: 0, p: 2 }}>
            <IconButton
              data-testid="closeIcon"
              aria-label="close"
              onClick={handlePopupClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "#4F4F4F",
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  align="center"
                  className="text-heading-signup"
                >
                  Sign up
                </Typography>
              </Grid>
              
              <Grid item xs={12} className="grid-subheading-signup">
                <Typography
                  align="center"
                  className="text-subheading-signup"
                >
                  Do you want to sign up as { isClientModuleEnabled && "a client or"} a recruiter?
                </Typography>
              </Grid>
            </Grid>

            <Grid container className="grid-email-signup">
              <Grid item xs={12} sm={12} 
                className={`item-recruiter-signup ${itemSelected.recruiter && "item-selected"}`}
                data-testid="grid-recruiter"
                onClick={() => {
                  setItemSelected({recruiter: true, client: false});
                }}
              >
                <Grid container item alignItems="center">
                  <SvgIcon 
                    component={itemSelected.recruiter ? RecruiterSignupIcon_Selected : RecruiterSignupIcon}
                    viewBox="0 0 60 60"
                    className="icon-signup-option"
                  />
                  <Typography className="text-email-signup">
                    Sign up as a Recruiter
                  </Typography>
                  { itemSelected.recruiter &&
                    <SvgIcon 
                      component={SignupCheckIcon} 
                      viewBox="0 0 20 20"
                      className="icon-signup-check"
                    />
                  }
                </Grid>
                { itemSelected.recruiter &&
                  <Grid container item direction="column" className="form-email-signup">
                    <Typography className="text-email-label">
                      Enter your email id
                    </Typography>

                    <FormControl
                      variant="standard"
                      required
                      error={formikRecruiter.touched.email && Boolean(formikRecruiter.errors.email)}
                      fullWidth
                      margin="dense"
                    >
                      <TextField
                        name="email"
                        type="email"
                        variant="outlined"
                        placeholder="Email Id"
                        autoFocus
                        onChange={(e) => {
                          formikRecruiter.handleChange(e);
                          setRecruiterError("");
                          setError("");
                        }}
                        value={formikRecruiter.values.email}
                        autoComplete="off"
                        inputProps={{ 
                          "data-testid": "input-signup-email-recruiter",
                          "className": "input-email-signup"
                        }}
                      />
                      <ErrorMessage
                        fontSize={"14px"}
                        errorText={formikRecruiter.touched.email && formikRecruiter.errors.email}
                      />
                    </FormControl>
                    {recruiterError !== "" && <Alert severity="error">{recruiterError}</Alert>}
                  </Grid>
                }
              </Grid>
              { isClientModuleEnabled &&              
              <Grid item xs={12} sm={12} 
                className={`item-client-signup ${itemSelected.client && "item-selected"}`}
                data-testid="grid-client"
                onClick={() => {
                  setItemSelected({recruiter: false, client: true});
                }}
              >
                <Grid container item alignItems="center">
                  <SvgIcon 
                    component={itemSelected.client ? ClientSignupIcon_Selected : ClientSignupIcon}
                    viewBox="0 0 60 60"
                    className="icon-signup-option"
                  />
                  <Typography className="text-email-signup">
                    Sign up as a Client
                  </Typography>
                  { itemSelected.client &&
                    <SvgIcon 
                      component={SignupCheckIcon} 
                      viewBox="0 0 20 20"
                      className="icon-signup-check"
                    />
                  }
                </Grid>
                { itemSelected.client &&
                  <Grid container item direction="column" className="form-email-signup">
                    <Typography className="text-email-label">
                      Enter your email id
                    </Typography>

                    <FormControl
                      variant="standard"
                      required
                      error={formikClient.touched.email && Boolean(formikClient.errors.email)}
                      fullWidth
                      margin="dense"
                    >
                      <TextField
                        name="email"
                        type="email"
                        variant="outlined"
                        placeholder="Email Id"
                        autoFocus
                        onChange={(e) => {
                          formikClient.handleChange(e);
                          setClientError("");
                          setError("");
                        }}
                        value={formikClient.values.email}
                        autoComplete="off"
                        inputProps={{ 
                          "data-testid": "input-signup-email-client",
                          "className": "input-email-signup"
                        }}
                      />
                      <ErrorMessage
                        fontSize={"14px"}
                        errorText={formikClient.touched.email && formikClient.errors.email}
                      />
                    </FormControl>
                    {clientError !== "" && <Alert severity="error">{clientError}</Alert>}
                  </Grid>
                }
              </Grid>
              }
              <Grid container ml={2}>
                <Grid item mt={2}>
                  <RedirectStaffline />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} className="item-verification-btn">
                <Button
                  size="small"
                  fullWidth
                  variant="contained"
                  className="btn-verify"
                  type="submit"
                  endIcon={
                    (formikRecruiter.isSubmitting || formikClient.isSubmitting)
                    ? (
                      <CircularProgress size={"16px"} color={"inherit"} />
                    ) : (
                      ""
                    )
                  }
                  disabled={
                    (formikRecruiter.isSubmitting || formikClient.isSubmitting) ||
                    !(formikClient.values.email || formikRecruiter.values.email)
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    
                    if (itemSelected.recruiter) {
                      formikRecruiter.handleSubmit();
                    }
                    else if (itemSelected.client) {
                      formikClient.handleSubmit();
                    }
                  }}
                >
                  Send Verification Link
                </Button>
                {error !== "" && <Alert severity="error">{error}</Alert>}
              </Grid>
            </Grid>

            {(itemSelected.client || itemSelected.recruiter) &&
            <Grid container spacing={0} className="grid-social-signup">
              <Grid item xs={12} sm={12} className="item-social-divider">
                  <Divider className="text-social-divider">Or sign up with</Divider>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Grid container item justifyContent={"center"} columnGap={2}>
                  <GoogleButton handleAlreadyExist={setError} mode={itemSelected}/>
                  <LinkedInButton handleAlreadyExist={setError} mode={itemSelected}/>
                </Grid>
              </Grid>
            </Grid>
            }
          </DialogContent>
        </Paper>
      </Dialog>
    </div>
  );
};
export default SignupPopup;
