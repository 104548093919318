import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Checkbox, FormControlLabel, FormControl, IconButton, Typography } from '@mui/material';
import RecruitmentAssessmentForm from '../Form/RecruitmentAssessmentForm';
import CloseIcon from "@mui/icons-material/Close";
import { Asterisk } from '../../../../ui/Asterisk';
interface props {
  assessmentDetails: any;
  assesmentResponseValue:any;
  handleOnSubmit:(obj:any, isValid:boolean)=>void
  isScreeningCompleted:boolean;
  loading:boolean;
}

const RecruiterAssessmentPopup: React.FC<props> = ({
  assessmentDetails,
  assesmentResponseValue,
  handleOnSubmit,
  isScreeningCompleted,
  loading
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const _handleOnSubmit = (values:any, isValid:boolean)=>{
    handleOnSubmit(values,isValid)
    setOpen(false);
  }

  return (
    <div>
      <FormControl 
          component="fieldset"
          sx={{ marginBottom: 2 }}
        >
        <FormControlLabel
            disabled={loading}
            onClick={handleClickOpen}
            control={
              <Checkbox
              
                checked={isScreeningCompleted}
              />
            }
            label={ <Typography variant="caption" display="block" sx={{marginTop:0, color:"#000"}}>
                <Asterisk/> Profile Screening
            </Typography> }
        />
      </FormControl>      
    
      <Dialog open={!loading && open} maxWidth="md" fullWidth>          
           
        <DialogTitle sx={{ textAlign: "center", fontSize: "24px" }} className="custom-popup-txt">
          Profile Screening   
          <IconButton
            edge="end"
            color="inherit"
            className='screeningPopupClose'
            onClick={handleClose}
            aria-label="close"
            sx={{position:"absolute", right:24,top:12}}
          >
             <CloseIcon />
            </IconButton>       
        </DialogTitle>
        <DialogContent>
          <RecruitmentAssessmentForm
            assessmentDetails={assessmentDetails}
            assesmentResponseValue={assesmentResponseValue}
            handleOnSubmit={_handleOnSubmit}
          />
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RecruiterAssessmentPopup;
