import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { PermissionsEnums } from "../../../../data/permission";
import { JobOriginIds } from "../../../../models/Job/JobDetailsModel";
import { UserAuth } from "../../../../models/interfaces";
import { CandidateSubmissionStatus } from "../../../../models/submission/CandidateSubmissionMasterStatusesModel";
import { ModuleComponent } from "../../../Common/Permission/ModuleComponent";
import "../../NewViewSubmission/styles.scss";

interface InterviewRescheduleButtonProps {
  row: any;
  userRole: any;
  currentjobDetails: any;
  setPopup: any;
  hasActiveEvent: any;
}

export default function InterviewRescheduleButton({
  row,
  userRole,
  currentjobDetails,
  setPopup,
  hasActiveEvent
} : InterviewRescheduleButtonProps) {

  const shouldRenderRescheduleButton =
    !hasActiveEvent(row?.interviewAvailabilities || []) &&
    currentjobDetails?.jobOriginId === JobOriginIds.jobRialto &&
    (row.finalStatusId === CandidateSubmissionStatus.InterviewScheduled ||
      row.finalStatusId === CandidateSubmissionStatus.NextRoundOfInterview ||
      row.finalStatusId === CandidateSubmissionStatus.InterviewRescheduled ||
      row.finalStatusId === CandidateSubmissionStatus.InterviewCancelled) &&
    (userRole === UserAuth.Client || userRole === UserAuth.ClientTeamMember);

  const handleRescheduleButtonClick = () => {
    if (shouldRenderRescheduleButton) {
      const rescheduleType =
        row.finalStatusId === CandidateSubmissionStatus.InterviewScheduled ||
        row.finalStatusId === CandidateSubmissionStatus.InterviewRescheduled ||
        row.finalStatusId === CandidateSubmissionStatus.NextRoundOfInterview
          ? "isConfirmInterviewRescheduled"
          : "InterviewRescheduled";

      setPopup((popup: any) => ({ ...popup, [rescheduleType]: true }));
    }
  };

  return (
    <>
    {shouldRenderRescheduleButton && (
      <ModuleComponent moduleId={PermissionsEnums.InterviewReschedule}>
        <Grid item>
          <Button
            className="submission-action-btn"
            variant="contained"
            color="primary"
            onClick={handleRescheduleButtonClick}
          >
            Interview Reschedule
          </Button>
        </Grid>
      </ModuleComponent>
    )}
    </>
  );
}