import KeyboardDoubleArrowUpRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowUpRounded";
import { Grid } from "@mui/material";
import Fab from "@mui/material/Fab";
import React from "react";
export const GotoTop = () => {
  const [show, setshow] = React.useState(false);
  window.addEventListener("scroll", function () {

    if (this.scrollY > 100) {
      setshow(true);
    }
    else {
      setshow(false);
    }
  });

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", 
    });
  };

  return (
    <>
      {show && (
        <div
          style={{
            position: "fixed",

            right: "32px",

            zIndex: "50",
            bottom: "96px",
          }}
        >
          <Fab
            onClick={handleClick}
            color="primary"
            aria-label="add"
            data-testid={'btn-up'}
          >
            <Grid spacing={1}>
              <Grid item>
                <KeyboardDoubleArrowUpRoundedIcon />
              </Grid>
              <Grid style={{ textTransform: 'none', paddingTop: "0px", paddingBottom: "3px", marginTop:"-58%" }} item>
                Top
              </Grid>
            </Grid>
          </Fab>
        </div>
      )}
    </>
  );
};
