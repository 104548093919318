import AddIcon from "@mui/icons-material/Add";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { ThemeProvider, styled } from "@mui/material/styles";
import React from "react";
import staticPageTheme from "../../Shared/theme/staticPageTheme";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "transparent",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary,
    boxShadow: "none",
  }));
export const FaqContent = () => {
    const [expanded, setExpanded] = React.useState("panel1");
    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };
  
  return (
    <ThemeProvider theme={staticPageTheme}>
    <Grid container spacing={3} sx={{ justifyContent: "space-around" }}>
    <Grid item xs={12} md={8}>
      <Item elevation={0}>
        <Typography
          variant="h2"
          component="div"
          className="abel abelh2 letter-spacing-normal"
          sx={{ paddingBottom: "30px",fontSize:{xs:"28px !important",sm:"36px !important",md:"42px !important"}  }}
        >
          {/* Frequently Asked Questions: */}
        </Typography>
        <Box className="faq-accordion">
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<AddIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Is this a job board?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{fontFamily:'verdana'}} variant="body2">
                No. This is not a job board. This is an independent
                recruiter marketplace where you get access to currently
                open jobs from various providers such as consulting
                companies, sometimes directly from the client.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<AddIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>
                What is the difference between a job board and this
                marketplace?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
              Job boards typically list jobs that are directed at the candidates that are looking for a job. JobRialto works exclusively with recruiters and provides them an avenue to source the right candidates through their own network and past acquaintances and submit them to open jobs.<br></br> The candidates submitted for any job listed on JobRialto are always aligned with a recruiter and all coordination with the candidate is fulfilled by the recruiter the represents the candidate.


              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<AddIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography>
                How do I submit a candidate?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
              JobRialto marketplace lists active job openings on a real-time basis from various sources. Recruiters who are registered and approved on JobRialto can browse through these listings, select a job and submit interested and available candidates from their network or acquaintance that they feel matches the experience and skill required. 
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              expandIcon={<AddIcon />}
              aria-controls="panel4a-content"
              id="panel4a-header"
            >
              <Typography>
                Who owns the right to represent for the submitted candidates?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
              For every candidate submission, the recruiter who submits a candidate first in JobRialto will have the exclusivity to present and benefit from that submission, on a job-to-job basis. But the same candidate can be submitted by another recruiter for a different job if that recruiter was the first to present the candidate against that job.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <AccordionSummary
              expandIcon={<AddIcon />}
              aria-controls="panel5a-content"
              id="panel5a-header"
            >
              <Typography>
              As a recruiter, is there any fee to participate in this marketplace?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
              During the introductory period, there is no fee for signing up, browsing jobs, or to submit candidates against job listings. And you will be compensated as per JobRialto’s terms for each candidate hired through your submissions. It will also remain free to any recruiter who signs up during the introductory period, stays engaged, and participates regularly. However, in the future, JobRialto might offer paid premium services such as searching through our partners’ resume banks to source candidates, and access to other ATS tools such as having a dedicated phone number etc. Such premium services will be introduced as we add recruiting tools in the future.
              </Typography>
            </AccordionDetails>
          </Accordion>
          
          <Accordion
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
          >
            <AccordionSummary
              expandIcon={<AddIcon />}
              aria-controls="panel6a-content"
              id="panel6a-header"
            >
              <Typography>
                Can another recruiter submit the same candidate for the same job?
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
              No. The recruiter who first presents a candidate will have the exclusivity for that candidate for that job. Duplicate candidate submissions will be rejected by the JobRialto marketplace.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel7"}
            onChange={handleChange("panel7")}
          >
            <AccordionSummary 
              expandIcon={<AddIcon />}
              aria-controls="panel7a-content"
              id="panel7a-header"
            >
              <Typography>
              What happens after a submission?                      
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
              Every candidate submission goes through a series of steps starting with being forwarded to the account manager who represents the client that has the job opening, followed by acceptance or rejection by the client after one or more interviews leading up to a final offer or rejection from the client. The progress is clearly displayed on the “View Submissions” section of the JobRialto marketplace in every recruiter’s dashboard and is a fully transparent process.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel8"}
            onChange={handleChange("panel8")}
          >
            <AccordionSummary
              expandIcon={<AddIcon />}
              aria-controls="panel8a-content"
              id="panel8a-header"
            >
              <Typography>
              Who will be the employer for the candidates that get confirmed?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
              Depends on the nature of the job posting. Candidates confirmed for full-time or direct hire positions will be employed by the client. Candidates for contingent jobs can either be placed in a contract with their current employer, or JobRialto, or be confirmed as an independent contractor with the party that owns the client relationship. Every job posting will clearly display the acceptable employment or contract terms.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel9"}
            onChange={handleChange("panel9")}
          >
            <AccordionSummary
              expandIcon={<AddIcon />}
              aria-controls="panel9a-content"
              id="panel9a-header"
            >
              <Typography>As a recruiter, am I expected to pre-screen candidates before submission?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
              Yes, absolutely. The basic expectation is that the recruiter, do the necessary due diligence to make sure the candidate is ideally suited for the job opening. This includes getting a Q{"&"}A with the candidates for self-assessment to validate skill requirements for the job, while also ensuring that the candidate is interested, available for submission, and commits to a negotiated pay-rate, agrees to let the recruiter represent the candidate with JobRialto, after fully making the candidate aware of work location, hours and duration of the assignment, and work authorization requirements.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel10"}
            onChange={handleChange("panel10")}
          >
            <AccordionSummary
              expandIcon={<AddIcon />}
              aria-controls="panel10a-content"
              id="panel10a-header"
            >
              <Typography>
              How do I provide the candidate with an assurance to trust and work with me as a recruiter?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
              First and foremost, the recruiters are encouraged to be upfront with the candidate that they are a talent recruiter representing JobRialto, a trusted source of 1000s of active requirements from around the world, including USA and Canada. Provide them the details of the job description, the skills expected, the location of the job, and discuss their pay rate expectation. Let them know that it is a completely transparent process, and they will be made aware of all details at every step of the way, and they can always reach out to Candidate Support if they seek more information about JobRialto.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            data-testid="faq-accordion"
            expanded={expanded === "panel11"}
            onChange={handleChange("panel11")}
          >
            <AccordionSummary
              expandIcon={<AddIcon />}
              aria-controls="panel11a-content"
              id="panel11a-header"
            >
              <Typography>
              Do I discuss compensation with the candidates?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
              JobRialto does show a suggested maximum pay rate for a job that is visible to the recruiters. The candidates will not be able to see the pay rates, as the final pay rate is subject to negotiation between JobRialto the client, account manager, the recruiter and the candidate. The final pay rate can vary based other aspects such as candidate’s skills and experience, and the recruiter’s ability to negotiate the best pay rate possible. Be aware that the commission as a recruiter significantly increases the more when the candidate is negotiated down from the maximum pay rate. But it is critical that the recruiter make it very clear to the candidate that it is subject to negotiation as the process moves forward with the client. The actual compensation is confirmed only after the candidate is interviewed, selected, confirmed and an agreement is signed.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel12"}
            onChange={handleChange("panel12")}
          >
            <AccordionSummary
              expandIcon={<AddIcon />}
              aria-controls="panel12a-content"
              id="panel12a-header"
            >
              <Typography>
              Am I allowed to further share the job details on social media and my website?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
              Depends, primarily the responsibility rests with the recruiter to make sure that they clearly mention that these jobs are sourced from JobRialto and that they represent candidates as a talent recruiter in JobRialto. JobRialto will help the recruiter generate a unique link that leads to the job details on JobRialto and will also automatically identify that the referral came through the recruiter’s link and JobRialto will ensure that the candidate will be encouraged to work with the recruiter directly during the process. JobRialto has all the necessary technology in place to track such referrals and make sure the recruiter is compensated as per the terms of engagement for successful placements. <br></br><br></br>
              However, the terms of engagement with JobRialto clearly prohibit the recruiter from publishing these jobs on a public job board or other job sites on the internet. The recruiter can publish these only in their own social media profiles or on a website that they manage directly.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel13"}
            onChange={handleChange("panel13")}
          >
            <AccordionSummary
              expandIcon={<AddIcon />}
              aria-controls="panel13a-content"
              id="panel13a-header"
            >
              <Typography>
              Will JobRialto help me source candidates?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
              Candidates that are registered on JobRialto are previously assigned to the recruiters who submitted them. So JobRialto currently doesn’t house a bank of candidate resumes that a recruiter can search from. It may be a premium feature that will be added in the future. But at this time, the recruiters are expected to source their own candidates.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel14"}
            onChange={handleChange("panel14")}
          >
            <AccordionSummary
              expandIcon={<AddIcon />}
              aria-controls="panel14a-content"
              id="panel14a-header"
            >
              <Typography>
              Can another recruiter submit a candidate I previously submitted for another job?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
              Yes. The exclusivity between the recruiter and the candidate is ensured on a per job basis. So, whoever presents the candidate first to any job is mapped against the recruiter who presents the candidate. However, the same candidate if not presented for another job by the original recruiter can be presented by any recruiter. The responsibility of ensuring the right to represent the candidate is expected to be secured by the recruiter directly with the candidate on a job-to-job basis.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel15"}
            onChange={handleChange("panel15")}
          >
            <AccordionSummary
              expandIcon={<AddIcon />}
              aria-controls="panel15a-content"
              id="panel15a-header"
            >
              <Typography>
              What happens if the same candidate has already been presented to the client through alternate sources?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
              Just like the exclusivity for the first submission, the client does have exclusivity with such candidates if they are already in possession of a candidate submission through an alternate source. In such cases, the submission will be rejected with the appropriate feedback detailing why the submission was rejected.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel16"}
            onChange={handleChange("panel16")}
          >
            <AccordionSummary
              expandIcon={<AddIcon />}
              aria-controls="panel16a-content"
              id="panel16a-header"
            >
              <Typography>
              If I source a candidate who is looking for a job, will JobRialto automatically recommend a suitable job?                      </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
              JobRialto marketplace is continually improving and adding features on an ongoing basis leveraging AI/ML and other capabilities as the platform evolves. In the near future, JobRialto will be able to match a potential candidate with a suitable job and make recommendations to the recruiters who most recently submitted any candidate to pursue and present the same candidate for a potential job opening subject to the candidate’s availability.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel17"}
            onChange={handleChange("panel17")}
          >
            <AccordionSummary
              expandIcon={<AddIcon />}
              aria-controls="panel17a-content"
              id="panel17a-header"
            >
              <Typography>
              Will JobRialto recommend jobs well suited to my area and industry of expertise?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography  variant="body2">
              During registration as a recruiter on the JobRialto marketplace, the sign-up process will prompt to specify preferences in terms of industries, types of jobs, locations and other information.<br></br>
              Using this information, JobRialto will help map jobs that match the recruiter’s preferences. Based on these the recruiter can save jobs and submit candidates against them, JobRialto’s AI based algorithms continuously learn based on the recruiter’s activity and progressively improve matches and identify the most appropriate job postings that best match past successes of the recruiter.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel18"}
            onChange={handleChange("panel18")}
          >
            <AccordionSummary
              expandIcon={<AddIcon />}
              aria-controls="panel18a-content"
              id="panel18a-header"
            >
              <Typography>
              Are international recruiters allowed to participate on JobRialto marketplace?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
              Independent recruiters with prior recruiting experience from any country, who can submit to the necessary legal requirements as required by JobRialto, can register so long as they are able to service the geography where the jobs and candidates are located. All recruiters go through a verification and approval process before they can actively engage in the marketplace and submit candidates.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel19"}
            onChange={handleChange("panel19")}
          >
            <AccordionSummary
              expandIcon={<AddIcon />}
              aria-controls="panel19a-content"
              id="panel19a-header"
            >
              <Typography>
              What are the legal requirements for a recruiter to participate in JobRialto marketplace?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
              The requirements can vary based on the country where the recruiter is located. In general, it is expected that the recruiter, after necessary background verification, due diligence, and approval, can provide information needed by JobRialto such as a local tax identification number and a bank account in their country of presence.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel20"}
            onChange={handleChange("panel20")}
          >
            <AccordionSummary
              expandIcon={<AddIcon />}
              aria-controls="panel20a-content"
              id="panel20a-header"
            >
              <Typography>
              How do I get compensated for my submissions?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
              A compensation amount, referred across this platform as “Commission”, is displayed to the recruiter while submitting a candidate against a job. The submission page also features a calculator that clearly shows how the compensation may vary as the pay rate is changed. <br></br> <br></br>
              For contingent positions the Commission amount is calculated based on each hour worked by the candidate for the duration of the assignment. <br></br> <br></br>
              For Direct Hire or Full-time positions, the Commission amount is displayed as a one-time fee that is paid as a lump sum for the placement once the guarantee period is successfully completed. Such guarantee periods can vary from job to job and will be clearly communicated during submission.<br></br> <br></br>
              For Right to Hire positions, an hourly commission will be due to the recruiter during the contract period with a final lump sum fee at the time of conversion to a full-time employment (if applicable on a job-to-job basis). <br></br><br></br>
              The exact amount of Commission, whether it’s ongoing or the lump sum fee, is confirmed and displayed on the recruiter dashboard for each candidate that was successfully placed using JobRialto. 
              </Typography>
            </AccordionDetails>
          </Accordion>
         
        </Box>
      
      </Item>
     
    </Grid>
   
  </Grid>
  </ThemeProvider>
  )
}
