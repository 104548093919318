import React from 'react';
import Grid from '@mui/material/Grid';
import LabelChip from '../LabelChip';
import SectionHeading from '../../../../ui/Form/Heading/SectionHeading';
import { Divider } from '@mui/material';

interface KeySkillsProps {
  skills: string[];
}

const ScorecardKeySkills: React.FC<KeySkillsProps> = ({ skills }) => {
  
  return (
    <Grid container spacing={2}>
        <Grid  item xs={12} >
            <SectionHeading
                title='Key Skills'
            />
             <Divider className="borderedDivider-color" />
        </Grid>
            
        {skills.map((skillName) => (
            <Grid item key={skillName}>            
            <LabelChip                 
                label={skillName}
            />   
            </Grid>        
            ))}
    </Grid>
  );
};

export default ScorecardKeySkills;
