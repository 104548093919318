import { createSlice } from "@reduxjs/toolkit";
import { CandidateListModel } from "../../models/Candidate/CandidateListModel";

const initialState: CandidateListModel = {
  recruiterId: "",
  searchKeywords: "",
  location: [],
  experience: [],
  workAuthorizationStatuses: [],
  skills: [],
  payRate: "",
  contractStatus: [],
  pageIndex: 1,
  pageSize: 20,
  sortColumn: "",
  sortOrder: "Descending",
  isSorted: true,
  totalRecord: 0,
  candidateGuids: [],
  recruiterGuids: [],
};

const CandidateListStateSlice = createSlice({
  name: "CandidateListStateSlice",
  initialState,
  reducers: {
    setCandidateListState: (state, action: any) => {
      return action.payload;
    },
    resetCandidateListState: () => {
      return initialState;
    },
  },
});

export const { setCandidateListState, resetCandidateListState } =
  CandidateListStateSlice.actions;

export default CandidateListStateSlice.reducer;
