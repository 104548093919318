import { ContractsMenuIcon } from "../../components/Common/ContractsMenuIcon";
import { CandidatesAdminMenuIcon } from "../../components/Common/Icons/CandidatesAdminMunuIcon";
import { DashboardMenuIcon } from "../../components/Common/Icons/DashboardMenuIcon";
import { EarningsAdminMenuIcon } from "../../components/Common/Icons/EarningsAdminMenuIcon";
import { JobsAdminMenuIcon } from "../../components/Common/Icons/JobsAdminMenuIcon";
import { RecruiterAdminMenuIcon } from "../../components/Common/Icons/RecruiterAdminMeuIcon";
import { SubmissionsMenuIcon } from "../../components/Common/Icons/SubmissionsMenuIcon";
import circleOkIcon from "../../assets/images/circle-ok.svg";
import { AllRecruiterIcon } from "../../components/Common/Icons/AllRecruiterIcon";
import { IndividualRecruiterIcon } from "../../components/Common/Icons/IndividualRecruiterIcon";
import PersonIcon from "@mui/icons-material/Person";
import { PermissionsEnums } from "../permission";
import { ClientMenuIcon } from "../../components/Common/Icons/ClientMenuIcon";
import { getIsClientModuleEnabled } from "../../utils/AppConfig";

const isClientModuleEnabled = getIsClientModuleEnabled();

export const superAdminNavItems = [
  {
    id: 1,
    orderId:1,
    name: "dashboard",
    label: "Dashboard",
    link: "/dashboard",
    icon: <DashboardMenuIcon />,
    permissionId: PermissionsEnums.Dashboard_Admin,
    sub: [],
  },
  {
    id: 2,
    orderId:2,
    name: "recruiters",
    label: "Recruiters",
    link: "/recruiters",
    permissionId: PermissionsEnums.Recruiters_View,
    icon: <RecruiterAdminMenuIcon />,
    sub: [],
  },
  {
    id: 3,
    orderId:3,
    name: "admins",
    label: "Admins",
    link: "/admin/admins/all",
    permissionId: PermissionsEnums.Admins_View,
    icon: <RecruiterAdminMenuIcon />,
    sub: [],
  },

  {
    id: 4,
    orderId:6,
    name: "useraccess",
    label: "User Access",
    link: "/role/manage",
    permissionId: PermissionsEnums.Role_Management_Page,
    icon: <RecruiterAdminMenuIcon />,
    sub: [],
  },
  {
    id: 5,
    orderId:7,
    name: "jobs",
    label: "Jobs",
    link: "/jobs/all",
    permissionId: PermissionsEnums.Jobs_View,
    icon: <JobsAdminMenuIcon />,
    sub: [],
  },
  {
    id: 6,
    orderId:8,
    name: "submissions",
    label: "Submissions",
    link: "/submission",
    permissionId: PermissionsEnums.Submissions_All,
    icon: <SubmissionsMenuIcon />,
    sub: [
      {
        id: 6.1,
        name: "Active",
        label: "Active",
        link: "/submission/active",
        permissionId: "",
        icon:  <img src={circleOkIcon} alt="circleOkIcon" />,
      },
      {
        id: 6.2,
        name: "Archived",
        label: "Archived",
        link: "/submission/archived",
        permissionId: "",
        icon:  <img src={circleOkIcon} alt="circleOkIcon" />,
      },
    ],
  },
  {
    id: 7,
    orderId:9,
    name: "candates",
    label: "Candidates",
    link: "/candidates",
    permissionId: PermissionsEnums.All_Candidates_View,
    icon: <CandidatesAdminMenuIcon />,
    sub: [],
  },
  {
    id: 8,
    orderId:10,
    name: "Contracts",
    label: "Contracts",
    link: "/contracts",
    permissionId: PermissionsEnums.Contracts_View,
    icon: <ContractsMenuIcon />,
    sub: [],
  },
  {
    id: 9,
    orderId:11,
    name: "Earnings",
    label: "Earnings",
    link: "/earnings",
    permissionId: PermissionsEnums.Earnings_View,
    icon: <EarningsAdminMenuIcon />,
    sub: [],
  },
  {
    id: 10,
    orderId:12,
    name: "Compensation",
    label: "Compensation",
    link: "/Manage Compensation",
    permissionId: PermissionsEnums.Compensation_View,
    icon: <SubmissionsMenuIcon />,
    sub: [
      {
        id: 10.1,
        name: "All",
        label: "All",
        link: "/compensation/allrecruiter",
        permissionId: "",
        icon:   <AllRecruiterIcon />,
      },
      {
        id: 10.2,
        name: "Individual",
        label: "Individual",
        link: "/compensation/all",
        permissionId: "",
        icon:   <PersonIcon />,
      },
      {
        id: 10.3,
        name: "Custom",
        label: "Custom",
        link: "/compensation/individualrecruiter",
        permissionId: "",
        icon:   <IndividualRecruiterIcon />,
      },
    ],
  },
  
];

if(isClientModuleEnabled){
  superAdminNavItems.push(
    {
      id: 12,
      orderId:4,
      name : "clients",
      label: "Clients",
      link: "/clients",
      permissionId : PermissionsEnums.CLUserView,
      icon :  <ClientMenuIcon />,
      sub: []
   },
    {
      id: 11,
      orderId:5,
      name : "manageclient",
      label: "Manage Client",
      link: "/manageclient",
      permissionId : PermissionsEnums.Manage_CLUser,
      icon :  <RecruiterAdminMenuIcon />,
      sub: []
   },
  );
}
