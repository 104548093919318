import React from 'react';
import {
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from '@mui/material'; 
import LoginIcon1 from "./../../../../assets/images/next-step-icon-1.svg";
import LoginIcon2 from "./../../../../assets/images/next-step-icon-2.svg";
import LoginIcon3 from "./../../../../assets/images/next-step-icon-3.svg";
import LoginIcon4 from "./../../../../assets/images/next-step-icon-4.svg";

const RecruiterSignUpStepBanner: React.FC = () => {
  return (
    <>
        <Typography
            variant="h2"
            color="inherit"
            component="span"
            className="abelh2 letter-spacing-normal"
        >
            Next Steps
        </Typography>

        <List className="signup-next-steps">
            <ListItem>
            <ListItemAvatar>
                <Avatar>
                <img src={LoginIcon1} alt="" />
                </Avatar>
            </ListItemAvatar>
            <ListItemText>
                <Typography component="span" className="title">
                Complete Sign Up
                </Typography>
                <Typography component="span" className="txt">
                <br></br>Fill this form to complete your profile
                </Typography>
            </ListItemText>
            </ListItem>
            <ListItem>
            <ListItemAvatar>
                <Avatar>
                <img src={LoginIcon2} alt="" />
                </Avatar>
            </ListItemAvatar>
            <ListItemText>
                <Typography component="span" className="title">
                Get Validated
                </Typography>
                <Typography component="span" className="txt">
                <br></br>Our Recruiter Support Team will contact you and complete
                onboarding formalities
                </Typography>
            </ListItemText>
            </ListItem>

            <ListItem>
            <ListItemAvatar>
                <Avatar>
                <img src={LoginIcon3} alt="" />
                </Avatar>
            </ListItemAvatar>
            <ListItemText>
                <Typography component="span" className="title">
                Get Approved
                </Typography>
                <Typography component="span" className="txt">
                <br></br>You will sent a notification once your onboarding is
                complete on JobRialto Marketplace
                </Typography>
            </ListItemText>
            </ListItem>

            <ListItem>
            <ListItemAvatar>
                <Avatar>
                <img src={LoginIcon4} alt="" />
                </Avatar>
            </ListItemAvatar>
            <ListItemText>
                <Typography component="span" className="title">
                Come back and Start Making Money
                </Typography>
                <Typography component="span" className="txt">
                <br></br>Once approved you will have full access to open jobs,
                you can submit your candidates, fulfill placements in
                coordination with our back office team and start making
                commissions on confirmed candidates
                </Typography>
            </ListItemText>
            </ListItem>
        </List>
    </>
  );
};

export default RecruiterSignUpStepBanner;
