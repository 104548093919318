import CloseIcon from "@mui/icons-material/Close";
import { DialogTitle } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import HelloSign from 'hellosign-embedded';
import React, { useEffect, useState } from "react";
import { InitiateContract } from "../../../../../Services/clientService";
import { ClientInitiateContractResponse } from "../../../../../models/ClientInitiateContractResponse";

interface Props {
    isOpen: boolean;
    handleClose: () => void;
    companyName: string;
    companyEmail: string;
    formik : any;
}
const ClientContractPopup: React.FC<Props> = ({ isOpen, handleClose, companyName, companyEmail, formik}) => {

    const [contractResponse, setContractResponse] = useState<ClientInitiateContractResponse>();

    useEffect(() => {
        if (companyName !== "" && companyEmail !== "") {
            InitiateContract({
                companyName: companyName,
                email: companyEmail
            }).then((response) => {
                if (response?.data?.message?.appStatusCode === "CL_RSF") {
                    setContractResponse(response?.data?.entity);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    }, []);

    useEffect(() => {
        if (contractResponse !== undefined) {
            const client = new HelloSign({
                clientId: process.env.REACT_APP_HELLOSIGN_CLIENT_ID
            });

            client.open(contractResponse.signUrl, {
                clientId: process.env.REACT_APP_HELLOSIGN_CLIENT_ID,
                skipDomainVerification: true,
                container: document.getElementById('contract-div')
            });
            
            client.on('sign', (data: any) => {
                formik.setFieldValue("contractConfirm", true);
                formik.setFieldValue("signatureRequestId", contractResponse.signatureRequestId);
                formik.setFieldValue("signatureId", contractResponse.signatureId);
            });

            // called only on canceling without completion
            client.on('cancel', (data: any) => {
                formik.setFieldValue("contractConfirm", false);
                formik.setFieldValue("signatureRequestId", "");
                formik.setFieldValue("signatureId", "");
            });

            // called on closing iframe, both after completing sign and by canceling early
            client.on('close', (data: any) => {
                handleClose();
            });
        }
    }, [contractResponse]);

    return (
        <>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                fullWidth
                maxWidth="lg"
            >
                <DialogTitle sx={{my:1, mx:3, p:0}}>
                <span className="">Initiate Contract</span>
                <IconButton
                    aria-label="Close Contract"
                    onClick={() => handleClose()}
                    sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                    }}
                    data-testid="close-contract-btn"
                >
                    <CloseIcon />
                </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <div id='contract-div' data-testid="contract-div"></div>
                </DialogContent>
            </Dialog>
        </>
    );
};
export default ClientContractPopup;
