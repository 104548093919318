import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
    blur?: boolean;
  }
  
  const useStyles = makeStyles((theme) => ({
    backdrop: (props: Props) => ({
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
      backdropFilter: props.blur ? 'blur(1px)' : 'none',
      WebkitBackdropFilter: props.blur ? 'blur(1px)' : 'none',
      backgroundColor: props.blur ? 'rgba(255, 255, 255, 0.5)' : 'transparent',
    }),
    center: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    },
  }));
  
  const LoadingScreen: React.FC<Props> = ({ blur = false }) => {
    const classes = useStyles({ blur });
  
    return (
      <Backdrop data-testid="loading-backdrop" className={classes.backdrop} open={true}>
        <div className={classes.center}>
          <CircularProgress color="primary" />
        </div>
      </Backdrop>
    );
  };
  
  export default LoadingScreen;