import * as React from "react";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import {
  Card,
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { CheckBoxSquareIcon } from "../../Common/CheckBoxSquareIcon";
import { KeyValue } from "../../../models/app/AppModels";

const Wrapper = styled("div")({
  fontFamily: "Roboto !important",
});

interface AppStatusFilterToggleProps {
  anchorEl: any;
  onClose: any;
  statues: KeyValue[];
  selectedStatuses: KeyValue[];
  onStatusChange: (statuses: KeyValue[]) => void;
}

export interface AppStatusFilterToggleRef {
  handleClearAll: () => void;
}

const AppStatusFilterToggle = (
  props: React.PropsWithChildren<AppStatusFilterToggleProps>, 
  ref: React.ForwardedRef<AppStatusFilterToggleRef>
) => {
  const { anchorEl } = props;
  const open = Boolean(anchorEl);
  const [selectedStatuses, setSelectedStatuses] = React.useState(
    props.selectedStatuses
  );

  const handleStatusChange = (e: any, status: KeyValue) => {
    let isChecked = e.target.checked;
    if (isChecked) {
      setSelectedStatuses([...selectedStatuses, status]);
    } else {
      setSelectedStatuses(
        selectedStatuses.filter((item) => item.value !== status.value)
      );
    }
  };

  React.useEffect(() => {
    props.onStatusChange(selectedStatuses);
  }, [selectedStatuses]);

  const handleClearStatus = () =>{
    setSelectedStatuses([]);
  }

  const handleClearAll = () => {
    handleClearStatus();
  };
  
  React.useImperativeHandle(ref, () => ({
    handleClearAll,
  }));

  return (
    <Wrapper>
      <Popper id={"id"} open={open} anchorEl={anchorEl}>
        <ClickAwayListener onClickAway={props.onClose}>
          <Card>
            <Box
              sx={{
                flexGrow: 1,
                bgcolor: "#FAFAFA",
                display: "flex",
              }}
            >
              <Grid container p={2}>
                <Grid item md={6}>
                  <Typography fontWeight={"bold"} className="roboto-font">
                    Status
                  </Typography>
                </Grid>
                <Grid item xs={6} className="closeArea">
                  <IconButton
                    aria-label="close"
                    size="small"
                    className="close-Btn"
                    onClick={props.onClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                bgcolor: "white",
                display: "flex",
                maxHeight: 386,
                overflowY: "auto",
              }}
            >
              <Grid container style={{ width: 223 }} p={2}>
                <Grid item xs={12} md={12}>
                  <Grid container>
                    <Grid item pt={1} pb={0.5}>
                      <FormControlLabel
                        onClick={handleClearStatus}
                        control={
                          <CheckBoxSquareIcon
                            style={{ paddingRight: "5px" }}
                          ></CheckBoxSquareIcon>
                        }
                        label={
                          <Typography fontSize={14} className="roboto-font">
                            Clear All
                          </Typography>
                        }
                        className="candidates-label"
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          paddingLeft: 0,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{ maxHeight: 300, overflowY: "auto" }}
                >
                  <MenuList style={{ paddingTop: "0px" }}>
                    <MenuItem
                      sx={{
                        paddingLeft: "0px",
                        paddingBottom: "0px",
                        minHeight: "auto",
                      }}
                    ></MenuItem>
                    {props.statues?.map((item, index) => {
                      return (
                        <MenuItem
                          key={ item?.value ?? index}
                          sx={{
                            paddingLeft: "0px",
                            paddingBottom: "0px",
                            minHeight: "auto",
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(e) => handleStatusChange(e, item)}
                                name={item.value}
                                sx={{ padding: "0px", fontSize: "14px" }}
                                checked={selectedStatuses.includes(item)}
                              />
                            }
                            label={
                              <Typography fontSize={14} className="roboto-font">
                                {item.label}
                              </Typography>
                            }
                            className="candidates-label roboto-font"
                            style={{ paddingLeft: 0 }}
                          />
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </ClickAwayListener>
      </Popper>
    </Wrapper>
  );
};


export default React.forwardRef(AppStatusFilterToggle);