import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Card, CardContent } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { UserAuth } from "../../../models/interfaces";
import FulFilledBySkillChart from "./FulFilledBySkillChart";

export default function ScoreBySkills({data,userType}) {
  let fullFill = data
  const [fullFullBySkills, setfullFullBySkills] = useState(fullFill)
  const [index, setIndex] = useState(0)

  useEffect(() => {
    if (data) {
      setfullFullBySkills(data);
    }
  }, [data])

  const increment = ()=> {
    let total = fullFullBySkills?.length - 1;
    let counter = index==total? 0 : index+1
    setIndex(counter)
  }
  const decrement = ()=> {
    let total = fullFullBySkills?.length - 1;
    let counter = index==0? total : index-1
    setIndex(counter)
  }
  return (
    <Card
      sx={{ height: "100%", paddingTop: "0px", paddingLeft: "0px" }}
      className="card-custom-shadow"
    >
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
          <Grid
            item
            sx={{
              padding: 0,
              width: "100%",
              height: "320px",
            }}
          >
            <Box>
              <Box
                sx={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}
              >
                <Typography
                  variant="caption2"
                  sx={{ fontSize: "14px", fontWeight: "600" }}
                >
                 {
                 (userType === UserAuth.Recruiter || userType === UserAuth.Client || userType === UserAuth.ClientTeamMember) 
                 ? "Score by Skills" 
                 : "Jobs Fulfilled by Skills"
                 }
              
                </Typography>
               
              </Box>
                {/* Navigation arrow  display on when data is present */}
                { data?.length > 0  && 
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      paddingTop:'15px'
                    }}
                  >
                    <ChevronLeftIcon data-testid="chevronLeftIcon" onClick={decrement} />
                    {fullFullBySkills?.[index]?.jobTitle}
                    <ChevronRightIcon data-testid="chevronRightIcon" onClick={increment} />
                  </Box>
                }
              <Box
                className="scorebyskills-chart"
                sx={{
                  paddingTop: "0px",
                  display: "flex",
                  justifyContent: `${data?.length > 0 ? 'center' : 'left' }`,
                  height: "250px",
                  paddingLeft: "0px",
                  marginTop: "0px",
                  marginLeft: "0px",
                }}
              >
              {
              fullFullBySkills!==undefined && data?.length > 0 
                ?
                  <FulFilledBySkillChart key={index} data={fullFullBySkills?.[index]} />
                : <Typography mt={2}>No data found</Typography> 
              } 
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
