import React, { useEffect } from 'react'

import { Autocomplete, Chip, CircularProgress, TextField } from '@mui/material'
import useApi from '../../hooks/useApi'
import { GetRecruiters } from '../../Services/recruiterService'
import constructName from '../../utils/constructName'
import { RecruiterStatus } from '../../models/interfaces'

export const RecruiterAutocomplete = (props) => {
    const [filter, setFilter] = React.useState({
        pageIndex: 1,
        pageSize: 10,
        sortColumn: "DateSignedUp",
        sortOrder: "Descending",
        isSorted: true,
        totalRecord: 0,
        searchKeywords: "",
        status: [RecruiterStatus[1]],
        filterByMasterData: {
          locationFilterInputs: [],
          domainFilterInputs: [],
          industryFilterInputs: [],
          skillsFilterInputs: [],
        },
      });

    const onIndustryInputChange = (e, value) => {
        
        setFilter({ ...filter, searchKeywords: value });
    }
    let recruiter = props.recruiter //formik.values.industries
    const allIndustries = useApi(() => GetRecruiters(filter));
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            allIndustries.request();
           
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [filter.searchKeywords])

    const getInputLabel = (option) => {
        const fullName = constructName(option.firstName, option.middleName, option.lastName);
        const emailSuffix = props?.showEmail && option.email ? ` (${option?.email})` : '';
      
        return `${fullName}${emailSuffix}`;
      };

      const renderInputPlaceholder = () => {
        if (props.disableLabel) {
          return null;
        } else if (props?.label) {
          return props.label;
        } else {
          return "Type Name Here";
        }
      };
      
      const renderInputRequired = () => {
        return props?.isRequired !== undefined ? props.isRequired : true;
      };
    return (
        <Autocomplete
            freeSolo={props.multiple} //remove dropdown arrow
            autoComplete="off"
            noOptionsText={allIndustries?.loading ? 'Loading...' : "No options"}
  
            multiple={props.multiple}
            readOnly={props.readOnly}
            id="size-small-standard-multi3"
            size="medium"
            onInputChange={onIndustryInputChange}
            onBlur={props.onBlur}

            onChange={props.onChange}
            options={allIndustries?.data?.entityList || []}            
            value={recruiter || ''}
            defaultValue={recruiter}
            getOptionLabel={(option) => getInputLabel(option)}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        key={getInputLabel(option) ?? index}
                        color="primary"
                        size="medium"
                        label={getInputLabel(option)}
                        {...getTagProps({ index })}
                    />
                ))
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder='Search by Name or Email'
                    variant="standard"
                    label={renderInputPlaceholder()}
                    required={renderInputRequired()}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {allIndustries?.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {recruiter && params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}

                />
            )}
        />
    )
}
RecruiterAutocomplete.defaultProps = {
 
    showEmail: false,
  
  }
  
