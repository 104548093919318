import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import React from "react";
import { addAdmin } from "../../../Services/adminService";
import { AdminUserModel } from "../../../models/AdminUserModel";
import { BreadCrumbItem } from "../../../models/AppModels";
import { getNevLink } from "../../../utils/getFileFromUrl";
import AppBreadCrumb from "../../Common/AppBreadCrumb";
import SnackbarPopup from "../../Common/Popup/snackbar/SnackbarPopup";
import { CustomAlert } from "../../ui/CustomAlert";
import { UserForm } from "./UserForm";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

const AddUser: React.FC = () => {
  const [pageStatus, setPageStatus] = React.useState({
    isSuccess: false,
    isError:false,
    error: "",
    loading: false,
    message: "",
  });
  const [uniQue, setuniQue] = React.useState(Date.now());

  const initialValue: AdminUserModel = {
    id: "",
    userId: "",
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  };

  let DefaultBreadcrumbs: BreadCrumbItem[] = [
    {
      isLeaf: false,
      sequence: 1,
      title: "Dashboard",
      to: getNevLink("/dashboard"),
    },
    {
      isLeaf: false,
      sequence: 2,
      title: "Admins",
      to: getNevLink("/admin/admins/all"),
    },
    {
      isLeaf: true,
      sequence: 3,
      title: "Add Admin",
      leafClasses: " v2LastLeft",
    },
  ];
  const onSubmit = async (values: AdminUserModel, action:any) => {
    try {
      const response = await addAdmin(values);
      if (response.data.message?.appStatusCode === "IP_RSF") {
        setPageStatus({
          isSuccess: true,
          error: "",
          isError:false,
          loading: false,
          message: "Admin Added Successfully",
        });
        setuniQue(Date.now());
        action.setSubmitting(false);
      } else {
        setPageStatus({
          isSuccess: false,
          isError:true,
          error: response.data?.message?.appStatusDescription,
          loading: false,
          message: "",
        });
        action.setSubmitting(false)
      }
    } catch (er: unknown) {
      setPageStatus({
        isSuccess: false,
        isError:true,
        error: (er as Error)?.message,
        loading: false,
        message: "",
      });
      action.setSubmitting(false)
    }
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <AppBreadCrumb
            classes="custom-appbreadcrumb"
            BreadCrumbItems={DefaultBreadcrumbs}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Typography className="Client-txt-fontwt">Add Admin</Typography>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12} md={12}>
          <Paper elevation={1}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Item elevation={0}>
                  <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                    sx={{
                      flexDirection: "row",
                      "& .MuiFormControl-root": {
                        marginTop: "15px",
                        marginBottom: "3px",
                      },
                    }}
                  >
                    <UserForm
                      type={"Add"}
                      key={uniQue}
                      initialValues={initialValue}
                      onSubmit={onSubmit}
                    />

                    {pageStatus.isError && (
                      <CustomAlert
                        message={pageStatus.error}
                        type={"error"}
                        onClose={() => {
                          setPageStatus({ ...pageStatus, isSuccess: false,isError:false });
                        }}
                      />
                    )}
                    {pageStatus.isSuccess && (
                      <>
                        <SnackbarPopup
                          open={pageStatus.isSuccess}
                          message={pageStatus.message}
                          onClose={() => {
                            setPageStatus({ ...pageStatus, isSuccess: false });
                          }}
                        />
                      </>
                    )}
                  </Box>
                </Item>
              </Grid>
              <Grid item xs={12} md={6}>
                <Item elevation={0}>
                  <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                    sx={{
                      flexDirection: "row",
                      "& .MuiFormControl-root": {
                        marginTop: "15px",
                        marginBottom: "3px",
                      },
                    }}
                  ></Box>
                </Item>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item mb={50}></Grid>
      </Grid>
    </>
  );
};

export default AddUser;
