import React, { useEffect, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { getRandomInt } from "../../utils/randomUtils";

type AppInfiniteScrollProps = {
  dataLength: any;
  next: any;
  loader: boolean;
  hasMore: boolean;
  isLoading: boolean;
  classNames?: any;
  height?:string;
};

const AppInfiniteScroll: React.FunctionComponent<AppInfiniteScrollProps> = (
  props
) => {
  const scrollRef = useRef<any>(null);
  const scrollRefInner = useRef<HTMLDivElement>(null);

  function genUniqueId(): string {
    const dateStr = Date
      .now()
      .toString(36); // convert num to base 36 and stringify
    
    const randomStr = getRandomInt()
      .toString(36)
      .substring(0, 8); // limiting length of random string
  
    return `${dateStr}-${randomStr}`;
  }

  const uniqueId = genUniqueId();
  useEffect(() => {
    if (!props.isLoading && props.hasMore && scrollRef.current) {
      const ele = document.getElementsByClassName("infinite-scroll-component")[0];
      const nextElement = document?.getElementById(uniqueId)  as HTMLDivElement | null;

      if(props?.height){
        handleLoadWithHeight(ele, nextElement);
      }else{
        handleLoadWithoutHeight(ele);
      }
    }
  }, [props.isLoading, props.hasMore]);

  function handleLoadWithHeight(ele: Element, nextElement: HTMLDivElement | null) {
    if(nextElement){
      if (ele.clientHeight > nextElement?.offsetHeight && nextElement?.offsetHeight > 100) {
        props.next();
      }
    }
  }

  function handleLoadWithoutHeight(ele: Element) : void {
    if (window.innerHeight > ele.clientHeight && ele.clientHeight > 100) {
      props.next();
    }
  }

  const getThreshold = () => {
    if (window.innerHeight < 1000) {
      return 0.8;
    } else if (window.innerHeight < 1500) {
      return 0.7;
    } else {
      return 0.2;
    }
  };

  return (
    <>
      <InfiniteScroll
        ref={scrollRef}
        dataLength={props.dataLength * 10}
        next={props.next}
        hasMore={props?.hasMore ?? false}
        loader={props.loader}
        scrollThreshold={getThreshold()}
        className={props?.classNames ? props.classNames : "defaultInfiniteScroll-cls"}
        {...(props.height ? {'height': props?.height} : {})}
      >
        <div id={`${uniqueId}`} ref={scrollRefInner}>
        {props.children}
        </div>
      </InfiniteScroll>
    </>
  );
};

export default AppInfiniteScroll;