import { createSvgIcon } from "@mui/material/utils";

export const AllJobsMenuIcon = createSvgIcon(
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.75 1.25V8.75H9.25V1.25H1.75ZM3 2.5H8V7.5H3V2.5Z" fill="white"/>
    <path d="M9.25049 18.751H1.75049V11.251H9.25049V18.751Z" fill="white"/>
    <path d="M19.2505 2.50098H11.7505V3.75098H19.2505V2.50098Z" fill="white"/>
    <path d="M16.7505 12.501H11.7505V13.751H16.7505V12.501Z" fill="white"/>
    <path d="M16.7505 16.251H11.7505V17.501H16.7505V16.251Z" fill="white"/>
    <path d="M19.2505 6.25098H11.7505V7.50098H19.2505V6.25098Z" fill="white"/>
    </svg>,
  "All Jobs"
);