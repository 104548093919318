
//truncate string if its size is greater then specified lenght and append ... at the end
function truncateString(str, size=20) {
    
      if (str?.length > size) {
        return str?.substring(0, size) + "...";
      } else {
        return str;
      }
  }
  export default truncateString;