
export const getTextNameEllipse = (textName,charLength=15) => {
  return textName?.length > charLength ? textName?.substring(0, charLength) + "..." : textName;
};
export const  capitalizeFirstLetter = (string)=> {
  if (string === undefined || string === "") return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const isVarNotEmpty = (string) => {
  return Boolean(string);
};

export function getFirstAndLastName(fullName) {
const nameParts = fullName?.trim().split(" ");
if (nameParts && nameParts?.length < 2) {
  throw new Error(
    "Invalid full name. Please provide both first name and last name."
  );
}
const firstName = nameParts && nameParts[0];
const lastName = nameParts && nameParts[nameParts?.length - 1];

return firstName  + " " + lastName ;
}

export function handleNullValue(string, specialCharacter){
if(string){
  return string;
}else{
  return specialCharacter??"-"
}
}
