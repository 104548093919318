import { FormControl, Input, InputLabel } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { SetPasswordService } from "../../../Services/clientService";
import { AuthenticateToken } from "../../../Services/resetPasswordService";
import cloud from "../../../assets/images/cloudbottom.svg";
import cloud1 from "../../../assets/images/cloudtop.svg";
import { passWordRegex } from "../../../constants/regex";
import { ErrorMessage } from "../../ui/ErrorMessage";
import PasswordSetPopup from "./PasswordSetPopup";
import "./SetPassword.scss";

interface Props {}

export const SetPassword: React.FC<Props> = () => {
  const [urlParams] = useSearchParams();
  const accessToken = urlParams?.get("accessToken");
  const teamMemberId = urlParams?.get("id");
  const userEmail = urlParams?.get("email");
  const [ShowHideSet, setShowHideSet] = React.useState(true);
  const [ShowHideSetSuccess, setShowHideSetSuccess] = React.useState(false);
  const [apiResponse, setApiResponse]: any = React.useState({});

  const navigate = useNavigate();
  React.useEffect(() => {
    formik.setFieldValue("email", userEmail);
    if (accessToken) {
      authenticateToken(accessToken);
    }
  }, []);
  const authenticateToken = (accessToken: any) => {
    AuthenticateToken(accessToken)
      ?.then((res) => {
        if (res.data.status == 200) {
          setApiResponse(res.data);
          if (res.data.entity.isExpired) {
            console.log("This link has expired");
          }
        } else {
          console.log("Unexpected error occurred");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const payload = {
    id: `${teamMemberId}`,
    email: "",
    password: "",
    confirmPassword: "",
  };
  const InitiateResetSchema = Yup.object({
    email: Yup.string()
      .email("Entered email address is invalid")
      .required("Please enter a valid email address"),
    password: Yup.string()
      .required(
        "The password must be 8-15 characters long and should contain at least one or more of: Uppercase characters (A-Z) Lowercase characters (a-z) Digits (0-9) Special characters (~!@#$%^&*_-+=`|(){}[]:;\"'<>,.?/)"
      )
      .matches(
        passWordRegex,
        "Password must be 8 -15 characters long and must contain atleast one or more of digits, special characters,  upper case and lower case letters"
      ),
    confirmPassword: Yup.string()
      .required(
        "Please re-enter the password. Minimum 8 characters are required"
      )
      .oneOf(
        [Yup.ref("password"), null],
        "Password and Confirm password do not match"
      ),
  });
  const formik = useFormik({
    initialValues: payload,
    validationSchema: InitiateResetSchema,
    onSubmit: (values) => {
      console.log(values, payload);
      const urlParams: any = new URLSearchParams(window.location.search);
      const accessToken: string = urlParams.get("accessToken");
      SetPasswordService(values, accessToken)
        .then((res) => {
          if (res.data.status == 200) {
            setShowHideSet(false);
            setShowHideSetSuccess(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });
  return (
    <div>
      {apiResponse?.entity?.isExpired ? (
        <Grid container>
          <Grid p={2} item xs={12} md={12}>
            <Alert
              style={{ marginTop: "20px" }}
              severity="error"
            >
              {"This link has expired"}
            </Alert>
          </Grid>
        </Grid>
      ) : (
        <>
          <img src={cloud1} className="custom-btm-img custom-displaynone" />
          <img src={cloud} className="custom-top-img custom-displaynone" />
          <Box className={` ${ShowHideSet ? "" : "d-none"}`}>
            <Container maxWidth="sm">
              <Grid
                container
                spacing={0}
                sx={{ justifyContent: "center", marginTop: "30px" }}
              >
                <Paper
                  elevation={3}
                  style={{ backgroundColor: "rgba(249, 250, 255, 1)" }}
                >
                  <Grid item xs={12} md={12} textAlign="right">
                    <IconButton
                      data-testid="closeIcon"
                      aria-label="close"
                      onClick={() => navigate("/login")}
                      sx={{
                        marginTop: "5px",
                        marginRight: "5px",
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                  <Grid
                    container
                    className="custom-container-padding custom-container-rspnv"
                  >
                    <Grid item xs={12} md={12}>
                      <Box data-testid="box-setnewpassword">
                        <Typography
                          data-testid="setpassword"
                          className="custom-pwd-head"
                        >
                          Set Password
                        </Typography>
                        <Box>
                          <Typography className="custom-enterpwd-txt">
                            Enter your password
                          </Typography>
                        </Box>
                        <Box
                          component="form"
                          noValidate
                          autoComplete="off"
                          sx={{
                            flexDirection: "row",
                            "& .MuiTextField-root": { m: 1 },
                          }}
                        >
                          <FormControl
                            variant="standard"
                            required
                            error={
                              formik.touched.email &&
                              Boolean(formik.errors.email)
                            }
                            fullWidth
                            margin="normal"
                          >
                            <InputLabel
                              className="custom-set-email"
                              htmlFor="email"
                            >
                              Your Email
                            </InputLabel>
                            <Input
                              name="email"
                              type="email"
                              readOnly={true}
                              disableUnderline
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.email}
                              autoComplete="off"
                            />
                            <ErrorMessage
                              errorText={
                                formik.touched.email && formik.errors.email
                              }
                            />
                          </FormControl>
                          <FormControl
                            required
                            error={
                              formik.touched.password &&
                              Boolean(formik.errors.password)
                            }
                            fullWidth
                            margin="normal"
                          >
                            <InputLabel
                              className="custom-set-email"
                              htmlFor="password"
                            >
                              New password
                            </InputLabel>
                            <Input
                              data-testid="password"
                              name="password"
                              type={"password"}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.password}
                            />
                            <ErrorMessage
                              errorText={
                                formik.touched.password &&
                                formik.errors.password
                              }
                            />
                          </FormControl>
                          <FormControl
                            required
                            error={
                              formik.touched.confirmPassword &&
                              Boolean(formik.errors.confirmPassword)
                            }
                            fullWidth
                            margin="normal"
                          >
                            <InputLabel
                              className="custom-set-email"
                              htmlFor="confirmPassword"
                            >
                              Confirm new password
                            </InputLabel>
                            <Input
                              data-testid="confirmPassword"
                              name="confirmPassword"
                              type={"password"}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.confirmPassword}
                            />
                            <ErrorMessage
                              errorText={
                                formik.touched.confirmPassword &&
                                formik.errors.confirmPassword
                              }
                            />
                          </FormControl>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              data-testid="btn-setnewpassword"
                              style={{ marginTop: "15px" }}
                              variant="contained"
                              className="custom-setpwd-button"
                              fullWidth
                              type="submit"
                              onClick={(e) => {
                                e.preventDefault();
                                formik.handleSubmit();
                              }}
                            >
                              Set Password
                            </Button>
                          </div>
                          <div style={{ marginTop: "40px" }}>
                            <Typography
                              variant="body1"
                              color="inherit"
                              component="span"
                              className="custom-setpwd-line"
                            >
                              Your password should contain minimum 8 characters
                              and include combination of alphabets, numbers and
                              special character
                            </Typography>
                          </div>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Container>
          </Box>
        </>
      )}
      <Box className={` ${ShowHideSetSuccess ? "" : "d-none"}`}>
        <PasswordSetPopup />
      </Box>
    </div>
  );
};
export default SetPassword;
