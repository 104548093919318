import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import { FormControl, Input, InputLabel } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BannerText from "../../Common/BannerText";
import PlacesAutocomplete from "react-places-autocomplete";
import { useFormik, getIn } from "formik";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './style.scss';
import { useLocation, useSearchParams } from 'react-router-dom';
import SuccessPopup from "./Components/SuccessPopup";
import { Alert, Autocomplete, Chip, CircularProgress, TextField } from "@mui/material";
import {
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';

import { AuthenticateRecaptcha } from "../../../Services/googleRecaptchaService";
import { ErrorMessage } from "../../ui/ErrorMessage";
import { GetAllDomains, GetRecruiterById, GetHearAboutUsOptions, RecruiterLinkVerificationCheck, GetRecruiterAssessmentSectionData } from "../../../Services/recruiterService";
import useApi from "../../../hooks/useApi";
import { interests } from "../../../data/tags";
import getAddressForAutoFill from "../../../utils/getAddressForAutoFill";
import { SignUpFooter } from "../../ui/SignUpFooter";
import getAddressForAutoComplete from "../../../utils/getAddressForAutoComplete";
import getLabel from "../../../utils/getLabelForAutoComplete";
import { Asterisk } from "../../ui/Asterisk";
import { defaultDataAutocomplete } from "../../../data/defaultDataAutocomplete";
import { searchOptions } from "../../../constants/googlePlaceOption";
import { IndustryAutocomplete } from "../../ui/IndustryAutocomplete";
import axios from '../../../Services/CommonHttpRequest'
import { BillingInfoType, DocumentTypeEnum } from "../../../models/interfaces";
import PhoneVerify from "../../Common/PhoneVerify/PhoneVerify";
import verifiedIcon from "./../../../assets/images/verified.svg"

import { CustomEmailInput } from "../../ui/CustomEmailInput";
import {preferredCountries} from "../../../data/phoneInput/preferredCountries";
import MenuItem from '@mui/material/MenuItem';
import { isValidPhoneNumber } from "react-phone-number-input";
import RecruiterSignUpStepBanner from "./Components/RecruiterSignUpStepBanner";
import { RecruiterSignupValidationSchema } from "./Components/RecruiterSignupValidationSchema";
import { ternary, valueOrDefault } from "../../../utils/complexityUtils";
import RecruiterAssessmentPopup from "../components/RecruiterProfileAssessment/Popup/RecruiterAssessmentPopup";
import { initializeFormikState } from "../components/RecruiterProfileAssessment/Utils/ValueProcessingUtils";
import { setNotLoggedUserAuthToken } from "../../../utils/setAuthToken";
import { recruiterScreeningResponsePDF } from "../../../Services/getPdfReportService";
import { UploadAttachment } from "../../../Services/uploadPhotoService";
import { FileUploader } from "../../Common/FileUploader";
import { getDocumentsByType } from "../../../utils/documentObjectUtils";
import PreviewFileDialog from "../../Common/PreviewFileDialog";
import AppLinkExpire from "../../Common/ExpireLinks/AppLinkExpire";
import BlankHeader from "../../Common/Navigation/BlankHeader";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
export default function Signup() {


  const allheaderAboutsUsOption = useApi (() =>GetHearAboutUsOptions());

  const [searchParams] = useSearchParams();
  let authToken = valueOrDefault(searchParams?.get("authToken") , "");
  setNotLoggedUserAuthToken(authToken);

  const [isOpen, setIsOpen] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  const [isLinkNotExpired, setIsLinkNotExpired] = useState<boolean| null>(null);
  const location = useLocation();
  const [autoCompleteSuggesions, setautoCompleteSuggesions] = React.useState("")
  const [isHidePassword, setIsHidePassword] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const recaptchaAction = "submit";
  const [countryShort, setCountryShort] = React.useState("us");
  const [showPassword, setshowPassword] = React.useState({password:false,confirmPassword:false})
  const [pageStatus, setPageStatus] = React.useState({
    isSuccess: false,
    error: "",
    loading: false,
  });
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [allDomainList, setAllDomainList] = useState<any>([]);
  const [assessmentDetails, setAssessmentDetails] = useState<any>();
  const [isScreeningCompleted, setIsscreeningCompleted] = React.useState(false);
  const [fileError, setfileError] = React.useState("");
  const [isUploading, setisUploading] = React.useState(false)
  const [ApiLoading, setApiLoading] = useState({
    getScreeningAPI:false,
    getComepletedSignupAPI:false,
    verificationLink:true
  });
  const [filePreview, setFilePreview] = React.useState<undefined | 
  {
        resourceUrl: string;
        fileName: string;
        fileType?: string;
  }>(undefined);
  const [authenticateModel, setAuthenticateModel] = React.useState({});
  const initialState = {
    id: searchParams.get("id"),
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    middleName: "",
    address: {
      streetAddress: "",
      city: "",
      state: "",
      postalCode: "",
      countryCode: ""
    },
    phoneNumbers: {
      primary: "",
      secondary: "",
      isPrimaryVerified: false,
      isSecondaryVerified: false,
    },

    experience: "",
    profiles: [
      {
        id: "linkedIn",
        type: "linkedIn",
        link: ""
      }
    ],
    linkedIn: "",
    interests: [],
    domains: [],
    skills: [],
    industries: [],
    preferredLocation: [],
    isEmailVerified: true,
    isSignupCompleted: true,
    isIdentityUpdated: true,
    loginType: "",
    billingInfoType: BillingInfoType[0], //not updated
    status: "Pending",
    hearAboutUs: {
      id: "",
      option: "",
      hearAboutUsSubOption: {
        id: "",
        option: ""
      },
      otherSpecification: {
        specifyOther: "",
      },
    },
    checkTermsnCondition: false,
    isScreeningResponseChecked:false,
    screeningResponse: {},
    documentInfo:[]
    
  };

  const [locationDetails, setLocationDetails] = React.useState({
    id: "",
    address: {
      id : '',
      streetAddress: "",
      city: "",
      state: "",
      postalCode: "",
      countryCode: "",
    },
    phoneNumbers: {
      primary: "",
      secondary: "",
      isPrimaryVerified: false,
      isSecondaryVerified: false,
    },
    currentWorkLocation: {
      id: "",
      city: "",
      state: "",
    },
    timezone: "",
  });

const [socialSubOptionList, setSocialSubOptionList] = React.useState([]);

  React.useEffect(() => {
    _GetRecruiterAssessmentSectionData();
    _GetAllDomains();
    allheaderAboutsUsOption.request();
    let email = searchParams.get("email");
    let linkedin = searchParams.get("type");
    if (email != null) {
      let expiryTime = searchParams.get("expiryDateTime") as string;
      let messageType = searchParams.get("messageType") as string;
      let id = searchParams?.get("id") as string;
      LinkVerificationCheck(email, expiryTime, messageType, id);
      GetRecruiterById(id)
      .then(res =>{
        if(res?.data?.status === 200 && res?.data?.message?.appStatusCode === "RC_RSF"){
          let {firstName , lastName} = res?.data?.entity;
          firstName && formik.setFieldValue('firstName', firstName);
          lastName && formik.setFieldValue('lastName', lastName);
        }
      });
    }
    if (linkedin != null && linkedin === 'linkedinSuccess' || linkedin === 'googleSuccess') {
      setIsLinkNotExpired(true);
      let { id, email, firstName, lastName }:any = location.state;
      let linkedInOrGoogle = linkedin === 'linkedinSuccess' ? 'LinkedIn' : 'Google';
      setIsHidePassword(true);
      formik.setFieldValue('id', id)
      formik.setFieldValue('email', email);
      formik.setFieldValue('firstName', firstName);
      formik.setFieldValue('lastName', lastName);
      formik.setFieldValue('loginType', linkedInOrGoogle);
      // Default password in case of linkedin & google
      formik.setFieldValue('password', 'Jobrialto@123');
      formik.setFieldValue('confirmPassword', 'Jobrialto@123');
    }

  }, []);

  const handleRejectedResponse = (msg:any) =>{
    setHasError(true);
    setErrorMessage(msg);
    setIsLinkNotExpired(false)
  }
  
  const LinkVerificationCheck = (email:string, expiryTime:string, messageType:string, id:string) => {
    RecruiterLinkVerificationCheck(email, expiryTime, messageType, id)
      ?.then(res => {
        setApiLoading({
          ...ApiLoading,verificationLink:false
        })
        if (res.data.status === 200 && res.data.message.appStatusCode === 'RC_RSF') {
          if(res?.data?.entity?.isSignupCompleted){
            handleRejectedResponse("The link has expired, and you are already registered");
          } else{
            email = decodeURI(email);
            formik.setFieldValue('email', email);
            formik.setFieldValue('id', id);
            formik.setFieldValue('loginType', 'Email_UserID');
            setIsLinkNotExpired(true);
          }   
          
        } else if (res.data.status === 200 && res.data.message.appStatusCode === 'RC_EXP') {
          handleRejectedResponse(res.data?.message?.appStatusDescription);
          console.log("Link Expired");     
          
        } else {
          handleRejectedResponse(res.data?.message?.appStatusDescription)
        }
      }).finally(()=>{
        setApiLoading({
          ...ApiLoading,verificationLink:false
        })
      })
  }

  const _GetAllDomains = ()=>{

    GetAllDomains()
    .then((response)=>{
      if(response?.data?.message?.appStatusCode === "RC_RSF"){
        setAllDomainList([...response?.data?.entityList])
      }else{
        setAllDomainList([])
      }

    })
  }

  const _GetRecruiterAssessmentSectionData = ()=>{
    setApiLoading({
      ...ApiLoading,getScreeningAPI:true
    })
    GetRecruiterAssessmentSectionData()
    .then((response)=>{
      if(response?.data?.message?.appStatusCode === "RC_RSF"){
        setAssessmentDetails(response?.data?.entity)
        const initialValues = initializeFormikState(response?.data?.entity);
        formik.setFieldValue('screeningResponse', initialValues)
      }else{
        setAssessmentDetails([])
      }

    })
    .finally(()=>{
      setApiLoading({
        ...ApiLoading,getScreeningAPI:false
      })
    })
  }


  async function generateScreeningPdfSave(values : any, actions:any) {
    let payload = {
        recruiterGuid: values.id,
        recruiterId: "",
        recruiterEmail: values.email,
        recruiterFirstName: values.firstName,
        recruiterMiddleName: values.middleName,
        recruiterLastName: values.lastName,
        sections:values.screeningResponse.sections
      }
      // step 2 upload the created PDF 
    let scoreCardFile= await recruiterScreeningResponsePDF(payload,authToken);
    uploadRecruiterScreeningPdf(values,scoreCardFile, actions); 
  }

  function uploadRecruiterScreeningPdf(model : any, scorePdf : File, actions:any) {
    UploadAttachment({
      documentType: DocumentTypeEnum.Other,
      file: scorePdf,
    })
    .then((pdfRes) => {
      model?.documentInfo?.push({
        resourceUrl: pdfRes.data.entity.absoluteUri,
        fileName: pdfRes.data.entity.fileName,
        newFileName: pdfRes.data.entity.newFileName,
        documentType: DocumentTypeEnum.RecruiterScreening,
      });
      
      // step 3 update the details of recruiter
      UpdateRecruiter(model,actions);
    })
    .catch((error) => {
      console.log("something went wrong",error);
      
    });
  }
  
  const formik = useFormik({
    initialValues: initialState,
    validationSchema: RecruiterSignupValidationSchema,
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true);

      setApiLoading({
        ...ApiLoading,getComepletedSignupAPI:true
      })
      return handleReCaptchaVerify(values, actions);
    },
  });

  const handleCompleteRecruiterSignup = async (values:any, actions:any) => {
    // step 1 create the recruiter screening pdf
    generateScreeningPdfSave(values,actions)
  }
  const UpdateRecruiter = async (values:any, actions:any) => {
    values.address.id = values.address.city;
   values.profiles= values.linkedIn === ""? []: values.profiles
    setPageStatus({ isSuccess: false, error: "", loading: true });

    try {
      const res = await axios.put(`${process.env.REACT_APP_RECUITER_API}api/RecruiterSignup/CompleteSignUp`, values, { headers: { "Content-Type": "application/json" } });
      if (res.data.status === 200) {
        setIsOpen(true);
        setAuthenticateModel(
          {
            id: values.id,
            email: values.email,
            password: values.password,
            loginType: values.loginType
          }
        );
        setPageStatus({ isSuccess: true, error: "", loading: false });
        actions.setSubmitting(false);
        
        actions.resetForm();
        setApiLoading({
          ...ApiLoading,
          getScreeningAPI:false,
          verificationLink:false,
          getComepletedSignupAPI:false
        })

      }
      else {
        setPageStatus({
          isSuccess: false,
          error: res.data?.message?.appStatusDescription,
          loading: false,
        });
        setApiLoading({
          ...ApiLoading,getComepletedSignupAPI:false
        })
      }
    } catch (er:any) {
      actions.setSubmitting(false);
     
      setPageStatus({
        isSuccess: false,
        error: er?.message,
        loading: false,
      });
    }
  }
  
  useEffect(()=>{
   let  subList = filterAboutsUsSubOption(allheaderAboutsUsOption?.data?.entity?.hearAboutUsOptions,"f1ea0b28-50d7-4164-80d6-0f503a03d872");
    setSocialSubOptionList(subList);
  },[formik.values.hearAboutUs.id]);

  const handleUploadClick = (files: any) => {
    if (!files) return;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    let docIndex = 0 // default set 0 
    
    if (files[0].size > 2097152) {
      formik.setFieldValue(`documentInfo[${docIndex}].fileName`, files[0].name);
      setfileError("File size too large");
    } else {
      formik.setFieldTouched('documentInfo[${docIndex}].fileName', false, false);
      let filUpload = { documentType: DocumentTypeEnum.Resume, file: files[0] }
      setisUploading(true)
      UploadAttachment(filUpload).then(res => {
        formik.setFieldValue(`documentInfo[${docIndex}].resourceUrl`, res.data.entity.absoluteUri);
        formik.setFieldValue(`documentInfo[${docIndex}].fileName`, res.data.entity.fileName);
        formik.setFieldValue(`documentInfo[${docIndex}].newFileName`, res.data.entity.newFileName);
        formik.setFieldValue(`documentInfo[${docIndex}].documentType`, DocumentTypeEnum.Resume);
      }).finally(() => { setisUploading(false) })
      setfileError("");
    }
  }

  const filterAboutsUsSubOption = (filteredData:any,selectedId:string) => {
    // for null value
    if (!filteredData) {
      return filteredData;
    }

    const filteredOption = filteredData.find(
      (row:any) => row.id === selectedId
    );

    return filteredOption;
  };


  const handleSelect = async (address:string) => {
   let resp = await getAddressForAutoFill(address)
    formik.setFieldValue("address.streetAddress", valueOrDefault(resp?.formattedAddress, ""));
    formik.setFieldValue("address.id",valueOrDefault(resp?.city, ""));

    setCountryShort(resp?.countryCode?.toLowerCase());
    formik.setFieldValue("address.postalCode", valueOrDefault(resp?.pin, ""));
    formik.setFieldValue("address.countryCode", valueOrDefault(resp?.country, ""));
    formik.setFieldValue("address.state", valueOrDefault(resp?.state, ""));
    formik.setFieldValue("address.city", valueOrDefault(resp?.city, ""));
    
    formik.setFieldTouched("address.streetAddress",false)
    formik.setFieldTouched("address.id",false)
    formik.setFieldTouched("address.postalCode",false)
    formik.setFieldTouched("address.countryCode",false)
    formik.setFieldTouched("address.state",false)
    formik.setFieldTouched("address.city",false)
    
  }
  

  const handleMouseDownPassword = (event:any) => {
    event.preventDefault();
  };

  const handleDomainChange = (domain: any) => {
    const item = { id: domain.id, domain: domain.domainName };
  
    if (formik.values.domains.some((e: any) => e.id === domain.id)) {
      // Remove the domain from the list
      const updatedDomains = formik.values.domains.filter(
        (item: any) => item.id !== domain.id
      );
      formik.setFieldValue('domains', updatedDomains);
    } else {
      // Add the domain to the list
      const updatedDomains = [...formik.values.domains, item];
      formik.setFieldValue('domains', updatedDomains);
    }
  }

  const handleInterestChange = (interest: any) => {
    if (formik.values.interests.some((e: any) => e === interest)) {
      // Remove the interest from the list
      const updatedInterests = formik.values.interests.filter(
        (item: any) => item !== interest
      );
      formik.setFieldValue('interests', updatedInterests);
    } else {
      // Add the interest to the list
      const updatedInterests = [...formik.values.interests, interest];
      formik.setFieldValue('interests', updatedInterests);
    }
  }

  const handleReCaptchaVerify = React.useCallback(async (values, actions) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha(recaptchaAction);
     AuthenticateRecaptcha({ 'reCaptchaToken': token, 'action': recaptchaAction }).then(response => {
      if (response.data.status === 200 && response.data.entity.score >= 0.5) {
        handleCompleteRecruiterSignup(values, actions)
      }
    })
  }, [executeRecaptcha]);

  const renderFunc = ({
    getInputProps,
    suggestions,
    getSuggestionItemProps,
    loading,
  }:any) => (
    <div className="signupcomplete-autocomplete">
      <FormControl variant="standard" error={formik.touched.address?.streetAddress && Boolean(formik.errors.address?.streetAddress)} fullWidth required>
      <InputLabel htmlFor="address-input">Address</InputLabel>
        <Input {...getInputProps()}   onBlur={formik.handleBlur} name="address.streetAddress" type="text" />
        <ErrorMessage errorText={formik.touched.address?.streetAddress && formik.errors.address?.streetAddress} />

      </FormControl>
      <div className="autocomplete-dropdown-container">
        {loading && <div>Loading...</div>}
        {suggestions.map((suggestion) => (
          <div key={suggestion.description} {...getSuggestionItemProps(suggestion)}>
            <span onChange={handleSelect} style={{ cursor: 'pointer' }}>{suggestion.description}</span>
          </div>
        ))}
      </div>
    </div>
  );
  
  const renderPreferredGeographies = ({
    getInputProps,
    suggestions,
  }) => (
   
        <Autocomplete

          multiple
          id="size-small-standard-multi1"
          size="medium"
          autoComplete='off'
          onKeyPress={(e) => {formik.values.preferredLocation?.length === 5? e.preventDefault() : console.log();}}
         
          onChange={(e,value)=> formik.setFieldValue("preferredLocation",value)}
          options={suggestions.length>0? suggestions.map((str) => ({ id: str.placeId, location: { city: getAddressForAutoComplete(str.description).city, state: getAddressForAutoComplete(str.description).state, country: getAddressForAutoComplete(str.description).country } })) : defaultDataAutocomplete}
          getOptionDisabled={(option) => formik.values.preferredLocation.some(e => e.id === option.id) || formik.values.preferredLocation?.length === 5 ? true : false}

          getOptionLabel={(option) => getLabel(option.location.city, option.location.state)} 
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
               key={ option?.id ?? index}
                color="primary"
                size="medium"
                label={getLabel(option.location.city,option.location.state)}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              {...getInputProps()}
              variant="standard"
              label="Preferred Geographies"
              placeholder="Please type in to search geographies"
              
            />
          )}
        />


  );

  const props_Phone1Verify={
    testId:"primaryPhoneBtn",
    userId: formik.values.id,
    phoneNumber: formik.values.phoneNumbers?.primary,
    isPrimaryPhone: true,
    otherPhoneVerifyStatus: formik.values.phoneNumbers?.isSecondaryVerified,
    updatePhoneVerify : (isVerified)=>{
      formik.setFieldValue("phoneNumbers.isPrimaryVerified",isVerified);
       setLocationDetails({...locationDetails, ...{phoneNumbers: { ...locationDetails.phoneNumbers, primary:formik.values.phoneNumbers?.primary,isPrimaryVerified: isVerified}}});
    }
  }
  const props_Phone2Verify={
    testId:"secondaryPhoneBtn",
    userId: formik.values.id,
    phoneNumber: formik.values.phoneNumbers?.secondary,
    isPrimaryPhone: false,
    otherPhoneVerifyStatus: formik.values.phoneNumbers?.isPrimaryVerified,
    updatePhoneVerify : (isVerified)=>{
      formik.setFieldValue("phoneNumbers.isSecondaryVerified",isVerified);
    }
  }
  const onPrimaryPhChange =(ev:any)=>{
    if(formik.values.phoneNumbers?.primary.replace(/-/g, "") != ev ) {
      formik.setFieldValue("phoneNumbers.isPrimaryVerified",false);
    }
    if(locationDetails.phoneNumbers?.primary.replace(/-/g, "") == ev ) {
      formik.setFieldValue("phoneNumbers.isPrimaryVerified", locationDetails.phoneNumbers.isPrimaryVerified );
    }
    formik.setFieldValue("phoneNumbers.primary", ev);
  }
  const onSecondaryPhChange =(ev:any)=>{
    if(formik.values.phoneNumbers?.secondary.replace(/-/g, "") != ev ) {
      formik.setFieldValue("phoneNumbers.isSecondaryVerified",false);
    }
    if(locationDetails.phoneNumbers?.secondary.replace(/-/g, "") == ev ) {
      formik.setFieldValue("phoneNumbers.isSecondaryVerified", locationDetails.phoneNumbers.isSecondaryVerified );
    }
    formik.setFieldValue("phoneNumbers.secondary", ev);
  }

  const sortedDomainList = allDomainList?.length > 0
  ? [...allDomainList].sort((a, b) => a.domainName.length - b.domainName.length)
  : [];

  const shouldShowSpinner = (isSubmitting:boolean, apiLoading:boolean) => {
    return isSubmitting || apiLoading;
  };

  function renderExpireMessage(errorMessage: string, isLinkNotExpired: boolean | null) {
    if (!errorMessage || isLinkNotExpired) {
      return null;
    }
  
    return (
      <div className="page-mt">
        <AppLinkExpire message={errorMessage} />
      </div>
    );
  }

  return (
    <>
    <BlankHeader />
    {renderExpireMessage(errorMessage, isLinkNotExpired)}

   {isLinkNotExpired &&
      <div className="page-mt">
      <Container maxWidth="xl" className="login-banner-text">
          <Grid container mt={2} sx={{ justifyContent: "space-around" }}>
            <Grid item xs={12}>
            <BannerText bannerText="Complete your Recruiter Profile with a Simple and Easy 2 step Sign Up Process"/>
            </Grid>
          </Grid>
        </Container>
        <Box
          sx={{
            flexGrow: 1,
            padding: "20px 0",
          }}
        >
          <Container maxWidth="xl" className="signup-right">
            <Grid container spacing={3} sx={{ justifyContent: "space-around" }}>
              <Grid item xs={12} lg={6}>              
                <Item
                  elevation={0}
                  sx={{
                    backgroundColor: "transparent",
                    textAlign: "left",
                  }}
                  className="completeSignForm"
                >
                  <Typography
                    variant="h2"
                    component="span"
                    className="abelh2 letter-spacing-normal"
                  >
                    Complete Sign Up
                  </Typography>
                  <Box
                    component="span"
                    sx={{
                      flexDirection: "row",
                      "& .MuiFormControl-root": {
                        marginTop: "3px",
                        marginBottom: "3px",
                      },
                    }}
                  >
                    {hasError && <Alert severity="error">{errorMessage}</Alert>}
                    <FormControl required error={formik.touched.email && Boolean(formik.errors.email)} fullWidth>
                      <InputLabel htmlFor="email">Your email</InputLabel>

                      <CustomEmailInput name = "email" value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} readOnly={true} isverified = {formik.values.isEmailVerified}/>

                    </FormControl>
                    {!isHidePassword && <FormControl
                      required
                      error={formik.touched.password && Boolean(formik.errors.password)}
                      fullWidth
                    >
                      <InputLabel htmlFor="standard-adornment-password">
                        Password
                      </InputLabel>
                      <Input
                        name="password"
                        type={ternary(showPassword.password, "text", "password")}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        endAdornment={
                          <InputAdornment position="end">
                            
                            <IconButton
                              onClick={()=>setshowPassword({...showPassword, password:!showPassword.password})}
                              onMouseDown={handleMouseDownPassword}
                              sx={{ padding: "4px" }}
                              className="showHideIcon"
                              data-testid="showHideIcon"
                            >
                              {ternary(
                                showPassword.password,
                                <Visibility />,
                                <VisibilityOff />
                              )}
                            </IconButton>
                            {formik.touched.password && !Boolean(formik.errors.password) &&
                            <CheckCircleIcon color="success"/>
                            }
                          </InputAdornment>
                        }
                      />
                    <ErrorMessage errorText={formik.touched.password && formik.errors.password} />

                    </FormControl>}
                    {!isHidePassword && <FormControl
                      required
                      error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                      fullWidth
                    >
                      <InputLabel htmlFor="standard-adornment-password">
                        Confirm password
                      </InputLabel>
                      <Input
                        name="confirmPassword"
                        type={ternary(showPassword.confirmPassword, "text", "password")}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.confirmPassword}
                        inputProps={{ "data-testid": "linkedInInput" }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              data-testid="showHideIconlinkedInInput"
                              onClick={()=>setshowPassword({...showPassword, confirmPassword:!showPassword.confirmPassword})}
                              sx={{ padding: "4px" }}
                              className="showHideIcon"
                            >
                              {ternary(
                                showPassword.confirmPassword,
                                <Visibility />,
                                <VisibilityOff />
                              )}
                            </IconButton>
                            {formik.touched.confirmPassword && !Boolean(formik.errors.confirmPassword) &&
                            <CheckCircleIcon color="success"/>
                          }
                          </InputAdornment>
                        }
                      />
                    <ErrorMessage errorText={formik.touched.confirmPassword && formik.errors.confirmPassword} />

                    </FormControl>}
                    <Box className="form-three-child name-wrapper">
                      <FormControl
                        variant="standard"
                        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                        required
                      >
                        <InputLabel htmlFor="first-name-input">
                          First Name
                        </InputLabel>
                        <Input
                          name="firstName"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.firstName}
                          type="text"
                        />
              <ErrorMessage errorText={formik.touched.firstName && formik.errors.firstName} />

                      </FormControl>
                      <FormControl variant="standard" error={formik.touched.middleName && Boolean(formik.errors.middleName)}>
                        <InputLabel htmlFor="middle-initial-input">
                          Middle initial
                        </InputLabel>
                        <Input
                          name="middleName"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.middleName}
                        />
                      <ErrorMessage errorText={formik.touched.middleName && formik.errors.middleName} />

                      </FormControl>
                      <FormControl
                        variant="standard"
                        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                        required
                      >
                        <InputLabel htmlFor="last-name-input">
                          Last Name
                        </InputLabel>
                        <Input
                          name="lastName"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.lastName}
                        />
      <ErrorMessage errorText={formik.touched.lastName && formik.errors.lastName} />

                      </FormControl>
                    </Box>
                    <PlacesAutocomplete
                      data-testid={'addressField'}
                      searchOptions={searchOptions}

                      value={formik.values.address?.streetAddress}
                      onChange={(ev:any) => {
                        formik.setFieldValue("address.streetAddress", ev);
                      }}
                      onSelect={handleSelect}
                    >
                      {renderFunc}
                    </PlacesAutocomplete>
                    <Box className="form-four-child">
                      <FormControl error={Boolean(
                        getIn(formik.touched, 'address.city') &&
                        getIn(formik.errors, 'address.city')
                      )} variant="standard" 
                      required
                      >
                        <InputLabel htmlFor="city-input">City</InputLabel>
                        <Input
                          data-testid="addressCity"
                          name="address.city"
                          type="text"
                          onChange={(val) => {
                          
                            formik.setFieldValue("address.city",val.target.value)}}
                          onBlur={formik.handleBlur}
                          value={formik.values.address.city}
                        />
                            <ErrorMessage errorText={formik.touched.address?.city && formik.errors.address?.city} />

                      </FormControl>
                      <FormControl
                        variant="standard"
                        error={Boolean(
                          getIn(formik.touched, 'address.state') &&
                          getIn(formik.errors, 'address.state')
                        )}
                        required
                        className="state-input"
                      >
                        <InputLabel htmlFor="state-input">State</InputLabel>
                        <Input
                          data-testid="addressState"
                          name="address.state"
                          type="text"
                          onChange={(val) => {
                            formik.setFieldValue("address.streetAddress","");
                            formik.setFieldValue("address.state",val.target.value)}}
                          onBlur={formik.handleBlur}
                          value={formik.values.address.state}
                        />
                      <ErrorMessage errorText={formik.touched.address?.state && formik.errors.address?.state} />

                      </FormControl>
                      <FormControl
                        variant="standard"
                        error={Boolean(
                          getIn(formik.touched, 'address.postalCode') &&
                          getIn(formik.errors, 'address.postalCode')
                        )}
                        required
                        className="state-input"
                      >
                        <InputLabel htmlFor="state-input">Zip</InputLabel>
                        <Input
                          name="address.postalCode"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.address.postalCode}
                        />
                        <ErrorMessage errorText={formik.touched.address?.postalCode && formik.errors.address?.postalCode} />

                      </FormControl>
                      <FormControl variant="standard" error={Boolean(
                        getIn(formik.touched, 'address.countryCode') &&
                        getIn(formik.errors, 'address.countryCode')
                      )} 
                      required
                      >
                        <InputLabel htmlFor="zip-input">Country</InputLabel>
                        <Input
                          data-testid="addressCountryCode"
                          name="address.countryCode"
                          type="text"
                          onChange={(val) => {
                            formik.setFieldValue("address.streetAddress","");
                            formik.setFieldValue("address.countryCode",val.target.value)}}
                          onBlur={formik.handleBlur}
                          value={formik.values.address.countryCode}
                        />
                      <ErrorMessage errorText={formik.touched.address?.countryCode && formik.errors.address?.countryCode} />

                      </FormControl>
                    </Box>
                    <br/>
                    <Box
                      className="form-three-child space-between"
                      sx={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <Box className="phone-number-wrapper signUpComplete-phoneInput">
                      <FormControl
                        variant="standard"
                        required
                      >
                        <span >Phone <Asterisk/></span>
                        <PhoneInput
                          data-testid={'primaryPhone'}
                          inputProps={{'data-testid':'primaryPhone'}}
                          inputClass=""
                          className="phoneCountryCustom"
                          placeholder="Phone"
                          country={countryShort}
                          preferredCountries={preferredCountries}
                          preserveOrder= {["preferredCountries"]}

                          onChange={(ev) => {
                            formik.setFieldValue("phoneNumbers.primary", ev);
                            onPrimaryPhChange(ev);
                          }}
                          value={formik.values.phoneNumbers?.primary}
                        />
                        {ternary(
                          formik.values.phoneNumbers?.isPrimaryVerified === true,
                          <img style={{paddingLeft:"10px", position: "absolute", left: "100.28%", right: "-6.31%",top: "50%",bottom: "-7.5%"}} src={verifiedIcon}></img>,
                          null
                        )}
                        {formik.touched.phoneNumbers?.primary && <ErrorMessage errorText={formik.errors.phoneNumbers?.primary} />}
                        {!formik.errors.phoneNumbers?.primary && formik.touched.phoneNumbers?.isPrimaryVerified && !formik.values.phoneNumbers?.isPrimaryVerified && <ErrorMessage errorText={formik.errors.phoneNumbers?.isPrimaryVerified} />}
                        </FormControl>
                        { !formik.values.phoneNumbers?.isPrimaryVerified && isValidPhoneNumber('+'+ formik.values.phoneNumbers?.primary)? <PhoneVerify {...props_Phone1Verify}></PhoneVerify> :null}
                      </Box>
                      <Box className="phone-number-wrapper  signUpComplete-phoneInput">
                      <FormControl
                        variant="standard"
                        required
                        className="phone-input"
                      >
                        Alternate Phone
                        <PhoneInput
                          inputProps={{'data-testid':'AlternatePhone'}}
                          inputClass=""
                          className="phoneCountryCustom"
                          placeholder="Alternate Phone"
                          country={countryShort}
                          onChange={(ev) => {
                            formik.setFieldValue("phoneNumbers.secondary", ev);
                            onSecondaryPhChange(ev);
                          }}
                          preferredCountries={preferredCountries}
                          preserveOrder= {["preferredCountries"]}
                          value={formik.values.phoneNumbers?.secondary}
                        />
                        { (locationDetails.phoneNumbers?.isSecondaryVerified  )|| 
                          (formik.values.phoneNumbers?.isSecondaryVerified ?? false) ?
                          <img style={{paddingLeft:"10px", position: "absolute", left: "100.28%", right: "-6.31%",top: "50%",bottom: "-7.5%"}} src={verifiedIcon}></img>
                          : null
                        }
                        {formik.touched.phoneNumbers?.secondary && <ErrorMessage errorText={formik.errors.phoneNumbers?.secondary} />}
                        
                      </FormControl>
                      {!formik.values.phoneNumbers?.isSecondaryVerified && isValidPhoneNumber('+'+ formik.values.phoneNumbers?.secondary) ? <PhoneVerify {...props_Phone2Verify}></PhoneVerify> :null}
                      </Box>
                    </Box>
                    
                      
                    <Box
                      className="form-three-child space-between"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <FormControl
                        variant="standard"
                        required
                        error={formik.touched.experience && Boolean(formik.errors.experience)}
                        
                      
                      >
                        <InputLabel htmlFor="experience-input">
                        Total Experience
                        </InputLabel>
                        <Input
                          data-testid="experinenceText"
                          onKeyDown={ (evt) => evt.key == '-' && evt.preventDefault() }
                          name="experience"
                        
                          inputProps={{
                            min: 0
                          }}
                          endAdornment={<InputAdornment position="start">Years</InputAdornment>}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.experience}
                        />
                        <ErrorMessage errorText={formik.touched.experience && formik.errors.experience} />

                      </FormControl>
                      <FormControl
                        variant="standard"
                        error={formik.touched.linkedIn && Boolean(formik.errors.linkedIn)}
                      
                        className="linkedIn-input"
                      >
                        <InputLabel htmlFor="linkedin-input">
                          Linkedin Profile {formik.values.documentInfo?.length === 0 && <Asterisk />}
                        </InputLabel>
                        <Input
                          name="linkedIn"
                          inputProps={{ "data-testid": "linkedInInputProfile" }}
                          type="text"
                          onChange={(event) => {
                            setPageStatus({ isSuccess: false, error: "", loading: false });
                            formik.setFieldValue('linkedIn', event.target.value);
                            formik.setFieldValue('profiles', [{ id: 'linkedIn', type: 'linkedIn', link: event.target.value }]);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.linkedIn}
                          placeholder="Please enter your linkedIn profile URL"
                        />
                        <ErrorMessage errorText={formik.touched.linkedIn && formik.errors.linkedIn} />

                      </FormControl>
                    </Box>
                    <Box className="form-three-child" sx={{ marginTop: "16px" }}>
                      <Typography variant="caption" className="label-sml-recruitersignup">
                        Select Domain
                        <span
                          aria-hidden="true"
                          className="MuiFormLabel-asterisk MuiInputLabel-asterisk"
                        >
                          *
                        </span>
                      </Typography>
            
                      {sortedDomainList?.map((domain,index) => {
                        return <FormControlLabel
                          key={domain?.id}
                          control={<Checkbox data-testid={`domain-${index}`} onChange={() => handleDomainChange(domain)} />}
                          label={domain.domainName}
                          sx={{ marginLeft: "10px" }}
                          className="checkbox-sml"
                          checked={formik.values.domains?.some((e:any) => e.id === domain.id)}
                        />
                      })}
                      {formik.touched.domains && formik.values.domains.length==0 && <ErrorMessage errorText={"Please select the domains"}/>}


                    </Box>
                    <Box className="form-three-child" sx={{ marginTop: "16px" }}>
                      <Typography variant="caption" className="label-sml-recruitersignup">
                        Your Interest
                        <span
                          aria-hidden="true"
                          className="MuiFormLabel-asterisk MuiInputLabel-asterisk"
                        >
                          *
                        </span>
                      </Typography>
                      {interests.map((x, index) => {
                        return <FormControlLabel key={x ?? index}
                            control={
                            <Checkbox
                              data-testid={`handleInterestChange-${index}`}
                              key={x}
                              onChange={() => handleInterestChange(x)}

                              id={x}
                            />
                          }
                          label={x}
                          checked={
                            formik.values?.interests?.some(e => e === x)
                          }
                          sx={{ marginLeft: "10px" }}
                          className="checkbox-sml"
                        />
                      })}
                      {formik.touched.interests && formik.values.interests.length==0 && <ErrorMessage errorText={"Please select the interests"}/>}

                    </Box>
                    <Box mt={1}>
                    <IndustryAutocomplete
                    industries={formik.values.industries}
                    onChange={(e:any, value:any) => {formik.setFieldValue('industries', value)}}
                    readOnly={false}
                    search={""}
                    isRequired={false}
                    placeholder="Please type in to search for the industry"
                  />
                    <ErrorMessage errorText={formik.touched.industries && formik.errors.industries} />


                    </Box>

                    <Box mt={1}>
                      
                      <PlacesAutocomplete
                        value={autoCompleteSuggesions}
                        onChange={(ev:any) => {
                        setautoCompleteSuggesions(ev)
                        }}
                        searchOptions={{ types: ['(cities)'] }}

                      >{renderPreferredGeographies}
                      </PlacesAutocomplete>
                      <ErrorMessage errorText={formik.touched.preferredLocation && formik.errors.preferredLocation} />


                    </Box>

                    <Box mt={1}>
                      <TextField
                        inputProps={{'data-testid':"hearAboutUsInput"}}
                        style={{ width: "100%" }}
                        variant="standard"
                        value={formik.values.hearAboutUs.id}
                        onChange={(e)=>{
                          formik.setFieldValue("hearAboutUs.id",e.target.value)
                          formik.setFieldTouched("hearAboutUs.id",false)
                          let selectedValue = allheaderAboutsUsOption?.data?.entity?.hearAboutUsOptions?.find(x => x.id === e.target.value)
                          formik.setFieldValue("hearAboutUs.option",selectedValue.option)
                          if(selectedValue.option!=="Other")
                          {
                            formik.setFieldValue("hearAboutUs.hearAboutUsSubOption.id","")
                          

                            formik.setFieldValue("hearAboutUs.hearAboutUsSubOption.option","")
                          }

                        
                        }}
                        select
                        label="How did you hear about us?"
                        name="hearAboutUs.id"
                        required
                      >
                        {
                            allheaderAboutsUsOption?.data?.entity?.hearAboutUsOptions?.map((row,index)=>{
                              return (
                                <MenuItem value={row?.id} key={ row?.id ?? index}
                                >
                                  {row?.option}</MenuItem>
                              )
                            })
                          }
                      </TextField>
                      <ErrorMessage
                        errorText={
                          formik.touched.hearAboutUs?.id &&
                          formik.errors.hearAboutUs?.id
                        }
                      />
                    </Box>
                    <Box mt={1}>
                      {formik.values.hearAboutUs.option === "Social Media" && (
                        <>
                          <TextField
                            inputProps={{
                              "data-testid": "hearAboutUsSubOptionInput",
                            }}
                            style={{ width: "100%" }}
                            variant="standard"
                            value={
                              formik.values.hearAboutUs.hearAboutUsSubOption.id
                            }
                            onChange={(e) => {
                              formik.setFieldValue(
                                "hearAboutUs.hearAboutUsSubOption.id",
                                e.target.value
                              );
                              let selectedValue =
                                socialSubOptionList?.subOptions?.find(
                                  (x:any) => x.id === e.target.value
                                );
                              formik.setFieldValue(
                                "hearAboutUs.hearAboutUsSubOption.option",
                                selectedValue?.option
                              );
                              formik.setFieldTouched(
                                "hearAboutUs.hearAboutUsSubOption.id",
                                false
                              );
                            }}
                            select
                            label="Which Platforms?"
                            name="hearAboutUs.hearAboutUsSubOption.id"
                            required
                          >
                            {socialSubOptionList?.subOptions?.map(
                              (row:any, index:any) => {
                                return (
                                  <MenuItem
                                    value={row?.id}
                                    key={row?.id ?? index}
                                  >
                                    {row?.option}
                                  </MenuItem>
                                );
                              }
                            )}
                          </TextField>
                          <ErrorMessage
                            errorText={
                              formik?.touched?.hearAboutUs?.hearAboutUsSubOption
                                ?.id &&
                              formik?.errors?.hearAboutUs?.hearAboutUsSubOption
                                ?.id
                            }
                          />
                        </>
                      )}
                    </Box>
                    {(formik.values.hearAboutUs.option === "Other" ||
                      formik.values.hearAboutUs.hearAboutUsSubOption.option ===
                        "Other") && (
                      <Box mt={1}>
                        <FormControl variant="standard" fullWidth required>
                          <InputLabel htmlFor="first-name-input">
                            Other (Please specify)
                          </InputLabel>
                          <Input
                            data-testid="specifyOther"
                            name="hearAboutUs.otherSpecification.specifyOther"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={
                              formik.values.hearAboutUs.otherSpecification
                                .specifyOther
                            }
                            type="text"
                            inputProps={{ maxLength: 500 }}
                          />
                          <ErrorMessage
                            errorText={
                              formik?.touched?.hearAboutUs?.otherSpecification
                                ?.specifyOther &&
                              formik?.errors?.hearAboutUs?.otherSpecification
                                ?.specifyOther
                            }
                          />
                        </FormControl>
                      </Box>
                    )}

                    <Box >
                      <FileUploader
                        editMode={true}
                        isUploading={isUploading}
                        fileName={getDocumentsByType(formik.values.documentInfo, DocumentTypeEnum.Resume)?.[0]?.fileName}
                        resourceUrl={getDocumentsByType(formik.values.documentInfo, DocumentTypeEnum.Resume)?.[0]?.resourceUrl}
                        fileError={fileError}
                        onFileChange={handleUploadClick}
                        setFilePreview={setFilePreview}
                        token={authToken}
                      />
                    </Box>
                    <Box mb={1}>
                      <RecruiterAssessmentPopup
                          loading={ApiLoading.getScreeningAPI} 
                          assessmentDetails={assessmentDetails}
                          assesmentResponseValue = {formik?.values?.screeningResponse}
                          handleOnSubmit={(values, isValid)=>{
                            formik.setFieldValue('screeningResponse', values)                          
                            if(isValid){
                              setIsscreeningCompleted(true);
                              formik.setFieldValue('isScreeningResponseChecked', true)
                            }
                          }}
                          isScreeningCompleted={isScreeningCompleted}
                          
                        />
                        <ErrorMessage
                          className="isScreeningResponseChecked"
                          errorText={
                            formik.touched.isScreeningResponseChecked &&
                            formik.errors.isScreeningResponseChecked
                          }
                        />
                    </Box>

                    
                    <SignUpFooter formik={formik} />
                    <ErrorMessage
                      errorText={
                        formik.touched.checkTermsnCondition &&
                        formik.errors.checkTermsnCondition
                      }
                    />
                    <Box style={{ textAlign: "right" }}>
                      <Button
                        data-testid="signup-button"
                        variant="contained"
                        className="signup-button"
                        size="small"
                        style={{ width: "14rem" }}
                        disabled={formik.isSubmitting || ApiLoading.getComepletedSignupAPI}
                        endIcon={
                          shouldShowSpinner(formik.isSubmitting, ApiLoading.getComepletedSignupAPI)? (
                            <CircularProgress size={"16px"} color={"inherit"} />
                          ) : (
                            ""
                          )
                        }
                        onClick={() => {                         
                          formik.handleSubmit();
                        }}
                      >
                        Complete Sign Up
                      </Button>
                    </Box>
                    {pageStatus.error && (
                      <Alert severity="error">{pageStatus.error}</Alert>
                    )}
                    
                  </Box>
                </Item>
              </Grid>
              <Grid item xs={12} lg={5}>
                <Item
                  elevation={0}
                  className="login-banner-right completeSignUpRightBanner"
                >
                  <RecruiterSignUpStepBanner />
                </Item>
              </Grid>
            </Grid>
          </Container>
        </Box>
        {filePreview !== undefined &&
          <PreviewFileDialog
            open={filePreview !== undefined}
            handleOpenClose={() => setFilePreview(undefined)}
            fileName={filePreview.fileName}
            fileURL={filePreview.resourceUrl}
            fileType={filePreview.fileType}
          />
          }
         
      </div>
    }
     {isOpen && <SuccessPopup isOpen={isOpen} authModel={authenticateModel} />}
    </>
  );
}
