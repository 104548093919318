import { useFormik } from 'formik';
import React from 'react';
import { DocumentTypeEnum, UserAuth } from '../../../models/interfaces';
import { USAPaymentDetail } from '../../../models/PaymentDetails';
import { UploadAttachment, UploadResourceModel } from '../../../Services/uploadPhotoService';
import { USAForm } from './USAForm';
import usaSchemaAdmin from './schema/usaAdminForm';
import { isValidFileExtention } from '../../../utils/isValidFileExtensions';
interface Props {
    editMode: any,
    onChange : any,
    billingDetail: {
        userId: string;
        email: string;
        name: string;
        billingInfoType: string;
    };
   
    values : any,
    USAFormRef:any;
 
}
export const AdminUSABilling: React.FC<Props> = ({
    editMode,
    billingDetail,
    onChange,
    values,
    USAFormRef
}) => {


    let initialState: USAPaymentDetail = {
        userId: billingDetail?.userId,
        email: billingDetail?.email,
        name: billingDetail?.name,
        billingInfoType: "USA",
        country: "USA",
        routingNumber: "",
        confirmRoutingNumber: "",
        accountHolderName: "",
        bankName: "",
        bankAccountNumber: "",
        confirmBankAccountNumber: "",
        location: {
            address: "",
            city: "",
            state: "",
            zip: "",
            country: ""
        },
        isRoutingNumberConfirm: false,
        isW9NameConfirm: true,
        resourceInfo: {
            resourceUrl: "",
            fileName: "",
            newFileName: "",
        },
        isUpdateDetails : false
    };
    const pageStatus = {
        isSuccess: false,
        error: "",
        loading: false,
    };

    const [isChecked, setIsChecked] = React.useState({ first: false, second: false })
    const [fileError, setfileError] = React.useState("");
    const [fileName, setFileName] = React.useState("");
    const [isUploading, setIsUploading] = React.useState(false);

    const formik = useFormik({
        initialValues: initialState,
        enableReinitialize: true,
        validationSchema: usaSchemaAdmin,
        onSubmit: () => {
            
        },
    });
    // Attach the Formik instance to the ref
    USAFormRef.current = formik;

    const handleUploadClick = (files: FileList | null) => {
        if (!files) return;
        
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        setFileName(files[0].name);

        if(!isValidFileExtention(files[0].name))
        {
            setfileError("Invalid file extension");
            return
        }
        if (files[0].size > 2 * 1024 * 1024) {
            setfileError("File size too large");
        } else {
          formik.setFieldTouched('resourceInfo.fileName', false, false);
          setfileError("");
        
        
        setFileName(files[0].name);
        const fileModel: UploadResourceModel = {
          documentType: DocumentTypeEnum.Resume,
          file: files[0],
        };
        setIsUploading(true)

        UploadAttachment(fileModel).then(res =>{
          onChange({target:{name:"resourceInfo.resourceUrl", value:res.data.entity.absoluteUri}},"usaBilling")
          onChange({target:{name:"resourceInfo.fileName", value:res.data.entity.fileName}},"usaBilling")

          onChange({target:{name:"resourceInfo.newFileName", value:res.data.entity.newFileName}},"usaBilling")

          
        }).catch(e => {console.log("file upload error ", e)}).finally(() => setIsUploading(false));;
        
      
        }
    };

    React.useEffect(() => {
        values!==undefined && values!==null && formik.setValues(values)
         
        setFileName(values?.resourceInfo?.fileName)
    }, [values]);

    const changeHandle = (e:any)=>
    {
        formik.setFieldValue(e.target.name,e.target.value);
        onChange(e,"usaBilling");
    }
    return (
        <USAForm 
            onChange={changeHandle} 
            onFileUpload={handleUploadClick} 
            viewMode={UserAuth.Admin} 
            editMode={editMode} 
            billingDetail={billingDetail} 
            formik={formik} 
            pageStatus={pageStatus} 
            fileError={fileError} 
            fileName={fileName} 
            setIsChecked={setIsChecked} 
            isChecked={isChecked} 
            formState={initialState} 
            setfileError={setfileError} 
            setFileName={setFileName}  
            isFileUploading={isUploading}
        />
    )
}
