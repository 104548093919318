import { Nullable } from "../../models/interfaces";
import getUserDetails from "./getUserDetails";

function checkUserId (userId:Nullable<string>): string  {
   
    if(userId!==null && getUserDetails().id!==null)
    {
        if(getUserDetails()?.id !== userId)
        {
                     localStorage.clear()
                     
        }
     
    }
   
return "";
}
export default checkUserId;
