
import { Card, CardContent } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

import Typography from "@mui/material/Typography";
import TechStatsRecruiter from "./TechStatHalfRadialChartRecruiter";
import TechStatsRadialAdmin from "./TechStatsRadialChartAdmin";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

export default function PerformanceByScore({data}) {
  return (
    <Card sx={{ height: "100%" }} className="card-custom-shadow">
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
          <Grid
            item
            sx={{
              width: "100%",
            }}
          >
            <Typography
              variant="caption2"
              sx={{
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              Performance Score
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TechStatsRecruiter data={data} />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-32px",
                marginBottom: "10px",
              }}
            >
              <Typography
                variant="caption2"
                sx={{
                  fontSize: "7px",
                  fontWeight: "400",
                  marginRight: "2px",
                  color: "#808080",
                }}
              >
                Current Year
              </Typography>
              <Typography
                variant="caption2"
                sx={{
                  fontSize: "7px",
                  fontWeight: "400",
                  marginRight: "2px",
                  color: "#808080",
                }}
              >
                |
              </Typography>
              <Typography
                variant="caption2"
                sx={{
                  fontSize: "7px",
                  fontWeight: "400",
                  marginLeft: "2px",
                  color: "#808080",
                }}
              >
                Last Year
              </Typography>
            </Box>

            <Box>
              <Grid
                container
                spacing={3}
                sx={{
                  paddingTop: "5px",
                }}
              >
                <Grid item xs={4} md={4} className="radial-chart-container">
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      padding: 0,
                    }}
                    className="flat-paper"
                  >
                    <TechStatsRadialAdmin
                      percentage={data?.technicalMatch}
                      title={"Technical Match"}
                    />
                  </Item>
                </Grid>
                <Grid item xs={4} md={4} className="radial-chart-container">
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      padding: 0,
                    }}
                    className="flat-paper"
                  >
                    <TechStatsRadialAdmin
                      percentage={data?.timelySubmission}
                      title={"Timely Submission"}
                    />
                  </Item>
                </Grid>
                <Grid item xs={4} md={4} className="radial-chart-container">
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      padding: 0,
                    }}
                    className="flat-paper"
                  >
                    <TechStatsRadialAdmin
                      percentage={data?.qualitySubmission}
                      title={"Quality of Submission"}
                    />
                  </Item>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid
                container
                spacing={3}
                sx={{
                  paddingTop: "5px",
                }}
              >
                <Grid item xs={4} md={4} className="radial-chart-container">
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      padding: 0,
                    }}
                    className="flat-paper"
                  >
                    <TechStatsRadialAdmin
                      percentage={data?.responsiveness}
                      title={"Responsiveness"}
                    />
                  </Item>
                </Grid>
                <Grid item xs={4} md={4} className="radial-chart-container">
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      padding: 0,
                    }}
                    className="flat-paper"
                  >
                    <TechStatsRadialAdmin
                      percentage={data?.interviews}
                      title={"Interviews"}
                    />
                  </Item>
                </Grid>
                <Grid item xs={4} md={4} className="radial-chart-container">
                  <Item
                    elevation={0}
                    sx={{
                      textAlign: "left",
                      padding: 0,
                    }}
                    className="flat-paper"
                  >
                    <TechStatsRadialAdmin
                      percentage={data?.offers}
                      title={"Offers"}
                    />
                  </Item>
                </Grid>
               
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
