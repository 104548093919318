import { Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import { editModeKey } from "../../../data/editModeKey/editModeKey";
import "../AdminManageCompensation.css";
import { CompensationForm } from "./CompensationForm";
import AppBreadCrumb from "../../Common/AppBreadCrumb";
import { BreadCrumbItem } from "../../../models/AppModels";
import { getNevLink } from "../../../utils/getFileFromUrl";

export const AllRecruiter = () => {
  const isEditable = useSelector(
    (state: any) =>
      state.editMode.individualEditMode.find(
        (x:any) => x.name === editModeKey.AllRecruiter
      ).editMode
  );

  let breadcrumb: BreadCrumbItem[] = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
    {
      isLeaf: true,
      sequence: 2,
      title: "All Compensation",
      to: "/",
      leafClasses: " v2LastLeft",
    },
  ];
  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <AppBreadCrumb
            classes="custom-appbreadcrumb"
            BreadCrumbItems={breadcrumb}
          ></AppBreadCrumb>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography
              variant="h4"
              component="div"
              className="letter-spacing-normal typography-with-right-text"
              gutterBottom
              style={{ fontSize: "1.25rem" }}
            >
            <span>Manage Compensation </span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <CompensationForm editMode={isEditable} />
          </Grid>
      </Grid>
    </>
  );
};
