import { createSvgIcon } from "@mui/material/utils";

export const DeleteIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="22"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M18.232 5.76929H5.77051V21.0001H18.232V5.76929Z"
      stroke="#F81919"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3 5.76929H21"
      stroke="#F81919"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.92383 3H14.0777"
      stroke="#F81919"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.92383 9.23071V16.1538"
      stroke="#F81919"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.0762 9.23071V16.1538"
      stroke="#F81919"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>,
  "DeleteIcon"
);
