import StaticFooter from "../Common/StaticFooter";
import BannerText from "../Common/BannerText";
import { GotoTop } from "../ui/GotoTop";
import { FaqContent } from "./FaqContent";
import { ThemeProvider } from "@mui/material/styles";
import staticPageTheme from "../../Shared/theme/staticPageTheme";
import { Grid } from "@mui/material";
import AppBreadCrumb from "../Common/AppBreadCrumb";
import { getNevLink } from "../../utils/getFileFromUrl";
import { BreadCrumbItem } from "../../models/AppModels";
import getLoggedInUserRole from "../../Shared/utils/getLoggedInUserRole";
import { UserAuth } from "../../models/interfaces";

const Faq: React.FC = () => {
  let userRole = getLoggedInUserRole();
  const DefaultBreadcrumbs: BreadCrumbItem[] = [
    {
      isLeaf: false,
      sequence: 1,
      title: "Dashboard",
      to: getNevLink("/dashboard"),
    },
    { isLeaf: true, sequence: 2, title: "FAQ", leafClasses: " v2LastLeft" },
  ];

  return (
    <>
      <ThemeProvider theme={staticPageTheme}>
        <Grid container spacing={2}>
          {userRole !== UserAuth.NotLoggedIn && (
            <Grid item xs={12} md={12}>
              <AppBreadCrumb
                classes="custom-appbreadcrumb"
                BreadCrumbItems={DefaultBreadcrumbs}
              />
            </Grid>
          )}
          <Grid
            item
            xs={12}
            md={12}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              padding: "10px 0",
              textAlign: "center",
            }}
            className="faq-content-pl"
          >
            <Grid item xs={12} md={12} >
              <BannerText bannerText="Frequently Asked Questions" />
              <FaqContent />
            </Grid>
            <div
              style={{
                position: "fixed",
                left: "80%",
                zIndex: "50",
                top: "26%",
              }}
            >
              <GotoTop />
            </div>
          </Grid>
        </Grid>
      </ThemeProvider>
      {userRole === UserAuth.NotLoggedIn && <StaticFooter />}
    </>
  );
};
export default Faq;
