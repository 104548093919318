
export enum RecruiterAssessmentType {
    RecruitmentSkillsAndExperience = "c86a0632-8ede-461e-8cc1-5b6b6bfc8f28",
    AvailabilityAndCommitment = "10a2a846-5b76-4288-a52b-07cd1b01a9ef",
    TechnicalProficiency = "8ff77ccc-88ad-4d0a-b252-0c5196f09442",
    AdditionalInformation = "bf11e42c-32a7-4656-b4c3-e473af2d689a",
}


export enum RecruiterAssessmentQuetionType {
    SingleSelect = 0,
    MultiSelect = 1,
    TextInput = 2,
}