

import { createSvgIcon } from "@mui/material/utils";

export const ResumeIcon = createSvgIcon(
   
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 1.5H2.5H10.5H11.5V2.5V10.5V11.5H10.5H2.5H1.5V10.5V2.5V1.5ZM3.5 3.5V9.5H9.5V3.5H3.5ZM12.5 3.5H13.5H21.5H22.5V5.5H21.5H13.5H12.5V3.5ZM12.5 7.5H13.5H21.5H22.5V9.5H21.5H13.5H12.5V7.5ZM12.5 12.5H13.5H21.5H22.5V13.5V21.5V22.5H21.5H13.5H12.5V21.5V13.5V12.5ZM14.5 14.5V20.5H20.5V14.5H14.5ZM1.5 14.5H2.5H10.5H11.5V16.5H10.5H2.5H1.5V14.5ZM1.5 18.5H2.5H10.5H11.5V20.5H10.5H2.5H1.5V18.5Z" fill="black"/>
</svg>

);
