import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@material-ui/core/Grid";
import TalkIcon from "./../../assets/images/Talk-icon.svg";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import BlueMagnifying from "./../../assets/images/home-connect-bg.svg";
import { Testimonial } from "./Testimonial";
import { ThemeProvider } from "@mui/material/styles";
import staticPageTheme from "../../Shared/theme/staticPageTheme";
import "./RecruitmentServices.scss";
import { Link, useNavigate } from "react-router-dom";

const RecruitmentServices: React.FC = () => {

  const navigate = useNavigate();

  return (
    <div>
      <ThemeProvider theme={staticPageTheme}>
        <Container
          maxWidth="xl"
          className="login-banner-text"
          sx={{
            marginTop: "16px",
          }}
        >
          <Typography
            variant="h5"
            component="div"
            className="letter-spacing-normal recruitment-Service-txt"
            gutterBottom
            textAlign={"center"}
            sx={{ paddingTop: "10px" }}
          >
            Recruitment Services
          </Typography>

          <Typography
            className="txt-roboto-font"
            variant="h4"
            color="inherit"
            component="h1"
            sx={{
              paddingBottom: "20px",
              fontWeight: "400",
              fontSize: {
                xs: "25px !important",
                sm: "32px !important",
                md: "34px !important",
              },
            }}
            textAlign={"center"}
          >
            One Stop Solution for Recruiters for Easy & Efficient
            <br /> Candidate Placements
          </Typography>
          <Typography
            className="txt-roboto-font txt-fontSize"
            variant="h6"
            color="inherit"
            component="h1"
            sx={{
              paddingBottom: "10px",
              fontWeight: "400",
              color: "#0E0E2C99",
            }}
            textAlign={"center"}
          >
            Refer candidates to JobRialto in 4 simple steps. Receive guaranteed
            ongoing monthly referral fees when your <br />
            referred candidate is successfully placed for contract jobs and
            one-time fees for full-time jobs. And there is no limit <br />
            on how many candidates you place or earn each month.
          </Typography>

          <Container
            sx={{
              paddingTop: { sm: "10px", md: "23px" },
              paddingBottom: { sm: "20px", md: "35px" },
            }}
          >
            <Grid container justify="center">
              <Button
                data-testid="contained"
                color="primary"
                variant="contained"
                className="txt-roboto-font btnFont-size"
                onClick={()=>{
                  navigate('/contactUs')
                }}
              >
                Lets Talk{" "}
                <img style={{ paddingLeft: "10px" }} src={TalkIcon}></img>
              </Button>
            </Grid>
          </Container>
          <br></br>

          <Container>
            <Typography
              className="letter-spacing-normal recruitment-Service-txt"
              style={{ fontSize: "22px", marginTop: "0px" }}
            >
              Act Now
            </Typography>

            <Box paddingTop="20px">
              <Stack
                direction={{ xs: "column", sm: "column", md: "row" }}
                spacing={2}
              >
                <div className="txt-roboto-font takeyour-txt-heading">
                  Take your recruitment game to the next level with JobRialto!
                </div>
                <Typography
                variant="h6"
                color="inherit"
                component="h1"
                sx={{ paddingBottom: "10px", fontWeight: "400", color: "#0E0E2C99" }}
              >
                With our team’s collective experience of being in the recruiting business, 
                we created the perfect process to help recruiters effortlessly fulfill the 
                contingent and full-time jobs from 100s of clients and employers. 
              </Typography>
              </Stack>
            </Box>
          </Container>
          <Container>
            <div
              className="row p-1"
              style={{ textAlign: "center", paddingTop: "60px" }}
            >
            </div>
          </Container>
          <Container>
            <Stack
              direction={{ xs: "column", sm: "column", md: "row" }}
              spacing={{ sm: 2, md: 10 }}
            >
              <Stack direction="column" spacing={2}>
                <div className="letter-spacing-normal primary-color-txt fontweignt-size-digit">
                  01 <span style={{ color: "#0E0E2C" }}>Create a Profile</span>
                </div>
                <Typography
                  className="tab-font"
                  color="inherit"
                  sx={{
                    paddingBottom: "10px",
                    fontWeight: "400",
                    color: "#0E0E2C99",
                  }}
                >
                  Sign up and create a personalized recruiter profile with your
                  email, with your social accounts like Google or LinkedIn
                </Typography>
              </Stack>
              <Stack direction="column" spacing={2}>
                <div className="letter-spacing-normal primary-color-txt fontweignt-size-digit">
                  02 <span style={{ color: "#0E0E2C" }}>Browse Jobs</span>
                </div>
                <Typography
                  className="tab-font"
                  color="inherit"
                  sx={{
                    paddingBottom: "10px",
                    fontWeight: "400",
                    color: "#0E0E2C99",
                  }}
                >
                  Browse from 1000s of jobs listed every week on JobRialto and
                  saved jobs to source candidates for
                </Typography>
              </Stack>
              <Stack direction="column" spacing={2}>
                <div className="letter-spacing-normal primary-color-txt fontweignt-size-digit">
                  03 <span style={{ color: "#0E0E2C" }}>Submit Candidates</span>
                </div>
                <Typography
                  className="tab-font"
                  color="inherit"
                  sx={{
                    paddingBottom: "10px",
                    fontWeight: "400",
                    color: "#0E0E2C99",
                  }}
                >
                  Once a candidate is submitted, JobRialto team prescreens &
                  submits to the client or employer
                </Typography>
              </Stack>
              <Stack direction="column" spacing={2}>
                <div className="letter-spacing-normal primary-color-txt fontweignt-size-digit">
                  04{" "}
                  <span style={{ color: "#0E0E2C" }}>Interviewed & hired</span>
                </div>
                <Typography
                  className="tab-font"
                  color="inherit"
                  sx={{
                    paddingBottom: "10px",
                    fontWeight: "400",
                    color: "#0E0E2C99",
                  }}
                >
                  If the candidate is hired after interviews with Client or
                  Employer, you start earning referral fees
                </Typography>
              </Stack>
            </Stack>
          </Container>
          <Container>
            <Stack direction="row" spacing={30}>
              
            </Stack>
          </Container>
          <Container>
            <Typography
              className="letter-spacing-normal recruitment-Service-txt"
              paddingTop="35px"
            >
              Expand your potential as a recruiter
            </Typography>
            <Box paddingTop="15px">
              <Stack
                direction={{ xs: "column", sm: "column", md: "row" }}
                spacing={{ xs: 5, sm: 5, md: 35 }}
              >
                <Stack direction="column" spacing={1}>
                  <Typography className="txt-roboto-font takeyour-txt-heading">
                    Grow With Ease
                  </Typography>
                  <Typography
                    color="inherit"
                    className="tab-font"
                    sx={{
                      paddingBottom: "10px",
                      fontWeight: "400",
                      color: "#0E0E2C99",
                    }}
                  >
                    Research shows that 90% of the best hires are found through
                    passive recruitment. JobRialto leverages the power of both
                    passive and active recruitment through our growing network
                    of recruiters. Onboard yourself to the marketplace now.
                  </Typography>
                  <Typography
                    variant="h5"
                    color="inherit"
                    component="span"
                    className="abel text-link login-faq-txt custom-text1"
                  >
                    Check out the{" "}
                    <Link
                      to="/faq"
                      style={{
                        textDecoration: "underline",
                      }}
                      className="primary-color-txt"
                    >
                      FAQ
                    </Link>{" "}
                    for details
                  </Typography>
                </Stack>
                <div style={{ padding: "10px" }}>
                  <img
                    src={BlueMagnifying}
                    alt="BlueMagnifying"
                    className="blue-magnifying-img"
                  />
                </div>
              </Stack>
            </Box>
          </Container>
        </Container>

        <Testimonial />
      </ThemeProvider>
    </div>
  );
};

export default RecruitmentServices;
