import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Box, Button, Divider, FormControlLabel, TextField } from "@mui/material";
import {  makeStyles } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import useApi from "../../../hooks/useApi";
import {
 
  GetRecruiters,
} from "../../../Services/recruiterService";
import React, { useState,useEffect, forwardRef, useImperativeHandle } from 'react';
import getUnique from "../../../utils/getUnique";
import { RecruiterStatus } from "../../../models/interfaces";
import { useSearchParams } from "react-router-dom";
import constructName from "../../../utils/constructName";

const useStyles = makeStyles((theme) => ({
  scrollMenu: {
    "&::-webkit-scrollbar": {
      width: "0.2em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#4540DB",
      outline: "1px solid #4540DB",
    },
  },
}));

const RecruiterFilter = ({
  isClear,
  setIsClear,
  filterState,
  onChange,
  handleClearAll,
  preselectedRecruiters
}, ref) => {
  
  const [search, setSearch] = React.useState({
    pageIndex: 1,
    pageSize: 10,
    sortColumn: "DateSignedUp",
    sortOrder: "Descending",
    isSorted: true,
    totalRecord: 0,
    searchKeywords: "",
    status: [RecruiterStatus[1]],
    filterByMasterData: {
      locationFilterInputs: [],
      domainFilterInputs: [],
      industryFilterInputs: [],
      skillsFilterInputs: [],
    },
  });
  const [selectedRecruiter, setselectedRecruiter] = useState(preselectedRecruiters??[]);
  const [searchParams] = useSearchParams();
  const classes = useStyles();
  const allRecruiters = useApi(() => GetRecruiters(search));
  const changeSearchHandler = (e) => {
    setSearch({...search, searchKeywords : e.target.value});
  };
  let recruiterNameFromParams = searchParams.get("recruiterName")
  let recruterId = searchParams.get("recruiterGuid")


  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      allRecruiters.request();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  useEffect(() => {
    if(recruiterNameFromParams!==null)
    {
      let recruiterToSelect = {firstName:recruiterNameFromParams,id:recruterId,lastName:''};
      selectedRecruiter.push(recruiterToSelect);
      allRecruiters.data?.entityList.push(recruiterToSelect);
    }
  }, []);
  
  const clearState = () => {
    setSearch({...search, searchKeywords :''});
    setselectedRecruiter([]);
  }

  useImperativeHandle(ref, () => ({
    clearState: clearState
  }));

  useEffect(() => {
    if (isClear) {
      clearState();
      setIsClear(false);
    }
  }, [isClear]);

  let skills =
    selectedRecruiter.length > 0
      ? selectedRecruiter.concat(allRecruiters.data?.entityList)
      : allRecruiters.data?.entityList;
   
  
  const renderRecruitersList = () => {
    const uniqueSkills = getUnique(skills);

    const handleFilterChange = (e, changeType) => {
      //update on parent component
      onChange(e, "recruiters");

      let isChecked = e.target.checked;

      let skillselected = skills.filter((x) => x.id === e.target.name)[0];
      onChange(e, "recruiters",skillselected);
      if (isChecked) {

        setselectedRecruiter([...selectedRecruiter, skillselected]);

      } else {
        
        let filteredArray = selectedRecruiter.filter(
          (item) => item.id !== e.target.name
        );

        setselectedRecruiter(filteredArray);
      }
    };

    return (
      <MenuList>
        {uniqueSkills?.map((item, index) => {
          return (
            <MenuItem
              key={item?.id}
              sx={{ paddingLeft: "3px", paddingBottom: "0px", minHeight:"auto" }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => handleFilterChange(e, "recruiterFilterInputs")}
                    name={item?.id}
                    checked={filterState?.includes(
                      item?.id
                    )}
                    sx={{ padding: "0px", fontSize:"12px" }}
                    inputProps={{
                      'data-testid':"select-recruiter"
                    }}
                  />
                }
                label={constructName(item?.firstName,item?.lastName)}
                className="candidates-label"
              />
            </MenuItem>
          );
        })}
      </MenuList>
    );
  };
  return (
    <> 
      <Typography variant="h6" component="h6" color="#929292" fontSize={"1rem"} ml={2.5}>
        <span> Recruiters:</span> ({filterState?.length + " "}
                Selected)<br />
      </Typography>
    
      <Box className="cand-filter-btnWrapper">
   
        <TextField
          name="skill"
          value={search.searchKeywords}
          onChange={changeSearchHandler}
          id="standard-basic"
          placeholder="Search Recruiters"
          variant="standard"
          sx={{ marginLeft: "20px",width: "120px" }}
          InputProps={{
            disableUnderline: true,
            "data-testid": "text-recruiterfilter-skill"
          }}
        />

        <Button
          className="candidateClearAllBtn"
          onClick={() => {
            clearState();
            handleClearAll();
          }}
          size="small"
          variant="contain"
          data-testid="clear-all-btn"
        >
          Clear All
        </Button>
      </Box>

      <Divider
        sx={{
          color: "primary",
          width: "120px",
          marginLeft: "21px",
          bgcolor: "secondary.light",

          padding: "0.8px",
          marginTop: "2px",
        }}
      />

      <Paper
        elevation={3}
        sx={{
          maxWidth: "100%",
          display: "grid",
          flexWrap: "nowrap",
          marginBottom: "10px",
          marginTop: "15px",
          marginRight: "15px",
          marginLeft: "15px",
        }}
        className={classes.scrollMenu}
        style={{
          maxHeight: 130,
          overflow: "auto",
        }}
      >
        {!allRecruiters.loading && allRecruiters.data?.entityList.length === 0 && (
          <p>No data found</p>
        )}

        {renderRecruitersList()}
      </Paper>
    </>
  );
};
export default forwardRef(RecruiterFilter);

