import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { PermissionsEnums } from "../../../../data/permission";
import { JobOriginIds } from "../../../../models/Job/JobDetailsModel";
import { CandidateStatus } from "../../../../models/interfaces";
import { ModuleComponent } from "../../../Common/Permission/ModuleComponent";
import "../../NewViewSubmission/styles.scss";

interface OfferDetailsButtonProps {
  row: any;
  currentjobDetails: any;
  setPopup: any;
}

export default function OfferDetailsButton({
  row,
  currentjobDetails,
  setPopup
} : OfferDetailsButtonProps) {
  const isStafflineJob =
    currentjobDetails?.jobOriginId === JobOriginIds.staffline;

  const hasCandidateHiredStatus = row?.candidateStatuses?.some(
    (x:any) => x.status === CandidateStatus.CandidateHired
  );

  const handleButtonClick = () => {
    if (hasCandidateHiredStatus) {
      setPopup((popup: any) => ({ ...popup, offerDetails: true }));
    }
  };

  return (
    <>
      {isStafflineJob && (
        <ModuleComponent
          moduleId={PermissionsEnums.Submissions_Offer_Details}
        >
          {hasCandidateHiredStatus && (
            <Grid item>
              <Button
                className="submission-action-btn"
                variant="contained"
                color="primary"
                onClick={handleButtonClick}
              >
                Offer Details
              </Button>
            </Grid>
          )}
        </ModuleComponent>
      )}
    </>
  );
}