import { createSlice } from "@reduxjs/toolkit";


export const paymentUpdateSlice = createSlice({
  name: "paymentUpdateSlice",
  initialState: {
    justUpdated: false,
    billingInfoType : ""
   
  },
  reducers: {
   
    updateBillingInfoType: (state) => {
      state.justUpdated = true;
     
    },
    setBillingInfoType: (state,action) => {
        state.billingInfoType = action.payload.billingInfoType;
      
      },
   
   
   
  },
  
});

// Action creators are generated for each case reducer function
export const { updateBillingInfoType,setBillingInfoType } =
  paymentUpdateSlice.actions;

export default paymentUpdateSlice.reducer;