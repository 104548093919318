import React, { useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import "./ViewRecruiterInfoPopup.css";
import {
  FormControl,
  IconButton,
  Input,
  InputLabel,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import constructName from "../../../../utils/constructName";
import { CandidateStatus } from "../../../../models/interfaces";
import * as Yup from "yup";
import { ShareInterviewDetailByRecruiter } from "../../../../Services/recruiterService";
import { useFormik } from "formik";
import moment from "moment";
import { formatAMPM } from "../../../../Shared/utils/dateUtils";
import truncateString from "../../../../utils/truncateString";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import InterviewDetailPdf from "../../../Candidate/SubmissionDetails/InterviewDetailPdf";
import { ErrorMessage } from "../../../ui/ErrorMessage";
import NearMeOutlinedIcon from "@mui/icons-material/NearMeOutlined";
import { CustomAlert } from "../../../ui/CustomAlert";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { downloadFile } from "../../../../utils/getFileFromUrl";
import { getInterviewDetailPdfFile } from "../../../../Services/getPdfReportService";
import { JobOriginIds } from "../../../../models/Job/JobDetailsModel";
import StatusChipLabel from "../../../Common/Chip/StatusChipLabel";
import CopyLinkButton from "../../../Common/Buttons/CopyLinkButton";
import { CandidateSubmissionStatus } from "../../../../models/submission/CandidateSubmissionMasterStatusesModel";
import { valueOrDefault } from "../../../../utils/complexityUtils";
interface Props {
  openPopup: boolean;
  handleInterviewDetailsClose: any;
  data: any;
}

export const InterviewDetailsPopup: React.FC<Props> = ({
  openPopup,
  handleInterviewDetailsClose,
  data,
}) => {    
  let candidateStatuses = data?.candidateStatuses;
  let candidateEmail = data?.email;
  let resume = data?.resumeInfo;
  let recruiterEmail = data?.recruiterEmail;
  let recruiterFullName = constructName(
    data?.recruiterFirstName,
    data?.recruiterMiddleName,
    data?.recruiterLastName
  );
  let jobID = data?.jobId;
  let jobTitle = data?.jobTitle;
  let candidateFirstName = data?.firstName;

  const [shareInterviewState, setShareInterviewState] = React.useState({
    popup: false,
    isSharing: false,
    success: false,
    msg: "",
  });
  const shareInterviewDiv = useRef<HTMLImageElement>(null);

  // CandidateShortlisted
  let table = candidateStatuses?.filter(
    (e: any) => e.statusId === CandidateSubmissionStatus.InterviewScheduled
  );

  //Interview Rescheduled by Client
  let table2 = candidateStatuses?.filter(
    (e: any) => e.statusId === CandidateSubmissionStatus.InterviewRescheduled
  );

  //NextRoundOfInterview
  let table3 = candidateStatuses?.filter(
    (e: any) => e.statusId === CandidateSubmissionStatus.NextRoundOfInterview
  );
  
  
  let tableArr = [...table, ...table2, ...table3];

  
  tableArr = tableArr.sort(function (a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return (
      new Date(b.createdDate).valueOf() - new Date(a.createdDate).valueOf()
    );
  });

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Entered email address is invalid")
      .required("Please enter a valid email address"),
  });
  const subscribeState = {
    email: candidateEmail,
  };
  const formik = useFormik({
    initialValues: subscribeState,
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      onShareDetail(values);
    },
  });

  const onShareDetailClick = () => {
    formik.resetForm();
    setShareInterviewState({
      ...shareInterviewState,
      popup: true,
      msg: "",
      isSharing: false,
    });
  };
  const onShareDetail = async (values: any) => {
    setShareInterviewState({
      ...shareInterviewState,
      isSharing: true,
      popup: false,
    });
    let interviewDetailPdf = await getInterviewDetailPdfFile({
      interviewDetail: {
        interviewRound: tableArr[0].event?.title,
        dateOfInterview: tableArr[0].event?.dateTime?.date,
        interviewTime: tableArr[0].event?.dateTime?.time,
        modeOfInterview: tableArr[0].event?.interviewType ?? tableArr[0].event?.location,
        scheduledOn: tableArr[0]?.createdDate,
        location:tableArr[0].event?.location,
        interviewLocation:tableArr[0].event?.interviewLocation,
        interviewTypeId:tableArr[0].event?.interviewTypeId
      },
    });
    let formData = new FormData();
    formData.append("recruiterEmail", recruiterEmail);
    formData.append("candidateEmail", values.email);
    formData.append("recruiterFullName", recruiterFullName);
    formData.append("jobId", jobID);
    formData.append("jobTitle", jobTitle);
    formData.append("candidateFirstName", candidateFirstName);
    formData.append("interviewDetailFile", interviewDetailPdf);
    formData.append("interviewLocation.id", valueOrDefault(tableArr[0].event?.interviewLocation?.id, ""));
    formData.append("interviewLocation.streetAddress", valueOrDefault(tableArr[0].event?.interviewLocation?.streetAddress, ""));
    formData.append("interviewLocation.city", valueOrDefault(tableArr[0].event?.interviewLocation?.city, ""));
    formData.append("interviewLocation.state", valueOrDefault(tableArr[0].event?.interviewLocation?.state, ""));
    formData.append("interviewLocation.postalCode", valueOrDefault(tableArr[0].event?.interviewLocation?.postalCode, ""));
    formData.append("interviewLocation.countryCode", valueOrDefault(tableArr[0].event?.interviewLocation?.countryCode, ""));
    formData.append("interviewLocation.meetingLink", valueOrDefault(tableArr[0].event?.interviewLocation?.meetingLink, ""));

   
    ShareInterviewDetailByRecruiter(formData)
      .then((res) => {
        setShareInterviewState({
          ...shareInterviewState,
          success: true,
          msg: "Interview Detail Shared successfully",
          popup: false,
        });
      })
      .catch(() => {
        setShareInterviewState({
          ...shareInterviewState,
          isSharing: false,
          popup: false,
          success: false,
        });
      });
  };

  const getStatus = (status: any) => {
    if (status) {
      if (status.toLowerCase() === "candidate shortlisted")
        return "Interview Scheduled";
      else return status;
    } else {
      return "NA";
    }
  };

  function getCurrentStatus(statusList: any[]): any | undefined {
    if (statusList?.length === 0) {
      return undefined; // Return undefined if the array is empty
    }
  
  // Use reduce to find the item with the maximum modified date
  const currentStatus = statusList?.reduce((prev, current) => {
    if (!prev.modifiedDate || (current.modifiedDate && current.modifiedDate > prev.modifiedDate)) {
      return current;
    }
    return prev;
  });

    return currentStatus;
  }

  return (
    <div>
      <Dialog
        className="custom-popup-border"
        open={openPopup}
        onClose={handleInterviewDetailsClose}
        aria-labelledby="viewRecruiterPopup-title"
        aria-describedby="viewRecruiterPopup-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              maxWidth: "1200px", // Set your width here
            },
          },
        }}
      >
        <Paper elevation={5} style={{ backgroundColor: "#FFF" }}>
          <DialogTitle sx={{ m: 0, p: 2 }}>
            <IconButton
              aria-label="close"
              onClick={handleInterviewDetailsClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="viewRecruiterPopup-description">
              <Grid style={{ marginBottom: "20px" }} item xs={12}>
                <Typography align="center" className="custom-document-txt">
                  Interview Details
                </Typography>
              </Grid>

              <TableContainer
                style={{ backgroundColor: "#FFF" }}
                component={Paper}
                className="blueheading"
                elevation={0}
              >
                <div style={{ width: "955px", textAlign: "center" }}>
                  {shareInterviewState.success &&
                  shareInterviewState.msg !== "" ? (
                    <CustomAlert key={2} message={shareInterviewState.msg} />
                  ) : (
                    ""
                  )}
                </div>
                <Table>
                  <TableHead className="custom-interview-thead">
                    <TableRow>
                      <TableCell align="left">Interview Round</TableCell>
                      <TableCell align="left">Date of Interview</TableCell>
                      <TableCell align="left">Time</TableCell>
                      <TableCell align="left">Mode of Interview</TableCell>
                      <TableCell align="left">Scheduled On</TableCell>
                      <TableCell align="left">Details</TableCell>
                      { data?.jobOriginId === JobOriginIds.jobRialto && 
                        <>
                         <TableCell align="left">Status</TableCell>
                         {getCurrentStatus(data?.candidateFinalStatuses)?.status !== CandidateStatus.InterviewCancelledbyClient && tableArr[0]?.event?.interviewType?.toLowerCase() === "webcam" &&
                         <TableCell align="left">Meeting Link</TableCell>
                         }
                         </>
                      }
                      {
                       getCurrentStatus(data?.candidateFinalStatuses)?.status !== CandidateStatus.InterviewCancelledbyClient &&
                        <TableCell>Action</TableCell>
                      }
                      
                    </TableRow>
                  </TableHead>

                  <TableBody className="custom-interview-tbody">
                    {tableArr?.map((tableData, index) => {
                      return (
                        tableData && (
                          <TableRow key={ tableData?.event?.id ?? index}>
                            <TableCell align="left" scope="row">
                              <span style={{'width': 'min-content', 'display': 'inline-block'}}>
                                {tableData?.event?.title}
                              </span>
                            </TableCell>
                            <TableCell align="left">
                              <span style={{'width': 'min-content', 'display': 'inline-block'}}>
                                {moment(tableData?.event?.dateTime?.date).format(
                                  "MMMM DD, YYYY"
                                )}
                              </span>
                            </TableCell>
                            <TableCell align="left">
                              <span style={{'width': 'min-content', 'display': 'inline-block'}}>
                                {formatAMPM(tableData?.event?.dateTime?.time)}
                              </span>
                            </TableCell>
                            <TableCell align="left">
                              <span style={{'width': 'min-content', 'display': 'inline-block'}}>
                                {
                                data?.jobOriginId === JobOriginIds.jobRialto ? 
                                tableData?.event?.interviewType : tableData?.event?.location
                                }
                              </span>
                            </TableCell>
                            <TableCell align="left">
                              <span style={{'width': 'min-content', 'display': 'inline-block'}}>
                                {moment(tableData?.createdDate).format(
                                  "MMMM DD, YYYY"
                                )}
                              </span>
                            </TableCell>
                            <TableCell align="left">
                              <div style={{ display: "flex" }}>
                                <SaveAltOutlinedIcon
                                  onClick={() =>
                                    downloadFile(
                                      resume?.resourceUrl,
                                      resume?.fileName
                                    )
                                  }
                                  data-testid="downloadFile"
                                  color="primary"
                                  sx={{ marginRight: 1 }}
                                  className="pointer"
                                />
                                <Tooltip title={resume?.fileName}>
                                  <span style={{'width': 'min-content', 'display': 'inline-block'}}>
                                    {truncateString(resume?.fileName, 10)}
                                  </span>
                                </Tooltip>
                              </div>
                            </TableCell>
                            { data?.jobOriginId === JobOriginIds.jobRialto && 
                                <>
                                <TableCell align="left"><StatusChipLabel label={getStatus(tableData?.status)} type="successChip"/> </TableCell>
                                {getCurrentStatus(data?.candidateFinalStatuses)?.status !== CandidateStatus.InterviewCancelledbyClient && tableData?.event?.interviewType?.toLowerCase() === "webcam" ?
                                <TableCell align="left"> <CopyLinkButton disabled={ index !== 0 } linkToCopy={tableData?.event?.interviewLocation?.meetingLink} /></TableCell>:<TableCell>N/A</TableCell>
                                }
                                </>
                              }
                              {
                                getCurrentStatus(data?.candidateFinalStatuses)?.status !== CandidateStatus.InterviewCancelledbyClient &&
                                <TableCell align="left">
                                <Button
                                  className="custom-submit-btn"
                                  disabled={index !== 0}
                                  onClick={onShareDetailClick}
                                  variant="contained"
                                >
                                  Share Details
                                </Button>
                              </TableCell>
                              }
                           
                          </TableRow>
                        )
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContentText>
          </DialogContent>
          <DialogActions></DialogActions>
        </Paper>
      </Dialog>
      <InterviewDetailPdf
        interviewData={tableArr && tableArr[0] ? [tableArr[0]] : []}
        resume={resume}
        shareInterviewDiv={shareInterviewDiv}
      ></InterviewDetailPdf>
      <Dialog
        open={shareInterviewState.popup}
        onClose={() => {
          setShareInterviewState({ ...shareInterviewState, popup: false });
        }}
        sx={{ width: "100%", alignSelf: "center" }}
      >
        <DialogContent sx={{ bgcolor: "#E6E9FB" }}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3} columns={1} textAlign={"center"}>
              <Grid item xs={1} textAlign={"left"}>
                <FormControl
                  variant="standard"
                  required
                  fullWidth
                  margin="normal"
                >
                  <InputLabel htmlFor="email">Enter Email address</InputLabel>
                  <Input
                    id="email"
                    name="email"
                    type="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  <ErrorMessage
                    errorText={formik.touched.email && formik.errors.email}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={1}>
                <Button
                  type="submit"
                  variant="contained"
                  data-testid="btn-dialog-share-details"
                >
                  Share Details
                  <NearMeOutlinedIcon />
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default InterviewDetailsPopup;