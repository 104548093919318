import {
  FormControl,
  Grid,
  Input,
  InputLabel,
  TextField,
  Typography
} from "@material-ui/core";
import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { searchOptions } from "../../../../../constants/googlePlaceOption";
import getAddressForAutoFill from "../../../../../utils/getAddressForAutoFill";
import { ErrorMessage } from "../../../../ui/ErrorMessage";
import { valueOrDefault } from "../../../../../utils/complexityUtils";

export default function AddressDetailsForm(props: any) {
  const { formik, formError } = props;

  const renderFunc = ({
    getInputProps,
    suggestions,
    getSuggestionItemProps,
    loading,
  }:any) => (
    <div className="signupcomplete-autocomplete">
      <FormControl variant="standard" error={formik.touched?.address?.streetAddress && formError?.address?.streetAddress} fullWidth required>
      <InputLabel htmlFor="address-input">Address</InputLabel>
        <Input {...getInputProps()}   onBlur={formik?.handleBlur} data-testid="test-addressId" name="address.streetAddress" type="text" />
        <ErrorMessage errorText={formik.touched?.address?.streetAddress && formError?.address?.streetAddress} />

      </FormControl>
      <div className="autocomplete-dropdown-container">
        {loading && <div>Loading...</div>}
        {suggestions?.map((suggestion:any) => (
          <div key={suggestion?.description} {...getSuggestionItemProps(suggestion)}>
            <span onChange={handleSelect} style={{ cursor: 'pointer' }}>{suggestion?.description}</span>
          </div>
        ))}
      </div>
    </div>
  );

  const handleSelect = async (address:any) => {
    let resp = await getAddressForAutoFill(address)
 
     formik.setFieldValue("address.streetAddress", valueOrDefault(resp?.formattedAddress, ""));
     formik.setFieldValue("address.id",valueOrDefault(resp?.city, ""));
     formik.setFieldValue("address.postalCode", valueOrDefault(resp?.pin, ""));
     formik.setFieldValue("address.countryCode", valueOrDefault(resp?.country, ""));
     formik.setFieldValue("address.state", valueOrDefault(resp?.state, ""));
     formik.setFieldValue("address.city", valueOrDefault(resp?.city, ""));
     
     formik.setFieldTouched("address.streetAddress",false)
     formik.setFieldTouched("address.id",false)
     formik.setFieldTouched("address.postalCode",false)
     formik.setFieldTouched("address.countryCode",false)
     formik.setFieldTouched("address.state",false)
     formik.setFieldTouched("address.city",false)
     
   }

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography className="signUpFormHeading" variant="h6" gutterBottom>
            Address Details
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <PlacesAutocomplete
            data-testid={'clientAddress'}
            searchOptions={searchOptions}

            value={formik.values.address?.streetAddress}
            onChange={(ev:any) => {
                formik.setFieldValue("address.streetAddress", ev);
            }}
            onSelect={handleSelect}
            >
            {renderFunc}
            </PlacesAutocomplete>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="city"
            name="address.city"
            label="City"
            value={formik.values?.address?.city}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched?.address?.city && formError?.address?.city}
            helperText={formik.touched?.address?.city && formError?.address?.city}
            fullWidth
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="state"
            name="address.state"
            label="State/Province"
            value={formik.values.address?.state}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched?.address?.state && formError?.address?.state}
            helperText={formik.touched?.address?.state && formError?.address?.state}
            fullWidth
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="postalCode"
            name="address.postalCode"
            label="ZIP/Postal Code"
            value={formik.values.address?.postalCode}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched?.address?.postalCode && formError?.address?.postalCode}
            helperText={formik.touched?.address?.postalCode && formError?.address?.postalCode}
            fullWidth
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
        <TextField
            required
            id="countryCode"
            name="address.countryCode"
            label="Country"
            value={formik.values.address?.countryCode}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched?.address?.countryCode && formError?.address?.countryCode}
            helperText={formik.touched?.address?.countryCode && formError?.address?.countryCode}
            fullWidth
            type="text"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
