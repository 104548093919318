import React from 'react';
import { Box, Typography, Card, Grid, Button } from '@mui/material';
import SuccessIcon from '../../../assets/images/Approved.svg';
import { useNavigate } from 'react-router-dom';
interface ContractSuccessMessageProps {
  email: string | null;
}

const ContractSuccessMessage: React.FC<ContractSuccessMessageProps> = ({ email }) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '90vh',
        padding: 3,
      }}
    >
      <Card>
        <Box sx={{ maxWidth: 600, textAlign: 'center',  padding:2}}>
        <figure>
            <img
              src={SuccessIcon}
              className="PopupStatusIcon"
              alt="Success Icon"
            />
          </figure>
          <Typography component="div" className="PopupPrimaryTitle" my={2}>
              Your Contract Has Been Signed Successfully!
            </Typography>
          <Typography variant="body1" className="PopupSecondaryTitle">
            Thank you for completing the contract signing process. A confirmation email, including a copy of your signed contract,
            has been sent to your email: <strong>{email}</strong>.
          </Typography>
          <Typography variant="body1" className="PopupSecondaryTitle" sx={{ mt: 1 }} >
            Please check your inbox to review and keep it for your records.
          </Typography>
          <Typography variant="body1" className="PopupSecondaryTitle">
            If you have any questions or need further assistance, feel free to contact us.
          </Typography>
          <Box my={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} >
                <Button
                  className="roboto-font"
                  sx={{ marginRight: 2, marginTop: "5px", fontSize: "1rem" }}
                  variant="outlined"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Go to Home Page
                </Button>
                <Button
                  className="roboto-font mobile-margin"
                  sx={{ marginTop: "5px", fontSize: "1rem" }}
                  variant="contained"
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  Login
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default ContractSuccessMessage;
