
import Grid from "@mui/material/Grid";
import Header from "../Common/Navigation/Header";
import { LeftNavigationLayout } from "../Layout/LeftNavigationLayout";
import { Button, Typography } from "@mui/material";
import Not404 from "./../../assets/images/GroupNotFound6.svg";
import "./NotFound.css";
import getLoggedInUserRole from "../../Shared/utils/getLoggedInUserRole";
import { UserAuth } from "../../models/interfaces";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const NotFound = () => {
  let navigate = useNavigate();
  let userRole = getLoggedInUserRole();
  return (
    <>

      <Header />
      <LeftNavigationLayout/>
      <Grid container justifyContent={"center"}>
      <Grid item xs={12} md={12} className="image-txt">
        <Typography
          sx={{ padding: userRole === UserAuth.NotLoggedIn ? "0" : "",fontSize:{xs:"1.3rem !important",sm:"1.8rem !important",md:"29px !important"}  }}
          className="notfound"
        >
          {" "}
          We apologize!<br></br> Seems like you got lost in your quest to locate
          the right<br></br> job for your candidate.
        </Typography>
        <div>
        <img
          src={Not404} alt="Not404" 
           className={userRole === UserAuth.NotLoggedIn ? "image-txts" : " "}
        />
        {userRole === UserAuth.NotLoggedIn?   <Button
          size="small"
          variant="contained"
          style={{
            textTransform: "capitalize",
            width: "68px",
            display: "flex",
            justifyContent: "space-between",
            position: "relative",
            bottom: "160px",
            left: "20%",
           
          }}
          // autoFocus
          onClick={() => {
            navigate(" ");
          }}
        >
          <ArrowBackIcon  style={{ width: "16px" }} /> Home
        </Button> : ""}
        </div>
      </Grid>
      </Grid>
    </>
  );
};
