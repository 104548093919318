import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';
import logo from "./../../../assets/images/logo.svg";

const BlankHeader: React.FC = () => {
  return (
    <AppBar
      className="main-header"
      style={{ background: '#fff', color: '#000' }}
    >
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="span"
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
          >
            <Link to="/">
              <img src={logo} className="logo" alt="logo" />
            </Link>
          </Typography>
          <Typography
            variant="h6"
            noWrap
            component="span"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
            <Link to="/">
              <img src={logo} className="logo" alt="logo" />
            </Link>
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default BlankHeader;
