import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Alert, Typography, IconButton, Tooltip } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { GetRecruiters } from "../../Services/recruiterService";
import { useSearchParams } from "react-router-dom";
import { DeleteIcon } from "../Common/Icons/DeleteIcon";
import ConfirmDelete from "./components/ConfirmDelete";
import CloseIcon from "@mui/icons-material/Close";
import "./incompletesignup.scss";
import moment from "moment";
import AppInfiniteScroll from "../Common/AppInfiniteScroll";
import truncateString from "../../utils/truncateString";

function IncompleteSignup({ handleClose1, onRecruiterDeleteSuccess }) {
  const [searchParams] = useSearchParams();
  const initialRecruiterFilterState = {
    pageIndex: 1,
    pageSize: 10,
    sortColumn: "CreatedDate",
    sortOrder: "Descending",
    isSorted: true,
    totalRecord: 0,
    searchKeywords: "",
    status:
      searchParams.get("status") == null ? [] : [searchParams.get("status")],
    filterByMasterData: {
      locationFilterInputs: [],
      domainFilterInputs: [],
      industryFilterInputs: [],
      skillsFilterInputs: [],
    },
    showIncompleteSignUps: true,
  };
  const [filter, setFilter] = React.useState(initialRecruiterFilterState);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [hasMoreData, setHasMoreFlag] = React.useState(null);
  const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);
  const [item, setItem] = React.useState();
  React.useEffect(() => {
    getRecruiters();
  }, [filter.pageIndex]);
  const getRecruiters = (search = false) => {
    setLoading(true);
    GetRecruiters(filter)
      ?.then((response) => {
        if (response.data.entityList.length < filter.pageSize) {
          setHasMoreFlag(false);
        } else {
          setHasMoreFlag(true);
        }
        let responseData = response.data.entityList.map((item) => ({
          Recruiter: `${item.firstName ?? ""}  ${item.middleName ?? ""} ${
            item.lastName ?? ""
          }`,
          firstName: item.firstName,
          middleName: item.middleName,
          lastName: item.lastName,
          email: item.email,
          imageUrl: item.imageInfo?.imageUrl,
          mobile: item.phoneNumbers?.primary ?? "",
          provider: item.company,
          location: `${item.address?.city ?? ""} ${
            item.address?.state ? "" + ", " + item.address?.state : "N/A"
          }`,
          Feedback:
            item.feedbackNotes[item.feedbackNotes.length - 1]
              ?.feedbackDescription ?? "N/A",
          Status: item.status,
          id: item.id,
          totalRecords: response.data.totalRecords,
          createdDateEST: item.createdDateEST,
        }));
        if (search) {
          setData([...responseData]);
        } else {
          setData([...data, ...responseData]);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const fetchMoreData = () => {
    setFilter({ ...filter, pageIndex: filter.pageIndex + 1 });
  };
  return (
    <div>
      <Dialog
        className="custom-popup-border"
        open={true}
        onClose={handleClose1}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Paper elevation={5} style={{ backgroundColor: "#FFF" }}>
          <DialogTitle id="alert-dialog-title">
            <IconButton
              aria-label="close"
              data-testid="closeIconBtn"
              onClick={handleClose1}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography align="center" className="custom-incomplete-txt">
                    Incomplete Signup
                  </Typography>
                </Grid>
              </Grid>
              <Box sx={{ flexGrow: 1 }}>
                <TableContainer>
                  <AppInfiniteScroll
                    dataLength={data?.length}
                    next={fetchMoreData}
                    hasMore={hasMoreData}
                    isLoading={loading}
                    loader={true}
                    height="300px"
                  >
                    <Table sx={{ minWidth: 250 }} aria-label="simple table">
                      <TableHead className="custom-tablehead">
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell sx={{ minWidth: 170 }}>
                            Signed Up On
                          </TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className="custom-tablebody">
                        {data?.map((row, index) => (
                          <TableRow key={row?.createdDateEST ?? index}>
                            <TableCell>
                              {row?.firstName && row?.lastName
                                ? row?.firstName + " " + row?.lastName
                                : "N/A"}
                            </TableCell>
                            <Tooltip title={row.email}>
                              <TableCell>
                                {truncateString(row?.email, 16)}
                              </TableCell>
                            </Tooltip>
                            <TableCell>
                              {moment(row?.createdDateEST).format(
                                "MMMM DD, YYYY  h:mm A"
                              )}
                            </TableCell>
                            <TableCell>
                              <DeleteIcon
                                style={{ cursor: "pointer", color: "#d32f2f" }}
                                onClick={() => {
                                  setOpenConfirmDelete(true);
                                  setItem(row);
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    {loading && (
                      <p
                        style={{
                          marginTop: "50px",
                          marginBottom: "4px",
                          textAlign: "center",
                        }}
                      >
                        Please wait...
                      </p>
                    )}
                    {!loading && data.length === 0 && (
                      <Alert severity="info" style={{ marginTop: "5px" }}>
                        No Records Found
                      </Alert>
                    )}
                  </AppInfiniteScroll>
                </TableContainer>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions></DialogActions>
        </Paper>
      </Dialog>
      {openConfirmDelete && (
        <ConfirmDelete
          item={item}
          openPopup={openConfirmDelete}
          handleConfirmationClose={() => setOpenConfirmDelete(false)}
          handleClose1={handleClose1}
          onRecruiterDeleteSuccess={onRecruiterDeleteSuccess}
        />
      )}
    </div>
  );
}

export default IncompleteSignup;
