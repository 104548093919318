
export  enum CandidateSubmissionStatus {

    // Active submission status
    // Note: CLI means client

    CandidateSubmitted = "0233cd95-7653-4bae-8ca1-8cb055681b65",
    SubmittedToAM = "25df8d7e-58b4-449c-aa3f-9cec8653985b",
    SubmittedToCLI = "97b763e8-3ce1-47a8-9f62-be9175b70f4d",
    RTROverdue = "0edba1ab-0224-4624-b75d-ad706d43ce54",
    InterviewScheduled = "31ce0b36-a11f-4b94-98c2-481bc84af498",
    CandidateSelected = "64768219-524e-4e16-9f85-2dc51d41b03c",
    CandidateSelectedInInterview = "74165ff8-d969-466d-a465-0f1b1d95aa2e",
    PendingCLIReview = "29b61f9d-ced5-47c1-9bbf-09b7b523fc54",
    NextRoundOfInterview = "65adbba6-a385-45cd-b630-2d97a7b13908",
    InterviewRescheduled = "e750fe42-4acf-437c-9ade-89b319920116",
    InterviewFeedbackPending = "ffbb21bb-d30a-4732-b81e-e3eb6a3f7a10",
    HiredOfferAccepted = "13d8310b-a152-41fe-bb84-2ce2716d662a",
    StartSheetInitiated = "c1e6bf81-f1e9-4bff-a2f2-fd838c3586a7",
    CandidateContractActive = "cb083aae-7ed7-4417-b044-92f4a88b975c",
    RecruiterAssigned = "ed01c25b-f591-4763-a19d-cddb69dc1075",
    PendingRecruiterReview = "1f258947-0e3a-4f86-b13a-996e457c221a",
    InterviewCancelled = "32592982-ac16-40e9-a34b-b9d42a907af6",
    //Archive status

    RTRDeclined = "9ac7ce5a-a161-441a-8b4a-24f1fdc8f568",
    RTRExpired = "823c14dd-f70f-4610-b366-fff9137d9106",
    RejectedByAM = "588f12f4-72e8-423c-8ddb-1e29c098a33c",    
    RejectedInInterview = "14842a65-f79f-42f1-b212-e66955c2fb23",    
    OfferDeclined = "b059545c-2ac5-4114-94f3-2791e0e7e278",
    RejectedByAdmin = "af3abdbe-6ea1-4792-bbe2-7f19add07084",
    RejectedByRecruiter = "ea0951a5-0c65-4b7c-9768-7d0d64f8ce7c",
    RejectedByCLI = "87930fb5-a3b5-4b68-9354-cf0a713f5b97",
    ContractEnds = "qw083aae-7ed7-4407-b044-92f4a88b977c",
}
