import * as React from "react";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import TeamMemberSearcxhBar from "../../Client/TeamMember/TeamMemberSearcxhBar";
import MemberListingTable from "../../Client/TeamMember/MemberListingTable";
import { BreadCrumbItem } from "../../../models/AppModels";
import { getNevLink } from "../../../utils/getFileFromUrl";
import AppBreadCrumb from "../../Common/AppBreadCrumb";

const DefaultBreadcrumbs: BreadCrumbItem[] = [
  { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink("/dashboard") },
  { isLeaf: true, sequence: 2, title: "Team Members", leafClasses:" v2LastLeft"},
];

export default function TeamMembers() {

  // logger user details from redux state
  const { recruiterData } = useSelector((state: any) => state.recruiter);
  const userStatus = recruiterData?.entity || {};
  let isApprovedUser =  (userStatus?.status && userStatus?.status === "Approved") || userStatus?.isActive === true

  console.log("userStatus",userStatus);
  
  const [totalRecords, setTotalRecords] = React.useState<number|undefined>(0);

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <AppBreadCrumb   classes="custom-appbreadcrumb" BreadCrumbItems={DefaultBreadcrumbs} />
      </Grid>        
      <Grid item xs={12}>
        <TeamMemberSearcxhBar totalRecords={totalRecords} isApprovedUser={isApprovedUser}/>
        <MemberListingTable
          totalRecords={totalRecords} 
          setTotalRecords={setTotalRecords} 
          isApprovedUser={isApprovedUser}
          />
      </Grid>
    </Grid>
  );
}
