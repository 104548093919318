import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Picture from "./../../assets/images/forgotpassword_banner.svg";

export const ForgotPasswordBanner = () => {
  return (
    <Box className="forgotpwd-banner-wrapper">
      <Box className="forgotpwd-banner-typography-text">
        <Typography
          variant="h2"
          component="span"
          className="custom-banner-txt"
          sx={{
            fontSize: {
              xs: "28px !important",
              sm: "28px !important",
              md: "28px !important",
            },
          }}
        >
          That's okay, it happens
        </Typography>
      </Box>
      <Box className="banner-bg login-banner-right">
        <img className="forgotpwd-banner-image" src={Picture} alt="" />
      </Box>
    </Box>
  );
};
