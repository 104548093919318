import { createSvgIcon } from "@mui/material/utils";
import React from "react";

interface EditProps  {
  color?: string;
}

export const EditAppIcon: React.FC<EditProps> = ({ color = "#4540DB" }) => {
  const SvgComponent = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 20" fill="none">
        <path d="M1.63711 18.3629H2.83765L14.925 6.27558L13.7244 5.07503L1.63711 17.1623V18.3629ZM18.3902 5.10232L14.8977 1.60982L16.0437 0.463847C16.3529 0.154616 16.7349 0 17.1896 0C17.6444 0 18.0264 0.154616 18.3356 0.463847L19.5362 1.66439C19.8454 1.97362 20 2.35562 20 2.81037C20 3.26512 19.8454 3.64711 19.5362 3.95634L18.3902 5.10232ZM17.2442 6.24829L3.4925 20H0V16.5075L13.7517 2.7558L17.2442 6.24829ZM14.3247 5.67531L13.7244 5.07503L14.925 6.27558L14.3247 5.67531Z" fill={`${color ? color : '#4540DB' }`}/>
    </svg>,
    "EditAppIcon"
  );

  return <SvgComponent />;
};
