import Grid from "@mui/material/Grid";
import React from "react";
import { BreadCrumbItem } from "../../../models/AppModels";
import CandidatesList from "../../Candidate/CandidateList/CandidatesList";
import AppBreadCrumb from "../../Common/AppBreadCrumb";
import { getNevLink } from "../../../utils/getFileFromUrl";
import { ternary } from "../../../utils/complexityUtils";
import getLoggedInUserRole from "../../../Shared/utils/getLoggedInUserRole";
import { UserAuth } from "../../../models/interfaces";


const CandidateListPage: React.FC = () => {

  const userRole = getLoggedInUserRole();

const DefaultBreadCrumbs: BreadCrumbItem[] = [
  { isLeaf: false, sequence: 1, title: "Dashboard", to:getNevLink('/dashboard') },
  {
    isLeaf: true,
    sequence: 3,
    title: ternary(userRole === UserAuth.Recruiter ,"My Candidates", "All Candidates"),
    leafClasses: " v2LastLeft",
  },
];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AppBreadCrumb
          classes="custom-appbreadcrumb"
          BreadCrumbItems={DefaultBreadCrumbs}
        />
      </Grid>
      <Grid item xs={12}>
        <CandidatesList />
      </Grid>
    </Grid>
  );
};

export default CandidateListPage;
