import { createSvgIcon } from "@mui/material/utils";

export const CheckBoxSquareIcon = createSvgIcon(
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="19" height="19" rx="2.5" fill="#DCDCDC" stroke="#DCDCDC"/>
<path d="M5 15L15 5" stroke="#262626" stroke-width="2"/>
<path d="M15 15L5 5" stroke="#262626" stroke-width="2"/>
</svg>
    ,
  "CheckBoxSquareIcon"
);