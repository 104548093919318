import { createSlice } from "@reduxjs/toolkit";
import { LoginTypeName } from "../../../models/interfaces";

interface ClientSignUpState {
  id: string;
  email: string,
  companyName: string;
  companyWebsite: string;
  industries: string[],
  companySize: string;
    contactDetails: {
    firstName: string;
    fullName: string;
    lastName: string;
    middleName: string;
    position: string;
    primaryPhoneNumber: string;
    isPrimaryPhoneVerified: boolean,
    secondaryPhoneNumber: string;
  },
  address: {
    id: string;
    streetAddress: string;
    city: string;
    state: string;
    postalCode: string;
    countryCode: string;
  },
  clientAdditionalDetails: {
    companyLogo: {
      resourceUrl: string;
      fileName: string;
      newFileName: string;
    },
    companyOverview: string;
    companyBenefits: string;
    hiringProcess: string;
    linkedInProfileUrl: string;
  },
  password: string;
  confirmPassword: string;
  loginType: string;
  consentPolicyConfirm:boolean;
  contractConfirm: boolean;
  signatureRequestId: string;
  signatureId: string;
  contractDocumentId: string;
  contractStatusCode: string;
}

const defaultInitalState : ClientSignUpState = {
    id: "",
    email: "",
    companyName: "",
    companyWebsite: "",
    industries: [],
    companySize: "",
    contactDetails: {
        firstName: "",
        fullName: "",
        lastName: "",
        middleName: "",
        position: "",
        primaryPhoneNumber: "",
        isPrimaryPhoneVerified: false,
        secondaryPhoneNumber: "",
    },
    address: {
        id: "",
        streetAddress: "",
        city: "",
        state: "",
        postalCode: "",
        countryCode: "",
    },
    clientAdditionalDetails: {
        companyLogo: {
        resourceUrl: "",
        fileName: "",
        newFileName: "",
        },
        companyOverview: "",
        companyBenefits: "",
        hiringProcess: "",
        linkedInProfileUrl: "",
    },
    password: "",
    confirmPassword: "",
    loginType: LoginTypeName.Email_UserID,
    consentPolicyConfirm:false,
    contractConfirm: false,
    signatureRequestId: "",
    signatureId: "",
    contractDocumentId: "",
    contractStatusCode: ""
}
export const clientSignUpFormSlice = createSlice({
  name: "clientSignUpFormSlice",
  initialState: defaultInitalState,
  reducers: {
    setClientSignUpFormState: (state,action) => {
        return action.payload;
    }
   
},
  
});

// Action creators are generated for each case reducer function
export const {setClientSignUpFormState } =
clientSignUpFormSlice.actions;

export default clientSignUpFormSlice.reducer;