export interface ApiResponse<T> {
  errorMessage?: string;
  responseCode?: string;
  data?: T;
}
export enum LoginType {
  Google,
  LinkedIn,
  Facebook,
  Email_UserID,
}

export enum LoginTypeName {
  Google = "Google",
  LinkedIn = "LinkedIn",
  Facebook = "Facebook",
  Email_UserID = "Email_UserID",
}
export enum LoggedInUserRole {
  Admin = "Admin",
  Recruiter = "Recruiter",
}
export enum CommissionTypes {
  DirectContract = "DirectContract",
  W2_Employee = "W2_Employee",
  FullTime = "FullTime",
  OneTimeCommission = "OneTimeCommission",
}
export enum ContractType {
  FullTime = "Full Time",
  Contract = "Contract",
}
export enum CandidateStatus {
  CandidateShortlisted = "Candidate Shortlisted",
  CandidateHired = "Candidate Hired",
  UnderReview = "Under Review",
  ApprovedByAM = "Approved by AM",
  PendingClientReview = "Pending Client Review",
  InterviewRescheduledbyClient = "Interview Rescheduled by Client",
  NextRoundInterview = "Next Round Interview",
  InterviewFeedbackDue = "Interview Feedback Due",
  SelectedinInterview = "Candidate Selected in Interview",
  RejectedbyAM = "Rejected by AM",
  CandidatenotQualified = "Candidate not Qualified",
  InterviewCancelledbyClient = "Interview Cancelled by Client",
  CandidateRejectedbyClient = "Candidate Rejected by Client",
  CandidateDeclinedOffer = "Candidate Declined Offer",
  CandidateContractActive = "Candidate Contract Active",
  RejectedByClient = "Rejected by client",
  RejectedInInterview = "Rejected in interview",
  OfferDeclined = "Offer declined",
  CandidateSubmitted = "Candidate Submitted",
  RTRDeclined = "Candidate RTR Declined",
}

export enum ExternalCandidateStatus {
  SubmittedToAm = "Submitted to AM",
  SubmitedToClient = "Submitted to client",
  RejectedByAM = "Rejected by AM",
  InterviewScheduled = "Interview scheduled",
  InterviewRescheduled = "Interview Rescheduled / Cancelled",
  InterviewFeedbackPending = "Interview feedback pending",
  CandidateShortListed = "Candidate Shortlisted",
  RejectedByClient = "Rejected by client",
  RejectedInInterview = "Rejected in interview",
  Hired = "Hired – Offer Accepted",
  OfferDeclined = "Offer Declined",
  CandidateContractActive = "Candidate Contract Active",
  StartSheetInitiated = "Start Sheet Initiated",
}

export enum CandidateFinalStatuses {
  CandidateSubmitted = "Candidate Submitted",
  SubmittedToClient = "Submitted to Client",
  CandidateRTROverDue = "Candidate RTR Overdue",
  SubmittedToAM = "Submitted to AM",
  ApprovedByAM = "Approved by AM",
  PendingClientReview = "Pending Client Review",
  PendingRecruiterReview = "Pending Recruiter Review",
  RecruiterAssigned = "Recruiter Assigned",
}
export enum InterviewEvents {
  CandidateShortlisted = "Candidate Shortlisted",
  InterviewRescheduledbyClient = "Interview Rescheduled by Client",
  NextRoundInterview = "Next Round of Interview",
}
export enum Roles {
  SuperAdminId = "d12eb4af-431d-453e-a6b9-a8bc6c84a06d",
  AdminId = "1478a7f6-a5af-47c6-944e-7df4788a1e87",
  RecruiterId = "6813b8de-0b23-40dd-8779-5230b98ed4f5",
  TeamMember = "d93f6299-2bee-4e06-8711-eb395219b740",
  UserRole = "944c0a74-6ec5-44f1-b54c-dc75bc6a081e",
}
export enum DocumentTypeEnum {
  UserProfileImg = "UserProfileImg",
  Resume = "Resume",
  IndianBilling = "IndianBilling",
  USBilling = "USBilling",
  Other = "Other",
  RTR = "RTR",
  W9 = "W9",
  InterviewTemplate = "InterviewTemplate",
  RecruiterScreening = "RecruiterScreening",
  RecruiterSignedContract = "RecruiterSignedContract"
}
export interface RecruiterSignupResponse {
  entity: Object;
  entityList: Object[];
  status: number;
  message: {
    appStatusCode: string;
    appStatusDescription: string;
  };
}
export type Nullable<T> = T | null;

export interface DomainModel {
  id: string;
  domainName: string;
}
export interface SkillModel {
  id: string;
  industryName: string;
}
export interface LocationModel {
  id: string;
  zipCode: string;
  city: string;
  county: string;
  state: string;
  countyFIPS: string;
  stateFIPS: string;
  timeZone: string;
  dayLightSavings: string;
  zipLatitude: string;
  zipLongitude: string;
}

export interface Message {
  appStatusCode: string;
  appStatusDescription: string;
}
export interface MasterData<T> {
  entityList: T[];
  entity: T;
  totalRecords: number;
  status: number;
  message: Message;
}
export interface MasterModel {
  pageIndex: number;
  pageSize: number;
  sortColumn: string;
  sortOrder: string;
  isSorted: boolean;
  totalRecord: number;
  id: string;
  domainName: string;
  keywordDomainNameSearch: string;
}
export enum VerifyRecruiterFeedbackTypeEnum {
  Good,
  VeryGood,
  Excellent,
  Poor,
}
export enum VerifyRecruiterStatusEnum {
  Pending,
  Approved,
  Rejected,
  MarkIncomplete,
  Suspended,
  Blacklisted,
}
export const RecruiterStatuses: string[] = [
  "Pending",
  "Approved",
  "Rejected",
  "MarkIncomplete",
  "Suspended",
  "Blacklisted",
];
export enum BillingInfoType {
  NotUpdated,
  Payoneer,
  USA,
  Other,
}

export enum RecruiterStatus {
  Pending,
  Approved,
  Rejected,
  MarkIncomplete,
  Suspended,
  Blacklisted,
}

export enum RecruiterStatusesId {
  Pending = "a90d221d-9d07-4039-82e2-b5210ca9f932",
  Approved = "d0a70c50-e17f-4650-abba-b5862b66f6ec",
  Rejected = "3cd313c8-db48-4b03-bd4f-c26f888ab6d4",
  MarkIncomplete = "60eb2af0-dfa2-41ce-80fd-8c4b61be2b3b",
  Suspended = "4be66f49-dcc0-492a-beee-b17c9e0bb0b3",
  Blacklisted = "fa44f1b6-6841-4b10-99e4-3dfb4717e957",
}
export enum ClientStatusesId {
  Pending = "de091dde-07f2-43ab-8a08-d7bedb229f37",
  Approved = "686c9635-5d4e-4c2e-bb28-fc8c613ce2f9",
  Rejected = "710ba227-2b64-44d3-b23b-3dcdafbda276",
  Suspended = "f198099a-54af-4980-ba20-1a0357eca13c",
}

export enum UserAuth {
  Admin,
  Recruiter,
  SuperAdmin,
  Client,
  ClientTeamMember,
  NotLoggedIn,
}

export enum UserAuthByRoleName {
  Admin = "Admin",
  Recruiter = "Recruiter",
  NotLoggedIn = "notLoggedIn",
  SuperAdmin = "SuperAdmin",
  Candidate = "Candidate",
}
export enum CandidateAuthorizationStatus {
  Due,
  Declined,
  Authorized,
  Expired,
  Overdue,
}

export enum CommissionTypeNames {
  DirectContract = "Direct Contract/C2C",
  W2_Employee = "Contract (W2)",
  FullTime = "Full Time",
  OneTimeCommission = "One Time Commission",
}