import { isValid, parse } from 'date-fns';


export const validateDate = (
    value: string | null | undefined,
    dateFormat: string
) => {
    value = value || ''
    return value === '' ? true : isValid(parse(value, dateFormat, new Date()))
}

export const isDateRangeValid = (
    firstDate: string,
    secondDate: string,
    dateFormat: string,
    isReversed?: boolean
) => {
    const startDate = parse(firstDate, dateFormat, new Date())
    const endDate = parse(secondDate, dateFormat, new Date())
    if (isValid(startDate) && isValid(endDate))
        return !isReversed ? endDate >= startDate : startDate >= endDate
    return true
}

export const checkDate = (
    firstDate: string | null | undefined,
    secondDate: string,
    dateFormat: string,
    isReversed?: boolean
) => {
    return !(
        validateDate(firstDate, dateFormat) &&
        isDateRangeValid(
            firstDate as string,
            secondDate,
            dateFormat,
            isReversed
        )
    )
}
export const  formatAMPM = (date:string)  => {
    if(date==null || date=='' || date ==undefined)
  {
    return date;
  }
    let newDate = new Date("2022-11-16T"+date)
 
     let hours = newDate.getHours();
     let minutes : any = newDate.getMinutes();
     let ampm = hours >= 12 ? 'pm' : 'am';
     hours = hours % 12;
     hours = hours ? hours : 12; // the hour '0' should be '12'
     minutes = minutes < 10 ? '0'+minutes : minutes;
     let strTime = hours + ':' + minutes + ' ' + ampm;
     return strTime;
   }

   export const isPastDate = (currDate: any) => {

    const today = new Date();
    const curr = new Date(currDate)

    return curr < today
}
