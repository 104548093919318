import React, { useEffect, useRef } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import "react-multi-carousel/lib/styles.css";
import "./react_multi_carousel.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Carousel from "react-multi-carousel";
import { useNavigate } from "react-router-dom";
import useApi from "../../hooks/useApi";
import { GetFeaturedJobs } from "../../Services/jobService";
import { UserAuth } from "../../models/interfaces";
import getLoggedInUserRole from "../../Shared/utils/getLoggedInUserRole";
import featuredJobIcon from "../../assets/images/featured-job.svg";
import { useDispatch, useSelector } from "react-redux";
import { RecruiterStatus } from "../../models/enumerations";
import { getNevLink } from "../../utils/getFileFromUrl";
import { handleCheckboxFilter } from "../../features/Jobs/clientJobSlice";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));



const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 4, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 600 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

export default function FeaturedJobs() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const featureJobAPI = useApi(() => GetFeaturedJobs());
  const{recruiterData} = useSelector((state) => state.recruiter)
  let userStatus = recruiterData?.entity?.status
  useEffect(() => {
    featureJobAPI.request();
  }, []);
  //1 for left and 2 for right
  const [activeArrow, setactiveArrow] = React.useState(0)
  let CarouselRef = useRef(null);

  const handleNext = () => {
    setactiveArrow(2)
    CarouselRef.next()
  }
  const handlePrev = () => {
    setactiveArrow(1)

    CarouselRef.previous()
  }

  const _handlefeatureJobIcon = () =>{
    dispatch(
      handleCheckboxFilter({
        filterType: "featured",
        filterValue: true,
      })
    );
    navigate('/jobs/all');
  }

  const renderCarousel = () => {
    if (!featureJobAPI.loading && featureJobAPI.data) {
      return <Carousel
        ref={el => (CarouselRef = el)}
        swipeable={true}
        draggable={false}
        showDots={true}
        responsive={responsive}
        ssr={false} // means to render carousel on server-side.
        infinite={true}
        slidesToSlide={4}
        autoPlay={true}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        transitionDuration={500}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        arrows={false}
      >
        {featureJobAPI.data?.entityList.map((item, i) => (
          <Item elevation={2} className="home-feature-jobs-card" key={ item?.id ??  i}>
            <Card elevation={1} sx={{ padding: "20px", margin: "20px 13px", height: "320px"}} spacing={10}>
              <Grid container padding={1}>
                <Grid item md={8}>
                {( getLoggedInUserRole() !== UserAuth.NotLoggedIn) && userStatus==RecruiterStatus.approved  && (<div>{item.clientName}</div>)}
                                    {`${item.city + ", " + item.stateCode} ${item.remoteJob?.toLowerCase()=='y'? "(Remote)":""}`}
 <br></br>
                  {item.jobType}
                </Grid>
              </Grid>

              <CardContent sx={{ paddingTop: 0, padding:"8px" }}>
                <span onClick={_handlefeatureJobIcon}>

                  <Typography variant="h5" color={'blue'} component="div" sx={{cursor:"pointer"}}>
                  
                    {item?.jobTitle} <span><img  src={featuredJobIcon} /></span>

                  </Typography>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    component="div"
                  >
                    {item?.subtitle}
                  </Typography>
                  <Typography style={{ fontWeight: '15px' }} variant="body2" color="text.secondary">
                    {item?.jobShortDesc}
                  </Typography>
                </span>
              </CardContent>
              <CardActions
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  bottom: '30px',
                  position: "absolute",
                  width: "222px",
                  height: "30px",
                  paddingRight: "300px"
                }}
              >
                <Box sx={{ paddingRight: '5px' }}>
                  <Typography variant="caption" className="price featuredjob" >

                  </Typography>
                 
                </Box>
                <Button 
                 data-testid="Sub"
                  onClick={() => {
                    getLoggedInUserRole()!==UserAuth.NotLoggedIn?
                    navigate(getNevLink(`/submitresume/${item?.id}`)):
                    navigate("/login",{state:{redirectUrl:`/submitresume/${item?.id}`}});
                  }}
                  variant="contained"
                >
                  Submit
                </Button>
              </CardActions>
            </Card>
          </Item>
        ))}

      </Carousel>
    }
  }

  return (
    <Container
      maxWidth="xl"
      className="home-feature-jobs"
      sx={{
        marginTop: "16px",
        paddingTop: "30px",
        paddingBottom: "30px",
      }}
    >
      <Typography
        variant="h4"
        color="inherit"
        component="div"
        sx={{ paddingBottom: "30px", fontWeight: "600", textAlign: "center" }}
      >
        Featured Jobs
      </Typography>

      <Grid container alignItems={'center'}>
        <Grid item xs={.5}>
          <IconButton className="arrowleft" aria-label="prev" style={{ background: activeArrow==1? 'blue' : "#868F95", color: "white" }} onClick={handlePrev} data-testid="prevArrow">
            <ChevronLeftIcon className="arrowright featurejobicon"/>
          </IconButton>
        </Grid>
        <Grid item xs={11} sm={11} pl={"18px"}>
          {renderCarousel()}
        </Grid>
        <Grid textAlign="right"item xs={.5}>
          <IconButton sx={{fontSize:"1rem"}} className="arrowright" aria-label="prev" style={{ background: activeArrow==2? 'blue' : "#868F95", color: "white" }} onClick={handleNext} data-testid="nextArrow">
            <ChevronRightIcon  className="arrowright featurejobicon"/>
          </IconButton>
        </Grid>
      </Grid>
      <Box sx={{ paddingTop: "40px", textAlign: "center" }}>

        <Button onClick={() => navigate(getNevLink("/jobs/all"))} data-testid="browserMoreJobs" variant="outlined" className="Browse-jobs-font">Browse More Jobs</Button>
      </Box>
    </Container>
  );
}
