import { CandidatesAdminMenuIcon } from "../../components/Common/Icons/CandidatesAdminMunuIcon";
import { DashboardMenuIcon } from "../../components/Common/Icons/DashboardMenuIcon";
import { RecruiterAdminMenuIcon } from "../../components/Common/Icons/RecruiterAdminMeuIcon";
import { SubmissionsMenuIcon } from "../../components/Common/Icons/SubmissionsMenuIcon";
import circleOkIcon from "../../assets/images/circle-ok.svg";
import { AllJobsMenuIcon } from "../../components/Common/Icons/AllJobMenuIcon";
import { Jobs as JobsIcon } from "../../components/Common/Icons/Jobs";
import { PermissionsEnums } from "../permission";

export const clientNavItems = [
  {
    id: 1,
    name: "dashboard",
    label: "Dashboard",
    link: "/dashboard",
    icon: <DashboardMenuIcon />,
    permissionId: "",
    sub: [],
  },
  {
    id: 4,
    name: "useraccess",
    label: "User Access",
    link: "/role/manage",
    icon: <RecruiterAdminMenuIcon />,
    sub: [],
  },
  {
    id: 5,
    name: "Jobs",
    label: "Jobs",
    link: "/jobs",
    permissionId:PermissionsEnums.Jobs_View,
    icon: <JobsIcon className="icon-color-default" />,
    sub: [
        {
          id: 5.1,
          name: "alljobs",
          label: "All",
          link: "/jobs/all",
          permissionId: PermissionsEnums.Jobs_View,
          icon:   <AllJobsMenuIcon />,
        },
        {
          id: 5.2,
          name: "pendingjobs",
          label: "Pending Jobs",
          link: "/jobs/pending",
          permissionId: PermissionsEnums.Jobs_Pending,
          icon:   <AllJobsMenuIcon />,
        },
        {
          id: 5.5,
          name: "Add Job",
          label: "Add Job",
          link: "/addJob",
          permissionId: PermissionsEnums.Jobs_Add,
          icon:   <AllJobsMenuIcon />,
        },
        
      ],
  },
  {
    id: 7,
    name: "teammembers",
    label: "Team Members",
    link: "/client/TeamMembers",
    permissionId: PermissionsEnums.Members_Listing,
    icon: <CandidatesAdminMenuIcon />,
    sub: [],
  },
  {
    id: 6,
    name: "Submission",
    label: "Submissions",
    link: "/submission",
    permissionId: PermissionsEnums.Submissions_All,
    icon: <SubmissionsMenuIcon />,
    sub: [
      {
        id: 6.1,
        name: "Active",
        label: "Active",
        link: "/client/submission/active",
        permissionId: "",
        icon:  <img src={circleOkIcon} alt="circleOkIcon" />,
      },
      {
        id: 6.2,
        name: "Archived",
        label: "Archived",
        link: "/client/submission/archived",
        permissionId: "",
        icon:  <img src={circleOkIcon} alt="circleOkIcon" />,
      },
    ],
  },
  
 
  
];
