import React from "react";
import Grid from "@mui/material/Grid";
import LabelChip from "../LabelChip";
import SectionHeading from "../../../../ui/Form/Heading/SectionHeading";

interface ScorecardCandidateInfoProps {
  certifications: string[];
  className: any;
}

const KeywordsCertification: React.FC<ScorecardCandidateInfoProps> = ({
  certifications,
  className="",
}) => {
  return (
    <Grid container spacing={2} className="keyword-margin">
      <Grid item xs={12}>
        <SectionHeading title="Certification" />
      </Grid>

      {certifications?.length> 0 && certifications?.map((certificationName) => (
        <Grid item key={certificationName}>
          <LabelChip  label={certificationName} className={className} />
        </Grid>
      ))}
    </Grid>
  );
};

export default KeywordsCertification;
