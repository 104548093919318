import * as Yup from "yup";
import { addressRegex, cityRegex, experienceRegex, firstNameRegex, linkedInRegex, middleNameRegex, phoneNumberRegex, zipValidation } from "../../../../constants/regex";
import { DocumentTypeEnum } from "../../../../models/interfaces";
import { getDocumentsByType } from "../../../../utils/documentObjectUtils";

export const EditRecruiterValidationSchema = Yup.object({
    id: Yup.string(),
    firstName: Yup.string()
      .required("Please enter the first name")
      .matches(firstNameRegex, "Entered first name is not valid"),
    middleName: Yup.string().matches(
      middleNameRegex,
      "Entered middle name is not valid"
    ),
    lastName: Yup.string()
      .required("Please enter the last name")
      .matches(firstNameRegex, "Entered last name is not valid"),
    email: Yup.string().email("Entered email address is invalid").required("Please enter a valid email address"),
    experience: Yup.string()
      .required("Please enter number of years of experience")
      .matches(experienceRegex, "Please enter valid experience from 0 to 25"),
    domains: Yup.array().min(1, "Please select the domain"),
    interests: Yup.array().min(1, "Please select the interest"),
    address: Yup.object().shape({
      streetAddress: Yup.string()
        .required("Please enter the address")
        .matches(addressRegex, "Entered address is invalid"),
      city: Yup.string()
        .required("Please enter the city")
        .matches(cityRegex, "Entered city is invalid"),
      state: Yup.string()
        .required("Please enter the State")
        .matches(cityRegex, "Entered state is invalid"),
      postalCode: Yup.string()
        .required("Please enter the Zip")
        .matches(zipValidation, "Entered zip code is invalid"),
      countryCode: Yup.string()
        .required("Please enter the Country")
        .matches(cityRegex, "Entered country is invalid"),
    }),
    phoneNumbers: Yup.object().shape({
      primary: Yup.string()
        .required("Please enter the phone number")
        .matches(phoneNumberRegex, "Entered phone number is invalid"),
      secondary: Yup.string().matches(
        phoneNumberRegex,
        "Entered Phone number is invalid"
        ),
      }),
      linkedIn: Yup.string().matches(linkedInRegex, "LinkedIn URL doesn't match")
      .when('documentInfo', {
        is: (documentInfo:any[]) =>  getDocumentsByType(documentInfo, DocumentTypeEnum.Resume)?.length === 0,
        then: Yup.string().required("LinkedIn is required if no resume document is uploaded"),
        otherwise: Yup.string(),
      }),
  });