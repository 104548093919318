import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { JobOriginIds } from "../../../../models/Job/JobDetailsModel";
import { UserAuth } from "../../../../models/interfaces";
import { CandidateSubmissionStatus } from "../../../../models/submission/CandidateSubmissionMasterStatusesModel";
import "../../NewViewSubmission/styles.scss";

interface HiredOfferAcceptedButtonProps {
  row: any;
  userRole: UserAuth;
  currentjobDetails: any;
  setPopup: any;
}

export default function HiredOfferAcceptedButton({
  row,
  userRole,
  currentjobDetails,
  setPopup
} : HiredOfferAcceptedButtonProps) {
  return (
    <>
      {
        userRole === UserAuth.Admin ||
        userRole === UserAuth.SuperAdmin ? 
        (
          currentjobDetails?.jobOriginId === JobOriginIds.jobRialto &&
          row.finalStatusId === CandidateSubmissionStatus.CandidateSelected
          &&
          <Grid item>
            <Button
              className="submission-action-btn"
              variant="contained"
              color="primary"
              onClick={() => {
                setPopup((popup : any) => ({
                  ...popup,
                  hiredofferaccepted: true,
                }));
              }}
            >
              Hired Offer Accepted
            </Button>
          </Grid>
        )
        :
        ""
      }
    </>
  );
}