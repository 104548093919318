import React from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import thumbsup from "./../../../../assets/images/thumbsup.svg";
import axios from './../../../../Services/CommonHttpRequest'
import { UpdateJobNotificationSetting } from "../../../../Services/recruiterService"; 
import setAuthToken from "../../../../utils/setAuthToken"; 
import { LoggedInUserRole } from "../../../../models/interfaces"; 
import { useDispatch } from "react-redux";
import { getRecruiterData } from "../../../../features/recruiterSlice"; 
import { setLoggedInUseDetails } from "../../../../features/userDetails/loggedInUserDetailsSlice"; 
import getLoggedInUserPermissions from "../../../../Shared/utils/getLoggedInUserPermission";
import { loadPermissions } from "../../../../features/permission/userPermissionSlice"; 
import { CustomAlert } from "../../../ui/CustomAlert"; 

function SuccessPopup({ isOpen, authModel }) {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [pageStatus, setPageStatus] = React.useState({
    isSuccess: false,
    error: "",
    loading: false,
  });
  const handleNotificationChange = (value) => {
    let notificationModel = {
      id: authModel?.id,
      jobNotification: value,
    };
    setPageStatus({ isSuccess: false, error: "", loading: true });
    UpdateJobNotificationSetting(notificationModel)
      .then((response) => {
        if (response.data.status == 200) {
          setPageStatus({ isSuccess: true, error: "", loading: false });
        } else {
          setPageStatus({
            isSuccess: false,
            error: response.data?.message?.appStatusDescription,
            loading: false,
          });
        }
      })
      .catch((er) => {
        setPageStatus({
          isSuccess: false,
          error: er.message,
          loading: false,
        });
      });
  };
  function DoSignin() {
    console.log("Login Type is "+ authModel.loginType);
    if(authModel.loginType==="LinkedIn" || authModel.loginType=="Google"){
      navigate('/login')
    }
    let signinModel = {
      username: authModel.email,
      password:
        authModel.loginType === "Email_UserID" ? authModel.password : "",
      loginType: authModel.loginType,
      returnUrl: "",
      loggedInUserRole: LoggedInUserRole.Recruiter.toString()

    };
    axios
      .post(
        `${process.env.REACT_APP_IDPROVIDER_API}api/SignIn/Login`,
        signinModel,
        { headers: { "Content-Type": "application/json" } }
      )
      ?.then((res) => {
        if (res.data.status === 200) {
          localStorage.setItem("user", JSON.stringify(res.data.entity));
          setAuthToken(res.data.accessToken);
          dispatch(getRecruiterData())
          dispatch(setLoggedInUseDetails(res.data?.entity))
          let userModules = getLoggedInUserPermissions();
          dispatch(loadPermissions({permissions:userModules}))

          navigate("/manage-profile");
        }
      })
      .catch((err) => {
        console.log(err?.message?.appStatusDescription)
      });
  }
  return (
    <Dialog
      open={isOpen}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      scroll="body"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <div
          className="row"
          style={{ textAlign: "center", padding: "10px" }}
        >
          <img
            style={{ width: "200px", position: "center" }}
            src={thumbsup}
            alt="thumbsup"
          />
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          className="alert-dialog-description"
          style={{
            textAlign: "center",
            fontSize: "16px",
            fontStyle: "normal",
            color: "#06152B",
          }}
        >
          Your account is successfully setup. One of our account representatives
          will contact you shortly for further verification. Meanwhile, you can
          explore the current active jobs.
          <div
            className="row"
            style={{ textAlign: "center", padding: "20px" }}
          >
            <Button
              data-testid="dosignin"
              size="lg"
              style={{ textTransform: "capitalize" }}
              variant="contained"
              onClick={() => {
                DoSignin();
              }}
              className="RedirectJobListing"
            >
              {" "}
              Go to Manage Profile
            </Button>
          </div>
        </DialogContentText>
        <div
          classname="row"
          style={{ textAlign: "center", padding: "20px", fontSize: "12px" }}
        >
          <div className="roboto-font">
            Send me relevant job listings?
          </div>
        </div>
        <div className="row" style={{ textAlign: "center" }}>
          <Button size="small" style={{marginRight:"5px" , textTransform: "capitalize" }} onClick={() => handleNotificationChange("No")} autoFocus>
            No
          </Button>
          <Button
            size="small"
            variant="contained"
            style={{ textTransform: "capitalize" }}
            onClick={() => handleNotificationChange("Yes")}
            autoFocus
          >
            Yes
          </Button>
        </div>
        <div className="row" style={{ textAlign: "center" }}>
          {pageStatus.error && (
            <CustomAlert message={pageStatus.error} type="error"/>
          )}
          {pageStatus.isSuccess && (
            <CustomAlert message={"Updated Successfully"} type="success"/>
          )}
        </div>
      </DialogContent>
      <DialogActions>
      </DialogActions>
    </Dialog>
  );
}
export default SuccessPopup;
