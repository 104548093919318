import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { PermissionsEnums } from "../../../../data/permission";
import { CandidateAuthorizationStatus } from "../../../../models/interfaces";
import { ModuleComponent } from "../../../Common/Permission/ModuleComponent";
import "../../NewViewSubmission/styles.scss";

interface BypassRTRButtonProps {
  row: any;
  setRTRPopup: any;
  pageType:string;
}

export default function BypassRTRButton ({
  row,
  setRTRPopup,
  pageType
} : BypassRTRButtonProps) {
  const isAuthorized =
    (row?.candidateAuthorization === CandidateAuthorizationStatus[0] ||
    row?.candidateAuthorization === CandidateAuthorizationStatus[4]) &&
    pageType === "active";

  const handleClick = () => {
    setRTRPopup({
      isOpen: true,
      submissionId: row.id,
      recruiterName: `${row.recruiterFirstName} ${row.recruiterMiddleName} ${row.recruiterLastName}`,
      recruiterEmail: row.recruiterEmail,
    });
  };

  return (
    <ModuleComponent moduleId={PermissionsEnums.Submissions_Bypass_RTR}>
      {isAuthorized && (
        <Grid item>
          <Button
            className="submission-action-btn"
            variant="contained"
            color="primary"
            onClick={handleClick}
          >
            Bypass RTR
          </Button>
        </Grid>
      )}
    </ModuleComponent>
  );
}