import { FormControl, Input, InputLabel, makeStyles } from "@material-ui/core";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { CircularProgress, InputAdornment, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import * as React from "react";
import PhoneInput from "react-phone-input-2";
import PlacesAutocomplete from "react-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  GetCandidateById,
  UpdateCandidate,
} from "../../Services/candidateService";
import { getCandidateFile } from "../../Services/getPdfReportService";
import { SendEmailForUpdateCandidate } from "../../Services/recruiterService";
import {
  DocumentType,
  UploadAttachment,
} from "../../Services/uploadPhotoService";
import getLoggedInUserRole from "../../Shared/utils/getLoggedInUserRole";
import { searchOptions } from "../../constants/googlePlaceOption";
import {
  addressRegex,
  cityRegex,
  experienceRegex,
  firstNameRegex,
  phoneNumberRegex,
  zipValidation,
} from "../../constants/regex";
import { preferredCountries } from "../../data/phoneInput/preferredCountries";
import { enableEdit, toggleEdit } from "../../features/editMode/editModeSlice";
import { BreadCrumbItem } from "../../models/AppModels";
import { DocumentTypeEnum, UserAuth } from "../../models/interfaces";
import getAddressForAutoFill from "../../utils/getAddressForAutoFill";
import { downloadFile, getFilePreviewUrl, getNevLink } from "../../utils/getFileFromUrl";
import truncateString from "../../utils/truncateString";
import AppBreadCrumb from "../Common/AppBreadCrumb";
import PreviewFileDialog from "../Common/PreviewFileDialog";
import { UploadAttachments } from "../Common/UploadAttachments";
import { Asterisk } from "../ui/Asterisk";
import { CustomAlert } from "../ui/CustomAlert";
import { ErrorMessage } from "../ui/ErrorMessage";
import "./CandidateView.scss";
import "./style.scss";
import { handleNullValue } from "../../utils/StringUtil";
import { ternary, valueOrDefault } from "../../utils/complexityUtils";
import SnackbarPopup from "../Common/Popup/snackbar/SnackbarPopup";

const useStyles = makeStyles((theme) => ({
  initComment: {
    color: "blue",
  },

  underline: {
    borderBottom: "1px solid #929292",
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  boxShadow: "none",
  fontSize: "12px",
}));

export default function CandidateViewDetails() {
  let { id }: any = useParams();
  const dispatch = useDispatch();


  const initialState = {
    candidateId: "",
    candidateGuid: "",
    email: "",
    firstName: "",
    middleName: "",
    lastName: "",
    address: {
      //address required for search
      id: "",
      streetAddress: "",
      city: "",
      state: "",
      postalCode: "",
      countryCode: "",
    },
    phone: "",
    experience: "",
    otherDocInfo: [],
    //temo
    documents: [],
    canRelocate: "",
    resumeInfo: {
      resourceUrl: "",
      fileName: "",
      newFileName: "",
    },
  };
  const classes = useStyles();
  const [file, setFile] = React.useState();
  const [fileName, setFileName] = React.useState("");
  const [fileError, setfileError] = React.useState("");
  const [hideSuccessTick, setHideSuccessTick] = React.useState(false);
  const [countryShort, setCountryShort] = React.useState("us");
  const updateSuccessMessage = "Candidates information is updated successfully";
  let userRole = getLoggedInUserRole();

  const [filePreview, setFilePreview] = React.useState(undefined);
  const editMode = useSelector((state: any) => state.editMode);
  const [pageStatus, setPageStatus] = React.useState({
    isSuccess: false,
    error: "",
    loading: false,
  });
  const [otherDocuments, setOtherDocuments] = React.useState([]);
  const SUPPORTED_FORMATS = ["txt", "pdf", "doc", "docx", "rtf"];
  const [isLoading, setIsLoading] = React.useState(false);
  const candidateEditSchema = Yup.object({
    candidateId: Yup.string().required(),
    candidateGuid: Yup.string(),
    email: Yup.string().email().required(),
    firstName: Yup.string()
      .required("First Name is required")
      .matches(firstNameRegex, "First Name is not valid"),
    middleName: Yup.string().matches(
      firstNameRegex,
      "Middle Name is not valid"
    ),
    lastName: Yup.string()
      .required("Last Name is required")
      .matches(firstNameRegex, "Last Name is not valid"),
    address: Yup.object().shape({
      streetAddress: Yup.string()
        .required("Address is required")
        .matches(addressRegex, "Address is not valid"),
      city: Yup.string()
        .required("City is required")
        .matches(cityRegex, "City is not valid"),
      state: Yup.string()
        .required("State is required")
        .matches(cityRegex, "State is not valid"),
      postalCode: Yup.string()
        .required("Zip is required")
        .matches(zipValidation, "Zip is not valid"),
      countryCode: Yup.string()
        .required("Country is required")
        .matches(cityRegex, "Country is not valid"),
    }),
    phone: Yup.string()
      .required("The phone field is required")
      .matches(phoneNumberRegex, "Phone number is not valid"),
    experience: Yup.string()
      .required("Please enter the total experience")
      .matches(
        experienceRegex,
        "Please enter the valid experience from 0 to 25 or 25+"
      ),

    resumeInfo: Yup.object()
      .shape({
        resourceUrl: Yup.string().required(),
        fileName: Yup.string().required("Please select a file"),
        newFileName: Yup.string().required(),
      })
      .required(),
    jobDetails: Yup.array().of(
      Yup.object().shape({
        recruiterId: Yup.string(),
        jobId: Yup.string(),
        contractStatus: Yup.string(),
      })
    ),
    id: Yup.string().required(),
  });
  let user = JSON?.parse(localStorage?.getItem("user") ?? "");

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: candidateEditSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setPageStatus({ isSuccess: false, error: "", loading: true });
      let jobDetailPdf: any = await getCandidateFile({
        currentDetails: candidateChangeLog?.previousData,
        modifiedDetails: candidateChangeLog?.newData,
      });
      UpdateCandidate(values).then((res) => {
        if (
          res.data.status == 200 &&
          res.data.message.appStatusCode == "RC_RSF"
        ) {
          let responseData = res.data.entity;

          let formData = new FormData();
          formData.append("recruiterEmail", responseData?.recruiterEmail);
          formData.append("candidateEmail", responseData?.email);
          formData.append(
            "recruiterFirstName",
            responseData.recruiterFirstName
          );
          formData.append("recruiterLastName", responseData.recruiterLastName);
          formData.append("candidateFirstName", responseData?.firstName);
          formData.append("candidateLastName", responseData.lastName);
          formData.append("candidateDetailFile", jobDetailPdf);
          formData.append(
            "recruiterPhoneNumber",
            responseData.recruiterPhoneNumbers?.primary
          );
          formData.append("candidatePhoneNumber", responseData.phone);
          formData.append("role", user.loggedInUserRole);
          SendEmailForUpdateCandidate(formData).finally(() => {
            setPageStatus({ isSuccess: true, error: "", loading: false });
          });
        } else {
          setPageStatus({
            isSuccess: false,
            error: res.data?.message?.appStatusDescription,
            loading: false,
          });
        }

      });
    },
  });

  const [candidateChangeLog, setCandidateChangeLog]: any = React.useState();

  const _GetCandidateById = ()=>{
    GetCandidateById(id)
      .then((response)=>{
        if(response?.data?.message?.appStatusCode === "RC_RSF"){

          setCandidateChangeLog({ previousData: response?.data.entity });
          formik.setValues(response.data.entity);
          formik.setFieldValue(
            "documents",
            response.data?.entity?.otherDocInfo
          );
          setOtherDocuments(
            response.data?.entity?.otherDocInfo?.filter(
              (x: any) => x.documentType == DocumentType.OtherDoc
            )
          );
        
      }

    })
      .catch((error:any)=>{
        console.log("error",error);
      
    })
  }

  const handleUploadClick = (files: any) => {
    if (files.length == 0) return;
    setfileError("");
    if (!SUPPORTED_FORMATS.includes(files[0].name.split(".").pop())) {
      setfileError("File type is not supported");
      formik.setFieldError(
        "resumeInfo.fileName",
        "File type is not supported!"
      );
      setHideSuccessTick(true);
      formik.setFieldValue("resumeInfo", {
        resourceUrl: "",
        fileName: "",
        newFileName: "",
      });
      return;
    }
    if (files[0].size > 200000) {
      setfileError("File size too large");
      formik.setFieldError("resumeInfo.fileName", "File size too large!");
      setHideSuccessTick(true);
      formik.setFieldValue("resumeInfo", {
        resourceUrl: "",
        fileName: "",
        newFileName: "",
      });
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    setFile(files[0]);
    setFileName(files[0].name);
  };

  const handleAttachmentChange = (files: any) => {
    formik.setFieldValue("otherDocInfo", files);
  };
  const handleSelect = async (address: any) => {
    let resp = await getAddressForAutoFill(address);
    formik.setFieldValue("address.streetAddress", handleNullValue(resp.formattedAddress, ""));
    setCountryShort(resp?.countryCode?.toLowerCase());
    formik.setFieldValue("address.postalCode", handleNullValue(resp.pin, ""));
    formik.setFieldValue("address.countryCode", handleNullValue(resp.country, ""));
    formik.setFieldValue("address.state", handleNullValue(resp.state, ""));
    formik.setFieldValue("address.city", handleNullValue(resp.city, ""));
    formik.setFieldValue("address.id", handleNullValue(resp.city, ""));

    if (resp.pin && resp.pin !== "") {
      formik.setFieldTouched("address.postalCode", false);
    }
    if (resp.formattedAddress && resp.formattedAddress !== "") {
      formik.setFieldTouched("address.streetAddress", false);
    }
    if (resp.state && resp.state !== "") {
      formik.setFieldTouched("address.state", false);
    }
    if (resp.city && resp.city !== "") {
      formik.setFieldTouched("address.city", false);
    }
    if (resp.country && resp.country !== "") {
      formik.setFieldTouched("address.countryCode", false);
    }
  };

  React.useEffect(() => {
    dispatch(enableEdit());
    _GetCandidateById();
  }, []);


  React.useEffect(() => {
    if (file) {
      setHideSuccessTick(true);
      setPageStatus({ isSuccess: false, error: "", loading: true });
      const model = {
        documentType: DocumentTypeEnum.Resume,
        file: file,
      };
      UploadAttachment(model)
        .then((response) => {
          if (
            response.data.status == 200 &&
            response.data.message.appStatusCode == "RC_RSF"
          ) {
            formik.values.resumeInfo = {
              fileName: response.data.entity.fileName,
              resourceUrl: response.data.entity.absoluteUri,
              newFileName: response.data.entity.newFileName,
            };
            setHideSuccessTick(false);
            formik.setFieldTouched("resumeInfo", true);
            setPageStatus({ isSuccess: false, error: "", loading: false });
          } else {
            setPageStatus({
              isSuccess: false,
              error: response.data.message.appStatusDescription,
              loading: false,
            });
          }
        })
        .catch((err) => {
          setPageStatus({
            isSuccess: false,
            error: err.message,
            loading: false,
          });
        });
    }
  }, [file, fileName]);

  const renderFunc = ({
    getInputProps,
    suggestions,
    getSuggestionItemProps,
    loading,
  }: any) => (
    <div >
      <FormControl
        variant="standard"
        error={
          formik.touched.address?.streetAddress &&
          Boolean(formik.errors.address?.streetAddress)
        }
        fullWidth
        required
      >
        <InputLabel htmlFor="address-input">Address</InputLabel>
        <Input
          {...getInputProps()}
          onBlur={formik.handleBlur}
          name="address.streetAddress"
          type="text"
          readOnly={!editMode.isEditable}
          disableUnderline={!editMode.isEditable}
        />
        <ErrorMessage
          errorText={
            formik.touched.address?.streetAddress &&
            formik.errors.address?.streetAddress
          }
        />
      </FormControl>
      <div className="autocomplete-dropdown-container">
        {loading && <div>Loading...</div>}
        {suggestions.map((suggestion: any) => (
          <div
            key={suggestion.description}
            {...getSuggestionItemProps(suggestion)}
          >
            <span
              data-testid="addressOnChange"
              onChange={handleSelect}
              style={{ cursor: "pointer" }}
            >
              {suggestion.description}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
  let breadcrumb: BreadCrumbItem[] = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink("/dashboard")},
    { isLeaf: false, sequence: 2, title: "My Candidates", to: getNevLink("/candidates") },
    {
      isLeaf: true,
      sequence: 3,
      title: "View Details",
      leafClasses: "custom-appbreadcrumb",
    },
  ];

  let adminBreadcrumb: BreadCrumbItem[] = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink("/dashboard") },
    { isLeaf: false, sequence: 2, title: ternary(userRole === UserAuth.Recruiter ,"My Candidates", "All Candidates"), to: getNevLink("/candidates") },
    {
      isLeaf: true,
      sequence: 3,
      title: "View Details",
      leafClasses: "custom-appbreadcrumb",
    },
  ];
  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} md={12}>
          <AppBreadCrumb
            classes="custom-appbreadcrumb"
            BreadCrumbItems={ternary(
              userRole === UserAuth.Recruiter,
              breadcrumb,
              adminBreadcrumb
            )}
          ></AppBreadCrumb>
        </Grid>
        <Grid item xs={12} sm={12} >
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Typography className="Client-txt-fontwt">
                Candidate Details
                <Typography
                  variant="h6"
                  component="span"
                  style={{ color: "grey" }}
                >
                  &nbsp; (
                  {formik.values?.candidateId})
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>               
          </Grid>
        </Grid>              
        <Grid item xs={12} md={12} >
          <Paper elevation={1} sx={{ padding: 2 }}>
            <Box
              component="form"
              noValidate
              autoComplete="off"
            >
              <Grid container spacing={2} mt={-2}>
                <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
                  {editMode.isEditable && (
                    <>                   
                      <Button
                        variant="outlined"
                        style={{
                          marginRight: "15px",
                          textTransform: "none",
                        }}
                        disabled={isLoading}
                        onClick={() => {
                          _GetCandidateById();
                          dispatch(toggleEdit());
                          setPageStatus({
                            isSuccess: false,
                            error: "",
                            loading: false,
                          });
                          setfileError("");
                        }}
                        data-testid="cancel-btn"
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        style={{ textTransform: "none" }}
                        disabled={pageStatus.loading || isLoading}
                        endIcon={
                          pageStatus.loading ? (
                            <CircularProgress size={"16px"} color={"inherit"} />
                          ) : (
                            ""
                          )
                        }
                        onClick={() => {
                          console.log(formik.errors);
                          setCandidateChangeLog({
                            ...candidateChangeLog,
                            newData: formik.values,
                          });
                          formik.handleSubmit();
                        }}
                        data-testid="update-btn"
                      >
                        Update
                      </Button>
                    </>
                  )}
                </Grid>
                <Grid item xs={6} md={2}>
                  <FormControl
                    variant="standard"
                    required
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    fullWidth
                  >
                    <InputLabel htmlFor="firstName">First Name</InputLabel>
                    <Input
                      data-testid="firstNameInput"
                      name="firstName"
                      type="text"
                      className="nameInput"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={valueOrDefault(
                        formik.values?.firstName,
                        ternary(!editMode.isEditable, "N/A", "")
                      )}
                      readOnly={!editMode.isEditable}
                      disableUnderline={!editMode.isEditable}
                    />
                    <ErrorMessage
                      errorText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={2} className={classes.underline2}>
                  <FormControl variant="standard" style={{ width: "100%" }}>
                    <InputLabel htmlFor="middleName">Middle Name</InputLabel>
                    <Input
                      className="nameInput"
                      name="middleName"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={valueOrDefault(
                        formik.values?.middleName,
                        ternary(!editMode.isEditable, "N/A", "")
                      )}
                      readOnly={!editMode.isEditable}
                      disableUnderline={!editMode.isEditable}
                    />
                    <ErrorMessage
                      errorText={
                        formik.touched.middleName && formik.errors.middleName
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={2}>
                  <FormControl
                    variant="standard"
                    required
                    error={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    fullWidth
                  >
                    <InputLabel htmlFor="lastName">Last Name</InputLabel>
                    <Input
                      name="lastName"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={valueOrDefault(
                        formik.values?.lastName,
                        ternary(!editMode.isEditable, "N/A", "")
                      )}
                      readOnly={!editMode.isEditable}
                      disableUnderline={!editMode.isEditable}
                    />
                    <ErrorMessage
                      errorText={
                        formik.touched.lastName && formik.errors.lastName
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="standard"
                    required
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    fullWidth
                  >
                    <InputLabel htmlFor="email">Candidate Email</InputLabel>
                    <Input
                      name="email"
                      autoComplete="off"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={valueOrDefault(
                        formik.values?.email,
                        ternary(!editMode.isEditable, "N/A", "")
                      )}
                      readOnly={!editMode.isEditable}
                      disableUnderline={!editMode.isEditable}
                    />
                    <ErrorMessage
                      errorText={formik.touched.email && formik.errors.email}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={6}>
                  <PlacesAutocomplete
                    searchOptions={searchOptions}
                    value={valueOrDefault(
                      formik.values?.address?.streetAddress,
                      ternary(!editMode.isEditable, "N/A", "")
                    )}
                    onChange={(ev: any) => {
                      formik.setFieldValue("address.streetAddress", ev);
                    }}
                    onSelect={handleSelect}
                  >
                    {renderFunc}
                  </PlacesAutocomplete>
                </Grid>
                <Grid item xs={6} md={1.5}>
                  <FormControl                   
                    variant="standard"
                    required
                    error={
                      formik.touched.address?.city &&
                      Boolean(formik.errors.address?.city)
                    }
                  >
                    <InputLabel htmlFor="address.city">City</InputLabel>
                    <Input
                      name="address.city"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={valueOrDefault(
                        formik.values?.address?.city,
                        ternary(!editMode.isEditable, "N/A", "")
                      )}
                      readOnly={!editMode.isEditable}
                      disableUnderline={!editMode.isEditable}
                    />
                    <ErrorMessage
                      errorText={
                        formik.touched.address?.city &&
                        formik.errors.address?.city
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={1.5}>
                  <FormControl
                    variant="standard"
                    required
                    error={
                      formik.touched.address?.state &&
                      Boolean(formik.errors.address?.state)
                    }
                    className="state-input"
                  >
                    <InputLabel htmlFor="address.state">State</InputLabel>
                    <Input
                      name="address.state"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={(val) => {
                        formik.setFieldValue("address.streetAddress", "");
                        formik.setFieldValue("address.state", val.target.value);
                      }}
                      value={valueOrDefault(
                        formik.values?.address?.state,
                        ternary(!editMode.isEditable, "N/A", "")
                      )}
                      readOnly={!editMode.isEditable}
                      disableUnderline={!editMode.isEditable}
                      data-testid="address-state-change"
                    />
                    <ErrorMessage
                      errorText={
                        formik.touched.address?.state &&
                        formik.errors.address?.state
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={1.5}>
                  <FormControl
                    variant="standard"
                    required
                    error={
                      formik.touched.address?.countryCode &&
                      Boolean(formik.errors.address?.countryCode)
                    }
                  >
                    <InputLabel htmlFor="address.countryCode">
                      Country
                    </InputLabel>
                    <Input
                      name="address.countryCode"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={(val) => {
                        formik.setFieldValue("address.streetAddress", "");
                        formik.setFieldValue(
                          "address.countryCode",
                          val.target.value
                        );
                      }}
                      value={valueOrDefault(
                        formik.values?.address?.countryCode,
                        ternary(!editMode.isEditable, "N/A", "")
                      )}
                      readOnly={!editMode.isEditable}
                      disableUnderline={!editMode.isEditable}
                      data-testid="address-countryCode-change"
                    />
                    <ErrorMessage
                      errorText={
                        formik.touched.address?.countryCode &&
                        formik.errors.address?.countryCode
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={1.5}>
                  <FormControl
                    variant="standard"
                    required
                    error={
                      formik.touched.address?.postalCode &&
                      Boolean(formik.errors.address?.postalCode)
                    }
                    className="zip-input"
                  >
                    <InputLabel htmlFor="address.postalCode">Zip</InputLabel>
                    <Input
                      name="address.postalCode"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={valueOrDefault(
                        formik.values?.address?.postalCode,
                        ternary(!editMode.isEditable, "N/A", "")
                      )}
                      readOnly={!editMode.isEditable}
                      disableUnderline={!editMode.isEditable}
                    />
                    <ErrorMessage
                      errorText={
                        formik.touched.address?.postalCode &&
                        formik.errors.address?.postalCode
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={1}>
                <Grid item md={4}>
                  <FormControl
                    variant="standard"
                    required
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                  >
                    <span>
                      Phone <Asterisk />
                    </span>
                    <PhoneInput
                      name="phone"
                      inputClass=""
                      className={`phoneCountryCustom ${ternary(
                        !editMode.isEditable,
                        "phoneInputDisabled phoneInputDisabledborder",
                        ""
                      )}`}
                      placeholder="Phone"
                      country={countryShort}
                      onChange={(ev) => {
                        formik.setFieldValue("phone", ev);
                      }}
                      value={valueOrDefault(
                        formik.values?.phone,
                        ternary(!editMode.isEditable, "N/A", "")
                      )}
                      readOnly={!editMode.isEditable}
                      preferredCountries={preferredCountries}
                      preserveOrder={["preferredCountries"]}
                      data-testid="phone-input"
                    />
                    {formik.touched.phone && (
                      <ErrorMessage errorText={formik.errors.phone} />
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs ={12} md={3} pt={"5px"}>
                  <FormControl className="totalexperiencemargin" fullWidth>
                    <InputLabel shrink={true} htmlFor="experience" required>
                      Total Experience
                    </InputLabel>
                    <Input
                      style={{ width: "120px" }}
                      name="experience"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={valueOrDefault(
                        formik.values?.experience,
                        ternary(!editMode.isEditable, "N/A", "")
                      )}
                      readOnly={!editMode.isEditable}
                      disableUnderline={!editMode.isEditable}
                      endAdornment={
                        <InputAdornment position="end">Years</InputAdornment>
                      }
                    />
                    <ErrorMessage
                      errorText={
                        formik.touched.experience && formik.errors.experience
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12}>
                  <FormControl
                    variant="standard"
                    required
                    style={{ width: "100%" }}
                  >
                    <FormLabel required>Resume</FormLabel>
                    <Grid container>
                      <Grid item md={4}>
                        <Grid container className="custom-resume">
                          <Grid item md={2} className="custom-resume-check">
                            {!hideSuccessTick && (
                              <CheckCircleIcon color="success" />
                            )}
                          </Grid>
                          <Grid item md={5}>
                            <Tooltip title={formik.values?.resumeInfo?.fileName}>
                              <Input
                                name="resume"
                                type="text"
                                value={truncateString(
                                  formik.values?.resumeInfo?.fileName,
                                  14
                                )}
                                disableUnderline={true}
                                readOnly={true}
                              />
                            </Tooltip>
                          </Grid>
                          <Grid item sx={{ marginLeft: "10px" }}>
                            {formik.values?.resumeInfo?.resourceUrl !==
                              undefined &&
                              formik.values?.resumeInfo?.resourceUrl !== "" && (
                                <>
                                  <VisibilityOutlinedIcon
                                    onClick={() => {
                                      getFilePreviewUrl(
                                        formik.values?.resumeInfo?.resourceUrl
                                      ).then((filePreviewUrl) =>
                                        setFilePreview({
                                          resourceUrl: filePreviewUrl,
                                          fileName:
                                            formik.values?.resumeInfo?.fileName,
                                        })
                                      );
                                    }}
                                    color="primary"
                                    className="pointer"
                                    sx={{ verticalAlign: "bottom" }}
                                    data-testid="view-resume"
                                  />
                                  <SaveAltOutlinedIcon
                                    onClick={() => {
                                      downloadFile(
                                        formik.values?.resumeInfo?.resourceUrl,
                                        formik.values?.resumeInfo?.fileName
                                      );
                                    }}
                                    color="primary"
                                    className="pointer"
                                    sx={{ pl: 1, verticalAlign: "bottom" }}
                                  />
                                </>
                              )}
                          </Grid>
                        </Grid>
                        <Typography className="fileUploadHelper-TextLabel">
                          (Max file size: 2MB as .txt, .rtf, .doc, .docx, .pdf)
                        </Typography>
                      </Grid>
                      {editMode.isEditable && (
                        <Grid item md={8}>
                          <Grid container>
                            <Grid
                              item
                              md={12}
                              style={{
                                display: "contents",
                                alignItems: "center",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Button
                                variant="contained"
                                component="label"
                                startIcon={<FileUploadIcon />}
                                disabled={!editMode.isEditable}
                                style={{ textTransform: "none" }}
                              >
                                <Typography sx={{ flexWrap: "wrap" }}>
                                  Update Resume
                                </Typography>
                                <input
                                  data-testid={"updateResumeInp"}
                                  type="file"
                                  accept="text/plain, application/pdf, .doc, .docx,.rtf "
                                  disabled={!editMode.isEditable}
                                  onChange={(e) => {
                                    handleUploadClick(
                                      e.currentTarget.files || null
                                    );
                                    e.currentTarget.value = null;
                                  }}
                                  hidden
                                />
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    {fileError && <ErrorMessage errorText={fileError} />}
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            {!editMode.isEditable && (
          <>
            <Grid item xs={12} lg={12}>
              <Item elevation={0} sx={{ paddingLeft: 0 }}>
                <Box mt={{ xs: 1, sm: 1, md: 1 }}>
                  <Box className="form-three-child">
                    <Typography
                      variant="subtitle1"
                      component="div"
                      className="abel"
                    >
                      Other Documents
                    </Typography>
                  </Box>
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Box
                className="main-wrapper-uploader"
                sx={{ width: "max-content" }}
              >
                {(otherDocuments.length > 0 &&
                  otherDocuments.map((otherDoc) => {
                    return (
                      <Box
                        key={otherDoc.fileName}
                        className="upload-wrapper-text success-upload"
                      >
                        <Typography
                          variant="subtitle1"
                          component="div"
                          className="abel title-text"
                        >
                          {otherDoc.fileName}
                        </Typography>
                        {otherDoc.resourceUrl !== undefined &&
                          otherDoc.resourceUrl !== "" && (
                            <>
                              {otherDoc.type !==
                                "application/x-zip-compressed" && // cannot preview zip files
                                otherDoc.fileName
                                  .split(".")[1]
                                  ?.toLowerCase() !== "zip" && (
                                  <VisibilityOutlinedIcon
                                    onClick={() => {
                                      getFilePreviewUrl(
                                        otherDoc.resourceUrl
                                      ).then((filePreviewUrl) =>
                                        setFilePreview({
                                          resourceUrl: filePreviewUrl,
                                          fileName: otherDoc.fileName,
                                        })
                                      );
                                    }}
                                    color="primary"
                                    className="pointer"
                                    sx={{ pl: 2, verticalAlign: "bottom" }}
                                  />
                                )}
                              <SaveAltOutlinedIcon
                                onClick={() => {
                                  downloadFile(
                                    otherDoc.resourceUrl,
                                    otherDoc.fileName
                                  );
                                }}
                                color="primary"
                                className="pointer"
                                sx={{ pl: 1, verticalAlign: "bottom" }}
                              />
                            </>
                          )}
                      </Box>
                    );
                  })) ||
                  "N/A"}
              </Box>
            </Grid>
          </>
        )}
        {editMode.isEditable && (
          <Box sx={{ paddingTop: "30px",marginLeft:"-5px"}}>
           
              <UploadAttachments
                classes="roboto-font"
                setFilePreview={setFilePreview}
                onChange={handleAttachmentChange}
                documents={otherDocuments}
                handleLoading={(val: any) => {
                  setIsLoading(val);
                }}
                acceptedFormats={
                  ".txt,.rtf,.doc,.docx,.pdf,.zip,.png,.jpg,.jpeg,.pptx"
                }
              />
        
          </Box>
        )}
          </Paper>{" "}
        </Grid>
      </Grid>

      {pageStatus.error && (
        <CustomAlert key={0} message={pageStatus.error} type={"error"} />
      )}
      {pageStatus.isSuccess && (
        <SnackbarPopup
          open={pageStatus.isSuccess}
          message={updateSuccessMessage}
          onClose={() => {
            setPageStatus({ ...pageStatus, isSuccess: false });
          }}
        />
      )}
      <br />
      
      {filePreview !== undefined && (
        <PreviewFileDialog
          open={filePreview !== undefined}
          handleOpenClose={() => setFilePreview(undefined)}
          fileName={filePreview.fileName}
          fileURL={filePreview.resourceUrl}
          fileType={filePreview.fileType}
        />
      )}
    </>
  );
}
