import { ContractsMenuIcon } from "../../components/Common/ContractsMenuIcon";
import { CandidatesAdminMenuIcon } from "../../components/Common/Icons/CandidatesAdminMunuIcon";
import { DashboardMenuIcon } from "../../components/Common/Icons/DashboardMenuIcon";
import { EarningsAdminMenuIcon } from "../../components/Common/Icons/EarningsAdminMenuIcon";
import { SubmissionsMenuIcon } from "../../components/Common/Icons/SubmissionsMenuIcon";
import circleOkIcon from "../../assets/images/circle-ok.svg";
import { AllJobsMenuIcon } from "../../components/Common/Icons/AllJobMenuIcon";
import { SavedJobsMenuIcon } from "../../components/Common/Icons/SavedJobsMenuIcon";
import { Jobs as JobsIcon } from "../../components/Common/Icons/Jobs";
import { PendingSubmissionsMenuIcon } from "../../components/Common/PendingSubmissionsMenuIcon";
import { Alerts as AlertsIcon } from "../../components/Common/Icons/Alerts";
import { SettingsMenuIcon } from "../../components/Common/SettingsMenuIcon";
import { PermissionsEnums } from "../permission";

export const recruiterNavItems = [
  {
    id: 1,
    name: "dashboard",
    label: "Dashboard",
    link: "/dashboard",
    icon: <DashboardMenuIcon />,
    permissionId: PermissionsEnums.Dashboard_Recruiter,
    sub: [],
  },
  
  {
    id: 5,
    name: "Jobs",
    label: "Jobs",
    link: "/jobs",
    permissionId: PermissionsEnums.Jobs_View,
    icon: <JobsIcon className="icon-color-default" />,
    sub: [
        {
          id: 10.1,
          name: "alljobs",
          label: "All",
          link: "/jobs/all",
          permissionId: PermissionsEnums.Jobs_View,
          icon:   <AllJobsMenuIcon />,
        },
   
        
        {
          id: 10.2,
          name: "Saved Jobs",
          label: "Saved Jobs",
          link: "/savedjobs/recruiter",
          permissionId: PermissionsEnums.Jobs_Saved,
          icon:   <SavedJobsMenuIcon />,
        }
      ],
  },
  {
    id: 6,
    name: "Submission",
    label: "Submissions",
    link: "/submission",
    permissionId: PermissionsEnums.Submissions_All,
    icon: <SubmissionsMenuIcon />,
    sub: [
      {
        id: 6.1,
        name: "Active",
        label: "Active",
        link: "/submission/active",
        permissionId: "",
        icon:  <img src={circleOkIcon} alt="circleOkIcon" />,
      },
      {
        id: 6.2,
        name: "Archived",
        label: "Archived",
        link: "/submission/archived",
        permissionId: "",
        icon:  <img src={circleOkIcon} alt="circleOkIcon" />,
      },
      {
        id: 6.3,
        name: "Pending Submission",
        label: "Pending Submission",
        link: "/pendingsubmission",
        permissionId: "",
        icon:  <PendingSubmissionsMenuIcon className="icon-color-default" />,
      },
    ],
  },
  {
    id: 7,
    name: "candates",
    label: "My Candidates",
    link: "/candidates",
    permissionId: PermissionsEnums.Candidates_View,
    icon: <CandidatesAdminMenuIcon />,
    sub: [],
  },
  {
    id: 8,
    name: "jobalerts",
    label: "Job Alerts",
    link: "/jobAlerts",
    permissionId: PermissionsEnums.Jobs_Alerts,
    icon:  <AlertsIcon className="icon-color-default" />,
    sub: [],
  },
  {
    id: 9,
    name: "Contracts",
    label: "Contracts",
    link: "/contracts",
    permissionId: PermissionsEnums.Contracts_View,
    icon: <ContractsMenuIcon />,
    sub: [],
  },
  {
    id: 10,
    name: "Earnings",
    label: "Earnings",
    link: "/earnings",
    permissionId: PermissionsEnums.Earnings_View,
    icon: <EarningsAdminMenuIcon />,
    sub: [],
  },
  {
    id: 11,
    name: "Settings",
    label: "Settings",
    link: "/notificationsettings",
    permissionId: PermissionsEnums.Settings,
    icon: <SettingsMenuIcon />,
    sub: [],
  },
  
  
];
