export const ifscCodeRegEx = /^[A-Za-z]{4}\d{7}$/
export const routingNumberRegEx = /^\d{9}$/
export const panCardRegEx = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
export const IndiabankAccountRegex = /^(\d{9,18})$/
export const USABankAccountRegex = /^(\d{9,12})$/
export const alphabetRegex = /^[a-zA-Z ]{2,40}$/
export const bankNameRegex = /^[A-Za-z ][A-Za-z-./, ]{2,100}$/
export const addressRegex = /^[A-Za-z0-9 ][A-Za-z0-9-./,&' ]{1,100}$/
export const cityRegex = /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]{2,40}$/
export const zipValidation = /^[A-Z0-9 ][A-Z0-9-./, ]{1,10}$/
export const linkedInRegex = /^http(s)?:\/\/www\.linkedin\.com\/(in|us|company)\/[a-z0-9_-]+\/?$/i;
export const phoneNumberRegex = /^[+]?\d{3}?[-\s.]?\d{3}[-\s.]?\d{4,8}$/im

export const passWordRegex =  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/
export const firstNameRegex = /^[a-zA-Z]{2,30}$/
export const middleNameRegex = /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]{1,30}$/

export const accountHolderNameRegex = /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]{2,40}$/
export const experienceRegex = /^([0-9]|1[0-9]|2[0-5])$|^25\+$/;
export const experienceRegexForSubmitResume = /^(0?[0-9]|[1-9][0-9])$/;
export const payoneerCustomerIdRegex =/^[0-9]{8}$/;
export const urlRegExp = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
export const companyNameRegExp = /^[a-zA-Z0-9\s.,'-]*$/;
export const uptoTwoDecimalsRegx = /^[0-9]*(\.[0-9]{0,2})?$/
export const onlyNumberRegex = /^\d*$/
export const companyNameRegex = /^[\w ]+$/;