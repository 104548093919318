import NearMeOutlinedIcon from "@mui/icons-material/NearMeOutlined";
import { Button, FormControl, IconButton, Input, InputLabel, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  GetActiveSubmissionCountByJob,
  GetInterviewingCountByJob,
} from "../../../Services/candidateService";
import { GetClientById } from "../../../Services/clientService";
import { getShareJobPdfFile } from "../../../Services/getPdfReportService";
import {
  GetJobById,
  UpdateJobAsFeatured,
  UpdateViewJob,
} from "../../../Services/jobService";
import {
  GetRecruiterById,
  RecruiterSaveJob,
  RecruiterUnsaveJob,
  ShareJobByRecruiter,
  getRecruiterInformationAccess,
} from "../../../Services/recruiterService";
import formatPhone from "../../../Shared/utils/formatPhone";
import getLoggedInUserRole from "../../../Shared/utils/getLoggedInUserRole";
import getUserDetails from "../../../Shared/utils/getUserDetails";
import getJobType from "../../../Shared/utils/getjobType";
import featuredJobIcon from "../../../assets/images/featured-job.svg";
import { setRecruiterSubmissionState as setActiveRecruiterSubmissionState } from "../../../features/recruiterSubmission/activeRecruiterSubmissionSlice";
import useApi from "../../../hooks/useApi";
import { UserAuth } from "../../../models/interfaces";
import { getNevLink } from "../../../utils/getFileFromUrl";
import getPayRate from "../../../utils/getPayRate";
import { Contract as ContractIcon } from "../../Common/Icons/Contract";
import { Currency as CurrencyIcon } from "../../Common/Icons/Currency";
import { MapMarker as MapMarkerIcon } from "../../Common/Icons/Marker";
import { Eye as EyeIcon } from "../../Common/Icons/Years";
import ContactInformationPopup from "../../ViewSubmissions/NewViewSubmission/Popups/ContactInformationPopup";
import { ErrorMessage } from "../../ui/ErrorMessage";
import ClientJobInformation from "./JobInformation";
import ClientRightSideBar from "./RightSideBar";
import SaveShareSubmitButtons, { SaveUnsavedJobStatusProps } from "./SaveShareSubmitButtons";
import "./jobdetails.scss";
import { ternary } from "../../../utils/complexityUtils";
import CloseIcon from "@mui/icons-material/Close";
import AppBreadCrumb from "../../Common/AppBreadCrumb";
import { BreadCrumbItem } from "../../../models/AppModels";
import { AppState } from "../../../store/AppState";
import { PageName } from "../../../models/app/Page/PageModel";

const DefaultBreadcrumbs: BreadCrumbItem[] = [
  { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
  { isLeaf: false, sequence: 2, title: "All Jobs", to: getNevLink('/jobs/all') },
  {isLeaf: true,sequence: 3,title: "Job Details", leafClasses: " v2LastLeft",},
];

const DefaultNotLoggedUseerBreadcrumbs: BreadCrumbItem[] = [
  { isLeaf: false, sequence: 1, title: "All Jobs", to: getNevLink('/jobs/all') },
  {isLeaf: true,sequence: 2,title: "Job Details", leafClasses: " v2LastLeft",},
];


 const fromPageRecruiterListBreadCrumbs = (id: string): BreadCrumbItem[] => {
    return [
  { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
  { isLeaf: false, sequence: 2, title: "Recruiter", to: getNevLink('/recruiters') },
  { isLeaf: false, sequence: 3, title: "Saved Jobs", to: getNevLink(`/recruiter/savedjobs/${id}`) },
  {isLeaf: true,sequence: 4,title: "Job Details", leafClasses: " v2LastLeft",},
  ]
 }
export default function JobDetails() {

  const navigate = useNavigate();
  const location = useLocation();
  const userRole = getLoggedInUserRole();
  const BreadcrumbState = useSelector((state:AppState) => state.BreadcrumbsState);

  const fromPageSavedJobListBreadCrumbs : BreadCrumbItem[] = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
    { isLeaf: false, sequence: 2, title: "Saved Jobs", to: BreadcrumbState.previousLink ? BreadcrumbState.previousLink :  getNevLink('/savedjobs/recruiter') },
    {isLeaf: true,sequence: 3,title: "Job Details", leafClasses: " v2LastLeft",},
  ]; 

  const [shareJobState, setShareJobState] = React.useState({
    popup: false,
    isSharing: false,
    success: false,
    msg: "",
  });
  const [saveUnsavedJobStatus, setSaveUnsavedJobStatus] = React.useState<SaveUnsavedJobStatusProps>({
    isSaved:false,
    isUnsaved:false,
    message:""
  });
  const [displayContactPopup, setDisplayContactPopup] = React.useState(false);
  const [recruiterData, setSecruiterData]: any = React.useState();
  const theme = useTheme();
  const [isLoadingJob, setIsLoading] = React.useState(true);
  const matchesXS = useMediaQuery(theme.breakpoints.down("md"));
  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));
  const dynamicStyles = {
    ...(matchesXS && { direction: "column", fontSize: "h5" }),
    ...(matchesMD && { direction: "row", fontSize: "h4" }),
  };
  const [userStatus, setUserStatus] = React.useState("");
  let { id }: any = useParams();
  let userDetails = getUserDetails();
  let userId = userDetails?.id;
  const [isSavedJob, setIsSavedJob] = useState(false);
  const [featureJob, setisFeatured]: any = useState({
    jobId: "",
    createdBy: userId,
    isFeatured: null,
  });
  const [featureState, setfeatureState]: any = useState(null);

  const [savedJobCount, setSavedJobCount]: any = useState("");

  const saveJobAPI: any = useApi(() =>
    RecruiterSaveJob({ recruiterId: userId, jobId: id })
  );
  const featureJobAPI: any = useApi(() => UpdateJobAsFeatured(featureJob));

  const unsaveJobAPI: any = useApi(() =>
    RecruiterUnsaveJob({ recruiterId: userId, jobId: id })
  );
  const [jobDetails, setjobDetails]: any = useState({});

  const dispatch = useDispatch();
  let activeSubmissionState: any = useSelector(
    (state: any) => state.activeRecruiterSubmissionState
  );

  const handleCardClick = (statusIds: any) => {
    dispatch(
      setActiveRecruiterSubmissionState({
        ...activeSubmissionState,
        pageIndex: 1,
        searchKeywords: `${id}`,
        statusIds: statusIds,
        durationFilter: { duration: "", durationType: "All" },
      })
    );

    navigate(getNevLink(`/submission/active`));
  };

  const [contactInformationData, setcontactInformationData]: any =
    React.useState({
      accountManager: null,
      recruiter: null,
      candidate: null,
      employerDetails: null,
    });
  
  const getJob = () => {
    GetJobById(id)
      .then((response) => {
        if (response.data.status == 200) {
          if (userRole == UserAuth.SuperAdmin || userRole == UserAuth.Admin) {
            Promise.all([
              GetInterviewingCountByJob(id),
              GetActiveSubmissionCountByJob(id),
            ]).then(([interviewingCountRes, submissionCountRes]) => {
              const interviewingCount =
                interviewingCountRes?.data?.totalRecords || 0;
              const submissionCount =
                submissionCountRes?.data?.totalRecords || 0;

              const updatedJobData = {
                ...response?.data?.entity,
                interviewingCount: interviewingCount,
                submissionCount: submissionCount,
              };
              setjobDetails(updatedJobData);
            });
          } else {
            setjobDetails(response?.data?.entity);
          }
          setIsLoading(false);
          setSavedJobCount(response?.data?.entity?.recruiterSavedJobsCount);
          setisFeatured({
            ...featureJob,
            jobId: response?.data?.entity.id,
            isFeatured: !response?.data?.entity?.isFeatured,
          });
          setfeatureState(response?.data?.entity?.isFeatured);
          setcontactInformationData({
            ...contactInformationData,
            accountManager: {
              recruiterName: response?.data?.entity?.recruiterName,
              recruiterEmail: response?.data?.entity?.recruiterEmail,
              recruiterWorkPhoneNo:
                response?.data?.entity?.recruiterWorkPhoneNo,
              salesPersonName: response?.data?.entity?.salesPersonName,
              salesPersonEmail: response?.data?.entity?.salesPersonEmail,
              salesPersonWorkPhoneNo:
                response?.data?.entity?.salesPersonWorkPhoneNo,
            },
          });
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const GetRecruiter = () => {
    if (userId) {
      GetRecruiterById(userId)
        .then((response) => {
          if (response.data.status == 200) {
            let savedJob = response.data?.entity?.savedJobs.find(
              (x: any) => x.jobId == id
            );
            if (savedJob) setIsSavedJob(true);
            setUserStatus(response?.data?.entity?.status);
            setSecruiterData(response.data?.entity);
          } 
        })
        .catch((er) => {
          console.log(er);
        });
    }
  };
  const incrementCount = () => {
    if (userRole == UserAuth.Recruiter) {
      let userId: any = getUserDetails().id;
      let jobId: any = id;
      UpdateViewJob(jobId, userId)
        .then(() => {})
        .catch((er) => {
          console.log(er);
        });
    }
  };

  useEffect(() => {
    getJob();
    incrementCount();
    GetRecruiter();
  }, []);


  useEffect(() => {
    if (saveJobAPI.data && saveJobAPI.data?.status === 200) {
      setIsSavedJob(true);
      setSavedJobCount(savedJobCount + 1);
      setSaveUnsavedJobStatus({
        ...saveUnsavedJobStatus,
        isSaved:true, 
        isUnsaved:false,
        message:"Job has been saved successfully"
      })
      
    }
  }, [saveJobAPI.data]);

  useEffect(() => {
    if (featureJobAPI.data && featureJobAPI.data?.status === 200) {
      setisFeatured({ ...featureJob, isFeatured: !featureJob.isFeatured });
      setfeatureState(!featureState);
    }
  }, [featureJobAPI.data]);

  useEffect(() => {
    if (unsaveJobAPI.data && unsaveJobAPI.data?.status === 200) {
      setIsSavedJob(false);
      setSavedJobCount(savedJobCount - 1);
      setSaveUnsavedJobStatus({
        ...saveUnsavedJobStatus,
        isSaved:false, 
        isUnsaved:true,
        message:"Job has been successfully unsaved"
      })
    }
  }, [unsaveJobAPI.data]);
  let formattedPayRate = getPayRate(
    jobDetails?.package?.packageType,
    jobDetails?.package?.packageValue,
    jobDetails?.package?.packageCurrency
  );

  let formattedBillRate = getPayRate(
    jobDetails?.billRatePckg?.type,
    jobDetails?.billRatePckg?.billRate,
    jobDetails?.billRatePckg?.packageCurrency
  );
  const formattedJobType = () => {
    return getJobType(jobDetails?.jobType, jobDetails?.jobDuraion);
  };
  const jobLocation = () => {
    if (jobDetails?.remoteJob == "y") {
      return jobDetails?.city + ", " + jobDetails?.stateName + " (Remote)";
    }
    return jobDetails?.city + ", " + jobDetails?.stateName;
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Entered email address is invalid")
      .required("Please enter a valid email address"),
  });
  const subscribeState = {
    email: "",
  };
  const formik = useFormik({
    initialValues: subscribeState,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      shareJob(values);
    },
  });

  const onShareJobClick = () => {
    formik.resetForm();
    setShareJobState({
      ...shareJobState,
      popup: true,
      msg: "",
      isSharing: false,
    });
  };

  const shareJob = async (values: any) => {
    setShareJobState({ ...shareJobState, isSharing: true, popup: false });

    let formData: any = new FormData();
    if (userRole === UserAuth.Admin || userRole === UserAuth.SuperAdmin) {
      let jobDetailPdf = await getShareJobPdfFile({ jobDetail: jobDetails });
      
      let role = getUserDetails()?.userRoleType;
      formData.append("role", role);
      formData.append("recruiterEmail", getUserDetails()?.email);
      formData.append("jobId", jobDetails?.id);
      formData.append("shareTo", values.email);
      formData.append("recruiterFullName", getUserDetails()?.userName);
      formData.append("jobTitle", jobDetails?.jobTitle);
      formData.append("jobDetailFile", jobDetailPdf);
      formData.append(
        "jobLocation",
        jobDetails?.city + " ," + jobDetails?.stateName
      );
      formData.append(
        "jobLink",
        window.location.origin + "/jobdetail/" + id + "/jobs"
      );
      formData.append(
        "recruiterPhone",
        recruiterData?.phoneNumbers?.primary !== undefined
          ? formatPhone(recruiterData?.phoneNumbers?.primary)
          : null
      );
    } else if (userRole === UserAuth.Recruiter) {
      getRecruiterInformationAccess({ EmailId: values.email })
        .then(async (res) => {
          
          let jobDetailPdf = await getShareJobPdfFile({
            jobDetail: jobDetails,
          });

          let role = getUserDetails()?.userRoleType;
          formData.append("role", role);
          formData.append("recruiterEmail", getUserDetails()?.email);
          formData.append("jobId", jobDetails?.id);
          formData.append("shareTo", values.email);
          formData.append("recruiterFullName", getUserDetails()?.userName);
          formData.append("jobTitle", jobDetails?.jobTitle);
          formData.append("jobDetailFile", jobDetailPdf);
          formData.append(
            "jobLocation",
            jobDetails?.city + " ," + jobDetails?.stateName
          );
          formData.append(
            "jobLink",
            window.location.origin + "/jobdetail/" + id + "/jobs"
          );
          formData.append(
            "recruiterPhone",
            recruiterData?.phoneNumbers?.primary !== undefined
              ? formatPhone(recruiterData?.phoneNumbers?.primary)
              : null
          );
          ShareJobByRecruiter(formData)
            .then(() => {
              setShareJobState({
                ...shareJobState,
                success: true,
                msg: "Job Shared successfully",
                popup: false,
              });
            })
            .catch(() => {
              setShareJobState({
                ...shareJobState,
                isSharing: false,
                popup: false,
                success: false,
              });
            });
        })
        .catch(() => {
          setShareJobState({
            ...shareJobState,
            isSharing: false,
            popup: false,
            success: false,
          });
        });
    } else {
      let clientData = await (
        await GetClientById(getUserDetails()?.clientId ?? "")
      ).data.entity;

      let jobDetailPdf = await getShareJobPdfFile({ jobDetail: jobDetails });
            
      let role = getUserDetails()?.userRoleType;
      formData.append("role", role);
      formData.append("recruiterEmail", clientData?.email);
      formData.append("jobId", jobDetails?.id);
      formData.append("shareTo", values.email);
      formData.append("recruiterFullName", clientData?.companyName);
      formData.append("jobTitle", jobDetails?.jobTitle);
      formData.append("jobDetailFile", jobDetailPdf);
      formData.append(
        "jobLocation",
        jobDetails?.city + " ," + jobDetails?.stateName
      );
      formData.append(
        "jobLink",
        window.location.origin + "/jobdetail/" + id + "/jobs"
      );
      formData.append(
        "recruiterPhone",
        clientData?.contactDetails?.primaryPhoneNumber !== undefined
          ? formatPhone(clientData?.contactDetails?.primaryPhoneNumber)
          : null
      );
    }
    
    ShareJobByRecruiter(formData)
      .then(() => {        
        setShareJobState({
          ...shareJobState,
          success: true,
          msg: "Job Shared successfully",
          popup: false,
        });
      })
      .catch(() => {
        setShareJobState({
          ...shareJobState,
          isSharing: false,
          popup: false,
          success: false,
        });
      });
  };

  function renderPayRate() {
    if (userRole === UserAuth.Client || userRole === UserAuth.ClientTeamMember) {
      return (
        <Chip
          className="custom-button-Robo custom-noborder custom-chiptxt-color"
          icon={
            <Tooltip title="Pay Rate">
              <CurrencyIcon />
            </Tooltip>
          }
          label={
            !isLoadingJob
              ? `${
                  formattedBillRate.packageValueAndCurrency +
                  " " +
                  formattedBillRate.packageType
                }`
              : ""
          }
          variant="outlined"
        />
      );
    }
    else if (userRole === UserAuth.NotLoggedIn) {
      return ("");
    }
    else {
      return (
        <Chip
          className="custom-button-Robo custom-noborder custom-chiptxt-color"
          icon={
            <Tooltip title="Pay Rate">
              <CurrencyIcon />
            </Tooltip>
          }
          label={ternary(
            !isLoadingJob,
            `${
              formattedPayRate.packageValueAndCurrency +
              " " +
              formattedPayRate.packageType
            }`,
            ""
          )}
          variant="outlined"
        />
      );
    }
  }

  const renderDescriptionAndShareButtons = () => {
    const commonDescription = (
      <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
        <Typography className="custom-description-heading">
          Description
        </Typography>
      </Grid>
    );
  
    const commonButtons = (
      <SaveShareSubmitButtons
        saveJobAPI={saveJobAPI}
        jobDetails={jobDetails}
        isSavedJob={isSavedJob}
        unsaveJobAPI={unsaveJobAPI}
        recruiterStatus={userStatus}
        featureJobAPI={featureJobAPI}
        isFeatured={featureState}
        isLoadingJob={isLoadingJob}
        shareJob={onShareJobClick}
        shareJobState={shareJobState}
        setDisplayContactPopup={setDisplayContactPopup}
        saveUnsavedJobStatus={saveUnsavedJobStatus}
        setSaveUnsavedJobStatus={setSaveUnsavedJobStatus}
        setShareJobState={setShareJobState}
      />
    );
  
    if (userRole === UserAuth.Admin || userRole === UserAuth.SuperAdmin) {
      return (
        <>
          {commonDescription}
          <Grid>{commonButtons}</Grid>
        </>
      );
    } else if (userRole === UserAuth.Recruiter) {
      return (
        <>
          {commonDescription}
          {commonButtons}
        </>
      );
    } else {
      return (
        <>
          {commonDescription}
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            {commonButtons}
          </Grid>
        </>
      );
    }
  };

  function getBreadcrumb(navigateFromPage:string) {
    switch (navigateFromPage) {
        case PageName.SAVEDJOBPAGE:
            return fromPageSavedJobListBreadCrumbs;
        case PageName.RecruiterList:
            return fromPageRecruiterListBreadCrumbs((location.state as any)?.recruiterID ?? "");
        default:
            return DefaultBreadcrumbs;
    }
  }
  return (
    <Grid container spacing={0}>
      <Grid item xs={12} md={12}>
          <AppBreadCrumb
            classes="custom-appbreadcrumb"
            BreadCrumbItems= { ternary(userRole === UserAuth.NotLoggedIn, DefaultNotLoggedUseerBreadcrumbs , getBreadcrumb(BreadcrumbState?.navigateFromPage))}
          />
        </Grid>
      <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
        <Typography
          component="h1"
          className="custom-client-job"
          gutterBottom
          sx={{
            marginTop: "20px",
          }}
        >
          <span>
            {" "}
            {jobDetails?.id} - {jobDetails?.jobTitle}
            {jobDetails?.isFeatured && (
              <Tooltip title="Featured Job">
                <img
                  className="ht-23 featurejobIcon featured-icon-mr"
                  src={featuredJobIcon}
                />
              </Tooltip>
            )}
          </span>
          {jobDetails?.isDisabled && (
            <Chip
              className="chip-notActive"
              label={ternary(jobDetails?.isDisabled === true, "Not Active", "")}
            />
          )}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Paper elevation={0} className="marginRightPaper">
          <Grid
            container
            className="custom-clientjobdetail-chip"
            style={{ backgroundColor: "white" }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Stack
                direction={dynamicStyles?.direction}
                spacing={2}
                sx={{ marginBottom: "0px" }}
                className="activity-chips custom-activity-chip"
              >
                <Chip
                  className="custom-button-Robo custom-btn-chip custom-chiptxt-color"
                  icon={
                    <Tooltip title="Location ">
                      <MapMarkerIcon />
                    </Tooltip>
                  }
                  label={ternary(!isLoadingJob, jobLocation(), "")}
                  variant="outlined"
                />
                <Chip
                  className="custom-button-Robo custom-chiptxt-color"
                  icon={
                    <Tooltip title="Experience">
                      <EyeIcon />
                    </Tooltip>
                  }
                  label={ternary(
                    !isLoadingJob,
                    jobDetails?.jobExperience + " Years",
                    ""
                  )}
                  variant="outlined"
                />
                <Chip
                  className="custom-button-Robo custom-chiptxt-color"
                  icon={
                    <Tooltip title="Job Type">
                      <ContractIcon />
                    </Tooltip>
                  }
                  label={!isLoadingJob ? formattedJobType() : ""}
                  variant="outlined"
                />

                {renderPayRate()}
              </Stack>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              backgroundColor: "white",
              paddingTop: "14px",
              paddingBottom: "20px",
            }}
            className="custom-text-btn custom-clientjobdetail-padding"
          >
            {renderDescriptionAndShareButtons()}
          </Grid>
          <Grid container style={{ backgroundColor: "white" }}>
            <Grid
              className="custom-clientjobdetail-padding"
              item
              xs={12}
              sm={9}
              md={9}
              lg={9}
              xl={9}
            >
              <div
                className="custom-button-Robo custom-jobDesc"
                dangerouslySetInnerHTML={{ __html: jobDetails?.jobDesc }}
              />
            </Grid>
            <Grid
              style={{ paddingRight: "32px" }}
              item
              xs={12}
              sm={4}
              md={3}
              lg={3}
              xl={3}
            >
              <ClientRightSideBar
                isLoadingJob={isLoadingJob}
                jobDetails={jobDetails}
              />
            </Grid>
          </Grid>
          <Grid
            className="custom-clientjobdetail-padding custom-paadingtop"
            container
            style={{ backgroundColor: "#FFF" }}
          >
            <ClientJobInformation
              jobDetails={jobDetails}
              handleCardClick={handleCardClick}
              savedJobCount={savedJobCount}
            />
          </Grid>
          <Dialog
            open={shareJobState.popup}
            onClose={() => {
              setShareJobState({ ...shareJobState, popup: false });
            }}
            sx={{ width: "100%", alignSelf: "center" }}
          >
            <DialogContent sx={{ bgcolor: "#FFF" }}>
              <IconButton
                data-testid={"onClose"}
                aria-label="close"
                onClick={() =>
                  setShareJobState({ ...shareJobState, popup: false })
                }
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <form onSubmit={formik.handleSubmit} noValidate>
                <Grid container spacing={3} columns={1} textAlign={"center"}>
                  <Grid item xs={1} textAlign={"left"}>
                    <FormControl
                      variant="standard"
                      required
                      fullWidth
                      margin="normal"
                    >
                      <InputLabel htmlFor="email">Enter Email address</InputLabel>
                      <Input
                        inputProps={{ "data-testid": "shareEmailTextField" }}
                        placeholder="Enter email here"
                        id="email"
                        name="email"
                        type="email"
                        variant="standard"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                      />
                      <ErrorMessage
                        errorText={formik.touched.email && formik.errors.email}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      data-testid={"sharejobBtn"}
                    >
                      Share Job
                      <NearMeOutlinedIcon />
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </DialogContent>
          </Dialog>
          {displayContactPopup && (
            <ContactInformationPopup
              isClientJob={jobDetails?.clientId}
              contactData={contactInformationData}
              openPopup={displayContactPopup}
              onlyPartnerInfo={true}
              recruitersGroupInfo={jobDetails?.locationGroup}
              handleDocumentsPopupClose={() => setDisplayContactPopup(false)}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}
