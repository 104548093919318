import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { PermissionsEnums } from "../../../../data/permission";
import { JobOriginIds } from "../../../../models/Job/JobDetailsModel";
import { UserAuth } from "../../../../models/interfaces";
import { CandidateSubmissionStatus } from "../../../../models/submission/CandidateSubmissionMasterStatusesModel";
import { ModuleComponent } from "../../../Common/Permission/ModuleComponent";
import "../../NewViewSubmission/styles.scss";

interface NextRoundOfInterviewButtonProps {
  row: any;
  userRole: any;
  currentjobDetails: any;
  setPopup: any;
  hasActiveEvent: any;
}

export default function NextRoundOfInterviewButton({
  row,
  userRole,
  currentjobDetails,
  setPopup,
  hasActiveEvent
} : NextRoundOfInterviewButtonProps) {

  const shouldRenderNextRoundButton = (
    !hasActiveEvent(row?.interviewAvailabilities || []) &&
    currentjobDetails?.jobOriginId === JobOriginIds.jobRialto &&
    (row.finalStatusId === CandidateSubmissionStatus.InterviewScheduled ||
      row.finalStatusId === CandidateSubmissionStatus.InterviewRescheduled ||
      row.finalStatusId === CandidateSubmissionStatus.NextRoundOfInterview ||
      row.finalStatusId === CandidateSubmissionStatus.InterviewCancelled) &&
    row.finalStatusId !== CandidateSubmissionStatus.NextRoundOfInterview &&
    (userRole === UserAuth.Client || userRole === UserAuth.ClientTeamMember)
  );

  const handleNextRoundButtonClick = () => {
    if (shouldRenderNextRoundButton) {
      setPopup((popup: any) => ({ ...popup, nextRoundOfInterview: true }));
    }
  };

  return (
    <>
    {shouldRenderNextRoundButton && (
      <ModuleComponent moduleId={PermissionsEnums.NextRoundOfInterview}>
        <Grid item>
          <Button
            className="submission-action-btn"
            variant="contained"
            color="primary"
            onClick={handleNextRoundButtonClick}
          >
            Next Round of Interview
          </Button>
        </Grid>
      </ModuleComponent>
    )}
    </>
  );
}