import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import "./ViewRecruiterInfoPopup.css";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";


interface Props {
  isOpen: boolean;
  handleOnClose: () => void;
  onConfirmReSchedule: () => void;
}

export const ConfirmRescheduleInterviewPopup: React.FC<Props> = ({
  isOpen,
  handleOnClose,
  onConfirmReSchedule,
}) => {
  

  return (
    <>
      <Dialog
        className="custom-popup-border"
        open={isOpen}
        onClose={handleOnClose}
        aria-labelledby="viewRecruiterPopup-title"
        aria-describedby="viewRecruiterPopup-description"
      >
        <Paper elevation={5} style={{ backgroundColor: "#FFF" }}>
          <DialogTitle sx={{ m: 0, p: 2 }}>
            <IconButton
              aria-label="close"
              onClick={handleOnClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="viewRecruiterPopup-description">
              <Grid container spacing={1} mt={1}>
                <Grid item xs={12}>
                  <Typography
                    data-testid="center"
                    align="center"
                    className="custom-reject-txt"
                  >
                    Re-schedule interview will lead to the cancellation of the
                    already scheduled interview. Are you sure you want to
                    reschedule?
                  </Typography>
                </Grid>
              </Grid>
            </DialogContentText>
            <Grid
              item
              xs={12}
              sm={12}
              className="row stc-btnRow"
              style={{
                marginTop: "16px",
                marginRight: "0px",
                textAlign:"right"
              }}
            >
              <Button
                className="submission-action-btn stc-btn"
                variant="outlined"
                style={{ textTransform: "capitalize" }}
                onClick={handleOnClose}

              >
                Cancel
              </Button>
              <Button
                data-testid="contained"
                className="submission-action-btn stc-btn"
                variant="contained"
                style={{ textTransform: "capitalize" }}
                onClick={onConfirmReSchedule}
              >
                Reschedule Interview
              </Button>
            </Grid>
          </DialogContent>
          <DialogActions></DialogActions>
        </Paper>
      </Dialog>

    </>
  );
};
export default ConfirmRescheduleInterviewPopup;
