import * as Yup from "yup";
import {
  addressRegex,
  cityRegex,
  companyNameRegExp,
  firstNameRegex,
  linkedInRegex,
  middleNameRegex,
  passWordRegex,
  phoneNumberRegex,
  urlRegExp,
  zipValidation,
} from "../../../../../constants/regex";
import { LoginTypeName } from "../../../../../models/interfaces";
import { isValidEmailWithWebsiteDomain } from "../../../../../utils/emailUtils";

const SignUpSchema = Yup.object().shape({
  password: Yup.string().when('loginType', {
    is: (loginType: string) => (loginType === LoginTypeName.Email_UserID),
    then: Yup.string()
    .required(
      "The password must be 8-15 characters long and should contain at least one or more of: Uppercase characters (A-Z) Lowercase characters (a-z) Digits (0-9) Special characters (~!@#$%^&*_-+=`|(){}[]:;\"'<>,.?/)"
    )
    .matches(
      passWordRegex,
      "Password must be 8 -15 characters long and must contain atleast one or more of digits, special characters,  upper case and lower case letters"
    ),
    otherwise: Yup.string().notRequired(),
  }),
  confirmPassword:
  Yup.string().when('loginType', {
    is: (loginType: string) => (loginType === LoginTypeName.Email_UserID),
    then: Yup.string()
    .required("Please re-enter the password. Minimum 8 characters are required")
    .oneOf(
      [Yup.ref("password"), null],
      "Password and Confirm password do not match"
    ),
    otherwise: Yup.string().notRequired(),
  })  ,
  companyName: Yup.string()
    .required("Please enter company name").matches(companyNameRegExp, "Please enter valid company name")
    .max(100, "Max 100 character is allow to enter"),
  companyWebsite: Yup.string()
    .required("Please enter company website")
    .matches(
      urlRegExp,
      "Please enter valid URL starting with http or https"
    )
    .test(
      'is-website-email-domain',
      'Company website does not match with Email domain',
      (value, context) => isValidEmailWithWebsiteDomain(context.parent.email, value)
    ),
  companySize: Yup.string()
    .required("Please select company size")
    .max(100, "Max 100 character is allow to enter"),
  industries: Yup.array()
    .required("Please select industry")
    .min(1, "Please select up to 5 industry preferences"),
  contactDetails: Yup.object().shape({
    firstName: Yup.string()
      .required("Please enter the first name")
      .matches(firstNameRegex, "Entered First Name is invalid"),
    middleName: Yup.string().matches( middleNameRegex,"Middle Name is not valid"),
    lastName: Yup.string()
      .required("Please enter the last name")
      .matches(firstNameRegex, "Entered Last Name is invalid"),
    position: Yup.string()
      .required("Please enter position").matches(
        companyNameRegExp,
        "Entered position is not valid"
      )
      .max(50, "Max 50 character is allow to enter"),
    primaryPhoneNumber: Yup.string()
      .required("Please enter the phone number")
      .matches(phoneNumberRegex, "Entered Phone number is invalid"),
  }),
  address: Yup.object().shape({
    id: Yup.string(),
    streetAddress: Yup.string()
      .required("Please enter the address")
      .matches(addressRegex, "Entered Address is invalid"),
    city: Yup.string()
      .required("Please enter the city")
      .matches(cityRegex, "Entered City is invalid"),
    state: Yup.string()
      .required("Please enter the state")
      .matches(cityRegex, "Entered State is invalid"),
    postalCode: Yup.string()
      .required("Please enter the zip")
      .matches(zipValidation, "Entered Zip is invalid"),
    countryCode: Yup.string()
      .required("Please enter the country")
      .matches(cityRegex, "Entered Country is invalid"),
  }),
  clientAdditionalDetails: Yup.object().shape({
    id: Yup.string(),
      companyOverview: Yup.string()
      .max(1500, "Max 1500 character is allow to enter"),
      companyBenefits: Yup.string()
      .max(1500, "Max 1500 character is allow to enter"),
      hiringProcess: Yup.string()
      .required("Please describe hiring process")
      .max(1500, "Max 1500 character is allow to enter"),
      linkedInProfileUrl: Yup.string()
      .matches(linkedInRegex, "LinkedIn URL doesn't match")
      .max(1500, "Max 1500 character is allow to enter"),
  }),
  consentPolicyConfirm: Yup.boolean().isTrue("Please acknowledge if you agree to the terms before submit").required(),
  contractConfirm: Yup.boolean()
    .test(
      'is-ready-for-contract',
      "Please fill out email and company name first",
      (_, context) => (context.parent.email && context.parent.companyName)
    )
    .isTrue("Please sign the contract before submit")
    .required(),
  signatureRequestId: Yup.string()
    .required("Contract information is missing"),
  signatureId: Yup.string()
    .required("Contract information is missing"),
});

export default SignUpSchema;
