import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { PermissionsEnums } from "../../../../data/permission";
import { JobOriginIds } from "../../../../models/Job/JobDetailsModel";
import { UserAuth } from "../../../../models/interfaces";
import { CandidateSubmissionStatus } from "../../../../models/submission/CandidateSubmissionMasterStatusesModel";
import { ModuleComponent } from "../../../Common/Permission/ModuleComponent";
import "../../NewViewSubmission/styles.scss";

interface ViewSharedAvailabilityButtonProps {
  row: any;
  userRole: any;
  currentjobDetails: any;
  setPopup: any;
  hasActiveEvent: any;
}

export default function ViewSharedAvailabilityButton({
  row,
  userRole,
  currentjobDetails,
  setPopup,
  hasActiveEvent
} : ViewSharedAvailabilityButtonProps) {
  const shouldRenderViewSharedAvailability = (
    hasActiveEvent(row?.interviewAvailabilities || []) &&
    currentjobDetails?.jobOriginId === JobOriginIds.jobRialto &&
    (row.finalStatusId === CandidateSubmissionStatus.SubmittedToCLI ||
      row.finalStatusId === CandidateSubmissionStatus.InterviewScheduled ||
      row.finalStatusId === CandidateSubmissionStatus.NextRoundOfInterview ||
      row.finalStatusId === CandidateSubmissionStatus.InterviewRescheduled ||
      row.finalStatusId === CandidateSubmissionStatus.InterviewCancelled) &&
    (userRole === UserAuth.Client ||
      userRole === UserAuth.ClientTeamMember ||
      userRole === UserAuth.Recruiter)
  );

  const handleViewSharedAvailabilityClick = () => {
    if (shouldRenderViewSharedAvailability) {
      setPopup((popup: any) => ({ ...popup, viewSharedAvailability: true }));
    }
  };

  return (
    shouldRenderViewSharedAvailability && (
      <ModuleComponent moduleId={PermissionsEnums.ViewSharedAvailability}>
        <Grid item>
          <Button
            className="submission-action-btn"
            variant="contained"
            color="primary"
            onClick={handleViewSharedAvailabilityClick}
          >
            View Shared Availability
          </Button>
        </Grid>
      </ModuleComponent>
    )
  );
}