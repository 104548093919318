import { useState ,useEffect} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {DialogActions, DialogContentText, Typography } from "@mui/material";
import {UploadAttachment ,DeleteAttachment} from "../../../../Services/uploadPhotoService";
import { CandidateAuthorize } from "../../../../Services/recruiterService";
import { CustomAlert } from "../../../ui/CustomAlert";
import { DocumentTypeEnum } from "../../../../models/interfaces";
import { downloadFile } from "../../../../utils/getFileFromUrl";
import CircularProgress from "@mui/material/CircularProgress";
import "./RtrBypass.scss";
import { valueOrDefault } from "../../../../utils/complexityUtils";
interface Props {
  isOpen: boolean;
  rtrPopup: any;
  handleClose: (open: boolean) => void;
  setIsConfirmRTR: any;
}
export const RTRBypass: React.FC<Props> = ({
    rtrPopup,
  handleClose,
  setIsConfirmRTR
})=>
 {
  const MAX_FILES_SIZE = 2097152;
  const SUPPORTED_FORMATS = ["jpeg", "png", "pdf"];
  const [isDelConfirm,setIsDelConfirm] = useState(false);
  const [isConfirm,setIsConfirm] = useState(false);
  const [documentInfo,setDocumentInfo] = useState({fileName:"",newFileName:"",resourceUrl:"",documentType:""});
  const [file, setFile] = useState<File>();
  const [fileName, setFileName] = useState("");
  const [pageStatus, setPageStatus] = useState({
    isSuccess: false,
    error: "",
    loading: false,
  });
  const clearState = () =>{
    setDocumentInfo({fileName:"",newFileName:"",resourceUrl:"",documentType:"RTRDoc"});
        setPageStatus({
      isSuccess: false,
      error: "",
      loading: false,
    });
    setIsConfirm(false);
  }
  const handleUploadClick = (files:FileList) => {
    if (files.length == 0) return;
    setPageStatus({
      isSuccess:false,
      error:"",
      loading:true
    });
    if (!SUPPORTED_FORMATS.includes(valueOrDefault(files[0].name.split(".").pop(), ""))) {
      setPageStatus({
        isSuccess:false,
        error:"File type is not supported",
        loading:false
      });
      return;
    }
    if (files[0].size > MAX_FILES_SIZE) {
      setPageStatus({
        isSuccess:false,
        error:"Maximum file size is 2MB",
        loading:false
      });
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    setFile(files[0]);
    setFileName(files[0].name);
    setPageStatus({
      isSuccess:false,
      error:"",
      loading:false
    });
  };
  const handleConfirm = () =>{
    setPageStatus({
      isSuccess:false,
      error:"",
      loading:true
    });
    let payload = {};
    if(documentInfo?.fileName === ""){
      payload = {
        submissionId: rtrPopup?.submissionId,
        recruiterName:rtrPopup?.recruiterName,
        recruiterEmail: rtrPopup?.recruiterEmail,
        rtrDoc:null,  // to send null in case of not uploading document
        bypassRtr: true,
      };
    }else{
      payload = {
        submissionId: rtrPopup?.submissionId,
        recruiterName:rtrPopup?.recruiterName,
        recruiterEmail: rtrPopup?.recruiterEmail,
        rtrDoc:documentInfo,
        bypassRtr: true,
      };
    }
    CandidateAuthorize(payload).then((res) => {
      if(res?.data?.status == 200){
        setIsConfirm(true);
        setIsConfirmRTR(true);
        setPageStatus({ isSuccess: true, error: "Candidate RTR completed successfully." ,loading: false});
      }else{
        setPageStatus({ isSuccess: false, error: res?.data?.message?.appStatusDescription ,loading: false});
      }
    });
    }
  const handleDelCondirm = () =>{
    setPageStatus({
      isSuccess:false,
      error:"",
      loading:true
    });
    DeleteAttachment(DocumentTypeEnum.RTR, documentInfo?.fileName)
    .then(res =>{
        if(res?.data.status == 200){
          setDocumentInfo({fileName:"",newFileName:"",resourceUrl:"",documentType:DocumentTypeEnum.RTR});
          setPageStatus({
            isSuccess:true,
            error:"File deleted successfully",
            loading:false
          })
          setIsDelConfirm(false);
        }
})
      }
      useEffect(() => {
    if (file) {
            setPageStatus({ isSuccess: false, error: "", loading: true });
      const model = {
        documentType: DocumentTypeEnum.RTR,
        file: file,
      };
      UploadAttachment(model)
        .then((response) => {
          console.log(response.data);

          if (
            response.data.status == 200 &&
            response.data.message.appStatusCode == "RC_RSF"
          ) {
            setDocumentInfo({
              fileName: response.data.entity.fileName,
              resourceUrl: response.data.entity.absoluteUri,
              newFileName: response.data.entity.newFileName,
              documentType:"RTRDoc"
            });
                        setPageStatus({ isSuccess: false, error: "", loading: false });
          } else {
            setPageStatus({
              isSuccess: false,
              error: "Unexpected error!",
              loading: false,
            });
            console.error(response.data.message.appStatusDescription);
          }
        })
        .catch((err) => {
          setPageStatus({
            isSuccess: false,
            error: err.message,
            loading: false,
          });
        });
    }
  }, [file, fileName]);
  return (
    <>
      <Dialog
        open={rtrPopup?.isOpen}
        onClose={() => {
          clearState();
          handleClose(false);
        }}
        className={"rtr-bypass"}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
      >
        <DialogTitle
          align="center"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid item xs={12} className="ManageJobAlert">
            Bypass RTR
          </Grid>
          <IconButton>
            <CloseIcon
              data-testid="close"
              onClick={() => {
                clearState();
                handleClose(false);
              }}
            />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={1} direction="column" alignItems="center">
            <Grid item xs={12}>
              <Box sx={{ textAlign: "center", marginBottom: "30px" }} className="confirmation-messag">
                Are you sure you want to bypass the candidate RTR process and
                directly submit to Account Manager?
              </Box>
              <Box
                sx={{
                  border: " 1px dashed rgba(56, 78, 183, 0.3)",
                  borderRadius: "4px",
                  padding: "20px",
                  background: "#F8F8FF",
                  maxWidth: "335px",
                  margin: "0 auto 20px",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "50%",
                    background: "#FFFFFF",
                    width: "59px",
                    height: "59px",
                    margin: "0 auto",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="input-wrapper-main"
                >
                  <svg
                    width="19"
                    height="26"
                    viewBox="0 0 19 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.4373 1.09961H2.1373C1.71105 1.09961 1.3623 1.44836 1.3623 1.87461V24.3496C1.3623 24.7759 1.71105 25.1246 2.1373 25.1246H17.6373C18.0636 25.1246 18.4123 24.7759 18.4123 24.3496V8.07461L11.4373 1.09961Z"
                      fill="#00B2AE"
                    />
                    <path
                      d="M17.6371 25.5129H2.13711C1.47836 25.5129 0.974609 25.0091 0.974609 24.3504V1.87539C0.974609 1.21664 1.47836 0.712891 2.13711 0.712891H11.5921L18.7996 7.92039V24.3504C18.7996 25.0091 18.2959 25.5129 17.6371 25.5129ZM2.13711 1.48789C1.90461 1.48789 1.74961 1.64289 1.74961 1.87539V24.3504C1.74961 24.5829 1.90461 24.7379 2.13711 24.7379H17.6371C17.8696 24.7379 18.0246 24.5829 18.0246 24.3504V8.23039L11.2821 1.48789H2.13711Z"
                      fill="#231C1C"
                    />
                    <path
                      d="M11.4375 7.29961C11.4375 7.72586 11.7862 8.07461 12.2125 8.07461H18.4125L11.4375 1.09961V7.29961Z"
                      fill="#008181"
                    />
                    <path
                      d="M18.4127 8.46251H12.2127C11.5539 8.46251 11.0502 7.95876 11.0502 7.30001V1.10001C11.0502 0.945008 11.1277 0.790008 11.2827 0.751258C11.4377 0.673758 11.5927 0.712508 11.7089 0.828758L18.6839 7.80376C18.8002 7.92001 18.8389 8.07501 18.7614 8.23001C18.7227 8.38501 18.5677 8.46251 18.4127 8.46251ZM11.8252 2.03001V7.30001C11.8252 7.53251 11.9802 7.68751 12.2127 7.68751H17.4827L11.8252 2.03001ZM4.0752 11.175H15.3127V11.95H4.0752V11.175ZM4.0752 13.8875H15.3127V14.6625H4.0752V13.8875Z"
                      fill="#231C1C"
                    />
                    <path
                      d="M4.0752 16.5996H15.3127V17.3746H4.0752V16.5996Z"
                      fill="#231C1C"
                    />
                    <path
                      d="M4.0752 19.6992H15.3127V20.4742H4.0752V19.6992Z"
                      fill="#231C1C"
                    />
                  </svg>
                  <input data-testid ="file" type="file" accept={".jpeg,.png,.pdf"}   onChange={(e) => {
                      handleUploadClick(
                                      e.currentTarget.files!
                                    );
                      e.currentTarget.value="";
                    }} className="input-wrapper-inner" disabled={isConfirm}/>
                </Box>
                <Typography className="rtrPopups-txt">
                  If you have a RTR document received from <br /> candidate,
                  upload it here.
                </Typography>
                <Typography className="clicktoBrowse">
                  or click to browse your computer and upload
                </Typography>
                {/* <Typography sx={{textAlign:"center",fontSize:"12px",color:"#676767"}}>
                Supported formats: JPEG, PNG, PDF
                </Typography> */}
                <Typography className="max-file-size-info fileUploadHelper-TextLabel">
                  (Max file size: 2MB as .jpeg, .png, .pdf)
                </Typography>
              </Box>
              {documentInfo?.fileName && (
                <Box className="Uploadfile">
                  <Box>
                    <Typography>{documentInfo?.fileName}</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      sx={{ minWidth: "auto" }}
                      className="bg-none"
                      onClick={() =>
                        downloadFile(
                          documentInfo?.resourceUrl,
                          documentInfo?.fileName
                        )
                      }
                    >
                      <svg
                        width="16"
                        height="14"
                        viewBox="0 0 16 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.5 8.9C0.632608 8.9 0.759785 8.95268 0.853553 9.04645C0.947322 9.14021 1 9.26739 1 9.4V11.9C1 12.1652 1.10536 12.4196 1.29289 12.6071C1.48043 12.7946 1.73478 12.9 2 12.9H14C14.2652 12.9 14.5196 12.7946 14.7071 12.6071C14.8946 12.4196 15 12.1652 15 11.9V9.4C15 9.26739 15.0527 9.14021 15.1464 9.04645C15.2402 8.95268 15.3674 8.9 15.5 8.9C15.6326 8.9 15.7598 8.95268 15.8536 9.04645C15.9473 9.14021 16 9.26739 16 9.4V11.9C16 12.4304 15.7893 12.9391 15.4142 13.3142C15.0391 13.6893 14.5304 13.9 14 13.9H2C1.46957 13.9 0.960859 13.6893 0.585786 13.3142C0.210714 12.9391 0 12.4304 0 11.9V9.4C0 9.26739 0.0526784 9.14021 0.146447 9.04645C0.240215 8.95268 0.367392 8.9 0.5 8.9Z"
                          fill="#4540DB"
                        />
                        <path
                          d="M7.646 10.854C7.69245 10.9006 7.74762 10.9375 7.80837 10.9627C7.86911 10.9879 7.93423 11.0009 8 11.0009C8.06577 11.0009 8.13089 10.9879 8.19163 10.9627C8.25238 10.9375 8.30755 10.9006 8.354 10.854L11.354 7.854C11.4479 7.76011 11.5006 7.63278 11.5006 7.5C11.5006 7.36722 11.4479 7.23989 11.354 7.146C11.2601 7.05211 11.1328 6.99937 11 6.99937C10.8672 6.99937 10.7399 7.05211 10.646 7.146L8.5 9.293V0.5C8.5 0.367392 8.44732 0.240215 8.35355 0.146447C8.25979 0.0526784 8.13261 0 8 0C7.86739 0 7.74021 0.0526784 7.64645 0.146447C7.55268 0.240215 7.5 0.367392 7.5 0.5V9.293L5.354 7.146C5.26011 7.05211 5.13278 6.99937 5 6.99937C4.86722 6.99937 4.73989 7.05211 4.646 7.146C4.55211 7.23989 4.49937 7.36722 4.49937 7.5C4.49937 7.63278 4.55211 7.76011 4.646 7.854L7.646 10.854Z"
                          fill="#4540DB"
                        />
                      </svg>
                    </Button>
                    <Button
                      sx={{ minWidth: "auto" }}
                      className="bg-none"
                      onClick={() => {
                        setIsDelConfirm(true);
                      }}
                    >
                      <svg
                        width="13"
                        height="14"
                        viewBox="0 0 13 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.5738 1.92582H10.8929C10.8704 1.91802 10.847 1.91296 10.8233 1.91064H9.03358V0.426269C9.03368 0.314718 8.98995 0.207621 8.912 0.127949C8.83396 0.0482851 8.72787 0.00232706 8.61643 0H4.38368C4.27061 0 4.16219 0.0448437 4.08232 0.124811C4.00236 0.204778 3.95741 0.313191 3.95741 0.426266V1.91063H2.17675C2.15306 1.91296 2.12968 1.91802 2.10721 1.92582H0.426259C0.273914 1.92582 0.1332 2.007 0.0570913 2.1389C-0.0190304 2.27079 -0.0190304 2.43325 0.0570913 2.56515C0.133213 2.69705 0.273917 2.77834 0.426259 2.77834H1.79575L2.90234 13.0726C2.91419 13.1774 2.96419 13.274 3.04285 13.3444C3.1214 13.4146 3.22313 13.4534 3.3286 13.4535H9.67747C9.78183 13.452 9.88205 13.4124 9.95949 13.3423C10.0368 13.2722 10.0859 13.1763 10.0977 13.0726L11.2042 2.79336H12.5737C12.7261 2.79336 12.8668 2.71218 12.9429 2.58028C13.019 2.44838 13.019 2.28582 12.9429 2.15402C12.8668 2.02213 12.7261 1.94084 12.5737 1.94084L12.5738 1.92582ZM4.81913 0.852523H8.18092V1.91063H4.81913V0.852523ZM9.29055 12.5948H3.7095L2.65139 2.79346H10.3486L9.29055 12.5948Z"
                          fill="#E41D1D"
                        />
                        <path
                          d="M6.50145 11.599C6.61452 11.599 6.72293 11.5541 6.80291 11.4742C6.88287 11.3942 6.92772 11.2858 6.92772 11.1727V4.2193C6.92772 4.06696 6.84653 3.92624 6.71464 3.85014C6.58274 3.77391 6.42018 3.77391 6.28828 3.85014C6.15638 3.92626 6.0752 4.06696 6.0752 4.2193V11.1727C6.0752 11.2858 6.12004 11.3942 6.20001 11.4742C6.27997 11.5541 6.38839 11.599 6.50146 11.599H6.50145Z"
                          fill="#E41D1D"
                        />
                        <path
                          d="M4.82221 11.593H4.84033C4.95289 11.5883 5.05898 11.5392 5.13551 11.4565C5.21194 11.3738 5.25263 11.2642 5.24848 11.1516L4.99147 4.19819L4.99157 4.19808C4.98924 4.08421 4.94055 3.9761 4.85664 3.89896C4.77262 3.82193 4.66087 3.78255 4.5471 3.78994C4.43453 3.7947 4.32845 3.84379 4.25202 3.9265C4.17549 4.0092 4.1349 4.11883 4.13895 4.2314L4.39596 11.1848C4.40133 11.2945 4.4485 11.3979 4.52786 11.4738C4.60712 11.5496 4.71249 11.5924 4.82221 11.593L4.82221 11.593Z"
                          fill="#E41D1D"
                        />
                        <path
                          d="M8.16368 11.5934H8.17877C8.29113 11.5959 8.39995 11.5542 8.48193 11.4774C8.56382 11.4005 8.61241 11.2945 8.61717 11.1822L8.87418 4.22877H8.87408C8.87823 4.11621 8.83754 4.00658 8.76111 3.92387C8.68459 3.84117 8.5785 3.79208 8.46593 3.78732C8.35084 3.77548 8.23605 3.81263 8.1497 3.88976C8.06336 3.96679 8.01356 4.07672 8.01244 4.19254L7.7524 11.146H7.7525C7.74673 11.2601 7.78701 11.3717 7.86435 11.4558C7.94159 11.5399 8.04949 11.5895 8.16368 11.5934L8.16368 11.5934Z"
                          fill="#E41D1D"
                        />
                      </svg>
                    </Button>
                  </Box>
                </Box>
              )}
              {!pageStatus.isSuccess && pageStatus.error && (
                <Box sx={{ padding: "9px 9px", margin: "0 auto" }}>
                  <CustomAlert
                    type={"error"}
                    message={pageStatus.error}
                    onClose={() => {}}
                  />
                </Box>
              )}
              {pageStatus.isSuccess && pageStatus.error && (
                <Box sx={{ padding: "9px 9px", margin: "0 auto" }}>
                  <CustomAlert
                    type={"success"}
                    message={pageStatus.error}
                    onClose={() => {}}
                  />
                </Box>
              )}
            </Grid>
            <Grid item xs={12} className="cancelConfirm-btn">
              <Button
                data-testid="handleClose"
                onClick={() => {
                  clearState();
                  handleClose(false)
                }}
                disabled={pageStatus.loading}
                variant="outlined"
                style={{ marginRight: "15px" }}
                endIcon={
                  pageStatus?.loading ? (
                    <CircularProgress size={"16px"} color={"inherit"} />
                  ) : (
                    ""
                  )
                }
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => handleConfirm()}
                disabled={pageStatus.loading || isConfirm}
                endIcon={
                  pageStatus?.loading ? (
                    <CircularProgress size={"16px"} color={"inherit"} />
                  ) : (
                    ""
                  )
                }
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isDelConfirm}
        //onClose={setUpdateConfirmation(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            padding: "20px 24px 10px 24px",
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ padding: "0px 0px 5px 0px" }}
        >
          <IconButton
            aria-label="close"
            size="small"
            className="close-Btn"
            style={{ position: "absolute", right: "5px", top: "10px" }}
            onClick={() => {
              setIsDelConfirm(false);
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent
          style={{ padding: "20px 0px 10px 0" }}
          sx={{ justifyContent: "center" }}
        >
          <DialogContentText>
            <Typography
              variant="h6"
              component="div"
              className="deletefileFontSizes"
            >
              Are you sure you want to delete this file?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: "center" }}
          style={{ padding: "15px 0 7px 0" }}
        >
          <Button onClick={handleDelCondirm} variant="contained" className="deletefileFontSizes">
            Ok
          </Button>
          <Button onClick={() => setIsDelConfirm(false)} variant="outlined" className="deletefileFontSizes">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default RTRBypass;