import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import staticPageTheme from "../../Shared/theme/staticPageTheme";


const TermsAndConditions: React.FC = () => {
  return (
    <div>
      
      <ThemeProvider theme={staticPageTheme}>

      <Container
        maxWidth="xl"
        className="login-banner-text"
        sx={{
          marginTop: "16px",
        }}
      >
        <Typography
          variant="h3"
          component="span"
          className=" letter-spacing-normal primary-color-text"
          gutterBottom

        >
          <div style= {{marginTop:"60px",textAlign:"center", marginBottom:"25px"}}>Terms {"&"} Conditions</div>
        </Typography>

        <Typography
          className = "primary-color-text"
        >
        Acceptance of Terms
        </Typography>

        <Typography
          sx = {{marginBottom: "25px"}}
        
        >
        Welcome to JobRialto.com. Please read the following Terms of Use (the "Terms") carefully. By using the Site, other than to read these Terms for the first time, you agree that your use of the Site will be governed by the Terms. JobRialto, Inc. ("JobRialto") reserves the right to change these Terms from time to time. Please refer to this site periodically for any changes.
        </Typography>


        <Typography
          className = "primary-color-text"
        >
        Description of Site and License
        </Typography>

        <Typography
          sx = {{marginBottom: "25px"}}
        
        >
          The Site provides information about JobRialto's products and services. The text, images, and other contents of the Site (collectively, the "Contents") are protected by the copyright laws of the United States and other countries. JobRialto grants you a license to view the Contents on the publicly accessible areas of the Site. This license does not include the right to screen scrape, reproduce, distribute, broadcast, modify, or otherwise commercially exploit any of the Contents, except that reproduction incidental to your use of the Site in accordance with these Terms is permitted. The license can be terminated by JobRialto at any time with or without cause.
        </Typography>

        <Typography
          className = "primary-color-text"
        >
        Modifications to Site
        </Typography>

        <Typography
          sx = {{marginBottom: "25px"}}
        
        >
          JobRialto reserves the right at any time and from time to time to modify the Contents or discontinue providing access to the Site (or any part thereof), temporarily or permanently, with or without notice to you. You agree that JobRialto will not be liable to you for any modification, suspension or discontinuance of the Site or the Contents.
        </Typography>

        <Typography
          className = "primary-color-text"
        >
        Termination of Your Access
        </Typography>

        <Typography
          sx = {{marginBottom: "25px"}}
        
        >
          You agree that JobRialto, in its sole discretion, may terminate your access to or use of the Site, for any reason, including, without limitation, our belief that you have violated or acted inconsistently with the letter or spirit of these Terms. You agree that any termination of your access to the Site under any provision of these Terms may occur without prior notice to you, and you also agree that JobRialto will not be liable to you for any termination of your access to the Site.
          
          You agree that you are solely responsible for obtaining and maintaining any and all equipment and services needed for your access to and use of the Site, and for all charges related thereto.
        </Typography>

        <Typography
          className = "primary-color-text"
        >
        Restrictions on Your Use of the Site
        </Typography>

        <Typography
          sx = {{marginBottom: "25px"}}
        
        >
          JobRialto will take action to prevent the misuse of the Site for any unlawful or objectionable purpose. Accordingly, you agree not to use the Site for any unlawful or objectionable purpose, including without limitation use of the Site to post, upload, or transmit any Content that is: (1) unlawful under the laws of any jurisdiction to which you or JobRialto are subject; (2) harmful, threatening, harassing, defamatory, invasive of the privacy of another, or obscene (including without limitation any images or depictions of child abuse, child pornography, or minors engaged in sexual conduct or explicitly sexual situations); (3) insider information, or any other proprietary or confidential information; (4) an infringement of any patent, trademark, trade secret, copyright or other intellectual property right; (5) falsified, including without limitation the use of forged headers or otherwise manipulated identifiers in order to disguise its origin; (6) containing or transmitting software viruses or any other malicious computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software; (7) to be used in any passenger rights claims actions; (8) an advertisement or solicitation for any products or services. You agree to indemnify and hold harmless JobRialto from any liability incurred as the result of your violation of these Terms.
        </Typography>

        <Typography
          className = "primary-color-text"
        >
        Privacy Policy
        </Typography>

        <Typography
          sx = {{marginBottom: "25px"}}
        
        >
          JobRialto's Privacy Policy is described on the website. Please consult it to learn JobRialto's current policies to protect the privacy of your information.
        </Typography>

        <Typography
          className = "primary-color-text"
        >
        Disclaimer of Warranties and Limitation of Liability
        </Typography>

        <Typography
          
        
        >
          YOU AGREE THAT THE SITE AND CONTENTS ARE PROVIDED TO YOU "AS IS" AND "AS AVAILABLE" WITHOUT ANY WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. JobRialto does not warrant that the Site will be available at any given time, secure, or free of error. You use the Site at your own risk, and you assume the risk that any material downloaded by you from the Site may cause loss of data or damage to your computer system.
        </Typography>

        <Typography
          sx = {{marginBottom: "25px"}}
        >
          YOU UNDERSTAND AND AGREE THAT IN NO EVENT WILL JobRialto BE LIABLE TO YOU FOR ANY DIRECT OR INDIRECT DAMAGES, EVEN IF JobRialto IS AWARE OF THE POSSIBILITY OF SUCH DAMAGES, INCLUDING WITHOUT LIMITATION LOSS OF PROFITS OR FOR ANY OTHER SPECIAL, CONSEQUENTIAL, EXEMPLARY OR INCIDENTAL DAMAGES, HOWEVER CAUSED, WHETHER BASED UPON CONTRACT, NEGLIGENCE, STRICT LIABILITY IN TORT, WARRANTY, OR ANY OTHER LEGAL THEORY.
        </Typography>

        <Typography
          className = "primary-color-text"
        >
        Links
        </Typography>

        <Typography
          sx = {{marginBottom: "25px"}}
        
        >
          The Site may contain links to other sites, whose terms of use and privacy policies will be different from ours. JobRialto is not responsible for the contents, terms of use, or privacy policies of external sites.
        </Typography>

        <Typography
          className = "primary-color-text"
        >
        Indemnification
        </Typography>

        <Typography
          sx = {{marginBottom: "25px"}}
        
        >
          You agree to indemnify, defend and hold harmless JobRialto, and its officers, directors, employees, agents, licensors, and suppliers from and against all losses, expenses, damages and costs, including reasonable attorneys' fees, resulting from any violation of these Terms, or any negligent or wrongful activity related to the use of the Site, by you or any other person accessing the Site using your account.
        </Typography>


        <Typography
          className = "primary-color-text"
        >
        Trademarks
        </Typography>

        <Typography
          sx = {{marginBottom: "25px"}}
        
        >
          JobRialto and JobRialto.com are registered trademarks of JobRialto, Inc, a Texas corporation. All rights reserved. All other trademarks appearing on the Site are the property of their respective owners.
        </Typography>

        <Typography
          className = "primary-color-text"
        >
        Choice of Law {"&"} Forum Selection
        </Typography>

        <Typography
          sx = {{marginBottom: "25px"}}
        
        >
          You agree that any claims under these Terms will be subject to the laws of the State of New Jersey and of the United States of America, and you hereby consent to jurisdiction and venue in the courts of theState of New Jersey. These Terms will be construed according to the laws of the State of New Jersey.
        </Typography>

        <Typography
          className = "primary-color-text"
        >
        No Third Party Beneficiaries
        </Typography>

        <Typography
          sx = {{marginBottom: "25px"}}
        
        >
          These Terms create no third party rights or obligations between JobRialto and any other person. It is understood and agreed that the parties do not intend that any third party should be a beneficiary of these Terms.
        </Typography>

        <Typography
          className = "primary-color-text"
        >
        Severability {"&"} Waiver
        </Typography>

        <Typography
          sx = {{marginBottom: "25px"}}
        
        >
          The invalidity of any term or provision of these Terms will not affect the validity of any other provision. Waiver by JobRialto of strict performances of any provision of these Terms will not be a waiver of or prejudice JobRialto's right to require strict performance of the same provision in the future or of any other provision of these Terms.
        </Typography>


        <Typography
          className = "primary-color-text"
        >
        Entire Agreement
        </Typography>

        <Typography
          sx = {{marginBottom: "25px"}}
        
        >
          These Terms constitute the entire agreement between the parties, and there are no promises, terms, conditions, or obligations between the parties other than those contained in these Terms. Subject to JobRialto's right to modify these Terms, no modification of these Terms will be valid unless in writing and signed by both parties.
        </Typography>

        <Typography
          className = "primary-color-text"
        >
        EQUAL EMPLOYMENT OPPORTUNITY
        </Typography>

        <Typography
          sx = {{marginBottom: "25px"}}
        
        >
          JobRialto filters these candidates to the best of their abilities, and with regard to all applicable laws as outlined by the EEOC and other governing bodies. In addition to the protected classes as outlined by the EEOC JobRialto does not discrimination based on sexual orientation or gender identity. 
        </Typography>




      </Container>
      </ThemeProvider>
    </div>
  );
}

export default TermsAndConditions;
