import {
  Autocomplete,
  Chip,
  FormControlLabel,
  FormHelperText,
  Input,
  Switch,
  TextField
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import moment from "moment";
import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import {
  searchOptions
} from "../../../../../constants/googlePlaceOption";
import { shiftTimes } from "../../../../../data/ShiftTimes";
import { JobDomain } from "../../../../../models/Job/JobDomain";
import getAddressForAutoFill from "../../../../../utils/getAddressForAutoFill";
import { Asterisk } from "../../../../ui/Asterisk";
import BasicDatePicker from "../../../../ui/DateRange";
import { ErrorMessage } from "../../../../ui/ErrorMessage";
import JobMutliSkillAutocomeplete from "../FormFields/MutliSelect/JobMutliSkillAutocomeplete";
import JobIndustriesAutocomplete from "../FormFields/SingleSelect/JobIndustriesAutocomplete";
import JobPrimarySkillAutoComplete from "../FormFields/SingleSelect/JobPrimarySkillAutoComplete";
import { JobRequiredDegree } from "../../../../../models/Job/JobRequiredDegree";
import { valueOrDefault } from "../../../../../utils/complexityUtils";

export interface JobDetailsProps {
  handleChange: any;
  handleBlur: (event: React.FocusEvent<any>) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  values: any;
  touched: any;
  errors: any;
  jobDomainsList: JobDomain[];
  jobRequireDegreesList: JobRequiredDegree[];
  jobWorkExperiencesList: any[];
  mode:string | undefined;
}

const JobDetailsFormSection: React.FC<JobDetailsProps> = ({
  handleChange,
  handleBlur,
  setFieldValue,
  values,
  touched,
  errors,
  jobDomainsList,
  jobRequireDegreesList,
  jobWorkExperiencesList,
  mode
}) => {

  const autoCompleteGetJobLocation = async (address: any) => {
    let resp = await getAddressForAutoFill(address);
    setFieldValue("jobDetails.city", valueOrDefault(resp.city, ""));
    setFieldValue("jobDetails.state", valueOrDefault(resp.state, ""));
    setFieldValue("jobDetails.stateCode", valueOrDefault(resp.stateCode, ""));
    setFieldValue("jobDetails.zipCode", valueOrDefault(resp.pin, ""));
    setFieldValue("jobDetails.countryName", valueOrDefault(resp.country, ""));
    setFieldValue("jobDetails.countryCode", valueOrDefault(resp.countryCode, ""));
  };

  interface JobExperienceProps {
    id: string;
    experience: string;
    experienceValue: number;
  }
   const getLabel = (id:string)=>{
    const jobExperience: JobExperienceProps | any = jobWorkExperiencesList?.find(
      (item: any) => item.id === id
    );
    return valueOrDefault(jobExperience?.experience, "");
  }

  const renderFuncCurrentJobLocation = ({
    getInputProps,
    suggestions,
    getSuggestionItemProps,
    loading,
  }: any) => (
    <>
      <FormControl
        variant="standard"
        error={touched?.jobDetails?.city && Boolean(errors?.jobDetails?.city)}
        required
        fullWidth
        className="cust-field-style"
        data-testid="cityinp2"
      >
        <InputLabel htmlFor="address-input">City</InputLabel>
        <Input
          {...getInputProps()}
          name="jobdetails.city"
          type="text"
          onBlur={handleBlur}
          fullWidth
          data-testid="cityinp"
          placeholder="Enter City"
          required
        />
        <ErrorMessage
          errorText={touched?.jobDetails?.city && errors?.jobDetails?.city}
        />
        <div className="autocomplete-dropdown-container">
          {loading && <div>Loading...</div>}
          {suggestions?.map((suggestion: any) => (
            <div {...getSuggestionItemProps(suggestion)}>
              <span
                onChange={autoCompleteGetJobLocation}
                style={{ cursor: "pointer" }}
              >
                {suggestion?.description}
              </span>
            </div>
          ))}
        </div>
      </FormControl>
    </>
  );

  return (
    <>
      <Grid container spacing={2}>
        {/* row 1 */}
        <Grid item xs={12} sm={6} md={6}>
          <InputLabel
            className="additionInfoFieldLabel"
            error={
              touched?.jobDetails?.jobTitle &&
              Boolean(errors?.jobDetails?.jobTitle)
            }
          >
            Job Title <Asterisk />
          </InputLabel>
          <TextField
            required
            id="jobTitle"
            name="jobDetails.jobTitle"
            value={values?.jobDetails?.jobTitle}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              touched?.jobDetails?.jobTitle &&
              Boolean(errors?.jobDetails?.jobTitle)
            }
            helperText={
              touched?.jobDetails?.jobTitle && errors?.jobDetails?.jobTitle
            }
            fullWidth
            type="text"
            multiline
            rows={2}
            variant="outlined"
            className="AdditionInfoTxtArea"
            placeholder="Enter Title"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <InputLabel
            className="additionInfoFieldLabel"
            error={
              touched?.jobDetails?.jobDescription &&
              Boolean(errors?.jobDetails?.jobDescription)
            }
          >
            Job Description <Asterisk />
          </InputLabel>
          <TextField
            required
            id="jobDescription"
            name="jobDetails.jobDescription"
            value={values?.jobDetails?.jobDescription}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              touched?.jobDetails?.jobDescription &&
              Boolean(errors?.jobDetails?.jobDescription)
            }
            helperText={
              touched?.jobDetails?.jobDescription &&
              errors?.jobDetails?.jobDescription
            }
            fullWidth
            type="text"
            multiline
            rows={2}
            variant="outlined"
            className="AdditionInfoTxtArea"
            placeholder="Enter Description"
          />
        </Grid>

        {/* row 2 */}
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            id="jobDomain-standard"
            disableClearable
            options={jobDomainsList?.map((options: any) => options?.domain)}
            getOptionLabel={(option) => option}
            value={values?.jobDetails?.jobDomain}
            onChange={(_, newValue: any) => {
              setFieldValue("jobDetails.jobDomain", newValue);
            }}
            onBlur={handleBlur}
            renderTags={(value, getTagProps) =>
              value.map((option: any, index: any) => (
                <Chip
                  color="primary"
                  size="small"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                required
                {...params}
                variant="standard"
                label="Job Domain"
                name="jobDetails.jobDomain"
                data-testid="autocomplete-domain"
                error={
                  touched?.jobDetails?.jobDomain &&
                  Boolean(errors?.jobDetails?.jobDomain)
                }
                helperText={
                  touched?.jobDetails?.jobDomain &&
                  errors?.jobDetails?.jobDomain
                }
                placeholder="Select Domain"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
                    
          <JobIndustriesAutocomplete
            label="Industry"
            fieldName="jobDetails.jobIndustry"
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            selectedValue={values?.jobDetails?.jobIndustry}
            helperText={
              touched?.jobDetails?.jobIndustry &&
              errors?.jobDetails?.jobIndustry
            }
            isError={
              touched?.jobDetails?.jobIndustry &&
              Boolean(errors?.jobDetails?.jobIndustry)
            }
            isRequired={true}
            placeholderText="Select Industry"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <JobPrimarySkillAutoComplete
            label="Primary Skill"
            fieldName="jobDetails.primarySkill"
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            selectedValue={values?.jobDetails?.primarySkill}
            helperText={
              touched?.jobDetails?.primarySkill &&
              errors?.jobDetails?.primarySkill
            }
            isError={
              touched?.jobDetails?.primarySkill &&
              Boolean(errors?.jobDetails?.primarySkill)
            }
            isRequired={true}
            placeholderText="Select Skill"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} style={{ marginTop: "-2px" }}>
          <BasicDatePicker
            label={"Job Start Date"}
            disableFuture={false}
            disablePast={true}
            value={values?.jobDetails?.jobStartDate}
            name="jobDetails.jobStartDate"
            minimum={moment().add(28+14, 'days').utc().toISOString()}
            onChange={(selectedDate: string) => {
              setFieldValue("jobDetails.jobStartDate", selectedDate);
            }}
            errorMessage={
              touched?.jobDetails?.jobStartDate &&
              errors?.jobDetails?.jobStartDate
            }
            defaultDateFormat={"DD-MM-YYYY"}
            views={["day"]}
            fullWidth={true}
            required={true}
          />
        </Grid>

        {/* row 3 */}
        <Grid item xs={6} sm={3} md={1.5} mt={1} style={{ marginTop: "21px" }}>
          <InputLabel
            id="shiftTimingFrom-label"
            shrink={true}
            style={{
              marginLeft: "0px",
              marginTop: "-35px",
              marginBottom: "0px",
            }}
          >
            Shift Timings <Asterisk />
          </InputLabel>
          <Autocomplete
            id="shiftTimingFrom-standard"
            className="shiftTimingInput"
            disableClearable={true}
            options={shiftTimes?.to?.map((options: any) => options)}
            getOptionLabel={(option) => option}
            value={values?.jobDetails?.shiftTimingFrom}
            onChange={(_, newValue: any) => {
              setFieldValue("jobDetails.shiftTimingFrom", newValue);
            }}
            onBlur={handleBlur}
            renderTags={(value, getTagProps) =>
              value.map((option: any, index: any) => (
                <Chip
                  color="primary"
                  size="small"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="From"
                name="jobDetails.shiftTimingFrom"
                data-testid="autocomplete-shiftTimingFrom"
                error={
                  touched?.jobDetails?.shiftTimingFrom &&
                  Boolean(errors?.jobDetails?.shiftTimingFrom)
                }
                helperText={
                  touched?.jobDetails?.shiftTimingFrom &&
                  errors?.jobDetails?.shiftTimingFrom
                }
                placeholder=""
              />
            )}
          />
        </Grid>
        <Grid item xs={6} sm={3} md={1.5}>
          <Autocomplete
            id="shiftTimingTo-standard"
            className="cust-field-style"
            disableClearable={true}
            options={shiftTimes?.from?.map((options: any) => options)}
            getOptionLabel={(option) => option}
            value={values?.jobDetails?.shiftTimingTo}
            onChange={(_, newValue: any) => {
              setFieldValue("jobDetails.shiftTimingTo", newValue);
            }}
            onBlur={handleBlur}
            renderTags={(value, getTagProps) =>
              value.map((option: any, index: any) => (
                <Chip
                  color="primary"
                  size="small"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="To"
                name="jobDetails.shiftTimingTo"
                data-testid="autocomplete-shiftTimingTo"
                error={
                  touched?.jobDetails?.shiftTimingTo &&
                  Boolean(errors?.jobDetails?.shiftTimingTo)
                }
                helperText={
                  touched?.jobDetails?.shiftTimingTo &&
                  errors?.jobDetails?.shiftTimingTo
                }
                placeholder=""
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            className="cust-field-style"
            required
            id="workingHours"
            name="jobDetails.workingHours"
            label="Hours of Working"
            placeholder="Enter Hours"
            variant="standard"
            type="number"
            InputLabelProps={
              (mode === "EDIT" || mode === "PENDING") ? { shrink: true } : {}
            }
            value={values?.jobDetails?.workingHours}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              touched?.jobDetails?.workingHours &&
              Boolean(errors?.jobDetails?.workingHours)
            }
            helperText={
              touched?.jobDetails?.workingHours &&
              errors?.jobDetails?.workingHours
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            className="cust-field-style"
            required
            id="numberOfOpenings"
            name="jobDetails.numberOfOpenings"
            label="Number of Openings"
            placeholder="Enter Openings"
            variant="standard"
            type="number"
            InputLabelProps={
              (mode === "EDIT" || mode === "PENDING") ? { shrink: true } : {}
            }
            value={values?.jobDetails?.numberOfOpenings}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              touched?.jobDetails?.numberOfOpenings &&
              Boolean(errors?.jobDetails?.numberOfOpenings)
            }
            helperText={
              touched?.jobDetails?.numberOfOpenings &&
              errors?.jobDetails?.numberOfOpenings
            }
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            className="cust-field-style"
            id="tags-standard"
            disableClearable={true}
            options={jobWorkExperiencesList.map((x:any)=>x.id)}
            getOptionLabel={(option: any) => getLabel(option)}
            value={values?.jobDetails?.experienceId}
            onChange={(_, newValue: any) => {
              if (newValue) {
                let value = jobWorkExperiencesList?.find((item: any) => item.id === newValue)
                setFieldValue("jobDetails.experience", value?.experienceValue);
                setFieldValue("jobDetails.experienceId", newValue);
                setFieldValue("jobDetails.experienceText", value?.experience);
                
              } else {
                setFieldValue("jobDetails.experience", '');

                setFieldValue("jobDetails.experienceId", "");
                setFieldValue("jobDetails.experienceText", "");
              }
            }}
            onBlur={handleBlur}
            renderTags={(value, getTagProps) =>
              value.map((option: any, index: any) => (
                <Chip
                  color="primary"
                  size="small"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                required
                {...params}
                variant="standard"
                label="Experience"
                data-testid="autocomplete-experience"
                error={
                  touched?.jobDetails?.experience &&
                  Boolean(errors?.jobDetails?.experience)
                }
                helperText={
                  touched?.jobDetails?.experience &&
                  errors?.jobDetails?.experience
                }
                placeholder="Select Experience"
              />
            )}
          />
        </Grid>

        {/* row 4 */}
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            className="cust-field-style"
            id="requiredDegree1-standard"
            disableClearable={true}
            options={jobRequireDegreesList?.map((option: any) => option)}
            getOptionLabel={(options) => options?.degreeName}
            value={
              values?.jobDetails?.requiredDegree?.degreeName !== ""
                ? values?.jobDetails?.requiredDegree
                : {}
            }
            onChange={(_, newValue: any) => {
              setFieldValue("jobDetails.requiredDegree", newValue);
            }}
            onBlur={handleBlur}
            renderTags={(value, getTagProps) =>
              value.map((option: any, index: any) => (
                <Chip
                  color="primary"
                  size="small"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                required
                {...params}
                variant="standard"
                label="Required Degree/Education"
                data-testid="autocomplete-education"
                error={
                  touched?.jobDetails?.requiredDegree &&
                  Boolean(errors?.jobDetails?.requiredDegree)
                }
                helperText={
                  touched?.jobDetails?.requiredDegree &&
                  errors?.jobDetails?.requiredDegree
                }
                placeholder="Select Education"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            className="cust-field-style"
            multiple
            disableClearable={true}
            id="tags-standard"
            options={[]}
            value={
              values?.jobDetails?.desiredCertifications?.length > 0
                ? values?.jobDetails?.desiredCertifications
                : []
            }
            onChange={(_, newValue) => {
              setFieldValue("jobDetails.desiredCertifications", newValue);
            }}
            freeSolo={true}
            renderTags={(value, getTagProps) =>
              value.map((option: any, index: any) => (
                <Chip
                  color="primary"
                  size="small"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Desired Certifications"
                data-testid="autocomplete-certification"
                placeholder={
                  values?.jobDetails?.desiredCertifications?.length > 0
                    ? ``
                    : `Please Enter Certifications`
                }
                error={
                  touched?.jobDetails?.desiredCertifications &&
                  Boolean(errors?.jobDetails?.desiredCertifications)
                }
                helperText={
                  touched?.jobDetails?.desiredCertifications &&
                  errors?.jobDetails?.desiredCertifications
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <PlacesAutocomplete
            searchOptions={searchOptions}
            value={values?.jobDetails?.city}
            onChange={(ev: any) => {
              setFieldValue("jobDetails.city", ev);
            }}
            onSelect={autoCompleteGetJobLocation}
          >
            {renderFuncCurrentJobLocation}
          </PlacesAutocomplete>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            className="cust-field-style"
            required
            id="state"
            name="jobDetails.state"
            label="State"
            variant="standard"
            placeholder="Enter State"
            value={values?.jobDetails?.state}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              touched?.jobDetails?.state && Boolean(errors?.jobDetails?.state)
            }
            helperText={touched?.jobDetails?.state && errors?.jobDetails?.state}
            fullWidth
          />
        </Grid>

        {/* row 5 */}
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            className="cust-field-style"
            required
            id="zipCode"
            name="jobDetails.zipCode"
            label="Zip Code"
            variant="standard"
            placeholder="Enter Zipcode"
            value={values?.jobDetails?.zipCode}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              touched?.jobDetails?.zipCode &&
              Boolean(errors?.jobDetails?.zipCode)
            }
            helperText={
              touched?.jobDetails?.zipCode && errors?.jobDetails?.zipCode
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <JobMutliSkillAutocomeplete
            className="cust-field-style"
            label="Required Skills"
            fieldName="jobDetails.requiredSkills"
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            selectedValue={values?.jobDetails?.requiredSkills}
            helperText={
              touched?.jobDetails?.requiredSkills &&
              errors?.jobDetails?.requiredSkills
            }
            isError={
              touched?.jobDetails?.requiredSkills &&
              Boolean(errors?.jobDetails?.requiredSkills)
            }
            isRequired={true}
            placeholderText={
              values?.jobDetails?.requiredSkills?.length > 0
                ? ``
                : `Select Skills`
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <InputLabel id="state-label" shrink={true}>
            Remote <Asterisk />
          </InputLabel>
          <FormControl
            className="cust-field-style-switch"
            size="medium"
            style={{ marginLeft: "12px !important" }}
          >
            <FormControlLabel
              control={
                <Switch
                  className={`switch-v2-small ${
                    values?.jobDetails?.isRemoteJob
                      ? "switch-v2-checked"
                      : "switch-v2"
                  }`}
                  size="medium"
                  name="jobDetails.isRemoteJob"
                  color="secondary"
                  checked={values?.jobDetails?.isRemoteJob}
                  onChange={handleChange}
                  style={{ marginLeft: "12px !important" }}
                />
              }
              label=""
            />
            <FormHelperText>
              {touched.isRemoteJob && errors.isRemoteJob
                ? errors.isRemoteJob.toString()
                : ""}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default JobDetailsFormSection;
