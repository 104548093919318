import { Alert, Collapse, IconButton } from "@mui/material";
import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";

export const CustomAlert = ({
  message,
  type,
  plainText,
  onClose = ()=>{},
  fontSize = "14px",
  backgroundColor = "",
}) => {
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    setOpen(true);
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setOpen(false);
      if (onClose) {
        onClose();
      }
    }, 4000);

    return () => {
      clearTimeout(timeId);
    };
  }, [message]);

  const renderAlert = () => {
    if (!open) {
      return null;
    }
    if (plainText === true) {
      return <p style={{ fontSize: "14px" }}>{message}</p>;
    }

    return (
      <Collapse in={open}>
        <Alert
          sx={{
            fontSize: fontSize ? fontSize : "14px",
            backgroundColor: backgroundColor ? backgroundColor : "",
          }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              data-testid={"btn-close"}
              onClick={() => {
                setOpen(false);
                if (onClose) {
                  onClose();
                }
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity={type}
        >
          {message}
        </Alert>
      </Collapse>
    );
  };
  return renderAlert();
};
CustomAlert.defaultProps = {
  type: "success",
  plainText: false,
};