import { FormControl, Input, InputLabel } from "@material-ui/core";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Button from "@mui/material/Button";
import { downloadFile, getFilePreviewUrl } from "../../utils/getFileFromUrl";
import { ErrorMessage } from "../ui/ErrorMessage";
import { valueOrDefault } from "../../utils/complexityUtils";
import Typography from "@mui/material/Typography";

type FileUploaderProps = {
  editMode: any;
  isUploading: boolean;
  fileName: string;
  resourceUrl: string;
  fileError: string;
  onFileChange: any;
  setFilePreview: any;
  token?: string;
};

export const FileUploader = (props: FileUploaderProps) => {

  function getButtonLabel(isUploading: boolean, resourceUrl: string) {
    if (isUploading) {
      return "Uploading";
    } else {
      if (resourceUrl) {
        return "Update Resume";
      } else {
        return "Attach Resume";
      }
    }
  }

  return (
    <>
      <FormControl variant="standard" error={props.fileError !== ""}>
        <InputLabel htmlFor="fileInput">Upload Resume</InputLabel>
        <Input
          readOnly={!props.editMode}
          value={valueOrDefault(props.fileName, "")}
          name="fileInput"
          type="text"
          className="UploadResume"
        />
      </FormControl>
      {props.resourceUrl !== undefined && props.resourceUrl !== "" && (
        <>
          <VisibilityOutlinedIcon
            data-testid="visibility-icon"
            onClick={() => {
              getFilePreviewUrl(props.resourceUrl, props?.token).then((filePreviewUrl) =>
                props.setFilePreview({
                  resourceUrl: filePreviewUrl,
                  fileName: props.fileName,
                })
              );
            }}
            color="primary"
            className="pointer"
            sx={{ pl: 1, verticalAlign: "bottom" }}
          />
          <SaveAltOutlinedIcon
            data-testid="save-icon"
            onClick={() => {
              downloadFile(props.resourceUrl, props.fileName, props?.token);
            }}
            color="primary"
            className="pointer"
            sx={{ pl: 1, verticalAlign: "bottom" }}
          />
        </>
      )}
      <Button
        variant="contained"
        component="label"
        className="upload-btn"
        disabled={!props.editMode || props.isUploading}
        sx={{ marginTop: "10px", marginLeft: "10px" }}
      >
        <FileUploadOutlinedIcon style={{ marginRight: "5px", width: "20px" }} />{" "}
        {getButtonLabel(props.isUploading, props.resourceUrl)}
        <input
          data-testid="file-input"
          readOnly={!props.editMode}
          type="file"
          accept="text/plain, application/pdf, .doc, .docx, .rtf"
          onChange={(e) => props.onFileChange(e.currentTarget.files || null)}
          hidden
        />
      </Button>
      {/* <Box
        className="form-three-child"
        sx={{ position: "relative", top: "-25px" }}
      > */}
        <Typography variant="subtitle1" component="div" className="fileUploadHelper-TextLabel">
          (Max file size: 2MB as
          <span>
            {" "}
            .txt, .rtf, .doc, .docx, .pdf
          </span>
          )
        </Typography>
      {/* </Box> */}
      <ErrorMessage errorText={props.fileError} />
    </>
  );
};
