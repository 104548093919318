import React from 'react';
import Grid from '@mui/material/Grid';
import ScoreChip from '../ScoreChip';

interface ScorecardCandidateInfoProps {
  scores: { label: string; percentage: number }[];
}

const ScoreSection: React.FC<ScorecardCandidateInfoProps> = ({ scores }) => {
  
  const scorePercentage = (score: any)=>{

    if(score?.percentage > 0 && score?.percentage < 100){
     return Math?.round(score?.percentage);
    }else{
      return score?.percentage;
    }
  }
  return (
    <Grid container spacing={2}>
      {scores.map((score) => (
        <Grid key={score?.label} item xs={12} sm={6} md={4} lg={3}>
          <ScoreChip 
            label={score.label}
            percentage={scorePercentage(score)}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ScoreSection;
