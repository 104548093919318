import React from "react";
import {
  Typography,
  InputLabel
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { Asterisk } from "../../../../ui/Asterisk";


interface CompanyInformationProps{
  clientLoginData: any;
  
}

const CompanyInformationFormSection: React.FC<CompanyInformationProps> = ({
  clientLoginData,
}) => {
  return (
    <>
      <Grid container spacing={2} mb={8}>
        {/* row 2 */}
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h5" className="postingJobFormHeading">Company Information</Typography>
        </Grid>
        {/* row 2 */}
        <Grid item xs={12} sm={6} md={3}>
        <InputLabel
            className="additionInfoFieldLabel"
            shrink={true}
          >
            Company Name <Asterisk />
          </InputLabel>
         <Typography className="form-field-font">
         {clientLoginData?.companyName}
         </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
        <InputLabel
            className="additionInfoFieldLabel"
            shrink={true}
          >
            Industry <Asterisk />
          </InputLabel>
         <Typography className="form-field-font">
         {clientLoginData?.industries?.[0]?.industry}
         </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default CompanyInformationFormSection;
