import { useEffect } from "react";
import {
  Routes,
  Route,
  useLocation,
  useSearchParams,
  matchPath,
  useNavigate,
} from "react-router-dom";
import AdminRoute from "../../Shared/routeConfig/AdminRoute";
import ProtectedRoute from "../../Shared/routeConfig/ProtectedRoute";
import PublicRoute from "../../Shared/routeConfig/PublicRoute";
import RecruiterRoute from "../../Shared/routeConfig/RecruiterRoute";
import checkUserId from "../../Shared/utils/checkUserId";
import Home from "../Home/Home";

import adminRoutes, { adminRoutePaths } from "./adminRoutes";
import protectedRoutes from "./protectedRoutes";
import recruiterRoutes, { recruiterRoutePaths } from "./recruiterRoute";
import ReactGA from 'react-ga4';
import getLoggedInUserRole from "../../Shared/utils/getLoggedInUserRole";
import { UserAuth } from "../../models/interfaces";
import superAdminRoutes, { superAdminRoutePaths } from "./superAdminRoutes";
import SuperAdminRoute from "../../Shared/routeConfig/SuperAdminRoute";
import { publicProtectedRoutes, publicRoutes } from "./publicRoutes";
import PublicProtectedRoute from "../../Shared/routeConfig/PublicProtectedRoute";
import clientRoutes from "./clientRoutes";
import ClientRoute from "../../Shared/routeConfig/ClientRoute";
import clientTeamMemberRoutes from "./clientTeamMemberRoutes";
import ClientTeamMemberRoute from "../../Shared/routeConfig/ClientTeamMemberRoute";
import { NotFound } from "../pages/NotFound";
import { getIsClientModuleEnabled } from "../../utils/AppConfig";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID ?? "");
function RouterProvider() {

  const isClientModuleEnabled = getIsClientModuleEnabled();
  let userRole = getLoggedInUserRole();

  const [idParams] = useSearchParams();
  const navigate = useNavigate();
  const routePath = useLocation();

  let allRoutes = adminRoutePaths.concat(
    superAdminRoutePaths,
    recruiterRoutePaths
  );

  useEffect(() => {
    //check if currently logged in user id matches with userId from params received from email
    checkUserId(idParams.get("userId") == null ? null : idParams.get("userId"));
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });

    if (userRole === UserAuth.Admin) {
      if (!window.location.pathname.startsWith("/admin")) {
        navigate("/admin" + window.location.pathname+ window.location.search, { replace:true});
      }
    }

    if (
      userRole === UserAuth.NotLoggedIn &&
      allRoutes.some((n) =>
        matchPath( { path: n.toLowerCase(), exact: true },routePath.pathname)
      )
    ) {
      navigate(
        "/login",
        {
          state: {
            redirectUrl: routePath.pathname.replace("/admin/", "/") + routePath.search,
          },
          replace: true,
        }
      );
    }
    
  }, [routePath.pathname]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <PublicRoute>
            <Home />
          </PublicRoute>
        }
      />

      {userRole === UserAuth.Recruiter
        ? recruiterRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={<RecruiterRoute>{route.element}</RecruiterRoute>}
            />
          ))
        : ""}
      {userRole === UserAuth.Admin
        ? adminRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={<AdminRoute>{route.element}</AdminRoute>}
            />
          ))
        : ""}

      {publicRoutes.map((route) => (
        <Route key={route.path} path={route.path} element={route.element} />
        ))}
         {publicProtectedRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={<PublicProtectedRoute>{route.element}</PublicProtectedRoute>}
        />
      ))}
      {protectedRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={<ProtectedRoute>{route.element}</ProtectedRoute>}
        />
      ))} 
      {userRole === UserAuth.SuperAdmin
        ? superAdminRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={<SuperAdminRoute>{route.element}</SuperAdminRoute>}
            />
          ))
        : ""}
        { isClientModuleEnabled && userRole === UserAuth.Client  
          ? clientRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={<ClientRoute>{route.element}</ClientRoute>}
            />
          )) : ""
        }
         { isClientModuleEnabled && userRole === UserAuth.ClientTeamMember
         ? clientTeamMemberRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={<ClientTeamMemberRoute>{route.element}</ClientTeamMemberRoute>}
            />
          ))
       :'' }
    <Route
          key={'notfound'}
          path={"*"}
          element={<NotFound/>}
        />
    </Routes>
  );
}

export default RouterProvider;
