import { FormControl } from '@material-ui/core';
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { FormControlLabel, Grid, Switch, Tooltip } from '@mui/material';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { FC, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { setActive } from '../../../Services/adminService';
import { PermissionsEnums } from '../../../data/permission';
import { SortType } from '../../../models/app/AppModels';
import convertToLocalizedDateTime from '../../../utils/AppDateUtils';
import constructName from '../../../utils/constructName';
import { getNevLink } from '../../../utils/getFileFromUrl';
import truncateString from '../../../utils/truncateString';
import AppSort from '../../Common/AppSort';
import StatusChip from '../../Common/Chip/StatusChip';
import { Fullname } from '../../Common/Fullname';
import { ModuleComponent } from '../../Common/Permission/ModuleComponent';
import { PhoneNumber } from '../../ui/PhoneNumber';
import { UpdateConfirmationDialog } from '../../ui/UpdateConfirmationDialog';
import GenericPagination from '../../Common/Table/GenericPagination';
import { AdminFilter } from '../../../models/admin/AdminFilterModel';

interface AdminListingTableProps {
  data: Array<{}>;
  paging:any
  setPaging:any;
  pageStatus:any;
  setPageStatus:any
  GetAdminUserList:()=> void;
  totalRecordCount:number;
  handlePaginationIndex:(index:number) => void;
}

const AdminListingTable: FC<AdminListingTableProps> = ({
    data,
    paging,
    setPaging,
    pageStatus,
    setPageStatus,
    GetAdminUserList,
    totalRecordCount,
    handlePaginationIndex,
    }) => {

    const navigate = useNavigate();
    const [confimationData, setConfimationData] = React.useState({
        open: false,
        title: "",
        description: "",
        id: "",
        userId: "",
        status: false,
      });

        // Pagination state
  const [rowsPerPage] = useState(paging?.pageSize);

  const handleChangePage = (event: unknown, newPage: number) => {
    handlePaginationIndex(newPage);
  };    

    const handleActiveInactive = (
        id: string,
        userId: string,
        status: boolean
      ) => {
        setConfimationData({
          open: true,
          title: `Are you sure you want to mark Admin as ${
            status ? "Inactive" : "Active"
          }?`,
          description: "",
          id: id,
          userId: userId,
          status: status,
        });
      };
      const handleConfirmationClose = () => {
        setConfimationData({
          open: false,
          title: ``,
          description: "",
          id: "",
          userId: "",
          status: false,
        });
      };
      const handleConfirmation = async() => {
        let reqData = {
          id: confimationData.id,
          userId: confimationData.userId,
          isActive: !confimationData.status,
        };
        setActive(reqData)
          .then(async(response) => {
            if (response.status === 200) {             
            
              GetAdminUserList();
    
              setPageStatus({
                ...pageStatus,
                isSuccess: true,
                error: "",
                key: Date.now(),
                loading: false,
                data: `Admin marked ${
                  !confimationData.status ? "Active" : "Inactive"
                } successfully`,
              });
            }
          })
          .catch((error) => {
            console.error(error);
            setPageStatus({
                ...pageStatus,
              isSuccess: false,
              error: "Error",
              key: Date.now(),
              loading: false,
              data: `Something went wrong`,
            });
          });
        setConfimationData({
          open: false,
          title: ``,
          description: "",
          id: "",
          userId: "",
          status: false,
        });
      };

    return (
    <>
        <GenericPagination
            count={totalRecordCount}
            rowsPerPage={rowsPerPage}
            page={paging?.pageIndex}
            onPageChange={handleChangePage}
        /> 
        <Table className="AppTable recruiterListTable">
            <TableHead className="custom-Client-fontsizes">
            <TableRow>
                <TableCell
                align="left"
                sx={{ px: 1 }}
                data-testid="sortColumn"
                onClick={() =>
                    setPaging({
                    ...paging,
                    sortColumn: "name",
                    sortOrder: paging.sortOrder == 0 ? 1 : 0,
                    pageIndex: 1,
                    })
                }
                className="custom-Client-fontsizes pointer custom-name-minwidth"
                >
                <Grid container>
                    <Grid className="custom-Client-fontsizes pointer custom-adminlist-padding">
                    Admin Name
                    </Grid>
                    <Grid
                    style={{
                        display: "inline-block",
                        verticalAlign: "top",
                    }}
                    className="custom-adminlist-padding"
                    >
                    <AppSort
                        sortType={
                        paging.sortOrder === 0
                            ? SortType.Ascending
                            : SortType.Descending
                        }
                        disable={paging.sortColumn !== "name"}
                    ></AppSort>
                    </Grid>
                </Grid>
                </TableCell>
                <TableCell sx={{ px: 1 }}>Email Address</TableCell>
                <TableCell sx={{ px: 1 }}>Phone</TableCell>

                <TableCell sx={{ px: 1 }}>
                <span
                    data-testid="Clear"
                    onClick={() =>
                    setPaging({
                        ...paging,
                        sortColumn: "createdDate",
                        sortOrder: paging.sortOrder == 0 ? 1 : 0,
                        pageIndex: 1,
                    })
                    }
                    className="custom-arrw-btn"
                >
                    Date{" "}
                    
                    <AppSort
                    sortType={
                        paging.sortOrder == 0
                        ? SortType.Ascending
                        : SortType.Descending
                    }
                    disable={paging.sortColumn !== "createdDate"}
                    ></AppSort>
                </span>
                </TableCell>

                <TableCell sx={{ px: 1 }} className="custom-status-width">
                Status
                </TableCell>
                <TableCell sx={{ px: 1 }}>Actions</TableCell>
            </TableRow>
            </TableHead>

            <TableBody>
            <React.Fragment>
                {data && data?.length > 0 && data.map((row: any, index) => {
                return (
                    <TableRow
                    key={row?.id}
                    className="hover-row v2-table-row"
                    >
                    <TableCell
                        sx={{
                        whiteSpace: "nowrap",
                        px: 1,
                        width: 200,
                        fontSize: "12px !important",
                        }}
                    >
                        <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "nowrap",
                        }}
                        >
                        <Fullname
                            fullName={constructName(
                            row?.firstName,
                            row?.middleName,
                            row?.lastName
                            )}
                        />
                        </div>
                    </TableCell>
                    <Tooltip title={row.email}>
                        <TableCell
                        sx={{
                            whiteSpace: "nowrap",
                            px: 1,
                            width: 200,
                            fontSize: "12px !important",
                        }}
                        align="left"
                        width={"9%"}
                        >
                        {
                            <Link
                            className="email-link"
                            color="primary"
                            to={"#"}
                            >
                            {truncateString(row.email, 28)}
                            <CheckCircleRoundedIcon
                                className="verifi-success-icon"
                                sx={{
                                marginLeft: "2px",
                                fontSize: "14px",
                                }}
                            />
                            </Link>
                        }
                        </TableCell>
                    </Tooltip>
                    <TableCell
                        align="left"
                        width={150}
                        sx={{
                        whiteSpace: "nowrap",
                        px: 1,
                        width: 200,
                        fontSize: "12px !important",
                        }}
                    >
                        {row.phoneNumber ? (
                        <>
                            <PhoneNumber number={row.phoneNumber} />
                            <CheckCircleRoundedIcon
                            className="verifi-success-icon"
                            sx={{
                                marginLeft: "2px",
                                fontSize: "14px",
                            }}
                            />
                        </>
                        ) : (
                        "N/A"
                        )}
                    </TableCell>

                    <TableCell
                        sx={{
                        whiteSpace: "nowrap",
                        px: 1,
                        width: 200,
                        fontSize: "12px !important",
                        }}
                        align="left"
                    >
                        {convertToLocalizedDateTime(
                        row?.createdDate,
                        "MMMM DD, YYYY,  h:mm A"
                        )}
                    </TableCell>

                    <TableCell>
                        <StatusChip
                        status={row.isActive ? "Active" : "Inactive"}
                        />
                    </TableCell>

                    <TableCell
                        className="edit-verify-clr"
                        sx={{
                        whiteSpace: "nowrap",
                        px: 1,
                        width: 100,
                        fontSize: "12px !important",
                        border: 0,
                        }}
                    >
                        <ModuleComponent
                        moduleId={PermissionsEnums.Admins_Verify}
                        >
                        <FormControl
                            className="cust-field-style-switch"
                            size="medium"
                            style={{ marginLeft: "12px !important" }}
                        >
                            <FormControlLabel
                            control={
                                <Switch
                                inputProps={{ 'data-testid': `switchid${index}` } as React.InputHTMLAttributes<HTMLInputElement>}
                                className={`switch-v2-small ${
                                    row?.isActive
                                    ? "switch-v2-checked"
                                    : "switch-v2"
                                }`}
                                size="medium"
                                name="jobDetails.isRemoteJob"
                                color="secondary"
                                style={{
                                    marginLeft: "12px !important",
                                }}
                                checked={row?.isActive}
                                onChange={() =>
                                    handleActiveInactive(
                                    row.id,
                                    row.userId,
                                    row.isActive
                                    )
                                }
                                />
                            }
                            label=""
                            />
                        </FormControl>
                        </ModuleComponent>
                        <ModuleComponent
                        moduleId={PermissionsEnums.Admins_Edit}
                        >
                        <Tooltip title={"Edit"} placement="top">
                            <EditOutlinedIcon
                            style={{
                                cursor: "pointer",
                                paddingRight: 10,
                            }}
                            onClick={() =>
                                navigate(
                                getNevLink(`/admin/user/edit/${row.id}`),
                                {
                                    state: { row },
                                }
                                )
                            }
                            />
                        </Tooltip>
                        </ModuleComponent>
                    </TableCell>
                    </TableRow>
                );
                })}
            </React.Fragment>
            </TableBody>
        </Table>

        <UpdateConfirmationDialog
            message={confimationData.title}
            aggreeText={"Yes"}
            disagreeText={"No"}
            onClose={() => handleConfirmationClose()}
            open={confimationData.open}
            onYes={handleConfirmation}
            onNo={handleConfirmationClose}
        />
    </>
  );
};

export default AdminListingTable;
