import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CheckCircle from "./../../../assets/images/check-circle-green.svg";
import { Button } from "@mui/material";


export const PasswordResetSuccess = (props) => {
  return (
    <> 
      <Typography
        variant="h6"
        color="inherit"
        component="span"
        className="abel"
        sx={{ textAlign: "center", align: "center", marginBottom: "20px"}}
      >
        <div className="passwordresetsuccess-heading">Password Reset Successfully</div>
      </Typography>
      <Box sx={{ padding: "20px 0", textAlign: "center" }}>
        <img src={CheckCircle} alt="" />
      </Box>
      <Typography component="span" className="passwordresetsuccess-subhead">
        Your password has been reset successfully. Go to the Sign In page
        to access your account now.
      </Typography>

      <div className="passwordresetsuccess-btn-div">
        <Button
          variant="contained"
          className="abel passwordresetsuccess-btn-text"
          data-testid="signinBtn"
          sx={{ marginTop: "40px", marginBottom: "40px" }}
          onClick={(e) => 
          {
              props.onClick()
          }}
        >
          Sign In here
        </Button>
      </div>
    </>
  )
}
