
function getJobType (jobType:string,duration:string): string  {
    if (jobType == "Full Time") {
        return jobType
      }
      if (duration == "") {
        return jobType
      }
  
      return jobType + (` (${duration?? ""} Months)`)
    }
export default getJobType;