import * as React from "react";

import Grid from "@mui/material/Grid";

import { makeStyles } from "@material-ui/core";

import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import Collapse from "@mui/material/Collapse";
import { Link, useSearchParams } from "react-router-dom";

import {
  Box,
  Button,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import formatPhone from "../../Shared/utils/formatPhone";
import { downloadFile, getNevLink } from "../../utils/getFileFromUrl";
import truncateString from "../../utils/truncateString";
import { CandidateFlowTest } from "../Candidate/CandidateFlowTest";
import { JobStatus } from "../ui/JobStatus";
import { Payrate } from "../ui/Payrate";

const useStyles = makeStyles((theme) => ({
    initComment: {
      color: "blue",
    },
    deleteIcon1: {
      "& svg": {
        fontSize: 25,
      },
    },
  }));

  const getScoreCardUrl = (otherDoc) => {
    
    if (otherDoc) {
      const ResumeScoreCard = otherDoc.find(
        (n) => n.documentType === "ResumeScoreCard"
      );
      if (ResumeScoreCard) return ResumeScoreCard.resourceUrl;
    }
  };
export default function Row({row,fromPage,jobDetails}) {
  const [searchParams] = useSearchParams();

   let recruiterGuid = searchParams.get("recruiterGuid") === null ? null : searchParams.get("recruiterGuid");
   let recruterName = searchParams.get("recruiterName") === null ? null : searchParams.get("recruiterName");

    const classes = useStyles();
    const [open] = React.useState(true);
    return (
      <React.Fragment>
        <TableRow style={{ borderBottom: 0 }}>
          <TableCell
            className={classes.tableCell1}
            scope="row"
            style={{
              borderBottom: "0px",
              color:'blue',
              verticalAlign:"top",
              paddingTop:"20px"
            }}
            sx={{whiteSpace:"nowrap",px:0}}
          >
            {row?.firstName + " " + row?.lastName}
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            style={{
              borderBottom: "0px",
            }}
            sx={{whiteSpace:"nowrap",px:0}}
          >
         <Tooltip style={{ marginTop: "0px;" }} title={row?.email}>
              <span style={{textDecoration:"underline"}}>{truncateString(row?.email, 20)}</span>
          </Tooltip>

          </TableCell>
  
          <TableCell
            className={classes.tableCell2}
            style={{
              borderBottom: "0px",
              display: "flex",
              flexDirection: "column",
              width: "88px",
              left: "-11px!important", 
              position: "relative"
              

            }}
            sx={{px:0}}
          >
            {row?.currentWorkLocation?.city +" , "+row?.currentWorkLocation?.state}
          </TableCell>
          <TableCell
            className={classes.tableCell2}
            style={{
              borderBottom: "0px",
            }}
            sx={{whiteSpace:"nowrap",px:0}}
          >
            {row?.experience + " years"}
          </TableCell>
          <TableCell
            className={classes.tableCell2}
            style={{
              borderBottom: "0px",
            }}
            sx={{whiteSpace:"nowrap",px:0}}
          >
            {formatPhone(row?.phone)}
          </TableCell>
  
          <TableCell
            className={classes.tableCell2}
            style={{
              borderBottom: "0px",
            }}
            sx={{whiteSpace:"nowrap",px:0}}
          >
          <Payrate packageType={row?.payRateType} packageValue={row?.payRate} currency={row?.packageCurrency}/>
          
          </TableCell>
          <TableCell
            style={{
              borderBottom: "0px",
              fontSize: "0.9rem",
            }}
            sx={{whiteSpace:"nowrap",px:0}}
          >
            {row?.workAuthorizationStatus}
            <br/>
            {
              row.isRemoteUSA ? "(Remote USA)" : ""
            }
          </TableCell>
          <TableCell
            className={classes.tableCell2}
            style={{
              borderBottom: "0px",
            }}
            sx={{whiteSpace:"nowrap",px:0}}
          >
            <Grid container>
              <Grid item md={12}>
              <Tooltip
                title={row?.resumeInfo?.fileName ?? ""}
              >
              <div style={{ display: "flex", justifyContent:"flexStart",  }}>
                  <SaveAltOutlinedIcon
                    data-testid = "submission-submit1"
                    onClick={() =>
                      downloadFile(row?.resumeInfo?.resourceUrl,row?.resumeInfo?.fileName)
                    }
                    color="primary"
                    className="pointer"
                  />                  
                </div>
                </Tooltip>
              </Grid>
            </Grid>
          </TableCell>
          <TableCell
            className={classes.tableCell2}
            style={{
              borderBottom: "0px",
            }}
            sx={{whiteSpace:"nowrap",px:0}}
          >
            <Grid container>
              <Grid item md={12}>
                {row?.IsScoreCardPending?"N/A":
              <Tooltip
                title={"Score Card"}
              >
              <div style={{ display: "flex", justifyContent:"flexStart" }}>
                  <SaveAltOutlinedIcon
                  data-testid = "submission-submit2"
                    onClick={() =>
                      downloadFile(getScoreCardUrl(row?.otherDocInfo),"Score-Card.pdf")
                    }
                    color="primary"
                    className="pointer"
                  />
                  
                </div>
                </Tooltip>
                }
              
              </Grid>
            </Grid>
          </TableCell>
          <TableCell
            style={{borderBottom: "0px",
            }}
            sx={{whiteSpace:"nowrap",px:0}}
          >
            <Button
              component={Link}
              to={ recruiterGuid!==null ? getNevLink(`/candidates/submissiondetails/${row.id}/${fromPage}?recruiterGuid=${recruiterGuid}&recruiterName=${recruterName}`) : getNevLink(`/candidates/submissiondetails/${row.id}/${fromPage}`)}
              variant="outlined"
              sx={{ borderRadius: "12px" }}
            >
              View Details
            </Button>
          </TableCell>
              
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ marginBottom: "1.125rem" }}>
               <Grid container>
               <Grid item xs={10} md={10}>
               <CandidateFlowTest data={row?.candidateFinalStatuses}/>
               </Grid>
               <Grid item xs={2} md={2} >
                <Typography mt={2}>
                  <JobStatus hiredCount={jobDetails?.totalStarts} jobStatus={jobDetails?.jobStatus} />
               </Typography>
               </Grid>
               </Grid>
                            </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }