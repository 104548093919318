import React from 'react';
import { Typography } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import { Document, getDocumentsByType } from '../../../../utils/documentObjectUtils';
import { DocumentTypeEnum } from '../../../../models/interfaces';
import { downloadFile, getFilePreviewUrl } from '../../../../utils/getFileFromUrl';


interface Props {
  documentInfo: Document[];
  setFilePreview:any;
}

const ResumeViewInfo: React.FC<Props> = ({ 
    documentInfo,
    setFilePreview
}) => {
  
  const resumeDocuments = getDocumentsByType(documentInfo, DocumentTypeEnum.Resume);

  return (
    <>
      <Typography variant="subtitle1" component="div" className="verify-rec-form-heading">
        Resume
      </Typography>
      {resumeDocuments?.length > 0 ? (
        resumeDocuments?.map((resumeDocument) => (
          <Typography key={resumeDocument?.fileName} variant="subtitle1" component="div" className="verify-rec-form-value">
            {resumeDocument.fileName}
            <VisibilityOutlinedIcon
              data-testid="primary"
              onClick={() => {
                getFilePreviewUrl(resumeDocument.resourceUrl).then(filePreviewUrl => {
                  setFilePreview({
                    resourceUrl: filePreviewUrl,
                    fileName: resumeDocument.fileName
                  });
                });
              }}
              color="primary"
              className="pointer"
              sx={{ pl: 1, verticalAlign: "bottom" }}
            />
            <SaveAltOutlinedIcon
              data-testid="pointer"
              onClick={() => {
                downloadFile(resumeDocument.resourceUrl, resumeDocument.fileName);
              }}
              color="primary"
              className="pointer"
              sx={{ pl: 1, verticalAlign: "bottom" }}
            />
          </Typography>
        ))
      ) : (
        <Typography variant="subtitle1" component="div" className="verify-rec-form-value">
          N/A
        </Typography>
      )}
    </>
  );
};

export default ResumeViewInfo;
