import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Card, CardContent } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  padding: theme.spacing(0),
  textAlign: "left",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));
export default function Highlights({data}) {
  return (
    <Card sx={{ height: "100%" }} className="card-custom-shadow highlight-card">
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
          <Grid
            item
            sx={{
              padding: 0,
              width: "100%",
              height: "137px",
            }}
          >
            <Box>
              <Typography
                variant="caption2"
                sx={{ fontSize: "14px", fontWeight: "600" }}
              >
                Highlights
              </Typography>
            </Box>
            <Box
              sx={{
                paddingTop: "15px",
              }}
            >
              <Typography color="textPrimary" variant="caption2">
                Ratings
              </Typography>
              <Divider
                sx={{
                  marginTop: "5px",
                }}
              />
              <Grid
                container
                spacing={0}
                sx={{
                  marginTop: "10px",
                }}
              >
                <Grid item xs={8}>
                  <Item elevation={0} sx={{ paddingLeft: 0 }}>
                    <Typography
                      color="textPrimary"
                      variant="caption1"
                      component="span"
                      sx={{ fontWeight: "600" }}
                    >
                      Strongest Tags
                      <ArrowRightIcon
                        sx={{
                          color: "#58595A",
                          position: "relative",
                          top: "8px",
                        }}
                        fontSize="small"
                      />
                    </Typography>
                    <br/>
                    <Typography variant="caption2">
                      Recent & Relevant Submissions
                    </Typography>
                  </Item>
                </Grid>
                <Grid item xs={4} sx={{ alignItem: "bottom" }}>
                  <Item className="percent">{data?.strongestTags && data?.strongestTags?.toFixed(2)+"%"}</Item>
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={8}>
                  <Item sx={{ paddingLeft: 0 }}>
                    {" "}
                    <Typography
                      color="textPrimary"
                      variant="caption1"
                      component="span"
                      sx={{ fontWeight: "600" }}
                    >
                      Weakest Tags{" "}
                      <ArrowRightIcon
                        sx={{
                          color: "#58595A",
                          position: "relative",
                          top: "8px",
                        }}
                        fontSize="small"
                      />
                    </Typography>
                    <br/>
                    <Typography variant="caption2">
                      Rejection & No Match
                    </Typography>
                  </Item>
                </Grid>
                <Grid item xs={4} sx={{ alignItem: "bottom" }}>
                  <Item className="percent light">{data?.weakestTags && data?.weakestTags?.toFixed(2) + "%"}</Item>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
