import NearMeOutlinedIcon from "@mui/icons-material/NearMeOutlined";
import { FormControl, InputBase, Paper } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SubscribeToEmail } from "../../Services/idProviderService";
import useApi from "../../hooks/useApi";
import { CustomAlert } from "../ui/CustomAlert";
import { ErrorMessage } from "../ui/ErrorMessage";
import SnackbarPopup from "../Common/Popup/snackbar/SnackbarPopup";
import { useState } from "react";

export const SubscribeForm = () => {
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Entered email address is invalid")
      .required("Please enter a valid email address"),
  });
  const subscribeState = {
    email: "",
  };

  const [pageStatus, setPageStatus] = useState({
    isSuccess: false,
    isError: false,
    error: "",
    loading: false,
    successMessage: "",
  });

  const formik = useFormik({
    initialValues: subscribeState,
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true);
      try{
        subscribeAPI.clear();
        await subscribeAPI.request();
        setPageStatus({ ...pageStatus, isSuccess: true });
      }
      catch (error){
        setPageStatus({ ...pageStatus, isError: true, error: error.message });
      }
      actions.setSubmitting(false);
    },
  });
  let subData = {
    email: formik.values.email,
  };
  const subscribeAPI = useApi(() => SubscribeToEmail(subData));

  return (
    <>
      {" "}
      <Paper
        component="form"
        className="subscribe-form"
        sx={{
          p: "4px",
          display: "flex",
          alignItems: "center",
          width: { xs: "100%", sm: "100%", md: "100%" },
        }}
      >
        <FormControl required fullWidth>
          <InputBase
            error={true}
            sx={{ ml: 1, flex: 1 }}
            onChange={(e) => {
              formik.handleChange(e);
              subscribeAPI.clear();
            }}
            onBlur={formik.handleBlur}
            placeholder="Enter your email..."
            name="email"
            inputProps={{ "aria-label": "Enter your email..." }}
          />
        </FormControl>

        <NearMeOutlinedIcon
          color="primary"
          style={{ cursor: "pointer" }}
          data-testid="subscribe-button"
          onClick={(e) => {
            e.preventDefault();
            console.log(formik.errors);
            console.log(formik.values);
            formik.handleSubmit();
          }}
        />
      </Paper>
      {subscribeAPI.error && (
        <CustomAlert key={2} message={subscribeAPI.error} type={"error"} />
      )}
      {pageStatus.isSuccess && (
        <SnackbarPopup
          open={subscribeAPI.data}
          message={subscribeAPI.data?.message?.appStatusDescription}
          onClose={() => {setPageStatus({ ...pageStatus, isSuccess: false });}}
        />
      )}
      <ErrorMessage errorText={formik.touched.email && formik.errors.email} />
    </>
  );
};
