import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { PermissionsEnums } from "../../../../data/permission";
import { ModuleComponent } from "../../../Common/Permission/ModuleComponent";
import "../../NewViewSubmission/styles.scss";

interface ConversationsButtonProps {
  row: any;
  setSubmissionForPopup: any;
  setPopup: any;
}

export default function ConversationsButton({
  row,
  setSubmissionForPopup,
  setPopup
} : ConversationsButtonProps) {
  const handleConversationsButtonClick = () => {
    setSubmissionForPopup(row);
    setPopup((popup : any) => ({
      ...popup,
      conversation: true,
      comments: row.comments,
    }));
  };

  return (
    <ModuleComponent moduleId={PermissionsEnums.Submissions_Conversations}>
      <Grid item>
        <Button
          className="submission-action-btn"
          variant="contained"
          color="primary"
          onClick={handleConversationsButtonClick}
        >
          Conversations
        </Button>
      </Grid>
    </ModuleComponent>
  );
};