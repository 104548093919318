import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Calender from "../Common/Icons/CandidateFlow/Calender";
import Process from "../Common/Icons/CandidateFlow/Process";
import Stroked from "../Common/Icons/CandidateFlow/Stroked";
import Success from "../Common/Icons/CandidateFlow/Success";
import TouchPoint from "../Common/Icons/CandidateFlow/Touchpoint";
import Vector from "../Common/Icons/CandidateFlow/Vector";
import { getRandomInt } from "../../utils/randomUtils";

export const CandidateFlowTest = (props) => {
  const renderIconBasedOnStatus = (index, color) => {
    switch (index) {
      case 0:
        return <TouchPoint className="largesvg" style={{ color: color}} />;
      case 1:
        return <Vector className="largesvg" style={{ color: color, paddingRight: "6px", paddingTop: "4px" }} />;
      case 2:
        return <Process className="largesvg" style={{ color: color, paddingRight: "12px"}} />;
      case 3:
        return <Calender className="largesvg" style={{ color: color,paddingRight:"12px" }} />;

      case 4:
        return <Stroked className="largesvg" style={{ color: color,paddingRight:"12px" }} />;

      case 5:
        return <Success className="largesvg" style={{ color: color,paddingRight:"14px" }} />;

      default:
        return <TouchPoint className="largesvg" style={{ color: color }} />;
    }
  };
  return (
    <Grid key={getRandomInt()} container sx={{ flexWrap: "nowrap !important",overflowX: "auto" }}>
      {props.data?.sort((a, b) => (a.step - b.step)).map((x, i) => {
        return (
          <>
         <Grid item key={x?.statusColor ?? i} sx={{ marginLeft: "1rem",marginRight:"-10px"}}>
              <Grid style={{ paddingLeft: "30%" }} mt={4}>
                {renderIconBasedOnStatus(i, x.statusColor)}
              </Grid>
              <Typography
                color="black"
                sx={{
                 
                  fontSize: "0.75rem",
                  textAlign: "center",
                  width: x?.status?.length>15? "3.3rem" : "2.1rem",
                                    marginLeft:"-0.6rem",
                }}
              >
                {x.status}
              </Typography>
            </Grid>
            {i !== props?.data?.length - 1 && (
              <>
              <Divider
                style={{
                  borderColor:  props.data[i + 1].statusColor,
                  borderBottomWidth: 4,
                  width: "5rem",
                }}
                sx={{
                  borderBottomWidth: "unset",
                
                  marginLeft: "0.5rem",
                  marginBottom: "4.625rem",
                 
                  marginTop:"2rem",
                  paddingRight:"-10.0px"
                }}
              />
            
              </>
            )}
          </>
        );
      })}
    </Grid>
  );
};
