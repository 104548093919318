import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined';
import { Card, CardContent } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import ReactSpeedometer from "react-d3-speedometer";
import { Thumbsup as ThumbsupIcon } from "../../Common/Icons/Thumbsup";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

export default function ActivityScore({data}) {
  return (
    <Card sx={{ height: "100%" }} className="card-custom-shadow">
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
          <Grid
            item
            sx={{
              padding: 0,
              width: "100%",
              height: "100%",
            }}
          >
            <Box sx={{ width: "100%", height: "100%" }}>
              <Typography
                variant="caption2"
                component="div"
                sx={{ fontSize: "14px", fontWeight: "600" }}
              >
                Activity Score
              </Typography>
              <Typography
                variant="caption2"
                component="div"
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  textAlign: "center",
                  paddingTop:'15px'
                }}
              >
                Activity Score is +{data?data?.score:0}
              </Typography>
              <Box
                sx={{
                  marginTop: "0px",
                  width: "100%",
                  height: "180px",
                  textAlign: "center",
                }}
              >
                <ReactSpeedometer

                  segments={1}
                  minValue={-100}
                 
                  maxValue={100}
                  value={data?.score}
                  valueFormat={"d"}
                  ringWidth={10}
                 
                  segmentColors={['#4540DB']}
                  needleColor="black"
                  
                  needleTransitionDuration={4000}
                  needleTransition="easeElastic"
                  needleHeightRatio={0.7}
                  textColor={"#fff"}
                 
                  forceRender={true}
                />
                
              </Box>
              <Box>
                <Grid
                  container
                  spacing={1}
                  sx={{
                    paddingTop: "5px",
                  }}
                >
                  <Grid item xs={3} md={3} className="activity-score-lagends">
                    <Item
                      elevation={0}
                      sx={{
                        textAlign: "left",
                        padding: 0,
                      }}
                    >
                      <Typography variant="subtitle2" component="div">
                        <DiamondOutlinedIcon className="icon-color-default" />
                        {data?data?.onBoard?.toFixed(2):"0.00"}%
                      </Typography>
                      <Typography variant="subtitle1" component="div">
                        Onboarded
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={3} md={3} className="activity-score-lagends">
                    <Item
                      elevation={0}
                      sx={{
                        textAlign: "left",
                        padding: 0,
                      }}
                    >
                      <Typography variant="subtitle2" component="div">
                      <ThumbsupIcon className="icon-color-default" />
                        {data?data?.offered?.toFixed(2):"0.00"}%
                      </Typography>
                      <Typography variant="subtitle1" component="div">
                        Offered
                      </Typography>
                    </Item>
                  </Grid>

                  <Grid item xs={3} md={3} className="activity-score-lagends">
                    <Item
                      elevation={0}
                      sx={{
                        textAlign: "left",
                        padding: 0,
                      }}
                    >
                      <Typography variant="subtitle2" component="div">
                      <ThumbsupIcon className="icon-color-default light" />
                        {data?data?.accepted?.toFixed(2):"0.00"}%
                      </Typography>
                      <Typography variant="subtitle1" component="div">
                        Accepted
                      </Typography>
                    </Item>
                  </Grid>

                  <Grid item xs={3} md={3} className="activity-score-lagends">
                    <Item
                      elevation={0}
                      sx={{
                        textAlign: "left",
                        padding: 0,
                      }}
                    >
                      <Typography variant="subtitle2" component="div">
                        <ThumbsupIcon className="icon-color-default light2" />

                        {data?data?.rejected?.toFixed(2):"0.00"}%
                      </Typography>
                      <Typography variant="subtitle1" component="div">
                        Rejected
                      </Typography>
                    </Item>
                  </Grid>

                  
                 
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
