import { createSlice } from "@reduxjs/toolkit";
import { ManageClientFormModel } from "../../../models/client/ManageClient/ManageClientFormModel";

const initialState: ManageClientFormModel = {
    clientId: "",
    selectedClientDetails:{},
    accountManagerDetails: {
      id: "",
      fullName: "",
      email: "",
      primaryPhone: ""
    },
    otherDetails: {
      jobType: "",
      jobTypeId: "",
      jobTaxTerm: "",
      jobTaxTermId: "",
      payrollOverhead: undefined,
      paymentTerms: 30,
      paymentMode: "ACH",
      modeOfDelivery: "",
      clientFee: undefined,
      clientFeeType: "USD",
      jobRialtoCommission: undefined,
      jobRialtoCommissionType: "Percentage",
      recruiterCommission: undefined,
      recruiterCommissionType: "USD",
      oneTimeCommission:undefined
    }
  }
  
const ManageClientStateSlice = createSlice({
  name: "ManageClientStateSlice",
  initialState,
  reducers: {
    setManageClientFormState: (action: any) => {
      return action.payload;
    },
    resetManageClientFormState: (action: any) => {
      return initialState;
    },
  },
});

export const { setManageClientFormState, resetManageClientFormState } = ManageClientStateSlice.actions;

export default ManageClientStateSlice.reducer;
