import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Asterisk } from "./Asterisk";

export const SignUpFooter = (props: any) => {
  const { formik } = props;
  const navigate = useNavigate();
  return (
    <FormControlLabel
      control={
        <Checkbox
          data-testid="checkbox"
          checked={formik?.values.checkTermsnCondition}
          onChange={(e) => {
            formik.setFieldValue("checkTermsnCondition", e.target.checked);
          }}
          style={{ paddingTop: "0px" }}
        />
      }
      style={{ color: "black" }}
      label={
        <Typography variant="caption" display="block" sx={{marginTop:-1.5}}>
          <Asterisk/> By signing up you confirm that you have read and accepted our
          <span> </span>
          <Link href="/TermsAndConditions" target="_blank" underline="none">
            Terms of Service
          </Link>
          {", "}
          <Link underline="none" href="/PrivacyPolicy" target="_blank">
            Privacy Policy
          </Link>{" "}
          and default
          <span> </span>
          <Link
            data-testid={"btn-NavigationSettings"}
            onClick={() => {
              navigate("/notificationSettings", {
                state: { email: props?.email },
              });
            }}
            underline="none"
            style={{ textDecoration: "none", cursor: "pointer" }}
          >
            Notification Settings.
          </Link>
        </Typography>
      }
      sx={{ alignSelf: "baseline" }}
    />
  );
};
