import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Paper, Grid, Box } from "@mui/material";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import { Payrate } from "../ui/Payrate";
import moment from "moment";
import { downloadRecruiterEarningPdf } from "../../utils/getFileFromUrl";
import { makeStyles } from "@material-ui/core";
import getUserDetails from "../../Shared/utils/getUserDetails";
import { Fullname } from "../Common/Fullname";
import { calculateSumWithPrecision } from "../../utils/numericUtils";
import GenericPagination from "../Common/Table/GenericPagination";
import { useState } from "react";
import PleaseWait from "../Common/Loder/PleaseWait";
import NoRecordsFound from "../Common/Table/NoRecordsFound";

const useStyles = makeStyles({
  tableCell: {
    width:"11.11%",
    flex: 1, // Make the columns equal size
  },
 });

export const RecruiterEarningTable = (props) => {
  const classes = useStyles();

  // Pagination state
  const [rowsPerPage] = useState(props?.filterState?.pageSize);

  const handleChangePage = (event, newPage) => {    
    props?.handlePaginationIndex(newPage);
  };

  return (
    <Grid item xs={12}>
      <Box
          sx={{
            marginTop: [-2, -5, -7.5] // -2 for mobile, -5 for tablet, and -7.5 for desktop
          }}
        >
        <TableContainer component={Paper} className="adminearning-table-compnent">
          <GenericPagination
            count={props?.totalRecords}
            rowsPerPage={rowsPerPage}
            page={props?.filterState?.pageIndex}
            onPageChange={handleChangePage}
          /> 
          <Table sx={{ fontSize: "1px" }} stickyHeader aria-label="sticky table" className="AppTable AppTableList">
            <TableHead className="AppTableListHeader">
              <TableRow>
                <TableCell align="left">Candidate Name</TableCell>
                <TableCell align="left">Client</TableCell>
                <TableCell align="left">Project</TableCell>
                <TableCell align="left">Hours Worked</TableCell>

                <TableCell align="left">Commission Approved</TableCell>
                <TableCell align="left">Commission</TableCell>
                <TableCell align="left">Payment Terms</TableCell>
                <TableCell align="left">Commission Due Date</TableCell>
                <TableCell>Statement</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {props?.data?.map((row,index) => {
                return (
                  <TableRow key={ row?.projectId ?? index} className={`AppTableList-table-row ${row?.isAdjustmentEntry ? "adjustment-row" : ""}`}>
                  <TableCell className={classes.tableCell}><Fullname fullName={row?.candidateName}/></TableCell>
                  <TableCell className={classes.tableCell}>{row?.projectClientName}</TableCell>
                  <TableCell className={classes.tableCell}>
                      <span className="projectId">{row?.projectId} </span>
                      <span className="projectName">{row?.projectName} </span>
                    </TableCell>
                  <TableCell className={classes.tableCell}>{row?.totalHours}</TableCell>
                  
                  <TableCell className={classes.tableCell}>{row?.isApproved ? "Yes" : "No"}</TableCell>
                  <TableCell className={classes.tableCell}>
                    <Payrate fontSize="0.875rem" packageType={""} packageValue={calculateSumWithPrecision(row?.totalCommission || 0, row?.oneTimeCommission || 0)} currency={row?.currencyType}/>
                  </TableCell>
                  <TableCell className={classes.tableCell}>{row?.paymentTerm}</TableCell>
                  <TableCell className={classes.tableCell}>
                  <span className="commissionDueDateText">
                      {moment(row?.commissionDueDate).format(
                          "MMMM DD, YYYY"
                        )}
                        </span>
                  </TableCell>

                    <TableCell  className={classes.tableCell} data-testid={"downloadEarningPDF"} onClick={()=>downloadRecruiterEarningPdf([{...row,recruiterGuid:getUserDetails()?.id}])}>
                    <SaveAltOutlinedIcon color="primary" className="pointer" />

                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          
        </TableContainer>
        {props.loading && <PleaseWait />}
        {!props.loading && props?.data?.length === 0 && <NoRecordsFound /> }
      </Box>
    </Grid>
  );
};
