import { FormControl, Input, InputLabel } from "@material-ui/core";
import { Autocomplete, Chip, CircularProgress, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { plainToInstance } from "class-transformer";
import { getIn, useFormik } from "formik";
import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  GetRecruiterById,
  UpdateRecruiterLocation,
} from "../../Services/recruiterService";
import { searchCities, searchOptions } from "../../constants/googlePlaceOption";
import { addressRegex, cityRegex, zipValidation } from "../../constants/regex";
import { timeZones } from "../../data/timeZones";
import {
  disableEdit,
  enableEdit,
  toggleEdit,
} from "../../features/editMode/editModeSlice";
import { RecruiterResponse } from "../../models/RecruiterResponse";
import { AppState } from "../../store/AppState";
import getAddressForAutoFill from "../../utils/getAddressForAutoFill";
import SnackbarPopup from "../Common/Popup/snackbar/SnackbarPopup";
import { CustomAlert } from "../ui/CustomAlert";
import { ErrorMessage } from "../ui/ErrorMessage";
import { valueOrDefault } from "../../utils/complexityUtils";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

interface LocationData {
  id: string;
  address: {
    id: string;
    streetAddress: string;
    city: string;
    state: string;
    postalCode: string;
    countryCode: string;
  };
  currentWorkLocation: {
    id: string;
    city: string;
    state: string;
  };
  phoneNumbers?:{
    primary:string;
    secondary: string;
  }
  timezone: string;
}

const LocationForm: React.FC = () => {
  const editMode = useSelector((state:AppState) => state.editMode);
  const dispatch = useDispatch();
  const [pageStatus, setPageStatus] = React.useState({
    isSuccess: false,
    error: "",
    loading: false,
  });

  const [locationDetails, setLocationDetails] = React.useState<LocationData>({
    id: "",
    address: {
      id: "",
      streetAddress: "",
      city: "",
      state: "",
      postalCode: "",
      countryCode: "",
    },

    currentWorkLocation: {
      id: "",
      city: "",
      state: "",
    },
    timezone: "",
  });

  React.useEffect(() => {
    dispatch(enableEdit());
    GetProfile();
  }, []);
  
  const GetProfile = () => {
    let userId = valueOrDefault(
      JSON.parse(localStorage.getItem("user") ?? "")?.userId,
      ""
    );
    GetRecruiterById(userId).then((res) => {
      const recruiter = plainToInstance(RecruiterResponse, res?.data);
      let apiData = recruiter?.entity;
      setLocationDetails({
        id: apiData?.id,
        address: {
          id: apiData?.address?.id ?? "", 
          streetAddress: apiData?.address?.streetAddress ?? "",
          city: apiData?.address?.city ?? "",
          state: apiData?.address?.state ?? "",
          postalCode: apiData?.address?.postalCode ?? "",
          countryCode: apiData?.address?.countryCode ?? "",
        },

        currentWorkLocation: apiData?.currentWorkLocation ?? {
          id: "",
          city: "",
          state: "",
        },
        timezone: apiData?.timezone,
      });
    });
  };

  const autoCompleteGetAddress = async (address: string) => {
    let resp = await getAddressForAutoFill(address);
    formik.setFieldValue("address.id", valueOrDefault(resp.city, ""));
    formik.setFieldValue("address.postalCode", valueOrDefault(resp.pin, ""));
    formik.setFieldValue("address.streetAddress", valueOrDefault(resp.formattedAddress, ""));
    formik.setFieldValue("address.countryCode", valueOrDefault(resp.country, ""));
    formik.setFieldValue("address.state", valueOrDefault(resp.state, ""));
    formik.setFieldValue("address.city", valueOrDefault(resp.city, ""));
    formik.setFieldValue("phoneNumbers.primary", "");
    formik.setFieldValue("phoneNumbers.secondary", "");

    if (resp.pin && resp.pin !== "") {
      formik.setFieldTouched("address.postalCode", false);
    }
    if (resp.formattedAddress && resp.formattedAddress !== "") {
      formik.setFieldTouched("address.streetAddress", false);
    }
    if (resp.state && resp.state !== "") {
      formik.setFieldTouched("address.state", false);
    }
    if (resp.city && resp.city !== "") {
      formik.setFieldTouched("address.city", false);
    }
    if (resp.country && resp.country !== "") {
      formik.setFieldTouched("address.countryCode", false);
    }
  };
  const autoCompleteGetWorkLocation = async (address: any) => {
    let resp = await getAddressForAutoFill(address);
    formik.setFieldValue("currentWorkLocation.city", valueOrDefault(resp.city, ""));
    formik.setFieldValue("currentWorkLocation.state", valueOrDefault(resp.state, ""));
    
    if (resp.placeId && resp.placeId !== "") {
      formik.setFieldTouched("currentWorkLocation.id", false);
    }
    if (resp.state && resp.state !== "") {
      formik.setFieldTouched("currentWorkLocation.state", false);
    }
    if (resp.city && resp.city !== "") {
      formik.setFieldTouched("currentWorkLocation.city", false);
    }
  };

  let initialState = locationDetails;
  const LocationSchema = Yup.object({
    id: Yup.string(),
    address: Yup.object().shape({
      id: Yup.string(),
      streetAddress: Yup.string()
        .required("Please enter the address")
        .matches(addressRegex, "Entered address is invalid"),
      city: Yup.string().required("Please enter the city").matches(cityRegex,"Entered city is invalid"),
      state: Yup.string().required("Please enter the State").matches(cityRegex,"Entered state is invalid"),
      postalCode: Yup.string().required("Please enter the Zip").matches(zipValidation,"Entered zip code is invalid"),
      countryCode: Yup.string().required("Please enter the Country").matches(cityRegex,"Entered country is invalid"),
    }),
  });

  const formik = useFormik({
    initialValues: initialState,
    enableReinitialize: true,
    validationSchema: LocationSchema,
    onSubmit: async (values, actions) => {
      setPageStatus({ isSuccess: false, error: "", loading: true });
      try {
        const response = await UpdateRecruiterLocation(values);
        if (response.data.status == 200) {
          dispatch(disableEdit());
          setLocationDetails({
            id: values.id,
            address: values.address,
            phoneNumbers: values?.phoneNumbers ?? {
              primary: "",
              secondary:""
            },
            currentWorkLocation: values.currentWorkLocation ?? {
              id: "",
              city: "",
              state: "",
            },
            timezone: values.timezone,
          });
          setPageStatus({ isSuccess: true, error: "", loading: false });
        } else {
          setPageStatus({
            isSuccess: false,
            error: response.data?.message?.appStatusDescription,
            loading: false,
          });
        }
      } catch (er:any) {
        setPageStatus({
          isSuccess: false,
          error: er?.message || "Something went wrong",
          loading: false,
        });
      }
    },
  });

  const renderFunc = ({
    getInputProps,
    suggestions,
    getSuggestionItemProps,
    loading,
  }:any) => (
    <div className="recruiter-location-address">
      <FormControl
        variant="standard"
        error={
          formik.touched?.address?.streetAddress &&
          Boolean(formik.errors.address?.streetAddress)
        }
        fullWidth
        required
      >
        <InputLabel htmlFor="address-input">Address</InputLabel>
        <Input
          {...getInputProps()}
          name="address.streetAddress"
          type="text"
          onBlur={formik.handleBlur}
          readOnly={!editMode.isEditable}
        />
        <ErrorMessage
          errorText={
            formik.touched.address?.streetAddress &&
            formik.errors?.address?.streetAddress
          }
        />
      </FormControl>
      <div className="autocomplete-dropdown-container">
        {loading && <div>Loading...</div>}
        {suggestions.map((suggestion: any) => (
          <div {...getSuggestionItemProps(suggestion)}>
            <span
              onChange={(e:any)=>{autoCompleteGetAddress(e.target.value)}}
              style={{ cursor: "pointer" }}
            >
              {suggestion.description}
            </span>
          </div>
        ))}
      </div>
    </div>
  );

  const renderFuncCurrentWorkLocation = ({
    getInputProps,
    suggestions,
    getSuggestionItemProps,
    loading,
  }:any) => (
    <div>
      <FormControl
        variant="standard"
        error={Boolean(
          getIn(formik.touched, "currentWorkLocation.city") &&
            getIn(formik.errors, "currentWorkLocation?.city")
        )}
        fullWidth
      >
        <InputLabel htmlFor="address-input">City</InputLabel>
        <Input
          {...getInputProps()}
          name="currentWorkLocation.city"
          type="text"
          readOnly={!editMode.isEditable}
        />
        <ErrorMessage
          errorText={
            formik.touched.currentWorkLocation?.city &&
            formik.errors.currentWorkLocation?.city
          }
        />
      </FormControl>
      <div className="autocomplete-dropdown-container">
        {loading && <div>Loading...</div>}
        {suggestions.map((suggestion:any) => (
          <div {...getSuggestionItemProps(suggestion)}>
            <span
              onChange={autoCompleteGetWorkLocation}
              style={{ cursor: "pointer" }}
            >
              {suggestion.description}
            </span>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <Grid container spacing={2} mb={3}>
      <Grid item xs={12} md={12}>
        {editMode.isEditable && (
          <Box className="manage-profile-btns" sx={{ textAlign: "right" }}>
            <Button
              variant="outlined"
              onClick={() => {
                dispatch(toggleEdit());
                formik.setValues(locationDetails);
                formik.setErrors({});
              }}
              data-testid="btn-cancel-update-location-form"
              className="manage-profile-btnCancel"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                formik.handleSubmit();
              }}
              variant="contained"
              disabled={formik.isSubmitting}
              endIcon={
                formik.isSubmitting ? (
                  <CircularProgress size={"16px"} color={"inherit"} />
                ) : (
                  ""
                )
              }
              data-testid="btn-update-location-form"
            >
              Update Profile
            </Button>
          </Box>
        )}
      </Grid>
      <Grid item>
          { pageStatus?.error && (
              <CustomAlert key={1} type={"error"} message={pageStatus.error} onClose={()=>{}}/>
            )}

          {pageStatus?.isSuccess && (
            <SnackbarPopup 
              open={pageStatus.isSuccess}
              message={"Location information updated successfully"}
              onClose={()=>{ setPageStatus({...pageStatus, isSuccess:false}) }}
            />
          )}
      </Grid> 
      <Grid item xs={12} md={12}>
        <Item
          elevation={0}
          sx={{
            backgroundColor: "transparent",
            textAlign: "left",
            padding: 0,
          }}
        >
          <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{
              flexDirection: "row",
              "& .MuiFormControl-root": {
                marginTop: "3px",
                marginBottom: "3px",
              },
            }}
          >
            <Grid container spacing={2}>
              {/* Address field */}
              <Grid item xs={12} md={12}>
                <PlacesAutocomplete
                searchOptions={searchOptions}
                value={formik.values?.address?.streetAddress}
                onChange={(ev: any) => {
                  formik.setFieldValue("address.streetAddress", ev);
                }}
                onSelect={autoCompleteGetAddress}
              >
                {renderFunc}
              </PlacesAutocomplete>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl
                  fullWidth
                  variant="standard"
                  error={Boolean(
                    getIn(formik.touched, "address.city") &&
                      getIn(formik.errors, "address.city")
                  )}
                  required
                >
                  <InputLabel htmlFor="address.city">City</InputLabel>
                  <Input
                    onChange={(val) => {
                      formik.setFieldValue("address.city", val.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values?.address?.city}
                    name="address.city"
                    type="text"
                    readOnly={!editMode.isEditable}
                  />
                  <ErrorMessage
                    errorText={
                      formik.touched.address?.city && formik.errors.address?.city
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl
                  fullWidth
                  variant="standard"
                  error={Boolean(
                    getIn(formik.touched, "address.state") &&
                      getIn(formik.errors, "address.state")
                  )}
                  className="state-input"
                  required
                >
                  <InputLabel htmlFor="address.state">State</InputLabel>
                  <Input
                    onChange={(val) => {
                      formik.setFieldValue("address.state", val.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values?.address?.state}
                    name="address.state"
                    type="text"
                    readOnly={!editMode.isEditable}
                  />
                  <ErrorMessage
                    errorText={
                      formik.touched.address?.state &&
                      formik.errors.address?.state
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl
                  fullWidth
                  variant="standard"
                  error={Boolean(
                    getIn(formik.touched, "address.countryCode") &&
                      getIn(formik.errors, "address.countryCode")
                  )}
                  className="state-input"
                  required
                >
                  <InputLabel htmlFor="address.countryCode">Country</InputLabel>
                  <Input
                    onChange={(val) => {
                      formik.setFieldValue(
                        "address.countryCode",
                        val.target.value
                      );
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values?.address?.countryCode}
                    name="address.countryCode"
                    type="text"
                    readOnly={!editMode.isEditable}
                  />
                  <ErrorMessage
                    errorText={
                      formik.touched?.address?.countryCode &&
                      formik.errors.address?.countryCode
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl
                  fullWidth
                  variant="standard"
                  error={Boolean(
                    getIn(formik.touched, "address.postalCode") &&
                      getIn(formik.errors, "address.postalCode")
                  )}
                  required
                >
                  <InputLabel htmlFor="address.postalCode">Zip</InputLabel>
                  <Input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values?.address?.postalCode}
                    name="address.postalCode"
                    type="text"
                    readOnly={!editMode.isEditable}
                  />
                  <ErrorMessage
                    errorText={
                      formik.touched?.address?.postalCode &&
                      formik.errors?.address?.postalCode
                    }
                  />
                </FormControl>
              </Grid>

              {/* Current location field */}
              <Grid item xs={12} md={12}>
                <InputLabel>
                    Current work location
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={3}>
                <PlacesAutocomplete
                  searchOptions={searchCities}
                  value={formik.values?.currentWorkLocation?.city}
                  onChange={(ev: any) => {
                    formik.setFieldValue("currentWorkLocation.city", ev);
                  }}
                  onSelect={autoCompleteGetWorkLocation}
                >
                  {renderFuncCurrentWorkLocation}
                </PlacesAutocomplete>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl
                  fullWidth
                  variant="standard"
                  error={Boolean(
                    getIn(formik.touched, "currentWorkLocation.state") &&
                      getIn(formik.errors, "currentWorkLocation.state")
                  )}
                  //required
                >
                  <InputLabel htmlFor="currentWorkLocation.state">
                    State
                  </InputLabel>
                  <Input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values?.currentWorkLocation?.state}
                    name="currentWorkLocation.state"
                    type="text"
                    readOnly={!editMode.isEditable}
                  />
                  <ErrorMessage
                    errorText={
                      formik.touched.currentWorkLocation?.state &&
                      formik.errors.currentWorkLocation?.state
                    }
                  />
                </FormControl>
              </Grid>

               {/* TimeZone field */}
              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="size-small-standard-multi2"
                  size="medium"
                  className="customCity"
                  readOnly={!editMode.isEditable}
                  options={timeZones.map((x) => x.value + " " + x.label)}
                  value={formik.values.timezone}
                  defaultValue={formik.values.timezone}
                  onChange={(e, value) => formik.setFieldValue("timezone", value)}
                  getOptionLabel={(option) => option}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        color="primary"
                        size="medium"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      //required
                      variant="standard"
                      label="TimeZone"
                    />
                  )}
                />
                 <ErrorMessage
                    errorText={formik.touched.timezone && formik.errors.timezone}
                  />
              </Grid>
            </Grid>          
          </Box>
        </Item>        
      </Grid>
    </Grid>
  );
}

export default LocationForm;
