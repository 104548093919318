import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Component } from "react";
import Chart from "react-apexcharts";

class TechStatsAdmin extends Component {
   styles = {
    position: "absolute",
    marginTop: "2.7%",
    marginLeft: "-2.6%",
    width: "15px"
  };
  
   responsiveStyles = {
    '@media (max-width: 600px)': {
      ...this.styles,
      marginTop: "1.5%",
      marginLeft: "-1.5%",
      width: "12px"
    },
    '@media (min-width: 601px) and (max-width: 1024px)': {
      ...this.styles,
      marginTop: "2%",
      marginLeft: "-2%",
      width: "14px"
    },
    '@media (min-width: 1025px) and (max-width: 2560px)': {
      marginTop: "1.7%",
      marginLeft: "-2.6%",
      width: "15px"
    },
    '@media (min-width: 1025px)': {
      ...this.styles,
      marginTop: "2.7%",
      marginLeft: "-2.6%",
      width: "15px"
    }
  };
  
  constructor(props) {
    
    super(props);
    let submissionsInCurrentYear = props?.data?.submissionsInCurrentYear
    let diffInSubmission = props?.data?.differenceInSubmissions
    let isPositive = props?.data?.isGrowthPositive


    this.state = {
      isPositive: isPositive,
      series: [diffInSubmission],
      options: {

        chart: {
          type: "radialBar",
          offsetY: -20,
          sparkline: {
            enabled: true,
          },

        },
        colors: ["#4540DB"],
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,

            track: {
              background: "#e7e7e7",
              strokeWidth: "60%",

            },
            dataLabels: {
              show: true,

              value: {
                show: true,
                offsetY: -30,
                fontSize: "16px",
                fontWeight: "bold",
                

              },
              total: {
                show: true,
                label: diffInSubmission + "%",

                offsetY: -30,
                fontSize: "10px",
                color: "#808080",
                fontWeight: "300",
                formatter: function (w) {
                  return submissionsInCurrentYear + "+"
                }
              }
            }
          },
        },
        grid: {
          padding: {
            bottom: 15,
          }
        },
        fill: {
          type: "solid",
          color: "#4540DB",
        },

      },
    };
  }
  renderArrowIcon = () => {
    return this.state.isPositive ? (
      <ArrowDropUpIcon sx={{ ...this.responsiveStyles, color: 'green' }} />
    ) : (
      <ArrowDropDownIcon sx={{ ...this.responsiveStyles, color: 'red' }} />
    );
  }


  render() {
    return (
      <>
        <div style={{
          height: "32px",
          width: "90px",
          backgroundColor: "#fff",
          borderTopLeftRadius: "110px",
          borderTopRightRadius: "110px",
          borderWidth: "10px",
          borderBlockColor: "#fff",
          position: "absolute",
          borderStyle: "solid",
          borderLeftColor: "lightgray",
          borderRightColor: "lightgray",
          marginBottom: "10px"

        }} />
        {this.renderArrowIcon()}



        <Chart
          options={this.state.options}
          series={this.state.series}
          type="radialBar"
          width="260"
        />
      </>

    );
  }
}

export default TechStatsAdmin;
