import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { PermissionsEnums } from "../../../../data/permission";
import { JobOriginIds } from "../../../../models/Job/JobDetailsModel";
import { UserAuth } from "../../../../models/interfaces";
import { CandidateSubmissionStatus } from "../../../../models/submission/CandidateSubmissionMasterStatusesModel";
import { ModuleComponent } from "../../../Common/Permission/ModuleComponent";
import "../../NewViewSubmission/styles.scss";
import { getIsClientModuleEnabled } from "../../../../utils/AppConfig";

interface SubmittedToClientButtonProps {
  row: any;
  userRole: any;
  currentjobDetails: any;
  setPopup: any;
}

export default function SubmittedToClientButton({
  row,
  userRole,
  currentjobDetails,
  setPopup
} : SubmittedToClientButtonProps) {

  const isClientModuleEnabled: boolean = getIsClientModuleEnabled();

  const isSubmittedToClient = (
    isClientModuleEnabled && 
    currentjobDetails?.jobOriginId === JobOriginIds.jobRialto &&
    row.finalStatusId === CandidateSubmissionStatus.SubmittedToAM &&
    (userRole === UserAuth.SuperAdmin || userRole === UserAuth.Admin)
  );

  const handleButtonClick = () => {
    if (isSubmittedToClient) {
      setPopup((popup: any) => ({ ...popup, submittedToClient: true }));
    }
  };

  return (
    <ModuleComponent moduleId={PermissionsEnums.Submissions_Submit_to_CL}>
      {isSubmittedToClient && (
        <Grid item>
          <Button
            className="submission-action-btn"
            variant="contained"
            color="primary"
            onClick={handleButtonClick}
          >
            Submitted to Client
          </Button>
        </Grid>
      )}
    </ModuleComponent>
  );
}