import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Login1 from "./../../assets/images/Login1.svg";
import Login2 from "./../../assets/images/Login2.svg";
import Login3 from "./../../assets/images/Login3.svg";
import Login4 from "./../../assets/images/Login4.svg";
import Picture from "./../../assets/images/loginpeople.svg";
import { Link } from "react-router-dom";

export const LoginBanner = () => {
  return (
    <Box className="login-Banner-wrapper">
      <Box className="banner-typography-text">
        <Typography
          variant="h2"
          component="span"
          className="custom-banner-txt"
          sx={{
            fontSize: {
              xs: "28px !important",
              sm: "28px !important",
              md: "28px !important",
            },
          }}
        >
          Are you an independent recruiter?
        </Typography>
        <Typography
          className="custom-banner-txt1"
          sx={{
            fontSize: {
              xs: "20px !important",
              sm: "20px !important",
              md: "20px !important",
            },
          }}
        >
          Get on board and work on 1000s of active jobs:
        </Typography>

        <List className="login-text-padding custom-banner-lst-txt">
          <ListItem>
            <ListItemIcon style={{ minWidth: "35px" }}>
              <img src={Login1} alt="" />
            </ListItemIcon>
            <ListItemText className="custom-banner-txt2">
              That are in your wheelhouse
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon style={{ minWidth: "35px" }}>
              <img src={Login2} alt="" />
            </ListItemIcon>
            <ListItemText className="custom-banner-txt2">
              Carefully curated with exclusive first right to respond
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon style={{ minWidth: "35px" }}>
              <img src={Login3} alt="" />
            </ListItemIcon>
            <ListItemText className="custom-banner-txt2">
              By leveraging your candidate pool
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon style={{ minWidth: "35px" }}>
              <img src={Login4} alt="" />
            </ListItemIcon>
            <ListItemText className="custom-text1 custom-banner-txt2">
              And get lifetime credits for referred candidates
            </ListItemText>
          </ListItem>
        </List>

        <Typography
          variant="h5"
          color="inherit"
          component="span"
          className="abel text-link login-faq-txt custom-text1"
        >
          Check out the{" "}
          <Link
            to="/faq"
            style={{
              textDecoration: "underline",
              color: "rgba(123, 184, 255, 1)",
            }}
          >
            FAQ
          </Link>{" "}
          for details
        </Typography>
      </Box>
      <Box className="banner-bg login-banner-right">
        <img className="banner-image" src={Picture} alt="" />
      </Box>
    </Box>
  );
};
