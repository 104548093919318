import { geocodeByAddress } from "react-places-autocomplete";
import { valueOrDefault } from "./complexityUtils";

const compIsType = (t, s) => {
  for (let z of t) {
    if (z === s) {
      return true;
    }
  }

  return false;
};

const getAddressForAutoFill = async (address) => {
  let returnData = {
    formattedAddress: "",
    pin: "",
    country: "",
    countryCode: "",
    city: "",
    state: "",
    stateCode: "",
    placeId : ""
  };
  let resData = geocodeByAddress(address)
    .then((results) => {
      let addressDetails = results[0];
      let data = handlePlaceChanged(addressDetails);
      returnData.city = data.city;
      returnData.state = data.state;
      returnData.stateCode = data.stateCode;
      returnData.country = data.country;
      returnData.countryCode = data.countryCode;
      returnData.pin = data.postalCode;
      returnData.placeId = data.placeId;
      returnData.formattedAddress =data.formattedAddress
      return returnData;
    })
    .catch((error) => {
      console.error("Error", error);
    });
  return resData;
};
export default getAddressForAutoFill;

const processAddressComponents = (addressComponents, placeId, formattedAddress) => {
  let country, countryCode, postalCode, state, stateCode, city = "";
  for (const addr of addressComponents) {
    const typ = addr.types;

    if (compIsType(typ, "administrative_area_level_1")) {
      stateCode = valueOrDefault(addr.short_name, "");
      state = valueOrDefault(addr.long_name, "");
    }

    if (compIsType(typ, "locality")) {
      city = valueOrDefault(addr.long_name, "");
    }

    if (compIsType(typ, "country")) {
      countryCode = valueOrDefault(addr.short_name, "");
      country = valueOrDefault(addr.long_name, "");
    }

    const postalCodeObj = addressComponents.find((ac) => ac.types.includes("postal_code"));
    postalCode = postalCodeObj?.short_name;

    if (state != null && city != null && country != null) break;
  }
  return { state, stateCode, city, countryCode, country, postalCode, placeId, formattedAddress };
};

const handlePlaceChanged = (place) => {
  let placeId = "";
  let formattedAddress = '';

  if (place.address_components) {
    formattedAddress = [
      valueOrDefault(place.address_components[0]?.short_name, ''),
      valueOrDefault(place.address_components[1]?.long_name, '')
    ].join(' ');
  }

  if (place.address_components !== undefined) {
    placeId = place.place_id;
    return processAddressComponents(place.address_components, placeId, formattedAddress);
  }
};
