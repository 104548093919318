//get unique elements by key by default key is id
function getUnique(arr,key="id")
{
  
    const uniqueIds = [];

    const uniqueIndustries = arr?.filter(element => {
      const isDuplicate = uniqueIds.includes(element?.[key]);
   
      if (!isDuplicate) {
        uniqueIds.push(element?.[key]);
   
        return true;
      }
   
      return false;
    });
    return uniqueIndustries
}
export default getUnique