

import { createSvgIcon } from "@mui/material/utils";

export const ContractIcon = createSvgIcon(
   
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.2777 3.42711H17.9919V12.8555H19.7064V1.71289H2.56348V22.2842H11.9919V20.57H4.2777V3.42711Z" fill="black"/>
<path d="M5.99219 5.14062H16.278V6.85484H5.99219V5.14062ZM5.99219 8.5693H14.5635V10.2835H5.99219V8.5693ZM5.99219 11.9977H11.1351V13.712H5.99219V11.9977ZM17.1332 13.7122C14.7663 13.7122 12.8474 15.631 12.8474 17.998C12.8474 20.3649 14.7663 22.2838 17.1332 22.2838C19.5002 22.2838 21.419 20.3649 21.419 17.998C21.419 15.631 19.5002 13.7122 17.1332 13.7122ZM17.1332 20.5693C15.7152 20.5693 14.5619 19.4157 14.5619 17.998C14.5619 16.58 15.7155 15.4266 17.1332 15.4266C18.5512 15.4266 19.7045 16.5802 19.7045 17.998C19.7045 19.4157 18.5509 20.5693 17.1332 20.5693Z" fill="black"/>
<path d="M16.8255 18.2259L15.8283 17.3322L14.9702 18.2898L16.9394 20.054L19.353 17.2773L18.383 16.4336L16.8255 18.2259Z" fill="black"/>
</svg>

);
