import axios from './CommonHttpRequest';

let baseUrl = `${process.env.REACT_APP_IDPROVIDER_API}`;

export interface InitiateResetEmailModel{
    email:string,
    redirectUrl:string,
    name:string
}
export interface InitiateResetModel{
    userName:string,
    password:string,
    confirmPassword:string,
    token:string
}
export async function InitiateResetPasswordEmail(values:InitiateResetEmailModel) {  
       
        values.name= values.name.trim() || "User" ;  
        return await axios.post(`${process.env.REACT_APP_IDPROVIDER_API}api/ResetPasswordEmailSend/InitiateResetPasswordEmail`,
        JSON.stringify(values),
        {
            headers:{'Content-Type':'application/json'},
        });
}
export async function ResetPasswordVerifyEmail(values:InitiateResetEmailModel) {   
    return await axios.get(`${process.env.REACT_APP_IDPROVIDER_API}api/ResetPasswordEmailSend/ResetPasswordVerifyEmail?email=${values.email}`);
    
}
export async function InitiateReset(values:InitiateResetModel) {   
    return await axios.post(`${process.env.REACT_APP_IDPROVIDER_API}api/ProfileUpdate/ResetPassword`,
    JSON.stringify(values),{
        headers:{'Content-Type':'application/json'} 
    });
}
export const AuthenticateToken = async (token: string) => {
    let body = {token:token}
    return await axios.post( `${baseUrl}api/Authentication/AuthenticateToken`, body);
  };

  export const GetRefreshToken = async (bodyPayload: string) => {
    return await axios.post( `${baseUrl}api/Authentication/GetRefreshToken`, bodyPayload);
  };
