import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import jwt_decode from "jwt-decode";
import * as React from "react";
import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";
import { GetRefreshToken } from "../Services/resetPasswordService";
import setAuthToken from "./setAuthToken";
import { valueOrDefault } from "./complexityUtils";

const RefreshToken = () => {
  const navigate = useNavigate();
  const timeout = 900000;
  const [remaining, setRemaining] = useState(timeout);

  const handleOnActive = () => {
    // intentionally blank
  };
  const handleOnIdle = () => {
    // intentionally blank
  };
  const { getRemainingTime } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
  });

  const [open, setOpen] = React.useState(false);
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [key, setKey] = React.useState('');
 
  let interval;
  useEffect(() => {
    interval = setInterval(() => {
      if (!sessionExpire) {
        checkForTokenExpire();
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [sessionExpire]);
  useEffect(() => {
    setRemaining(getRemainingTime());
   
    setInterval(() => {
      setRemaining(getRemainingTime());
     
    }, 1000);
  }, []);

  useEffect(() => {
    if (remaining <= 0) {
      if (localStorage.getItem("accessToken")) {
        setOpen(true);
      }
    }
  }, [remaining]);
  useEffect(() => {
   setSessionExpire(false)
  }, [key]);
  const handleSignIn = () => {
    let userDe = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : "";
    let refreshToken = valueOrDefault(userDe?.refreshToken, "");
    let jwt_token = localStorage.getItem("accessToken")
      ? localStorage.getItem("accessToken")
      : "";
    let refreshTokenData = {
      accessToken: jwt_token,
      refreshToken: refreshToken,
    };
    GetRefreshToken(refreshTokenData)
      .then((response) => {
        if (response?.data?.message?.appStatusCode === "IP_RSF") {
          setAuthToken(response?.data?.entity?.accessToken);
          localStorage.setItem(
            "user",
            JSON.stringify({
              ...userDe,
              refreshToken: response?.data?.entity?.refreshToken,
            })
           
          );
          setKey(Date.now())//re init the checking method
          setOpen(false);
        } else {
          if (response?.data?.message?.appStatusCode === "RC_TOK_EXP") {
            handleLogout();
          }
        }
      
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleYes = () => {
    if (localStorage.getItem("accessToken")) {
      let token = localStorage.getItem("accessToken")
        ? localStorage.getItem("accessToken")
        : null;
      let decoded = jwt_decode(token);
      let currentUTCTime = Math.floor(new Date().getTime() / 1000);
      if (decoded?.exp) {
        if (decoded?.exp < currentUTCTime) {
          // expiring session stops checking for token
          setSessionExpire(true);
          // setting key sets sessionExpire=false, so token gets checked again
          // which refreshes and closes popup
          setKey(Date.now());
        } else {
          setOpen(false);
        }
      } else {
        setOpen(false);
      }
    } else {
      setOpen(false);
    }
  };

  const handleLogout = () => {
    navigate("/logout");
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const checkForTokenExpire = () => {
    
    if (localStorage.getItem("accessToken")) {
      let token = localStorage.getItem("accessToken")
        ? localStorage.getItem("accessToken")
        : null;
      let decoded = jwt_decode(token);
      let currentUTCTime = Math.floor(new Date().getTime() / 1000);
      if (decoded?.exp) {
        if (decoded?.exp < currentUTCTime) {
          handleSignIn();
          setSessionExpire(true); // stop the checking while token refreshes
        }
      }
    }
  };

  return (
    <div>
      <Dialog
        data-testid={"closePopup"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onBackdropClick="false"
        PaperProps={{
          style: {
            background: "#E6E9FB",
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ justifyContent: "center", padding: "16px 24px 10px" }}
          style={{ textAlign: "center" }}
        >
          <WarningAmberIcon style={{ color: "#FFCC00", fontSize: "24px" }} />
          <p style={{ margin: "0 10px 0 0" }}>Your session has expired!</p>
        </DialogTitle>

        <DialogContent sx={{ padding: "0 25px" }}>
          <DialogContentText id="alert-dialog-description">
            Do you want to stay signed in ?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", m: "1rem" }}>
          <Button data-testid="contained" variant="contained" onClick={handleYes}>
            Yes
          </Button>
          <Button data-testid="contained" variant="outlined" onClick={handleLogout}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RefreshToken;
