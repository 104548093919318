import { Nullable } from "../../models/interfaces";
import { valueOrDefault } from "../../utils/complexityUtils";
export interface UserDetails {
  id : Nullable<string>;
  userName: Nullable<string>;
  email: Nullable<string>;
  userRoleType: Nullable<string>;
  clientId:Nullable<string>;
}
//get loggedin user info
function getUserDetails(): UserDetails {
    let user = localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")!);
    if (user) {
    
        let x: UserDetails = {
          id : user?.userId,
          userName: user?.userName,
          email: user?.email,
          userRoleType: user?.loggedInUserRole,
          clientId: valueOrDefault(user?.clientId, "")
        };
        return x;
      }
      return {
        id :null,
        userName: null,
        email: null,
        userRoleType:null,
        clientId:null
    }
 
}
export default getUserDetails;
