import circleOkIcon from "../../assets/images/circle-ok.svg";
import { AllJobsMenuIcon } from "../../components/Common/Icons/AllJobMenuIcon";
import { CandidatesAdminMenuIcon } from "../../components/Common/Icons/CandidatesAdminMunuIcon";
import { DashboardMenuIcon } from "../../components/Common/Icons/DashboardMenuIcon";
import { Jobs as JobsIcon } from "../../components/Common/Icons/Jobs";
import { SubmissionsMenuIcon } from "../../components/Common/Icons/SubmissionsMenuIcon";
import { PermissionsEnums } from "../permission";

export const teamNavItems = [
  {
    id: 1,
    name: "dashboard",
    label: "Dashboard",
    link: "/dashboard",
    icon: <DashboardMenuIcon />,
    permissionId: "",
    sub: [],
  },
  {
    id: 5,
    name: "Jobs",
    label: "Jobs",
    link: "/jobs",
    icon: <JobsIcon className="icon-color-default" />,
    permissionId:PermissionsEnums.Jobs_View,
    sub: [
        {
          id: 5.1,
          name: "alljobs",
          label: "All",
          link: "/jobs/all",
          permissionId:PermissionsEnums.Jobs_View,
          icon:   <AllJobsMenuIcon />,
        },
        {
            id: 5.4,
            name: "pendingjobs",
            label: "Pending Jobs",
            link: "/jobs/pending",
            permissionId: PermissionsEnums.Jobs_Pending,
            icon:   <AllJobsMenuIcon />,
          },
          {
            id: 5.5,
            name: "addjob",
            label: "Add Job",
            link: "/team/addJob",
            permissionId: `${PermissionsEnums.Jobs_Add},${PermissionsEnums.Jobs_Pending}`,
            icon:   <AllJobsMenuIcon />,
          },
       
        
       
         
      ],
  },
  {
    id: 7,
    name: "teammembers",
    label: "Team Members",
    link: "/team/TeamMembers",
    permissionId: PermissionsEnums.Members_Listing,
    icon: <CandidatesAdminMenuIcon />,
    sub: [],
  },
  {
    id: 6,
    name: "Submission",
    label: "Submissions",
    link: "/submission",
    permissionId: PermissionsEnums.Submissions_All,
    icon: <SubmissionsMenuIcon />,
    sub: [
      {
        id: 6.1,
        name: "Active",
        label: "Active",
        link: "/submission/active",
        permissionId: "",
        icon:  <img src={circleOkIcon} alt="circleOkIcon" />,
      },
      {
        id: 6.2,
        name: "Archived",
        label: "Archived",
        link: "/submission/archived",
        permissionId: "",
        icon:  <img src={circleOkIcon} alt="circleOkIcon" />,
      },
    ],
  },

];
