import Brightness1Icon from "@mui/icons-material/Brightness1";
import { Card, CardContent } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { UserAuth } from "../../../models/interfaces";

const BorderLinearProgressLight = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#9390FA",
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

export default function TopProfiles({ data , userType}) {
  return (
    <Card sx={{ height: "100%" }} className="card-custom-shadow">
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
          <Grid
            item
            sx={{
              padding: 0,
              width: "100%",
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Typography
                variant="caption2"
                sx={{ fontSize: "14px", fontWeight: "600" }}
              >
                Top {userType==UserAuth.Admin? "Recruiters" : "Profiles"}
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: "15px",
                padding: 0,
                alignItems: "left",
              }}
            >
              {
              data?.length > 0 
                ?  data?.map((x) => {
                    return (
                      <>
                        {" "}
                        <Grid container spacing={0} sx={{ marginBottom: "10px" }}>
                          <Grid item xs={9}>
                            <Item sx={{ paddingLeft: 0 }}>
                            <Brightness1Icon
                                  sx={{ fontSize: "10px", color: "#9390FA" }}
                                />
                              <Typography
                                color="textPrimary"
                                variant="caption1"
                                component="span"
                                sx={{ fontWeight: "600", paddingLeft:'7px' }}
                              >
                              {x?.jobTitle}
                              </Typography>
                            </Item>
                          </Grid>
                          <Grid item xs={3}>
                            <Item
                              className="percent light"
                              sx={{ textAlign: "right" }}
                            >
                              <Typography
                                color="textPrimary"
                                variant="caption1"
                                component="span"
                                sx={{ fontWeight: "600" }}
                              >
                                { userType==UserAuth.Recruiter || userType==UserAuth.Client || userType==UserAuth.ClientTeamMember 
                                  ? x?.submissionCount 
                                  : x.recruiterCount
                                }
                            
                              </Typography>
                            </Item>
                          </Grid>
                        </Grid>
                        <BorderLinearProgressLight
                          variant="determinate"
                          value={x?.progressPercentage}
                        />
                      {userType==UserAuth.Admin && <br/>}

                      </>
                    );
                  })
                : <Typography mt={2}>No data found</Typography>
            }
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
