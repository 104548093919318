import {
  Autocomplete,
  Chip,
  CircularProgress,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { GetAllSkills } from "../../../../../../Services/recruiterService";
import useApi from "../../../../../../hooks/useApi";
import toTitles from "../../../../../../utils/toTitles";

interface JobMutliSkillAutocomepleteProps {
  label:string;
  fieldName:string;
   handleBlur: (event: React.FocusEvent<any>) => void;
    setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  selectedValue: string[];
  isError: boolean;
  isRequired: boolean;
  helperText: string;
  placeholderText: string;
  className: string;
}

const JobMutliSkillAutocomeplete: React.FC<JobMutliSkillAutocomepleteProps> = ({
  label,
  fieldName,
  handleBlur,
  setFieldValue,
  selectedValue,
  helperText,
  isError,
  isRequired,
  placeholderText,
  className
}) => {

    const [searchSkill, setSearchSkill] = React.useState<any>("");

    const onSkillInputChange = (event : any) => {
      setSearchSkill(event.target.value);
    };
  const allSkills = useApi(() => GetAllSkills(searchSkill, 20));
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      allSkills.request();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchSkill]);
  return (
    <>
      <Autocomplete
        noOptionsText={allSkills?.loading ? "Loading..." : "No options"}
        id="jobIndustries"
        multiple={true}
        disableClearable={false}
        className={className}
        size="medium"
        data-testid="job-industry"        
        onChange={(_, newValue:any) => {
          setFieldValue(fieldName, newValue);
         }}   
         onBlur={handleBlur}      
        options={
            allSkills?.data?.entityList?.map(
            (list:any) => list?.skillName
          ) || []
        }
        value={selectedValue}
        defaultValue={selectedValue}
        getOptionLabel={(option:any) => option}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              color="primary"
              size="small"
              label={toTitles(option)}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}           
            variant="standard"
            label={label}
            name={fieldName}
            onKeyUp={onSkillInputChange}
            required={isRequired}
            error={isError}
            helperText={helperText}
            placeholder={placeholderText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {allSkills?.loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
};

export default JobMutliSkillAutocomeplete;
