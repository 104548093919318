
export const defaultDataAutocomplete =  [
  {
    id : "ChIJOwg_06VPwokRYv534QaPC8g",
    location : {city:"New York",state:" NY",country:" USA"}
  },
  {
    id : "ChIJLwPMoJm1RIYRetVp1EtGm10",
    location : {city:"Austin",state:" TX",country:" USA"}
  },
  {
  
    id : "ChIJ-XF5tvbdw4kRNd9v-hN2S6U",
    location : {city:"Plainsboro Township",state:" NJ",country:" USA"}
  },
  {
    id : "ChIJd7zN_thz54gRnr-lPAaywwo",
    location : {city:"Orlando ",state:" FL",country:" Canada"}
  },
  {
    id : "ChIJWzsbOMS7ClMRSDp_bUV84hU",
    location : {city:"Camrose",state:" AB",country:" Canada"}
  }
 
  
  ]
export const defaultSelectAutoComplete = [
  {
    description: "San Francisco, CA, USA",
    formattedSuggestion: { mainText: "San Francisco", secondaryText: "CA, USA" },
    placeId: "1b9ea3c094d3ac23c9a3afa8cd4d8a41f05de50a"
  },
  {
    description : "New York, NY, USA",
    formattedSuggestion : {mainText:"New York",secondaryText:"NY, USA"},
    placeId : "ChIJOwg_06VPwokRYv534QaPC8g",

  },
  {
    description : "Austin, TX, USA",
    formattedSuggestion : {mainText:"Austin",secondaryText:"TX, USA"},
    placeId : "ChIJLwPMoJm1RIYRetVp1EtGm10",
  },
  {
    description : "Plainsboro Township, NJ, USA",
    formattedSuggestion : {mainText:"Plainsboro Township",secondaryText:"NJ, USA"},
    placeId : "ChIJ-XF5tvbdw4kRNd9v-hN2S6U",
  }
]