import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import "./ViewRecruiterInfoPopup.css";

import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import CandidateInterviewSchedulerForm from "../Components/InterviewSchedule/CandidateInterviewSchedulerForm";

interface Props {
  isOpen:boolean;
  handleOnClose: ()=>void;
  type: 'viewSharedAvailability' | 'shareInterviewAvailbility' | string;
  onSuccess: (action: string, message: string) => void;
  selectedRowDetails:any;
  source?: 'nextRoundOfInterview' |  'InterviewRescheduled' | string | ""
}

export const CandidateInterviewPopup: React.FC<Props> = ({
  isOpen,
  handleOnClose,
  type,
  onSuccess,
  selectedRowDetails,
  source=""
}) => {

  const _handleClose = () => {
    handleOnClose();
  };
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={isOpen}
        disableEscapeKeyDown
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "960px", 
            },
          },
        }}
      >
        <DialogTitle sx={{ m: 2, p: 0 }}>
              <IconButton
                aria-label="close"
                onClick={_handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
            }}
          >
            <CandidateInterviewSchedulerForm 
              type={type}
              onSuccess={onSuccess}
              selectedRowDetails={selectedRowDetails}
              source={source}
              handleClose={_handleClose}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default CandidateInterviewPopup;
