import React from "react";
import { Typography } from "@material-ui/core";
import { StafflineRedirectHitCount } from "../../../Services/jobService";
import Link from "@mui/material/Link";

const RedirectStaffline: React.FC = () => {
  const handleRediection = () => {
    StafflineRedirectHitCount()
      .then((response) => {
        if (response?.data?.message?.appStatusCode === "RC_RSF") {
          window.location.href = process.env.REACT_APP_STAFFLINE_URL as string;
        } else {
          console.log("API failed");
        }
      })
      .finally(() => {
        window.location.href = process.env.REACT_APP_STAFFLINE_URL as string;
      });
  };
  return (
    <>
      <Typography>
        If you are a candidate looking for a job, please visit{" "}
        <Link
          target="_blank"
          style={{ textDecoration: "none", cursor: "pointer" }}
          onClick={handleRediection}
        >
          StafflinePro.com.
        </Link>
      </Typography>
    </>
  );
};

export default RedirectStaffline;
