import { SvgIcon } from '@mui/material'
import React from 'react'

export default function Stroked(props) {
  return (
    <SvgIcon viewBox='0 0 35 35' {...props}>
<path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.00976562 0.488281H2.96928H5.86788H16.3029H20.9406H24.9987H27.9582L26.2084 2.87513L17.8029 14.3412V19.7813V20.5556L17.1716 21.0041L12.5339 24.2991L10.1651 25.982V23.0762V14.3412L1.75953 2.87513L0.00976562 0.488281ZM13.1651 15.3503V20.1705L14.8029 19.0069V15.3503H13.1651ZM15.5426 12.3503L22.0392 3.48828H20.9406H16.3029H5.92879L12.4254 12.3503H15.5426Z"
    />    </SvgIcon>
  )
}