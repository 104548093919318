import getLoggedInUserRole from "../Shared/utils/getLoggedInUserRole";
import { UserAuth } from "../models/interfaces";

import { getEarningFile } from "../Services/getPdfReportService";

export const getFileFromUrl = async (url: string, fileName: string, token?:string) => {
  let response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${ token ?? localStorage.getItem("accessToken") ?? ""}`,
    },
  });
  let blobFile = await response.blob();
  return new File([blobFile], fileName);
};

export const getFilePreviewUrl = async (url: string, token?:string) : Promise<string> => {
  return fetch(url+'/PreviewLink',
  {
    method: "GET",
    headers: {
      Authorization: `Bearer ${ token ?? localStorage.getItem("accessToken") ?? ""}`,
    },
  })
  .then(response => response.json() as Promise<{entity: string}>)
  .then(data => data.entity);
};

export const getScoreCardUrl = (otherDoc: any) => {
  if (otherDoc) {
    const ResumeScoreCard = otherDoc.find(
      (n: any) => n.documentType === "ResumeScoreCard"
    );
    if (ResumeScoreCard) return ResumeScoreCard.resourceUrl;
  }
};

export const loadImage = async (url: string, callback: (blobString: string) => any,authToken?:string) => {
  if(url){
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${ authToken ?? localStorage.getItem("accessToken") ?? ""}`,
    },
  });
  const blob = await response.blob();
  callback(URL.createObjectURL(blob));
  }
};

export const downloadFile = async (url: string,fileName:string,authToken?:string) => {
  if (url && fileName) 
  {
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${authToken ?? localStorage.getItem("accessToken") ?? ""}`,
      },
    });
    const blob = await response.blob();
    if(blob){
    downloadURL(URL.createObjectURL(blob), fileName);
    }
  }
};

export const downloadEarningPdf=async (data:any[])=>{
  let earningPdf=await getEarningFile({earnings:data,isForApprovalView:true})
  downloadURL(URL.createObjectURL(earningPdf),"earning.pdf")
}

export const downloadRecruiterEarningPdf=async (data:any[])=>{
  let earningPdf=await getEarningFile({earnings:data,isForApprovalView:false})
  downloadURL(URL.createObjectURL(earningPdf),"earning.pdf")
}

export const downloadURL = (url:string, filename:string) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export const getNevLink = (navLink: string) => {
  let userRole = getLoggedInUserRole();
  if (userRole === UserAuth.Admin || userRole === UserAuth.SuperAdmin) {
    return ensurePrefix("/admin", navLink);
  }
  if (userRole === UserAuth.Client ) {
    return ensurePrefix("/client", navLink);
  }
  if (userRole === UserAuth.ClientTeamMember ) {
    return ensurePrefix("/team", navLink);
  }

  return navLink;
};

function ensurePrefix(prefix : string, navLink : string) : string {
  if ((navLink.startsWith(prefix) || navLink==="/")) return navLink;

  return prefix + navLink;
}