import React from 'react';
import Grid from '@mui/material/Grid';
import LabelChip from '../LabelChip';
import SectionHeading from '../../../../ui/Form/Heading/SectionHeading';
import { Divider } from '@mui/material';

interface ScorecardToolsInfoProps {
  tools: string[];
}

const ScorecardTools: React.FC<ScorecardToolsInfoProps> = ({ tools }) => {
  
  return (
    <Grid container spacing={2}>
        <Grid  item xs={12} >
            <SectionHeading
                title='Tools'
            />
         <Divider className="borderedDivider-color" />
        </Grid>
            
        {tools.map((toolName) => (
            <Grid item key={toolName}>            
            <LabelChip                  
                label={toolName}
            />   
            </Grid>        
            ))}
    </Grid>
  );
};

export default ScorecardTools;
