import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import "./ViewRecruiterInfoPopup.css";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ConversationAddComment } from "../../../../Services/candidateService";
import getLoggedInUserRole from "../../../../Shared/utils/getLoggedInUserRole";
import { UserAuth } from "../../../../models/interfaces";
import getUserDetails from "../../../../Shared/utils/getUserDetails";
import { CustomAlert } from "../../../ui/CustomAlert";
import { ModuleComponent } from "../../../Common/Permission/ModuleComponent";
import { PermissionsEnums } from "../../../../data/permission";
import convertToLocalizedDateTime from "../../../../utils/AppDateUtils";
import TextField from "@mui/material/TextField";
interface Props {
  comments: any;
  openPopup: boolean;
  handleDocumentsPopupClose: any;
  onCommentAddedSuccess: any;
  submission: any;
}
const ariaLabel = { "aria-label": "description" };
export const ConversationHistoryPopup: React.FC<Props> = ({
  comments,
  openPopup,
  handleDocumentsPopupClose,
  onCommentAddedSuccess,
  submission,
}) => {
  const initialState = {
    commentText: "",
  };
  let userRole = getLoggedInUserRole();
  const commentSchema = Yup.object({
    commentText: Yup.string()
    .required("Please enter comment")
    .max(
      1000,
      "Comment must be less than 1000 characters"
    ),
  });
  const formik = useFormik({
    initialValues: initialState,
    validationSchema: commentSchema,
    onSubmit: async (values) => {
      AddComment();
    },
  });

  const handleConfirmationCancel = () => {
    handleDocumentsPopupClose();
  };
  const sortedComments = [...comments].sort(function (a: any, b: any) {
    return (
      new Date(a.createdDate).valueOf() - new Date(b.createdDate).valueOf()
    );
  });
  const AddComment = () => {

    if(formik.values.commentText === ""){
      formik.setFieldTouched("commentText",true);
      formik.setFieldError("commentText","Please enter comment");
      return;
    }
    let payLoad = {
      submissionid: submission.id,
      comment: formik.values.commentText,
      commentHeading:
        userRole === UserAuth.Admin ? "Admin Comment" : "Recruiter Comment",
      createdDate: "",
      userRole: userRole === UserAuth.Admin ? "Admin" : "Recruiter",
      addedBy: getUserDetails().id,
    };

    ConversationAddComment(payLoad).then((response) => {
      if (response?.data?.message?.appStatusCode === "RC_RSF") {        
        onCommentAddedSuccess();
      } else {
        handleConfirmationCancel();
      }
    });
  };

  return (
    <div>
      <Dialog
        className="custom-popup-border"
        open={openPopup}
        onClose={handleDocumentsPopupClose}
        aria-labelledby="viewRecruiterPopup-title"
        aria-describedby="viewRecruiterPopup-description"
      >
        <Paper elevation={5} style={{ backgroundColor: "#FFF" }}>
          <DialogTitle sx={{ m: 0, p: 2 }}>
            <IconButton
              aria-label="close"
              onClick={handleDocumentsPopupClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="viewRecruiterPopup-description">
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Typography
                    align="center"
                    className="custom-conversation-txt"
                    style={{
                      paddingBottom: "15px",
                    }}
                  >
                    Conversation History
                  </Typography>
                </Grid>

                <Box>
                  <Grid container spacing={1} className="recruiterInfoBox">
                    {sortedComments.map((comment: any) => {
                      return (
                        <>
                          <Grid item xs={6} sm={6}>
                            <Typography className="custom-commentheading">
                              {comment?.commentHeading
                                ? comment?.commentHeading
                                : "NA"}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography className="custom-posteddate">
                              Posted on
                              {" " +
                                convertToLocalizedDateTime(
                                  comment?.createdDate,
                                  "MMMM DD, YYYY  |   h:mm A"
                                )}
                            </Typography>
                          </Grid>
                          <Grid className="custom-cmnt-txt" item xs={6} sm={12}>
                            {comment?.comment}
                          </Grid>
                          <Divider
                            sx={{
                              width: "100%",
                              height: "1px",
                              background: "#EAEAEA",
                              marginTop: "20px",
                              marginBottom: "20px",
                              marginLeft: "10px",
                            }}
                          />
                        </>
                      );
                    })}
                  </Grid>
                </Box>
                <ModuleComponent
                  moduleId={PermissionsEnums.Submissions_Comments}
                >
                  <Box>
                    <Grid container spacing={1} className="recruiterInfoBox">
                      <Grid item xs={6} sm={12}>
                        <TextField
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.commentText}
                          name="commentText"
                          placeholder="Add your comment"
                          inputProps={ariaLabel}
                          fullWidth
                          type="text"
                          multiline
                          rows={3}
                          variant="outlined"
                          className="AddCommentTxtArea addCommentTextField"
                          error={formik?.touched?.commentText &&
                            Boolean(formik?.errors?.commentText)}
                          helperText= {formik.touched?.commentText &&
                            formik.errors?.commentText}
                        />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        style={{
                          marginTop: "30px",
                        }}
                        textAlign="right"
                        className="custom-mobile-converstaion"
                      >
                        <Button
                        data-testid="submit"
                          className="custom-submit-btn"
                          sx={{
                            "&.MuiButtonBase-root:hover": {
                              bgcolor: "4540DB !important",
                            },
                            width: "90px",
                          }}
                          variant="contained"
                          style={{
                            textTransform: "capitalize",
                            color: "4540DB",
                          }}
                          onClick={() => {
                            AddComment()
                          }}
                        >
                          Submit
                        </Button>
                        <Grid item xs={6} sm={12}>
                          <CustomAlert
                            plainText={true}
                            message={""}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </ModuleComponent>
              </Grid>
            </DialogContentText>
          </DialogContent>

          <DialogActions></DialogActions>
        </Paper>
      </Dialog>
    </div>
  );
};
export default ConversationHistoryPopup;
