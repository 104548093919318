import { createSvgIcon } from "@mui/material/utils";

export const UploadIcon = createSvgIcon(
  <svg viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.44481 3.8094C8.53609 3.8094 9.42075 4.69406 9.42075 5.78534V11.9778C9.42075 12.084 9.517 12.1709 9.63465 12.1709H11.2389C11.3566 12.1709 11.4528 12.084 11.4528 11.9778V5.79068C11.4528 4.69645 12.3399 3.8094 13.4341 3.8094C13.6133 3.8094 13.7122 3.62348 13.6026 3.49792L10.6079 0.0741139C10.5879 0.0510266 10.5623 0.032355 10.5332 0.0195142C10.504 0.00667334 10.4719 0 10.4395 0C10.407 0 10.375 0.00667334 10.3458 0.0195142C10.3166 0.032355 10.291 0.0510266 10.271 0.0741139L7.27636 3.49551C7.16674 3.62348 7.26567 3.8094 7.44481 3.8094ZM20.2256 11.2534H18.6213C18.5036 11.2534 18.4074 11.3403 18.4074 11.4466C18.4074 13.5002 16.7426 15.165 14.689 15.165H6.18993C4.13633 15.165 2.47155 13.5002 2.47155 11.4466C2.47155 11.3403 2.37529 11.2534 2.25764 11.2534H0.653366C0.535719 11.2534 0.439463 11.3403 0.439463 11.4466V16.2273C0.439463 16.6547 0.821816 17 1.29508 17H19.5838C20.0571 17 20.4395 16.6547 20.4395 16.2273V11.4466C20.4395 11.3403 20.3432 11.2534 20.2256 11.2534Z"
      fill="#4540DB"
    />
  </svg>,
  "UploadIcon"
);
