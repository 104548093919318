import React from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import "./jobdetails.scss";
import moment from "moment";
import getLoggedInUserRole from "../../../Shared/utils/getLoggedInUserRole";
import { UserAuth } from "../../../models/interfaces";
import { ResumeSubmitted as ResumeIcon } from "../../Common/Icons/ActiveSubmission";
import { ArrowDown as ArrowDownIcon } from "../../Common/Icons/Saved";
import { Interviewing as InterviewingIcon } from "../../Common/Icons/InterviewScheduled";
import { StatusEnumList } from "../../Common/SubmissionStatusEnumList";
import convertToLocalizedDateTime from "../../../utils/AppDateUtils";
import SigninForm from "../../Login/SigninForm";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

interface Props {
  jobDetails: any;
  handleCardClick: any;
  savedJobCount: any;
}
const ClientJobInformation: React.FC<Props> = ({
  jobDetails,
  handleCardClick,
  savedJobCount,
}) => {
  let userRole = getLoggedInUserRole();

  function formatRateNegotiable(rateNegotiable: any) : any {
    if (rateNegotiable === "y") return "Yes";
    else if (rateNegotiable === "n") return "No";
    else return rateNegotiable;
  }

  function _formatDate(dateValue: any) : string {
    return (
      dateValue 
      ? convertToLocalizedDateTime(dateValue,"MMMM DD, YYYY,h:mm:ss A") 
      : "N/A"
    );
  }

  function renderClientJobDetails() {
    if (userRole === UserAuth.NotLoggedIn) return <>{""}</>;
    
    return (
      <>
        <Grid item xs={6} sm={6} md={3}>
          <Item elevation={0} className="custom-heading-clientjobdetail">
            Client
          </Item>
          <Item className="custom-txt-clientjobdetails" elevation={0}>
            {jobDetails?.clientName || "N/A"}
          </Item>
        </Grid>
        {userRole == UserAuth.Admin ? (
          <Grid item xs={6} sm={6} md={3}>
            <Item elevation={0} className="custom-heading-clientjobdetail">
              Department
            </Item>
            <Item className="custom-txt-clientjobdetails" elevation={0}>
              {jobDetails?.departmentName || "N/A"}
            </Item>
          </Grid>
        ) : (
          ""
        )}

        <Grid item xs={6} sm={6} md={3}>
          <Item elevation={0} className="custom-heading-clientjobdetail">
            Education Level
          </Item>
          <Item elevation={0} className="custom-txt-clientjobdetails">
            {jobDetails?.education?.length > 0
              ? jobDetails?.education.map((x: any, i: any) => {
                  return (
                    <span key={ x?.degreeName ?? i}>
                      {" "}
                      {i > 0 && ", "} {x.degreeName}
                    </span>
                  );
                })
              : "N/A"}
          </Item>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Item elevation={0} className="custom-heading-clientjobdetail">
            Primary Skill{" "}
          </Item>
          <Item className="custom-txt-clientjobdetails" elevation={0}>
            {jobDetails?.jobPrimarySkill || "N/A"}
          </Item>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Item elevation={0} className="custom-heading-clientjobdetail">
            Hours per Week{" "}
          </Item>
          <Item className="custom-txt-clientjobdetails" elevation={0}>
            {jobDetails?.requiredHoursPerWeek || "N/A"}
          </Item>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Item elevation={0} className="custom-heading-clientjobdetail">
            Job Start Date{" "}
          </Item>
          <Item className="custom-txt-clientjobdetails" elevation={0}>
            {moment.utc(jobDetails?.jobStartDate).format("LL")}
          </Item>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Item elevation={0} className="custom-heading-clientjobdetail">
            Certifications & Licenses, if any{" "}
          </Item>

          <Item className="custom-txt-clientjobdetails" elevation={0}>
            {jobDetails?.manualData?.certificationsAndLicenses?.length > 0
              ? jobDetails?.manualData?.certificationsAndLicenses?.map(
                  (x: any, i: any) => {
                    return (
                      <span key={ x?.certificationsLicenses ?? i}>
                        {" "}
                        {x?.certificationsLicenses}
                        <br />
                      </span>
                    );
                  }
                )
              : "N/A"}
          </Item>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Item elevation={0} className="custom-heading-clientjobdetail">
            Tax Terms{" "}
          </Item>
          <Item className="custom-txt-clientjobdetails" elevation={0}>
            {jobDetails?.taxTerms || "N/A"}
          </Item>
        </Grid>
      </>
    );
  }

  return (
    <div>
      {" "}
      <Grid
        container
        spacing={3}
        className="job-details-info-tbl custom-border-bottom"
      >
        <Grid item xs={6} sm={6} md={3}>
          <Item elevation={0} className="custom-heading-clientjobdetail">
            Industry
          </Item>{" "}
          <Item className="custom-txt-clientjobdetails breakword" elevation={0}>
            {jobDetails?.industry || "N/A"}
          </Item>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Item elevation={0} className="custom-heading-clientjobdetail">
            Domain
          </Item>
          <Item className="custom-txt-clientjobdetails" elevation={0}>
            {jobDetails?.domain || "N/A"}
          </Item>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Item elevation={0} className="custom-heading-clientjobdetail">
            Assignment Type
          </Item>
          <Item className="custom-txt-clientjobdetails" elevation={0}>
            {jobDetails?.jobAssignmentType || "N/A"}
          </Item>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Item elevation={0} className="custom-heading-clientjobdetail">
            Rate Negotiable
          </Item>
          <Item className="custom-txt-clientjobdetails" elevation={0}>
            {formatRateNegotiable(jobDetails?.rateNegotiable)}
          </Item>
        </Grid>
        
        {renderClientJobDetails()}

        {userRole === UserAuth.Client ||
        userRole === UserAuth.ClientTeamMember ? (
          <>
            <Grid item xs={6} sm={6} md={3}></Grid>
            <Grid item xs={6} sm={6} md={3}>
              <Item elevation={0} className="custom-heading-clientjobdetail">
                Published By{" "}
              </Item>
              <Item className="custom-txt-clientjobdetails" elevation={0}>
                {jobDetails?.publishedByName || "N/A"}{" "}
              </Item>
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              <Item elevation={0} className="custom-heading-clientjobdetail">
                Published Date{" "}
              </Item>
              <Item className="custom-txt-clientjobdetails" elevation={0}>
                {_formatDate(jobDetails?.postingDate)}
              </Item>
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              <Item elevation={0} className="custom-heading-clientjobdetail">
                Modified By{" "}
              </Item>
              <Item className="custom-txt-clientjobdetails" elevation={0}>
                {jobDetails?.modifiedByName || "N/A"}{" "}
              </Item>
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              <Item elevation={0} className="custom-heading-clientjobdetail">
                Modified Date{" "}
              </Item>
              <Item className="custom-txt-clientjobdetails" elevation={0}>
                {_formatDate(jobDetails?.modifiedOn)}
              </Item>
            </Grid>
          </>
        ) : (
          ""
        )}
      </Grid>
      {userRole === UserAuth.NotLoggedIn ? (
        ""
      ) : (
        <>
          <Typography
            variant="body1"
            component="div"
            className="custom-workauth-heading"
          >
            Skills
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            sx={{ marginBottom: "35px" }}
            flexWrap={"wrap"}
          >
            {jobDetails?.manualData?.skills &&
            jobDetails?.manualData?.skills.length > 0 ? (
              jobDetails?.manualData?.skills.map((x: any, i: any) => {
                return <Chip key={x?.skill ?? i} label={x.skill} className="custom-chip" />;
              })
            ) : (
              <>{"N/A"} </>
            )}
          </Stack>
          <div className="custom-padding-bottom">
            <Typography className="custom-workauth-heading">
              Work Authorization
            </Typography>
            <Stack direction="row" spacing={2} flexWrap={"wrap"}>
              {jobDetails?.jobWorkAuthorization?.map((x: any, i: any) => {
                return <Chip className="custom-chip" key={ x ?? i} label={x} />;
              })}
            </Stack>
          </div>
        </>
      )}
      {getLoggedInUserRole() === UserAuth.Admin ||
      getLoggedInUserRole() === UserAuth.SuperAdmin ? (
        <>
          <Typography className="custom-workauth-heading custom-activity-paddingtop">
            Activity on this Job
          </Typography>

          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            flexWrap={"wrap"}
            sx={{
              marginBottom: { xs: "10px", sm: "10px", md: "35px" },
              paddingTop: "1rem",
            }}
            className="activity-chips"
          >
            <Chip
              className="custom-txt-clientjobdetails"
              icon={<ResumeIcon />}
              label={`Active Submissions : ${jobDetails?.submissionCount}`}
              variant="outlined"
              onClick={() => handleCardClick([])}
              data-testid={"active_sumissions"}
            />
            <Chip
              className="custom-txt-clientjobdetails"
              icon={<ArrowDownIcon />}
              label={`Saved by : ${savedJobCount}`}
              variant="outlined"
            />
            <Chip
              className="custom-txt-clientjobdetails"
              icon={<InterviewingIcon />}
              label={`Interviews Scheduled : ${jobDetails?.interviewingCount}`}
              variant="outlined"
              onClick={() =>
                handleCardClick([StatusEnumList?.candidate_shortlisted?.id])
              }
              data-testid={"interview_scheduled"}
            />
          </Stack>
        </>
      ) : null}
      {userRole === UserAuth.NotLoggedIn ? (
        <div
          style={{
            gridRowStart: 1,
            gridColumnStart: 1,
            zIndex: 20,
          }}
        >
          <div>
            <Typography
              align="center"
              variant="h5"
              fontWeight={"bold"}
              color="black"
              className="helpTextHeadingLogin"
            >
              To know more, Sign in with us now and start applying with
              unlimited jobs to start earning commission per submission
            </Typography>
          </div>
          <div style={{ textAlign: "-webkit-center" }}>
            <Grid item xs={12} md={8} className="login-right-section" pl={0}>
              <Item
                elevation={0}
                sx={{
                  textAlign: "left",
                  paddingRight: { xs: "0 ", md: "40px" },
                }}
                className="login-banner-right"
              >
                {jobDetails.id ? (
                  <SigninForm
                    formType="secondary"
                    redirectUrl={
                      "/jobdetail/" + jobDetails.id + "/jobs?redirect=url"
                    }
                  />
                ) : (
                  ""
                )}
              </Item>
            </Grid>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default ClientJobInformation;
