import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRecruiterDashboard } from "../../Services/recruiterService";


export const adminDashboardCount = createAsyncThunk(
  'getAdminDashboard',
  async (id, thunkAPI) => {
   
    let userId = JSON.parse(localStorage.getItem('user'))?.userId;
    if(userId){

    return getRecruiterDashboard(userId).then(response => response.data)
    .catch(error => thunkAPI.rejectWithValue(error?.response?.data || error))
    }
  }
)


export const adminDashboardState = createSlice({
  name: "adminDashboard",
  initialState: {
    dashboardCount: null,
    error: null,
    success: null,
    loading : false
  },
  reducers: {
    
    clear: (state) => {
      
      state.dashboardCount= null
      state.error= null
      state.success= null
      state.loading = false
    },
   
   
  },
  extraReducers: (builder) => {
    //Post candidate
    builder.addCase(adminDashboardCount.pending, (state, action) => {
      state.loading = true;
    })
     
   
    builder.addCase(adminDashboardCount.fulfilled, (state, action) => {
    
     
      state.dashboardCount = action.payload
      state.error=null
      state.success=true
      state.loading = false

    })
    builder.addCase(adminDashboardCount.rejected, (state, action) => {
      console.log(action);  
      state.error = action.payload?.message? action.payload.message : action.error.message
      state.loading = false
    })

  },
});

// Action creators are generated for each case reducer function
export const {clear } =
  adminDashboardState.actions;

export default adminDashboardState.reducer;