import React from "react";
import Grid from "@mui/material/Grid";
import LabelChip from "../LabelChip";
import SectionHeading from "../../../../ui/Form/Heading/SectionHeading";

interface ScorecardCandidateInfoProps {
  skills:string[];
  className?: any;
  type?:'matching' | 'missing'
}

const KeywordsSkills: React.FC<ScorecardCandidateInfoProps> = ({
  skills,
  className="",
  type
}) => {  
  return (
    <Grid container spacing={2} className="keyword-margin">
      <Grid item xs={12}>
        <SectionHeading title="Skills" />
      </Grid>

      {skills?.length> 0 && skills?.map((skillName) => (
        <Grid item key={skillName} >
          <LabelChip label={skillName} className={className} />
        </Grid>
      ))}
    </Grid>
  );
};

export default KeywordsSkills;
