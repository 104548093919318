import { CircularProgress, CircularProgressProps, Typography } from "@mui/material";
import { Box } from "@mui/system";

export function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number, size?: string ,textFontSize?: string}
  ) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress size={props?.size??"53px"} variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
          style={{fontSize:props?.textFontSize??"15px"}}
            variant="h6"
            component="div"
            color="text.secondary"
          >{`${Math?.round(props?.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }
  