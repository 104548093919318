import { ResourceInfo } from "./ResourceInfo";

export class IndiaPaymentDetail {
  userId: string;
  email: string;
  name: string;
  billingInfoType: string;
  country: string;
  isGstRegistered: string;
  gstNumber: string;
  gstAccountHolderName : string;
  accountHolderName: string;
  ifcsCode: string;
  bankName: string;
  bankAccountNumber: string;
  confirmBankAccountNumber: string;
  accountType: string;
  accountNickName: string;
  panCardNumber : string;

  resourceInfo: ResourceInfo;
}
export class USAPaymentDetail {
  userId: string;
  email: string;
  name: string;
  billingInfoType: string;
  country: string;
  routingNumber: string;
  confirmRoutingNumber: string;
  accountHolderName: string;
  bankName: string;
  bankAccountNumber: string;
  confirmBankAccountNumber: string;
  location: {
    address: string;
    city: string;
    state: string;
    zip: string;
    country : string;
  };
  isRoutingNumberConfirm: boolean;
  isW9NameConfirm: boolean;
  resourceInfo: ResourceInfo;
  isUpdateDetails: boolean;
}
export class PayoneerPaymentDetail {
  userId: string;
  email: string;
  billingInfoType: string;
  isUpdateDetails: boolean;

  
  
}
export enum BillingType {
  Payoneer = "Payoneer",
  USA = "USA",
  Other = "Other",
}
export enum AccountType {
  Current = "Current A/C",
  Saving = "Saving A/C",
  Other = "Other",
}
