import React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

type BannerTextProps = {
  bannerText?: string;
  subBannerText?: string;
  bannerType?: "primary" | "secondary";
};

const BannerText: React.FC<BannerTextProps> = (props) => {
  return (
    <>
      {props.bannerType && props.bannerType === "primary" ? (
        <>
          <Container
            maxWidth="xl"
            className="login-banner-text"
            sx={{
              textAlign: "center",
              padding: "30px 20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h1"
              color="inherit"
              component="h1"
              sx={{
                paddingBottom: "20px",
                fontWeight: "700",
                fontFamily: "Roboto",
                fontSize: { xs: 300, md: "3.75rem" },
              }}
            >
              <b>
                {props.bannerText ?? "Your One Stop Recruitment Marketplace"}
              </b>
            </Typography>
            <Typography
              variant="h4"
              color="inherit"
              component="span"
              sx={{
                maxWidth: "800px",
                alignSelf: "center",
                fontWeight: "600",
                fontSize: { xs: 20, md: "25px" },
                color: "#000000",
              }}
            >
              {props.subBannerText ??
                "Thousands of jobs, Hundreds of Recruiters with access to Several Thousands of Candidates"}
            </Typography>
          </Container>
        </>
      ) : (
        <Container
          maxWidth="xl"
          className="login-banner-text"
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h2"
            component="h1"
            className="custom-login-banner-txt"
            sx={{
              textAlign: "center",
            }}
          >
            {props.bannerText}
          </Typography>
        </Container>
      )}
    </>
  );
};
export default BannerText;
