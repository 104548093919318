import axios from './CommonHttpRequest';

let invoiceManagerAPI = `${process.env.REACT_APP_INVOICEMANAGER_API}`;

export const GetIndiaPaymentDetail = async (id:string) => {
    return axios.get(`${invoiceManagerAPI}api/IndianBilling/${id}`);
}
export const GetUSPaymentDetail = async (id:string) => {
    return axios.get(`${invoiceManagerAPI}api/USABilling/${id}`,);
}
export const GetPayoneerPaymentDetail = async (id:string) => {
    return axios.get(`${invoiceManagerAPI}api/PayoneerBilling/${id}`,);
}
export const GetBankDetailsByIfscCode = async (ifscCode:string) => {
    return axios.get(`${invoiceManagerAPI}api/IndianBilling/GetBankDetailsByIfscCode/${ifscCode}`,);
}
export const GetBankDetailsByRoutingNumber = async (routingNumber:string) => {
    return axios.get(`${invoiceManagerAPI}api/USABilling/GetBankDetailsByRoutingNumber/${routingNumber}`,);
}
export const GetGSTDetails = async (gstNumber:string) => {
    return axios.get(`${invoiceManagerAPI}api/IndianBilling/GetGSTDetails/${gstNumber}`,);
}
export const SetCompensationPercent = async (body:any) => {
    return axios.post(`${invoiceManagerAPI}api/Compensation/SetCompensationPercentage`,body);
}
export const GetEffectiveCompensation = async (body :any) => {
    
    return axios.post(`${invoiceManagerAPI}api/Compensation/GetEffectiveCompensation`,body);
}
export const GetChangeTracker = async (body :any) => {
    
    return axios.post(`${invoiceManagerAPI}api/Compensation/ChangeTracker`,body);
}
export const GetRecruiterCompensation = async (body :any) => {
    
    return axios.post(`${invoiceManagerAPI}api/Compensation/RecruitersCompensation`,body);
}
export const GetRecruiterEarnings = async (body :any) => {
    
    return axios.post(`${invoiceManagerAPI}api/RecruiterEarnings/Filter`,body);
}

export const GetRecruiterEarningsDetails = async (id :any) => {
    return axios.get(`${invoiceManagerAPI}api/RecruiterEarnings/${id}`,);
}

export const UpdateApproveEarning = async (body :any) => {
    return axios.post(`${invoiceManagerAPI}api/RecruiterEarnings/Approve`,body);
}

export const GetFilterConsolidatedEarnings = async (body :any) => {
    
    return axios.post(`${invoiceManagerAPI}api/RecruiterEarnings/FilterConsolidatedEarnings`,body);
}

export const UpdateEarningsDeatils = async (body :any) => {
    
    return axios.put(`${invoiceManagerAPI}api/RecruiterEarnings/Earnings`,body);
}

export const GetClientPaymentChangeTrackerData = async (body :any) => {
    
    return axios.post(`${invoiceManagerAPI}api/client-payment-setting/filter`,body);
}
