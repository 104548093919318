import { formatPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';

function formatPhone(num: string): string {
    if (!num) return num;
    if (num.charAt(0) !== "+") {
        num = `+${num}`;
    }
    let phoneNumber = parsePhoneNumber(num);
    if (!phoneNumber) {
        return num;
    }
    let callingCode =  phoneNumber?.countryCallingCode
    let formatted =  formatPhoneNumber(num)
    if(callingCode=="91")
    {
        formatted =  formatted.charAt(0)==="0"? formatted.slice(1) : formatted;
    }
   
    return `+${callingCode} ${formatted}`;
}

export function hasCountryCode(num: string) : boolean
{
    if (num===undefined || num===null || num==='') return false;
    // num expected to not start with +, so adding here
    num = `+${num}`
    let phoneNumber =  parsePhoneNumber(num)
    let callingCode =  phoneNumber?.countryCallingCode
    return callingCode !== undefined;
}

export default formatPhone;
