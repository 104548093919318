import {
  Button,
  Card,
  ClickAwayListener,
  FormControlLabel,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React, { FC, useEffect, useState } from "react";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import DataSaverOffRoundedIcon from "@mui/icons-material/DataSaverOffRounded";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import Popper from "@mui/material/Popper";
import TableContainer from "@mui/material/TableContainer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { adminFilter } from "../../Services/adminService";
import { PermissionsEnums } from "../../data/permission";
import {
  clearAdminStatusFilter,
  handleAdminSearchKeyword,
  handleAdminStatusArrayChange,
} from "../../features/AdminListing/adminListingSlice";
import { AdminUserModel } from "../../models/AdminUserModel";
import { BreadCrumbItem } from "../../models/AppModels";
import {
  AdminFilter,
  AdminFilterDefault,
} from "../../models/admin/AdminFilterModel";
import { AppState } from "../../store/AppState";
import formatAmount from "../../utils/formatAmount";
import { getNevLink } from "../../utils/getFileFromUrl";
import AppBreadCrumb from "../Common/AppBreadCrumb";
import { CheckBoxSquareIcon } from "../Common/CheckBoxSquareIcon";
import { ModuleComponent } from "../Common/Permission/ModuleComponent";
import SnackbarPopup from "../Common/Popup/snackbar/SnackbarPopup";
import { GotoTop } from "../ui/GotoTop";
import { Searchbar } from "../ui/Searchbar";
import AdminListingTable from "./Components/AdminListingTable";
import PleaseWait from "../Common/Loder/PleaseWait";
import NoRecordsFound from "../Common/Table/NoRecordsFound";

export const Admins: FC = () => {
  let breadcrumb: BreadCrumbItem[] = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
    {
      isLeaf: true,
      sequence: 2,
      title: "Admins",
      to: "/",
      leafClasses: " v2LastLeft",
    },
  ];

  const navigate = useNavigate();

  const { searchKeywords, status } = useSelector(
    (state: AppState) => state.adminListingReducer
  );

  const dispatch = useDispatch();
  const [anchorEl3, setAnchorEl3] = useState(null);
  const [data, setData] = useState<AdminUserModel[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [search, setSearch] = useState(searchKeywords);

  const [pageStatus, setPageStatus] = useState({
    isSuccess: false,
    error: "",
    loading: false,
    data: "",
    key: Date.now(),
  });

  const [loading, setLoading] = useState(false);
  const [paging, setPaging] = useState<AdminFilter>({
    ...AdminFilterDefault,
    searchKeywords: searchKeywords,
  });

  const onSearchKeywordChange = () => {
    dispatch(handleAdminSearchKeyword(search ?? ""));
    setPaging({ ...paging, searchKeywords: search, pageIndex: 1 });
  };

  const ClearAllGlobalSearch = () => {
    dispatch(handleAdminSearchKeyword(""));
    setPaging({ ...paging, searchKeywords: "", isActive: null, pageIndex: 1 });
    dispatch(clearAdminStatusFilter());
    setSearch("");
  };

  const open3 = Boolean(anchorEl3);

  const lockScroll = (lock: any) => {
    const html = document.querySelector("html");
    if (html) {
      html.style.overflow = lock ? "hidden" : "auto";
    }
  };

  const openStatus = (event: any) => {
    lockScroll(true);
    setAnchorEl3(event.currentTarget);
  };

  const closeStatus = () => {
    setAnchorEl3(null);
    lockScroll(false);
  };

  const ClearAllActiveStatus = () => {
    dispatch(clearAdminStatusFilter());
    setPaging({ ...paging, isActive: null, pageIndex: 1 });
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const name = e.target.name;
    dispatch(
      handleAdminStatusArrayChange({ isChecked: isChecked, name: name })
    );
  };

  const _GetAdminUserList = () => {
    setLoading(true);
    setData([]);
    adminFilter(paging)
      .then((response: any) => {       
        if (response?.data?.message?.appStatusCode === "IP_RSF") {
          setTotalRecords(response?.data?.totalRecords ?? 0);
          setData(response.data?.entityList ?? []);
        } else {
          setTotalRecords(0);
          setData([]);
        }
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (status?.length === 1) {
      if (status?.length > 0 && status[0] === "Active") {
        setPaging({ ...paging, isActive: true });
      } else if (status?.length > 0 && status[0] === "Inactive") {
        setPaging({ ...paging, isActive: false });
      } else {
        setPaging({ ...paging, isActive: null });
      }
    } else {
      setPaging({ ...paging, isActive: null });
    }
  }, [status]);

  useEffect(() => {
    if (paging.pageIndex === 1) {
      _GetAdminUserList();
    }
  }, [paging.searchKeywords, paging.isActive, paging.pageIndex]);

  useEffect(() => {
    if (paging.pageIndex > 1) {
      _GetAdminUserList();
    }
  }, [paging.pageIndex]);


  const _handlePaginationIndex = (index:number) => {
    setPaging({
      ...paging,
      pageIndex: index,
    })    
  };

  const haveAnyFilter = () => {
    return paging.searchKeywords !== "" || status.length !== 0;
  };

  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const searchBarWidth = isSmallScreen ? "310px" : "383px";

  const _handleSuccessClose = () => {
    setPageStatus({
      ...pageStatus,
      isSuccess: false,
      data: "",
      loading: false,
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AppBreadCrumb
          classes="custom-appbreadcrumb"
          BreadCrumbItems={breadcrumb}
        ></AppBreadCrumb>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={0}>
          <Grid mb={2} item xs={12} md={3}>
            <Typography
              variant="h6"
              component="div"
              className="listing-total-heading"
              gutterBottom
            >
              Total Admins ({formatAmount(totalRecords)})
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} className="adminListingSearchbar">
            <Box className="AdminListSearchbarWrapper">
              <Searchbar
                classes="ClientListingSearchbarContainer"
                isNewSeachBar={true}
                width={searchBarWidth}
                height="1.7rem"
                onEnter={onSearchKeywordChange}
                onIconClick={onSearchKeywordChange}
                placeHolderText="Search"
                value={search}
                onChange={(e: any) => {
                  setSearch(e.target.value);
                }}
                txtFontSize={"14px"}
              />
            </Box>
            <Button
              component="div"
              variant="text"
              className="v2-base-btn cust-btn-bgClr searchbarRow-ht-btn toolbarActionBtn"
              style={{
                backgroundColor: "#F6F7FC",
              }}
              startIcon={<DataSaverOffRoundedIcon />}
              id="demo-positioned-button2"
              aria-haspopup="true"
              onClick={openStatus}
            >
              Status
            </Button>
            <Popper id={"id"} open={open3} anchorEl={anchorEl3}>
              <ClickAwayListener onClickAway={closeStatus}>
                <Card>
                  <Box
                    sx={{
                      flexGrow: 1,
                      bgcolor: "#FAFAFA",
                      display: "flex",
                    }}
                  >
                    <Grid container p={2}>
                      <Grid item md={6}>
                        <Typography fontWeight={"bold"} className="roboto-font">
                          Status
                        </Typography>
                      </Grid>
                      <Grid item xs={6} className="closeArea">
                        <IconButton
                          aria-label="close"
                          size="small"
                          className="close-Btn"
                          data-testid="CloseFilterBtn"
                          onClick={closeStatus}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    sx={{
                      flexGrow: 1,
                      bgcolor: "white",
                      display: "flex",
                      maxHeight: 386,
                      overflowY: "auto",
                    }}
                  >
                    <Grid container style={{ width: 223 }} p={2}>
                      <Grid item xs={12} md={12}>
                        <Grid container>
                          <Grid item pt={1} pb={0.5}>
                            <FormControlLabel
                              data-testid="clearAllBtn"
                              onClick={ClearAllActiveStatus}
                              control={
                                <CheckBoxSquareIcon
                                  style={{ paddingRight: "5px" }}
                                ></CheckBoxSquareIcon>
                              }
                              label={
                                <Typography
                                  fontSize={14}
                                  className="roboto-font"
                                >
                                  Clear All
                                </Typography>
                              }
                              className="candidates-label"
                              style={{
                                color: "red",
                                fontWeight: "bold",
                                paddingLeft: 0,
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{ maxHeight: 300, overflowY: "auto" }}
                      >
                        <MenuList style={{ paddingTop: "0px" }}>
                          {["Active", "Inactive"].map((x: string) => {
                            return (
                              <MenuItem
                                key={x}
                                sx={{
                                  paddingLeft: "10px",
                                  paddingBottom: "0px",
                                  minHeight: "auto",
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name={x}
                                      checked={status.includes(x)}
                                      onChange={(e) => {
                                        setPaging({ ...paging, pageIndex: 1 });
                                        handleStatusChange(e);
                                      }}
                                      sx={{ padding: "4px 12px 4px 0" }}
                                    />
                                  }
                                  label={x}
                                />
                              </MenuItem>
                            );
                          })}
                        </MenuList>
                      </Grid>
                    </Grid>
                  </Box>
                </Card>
              </ClickAwayListener>
            </Popper>
            <ModuleComponent moduleId={PermissionsEnums.Admins_Add}>
              <Button
                variant="contained"
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => navigate(getNevLink("/admin/user/add"))}
                className="custPrimary searchbarRow-ht-btn toolbarActionBtn"
              >
                Add Admin
              </Button>
            </ModuleComponent>

            <Button
              data-testid="text"
              variant="text"
              size="small"
              disabled={!haveAnyFilter()}
              className={`toolbarActionBtn ${
                haveAnyFilter()
                  ? "custom-Btn-fontsizes"
                  : "disable-custom-Btn-fontsizes"
              } cust-btn-bgClr searchbarRow-ht-btn clear-cust-wrap`}
              startIcon={<ClearIcon />}
              onClick={() => {
                ClearAllGlobalSearch();
              }}
            >
              Clear
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12} md={12}>
            <TableContainer component={Paper} className="custom-table-compnent">
              <AdminListingTable
                data={data}
                paging={paging}
                setPaging={setPaging}
                pageStatus={pageStatus}
                setPageStatus={setPageStatus}
                GetAdminUserList={_GetAdminUserList}
                totalRecordCount={totalRecords}
                handlePaginationIndex={_handlePaginationIndex}
              />
            </TableContainer>
            {loading && <PleaseWait /> }
            {!loading && data?.length === 0 &&  <NoRecordsFound />}
          </Grid>
        </Grid>        
        {pageStatus.isSuccess && (
          <SnackbarPopup
            open={pageStatus?.isSuccess}
            message={pageStatus?.data}
            onClose={_handleSuccessClose}
          />
        )}
        <GotoTop />
      </Grid>
    </Grid>
  );
};
