import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import "./ViewRecruiterInfoPopup.css";
import formatPhone from "../../../../Shared/utils/formatPhone";
import { IconButton, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import message from "../../../../assets/images/Mail.svg";
import Telephone from "../../../../assets/images/TelephoneIcon.svg";
import location from "../../../../assets/images/Location.svg";
import User from "../../../../assets/images/User.svg";
import truncateString from "../../../../utils/truncateString";
import { isVarNotEmpty } from "../../../../utils/StringUtil";
import getLoggedInUserRole from "../../../../Shared/utils/getLoggedInUserRole";
import { UserAuth } from "../../../../models/interfaces";
import { Fullname } from "../../../Common/Fullname";
import "./Popup.scss";
import { ternary } from "../../../../utils/complexityUtils";

interface Props {
  contactData: any;
  openPopup: boolean;
  handleDocumentsPopupClose: any;
  onlyPartnerInfo: boolean;
  recruitersGroupInfo: any;
  isClientJob: boolean;
}
export const ContactInformationPopup: React.FC<Props> = ({
  openPopup,
  handleDocumentsPopupClose,
  contactData,
  onlyPartnerInfo,
  recruitersGroupInfo,
  isClientJob,
}) => {
  const userType = getLoggedInUserRole();
  const recruiterPhone = formatPhone(contactData?.accountManager?.recruiterWorkPhoneNo);
  const accountManagerPhone = formatPhone(contactData?.accountManager?.salesPersonWorkPhoneNo);

  const showCurrentEmployer = () => {
    const employerDetails = contactData?.employerDetails;
    return (
      employerDetails?.name ||
      employerDetails?.phoneNumber ||
      employerDetails?.email ||
      employerDetails?.reference
    );
  };

  const renderRecruitersGroupInfo = () => {
    if (!isClientJob && (userType === UserAuth.Client || userType === UserAuth.ClientTeamMember)) {
      return null;
    }

    if (recruitersGroupInfo) {
      return (
        <Grid container spacing={1.0} className="recruiterInfoBox">
          <Grid item xs={12}>
            <Typography className="custom-recruiterbox">Recruiters Group</Typography>
          </Grid>
          <Grid item xs={0.6} sm={0.6}>
            <img src={User} className="user-icon" alt="User icon" />
          </Grid>
          <Grid item xs={11} sm={11}>
            <Typography className="custom-content">{recruitersGroupInfo !== null && recruitersGroupInfo !== undefined ? recruitersGroupInfo : "N/A"}</Typography>
          </Grid>
        </Grid>
      );
    }

    return null;
  };

  function renderEmployerName() {
    return (
      <>
        {contactData?.employerDetails?.name.length > 0 ? 
          (
            <>
              <Grid item xs={0.6} sm={0.6}>
                <img src={User} className="user-icon" alt="User icon" />
              </Grid>
              <Grid item xs={2} sm={3}>
                <Typography className="custom-content">
                  <Fullname
                    fullName={
                      contactData?.employerDetails?.name ||
                      "N/A"
                    }
                  />
                </Typography>
              </Grid>
            </>
          ) : 
          (
            ""
          )
        }
      </>
    );
  }

  function formatAddressSection(section: string): string {
    return (section ?? "") + ternary(isVarNotEmpty(section), ", ", "");
  }

  function addressEmptyDefault() : string {
    return ternary(
      (
        !isVarNotEmpty(
          contactData?.employerDetails?.address
            ?.streetAddress
        ) &&
        !isVarNotEmpty(
          contactData?.employerDetails?.address
            ?.city
        ) &&
        !isVarNotEmpty(
          contactData?.employerDetails?.address
            ?.state
        ) &&
        !isVarNotEmpty(
          contactData?.employerDetails?.address
            ?.countryCode
        ) &&
        !isVarNotEmpty(
          contactData?.employerDetails?.address
            ?.postalCode
        )
      ),
      "N/A",
      ""
    );
  }

  function renderEmployerAddress() {
    return (
      <>
        {contactData?.employerDetails?.address
          ?.streetAddress.length > 0 ? (
          <>
            {" "}
            <Grid item xs={0.6} sm={0.8}>
              <img
                src={location}
                className="message-icon"
                alt="Location icon"
              />
            </Grid>
            <Grid
              item
              xs={8}
              sm={7.6}
              style={{ paddingLeft: 0 }}
            >
              <Typography className="custom-content">
                {
                  formatAddressSection(contactData?.employerDetails?.address?.streetAddress) +
                  formatAddressSection(contactData?.employerDetails?.address?.city) +
                  formatAddressSection(contactData?.employerDetails?.address?.state) +
                  formatAddressSection(contactData?.employerDetails?.address?.countryCode) +
                  (contactData?.employerDetails?.address
                    ?.postalCode ?? "")
                }
                {addressEmptyDefault()}
              </Typography>
            </Grid>
          </>
        ) : (
          ""
        )}
      </>
    );
  }

  return (
    <div>
      <Dialog
        className="custom-popup-border"
        open={openPopup}
        onClose={handleDocumentsPopupClose}
        aria-labelledby="viewRecruiterPopup-title"
        aria-describedby="viewRecruiterPopup-description"
      >
        <Paper elevation={5} style={{ backgroundColor: "#FFF" }}>
          <DialogTitle sx={{ m: 0, p: 2 }}>
            <IconButton
              aria-label="close"
              onClick={handleDocumentsPopupClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="viewRecruiterPopup-description">
              <Grid container spacing={1}>
                {!onlyPartnerInfo && (
                  <>
                    <Grid item xs={12}>
                      <Typography align="center" className="custom-contact-txt">
                        Contact Information
                      </Typography>
                    </Grid>

                    <Box>
                      <Grid container spacing={1} className="recruiterInfoBox">
                        <Grid item xs={12} sm={12}>
                          <Typography className="custom-recruiterbox">
                            Candidate
                          </Typography>
                        </Grid>
                        <Grid item xs={0.6} sm={0.6}>
                          <img src={User} className="user-icon" alt="User icon" />
                        </Grid>
                        <Grid item xs={2} sm={3}>
                          <Typography className="custom-content">
                            <Fullname fullName={contactData?.candidate?.name} />
                          </Typography>
                        </Grid>

                        <Grid item xs={0.6} sm={0.6}>
                          <img src={message} className="message-icon" alt="Message icon" />
                        </Grid>
                        <Grid item xs={4} sm={3.4}>
                          <Tooltip title={contactData?.candidate?.email}>
                            <Typography className="custom-content">
                              {truncateString(
                                contactData?.candidate?.email,
                                16
                              )}
                            </Typography>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={0.6} sm={0.6}>
                          <img src={Telephone} className="telephone-icon" alt="Telephone icon" />
                        </Grid>
                        <Grid item xs={4} sm={3.4}>
                          <Typography className="custom-content">
                            {formatPhone(contactData?.candidate?.phone)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>

                    {showCurrentEmployer() && (
                      <>
                        <Box>
                          <Grid
                            container
                            spacing={1.0}
                            className="recruiterInfoBox"
                          >
                            <Grid item xs={12}>
                              <Typography className="custom-recruiterbox">
                                Current Employer
                              </Typography>
                            </Grid>
                            {renderEmployerName()}
                            {renderEmployerAddress()}
                          </Grid>
                        </Box>
                        <Box
                          className={
                            userType === UserAuth.Recruiter
                              ? "withRecruiterInfo"
                              : ""
                          }
                        >
                          <Grid
                            container
                            spacing={1.0}
                            className="recruiterInfoBox"
                          >
                            {contactData?.employerDetails?.reference.length >
                            0 ? (
                              <>
                                {" "}
                                <Grid item xs={0.6} sm={0.6}>
                                  <img src={User} className="user-icon" alt="User icon" />
                                </Grid>
                                <Grid item xs={2} sm={3}>
                                  <Typography className="custom-content">
                                    {contactData?.employerDetails?.reference ||
                                      "N/A"}
                                  </Typography>
                                </Grid>
                              </>
                            ) : (
                              ""
                            )}
                            {contactData?.employerDetails?.email.length > 0 ? (
                              <>
                                <Grid item xs={0.8} sm={0.8}>
                                  <img
                                    src={message}
                                    className="message-icon"
                                    alt="Message icon"
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  sm={3.4}
                                  style={{ paddingLeft: 0 }}
                                >
                                  <Tooltip
                                    title={contactData?.employerDetails?.email}
                                  >
                                    <Typography className="custom-content">
                                      {truncateString(
                                        contactData?.employerDetails?.email ||
                                          "N/A",
                                        20
                                      )}
                                    </Typography>
                                  </Tooltip>
                                </Grid>
                              </>
                            ) : (
                              ""
                            )}
                            {contactData?.employerDetails?.phoneNumber.length >
                            0 ? (
                              <>
                                {" "}
                                <Grid item xs={0.6} sm={0.6}>
                                  <img
                                    src={Telephone}
                                    className="telephone-icon"
                                    alt="Telephone icon"
                                  />
                                </Grid>
                                <Grid item xs={4} sm={3.4}>
                                  <Typography className="custom-content">
                                    {formatPhone(
                                      contactData?.employerDetails?.phoneNumber
                                    ) || "N/A"}
                                  </Typography>
                                </Grid>{" "}
                              </>
                            ) : (
                              ""
                            )}
                          </Grid>
                        </Box>
                      </>
                    )}
                    {userType !== UserAuth.Recruiter && (
                      <Box style={{ paddingBottom: "18px" }}>
                        <Grid
                          container
                          spacing={1.0}
                          className="recruiterInfoBox"
                        >
                          <Grid item xs={12}>
                            <Typography className="custom-recruiterbox">
                              JobRialto Recruiter
                            </Typography>
                          </Grid>
                          <Grid item xs={0.6} sm={0.6}>
                            <img src={User} className="user-icon" alt="User icon" />
                          </Grid>
                          <Grid item xs={2} sm={3}>
                            <Tooltip title={contactData?.recruiter?.name}>
                              <Typography className="custom-content">
                                <Fullname
                                  fullName={truncateString(
                                    contactData?.recruiter?.name,
                                    20
                                  )}
                                ></Fullname>
                              </Typography>
                            </Tooltip>
                          </Grid>

                          <Grid item xs={0.8} sm={0.8}>
                            <img
                              src={message}
                              className="message-icon"
                              alt="Message icon"
                            />
                          </Grid>
                          <Grid item xs={4} sm={3.4} style={{ paddingLeft: 0 }}>
                            <Tooltip title={contactData?.recruiter?.email}>
                              <Typography className="custom-content">
                                {truncateString(
                                  contactData?.recruiter?.email,
                                  16
                                )}
                              </Typography>
                            </Tooltip>
                          </Grid>
                          <Grid item xs={0.6} sm={0.6}>
                            <img
                              src={Telephone}
                              className="telephone-icon"
                              alt="Telephone icon"
                            />
                          </Grid>
                          <Grid item xs={4} sm={3.4}>
                            <Typography className="custom-content">
                              {formatPhone(
                                contactData?.recruiter?.phoneNumber
                              ) || "N/A"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                    <Divider
                      sx={{
                        height: "1px",
                        width: "100%",
                        margin: "0 auto 16px auto",
                        background: "#D9D9D9",
                      }}
                    />
                  </>
                )}
                <Grid item xs={12}>
                  <Typography align="center" className="custom-contact-txt">
                    Partner Information
                  </Typography>
                </Grid>
                <Box>
                  {renderRecruitersGroupInfo()}
                  <Grid container spacing={1.0} className="recruiterInfoBox">
                    <Grid item xs={12}>
                      <Typography className="custom-recruiterbox">
                        Account Manager
                      </Typography>
                    </Grid>
                    <Grid item xs={0.6} sm={0.6}>
                      <img src={User} className="user-icon" alt="User icon" />
                    </Grid>
                    <Grid item xs={2} sm={3}>
                      <Tooltip
                        title={contactData?.accountManager?.salesPersonName}
                      >
                        <Typography className="custom-content">
                          <Fullname
                            fullName={
                              truncateString(
                                contactData?.accountManager?.salesPersonName,
                                20
                              ) || "N/A"
                            }
                          />
                        </Typography>
                      </Tooltip>
                    </Grid>

                    <Grid item xs={0.6} sm={0.6}>
                      <img src={message} className="message-icon" alt="Message icon" />
                    </Grid>
                    <Grid item xs={4} sm={3.4}>
                      <Tooltip
                        title={contactData?.accountManager?.salesPersonEmail}
                      >
                        <Typography className="custom-content">
                          {truncateString(
                            contactData?.accountManager?.salesPersonEmail,
                            16
                          ) || "N/A"}
                        </Typography>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={0.6} sm={0.6}>
                      <img src={Telephone} className="telephone-icon" alt="Telephone icon" />
                    </Grid>
                    <Grid item xs={4} sm={3.4}>
                      <Typography className="custom-content">
                        {accountManagerPhone || "N/A"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Grid container spacing={1.0} className="recruiterInfoBox">
                    <Grid item xs={12}>
                      <Typography className="custom-recruiterbox">
                        Primary Recruiter
                      </Typography>
                    </Grid>
                    <Grid item xs={0.6} sm={0.6}>
                      <img src={User} className="user-icon" alt="User icon" />
                    </Grid>
                    <Grid item xs={2} sm={3}>
                      <Tooltip
                        title={contactData?.accountManager?.recruiterName}
                      >
                        <Typography className="custom-content">
                          <Fullname
                            fullName={
                              truncateString(
                                contactData?.accountManager?.recruiterName,
                                16
                              ) || "N/A"
                            }
                          />
                        </Typography>
                      </Tooltip>
                    </Grid>

                    <Grid item xs={0.6} sm={0.6}>
                      <img src={message} className="message-icon" alt="Message icon" />
                    </Grid>
                    <Grid item xs={4} sm={3.4}>
                      <Tooltip
                        title={contactData?.accountManager?.recruiterEmail}
                      >
                        <Typography className="custom-content">
                          {truncateString(
                            contactData?.accountManager?.recruiterEmail,
                            16
                          ) || "N/A"}
                        </Typography>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={0.6} sm={0.6}>
                      <img src={Telephone} className="telephone-icon" alt="Telephone icon" />
                    </Grid>
                    <Grid item xs={4} sm={3.4}>
                      <Typography className="custom-content">
                        {recruiterPhone || "N/A"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions></DialogActions>
        </Paper>
      </Dialog>
    </div>
  );
};

export default ContactInformationPopup;
