import { useState } from "react";
export default (apiFunc: (...args: any[]) => any) => {
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  
  const clear = ()=>{
    setData(null)
  }

  const request = async (...args: any[]) => {
    setLoading(true);
    try {
      const result = await apiFunc(...args);
      setData(result?.data);
    } catch (err) {
      setError((err as Error)?.message || "Unexpected Error!");
    } finally {
      setLoading(false);
    }
  };
  

  return {
    data,
    error,
    loading,
    request,
    clear
  };
};