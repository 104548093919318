export const timeZones = [
    { "label": "International Date Line West (Etc/GMT+12)", "value": "-12:00" },
    { "label": "Midway Island, Samoa (Pacific/Midway)", "value": "-11:00" },
    { "label": "Hawaii (Pacific/Honolulu)", "value": "-10:00" },
    { "label": "Alaska (US/Alaska)", "value": "-09:00" },
    { "label": "Pacific Time (US & Canada) (America/Los_Angeles)", "value": "-08:00" },
    { "label": "Tijuana, Baja California (America/Tijuana)", "value": "-08:00" },
    { "label": "Arizona (US/Arizona)", "value": "-07:00" },
    { "label": "Chihuahua, La Paz, Mazatlan (America/Chihuahua)", "value": "-07:00" },
    { "label": "Mountain Time (US & Canada) (US/Mountain)", "value": "-07:00" },
    { "label": "Central America (America/Managua)", "value": "-06:00" },
    { "label": "Central Time (US & Canada) (US/Central)", "value": "-06:00" },
    { "label": "Guadalajara, Mexico City, Monterrey (America/Mexico_City)", "value": "-06:00" },
    { "label": "Saskatchewan (Canada/Saskatchewan)", "value": "-06:00" },
    { "label": "Bogota, Lima, Quito, Rio Branco (America/Bogota)", "value": "-05:00" },
    { "label": "Eastern Time (US & Canada) (US/Eastern)", "value": "-05:00" },
    { "label": "Indiana (East) (US/East-Indiana)", "value": "-05:00" },
    { "label": "Atlantic Time (Canada) (Canada/Atlantic)", "value": "-04:00" },
    { "label": "Caracas, La Paz (America/Caracas)", "value": "-04:00" },
    { "label": "Manaus (America/Manaus)", "value": "-04:00" },
    { "label": "Santiago (America/Santiago)", "value": "-04:00" },
    { "label": "Newfoundland (Canada/Newfoundland)", "value": "-03:30" },
    { "label": "Brasilia (America/Sao_Paulo)", "value": "-03:00" },
    { "label": "Buenos Aires, Georgetown (America/Argentina/Buenos_Aires)", "value": "-03:00" },
    { "label": "Greenland (America/Godthab)", "value": "-03:00" },
    { "label": "Montevideo (America/Montevideo)", "value": "-03:00" },
    { "label": "Mid-Atlantic (America/Noronha)", "value": "-02:00" },
    { "label": "Cape Verde Is. (Atlantic/Cape_Verde)", "value": "-01:00" },
    { "label": "Azores (Atlantic/Azores)", "value": "-01:00" },
    { "label": "Casablanca, Monrovia, Reykjavik (Africa/Casablanca)", "value": "+00:00" },
    { "label": "Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London (Etc/Greenwich)", "value": "+00:00" },
    { "label": "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (Europe/Amsterdam)", "value": "+01:00" },
    { "label": "Belgrade, Bratislava, Budapest, Ljubljana, Prague (Europe/Belgrade)", "value": "+01:00" },
    { "label": "Brussels, Copenhagen, Madrid, Paris (Europe/Brussels)", "value": "+01:00" },
    { "label": "Sarajevo, Skopje, Warsaw, Zagreb (Europe/Sarajevo)", "value": "+01:00" },
    { "label": "West Central Africa (Africa/Lagos)", "value": "+01:00" },
    { "label": "Amman (Asia/Amman)", "value": "+02:00" },
    { "label": "Athens, Bucharest, Istanbul (Europe/Athens)", "value": "+02:00" },
    { "label": "Beirut (Asia/Beirut)", "value": "+02:00" },
    { "label": "Cairo (Africa/Cairo)", "value": "+02:00" },
    { "label": "Harare, Pretoria (Africa/Harare)", "value": "+02:00" },
    { "label": "Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius (Europe/Helsinki)", "value": "+02:00" },
    { "label": "Jerusalem (Asia/Jerusalem)", "value": "+02:00" },
    { "label": "Minsk (Europe/Minsk)", "value": "+02:00" },
    { "label": "Windhoek (Africa/Windhoek)", "value": "+02:00" },
    { "label": "Kuwait, Riyadh, Baghdad (Asia/Kuwait)", "value": "+03:00" },
    { "label": "Moscow, St. Petersburg, Volgograd (Europe/Moscow)", "value": "+03:00" },
    { "label": "Nairobi (Africa/Nairobi)", "value": "+03:00" },
    { "label": "Tbilisi (Asia/Tbilisi)", "value": "+03:00" },
    { "label": "Tehran (Asia/Tehran)", "value": "+03:30" },
    { "label": "Abu Dhabi, Muscat (Asia/Muscat)", "value": "+04:00" },
    { "label": "Baku (Asia/Baku)", "value": "+04:00" },
    { "label": "Yerevan (Asia/Yerevan)", "value": "+04:00" },
    { "label": "Kabul (Asia/Kabul)", "value": "+04:30" },
    { "label": "Yekaterinburg (Asia/Yekaterinburg)", "value": "+05:00" },
    { "label": "Islamabad, Karachi, Tashkent (Asia/Karachi)", "value": "+05:00" },
    { "label": "Chennai, Kolkata, Mumbai, New Delhi (Asia/Calcutta)", "value": "+05:30" },
    { "label": "Sri Jayawardenapura (Asia/Calcutta)", "value": "+05:30" },
    { "label": "Kathmandu (Asia/Katmandu)", "value": "+05:45" },
    { "label": "Almaty, Novosibirsk (Asia/Almaty)", "value": "+06:00" },
    { "label": "Astana, Dhaka (Asia/Dhaka)", "value": "+06:00" },
    { "label": "Yangon (Rangoon) (Asia/Rangoon)", "value": "+06:30" },
    { "label": "Bangkok, Hanoi, Jakarta (Asia/Bangkok)", "value": "+07:00" },
    { "label": "Krasnoyarsk (Asia/Krasnoyarsk)", "value": "+07:00" },
    { "label": "Beijing, Chongqing, Hong Kong, Urumqi (Asia/Hong_Kong)", "value": "+08:00" },
    { "label": "Kuala Lumpur, Singapore (Asia/Kuala_Lumpur)", "value": "+08:00" },
    { "label": "Irkutsk, Ulaan Bataar (Asia/Irkutsk)", "value": "+08:00" },
    { "label": "Perth (Australia/Perth)", "value": "+08:00" },
    { "label": "Taipei (Asia/Taipei)", "value": "+08:00" },
    { "label": "Osaka, Sapporo, Tokyo (Asia/Tokyo)", "value": "+09:00" },
    { "label": "Seoul (Asia/Seoul)", "value": "+09:00" },
    { "label": "Yakutsk (Asia/Yakutsk)", "value": "+09:00" },
    { "label": "Adelaide (Australia/Adelaide)", "value": "+09:30" },
    { "label": "Darwin (Australia/Darwin)", "value": "+09:30" },
    { "label": "Brisbane (Australia/Brisbane)", "value": "+10:00" },
    { "label": "Canberra, Melbourne, Sydney (Australia/Canberra)", "value": "+10:00" },
    { "label": "Hobart (Australia/Hobart)", "value": "+10:00" },
    { "label": "Guam, Port Moresby (Pacific/Guam)", "value": "+10:00" },
    { "label": "Vladivostok (Asia/Vladivostok)", "value": "+10:00" },
    { "label": "Magadan, Solomon Is., New Caledonia (Asia/Magadan)", "value": "+11:00" },
    { "label": "Auckland, Wellington (Pacific/Auckland)", "value": "+12:00" },
    { "label": "Fiji, Kamchatka, Marshall Is. (Pacific/Fiji)", "value": "+12:00" },
    { "label": "Nuku'alofa (Pacific/Tongatapu)", "value": "+13:00" }
]