import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import truncateString from "../../../utils/truncateString";
import { valueOrDefault } from "../../../utils/complexityUtils";

interface DataItem {
  month: string;
  pendingJobs: number;
  publishedJobs: number;
}

interface RelevantJobsChartProps {
  data?: DataItem[];
}

interface ChartData {
  cat: string[];
  series: {
    name: string;
    data: number[];
  }[];
  options: {
    chart: {
      type: "bar";
      height: number;
      toolbar: {
        show: boolean;
      };
      events: Record<string, unknown>;
      fontFamily: string;
    };
    colors: string[];
    plotOptions: {
      bar: {
        borderRadius: number;
        horizontal: boolean;
      };
    };
    yaxis: {
      labels: {
        formatter: (val: number) => number;
      };
    };
    xaxis: {
      categories: string[];
      labels: {
        style: {
          colors: string[];
          fontSize: string;
        };
        rotate: number;
      };
    };
    tooltip: {
      x: {
        shared: boolean;
        formatter: ({ dataPointIndex }: { dataPointIndex: number }) => string;
      };
    };
    legend: {
      show: boolean;
      fontSize: string;
      position: string;
      offsetX: number;
      offsetY: number;
      itemContainers: {
        clickable: boolean;
        focusable: boolean;
      };
    };
    noData: {
      text: string;
      align: string;
      verticalAlign: string;
      offsetX: number;
      offsetY: number;
    };
  };
}

const RelevantJobsChart: React.FC<RelevantJobsChartProps> = ({ data }) => {
  const [chartData, setChartData] = useState<ChartData>({
    cat: [],
    series: [
      {
        name: "Published Jobs",
        data: [],
      },
      {
        name: "Pending Jobs",
        data: [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 280,
        toolbar: {
          show: false,
        },
        events: {
        },
        fontFamily:"Roboto"
      },
      colors: ["#4540DB", "#8C83FA"],
      plotOptions: {
        bar: {
          borderRadius: 6,
          horizontal: false,
        },
      },
      yaxis: {
        labels: {
          formatter: (val) => {            
            // Check if val is a number, if not, default to 1
            return typeof val === "number" && val !== Infinity ? Math.floor(val) : 1;
          },
        },
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            colors: ["#4540DB", "#4540DB", "#4540DB", "#4540DB", "#4540DB"],
            fontSize: "10px",
          },
          rotate: 0,
        },
      },
      tooltip: {
        x: {
          shared: false,
          formatter: ({ dataPointIndex }) =>
            valueOrDefault(data?.[dataPointIndex]?.month, ""),
        },
      },
      legend: {
        show: true,
        fontSize: "9px",
        position: "top",
        offsetX: -10,
        offsetY: 15,
        itemContainers: {
          clickable: false,
          focusable: false,
        },
      },
      noData: {
        text: "No Record found",
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
      },
    },
  });

  useEffect(() => {
    if (data) {
      const month = Array.isArray(data) ? data.map((x) => x?.month) : [];
      const pendingJobsCount = Array.isArray(data)
        ? data.map((x) => x?.pendingJobs)
        : [];
      const publishedJobsCount = Array.isArray(data)
        ? data.map((x) => x?.publishedJobs)
        : [];

      setChartData({
        ...chartData,
        cat: month,
        series: [
          {
            ...chartData.series[0],
            data: publishedJobsCount,
          },
          {
            ...chartData.series[1],
            data: pendingJobsCount,
          },
        ],
        options: {
          ...chartData.options,
          xaxis: {
            ...chartData.options.xaxis,
            categories: Array.isArray(month)
            ? month.flatMap((x) =>
                x.replace(" - ", "- ").split(" ").map((x) =>
                  truncateString(x, 12)
                )
              )
            : [],
          },
        },
      });
    }
  }, [data]);

  return (
    <>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        width="100%"
        height="260"
      />
    </>
  );
};

export default RelevantJobsChart;
