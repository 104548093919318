import { FC, useEffect, useState } from "react";
import Chart from "react-apexcharts";

interface DataItem {
  month: string;
  score: number;
}

interface SatisfactionTrendChartProps {
  data: DataItem[];
} 

const SatisfactionTrendChart: FC<SatisfactionTrendChartProps> = ({ data }) => {
  const dates = Array.isArray(data) ? data.map((x) => x?.month) : [];
  const scores = Array.isArray(data) ? data.map((x) => x?.score) : [];

  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Satisfaction Trend",
        data: scores,
      },
    ],
    options: {
      chart: {
        height: 400,
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        fontFamily:"Roboto"
      },
      colors: ["#4540DB"],
      markers: {
        size: [6, 6],
      },
      stroke: {
        curve: "straight",
        width: 2,
      },
      grid: {
        row: {
          colors: ["#ffffff", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: dates,
      },
    },
  });

  useEffect(() => {
    setChartData((prevChartData) => ({
      ...prevChartData,
      series: [{ name: "Satisfaction Trend", data: scores }],
    }));
  }, []);

  return (
    <Chart
      options={chartData?.options}
      series={chartData.series}
      type="line"
      width="100%"
    />
  );
};

export default SatisfactionTrendChart;
