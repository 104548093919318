import Box from "@mui/material/Box";
import pathToRegexp from "path-to-regexp";
import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation } from "react-router-dom";
import getLoggedInUserRole from "../../Shared/utils/getLoggedInUserRole";
import { setReduxMetaData } from "../../features/Layout/NavigationSlice";
import { UserAuth } from "../../models/interfaces";
import getPathName from "../../utils/getPathName";
import RecruiterStatusBar from "../Common/RecruiterStatusBar";
import StaticFooter from "../Common/StaticFooter";
import AppHelmet from "../Common/meta/AppHelmet";
import HeaderLayout from "../Layout/HeaderLayout";
import { LeftNavigationLayout } from "../Layout/LeftNavigationLayout";
import RouterProvider from "../routes/RouterProvider";
import adminRoutes from "../routes/adminRoutes";
import clientRoutes from "../routes/clientRoutes";
import clientTeamMemberRoutes from "../routes/clientTeamMemberRoutes";
import protectedRoutes from "../routes/protectedRoutes";
import { publicProtectedRoutes, publicRoutes } from "../routes/publicRoutes";
import recruiterRoutes from "../routes/recruiterRoute";
import superAdminRoutes from "../routes/superAdminRoutes";

export const Layout = () => {
  const [metaData, setMetaData] = React.useState({
    title: "",
    description: "",
  });

  const metaDataList = useSelector(
    (state) => state.navigationState.metaData
  );

  const dispatch = useDispatch();

  const metaList = [
    {
      pageName: "Home",
      path: "/",
      url: "https://www.jobrialto.com",
      metaTitle: "JobRialto - Your One Stop Recruitment Marketplace!",
      metaDescription:
        "With JobRialto marketplace recruiters have of fulfilling 1000's of jobs both contingent and full-time, listed in the marketplace for IT, Non-IT and Healthcare domains",
      h1Tags: "",
      metaKeywords: "",
      default: true,
    },
  ];

  const user = getLoggedInUserRole();
  let { pathname } = useLocation();

  const fetchJson = () => {
    fetch("/meta/metaFile.json")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        dispatch(setReduxMetaData(data));
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  React.useEffect(() => {
    fetchJson();
  }, []);
  pathname = getPathName(pathname);

  let allRoutes = adminRoutes.concat(
    protectedRoutes,
    publicRoutes,
    publicProtectedRoutes,
    recruiterRoutes,
    superAdminRoutes,
    clientRoutes,
    clientTeamMemberRoutes
  );

  let leftNavShown = allRoutes
    .filter((x) => x.showLeftNav)
    .map((x) => getPathName(x.path));
  let footerShown = allRoutes
    .filter((x) => x.showFooter)
    .map((x) => getPathName(x.path));
  let headerShown = allRoutes
    .filter((x) => x.showHeader)
    .map((x) => getPathName(x.path));

  React.useEffect(() => {
    let metaData = metaDataList
      .filter((n) =>
          user === UserAuth.NotLoggedIn
          ? n.auth === false
          : n.auth === true || n.default
      )
      .find((n) => n.path && n.path.toLowerCase() === pathname.toLowerCase());
    if (metaData) {
      setMetaData({
        title: metaData.metaTitle,
        description: metaData.metaDescription,
      });
    } else {
      let defaultMetaData = metaList.find((n) => n.default);
      setMetaData({
        title: defaultMetaData.metaTitle,
        description: defaultMetaData.metaDescription,
      });
    }
  }, [pathname, metaDataList]);

  const getContainer = () => {
    const isMatch = leftNavShown.some((route) => {
      const keys = [];
      const pattern = pathToRegexp(route, keys);
      const match = pattern.exec(pathname);
      return match; // Return true if there's a match, otherwise false
    });
  
    if (isMatch)
    {
      return <Box sx={{ display: "flex" }}>
          <LeftNavigationLayout />
          <Box
            component="main"
            data-testid="main-content"
            className="custom-all-pages page-contentArea"
            sx={{ flexGrow: 1, p: 3 }}
          >
            <RecruiterStatusBar />
            <RouterProvider />
          </Box>
        </Box>
    }
    else
    {
      return <RouterProvider />;
    }
   
  };

  return (
    <>
    <Box
          component="div"
          data-testid="main-app-wrapper"
          className={`main-app-wrapper app-bgColor`}
          sx={{ flexGrow: 1, p: 3 }}
        >
      <AppHelmet title={metaData.title} metaDescription={metaData.description}></AppHelmet>
      <HeaderLayout headerShown={headerShown} />
      {getContainer()}
      {footerShown.some((n) =>
        matchPath( { path: n.toLowerCase(), exact: true },pathname.toLowerCase())
      ) && user == UserAuth.NotLoggedIn &&(
          <StaticFooter data-testid="footer-layout" />
        )}
      </Box>
    </>
  );
};
