import React from "react";
import { Badge, Avatar } from "@mui/material";
import { UploadPhoto as UploadPhotoIcon } from "../../../Common/Icons/UploadPhoto";
import camera from "../../../../assets/images/Camera.svg";
import { styled } from "@mui/material/styles";

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
  }));

interface RenderPhotoProps {
  imageInfo?: {
        resourceUrl?: string;
  };
  imgData: string;
}

const RenderPhoto: React.FC<RenderPhotoProps> = ({ imageInfo, imgData }) => {

  const hasImage = imageInfo && imageInfo?.resourceUrl;
  if (
    imageInfo &&
    imageInfo?.resourceUrl &&
    imgData !== ""
  ) {
    return (
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeContent={<SmallAvatar alt="Remy Sharp" src={camera} />}
      >
        <Avatar
          style={{ marginTop: "25px" }}
          sx={{ width: 120, height: 120 }}
          alt=""
          src={hasImage ? imgData : ''}
        ></Avatar>
      </Badge>
    );
  } else {
    return <UploadPhotoIcon />;
  }
};

export default RenderPhoto;
