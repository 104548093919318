import { FC } from "react";
import Chart from "react-apexcharts";
import truncateString from "../../../utils/truncateString";

interface SubmissionData {
  jobTitle: string;
  submissionCount: number;
}

interface TopSubmissionsChartProps {
  data: SubmissionData[];
}

const TopSubmissionsChart: FC<TopSubmissionsChartProps> = ({ data }) => {
  const jobTitles = Array.isArray(data) ? data?.map((x) => x?.jobTitle) : [];
  const submissionCount = Array.isArray(data) ? data?.map((x) => x.submissionCount) : [];
  const categories = jobTitles.map((x) => x.trim().replace(" - ", "- ").split(" ").map((x) => truncateString(x, 12)).slice(0, 5));

  const chartOptions = {
    chart: {
      type: "bar", 
      height: "180",
      toolbar: {
        show: false,
      },
      fontFamily:"Roboto"
    },
    colors: ["#4540DB"],
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories,
      labels: {
        style: {
          colors: ["#4540DB", "#4540DB", "#4540DB", "#4540DB", "#4540DB"],
          fontSize: "10px",
        },
        rotate: 0,
      },
    },
    yaxis: {
      labels: {
        formatter: function (val: number) {
          return Math.floor(val);
        },
      },
    },
    tooltip: {
      x: {
        shared: false,
        formatter: function (value: any, { dataPointIndex }: { dataPointIndex: number }) {
          return jobTitles[dataPointIndex];
        },
      },
    },
  };

  const chartSeries = [
    {
      name: "",
      data: submissionCount,
    },
  ];
 
  return (
    <Chart options={chartOptions} series={chartSeries} type="bar" width="100%" height="195" />
  );
};

export default TopSubmissionsChart;
