function formatAmount(amt, removeLeadingZero = false, currency = "") {
  if (removeLeadingZero) {
    if (
      currency !== "" &&
      (currency?.toLowerCase() === "inr" || currency?.toLowerCase() === "in")
    ) {
      return amt?.toLocaleString("en-IN").replace(/^0+/, "");
    } else {
      return amt?.toLocaleString("en-US").replace(/^0+/, "");
    }
  } else {
    if (
      currency !== "" &&
      (currency?.toLowerCase() === "inr" || currency?.toLowerCase() === "in")
    ) {
      return amt?.toLocaleString("en-IN");
    } else {
      return amt?.toLocaleString("en-US");
    }
  }
}
export default formatAmount;
