import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import { Document, getDocumentsByType } from '../../../../utils/documentObjectUtils';
import { DocumentTypeEnum } from '../../../../models/interfaces';
import { downloadFile, getFilePreviewUrl } from '../../../../utils/getFileFromUrl';
import { recruiterSignedContractResponsePDF } from '../../../../Services/getPdfReportService';
import { UploadAttachment } from '../../../../Services/uploadPhotoService';
import { SetContractDetails } from '../../../../Services/recruiterService';
import { ContractStatusCode } from '../ShareContractDemoContract/SignRecruiterContract';

interface Props {
  type:DocumentTypeEnum;
  title:string;
  recruiterInfo: any;
  documentInfo:Document[];
  setFilePreview:any;
  mode: 'View' | 'Edit' | string;
  contractDetails:any;
}

const SignedContractDocument: React.FC<Props> = ({
    type,
    title, 
    recruiterInfo,
    documentInfo,
    setFilePreview,
    mode = 'View',
    contractDetails
}) => {
  
  

  const [file, setFileDetails] = useState<any>();

  async function generateSignedContractPdfSave(signatureRequestId:string) {

  const token = localStorage.getItem("accessToken") ?? "";
  const signedDocumenetFile = await recruiterSignedContractResponsePDF(signatureRequestId,token);
  uploadRecruiterScreeningPdf(signedDocumenetFile); 
}

function uploadRecruiterScreeningPdf( PdfDocumentFile : File) {
  UploadAttachment({
    documentType: DocumentTypeEnum.Other,
    file: PdfDocumentFile,
  })
  .then((pdfRes) => {

    let UpdatedPayload = {
      resourceUrl: pdfRes.data.entity.absoluteUri,
      fileName: pdfRes.data.entity.fileName,
      newFileName: pdfRes.data.entity.newFileName,
      documentType: DocumentTypeEnum.RecruiterSignedContract,
    }
    setFileDetails(UpdatedPayload)

    let payload = {
      id: recruiterInfo?.id ?? "",
      signatureRequestId:contractDetails?.signatureRequestId,
      signatureId:contractDetails?.signatureId,
      signUrl: contractDetails?.signUrl,
      contractStatusCode:ContractStatusCode.SkipNotification,
      redirectUrl:contractDetails?.signUrl,
      SkipNotification:true, 
      contractDocumentInfo: {
        resourceUrl: pdfRes.data.entity.absoluteUri,
        fileName: pdfRes.data.entity.fileName,
        newFileName: pdfRes.data.entity.newFileName,
        documentType: DocumentTypeEnum.RecruiterSignedContract, 
      } 
    };
    _setContractDetails(payload)
    
  })
  .catch((error) => {
    console.log("something went wrong",error);
    
  });
}

const _setContractDetails = (payload:any, isFinal:boolean = false) => {
        
  SetContractDetails(payload)
    .then((response) => {
      if (response && response.status === 200) {
        console.log("set document successfully");
        
      }
    })
    .catch((error) => {
      console.error("Error during API call:", error);
    });
};

useEffect(()=>{
  const Documents = getDocumentsByType(documentInfo, type);
  if(Documents?.length > 0){
    let ExistingDocInfo = {
      fileName: getDocumentsByType(Documents, type)?.[0]?.fileName,
      resourceUrl: getDocumentsByType(Documents, type)?.[0]?.resourceUrl,
      newFileName:getDocumentsByType(Documents, type)?.[0]?.newFileName,
      documentType: DocumentTypeEnum.RecruiterSignedContract,
    }
    setFileDetails(ExistingDocInfo);
    
  }else if(contractDetails?.signatureRequestId && contractDetails?.contractStatusCode === ContractStatusCode.Signed){
    generateSignedContractPdfSave(contractDetails.signatureRequestId)

  }else{
    console.log("contract under process");
    
  }  
  
},[documentInfo])
  

  return (
    <>
      <Typography variant="subtitle1" component="div" className={`${ mode === 'View' ? 'verify-rec-form-heading' : 'MuiFormLabel-root MuiInputLabel-shrink' }`}>
        {title}
      </Typography>
      {file?.resourceUrl ? 
          <Typography key={file?.fileName} variant="subtitle1" component="div" className="verify-rec-form-value">
            {file.fileName}
            <VisibilityOutlinedIcon
              data-testid="primary"
              onClick={() => {
                getFilePreviewUrl(file.resourceUrl).then(filePreviewUrl => {
                  setFilePreview({
                    resourceUrl: filePreviewUrl,
                    fileName: file.fileName,
                    fileType: "application/pdf"
                  });
                });
              }}
              color="primary"
              className="pointer"
              sx={{ pl: 1, verticalAlign: "bottom" }}
            />
            <SaveAltOutlinedIcon
              data-testid="pointer"
              onClick={() => {
                downloadFile(file.resourceUrl, file.fileName);
              }}
              color="primary"
              className="pointer"
              sx={{ pl: 1, verticalAlign: "bottom" }}
            />
          </Typography>
        
       : 
        <Typography variant="subtitle1" component="div" className="verify-rec-form-value">
          N/A
        </Typography>
      }
    </>
  );
};

export default SignedContractDocument;
