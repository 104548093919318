import React from "react";
import {
    FormControl,
    Input,
    InputLabel,
    TextField,
    Grid
  } from "@material-ui/core";
import PlacesAutocomplete from "react-places-autocomplete";
import { searchOptions } from "../../../../../constants/googlePlaceOption"; 
import { ErrorMessage } from "../../../../ui/ErrorMessage"; 
import getAddressForAutoFill from "../../../../../utils/getAddressForAutoFill";
import { valueOrDefault } from "../../../../../utils/complexityUtils";

interface LocationDetailsSectionProps{
    handleChange: any;
     handleBlur: (event: React.FocusEvent<any>) => void;
     setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  values: any;
  touched: any;
  errors: any;
  setFieldTouched:any;
  isReadOnly?:boolean;
}
const LocationDetailsSection: React.FC<LocationDetailsSectionProps> = ({
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    values,
    touched,
    errors,
    isReadOnly=false
}) =>{

  const renderFunc = ({
    getInputProps,
    suggestions,
    getSuggestionItemProps,
    loading,
  }:any) => (
    <div className="signupcomplete-autocomplete">
      <FormControl variant="standard" error={touched?.InterviewLocation?.streetAddress && errors?.InterviewLocation?.streetAddress} fullWidth required>
      <InputLabel htmlFor="InterviewLocation-input">Street Address</InputLabel>
        <Input {...getInputProps()}   onBlur={handleBlur} data-testid="test-addressId" name="InterviewLocation.streetAddress" type="text" readOnly={isReadOnly}/>
        <ErrorMessage errorText={touched?.InterviewLocation?.streetAddress && errors?.InterviewLocation?.streetAddress} />

      </FormControl>
      <div className="autocomplete-dropdown-container">
        {loading && <div>Loading...</div>}
        {suggestions?.map((suggestion:any) => (
          <div key={suggestion?.description} {...getSuggestionItemProps(suggestion)}>
            <span onChange={handleSelect} style={{ cursor: 'pointer' }}>{suggestion?.description}</span>
          </div>
        ))}
      </div>
    </div>
  );

  const handleSelect = async (address:any) => {
    let resp = await getAddressForAutoFill(address)
 
     setFieldValue("InterviewLocation.streetAddress", valueOrDefault(resp?.formattedAddress, ""));
     setFieldValue("InterviewLocation.id",valueOrDefault(resp?.placeId, ""));
     setFieldValue("InterviewLocation.postalCode", valueOrDefault(resp?.pin, ""));
     setFieldValue("InterviewLocation.countryCode", valueOrDefault(resp?.country, ""));
     setFieldValue("InterviewLocation.state", valueOrDefault(resp?.state, ""));
     setFieldValue("InterviewLocation.city", valueOrDefault(resp?.city, ""));
     
     setFieldTouched("InterviewLocation.streetAddress",false)
     setFieldTouched("InterviewLocation.id",false)
     setFieldTouched("InterviewLocation.postalCode",false)
     setFieldTouched("InterviewLocation.countryCode",false)
     setFieldTouched("InterviewLocation.state",false)
     setFieldTouched("InterviewLocation.city",false)
     
   }

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4}>
          <PlacesAutocomplete
            data-testid={'clientAddress'}
            searchOptions={searchOptions}

            value={values.InterviewLocation?.streetAddress}
            onChange={(ev:any) => {
                setFieldValue("InterviewLocation.streetAddress", ev);
            }}
            onSelect={handleSelect}
            >
            {renderFunc}
            </PlacesAutocomplete>
        </Grid>        
        <Grid item xs={12} sm={12} md={2}>
          <TextField
            required
            id="state"
            name="InterviewLocation.state"
            label="State"
            InputLabelProps={{
              shrink: Boolean(values?.InterviewLocation?.state),
            }}
            InputProps={{
              readOnly: isReadOnly,
            }}
            value={values.InterviewLocation?.state}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched?.InterviewLocation?.state && errors?.InterviewLocation?.state}
            helperText={touched?.InterviewLocation?.state && errors?.InterviewLocation?.state}
            fullWidth
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <TextField
            required
            id="city"
            name="InterviewLocation.city"
            label="City"
            InputLabelProps={{
              shrink: Boolean(values?.InterviewLocation?.city),
            }}
            InputProps={{
              readOnly: isReadOnly,
            }}
            value={values?.InterviewLocation?.city}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched?.InterviewLocation?.city && errors?.InterviewLocation?.city}
            helperText={touched?.InterviewLocation?.city && errors?.InterviewLocation?.city}
            fullWidth
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
        <TextField
            required
            id="countryCode"
            name="InterviewLocation.countryCode"
            label="Country"
            InputLabelProps={{
              shrink: Boolean(values?.InterviewLocation?.countryCode),
            }}
            InputProps={{
              readOnly: isReadOnly,
            }}
            value={values.InterviewLocation?.countryCode}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched?.InterviewLocation?.countryCode && errors?.InterviewLocation?.countryCode}
            helperText={touched?.InterviewLocation?.countryCode && errors?.InterviewLocation?.countryCode}
            fullWidth
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <TextField
            required
            id="postalCode"
            name="InterviewLocation.postalCode"
            label="ZIP/Postal Code"
            value={values.InterviewLocation?.postalCode}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched?.InterviewLocation?.postalCode && errors?.InterviewLocation?.postalCode}
            helperText={touched?.InterviewLocation?.postalCode && errors?.InterviewLocation?.postalCode}
            fullWidth
            type="text"
            InputProps={{
              readOnly: isReadOnly,
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default LocationDetailsSection