import { makeStyles } from "@material-ui/core";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetRecuiterJobAlerts } from "../../../Services/recruiterJobAlertService";
import getUserDetails from "../../../Shared/utils/getUserDetails";
import { PermissionsEnums } from "../../../data/permission";
import { setBreadCrumbsState } from "../../../features/Common/BreadcrumbsStateSlice";
import {
  handleAdvanceSearchKeyword,
  handleDomainChange,
  setActiveJobPage,
} from "../../../features/Jobs/clientJobSlice";
import { BreadCrumbItem } from "../../../models/AppModels";
import { PageName } from "../../../models/app/Page/PageModel";
import { AppState } from "../../../store/AppState";
import { getNevLink } from "../../../utils/getFileFromUrl";
import AppBreadCrumb from "../../Common/AppBreadCrumb";
import { DeleteAppIcon } from "../../Common/Icons/DeleteAppIcon";
import { EditAppIcon } from "../../Common/Icons/EditAppIcon";
import { ModuleComponent } from "../../Common/Permission/ModuleComponent";
import { GotoTop } from "../../ui/GotoTop";
import "./JobAlert.scss";
import JobAlertDeletePopup from "./JobAlertDeletePopup";
import JobAlertEditPopup from "./JobAlertEditPopup";
import SnackbarPopup from "../../Common/Popup/snackbar/SnackbarPopup";
import GenericPagination from "../../Common/Table/GenericPagination";
import PleaseWait from "../../Common/Loder/PleaseWait";
import NoRecordsFound from "../../Common/Table/NoRecordsFound";
import { AppPaginationPerPage } from "../../../utils/AppConstant";


const useStyles = makeStyles({
  tableRow: {
    height: "20px !important",
  },
  tableCell: {
    padding: "20px 10px !important",
  },
});

const JobAlerts: React.FC = () => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const DefaultBreadCrumbs: BreadCrumbItem[] = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to: `${getNevLink('/dashboard')}` },
    { isLeaf: true, sequence: 2, title: "Job Alerts", leafClasses:" v2LastLeft"},
  ];
  const userDetails = getUserDetails();
  const breadCrumbState = useSelector((state:AppState) => state.BreadcrumbsState);

  const [editOpen, setEditOpenalert] = React.useState(false);
  const [deleteOpen, setDeleteOpenalert] = React.useState(false);
  const [recruiterJobAlerts, setRecruiterJobAlerts] = React.useState<any>([]);
  const [jobAlertId, setJobAlertId] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [showSuccessMsg, setShowSuccessMsg] = React.useState(false);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [isLoading, setIsloading] = React.useState<boolean>(false);

  
  const [recruiterJobAlertPayload, setRecruiterJobAlertPayload] =
    React.useState<any>({
      pageIndex: 1,
      pageSize: AppPaginationPerPage,
      sortColumn: "CreatedDate",
      sortOrder: "Descending",
      isSorted: true,
      totalRecord: 0,
      recruiterGuid: userDetails?.id,
    });

  // Pagination state

  const handleChangePage = (event: unknown, newPage: number) => {
    handlePaginationIndex(newPage);
  };

  const handleClickOpen = (id:string) => {
    setEditOpenalert(true);
    setJobAlertId(id);
  };
  const handleClickOpenDelete = (id:string) => {
    setDeleteOpenalert(true);
    setJobAlertId(id);
  };
  const handleClose = (success:boolean) => {
    if (success) {
      setSuccessMessage("Job alerts settings saved successfully.");
      setShowSuccessMsg(true);
    }
    setEditOpenalert(false);
  };
  const handleCloseDelete = (success: boolean, jobAlertId: string) => {
    if (success) {
      setSuccessMessage("Job alerts deleted successfully.");
      setShowSuccessMsg(true);
      _GetRecuiterJobAlerts();
    }
    setDeleteOpenalert(false);
  };

  const handlePaginationIndex = (index:number) => {
    setRecruiterJobAlertPayload({
      ...recruiterJobAlertPayload,
      pageIndex: index,
    });  
  };


  React.useEffect(() => {
    _GetRecuiterJobAlerts();
  }, [recruiterJobAlertPayload?.pageIndex]);

  const _GetRecuiterJobAlerts = () =>{
      setIsloading(true);
      setRecruiterJobAlerts([]);
      GetRecuiterJobAlerts(recruiterJobAlertPayload)?.then((res) => {
        if (
          res?.data?.status == 200 &&
          res?.data?.message?.appStatusCode == "RC_RSF"
        ) {
          setTotalRecords(res?.data?.totalRecords);
          setRecruiterJobAlerts(res?.data?.entityList || []);
          
        }else{
          setTotalRecords(0);
          setRecruiterJobAlerts([]);
        }
        
      })
      .finally(()=>{
        setIsloading(false);
      })
    }

  const _handleViewJobs = async (al:any) => {
    let updatedAdvanceFilterData = {
      jobTitle: al?.jobTitle || [],
      location: al.location,
      client: al.client,
      minimumPayRate: al.minimumPayRate !== "" ? al.minimumPayRate : 0,
      maximumPayRate: al.maximumPayRate !== "" ? al.maximumPayRate : 0,
      currency: al.currency,
    };
    
    try {
      dispatch(setActiveJobPage("filter"));
      dispatch(handleAdvanceSearchKeyword(updatedAdvanceFilterData));
      al?.domains?.forEach((domain:string) => {
        dispatch(handleDomainChange(domain));
      });
      
      dispatch(
        setBreadCrumbsState({
          ...breadCrumbState,
          navigateFromPage: PageName.JOBALERTLISTPAGE,
        })
      );
      navigate("/jobs/all", { state: { alertId: al?.id, fromPage:"JOBALERT" } });
    } catch (error) {
      console.error("Error dispatching:", error);
    }
  };
  return (
    <>
        <Grid container>
          <Grid item xs={12}>
            <AppBreadCrumb
              classes="custom-appbreadcrumb"
              BreadCrumbItems={DefaultBreadCrumbs}
            />
          </Grid>
          <Grid item xs={12}>
          <Typography
              variant="subtitle1"
              component="span"
              className="listing-total-heading"
              gutterBottom
              sx={{ display: "block",marginTop:"10px"}}
            >
              {"Active Alerts (" + totalRecords + ")"}
            </Typography>
          </Grid>
          <Grid item xs={12} component={Paper} mt={2}>
            <TableContainer sx={{ maxWidth: "100%" }}>
              <GenericPagination
                count={totalRecords}
                rowsPerPage={recruiterJobAlertPayload?.pageSize}
                page={recruiterJobAlertPayload?.pageIndex}
                onPageChange={handleChangePage}
              /> 
              <Table aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: 100 }}>Domains</TableCell>
                    <TableCell sx={{ width: 200 }}>Job Title</TableCell>
                    <TableCell sx={{ width: 200 }}>Location</TableCell>
                    <TableCell sx={{ width: 200 }}>Client</TableCell>
                    <TableCell sx={{ minWidth: 130 }}>
                      Pay Rate (Minimum)
                    </TableCell>
                    <TableCell sx={{ minWidth: 135 }}>
                      Pay Rate (Maximum)
                    </TableCell>
                    <TableCell sx={{ width: 300 }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>                 
                  {recruiterJobAlerts?.map((al:any, index:number) => {
                    return (
                      <TableRow
                        className={classes.tableRow}
                        key={ al?.id ?? index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell sx={{ width: 100 }}>
                          {al?.domains?.length == 0
                            ? "NA"
                            : al?.domains?.join(", ")}
                        </TableCell>
                        <TableCell sx={{ width: 200 }}>
                          {" "}
                          {al?.jobTitle?.length == 0
                            ? "NA"
                            : al?.jobTitle?.join(", ")}
                        </TableCell>
                        <TableCell sx={{ width: 200 }}>
                          {al?.location?.length == 0
                            ? "NA"
                            : al?.location?.join(", ")}
                        </TableCell>
                        <TableCell sx={{ width: 200 }}>
                          {al?.client?.length == 0
                            ? "NA"
                            : al?.client?.join(", ")}
                        </TableCell>
                        <TableCell sx={{ width: 200 }}>
                          {" "}
                          {al?.minimumPayRate
                            ? `${al.minimumPayRate} ${al.currency}`
                            : "NA"}
                        </TableCell>
                        <TableCell sx={{ width: 200 }}>
                          {" "}
                          {al?.maximumPayRate
                            ? `${al.maximumPayRate} ${al.currency}`
                            : "NA"}
                        </TableCell>
                        <TableCell
                          sx={{ width: 300, paddingTop: "0.85rem !important" }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <ModuleComponent
                              moduleId={PermissionsEnums.Jobs_Alerts_View}
                            >
                              <Button
                                  variant="outlined"
                                  className="Viewjobs-btn"
                                  size={"small"}
                                  sx={{ margin: "0 !important" }}
                                  onClick={() => {
                                    _handleViewJobs(al);
                                  }}
                              >
                                View Jobs
                              </Button>
                            </ModuleComponent>
                            <ModuleComponent
                              moduleId={PermissionsEnums.Jobs_Alerts_Edit}
                            >
                              <Tooltip title={"Edit"} placement="top">
                              <IconButton
                                sx={{ color:"#000", "&:hover": { backgroundColor: "transparent !important" } }}
                                onClick={() => handleClickOpen(al?.id)}
                                className="EditIconJobAlert"
                                data-testid="editAjobAlertIcon"
                              >
                              <EditAppIcon color="#000"/>
                              </IconButton>
                                
                              </Tooltip>
                            </ModuleComponent>
                            <ModuleComponent
                              moduleId={PermissionsEnums.Jobs_Alerts_Delete}
                            >
                              <Tooltip title={"Delete"} placement="top">
                                <IconButton
                                  data-testid="deleteAjobAlertIcon" 
                                  onClick={() => handleClickOpenDelete(al?.id)}
                                  sx={{ "&:hover": { backgroundColor: "transparent !important" } }}
                                  >
                                  <DeleteAppIcon color="#000"/>
                                </IconButton>
                              </Tooltip>
                            </ModuleComponent>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {isLoading && <PleaseWait />}
             {!isLoading && recruiterJobAlerts?.length === 0 && <NoRecordsFound /> }
             {showSuccessMsg && 
                <SnackbarPopup
                  open={showSuccessMsg}
                  message={successMessage}
                  onClose={() => { setShowSuccessMsg(false)}}
                />
            }
            </TableContainer>
            <GotoTop />
          </Grid>
        </Grid>


      {editOpen && (
        <JobAlertEditPopup
          open={editOpen}
          onClose={handleClose}
          jobAlertId={jobAlertId}
        />
      )}
      {deleteOpen && (
        <JobAlertDeletePopup
          open={deleteOpen}
          onClose={handleCloseDelete}
          jobAlertId={jobAlertId}
        />
      )}
    </>
  );
}

export default JobAlerts
