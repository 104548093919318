import { DocumentTypeEnum } from '../models/interfaces';
import axios from './CommonHttpRequest';
export interface UploadResourceModel {
    documentType : DocumentTypeEnum,
    file : File
}
export interface DeleteResourceModel {
    documentType : DocumentTypeEnum,
    fileNameToBeDeleted:string
}

export async function UploadAttachment(values:UploadResourceModel) { 
    let formData = new FormData();
    formData.append("DocumentType", DocumentTypeEnum[values.documentType]);
    formData.append("File", values.file);
let response = axios.post(`${process.env.REACT_APP_RESOURCE_API}api/ResourceUpload/UploadResource`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
})
     return response;
}

export async function UploadDocumentWithProgress(docType : DocumentTypeEnum,   file : File, config:any ) { 
    let formData = new FormData();
formData.append("DocumentType",docType.toString());
formData.append("File", file);
let response = axios.post(`${process.env.REACT_APP_RESOURCE_API}api/ResourceUpload/UploadResource`, formData, config)
     return response;
}

export async function DeleteAttachment (docType : DocumentTypeEnum,   fileName : string){
   let values =  {
        "documentType": docType,
        "fileNameToBeDeleted": fileName
      }
    const response = axios.post(`${process.env.REACT_APP_RESOURCE_API}api/ResourceUpload/DeleteResource`,JSON.stringify(values),{
        headers:{'Content-Type':'application/json'},
    });
    return response;
}

export async function DeletePhoto (values:DeleteResourceModel){
    const response = axios.post(`${process.env.REACT_APP_RESOURCE_API}api/ResourceUpload/DeleteResource`,JSON.stringify(values),{
        headers:{'Content-Type':'application/json'},
    });
    return response;
}
export enum DocumentType{
     // ProfileImage = 1,
    // Resume = 2,
    // IndianBilling = 3,
    // USBilling = 4,
    OtherDoc="OtherDoc",
    ResumeScoreCard ="ResumeScoreCard",
    RTRDoc="RTRDoc"
}