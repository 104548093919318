import {
  Breadcrumbs,
  makeStyles,
} from "@material-ui/core";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Button, CircularProgress, Divider } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React,{ useEffect, useState } from "react";
import { EditByRole, GetClientById } from "../../Services/clientService";
import useApi from "../../hooks/useApi";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { UploadAttachment } from "../../Services/uploadPhotoService";
import {
  companyNameRegExp,
  firstNameRegex,
  linkedInRegex,
  middleNameRegex,
  phoneNumberRegex,
  urlRegExp,
} from "../../constants/regex";
import { SUPPORTED_FORMATS } from "../../data/supportedImageFormat";
import { ClientStatusesId, DocumentTypeEnum } from "../../models/interfaces";
import convertToLocalizedDateTime from "../../utils/AppDateUtils";
import { capitalizeFirstLetter } from "../../utils/StringUtil";
import { getFileFromUrl, getNevLink } from "../../utils/getFileFromUrl";
import ClientPopup from "../ui/ClientPopup";
import { CustomAlert } from "../ui/CustomAlert";
import { ClientCommentTimeline } from "./ClientCommentTimeline";
import { ClientDetails } from "./ClientDetails";
import VerificationPopup from "./VerificationPopup";

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "#E0E3FA !important",
    },
  },
}));

export const Edit = () => {
  const classes = useStyles();
  const navigate = useNavigate();


  const initialState = {
    companyName: "",
    companyPseudoName: "",
    companyWebsite: "",
    email: "",
    companySize: "",
    industries: [] as any,
    address: {
      id: "",
      streetAddress: "",
      city: "",
      state: "",
      postalCode: "",
      countryCode: "",
    },
    contactDetails: {
      firstName: "",
      middleName: "",
      lastName: "",
      position: "",
      primaryPhoneNumber: "",
      isPrimaryPhoneVerified: false,
    },
    accountManagerDetails: {
      id: "",
      fullName: "",
      email: "",
      primaryPhone: "",
    },
    clientOtherDetails: {
      paymentTerms: "30",
      paymentMode: "ACH",
      modeOfDelivery: "",
      clientFee: "",
      clientFeeType: "USD",
      jobrialtoCommission: "",
      jobrialtoCommissionType: "USD",
      endClientName: "",
      endClientId: "",
    },
    clientAdditionalDetails: {
      companyLogo: {
        resourceUrl: "",
        fileName: "",
        newFileName: "",
      },
      companyOverview: "",
      companyBenefits: "",
      hiringProcess: "",
      linkedInProfileUrl: "",
    },
  };

  const clientSchema = Yup.object({
    companyName: Yup.string()
      .required("Please enter company name")
      .matches(companyNameRegExp, "Please enter valid company name"),
    companyPseudoName: Yup.string()
      .required("Please enter company pseudo name")
      .matches(companyNameRegExp, "Please enter valid pseudo name")
      .max(100,"Company pseudo name must be less than 100 characters"),
    companyWebsite: Yup.string().required('Please enter company website').matches(
      urlRegExp,
      "Please enter valid URL starting with http or https"
    ),
    email: Yup.string()
      .email("Entered email address is invalid")
      .required("Please enter a valid email address"),
    companySize: Yup.string().required("Please enter company size"),

    // Add validation rules for other fields
    industries: Yup.array().min(1, "Please enter select industries"),

    address: Yup.object().shape({
      id: Yup.string(),
      streetAddress: Yup.string().required("Please enter street address"),
      city: Yup.string().required("Please enter city"),
      state: Yup.string().required("Please enter state"),
      postalCode: Yup.string().required("Please enter postal code"),
      countryCode: Yup.string().required("Please enter country"),
    }),

    contactDetails: Yup.object().shape({
      firstName: Yup.string()
        .required("Please enter the first name")
        .matches(firstNameRegex, "Entered first name is not valid"),
      middleName: Yup.string().matches(
        middleNameRegex,
        "Entered middle name is not valid"
      ),
      lastName: Yup.string()
        .required("Please enter the last name")
        .matches(firstNameRegex, "Entered last name is not valid"),
      position: Yup.string().required("Please enter position").matches(
        companyNameRegExp,
        "Entered position is not valid"
      ),
      primaryPhoneNumber: Yup.string()
        .required("Please enter the phone number")
        .matches(phoneNumberRegex, "Entered phone number is invalid"),
    }),
    clientAdditionalDetails: Yup.object().shape({
      companyBenefits: Yup.string(),
      hiringProcess: Yup.string().required("Hiring Process is required"),
      linkedInProfileUrl: Yup.string() 
        .matches(linkedInRegex, "LinkedIn URL doesn't match"),
    }),
  });

  let { id, type } = useParams();
  const [pageStatus, setPageStatus] = React.useState({
    success: false,
    error: "",
    loading:false,
    key: Date.now(),
  });
  const [defaultEmail, setDefaultEmail] = useState<string>("");
  const [selectedFile, setSelectedFile] = React.useState({
    file: null,
    error: "",
  });
  const [verificationPopup, setverificationPopup] = React.useState({
    open: false,
    statusId: "",
  });
  const clientById = useApi(() => GetClientById(id));

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: clientSchema,
    onSubmit: async (values, actions) => {
      setPageStatus({
        ...pageStatus,        
        loading:true,
      });

      if (selectedFile.error) {
        return;
      }

      let reqBody = { ...values, redirectUrl: `${window.location.origin}` };
      if (selectedFile.file) {
        UploadAttachment({
          documentType: DocumentTypeEnum.Other,
          file: selectedFile.file,
        })
          .then((uploadResponse) => {
            // Create the nested property if it doesn't exist
            if (!reqBody.clientAdditionalDetails.companyLogo) {
              reqBody.clientAdditionalDetails.companyLogo = {};
            }

            reqBody.clientAdditionalDetails.companyLogo.resourceUrl =
              uploadResponse?.data?.entity?.absoluteUri;
            reqBody.clientAdditionalDetails.companyLogo.fileName =
              uploadResponse?.data?.entity?.fileName;
            reqBody.clientAdditionalDetails.companyLogo.newFileName =
              uploadResponse?.data?.entity?.newFileName;
            
            // Call EditByRole inside this block to ensure it runs after UploadAttachment is successful
            executeEditByRole(reqBody);
          })
          .catch((error) => {
            // Handle errors from UploadAttachment
            setPageStatus({
              success: false,
              loading:false,
              error: "Upload Attachment Error",
              key: Date.now(),
            });
          });
      } else {
        // Execute EditByRole directly when selectedFile.file is null
        delete reqBody.clientAdditionalDetails.companyLogo;

        executeEditByRole(reqBody);
      }
    },
  });

  function executeEditByRole(reqBody: any) {
    reqBody.address.id = reqBody.address.city;

    EditByRole(reqBody)
      .then((response) => {
        // Check the response and update the pageStatus state accordingly
        if (response?.data?.message?.appStatusCode === "CL_RSF") {
          setPageStatus({ success: true, loading:false, error: "", key: Date.now() });
        } else {
          setPageStatus({
            success: false,
            loading:false,
            error: response?.data?.message?.appStatusDescription,
            key: Date.now(),
          });
        }
      })
      .catch((error) => {
        setPageStatus({
          success: false,
          loading:false,
          error: "API Error",
          key: Date.now(),
        });
      });
  }

  const renderButton = () => {
    let approve = (
      <Button
        startIcon={<CheckIcon />}
        onClick={(e) => {
          setverificationPopup({
            open: true,
            statusId: ClientStatusesId.Approved,
          });
        }}
        variant="contained"
      >
        Approve
      </Button>
    );
    let reject = (
      <Button
        startIcon={<CloseIcon />}
        onClick={() =>
          setverificationPopup({
            open: true,
            statusId: ClientStatusesId.Rejected,
          })
        }
        style={{ marginRight: "6px", color: "red", fontFamily: "Roboto" }}
        classes={{ root: classes.root }}
        variant="outlined"
        color="error"
      >
        Reject
      </Button>
    );
    let suspend = (
      <Button
        onClick={() =>
          setverificationPopup({
            open: true,
            statusId: ClientStatusesId.Suspended,
          })
        }
        style={{
          marginRight: "6px",
          color: "#4540DB",
          fontFamily: "Roboto",
          borderColor: "#4540DB",
        }}
        classes={{ root: classes.root }}
        variant="outlined"
        color="error"
      >
        Suspend
      </Button>
    );
    let clientStatus = clientById?.data?.entity?.status;
    if (clientStatus == "Pending") return [reject, suspend, approve];
    if (clientStatus == "Approved") return [reject, suspend];
    else return [approve];
    // Return both variables as an array
  };
  
  const handleLogoChange = (e: any, type = "2") => {
    let file;
    if (type == "1") {
      file = e.target.files[0];
    } else {
      file = e;
    }
    if (file) {
      if (!SUPPORTED_FORMATS.includes(file.type)) {
        setSelectedFile({ file: null, error: "Invalid file format." });
        return;
      }

      const maxSize = 200 * 1024; // 200KB in bytes
      if (file.size > maxSize) {
        setSelectedFile({
          file: null,
          error: "File size exceeds the 2MB limit",
        });
        return;
      }
      setSelectedFile({ file, error: "" });
    }
  };

  const loadFile = async () => {
    let fUrl = clientById?.data?.entity?.clientAdditionalDetails?.companyLogo;
    if (fUrl) {
      let file = await getFileFromUrl(fUrl?.resourceUrl, fUrl.fileName);
      setSelectedFile({ file, error: "" });
    }
  };
  
  useEffect(() => {
    clientById?.request();
  }, []);

  useEffect(() => {
    if (clientById?.data){
      Object.keys(clientById.data?.entity).forEach((key) => {
        formik.setFieldValue(key, clientById.data?.entity[key]);
        //defaults
      });
      setDefaultEmail(clientById.data?.entity?.email);
      loadFile();
    }
  }, [clientById?.data]);

  return (
      <>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} sm={12}>
            <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link
              color="primary"
              to={"/"}
              style={{ textDecoration: "none", color: "#4540DB" }}
              className="custom-breadCum-fontsize"
            >
              Dashboard
            </Link>
            <Link
              color="primary"
              to={getNevLink("/clients")}
              style={{ textDecoration: "none", color: "#4540DB" }}
              className="custom-breadCum-fontsize"
            >
              Clients
            </Link>
              <Typography className="custom-breadCum-fontsize">
                {capitalizeFirstLetter(type)}
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} sm={6}>

            <Typography className="Client-txt-fontwt">
              Manage Client
              <Typography
                variant="h6"
                component="span"
                style={{ color: "grey" }}
              >
                &nbsp; ({clientById?.data?.entity?.clientId})
              </Typography>
            </Typography>
          </Grid>
          <Grid  item xs={12} sm={6} className="custom-client-rspnsv">
            <Typography className="client-text" pr={2} sx={{
                  textAlign: {
                    xs: 'unset',  // For extra small screens
                    sm: 'left',    // For small screens
                    md: 'right',   // For medium screens
                    lg: 'right',  // For large screens
                    xl: 'right'     // For extra large screens
                  }
                }}>
              Signed Up on : &nbsp;{" "}
              <span className="singnedDateText">
              {convertToLocalizedDateTime(
                clientById?.data?.entity?.completeSignupDate,
                "MMMM DD,YYYY,h:mm:ss A"
              )}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
              <ClientDetails
                defaultEmail={defaultEmail}
                type={type}
                id={id}
                formik={formik}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                handleLogoChange={handleLogoChange}
              />
          </Grid>
          <Grid item xs={12}>
            {type == "edit" ? (
              <>
                <Button
                  startIcon={<CloseIcon />}
                  onClick={() => navigate(getNevLink("/clients"))}
                  style={{ marginRight: "16px", color: "red" }}
                  classes={{ root: classes.root }}
                  variant="outlined"
                  color="error"
                  disabled= { pageStatus.loading }
                  endIcon={ pageStatus.loading && 
                    <CircularProgress
                      size={"16px"}
                      color={"inherit"}
                    />
                  }
                >
                  Cancel
                </Button>

                <Button
                  startIcon={<CheckIcon />}
                  onClick={(e) => {
                    formik.handleSubmit();
                  }}
                  variant="contained"
                  style={{ fontFamily: "Roboto" }}
                  disabled= {pageStatus.loading }
                  endIcon={ pageStatus.loading && 
                    <CircularProgress
                      size={"16px"}
                      color={"inherit"}
                    />
                  }
                >
                  Update
                </Button>
              </>
            ) : (
              <>{renderButton()}</>
            )}
          </Grid>
          <Grid  item xs={12}>
            <Divider />
          </Grid>
          <Grid  item xs={12}>
            <ClientCommentTimeline
              commentDetails={clientById?.data?.entity?.comments}
              type={"client"}
            />
          </Grid>
          <Grid item xs={12}>          
            {pageStatus.error && (
              <CustomAlert
                message={pageStatus.error}
                key={pageStatus.key}
                type={"error"}
              />
            )}
          <ClientPopup
            isOpen={pageStatus.success}
            handleClose={() => {
              navigate(getNevLink("/clients"));
            }}
            type={"update"}
            title={"update"}
          ></ClientPopup>
            {verificationPopup.open && (
              <VerificationPopup
                clientId={clientById?.data?.entity?.id}
                statusId={verificationPopup.statusId}
                openPopup={verificationPopup.open}
                handleDocumentsPopupClose={() =>
                  setverificationPopup({ open: false, statusId: "" })
                }
              />
            )}
          </Grid>
        </Grid>
        
      </>
  );
};
