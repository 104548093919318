function getAddressForAutoComplete(str){
    let {city,state,country} = "";
   let arr = str?.split(',')
 if(arr?.length===3)
   {
    city = arr[0] ;
    state =arr[1];
    country = arr[2]
    
   }
   if(arr?.length===2)
   {
    city = arr[0] 
    state =arr[1];
    country = "";
    
   }
   if(arr?.length===1)
   {
    city = arr[0] 
    state ="";
    country = "";
    
   }
   
   return {city,state,country}
   
  }
  export default getAddressForAutoComplete;

 