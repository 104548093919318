import { Box, Typography } from "@mui/material";
import CircularProgress, {
    CircularProgressProps,
  } from "@mui/material/CircularProgress";

export function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number }
  ) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress size={"32px"} variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h6"
            component="div"
            color="text.secondary"
            className="ackn-score-meter"
          >{`${Math?.round(props?.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }