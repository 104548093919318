
export enum PermissionsEnums {
  //Modules area start
  GetJob = "54e064b3-037c-4193-90f3-1bface9354b4",
  GetGraphicalDashboard = "9b521ef1-6294-4d54-a0a0-324bc6fe5c1b",
  GetDashboard = "9513d7ad-e02c-4af1-af2d-d09f87e86c0c",
  Admins_Add = "b393a87e-264f-4dda-9c3a-f72af4551618",
  Admins_Edit = "d22f775d-52d1-4d12-b4d0-f68aec1f636d",
  Admins_Verify = "60a4dbb8-b946-423d-9d8d-327ec26e70b9",
  Recruiter_Add = "2fc7a507-21e6-4d9d-b797-8ee37c6cdb8d",
  Recruiters_Edit = "5e3b6810-e974-43b5-84d3-fd9edba9c633",
  Recruiter_Manage = "d22117e4-4f73-43e5-b387-dccd66284ec8",
  Recruiters_SavedJobs = "3fa44d0f-2f45-4690-a4de-968ed3e358f3",
  Recruiters_Submissions = "b35d22a4-5632-4d4a-b3b2-81b026e4e415",
  Jobs_Mark_Featured = "e57d1e54-a4e5-4a38-9f0f-1d7469338511",
  Jobs_Mark_Not_Active = "9a8a2b55-51d6-48f5-a9c7-a16062a4e650",
  Jobs_Job_Details = "e89d3d55-cbf0-4400-bc44-8b233d9c0552",
  Jobs_Partner_Contacts = "d3ce28f1-89a7-4bfc-9850-070f8891acc1",
  Jobs_Share = "bc397f5e-010a-4eab-b075-d4306288d29b",
  Submissions_Bypass_RTR = "b8aa381a-87a1-4433-8389-041aeea8f306",
  Submissions_RTR_Request = "6fd8914c-bc85-45e5-b91c-86cb7bd16093",
  Submissions_Update = "e427a37c-1d0f-4cc0-82f2-cfe5b13a050b",
  Submissions_Documents = "46476ba2-dfa0-454f-8573-ad113ea4b02f",
  Submissions_Contacts = "8b647e13-e551-4268-8727-1aa2ba9ed95e",
  Submissions_Conversations = "099ed9e5-3307-417a-8d47-d9d630e5e35c",
  Submissions_Comments = "59ca7515-ac92-4a62-beb7-18519d500a0f",
  Submissions_Reject = "5ada94fb-66a5-417b-a1b8-9ebc87a36a92",
  Submissions_Interview_Details = "79b051ce-7408-4f1c-a86a-1eac396b8226",
  Submissions_Offer_Details = "21405d1c-0b38-40da-a102-6780c1d9b332",
  Submissions_Contract_Active = "3d0b7871-4b88-40ed-a5bc-8d8a6bc23678",
  Submissions_Contract_End = "8902ac05-03bf-45a3-954e-700b318ebddc",
  //CL = Client
  Submissions_Submit_to_CL="6ec9a48d-e06c-42f1-8b52-5288e2d83d0c",
  Contracts_Details = "b7e5c0ea-bbe9-4fcd-95b9-610d4cb77254",
  Contracts_View_Previous_Rate = "3790dee3-0b2b-4ae3-bb74-34c986cf95d1",
  Candidates_Details = "8133f964-91f2-4e47-8c09-f016088d7aba",
  Earnings_Approve = "86ae95f2-392b-41ea-85ff-7bf1ab59ce0d",
  Compensation_Update = "7acb2917-16a4-4a80-8649-3ae86256957d",
  Compensation_Calculator = "be6de403-cc90-4325-935d-f966f43c11f6",
  Jobs_Alerts_View = "91e3b55c-93dc-4918-96b6-06502542c5e7",
  Jobs_Alerts_Edit = "4fc28857-c427-4b17-8be5-edf187db49d7",
  Jobs_Alerts_Delete = "e262a689-2a5e-4079-a1b3-42ee7f3adfaa",
  Profile_Account = "fd54bb61-686e-4d7b-a1a3-6905baefa7e3",
  Profile_Location = "72f2f3d3-56f5-43eb-be9e-42ef95834625",
  Profile_UserDetails = "25053544-82d8-4a8c-af2e-6abc661b3353",
  Profile_Payments = "67833c7e-cc9f-43a2-9c56-6ee2f4c3ad2e",
   //Modules area end
  
  
   //Feature area start
 
  Dashboard_Admin = "aa31e92f-b6ce-4544-a419-b553105cf65c",

  Admins_View = "57f0f15f-4a2b-4174-917d-ab04d1165f9d",

  Role_Management_Page = "67303d6b-18aa-469e-b74e-c8e4f0d6593f",

  Recruiters_View = "227b0136-6ecf-44b4-9ec8-520529050fb8",

  Jobs_View = "56f9b114-f7aa-4a66-be1d-71494b219502",

  Submissions_All = "c3a3de33-8f38-4f2e-a978-af6ddb88786f",

  Contracts_View = "86521405-8a8f-4551-bd1c-9f9861e285c0",

  Candidates_View = "670e361f-7e75-403c-9dcd-0193fa56189b",

  Earnings_View = "5796b2fd-027b-4099-9151-bd11e5f0d7f8",

  Compensation_View = "0a929a27-74fb-4e5d-83fe-3e5e9a5e2325",

  Submit_Resume = "34f45aa5-285b-4928-8916-97fe038c34da",

  Jobs_Find = "ce029b1b-e197-44e4-904d-ab6df5145564",

  Jobs_Alerts = "fc3df9ff-5b3f-45e9-b63c-ed941c772f11",

  Settings = "e71da7e5-4f44-4486-8e13-88de88d35a00",

  FAQ = "81456cac-109c-42b6-bc7c-fdac74adc81f",

  Manage_Profile = "f8d14f87-c249-4520-8406-c171805c2339",
  Dashboard_Recruiter = '52cc0419-c53d-494b-9c07-faf1fa3c0760',
  Jobs_Saved = "0db0787f-6a70-44be-b6c6-7a55d2888804",
  All_Candidates_View = "641db899-5dbd-4e9b-a26d-7b176afab7b4",
  AllCandidates_Details = '8150b620-9317-4608-8261-1c1bc8b6185a',
  Privileged= "2d536b9b-6c68-4ab5-aef9-9b635514524c",
  Recruiters_Feedback_History= "1771eb1f-1941-4414-ac22-0a7fec232388",
  Members_Add = '7b6b78f1-30d6-46b1-9b4a-38cd17d95f84',
   Members_Edit = 'a42e6803-3d7f-4ba6-8dbd-11cb5c2d6b72',
   Members_Verify = '469fda40-cf1a-4b2d-b4a9-803109756b2b',
   Members_Listing='48226b63-efab-4409-b3c4-051519e16203',
   Jobs_Add = '7c349bfd-e4c2-418a-a40c-bdc7ed32a627',
   Jobs_Add_Publish = 'c5a14d11-60ef-4b89-9af7-8f856dfae0c7',
  Jobs_Pending_Publish_Save = "a8c95280-6cb2-41bf-90a9-6f900bb54da7",
  Jobs_Pending_Publish = "b01d0953-bb5e-41ca-8d30-c3d16dfea869",
   Jobs_Add_Save = "eb7597dc-2dea-401a-9401-be553d6403c8",
   Jobs_Pending= '82fcdae8-4ab4-496e-889c-5ec33d9c1076',
   Jobs_Edit = 'e54d962f-b7af-4277-8a40-6fe42572478a',
   Company_Profile = "d6cff28c-c1c8-4e69-b7b1-e5d016b05dd2",
   Jobs_Pending_Details = "9928b08a-cc05-469b-bd25-29dc407386c4",
   Jobs_Pending_Publish_Publish="e9ae9315-5c49-468f-bfd2-f322e74ff1c2",
   //CL = Client
   CLUserView = "2a63047e-ef28-4e09-af5b-75d151d0016b",
   CLUserAdd ="ee45e791-8929-4077-a13b-2197f67eb95f",
   CLUserEdit = "806129d4-77f3-4bd0-8c1a-f9c98d300e99",
   CLUserVerify = "d7a92f6e-2275-4d34-bc76-9741e5fb99ec",
   Manage_CLUser = "5e3292db-5e78-4019-9670-ab27f78fb6e1",
   Manage_CLUpdate = "0511b86f-f7e1-45f2-9c99-57341ba1d0bd",

   // Interview Schedule Permissions
   ShareInterviewAvailability = "d1a6063e-f77d-42b7-a13d-7e628ab90e1d",
   ViewSharedAvailability = "bb20c510-7fe4-4c5b-bacd-d19cef841f87",
   InterviewSchedule = "12e88795-ac88-4ecc-8870-f3679993fcfc",
   InterviewReschedule = "2e40540f-2b3d-4376-9e00-3610261010cb",
   InterviewCancelled = "057e5958-4cdb-4aef-9c97-825ef2097c99",
   NextRoundOfInterview = "4d3cead7-8894-46d3-9f49-25601ee621e7",
   CandidateSelected = "20966f53-71b3-4109-a4b3-22ac217cccd7",
   RejectInInterview = "b7fb60a0-1cc0-41f3-9b3d-5cbf1687b6bb",
   InterviewFeedbackDue = "1b4c7d83-1bb6-4daa-b25f-b2035e0cb48a",
   InterviewDetails = "c1481bcb-5033-455b-a9a2-1f4f3b213729"




                  
  
}


