import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  FormControl
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { BillingType } from "../../../models/PaymentDetails";
import { OtherBilling } from "./OtherBilling";
import { AdminPayoneerBilling } from "./AdminPayoneerBilling";
import { AdminUSABilling } from "./AdminUSABilling";
import SectionHeading from "../../ui/Form/Heading/SectionHeading";

interface Props {
  editMode : any,
 
  values : any,
  onChange : any,
  billingDetail:{
    userId:string,
    email:string,
    name:string,
    billingInfoType : string
  },
  onCountryChange : any,
  selectedOption : string;
  USAFormRef:any;
  PayoneerFormRef:any;
 
}
export const AdminPaymentDetail: React.FC<Props> = ({
 
  billingDetail,
  editMode,
 
  values,
  onChange,
  onCountryChange,
  selectedOption,
  USAFormRef,
  PayoneerFormRef
 
}) =>  {
   const [selectedCountry, setselectedCountry] = useState("Please select a country")
  
  const handleCountryChange = (event:any) => {
    setselectedCountry(event.target.value)
    onCountryChange(event.target.value)
    //Payment details change Payoneer
  };

  const getSelectedCountry = (selectedOption:string) => {
    switch (selectedOption) {
      case "NotUpdated":
        return "Please select a country";
      case "USA":
        return BillingType.USA;
      case "Payoneer":
        return BillingType.Payoneer;
      default:
        return BillingType.Other;
    }
  };

  // Disable AutoSelection Payoneer change
  useEffect(() => {    
    setselectedCountry(getSelectedCountry(selectedOption));
}, [selectedOption])

  const renderOptions = () => {
    if(billingDetail!==null){
    return <React.Fragment>
      <RadioGroup
           
            row
            value={selectedCountry}
            aria-labelledby="independentRecruiter-radio-buttons-group-label"
            name="selectedCountry"
            onChange={(e) => {
              handleCountryChange(e)
             }}
           
          >
            <FormControlLabel value={BillingType.USA}   control={<Radio disabled={!editMode} readOnly={false}
            />} label="US Account" />
            <FormControlLabel value={BillingType.Payoneer}   control={<Radio disabled={!editMode} readOnly={false} data-testid="PayoneerPaymentRadio"
           />} label="Payoneer" />
             <FormControlLabel value={BillingType.Other}   control={<Radio disabled={!editMode} readOnly={false}
           />} label="Others" />
          </RadioGroup>
      
      </React.Fragment> 
  }
  else{
    return <p style={{marginTop:'5px'}}>Please wait...</p>
  }
}
  const renderForm = () => {
    if(selectedCountry===BillingType.Payoneer){
    return <React.Fragment> 
      <AdminPayoneerBilling 
      onChange={onChange} 
      values={values?.payoneerBilling}  
      billingDetail={billingDetail} 
      editMode={editMode} 
      key={1}
      PayoneerFormRef={PayoneerFormRef}
      /></React.Fragment>
    } 
    if(selectedCountry===BillingType.Other){
    return <OtherBilling/>
    }
    if(selectedCountry === BillingType.USA){
    return <React.Fragment>
        <AdminUSABilling  
          onChange={onChange} 
          values={values?.usaBilling}   
          editMode={editMode} 
          billingDetail={billingDetail}
          USAFormRef={USAFormRef}
        />
        </React.Fragment> 
    }
  }
  return (
    <React.Fragment>
    <Box
      sx={{
        flexGrow: 1,
      }}
      className="billing-form"
    >
      <Grid container spacing={2} sx={{ justifyContent: "space-start" }} mt={1}>
        <Grid item xs={12} md={12}>
          <Typography
            variant="h6"
            component="span"
            className="abel letter-spacing-normal primary-color-text typography-with-right-text"
            gutterBottom
            sx={{ width: { sm: "100%", md: "80%" }, fontSize:"1rem" }}
          >
            <SectionHeading
            title="Payment Details"
            />
          </Typography>
        </Grid>
        <Grid item xs={12} md={7} mt={-3}>
       
            <Box
              component="form"
              noValidate
              autoComplete="off"
              sx={{
                flexDirection: "row",
                "& .MuiFormControl-root": {
                
                  marginBottom: "3px",
                },
              }}
            >
              <FormControl
                required
                fullWidth
              >
              
            
                  {renderOptions()}
                   
               
                
              </FormControl>
             
               {renderForm()}
              </Box>
         
        </Grid>
      </Grid>
    </Box>
    </React.Fragment>
  )
}
