import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Grid, Box, Paper, Button } from "@mui/material";
import { UploadIcon } from "../Common/Icons/UploadIcon";
import { useNavigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  boxShadow: "none",
  color: "#fff",
}));

export default function Jobs() {
  const navigate = useNavigate()
  return (
    <Container
      maxWidth="xl"
      className="home-recruiter"
      sx={{
        marginTop: "16px",
        paddingTop: "50px",
        paddingBottom: "50px",
      }}
    >
      <Box className="home-connect-submit-box">
        <Grid container spacing={3} sx={{ justifyContent: "space-around" }}>
          <Grid item xs={12} sm={6} md={6}>
            <Item elevation={0}></Item>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Item elevation={0} >
              <Typography
                variant="h3"
                color="inherit"
                component="div"
                className="connect-text"
                textAlign={"left"}
                sx={{fontSize:{xs:30,md: "3rem"}}}
              >
                Get Matched to the Most <br />
                Valuable Jobs, Submit your <br />
                candidate at JobRialto
              </Typography>
              <Typography
                variant="subtitle1"
                color="inherit"
                component="div"
                textAlign={"left"}
                fontWeight="600"
                mt={1}
              >
                Sign up/Sign in and submit your candidate with a few easy clicks
              </Typography>
              <Box
                mt={5}
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                }}
              >
                <Box mb={3} textAlign="left">
                  <Typography
                    variant="subtitle1"
                    color="inherit"
                    component="div"
                    textAlign={"left"}
                    fontWeight="600"
                  >
                    Recruiters
                  </Typography>
                  <Button onClick={()=> navigate('/login')} variant="contained" data-testid="SubmitBtn" startIcon={<UploadIcon />}>
                    Submit your Candidate
                  </Button>
                </Box>
              </Box>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
