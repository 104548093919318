import { createSvgIcon } from "@mui/material/utils";

export const Tag = createSvgIcon(
  <svg viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.0659 0.6875H19.6872H29.8956H31.3956V2.1875V3.60417H34.2706H35.7706V5.10417V15.3125V15.9338L35.3312 16.3732L19.2896 32.4148L18.2289 33.4755L17.1682 32.4148L14.5831 29.8297L13.4562 30.9565L12.3956 32.0172L11.3349 30.9565L1.12658 20.7482L0.065918 19.6875L1.12658 18.6268L18.6266 1.12684L19.0659 0.6875ZM16.7044 27.7083L18.2289 29.2328L32.7706 14.6912V6.60417H31.3956V12.3958V13.0172L30.9562 13.4565L16.7044 27.7083ZM28.3956 3.6875H25.5746C26.9825 3.992 28.0911 5.10056 28.3956 6.50851V5.10417V3.6875ZM28.3956 8.07482C28.0365 9.73518 26.5592 10.9792 24.7914 10.9792C22.7549 10.9792 21.1039 9.32822 21.1039 7.29167C21.1039 5.52385 22.3479 4.04659 24.0083 3.6875H20.3086L4.30856 19.6875L12.3956 27.7745L13.5224 26.6477L28.3956 11.7745V8.07482ZM24.7914 6.60417C24.4117 6.60417 24.1039 6.91197 24.1039 7.29167C24.1039 7.67136 24.4117 7.97917 24.7914 7.97917C25.1711 7.97917 25.4789 7.67136 25.4789 7.29167C25.4789 6.91197 25.1711 6.60417 24.7914 6.60417Z"
      fill="#4540DB"
    />
  </svg>,
  "Tag"
);
