import { FormControl } from "@material-ui/core";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  UpdateAccountDetails,
  UpdateClientPhoto,
} from "../../../../Services/clientService";
import {
  companyNameRegExp,
  phoneNumberRegex,
  urlRegExp,
} from "../../../../constants/regex";
import { preferredCountries } from "../../../../data/phoneInput/preferredCountries";
import { updateImage } from "../../../../features/recruiterSlice";
import { setLoggedInUseDetails } from "../../../../features/userDetails/loggedInUserDetailsSlice";
import { ImageInfo } from "../../../../models/ImageInfo";
import { loadImage } from "../../../../utils/getFileFromUrl";
import PhoneVerify from "../../../Common/PhoneVerify/PhoneVerify";
import UploadPhotoDialog from "../../../Common/UploadPhotoDialog";
import { Asterisk } from "../../../ui/Asterisk";
import { ErrorMessage } from "../../../ui/ErrorMessage";
import "../AccountDetails.scss";
import { valueOrDefault } from "../../../../utils/complexityUtils";
import RenderPhoto from "./RenderPhoto";
import { isValidPhoneNumber } from "react-phone-number-input";
import { pageStatus } from "../ManageProfile";

interface AccountDetailsProps {
  userInfo?: any;
  onChildFunction: any;
  clientId?: string;
  setPageStatus: any;
  pageStatus:pageStatus;
  emailVerifyMode: boolean;
  emailVerificationSuccess: boolean;
  handleChangeEmail: (isUpdate: boolean, type: string) => void;
}
export const AccountDetails: React.FC<AccountDetailsProps> = ({
  userInfo,
  onChildFunction,
  clientId,
  setPageStatus,
  pageStatus,
  emailVerifyMode,
  emailVerificationSuccess,
  handleChangeEmail,
}) => {

  const dispatch = useDispatch();
  const loggedInUserDetails = useSelector(
    (state:any) => state?.loggedInUserDetails
  );
  const initialState = {
    clientId: clientId,
    companyWebsite: userInfo?.companyWebsite,
    companyName: "",
    primaryPhoneNumber: "",
    isPrimaryPhoneVerified: true,
    email: "",
  };
  const accountDetailsSchema = Yup.object({
    companyWebsite: Yup.string().required("Please enter company website").matches(
      urlRegExp,
      "Please enter valid URL starting with http or https"
    ),
    companyName: Yup.string()
      .required("Please enter company name")
      .matches(companyNameRegExp, "Please enter valid company name"),
    primaryPhoneNumber: Yup.string()
      .required("Please enter the phone number")
      .matches(phoneNumberRegex, "Entered phone number is invalid"),
    email: Yup.string()
      .email("Entered email address is invalid")
      .required("Please enter a valid email address"),
  });
  const [imageInfo, setimageInfo] = React.useState<any>({});
  const [isNewImage, setIsNewImage] = React.useState(false)

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: accountDetailsSchema,
    onSubmit: async (values, actions) => {
      setPageStatus({
        ...pageStatus,
        loading:true,
      });
      let updatePhotoBody = {
        clientId,
        companyLogo: {
          resourceUrl: imageInfo?.resourceUrl,
          fileName: imageInfo?.fileName,
          newFileName: imageInfo?.newFileName,
        },
      };      
      if (emailVerificationSuccess) {
        handleChangeEmail(true, "OK");
      }
      UpdateClientPhoto(updatePhotoBody);
      await UpdateAccountDetails(values).then((response:any)=>{
        if(response?.data?.message?.appStatusCode=='CL_RSF')
        {
          setPageStatus({
            loading:false,
            isSuccess: true,
            isError: false,
            message: "Account Details Updated Successfully.",
            key: Date.now(),
          });
          // Update the username in header          
          dispatch(setLoggedInUseDetails({...loggedInUserDetails, userName:valueOrDefault(values?.companyName, "")}))
        }
        else
        {
          setPageStatus({
            isSuccess: false,
            isError: true,
            message: response?.data?.message?.appStatusDescription,
            key: Date.now(),
          });

        }
      });
     
      dispatch(updateImage({ imageUrl: imageInfo?.resourceUrl }));
    },
  });
  const [imgData, setImageData] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const [isEmailVerified, setIsEmailVerified] = useState<boolean>(false);
  const [verifiedEmailId, setVerifiedEmailId] = useState<string>("");

  const onPrimaryPhChange = (ev: any) => {
    if (formik.values.primaryPhoneNumber.replace(/-/g, "") != ev) {
      formik.setFieldValue("isPrimaryPhoneVerified", false);
    }

    formik.setFieldValue("primaryPhoneNumber", ev);
  };
  const childFunction = () => {    
    formik.handleSubmit(); 
  };

  // Make the child function accessible through the prop
  onChildFunction(childFunction);

  React.useEffect(() => {
    formik.setFieldValue("companyWebsite", userInfo?.companyWebsite);
    formik.setFieldValue(
      "email",
      emailVerifyMode ? userInfo?.emailChangeRequest?.email : userInfo?.email
    );
    formik.setFieldValue("companyName", userInfo?.companyName);
    formik.setFieldValue(
      "primaryPhoneNumber",
      userInfo?.contactDetails?.primaryPhoneNumber
    );
    formik.setFieldValue("clientId", userInfo?.id);
    formik.setFieldValue(
      "isPrimaryPhoneVerified",
      userInfo?.contactDetails?.isPrimaryPhoneVerified
    );

    loadImage(
      userInfo?.clientAdditionalDetails?.companyLogo?.resourceUrl,
      setImageData
    );

    if (emailVerificationSuccess) {
      setIsEmailVerified(true);
      setVerifiedEmailId(userInfo?.emailChangeRequest?.email);
    } else {
      if (emailVerifyMode) {
        setIsEmailVerified(false);
      } else {
        setIsEmailVerified(userInfo?.isEmailVerified);
        setVerifiedEmailId(userInfo?.email);
      }
    }
  }, [userInfo, emailVerifyMode, emailVerificationSuccess]);

  useEffect(()=>{
    if(!isNewImage){
      setimageInfo({
        resourceUrl: userInfo?.clientAdditionalDetails?.companyLogo?.resourceUrl,
        fileName: userInfo?.clientAdditionalDetails?.companyLogo?.fileName,
        newFileName: userInfo?.clientAdditionalDetails?.companyLogo?.newFileName    
    })
    }
    
  },[userInfo?.clientAdditionalDetails?.companyLogo,isNewImage])

  const handleOpenCloseDialog = (imageInfo: ImageInfo) => {
    setPageStatus({
      ...pageStatus,
      loading:true,
    });
    if (imageInfo && imageInfo?.fileName ) {
      UpdateClientPhoto({
        clientId: clientId,

        companyLogo: {
          resourceUrl: imageInfo?.imageUrl,
          fileName: imageInfo?.fileName,
          newFileName: imageInfo?.newFileName,
        },
      });
      loadImage(imageInfo.imageUrl, setImageData);
      setIsNewImage(true)
      setimageInfo({
        resourceUrl: imageInfo?.imageUrl,
        fileName: imageInfo?.fileName,
        newFileName: imageInfo?.newFileName    
      })
      dispatch(updateImage({ imageUrl: imageInfo?.imageUrl }));
      setPageStatus({
        ...pageStatus,
        loading:false,
      });
    }
    if (imageInfo === undefined) {
      setimageInfo({});
      UpdateClientPhoto({
        clientId: clientId,
        companyLogo: {},
      });
      dispatch(updateImage({})); 
    }
    setOpen(!open);
  };
  const props_Phone1Verify = {
    testId: "primaryPhoneBtn",
    userId: formik.values.clientId,
    phoneNumber: formik.values.primaryPhoneNumber,
    isPrimaryPhone: true,
    otherPhoneVerifyStatus: formik.values.isPrimaryPhoneVerified,
    updatePhoneVerify: (isVerified: boolean) => {
      formik.setFieldValue("isPrimaryPhoneVerified", isVerified);
    },
  };


  return (
    <div>
      <Box className="padding-ac-details">
        <Grid container className="cust-details-mr" spacing={1}>
          <Grid className="cust-upload-mr" item xs={12} md={12}>
            <Typography className="custom-details-txt">Details</Typography>
          </Grid>
          <Grid style={{ marginBottom: "40px" }} item xs={12} md={12}>
            <Stack direction="row" spacing={2}>
              <div data-testid="renderPhotoDiv" onClick={() => setOpen(!open)}>
                <RenderPhoto imageInfo={imageInfo} imgData={imgData} />
              </div>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              className="form-field-txt"
              required
              name="companyName"
              label="Company Name"
              value={formik.values.companyName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched?.companyName &&
                Boolean(formik.errors?.companyName)
              }
              helperText={
                formik.touched?.companyName && formik.errors?.companyName
              }
              variant="standard"
              fullWidth
              type="text"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
               required
              className="form-field-txt"
              name="companyWebsite"
              label="Company Website"
              value={valueOrDefault(formik?.values?.companyWebsite, "")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched?.companyWebsite &&
                Boolean(formik.errors?.companyWebsite)
              }
              helperText={
                formik.touched?.companyWebsite && formik.errors?.companyWebsite
              }
              variant="standard"
              fullWidth
              type="text"
            />
            
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <div className="verifi-icon">
              <TextField
                data-testid="email-verified-icon"
                className="form-field-txt"
                required
                id="state"
                name="email"
                disabled
                label="Email"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {isEmailVerified &&
                        verifiedEmailId === formik.values.email && (
                          <CheckCircleRoundedIcon className="verifi-success-icon" />
                        )}
                    </InputAdornment>
                  ),
                }}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched?.email && Boolean(formik.errors?.email)}
                helperText={formik.touched?.email && formik.errors?.email}
                variant="standard"
                fullWidth
                type="text"
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={10} md={4}>
            <Box className="phone-number-wrapper">
              <FormControl variant="standard" required fullWidth
               error={
                formik.touched.primaryPhoneNumber &&
                Boolean(formik.errors.primaryPhoneNumber)
              }>
                <span className="phoneNumberLabel">
                  Phone Number <Asterisk />
                </span>
                <PhoneInput
                  inputProps={{'data-testid':'primaryPhone'}}
                  containerClass="phoneCountryCustom"
                  placeholder="Phone"
                  country={"us"}
                  preferredCountries={preferredCountries}
                  preserveOrder={["preferredCountries"]}
                  onChange={(ev: any) => {
                    formik.setFieldValue("primaryPhoneNumber", ev);
                    onPrimaryPhChange(ev);
                  }}
                  value={formik.values?.primaryPhoneNumber}
                />
                {formik.values?.isPrimaryPhoneVerified ?? false ? (
                  <CheckCircleRoundedIcon
                    style={{
                      paddingLeft: "10px",
                      position: "absolute",
                      left: "100.28%",
                      right: "-6.31%",
                      top: "45%",
                      height: "30px",
                      width: "25px",
                      bottom: "-7.5%",
                    }}
                    className="verifi-success-icon"
                  />
                ) : null}
                <ErrorMessage
                    errorText={
                      formik.touched.primaryPhoneNumber &&
                      formik.errors.primaryPhoneNumber
                    }
                  />
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={2} md={2}>
          {!formik.values?.isPrimaryPhoneVerified &&  isValidPhoneNumber('+'+ formik.values?.primaryPhoneNumber) ?(
            <PhoneVerify {...props_Phone1Verify}></PhoneVerify>
          ):""}
        </Grid>
        </Grid>
      </Box>
      {open && (
        <UploadPhotoDialog
          handleOpenClose={handleOpenCloseDialog}
          open={open}
        />
      )}
    </div>
  );
};
