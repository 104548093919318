import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import featuredJobIcon from "../../../assets/images/featured-job.svg";
import { PermissionsEnums } from "../../../data/permission";
import { PageName } from "../../../models/app/Page/PageModel";
import { UserAuth } from "../../../models/interfaces";
import { getNevLink } from "../../../utils/getFileFromUrl";
import { jobLocation } from "../../../utils/jobUtils";
import { ModuleComponent } from "../../Common/Permission/ModuleComponent";
import { JobStatus } from "../../ui/JobStatus";
import { Payrate } from "../../ui/Payrate";
import MoveToPendingJob from "./Components/MoveToPendingJob";
import "./JobPage.scss";
import { ternary, valueOrDefault } from "../../../utils/complexityUtils";
import { useDispatch, useSelector } from "react-redux";
import { setBreadCrumbsState } from "../../../features/Common/BreadcrumbsStateSlice";
import { AppState } from "../../../store/AppState";
import { getIsClientModuleEnabled } from "../../../utils/AppConfig";
import moment from "moment";


export interface ClientJobRowProps {
  key:string | number;
  row: any;
  index: number;
  page: string | undefined;
  recruiterData: any;
  userId: string;
  recruiterID?:string;
  userRole: UserAuth;
  setDisplayConfirmPopup: any;
  setCallback: any;
  parent: any;
  setDisplayContactPopup: any;
  setcontactInformationData: any;
  checkedSelectedJobRow: any;
  actionType: any;
  selectedNotActiveJob: any;
  resposeMessage: any;
  setResponseMessage: any;
  handleOnSuccessMoveToPending: () => void;
  pageNameType?: PageName.ALLJOBPAGE | PageName.SAVEDJOBPAGE;
}

export const JobRow = ({
  key,
  row,
  index,
  page,
  recruiterData,
  userId,
  recruiterID,
  userRole,
  setDisplayConfirmPopup,
  setCallback,
  parent,
  setDisplayContactPopup,
  setcontactInformationData,
  checkedSelectedJobRow,
  actionType,
  selectedNotActiveJob,
  setResponseMessage,
  resposeMessage,
  handleOnSuccessMoveToPending,
  pageNameType,
}: ClientJobRowProps) => {

  const isClientModuleEnabled: boolean = getIsClientModuleEnabled();
  const currentDate = new Date();
  const dispatch = useDispatch();
  const location = useLocation();
  
  const breadCrumbState = useSelector((state:AppState) => state.BreadcrumbsState);

  const [isDisabled, setIsDisabled] = useState(row?.isDisabled ?? false);

  const [isFeatured, setisFeatured] = useState(
    ternary(row?.isFeatured === null, false, row?.isFeatured)
  );
  const isNotActive = ternary(row?.isDisabled === null, false, row?.isDisabled);

  let { fromPage } = useParams();

  const toggleJobActiveStatus = (
    jobId: any,
    jobTitle: any,
    setDisabled: boolean
  ) => {
    if (setDisabled) {
      let myCallback = () => {
        setDisplayConfirmPopup(false);
        checkedSelectedJobRow(
          jobId,
          jobTitle,
          isFeatured !== null ? isFeatured : row?.isFeatured,
          isNotActive !== null ? !isNotActive : row?.isDisabled,
          "notActive",
          row?.recruiterSavedJobsCount
        );
        setIsDisabled(setDisabled);
        setisFeatured(false);
      };
      setCallback(() => myCallback);
    } else {
      setIsDisabled(setDisabled);
    }

    checkedSelectedJobRow(
      jobId,
      jobTitle,
      isFeatured !== null ? isFeatured : row?.isFeatured,
      isDisabled !== null ? !isDisabled : row?.isDisabled,
      "notActive",
      row?.recruiterSavedJobsCount
    );
  };
  const updateFeatureJob = (e: any) => {
    if (isFeatured == null) {
      setisFeatured(!row.isFeatured);
    } else {
      setisFeatured(!isFeatured);
    }

    checkedSelectedJobRow(
      e.target.name,
      row?.jobTitle,
      isFeatured !== null ? !isFeatured : row?.isFeatured,
      isNotActive !== null ? isNotActive : row?.isDisabled,
      "feature",
      row?.recruiterSavedJobsCount
    );
  };

  useEffect(() => {
    if (actionType === "feature") {
      if (selectedNotActiveJob.jobId === row?.id) {
        console.log();
        setisFeatured(selectedNotActiveJob.isFeatured);
      }
    }
  }, [actionType, selectedNotActiveJob]);

  useEffect(() => {
    if (resposeMessage?.length === 0) return;

    const timeId = setTimeout(() => {
      setResponseMessage("");
    }, 4000);

    return () => {
      clearTimeout(timeId);
    };
  }, [resposeMessage]);

  const navigate = useNavigate();

  function showIDandReference() {
    return (
      page == "all" ||
      (
        page == "pending" &&
        !(
          userRole == UserAuth.Client ||
          userRole == UserAuth.ClientTeamMember
        )
      ) ||
    pageNameType == PageName.SAVEDJOBPAGE);
  }

  function renderIDAndReference() {
    if (userRole === UserAuth.NotLoggedIn) return <>{""}</>;

    return (
      <>
        {showIDandReference() && (
          <TableCell
            className=""
            align="left"
            width={"13.1%"}
            sx={{
              whiteSpace: "nowrap",
              px: 1,
              width: 100,
              fontSize: "12px !important",
            }}
          >
            <Link
              target={"_blank"}
              to={getNevLink(`/jobdetail/${row.id}/${page}`)}
              style={{ textDecoration: "none" }}
              className="custom-client-companyname"
            >
              {row?.id}
            </Link>
            <br />
            <Typography
              color="textPrimary"
              component="span"
              fontSize={"14px"}
            >
              {row?.referenceNumber}
            </Typography>
          </TableCell>
        )}
        {" "}
      </>
    );
  }

  function renderTypeAndTitleForAllPage() {
    return (
      <>
        <TableCell
          align="left"
          sx={{
            whiteSpace: "nowrap",
            px: 1,
            width: 100,
            fontSize: "12px !important",
          }}
        >
          {row?.jobType}{" "}
        </TableCell>
        <TableCell
          className="custom-col-width"
          sx={{
            maxWidth: "200px",
            px: 1,
            width: 100,
            fontSize: "12px !important",
          }}
          style={{ verticalAlign: "top" }}
          align="left"
        >
          {row.jobTitle}{" "}
          {row.isFeatured && (
            <Tooltip title="Featured Job">
              <img className="ht-23 featurejobIcon" src={featuredJobIcon} />
            </Tooltip>
          )}
        </TableCell>
      </>
    );
  }

  function renderTypeAndTitle() {
    if (page == "all") return renderTypeAndTitleForAllPage();
    
    return (
      <>
        <TableCell
          className="custom-col-width"
          sx={{
            maxWidth: "200px",
            px: 1,
            width: 100,
            fontSize: "12px !important",
          }}
          style={{ verticalAlign: "top" }}
          align="left"
        >
          {row.jobTitle}
        </TableCell>
        <TableCell
          align="left"
          sx={{
            whiteSpace: "nowrap",
            px: 1,
            width: 100,
            fontSize: "12px !important",
          }}
        >
          {row?.jobType}{" "}
        </TableCell>
      </>
    );
  }

  function renderClientName() {
    return (
      <>
        {userRole !== UserAuth.NotLoggedIn && (
          <TableCell
            className="custom-col-width"
            align="left"
            sx={{
              px: 1,
              width: 100,
              fontSize: "12px !important",
            }}
          >
            {valueOrDefault(row?.clientName, "N/A")}
            <Typography className="locationfont">{jobLocation(row?.remoteJob, row?.city, row?.stateName)}</Typography>
          </TableCell>
        )}
      </>
    );
  }

  function showContacts() {
    return (userRole === UserAuth.Client && page === "all") ||
      (userRole === UserAuth.ClientTeamMember && page === "all") ||
      userRole === UserAuth.Admin ||
      userRole === UserAuth.SuperAdmin;
  }

  function renderContacts() {
    if (!showContacts()) return <>{""}</>;
    return (
      <ModuleComponent moduleId={PermissionsEnums.Jobs_Partner_Contacts}>
        <TableCell>
          <Button
            data-testid="ContactsBtn"
            variant="outlined"
            size="small"
            className="submit-btn-ht cust-subDetails-btn contact-Btn"
            style={{ marginTop: 0, border: "1px solid #4540DB" }}
            onClick={() => {
              setcontactInformationData({
                accountManager: {
                  recruiterName: row?.recruiterName,
                  recruiterEmail: row?.recruiterEmail,
                  recruiterWorkPhoneNo: row?.recruiterWorkPhoneNo,
                  salesPersonName: row?.salesPersonName,
                  salesPersonEmail: row?.salesPersonEmail,
                  salesPersonWorkPhoneNo: row?.salesPersonWorkPhoneNo,
                },
                locationGroup: row?.locationGroup,
                isClientjob: row?.isAllowedToEdit,
              });
              setDisplayContactPopup(true);
            }}
          >
            Contacts
          </Button>
        </TableCell>
        
      </ModuleComponent>
    );
  }

  function renderPayRate() {
    return (
      <>
        {userRole !== UserAuth.NotLoggedIn && (
          <TableCell
            className="custom-payrate-font"
            sx={{
              whiteSpace: "nowrap",
              px: 1,
              width: 100,
            }}
            style={{ paddingLeft: "28px" }}
          >
            {userRole === UserAuth.Client ||
            userRole === UserAuth.ClientTeamMember ? (
              <Payrate
                packageType={row.billRatePckg?.type}
                packageValue={row.billRatePckg?.billRate}
                currency={row.billRatePckg?.packageCurrency}
              />
            ) : (
              <Payrate
                packageType={row.package?.packageType}
                packageValue={row.package?.packageValue}
                currency={row.package?.packageCurrency}
              />
            )}
          </TableCell>
        )}
      </>
    );
  }

  function isPublishDisabled() {
    if (row?.jobEndDate > currentDate) return true;
    else if (userRole === UserAuth.ClientTeamMember) return false;
    else {
      return ternary(
        Boolean(userId),
        recruiterData?.entity?.status !== "Approved",
        false
      ) || ternary(row?.isDisabled !== null, Boolean(row?.isDisabled), false);
    }
  }

  function renderPublishBtn() {
    return (
      <>
        {page == "pending" && (
          <>
            <ModuleComponent
              moduleId={PermissionsEnums.Jobs_Pending_Publish}
            >
              <Button
                variant="contained"
                size="small"
                className="submit-btn-ht cust-subDetails-btn"
                disabled={isPublishDisabled()}
                onClick={() => {
                  navigate(getNevLink(`/pendingjob/${row.id}`));
                }}
                data-testid="btn-clientjobrow-publish"
              >
                Publish
              </Button>
            </ModuleComponent>
          </>
        )}
      </>
    );
  }

  const handleSubmitButtonNavigation = () => {
    if (userRole !== UserAuth.NotLoggedIn) {
      if(pageNameType === PageName.SAVEDJOBPAGE){
        navigate(
          getNevLink(
            `/submitresume/${row.id}/recruiter`
          )
        );
      }else{
        navigate(
          getNevLink(
            `/submitresume/${row.id}/${
              fromPage == "jobalerts" ? fromPage : parent
            }`
          )
        );
      }
      
    } else {
      navigate("/login", {
        state: {
          redirectUrl: `/submitresume/${row.id}/${parent}`,
        },
      });
    }
  };

  function isSubmitDisabled() : boolean {
    return (
      (row?.jobStatus === "Inactive") ||
      ternary(
        Boolean(userId),
        recruiterData?.entity?.status !== "Approved",
        false
      ) ||
      ternary(row?.isDisabled !== null, row?.isDisabled, false)
    );
  }

  return (
    <TableRow
      className="custom-hover-bgcolor"
      hover
      key={key}
      style={{ padding: 20, backgroundColor: "white" }}
    >
      {renderIDAndReference()}

      {renderTypeAndTitle()}

      <TableCell align="left">{row.jobShortDesc}</TableCell>
      
      {renderClientName()}

      {userRole === UserAuth.NotLoggedIn && (
        <TableCell
          sx={{
            px: 1,
            width: 100,
            fontSize: "12px !important",
          }}
          className="btn-cclose custom-col-width"
        >
          {jobLocation(row?.remoteJob, row?.city, row?.stateName)}
        </TableCell>
      )}
      
      {renderContacts()}

      {renderPayRate()}

      {userRole === UserAuth.NotLoggedIn ? "" : <TableCell style={{whiteSpace:"nowrap"}}>
        {moment.utc(row?.postingDate).format("MMMM DD, YYYY")}
      </TableCell>}
      <TableCell
        className="edit-verify-clr"
        sx={{
          whiteSpace: "nowrap",
          px: 1,
          width: 100,
          fontSize: "12px !important",
        }}
      >
        {row?.hiredCount && (
          <Typography mb={2}>
            <JobStatus
              hiredCount={row?.totalStarts}
              jobStatus={row?.jobStatus}
            />
          </Typography>
        )}

        <Grid display={"flex"}>
          <div>
            {page == "all" && (
              <>
                <ModuleComponent moduleId={PermissionsEnums.Jobs_Edit}>
                  <EditOutlinedIcon
                    onClick={(e) =>
                      userRole !== UserAuth.NotLoggedIn
                        ? navigate(getNevLink(`/editjob/${row.id}`))
                        : navigate("/login", {
                            state: {
                              redirectUrl: `/submitresume/${row.id}/${page}`,
                            },
                          })
                    }
                    data-testid="btn-clientjobrow-edit"
                    style={{
                      cursor: "pointer",
                      height: "20",
                      width: "20px",
                      marginRight: "10px",
                    }}
                  />
                </ModuleComponent>
              </>
            )}

            {(userRole === UserAuth.Admin ||
              userRole === UserAuth.SuperAdmin) && (
              <Typography
                variant="body2"
                gutterBottom
                className="Recruiters-font"
              >
                {row.activeRecruiterJobsCount} Recruiters actively working
              </Typography>
            )}

            {page !== "pending" && (
              <ModuleComponent
                moduleId={ternary(
                  page == "pending",
                  PermissionsEnums.Jobs_Pending_Details,
                  PermissionsEnums.Jobs_Job_Details
                )}
                showIfNotLoggedIn={true}
              >
                <Button
                  variant="text"
                  size="small"
                  className="cust-btn-bgClrDetails submit-btn-ht cust-subDetails-btn"
                  onClick={() =>{
                    if(pageNameType === PageName.SAVEDJOBPAGE && (userRole === UserAuth.Admin || userRole === UserAuth.SuperAdmin)){
                      dispatch(
                        setBreadCrumbsState({
                          ...breadCrumbState,
                          navigateFromPage: PageName.RecruiterList,
                          previousLink:location.pathname
                        })
                      );
                      
                    }else{
                      dispatch(
                        setBreadCrumbsState({
                          ...breadCrumbState,
                          navigateFromPage: PageName.SAVEDJOBPAGE,
                          previousLink:location.pathname
                        })
                      );
                    }                    
                    navigate(getNevLink(`/jobdetail/${row.id}/${page ?? "details"}`), {state:{recruiterID:recruiterID}})
                    }
                  }
                  data-testid="btn-clientjobrow-details"
                >
                  Details
                </Button>
              </ModuleComponent>
            )}

            { isClientModuleEnabled &&
            row?.isAllowedToEdit &&
              (userRole === UserAuth.Admin ||
                userRole === UserAuth.SuperAdmin) && (
                <MoveToPendingJob
                  jobId={row?.id}
                  onSuccess={handleOnSuccessMoveToPending}
                />
              )}

            {userRole == UserAuth.Recruiter ||
            userRole == UserAuth.NotLoggedIn ? (
              <Grid>
              <Button
                variant="outlined"
                size="small"
                className="submit-btn-ht cust-subDetails-btn"
                style={{
                  marginTop: "11px",
                }}
                disabled={isSubmitDisabled()}
                onClick={handleSubmitButtonNavigation}
              >
                Submit
              </Button>
              </Grid>
            ) : null}

            {
            ternary( row?.isAllowedToEdit , isClientModuleEnabled , true )  &&
            page == "all" && (
              <div className="custom-checkbox-alg">
                <ModuleComponent moduleId={PermissionsEnums.Jobs_Mark_Featured}>
                  <FormControlLabel
                    className="custom-check"
                    control={
                      <Checkbox
                        data-testid="updateFeatureJob"
                        name={row?.id}
                        checked={
                          isFeatured !== null ? isFeatured : row?.isFeatured
                        }
                        onChange={updateFeatureJob}
                      />
                    }
                    label="Featured"
                    disabled={isDisabled}
                  />
                </ModuleComponent>
              </div>
            )}

            {            
            ternary( row?.isAllowedToEdit , isClientModuleEnabled , true )  &&
            page == "all" &&
              (userRole === UserAuth.Client ||
                userRole === UserAuth.Admin ||
                userRole === UserAuth.SuperAdmin) && (
                <div className="custom-checkbox-alg">
                  <ModuleComponent
                    moduleId={PermissionsEnums.Jobs_Mark_Not_Active}
                  >
                    <FormControlLabel
                      className="custom-check"
                      control={
                        <Checkbox
                          data-testid="toggleJobActiveStatus"
                          name={row?.id}
                          checked={isDisabled}
                          onChange={() =>
                            toggleJobActiveStatus(
                              row?.id,
                              row?.jobTitle,
                              !isDisabled
                            )
                          }
                        />
                      }
                      label={"Not Active"}
                    />
                  </ModuleComponent>
                  {selectedNotActiveJob?.jobId === row?.id ? (
                    <Collapse in={resposeMessage} className="custom--txt-wrp">
                      {" "}
                      {resposeMessage}{" "}
                    </Collapse>
                  ) : (
                    ""
                  )}
                </div>
              )}

            {renderPublishBtn()}
          </div>
        </Grid>
      </TableCell>
    </TableRow>
  );
};
