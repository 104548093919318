import { Component } from "react";
import Chart from "react-apexcharts";

class RecruiterOnboardedTrendChart extends Component {
  constructor(props) {
    let dates = props?.data?.map(x=>x.month)
    let scores = props?.data?.map(x=>x.count)
    super(props);

    this.state = {
      series: [
        {
          name: "Onboarded Trend",
          data: scores,
        },
      ],
      options: {
        chart: {
          height: 400,
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#4540DB"],
        markers: {
          size: [6, 6],
        },
        stroke: {
          curve: "straight",
          width: 2,
        },
        grid: {
          row: {
            colors: ["#ffffff", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: dates,
        },
      },
    };
  }

  render() {
    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type="line"
        width="100%"
      />
    );
  }
}

export default RecruiterOnboardedTrendChart;
