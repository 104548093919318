import { Card, CardContent } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function TotalResponses({data}) {
  return (
    <Card
      sx={{ height: "100%" }}
      className="card-custom-shadow total-responses-card"
    >
      <CardContent sx={{ padding: 0 }}>
        <Grid container spacing={3}>
          <Grid
            item
            sx={{
              padding: 0,
              width: "100%",
              height: "137px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                background:
                  "linear-gradient(225deg, #8E8AFF 35.01%, #6D68FC 100%)",
                height: "40px",
                padding: "15px",
              }}
              className="total-responses-card-header"
            >
              <Grid
                container
                spacing={3}
                sx={{ justifyContent: "space-around" }}
              >
                <Grid item>
                  <Typography variant="subtitle2" component="div">
                    Average Time
                  </Typography>
                  <Typography variant="subtitle1" component="div">
                  &nbsp;{data?.averageTime} Hrs                 
                   </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle2" component="div">
                    Completion Rate
                  </Typography>
                  <Typography variant="subtitle1" component="div">
                  &nbsp;{data?.completionRate ?? "" +'%'}

                  </Typography>
                </Grid>
                <Grid item>
                  <CircularProgress
                    color="secondary"
                    variant="determinate"
                    value={data?.completionRate}
                    thickness={8}
                    sx={{
                      marginTop: "5px",
                      borderRadius: "50%",
                      boxShadow: "inset 0 0 1px 6px #fff",
                      backgroundColor: "transparent",
                    }}
                    style={{ color: "#4540DB" }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                margin: "15px",
                padding: "15px 15px 25px 15px",
                borderBottom: " 1px solid rgba(228, 228, 228, 0.6);",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                color="textPrimary"
                variant="h3"
                display={"inline-block"}
                className="primary-color-text"
              >
                {data?.totalResponse}
              </Typography>
              <Typography variant="h5" display={"inline-block"}>
                &nbsp;Total Responses
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
