import React from "react";
import Grid from "@mui/material/Grid";
import LabelChip from "../LabelChip";
import SectionHeading from "../../../../ui/Form/Heading/SectionHeading";

interface ScorecardCandidateInfoProps {
  tools: string[];
  className: any;
}

const KeywordsTools: React.FC<ScorecardCandidateInfoProps> = ({
  tools,
  className="",
}) => {
  return (
    <Grid container spacing={2} className="keyword-margin">
      <Grid item xs={12}>
        <SectionHeading title="Tools" />
      </Grid>

      {tools?.length> 0 &&  tools.map((toolName) => (
        <Grid item key={toolName} >
          <LabelChip label={toolName} className={className} />
        </Grid>
      ))}
    </Grid>
  );
};

export default KeywordsTools;
