import { Alert, Box } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import * as React from 'react';
import SuccessRightIcon from '../../Icons/SuccessRightIcon';
import ErrorIcon from '@mui/icons-material/Error';
import { ternary } from '../../../../utils/complexityUtils';

interface SnackbarPopupProps {
  message: string;
  open: boolean;
  onClose: () => void;
  severity?: 'success' | 'error'; // Optional severity prop with default
}

const SnackbarPopup: React.FC<SnackbarPopupProps> = ({
  message,
  open,
  onClose,
  severity = 'success',
}) => {

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    onClose();
    if (reason === 'clickaway') {        
      return;
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Snackbar
        open={open}
        autoHideDuration={40000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          data-testid="snackbar-alert-close"
          onClose={handleClose}
          severity={severity}   
          sx={{ width: '100%' }}
          className={ternary(severity === 'success', "snackbarSuccess", "")}
          icon={ ternary(severity === 'success',  <SuccessRightIcon /> , <ErrorIcon />)}
        >
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default SnackbarPopup;
