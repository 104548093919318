import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Alert,
  Button,
  Divider,
} from "@mui/material";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { useNavigate, useLocation, useParams  } from "react-router-dom";
import {
  GetFilterConsolidatedEarnings,
  GetRecruiterEarningsDetails,
  UpdateApproveEarning,
  UpdateEarningsDeatils,
  GetChangeTracker,
  GetRecruiterEarnings,
} from "../../Services/invoiceManagerService";
import getUserDetails from "../../Shared/utils/getUserDetails";
import { CustomAlert } from "../ui/CustomAlert";
import TextField from '@mui/material/TextField';
import { Payrate } from "../ui/Payrate";
import moment from "moment";
import { CompensationTracker } from "./CompensationTracker";
import isObjectEmpty from "../../utils/isObjectEmpty";
import { BreadCrumbItem } from "../../models/AppModels";
import AppBreadCrumb from "../Common/AppBreadCrumb";
import AppInfiniteScroll from "../Common/AppInfiniteScroll";
import BasicDatePicker from "../ui/DateRange";
import { AppDateFormat } from "../../utils/dateUtils";
import TableFooter from '@mui/material/TableFooter';
import { downloadEarningPdf, getNevLink } from "../../utils/getFileFromUrl";
import convertToLocalizedDateTime from "../../utils/AppDateUtils";
import { calculateSumWithPrecision } from "../../utils/numericUtils";
import { ternary } from "../../utils/complexityUtils";
import SectionHeading from "../ui/Form/Heading/SectionHeading";
import SnackbarPopup from "../Common/Popup/snackbar/SnackbarPopup";

type ApprovedProps = {};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

const ApproveEarning: React.FC<ApprovedProps> = (props) => { 
  let { id } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const[customTotal,setCustomTotal] = useState(0);
  const[isExporting,setIsExporting] = useState(false);
  let LoggedUser = getUserDetails();
  const[earningDetails,setEarningDetails] = useState<any>([]);
  const[oneTimeCommission,setOneTimeCommission] = useState<any>([]);
  const [_, setLoading] = useState<boolean>(false);
  const [filterEarningLoading,setFilterEarningLoading] = useState<any>();
  const [hasMoreData,setHasMoreFlag] = useState<boolean>(false);
  const [earningFilterData, setEarningFilterData] = useState<any>([]);
  const reloadChangeTracker = Date.now()
  const [pageStatus, setPageStatus] = useState<any>({
    isSuccess: false,
    isError:false,
    msg: "",
    loading: false,
  });
  const [isApprovedSuccess,setIsApproveSuccsss] =  useState<any>(false);
  const [isApprovedFailure,setIsApproveFailure] =  useState<any>(false);
  const [updateEarningResponse,setUpdateEarningResponse] = useState<any>({
    isSuccess:false,
    isError:false,
    msg:""
  })
  const [isApproved,setIsApproved] = useState(false);
  const initialTrackerFilterState = {
    compensationTypes: [],
    recruiterGuid:  "",
    trackerListType: "IndividualRecruiter",}

  const [changeTrackerFilter, setChangeTrackerFilter] = useState(initialTrackerFilterState);

  const [changeTrackerData,setChangeTrackerData] = useState<any>([]);
  const[fState,setFstate] = useState<any>(state); 
  const [approveState, setApproveState] = useState<any>({
      submissionId:"",
      commissionDueDate:"",
      projectId:"",
      fromDate:"",
      toDate:"",
      approvedById: LoggedUser?.id
  }); 

  const [recruiterEarningState,setRecruiterEarningState] = useState({
    submissionId:"",
    commissionDueDate:"",
    projectId:"",
    fromDate:"",
    toDate:"",
    modifiedById:LoggedUser?.id
  })

  const BreadCrumbFromAdminEarning: BreadCrumbItem[] = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
    { isLeaf: false, sequence: 2, title: "Earnings", to: "/admin/earnings" },
    { isLeaf: true, sequence: 3, title: "Approve",leafClasses:" v2LastLeft" },
  ];

  const BreadCrumbFromAdminEarningViewMode: BreadCrumbItem[] = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
    { isLeaf: false, sequence: 2, title: "Earnings", to: "/admin/earnings" },
    { isLeaf: true, sequence: 3, title: "Preview", leafClasses:" v2LastLeft" },
  ];

  useEffect(()=>{
    getRecruiterEarningDetails();
    getSelectedEarningDetails();
  },[]);

  useEffect(()=>{
    if(changeTrackerFilter?.recruiterGuid !==""){
      getChangeTracker()
    }
  },[changeTrackerFilter?.recruiterGuid])

  useEffect(()=>{
    setRecruiterEarningState({
      ...recruiterEarningState,
      submissionId:earningDetails?.submissionId,
      commissionDueDate:earningDetails?.commissionDueDate,
      projectId:earningDetails?.projectId,
      fromDate:fState?.selectedRow?.fromDate,
      toDate:fState?.selectedRow?.toDate,
    });

    setApproveState({
      ...approveState,
      submissionId:earningDetails?.submissionId,
      commissionDueDate:earningDetails?.commissionDueDate,
      projectId:earningDetails?.projectId,
      fromDate:fState?.selectedRow?.fromDate,
      toDate:fState?.selectedRow?.toDate,
    })

    if(earningDetails?.recruiterGuid){
      setFstate({...fState,filterState:{...fState?.filterState,recruiterGuid:earningDetails.recruiterGuid}})
      setChangeTrackerFilter({...changeTrackerFilter,recruiterGuid:earningDetails?.recruiterGuid});
    }

  },[earningDetails?.recruiterGuid]);

  useEffect(()=>{
    getEarningsByFilter(true);
    getOneTimeCommision();
  },[fState?.recruiterGuid])

  useEffect(()=>{
       
    getEarningsByFilter();
  },[fState?.filterState?.pageIndex])

  // handle to check next records is more or not

  const fetchMoreData = () => {
    
    setFstate({ ...fState,filterState:{...fState.filterState, pageIndex: fState?.filterState?.pageIndex + 1 }});
  }

  //handle Approve btn action

  const handleApprove = ()=>{
    approvedEarning();
    
  }

  // handle Update

  const handleUpdate = ()=>{
    updateEarningsDeatils(recruiterEarningState)
  }

  const updateEarningsDeatils = (payloadData :any)=>{

    setUpdateEarningResponse({
      isSuccess:false,
      isError:false,
      msg:""
    })

    UpdateEarningsDeatils(payloadData)?.then((response)=>{
     
      if(response?.data?.message?.appStatusCode === "RC_RSF"){
        setUpdateEarningResponse({
          isSuccess:true,
          isError:false,
          msg:response?.data?.message?.appStatusDescription
        })
      }else{
        setUpdateEarningResponse({
          isSuccess:false,
          isError:true,
          msg:response?.data?.message?.appStatusDescription
        })
      }

    }).catch((error)=>{
      setUpdateEarningResponse({
        isSuccess:false,
        isError:true,
        msg:error?.data?.message?.appStatusDescription
      })
    }).finally(()=>{ })
  }

  // function to get recruiter earning details
  const getRecruiterEarningDetails = ()=>{
    setLoading(true);
    GetRecruiterEarningsDetails(id)
    ?.then((response) => {
    
      if(response?.data?.message?.appStatusCode === "RC_RSF"){
        setEarningDetails(response?.data?.entity)
      }
      
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => setLoading(false));

  } 

  //  Get Consolidated selected earning details

  const getSelectedEarningDetails = ()=>{
    let payload = fState?.filterState;
    let updatedPayload = {
      ...payload,
      fromDate:fState?.selectedRow?.fromDate,
      toDate:fState?.selectedRow?.toDate,
      recruiterGuid: fState?.selectedRow?.recruiterGuid,
      projectId: fState?.selectedRow?.projectId,
      submissionId: fState?.selectedRow?.submissionId,
    }
    GetFilterConsolidatedEarnings(updatedPayload)
      ?.then((response) => {
        if(response?.data?.message?.appStatusCode === "RC_RSF"){
          
          setIsApproved(response?.data?.entityList[0]?.isApproved);
        }else{
          setIsApproved(false);
        }
        
      })
      .catch((error) => {
        console.log(error);
      })
      .finally();
  }

  // function to get earning filter data

  const getEarningsByFilter = (flag?:boolean) => {
    
    setFilterEarningLoading(true);
    GetRecruiterEarnings({
      ...fState?.filterState,
      fromDate: fState?.selectedRow?.fromDate,
      toDate: fState?.selectedRow?.toDate, 
      contractType:["DirectContract","W2_Employee"] 
    })
      ?.then((response) => {
        if (response?.data?.entityList?.length < fState?.filterState?.pageSize) {
          setHasMoreFlag(false);
        } else {
          setHasMoreFlag(true);
        }
        console.log('response?.data?.entityList', response?.data?.entityList);
        if(response?.data?.entityList?.length > 0){          
          setEarningDetails(response?.data?.entityList[0])
        }else{
          setEarningDetails([])
        }
        if(flag){
          setEarningFilterData([...response?.data?.entityList]);
        }else{
          setEarningFilterData([...earningFilterData, ...response?.data?.entityList]);
        }
        setCustomTotal(response?.data?.customTotal)
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setFilterEarningLoading(false));
  };

  // Get one Time Commision

  const getOneTimeCommision = () => {
    
    setFilterEarningLoading(true);
    GetRecruiterEarnings({
      ...fState?.filterState,
      fromDate: fState?.selectedRow?.fromDate,
      toDate: fState?.selectedRow?.toDate, 
      contractType:["OneTimeCommission"] 
    })
      ?.then((response) => {
        if(response?.data?.entityList?.length > 0){     
          let oneTimeCommission =response?.data?.entityList[0]?.oneTimeCommission;      
          setOneTimeCommission(oneTimeCommission ? oneTimeCommission : 0)
        }else{
          setOneTimeCommission(0)
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setFilterEarningLoading(false));
  };

  // function to action for approved earning
  const approvedEarning = ()=>{
    setPageStatus({
      ...pageStatus,
      isSuccess: false,
      isError:false,
      msg: "",
      loading: true,
    });
    setIsApproved(false)
    UpdateApproveEarning(approveState)
    ?.then((response : any) => {
      
      if(response?.data?.message?.appStatusCode === "RC_RSF"){  
        setIsApproveSuccsss(true);      
      setPageStatus({
        ...pageStatus,
        isSuccess: true,
        isError:false,
        msg: "Earnings have been approved successfully and available for the recruiter also",
        loading: false,
      });
      
      setIsApproved(true)
    }else{
      setIsApproveFailure(true);
    }
    })
    .catch((error : any) => {
      setIsApproveFailure(true);
      setPageStatus({
        ...pageStatus,
        isSuccess: false,
        isError:true,
        msg: error?.data?.message?.appStatusDescription,
        loading: false,
      });
    })
    .finally(() =>  
          setPageStatus({
            ...pageStatus,
            loading: false,
            })
    )
  
  }

  const getChangeTracker = ()=>{
    GetChangeTracker(changeTrackerFilter)
    .then((response) => {
      setChangeTrackerData([...response.data.entityList]);
     })
     .catch((error) => {
       console.log(error);
     })
     .finally(() => {});
  }

  const handleDateChange = (selectedDate : any)=>{    
    let date = moment.utc(selectedDate).format();
    setRecruiterEarningState({
      ...recruiterEarningState,commissionDueDate:date
    });
  }


   const handleExport = () => {
     setIsExporting(true);
     GetRecruiterEarnings({
       ...fState?.filterState,
       fromDate: fState?.selectedRow?.fromDate,
       toDate: fState?.selectedRow?.toDate,
       pageIndex: 1,
       pageSize: customTotal,
       contractType:[
        "DirectContract",
        "W2_Employee",
        "OneTimeCommission"
      ]
     })
       ?.then((res) => {
         downloadEarningPdf(res.data.entityList).finally(() => {
           setIsExporting(false);
         });
       })
       .catch(() => {
         setIsExporting(false);
       });
   };

  function formatEmploymentType(employmentType: any) : any {
    if (employmentType === "DirectContract") return "C2C";
    else if (earningDetails?.employmentType === "W2_Employee") return "W2";
    else if (earningDetails?.employmentType === "FullTime") return "";
    else return earningDetails?.employmentType;
  }

  return (
    <>
    <Grid container spacing={1}>
      <Grid item xs={12}>
          <AppBreadCrumb 
            classes="custom-appbreadcrumb"
            BreadCrumbItems={ternary(
              fState?.mode ==="viewMode",
              BreadCrumbFromAdminEarningViewMode,
              BreadCrumbFromAdminEarning
            )}
          >              
          </AppBreadCrumb>
      </Grid>
      <Grid item xs={12}>
        <Typography className="Client-txt-fontwt">
          Recruiter Earnings           
          <Typography
            variant="h6"
            component="span"
            style={{ color: "grey" }}
          >
            &nbsp; {earningDetails?.recruiterId && `( ${earningDetails?.recruiterId})` }
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} mt={1}>
        <Paper elevation={1}>
          <Grid container spacing={2} pl={2} pr={2}>
            <Grid item xs={12}>
              <SectionHeading
              title="Recruiter Details">
              </SectionHeading>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    className="earningDetailsField"
                    fullWidth
                    label="Recruiter Name"
                    variant="standard"
                    value={earningDetails?.recruiterFullName}
                    disabled={true}
                    InputLabelProps={{ shrink: true }} 
                  />
            </Grid>
            <Grid item xs={12} sm={6} md={4} >
              <TextField
                  className="earningDetailsField"
                  fullWidth
                  label="Candidate Name"
                  variant="standard"
                  value={earningDetails?.candidateName}
                  disabled={true}
                  InputLabelProps={{ shrink: true }} 
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} >
              <TextField
                  className="earningDetailsField"
                  fullWidth
                  label="Canidate email"
                  variant="standard"
                  value={earningDetails?.candidateEmail}
                  disabled={true}
                  InputLabelProps={{ shrink: true }} 
                />
            </Grid>
          </Grid>
          <Grid container mt={1} spacing={2} pl={2} pr={2}>
            <Grid item xs={12} sm={6} md={5}>
            <TextField
              className="earningDetailsField"
              fullWidth
              label="Client"
              variant="standard"
              value={earningDetails?.projectClientName}
              disabled={true}
              InputLabelProps={{ shrink: true }} 
                />
            </Grid>
          </Grid>
          <Grid container mt={1} spacing={2} pl={2} pr={2}>
            <Grid item xs={12} sm={6} md={5}>
              <TextField
                  className="earningDetailsField"
                  fullWidth
                  label="Project Name"
                  variant="standard"
                  value={earningDetails?.projectName}
                  disabled={true}
                  InputLabelProps={{ shrink: true }} 
                />
            </Grid>
            <Grid item xs={12} sm={6} md={3} >
            <TextField
                  className="earningDetailsField"
                  fullWidth
                  label="Project ID"
                  variant="standard"
                  value={earningDetails?.projectId}
                  disabled={true}
                  InputLabelProps={{ shrink: true }} 
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} >
            <TextField
                  className="earningDetailsField"
                  fullWidth
                  label="Contract"
                  variant="standard"
                  value={formatEmploymentType(earningDetails?.employmentType)}
                  disabled={true}
                  InputLabelProps={{ shrink: true }} 
                />
            </Grid>
          </Grid>
          <Grid container mt={1} spacing={2} pl={2} pr={2} pb={fState?.mode !=="ApproveMode" ? 3: 0}>
            <Grid item xs={12} sm={6} md={3}>
            <TextField
                  className="earningDetailsField"
                  fullWidth
                  label="Total Commission"
                  variant="standard"
                  value={fState?.selectedRow?.currencyType +" "+ fState?.totalCommission?.toFixed(2) }
                  disabled={true}
                  InputLabelProps={{ shrink: true }} 
                />
            </Grid>
            <Grid item xs={12} sm={6} md={5} >
                { fState?.mode ==="ApproveMode" && !isApproved  ? 
                <>
                <BasicDatePicker
                    className=""
                    label={"Commission Due Date"}
                    value={recruiterEarningState.commissionDueDate}
                    name="commissionDueDate"
                    onChange={handleDateChange}
                    errorMessage={""}
                    defaultDateFormat={"DD-MM-YYYY"}
                    views={["day"]}
                  />
                </>
                :
                <TextField
                  className="earningDetailsField"
                  fullWidth
                  label="Commission Due Date"
                  variant="standard"
                  value={ earningDetails?.commissionDueDate ? convertToLocalizedDateTime(earningDetails?.commissionDueDate,"MMMM DD, YYYY  h:mm:ss A") : "N/A"}
                  disabled={true}
                  InputLabelProps={{ shrink: true }} 
                />
                  }
            </Grid>
            { (!isApproved && fState?.mode ==="ApproveMode") &&

            <Grid item xs={12} sm={12} mb={2} >
              <Box className="UpdateActionBox">
              
              <Button
                size="small"
                variant="contained"
                component="label"
                onClick={()=>{handleUpdate()}}
                className="approvedEarningCancelBtn"
              >
                Update
              </Button>
              </Box>
            </Grid>
            }
            {
            updateEarningResponse?.isError && (
              <Grid item xs={12} sm={12} md={9} lg={8} mb={2}>
                <CustomAlert key={0} message={updateEarningResponse?.msg} type={'error'}  />
                </Grid>
              )
            }
            {
              updateEarningResponse?.isSuccess && (
                <Grid item xs={12} sm={12} md={9} lg={8}  mb={2}>
                <SnackbarPopup
                    open={updateEarningResponse?.isSuccess}
                    message={"Update done successfully"}
                    onClose={() => {
                      setUpdateEarningResponse({
                        isSuccess: false,
                        isError: false,
                        msg: "",
                      });
                    }}
                  />
                </Grid>
              )
            }
            
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} mt={3}>
        <Paper elevation={1} >
          <Grid container spacing={2} pl={2} pr={2} pb={2}>
            <Grid item xs={12}>
                <SectionHeading title="Compensation Tracker" />
            </Grid>
            <Grid item xs={12}>
            <CompensationTracker
              data={changeTrackerData}
              key={reloadChangeTracker}
              selectedRecruiter={
                isObjectEmpty(earningDetails?.recruiterGuid) ? null : earningDetails?.recruiterGuid
              }
            />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} mt={3}>
        <Paper elevation={1}>
          <Grid container spacing={2} pl={2} pr={2} >
            <Grid item xs={12}>
              <SectionHeading title="Timesheet Entries"></SectionHeading>
            </Grid>
            <Grid item xs={12} mb={3}>
              <TableContainer component={Paper} className="approve-earning-table-compnent">
              <AppInfiniteScroll
                dataLength={earningFilterData?.length}
                next={fetchMoreData}
                hasMore={hasMoreData}
                isLoading={filterEarningLoading}
                loader={true}
                height={ternary(earningFilterData?.length > 0, "40vh", "auto")}
                    >
                <Table stickyHeader={true} >
                  <TableHead>
                    <TableCell align="left">Invoice ID</TableCell>
                    <TableCell align="left">Paid On</TableCell>
                    <TableCell align="left">Date</TableCell>
                    <TableCell align="left">Net Bill Rate</TableCell>
                    <TableCell align="left">Pay Rate</TableCell>
                    <TableCell align="left">Hours Worked</TableCell>
                    <TableCell align="left">Amount Paid</TableCell>
                    <TableCell align="left">Commission (%)</TableCell>
                    <TableCell align="left"> Commission</TableCell>
                  </TableHead>
                  <TableBody>
                  {earningFilterData?.map((row : any,index :string) => {
                  return <TableRow key={row?.clientInvoiceId} className={row?.isAdjustmentEntry ? "adjustment-row" : ""}>
                    <TableCell >{row?.clientInvoiceId}</TableCell>
                    <TableCell>{row?.clientPaymentDate ?  AppDateFormat(row?.clientPaymentDate) : "N/A" }</TableCell>
                    <TableCell>{row?.timeSheetEntryDate ? AppDateFormat(row?.timeSheetEntryDate,"MMMM DD, YYYY") : "N/A" }</TableCell>
                    
                    <TableCell>
                    <Payrate packageType={row?.billRateType} packageValue={row.billRate} currency={row?.currencyType}/>
                      </TableCell>
                    <TableCell>
                    <Payrate packageType={row?.payRateType} packageValue={row.payRate} currency={row?.currencyType}/>
                    </TableCell>
                    <TableCell>{row?.totalHours}</TableCell>
                    <TableCell>
                        {row?.clientInvoiceAmount ?
                        <Payrate packageType={""} packageValue={row?.clientInvoiceAmount?.toFixed(2)} currency={row?.currencyType}/>
                        : "N/A"}
                    </TableCell>
                    <TableCell>{row?.commissionPercentage}</TableCell>
                    <TableCell>  
                      <Payrate packageType={""} packageValue={row?.totalCommission?.toFixed(2)} currency={row?.currencyType}/></TableCell>
                    </TableRow>})}
                    
                  </TableBody>
                  <TableFooter>
                  { earningFilterData?.length > 0  &&
                  <>
                  <TableRow>
                      <TableCell colSpan={8} align="right" >
                        <span className="sumCommisions">Commission : </span>
                      </TableCell>
                      <TableCell colSpan={1}>
                        <Payrate packageType={""} packageValue={fState?.totalCommission?.toFixed(2)} currency={fState?.selectedRow?.currencyType}/>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={8} align="right" >
                        <span className="sumCommisions">One Time Commission : </span>
                      </TableCell>
                      <TableCell colSpan={1}>
                        <span><b>
                          {fState?.selectedRow?.currencyType}{" "}
                          {oneTimeCommission === 0 ? 0 : oneTimeCommission }
                          </b>
                        </span>
                        
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={8} align="right" >
                        <span className="sumCommisions">Total Commission : </span>
                      </TableCell>
                      <TableCell colSpan={1}>
                        <Payrate packageType={""} packageValue={calculateSumWithPrecision(fState?.totalCommission || 0, fState?.selectedRow?.oneTimeCommission || 0)} currency={fState?.selectedRow?.currencyType}/>
                      </TableCell>
                    </TableRow>
                    </>
                    }
                  </TableFooter>
                    {filterEarningLoading && (
                    <p
                      style={{
                        marginTop: "30px",
                        marginBottom: "4px",
                        textAlign: "center",
                        display:"block"
                      }}
                    >
                      Please wait...
                    </p>
                  )}
                </Table>
                </AppInfiniteScroll>
              </TableContainer>
                 
                {!filterEarningLoading && earningFilterData?.length === 0 && (
                  <Alert severity="info" style={{ marginTop: "5px" }}>
                    No Records Found
                  </Alert>
                )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
      <Item>
        <Grid container>
          <Grid item xs={12}>
            <Item>
              <Box className="ApproveActionBox">
              <Button
                size="small"
                variant="outlined"  
                component="label"
                onClick={()=>{ navigate('/admin/earnings/')}}
                className="approvedEarningCancelBtn"
              >
                  Cancel
              </Button>
              <Button
                data-testid="contained"
                size="small"
                variant="contained"
                component="label"
                className="approvedEarningCancelBtn"
                onClick={handleExport}
                disabled={isExporting}
                >
                Export
              </Button>
              {(!isApproved && fState?.mode ==="ApproveMode") &&
              <Button
                size="small"
                variant="contained"
                component="label"
                onClick={()=>{handleApprove()}}                
              >
                Approve
              </Button>
              }
              </Box>
            </Item>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={8} mt={2} mb={4}>
            <Item>
                {
                isApprovedFailure && (
                  <CustomAlert key={21} message={pageStatus?.msg} type={'error'} />
                  )
                }
               {isApprovedSuccess && (
                    <SnackbarPopup
                      open={isApprovedSuccess}
                      message={"Earnings have been approved successfully and available for the recruiter also"}
                      onClose={() => {
                        setIsApproveSuccsss(false); 
                   }}
                    />
                  )}
              </Item>
          </Grid>
        </Grid>

      </Item>
      </Grid>
    </Grid>      
    </>
  );
};

export default ApproveEarning;
