import { makeStyles } from "@material-ui/core";
import SquareIcon from "@mui/icons-material/Square";
import {
  Box,
  Card,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import React from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { GetSubmissions } from "../../Services/candidateService";
import { GetJobById } from "../../Services/jobService";
import getLoggedInUserRole from "../../Shared/utils/getLoggedInUserRole";
import getUserDetails from "../../Shared/utils/getUserDetails";
import getJobType from "../../Shared/utils/getjobType";
import { UserAuth } from "../../models/interfaces";
import getPayRate from "../../utils/getPayRate";
import AppBreadCrumb from "../Common/AppBreadCrumb";
import { Clock as ClockIcon } from "../Common/Icons/Clock";
import { Eye as EyeIcon } from "../Common/Icons/Eye";
import { Interviewing as InterviewingIcon } from "../Common/Icons/Interviewing";
import { MapMarker as MapMarkerIcon } from "../Common/Icons/MapMarker";
import { NoOfOpenings as NoOfOpeningsIcon } from "../Common/Icons/NoOfOpenings";
import { ResumeSubmitted as ResumeIcon } from "../Common/Icons/ResumeSubmitted";
import { Tag as TagIcon } from "../Common/Icons/Tag";
import JobTitle from "../Common/JobTitle";
import Row from "./Row";
import "./viewsubmissions.css";
import { getNevLink } from "../../utils/getFileFromUrl";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));
const useStyles = makeStyles((theme) => ({
  initComment: {
    color: "blue",
  },
  deleteIcon1: {
    "& svg": {
      fontSize: 25,
    },
  },
}));
export default function ViewSubmissions() {
  const [searchParams] = useSearchParams();

  let recruiterGuid = searchParams.get("recruiterGuid") === null ? null : searchParams.get("recruiterGuid");
  let recruiterName = searchParams.get("recruiterName") === null ? null : searchParams.get("recruiterName");


  const classes = useStyles();
  let { id, fromPage } = useParams();
  const userType = getLoggedInUserRole();
  const user = getUserDetails();
  const [submissions, setSubmissions] = React.useState([]);
  const [jobDetails, setJobDetails] = React.useState({});
  const [payload] = React.useState({
    recruiterGuid: userType === UserAuth.Recruiter ? user.id : "",
    jobId: id,
    searchKeywords: "",
    location: "",
    statuses: "",
    contractType: "",
    pageIndex: 1,
    pageSize: 20,
    sortColumn: "createdDate",
    sortOrder: "Descending",
    isSorted: true,
    totalRecord: 0,
  });

  let defaultBreadCrumbsNav = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
    { isLeaf: false, sequence: 2, title: "Submissions", to: "/submissions" },
    { isLeaf: true, sequence: 3, title: "View Submissions" },
  ];
  let submissionfilterBreadCrumbsNav = []
  if(recruiterGuid !==null)
  {
    submissionfilterBreadCrumbsNav = [{ isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
    { isLeaf: false, sequence: 2, title: "Recruiters", to: "/recruiters" },
    { isLeaf: false, sequence: 3, title: "Submissions", to: `/submissionfilter?recruiterGuid=${recruiterGuid}&recruiterName=${recruiterName}` },
    { isLeaf: true, sequence: 4, title: "View Submissions" }]
  }
  else
  {

   submissionfilterBreadCrumbsNav = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
    { isLeaf: false, sequence: 2, title: "Submissions", to: "/submissionfilter" },
    { isLeaf: true, sequence: 3, title: "View Submissions" },
  ];
}

  React.useEffect(() => {
    GetJobById(id)?.then((res) => {
      setJobDetails(res?.data?.entity);
    });
    GetSubmissions(payload)?.then((res) => {
      setSubmissions(res?.data?.entityList);
    });
  }, []);
  let formattedPayRate = getPayRate(
    jobDetails?.package?.packageType,
    jobDetails?.package?.packageValue,
    jobDetails?.package?.packageCurrency
  )
  const jobLocation = () => { 
    if (jobDetails?.remoteJob == 'y') { 
      return jobDetails?.city+", "+jobDetails?.stateName + " (Remote)"
    } 
    return jobDetails?.city+", "+jobDetails?.stateName
  }
  return (
    <div>
     

     
          
          <Grid item xs={12} md={12} className="view-sub">
          <Item elevation={0} >
              <AppBreadCrumb BreadCrumbItems={ fromPage==='submissionfilter' ? submissionfilterBreadCrumbsNav :  defaultBreadCrumbsNav}></AppBreadCrumb>
            </Item>
            <Item elevation={0}>
              <Card sx={{ padding: "0" }}>
                <Grid container sx={{alignItems:"center"}}>
                  <Box className="flex-col-mobile" sx={{width:"100%", display:"flex",alignItems:"center",justifyContent:"space-between",padding:"15px"}}>
                    <Typography
                      variant="h4"
                      component="div"
                      className="abel  letter-spacing-normal primary-color-text"
                      sx={{ paddingTop: "0px" }}
                    >
                      Job Details
                    </Typography>
                    <Box sx={{display:'flex',alignItems:"center",paddingRight:"20px"}}>
                      <SquareIcon className={jobDetails?.jobStatus ==='Active' && jobDetails?.isDisabled !==true? 'viewstyle':'toviewstyle'} />
                      Job Status: {jobDetails?.isDisabled === true ? "Not Active" : jobDetails?.jobStatus}
                    </Box>
                  </Box>
                  <Divider
                    sx={{
                      borderColor: "#4540DB",
                      borderBottomWidth: "unset",
                      width: "100%",
                    }}
                  />
                </Grid>
                <Box className="custom-flex-wrapper">
                  <Box sx={{padding:"15px"}} className="border-right">
                         <Typography >
                         <JobTitle title={jobDetails?.jobTitle} isFeatured={jobDetails?.isFeatured} jobId={jobDetails?.id}/>
                        
                        </Typography>
                        <Box sx={{padding:"15px 0", margin:"0 -15px"}}>
                        <Chip
                          icon={<MapMarkerIcon />}
                          label={jobLocation()}
                          variant="outlined"
                          sx={{padding:"0 15px"}}
                        />
                        <Chip
                          icon={<EyeIcon />}
                          label={jobDetails?.jobExperience + " Years"}
                          variant="outlined"
                          sx={{padding:"0 15px"}}
                        />
                        <Chip
                          icon={<ClockIcon />}
                          label={ getJobType( jobDetails?.jobType,jobDetails?.jobDuraion)
                           
                          }
                          variant="outlined"
                          sx={{padding:"0 15px"}}
                        />
                        <Chip
                          icon={<TagIcon />}
                          label={`Pay Rate ${formattedPayRate.packageValueAndCurrency +" "+ formattedPayRate.packageType}`}

                          variant="outlined"
                          sx={{padding:"0 15px"}}
                        />
                        </Box>
                        <Typography sx={{padding:"0 0 15px"}}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: jobDetails?.jobShortDesc
                          }}
                        />
                      </Typography>
                      <span style={{ fontSize: "1rem" }}>
                        <Link
                          style={{ textDecoration: "none" }}
                          to={`/jobdetail/${jobDetails?.id}/Jobs`}
                        >
                          View Job Posting
                        </Link>
                      </span>
                  </Box>
                  <Box sx={{padding:"15px"}} className="right-side-wrapper">
                  <Chip
                      icon={<ResumeIcon />}
                      label={
                        jobDetails?.candidateSubmissionCount +
                        " Resumes Submitted"
                      }
                      style={{justifyContent:"flex-start"}}
                      variant="outlined"
                      sx={{padding:"15px 0",height:"auto"}}
                    />
                    
                    <Chip
                      icon={<InterviewingIcon />}
                      label="Interviewing : 0"
                      variant="outlined"
                      style={{justifyContent:"flex-start"}}
                      sx={{padding:"15px 0",height:"auto"}}
                    />
                    <Chip
                      icon={<NoOfOpeningsIcon />}
                      label={"No of Openings: " + jobDetails?.totalRemainingPositions}
                      variant="outlined"
                      style={{justifyContent:"flex-start"}}
                      sx={{padding:"15px 0",height:"auto"}}
                    />
                    

                  </Box>
                </Box>
              </Card>
              <Card sx={{ padding: "0",marginTop:"62px" }}>
                <Box sx={{padding:"15px"}}>
                <Typography
                    variant="h4"
                    component="div"
                    className="abel  letter-spacing-normal primary-color-text"
                    sx={{ paddingTop: "6px", paddingLeft: "13px" }}
                  >
                    All Submissions
                  </Typography>
                 
                </Box>
                <Divider
                    sx={{
                      borderBottomWidth: "1px",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                  />
                   <Typography
                    variant="h6"
                    component="div"
                    className="abel  letter-spacing-normal primary-color-text"
                    sx={{ paddingTop: "50px", paddingLeft: "13px" }}
                  >
                    Candidates ({submissions?.length})
                  </Typography>
              <TableContainer
                  component={Paper}
                  className="submission-table submissiontableAll"
                  sx={{maxWidth:"100%"}}
                >
                  
                 
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.headingColor}>
                          Name
                        </TableCell>
                        <TableCell
                          sx={{minWidth:"125px"}}
                          className={classes.headingColor}
                        >
                          Email Address
                        </TableCell>
                        <TableCell
                          sx={{minWidth:"30px!important"}}
                          className={`viewSubmission-currentLocation-th ${classes.headingColor}`}
                        >
                          Location
                        </TableCell>
                        <TableCell className={`viewSubmission-TotalExp-th ${classes.headingColor}`}>
                          Experience
                        </TableCell>
                        <TableCell
                          className={classes.headingColor}
                        >
                          Phone
                        </TableCell>
                        <TableCell
                          className={classes.headingColor}
                        >
                          Pay Rate
                        </TableCell>
                        <TableCell className={`viewSubmission-work-athorization-th ${classes.headingColor}`}>
                          Work Auth
                        </TableCell>
                        <TableCell className={`viewSubmission-resume-th ${classes.headingColor}`}>
                          Resume
                        </TableCell>
                        <TableCell className={`viewSubmission-scorecard-th ${classes.headingColor}`}>
                          Score Card
                        </TableCell>
                        <TableCell
                          width={"12%"}
                          style={{ paddingTop: "10px" }}
                          className={classes.headingColor}
                        >
                          Details
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {submissions?.map((row) => {
                        return <Row key={row.candidateGuid} row={row} jobDetails={jobDetails} fromPage={fromPage} />;
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Item>
          </Grid>
       
    </div>
  );
}
