import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router';
import { getNevLink } from '../../../../utils/getFileFromUrl';
import SuccessIcon from "./../../../../assets/images/Approved.svg";
import './../EditRecruiter.scss';

interface EditRecruiterSuccessPopupProps {
  open: boolean;
  onClose: () => void;
}

const EditRecruiterSuccessPopup: React.FC<EditRecruiterSuccessPopupProps> = ({ open, onClose }) => {

  const navigate = useNavigate();

  const _handleOnUpdatePopupClose = () => {
    onClose();
  };


  return (
    <Dialog open={open}>
      <DialogContent>
        <IconButton
          aria-label="close"
          data-testid="CloseIcon"
          onClick={_handleOnUpdatePopupClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContentText
          component="div"
          id="success-dialog-description"
          textAlign="center"
        >
        <figure>
            <img src={SuccessIcon} className="PopupStatusIcon" alt="Success Icon" />
        </figure>
          <Typography
            component="div"
            className="PopupPrimaryTitle"
            my={2}
          >
            Recruiter Profile Updated 
            <span className='successTextColor'> Successfully</span>
          </Typography>
          <Typography
            component="div"
            fontWeight={600}
            my={2}
            className="PopupSecondaryTitle"
          >
            The recruiter profile has been successfully updated.
            He/She will receive an email for the profile updates.
          </Typography>
          <Box my={3}>
            <Button
              variant="contained"
              onClick={() => {
                navigate(getNevLink("/Dashboard"));
              }}
            >
              Go to Home Page
            </Button>
          </Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default EditRecruiterSuccessPopup;
