import { InputBase } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";

export const Searchbar = ({
  classes="",
  value,
  onChange,
  onEnter,
  onIconClick,
  placeHolderText,
  marginLeft = "8px",
  marginBottom = "30px",
  height = "2.4375em",
  width = "",
  txtFontSize,
  isNewSeachBar
}) => {
  return (
    <>
          {isNewSeachBar ? (
        <>
        <Paper
      component="form"
      sx={{
        display: "flex",
        alignItems: "center",
        width: width ? width : { xs: "96%", md: "450" },
        marginBottom: marginBottom,
        marginLeft: marginLeft,
        backgroundColor:"#FFFFFF!important"
        , border: "1px solid #DFDFE9",
        borderRadius: "4px",
        position:"relative"
      }}
      className={`${classes} jobs-search-box`}
      elevation={0}
    >
      <InputBase
        data-testid="key-button"
        value={value}
        onChange={(e) => {
          onChange(e);
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
            onEnter();
          }
        }}
        sx={{ ml: 1, flex: 1, marginLeft: "-15px",width:"100%"}}
        placeholder={placeHolderText}
        inputProps={{
          style: {
            height: height,
            color: "#7C7C7C",
            fontSize: txtFontSize ? txtFontSize : "16px",
            backgroundColor:"#FFFFFF", 
          },
          "aria-label": placeHolderText,
        }}
        className="roboto-font"
      />

      <IconButton
        onClick={(e) => {
          e.preventDefault();
          onIconClick();
        }}
        data-testid="globalSearchIcon"
        sx={{ p: "5px",position:"absolute",right:"0",mr:"4px" }}
        aria-label="search"
        className="search-icon"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.16675 1.66666C13.3067 1.66666 16.6667 5.02666 16.6667 9.16666C16.6667 13.3067 13.3067 16.6667 9.16675 16.6667C5.02675 16.6667 1.66675 13.3067 1.66675 9.16666C1.66675 5.02666 5.02675 1.66666 9.16675 1.66666ZM9.16675 15C12.3892 15 15.0001 12.3892 15.0001 9.16666C15.0001 5.94333 12.3892 3.33333 9.16675 3.33333C5.94341 3.33333 3.33341 5.94333 3.33341 9.16666C3.33341 12.3892 5.94341 15 9.16675 15ZM16.2376 15.0592L18.5951 17.4158L17.4159 18.595L15.0592 16.2375L16.2376 15.0592V15.0592Z"
            fill="#353535"
          />
        </svg>
      </IconButton>
    </Paper></>
      ) : (
    <Paper
      component="form"
      sx={{
        display: "flex",
        alignItems: "center",
        width: width ? width : { xs: "96%", md: "450" },
        marginBottom: marginBottom,
        marginLeft: marginLeft,
      }}
      className="jobs-search-box"
      elevation={0}
    >
      <InputBase
        value={value}
        onChange={(e) => {
          onChange(e);
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
            onEnter();
          }
        }}
        sx={{ ml: 1, flex: 1, marginLeft: "-15px" }}
        placeholder={placeHolderText}
        inputProps={{
          style: {
            height: height,
            color: "#4540DB",
            fontSize: txtFontSize ? txtFontSize : "16px"
        },
          "aria-label": placeHolderText,
        }}
      />

      <IconButton
        onClick={(e) => {
          e.preventDefault();
          onIconClick();
        }}
        data-testid="onIconClick"
        sx={{ p: "5px", marginRight: '8px'}}
        aria-label="search"
        className="search-icon"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.16675 1.66666C13.3067 1.66666 16.6667 5.02666 16.6667 9.16666C16.6667 13.3067 13.3067 16.6667 9.16675 16.6667C5.02675 16.6667 1.66675 13.3067 1.66675 9.16666C1.66675 5.02666 5.02675 1.66666 9.16675 1.66666ZM9.16675 15C12.3892 15 15.0001 12.3892 15.0001 9.16666C15.0001 5.94333 12.3892 3.33333 9.16675 3.33333C5.94341 3.33333 3.33341 5.94333 3.33341 9.16666C3.33341 12.3892 5.94341 15 9.16675 15ZM16.2376 15.0592L18.5951 17.4158L17.4159 18.595L15.0592 16.2375L16.2376 15.0592V15.0592Z"
            fill="#4540DB"
          />
        </svg>
      </IconButton>
    </Paper>
    )}
    </>
  );
};
