import React from 'react';
import Grid from '@mui/material/Grid';
import LabelChip from '../LabelChip';
import SectionHeading from '../../../../ui/Form/Heading/SectionHeading';
import { Divider } from '@mui/material';

interface CertificationProps {
  certification: string[];
}

const ScorecardCertification: React.FC<CertificationProps> = ({ certification }) => {
  
  return (
    <Grid container spacing={2}>
        <Grid  item xs={12} >
            <SectionHeading
                title='Certification'
            />
             <Divider className="borderedDivider-color" />
        </Grid>
            
        {certification.map((certificationName) => (
            <Grid item key={certificationName}>            
            <LabelChip                  
                label={certificationName}
            />   
            </Grid>        
            ))}
    </Grid>
  );
};

export default ScorecardCertification;
