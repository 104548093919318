import React, { useEffect, useState } from "react";
import EmailVerificationStatus from "./components/EmailVerificationStatus";
import ClientSignUp from "./ClientSignUp";
import { checkVerifyEmailLinkForExpire } from "../../../Services/clientService";
import { useLocation, useSearchParams } from "react-router-dom";
import LoadingScreen from "../../Common/Loder/LoadingScreen";
import { setNotLoggedUserAuthToken } from "../../../utils/setAuthToken";
import { Client } from "../../../models/Client";
import {LoginTypeName } from "../../../models/interfaces";

const ClientSignupComplete: React.FC = () => {

  const location = useLocation();
  const [searchParams]  = useSearchParams();
  const clientSignUpIdParam = searchParams?.get("id");
  const expiryTimeParam = searchParams?.get("expiryDateTime")
  const emailParam = searchParams?.get("email");
  const messageTypeParam = searchParams.get("messageType");
  const authToken = searchParams?.get("authToken") ?? "";

  const [linkExpire,setLinkExpire] = useState({
    isExpire:false,
    loading:true,
    message:""
  })

  const[clientSignUpData,setClientSignUpData] = useState<Client>({
    id:clientSignUpIdParam ?? "",
    email:emailParam ?? "",
    loginType:LoginTypeName?.Email_UserID,
    contactDetails: {
      firstName: "",
      middleName: "",
      lastName: "",
      fullName: "",
      isPrimaryPhoneVerified: false,
    }
  });

  useEffect(()=>{
    let clientSocial = location?.state as Client;
    if (clientSocial !== undefined && clientSocial !== null) {
      setClientSignUpData(clientSocial);
      setLinkExpire({...linkExpire,isExpire:false,loading:false});
    } else if(emailParam !=="" && expiryTimeParam !=="" && messageTypeParam !=="" && clientSignUpIdParam !=="" && authToken!==""){
      verifyLink(emailParam,expiryTimeParam,messageTypeParam,clientSignUpIdParam)
      setNotLoggedUserAuthToken(authToken);
    } else{
      setLinkExpire({...linkExpire,
        isExpire:true,
        loading:false,
        message:"Something went wrong. Please reach out to the administrator for further assistance."
      })
    }
  },[])

   
  const verifyLink = (email:string|null, expiryTime:string|null, messageType:string|null, id:string|null)=>{
    checkVerifyEmailLinkForExpire(email,expiryTime,messageType,id)
      ?.then((response :any )=> {
        if (response?.data?.status === 200 && response?.data?.message?.appStatusCode === 'CL_RSF') {
          setClientSignUpData({...clientSignUpData,
            loginType:response?.data?.entity?.loginType
          })
          setLinkExpire({...linkExpire,isExpire:false,loading:false})
          
        } else if (response?.data?.status === 200 && response?.data?.message?.appStatusCode === 'CL_EXP') {
          setLinkExpire({...linkExpire,isExpire:true,loading:false,
            message:"Your email verification link has expired. Please reach out to the administrator for further assistance."
          })
          
        } else {
          setLinkExpire({...linkExpire,
            isExpire:false,
            loading:false,
            message:"Your email verification link has expired. Please reach out to the administrator for further assistance."
          })
          
        }
      })
      .catch((error:any)=>{
        setLinkExpire({...linkExpire,isExpire:true,loading:false,message:error?.data?.message?.appStatusDescription})
      })
  }

  const renderContent = () => {
    if (linkExpire.loading) {
      return <LoadingScreen />;
    } else if (!linkExpire.loading && !linkExpire.isExpire) {
      return <ClientSignUp clientSignUpData={clientSignUpData} authToken={authToken} />;
    } else {
      return <EmailVerificationStatus expireMessage={linkExpire.message} />;
    }
  };


  return <>{renderContent()}</>;
};

export default ClientSignupComplete;
