import pathToRegexp from "path-to-regexp";
import { Navigate, useLocation } from "react-router-dom";
import recruiterRoutes from "../../components/routes/recruiterRoute";
import { UserAuth } from "../../models/interfaces";
import getLoggedInUserRole from "../utils/getLoggedInUserRole";
import PermissionRoute from "./PermissionRoute";

const RecruiterRoute = ({ children }) => {
  const location = useLocation();
  const user = getLoggedInUserRole();

  if (user!== UserAuth.NotLoggedIn && user===UserAuth.Recruiter) {
    // Check if the current route requires a permissionId
    const route = recruiterRoutes.find((route) => {
      const keys = [];
      const regexp = pathToRegexp(route.path, keys);
      const match = regexp.exec(location.pathname);
      const isExact = match && match[0] === location.pathname;
      return isExact && route.permissionId;
    });
    
    if (route && route.permissionId) {
      // Check if the user has the required permission for the current route
      return (
        <PermissionRoute permissionId={route?.permissionId}>
          {children}
        </PermissionRoute>
      );
    }

    return children;
  }

  return (
    <Navigate
      to="/login"
      replace
      state={{ redirectUrl: location.pathname }}
    />
  );
};

export default RecruiterRoute;
