import { Card, CardContent } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import RelevantRecruitersChart from "./RelevantRecruitersChart";

export default function RelevantRecruiters({data}) {
  return (
    <Card sx={{ height: "100%" }} className="card-custom-shadow">
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
          <Grid
            item
            sx={{
              padding: 0,
              width: "100%",
              height: "100%",
            }}
          >
            <Box sx={{ width: "100%", height: "100%" }}>
              <Typography
                variant="caption2"
                sx={{ fontSize: "14px", fontWeight: "600" }}
              >
                Relevant Recruiters
              </Typography>
              <Box sx={{ marginTop: "0px", width: "100%", height: "100%" }} >
               
                <RelevantRecruitersChart data={data}/>
              
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
