import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Input,
  InputLabel,
  TextField,
} from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import PlacesAutocomplete from "react-places-autocomplete";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { GetTeamMemberById, SetPasswordByTeamMeberId, addClientTeamMember, editClientTeamMember } from "../../Services/clientService";
import { AuthenticateToken } from "../../Services/resetPasswordService";
import getLoggedInUserRole from "../../Shared/utils/getLoggedInUserRole";
import getUserDetails from "../../Shared/utils/getUserDetails";
import { searchCities } from "../../constants/googlePlaceOption";
import { preferredCountries } from "../../data/phoneInput/preferredCountries";
import rawCountries from "../../data/phoneInput/rawCountries";
import TeamMember from "../../models/TeamMember/AddEditTeamMemberModel";
import { TeamMemberDetails } from "../../models/TeamMember/TeamMemberDetails";
import { FormMode } from "../../models/app/AppModels";
import { UserAuth } from "../../models/interfaces";
import { AppState } from "../../store/AppState";
import getAddressForAutoFill from "../../utils/getAddressForAutoFill";
import { getNevLink } from "../../utils/getFileFromUrl";
import { Asterisk } from "../ui/Asterisk";
import { CustomAlert } from "../ui/CustomAlert";
import { ErrorMessage } from "../ui/ErrorMessage";
import './TeamMemberStyle.scss';
import TeamMemberAddEditFormValidationSchema from "./components/FormModel/TeamMemberAddEditFormValidationSchema";
import SuccessSnackBarPopup from "./components/Popup/successSnackBarPopup";
import { valueOrDefault } from "../../utils/complexityUtils";

interface TeamMemberAddEditProps{
  mode: 'ADD' | 'EDIT';
  teamMemberId?:string;
  source?: 'Client' | 'TeamMember';
}

type RequiredCallbacks = {
  source: 'Client' | 'TeamMember';
  onSuccess: () => void;
  onFailure: () => void;
  onClose : () => void;
};

type OptionalCallbacks = {
  source?: 'Client' | 'TeamMember';
  onSuccess?: () => void;
  onFailure?: () => void;
  onClose? : () => void;
};

type PropsWithCallbacks = TeamMemberAddEditProps & (RequiredCallbacks | OptionalCallbacks);

const TeamMemberAddEditForm: React.FC<PropsWithCallbacks> = (props) => {
  
  const navigate = useNavigate(); 
  let userRole = getLoggedInUserRole();
  const initialValues = useSelector(
    (state: AppState) => state.AddEditTeamMemberFormState
  );

  // creating form reference
  const formRef = React.useRef<FormikProps<TeamMember>>(null);

    const [isEmailVerified, setIsEmailVerified] = useState<boolean>(true);
    const [isTokenExpire, setIsTokenExpire] = useState<boolean>(true);

  // page states
  const [pageStatus, setPageStatus] = useState<any>({
    isSuccess: false,
    isError: false,
    message: "",
    loading: false,
  });

  useEffect(()=>{

    if(props?.mode === FormMode.EDIT){
      if (props?.teamMemberId !== undefined) {
        _GetTeamMemberById(props?.teamMemberId);
      }
    }
  },[])

  const _handleSuccessClose = ()=>{    
    setPageStatus({
      isSuccess: false,
      isError: false,
      message: "",
      loading: false,
    });
  }

  // Get the Team member details for edit mode
  const _GetTeamMemberById = (jobId: string) => {
    GetTeamMemberById(jobId)?.then((response) => {
        if (response?.data?.message?.appStatusCode === "CL_RSF") {
            _setTeamMemberDetails(response?.data?.entity);
        } 
      })
      .catch((er) => {
        console.log(er)
      })
    } 

    // Set Team member details for edit mode
    const _setTeamMemberDetails = (teamMemberDetails:TeamMemberDetails)=>{

      setIsEmailVerified(teamMemberDetails?.isEmailVerified);

      formRef.current?.setValues({
        ...formRef.current?.values,
        clientId: teamMemberDetails?.clientId || '',
        email: teamMemberDetails?.email || '',
        firstName: teamMemberDetails?.firstName || '',
        lastName: teamMemberDetails?.lastName || '',
        middleName: teamMemberDetails?.middleName || '',
        primaryPhoneNumber: teamMemberDetails?.primaryPhoneNumber || '',
        secondaryPhoneNumber: teamMemberDetails?.secondaryPhoneNumber || '',
        address: {
          id: teamMemberDetails?.address?.id || '',
          streetAddress: teamMemberDetails?.address?.streetAddress || '',
          city: teamMemberDetails?.address?.city || '',
          state: teamMemberDetails?.address?.state || '',
          postalCode: teamMemberDetails?.address?.postalCode || '',
          countryCode: teamMemberDetails?.address?.countryCode || '',
        }
      });
      if(teamMemberDetails?.authToken !=""){
      _checkAuthenticateToken(teamMemberDetails?.authToken);
      }else{
        setIsTokenExpire(false);
      }
    }

   // Check for token expiry to send the set password link in edit case
   const _checkAuthenticateToken = (authToken:string)=>{
    AuthenticateToken(authToken)
      .then((res) => {
        if (res?.data?.entity?.isExpired === false) {
          setIsTokenExpire(true)
          } else {
            setIsTokenExpire(false)
          }
       }) 
   } 

   // handle the set password link button

   const _handleSetPasswordLink = async ()=>{

    setPageStatus({
      ...pageStatus,
      isSuccess: false,
      isError: false,
      message: "",
    });

    await SetPasswordByTeamMeberId(props?.teamMemberId)
      .then((response: any) => {
        if (response?.data?.message?.appStatusCode === "CL_RSF") {
          setIsTokenExpire(true)
          setPageStatus({
              ...pageStatus,
              isSuccess: true,
              isError: false,
              message: "An Email has been sent successfully to set the password"
            });
        } else {
          setPageStatus({
            ...pageStatus,
            isSuccess: false,
            isError: true,
            message: response?.data?.message?.appStatusDescription,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setPageStatus({
          ...pageStatus,
          isSuccess: false,
          isError: true,
          message: "Something Went wrong!!!",
        });
      });
   }

  // handle submit action
  const _handleSubmit = (values: TeamMember, action:any) => {   
    _SubmitClientTeamMember(values,action);
  };

  function computeClientId() {
    if (userRole==UserAuth.Client) return getUserDetails()?.id;
    else if (userRole==UserAuth.ClientTeamMember) return getUserDetails()?.clientId;
    else return "";
  }

  function handleAddClientTeamMember(response: any, action: any) {
    action.setSubmitting(false);
    if (response?.data?.message?.appStatusCode === "CL_RSF") {
      
      setPageStatus({
        ...pageStatus,
        isSuccess: true,
        isError: false,
        message: "Team member added successfully",
      });
    } else {      
      setPageStatus({
        ...pageStatus,
        isSuccess: false,
        isError: true,
        message: response?.data?.message?.appStatusDescription,
      });
    }
  }

  function handleEditClientTeamMember(response: any, action: any) {
    action.setSubmitting(false);
    if (response?.data?.message?.appStatusCode === "CL_RSF") {
          
      if(props?.source === "Client"){
        props?.onSuccess();
      }
      setPageStatus({
        ...pageStatus,
        isSuccess: true,
        isError: false,
        message: "Team member details updated successfully"
      });
    } else {
      
      if(props?.source === "Client"){
        props?.onFailure();
      }
      setPageStatus({
        ...pageStatus,
        isSuccess: false,
        isError: true,
        message: response?.data?.message?.appStatusDescription,
      });
    }
  }

  const _SubmitClientTeamMember = async (payload:TeamMember,action:any) => {
    setPageStatus({
      ...pageStatus,
      isSuccess: false,
      isError: false,
      message: "",
    });

    if(props?.mode === FormMode.ADD){
      const AddUpdatedPayload = {
        ...payload,
        clientId: computeClientId()
      }

      await addClientTeamMember(AddUpdatedPayload)
      .then((response: any) => handleAddClientTeamMember(response, action))
      .catch((error) => {
        console.log(error);
        action.setSubmitting(false);
        setPageStatus({
          ...pageStatus,
          isSuccess: false,
          isError: true,
          message: "Something Went wrong!!!",
        });
      });
  
    } else if(props?.mode === FormMode.EDIT){
      let EditUpdatedPayload = {
        ...payload,
        id:props?.teamMemberId,
        modifiedById:getUserDetails()?.id
      }
      await editClientTeamMember(EditUpdatedPayload)
      .then((response: any) => {
        handleEditClientTeamMember(response, action);
      })
      .catch((error) => {
        console.log(error);
        action.setSubmitting(false);
        if(props?.source === "Client"){
          props?.onFailure();
        }
        
         setPageStatus({
          ...pageStatus,
          isSuccess: false,
          isError: true,
          message: "Something Went wrong!!!",
        });
      });
  
    }   
  }

  //handle city auto complete
  const autoCompleteGetJobLocation = async (address: any) => {
    let resp = await getAddressForAutoFill(address);
    
    formRef.current?.setFieldValue("address.id", valueOrDefault(resp?.placeId, ""));
    formRef.current?.setFieldValue("address.city", valueOrDefault(resp?.city, ""));
    formRef.current?.setFieldValue("address.state", valueOrDefault(resp?.state, ""));
    formRef.current?.setFieldValue("address.streetAddress", valueOrDefault(resp?.formattedAddress, ""));
    formRef.current?.setFieldValue("address.postalCode", valueOrDefault(resp?.pin, ""));
    formRef.current?.setFieldValue("address.countryCode", valueOrDefault(resp?.countryCode, ""));
  };

  // rendering city field
  const renderFuncCurrentJobLocation = ({
    getInputProps,
    suggestions,
    getSuggestionItemProps,
    loading,
  }: any) => (
    <>
      <FormControl
        variant="standard"
        error={formRef.current?.touched?.address?.city && Boolean(formRef.current?.errors?.address?.city)}
        required
        fullWidth
        className="cust-field-style"
        data-testid="cityinp2"
      >
        <InputLabel htmlFor="address-input">City</InputLabel>
        <Input
          {...getInputProps()}
          name="address.city"
          type="text"
          onBlur={formRef.current?.handleBlur}
          fullWidth
          data-testid="cityinp"
          placeholder="Enter City"
          required
        />
        <ErrorMessage
          errorText={formRef.current?.touched?.address?.city && formRef.current?.errors?.address?.city}
        />        
        <div className="autocomplete-dropdown-container">
          {loading && <div>Loading...</div>}
          {suggestions?.map((suggestion: any) => (
            <div {...getSuggestionItemProps(suggestion)}>
              <span
                onChange={autoCompleteGetJobLocation}
                style={{ cursor: "pointer" }}
              >
                {suggestion?.description}
              </span>
            </div>
          ))}
        </div>        
      </FormControl>
    </>
  );


  return (
    <>
    <Formik
      initialValues={initialValues}
      validationSchema={TeamMemberAddEditFormValidationSchema}
      onSubmit={_handleSubmit}
      innerRef={formRef}
    >
      {({
        isSubmitting,
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        setFieldTouched,
        handleSubmit,
        touched,
        errors,
      }) => (
        <Form onSubmit={handleSubmit} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={props?.source === "Client" ? 6 : 3}>
              <TextField
                required
                name="firstName"
                label="First Name"
                variant="standard"
                fullWidth
                value={values?.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched?.firstName && Boolean(errors?.firstName)}
                helperText={touched?.firstName && errors?.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={props?.source === "Client" ? 6 : 3}>
              <TextField
                name="middleName"
                label="Middle Name"
                variant="standard"
                fullWidth
                value={values?.middleName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched?.middleName && Boolean(errors?.middleName)}
                helperText={touched?.middleName && errors?.middleName}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={props?.source === "Client" ? 6 : 3}>
              <TextField
                required
                name="lastName"
                label="Last Name"
                variant="standard"
                fullWidth
                value={values?.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched?.lastName && Boolean(errors?.lastName)}
                helperText={touched?.lastName && errors?.lastName}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={props?.source === "Client" ? 6 : 3}>
              <TextField
                disabled={props?.mode === FormMode.EDIT ? true :false}
                required
                name="email"
                label="Email"
                variant="standard"
                fullWidth
                value={values?.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched?.email && Boolean(errors?.email)}
                helperText={touched?.email && errors?.email}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={props?.source === "Client" ? 6 : 3}>
              <FormControl
                variant="standard"
                required
                error={
                  touched.primaryPhoneNumber &&
                  Boolean(errors.primaryPhoneNumber)
                }
                fullWidth
                sx={{ paddingTop:"0px !important" }}
              >
                <span className="phoneInputLabel">
                Phone Number <Asterisk />
                </span>
                <PhoneInput
                  containerClass="phoneCountryCustom teamMemberPhoneheight"
                  placeholder="Phone"
                  country={"us"}
                  onChange={(ev: any) => {
                    setFieldValue("primaryPhoneNumber", ev);
                  }}
                  onBlur={() => setFieldTouched('primaryPhoneNumber',true)}
                  onlyCountries={rawCountries.map((x) => x[2]?.toString())}
                  value={values?.primaryPhoneNumber}
                  preferredCountries={preferredCountries}
                  preserveOrder={["preferredCountries"]}
                />{" "}
                
                <ErrorMessage
                    errorText={
                      touched.primaryPhoneNumber &&
                      errors.primaryPhoneNumber
                    }
                  />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={props?.source === "Client" ? 6 : 3}>
              <PlacesAutocomplete
            searchOptions={searchCities}
            value={values?.address?.city}
            onChange={(ev: any) => {
              setFieldValue("address.city", ev);
            }}
            onSelect={autoCompleteGetJobLocation}
          >
            {renderFuncCurrentJobLocation}
          </PlacesAutocomplete>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={props?.source === "Client" ? 6 : 3} mt={1}>
              <TextField
                required
                name="address.state"
                label="State"
                variant="standard"
                fullWidth
                value={values?.address?.state}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched?.address?.state && Boolean(errors?.address?.state)
                }
                helperText={touched?.address?.state && errors?.address?.state}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={12}
              justifyContent="flex-end"
              alignItems="center"
            >
              <Box style={{ display: "flex", justifyContent: "flex-end", marginTop:"16px" }}>
                { (props?.source !== "Client" && !isEmailVerified && !isTokenExpire) &&
                  <Button
                  data-testid="setpasswordlink"
                  className="custNoBorderBtn"
                  type="button"
                  disabled={isSubmitting}
                  style={{ marginRight: "15px" }}
                  onClick={_handleSetPasswordLink}
                >
                  Set Password Link
                </Button>
                }
                { props?.source === "Client" ?
                <>
                  <Button
                  className="custNoBorderBtn"
                  type="button"
                  disabled={isSubmitting && pageStatus?.isSuccess }
                  variant="outlined"
                  style={{ marginRight: "15px" }}
                  onClick={props?.onClose}
                >
                  Cancel
                </Button></>
                :
                <Button
                  className="custNoBorderBtn cancelBtn"
                  type="button"
                  disabled={isSubmitting && pageStatus?.isSuccess}
                  style={{ marginRight: "15px" }}
                  onClick={()=>{ navigate(getNevLink('/teammembers')) }}
                >
                  Cancel
                </Button>
                }
                
                <Button
                  className="custPrimary"
                  type="submit"
                  disabled={isSubmitting}
                  name="Add"
                  value="Add"
                  variant="contained"
                  color="primary"
                  endIcon={ isSubmitting && 
                    <CircularProgress
                      size={"16px"}
                      color={"inherit"}
                    />
                  }
                >
                  {props?.mode === FormMode.EDIT ? 'Update' : 'Add' }
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              {pageStatus?.isSuccess &&
              <SuccessSnackBarPopup open={pageStatus?.isSuccess} message={pageStatus?.message} onClose={_handleSuccessClose} />
              }

              {pageStatus?.isError &&
                  <CustomAlert message={pageStatus.message} type={"error"}/>
              }
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
    </>
  );
};

export default TeamMemberAddEditForm;
