export const candidateInit = {
    id: "",
    email: "",
    firstName: "",
    lastName: "",
    middleInitial: "",
    zip: "",
    country: "",
    address: "",
    city: "",
    state: "",
    phoneNumbers: {
      id: "",
      primary: "",
      secondary: "",
    },
    phone: "",
    experience: "",
    skills: [],
    workAuthorization: "",
    isRemoteUSA: false,
    employeeName: "",
    employerDetails: {
      name: "",
      email: "",
      phoneNumber: "",
      reference: "",
      address: {
        streetAddress: "",
        city: "",
        state: "",
        zip: "",
        country: "",
      },
    },
    payrate: "",
    payrateType: "hourly",
    candidateEmployment: "",
    currentWorkLocation: {
      id: "",
      city: "",
      state: "",
    },
    recruiterComments: "",
    resume: "",
    otherDocument: "",
    resumeInfoObj: {
      resourceUrl: "",
      fileName: "",
      newFileName: "",
    },
    otherDocInfo: [
      {
        resourceUrl: "",
        fileName: "",
        newFileName: "",
        documentType: "",
      },
    ],
    candidateId: "",
    candidateGuid: "",
    canRelocate: false,
    recruiterEmail: "",
    recruiterFirstName: "",
    recruiterMiddleName: "",
    recruiterLastName: "",
    recruiterId:"",
    recruiterGuid:"",
    comments:[],
    recruiterPhoneNumbers : { 
      primary: "", 
      secondary: ""
    }
  };