import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import "./ViewRecruiterInfoPopup.css";
import { IconButton, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import {
  downloadFile,
  getFileFromUrl,
  getFilePreviewUrl,
} from "../../../../utils/getFileFromUrl";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import truncateString from "../../../../utils/truncateString";
import moment from "moment";
import convertToLocalizedDateTime from "../../../../utils/AppDateUtils";
import "./Popup.scss";
import { valueOrDefault } from "../../../../utils/complexityUtils";
interface Props {
  openPopup: boolean;
  handleDocumentsPopupClose: any;
  submission: any;
  setFilePreview: any;
}
interface DocumentInfo {
  name: string;
  resourceUrl: string;
  type: string;
  size: number;
  sizeInMB: string;
  documentType: any;
  createdDate: string;
  modifiedDate: string;
}
interface OtherDoc {
  fileName: string;
  newFileName: string;
  resourceUrl: string;
  documentType: string;
  createdDate: string;
}
export const DocumentsPopup: React.FC<Props> = ({
  openPopup,
  handleDocumentsPopupClose,
  submission,
  setFilePreview,
}) => {
  const [documentsInfo, setDocumentsInfo] = React.useState(
    [] as DocumentInfo[]
  );
  const [loading, setLoading] = React.useState(false);
  const downloadAll = () => {
    documentsInfo?.forEach((x) => {
      downloadFile(x.resourceUrl, x.name);
    });
  };
  const processDocuments = () => {
    setLoading(true);
    let otherDocInfo: OtherDoc[] = submission?.otherDocInfo;
    let resumeInfo: OtherDoc = submission?.resumeInfo;
    let newOtherDocInfo = [...otherDocInfo];

    newOtherDocInfo?.push({
      fileName: resumeInfo?.fileName,
      newFileName: resumeInfo?.newFileName,
      resourceUrl: resumeInfo?.resourceUrl,
      documentType: "2",
      createdDate: resumeInfo?.createdDate,
    });
    async function fetchFileBlob() {
      let documentsArray: DocumentInfo[] = [];
      await Promise.all(
        newOtherDocInfo
          ?.filter(
            (x) =>
              x.documentType.toString() === "2" ||
              x.documentType.toString() === "RTRDoc" ||
              x.documentType.toString() === "OtherDoc" ||
              x.documentType.toString() === "ResumeScoreCard"
          )
          ?.map(async (x: any) => {
            if (x.resourceUrl !== "" && x.fileName !== "") {
              let { documentType, resourceUrl, createdDate, modifiedDate } = x;
              let { name, type, size } = await getFileFromUrl(
                x.resourceUrl,
                x.fileName
              );

              let sizeInMB = (size / 1048576).toFixed(2); //conversion into MBs
              createdDate =
                createdDate &&
                moment(createdDate).utc().format("MMMM DD, YYYY,  h:mm:ss A");
              modifiedDate =
                modifiedDate &&
                moment(modifiedDate).utc().format("MMMM DD, YYYY,  h:mm:ss A");

              let documentInfo: DocumentInfo = {
                name,
                resourceUrl,
                type,
                size,
                sizeInMB,
                documentType,
                createdDate,
                modifiedDate,
              };
              documentsArray.push(documentInfo);
            }
          })
      );
      setDocumentsInfo(documentsArray);
      setLoading(false);
    }
    fetchFileBlob();
  };
  React.useEffect(() => {
    processDocuments();
  }, []);
  return (
    <div>
      <Dialog
        className="custom-popup-border"
        open={openPopup}
        onClose={handleDocumentsPopupClose}
        aria-labelledby="viewRecruiterPopup-title"
        aria-describedby="viewRecruiterPopup-description"
      >
        <Paper elevation={5} style={{ backgroundColor: "#FFF" }}>
          <DialogTitle sx={{ m: 0, p: 2 }}>
            <IconButton
              aria-label="close"
              onClick={handleDocumentsPopupClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "#4F4F4F",
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="viewRecruiterPopup-description">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography align="center" className="custom-document-txt">
                    Documents
                  </Typography>
                </Grid>

                {documentsInfo.find((x) => x.documentType === "2") && (
                  <Box>
                    <Grid container spacing={1} className="recruiterInfoBox">
                      <Grid item xs={12} sm={12}>
                        <Typography className="custom-resume-txt">
                          Resume
                        </Typography>
                      </Grid>
                      <Grid item xs={3.4} sm={3.4}>
                        <Tooltip
                          title={valueOrDefault(
                            documentsInfo.find((x) => x.documentType === "2")
                              ?.name,
                            ""
                          )}
                        >
                          <Typography className="custom-txt">
                            {truncateString(
                              documentsInfo.find((x) => x.documentType === "2")
                                ?.name || "N/A",
                              16
                            )}
                          </Typography>
                        </Tooltip>
                      </Grid>
                      <Divider />
                      <Grid item xs={2} sm={1.5}>
                        <Typography className="custom-txt">
                          {" "}
                          {`${
                            documentsInfo.find((x) => x.documentType === "2")
                              ?.sizeInMB
                          } MB` || "N/A"}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} sm={1.5}>
                          <Typography className="custom-txt">
                            {documentsInfo
                              .find((x) => x.documentType === "2")
                              ?.name?.split(/\.(?=[^\.]+$)/)[1]
                              ?.toUpperCase() || "N/A"}
                          </Typography>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <Typography className="custom-txt">
                          {convertToLocalizedDateTime(
                            documentsInfo.find((x) => x.documentType === "2")
                              ?.createdDate ||
                              documentsInfo.find((x) => x.documentType === "2")
                                ?.modifiedDate ||
                              "N/A",
                            "MMM DD, YYYY h:mm A"
                          )}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        style={{ paddingTop: "5px" }}
                        xs={0.8}
                        sm={0.8}
                      >
                        <Tooltip title={"view"}>
                          <VisibilityIcon
                            onClick={() => {
                              let doc = documentsInfo?.find(
                                (x) => x.documentType === "2"
                              );
                              getFilePreviewUrl(doc?.resourceUrl!).then(
                                (filePreviewUrl) =>
                                  setFilePreview({
                                    resourceUrl: filePreviewUrl,
                                    fileName: doc?.name,
                                  })
                              );
                            }}
                            color="primary"
                            className="pointer custom-preview-dwnload-size"
                          />
                        </Tooltip>
                      </Grid>
                      <Grid
                        style={{ paddingTop: "3.5px" }}
                        item
                        xs={0.8}
                        sm={0.8}
                      >
                        <Tooltip title={"download"}>
                          <SaveAltOutlinedIcon
                            onClick={() => {
                              let doc = documentsInfo?.find(
                                (x) => x.documentType === "2"
                              );
                              downloadFile(doc?.resourceUrl!, doc?.name!);
                            }}
                            color="primary"
                            className="pointer custom-preview-dwnload-size"
                          />
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Divider
                      sx={{
                        width: "98%",
                        height: "1px",
                        background: "#EAEAEA",
                        marginTop: "10px",
                        marginLeft: "8px",
                      }}
                    />
                  </Box>
                )}

                {documentsInfo.find((x) => x.documentType === "OtherDoc") && (
                  <Box>
                    <Grid
                      container
                      spacing={1}
                      maxHeight={150}
                      style={{ overflow: "overlay" }}
                      className="recruiterInfoBox"
                    >
                      <Grid item xs={12} sm={12} style={{ marginTop: "12px" }}>
                        <Typography className="custom-resume-txt">
                          Other Documents
                        </Typography>
                      </Grid>

                      {documentsInfo
                        ?.filter((x) => x.documentType === "OtherDoc")
                        ?.map((doc) => {
                          return (
                            <>
                              <Grid item xs={3.4} sm={3.4}>
                                <Tooltip title={valueOrDefault(doc?.name, "")}>
                                  <Typography className="custom-txt">
                                    {truncateString(doc?.name || "N/A", 14)}
                                  </Typography>
                                </Tooltip>
                              </Grid>
                              <Grid item xs={2} sm={1.5}>
                                <Typography className="custom-txt">
                                  {" "}
                                  {`${doc?.sizeInMB} MB` || "N/A"}
                                </Typography>
                              </Grid>
                              <Grid item xs={2} sm={1.5}>
                                <Typography className="custom-txt">
                                  {" "}
                                  {doc?.name?.split(".")[1]?.toUpperCase() ||
                                    "N/A"}
                                </Typography>
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <Typography className="custom-txt">
                                  {convertToLocalizedDateTime(
                                    doc?.createdDate
                                      ? doc?.createdDate || "N/A"
                                      : doc?.modifiedDate || "N/A",
                                    "MMM DD, YYYY  h:mm A"
                                  )}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                style={{ paddingTop: "5px" }}
                                xs={0.8}
                                sm={0.8}
                              >
                                {doc?.name?.split(".")[1]?.toLowerCase() !==
                                  "zip" && (
                                  <Tooltip title={"view"}>
                                    <VisibilityIcon
                                      onClick={() => {
                                        getFilePreviewUrl(
                                          doc?.resourceUrl
                                        ).then((filePreviewUrl) =>
                                          setFilePreview({
                                            resourceUrl: filePreviewUrl,
                                            fileName: doc?.name,
                                          })
                                        );
                                      }}
                                      color="primary"
                                      className="pointer custom-preview-dwnload-size"
                                    />
                                  </Tooltip>
                                )}
                              </Grid>
                              <Grid
                                style={{ paddingTop: "3.5px" }}
                                item
                                xs={10}
                                sm={0.8}
                              >
                                <Tooltip title="download">
                                  <SaveAltOutlinedIcon
                                    onClick={() => {
                                      downloadFile(doc?.resourceUrl, doc?.name);
                                    }}
                                    color="primary"
                                    className="pointer custom-preview-dwnload-size"
                                  />
                                </Tooltip>
                              </Grid>
                            </>
                          );
                        })}
                    </Grid>
                    <Divider
                      sx={{
                        width: "98%",
                        height: "1px",
                        background: "#EAEAEA",
                        marginTop: "10px",
                        marginLeft: "8px",
                      }}
                    />
                  </Box>
                )}

                {documentsInfo.find(
                  (x) => x.documentType === "ResumeScoreCard"
                ) && (
                  <Box>
                    <Grid
                      container
                      spacing={1}
                      maxHeight={150}
                      style={{ overflow: "overlay" }}
                      className="recruiterInfoBox"
                    >
                      <Grid item xs={12} sm={12} style={{ marginTop: "12px" }}>
                        <Typography className="custom-resume-txt">
                          Score Card
                        </Typography>
                      </Grid>
                      {documentsInfo
                        ?.filter((x) => x.documentType === "ResumeScoreCard")
                        ?.map((doc) => {
                          return (
                            <>
                              <Grid item xs={3.4} sm={3.4}>
                                <Tooltip title={valueOrDefault(doc?.name, "")}>
                                  <Typography className="custom-txt">
                                    {truncateString(doc?.name || "N/A", 20)}
                                  </Typography>
                                </Tooltip>
                              </Grid>
                              <Grid item xs={2} sm={1.5}>
                                <Typography className="custom-txt">
                                  {" "}
                                  {`${doc?.sizeInMB} MB` || "N/A"}
                                </Typography>
                              </Grid>
                              <Grid item xs={2} sm={1.5}>
                                <Typography className="custom-txt">
                                  {" "}
                                  {doc?.name?.split(".")[1]?.toUpperCase() ||
                                    "N/A"}
                                </Typography>
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <Typography className="custom-txt">
                                  {convertToLocalizedDateTime(
                                    doc?.createdDate
                                      ? doc?.createdDate || "N/A"
                                      : doc?.modifiedDate || "N/A",
                                    "MMM DD, YYYY  h:mm A"
                                  )}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                style={{ paddingTop: "5px" }}
                                xs={0.8}
                                sm={0.8}
                              >
                                {doc?.name?.split(".")[1]?.toLowerCase() !==
                                  "zip" && (
                                  <Tooltip title="view">
                                    <VisibilityIcon
                                      onClick={() => {
                                        getFilePreviewUrl(
                                          doc?.resourceUrl
                                        ).then((filePreviewUrl) =>
                                          setFilePreview({
                                            resourceUrl: filePreviewUrl,
                                            fileName: doc?.name,
                                            fileType: "application/pdf",
                                          })
                                        );
                                      }}
                                      color="primary"
                                      className="pointer custom-preview-dwnload-size"
                                    />
                                  </Tooltip>
                                )}
                              </Grid>
                              <Grid
                                style={{ paddingTop: "3.5px" }}
                                item
                                xs={0.8}
                                sm={0.8}
                              >
                                <Tooltip title="download">
                                  <SaveAltOutlinedIcon
                                    onClick={() => {
                                      downloadFile(doc?.resourceUrl, doc?.name);
                                    }}
                                    color="primary"
                                    className="pointer custom-preview-dwnload-size"
                                  />
                                </Tooltip>
                              </Grid>
                            </>
                          );
                        })}
                    </Grid>
                    <Divider
                      sx={{
                        width: "98%",
                        height: "1px",
                        background: "#EAEAEA",
                        marginTop: "10px",
                        marginLeft: "8px",
                      }}
                    />
                  </Box>
                )}

                {documentsInfo.find((x) => x.documentType === "RTRDoc") && (
                  <Box>
                    <Grid container spacing={1} className="recruiterInfoBox">
                      <Grid item xs={12} sm={12} style={{ marginTop: "12px" }}>
                        <Typography className="custom-resume-txt">
                          RTR Document
                        </Typography>
                      </Grid>
                      <Grid item xs={3.4} sm={3.4}>
                        <Tooltip
                          title={valueOrDefault(
                            documentsInfo.find(
                              (x) => x.documentType === "RTRDoc"
                            )?.name,
                            ""
                          )}
                        >
                          <Typography className="custom-txt">
                            {truncateString(
                              documentsInfo.find(
                                (x) => x.documentType === "RTRDoc"
                              )?.name || "N/A",
                              20
                            )}
                          </Typography>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={2} sm={1.5}>
                        <Typography className="custom-txt">
                          {" "}
                          {`${
                            documentsInfo.find(
                              (x) => x.documentType === "RTRDoc"
                            )?.sizeInMB
                          } MB` || "N/A"}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} sm={1.5}>
                        <Typography className="custom-txt">
                          {" "}
                          {documentsInfo
                            .find((x) => x.documentType === "RTRDoc")
                            ?.name?.split(".")[1]
                            ?.toUpperCase() || "N/A"}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <Typography className="custom-txt">
                          {documentsInfo.find(
                            (x) => x.documentType === "RTRDoc"
                          )?.createdDate || "N/A"}
                        </Typography>
                      </Grid>
                      <Grid
                        style={{ paddingTop: "5px" }}
                        item
                        xs={0.8}
                        sm={0.8}
                      >
                        <Tooltip title="view">
                          <VisibilityIcon
                            onClick={() => {
                              let doc = documentsInfo.find(
                                (x) => x.documentType === "RTRDoc"
                              );
                              getFilePreviewUrl(doc?.resourceUrl!).then(
                                (filePreviewUrl) =>
                                  setFilePreview({
                                    resourceUrl: filePreviewUrl,
                                    fileName: doc?.name,
                                  })
                              );
                            }}
                            color="primary"
                            className="pointer custom-preview-dwnload-size"
                          />
                        </Tooltip>
                      </Grid>
                      <Grid
                        style={{ paddingTop: "3.5px" }}
                        item
                        xs={0.8}
                        sm={0.8}
                      >
                        <Tooltip title="download">
                          <SaveAltOutlinedIcon
                            onClick={() => {
                              let doc = documentsInfo?.find(
                                (x) => x.documentType === "RTRDoc"
                              );
                              downloadFile(doc?.resourceUrl!, doc?.name!);
                            }}
                            color="primary"
                            className="pointer custom-preview-dwnload-size"
                          />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Grid>
              {loading && (
                <p
                  style={{
                    marginTop: "20px",
                    marginBottom: "4px",
                    textAlign: "center",
                  }}
                >
                  Please wait...
                </p>
              )}
            </DialogContentText>
            <div
              className="row"
              style={{
                textAlign: "right",
                marginTop: "30px",
                marginRight: "25px",
              }}
            >
              <Button
                className="custom-btn"
                onClick={handleDocumentsPopupClose}
                variant="outlined"
                style={{ marginRight: "20px", textTransform: "capitalize" }}
              >
                Close
              </Button>
              <Button
                data-testid="DownloadAll"
                className="custom-btn"
                variant="contained"
                style={{ textTransform: "capitalize" }}
                onClick={downloadAll}
              >
                Download All
              </Button>
            </div>
          </DialogContent>
          <DialogActions></DialogActions>
        </Paper>
      </Dialog>
    </div>
  );
};
export default DocumentsPopup;
