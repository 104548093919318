import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { FormControl, Input, InputLabel } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { Alert, CircularProgress } from "@mui/material";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { AuthenticateRecaptcha } from "../../Services/googleRecaptchaService";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import axios from './../../Services/CommonHttpRequest'
import {  LoginType } from "../../models/interfaces";
import AdminSigninHeader from "../Common/AdminSigninHeader";
import { ErrorMessage } from "../ui/ErrorMessage";
import setAuthToken from "../../utils/setAuthToken";
import { passWordRegex } from "../../constants/regex";
import { getExpiringFeaturedJobs } from "../../features/dashboard/recruiterDashboardSlice";
import { useDispatch } from "react-redux";
import { clear, getRecruiterData } from "../../features/recruiterSlice";
import { getNevLink } from "../../utils/getFileFromUrl";
import { setLoggedInUseDetails } from "../../features/userDetails/loggedInUserDetailsSlice";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  InitiateResetModel,
  InitiateReset,
  AuthenticateToken,
} from "../../Services/resetPasswordService";
import { PasswordResetSuccess } from "./ResetPassword/PasswordResetSuccess";
import getLoggedInUserPermissions from "../../Shared/utils/getLoggedInUserPermission";
import { loadPermissions } from "../../features/permission/userPermissionSlice";

const AdminSignin: React.FC = () => {
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  const [error, setError] = React.useState("");
  const { executeRecaptcha } = useGoogleReCaptcha();
  const navigate = useNavigate();
  const recaptchaAction = "signin";
  const [errorMessage, setErrorMessage] = React.useState("");
  const [expiredError, setExpiredError] = React.useState("");
  const dispatch = useDispatch<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [ShowHideLogin, setShowHideLogin] = React.useState(false);
  const [ShowHideReset, setShowHideReset] = React.useState(false);
  const [ShowHideResetSuccess, setShowHideResetSuccess] = React.useState(false);

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  useEffect(()=>{
    if(error){
      console.log(error);
    }
  },[error])

  const initialStateLogin = {
    username: "",
    password: "",
    loginType: LoginType.Email_UserID,
    returnUrl: "",

  };
  const LoginSchema = Yup.object({
    username: Yup.string().email().required("Please enter your email address"),
    password: Yup.string()
    .required("Please enter your password"
    ),
    loginType: Yup.number(),
    returnUrl: Yup.string(),
  });

  const formikLogin = useFormik({
    initialValues: initialStateLogin,
    validationSchema: LoginSchema,
    onSubmit: (values, actions) => {
      actions.setSubmitting(true);
      handleReCaptchaVerify(values, actions);
    },
  });
  const handleReCaptchaVerify = React.useCallback(
    async (values, actions) => {
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        return;
      }
      const token = await executeRecaptcha(recaptchaAction);
      AuthenticateRecaptcha({
        reCaptchaToken: token,
        action: recaptchaAction,
      }).then((response) => {
        actions.setSubmitting(false);
        if (response.data.status === 200 && response.data.entity.score >= 0.5) {
          DoSignin(values, actions);
        } else if (
          response.data.status === 200 &&
          response.data.entity.score <= 0.5
        ) {
          setError(response?.data?.message?.appStatusDescription);
        } else {
          setError("Unexpected error!");
        }
      })
      .catch((err) => {
        setError(err?.message);
        actions.setSubmitting(false);
      });
    },
    [executeRecaptcha]
  );

  function DoSignin(values: any, actions: any) {
    axios
      .post(`${process.env.REACT_APP_IDPROVIDER_API}api/SignIn/admin-login`, values, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {        
        if (res.data.status === 200) {
         
          localStorage.setItem("user", JSON.stringify(res.data.entity));
          setAuthToken(res.data.accessToken);
          dispatch(clear()) 
         
          dispatch(getRecruiterData())
          let userModules = getLoggedInUserPermissions();
          dispatch(loadPermissions({permissions:userModules}));
          dispatch(setLoggedInUseDetails(res.data?.entity));

          actions.resetForm();
          navigate(getNevLink("/dashboard"));
          dispatch(getExpiringFeaturedJobs());
        } else if (res?.data?.status=='401'){
          setErrorMessage(res?.data?.message?.appStatusDescription);
        } else {
          setError(res?.data?.message?.appStatusDescription);
          setErrorMessage("Unexpected error occurred, please try again");
          
        }
      })
      .catch((err) => {
        setError(err?.message);
        actions.setSubmitting(false);
      });
  }
  
  React.useEffect(() => {
    localStorage.clear();
    checkForResetParam();
  }, []);
  

  const initiateResetValues: InitiateResetModel = {
    userName: "",
    password: "",
    confirmPassword: "",
    token: "",
  };
  const InitiateResetSchema = Yup.object({
    userName: Yup.string()
      .email("Entered email address is invalid")
      .required("Please enter a valid email address"),
    password: Yup.string()
      .required(
        "The password must be 8-15 characters long and should contain at least one or more of: Uppercase characters (A-Z) Lowercase characters (a-z) Digits (0-9) Special characters (~!@#$%^&*_-+=`|(){}[]:;\"'<>,.?/)"
      )
      .matches(
        passWordRegex,
        "Password must be 8-15 characters long and must contain atleast one or more of digits, special characters,  upper case and lower case letters"
      ),
    confirmPassword: Yup.string()
      .required(
        "Please re-enter the password. Minimum 8 characters are required"
      )
      .oneOf(
        [Yup.ref("password"), null],
        "Password and Confirm password do not match"
      ),
  });


  const formikReset = useFormik({
    initialValues: initiateResetValues,
    validationSchema: InitiateResetSchema,
    onSubmit: (values, actions) => {
      actions.setSubmitting(true);
      initiateReset(values, actions);
    },
  });

  function initiateReset(
    values: InitiateResetModel,
    actions: FormikHelpers<InitiateResetModel>
  ) {
    InitiateReset(values).then((res) => {
      actions.setSubmitting(false);
      if (res.data.status == 200) {
        setShowHideReset(false);
        setShowHideResetSuccess(true);
        searchParams.delete("resetPassword");
        searchParams.delete("email");
        setSearchParams(searchParams);
        formikReset.resetForm();
        setErrorMessage("");
      } else {
        setErrorMessage(res.data.message?.appStatusDescription);
      }
    });
  }


  const checkForResetParam = () => {
    let resetPassword = searchParams.get("resetPassword");
    let emailtoReset = searchParams.get("email");
    let accessToken = searchParams.get("accessToken");
    if (resetPassword == "true" && emailtoReset != null) {
      authenticateToken(accessToken);
      formikReset.setFieldValue("userName", emailtoReset);
      formikReset.setFieldValue("token", accessToken);
    }
    else {
      setShowHideLogin(true);
    }
  };

  const authenticateToken = (token: any) => {
    AuthenticateToken(token)
      .then((res) => {
        if (res.data.status == 200) {
          if (!res?.data?.entity?.isExpired) {
            setShowHideReset(true);
          } else {
            setShowHideLogin(false);
            setExpiredError("This link has expired");
          }
        } else {
          setShowHideLogin(true);
          setErrorMessage("Unexpected error occurred");
        }
      })
      .catch((err) => {
        setShowHideLogin(true);
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <AdminSigninHeader />
      <Box 
        className="login-forms card mainLoginWrapper"
      >
        <Box className={`${(ShowHideLogin||ShowHideReset||ShowHideResetSuccess) ? "InnerLoginWrapper" : "d-none"}`}>
          <Box className={`${ShowHideLogin ? "" : "d-none"}`} data-testid="box-signin">
            <Typography
              variant="h2"
              color="inherit"
              component="div"
              className="abel abelh2 adminSignHeading"
            >
              Sign In
            </Typography>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              sx={{
                flexDirection: "row",
                maxWidth: "fit-content",
                alignItems: "center",
                "& .MuiTextField-root": { m: 1 },
              }}
            >
              <FormControl
                variant="standard"
                required
                error={
                  formikLogin.touched.username &&
                  Boolean(formikLogin.errors.username)
                }
                fullWidth
                margin="normal"
              >
                <InputLabel htmlFor="email">Your email</InputLabel>
                <Input
                  name="username"
                  type="email"
                  autoComplete="new-password"
                  onChange={(e)=> {formikLogin.handleChange(e); setError('')}}
                  onBlur={formikLogin.handleBlur}
                  value={formikLogin.values.username}
                  inputProps={{ "data-testid": "input-signin-email" }}
                />
                <ErrorMessage errorText={formikLogin.touched.username &&formikLogin.errors.username} />
              </FormControl>
              
              <FormControl
                required
                error={
                  formikLogin.touched.password &&
                  Boolean(formikLogin.errors.password)
                }
                variant="standard"
                fullWidth
                margin="normal"
              >
                <InputLabel htmlFor="standard-adornment-password">
                  Password
                </InputLabel>
                <Input
                  name="password"
                  type={values.showPassword ? "text" : "password"}
                  onChange={(e)=> {formikLogin.handleChange(e); setError('')}}
                  onBlur={formikLogin.handleBlur}
                  value={formikLogin.values.password}
                  autoComplete="no"
                  endAdornment={
                    <span
                      onClick={handleClickShowPassword}
                      className="showHideIcon"
                      data-testid="icon-showpassword"
                    >
                      <InputAdornment position="end">
                        <IconButton size="small">
                          {values.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    </span>
                  }
                  inputProps={{ "data-testid": "input-signin-password" }}
                />
                <ErrorMessage errorText={formikLogin.touched.password &&formikLogin.errors.password} />
              </FormControl>

              <Button
              type="submit"
                onClick={() => {
                  formikLogin.handleSubmit();
                }}
                disabled={formikLogin.isSubmitting}
                style={{ marginTop: "20px" ,textTransform:"none"}}
                variant="contained"
                className="abel signup-button"
                fullWidth
                data-testid="btn-signin"
              >
                Sign in
              </Button>
            </Box>
          </Box>
          
          <Box className={`${ShowHideReset ? "" : "d-none"}`} data-testid="box-setnewpassword">
            <Typography
              variant="h5"
              color="inherit"
              component="span"
              className="abel"
              textAlign="center"
              sx={{ align: "center", marginBottom: "20px", fontSize:{xs:"20px !important",sm:"20px !important",md:"1.5rem !important"} }}
            >
              Set your new password
            </Typography>

            <Box
              component="form"
              noValidate
              autoComplete="off"
              sx={{
                flexDirection: "row",
                maxWidth: "fit-content",
                alignItems: "center",
                "& .MuiTextField-root": { m: 1 },
              }}
            >
              <FormControl
                variant="standard"
                required
                error={
                  formikReset.touched.userName &&
                  Boolean(formikReset.errors.userName)
                }
                fullWidth
                margin="normal"
              >
                <InputLabel htmlFor="userName">Your email</InputLabel>
                <Input
                  name="userName"
                  type="email"
                  readOnly={true}
                  onChange={formikReset.handleChange}
                  onBlur={formikReset.handleBlur}
                  value={formikReset.values.userName}
                  autoComplete="off"
                  inputProps={{ "data-testid": "input-setnewpassword-email" }}
                />
                <ErrorMessage
                  errorText={
                    formikReset.touched.userName && formikReset.errors.userName
                  }
                />
              </FormControl>
              <FormControl
                required
                error={
                  formikReset.touched.password &&
                  Boolean(formikReset.errors.password)
                }
                fullWidth
                margin="normal"
              >
                <InputLabel htmlFor="password">New Password</InputLabel>
                <Input
                  name="password"
                  type={values.showPassword ? "text" : "password"}
                  onChange={formikReset.handleChange}
                  onBlur={formikReset.handleBlur}
                  value={formikReset.values.password}
                  inputProps={{ "data-testid": "input-setnewpassword-pwd" }}
                />
                <ErrorMessage
                  errorText={
                    formikReset.touched.password && formikReset.errors.password
                  }
                />
              </FormControl>
              <FormControl
                required
                error={
                  formikReset.touched.confirmPassword &&
                  Boolean(formikReset.errors.confirmPassword)
                }
                fullWidth
                margin="normal"
              >
                <InputLabel htmlFor="confirmPassword">
                  Confirm password
                </InputLabel>
                <Input
                  name="confirmPassword"
                  type={values.showPassword ? "text" : "password"}
                  onChange={formikReset.handleChange}
                  onBlur={formikReset.handleBlur}
                  value={formikReset.values.confirmPassword}
                  inputProps={{ "data-testid": "input-setnewpassword-confirmpwd" }}
                />
                <ErrorMessage
                  errorText={
                    formikReset.touched.confirmPassword &&
                    formikReset.errors.confirmPassword
                  }
                />
              </FormControl>
              <Typography
                variant="body1"
                color="inherit"
                component="span"
                className="abel"
                sx={{ marginTop: "20px" }}
              >
                Your password should contain minimum 8 characters and include
                combination of alphabets, numbers and special character
              </Typography>

              <Button
                style={{ marginTop: "15px" }}
                variant="contained"
                className="abel signup-button"
                fullWidth
                type="submit"
                endIcon={formikReset.isSubmitting?<CircularProgress size={"16px"} color={"inherit"} />:"" }
                disabled={formikReset.isSubmitting}
                onClick={(e) => {
                  e.preventDefault();
                  formikReset.handleSubmit();
                }}
                data-testid="btn-setnewpassword"
              >
                Submit
              </Button>
            </Box>
          </Box>

          <Box className={`${ShowHideResetSuccess ? "" : "d-none"}`} data-testid="box-setnewpassword-success">
            <PasswordResetSuccess
              onClick={(e: any) => {
                setShowHideLogin(true);
                setShowHideResetSuccess(false);
              }}
            />
          </Box>

          {errorMessage !== "" && (
            <Alert
              onClose={() => {
                setErrorMessage("");
              }}
              severity="error"
              sx={{ mt: 1.5 }}
            >
              {errorMessage}
            </Alert>
          )}
        </Box>
        {expiredError !== "" && (
            <Alert
              onClose={() => {
                setExpiredError("");
              }}
              severity="error"
              sx={{ mt: 1.5 }}
            >
              {expiredError}
            </Alert>
          )}
      </Box>
    </React.Fragment>
  );
};

export default AdminSignin;
