import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { PermissionsEnums } from "../../../../data/permission";
import { CandidateFinalStatuses, CandidateStatus, UserAuth } from "../../../../models/interfaces";
import constructName from "../../../../utils/constructName";
import { ModuleComponent } from "../../../Common/Permission/ModuleComponent";
import "../../NewViewSubmission/styles.scss";

interface ContactsButtonProps {
  row: any;
  userRole: UserAuth;
  currentjobDetails: any;
  setPopup: any;
  setSelectedRowJobDetails: any;
  setcontactInformationData: any;
}

export default function ContactsButton({
  row,
  userRole,
  currentjobDetails,
  setPopup,
  setSelectedRowJobDetails,
  setcontactInformationData
} : ContactsButtonProps) {

  function renderContactsForAdmin() {
    return (
      <>
        <ModuleComponent
          moduleId={PermissionsEnums.Submissions_Contacts}
        >
          <Grid item>
            <Button
              className="submission-action-btn"
              variant="contained"
              color="primary"
              onClick={() => {
                setPopup((popup : any) => ({
                  ...popup,
                  contact: true,
                }));
  
                setSelectedRowJobDetails(currentjobDetails);
  
                setcontactInformationData((contactInformationData: any) => ({
                  ...contactInformationData,
                  accountManager: row?.accountManagerDetails,
                  recruiter: {
                    name: constructName(
                      row?.recruiterFirstName,
                      row?.recruiterMiddleName,
                      row?.recruiterLastName
                    ),
                    email: row?.recruiterEmail,
                    phoneNumber: row?.recruiterPhoneNumbers?.primary,
                  },
                  candidate: {
                    name: constructName(
                      row?.firstName,
                      row?.middleName,
                      row?.lastName
                    ),
                    email: row?.email,
                    phone: row?.phone,
                  },
                  employerDetails: row?.employerDetails,
                }));
              }}
            >
              Contacts
            </Button>
          </Grid>
        </ModuleComponent>
      </>
    );
  }
  
  function renderContactsForNonAdmin() {
    return (
      <>
        <ModuleComponent
          moduleId={PermissionsEnums.Submissions_Contacts}
        >
          {row?.finalStatus ===
            CandidateFinalStatuses.CandidateSubmitted ||
            row?.candidateStatuses?.some(
              (x: any) => x.status === CandidateStatus.RTRDeclined
            ) 
            ? null 
            : 
            (
              <Grid item> 
                <Button
                  className="submission-action-btn"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setPopup((popup: any) => ({
                      ...popup,
                      contact: true,
                    }));
                    setSelectedRowJobDetails(currentjobDetails);
                    setcontactInformationData((contactInformationData: any) => ({
                      ...contactInformationData,
                      accountManager: row?.accountManagerDetails,
                      recruiter: {
                        name: constructName(
                          row?.recruiterFirstName,
                          row?.recruiterMiddleName,
                          row?.recruiterLastName
                        ),
                        email: row?.recruiterEmail,
                        phoneNumber: row?.recruiterPhoneNumbers?.primary,
                      },
                      candidate: {
                        name: constructName(
                          row?.firstName,
                          row?.middleName,
                          row?.lastName
                        ),
                        email: row?.email,
                        phone: row?.phone,
                      },
                      employerDetails: row?.employerDetails,
                    }));
                  }}
                >
                  Contacts
                </Button>
              </Grid>
            )
          }
        </ModuleComponent>
      </>
    );
  }

  return (
    <>
      {userRole === UserAuth.Admin ||
       userRole === UserAuth.SuperAdmin
        ? renderContactsForAdmin()
        : renderContactsForNonAdmin()
      }
    </>
  );
}