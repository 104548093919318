import { StylesProvider } from "@material-ui/core/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import GoToTop from "./components/Common/GotoTop";
import { Layout } from "./components/Layout/Layout";
import { getRecruiterData } from "./features/recruiterSlice";
import { getLoggedInUserDetails } from "./features/userDetails/loggedInUserDetailsSlice";
import { UserAuth } from "./models/interfaces";
import getLoggedInUserRole from "./Shared/utils/getLoggedInUserRole";

import { loadPermissions } from "./features/permission/userPermissionSlice";
import getLoggedInUserPermissions from "./Shared/utils/getLoggedInUserPermission";
import RefreshToken from "./utils/RefreshToken";

function App() {

  const dispatch = useDispatch<any>();
 
  let userRole = getLoggedInUserRole();
  let userModules = getLoggedInUserPermissions();
  useEffect(() => {
    if (
      userRole == UserAuth.Recruiter ||
      userRole == UserAuth.Client ||
      userRole == UserAuth.ClientTeamMember
    ) {
      //get recruiter
      dispatch(getRecruiterData());

    }
    dispatch(getLoggedInUserDetails());
    if (getLoggedInUserRole() != UserAuth.NotLoggedIn) {
      dispatch(loadPermissions({ permissions: userModules }));
    }

  }, []);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#4540DB",
      },
      secondary: {
        light: "#0066ff",
        main: "#0044ff",
        contrastText: "#ffcc00",
      },
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
          },
        },
      },
    },
  });



  return (
    <GoogleReCaptchaProvider
      useRecaptchaNet
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_ID as string}
      scriptProps={{ async: true, defer: true, appendTo: "body" }}
    >
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID ?? ""}
      >
        <StylesProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Router basename="/">
              <GoToTop />
              <Layout />
              <RefreshToken/>
            </Router>
          </ThemeProvider>
        </StylesProvider>
      </GoogleOAuthProvider>
    </GoogleReCaptchaProvider>
  );
}

export default App;
