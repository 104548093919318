import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { IconButton, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Asterisk } from "../../ui/Asterisk";
import { useState } from "react";
import { BacklistIcon } from "../../Common/Icons/BacklistIcon";
import { SuspendIcon } from "../../Common/Icons/SuspendIcon";
import { IncompleteIcon } from "../../Common/Icons/IncompleteIcon";
import { RejectIcon } from "../../Common/Icons/RejectIcon";
import { ApproveIcon } from "../../Common/Icons/ApproveIcon";
import {
  RecruiterStatus,
  RecruiterStatusesId,
} from "../../../models/interfaces";
import { ErrorMessage } from "../../ui/ErrorMessage";
import { VerifyReasonsRecruiter } from "../../../Services/recruiterService";

const ariaLabel = { "aria-label": "description" };

interface IPROPS {
  openPopup: any;
  handleDocumentsPopupClose: any;
  handlePopupSubmit: any;
}


export const RecruiterStatusUpdatePopup: React.FunctionComponent<IPROPS> = ({
  openPopup,
  handleDocumentsPopupClose,
  handlePopupSubmit,
}) => {
  const [feedbackDescription, setFeedbackDescription] =
    React.useState<string>("");
  const [resons, setResons] = useState<any>([]);
  const [errors, setErrors] = React.useState<any>(null);
  const [verifyReasonsAPIsts, setVerifyReasonsAPIsts] = React.useState({
    isSuccess: false,
    error: "",
    loading: false,
  });

  const getRecruiterStatusId = (status: string): string => {
    const statusMap: Record<string, string> = {
      [RecruiterStatus[RecruiterStatus.Blacklisted]]: RecruiterStatusesId.Blacklisted,
      [RecruiterStatus[RecruiterStatus.Suspended]]: RecruiterStatusesId.Suspended,
      [RecruiterStatus[RecruiterStatus.MarkIncomplete]]: RecruiterStatusesId.MarkIncomplete,
      [RecruiterStatus[RecruiterStatus.Rejected]]: RecruiterStatusesId.Rejected,
      [RecruiterStatus[RecruiterStatus.Approved]]: RecruiterStatusesId.Approved,
    };
  
    return statusMap[status] || "";
  };

  React.useEffect(() => {
    if (openPopup.action) {
      let recruiter_sts_id = openPopup.action ? getRecruiterStatusId(openPopup?.status) : "";
      if (recruiter_sts_id) {
        getVerifyReasonsRecruiter(recruiter_sts_id);
      }
    }
  }, [openPopup.action]);

  const getVerifyReasonsRecruiter = (recruiter_sts_id: string) => {
    setVerifyReasonsAPIsts({ isSuccess: false, error: "", loading: true });

    VerifyReasonsRecruiter(recruiter_sts_id)
      ?.then((response) => {
        if (response.data.status === 200) {
          let updatedReasons = response?.data?.entityList;
          updatedReasons.forEach((object: any) => {
            object.isChecked = false;
          });

          setResons(updatedReasons || []);
          setVerifyReasonsAPIsts({
            isSuccess: true,
            error: "",
            loading: false,
          });
        } else {
          setVerifyReasonsAPIsts({
            isSuccess: false,
            error: response.data?.message?.appStatusDescription,
            loading: false,
          });
        }
      })
      .catch((er) => {
        setVerifyReasonsAPIsts({
          isSuccess: false,
          error: er.message,
          loading: false,
        });
      });
  };

  const handleCheckboxChange = (id: string) => {
    const updatedItems = resons.map((item: any) =>
      item.id === id ? { ...item, isChecked: !item.isChecked } : item
    );
    setResons(updatedItems);
  };

  const handleChange = (e: any) => {
    setFeedbackDescription(e.target.value);
  };

  React.useEffect(() => {
    setErrors(null);
  }, [feedbackDescription]);

  const handleSubmit = () => {
    if (feedbackDescription.trim().length < 1) {
      setErrors("Please enter the comment");
    } else if (feedbackDescription.trim().length > 500) {
      setErrors("Max 500 characters limit");
      return;
    } else {
      handlePopupSubmit(resons, feedbackDescription, openPopup?.status);
      setFeedbackDescription("");
      handleDocumentsPopupClose();
    }
  };

  function getStatusReasonLabel(status: string | undefined): string {
    switch (status) {
      case RecruiterStatus[RecruiterStatus.Blacklisted]:
        return "Blacklist Reasons";
      case RecruiterStatus[RecruiterStatus.Suspended]:
        return "Suspension Reasons";
      case RecruiterStatus[RecruiterStatus.MarkIncomplete]:
        return "Incomplete Reasons";
      case RecruiterStatus[RecruiterStatus.Rejected]:
        return "Rejection Reasons";
      case RecruiterStatus[RecruiterStatus.Approved]:
        return "Approval Reasons";
      default:
        return "";
    }
  }
  
  function getStatusIcon(status: string | undefined): JSX.Element | null {
    switch (status) {
      case RecruiterStatus[RecruiterStatus.Blacklisted]:
        return <BacklistIcon />;
      case RecruiterStatus[RecruiterStatus.Suspended]:
        return <SuspendIcon />;
      case RecruiterStatus[RecruiterStatus.MarkIncomplete]:
        return <IncompleteIcon />;
      case RecruiterStatus[RecruiterStatus.Rejected]:
        return <RejectIcon />;
      case RecruiterStatus[RecruiterStatus.Approved]:
        return <ApproveIcon />;
      default:
        return null;
    }
  }

  function getStatusLabel(status:string) {
    switch (status) {
      case RecruiterStatus[RecruiterStatus.Blacklisted]:
        return "Blacklist";
      case RecruiterStatus[RecruiterStatus.Suspended]:
        return "Suspend";
      case RecruiterStatus[RecruiterStatus.MarkIncomplete]:
        return "Incomplete";
      case RecruiterStatus[RecruiterStatus.Rejected]:
        return "Reject";
      case RecruiterStatus[RecruiterStatus.Approved]:
        return "Approve";
      default:
        return "";
    }
  }

  const _handleOnClose = () =>{
    handleDocumentsPopupClose(openPopup.status, false);
    setFeedbackDescription("");
    setResons([]);
    setErrors(null);
  }

  return (
    <div>
      <Dialog
        className="custom-popup-border"
        open={openPopup.action}
        onClose={_handleOnClose}
        aria-labelledby="viewRecruiterPopup-title"
        aria-describedby="viewRecruiterPopup-description"
      >
        <Paper elevation={5} style={{ backgroundColor: "#FFF" }}>
          <DialogTitle sx={{ m: 0, p: 2 }}>
            <IconButton
              aria-label="close"
              onClick={_handleOnClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="viewRecruiterPopup-description">
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography className="custom-document-txt">
                    {getStatusReasonLabel(openPopup?.status)}
                  </Typography>
                </Grid>
                {verifyReasonsAPIsts?.loading ? (
                  <p className="loadTextReasonPopup">Please wait...</p>
                ) : (
                  <>
                    {resons.length > 0 && (
                      <>
                        <Typography className="verify-rec-dialog-checklistBox">
                          <Grid container>
                            {resons.map((item: any, index:number) => (
                              <Grid
                                item
                                xs={12}
                                sm={resons.length > 8 ? 6 : 12}
                                key={item.id}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={item.isChecked}
                                      onChange={() =>
                                        handleCheckboxChange(item.id)
                                      }
                                      color="primary"
                                      data-testid={`handleCheckboxChange-${index}`}
                                    />
                                  }
                                  label={item.reason}
                                  className="ver-rec-checklistText"
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </Typography>
                      </>
                    )}
                  </>
                )}
              </Grid>
              {resons.length > 0 && (
                <div style={{ marginLeft: "13px" }}>
                  <Divider />
                </div>
              )}

              <Box style={{ marginLeft: "13px" }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <Typography
                      className="custom-robo-font"
                      mt={3}
                      style={{
                        color: "black",
                        fontFamily: "Roboto",
                        fontSize: "16px",
                      }}
                    >
                      Add Comments <Asterisk />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      inputProps={{
                        ...ariaLabel,
                        "data-testid": "input-recruiterstatus-feedback",
                      }}
                      variant="outlined"
                      multiline
                      name="feedbackDescription"
                      value={feedbackDescription}
                      onChange={(event: any) => {
                        handleChange(event);
                      }}
                      fullWidth
                      placeholder="Text here"
                      rows={3}
                      maxRows={6}
                    />

                    {errors !== null && <ErrorMessage errorText={errors} />}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className="custom-max-limit">
                      Maximum limit allowed is 500 characters.
                    </Typography>
                  </Grid>
                </Grid>
                <Button
                  style={{
                    marginTop: "12px",
                    float: "right",
                    fontSize: "13px",
                  }}
                  variant="contained"
                  defaultValue="approve"
                  onClick={() => {
                    handleSubmit();
                  }}
                  data-testid="Approve"
                  startIcon={getStatusIcon(openPopup?.status)}
                >
                  {getStatusLabel(openPopup?.status)}
                </Button>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Paper>
      </Dialog>
    </div>
  );
};
export default RecruiterStatusUpdatePopup;
