export const initialStateCandidateAcknowledgment = {
    id:"",
    email: '',
    firstName: "",
    lastName: "",
    middleName: "",
    zip: "",
    country: "",
    city: "",
    state: "",
    phone: "",
    experience: "",
    skills: [],
    workAuthorization: "",
    isRemoteUSA : false,
    employmentType:"",
    workAuthorizationStatus:"",
    employeeName: "",
    employerDetails:{
      name:"",
      email:"",
      phoneNumber:"",
      reference:"",
      address:{
        id:"",
        streetAddress:"",
        city:"",
        state:"",
        postalCode:"",
        countryCode:""
      }
    },
    payRate: "",
    payrateType: "hourly",
    candidateEmployment: "",
    addresss:"",
    address:{
      city:"",
      countryCode:"",
      id:"",
      postalCode:"",
      state:"",
      streetAddress:""
    },
    currentWorkLocation: {
      id: "",
      city: "",
      state: "",
    },
    recruiterComments: {},
    resume: "",
    otherDocument: "",
    resumeInfoObj: {
      resourceUrl: "",
      fileName: "",
      newFileName: "",
    },
    otherDocInfo: [      
      {
        resourceUrl: "",
        fileName: "",
        newFileName: "",
        documentType:""
      }
    ],
    candidateId: "",
    candidateGuid: "",
    canRelocate:false,
    comment:"",
    candidateComments:[{
      id:"",
      createdDate:"",
      lastModifiedDate:"",
      comment:""
    }],
    modifiedBy:"",
    isUpdatedByCandidate: true,
    isPhoneVerified:false,
    phoneNumbers: {
      primary: "",
      secondary: "",
      isPrimaryVerified: false,
      isSecondaryVerified: false,
    },
    pageName:"ackn"
  }