import { Input } from "@material-ui/core";
import verifiedIcon from "./../../assets/images/verified.svg";

export const CustomEmailInput = (props) => {
  const {
    value,
    onChange,
    isverified,
    onBlur,
    readOnly,
    name,
    style,
    paddingTop = "23px",
    disableUnderline,
  } = props;

  return (
    <div
      style={{ paddingTop: paddingTop, display: "flex", alignItems: "center" }}
    >
      <Input
        fullWidth
        name={name}
        type="email"
        readOnly={readOnly}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        autoComplete="off"
        style={style}
        disableUnderline={disableUnderline}
      />
      {isverified ? (
        <img
          src={verifiedIcon}
          style={{ paddingRight: value?.length > 20 ? "15px" : "65px" }}
          className="custom-email-verf-icon"
        ></img>
      ) : null}
    </div>
  );
};

CustomEmailInput.defaultProps = {
  isverified: false,
};
