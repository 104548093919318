import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import RelevantJobsChart from "./RelevantJobsChart";

interface RelevantJobsProps {
  data?: any; 
}

const RelevantJobs: React.FC<RelevantJobsProps> = ({ data }) => {
  return (
    <Card sx={{ height: "100%" }} className="card-custom-shadow">
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
          <Grid
            item
            sx={{
              padding: 0,
              width: "100%",
              height: "260px",
            }}
          >
            <Box sx={{ width: "100%", height: "100%" }}>
              <Typography
                sx={{ fontSize: "14px", fontWeight: "600" }}
              >
                Relevant Jobs
              </Typography>
              <Box sx={{ marginTop: "0px", width: "100%", height: "100%" }}>
                {data !== undefined && <RelevantJobsChart data={data} />}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RelevantJobs;
