import React, { useEffect, useState } from "react";
import {
  Typography,
  TextField,
  InputLabel,
  Button,
  Grid,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useFormik } from "formik";

import TimeZoneAutoComplete, {
  TimezoneType,
} from "../../../../Common/Form/FormField/TimeZoneAutoComplete";
import SectionHeading from "../../../../ui/Form/Heading/SectionHeading";
import InterviewTypeAutoComplete, {
  InterviewType, InterviewTypeOption,
} from "../../../../Common/Form/FormField/InterviewTypeAutoComplete";
import LocationDetailsSection from "./LocationDetailsSection";
import EventTypeAutoComplete, {
  EventTypeOption,
} from "../../../../Common/Form/FormField/EventTypeAutoComplete";
import ReminderAutoComplete, { ReminderTypesOption } from "../../../../Common/Form/FormField/ReminderAutoComplete";
import InterviewAvailabilityValidationSchema from "./InterviewAvailabilityValidationSchema";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../store/AppState";
import ScheduleAvailability from "./ScheduleAvailability";
import PreviousAvailability from "./PreviousAvailability";
import ChooseAvailability from "./ChooseAvailability";
import getLoggedInUserRole from "../../../../../Shared/utils/getLoggedInUserRole";
import { UserAuth } from "../../../../../models/interfaces";
import { Box } from "@mui/system";
import { CandidateSubmissionStatus } from "../../../../../models/submission/CandidateSubmissionMasterStatusesModel";
import { AddInterviewAvailability, GetInterviewAvailabilityById, UpdateInterviewAvailability } from "../../../../../Services/candidateService";
import { CustomAlert } from "../../../../ui/CustomAlert";
import moment from "moment";
import { ModuleComponent } from "../../../../Common/Permission/ModuleComponent";
import { PermissionsEnums } from "../../../../../data/permission";
import { ternary, valueOrDefault } from "../../../../../utils/complexityUtils";

interface Props {
  type: 'viewSharedAvailability' | 'shareInterviewAvailbility' | string;
  onSuccess:(action: string, message: string) => void;
  selectedRowDetails:any;
  source:string;
  handleClose:()=>void;
}

const CandidateInterviewSchedulerForm: React.FC<Props> = ({
  type,
  onSuccess,
  selectedRowDetails,
  source,
  handleClose
}) => {
  const initialValues = useSelector(
    (state: AppState) => state.CandidateInterviewAvailabilityState
  );
  const userRole = getLoggedInUserRole();

  const [isChecked, setIsChecked] = useState((userRole === UserAuth.Client || userRole === UserAuth.ClientTeamMember) && type !== "viewSharedAvailability" ? true : false);
  const isReadOnly = ternary(type === "viewSharedAvailability", true, false);
  const [currentAvailabilities, setCurrentAvailabilities] = useState<any>([]);
  const [previousAvailabilities, setPreviousAvailabilities] = useState<any>([]);
  const [defaultReminderValue, setDefaultReminderValue] = useState<any>(null);
  const [pageStatus, setPageStatus] = React.useState({
    isSuccess: false,
    isError:false,
    error: "",
    loading: false,
    message: "",
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: InterviewAvailabilityValidationSchema,
    onSubmit: async (values, actions) => {
      
      let status;
      if (source === "nextRoundOfInterview") {
        status = CandidateSubmissionStatus.NextRoundOfInterview;
      } else if (source === "InterviewRescheduled") {
        status = CandidateSubmissionStatus.InterviewRescheduled;
      } else {
        status = CandidateSubmissionStatus.InterviewScheduled;
      }

      let payload={
        ...values,
        eventId: status
      }

      // Iterate through the interviewData and convert interviewTime
      
      if(payload?.finalizedAvailabilityId !==""){
        let updatedPayload = {...payload, availabilities: null}
        const { eventId, ...modifiedInterviewEvent } = updatedPayload;
        handleOnSubmit(modifiedInterviewEvent);
      }else{
        payload?.availabilities?.forEach(interview => {
          const formattedTime = moment(interview.interviewTime, 'ddd MMM DD YYYY HH:mm:ss [GMT]Z').format('HH:mm:ss');
          interview.interviewTime = formattedTime;
          });          
          handleOnSubmit(payload);
      }
    },
  });

  useEffect(()=>{
    if(type === "viewSharedAvailability"){
      getInterviewAvailabilityById();
    }
  },[type])

  useEffect(()=>{
    
    formik?.setFieldValue("title",selectedRowDetails?.jobTitle);
    formik?.setFieldValue("submissionId",selectedRowDetails?.id);

  },[])

  useEffect(()=>{
    formik?.setFieldValue("finalizedAvailabilityId","");
  },[isChecked])

  const getInterviewAvailabilityById = () =>{
    GetInterviewAvailabilityById(selectedRowDetails?.id).then((response) => {
      if (response?.data?.message?.appStatusCode === "RC_RSF") {
        setDefaultField(response?.data?.entity);
        setCurrentAvailabilities(valueOrDefault(response?.data?.entity?.currentAvailabilities, []))
        setPreviousAvailabilities(valueOrDefault(response?.data?.entity?.previousAvailabilities, []))
        
      } else {
        setPageStatus({
          isSuccess: false,
          isError:true,
          error: response.data?.message?.appStatusDescription,
          message: response.data?.message?.appStatusDescription,
          loading: false,
        });
      }
    });
  }
  const setDefaultField = (interviewAvailabilities:any) =>{

    formik?.setFieldValue("title",interviewAvailabilities?.title);

    formik?.setFieldValue("id",interviewAvailabilities?.id);
    formik?.setFieldValue("subject",interviewAvailabilities?.subject);

    formik?.setFieldValue("eventType",interviewAvailabilities?.eventType);
    formik?.setFieldValue("eventTypeId",interviewAvailabilities?.eventTypeId);

    formik?.setFieldValue("interviewType",interviewAvailabilities?.interviewType);
    formik?.setFieldValue("interviewTypeId",interviewAvailabilities?.interviewTypeId);

    formik?.setFieldValue("timeZoneId",interviewAvailabilities?.timeZoneId);
    formik?.setFieldValue("timeZone",interviewAvailabilities?.timeZone);
    
    
    formik?.setFieldValue("reminderMinutes",interviewAvailabilities?.reminderMinutes || undefined);
    formik?.setFieldValue("reminderDisplayValue",valueOrDefault(interviewAvailabilities?.reminderDisplayValue, ""));
    setDefaultReminderValue(interviewAvailabilities?.reminderMinutes || undefined);

    formik?.setFieldValue("eventId",interviewAvailabilities?.eventId);

    formik?.setFieldValue("InterviewLocation.streetAddress", valueOrDefault(interviewAvailabilities?.interviewLocation?.streetAddress, ""));
    formik?.setFieldValue("InterviewLocation.id",valueOrDefault(interviewAvailabilities?.interviewLocation?.city, ""));
    formik?.setFieldValue("InterviewLocation.postalCode", valueOrDefault(interviewAvailabilities?.interviewLocation?.postalCode, ""));
    formik?.setFieldValue("InterviewLocation.countryCode", valueOrDefault(interviewAvailabilities?.interviewLocation?.countryCode, ""));
    formik?.setFieldValue("InterviewLocation.state", valueOrDefault(interviewAvailabilities?.interviewLocation?.state, ""));
    formik?.setFieldValue("InterviewLocation.city", valueOrDefault(interviewAvailabilities?.interviewLocation?.city, ""));
    

    formik?.setFieldValue("InterviewLocation.meetingLink", valueOrDefault(interviewAvailabilities?.interviewLocation?.meetingLink, ""));
    formik?.setFieldValue("description", valueOrDefault(interviewAvailabilities?.description, ""));
  }
  

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  function handleShareInterviewAvailability(payload: any) {
    AddInterviewAvailability(payload)
    .then((response) => {
      if (response?.data?.message?.appStatusCode === "RC_RSF") {
        setPageStatus({
          isSuccess: true,
          isError:false,
          error: "",
          loading: false,
          message: "Your availability of Interview shared successfully with recruiter!",
        });
        
        let successMessage;

        if (payload?.finalizedAvailabilityId !== "") {
          successMessage = "Interview schedule successfully!";
        } else {
          successMessage = `Your availability of Interview shared successfully with ${ternary(
            userRole === UserAuth.Client, "recruiter", "client"
          )}!`;
        }

        onSuccess("shareInterviewAvailbility", successMessage);

      } else {
        setPageStatus({
          isSuccess: false,
          isError:true,
          error: response.data?.message?.appStatusDescription,
          message: response.data?.message?.appStatusDescription,
          loading: false,
        });
      }
    });
  }

  function handleViewSharedAvailability(payload: any) {
    UpdateInterviewAvailability(payload).then((response) => {
      if (response?.data?.message?.appStatusCode === "RC_RSF") {
        setPageStatus({
          isSuccess: true,
          isError:false,
          error: "",
          loading: false,
          message: "Your availability of Interview shared successfully with recruiter!",
        });
        
        let successMessage;

        if (payload?.finalizedAvailabilityId !== "") {
          successMessage = "Interview schedule successfully!";
        } else {
          successMessage = `Your availability of Interview shared successfully with ${ternary(
            userRole === UserAuth.Client, "recruiter", "client"
          )}!`;
        }

        onSuccess("shareInterviewAvailbility", successMessage);

      } else {
        setPageStatus({
          isSuccess: false,
          isError:true,
          error: response.data?.message?.appStatusDescription,
          message: response.data?.message?.appStatusDescription,
          loading: false,
        });
      }
    });
  }

  const handleOnSubmit = (payload: any) => {
    setPageStatus({
      isSuccess: false,
      isError:false,
      error: "",
      loading: true,
      message: "",
    });

    if(type === "shareInterviewAvailbility"){
      handleShareInterviewAvailability(payload);
    }else if(type === "viewSharedAvailability"){
      handleViewSharedAvailability(payload);
    }else{
      console.log("formValues",payload);
    }
  };

  function formatButtonText() : string {
    if (type === "viewSharedAvailability"  && !isChecked) return 'Schedule Interview';
    else if (userRole === UserAuth.Recruiter) return 'Submit to Client';
    else return 'Submit to Recruiter';
  }

 
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <SectionHeading title="Interview Availability" />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth={true}
            variant="standard"
            id="subject"
            label="Subject"
            name="subject"
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            value={formik?.values?.subject}
            error={formik?.touched?.subject && Boolean(formik?.errors?.subject)}
            helperText={formik?.touched?.subject && formik?.errors?.subject}
            InputProps={{
              readOnly: isReadOnly,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel required shrink={true}>
            Title
          </InputLabel>
          <Typography variant="body1">
            {selectedRowDetails?.jobTitle}({selectedRowDetails?.jobId})
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} >
          <EventTypeAutoComplete
            data-testid="Interview"
            label="Event Type"
            fieldName="eventType"
            value={{
              id: "8a62656d-7c7d-4151-83a9-90544d56c482",
              eventName: "Interview",
            }}
            isRequired={true}
            isError={
              formik.touched?.eventType && Boolean(formik.errors?.eventType)
            }
            helperText={formik.touched?.eventType && formik.errors?.eventType}
            handleChange={(selectedValue: EventTypeOption | null) => {
              formik?.setFieldValue("eventTypeId", selectedValue?.id);
              formik?.setFieldValue("eventType", selectedValue?.eventName);
            }}
            handleBlur={(e: React.FocusEvent<any, Element>) =>
              formik.handleBlur(e)
            }
            isReadOnly={isReadOnly}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <InterviewTypeAutoComplete
            data-testid="interviewType"
            label="Interview Type"
            fieldName="interviewType"
            value={{
              id: formik.values?.interviewTypeId,
              interviewType: formik.values?.interviewType,
            }}
            isRequired={true}
            isError={
              formik.touched?.interviewType &&
              Boolean(formik.errors?.interviewType)
            }
            helperText={
              formik.touched?.interviewType && formik.errors?.interviewType
            }
            handleChange={(selectedValue: InterviewType | null) => {
              formik?.setFieldValue("interviewTypeId", valueOrDefault(selectedValue?.id, ""));
              formik?.setFieldValue(
                "interviewType",
                valueOrDefault(selectedValue?.interviewType, "")
              );
            }}
            handleBlur={(e: React.FocusEvent<any, Element>) =>
              formik.handleBlur(e)
            }
            isReadOnly={isReadOnly}
          />
        </Grid>
      </Grid>
      {/* section for timezone*/}

      <Grid container>
        <Grid item xs={12} sm={12} mt={2}>
          <SectionHeading title="TimeZone" />
        </Grid>
        <Grid item xs={12} sm={4} mt={1}>
          <TimeZoneAutoComplete
            label="Time Zone"
            fieldName="timeZone"
            value={{
              id: formik.values?.timeZoneId,
              timeZone: formik.values?.timeZone,
            }}
            isRequired={true}
            isError={
              formik.touched?.timeZone && Boolean(formik.errors?.timeZone)
            }
            helperText={formik.touched?.timeZone && formik.errors?.timeZone}
            handleChange={(selectedValue: TimezoneType | null) => {
              formik?.setFieldValue("timeZoneId", valueOrDefault(selectedValue?.id, ""));
              formik?.setFieldValue("timeZone", valueOrDefault(selectedValue?.timeZone, ""));
            }}
            handleBlur={(e: React.FocusEvent<any, Element>) =>
              formik.handleBlur(e)
            }
            isReadOnly={isReadOnly}
          />
        </Grid>
      </Grid>
      {/* Sections for Availability */}

      <Grid container spacing={1} mt={2}>
        <Grid item xs={12}>
          <SectionHeading title="Availabilities" />
        </Grid>
        <Grid item xs={12} mb={2}>
          <PreviousAvailability formik={formik} previousAvailabilities={previousAvailabilities}/>
        </Grid>
        { type === "viewSharedAvailability" &&
        <Grid item xs={12}>
          <ChooseAvailability formik={formik}  currentAvailabilities={currentAvailabilities} isChecked={isChecked}/>
        </Grid>
        }
        { type === "viewSharedAvailability" && 
        <Grid item xs={12}>
        <FormControlLabel
            control={
              <Checkbox 
                checked={isChecked} 
                onChange={handleCheckboxChange} 
                />
              }
            color="primary"
            label="Choose Availability"
          />
        </Grid>
        }
        { isChecked &&
        <Grid item xs={12}>
          <ScheduleAvailability formik={formik}/>
        </Grid>
        }
      </Grid>

      {/* Sections for Location */}

      <Grid container spacing={1} mt={2}>
        <Grid item xs={12}>
          <SectionHeading title="Location" />
        </Grid>
        { formik.values?.interviewTypeId === InterviewTypeOption.inPerson && (
          <Grid item xs={12}>
            <LocationDetailsSection
              handleChange={formik?.handleChange}
              handleBlur={formik?.handleBlur}
              setFieldValue={formik?.setFieldValue}
              setFieldTouched={formik?.setFieldTouched}
              values={formik?.values}
              touched={formik?.touched}
              errors={formik?.errors}
              isReadOnly={isReadOnly}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={4}>
          <ReminderAutoComplete 
            label="Reminder"
            fieldName="reminderMinutes"
            value={{
              id:"",
              reminderDisplayValue:valueOrDefault(formik.values?.reminderDisplayValue, ""),
              reminderMinutes: valueOrDefault(formik.values?.reminderMinutes, undefined)
            }}
            defaultValue={defaultReminderValue}
            isRequired={true}
            isError={
              formik.touched?.reminderMinutes && Boolean(formik.errors?.reminderMinutes)
            }
            helperText={formik.touched?.reminderMinutes && formik.errors?.reminderMinutes}
            handleChange={(selectedValue: ReminderTypesOption | null) => {
              formik?.setFieldValue("reminderMinutes", selectedValue?.reminderMinutes || undefined);
              formik?.setFieldValue("reminderDisplayValue", valueOrDefault(selectedValue?.reminderDisplayValue, ""));
            }}
            handleBlur={(e: React.FocusEvent<any, Element>) =>
              formik.handleBlur(e)
            } 
            isReadOnly={isReadOnly}
            />
        </Grid>
         { (formik?.values?.interviewTypeId === InterviewTypeOption.webcam ) && 
            <Grid item xs={12} sm={12} md={8}>
              <TextField
                
                InputProps={{
                  readOnly: (formik?.values?.interviewTypeId === InterviewTypeOption.webcam  && formik?.values?.finalizedAvailabilityId !== "") ? false : isReadOnly,
                }}
                required={(formik?.values?.interviewTypeId === InterviewTypeOption.webcam  && formik?.values?.finalizedAvailabilityId !== "")}
                fullWidth={true}
                variant="standard"
                id="meetingLink"
                label="Meeting Link"
                name="InterviewLocation.meetingLink"
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                value={formik?.values?.InterviewLocation?.meetingLink}
                error={formik?.touched?.InterviewLocation?.meetingLink && Boolean(formik?.errors?.InterviewLocation?.meetingLink)}
                helperText={formik?.touched?.InterviewLocation?.meetingLink && formik?.errors?.InterviewLocation?.meetingLink}
              />
            </Grid>
          }
      </Grid>

      <Grid container spacing={1} mt={2}>
        <Grid item xs={12}>
          <InputLabel htmlFor="minimum-height-textarea" required shrink={true}>
            Description
          </InputLabel>
          <TextField
            required
            multiline
            aria-label="minimum height"
            minRows={5}
            placeholder="Enter the description here"
            className="interviewAVdescription"
            id="minimum-height-textarea"
            variant="outlined"
            label=""
            fullWidth
            error={formik?.touched?.description && Boolean(formik?.errors?.description)}
            helperText={formik?.touched?.description && formik?.errors?.description || "Maximum limit allowed is 500 characters."}
            name="description"
            value={formik?.values?.description}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            
          />
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
      {/* Feedback only required when schedule interview i.e select the interview availability(finalizedAvailabilityId) */}
      { (formik?.values?.finalizedAvailabilityId !== undefined && formik?.values?.finalizedAvailabilityId !== "") &&
      <Grid container spacing={1} mt={2}>
        <Grid item xs={12} sm={12}>
          <InputLabel required shrink={true}>Feedback/Comment</InputLabel>
          <TextField
            error={
              formik.touched?.feedback &&
              Boolean(formik.errors?.feedback)
            }
            helperText={
              formik.touched?.feedback &&
              formik.errors?.feedback || "Maximum limit allowed is 500 characters"
            }
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            value={formik.values?.feedback}
            name="feedback"
            fullWidth
            type="text"
            multiline
            rows={3}
            variant="outlined"
            className="interviewAVdescription"
            label=""
            placeholder="Add feedback here"
          />
        </Grid>
      </Grid>
      }
      <Grid container spacing={1} mt={2} justifyContent="flex-end">
      {pageStatus.isError &&
            <Grid item xs={12}>
              <CustomAlert message={pageStatus.message} type={"error"} />
            </Grid>
          }
        <Grid item xs={12}>
        <Box sx={{textAlign:"right"}}>
          <Button
            className="submission-action-btn"
            variant="outlined"
            style={{ textTransform: "capitalize" }}
            onClick={handleClose}
            disabled={pageStatus?.loading}
          >
            Cancel
          </Button>
          <ModuleComponent moduleId={type === "viewSharedAvailability"  && !isChecked ? PermissionsEnums.InterviewSchedule : ""}>
          <Button
            data-testid="contained"
            className="submission-action-btn"
            variant="contained"
            style={{ textTransform: "capitalize" }}
            onClick={() =>{
              console.log("values",formik?.values);
              console.log("errors",formik?.errors);
              formik.handleSubmit()
              }
            }
            disabled={pageStatus?.loading}
            endIcon={
              pageStatus?.loading ? (
                <CircularProgress size={"16px"} color={"inherit"} />
              ) : ( 
                ""
              )
            }
          >
            {formatButtonText()}
          </Button>
          </ModuleComponent>
          </Box>
        </Grid>
        
      </Grid>
    </>
  );
};
export default CandidateInterviewSchedulerForm;
