import { createSlice } from "@reduxjs/toolkit";
import TeamMember from "../../models/TeamMember/AddEditTeamMemberModel";


  
  const initialState: TeamMember = {
    clientId: "",
    email: "",
    firstName: "",
    lastName: "",
    middleName: "",
    primaryPhoneNumber: "",
    secondaryPhoneNumber: "",
    address: {
      id: "",
      streetAddress: "",
      city: "",
      state: "",
      postalCode: "",
      countryCode: "",
    },
  };
  
const AddEditTeamMemberFormSlice = createSlice({
  name: "AddEditTeamMemberFormSlice",
  initialState,
  reducers: {
    setTeamMemberFormState: (state, action: any) => {
      return action.payload;
    },
  },
});

export const { setTeamMemberFormState } = AddEditTeamMemberFormSlice.actions;

export default AddEditTeamMemberFormSlice.reducer;
