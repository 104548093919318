import React from 'react';
import Typography from '@mui/material/Typography';

interface SectionHeadingProps {
  title: string;
  level?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  classes?:string;
}


const SectionHeading: React.FC<SectionHeadingProps> = ({ title, level = 'h5',classes="" }) => {

  return (
    <Typography variant={level as any} className={`formSectionHeading ${classes}`} >
      {title}
    </Typography>
  );
};

export default SectionHeading;
