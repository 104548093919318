import moment from 'moment-timezone';

function convertToLocalizedDateTime(
  dateTimeString: string,
  format: string
): string {
  try {
    const clientTimeZone: string = getBrowserTimezone();
    const dateTime = moment(dateTimeString);
    
    if (dateTime.isValid()) {
      // If the date is valid, convert to the client's timezone and format it
      return dateTime.tz(clientTimeZone).format(format);
    } else {
      // If the date is invalid, return the original input
      return dateTimeString;
    }
  } catch (error) {
    console.error('Error converting dateTime:', error);
    return '';
  }
}


function getBrowserTimezone(): string {
  return moment.tz.guess();
}

export default convertToLocalizedDateTime;
