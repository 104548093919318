//Navigation for recruiter when usertype is recruiter

import { PermissionsEnums } from "../permission";


//Navigation for admin when usertype is admin
export const recruiterHeaderNavigation = [
    {
        name: 'Dashboard',
        to: '/dashboard',
        permissionId : PermissionsEnums.Dashboard_Recruiter

    },
    {
        name: 'Jobs',
        to: '/jobs/all',
        permissionId : PermissionsEnums.Jobs_View
    },
    {
        name: 'FAQ',
        to: '/faq',
        permissionId : PermissionsEnums.FAQ

    }
]

export const clientHeaderNavigation = [
    {
        name: 'Dashboard',
        to: '/dashboard',

    },
    {
        name: 'Jobs',
        to: '/jobs/all',
        permissionId : PermissionsEnums.Jobs_View,

    },
    {
        name: 'FAQ',
        to: '/faq',

    }
]