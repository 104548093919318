import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { PermissionsEnums } from "../../../../data/permission";
import { JobOriginIds } from "../../../../models/Job/JobDetailsModel";
import { UserAuth } from "../../../../models/interfaces";
import { CandidateSubmissionStatus } from "../../../../models/submission/CandidateSubmissionMasterStatusesModel";
import { ModuleComponent } from "../../../Common/Permission/ModuleComponent";
import "../../NewViewSubmission/styles.scss";

interface CancelInterviewButtonProps {
  row: any;
  userRole: any;
  currentjobDetails: any;
  setPopup: any;
  hasActiveEvent: any;
}

export default function CancelInterviewButton({
  row,
  userRole,
  currentjobDetails,
  setPopup,
  hasActiveEvent
} : CancelInterviewButtonProps) {
  const shouldRenderCancelInterviewButton = (
    !hasActiveEvent(row?.interviewAvailabilities || []) &&
    currentjobDetails?.jobOriginId === JobOriginIds.jobRialto &&
    (row.finalStatusId === CandidateSubmissionStatus.InterviewScheduled ||
      row.finalStatusId === CandidateSubmissionStatus.InterviewRescheduled ||
      row.finalStatusId === CandidateSubmissionStatus.NextRoundOfInterview) &&
    (userRole === UserAuth.Client || userRole === UserAuth.ClientTeamMember)
  );

  const handleCancelInterviewClick = () => {
    if (shouldRenderCancelInterviewButton) {
      setPopup((popup: any) => ({ ...popup, cancelInterview: true }));
    }
  };

  return (
    <>
    {shouldRenderCancelInterviewButton && (
      <ModuleComponent moduleId={PermissionsEnums.InterviewCancelled}>
        <Grid item>
          <Button
            className="submission-action-btn"
            variant="contained"
            color="primary"
            onClick={handleCancelInterviewClick}
          >
            Cancel Interview
          </Button>
        </Grid>
      </ModuleComponent>
    )}
    </>
  );
}