import  { FC } from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';

interface SaveAndContinueConfirmPopupProps {
  saveResumeConfirmDialog: boolean;
  handleSaveConfirmationYes: () => void;
  handleSaveConfirmationNo: () => void;
}

const SaveAndContinueConfirmPopup: FC<SaveAndContinueConfirmPopupProps> = ({
  saveResumeConfirmDialog,
  handleSaveConfirmationYes,
  handleSaveConfirmationNo,
}) => {
  return (
    <Dialog
      open={saveResumeConfirmDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent >
        <DialogContentText>
          <Typography
             component="div"
             className="PopupPrimaryTitle"
          >
            Are you sure to save candidate's information?
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{ justifyContent: "center", marginBottom:"16px" }}
      >
        <Button
          className="roboto-font"
          data-testid="saveAndContinueYes"
          onClick={handleSaveConfirmationYes}
          variant="contained"
        >
          Yes
        </Button>
        <Button
          className="roboto-font"
          data-testid="saveAndContinueNo"
          onClick={handleSaveConfirmationNo}
          variant="outlined"
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveAndContinueConfirmPopup;
