import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import getLoggedInUserRole from "../../../Shared/utils/getLoggedInUserRole";
import getUserDetails from "../../../Shared/utils/getUserDetails";
import { PermissionsEnums } from "../../../data/permission";
import { RecruiterStatus } from "../../../models/enumerations";
import { UserAuth } from "../../../models/interfaces";
import { ModuleComponent } from "../../Common/Permission/ModuleComponent";
import SnackbarPopup from "../../Common/Popup/snackbar/SnackbarPopup";
import verifiedIcon from "./../../../assets/images/verified.svg";
import SaveUnsaveConfirmPopup from "./SaveUnsaveConfirmPopup";
import { ternary, valueOrDefault } from "../../../utils/complexityUtils";


enum JobType {
  SAVED,
  UNSAVED,
}

export interface SaveUnsavedJobStatusProps{
  isSaved:boolean;
  isUnsaved:boolean;
  message:string;
}

interface Props {
  saveJobAPI: any;
  jobDetails: any;
  isSavedJob: any;
  unsaveJobAPI: any;
  featureJobAPI: any;
  isFeatured: any;
  isLoadingJob: any;
  shareJob: any;
  shareJobState: any;
  setShareJobState:any;
  setDisplayContactPopup: any;
  recruiterStatus: any;
  saveUnsavedJobStatus:SaveUnsavedJobStatusProps;
  setSaveUnsavedJobStatus: any;
}
const SaveShareSubmitButtons: React.FC<Props> = ({
  saveJobAPI,
  jobDetails,
  isSavedJob,
  unsaveJobAPI,
  featureJobAPI,
  isFeatured,
  isLoadingJob,
  shareJob,
  shareJobState,
  setShareJobState,
  setDisplayContactPopup,
  recruiterStatus,
  saveUnsavedJobStatus,
  setSaveUnsavedJobStatus
}) => {
  
  let isJobActive = jobDetails?.jobStatus?.toLowerCase() === "active";
  let userId = getUserDetails()?.id;
  let navigate = useNavigate();
  let { fromPage } = useParams();

  let isPermitted = getLoggedInUserRole();
  const [isOpenSaveUnsaveConfirmPopup, setIsOpenSaveUnsaveConfirmPopup] = useState<boolean>(false);
  const [confirmMessage, setConfirmMessage] = useState<string>('');
  const [JobTypeState, setJobTypeState] = useState<JobType | undefined>(undefined); 

  const updateFeatureJob = () => {
    featureJobAPI.clear();
    featureJobAPI.request();
  };
  const { recruiterData } = useSelector((state: any) => state.recruiter);

  const _handleSaveJob = () =>{
    if (isSavedJob !== true) saveJobAPI.request();
  }

  const _handleUnsaveJob = () =>{
    if (isSavedJob === true) unsaveJobAPI?.request();
  }

  const _handleConfirmSaveUnsave = (type:JobType) =>{
    if(JobType.SAVED === type){
      setConfirmMessage('Are you sure you want to save this job?')
      setJobTypeState(JobType.SAVED)
    }else if (JobType.UNSAVED === type){
      setConfirmMessage('Are you sure you want to unsave this job?')
      setJobTypeState(JobType.UNSAVED)
    }
    setIsOpenSaveUnsaveConfirmPopup(true)
  }

  const _handleConfirm = () =>{
    setIsOpenSaveUnsaveConfirmPopup(false);
    if(JobTypeState === JobType.SAVED){
      _handleSaveJob()
    }else if(JobTypeState === JobType.UNSAVED){
      _handleUnsaveJob()
    }
  }

  const _handleCloseDialog = () =>{
    setIsOpenSaveUnsaveConfirmPopup(false);
  }

  function adminActionButtons() {
    return (
      <>
        {" "}
        <ModuleComponent moduleId={PermissionsEnums.Jobs_Mark_Featured}>
          <Button
            data-testid={"updateFeatureJob"}
            onClick={updateFeatureJob}
            className={ternary(
              (!isJobActive || jobDetails?.isDisabled !== null),
              "custom-button-Robo custom-three-button custom-button-right",
              "custom-button-Robo custom-three-button custom-button-right"
            )}
            disabled={valueOrDefault(
              !isJobActive,
              ternary(
                jobDetails?.isDisabled !== null,
                jobDetails?.isDisabled,
                false
              )
            )}
            variant={"contained"}
          >
            {ternary(isFeatured, "Featured Job", null)}
            {ternary(
              isFeatured,
              <img
                style={{ paddingLeft: "10px", height: "20px" }}
                src={verifiedIcon}
              ></img>,
              <>{"Featured Job"}</>
            )}
          </Button>
        </ModuleComponent>
        <ModuleComponent moduleId={PermissionsEnums.Jobs_Share}>
          <Button
            data-testid="shareJobBtn2"
            className="custom-button-Robo custom-three-button custom-button-right"
            disabled={valueOrDefault(
              ternary(
                jobDetails?.isDisabled !== null,
                jobDetails?.isDisabled,
                false
              ),
              shareJobState.isSharing
            )}
            variant="contained"
            onClick={() => {
              shareJob();
            }}
            endIcon={ternary(
              shareJobState.isSharing,
              <CircularProgress size={"16px"} color={"inherit"} />,
              <>{""}</>
            )}
          >
            Share Job
          </Button>
        </ModuleComponent>
        <ModuleComponent moduleId={PermissionsEnums.Jobs_Partner_Contacts}>
          <Button
            className="custom-button-Robo custom-three-button"
            variant="contained"
            onClick={() => {
              setDisplayContactPopup(true);
            }}
          >
            Contacts
          </Button>
        </ModuleComponent>
        <div style={{ marginTop: "10px" }}>
          {shareJobState.success && shareJobState.msg != "" ? (
            <SnackbarPopup
            open={shareJobState.success}
            message={shareJobState.msg}
            onClose={() =>
              setShareJobState((prevState: any) => ({
                ...prevState,
                popup: false,
                success: false
              }))
            }
          />
          ) : (
            ""
          )}
        </div>
      </>
    );
  }

  const actionButtons = () => {
    if (isPermitted == UserAuth.Admin || isPermitted == UserAuth.SuperAdmin) {
      return adminActionButtons();
    }
    return (
      <>
        {" "}
        {userId && (
          <>
            <ModuleComponent moduleId={PermissionsEnums.Jobs_Saved}>
              {isSavedJob ? (
                <Button
                  data-testid="SavedBtn"
                  className="custom-button-Robo custom-three-button"
                  style={{ marginRight: "20px" }}
                  onClick={()=>{_handleConfirmSaveUnsave(JobType.UNSAVED)}}
                  disabled={
                    (recruiterStatus !== RecruiterStatus.approved)
                  }
                  variant="outlined"
                >
                  Saved{" "}
                  <img
                    style={{ paddingLeft: "10px", height: "20px" }}
                    src={verifiedIcon}
                  ></img>
                </Button>
              ) : (
                <Button
                  data-testid="UnsavedBtn"
                  className="custom-button-Robo custom-three-button"
                  style={{ marginRight: "20px" }}
                  onClick={()=>{_handleConfirmSaveUnsave(JobType.SAVED)}}
                  disabled={
                    !isJobActive ||
                    (recruiterStatus !== RecruiterStatus.approved) ||
                    ternary(
                      jobDetails?.isDisabled !== null,
                      jobDetails?.isDisabled,
                      false
                    )
                  }
                  variant="outlined"
                >
                  Save Job
                </Button>
              )}
            </ModuleComponent>
          </>
        )}
        <ModuleComponent moduleId={PermissionsEnums.Submit_Resume} showIfNotLoggedIn={true}>
          <Button
            className="custom-button-Robo custom-three-button"
            style={{ marginRight: "20px" }}
            disabled={valueOrDefault(
              ternary(
                Boolean(userId),
                valueOrDefault(!isJobActive, recruiterStatus !== RecruiterStatus.approved),
                false
              ),
              ternary(jobDetails?.isDisabled !== null, jobDetails?.isDisabled, false)
            )}
            variant="contained"
            onClick={() => {
              getLoggedInUserRole() !== UserAuth.NotLoggedIn
                ? navigate(
                    `/submitresume/${jobDetails?.id}/${
                      fromPage + "" + ternary(fromPage === "recruiter" , "SavedJob", "") + "details"
                    }`
                  )
                : navigate("/login", {
                    state: {
                      redirectUrl: `/submitresume/${jobDetails?.id}/${
                        fromPage + "" + "details"
                      }`,
                    },
                  });
            }}
          >
            Submit Resume
          </Button>
        </ModuleComponent>
        {userId && (
          <>

            {isPermitted === UserAuth.ClientTeamMember ? (
              <ModuleComponent moduleId={PermissionsEnums.Jobs_Share}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    data-testid="shareJobBtn3"
                    className="custom-button-Robo custom-three-button"
                    variant="contained"
                    onClick={() => {
                      shareJob();
                    }}
                    endIcon={ternary(
                      shareJobState.isSharing,
                      <CircularProgress size={"16px"} color={"inherit"} />,
                      <>{""}</>
                    )}
                  >
                    Share Job
                  </Button>
                </div>
              </ModuleComponent>
            ) : (
              <ModuleComponent moduleId={PermissionsEnums.Jobs_Share}>
                <Button
                  data-testid="shareJobBtn1"
                  className="custom-button-Robo custom-three-button"
                  disabled={
                    recruiterData?.entity?.status !== RecruiterStatus.approved
                  }
                  variant="contained"
                  onClick={() => {
                    shareJob();
                  }}
                  endIcon={ternary(
                    shareJobState.isSharing,
                    <CircularProgress size={"16px"} color={"inherit"} />,
                    <>{""}</>
                  )}
                >
                  Share Job
                </Button>
              </ModuleComponent>
            )}

            <div className="custom-alrt-margin">
              {shareJobState.success && shareJobState.msg != "" ? (
                <SnackbarPopup
                open={shareJobState.success}
                message={shareJobState.msg}
                onClose={() =>
                  setShareJobState((prevState: any) => ({
                    ...prevState,
                    popup: false,
                    success: false
                  }))
                }
              />
              ) : (
                ""
              )}
            </div>
          </>
        )}
      </>
    );
  };
  return (
    <Grid>
      <Box className="jobsRightSidebar">
        <Box className="rightSidebarbtn">{actionButtons()}</Box>

        <SaveUnsaveConfirmPopup 
          open={isOpenSaveUnsaveConfirmPopup}
          message={confirmMessage}
          handleConfirm={_handleConfirm} 
          onClose={_handleCloseDialog} 
          />
        {
          saveUnsavedJobStatus?.isSaved && 
           <SnackbarPopup
            open={(saveUnsavedJobStatus?.isSaved) }
            message={saveUnsavedJobStatus?.message}
            onClose={
                ()=>{
                setSaveUnsavedJobStatus({
                ...saveUnsavedJobStatus,
                isSaved:false, 
                isUnsaved:false,
                message:""
              })
            }
          }
         />
          
        }
        {
          saveUnsavedJobStatus?.isUnsaved && 
          <SnackbarPopup
            open={(saveUnsavedJobStatus?.isUnsaved) }
            message={saveUnsavedJobStatus?.message}
            onClose={
                ()=>{
                setSaveUnsavedJobStatus({
                ...saveUnsavedJobStatus,
                isSaved:false, 
                isUnsaved:false,
                message:""
              })
            }
          }
          />
        }
      </Box>
    </Grid>
  );
};
export default SaveShareSubmitButtons;
