import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";


interface Props {
  isOpen: boolean;
  status: string;
}

const StatusPopup: React.FC<Props> = ({ isOpen, status }) => {

  const [useMessage, setMessage] = React.useState("");

  const checkMessage = () => {
    if (status === "Approved")
      setMessage("The profile has now approved by JobRialto team");
    else if (status === "Suspended")
      setMessage("Account Suspended Permanently");
    else if (status === "Blacklisted")
      setMessage("Account blacklisted");
    else if (status === "Rejected")
      setMessage("Account rejected");
    else if (status === "Incomplete")
      setMessage("Account incomplete");
    else setMessage("The Profile is Pending for review");
  };

  React.useEffect(() => {
    checkMessage();
  });

  return (
    <Dialog
      container={() => document.getElementById("parentDiv")}
      open={isOpen}
      aria-describedby="alert-dialog-description"
      style={{ zIndex: 5 }}
    >
      <DialogTitle>
        <div
          className="row p-1"
          style={{ textAlign: "center", padding: "10px",fontWeight:"bold" }}
        >
          {useMessage}
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          className="alert-dialog-description"
          style={{
            textAlign: "center",
            padding: "20px",
            fontFamily: "DM Sans",
            fontSize: "16px",
            fontStyle: "normal",
            color: "#06152B",
          }}
        >
          It looks like your JobRialto account is {status}. To get all the
          details, look for the email we sent you at the time your account was{" "}
          {status} by Admin. The email subject line should read: “Your JobRialto
          account has been {status}. You can contact the support center for more
          details by writing an email to: SupportCenter@Jobrialto.com
        </DialogContentText>
        <div
          className="row p-5"
          style={{ textAlign: "center", padding: "30px", fontSize: "12px" }}
        ></div>
        <div className="row pt-2" style={{ textAlign: "center" }}>
          <Button variant="contained" href="mailto:customersupport@jobrialto.com">
            Contact Support Center
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default StatusPopup;
