import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { JobOriginIds } from "../../../../models/Job/JobDetailsModel";
import { UserAuth } from "../../../../models/interfaces";
import { CandidateSubmissionStatus } from "../../../../models/submission/CandidateSubmissionMasterStatusesModel";
import "../../NewViewSubmission/styles.scss";

interface OfferDeclinedButtonProps {
  row: any;
  userRole: UserAuth;
  currentjobDetails: any;
  setPopup: any;
}

export default function OfferDeclinedButton({
  row,
  userRole,
  currentjobDetails,
  setPopup
} : OfferDeclinedButtonProps) {
  return (
    <>
      {userRole === UserAuth.Admin ||
       userRole === UserAuth.SuperAdmin ? 
        (
          currentjobDetails?.jobOriginId === JobOriginIds.jobRialto &&
          row.finalStatusId === CandidateSubmissionStatus.CandidateSelected &&
          <Grid item>
            <Button
              className="submission-action-btn"
              variant="contained"
              color="primary"
              onClick={() => {
                setPopup((popup : any) => ({
                  ...popup,
                  offerdeclined: true,
                }));
              }}
            >
              Offer Declined
            </Button>
          </Grid>
        )
        :
        ""
      }
    </>
  );
}