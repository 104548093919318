import { createSvgIcon } from "@mui/material/utils";

export const Contract = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="26"
    viewBox="0 0 28 28"
    fill="none"
  >
    <path
      d="M19.6053 24.5171H20.9831C20.9831 24.569 20.9925 24.6209 20.9925 24.6728C20.9925 25.2911 20.9925 25.9093 20.9925 26.5276C20.9925 27.4101 20.4074 28 19.5204 28C13.509 28 7.49759 28 1.48621 28C0.584973 28 -0.00012207 27.4054 -0.00012207 26.504C-0.00012207 18.1884 -0.00012207 9.86347 -0.00012207 1.54323C-0.00012207 0.835328 0.301863 0.325636 0.877521 0.108545C1.08985 0.0330356 1.32578 0.00471937 1.55227 0C5.98295 0 10.4136 0 14.8443 0C16.3967 0 17.9491 0 19.5062 0C20.1857 0 20.6953 0.330356 20.9029 0.910838C20.9548 1.05714 20.9878 1.2176 20.9878 1.37334C20.9925 3.90764 20.9878 6.43722 20.9878 8.97151C20.9878 9.00455 20.9831 9.03759 20.9784 9.08006H19.61V1.40637H1.40128V26.6031H19.6053V24.5218V24.5171Z"
      fill="#4540DB"
    />
    <path
      d="M15.4391 17.4995C15.3212 17.4995 15.2173 17.4995 15.1135 17.4995C11.5511 17.4995 7.99331 17.4995 4.43083 17.4995C4.27984 17.4995 4.20435 17.4224 4.20435 17.2682C4.20435 16.886 4.20435 16.5037 4.20435 16.1073H15.4202C15.5288 15.6306 15.6326 15.1776 15.7411 14.6962H4.20906V13.3087C4.28928 13.3087 4.36477 13.3087 4.43555 13.3087C8.37079 13.3087 12.3107 13.3087 16.246 13.3087C16.4017 13.3087 16.4866 13.2568 16.581 13.1341C17.6663 11.6522 19.1196 10.7649 20.9503 10.5526C24.0929 10.1845 26.9428 12.1194 27.778 15.1587C28.75 18.6935 26.4285 22.3557 22.8142 22.9881C19.4168 23.5875 16.1894 21.3835 15.5241 18.0092C15.491 17.844 15.4674 17.6741 15.4391 17.49V17.4995ZM26.6078 16.8199C26.6173 14.1015 24.4279 11.9023 21.71 11.8976C19.011 11.8882 16.8028 14.0921 16.798 16.7916C16.7933 19.4911 18.9874 21.6997 21.6959 21.7092C24.3949 21.7233 26.6078 19.5194 26.6126 16.8152L26.6078 16.8199Z"
      fill="#4540DB"
    />
    <path
      d="M8.67727 20.0524C9.24821 19.5616 9.89936 19.3162 10.659 19.4059C11.4423 19.5003 11.9661 19.9156 12.2303 20.666C12.4096 20.5149 12.5653 20.3498 12.7493 20.2271C13.0891 20.0005 13.4335 19.7787 13.7969 19.5947C14.1743 19.4059 14.5896 19.5711 14.7453 19.9345C14.9057 20.3026 14.7406 20.6895 14.3489 20.8642C13.5562 21.2181 12.9947 21.8269 12.5134 22.5301C12.3436 22.7802 12.1973 23.054 12.0463 23.3182C11.8906 23.592 11.6075 23.7477 11.3291 23.7052C11.0035 23.6533 10.7723 23.4221 10.7345 23.0964C10.7204 22.9879 10.7487 22.8699 10.777 22.7613C10.895 22.3177 10.9893 21.8694 10.961 21.4022C10.9233 20.85 10.6968 20.6943 10.0881 20.8217C9.80499 20.883 9.59738 21.0765 9.41808 21.2983C9.02172 21.7797 8.79051 22.3413 8.61593 22.936C8.59234 23.0209 8.57346 23.1059 8.54987 23.1908C8.43663 23.5542 8.0733 23.7524 7.70998 23.658C7.35609 23.5684 7.14375 23.2238 7.20981 22.851C7.29475 22.346 7.39855 21.8411 7.45046 21.3314C7.47405 21.086 7.42686 20.8311 7.37496 20.5857C7.32778 20.3592 7.22397 20.3073 6.99748 20.3073C6.61528 20.3073 6.29914 20.463 6.03019 20.7226C5.59136 21.1473 5.33656 21.6853 5.14782 22.2564C5.04402 22.5679 4.96852 22.8888 4.89774 23.205C4.79865 23.6344 4.46364 23.8704 4.05785 23.7902C3.68037 23.7147 3.4586 23.356 3.53409 22.9407C3.72283 21.9496 4.04369 21.0057 4.65238 20.1893C5.12423 19.5522 5.7282 19.1086 6.52563 18.9623C7.47405 18.7924 8.27148 19.118 8.65368 20.0147C8.6584 20.0288 8.67255 20.0383 8.68671 20.0619L8.67727 20.0524Z"
      fill="#4540DB"
    />
    <path
      d="M4.19952 7.71175H16.7791V9.09453C16.7083 9.09453 16.6422 9.09453 16.5809 9.09453C12.5183 9.09453 8.45562 9.09453 4.39298 9.09453C4.23255 9.09453 4.19009 9.04733 4.1948 8.89159C4.20424 8.49988 4.1948 8.10818 4.1948 7.70703L4.19952 7.71175Z"
      fill="#4540DB"
    />
    <path
      d="M16.7791 11.8886H4.20902C4.20902 11.8273 4.19958 11.7659 4.19958 11.7046C4.19958 11.3648 4.2043 11.025 4.19958 10.6899C4.19958 10.5483 4.23733 10.5059 4.38361 10.5059C5.87937 10.5106 7.37986 10.5059 8.87563 10.5059C11.4378 10.5059 14.0047 10.5059 16.5668 10.5059C16.6376 10.5059 16.7084 10.5059 16.7839 10.5059V11.8886H16.7791Z"
      fill="#4540DB"
    />
    <path
      d="M16.7836 4.91797V6.27715H4.2135V4.91797H16.7883H16.7836Z"
      fill="#4540DB"
    />
    <path
      d="M23.9743 14.2148C24.3235 14.5641 24.6443 14.885 24.9841 15.2248C24.9133 15.2861 24.8331 15.3522 24.7576 15.4277C23.6959 16.4896 22.6295 17.5514 21.5679 18.618C21.1857 19.0003 20.8082 19.0003 20.4307 18.618C19.7984 17.9856 19.1614 17.3485 18.5291 16.7161C18.4961 16.6831 18.4631 16.6453 18.4442 16.6264C18.7698 16.3008 19.0906 15.9752 19.4257 15.6448C19.9305 16.1545 20.459 16.6831 20.9875 17.2164C21.9925 16.2111 22.9834 15.2153 23.9743 14.2196V14.2148Z"
      fill="#4540DB"
    />
  </svg>,
  "Contract"
);
