import { UserAuth } from "../../models/interfaces";
import { LeftNavigation } from "../Common/Navigation/LeftNavigation";
import { superAdminNavItems } from "../../data/navigation/superadmin";
import { recruiterNavItems } from "../../data/navigation/recruiter";
import { adminNavItems } from "../../data/navigation/admin";
import { clientNavItems } from "../../data/navigation/client";
import { teamNavItems } from "../../data/navigation/teams";
import getLoggedInUserRole from "../../Shared/utils/getLoggedInUserRole";

export const LeftNavigationRoot = () => {
  const userType = getLoggedInUserRole();

  // Sort Admin Left menu by orderId 
  const sortPagesByOrderId = (pages) => pages.sort((a, b) => a.orderId - b.orderId);

  const sortedAdminNavItems = sortPagesByOrderId(adminNavItems);

  // Sort Admin Left menu by orderId 
  const sortedSuperAdminNavItems = sortPagesByOrderId(superAdminNavItems);

    const renderNavigation = () => {
        switch (userType) {
          case UserAuth.Admin:
            return (
              <LeftNavigation userRole={UserAuth.Admin} items={sortedAdminNavItems} />
            );
          case UserAuth.SuperAdmin:
            return (
              <LeftNavigation
                userRole={UserAuth.SuperAdmin}
                items={sortedSuperAdminNavItems}
              />
            );
          case UserAuth.Recruiter:
            return (
              <LeftNavigation
                userRole={UserAuth.Recruiter}
                items={recruiterNavItems}
              />
            );
            case UserAuth.Client:
              return (
                <LeftNavigation
                  userRole={UserAuth.Client}
                  items={clientNavItems}
                />
              );
              case UserAuth.ClientTeamMember:
                return (
                  <LeftNavigation
                    userRole={UserAuth.ClientTeamMember}
                    items={teamNavItems}
                  />
                );
    
          default:
            break;
        }
      };
  return (
    <>{renderNavigation()}</>
  )
}
