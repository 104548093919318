import { Avatar, Card, CardContent } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function Statistics({
  bg,
  yourStatText,
  bigNo,
  sinceText,
  icon,
}) {
  return (
    <Card sx={{ height: "100%" }} className={"dashboard-sml-card " + bg}>
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="overline">
              {yourStatText == "Interviews Acceptance" ? "Candidate Selected" : yourStatText}
            </Typography>
            <Typography color="textPrimary" variant="h4">
              {bigNo}
            </Typography>
          </Grid>
          <Grid item sx={{ position: "relative", top: "5px" }}>
            <Avatar
              sx={{
                backgroundColor: "transparent",
                height: 20,
                width: 20,
                borderRadius: "0",
              }}
            >
              <img src={icon} alt="" />
            </Avatar>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography color="textSecondary" variant="caption">
            {sinceText}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}
