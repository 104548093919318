import { makeStyles } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { Button, Grid, Tooltip } from "@mui/material";
import React from "react";
import { PermissionsEnums } from "../../../data/permission";
import { ternary } from "../../../utils/complexityUtils";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  disabledButton: {
    backgroundColor: "rgba(0, 0, 0, 0.12) !important",
    color: "#757575 !important",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.12) !important",
      color: "#757575 !important",
    },
    width: "100%",
  },
});

interface Props {
  activePermissions: any;
  inactivePermissions: any;
  allFeatures: any;
  handleAccessChange: any;
}

export const AccessSection: React.FC<Props> = ({
  activePermissions,
  inactivePermissions,
  allFeatures,
  handleAccessChange,
}) => {
  const classes = useStyles();

  const allModule = allFeatures?.data?.entityList?.flatMap(
    (entity: any) => entity.modules.map((module: any) => module)
  );

  //MODULE = permisssions, Feature = PAGE

  const getModuleById = (id: string) => {
    let module = allModule.find((x: any) => x.id == id);
    if (module !== undefined) {
      return module;
    }
  };
  const getFeatureById = (id: string) => {
    return allFeatures.data?.entityList?.find((x: any) => x.id == id);
  };
  const checkIdType = (id: string): string => {
    const feature = allFeatures?.data?.entityList?.find(
      (feature: any) => feature.id === id
    );
    if (feature) {
      return "Feature";
    }

    const module = allFeatures?.data?.entityList
      .flatMap((feature: any) => feature.modules)
      .find((module: any) => module.id === id);

    if (module) {
      return "Module";
    }

    return "Not Found";
  };
  const replaceUnderscores = (text:any) => {
    if (text?.length > 19) {
      const lastUnderscoreIndex = text.lastIndexOf('_');
      if (lastUnderscoreIndex > -1) {
        const newText = text?.slice(0, lastUnderscoreIndex) + ' ' + text?.slice(lastUnderscoreIndex + 1);
        return newText;
      }
    }
    return text;
  };

  function getParentPermission(childPermission: string, dependencyMap: {[key: string]: string[]}): string[] {
    const parentPermissions: string[] = [];
    for (const parentPermission in dependencyMap) {
      if (dependencyMap[parentPermission].includes(childPermission)) {
        parentPermissions.push(parentPermission);
      }
    }
    return parentPermissions;
  }

  function findIds(id:string,source:string) : string[] {
    
    const dependencyMap = {
      [PermissionsEnums.Contracts_Details] : [PermissionsEnums.Contracts_View_Previous_Rate],
      [PermissionsEnums.Submissions_Conversations] : [PermissionsEnums.Submissions_Comments],
      [PermissionsEnums.Earnings_View] : [PermissionsEnums.Earnings_Approve],
      [PermissionsEnums.Jobs_Job_Details] : [PermissionsEnums.Jobs_Share],
      [PermissionsEnums.CLUserView] : [PermissionsEnums.Members_Verify,PermissionsEnums.Members_Add,PermissionsEnums.Members_Listing,PermissionsEnums.Members_Edit],
      [PermissionsEnums.Jobs_Add] : [PermissionsEnums.Jobs_Pending,PermissionsEnums.Jobs_Add_Publish],
      [PermissionsEnums.Jobs_View] : [PermissionsEnums.Jobs_Pending,PermissionsEnums.Jobs_Add_Publish],
      [PermissionsEnums.CLUserEdit] : [PermissionsEnums.Members_Verify,PermissionsEnums.Members_Add,PermissionsEnums.Members_Listing,PermissionsEnums.Members_Edit],
      [PermissionsEnums.Jobs_Pending] : [PermissionsEnums.Jobs_Pending_Publish_Publish,PermissionsEnums.Jobs_Pending_Publish_Save,PermissionsEnums.Jobs_Pending_Publish],
      [PermissionsEnums.ViewSharedAvailability] : [PermissionsEnums.InterviewSchedule],
    } as {[key: string] : string[]};

    let featuresObj = allFeatures?.data?.entityList
    
    const dependencies  = dependencyMap[id] ;
    let otherIds =  dependencies || [];
    for (const entity of featuresObj) {
      if (entity.id === id) {
        return processFeatures(
          id, source, entity,
          dependencyMap, otherIds
        );
      }
      else if (entity.modules) {
        let res = processModules(
          id, source, entity,
          dependencyMap, otherIds
        );
        if (res !== undefined) return res;
      }
    }
  
    // Return an empty array if the id is not found
    return [];
  }

  function processFeatures(
    id:string, 
    source:string, 
    entity:any, 
    dependencyMap: {[key: string]: string[]},
    otherIds: string[]
  ) : string[] {
    if(source=='inactivePermission')
    {
      let pare = getParentPermission(entity.id, dependencyMap)
      return [id].concat(pare);
    }
  
    // If the id is found in the top-level entities, check if it's a module
    return entity.modules ? [entity.id, ...entity.modules.map((module:any) => module.id)].concat(otherIds) : [entity.id];
  }

  function processModules(
    id:string,
    source:string,
    entity:any,
    dependencyMap: {[key: string]: string[]},
    otherIds: string[]
  ) : string[] | undefined {
    // Check if the id belongs to any module inside a feature
    const module = entity.modules.find((module:any) => module.id === id);
    if (module && source=="inactivePermission") {
      //For dependency map if hierarchical
      for (const key in dependencyMap) {
        if (dependencyMap[key].includes(id)) {
          return [key].concat([entity.id, module.id])
        }
      }

      return [entity.id, module.id];
    }
    
    if(module)
    {
      //For dependency map if hierarchical
      return [module.id].concat(otherIds)
    }

    return undefined;
  }

  const handlePermissionClick = (id:string,source:string)=>{
    let dependendIds = findIds(id,source)
    for (let dependId of dependendIds) {
      let idType = checkIdType(dependId);
      let finalIdType =  idType == "Feature" ? "featureIds" : "moduleIds"
      handleAccessChange(
        source,
        dependId,
        finalIdType
      );
    }
  }
  
  return (
    <>
      {activePermissions.moduleIds.length > 0 ||
      activePermissions.featureIds.length > 0 ? (
        <Grid
          mt={2}
          pb={2}
          ml={0.1}
          container
          spacing={2}
          style={{ backgroundColor: "#F6F7FC", borderRadius: "4px" }}
        >
          {activePermissions.moduleIds
            ?.concat(activePermissions.featureIds)
            .sort((a:any, b:any) => {
              let moduleA, moduleB;
              const typeA = checkIdType(a);
              const typeB = checkIdType(b);

              if (typeA === "Module") {
                moduleA = getModuleById(a)?.moduleName;
              } else {
                moduleA = getFeatureById(a)?.featureName;
              }

              if (typeB === "Module") {
                moduleB = getModuleById(b)?.moduleName;
              } else {
                moduleB = getFeatureById(b)?.featureName;
              }

              return moduleA?.localeCompare(moduleB);
            })
            .map((id: any, index: any) => {
              let moduleData;
              let moduleName;
              let moduleDescription;
              let typee = checkIdType(id);
              if (typee == "Module") {
                moduleData = getModuleById(id);
                moduleName = moduleData?.moduleName;
                moduleDescription = moduleData?.moduleDescription;
              } else {
                const featureData = getFeatureById(id);
                moduleName = featureData?.featureName;
                moduleDescription = featureData?.featureDescription;
              }

              return (
                <>
                  {
                    ternary(
                      moduleName,
                      <Grid key={id ?? index} item xs={6} sm={4}>
                        <Tooltip title={moduleDescription}>
                          <Button
                            data-testid="activePermissionHandle"
                            onClick={() =>
                              handlePermissionClick(id,'activePermission')
                            }
                            style={{
                              wordWrap: 'break-word', 
                              maxWidth: '100%', 
                            }}
                            className="page-access-btn"
                            variant="contained"
                            endIcon={<ArrowDropDownIcon />}
                          >
                            {replaceUnderscores(moduleName)}
                          </Button>
                        </Tooltip>
                      </Grid>,
                      null
                    )
                  }
                </>
                
              );
            })}

          <br />
        </Grid>
      ) : null}
      <br />
      {inactivePermissions.moduleIds.length > 0 ||
      inactivePermissions.featureIds.length > 0 ? (
        <Grid
          mt={2}
          pb={2}
          ml={0.1}
          container
          spacing={2}
          style={{ backgroundColor: "#F6F7FC", borderRadius: "4px" }}
        >
          {inactivePermissions.moduleIds
            ?.concat(inactivePermissions.featureIds)
            .sort((a:any, b:any) => {
              let moduleA, moduleB;
              const typeA = checkIdType(a);
              const typeB = checkIdType(b);

              if (typeA === "Module") {
                moduleA = getModuleById(a)?.moduleName;
              } else {
                moduleA = getFeatureById(a)?.featureName;
              }

              if (typeB === "Module") {
                moduleB = getModuleById(b)?.moduleName;
              } else {
                moduleB = getFeatureById(b)?.featureName;
              }

              return moduleA?.localeCompare(moduleB);
            })
            .map((id: any, index: any) => {
              let moduleData;
              let moduleName;
              let moduleDescription;
              let typee = checkIdType(id);
              if (typee === "Module") {
                moduleData = getModuleById(id);
                moduleName = moduleData?.moduleName;
                moduleDescription = moduleData?.moduleDescription;
              } else {
                const featureData = getFeatureById(id);
                moduleName = featureData?.featureName;
                moduleDescription = featureData?.featureDescription;
              }

              return (
                <>
                  {ternary(
                    moduleName,
                    <Grid key={id ?? index} item xs={6} sm={4}>
                      <Tooltip title={moduleDescription}>
                        <Button
                        data-testid="inActivePermissionHandle"
                          onClick={() =>
                            handlePermissionClick(id,'inactivePermission')
                          }
                          className={classes.disabledButton}
                          variant="contained"
                          endIcon={<ArrowDropUpIcon />}
                        >
                          {replaceUnderscores(moduleName)}
                        </Button>
                      </Tooltip>
                    </Grid>,
                    null
                  )}
                </>
              );
            })}

          <br />
        </Grid>
      ) : null}
    </>
  );
};
