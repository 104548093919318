import Button from '@mui/material/Button';
import React, { useState } from 'react';

interface CopyLinkButtonProps {
  linkToCopy: string;
  variant?: 'outlined' | 'contained' | 'text';
  disabled?: boolean;
}

const CopyLinkButton: React.FC<CopyLinkButtonProps> = ({
  linkToCopy,
  variant = 'outlined',
  disabled = false,
}) => {
  const [buttonText, setButtonText] = useState('Copy Link');

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(linkToCopy);
      setButtonText(`${linkToCopy ==="" ? "Empty Link" : 'Link Copied'}`);
      setTimeout(() => {
        setButtonText('Copy Link');
      }, 2000);
    } catch (error) {
      console.error('Error copying link to clipboard', error);
    }
  };

  return (
    <Button disabled={disabled} variant={variant} color="primary" className="appBtn minWidth110" onClick={handleCopyLink}>
      {buttonText}
    </Button>
  );
};

export default CopyLinkButton;
