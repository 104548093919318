import IconButton from "@material-ui/core/IconButton";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import SaveIcon from '@mui/icons-material/Save';
import { DialogTitle } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import React from "react";
import { GetRecruiterById } from "../../Services/recruiterService";
import {
  DeletePhoto,
  DeleteResourceModel,
  UploadResourceModel,
} from "../../Services/uploadPhotoService";
import { Attach as AttachIcon } from "../Common/Icons/Attach";
import { Delete as DeleteIcon } from "../Common/Icons/Delete";
import { Edit as EditIcon } from "../Common/Icons/Edit";
import axios from './../../Services/CommonHttpRequest';

import CancelIcon from '@mui/icons-material/Cancel';
import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw';
import "cropperjs/dist/cropper.css";
import Cropper from "react-cropper";
import { useDispatch } from "react-redux";
import { GetClientById } from "../../Services/clientService";
import getLoggedInUserRole from "../../Shared/utils/getLoggedInUserRole";
import { SUPPORTED_FORMATS } from "../../data/supportedImageFormat";
import { updateImage } from "../../features/recruiterSlice";
import { ImageInfo } from "../../models/ImageInfo";
import { DocumentTypeEnum, UserAuth } from "../../models/interfaces";
import { loadImage } from "../../utils/getFileFromUrl";
import { CustomAlert } from "../ui/CustomAlert";
import RenderPhoto from "./RenderPhoto";
interface Props {
  handleOpenClose: (imageInfor: ImageInfo) => void;
  open: boolean;
}
const UploadPhotoDialog: React.FC<Props> = ({ handleOpenClose, open }) => {

  let loggedInUserRole = getLoggedInUserRole();
  const [file, setFile] = React.useState<File>();
  const [fileName, setFileName] = React.useState<string>();
  const dispatch = useDispatch()
  const [imageInfo, setImageInfo] = React.useState<ImageInfo>();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const cropperRef = React.useRef<HTMLImageElement>(null);
  const [isEditing, setIsEditing] = React.useState(false);
  const [imgData,setImageData]=React.useState("");

  const onSave = async () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    const type = imageInfo?.newFileName.split(".")[1];
    const res = await fetch(cropper.getCroppedCanvas()?.toDataURL('image/jpeg',0.5));
    const buf = await res?.arrayBuffer();
    const file = new File([buf], imageInfo?.newFileName!, { type: type! });
    setFile(file);
    setFileName(imageInfo?.newFileName);
  };
  const rotate = () => {
    setIsSubmitting(true);
    const imageElement:any = cropperRef?.current;
    const cropper : any = imageElement?.cropper;
    cropper?.rotate(90);
    
    setIsSubmitting(false);
  };
  const MAX_PHOTO_SIZE = 2000000;
  
  React.useEffect(() => {
    FetchCurrentUser();
  }, []);

  React.useEffect(() => {
    if (file && fileName) {
      UploadFile();
    }
  }, [file, fileName]);

  function FetchCurrentUser() {
    const user = JSON.parse(localStorage.getItem("user")!);
    if(loggedInUserRole==UserAuth.Recruiter){
    GetRecruiterById(user?.userId)
      .then((res) => {
        let { entity } = res.data;
        console.log("entity.imageInfo",entity.imageInfo);
        
        setImageInfo(entity.imageInfo);
      })
      .catch((err) => {
        console.log("Error while fetching user", err);
      });
    }
    if(loggedInUserRole==UserAuth.Client){
      GetClientById(user?.userId)
      .then((res) => {
        let { entity } = res.data;
        let imageInfo= {
          "imageUrl": entity?.clientAdditionalDetails?.companyLogo?.resourceUrl,
          "fileName": entity?.clientAdditionalDetails?.companyLogo?.fileName,
          "newFileName": entity?.clientAdditionalDetails?.companyLogo?.newFileName
      }
      setImageInfo(imageInfo);
      })
      .catch((err) => {
        console.log("Error while fetching user", err);
      });
    }
  }
  const UploadFile = () => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("formFile", file!);
    formData.append("fileName", fileName!);
    const model: UploadResourceModel = {
      documentType: DocumentTypeEnum.UserProfileImg,
      file: file!,
    };
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
   
    let apiToCall =   axios
    .post(
      `${process.env.REACT_APP_RESOURCE_API}api/ResourceUpload/UploadResource`,
      model,
      config
    ) 

    apiToCall?.then((res) => {
      setIsSubmitting(false);
      if (res.status === 200) {
        const successMessage = isEditing ? "Profile image updated successfully" : "Profile image uploaded successfully";
        setSuccess(successMessage);
        setImageInfo({
          fileName: res.data.entity.fileName,
          newFileName: res.data.entity.newFileName,
          imageUrl: res.data.entity.absoluteUri,
        });
      }
    })
    
      .catch((e) => {
        setIsSubmitting(false);
      });
  };
  const handleUploadClick = (files: FileList | null) => {
    setError("");
    setSuccess("");
    if (!files) return;
    setIsSubmitting(true);
    if (files[0].size > MAX_PHOTO_SIZE) {
      setIsSubmitting(false);
      setError("File size too large");
      return;
    }
    if (!SUPPORTED_FORMATS.includes(files[0].type)) {
      setIsSubmitting(false);
      setError("File type not supported");
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    setFile(files[0]);
    setFileName(files[0].name);
  };
  const handleDelete = () => {
    const values: DeleteResourceModel = {
      documentType: DocumentTypeEnum.UserProfileImg,
      fileNameToBeDeleted: imageInfo?.newFileName!,
    };    
    DeletePhoto(values).then((res) => {
      if(res.status == 200 && res.data.message.appStatusCode == "RC_RSF"){  
        setSuccess("Profile image deleted successfully");
        setImageInfo(undefined);
        dispatch(updateImage({imageUrl:''}))
      }else{
        console.log(res.data.message)
      }
    });
  };

  React.useEffect(()=>{
     loadImage(imageInfo?.imageUrl??"",setImageData)
     
  },[imageInfo])

  return (
    <Dialog
    data-testid="Closed"
      open={open}
      onClose={() => handleOpenClose(imageInfo!)}
      className="upload-Photo-dialog"
    >
      <DialogTitle
        sx={{ p: 2 }}
      ></DialogTitle>
      <DialogContent>
        {isEditing && (
          <Cropper
            src={imgData}
            style={{ height: 400, width: "100%" }}
            initialAspectRatio={1}
            viewMode={1}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false}
            // Cropper.js options
            guides={false}
            ref={cropperRef}
          />
        )}

        {!isEditing && (
          <DialogContentText>
            <IconButton
              aria-label="Close Dialog"
              onClick={() => handleOpenClose(imageInfo!)}
              className="dialog-close-btn uploadPhotoClose"
              data-testid="uploadPhotoClose"
            >
              <CloseSharpIcon />
            </IconButton>
              <RenderPhoto imageInfo={imageInfo} imgData={imgData} />
            <Box>
              {error && <CustomAlert type="error" message={error} onClose={()=>{}} />}
              {success && <CustomAlert type="success" message={success} onClose={()=>{}} />}
            </Box>
          </DialogContentText>
        )}
        {isEditing ? (

          <>
          <Box>
          {error && <CustomAlert type="error" message={error} onClose={()=>{}} />}
          {success && <CustomAlert type="success" message={success} onClose={()=>{}} /> }
          </Box>
          <DialogActions sx={{ p: 3, justifyContent: "center" }}>
            <Button
              startIcon={<Rotate90DegreesCwIcon />}
              disabled={isSubmitting}
              component="label"
              onClick={() => rotate()}
            >
              Rotate
            </Button>
            <Button
              data-testid="onsave"
              startIcon={<SaveIcon />}
              disabled={isSubmitting}
              component="label"
              onClick={() => onSave()}
            >
              Save
            </Button>
            <Button
              startIcon={<CancelIcon />}
              disabled={isSubmitting}
              component="label"
              onClick={() => 
                {
                  setIsEditing(false);
                  setSuccess("");
                  setError("");
                }
              }
            >
              Cancel
            </Button>
          </DialogActions>
          </>
        ) : (
          <DialogActions data-testid="save" sx={{ p: 3, justifyContent: "center" }}>
            {imageInfo?.imageUrl && (<Button
            data-testid="editicon"
            className="roboto-btn"
              startIcon={<EditIcon />}
              disabled={isSubmitting}
              component="label"
              onClick={() => {
                
                  setIsEditing(true);
                  setSuccess("");
                  setError("");
             
              }}
            >
              Edit
            </Button>)}
            <Button
             className="roboto-btn"
              startIcon={<AttachIcon />}
              disabled={isSubmitting}
              component="label"
            >
              { imageInfo && imageInfo?.imageUrl ? 'Update photo' : 'Add Photo'}
              
              <input
                type="file"
                accept="image/phg, image/bmp, image/jpeg , image/jpg,image/png "
                onChange={(e) =>
                  handleUploadClick(e.currentTarget.files || null)
                }
                hidden
                data-testid={"inp-addPhoto"}
              />
            </Button>
            {imageInfo?.imageUrl && (<Button
             className="roboto-btn"
              startIcon={<DeleteIcon />}
              disabled={isSubmitting}
              onClick={handleDelete}
            >
              Delete
            </Button>)}
          </DialogActions>
        )}
      </DialogContent>
    </Dialog>
  );
};
export default UploadPhotoDialog;
