import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import CheckCircle from "./../../../assets/images/check-circle-green.svg";

export const ResetPasswordConfirmation = (props) => {
  return (
    <>
      <Typography
        variant="h6"
        color="inherit"
        component="span"
        className="forgotpasswordsuccess-heading"
      >
        Check your Inbox for link to reset password!
      </Typography>

      <Box sx={{ padding: "40px 0", textAlign: "center" }}>
        <img src={CheckCircle} alt="" />
      </Box>

      <Typography
        component="span"
        className="forgotpasswordsuccess-body"
      >
        Go to your mailbox and click on the password reset link that was sent to
        {" "}
        <span className="forgotpasswordsuccess-body-email">{props.email}</span>
        {" "}
        within the next 15 minutes.
      </Typography>

      <Typography
        component="span"
        className="forgotpasswordsuccess-notmyemail-box"
      >
        <Link
          onClick={(e) => {
            props.onClick()
          }}
          className="forgotpasswordsuccess-notmyemail-link"
          data-testid="not-my-email-link"
        >
          Not my email address
        </Link>
        <br />
      </Typography>
    </>
  );
};
