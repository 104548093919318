export const isEmployerNameRequired =  (workAuthorization:string,candidateEmployment:string) => {
    if(candidateEmployment === "DirectContract")
      {
        if(workAuthorization === "H1B" || workAuthorization === "E3" || workAuthorization === "TN")
        {
            return true
        }
      }
    return false;
  };
  