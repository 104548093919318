import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const SaveUnsaveConfirmPopup: React.FC<{ 
    open: boolean;
    message:string; 
    onClose: () => void;
    handleConfirm: () => void;
 }> = ({
  open,
  message,
  handleConfirm,
  onClose,
}) => {

  return (
    <Dialog open={open} onClose={onClose} className='saveUnsaveMainWrapper'>
      <DialogTitle>
        <IconButton
            data-testid={"onClose"}
            aria-label="close"
            onClick={() => onClose()}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
        >
            <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className='v2-confirmPopupTitle'>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions className='v2DialogActionSection'>
        <Button onClick={onClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleConfirm} color="primary" variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveUnsaveConfirmPopup;
