import React from "react";
interface FullnameProps {
  fullName?: string;
}
export const Fullname: React.FC<FullnameProps> = ({ fullName }) => {
  if(!fullName)
  {
    return <>{""}</>
  }
  if( fullName=="N/A")
  {
    return <>{"N/A"}</>
  }
    let words = fullName?.split(" ");

    // Capitalize the first letter of each word
    let capitalizedWords = words?.map(function(word) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
  
    // Join the capitalized words back into a single string
    let capitalizedString = capitalizedWords?.join(" ");    
  return <>{capitalizedString}</>;
};
