import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  resume: "",
  resumeInfo: {
    resourceUrl: "",
    fileName: "",
    newFileName: "",
  },
  jobType: [],
  domains: [],
  industries: [],
  jobTitle: [],
  location: [],
  minimumPayRate: "",
  maximumPayRate: "",
  currency: "",
  resumeFile: {
    file: File,
    fileName: String,
  },
  isSearchResult:false,
  searchJobsId:[] as any,
  recommendationData: []  as any,
  sliderInfo:{
    skill:5,
    title:5,
    domain:5,
    responsibility:5
  },
  workExp:"",
  experienceId:"",
  experienceText:"",
  n:10,
  workAuthorizations:[],
  SearchRadius:"",
  remote:"",
  allowRelocation:""
}
export const findJobsSearchSlice = createSlice({
  name: "findJobsSearchSlice",
  initialState,
  reducers: {
    setFindJobsSearchState: (state,action) => {
        return action.payload;
    },
    resetFindJobsSearchState: (state) => initialState,
  },
  
});

// Action creators are generated for each case reducer function
export const {setFindJobsSearchState,resetFindJobsSearchState} =
findJobsSearchSlice.actions;

export default findJobsSearchSlice.reducer;