import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  MenuList,
  Tab,
  Tabs,
  TextField,
  Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import { styled } from "@mui/material/styles";
import * as React from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { GetAllIndustries } from "../../../Services/recruiterService";
import { defaultSelectAutoComplete } from "../../../data/defaultDataAutocomplete";
import useApi from "../../../hooks/useApi";
import { Industry } from "../../../models/app/AppModels";
import getAddressForAutoComplete from "../../../utils/getAddressForAutoComplete";
import getUnique from "../../../utils/getUnique";
import getUniqueLocationForAutoComplete from "../../../utils/getUniqueLocationFromAutoComplete";
import { CheckBoxSquareIcon } from "../CheckBoxSquareIcon";
import { valueOrDefault } from "../../../utils/complexityUtils";

const Wrapper = styled("div")({
  fontFamily: "Roboto !important !important",
});

interface AppFilterToggleProps {
  anchorEl: any;
  onClose: (event: any) => void;
  selectedLocations: any[];
  onLocationChange: (locations: string[]) => void;
  selectedIndustries: Industry[];
  onIndustryChange: (industries: Industry[]) => void;
  filterTypes: ("Locations" | "Industry")[];
}

export interface AppFilterToggleRef {
  handleClearAll: () => void;
}

const AppFilterToggle = (
  props: React.PropsWithChildren<AppFilterToggleProps>, 
  ref: React.ForwardedRef<AppFilterToggleRef>
) => {
  const { anchorEl } = props;
  const open = Boolean(anchorEl);
  const locationInputRef = React.useRef(null);
  const [value, setValue] = React.useState(0);
  const [selectedLocations, setLocationsSelected] = React.useState(
    props.selectedLocations
  );
  const [search, setSearch] = React.useState("");
  const [industrySearch, setIndustrySearch] = React.useState("");
  const [selectedIndustries, setselectedIndustries] = React.useState(
    props.selectedIndustries
  );

  const allIndustries = useApi(() =>
    GetAllIndustries(industrySearch, 5)
  ) as any;

  const changeSearchHandler = (e: any) => {
    setIndustrySearch(e.target.value);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [autoSuggestions, setautoSuggestions] = React.useState(
    defaultSelectAutoComplete
  );

  const loadSuggestions = (sugg: any) => {
    if (sugg.length > 0) {
      setautoSuggestions(sugg);
    }
  };

  const getCity = (description: any) => {
    let city = getAddressForAutoComplete(description).city;

    return city;
  };

  let locationLists = getUnique(
    selectedLocations?.concat(
      getUniqueLocationForAutoComplete(autoSuggestions)
    ),
    "placeId"
  );

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      allIndustries.request();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [industrySearch]);

  let industries =
    selectedIndustries.length > 0
      ? selectedIndustries.concat(allIndustries.data?.entityList)
      : allIndustries.data?.entityList;

  const uniqueIndustries = getUnique(industries);

  const handleLocationChange = (e: any, location: any) => {
    let isChecked = e.target.checked;
    if (isChecked) {
      setLocationsSelected([...selectedLocations, location]);
    } else {
      setLocationsSelected(
        selectedLocations.filter((item) => item.placeId !== location.placeId)
      );
    }
  };

  const handleIndustryChange = (e: any, industry: any) => {
    let isChecked = e.target.checked;
    if (isChecked) {
      setselectedIndustries([...selectedIndustries, industry]);
    } else {
      setselectedIndustries(
        selectedIndustries.filter((item) => item.id !== industry?.id)
      );
    }
  };

  React.useEffect(() => {
    props.onLocationChange(selectedLocations);
  }, [selectedLocations]);

  React.useEffect(() => {
    props.onIndustryChange(selectedIndustries);
  }, [selectedIndustries]);

  const handleClearLocations = () => {
    setLocationsSelected([]);
    setSearch("");
    setautoSuggestions(defaultSelectAutoComplete);
  };

  const handleClearIndustries = () => {
    setIndustrySearch("");
    setselectedIndustries([]);
  };

  const handleClearAll = () => {
    handleClearLocations();
    handleClearIndustries();
  };

  React.useImperativeHandle(ref, () => ({
    handleClearAll,
  }));

  return (
    <Wrapper>
      <Popper id={"id"} open={open} anchorEl={anchorEl}>
        <ClickAwayListener onClickAway={props.onClose}>
          <Card>
            <Box
              sx={{
                flexGrow: 1,
                bgcolor: "#FAFAFA",
                display: "flex",
              }}
            >
              <Grid container p={2}>
                <Grid item md={6}>
                  <Typography fontWeight={"bold"} className="roboto-font">
                    Filters
                  </Typography>
                </Grid>
                <Grid item xs={6} className="closeArea">
                  <IconButton
                    aria-label="close"
                    size="small"
                    className="close-Btn"
                    onClick={props.onClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                bgcolor: "#FAFAFA",
                display: "flex",
                height: 386,
              }}
            >
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: "divider", width: 146 }}
              >
                {props.filterTypes.some((n) => n === "Locations") ? (
                  <Tab
                    data-testid="location-tab"
                    label={
                      <Typography
                        fontSize={16}
                        className="roboto-font"
                        color={"#1D343E"}
                      >
                        Location
                      </Typography>
                    }
                    {...a11yProps(0)}
                    style={{
                      backgroundColor: value === 0 ? "white" : "inherit",
                      alignItems: "flex-start",
                    }}
                  />
                ) : (
                  <></>
                )}
                {props.filterTypes.some((n) => n === "Industry") ? (
                  <Tab
                    data-testid="industry-tab"
                    label={
                      <Typography
                        fontSize={16}
                        className="roboto-font"
                        color={"#1D343E"}
                      >
                        Industry
                      </Typography>
                    }
                    {...a11yProps(1)}
                    style={{
                      backgroundColor: value === 1 ? "white" : "inherit",
                      alignItems: "flex-start",
                    }}
                  />
                ) : (
                  <></>
                )}
              </Tabs>
              <TabPanel value={value} index={0}>
                <PlacesAutocomplete
                  data-testid="place-autocomplete"
                  searchOptions={{ types: ["(cities)"] }}
                  ref={locationInputRef}
                  value={search}
                  onChange={(ev: any) => {
                    if (!ev) {
                      setautoSuggestions(defaultSelectAutoComplete);
                    }
                    setSearch(ev);
                  }}
                >
                  {({ getInputProps, suggestions }: any) => {
                    loadSuggestions(suggestions);
                    return (
                      <Grid container style={{ width: 223 }} p={2}>
                        <Grid item md={12}>
                          <Grid container>
                            <Grid item>
                              <TextField
                                className="roboto-font filter-searchbox"
                                placeholder="Search Location"
                                size="small"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton>
                                        <svg
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M9.16675 1.66666C13.3067 1.66666 16.6667 5.02666 16.6667 9.16666C16.6667 13.3067 13.3067 16.6667 9.16675 16.6667C5.02675 16.6667 1.66675 13.3067 1.66675 9.16666C1.66675 5.02666 5.02675 1.66666 9.16675 1.66666ZM9.16675 15C12.3892 15 15.0001 12.3892 15.0001 9.16666C15.0001 5.94333 12.3892 3.33333 9.16675 3.33333C5.94341 3.33333 3.33341 5.94333 3.33341 9.16666C3.33341 12.3892 5.94341 15 9.16675 15ZM16.2376 15.0592L18.5951 17.4158L17.4159 18.595L15.0592 16.2375L16.2376 15.0592V15.0592Z"
                                            fill="#353535"
                                          />
                                        </svg>
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                                {...getInputProps()}
                              />
                            </Grid>
                            <Grid item pt={1} pb={0.5}>
                              <FormControlLabel
                                data-testid={"clear-locations-button"}
                                onClick={handleClearLocations}
                                control={
                                  <CheckBoxSquareIcon
                                    style={{ paddingRight: "5px" }}
                                  ></CheckBoxSquareIcon>
                                }
                                label={
                                  <Typography
                                    fontSize={14}
                                    className="roboto-font"
                                  >
                                    Clear All
                                  </Typography>
                                }
                                className="candidates-label roboto-font"
                                style={{
                                  color: "red",
                                  fontWeight: "bold",
                                  paddingLeft: 0,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          md={12}
                          style={{ maxHeight: 300, overflowY: "auto" }}
                        >
                          <MenuList
                            style={{ paddingTop: "0px" }}
                            className="listing-filter"
                          >
                            <MenuItem
                              sx={{
                                paddingLeft: "0px",
                                paddingBottom: "0px",
                                minHeight: "auto",
                              }}
                            ></MenuItem>
                            {locationLists.map((suggestion: any, index: any) => {
                              let state = valueOrDefault(
                                suggestion?.formattedSuggestion?.secondaryText?.split(",")[0],
                                ""
                              );
                              let city = getCity(suggestion?.description);

                              if (city !== undefined)
                                return (
                                  <MenuItem
                                    key={ suggestion?.placeId ?? index}
                                    sx={{
                                      paddingLeft: "0px",
                                      paddingBottom: "0px",
                                      minHeight: "auto",
                                    }}
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          data-testid="location-checkbox"
                                          onChange={(e) =>
                                            handleLocationChange(e, suggestion)
                                          }
                                          checked={selectedLocations.includes(
                                            suggestion
                                          )}
                                          name={city}
                                          sx={{
                                            padding: "0px",
                                            fontSize: "14px",
                                          }}
                                        />
                                      }
                                      label={
                                        <Typography
                                          fontSize={14}
                                          className="roboto-font"
                                        >
                                          {city + ", " + state}
                                        </Typography>
                                      }
                                      className="candidates-label roboto-font"
                                      style={{ paddingLeft: 0 }}
                                    />
                                  </MenuItem>
                                );
                            })}
                          </MenuList>
                        </Grid>
                      </Grid>
                    )
                  }}
                </PlacesAutocomplete>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid container style={{ width: 223 }} p={2}>
                  <Grid item md={12}>
                    <Grid container>
                      <Grid item>
                        <TextField
                          className="roboto-font filter-searchbox"
                          placeholder="Search Industry"
                          size="small"
                          value={industrySearch}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton>
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M9.16675 1.66666C13.3067 1.66666 16.6667 5.02666 16.6667 9.16666C16.6667 13.3067 13.3067 16.6667 9.16675 16.6667C5.02675 16.6667 1.66675 13.3067 1.66675 9.16666C1.66675 5.02666 5.02675 1.66666 9.16675 1.66666ZM9.16675 15C12.3892 15 15.0001 12.3892 15.0001 9.16666C15.0001 5.94333 12.3892 3.33333 9.16675 3.33333C5.94341 3.33333 3.33341 5.94333 3.33341 9.16666C3.33341 12.3892 5.94341 15 9.16675 15ZM16.2376 15.0592L18.5951 17.4158L17.4159 18.595L15.0592 16.2375L16.2376 15.0592V15.0592Z"
                                      fill="#353535"
                                    />
                                  </svg>
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          onChange={changeSearchHandler}
                        />
                      </Grid>
                      <Grid item pt={1} pb={0.5}>
                        <FormControlLabel
                          data-testid={"clear-industries-button"}
                          onClick={handleClearIndustries}
                          control={
                            <CheckBoxSquareIcon
                              style={{ paddingRight: "5px" }}
                            ></CheckBoxSquareIcon>
                          }
                          label={
                            <Typography fontSize={14} className="roboto-font">
                              Clear All
                            </Typography>
                          }
                          className="candidates-label"
                          style={{
                            color: "red",
                            fontWeight: "bold",
                            paddingLeft: 0,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    style={{ maxHeight: 300, overflowY: "auto" }}
                  >
                    <MenuList
                      style={{ paddingTop: "0px" }}
                      className="listing-filter"
                    >
                      <MenuItem
                        sx={{
                          paddingLeft: "0px",
                          paddingBottom: "0px",
                          minHeight: "auto",
                        }}
                      ></MenuItem>
                      {uniqueIndustries?.map((item: any, index: any) => {
                        return (
                          <MenuItem
                            key={ item?.id ?? index}
                            sx={{
                              paddingLeft: "0px",
                              paddingBottom: "0px",
                              minHeight: "auto",
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  data-testid={`industry-checkbox-${index}`}
                                  onChange={(e) =>
                                    handleIndustryChange(e, item)
                                  }
                                  name={item?.id}
                                  sx={{ padding: "0px", fontSize: "14px" }}
                                  checked={selectedIndustries.includes(item)}
                                />
                              }
                              label={
                                <Typography
                                  fontSize={14}
                                  className="roboto-font"
                                >
                                  {item?.industryName}
                                </Typography>
                              }
                              className="candidates-label roboto-font"
                              style={{ paddingLeft: 0 }}
                            />
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  </Grid>
                </Grid>
              </TabPanel>
            </Box>
          </Card>
        </ClickAwayListener>
      </Popper>
    </Wrapper>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ backgroundColor: "white" }}
    >
      {value === index && (
        <Typography className="roboto-font">{children}</Typography>
      )}
    </div>
  );
}

export default React.forwardRef(AppFilterToggle);
