import React from "react";
import { SortType } from "../../models/app/AppModels";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

type AppSortProps = {
  sortType: SortType;
  disable?:boolean
};

const disableColor = "#C5C5DA";
const enableColor = "#353535";
const AppSort: React.FunctionComponent<AppSortProps> = ({ sortType,disable }) => {
  return (
    <>
      <KeyboardArrowUpIcon
        className={
          "new-custom-arrws-up " +
          (disable || sortType === SortType.Descending ? "disable-sort-icon" : "")
        }
      />
      <KeyboardArrowDownIcon
        className={
          "new-custom-arrws-down " +
          (disable || sortType === SortType.Ascending ? "disable-sort-icon" : "")
        }
      />
    </>
  );
};

export default AppSort;
