import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import { AdminPaymentDetail } from "../../ManageProfile/BillingDetailsForms/AdminPaymentDetails";
import { useFormik } from "formik";
import { GetPayoneerPaymentDetail, GetUSPaymentDetail } from "../../../Services/invoiceManagerService";
import getUserDetails from "../../../Shared/utils/getUserDetails";
import { valueOrDefault } from "../../../utils/complexityUtils";


const RecruiterPaymentDetails = function (props) {
  const formik = useFormik({
    initialValues: initialState,

    enableReinitialize: true,
    onSubmit: (values) => {},
  });
  useEffect(() => {
    if (props?.recruiter !== null) {

        GetUSPaymentDetail(props?.recruiter?.id).then((response) => {
          const paymentDetails = response?.data?.entity || [];

          Object.keys(
            initialState.paymentDetailsChangeRequest.usaBilling
          ).forEach((e) =>
            formik.setFieldValue(
              `paymentDetailsChangeRequest.usaBilling.${e}`,
              valueOrDefault(paymentDetails[e], "")
            )
          );
        });
        GetPayoneerPaymentDetail(props?.recruiter?.id).then(
          (response) => {
            const payoneerData = response?.data?.entity || [];
            Object.keys(
              initialState.paymentDetailsChangeRequest.payoneerBilling
            ).forEach((e) =>
              formik.setFieldValue(
                `paymentDetailsChangeRequest.payoneerBilling.${e}`,
                valueOrDefault(payoneerData[e], "")
              )
            );
          }
        );
      }

  }, [props?.recruiter]);
  const getDefaultSelection = ()=> {
    let entity = props?.recruiter;
      return entity?.billingInfoType
  }
  let defaultSelectedPaymentOption = getDefaultSelection()
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={12}>
          {props?.recruiter !== null && (
            <AdminPaymentDetail
              USAFormRef={props?.USAFormRef}
              PayoneerFormRef={props?.PayoneerFormRef}
              onCountryChange={()=>{}}
              selectedOption={defaultSelectedPaymentOption}
              onChange={()=>{}}
              editMode={false}
              values={formik.values.paymentDetailsChangeRequest}
              billingDetail={{
                userId: props?.recruiter?.id,
                name: props?.recruiter?.firstName,
                email: props?.recruiter?.email,
                billingInfoType:
                  props?.recruiter?.billingInfoType,
              }}
            />
          )}
          </Grid>
      </Grid>
      
    </>
  );
};

export default RecruiterPaymentDetails;
const initialState = {
  billingInfoType: "",
  paymentDetailsChangeRequest: {
    usaBilling: {
      userId: "",
      email: "",
      name: "",
      billingInfoType: "USA",
      country: "",
      routingNumber: "",
      confirmRoutingNumber: "",
      accountHolderName: "",
      bankName: "",
      bankAccountNumber: "",
      confirmBankAccountNumber: "",
      location: {
        address: "",
        city: "",
        state: "",
        zip: "",
        country: "",
      },
      isRoutingNumberConfirm: true,
      isW9NameConfirm: true,
      resourceInfo: {
        resourceUrl: "",
        fileName: "",
        newFileName: "",
      },
      isUpdateDetails: false,
    },
    payoneerBilling: {
      userId: "",
      email: "",
      billingInfoType: "Payoneer",
      isUpdateDetails: false,
    },
    isActive: true,
    createdDate: new Date(),
    createdBy: getUserDetails().id,
    validTill: new Date(Date.now() + 1 * 24 * 60 * 60 * 1000),
    authToken: "",
  },
};