import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import IconButton from "@mui/material/IconButton";
import { DialogTitle } from "@mui/material";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

interface Props {
  handleOpenClose: () => void;
  open: boolean;
  fileURL: string;
  fileName: string;
  fileType?: string;
}
const PreviewFileDialog: React.FC<Props> = ({ handleOpenClose, open, fileURL, fileName, fileType }) => {
  
  
  return (
    <Dialog
      open={open}
      onClose={() => handleOpenClose()}
      className="preview-file-dialog"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle sx={{my:1, mx:3, p:0}} className="preview-file-name-paper">
        <span className="preview-file-name">{fileName}</span>
        <IconButton
          aria-label="Close Preview"
          onClick={() => handleOpenClose()}
          className="preview-dialog-close-btn"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          data-testid="close-preview-btn"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <DocViewer
          documents={[{uri: fileURL, fileName: fileName, fileType: fileType}]}
          pluginRenderers={DocViewerRenderers}
          config={{pdfVerticalScrollByDefault: true, header: {disableHeader: true}}}
        />
      </DialogContent>
    </Dialog>
  );
};
export default PreviewFileDialog;
