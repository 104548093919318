import React from "react";
import PostingJobForm from "../../Jobs/AddJob/PostingJobForm";
import { Container, Typography, Box, Divider } from "@mui/material";
import Grid from "@mui/material/Grid";
import AppBreadCrumb from "../../Common/AppBreadCrumb";
import { BreadCrumbItem } from "../../../models/AppModels";
import getLoggedInUserRole from "../../../Shared/utils/getLoggedInUserRole";
import { UserAuth } from "../../../models/interfaces";
import { useLocation } from "react-router-dom";
import { getNevLink } from "../../../utils/getFileFromUrl";

interface LocationState {
  fromPage ?: string; 
}

const AddJobPage: React.FC = () => {
  const userRole = getLoggedInUserRole();
  const { state } = useLocation() as { state: LocationState };

  const AllJobPageBreadcrumbs: BreadCrumbItem[] = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
    { isLeaf: false, sequence: 2, title: "All Jobs", to: `${userRole === UserAuth.ClientTeamMember ? '/team' : '/client'}/jobs/all` },
    { isLeaf: true, sequence: 3, title: "Add Job", leafClasses:" v2LastLeft"},
  ];
  
  const PendingPageBreadcrumbs: BreadCrumbItem[] = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
    { isLeaf: false, sequence: 2, title: "Pending Jobs", to: `${userRole === UserAuth.ClientTeamMember ? '/team' : '/client'}/jobs/pending` },
    { isLeaf: true, sequence: 3, title: "Add Job", leafClasses:" v2LastLeft"},
  ];
  
  const DefaultBreadCrumbs: BreadCrumbItem[] = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
    { isLeaf: true, sequence: 3, title: "Add Job", leafClasses:" v2LastLeft"},
  ];

  const getBreadcrumbs = () => {
    if (state?.fromPage === "ALLJOB") {
      return AllJobPageBreadcrumbs;
    } else if (state?.fromPage === "PENDINGJOB") {
      return PendingPageBreadcrumbs;
    } else {
      return DefaultBreadCrumbs;
    }
  };

  return (
    <>
      <Box className="PageWrapper">
        <Container maxWidth={false} disableGutters={true}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AppBreadCrumb
                classes="custom-appbreadcrumb"
                BreadCrumbItems={getBreadcrumbs()}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className="PageTitle">Add Job</Typography>
              <Divider className="borderedDivider" />
            </Grid>
          </Grid>
        </Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {/* Add job form */}
            <PostingJobForm mode="ADD" />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AddJobPage;
