import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import './popup.scss';

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  handleYes: () => void;
  handleNotNow: () => void;
  title: string;
  subTitle?: string;
}

const EmailVerifyPopup: React.FC<IProps> = ({
  isOpen,
  handleClose,
  handleYes,
  handleNotNow,
  title,
  subTitle,
}) => {
  return (
    <>
      <Dialog
        className="v2-emailVerifyDialogBox"
        open={isOpen}
        aria-labelledby="pop-up-title"
        PaperProps={{
            style: {
              padding: "0 0 16px 0",
            },
          }}
      >
        <DialogTitle style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography style={{ textAlign: "center" }}></Typography>
          <Typography className="v2-EmailVerifyPopupTitle">
          To update the requested email id click on Update Profile button
          </Typography>
          {subTitle &&
          <Typography component={"p"} >
            {subTitle}
          </Typography>
            }   
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: "center" }}
        >
            <Box>
          <Button
            type="button"
            name="ok"
            value="ok"
            variant="contained"
            color="primary"
            onClick={handleYes}
            className="v2-primary-button button-gap"
          >
            Ok
          </Button>
            <Button
              type="button"
              name="notnow"
              value="notnow"
              variant="outlined"
              color="primary"
              onClick={handleNotNow}              
              className="v2-outlined-button"
            >
              Not Now
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EmailVerifyPopup;
