import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { PermissionsEnums } from "../../../../data/permission";
import { ModuleComponent } from "../../../Common/Permission/ModuleComponent";
import "../../NewViewSubmission/styles.scss";

interface DocumentsButtonProps {
  row: any;
  showUpdateBtn: boolean;
  setSubmissionForPopup: any;
  setPopup: any;
}

export default function DocumentsButton({
  row,
  showUpdateBtn,
  setSubmissionForPopup,
  setPopup
} : DocumentsButtonProps) {
  return (
    <>
      {showUpdateBtn && (
        <ModuleComponent moduleId={PermissionsEnums.Submissions_Documents}>
          <Grid item>
            <Button
              className="submission-action-btn"
              variant="contained"
              color="primary"
              onClick={() => {
                setSubmissionForPopup(row);
                setPopup((popup : any) => ({ ...popup, documents: true }));
              }}
            >
              Documents
            </Button>
          </Grid>
          <br />
        </ModuleComponent>
      )}
    </>
  );
};