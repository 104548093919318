import { Navigate } from "react-router-dom";
import { getNevLink } from "../../utils/getFileFromUrl";

const PublicRoute = ({ children }) => {
    const auth = localStorage.getItem("accessToken") ? true : false;
    if (auth) {
      return <Navigate to={getNevLink("/dashboard")} replace />;
    }
  
    return children;
  };

export default PublicRoute;