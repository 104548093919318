import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import formatPhone from '../../../Shared/utils/formatPhone';
import verifiedIcon from "../../../assets/images/verified.svg"
import Chip from '@mui/material/Chip';
import { isVarNotEmpty } from "../../../utils/StringUtil";
import PreviewFileDialog from '../../Common/PreviewFileDialog';
import { Fullname } from '../../Common/Fullname';
import { valueOrDefault } from '../../../utils/complexityUtils';
import SectionHeading from '../../ui/Form/Heading/SectionHeading';
import StringWithTooltip from '../../Common/ToolTip/StringWithTooltip';
import ResumeViewInfo from './Common/ResumeViewInfo';
import ScreeningViewInfo from './Common/ScreeningViewInfo';
import SignedContractDocument from './Common/SignedContractDocument';
import { DocumentTypeEnum } from '../../../models/interfaces';
interface IPROPS{
    recruiterDetails:any;
}
export const RecruiterViewInfo: React.FunctionComponent<IPROPS> = (props) => {
    
    const recruiterFullName = (firstName : string = "",middleName : string ="",lastName: string ="")=>{
        return firstName + " " + middleName+" " + lastName
    }

    const CurrentWorkLocation = (city : string,state : string) =>{
        if(city && state){
            return city + ", " + state
        }else if(city){
            return city
        }else if(state){
            return state
        }else{ return "";}
    }

    const [filePreview, setFilePreview] = React.useState<undefined | 
    {
      resourceUrl: string;
      fileName: string;
      fileType?: string;
    }>(undefined);

    const renderAddress = () => {
        const addressDetails = props?.recruiterDetails?.address;
      
        if (!addressDetails) {
          return "N/A";
        }
      
        const { streetAddress, city, state, countryCode, postalCode } = addressDetails;
      
        const addressString =
          (streetAddress ?? "") +
          (isVarNotEmpty(streetAddress) ? ", " : "") +
          (city ?? "") +
          (isVarNotEmpty(city) ? ", " : "") +
          (state ?? "") +
          (isVarNotEmpty(state) ? ", " : "") +
          (countryCode ?? "") +
          (isVarNotEmpty(countryCode) ? ", " : "") +
          (postalCode ?? "");
      
        const isEmptyAddress =
          !isVarNotEmpty(streetAddress) &&
          !isVarNotEmpty(city) &&
          !isVarNotEmpty(state) &&
          !isVarNotEmpty(countryCode) &&
          !isVarNotEmpty(postalCode);
      
        return !isEmptyAddress ? addressString : "N/A";
      };

    return (
        <>
        <Grid container spacing={2}>
        <Grid item xs={12}>
          <SectionHeading title="Recruiter Information" />
        </Grid>
        <Grid item xs={12} sm={4} md={3} lg={3} xl={2}>
            <Typography variant="subtitle1" component="div" className="verify-rec-form-heading">
                Name
            </Typography>
            <Typography variant="subtitle1" component="div" className="verify-rec-form-value">
                <Fullname fullName=
                {recruiterFullName(props?.recruiterDetails?.firstName,
                    props?.recruiterDetails?.middleName,
                    props?.recruiterDetails?.lastName)
                    || "N/A"
                    }
                />
            </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={3} lg={3} xl={2}>
            <Typography variant="subtitle1" component="div" className="verify-rec-form-heading">
                Email
            </Typography>
            <StringWithTooltip 
                fontSize="16px"
                className='textToolTip'
                text={props.recruiterDetails?.email || "N/A"} 
                />
        </Grid>
        <Grid item xs={12} sm={4} md={3} lg={3} xl={2}>
            <Typography variant="subtitle1" component="div" className="verify-rec-form-heading">
                Phone
            </Typography>
            <Typography variant="subtitle1" component="div" className="verify-rec-form-value">
                { formatPhone(props?.recruiterDetails?.phoneNumbers?.primary) || "N/A"}
                { props?.recruiterDetails?.phoneNumbers?.isPrimaryVerified  ?
                <img style={{paddingLeft:"3px", verticalAlign:"sub", width:"22px"}} src={verifiedIcon}></img>
                : null
            }
            </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={3} lg={3} xl={2}>
            <Typography variant="subtitle1" component="div" className="verify-rec-form-heading">
                Alternate Phone
            </Typography>
            <Typography variant="subtitle1" component="div" className="verify-rec-form-value">
            { formatPhone(props?.recruiterDetails?.phoneNumbers?.secondary) || "N/A"}
            { props?.recruiterDetails?.phoneNumbers?.isSecondaryVerified  ?
                <img style={{paddingLeft:"0px", position: "absolute", right: "0",top: "25px",width:"22px"}} src={verifiedIcon}></img>
                : null
            }
            </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Typography variant="subtitle1" component="div" className="verify-rec-form-heading">
                Address
            </Typography>
            <Typography variant="subtitle1" component="div" className="verify-rec-form-value">
                {renderAddress()}
            </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={3} lg={3} xl={2}>
            <Typography variant="subtitle1" component="div" className="verify-rec-form-heading">
                Current work location
            </Typography>
            <Typography variant="subtitle1" component="div" className="verify-rec-form-value">
                {valueOrDefault(
                    CurrentWorkLocation(
                        valueOrDefault(props?.recruiterDetails?.currentWorkLocation?.city, ""),
                        valueOrDefault(props?.recruiterDetails?.currentWorkLocation?.state, "")
                    ),
                    "N/A"
                )}
            </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3} xl={4}>
            <Typography variant="subtitle1" component="div" className="verify-rec-form-heading">
                Time Zone
            </Typography>
            <Typography variant="subtitle1" component="div" className="verify-rec-form-value">
                {props?.recruiterDetails?.timezone || "N/A"}
            </Typography>
        </Grid>
        
        <Grid item xs={12} sm={4} md={3} lg={3} xl={2}>
            <Typography variant="subtitle1" component="div" className="verify-rec-form-heading">
            Domain
            </Typography>
            <Typography variant="subtitle1" component="div" className="verify-rec-form-value">
                {props?.recruiterDetails?.domains.length
                ? props?.recruiterDetails?.domains.map((x :any, i:any) => {
                    return (
                        <Chip key={ x?.domain ?? i} label={x.domain} className="domainChipLabel"/>
                    );
                    })
                : "N/A"}
            </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
            <Typography variant="subtitle1" component="div" className="verify-rec-form-heading">
            Total Experience
            </Typography>
            <Typography variant="subtitle1" component="div" className="verify-rec-form-value">
            {props?.recruiterDetails?.experience + " " + "Years" || "N/A"}
            </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={5} lg={5} xl={5}>
            <Typography variant="subtitle1" component="div" className="verify-rec-form-heading">
            Linkedin Profile
            </Typography>
            <Typography variant="subtitle1" component="div" className="verify-rec-form-value">
                {props?.recruiterDetails?.profiles?.length > 0
                ? props?.recruiterDetails?.profiles?.map((x :any, i:any) => {
                    

                    return (
                        <Typography key={ x?.link ?? i} variant="subtitle1" component="div" className="verify-rec-form-value">
                            {x?.link}
                        </Typography>
                    );
                    })
                : "N/A"}
            </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={5} xl={4}>
            <Typography variant="subtitle1" component="div" className="verify-rec-form-heading">
            Your Interest
            </Typography>
            <Typography variant="subtitle1" component="div" className="verify-rec-form-value">
                {props?.recruiterDetails?.interests.length
                ? props?.recruiterDetails?.interests.map((x : any, i : any) => {
                    return (
                        <Chip key={ x ?? i} label={x} className="domainChipLabel"/>
                    );
                    })
                : "N/A"}
            </Typography>
        </Grid>
        
        <Grid item xs={12} sm={12} md={6} lg={7} xl={8}>
            <Typography variant="subtitle1" component="div" className="verify-rec-form-heading">
            Preferred Geographies
            </Typography>
            <Typography variant="subtitle1" component="div" className="verify-rec-form-value">
                {props?.recruiterDetails?.preferredLocation.length
                ? props?.recruiterDetails?.preferredLocation.map((x : any, i : any) => {
                    return (
                        <Chip key={ x?.id ?? + i} label={x.location?.city + ", " + x.location?.state} className="domainChipLabel"/>
                    );
                    })
                : "N/A"}
            </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="subtitle1" component="div" className="verify-rec-form-heading">
            Industry Preferences
            </Typography>
            <Typography variant="subtitle1" component="div" className="verify-rec-form-value">
                {props?.recruiterDetails?.industries.length
                ? props?.recruiterDetails?.industries.map((x : any, i : any) => {
                    return (
                        <Chip key={ x?.id ?? i} label={x.industry} className="domainChipLabel"/>
                    );
                    })
                : "N/A"}
            </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="subtitle1" component="div" className="verify-rec-form-heading">
            Skills
            </Typography>
            <Typography variant="subtitle1" component="div" className="verify-rec-form-value">
                {props?.recruiterDetails?.skills.length
                ? props?.recruiterDetails?.skills.map((x : any, i : any) => {
                    return (
                        <Chip key={ x?.id ?? i} label={x.skill} className="domainChipLabel"/>
                    );
                    })
                : "N/A"}
            </Typography>
        </Grid>
        
            <Grid item xs={12}>
                <SectionHeading title='Documents' />
                <Grid container>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <ResumeViewInfo 
                            documentInfo={props?.recruiterDetails?.documentInfo}
                            setFilePreview={setFilePreview}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <ScreeningViewInfo 
                            documentInfo={props?.recruiterDetails?.documentInfo}
                            setFilePreview={setFilePreview}
                            mode='View'
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <SignedContractDocument
                            title='Contract' 
                            recruiterInfo={props?.recruiterDetails}
                            documentInfo={props?.recruiterDetails?.documentInfo}
                            setFilePreview={setFilePreview}
                            contractDetails={props?.recruiterDetails?.contractDetails}
                            mode='View'
                            type={DocumentTypeEnum.RecruiterSignedContract}
                        />
                    </Grid>
                </Grid>               
                
            </Grid>
           
        </Grid>
        
        {filePreview !== undefined &&
        <PreviewFileDialog
            open={filePreview !== undefined}
            handleOpenClose={() => setFilePreview(undefined)}
            fileName={filePreview.fileName}
            fileURL={filePreview.resourceUrl}
            fileType={filePreview.fileType}
        />
        }
        </>
    )
}