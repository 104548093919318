import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import "./ViewRecruiterInfoPopup.css";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ErrorMessage } from "../../../ui/ErrorMessage";
import {  UpdateCandidateStatus } from "../../../../Services/candidateService";
import CircularProgress from '@mui/material/CircularProgress';
import { CustomAlert } from "../../../ui/CustomAlert";
import { CandidateSubmissionStatus } from "../../../../models/submission/CandidateSubmissionMasterStatusesModel";
import { valueOrDefault } from "../../../../utils/complexityUtils";

interface Props {
  openPopup: boolean;
  handleDocumentsPopupClose: any;
  selectedRowDetails: any;
  onSuccess: (action: string, message:string) => void;
}
const ariaLabel = { "aria-label": "description" };

export const CandidateInterviewCancelPopup: React.FC<Props> = ({
  openPopup,
  handleDocumentsPopupClose,
  selectedRowDetails,
  onSuccess,
}) => {
  
  const initialState = {
    commentText: "",
  };

  const commentSchema = Yup.object({
    commentText: Yup.string()
      .required("Please enter comments")
      .max(
        500,
        "Maximum limit allowed is less than or equal to 500 characters"
      ),
  });
  const formik = useFormik({
    initialValues: initialState,
    validationSchema: commentSchema,
    onSubmit: async (values, actions) => {
      _handleSubmiitedToClient();
    },
  });
  const [pageStatus, setPageStatus] = React.useState({
    isSuccess: false,
    isError:false,
    error: "",
    loading: false,
    message: "",
  });

  const _handleSubmiitedToClient = () => {
    setPageStatus({ ...pageStatus, loading: true, message: "" });

    const requestPayload = {
        submissionId: valueOrDefault(selectedRowDetails?.id, ""),
        clientId: valueOrDefault(selectedRowDetails?.clientId, ""),
        eventId: CandidateSubmissionStatus.InterviewCancelled,
        feedback: formik.values.commentText,
        redirectUrl: ""
      }
      
      
    
    UpdateCandidateStatus(requestPayload).then((response) => {
      if (response?.data?.message?.appStatusCode == "RC_RSF") {
        setPageStatus({
          isSuccess: true,
          isError:false,
          error: "",
          loading: false,
          message: "Interview Cancelled successfully!",
        });
        onSuccess("InterviewCancelled","Interview Cancelled successfully!");
      } else {
        setPageStatus({
          isSuccess: false,
          isError:true,
          error: response.data?.message?.appStatusDescription,
          message: response.data?.message?.appStatusDescription,
          loading: false,
        });
      }
    });
  };
  return (
    <>
        <Dialog
          className="custom-popup-border"
          open={openPopup}
          onClose={handleDocumentsPopupClose}
          aria-labelledby="viewRecruiterPopup-title"
          aria-describedby="viewRecruiterPopup-description"
        >
          <Paper elevation={5} style={{ backgroundColor: "#FFF" }}>
            <DialogTitle sx={{ m: 0, p: 2 }}>
              <IconButton
                aria-label="close"
                onClick={handleDocumentsPopupClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="viewRecruiterPopup-description">
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography
                      data-testid="center"
                      align="center"
                      className="custom-reject-txt"
                    >
                        Are you sure you want to{" "}
                      <span style={{ color: "#F24444" }}>Cancel</span> 
                      {" "}<br />the Interview?
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={1} className="recruiterInfoBoxV2">
                      <Grid item xs={12} sm={12}>
                        <TextField
                          error={
                            formik.touched.commentText &&
                            Boolean(formik.errors.commentText)
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.commentText}
                          name="commentText"
                          inputProps={ariaLabel}
                          fullWidth
                          type="text"
                          multiline
                          rows={3}
                          variant="outlined"
                          className="AddCommentTxtArea"
                          placeholder="Add Feedback here"
                        />
                        <ErrorMessage
                          errorText={
                            formik.touched.commentText &&
                            formik.errors.commentText
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className="custom-max-limit">
                          Maximum limit allowed is 500 characters.
                        </Typography>
                      </Grid>
                      {pageStatus.isError &&
                      <Grid item xs={12}>
                        <CustomAlert message={pageStatus.message} type={"error"} />
                      </Grid>
                    }
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContentText>
              <Grid
                item
                xs={12}
                sm={12}
                className="row stc-btnRow"
                style={{
                  marginTop: "30px",
                  marginRight: "0px",
                }}
              >
                <Button
                  className="submission-action-btn stc-btn"
                  variant="outlined"
                  style={{ textTransform: "capitalize" }}
                  onClick={handleDocumentsPopupClose}
                  disabled={pageStatus?.loading}
                  
                >
                  Cancel
                </Button>
                <Button
                  data-testid="contained"
                  className="submission-action-btn stc-btn"
                  variant="contained"
                  style={{ textTransform: "capitalize" }}
                  onClick={() => formik.handleSubmit()}
                  disabled={pageStatus?.loading}
                  endIcon={pageStatus?.loading ? <CircularProgress size={"16px"} color={"inherit"} />:"" }
                >
                  Cancel Interview
                </Button>
              </Grid>
            </DialogContent>
            <DialogActions></DialogActions>
          </Paper>
        </Dialog>
      
    </>
  );
};
export default CandidateInterviewCancelPopup;
