import React from "react";
import Grid from "@mui/material/Grid";
import AppBreadCrumb from "../../Common/AppBreadCrumb";
import { BreadCrumbItem } from "../../../models/AppModels";
import RecruiterList from "../../Recruiters/Listing/RecruiterList";
import { getNevLink } from "../../../utils/getFileFromUrl";

interface Recruiter {
  id: number;
  name: string;
}

interface RecruiterListPageProps {
  recruiters: Recruiter[];
}

const DefaultBreadCrumbs: BreadCrumbItem[] = [
  { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
  {
    isLeaf: true,
    sequence: 3,
    title: "Recruiters",
    leafClasses: " v2LastLeft",
  },
];

const RecruiterListPage: React.FC<RecruiterListPageProps> = ({
  recruiters,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AppBreadCrumb
          classes="custom-appbreadcrumb"
          BreadCrumbItems={DefaultBreadCrumbs}
        />
      </Grid>
      <Grid item xs={12}>
        <RecruiterList />
      </Grid>
    </Grid>
  );
};

export default RecruiterListPage;
