import { createSvgIcon } from "@mui/material/utils";

export const BacklistIcon = createSvgIcon(
    <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_12260_39135)">
    <path d="M11.107 0C11.2394 2.8119e-05 11.3663 0.052531 11.46 0.146L15.854 4.54C15.9475 4.63371 16 4.76065 16 4.893V11.107C16 11.2394 15.9475 11.3663 15.854 11.46L11.46 15.854C11.3663 15.9475 11.2394 16 11.107 16H4.893C4.76065 16 4.63371 15.9475 4.54 15.854L0.146 11.46C0.052531 11.3663 2.8119e-05 11.2394 0 11.107L0 4.893C2.8119e-05 4.76065 0.052531 4.63371 0.146 4.54L4.54 0.146C4.63371 0.052531 4.76065 2.8119e-05 4.893 0L11.107 0Z" fill="black"/>
    </g>
    <defs>
    <clipPath id="clip0_12260_39135">
    <rect width="16" height="16" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    ,
  "BacklistIcon"
);
