import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Grid,
} from "@mui/material";
// Icons
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useNavigate } from "react-router-dom";
import { getNevLink } from "../../../../utils/getFileFromUrl";
import convertToLocalizedDateTime from "../../../../utils/AppDateUtils";
import StatusChip from "../../../Common/Chip/StatusChip";
import truncateString from "../../../../utils/truncateString";
import { PhoneNumber } from "../../../ui/PhoneNumber";
import SavedJobIcon from "../../../Common/Icons/Job/SavedJobIcon";
import SubmissionIcon from "../../../Common/Icons/SubmissionScreen/SubmissionIcon";
import AppSort from "../../../Common/AppSort";
import { SortType } from "../../../../models/app/AppModels";
import { Fullname } from "../../../Common/Fullname";
import { ModuleComponent } from "../../../Common/Permission/ModuleComponent";
import { PermissionsEnums } from "../../../../data/permission";
import { setBreadCrumbsState } from "../../../../features/Common/BreadcrumbsStateSlice";
import { PageName } from "../../../../models/app/Page/PageModel";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../store/AppState";
import { RecruiterListRequestPayload } from "../../../../models/Recruiter/RecruiterListModel";
import GenericPagination from "../../../Common/Table/GenericPagination";
import PleaseWait from "../../../Common/Loder/PleaseWait";
import NoRecordsFound from "../../../Common/Table/NoRecordsFound";

interface RecruiterListTableProps {
  recruitersData: Array<[]>;
  isLoading: boolean;
  handleSortChange: (sort:boolean, sortColumn: string)=>void;
  totalRecordCount:number;
  handlePaginationIndex:(index:number) => void;
  RecruiterListState:RecruiterListRequestPayload;
}

const RecruiterListTable: React.FC<RecruiterListTableProps> = ({
  recruitersData,
  isLoading,
  handleSortChange,
  totalRecordCount,
  handlePaginationIndex,
  RecruiterListState
}) => {
  const navigate = useNavigate();
  const breadCrumbState = useSelector((state:AppState) => state.BreadcrumbsState);
  const dispatch = useDispatch();
  const [sort, setSort] = useState<boolean>(true);
  const [sortColumnName, setSortColumnName] = useState<string>("createdDate");

  // Pagination state
  const [rowsPerPage] = useState(RecruiterListState?.pageSize);

  const handleChangePage = (event: unknown, newPage: number) => {
    handlePaginationIndex(newPage);
  };

  // Handle sort
const handleSortClicked = (sortColumnName: string) => {
    handleSortChange(!sort,sortColumnName)
    setSort(!sort);
    setSortColumnName(sortColumnName);
    
  };
  
  const _handleSubmissionBtnClick = (row:any) => {
    dispatch(
      setBreadCrumbsState({
        ...breadCrumbState,
        navigateFromPage: PageName.ADMINSUBMISSIONACTIVE,
      })
    );
    navigate(getNevLink(`/submission/active?recruiterGuid=${row.id}`));
  }

  return (
    <TableContainer component={Paper}>
      <GenericPagination
        count={totalRecordCount}
        rowsPerPage={rowsPerPage}
        page={RecruiterListState?.pageIndex}
        onPageChange={handleChangePage}
      />  
        <Table className="AppTable recruiterListTable cust-th-font" >
          <TableHead>
            <TableRow key={"hederRow"}>
              <TableCell 
                key="Name" 
                className="nameTableHead"
                data-testid="name-sort-column"
                onClick={()=>{
                    handleSortClicked("name")
                }}
                >                
                <Grid container>
                    <Grid className="custom-Client-fontsizes pointer">
                    Name
                    </Grid>
                    <Grid>
                    <AppSort
                        sortType={
                        sort ? SortType.Ascending : SortType.Descending
                        }
                        disable={sortColumnName !== "name"}
                    ></AppSort>
                    </Grid>
                </Grid>
              </TableCell>
              <TableCell key="Email">Email</TableCell>
              <TableCell key="Phone" className="phoneTableHead">
                Phone
              </TableCell>
              <TableCell key="Location">Location</TableCell>
              <TableCell 
                key="Date" 
                className="dateTableHead"
                data-testid="date-sort-column"
                onClick={()=>{
                    handleSortClicked("createdDate")
                }}
                >
                <Grid container>
                    <Grid className="custom-Client-fontsizes pointer">
                    Date
                    </Grid>
                    <Grid>
                    <AppSort
                        sortType={
                        sort ? SortType.Ascending : SortType.Descending
                        }
                        disable={sortColumnName !== "createdDate"}
                    ></AppSort>
                    </Grid>
                </Grid>
              </TableCell>
              <TableCell key="Feedback">Feedback</TableCell>
              <TableCell key="StatusHead">Status</TableCell>
              <TableCell key="Action" className="actionTableHead">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recruitersData?.length > 0
              ? recruitersData.map((recruiter: any, rowIndex: any) => (
                  <TableRow key={ recruiter?.id ?? rowIndex} className="hover-row v2-table-row">
                    <TableCell>
                     <Fullname fullName={recruiter?.recruiterFullName}/>
                    </TableCell>
                    <TableCell>
                      <div className="emailCell">
                        {recruiter?.email ?? "N/A"}
                        <CheckCircleRoundedIcon
                          className="verifi-success-icon"
                          sx={{
                            marginLeft: "2px",
                            fontSize: "14px",
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="phoneCell">
                        <PhoneNumber
                          number={recruiter?.primaryPhone ?? "N/A"}
                        />
                        <CheckCircleRoundedIcon
                          className="verifi-success-icon"
                          sx={{
                            marginLeft: "2px",
                            fontSize: "14px",
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      {recruiter?.location}
                    </TableCell>
                    <TableCell>
                      {convertToLocalizedDateTime(
                        recruiter?.createdDate,
                        "MMMM DD, YYYY,  h:mm A"
                      )}
                    </TableCell>
                    <TableCell>
                      {recruiter?.feedback?.length < 20 ? (
                        truncateString(recruiter?.feedback, 20)
                      ) : (
                        <Tooltip title={recruiter?.feedback}>
                          <span>{truncateString(recruiter?.feedback, 20)}</span>
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell>
                      <StatusChip status={recruiter?.Status} />
                    </TableCell>
                    <TableCell>
                      {/* Edit Recruiter Button */}
                      <ModuleComponent moduleId={PermissionsEnums.Recruiters_Edit}>
                        <Tooltip title={"Edit"} placement="top" key="editIconTooltip">
                          <span className="icon-span">
                            <EditOutlinedIcon
                              className="recruiterListActionButtonIcon"
                              data-testid="editIcon-btn"
                              onClick={() =>
                                navigate(
                                  getNevLink(`/edit-recruiter/${recruiter?.id}`)
                                )
                              }
                            />
                          </span>
                        </Tooltip>
                      </ModuleComponent>
                      {/* Verify Recruiter Button  */}
                      <ModuleComponent moduleId={PermissionsEnums.Recruiter_Manage}>
                        <Tooltip title={"Verify"} placement="top">
                          <span className="icon-span">
                            <TaskAltOutlinedIcon
                              style={{ marginRight: "8px" }}
                              className="recruiterListActionButtonIcon"
                              data-testid="verifyIcon-btn"
                              onClick={() => {
                                navigate(
                                  getNevLink(
                                    `/recruiters/verify/${recruiter?.id}`
                                  )
                                );
                              }}
                            />
                          </span>
                        </Tooltip>
                      </ModuleComponent>
                      {/* Saved Job Button*/}
                     <ModuleComponent moduleId={PermissionsEnums.Recruiters_SavedJobs}>
                        <Tooltip title={"Saved Job"} placement="top">
                          <span className="icon-span">
                            <SavedJobIcon
                              style={{ fontSize: "20px" }}
                              className="recruiterListActionButtonIcon"
                              data-testid="savedJobIcon-btn"
                              onClick={() => {
                                navigate(
                                  getNevLink(
                                    `/recruiter/savedjobs/${recruiter?.id}`
                                  )
                                );
                              }}
                            />
                          </span>
                        </Tooltip>
                     </ModuleComponent>
                      {/* Active submission Button */}
                     <ModuleComponent moduleId={PermissionsEnums.Recruiters_Submissions}>
                        <Tooltip title={"Submissions"} placement="top">
                          <span className="icon-span">
                          <SubmissionIcon
                          type="button"
                            className="recruiterListActionButtonIcon submissionIcon"
                            data-testid="submissionIcon-btn"
                            onClick={()=>{
                              _handleSubmissionBtnClick(recruiter);
                            }}
                          />
                          </span>
                        </Tooltip>
                     </ModuleComponent>
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>

        {isLoading && <PleaseWait />}
        {!isLoading && recruitersData?.length === 0 && <NoRecordsFound /> }
    </TableContainer>
  );
};

export default RecruiterListTable;
