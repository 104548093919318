import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { PermissionsEnums } from "../../../../data/permission";
import { UserAuth } from "../../../../models/interfaces";
import { CandidateSubmissionStatus } from "../../../../models/submission/CandidateSubmissionMasterStatusesModel";
import "../../NewViewSubmission/styles.scss";

interface UpdateInterviewStatusButtonProps {
  row: any;
  userRole: any;
  setPopup: any;
  hasActiveEvent: any;
  features: any;
}

export default function UpdateInterviewStatusButton({
  row,
  userRole,
  setPopup,
  hasActiveEvent,
  features
} : UpdateInterviewStatusButtonProps) {

  const shouldRenderUpdateStatusButton =
    !hasActiveEvent(row?.interviewAvailabilities || []) &&
    (row.finalStatusId === CandidateSubmissionStatus.InterviewScheduled ||
      row.finalStatusId === CandidateSubmissionStatus.InterviewRescheduled ||
      row.finalStatusId === CandidateSubmissionStatus.NextRoundOfInterview ||
      row.finalStatusId === CandidateSubmissionStatus.InterviewFeedbackPending) &&
    (userRole === UserAuth.Client || userRole === UserAuth.ClientTeamMember) &&
    (features?.includes(PermissionsEnums.CandidateSelected) ||
      features?.includes(PermissionsEnums.RejectInInterview));

  const handleUpdateStatusButtonClick = () => {
    if (shouldRenderUpdateStatusButton) {
      setPopup((popup: any) => ({ ...popup, updateInterviewStatus: true }));
    }
  };

  return (
    <>
    {shouldRenderUpdateStatusButton && (
      <Grid item>
        <Button
          className="submission-action-btn"
          variant="contained"
          color="primary"
          onClick={handleUpdateStatusButtonClick}
        >
          Update Interview Status
        </Button>
      </Grid>
    )}
    </>
  );
}