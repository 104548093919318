import getUserDetails from "../../../Shared/utils/getUserDetails";
import { valueOrDefault } from "../../../utils/complexityUtils";

export const initialState = {
    id: "",
    email: "",
    firstName: "",
    middleName: "",
    lastName: "",
    experience: "",
    profiles: [] as any,
    domains: [] as any,
    interests: [] as any,
    preferredLocation: [] as any,
    industries: [] as any,
    isSignupCompleted: false,
    isEmailVerified: true,
    isIdentityUpdated: false,
    skills: [],
    recruiterId: "",
    userSelfDescription: "",
    linkedIn: "",
    address: {
      id: "",
      streetAddress: "",
      city: "",
      state: "",
      postalCode: "",
      countryCode: "",
    },
    phoneNumbers: {
      primary: "",
      secondary: "",
      isPrimaryVerified: false,
      isSecondaryVerified: false,
    },
    currentWorkLocation: {
      id: "",
      city: "",
      state: "",
    },
    timezone: "",
    documentInfo: [] as any,
    hearAboutUs: {
      id: "",
      option: "",
      hearAboutUsSubOption: {
        id: "",
        option: ""
      },
      otherSpecification: {
        specifyOther: ""
      }
    },
    billingInfoType: "",
    paymentDetailsChangeRequest: {
      usaBilling: {
        userId: "",
        email: "",
        name: "",
        billingInfoType: "USA",
        country: "USA",
        routingNumber: "",
        confirmRoutingNumber: "",
        accountHolderName: "",
        bankName: "",
        bankAccountNumber: "",
        confirmBankAccountNumber: "",
        location: {
          address: "",
          city: "",
          state: "",
          zip: "",
          country: ""
        },
        isRoutingNumberConfirm: true,
        isW9NameConfirm: true,
        resourceInfo: {
          resourceUrl: "",
          fileName: "",
          newFileName: ""
        },
        isUpdateDetails: false
      },
      payoneerBilling: {
        userId: "",
        email: "",
        billingInfoType: "Payoneer",
        isUpdateDetails: false
      },
      isActive: true,
      createdDate: new Date(),
      createdBy: valueOrDefault(getUserDetails()?.id, ""),
      validTill: new Date(Date.now() + 1 * 24 * 60 * 60 * 1000),
      authToken: localStorage?.getItem("accessToken") ?? ""
    }
  };