import React, { ReactNode } from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';

interface PageTitleProps {
  children: ReactNode;
  typographyProps?: TypographyProps;
}

const ScorecardPageHeader: React.FC<PageTitleProps> = ({ children, typographyProps }) => {
  return (
    <Typography variant="h4" gutterBottom {...typographyProps}>
      {children}
    </Typography>
  );
};

export default ScorecardPageHeader;
