import { createSlice } from "@reduxjs/toolkit";


let initialState = {
  recruiterGuid: "",
  jobId: "",
  searchKeywords: JSON.parse(localStorage.getItem("activeRecruiterSearchData"))?.searchKeywords ?? "",
  location: [],
  statuses: [],
  statusIds: JSON.parse(localStorage.getItem("activeRecruiterSearchData"))?.statusClearAll ?  [] : JSON.parse(localStorage.getItem("activeRecruiterSearchData"))?.statusIds ?? [],
  contractType: [],
  showPendingSubmissions: false,
  pageIndex: 1,
  pageSize: 10,
  sortColumn: "createdDate",
  sortOrder: "Descending",
  isSorted: true,
  totalRecord: 0,
  durationFilter: JSON.parse(localStorage.getItem("activeRecruiterSearchData"))?.durationFilter ?? {
    duration: '0',
    durationType: "All",
    fromDate : "",
    toDate : ''
  },
  candidateGuids: JSON.parse(localStorage.getItem("activeRecruiterSearchData"))?.candidateGuids  ?? [] ,
  recruiterFilter: [],
  selectedCandidateFilter: JSON.parse(localStorage.getItem("activeRecruiterSearchData"))?.selectedCandidateFilter  ?? [],
  clientNames: JSON.parse(localStorage.getItem("activeRecruiterSearchData"))?.clientNames ?? [] ,
  selectedClientDetails: JSON.parse(localStorage.getItem("activeRecruiterSearchData"))?.selectedClientDetails ?? [],
  }
export const activeRecruiterSubmissionSlice = createSlice({
  name: "activeRecruiterSubmissionSlice",
  initialState,
  reducers: {
    setRecruiterSubmissionState: (state,action) => {
        return action.payload;
    },
    resetActiveRecruiterSubmission: (state) => initialState,
    setRecruiterGuid: (state, action) => {
      state.recruiterGuid = action.payload;
    },},
  
});

// Action creators are generated for each case reducer function
export const {setRecruiterSubmissionState,resetActiveRecruiterSubmission,setRecruiterGuid} =
  activeRecruiterSubmissionSlice.actions;

export default activeRecruiterSubmissionSlice.reducer;