import { createSvgIcon } from "@mui/material/utils";

export const ApproveIcon = createSvgIcon(
<svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="8" cy="8" r="8" fill="#2BB02B"/>
<path d="M3.38477 8.0009L7.69246 11.6932L12.9232 4.30859" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
 
    ,
  "ApproveIcon"
);