import * as Yup from "yup";
import { addressRegex, cityRegex, experienceRegex, firstNameRegex, middleNameRegex, phoneNumberRegex, zipValidation } from "../../../../constants/regex";

export const SubmitResumeValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Entered email address is invalid")
      .required("Please enter a valid email address"),
    firstName: Yup.string()
      .required("Please enter the first name")
      .matches(firstNameRegex, "Entered First Name is invalid"),
    middleInitial: Yup.string().matches(
      middleNameRegex,
      "Middle Name is not valid"
    ),
    lastName: Yup.string()
      .required("Please enter the last name")
      .matches(firstNameRegex, "Entered Last Name is invalid"),
    canRelocate: Yup.boolean(),
    phone: Yup.string()
      .required("Please enter the phone number")
      .matches(phoneNumberRegex, "Entered Phone number is invalid"),
    experience: Yup.string()
      .required("Please enter the total experience")
      .matches(
        experienceRegex,
        "Please enter the valid experience from 0 to 25 or 25+"
      ),
    resume: Yup.string().required("Please attach the candidate resume"),
    payrate: Yup.number()
      .required("Please enter expected pay rate")
      .min(0, "Min value 0"),
    payrateType: Yup.string().required(
      "Please specify if the pay rate is Daily, Monthly or Yearly"
    ),
    candidateEmployment: Yup.string().required(
      "Please select Candidate Employment"
    ),
    zip: Yup.string()
      .required("Please enter the zip code")
      .matches(zipValidation, "Entered Zip is invalid"),
    country: Yup.string()
      .required("Please enter the country")
      .matches(cityRegex, "Entered Country is invalid"),
    address: Yup.string()
      .required("Please enter the address")
      .matches(addressRegex, "Entered Address is invalid"),
    city: Yup.string()
      .required("Please enter the city")
      .matches(cityRegex, "Entered City is invalid"),
    state: Yup.string()
      .required("Please enter the state")
      .matches(cityRegex, "Entered State is invalid"),
    recruiterComments: Yup.string().required("Please enter comments").max(1000),
    workAuthorization: Yup.string().required(
      "Please select work authorization status"
    ),
    isSkillsExist: Yup.boolean(),
    employerDetails: Yup.object().when(
      ["workAuthorization", "candidateEmployment"],
      {
        is: (workAuthorization: string, candidateEmployment: string) =>
          candidateEmployment === "DirectContract" &&
          (workAuthorization === "H1B" ||
            workAuthorization === "E3" ||
            workAuthorization === "TN"),
        then: Yup.object().shape({
          name: Yup.string().required("Please enter employer name"),
          email: Yup.string()
            .email("Please enter valid email")
            .required("Please enter employer email"),
          phoneNumber: Yup.string().required(
            "Please enter employer phone number"
          ),
          reference: Yup.string().required("Please enter employer reference"),
          address: Yup.object().shape({
            zip: Yup.string()
              .required("Please enter employer zip")
              .matches(zipValidation, "Entered Zip is invalid"),
            country: Yup.string()
              .required("Please enter employer country")
              .matches(cityRegex, "Entered Country is invalid"),
            streetAddress: Yup.string()
              .required("Please enter employer address")
              .matches(addressRegex, "Entered Address is invalid"),
            city: Yup.string()
              .required("Please enter employer city")
              .matches(cityRegex, "Entered City is invalid"),
            state: Yup.string()
              .required("Please enter employer city")
              .matches(cityRegex, "Entered State is invalid"),
          }),
        }),
        otherwise: Yup.object().shape({
          name: Yup.string(),
          email: Yup.string().email("Please enter valid email"),
          phoneNumber: Yup.string(),
          reference: Yup.string(),
          address: Yup.object().shape({
            streetAddress: Yup.string(),
            city: Yup.string(),
            country: Yup.string(),
            state: Yup.string(),
            zip: Yup.string(),
          }),
        }),
      }
    ),
    skills: Yup.array().when("isSkillsExist", {
      is: true,
      then: Yup.array().min(1, "Please select skills").required(),
    }),
    currentWorkLocation: Yup.object({
      city: Yup.string()
        .required("Please enter the city")
        .matches(cityRegex, "Entered City is invalid"),
      state: Yup.string()
        .required("Please enter the state")
        .matches(cityRegex, "Entered State is invalid"),
    }),
    iConfirm: Yup.boolean().isTrue().required(),
    informedCandidate: Yup.boolean().isTrue().required(),
    isRemoteJob: Yup.boolean(),
    //apply validation only if job is remote and workauthorization is not remote global
    isRemoteUSA: Yup.boolean().when(["workAuthorization", "isRemoteJob"], {
      is: (workAuthorization: string, isRemoteJob: boolean) =>
        isRemoteJob && (workAuthorization !== "Remote (Global)"),
      then: Yup.boolean()
        .isTrue("Please select remote (USA)")
        .required("Remote (USA) Field must be checked"),
  }),
  });