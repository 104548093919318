import jwtDecode from 'jwt-decode';

function decodeJwt(): { [key: string]: any } {
  let token = localStorage.getItem("accessToken");
  if(token){
  const decoded = jwtDecode(token);
  if (!decoded || typeof decoded !== 'object') {
    throw new Error('Invalid token');
  }
  return decoded;
} 
throw new Error('token not available');
}
export default decodeJwt;
