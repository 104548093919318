import React from 'react';
import './../Scorecard.scss'; 

interface ScoreChipProps {
  label: string;
  percentage: any;
  className?: string;
}

const ScoreChip: React.FC<ScoreChipProps> = ({ label, percentage, className }) => {
  return (
    <div className="score-chip-root">
      <span className="score-chip-label">{label}</span>
      <span className="score-chip-percentage">{`${percentage}%`}</span>
    </div>
  );
};

export default ScoreChip;
