import { Component } from "react";
import Chart from "react-apexcharts";
import truncateString from "../../../utils/truncateString";

class RelevantRecruitersChart extends Component {
  
  constructor(props) {

    let jobTitles = props?.data?.map(x => x.jobTitle)
    let lastMonth = props?.data?.map(x=>x.previousMonthRecruiterCount)
    let currentMonth = props?.data?.map(x=>x.currentMonthRecruiterCount)
    const categories = jobTitles
    
    super(props);
    this.changeData = this.changeData.bind(this);
    let stateData ={
      cat : categories,
      series: [
        {
          name: "Last Month",
          data: lastMonth,
        },
        {
          name: "This Month",
          data: currentMonth,
        },
      ],

      options: {
        chart: {
          type: "bar",
          height: 280,
          toolbar: {
            show: false,
          },
          events: {
            legendClick: function (chartContext, seriesIndex, config) {
            
              this.changeData()
              
              
            }
          },
        },
        colors: ["#8C83FA", "#4540DB"],
        plotOptions: {
          bar: {
            borderRadius: 6,
            horizontal: false,
          },
        },
        yaxis: {
          labels: {
            formatter: (val) => {            
              // Check if val is a number, if not, default to 1
              return typeof val === "number" && val !== Infinity ? Math.floor(val) : 1;
            },
          },
          
        },
       
        xaxis: {
         categories: categories.map(x => x.replace(' - ', "- ").split(' ').map(x => truncateString(x, 12))),
          
          labels: {
            style: {
              colors: ["#4540DB", "#4540DB", "#4540DB", "#4540DB", "#4540DB"],
              fontSize: "10px",
            },

            rotate: 0

          },
        },

        tooltip: {
          x: {
            shared: false,

            formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
              return jobTitles[dataPointIndex]
            }
          },

        },

        legend: {
          show: true,
          fontSize: "9px",
          position: "top",
          offsetX: -10,
          offsetY: 15,
          itemContainers: {
            clickable: false,
            focusable: false
          }
        },
        noData: {
          text: "No Record found",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
        },

      },
    };

    this.state = stateData
  }
  
  changeData (){
    // does nothing, not sure if intentional
  }

  render() {
        
    
       return (
        <>
      <Chart
        options={this.state.options}
        series={this.state.series}
        type="bar"
        width="100%"
        height="260"
      />
      </> 
    );
  }
}

export default RelevantRecruitersChart;
