import { IndiaPaymentDetail, PayoneerPaymentDetail, USAPaymentDetail } from './../models/PaymentDetails';
import axios from './CommonHttpRequest';

let baseUrl = `${process.env.REACT_APP_RECUITER_API}`;

export const AddIndianPaymentDetail = async (data:IndiaPaymentDetail) => {
    return axios.post(`${baseUrl}api/RecruiterBilling/AddIndianBilling`, data);
}
export const AddUSAPaymentDetail = async (data:USAPaymentDetail) => {
    return axios.post(`${baseUrl}api/RecruiterBilling/AddUSABilling`, data);
}
export const AddPayoneerPaymentDetail = async (data:PayoneerPaymentDetail) => {
    return axios.post(`${baseUrl}api/RecruiterBilling/AddPayoneerBilling`, data);
}