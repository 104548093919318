import { createTheme } from "@mui/material/styles";

let staticPageTheme = createTheme({
    typography: {
    },
    palette: {
        primary: {
          main: "#4540DB",
        },
        secondary: {
          light: "#0066ff",
          main: "#0044ff",
          contrastText: "#ffcc00",
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none"
            }
          }
        }
      }
  });
export default staticPageTheme;