import styled from "@emotion/styled";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
  Autocomplete,
  Chip,
  TextField,
  CircularProgress,
  IconButton,
} from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";
import { UserAuth } from "../../../../models/interfaces";
import { GetCandidateByFilter } from "../../../../Services/candidateService";
import getUserDetails from "../../../../Shared/utils/getUserDetails";
import getLoggedInUserRole from "../../../../Shared/utils/getLoggedInUserRole";
import CloseIcon from "@mui/icons-material/Close";

const Wrapper = styled.div``;

type CandidateDialogProps = {
  isOpen: boolean;
  onConfirm: any;
  onClose: any;
  isLoadingData: boolean;
};

const CandidateDialog: FunctionComponent<CandidateDialogProps> = ({
  isOpen,
  onClose,
  onConfirm,
  isLoadingData,
}) => {
  const [searchCandidate, setSearchCandidate] = useState("");
  const [candidateOption, setCandidateOption] = useState<any[]>([]);
  const [selectedCandidate, setSelectedCandidate] = useState<any>();
  const [loading, setLoading] = useState(false);
  let user = getUserDetails();
  let userRole = getLoggedInUserRole();

  const candidateFilter = {
    recruiterId: userRole === UserAuth.Recruiter ? user?.id : "",
    searchKeywords: "",
    location: [],
    experience: [],
    workAuthorizationStatuses: [],
    skills: [],
    payRate: "",
    contractStatus: [],
    pageIndex: 1,
    pageSize: 100,
    sortColumn: "firstName",
    sortOrder: "Descending",
    isSorted: true,
    totalRecord: 0,
  };

  const toPascalCase = (str: string) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  useEffect(() => {
    if(isOpen)
      GetCandidates(searchCandidate);
  }, [searchCandidate,isOpen]);

  const GetCandidates = (search: string) => {
    setCandidateOption([]);
    setLoading(true)
    GetCandidateByFilter({ ...candidateFilter, searchKeywords: search })
      .then((res) => {
        setCandidateOption([...res.data.entityList]);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {setLoading(false)});
  };

  const sortByName: ((a: any, b: any) => number) | undefined = (a, b) => {
    const nameA = a.firstName.toUpperCase(); // ignore upper and lowercase
    const nameB = b.firstName.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  };
  
  let sortedCandidateOption = [...candidateOption];
  sortedCandidateOption.sort(sortByName);

  return (
    <>
      <Wrapper>
        <Dialog
          open={isOpen}
          onClose={() => {}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{ bgcolor: "#E6E9FB" }}
          ></DialogTitle>
          <DialogContent sx={{ bgcolor: "#E6E9FB" }}>
            <IconButton
              aria-label="close"
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
                padding:"5px"
              }}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent style={{paddingLeft:0,paddingRight:0}}>
              <Grid container spacing={2} pt={2} justifyContent={'space-between'}>
                <Grid item sm={2} style={{ padding: "16px 0 0 16px" }}>
                  <Typography className="AdvanceSearchLabel" fontSize={20}>
                    Candidate:
                  </Typography>
                </Grid>
                <Grid item xs={9.9} pb={8} mt={"5px"}>
                <Autocomplete
                    freeSolo
                    id="tags-standard"
                    data-testid="autocomplete-candidate"
                    disableClearable
                    getOptionLabel={(option: any) => {
                      const firstName = toPascalCase(option.firstName);
                      const lastName = toPascalCase(option.lastName);
                      return (
                        firstName + " " + lastName + " (" + option.email + ")"
                      );
                    }}
                    options={sortedCandidateOption}
                    style={{ padding: "0px", margin: "1px !important" }}
                    onBlur={(e) => {}}
                    clearOnBlur
                    onKeyUp={(event: any) => {
                      setSearchCandidate(event.target.value);
                    }}
                    onChange={(event, newValue) => {
                      setSelectedCandidate(newValue);
                    }}
                    value={selectedCandidate}
                    loading={loading}
                    loadingText={"loading..."}
                    noOptionsText={"No Options"}
                    ListboxProps={{style:{
                      maxHeight:140
                    }}}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          color="primary"
                          size="medium"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        fullWidth
                        id="jobTitle"
                        name="jobTitle"
                        label=""
                        type="text"
                        placeholder="Search by Name or Email"
                        style={{paddingBottom:0}}
                        inputProps={{
                          ...params.inputProps,
                          style:{
                            paddingBottom:0
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </DialogContent>
          </DialogContent>
          <DialogActions sx={{ bgcolor: "#E6E9FB" }}>
            <Button
              variant="outlined"
              size="small"
              style={{ marginRight: "5px", textTransform: "capitalize" }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              style={{ textTransform: "capitalize" }}
              onClick={() => onConfirm(selectedCandidate)}
              disabled={selectedCandidate && !isLoadingData ? false : true}
              endIcon={
                isLoadingData ? (
                  <CircularProgress size={"16px"} color={"inherit"} />
                ) : (
                  ""
                )
              }
            >
              Continue
            </Button>
          </DialogActions>
        </Dialog>
      </Wrapper>
    </>
  );
};

export default CandidateDialog;
