import styled from "@emotion/styled";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
} from "@mui/material";
import React, { FunctionComponent } from "react";
import CloseIcon from "@mui/icons-material/Close";

const Wrapper = styled.div``;

type ExistingCandidateDialogProps = {
  isOpen: boolean;
  onConfirm: any;
  onClose: any;
};

const ExistingCandidateDialog: FunctionComponent<
  ExistingCandidateDialogProps
> = ({ isOpen, onClose, onConfirm }) => {
  return (
    <>
      <Wrapper>
        <Dialog
          open={isOpen}
          onClose={() => {}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{ bgcolor: "#FFF" }}
          ></DialogTitle>
          <DialogContent sx={{ bgcolor: "#FFF" }}>
          <IconButton
            aria-label="close"
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
              padding:"5px"
            }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
            <DialogContentText
              id="success-dialog-description"
              className="roboto-font"
              style={{
                textAlign: "center",
                padding: "10px",
                fontSize: "20px",
                fontStyle: "normal",
                color: "#06152B",
                paddingBottom: "7px",
                paddingTop:"13px"
              }}
            >
              Do you want to go with existing candidate?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ bgcolor: "#FFF" }}>
            <Button size="small" className="roboto-font" variant="outlined" onClick={onClose} data-testid="close-popup-existing-candidate">
              No
            </Button>
            <Button size="small" className="roboto-font" variant="contained" onClick={onConfirm}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Wrapper>
    </>
  );
};

export default ExistingCandidateDialog;
