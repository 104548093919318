import { SvgIcon } from '@mui/material'
import React from 'react'

export default function TouchPoint(props) {
  return (
    <SvgIcon viewBox='0 0 35 35' {...props}>
<path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.30013 13.5622C8.26003 13.7003 8.20755 13.835 8.14308 13.9645L6.93598 13.364L8.14308 13.9645C7.63339 14.989 6.53128 15.4576 5.5143 15.2598C5.98266 15.9784 6.34353 16.6013 6.63688 17.1076C6.82698 17.4357 6.98873 17.7149 7.13299 17.9395C7.45017 18.4332 7.63569 18.6035 7.7943 18.6948C7.94878 18.7839 8.25056 18.8955 8.97924 18.8955H11.0626C12.5353 18.8955 13.7292 17.7016 13.7292 16.2288V13.5726H11.6459V13.5622H10.4792H9.56258H8.30013ZM14.1564 9.5207C13.694 8.88905 12.947 8.47884 12.1042 8.47884C12.0938 8.47884 12.0835 8.47891 12.0731 8.47903C11.8976 8.23927 11.6811 8.03142 11.4339 7.86579L11.7907 7.32904C12.2784 6.59564 12.5625 5.71397 12.5625 4.77051C12.5625 2.21619 10.4918 0.145508 7.93748 0.145508C5.38316 0.145508 3.31248 2.21619 3.31248 4.77051C3.31248 5.71397 3.59658 6.59564 4.08421 7.32904L4.91472 8.57814L5.39591 8.2582V11.4764L4.86868 10.9492C3.8761 9.95659 2.2668 9.95659 1.27422 10.9492C0.300928 11.9225 0.282014 13.4887 1.21748 14.4851C2.61609 16.1628 3.34051 17.4116 3.95026 18.4627C4.17607 18.852 4.38616 19.2141 4.60893 19.5609C5.01413 20.1917 5.51532 20.8441 6.29654 21.2942C7.0819 21.7467 7.97308 21.8955 8.97924 21.8955H11.0626C14.1922 21.8955 16.7292 19.3585 16.7292 16.2288V12.0622C16.7292 10.6585 15.5913 9.52051 14.1876 9.52051C14.1772 9.52051 14.1668 9.52057 14.1564 9.5207Z"
    />    </SvgIcon>
  )
}