import Typography from "@mui/material/Typography";
import {
  Button
} from "@mui/material";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "./JobPage.scss";

interface ClientJobInactivePopupProps {
  openPopup: boolean;
  handleConfirm: () => void;
  handleClose: () => void;
  selectedNotActiveJob:any;
}

export const JobInactivePopup = ({
  openPopup, handleConfirm, handleClose, selectedNotActiveJob
} : ClientJobInactivePopupProps) => { 

  const getFeaturedMessage = () => {
    if (selectedNotActiveJob.isFeatured) {
      return `This job is marked as featured${selectedNotActiveJob?.recruiterSavedJobsCount > 0
        ? ` and also saved by ${selectedNotActiveJob?.recruiterSavedJobsCount} recruiter.`
        : '.'}`;
    } else {
      return selectedNotActiveJob?.recruiterSavedJobsCount > 0
        ? `This job is saved by ${selectedNotActiveJob?.recruiterSavedJobsCount} recruiter.`
        : '';
    }
  };

  return (
    <Dialog
      open={openPopup}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={
        {
          style:{
            padding:"20px 24px 10px 24px"
          }
        }
      }
    >
      <DialogTitle id="alert-dialog-title" style={{padding:"0px 0px 5px 0px"}}>
      <IconButton 
        aria-label="close" 
        size="small" 
        className="close-Btn" 
        style={{position:"absolute",right:"5px",top:"10px"}} 
        onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
      </DialogTitle>
      <DialogContent style={{padding:"20px 0px 10px 0"}} sx={{justifyContent:"center"}}>
        <DialogContentText>
        <Typography
            variant="h6"
            component="div"
            className="roboto-font  letter-spacing-normal"
            sx={{textAlign:"center"}}
          >
            {getFeaturedMessage()}
            
           <div> Are you sure to want to mark this job as Not Active?</div>
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{justifyContent:"center"}} style={{padding:"15px 0 7px 0"}}>
        <Button className="custPrimary" onClick={handleConfirm} data-testid={"handleSaveConfirmationYes"} variant="contained">Yes</Button>
        <Button className="custPrimary" onClick={handleClose} data-testid={"handleSaveConfirmationNo"} variant="outlined">No</Button>
      </DialogActions>
    </Dialog>
  );
};
