import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  FormControl,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import useApi from "../../hooks/useApi";
import { GetRecruiterById, SendEmailForDeniedPaymentUpdation } from "../../Services/recruiterService";
import { useSelector,useDispatch } from "react-redux";
import { OtherBilling } from "./BillingDetailsForms/OtherBilling";
import { BillingType } from "../../models/PaymentDetails";
import { RecruiterPayoneerBilling } from "./BillingDetailsForms/RecruiterPayoneerBilling";
import { RecruiterUSABilling } from "./BillingDetailsForms/RecruiterUSABilling";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UpdateConfirmationDialog } from "../ui/UpdateConfirmationDialog";
import { AddPayoneerPaymentDetail, AddUSAPaymentDetail } from "../../Services/recruiterPaymentService";
import { enableEdit } from "../../features/editMode/editModeSlice";
interface Props {
  editMode : any,
  
  billingDetail:{
    userId:string,
    email:string,
    name:string,
    billingInfoType : string
  }
 
}
export const PaymentDetails: React.FC<Props> = ({
 
  billingDetail,
  editMode,
}) =>  {
  const isUpdated = useSelector((state : any) => state.paymentUpdate)
  const [selectedCountry, setselectedCountry] = useState("Please select a country")
  const [updateConfirmation, setupdateConfirmation] = useState(false)
  const [tempselectedCountry, settempselectedCountry] = useState("")
  const [openCountryChangeConfirmation, setopenCountryChangeConfirmation] = useState(false)
  const getRecruterAPI = useApi(()=> GetRecruiterById(billingDetail.userId));
  
  const handleCountryChange = (event:any) => {
    if(isUpdated.billingInfoType!=="NotUpdated")
    {
     setopenCountryChangeConfirmation(true)   
     settempselectedCountry(event.target.value)
    }
    else{
      setselectedCountry(event.target.value)
    }
     //Payment details change Payoneer
     
  };
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let isLoading : any = getRecruterAPI.loading;
  let recruiterData : any = getRecruterAPI.data;
  let authToken = searchParams.get("authToken") === "" ? "" : searchParams.get("authToken");
  React.useEffect(() => {
    dispatch(enableEdit());
  }, []);
  // Disable AutoSelection Payoneer change
  useEffect(() => {
    getRecruterAPI.request()
     setselectedCountry(getSelectedCountry(isUpdated?.billingInfoType));
    }, [])

  useEffect(() => {
   
    if(searchParams.get("verificationType")=="paymentDetails")
    {
      setupdateConfirmation(true)
      if(recruiterData?.entity?.paymentDetailsChangeRequest?.usaBilling)
      {
        setselectedCountry("USA")
      }
      if(recruiterData?.entity?.paymentDetailsChangeRequest?.payoneerBilling)
      {
        setselectedCountry("Payoneer")

      }

    }
    else
    {
      let selectedCountry;
      if (isUpdated?.billingInfoType === "NotUpdated") {
        selectedCountry = "Please select a country";
      } else {
        selectedCountry = billingDetail?.billingInfoType === "USA" ? BillingType.USA : BillingType.Payoneer;
      }

      setselectedCountry(selectedCountry)

    }
  }, [recruiterData])

  const getSelectedCountry = (billingInfoType:string) => {
    if (billingInfoType === "NotUpdated") {
      return "Please select a country";
    } else {
      return billingDetail?.billingInfoType === "USA"
        ? BillingType.USA
        : BillingType.Payoneer;
    }
  };

  const handleUpdateConfirmationOk = ()=>{
    
    //Check for changes
    let changeRequest = recruiterData?.entity?.paymentDetailsChangeRequest;
    if(changeRequest)
    {
    
      if(changeRequest?.usaBilling)
      {
       
        AddUSAPaymentDetail(changeRequest?.usaBilling).finally(()=>{
          navigate('')
        })
        
       
      }
      if(changeRequest?.payoneerBilling)
      {
        AddPayoneerPaymentDetail(changeRequest?.payoneerBilling).finally(()=>{
          navigate('')
        })
        setselectedCountry("Payoneer")
       
      }
    }
    navigate('')
   
    
    
    setupdateConfirmation(false)
  }
 
  

  const handleUpdateConfirmationNotNow = ()=>{
    navigate('')
    if(authToken !=="")
    {
      let token = { authToken : authToken}
      SendEmailForDeniedPaymentUpdation(token);
    }
    setupdateConfirmation(false)
    getRecruterAPI.request()
    
  }

  const handleDialogClose = ()=>{
    navigate('')
    setupdateConfirmation(false)
  }
  const renderOptions = () => {
    if(!isLoading){
    return <React.Fragment>
      <RadioGroup
           
            row
            value={selectedCountry}
            aria-labelledby="independentRecruiter-radio-buttons-group-label"
            name="selectedCountry"
            onChange={(e) => {
              handleCountryChange(e)
             }}
           
          >
            <FormControlLabel value={BillingType.USA}   control={<Radio disabled={!editMode} readOnly={false} data-testid="USARadio"
            />} label="US Account" />
            <FormControlLabel value={BillingType.Payoneer}   control={<Radio disabled={!editMode} readOnly={false} data-testid="payoneerRadio"
           />} label="Payoneer" />
             <FormControlLabel value={BillingType.Other}   control={<Radio disabled={!editMode} readOnly={false}
           />} label="Others" />
          </RadioGroup>
      
      </React.Fragment> 
  }
  else{
    return <p style={{marginTop:'5px'}}>Please wait...</p>
  }
}
  const renderForm = () => {
    if(selectedCountry===BillingType.Payoneer){
    return <React.Fragment> <RecruiterPayoneerBilling recruiterData={recruiterData?.entity}  billingDetail={billingDetail} editMode={editMode} key={1}/></React.Fragment>
    }
    if(selectedCountry===BillingType.Other){
    return <OtherBilling/>
    }
    if(selectedCountry === BillingType.USA){
    return <React.Fragment><RecruiterUSABilling recruiterData={recruiterData?.entity}  editMode={editMode} billingDetail={billingDetail}/></React.Fragment> 
    }
  }
  return (
    <React.Fragment>
    <Box
      sx={{
        flexGrow: 1,
        marginBottom: "16px",
      }}
      className="billing-form"
    >
      <Grid container spacing={2} >
        <Grid item xs={12} md={12}>          
            <Box
              component="form"
              noValidate
              autoComplete="off"
              sx={{
                flexDirection: "row",
                "& .MuiFormControl-root": {
                  marginTop: "3px",
                  marginBottom: "3px",
                },
              }}
            >
              <FormControl
                required
                fullWidth
              >
                {renderOptions()}                
              </FormControl>
               {renderForm()}
              </Box>         
        </Grid>
      </Grid>
      
        <UpdateConfirmationDialog 
          message={"Are you sure to update existing payment details?"} 
          aggreeText={"Yes"} 
          disagreeText={"No"} 
          onClose={()=>setopenCountryChangeConfirmation(false)} 
          open={openCountryChangeConfirmation} 
          onYes={()=>{setopenCountryChangeConfirmation(false); 
          setselectedCountry(tempselectedCountry)}} 
          onNo={()=>setopenCountryChangeConfirmation(false)}
        />

        <UpdateConfirmationDialog 
          message={" Please click the verification button to update the requested payment details."}  
          disagreeText={"Not Now"} 
          aggreeText={"Verify Payment"} 
          onClose={handleDialogClose} 
          open={updateConfirmation} 
          onYes={handleUpdateConfirmationOk} 
          onNo={handleUpdateConfirmationNotNow}
        />

    </Box>
    </React.Fragment>
  )
}
