import { createSvgIcon } from "@mui/material/utils";

export const UploadPhoto = createSvgIcon(
  <svg viewBox="0 0 50 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25 25.2812C27.072 25.2812 29.0591 24.4582 30.5243 22.993C31.9894 21.5279 32.8125 19.5408 32.8125 17.4688C32.8125 15.3967 31.9894 13.4096 30.5243 11.9445C29.0591 10.4794 27.072 9.65625 25 9.65625C22.928 9.65625 20.9409 10.4794 19.4757 11.9445C18.0106 13.4096 17.1875 15.3967 17.1875 17.4688C17.1875 19.5408 18.0106 21.5279 19.4757 22.993C20.9409 24.4582 22.928 25.2812 25 25.2812Z"
      fill="#4540DB"
    />
    <path
      d="M3.125 0.125C2.2962 0.125 1.50134 0.45424 0.915291 1.04029C0.32924 1.62634 0 2.4212 0 3.25V37.625C0 38.4538 0.32924 39.2487 0.915291 39.8347C1.50134 40.4208 2.2962 40.75 3.125 40.75H4.6875C5.1019 40.75 5.49933 40.5854 5.79235 40.2924C6.08538 39.9993 6.25 39.6019 6.25 39.1875C6.25 38.7731 6.41462 38.3757 6.70765 38.0826C7.00067 37.7896 7.3981 37.625 7.8125 37.625C8.2269 37.625 8.62433 37.7896 8.91735 38.0826C9.21038 38.3757 9.375 38.7731 9.375 39.1875C9.375 39.6019 9.53962 39.9993 9.83265 40.2924C10.1257 40.5854 10.5231 40.75 10.9375 40.75H39.0625C39.4769 40.75 39.8743 40.5854 40.1674 40.2924C40.4604 39.9993 40.625 39.6019 40.625 39.1875C40.625 38.7731 40.7896 38.3757 41.0826 38.0826C41.3757 37.7896 41.7731 37.625 42.1875 37.625C42.6019 37.625 42.9993 37.7896 43.2924 38.0826C43.5854 38.3757 43.75 38.7731 43.75 39.1875C43.75 39.6019 43.9146 39.9993 44.2076 40.2924C44.5007 40.5854 44.8981 40.75 45.3125 40.75H46.875C47.7038 40.75 48.4987 40.4208 49.0847 39.8347C49.6708 39.2487 50 38.4538 50 37.625V6.375C50 5.5462 49.6708 4.75134 49.0847 4.16529C48.4987 3.57924 47.7038 3.25 46.875 3.25H20.9594L18.75 1.04062C18.1641 0.454533 17.3694 0.125177 16.5406 0.125H3.125ZM3.125 3.25H16.5406L18.75 5.45937C19.3359 6.04547 20.1306 6.37482 20.9594 6.375H46.875V37.625H46.6094C46.3543 36.9041 45.9267 36.2566 45.3639 35.739C44.8011 35.2213 44.1202 34.8492 43.3806 34.655C42.641 34.4609 41.8651 34.4506 41.1206 34.6251C40.3761 34.7996 39.6857 35.1536 39.1094 35.6562C37.1406 32.0719 32.9813 28.25 25 28.25C17.0156 28.25 12.8563 32.075 10.8938 35.6562C10.3175 35.1536 9.62698 34.7996 8.8825 34.6251C8.13801 34.4506 7.36213 34.4609 6.62252 34.655C5.88291 34.8492 5.20203 35.2213 4.63925 35.739C4.07646 36.2566 3.64887 36.9041 3.39375 37.625H3.125V3.25Z"
      fill="#4540DB"
    />
  </svg>,
  "UploadPhoto"
);
