// common methods to reduce complexity in methods

/**
 * Generic Or, used to provide defaults.
 * 
 * NOTE: Passing as function parameters means both `arg` and `defaultValue` get evaluated.
 * Do not use this method in cases where that is undesirable.
 * 
 * @param arg - The main value
 * @param defaultValue - value to be returned if `arg` is falsy
 * @returns (arg || defaultValue)
 */
export function valueOrDefault<Type>(arg: Type | null | undefined, defaultValue: Type) : Type {
  return arg || defaultValue;
};

/**
 * Generic Or with multiple parameters
 * 
 * NOTE: Passing as function parameters means all args get evaluated.
 * Do not use this method in cases where that is undesirable.
 * 
 * @param args - series of arguments between which or operator will be applied
 * @returns (arg1 || arg2 || arg3 || ...)
 */
export function orValues<Type>(...args: Type[]) : Type {
  let res = args[0];
  for (let item of args) {
    res = res || item;
    if (res) return res;
  }
  return res;
};

/**
 * Generic ternary expression.
 *  
 * NOTE: Passing as function parameters means both `value1` and `value2` get evaluated.
 * Do not use this method in cases where condition is preventing error by avoiding a branch.
 * 
 * For example, `ternary(x !== null, x.name, "N/A")` would throw an error when x is null,
 * because it always fetches the value of `x.null`. 
 * 
 * Typescript should already be showing an error in such cases, like `'x' is posssibly 'null'`.
 * 
 * @param condition - boolean condition
 * @param value1 - returned if `condition` is `true`
 * @param value2 - returned if `condition` is `false`
 * @returns (condition ? value1 : value2)
 */
  export function ternary<Type>(condition: boolean, value1: Type, value2: Type) : Type {
    return condition ? value1 : value2;
  };