import { Typography } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoginUser } from "../../../../../Services/idProviderService";
import getLoggedInUserPermissions from "../../../../../Shared/utils/getLoggedInUserPermission";
import { loadPermissions } from "../../../../../features/permission/userPermissionSlice";
import { getRecruiterData } from "../../../../../features/recruiterSlice";
import { setLoggedInUseDetails } from "../../../../../features/userDetails/loggedInUserDetailsSlice";
import { UserLoginRequest } from "../../../../../models/app/Login/UserLoginModel";
import setAuthToken from "../../../../../utils/setAuthToken";
import { CustomAlert } from "../../../../ui/CustomAlert";
import setup from "./../../../../../assets/images/setup.svg";

interface Props {
    isOpen: boolean;
    handleClose: () => void;
    authenticateModel: UserLoginRequest;
}
const SignupCompleteSuccessPopup: React.FC<Props> = ({
    isOpen,
    handleClose,
    authenticateModel
}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [apiLoading, setApiLoading] = useState<boolean>(false);
    const [loginResponse, setLoginResponse] = useState<any>({
        isError: false,
        message: ""
    });

    const _doSignin = () => {
        // Set Loading mode
        setApiLoading(true);
        let loginRequestPayload: UserLoginRequest = {
            username: authenticateModel?.username,
            password: authenticateModel?.password,
            loginType: authenticateModel.loginType,
            returnUrl: authenticateModel.returnUrl,
        };

        LoginUser(loginRequestPayload)
            .then((res) => {
                if (res.data.status === 200) {
                    setApiLoading(false)
                    localStorage.setItem("user", JSON.stringify(res.data.entity));

                    setAuthToken(res?.data?.accessToken);

                    dispatch(getRecruiterData() as any)
                    dispatch(setLoggedInUseDetails(res.data?.entity))

                    let userModules = getLoggedInUserPermissions();
                    dispatch(loadPermissions({ permissions: userModules }));

                    navigate("/client/dashboard");
                } else {
                    setLoginResponse({
                        ...loginResponse,
                        isError: true,
                        message: res?.data?.message?.appStatusDescription || "Something went wrong while login"
                    })
                }
            })
            .catch((err: any) => {
                setApiLoading(false);
                setLoginResponse({
                    ...loginResponse,
                    isError: true,
                    message: err?.message?.appStatusDescription || "Something went wrong while login"
                })
            });
    }
    return (
        <div>
            <Dialog
                className="custom-client-popup"
                open={isOpen}
                onClose={() => { }}
                aria-labelledby="pop-up-title"
                disableEscapeKeyDown={true}
                scroll="body"
            >
                <DialogTitle style={{ display: "flex", justifyContent: "flex-end" }}>
                    <IconButton
                       data-testid="closeButton"
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography style={{ textAlign: "center" }}>
                        <img src={setup} alt="Setup Icon" />
                    </Typography>
                    <Typography className="v2-popupTitle">
                        <div>Your account is successfully setup</div>
                    </Typography>
                    <Typography className="v2-popupContentText">
                        One of our account representatives will contact you shortly for further verification.
                    </Typography>
                    <Box className="popupActionBar">
                        <Button
                            disabled={apiLoading}
                            endIcon={apiLoading ? <CircularProgress size={"16px"} color={"inherit"} /> : ""}
                            variant="contained"
                            onClick={_doSignin}
                        >Go to Dashboard</Button>
                    </Box>
                    {
                        loginResponse.isError &&
                        <Box style={{ margin: "16px" }}>
                            <CustomAlert type="error" message={loginResponse?.message} />
                        </Box>
                    }
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default SignupCompleteSuccessPopup;
