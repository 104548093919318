import * as React from "react";
import Grid from "@mui/material/Grid";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import JobSearchbar from "./JobSearchbar";
import JobListingTable from "./JobListingTable";
import { useDispatch, useSelector } from "react-redux";
import BannerText from "../../Common/BannerText";
import { UserAuth } from "../../../models/interfaces";
import getLoggedInUserRole from "../../../Shared/utils/getLoggedInUserRole";
import { PageName } from "../../../models/app/Page/PageModel";
import AppBreadCrumb from "../../Common/AppBreadCrumb";
import { BreadCrumbItem } from "../../../models/AppModels";
import { setBreadCrumbsState } from "../../../features/Common/BreadcrumbsStateSlice";
import { getNevLink } from "../../../utils/getFileFromUrl";
import { GetJobAlertById } from "../../../Services/recruiterJobAlertService";
import { handleAdvanceSearchKeyword, setDomainOnSignin } from "../../../features/Jobs/clientJobSlice";



interface AllJobPageProps {
}

const AllJobPage: React.FC<AllJobPageProps> = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const BreadcrumbState = useSelector((state:any) => state.BreadcrumbsState);

   let { page } = useParams();
   const [searchParams]  = useSearchParams();   
   const jobAlertIdParam = searchParams?.get("jobAlertId");
   const DefaultBreadCrumbs: BreadCrumbItem[] = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
    { isLeaf: true, sequence: 2, title: `${page === "pending" ? "Pending Jobs" :"All Jobs"}`, leafClasses: " v2LastLeft"},
  ];
  
  const fromPageJobAlertListBreadCrumbs: BreadCrumbItem[] = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
    { isLeaf: false, sequence: 1, title: "Job Alert", to: "/jobAlerts" },
    { isLeaf: true, sequence: 2, title: "View Jobs", leafClasses: " v2LastLeft"},
  ];  

  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [isJobAlertLoading, setIsJobAlertLoading] = React.useState(true);
  const userRole = getLoggedInUserRole();

  React.useEffect(()=>{
    
    if (userRole === UserAuth.NotLoggedIn && searchParams?.size > 0) {
      navigate("/login", { state: { redirectUrl: location.pathname + location.search } });
    } else if (jobAlertIdParam) {
      GetJobAlert(jobAlertIdParam);
    } else {
      setIsJobAlertLoading(false);
    }
  },[jobAlertIdParam])

  React.useEffect(()=>{

    return () => {
      dispatch(setBreadCrumbsState({...BreadcrumbState, navigateFromPage:PageName.ALLJOBPAGE }));
      }
  },[])

  const GetJobAlert = (alertId:string) =>{
    GetJobAlertById(alertId).then(res => {
      if(res?.data?.status == 200 && res?.data?.message?.appStatusCode == "RC_RSF"){

      const advanceSearch = {
          jobTitle:res?.data?.entity?.jobTitle || [],
          location:res?.data?.entity?.location || [],
          client:res?.data?.entity?.client || [],
          minimumPayRate:res?.data?.entity?.minimumPayRate ?? 0 ,
          maximumPayRate:res?.data?.entity?.maximumPayRate ?? 0 ,
          currency:res?.data?.entity?.currency || ''
        }
        dispatch(setDomainOnSignin([ ...res?.data?.entity?.domains ]))
        dispatch(handleAdvanceSearchKeyword(advanceSearch)); 
        
      }
    })
    .finally(()=>{
      setIsJobAlertLoading(false)
    })
  }


  return (    
    <Grid container >
      { (userRole === UserAuth.NotLoggedIn || userRole === UserAuth.Recruiter) &&
      <Grid item xs={12}>
        <BannerText bannerText={"Active full-time and contingent jobs"} bannerType="secondary"/>
      </Grid>
      }
      
        { userRole !== UserAuth.NotLoggedIn &&
        <Grid item xs={12}>
          <AppBreadCrumb
            classes="custom-appbreadcrumb"
            BreadCrumbItems={BreadcrumbState?.navigateFromPage === PageName.JOBALERTLISTPAGE ? fromPageJobAlertListBreadCrumbs : DefaultBreadCrumbs}
          />
        </Grid>
        }
        {
          !isJobAlertLoading &&
          <>
            <Grid item xs={12}>
              <JobSearchbar totalRecords={totalRecords} pageNameType={PageName.ALLJOBPAGE} />
            </Grid>
            <Grid item xs={12} >
              <JobListingTable
                totalRecords={totalRecords} 
                setTotalRecords={setTotalRecords} 
                pageNameType={PageName.ALLJOBPAGE}
                
                />
            </Grid>
          </>
        }      
    </Grid>
  );
}

export default AllJobPage;
