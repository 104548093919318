import formatAmount from "./formatAmount";
//packageType = hourly,annully, monthly
//packageValue = 10,20,12000
//currency = USD, CAD, AUD
function getPayRate (packageType,packageValue,currency) {
    let returnData = {
      //Eg USD 15
      packageValueAndCurrency: "",
      //Eg Hourly
      packageType: "",
    };
    if(packageValue === 0)
    {
      returnData.packageValueAndCurrency = "N/A";
      returnData.packageType = ""
      return  returnData;
    }
    if(currency === null || currency === undefined)
    {
      returnData.packageValueAndCurrency = formatAmount(parseFloat(packageValue) % 1 !== 0? parseFloat(packageValue)?.toFixed(2) : parseFloat(packageValue),true,currency);;
      returnData.packageType = packageType?.toLowerCase() === "annually"? "yearly" : packageType?.toLowerCase();
      return  returnData;

    }
    returnData.packageValueAndCurrency = currency + " "+formatAmount(parseFloat(packageValue) % 1 !== 0? parseFloat(packageValue).toFixed(2) : parseFloat(packageValue),true,currency);
    returnData.packageType = packageType?.toLowerCase() === "annually" ? "yearly" : packageType?.toLowerCase();
    return returnData;
  }
export default getPayRate;