import React from 'react';
import Chip from '@mui/material/Chip';
import './chip.scss';

interface StatusChipLabelProps {
  label: string;
  type?: "successChip" | "rejectedChip" | "pendingChip" | "backlistedChip" | "markInCompleteChip" ;
}

const StatusChipLabel: React.FC<StatusChipLabelProps> = ({ label, type = 'successChip', }) => {

  return (
    <Chip
      data-testid="status-chip"
      label={label}
      className={`chipProps ${type}`}
      sx={{
        'height': 'auto',
        'width': 'min-content',
        '& .MuiChip-label': {
          display: 'block',
          whiteSpace: 'normal',
        }
      }}
    />
  );
};

export default StatusChipLabel;
