import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { GetCandidateEventDurationTypes } from '../../../../Services/candidateService';

export interface DurationOption {
  id: string;
  duration: string;
}

interface DurationPickerProps {
  label: string;
  fieldName: string;
  value: DurationOption;
  isRequired?: boolean;
  isError?: boolean;
  helperText?: string | boolean;
  handleChange: (selectedEventType: DurationOption | null) => void;
  handleBlur: (e: React.FocusEvent<any, Element>) => void;
  defaultOptionList?: DurationOption[]; // only usable when multiple duration dropdown list in single form to avoid multiple calls
}

const DurationAutocomplete: React.FC<DurationPickerProps> = ({
  label,
  fieldName,
  value,
  isRequired = false,
  isError = false,
  helperText = '',
  handleChange,
  handleBlur,
  defaultOptionList,
}) => {
  // Initial component States
  const [eventDurationTypesList, setEventDurationTypesList] = useState<DurationOption[]>([]);

  useEffect(() => {
    // If defaultOptionList is provided, use it; otherwise, fetch asynchronously
    if (defaultOptionList) {
      setEventDurationTypesList(defaultOptionList);
    } else {
      _GetCandidateEventTypes();
    }
  }, []);

  useEffect(()=>{
    if (defaultOptionList) {
      setEventDurationTypesList(defaultOptionList);
    }
  },[defaultOptionList])

  // GET Master Duration Type for candidate interview schedule
  const _GetCandidateEventTypes = async () => {
    try {
      const response = await GetCandidateEventDurationTypes();
      if (response?.data?.message?.appStatusCode === 'RC_RSF') {
        setEventDurationTypesList(response?.data?.entityList);
      } else {
        setEventDurationTypesList([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDurationChange = (_event: React.ChangeEvent<{}>, changedValue: DurationOption | null) => {
    handleChange(changedValue);
    
  };

  return (
    <Autocomplete
      clearOnEscape
      value={value}
      onChange={handleDurationChange}
      options={eventDurationTypesList}
      getOptionLabel={(option) => option.duration}
      renderInput={(params) => (
        <TextField
          {...params}
          required={isRequired}
          label={label}
          name={fieldName}
          error={isError}
          variant="standard"
          helperText={helperText}
          onBlur={handleBlur}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
    />
  );
};

export default DurationAutocomplete;
