import React from "react";
import {Divider, Paper } from "@mui/material";
import Grid from "@mui/material/Grid";
import AppBreadCrumb from "../../Common/AppBreadCrumb";
import { BreadCrumbItem } from "../../../models/AppModels";
import TeamMemberAddEditForm from "../../ClientTeam/TeamMemberAddEditForm";
import { FormMode } from "../../../models/app/AppModels";
import { useParams } from "react-router";
import { getNevLink } from "../../../utils/getFileFromUrl";
import SectionHeading from "../../ui/Form/Heading/SectionHeading";

const editTeamMemnberBreadcrumbs: BreadCrumbItem[] = [
  { isLeaf: false, sequence: 1, title: "Dashboard", to:  getNevLink("/dashboard") },
  { isLeaf: false, sequence: 2, title: "Team Members", to: getNevLink("/TeamMembers")},
  { isLeaf: true, sequence: 3, title: "Edit Team Member", leafClasses:" v2LastLeft"},
];
const EditClientTeamMemberPage: React.FC = () => {

  const { id } = useParams();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AppBreadCrumb
            classes="custom-appbreadcrumb"
            BreadCrumbItems={editTeamMemnberBreadcrumbs}
          />
        </Grid>
        <Grid item xs={12} >
        <SectionHeading title="Team Member Information"></SectionHeading>
          <Divider className="borderedDivider" />
        </Grid>
        <Grid item xs={12} mt={-2}>
          <Paper elevation={0} sx={{p:2}} className="teamMemberFormPage">
            
            <TeamMemberAddEditForm mode={FormMode.EDIT} teamMemberId={id} />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default EditClientTeamMemberPage;
