import { FormControl, Input, InputLabel } from "@material-ui/core";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import * as Yup from "yup";
import { UpdateLocation } from "../../../../Services/clientService";
import { searchOptions } from "../../../../constants/googlePlaceOption";
import {
  addressRegex,
  cityRegex,
  zipValidation,
} from "../../../../constants/regex";
import getAddressForAutoFill from "../../../../utils/getAddressForAutoFill";
import { ErrorMessage } from "../../../ui/ErrorMessage";
import "../Location.scss";
import { valueOrDefault } from "../../../../utils/complexityUtils";
import { pageStatus } from "../ManageProfile";

interface LocationProps {
  userInfo?: any;
  onChildFunction: any;
  clientId?: string;
  setPageStatus: any;
  pageStatus:pageStatus;
}
export const Location: React.FC<LocationProps> = ({
  userInfo,
  onChildFunction,
  clientId,
  setPageStatus,
  pageStatus
}) => {
  let initialState = {
    clientId: clientId,
    address: {
      id: "",
      streetAddress: "",
      city: "",
      state: "",
      postalCode: "",
      countryCode: "",
    },
  };
  const LocationSchema = Yup.object({
    id: Yup.string(),
    address: Yup.object().shape({
      id: Yup.string(),
      streetAddress: Yup.string()
        .required("Please enter the address")
        .matches(addressRegex, "Entered address is invalid"),
      city: Yup.string()
        .required("Please enter the city")
        .matches(cityRegex, "Entered city is invalid"),
      state: Yup.string()
        .required("Please enter the State")
        .matches(cityRegex, "Entered state is invalid"),
      postalCode: Yup.string()
        .required("Please enter the Zip")
        .matches(zipValidation, "Entered zip code is invalid"),
      countryCode: Yup.string()
        .required("Please enter the Country")
        .matches(cityRegex, "Entered country is invalid"),
    }),
  });

  const formik = useFormik({
    initialValues: initialState,
    enableReinitialize: true,
    validationSchema: LocationSchema,
    onSubmit: async (values, actions) => {
      setPageStatus({
        ...pageStatus,
        loading:true,
      });
      UpdateLocation(values)
      .then((response)=>{
        if(response?.data?.message?.appStatusCode === "CL_RSF"){
          setPageStatus({
            isSuccess: true,
            isError: false,
            laoding:false,
            message: "Location Details Updated Successfully.",
            key: Date.now(),
          });
        }else{
          setPageStatus({
            isSuccess: false,
            isError: true,
            laoding:false,
            message: response?.data?.message?.appStatusDescription + "Something went wrong!!!",
            key: Date.now(),
          });
        }
      })
    },
  });
  const autoCompleteGetAddress = async (address: any) => {
    let resp = await getAddressForAutoFill(address);
    formik.setFieldValue("address.id", valueOrDefault(resp?.city, ""));
    formik.setFieldValue("address.postalCode", valueOrDefault(resp?.pin, ""));
    formik.setFieldValue("address.streetAddress", valueOrDefault(resp?.formattedAddress, ""));
    formik.setFieldValue("address.countryCode", valueOrDefault(resp?.country, ""));
    formik.setFieldValue("address.state", valueOrDefault(resp?.state, ""));
    formik.setFieldValue("address.city", valueOrDefault(resp?.city, ""));

    if (resp?.pin && resp?.pin !== "") {
      formik.setFieldTouched("address.postalCode", false);
    }
    if (resp?.formattedAddress && resp?.formattedAddress !== "") {
      formik.setFieldTouched("address.streetAddress", false);
    }
    if (resp?.state && resp?.state !== "") {
      formik.setFieldTouched("address.state", false);
    }
    if (resp?.city && resp?.city !== "") {
      formik.setFieldTouched("address.city", false);
    }
    if (resp?.country && resp?.country !== "") {
      formik.setFieldTouched("address.countryCode", false);
    }
  };
  const renderFunc = ({
    getInputProps,
    suggestions,
    getSuggestionItemProps,
    loading,
  }: any) => (
    <div className="recruiter-location-address">
      <FormControl
        variant="standard"
        error={
          formik.touched.address?.streetAddress &&
          Boolean(formik.errors.address?.streetAddress)
        }
        fullWidth
        required
      >
        <InputLabel
          className="custom-address-txt"
          htmlFor="address.streetAddress"
        >
          Address
        </InputLabel>
        <Input
          {...getInputProps()}
          name="address.streetAddress"
          id="address.streetAddress"
          type="text"
          onBlur={formik.handleBlur}
        />
        <ErrorMessage
          errorText={
            formik.touched.address?.streetAddress &&
            formik.errors?.address?.streetAddress
          }
        />
      </FormControl>
      <div className="autocomplete-dropdown-container">
        {loading && <div>Loading...</div>}
        {suggestions?.map((suggestion: any) => (
          <div
            key={suggestion?.description}
            {...getSuggestionItemProps(suggestion)}
          >
            <span
              onChange={autoCompleteGetAddress}
              style={{ cursor: "pointer" }}
            >
              {suggestion?.description}
            </span>
          </div>
        ))}
      </div>
    </div>
  );

  const childFunction = () => {
    formik.handleSubmit();
    // You can add any logic you want here
  };

  onChildFunction(childFunction);
  // Make the child function accessible through the prop
  React.useEffect(() => {
    formik.setFieldValue("address", userInfo?.address);
  }, []);

  return (
    <div>
      <Box>
        <Grid container spacing={3}>
          <Grid className="custom-padding-top" item xs={12} md={12}>
            <Typography className="custom-location-txt">Location</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography>
              <PlacesAutocomplete
                searchOptions={searchOptions}
                value={formik.values?.address?.streetAddress}
                onChange={(ev: any) => {
                  formik.setFieldValue("address.streetAddress", ev);
                }}
                onSelect={autoCompleteGetAddress}
              >
                {renderFunc}
              </PlacesAutocomplete>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              data-testid="City"
              variant="standard"
              className="custom-address-txt"
              required
              id="City"
              name="address.city"
              label="City"
              value={formik.values?.address?.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched?.address?.city &&
                Boolean(formik.errors?.address?.city)
              }
              helperText={
                formik.touched?.address?.city && formik.errors.address?.city
              }
              fullWidth
              type="text"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              data-testid="State"
              variant="standard"
              className="custom-address-txt"
              required
              id="State"
              name="address.state"
              label="State"
              value={formik.values?.address?.state}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched?.address?.state &&
                Boolean(formik.errors?.address?.state)
              }
              helperText={
                formik.touched?.address?.state && formik.errors?.address?.state
              }
              fullWidth
              type="text"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              data-testid="Zip"
              variant="standard"
              className="custom-address-txt"
              required
              id="State"
              name="address.postalCode"
              label="Zip"
              value={formik.values?.address?.postalCode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched?.address?.postalCode &&
                Boolean(formik.errors?.address?.postalCode)
              }
              helperText={
                formik.touched?.address?.postalCode &&
                formik.errors?.address?.postalCode
              }
              fullWidth
              type="text"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              data-testid="Country"
              variant="standard"
              className="custom-address-txt"
              required
              id="Country"
              name="address.countryCode"
              label="Country"
              value={formik.values?.address?.countryCode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched?.address?.countryCode &&
                Boolean(formik.errors?.address?.countryCode)
              }
              helperText={
                formik.touched?.address?.countryCode &&
                formik.errors?.address?.countryCode
              }
              fullWidth
              type="text"
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
